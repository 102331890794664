import { Injectable } from '@angular/core';
import { NetworkGroup } from '@netfoundry-ui/shared/model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class NetworkGroupService extends MopResourceService {
    networkGroupList: NetworkGroup[];
    private networkGroupSource = new Subject<NetworkGroup[]>();
    private networkGroups = this.networkGroupSource.asObservable();

    constructor(protected logger: LoggerService, private apiservice: ApiService) {
        super(logger, apiservice);
    }

    /**
     * Gets all Organizations
     */
    get(): Observable<any> {
        const params = {};
        this.apiService
            .get('network-groups', params, true)
            .pipe(map(this._extractEmbedded))
            .subscribe((orgs) => {
                this.networkGroupSource.next(orgs);
            });

        return this.networkGroups;
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const networkGroups: NetworkGroup[] = [];
        let rows = [];
        if (res['_embedded'] !== undefined && res['_embedded']['organizations'] !== undefined) {
            rows = res['_embedded']['organizations'];
        } else if (res?._embedded?.networkGroupList?.length) {
            rows = res?._embedded?.networkGroupList;
        }
        for (let i = 0; i < rows.length; i++) {
            const networkGroup = rows[i];
            networkGroups.push(new NetworkGroup(networkGroup));
        }
        return networkGroups;
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return 'network-groups';
    }
}
