import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class ErrorHistoryService {
    private errorHistory: string[] = [];

    private maxErrorLength = 10;

    public addError(errorString): void {
        const timestampString = moment().format(moment.defaultFormat);

        errorString = timestampString + ' - ' + errorString;
        this.errorHistory.unshift(errorString);
        if (this.errorHistory.length > this.maxErrorLength) {
            this.errorHistory.pop();
        }
    }

    public getErrorHistroy(): string[] {
        return this.errorHistory;
    }

    public clearErrorHistory(): void {
        this.errorHistory = [];
    }

    public setMaxErrorHistoryLength(maxLength: number): void {
        this.maxErrorLength = maxLength;
    }
}
