<div [ngClass]="{ inline: isInline }" class="fullModal opened addForm" id="AddGatewayModal">
    <app-loader [isLoading]="isLoading"></app-loader>

    <div *ngIf="!isComplete && !isLoading" class="fullRow" id="Stage2">
        <div class="title" style="filter: brightness(50%)">Create A New Gateway</div>
        <div class="subtitle">Enter your Gateway attributes</div>

        <div class="sized">
            <div class="label row">
                Gateway Name
                <span [hidden]="!errorNameLength" class="error">Name must be at least 5 characters</span>
                <span [hidden]="errorNameLength" class="note">Required</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="model.name"
                    [ngClass]="{ error: errorName }"
                    class="restrictNormal"
                    id="EditName"
                    maxlength="{{ maxLength }}"
                    minlength="5"
                    placeholder="What would you like to call this gateway?"
                    type="text"
                />
            </div>

            <div class="label row">
                <span class="private">Cloud </span>Region<span [hidden]="!errorRegion" class="error">Required</span
                ><span [hidden]="errorRegion" class="note">Required</span>
            </div>
            <div class="row">
                <select
                    (keyup.enter)="save()"
                    [(ngModel)]="model.geoRegionId"
                    [ngClass]="{ error: errorRegion }"
                    class="regions"
                    id="RegionList"
                    placeholder="Select a Cloud Region"
                >
                    <option value="">Select A Region</option>
                    <option *ngFor="let region of regions | sortby: 'name'" [value]="region.id">
                        {{ region.name }}
                    </option>
                </select>
            </div>

            <!-- <div *ngIf="showBreakoutFields">
<div class="label row">O365 Breakout Next Hop IP
<span class="error" [hidden]="!errorNextHop">Invalid</span>
<span class="note" [hidden]="errorNextHop">Optional</span>
</div>
<div class="row"><input id="NextHopIp" type="text" class="restrictNormal" [ngClass]="{error:errorNextHop}" placeholder="Next Hop IP" [(ngModel)]="model.o365BreakoutNextHopIp"/></div>
</div> -->

            <div *ngIf="!netflowEnabled && !hideHaUpgrade && authorizationService.canCreateGatewayClusters()">
                <div class="label row">Enable High Availablity<span class="note">Enable to Edit Details</span></div>
                <div class="row">
                    <div class="darkGrid">
                        <div class="title">
                            <div class="icon-ha"></div>
                        </div>
                        <div class="subtitle">
                            ENABLE HIGH AVAILABILITY<br />
                            FOR ULTIMATE REDUNDANCY
                        </div>
                        <div>
                            <div class="switchGrid">
                                <div>
                                    <div (click)="toggleHAGateway()" [ngClass]="{ on: isHAGateway }" class="toggle">
                                        <span [hidden]="!isHAGateway">ON&nbsp;&nbsp;&nbsp;</span>
                                        <span [hidden]="isHAGateway">&nbsp;&nbsp;&nbsp;OFF</span>
                                        <div class="switch"></div>
                                    </div>
                                </div>
                                <div>
                                    <span [hidden]="!isHAGateway">Enabled</span>
                                    <span [hidden]="isHAGateway">Disabled</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!isHAGateway && collectors.length > 0" class="label row labelExpansion">
                Advanced Options<span class="note">Open to Edit Details</span>
            </div>
            <div *ngIf="!isHAGateway && collectors.length > 0" class="row expansion">
                <mat-accordion>
                    <mat-expansion-panel #panel [hideToggle]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'65px'">
                            <mat-panel-title class="expansionLabel">
                                <div class="title">
                                    <div class="icon-AdvancedOptions"></div>
                                </div>
                                <div class="subtitle">Advanced Options</div>
                                <span
                                    [ngClass]="{ open: panel.expanded }"
                                    class="expansion-indicator icon-Sort_Down"
                                ></span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-grid-list cols="10" rowHeight="45px">
                            <mat-grid-tile [colspan]="9">
                                <div class="label netflowFieldLabel">Netflow</div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1">
                                <div class="toggleTile">
                                    <div
                                        (click)="toggleNetflowEnabled()"
                                        [ngClass]="{ on: netflowEnabled }"
                                        class="toggle gridToggle"
                                        id="IcmpToggle"
                                    >
                                        <span [hidden]="!netflowEnabled" id="NetflowToggleOn"
                                            >ON&nbsp;&nbsp;&nbsp;</span
                                        >
                                        <span [hidden]="netflowEnabled" id="NetflowToggleOff"
                                            >&nbsp;&nbsp;&nbsp;OFF</span
                                        >
                                        <div class="switch"></div>
                                    </div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile *ngIf="netflowEnabled" [colspan]="5">
                                <div class="label netflowFieldLabel">Collector</div>
                            </mat-grid-tile>
                            <mat-grid-tile *ngIf="netflowEnabled" [colspan]="5">
                                <select
                                    [(ngModel)]="exportModel.collectorId"
                                    [ngClass]="{ error: errorCollector }"
                                    class="collectors netflowField"
                                    id="CollectorList"
                                    placeholder="Select a Netflow Collector"
                                >
                                    <option value="">Select A Netflow Collector</option>
                                    <option
                                        *ngFor="let collector of collectors | sortby: 'name'"
                                        [value]="collector.id"
                                    >
                                        {{ collector.name }}
                                    </option>
                                </select>
                            </mat-grid-tile>
                            <mat-grid-tile *ngIf="netflowEnabled" [colspan]="5">
                                <div class="label netflowFieldLabel">Update Frequency (in seconds)</div>
                            </mat-grid-tile>
                            <mat-grid-tile *ngIf="netflowEnabled" [colspan]="5">
                                <input
                                    [(ngModel)]="exportModel.exportIntervalSeconds"
                                    [ngClass]="{ error: errorFrequency }"
                                    class="netflowField"
                                    id="EditFrequency"
                                    max="300"
                                    min="1"
                                    placeholder="the frequency the collector updates in seconds"
                                    type="number"
                                />
                            </mat-grid-tile>
                        </mat-grid-list>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div class="row buttonRow right" style="margin-top: 20px">
                <button (click)="save()" *ngIf="!processing" class="save" id="SaveGatewayButton">Create</button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
        </div>
    </div>
    <div [hidden]="!isComplete || isLoading" class="fullRow" id="Stage3">
        <div class="title">congratulations</div>
        <div class="subtitle">Your Gateway {{ completedTitleStatusString }}</div>
        <div [ngClass]="{ building: isBuilding, created: !isBuilding }" class="row center">
            <span *ngIf="isBuilding" class="completedEndpointBubble">
                <svg class="circular" viewBox="25 25 50 50">
                    <circle
                        class="path"
                        cx="50"
                        cy="50"
                        fill="none"
                        r="20"
                        stroke-miterlimit="10"
                        stroke-width="5"
                    ></circle>
                </svg>
            </span>
        </div>

        <div>
            <div *ngFor="let gateway of gateways; let index = index" class="row">
                <div class="sized">
                    <div *ngIf="!zitiEnabledService.zitiPure">
                        <div [hidden]="gateways.length === 0" class="label row">
                            {{ gateway.name }} Registration Key<span class="note">Click to Copy</span>
                        </div>
                        <div class="row">
                            <input
                                (click)="gatewayFormService.copy(gateway)"
                                [(ngModel)]="gateway.registrationKey"
                                class="RegKey"
                                data-enter="SaveButton"
                                id="RegKey_{{ gateway.id }}"
                                readonly
                                style="margin-bottom: 10px"
                                type="text"
                            />
                        </div>
                    </div>
                    <div *ngIf="zitiEnabledService.zitiPure">
                        <div class="label row">
                            {{ gateway.name }} Registration Key<span class="note">Tap To Download</span>
                        </div>
                        <div class="row">
                            <div (click)="downloadJWT(gateway, index)" class="downloadButton">
                                <div class="DownloadKey"></div>
                                <div>DOWNLOAD KEY</div>
                                <div class="TapToDownload"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="subtitle">Download and install instructions</div>

        <div class="row center">
            <div
                (click)="
                    gatewayFormService.openUrl(
                        'https://marketplace.alibabacloud.com/products/56680003/sgcmjj00024839.html'
                    )
                "
                class="buttonCard shadow openable"
            >
                <div class="gwdownloadicons" style="background-image: url(/assets/svgs/Icon_Download.svg)"></div>
                <div class="title">Download Install Package</div>
                <div class="content">Use this link to download the AliBaba Gateway image.</div>
                <div class="row center buttonArea">
                    <button class="save" href="/app-downloads">Download</button>
                </div>
            </div>
            <div
                (click)="gatewayFormService.openUrl('https://netfoundry.github.io/mop/alicloud/intro/')"
                class="buttonCard shadow"
            >
                <div class="gwdownloadicons" style="background-image: url(/assets/svgs/Icon_Doc.svg)"></div>
                <div class="title">View Install Instructions</div>
                <div class="content">Connect Private IP space using a AliBaba Gateway.</div>
                <div class="row center buttonArea">
                    <button class="save">View</button>
                </div>
            </div>
        </div>
    </div>
    <div (click)="hideForm()" class="buttonBall close" id="CloseGatewayButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div class="private">
        <div
            (click)="share()"
            *ngIf="authorizationService.canShareEndpoint(model.id)"
            [hidden]="!isComplete"
            class="buttonBall share"
            id="ShareGatewayButton"
        >
            <div class="buttonLabel">SHARE</div>
        </div>
    </div>
    <div (click)="goBack()" [hidden]="isLoading || isComplete" class="buttonBall back" id="BackGatewayButton">
        <div class="buttonLabel">BACK</div>
    </div>
</div>
