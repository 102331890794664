import {Component, Inject, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { EdgeRouterServiceV2, EndpointServiceV2, PlatformServiceService } from '@netfoundry-ui/shared/apiv2';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { TobytesPipe } from '@netfoundry-ui/ui/pipes';
import { Chart } from 'angular-highcharts';
import { Subscription } from 'rxjs';
import { NetworkUtilizationTemplateQueryParameter } from '@netfoundry-ui/shared/model';
import { TEMPLATE_SEARCH_SERVICE, TemplateSearchService } from "@netfoundry-ui/shared/apiv2";
import {isEmpty, isNil} from "lodash";

@Component({
    selector: 'app-ziti-utilization-pie-mini',
    templateUrl: './ziti-utilization-pie-mini.component.html',
    styleUrls: ['./ziti-utilization-pie-mini.component.scss'],
    providers: [TobytesPipe, { provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }],
})
export class ZitiUtilizationPieMiniComponent implements OnInit, OnChanges, OnDestroy {
    @Input() sourceId: string;
    @Input() networkId: any = null;
    @Input() organizationId: any = null;
    @Input() dateFilter: any = '24h';
    @Input() height = '300px';
    @Input() aggregateField = 'nf_endpoint_name.keyword';
    @Input() sumAggregateField: string;
    @Input() getById: (id: string) => Promise<string>;
    @Input() label = 'Endpoints';
    @Input() selectedAttributes: 'selectedAttibutes';
    @Input() newStartTime: any;
    @Input() newEndTime: any;
    chart: Chart;
    colors = ['#0273fb', '#08dc5a', '#FF0D49', '#1aadce', '#6d00f2', '#ffc000', '#ff7e00', '#ca0000', '#00aeb0'];
    noData = true;
    isLoading = false;
    initialized = false;
    currentOrgId;
    zitiId;
    top_five_options = {};
    top_five_series = [];
    interval = '1m';
    items = [];
    @Input() filterField = null;
    // @Input() aggregateField = 'identity_id.keyword';
    // @Input() sumAggregateField = 'identity_sum';
    @Input() filterValue = null;
    @Input() endTime = Date.now();
    @Input() startTime = this.endTime - 24 * 60 * 60 * 1000;
    private subscription = new Subscription();

    constructor(
        private elasticsearch: ElasticsearchService,
        private toBytes: TobytesPipe,
        private graphViewer: MatDialog,
        public platformService: PlatformServiceService,
        public endpointService: EndpointServiceV2,
        public edgeRouterService: EdgeRouterServiceV2,
        public filterService: TableFilterService,
        @Inject(TEMPLATE_SEARCH_SERVICE) public templateService: TemplateSearchService
    ) {}

    ngOnInit() {
        this.initialized = true;
        this.generateTopItemsData();
        this.subscription.add(
            this.filterService.setDateFilterEvent.subscribe(() => {
                this.newStartTime = this.filterService.getStartTime();
                this.newEndTime = this.filterService.getEndTime();
                this.generateTopItemsData();
            })
        );
    }

    ngOnChanges() {
        this.generateTopItemsData();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public set_chart_options() {
        const pipe = this.toBytes;
        this.top_five_options = {
            colors: this.colors,
            styledMode: true,
            title: { text: null },
            subtitle: {
                text: this.label,
                verticalAlign: 'bottom',
            },
            credits: { enabled: false },
            chart: {
                type: 'pie',
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                height: this.height,
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    // center: ['100px', '100px'], // this was doing weird things to the tooltip and hiding it behind the white
                    dataLabels: {
                        enabled: false,
                    },
                    size: '100%',
                },
            },
            yAxis: {
                title: {
                    text: null,
                },
                labels: {
                    formatter: function () {
                        return pipe.transform(this.value);
                    },
                    style: {
                        color: window.getComputedStyle(document.body).getPropertyValue('--text'),
                    },
                },
            },
            tooltip: {
                formatter: function () {
                    return this.point.name + '<br /><strong>' + pipe.transform(this.y) + '</strong>';
                },
            },
        };
    }

    public getTopItems() {
        const index = 'ncutilization';
        const TopItems = [];
        const items = [];

        this.noData = true;
        this.isLoading = true;

        //reporting
        let componentName: any = '';
        let componentValue: any = '';
        let attributes: any = '';
        if (this.filterField != null && this.filterValue != null) {
            componentName = this.filterField + '';
            componentValue = this.filterValue + '';
        }
        if (this.selectedAttributes.length > 0) {
            attributes = JSON.stringify(this.selectedAttributes);
        }

      const networkUtilizationTemplateQueryParameter: NetworkUtilizationTemplateQueryParameter =
        this.templateService.getUtilizationTemplateQuery(
          this.newStartTime.valueOf(),
          this.newEndTime.valueOf(),
          this.dateFilter,
          componentName,
          componentValue,
          attributes,
          this.networkId,
          this.organizationId,
          index,
          this.aggregateField,
          '10'
        );

        this.subscription.add(
            //this.elasticsearch.search(this.organizationId, index, this.getQuery())
            this.elasticsearch
                .apiTemplateSearch(
                  this.templateService.UTILIZATION_SUM_BY_COMPONENT_TEMPLATE,
                    networkUtilizationTemplateQueryParameter
                )
                .subscribe((data) => {
                    const items = this.templateService.getUsagePieSumByComponentData(data);
                    if (isEmpty(items) || isNil(items)) {
                      this.isLoading = false;
                      this.noData = true;
                      return;
                    }
                    this.isLoading = false;
                    this.noData = false;
                    this.set_chart_options();
                    this.top_five_series = [
                        {
                            name: this.label,
                            data: items,
                        },
                    ];
                    this.items = items;
                })
        );
    }

    public getQuery() {
        const model: any = {
            aggs: {
                items: {
                    terms: {
                        field: this.aggregateField,
                        size: 10,
                        order: {
                            item_sum: 'desc',
                        },
                    },
                    aggs: {
                        item_sum: {
                            sum: {
                                field: 'usage',
                            },
                        },
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            match_phrase: {
                                'network_id.keyword': {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                'organizationId.keyword': {
                                    query: this.organizationId,
                                },
                            },
                        },
                        {
                            // filter down to two usage types since these are the items that we will calculate billing on
                            bool: {
                                should: [
                                    {
                                        match_phrase: {
                                            // traffic delivered through the fabric to service's destination, renders as TX
                                            'usage_type.keyword': 'usage.ingress.tx',
                                        },
                                    },
                                    {
                                        match_phrase: {
                                            // traffic received back through the fabric to the initiator, renders as RX
                                            'usage_type.keyword': 'usage.egress.tx',
                                        },
                                    },
                                ],
                                minimum_should_match: 1,
                            },
                        },
                    ],
                    filter: {
                        bool: {
                            must: [{ match_all: {} }],
                        },
                    },
                },
            },
        };

        // this will allow us to filter by endpoint, edge router, or service
        if (this.filterField != null && this.filterValue != null) {
            const search = { match_phrase: {} };
            search.match_phrase[this.filterField] = { query: this.filterValue };
            model.query.bool.must.push(search);
        }
        if (this.selectedAttributes.length > 0) {
            const attributeFilter = {
                terms: {
                    'attributes.keyword': this.selectedAttributes,
                },
            };

            model.query.bool.filter.bool.must.push(attributeFilter);
        }

        model.query.bool.must.push({
            range: {
                '@timestamp': {
                    gte: this.newStartTime.valueOf(),
                    lte: this.newEndTime.valueOf(),
                    format: 'epoch_millis',
                },
            },
        });

        return model;
    }

    private generateTopItemsData() {
        if (this.initialized && this.networkId !== null && this.networkId && this.organizationId !== null) {
            this.getTopItems();
        }
    }
}
