import { Component, Input, OnChanges } from '@angular/core';
import { LoggerService, S3Service } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-s3-service-preselect',
    templateUrl: './s3-service-preselect.component.html',
    styleUrls: ['./s3-service-preselect.component.scss'],
})
export class S3ServicePreselectComponent implements OnChanges {
    @Input() regionId;
    @Input() step = 3;
    @Input() regions;

    services: any[];
    region: any = {};

    public filteredRanges: any[];
    private subscription = new Subscription();

    constructor(private logger: LoggerService, private s3: S3Service) {}

    ngOnChanges(): void {
        this.getIPs();
    }

    getIPs() {
        // this.logger.info("region", this.region);

        this.region = this.regions.find((region) => region.id === this.regionId);
        this.logger.info(this.regions);

        if (this.region === undefined) {
            return;
        }

        const regionName = this.region.name.slice(this.region.name.lastIndexOf(' ') + 1).toLowerCase();

        this.s3.getAwsIps().subscribe((ip: any) => {
            this.filteredRanges = ip.prefixes.filter((ip: any) => ip.region === regionName && ip.service === 'S3');
            this.logger.info(JSON.stringify(this.filteredRanges));
        });
    }

    isPassedStep(index) {
        return this.step > index;
    }
}
