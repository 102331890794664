import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientService, GatewayClusterService, GatewayService, ValidateService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss'],
})
export class ShareComponent {
    type = 'client';
    emails = '';
    endpoint = null;

    template = 'EmailClient';

    replacementParams = {
        EMAIL: localStorage.getItem('profile_email'),
        USER: localStorage.getItem('profile_nick'),
        ENV: '',
    };

    errorEmails = false;

    constructor(
        private gatewayService: GatewayService,
        private clientService: ClientService,
        private gatewayClusterService: GatewayClusterService,
        private dialogRef: MatDialogRef<ShareComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private validateService: ValidateService
    ) {
        this.type = data.type;
        this.endpoint = data.endpoint;
    }

    doShare() {
        if (this.validate()) {
            const splitEmails = this.emails.split(';');
            const emailList = [];
            for (const email of splitEmails) {
                emailList.push(email.trim());
            }

            const payload = {
                toList: emailList.join(',').trim(),
                subject: 'NetFoundry - Registration Information',
                from: 'no-reply@netfoundry.io',
                replacementParams: this.replacementParams,
            };

            if (this.endpoint.endpointType === 'CL' || this.endpoint.endpointType === 'ZTCL') {
                this.clientService.share(this.endpoint, payload).subscribe(() => {
                    this.close();
                });
            } else if (this.endpoint.protectionType != null) {
                this.gatewayClusterService.share(this.endpoint, payload).subscribe(() => {
                    this.close();
                });
            } else {
                this.gatewayService.share(this.endpoint, payload).subscribe(() => {
                    this.close();
                });
            }
        }
    }

    close() {
        this.emails = '';
        this.dialogRef.close();
    }

    validate() {
        this.errorEmails = !this.validateService.hasValue(this.emails);

        if (!this.validateService.isValidEmailList(this.emails)) {
            this.errorEmails = true;
        }

        return !this.errorEmails;
    }
}
