import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HTTP_CLIENT, LoggerService, TokenService } from '@netfoundry-ui/shared/services';
import { Observable, of as observableOf } from 'rxjs';
import moment from 'moment';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { EventTemplateQueryParameter } from '@netfoundry-ui/shared/model';
import { ElasticsearchQuery } from './query/elasticsearch-query';

export const ELASTIC_CONFIGURATION = new InjectionToken<ElasticConfig>('ELASTIC_CONFIGURATION');

export interface ElasticConfig {
    usePublicApi: boolean;
    url?: string;
    username?: string;
    password?: string;
}

@Injectable({ providedIn: 'root' })
export class ElasticsearchService {
    public intervals = {
        '1h': moment.duration(5, 'minutes').asMilliseconds(),
        '6h': moment.duration(15, 'minutes').asMilliseconds(),
        '24h': moment.duration(1, 'hours').asMilliseconds(),
        '3d': moment.duration(3, 'hours').asMilliseconds(),
        '7d': moment.duration(1, 'hours').asMilliseconds(),
        '30d': moment.duration(1, 'days').asMilliseconds(),
        '1m': moment.duration(1, 'days').asMilliseconds(),
        '6m': moment.duration(1, 'weeks').asMilliseconds(),
        '12m': moment.duration(1, 'months').asMilliseconds(),
    };
    //  however, for 30d and 1m it decides to display 7 day intervals instead of 5 as specified in the intervals object
    public units = {
        '1h': null,
        '6h': null,
        '24h': null,
        '3d': null,
        '7d': null,
        // '30d': [['day', [5, 10, 15, 20, 25, 30]]],
        '30d': null,
        '1m': [['day', [5, 10, 15, 20, 25, 30]]],
        '6m': null,
        '12m': null,
    };

    // object for obtaining the interval to use for a chart given the current date filter
    // for most filters the chart displays fine given just the interval
    // protected elasticConfig;
    private apiUrl;

    /**
     * Constructor
     */
    constructor(
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ELASTIC_CONFIGURATION) config: ElasticConfig,
        @Inject(ENVIRONMENT) private environment: Environment,
        private logger: LoggerService,
        public tokenService: TokenService
    ) {
        this.apiUrl = config['url'];
    }

    /**
     * Generic HTTP Post
     */
    search(orgId: string, path: string, body: any = {}, params: any = {}): Observable<any> {
        return this._apiSearch(orgId, path, body, params);
    }

    /**
     * Call explicitly for datacenters. an org ID is not needed here
     */
    dataCenterSearch(path: string, body: any = {}, params: any = {}): Observable<any> {
        const fullpath = `${this.apiUrl}elastic/${path}/_search/`;
        this.logger.info(`Fetch elastic data from: ${fullpath}`);

        // Http interceptor takes care of auth headers, no longer need to inject them here
        return this.http.post(fullpath, body, params);
    }

    /**
     * Returns an elasticsearch query as an associative array (table style structure)
     */
    public toTable(result: any, query: ElasticsearchQuery) {
        const table = {};

        const aggregate = query.getAggregateName();
        if (result.aggregations != null && result.aggregations[aggregate] != null) {
            if (result.aggregations[aggregate].buckets.length > 0) {
                for (let i = 0; i < result.aggregations[aggregate].buckets.length; i++) {
                    const key = result.aggregations[aggregate].buckets[i].key;
                    const val = result.aggregations[aggregate].buckets[i]['1'].value;

                    table[key] = val;
                }
            }
        }

        return table;
    }

    /**
     * Processes a plain search into an array, and returns the _source fields
     */
    public hitsToArray(data: any, searchName) {
        const eventArray = [];

        if (
            data.hits !== undefined &&
            data.hits !== null &&
            data.hits.hits !== null &&
            data.hits.hits.length !== undefined
        ) {
            if (data.hits.hits.length > 0) {
                // traverse the hits
                for (let i = 0; i < data.hits.hits.length; i++) {
                    const eventRec = data.hits.hits[i]['_source'];
                    eventArray.push(eventRec);
                }

                // push as a single event so we don't redraw the dom
                return eventArray;
            }
        }

        return eventArray;
    }

    /**
     * Determine what interval setting to use for time-based aggregations
     */
    public determine_interval(dateFilter) {
        if (dateFilter.includes('m')) {
            return '1d';
        } else if (dateFilter.includes('d')) {
            return '1h';
        } else if (dateFilter === '1h') {
            return '5m';
        } else if (dateFilter.includes('h')) {
            return '1h';
        } else {
            // just in case...
            return '1d';
        }
    }

    /**
     * Call the public API for data
     */
    private _apiSearch(orgId: string, path: string, body: any = {}, params: any = {}): Observable<any> {
        if (orgId === undefined || orgId == null) {
            // noinspection TypeScriptUnresolvedFunction
            return observableOf({});
        }

        let fullpath;
        if (this.environment.elasticConfig?.reportingServiceEnabled) {
            fullpath = `${this.environment.elasticConfig.reportingServiceUrl}/${path}/${orgId}/_search/`;
        } else {
            fullpath = `${this.apiUrl}elastic/${path}/${orgId}/_search/`;
        }

        // Http interceptor takes care of auth headers, no longer need to inject them here
        const token = this.tokenService.getAccessToken();
        if (!token.expired && token.accessToken) {
            if (!params.headers) {
                params.headers = [];
            }
            params.headers['Authorization'] = `Bearer ${token.accessToken}`;
        }

        return this.http.post(fullpath, body, params);
    }

    /**
     * Call the events template search
     */
    apiTemplateSearch(pathParameter: string, eventParams: any): Observable<any> {
        // add networkGroupId check similar to search query call
        if (
            eventParams.networkGroupId === undefined ||
            eventParams.networkGroupId == null ||
            eventParams.networkId === undefined
        ) {
            // noinspection TypeScriptUnresolvedFunction
            return observableOf({});
        }
        const params = this.mapTemlateParamsToHttpParams(eventParams);
        const headers = new HttpHeaders();
        const fullpath = `${this.environment.elasticConfig.reportingTemplateServiceUrl}/${pathParameter}`;
        return this.http.get(fullpath, { headers, params });
    }

    mapTemlateParamsToHttpParams(templateParams: any): HttpParams {
        return Object.keys(templateParams).reduce(
            (params, key) => params.set(key, templateParams[key]),
            new HttpParams()
        );
    }
}
