<div class="row" style="padding: 0; margin-top: -53px">
    <div *ngIf="!isLoading && !noData">
        <app-highcharts-generic
            [chartheight]="height"
            [options]="chart_options"
            [series]="chart_series"
        ></app-highcharts-generic>
    </div>
    <div *ngIf="!isLoading && noData" class="noDataZiti">No Data</div>
    <app-loader [isLoading]="isLoading"></app-loader>
</div>
