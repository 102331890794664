import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { FeatureZitiZitiShareModule } from '@netfoundry-ui/feature/ziti/ziti-share';
import { UiNoitemsModule } from '@netfoundry-ui/ui/noitems';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedModelModule } from '@netfoundry-ui/shared/model';

import { MatDialogModule } from '@angular/material/dialog';
import { SharedApiv2Module } from '@netfoundry-ui/shared/apiv2';
import { FeatureTagsSelectorModule } from '@netfoundry-ui/feature/tags-selector';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { FeatureNfSelect2Module } from '@netfoundry-ui/feature/nf-select2';
import { FeatureDateFiltersModule } from '@netfoundry-ui/feature/date-filters';
import { FeatureZitiUtilizationBasicModule } from '@netfoundry-ui/feature/ziti-utilization-basic';
import { FeatureFormSupportButtonModule } from '@netfoundry-ui/feature/form-support-button';
import { FeatureZitiModule } from '@netfoundry-ui/feature/ziti';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FeatureMetricsModalModule } from '@netfoundry-ui/feature/metrics-modal';
import { FeatureEventsModalModule } from '@netfoundry-ui/feature/events-modal';
import { FeatureOnlineStatusModule } from '@netfoundry-ui/feature/online-status';
import { FeaturePreviewListModule } from '@netfoundry-ui/feature/preview-list';
import { ZitiEndpointFormComponent } from './ziti-endpoint-form.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FeatureZitiZitiShareModule,
        UiPipesModule,
        UiLoaderModule,
        UiNoitemsModule,
        MatTooltipModule,
        QRCodeModule,
        SharedModelModule,
        MatDialogModule,
        SharedApiv2Module,
        FeatureTagsSelectorModule,
        MatExpansionModule,
        MatGridListModule,
        FeatureNfSelect2Module,
        FeatureDateFiltersModule,
        FeatureZitiUtilizationBasicModule,
        FeatureFormSupportButtonModule,

        MatTabsModule,
        FeatureZitiModule,
        MatChipsModule,
        MatDatepickerModule,
        MatChipsModule,
        FeatureMetricsModalModule,
        FeatureEventsModalModule,
        FeatureOnlineStatusModule,
        FeaturePreviewListModule,

        SharedApiv2Module,
        UiPipesModule,
        MatDatepickerModule,
    ],
    declarations: [ZitiEndpointFormComponent],
    exports: [ZitiEndpointFormComponent],
})
export class FeatureFormZitiEndpointFormModule {}
