<span (clickOutside)="hide()" [ngClass]="{ readOnly: disableField }" class="clickOutside fullWidth tagsSelector">
    <span
        class="select2 select2-container select2-container--default select2-container--below select2-container--focus"
        dir="ltr"
    >
        <span class="selection">
            <span
                [ngClass]="{ 'select2-selection-focus': !hideSelect, tagError: _hasError }"
                aria-expanded="false"
                aria-haspopup="true"
                class="select2-selection select2-selection--multiple"
                role="combobox"
                tabindex="-1"
            >
                <input
                    (focus)="inputFocus()"
                    (keydown)="handleKeyDown($event)"
                    (keyup)="handleKeyUp($event, filterString)"
                    (mouseup)="applyFilterMouseUp()"
                    [(ngModel)]="filterString"
                    [disabled]="disableField"
                    [ngClass]="{ empty: _selectedAttributes?.size <= 0, tagError: _hasError }"
                    aria-autocomplete="list"
                    autocapitalize="off"
                    autocomplete="off"
                    autocorrect="off"
                    class="select2-search__field"
                    placeholder="{{
                        disableField ? (_selectedAttributes?.size <= 0 ? 'Nothing selected' : '') : placeholder
                    }}"
                    role="textbox"
                    spellcheck="false"
                    tabindex="0"
                    type="search"
                />
                <span *ngIf="isLoading" class="spinner"></span>

                <ul
                    (click)="openSelect()"
                    [ngClass]="{ error: _hasError, readOnly: disableField, empty: _selectedAttributes?.size <= 0 }"
                    class="select2-selection__rendered"
                >
                    <li class="select2-search select2-search--inline"></li>

                    <li></li>
                    <li
                        (click)="$event.stopPropagation()"
                        *ngFor="let item of _selectedAttributeArray"
                        [hidden]="item.name === this.hideOption"
                        [ngClass]="{
                            hashtag: item?.isGroup,
                            attag: item?.isNamed,
                            notatt: !item?.isNamed && !item?.isGroup
                        }"
                        class="select2-selection__choice"
                        title="{{ item.name }}"
                    >
                        <span
                            (click)="remove(item); $event.stopPropagation()"
                            class="select2-selection__choice__remove"
                            role="presentation"
                            >×</span
                        >{{ item.name }}
                    </li>
                </ul>
            </span>
        </span>
        <span aria-hidden="true" class="dropdown-wrapper"></span>
    </span>
    <span *ngIf="_hasError" class="err">
        {{ errorMessage }}
    </span>

    <span
        *ngIf="!hideSelect"
        [ngClass]="{ withBorder: !hideSelect }"
        class="nf-select2-dropdown select2-dropdown--below"
        dir="ltr"
    >
        <span class="select2-results">
            <ul
                aria-expanded="true"
                aria-hidden="false"
                aria-multiselectable="true"
                class="select2-results__options"
                role="tree"
            >
                <li
                    (click)="addNewAttribute(filterString)"
                    [hidden]="filterString === '' || exists(filterString)"
                    aria-selected="false"
                    class="select2-results__option hashtag"
                    role="treeitem"
                    title="{{ filterString }} (New Attribute)"
                >
                    {{ filterString }} (New Attribute)
                </li>
                <li
                    (click)="addSelected(option)"
                    *ngFor="let option of displayedOptions"
                    [hidden]="option.name === this.hideOption || isSelected(option)"
                    [ngClass]="{
                        hashtag: option?.isGroup,
                        attag: option?.isNamed,
                        notatt: !option?.isNamed && !option?.isGroup
                    }"
                    aria-selected="false"
                    class="select2-results__option"
                    role="treeitem"
                >
                    {{ option.name }}
                </li>
            </ul>
        </span>
    </span>
</span>
