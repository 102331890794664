import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { FeatureSideFilterModule } from '@netfoundry-ui/feature/side-filter';
import { FeatureResourceStatusModule } from '@netfoundry-ui/feature/resource-status';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule } from '@angular/forms';
import { AngularResizeEventModule } from 'angular-resize-event';
import { ChipsModule } from 'primeng/chips';
import { CalendarModule } from 'primeng/calendar';
import { DataTableComponent } from './data-table.component';
import { TableCellDetailsComponent } from './table-cell-details/table-cell-details.component';
import { TableCellMenuComponent } from './table-cell-menu/table-cell-menu.component';
import { TableCellMFAComponent } from './table-cell-mfa/table-cell-mfa.component';
import { TableCellOSComponent } from './table-cell-os/table-cell-os.component';
import { TableCellProcessInfoComponent } from './table-cell-process-info/table-cell-process-info.component';
import { TableCellRegistrationComponent } from './table-cell-registration/table-cell-registration.component';
import { TableCellResourceStatusComponent } from './table-cell-resource-status/table-cell-resource-status.component';
import { TableCellSelectComponent } from './table-cell-select/table-cell-select.component';
import { TableCellSessionComponent } from './table-cell-session/table-cell-session.component';
import { TableCellTypeComponent } from './table-cell-type/table-cell-type.component';
import { TableHeaderDefaultComponent } from './table-header-default/table-header-default.component';
import { TableHeaderFilterComponent } from './table-header-filter/table-header-filter.component';
import { TableHeaderMenuComponent } from './table-header-menu/table-header-menu.component';
import { TableHeaderProcessComponent } from './table-header-process/table-header-process.component';
import { TableHeaderSelectComponent } from './table-header-select/table-header-select.component';
import { TableCellActiveComponent } from './table-cell-active/table-cell-active.component';
import { TableCellEmailComponent } from './table-cell-email/table-cell-email.component';
import { DataTable2Component } from './data-table2.component';
import { TableCellUpgradableComponent } from './table-cell-upgradable/table-cell-upgradable.component';
import { TableCellEventsComponent } from './table-cell-events/table-cell-events.component';
import { TableIpAndPortFilterComponent } from './table-ip-and-port-filter/table-ip-and-port-filter.component';
import { TableCellManagedComponent } from './table-cell-managed/table-cell-managed.component';

@NgModule({
    imports: [
        CommonModule,
        ClickOutsideModule,
        AgGridModule,
        FormsModule,
        AngularResizeEventModule,
        FeatureSideFilterModule,
        FeatureResourceStatusModule,
        FeatureSideFilterModule,
        ChipsModule,
        CalendarModule,
    ],
    declarations: [
        DataTableComponent,
        DataTable2Component,
        TableCellMenuComponent,
        TableCellSelectComponent,
        TableHeaderMenuComponent,
        TableHeaderSelectComponent,
        TableHeaderDefaultComponent,
        TableCellRegistrationComponent,
        TableCellManagedComponent,
        TableCellResourceStatusComponent,
        TableCellSessionComponent,
        TableCellOSComponent,
        TableHeaderFilterComponent,
        TableCellMFAComponent,
        TableCellDetailsComponent,
        TableCellProcessInfoComponent,
        TableHeaderProcessComponent,
        TableCellTypeComponent,
        TableCellActiveComponent,
        TableCellEmailComponent,
        TableCellUpgradableComponent,
        TableCellEventsComponent,
        TableIpAndPortFilterComponent,
    ],
    exports: [
        DataTableComponent,
        DataTable2Component,
        TableCellMenuComponent,
        TableCellSelectComponent,
        TableHeaderMenuComponent,
        TableHeaderSelectComponent,
        TableHeaderDefaultComponent,
        TableCellRegistrationComponent,
        TableCellResourceStatusComponent,
        TableCellManagedComponent,
        TableCellSessionComponent,
        TableCellOSComponent,
        TableCellDetailsComponent,
        TableCellProcessInfoComponent,
        TableHeaderProcessComponent,
        TableCellTypeComponent,
        TableCellActiveComponent,
        TableCellEmailComponent,
        TableIpAndPortFilterComponent,
    ],
})
export class FeatureDataTableModule {}
