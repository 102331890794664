<div [ngClass]="{ 'show-warning': data.entityType === 'Services' }" class="modal solid" id="DownloadEntitiesModal">
    <div class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span>Download {{ this.data.entityType }}</span>
        </div>
        <div class="subtitle">Tap file type to download</div>
        <div class="sized">
            <div [ngClass]="{ 'hide-csv': data.downloadNetwork }" class="row buttons-row" style="margin-top: 12px">
                <div
                    (click)="downloadCSV()"
                    *ngIf="!data.downloadNetwork"
                    [ngClass]="{ 'button-disabled': asCreate }"
                    [title]="asCreate ? 'Re-create format not supported' : ''"
                    class="downloadButton download-csv"
                    id="Download_CSV_Button"
                >
                    <div class="csv-container">
                        <span class="button-label">CSV</span>
                        <div *ngIf="data.entityType === 'Services'">*</div>
                    </div>
                </div>
                <div (click)="downloadJSON()" class="downloadButton download-json" id="Download_JSON_Button">
                    <span class="button-label">JSON</span>
                </div>
                <div (click)="downloadYAML()" class="downloadButton download-yaml" id="Download_YAML_Button">
                    <span class="button-label">YAML</span>
                </div>
            </div>
            <div *ngIf="data.entityType === 'Services'" class="row csv-warning">
                *CSV includes only a limited number of top-level properties. Nested properties not included.
            </div>
            <div [ngClass]="{ 'show-warning': data.entityType === 'Services' }" class="toggle-container">
                <div (click)="toggleAsCreate()" [ngClass]="{ on: asCreate }" class="toggle mt4" id="AsCreateToggle">
                    <span [hidden]="!asCreate" style="margin-left: -15px">YES</span>
                    <span [hidden]="asCreate" style="margin-right: -15px">NO</span>
                    <div class="switch"></div>
                </div>
                <div class="label mt4">Download Re-Create Format</div>
            </div>
        </div>
    </div>
    <div (click)="hide()" class="buttonBall close" id="EscZEndpointClose"></div>
    <app-loader *ngIf="isLoading" [isLoading]="isLoading" id="DownloadEntitiesLoader"></app-loader>
</div>
