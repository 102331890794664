import { HateoasResource } from '@lagoshny/ngx-hateoas-client';
import { DialBind } from '../enums/dial-bind';
import { SemanticTypes } from '../enums/semantics';
import { BaseServicePolicy } from './base-service-policy';

@HateoasResource('service-policies')
export class ServicePolicy extends BaseServicePolicy {
    id: string;
    networkId: string;
    zitiId: string;
    name: string;
    type: string;
    semantic: SemanticTypes;
    serviceAttributes: string[];
    edgeRouterAttributes: string[];
    postureCheckAttributes: string[];
    ownerIdentityId: string;
    createdAt: string;
    updatedAt: string;

    getSemanticTypes() {
        const semanticTypes = Object.keys(SemanticTypes).map(function (type) {
            return type;
        });

        return semanticTypes;
    }

    getPolicyTypes() {
        const policyTypes = Object.keys(DialBind).map(function (policyType) {
            return policyType;
        });

        return policyTypes;
    }
}
