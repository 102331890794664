import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EdgeRouterServiceV2, EndpointServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { LoggerService, ValidateService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-ziti-share',
    templateUrl: './ziti-share.component.html',
    styleUrls: ['./ziti-share.component.scss'],
})
export class ZitiShareComponent {
    type = 'client';
    emails = '';
    endpoint = null;
    edgeRouter = null;

    template = 'EmailClient';

    replacementParams = {
        EMAIL: localStorage.getItem('profile_email'),
        USER: localStorage.getItem('profile_nick'),
        ENV: '',
    };

    errorEmails = false;

    constructor(
        private logger: LoggerService,
        public endpointServiceV2: EndpointServiceV2,
        public edgeRouterServiceV2: EdgeRouterServiceV2,
        private dialogRef: MatDialogRef<ZitiShareComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private validateService: ValidateService,
        private growlerService: GrowlerService
    ) {
        this.type = data.type;
        this.endpoint = data.endpoint;
        this.edgeRouter = data.edgeRouter;
    }

    doShare() {
        if (this.validate()) {
            const splitEmails = this.emails.split(';');
            const emailList = [];
            for (const email of splitEmails) {
                emailList.push(email.trim());
            }

            const payload = {
                toList: emailList,
                subject: 'NetFoundry - Registration Information',
                id: this.type === 'endpoint' ? this.endpoint['id'] : this.edgeRouter['id'],
            };

            if (this.type === 'endpoint') {
                this.endpointServiceV2.share(payload).subscribe(
                    () => {
                        this.close();
                    },
                    (error) => {
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Share registration Information request failed',
                                'Email is not sent'
                            )
                        );
                        this.logger.error('Share registration Information request failed, Email is not sent', error);
                    }
                );
            } else {
                this.edgeRouterServiceV2.share(payload).subscribe(
                    (res) => {
                        this.close();
                    },
                    (error) => {
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Share registration Information request failed',
                                'Email is not sent'
                            )
                        );
                        this.logger.error('Share registration Information request failed, Email is not sent', error);
                    }
                );
            }
        }
    }

    close() {
        this.emails = '';
        this.dialogRef.close();
    }

    validate() {
        this.errorEmails = false;

        if (!this.validateService.hasValue(this.emails)) {
            this.errorEmails = true;
        }

        if (!this.validateService.isValidEmailList(this.emails)) {
            this.errorEmails = true;
        }

        return !this.errorEmails;
    }
}
