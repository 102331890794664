<div *ngIf="view === 'select' || view === 'other'" class="modal solid tunnelers-select-container">
    <div class="title" style="filter: brightness(50%)">
        Enroll an {{ featureService?.disableNewNavigation ? 'Endpoint' : 'Identity' }}
    </div>
    <div class="subtitle">Choose the tunneler software for your OS</div>
    <div class="tunneler-cards-container">
        <app-tunneler-card
            (selected)="itemSelected($event)"
            *ngFor="let item of tunnelerItems"
            [item]="item"
        ></app-tunneler-card>
    </div>
    <div (click)="hide()" class="buttonBall close" id="EscEnrollmentSteps">
        <div class="buttonLabel">ESC</div>
    </div>
</div>
<div *ngIf="view === 'windows'" class="modal solid enrollment-instructions-container">
    <div (click)="prevStep()" class="buttonBall back" id="BackButton">
        <div class="buttonLabel">Back</div>
    </div>
    <div class="title" style="filter: brightness(50%)">Ziti Desktop Edge for Windows</div>
    <div class="tunneler-cards-container">
        <div *ngIf="step === 1" class="enrollment-step">
            <img class="enrollment-step-image" src="/assets/images/tunnelers/windows-step-1.jpg" />
            <span class="step-num">1 of 4</span>
            <p class="help-text">
                Select the "Show hidden icons" button from the bottom right side of the windows menu bar. Then click the
                Ziti Desktop Edge icon.
            </p>
        </div>
        <div *ngIf="step === 2" class="enrollment-step">
            <img class="enrollment-step-image" src="/assets/images/tunnelers/windows-step-2.jpg" />
            <span class="step-num">2 of 4</span>
            <p class="help-text">The Windows Desktop Edge will display and the user will need to click ADD IDENTITY.</p>
        </div>
        <div *ngIf="step === 3" class="enrollment-step">
            <img class="enrollment-step-image" src="/assets/images/tunnelers/windows-step-3.png" />
            <span class="step-num">3 of 4</span>
            <p class="help-text">
                You will be brought to your file manager and should navigate to the directory where you saved the .jwt
                file that was downloaded in the previous steps. <br />
                Click "Open"
            </p>
        </div>
        <div *ngIf="step === 4" class="enrollment-step">
            <img class="enrollment-step-image" src="/assets/images/tunnelers/windows-step-4.jpg" />
            <span class="step-num">4 of 4</span>
            <p class="help-text">
                Once the {{ featureService?.disableNewNavigation ? 'endpoint' : 'identity' }} has been enrolled and
                registered you will see any services that your
                {{ featureService?.disableNewNavigation ? 'endpoint' : 'identity' }} has access to.
            </p>
            <span class="done-instructions">When you're finished click "Done" to close this window</span>
        </div>
    </div>
    <div (click)="hide()" *ngIf="step === 4" class="button done-button">Done</div>

    <div (click)="hide()" class="buttonBall close" id="EscEnrollmentSteps">
        <div class="buttonLabel">ESC</div>
    </div>
    <div (click)="nextStep()" *ngIf="step !== 4" class="buttonBall forward" id="ForwardButton">
        <div class="buttonLabel">Forward</div>
    </div>
</div>

<div *ngIf="view === 'mac'" class="modal solid enrollment-instructions-container">
    <div (click)="prevStep()" class="buttonBall back" id="BackButton">
        <div class="buttonLabel">Back</div>
    </div>
    <div class="title" style="filter: brightness(50%)">Ziti Desktop Edge for MacOS</div>
    <div class="tunneler-cards-container">
        <div *ngIf="step === 1" class="enrollment-step">
            <img class="enrollment-step-image" src="/assets/images/tunnelers/mac-step-1.png" />
            <span class="step-num">1 of 5</span>
            <p class="help-text">After you've downloaded and installed the edge software, click on "open"</p>
        </div>
        <div *ngIf="step === 2" class="enrollment-step">
            <img class="enrollment-step-image" src="/assets/images/tunnelers/mac-step-2.png" />
            <span class="step-num">2 of 5</span>
            <p class="help-text">
                Then click on "Turn Ziti On" to open the
                {{ featureService?.disableNewNavigation ? 'endpoint' : 'identity' }} selection window.
            </p>
        </div>
        <div *ngIf="step === 3" class="enrollment-step">
            <img class="enrollment-step-image" src="/assets/images/tunnelers/mac-step-3.png" />
            <span class="step-num">3 of 5</span>
            <p class="help-text">Find and select the downloaded .jwt key and then click on "Open"</p>
        </div>
        <div *ngIf="step === 4" class="enrollment-step">
            <img class="enrollment-step-image" src="/assets/images/tunnelers/mac-step-4.png" />
            <span class="step-num">4 of 5</span>
            <p class="help-text">You'll be brought back to the MAC desktop edge where you will click on "Enroll"</p>
        </div>
        <div *ngIf="step === 5" class="enrollment-step">
            <img class="enrollment-step-image" src="/assets/images/tunnelers/mac-step-5.png" />
            <span class="step-num">5 of 5</span>
            <p class="help-text">The Enrollment Status will show "Enrolled" once it's completed.</p>
            <span class="done-instructions">When you're finished click "Done" to close this window</span>
        </div>
    </div>
    <div (click)="hide()" *ngIf="step === 5" class="button done-button">Done</div>
    <div (click)="hide()" class="buttonBall close" id="EscEnrollmentSteps">
        <div class="buttonLabel">ESC</div>
    </div>
    <div (click)="nextStep()" *ngIf="step !== 5" class="buttonBall forward" id="ForwardButton">
        <div class="buttonLabel">Forward</div>
    </div>
</div>
