<div
    [ngClass]="{ inline: isInline, readOnly: !canEdit && !isNew }"
    class="fullModal opened"
    id="ZitiAdvancedServiceConfigAddModal"
>
    <div [hidden]="isComplete" class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span *ngIf="!isEditing">Create A New Service</span>
            <span *ngIf="isEditing">Edit Your Service</span>
        </div>
        <div *ngIf="!isEditing" class="subtitle">Enter your service details</div>
        <div *ngIf="isEditing" class="subtitle">Edit your service details</div>
        <div
            class="subhelp"
            matTooltip="A service defines how connections are created for applications. Services are a fundamental building block of a NetFoundry overlay network."
            matTooltipPosition="below"
        >
            What's a Service?
            <div class="infoicon"></div>
        </div>
        <div class="sized full">
            <div class="grid two">
                <div>
                    <div class="label row">SERVICE DETAILS</div>
                    <div class="service-details-container">
                        <app-text-property-editor
                            (focusOut)="validateName()"
                            (modelChange)="nameChanged()"
                            [(model)]="model"
                            [disabled]="isEditing && !canEdit"
                            [errors]="errors['name']"
                            [label]="'Service Name'"
                            [maxLength]="'64'"
                            [minLength]="'5'"
                            [placeholder]="'Name this service'"
                            [propertyName]="'name'"
                            [required]="true"
                        ></app-text-property-editor>
                        <div class="label row">
                            Service Attributes
                            <div
                                class="infoicon"
                                matTooltip="Service Attributes are tags applied to a service. Apply the same tag to other services to form a group of services."
                                matTooltipPosition="below"
                            ></div>
                            <span class="note">Optional</span>
                        </div>
                        <div class="row">
                            <app-tags-selector
                                (addAttribute)="addServiceAttribute($event)"
                                (removeAttribute)="removeServiceAttribute($event)"
                                [availableAttributes]="serviceAttributes"
                                [disableField]="isEditing && !canEdit"
                                [hasError]="svcError"
                                [isLoading]="isLoadingSvcAttr"
                                [placeholder]="'Add attributes to group services'"
                                [selectedAttributes]="selectedServiceAttributes"
                                id="Service"
                            ></app-tags-selector>
                        </div>
                        <app-toggle-property-editor
                            [(model)]="model"
                            [description]="'ENCRYPT THIS SERVICE'"
                            [disabled]="disableEncryptionToggle"
                            [errors]="errors['encryptionRequired']"
                            [label]="'End-to-End Encryption'"
                            [ngClass]="{ disabled: disableEncryptionToggle }"
                            [propertyName]="'encryptionRequired'"
                            [required]="false"
                            [toolTip]="
                                !model.modelType
                                    ? 'Encryption must be enabled for advanced configurations'
                                    : isEditing
                                    ? 'Encryption can\'t be changed once service has been created'
                                    : 'Encrypts packets when entering or leaving the network.'
                            "
                            class="encryptionToggle"
                        ></app-toggle-property-editor>
                    </div>
                    <div class="label row">SERVICE BIND POLICY</div>
                    <div class="service-details-container">
                        <div class="label row">
                            SELECT HOSTS FOR THIS SERVICE
                            <div
                                class="infoicon"
                                matTooltip="Endpoint Attributes are tags applied to a service. Apply the same tag to other endpoints to form a group of services."
                                matTooltipPosition="below"
                            ></div>
                            <span class="note">Optional</span>
                        </div>
                        <div class="row">
                            <app-tags-selector
                                (addAttribute)="addEndpointsAttributes($event)"
                                (removeAttribute)="removeEndpointAttribute($event)"
                                [availableAttributes]="endpointAttributes"
                                [disableField]="isEditing && !canEdit"
                                [hasError]="endpointAttributeError"
                                [isLoading]="isLoadingEndpointAttr"
                                [placeholder]="'Choose the endpoints to associate to this Service'"
                                [selectedAttributes]="selectedEndpointAttributes"
                                id="Service"
                            ></app-tags-selector>
                        </div>
                    </div>
                    <div class="label row">Service Edge Router Policy</div>
                    <div class="service-details-container">
                        <div class="label row">
                            Edge Router Attributes
                            <div
                                class="infoicon"
                                matTooltip="Service Attributes are tags applied to a service. Apply the same tag to other services to form a group of services."
                                matTooltipPosition="below"
                            ></div>
                            <span class="note">Optional</span>
                        </div>
                        <div class="row">
                            <app-tags-selector
                                (addAttribute)="addEdgeRouterAttribute($event)"
                                (removeAttribute)="removeEdgeRouterAttribute($event)"
                                [availableAttributes]="edgeRouterAttributes"
                                [disableField]="isEditing && !canEdit"
                                [hasError]="edgeRouterError"
                                [isLoading]="isLoadingEdgeRouterAttr"
                                [placeholder]="'Default: #all, restricts routers available to this service'"
                                [selectedAttributes]="selectedEdgeRouterAttributes"
                                id="Router"
                            ></app-tags-selector>
                        </div>
                    </div>

                    <div *ngIf="isEditing" class="associated row">
                        <app-preview-list
                            (itemSelected)="endpointSelected($event)"
                            [allNames]="assocEndpointNames"
                            [clickable]="!isInline"
                            label="Associated Endpoints"
                        ></app-preview-list>
                        <app-preview-list
                            (itemSelected)="routerSelected($event)"
                            *ngIf="featureService.powerUserEnabled"
                            [allNames]="assocRouterNames"
                            [clickable]="!isInline"
                            label="Associated Edge Routers"
                        ></app-preview-list>
                    </div>
                </div>
                <div>
                    <div class="label row">
                        Service Configuration
                        <div
                            class="infoicon"
                            matTooltip="Protocol IP/Hostname and port of destination application. Note: Must be reachable from assigned endpoint/host."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">OPTIONAL</span>
                    </div>
                    <div *ngFor="let addedConfig of addedConfigs; let i = index" class="darkBox configBox">
                        <div class="row mini-pod">
                            <div class="label row" style="margin-bottom: 0px">SELECT CONFIGURATION</div>
                            <select
                                (change)="configTypeChanged(addedConfig)"
                                [(ngModel)]="addedConfig.selectedConfigTypeId"
                                [disabled]="isEditing && !addedConfig.isNew"
                                [ngClass]="{ error: configErrors.configType }"
                                class="service-config-select"
                                id="ServiceConfigTypes"
                            >
                                <option [ngValue]="undefined">Select a Config Type</option>
                                <option
                                    *ngFor="let configType of configTypes"
                                    [hidden]="configType.hidden && configType.id !== addedConfig.type.id"
                                    [value]="configType.id"
                                >
                                    {{ configType.name }}
                                </option>
                            </select>
                            <select
                                (change)="configChanged(addedConfig)"
                                [(ngModel)]="addedConfig.selectedConfigId"
                                [disabled]="!addedConfig.selectedConfigTypeId || (isEditing && !addedConfig.isNew)"
                                [ngClass]="{ error: configErrors.config }"
                                class="service-config-select"
                                id="ServiceConfigs"
                            >
                                <option [ngValue]="undefined">Select a Config Or Add New</option>
                                <option [value]="'new_config'">NEW CONFIG</option>
                                <option
                                    *ngFor="let displayConfig of addedConfig.displayConfigs"
                                    [value]="displayConfig.id"
                                >
                                    {{ displayConfig.name }}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="addedConfig.selectedConfigTypeId && addedConfig.selectedConfigId">
                            <div class="row mini-pod">
                                <div class="label row">CONFIG NAME</div>
                                <input
                                    (keyup.enter)="save()"
                                    [(ngModel)]="addedConfig.name"
                                    [disabled]="isEditing && !addedConfig.isNew"
                                    [ngClass]="{
                                        error:
                                            errors.configs[addedConfig.id] &&
                                            errors.configs[addedConfig.id]['nameInvalid']
                                    }"
                                    id="EditConfigName"
                                    maxlength="64"
                                    minlength="5"
                                    placeholder="Configuration Name"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div *ngIf="true">
                            <div class="viewgrid">
                                <div
                                    (click)="toggleJsonView()"
                                    *ngIf="featureService.experimentalFeatures"
                                    [ngClass]="{ on: isFormView }"
                                    class="jsonView"
                                >
                                    JSON VIEW
                                </div>
                                <div
                                    (click)="toggleJsonView()"
                                    *ngIf="featureService.experimentalFeatures"
                                    [ngClass]="{ on: isFormView }"
                                    class="toggle"
                                >
                                    <div *ngIf="featureService.experimentalFeatures" class="switch"></div>
                                </div>
                                <div
                                    (click)="toggleJsonView()"
                                    *ngIf="featureService.experimentalFeatures"
                                    class="formView"
                                >
                                    FORM VIEW
                                </div>
                            </div>
                            <div
                                *ngIf="!isFormView"
                                [ngClass]="{ error: addedConfig.invalid, readOnly: isEditing && !addedConfig.isNew }"
                                class="row"
                                style="border-radius: 7px"
                            >
                                <app-json-view
                                    #editor
                                    [(data)]="addedConfig.data"
                                    [(jsonInvalid)]="addedConfig.invalid"
                                    [readOnly]="!canEdit"
                                    ngDefaultControl
                                ></app-json-view>
                            </div>
                            <div *ngIf="isFormView && featureService.experimentalFeatures">
                                <app-tab-creator
                                    (selectTab)="selectActiveTab($event)"
                                    [activeTitle]="activeTitle"
                                    [titles]="titles"
                                ></app-tab-creator>
                                <app-host-v1-options
                                    *ngIf="activeTitle === 'Options'"
                                    [errors]="errors"
                                    [isEditing]="isEditing"
                                    [isNew]="isNew"
                                    [model]="addedConfig"
                                    [readOnly]="!canEdit"
                                ></app-host-v1-options>
                            </div>
                            <button (click)="removeServiceConfig(i)" class="remove">
                                <div class="icon-HamburgerAppClose"></div>
                            </button>
                        </div>
                    </div>
                    <div class="darkBox pb16">
                        <button (click)="addServiceConfig()" class="save another mb0">ADD SERVICE CONFIGURATION</button>
                    </div>
                </div>
            </div>

            <div *ngIf="!isEditing || canEdit" class="row buttonRow right" style="margin-top: 20px">
                <button (click)="save()" *ngIf="!processing && !isLoading" class="save" id="SaveServiceZitiButton">
                    {{ bulkEdit ? 'Done' : isEditing ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="isLoading || processing" class="save confirm" disabled>
                    {{ isLoading ? 'Loading' : 'Saving' }}
                    <span class="spinner"></span>
                </button>
            </div>
        </div>
        <div (click)="getMetrics()" *ngIf="isEditing" class="buttonBall metrics" id="MetricsButton">
            <div class="buttonLabel">METRICS</div>
        </div>
        <div (click)="getDialLogs()" *ngIf="isEditing" class="buttonBall network-failure" id="DialLogsButton">
            <div class="buttonLabel">Dial Logs</div>
        </div>

        <div (click)="hide()" class="buttonBall close" id="EscZitiServiceClose">
            <div class="buttonLabel">ESC</div>
        </div>
        <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    </div>
</div>
