import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableviewComponent } from '@netfoundry-ui/feature/tableview';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { AlarmMappingService, AlarmsService, ApiService, LoggerService } from '@netfoundry-ui/shared/services';
import { AlarmDatePipe, AlarmTranslatePipe } from '@netfoundry-ui/ui/pipes';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-alarms',
    templateUrl: './alarms.component.html',
    styleUrls: ['./alarms.component.scss'],
    providers: [AlarmTranslatePipe, AlarmDatePipe],
})
export class AlarmsComponent implements OnChanges, OnDestroy {
    @Input() endTime: number = Date.now();
    @Input() startTime: number;

    // network id is not needed, but as it is already accessable from the dashboard,
    //  allow it as an input so that the alarms can be updated with the rest of the dashboard on network change
    @Input() networkId: any;
    alarms = [];
    lastDate = '';
    isLoading = false;
    alarmCount = 0;
    refresher;
    refreshInterval = 30000;
    dialogRef;
    public canReadAlarms = true;
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private alarmsService: AlarmsService,
        public translatePipe: AlarmTranslatePipe,
        private alarmDatePipe: AlarmDatePipe,
        public dialogTable: MatDialog,
        public apiService: ApiService,
        private alarmsMappingService: AlarmMappingService,
        private authorizationService: AuthorizationService
    ) {}

    ngOnChanges() {
        this.canReadAlarms = this.authorizationService.canReadAlarms();
        if (this.canReadAlarms) {
            this.subscription.add(
                this.alarmsService.getCurrent().subscribe((alarms) => {
                    this.lastDate = '';
                    this.alarms = alarms.sort(
                        (a: any, b: any) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
                    );

                    // Put the pipe in here because we need the translated count
                    const translated = this.translatePipe.transform(alarms);

                    // filtering the alarms here so the count can be set correctly
                    const dateFilteredAlarms = this.alarmDatePipe.transform(translated, this.startTime, this.endTime);
                    this.alarms = this.vtcNameMapping(dateFilteredAlarms);
                    this.alarmCount = this.alarms.length;
                    this.isLoading = false;

                    this.refresh();
                })
            );
        } else {
            this.isLoading = false;
        }
    }

    getAlarms() {
        // setting isLoading to true
        this.isLoading = true;
        this.logger.info('get alarms');

        //  updating the alarms
        this.alarmsService.getCurrent();
    }

    refresh() {
        clearTimeout(this.refresher);
        this.refresher = setTimeout(() => {
            this.getAlarms();
        }, this.refreshInterval);
    }

    formatTime(theDate) {
        return moment.utc(theDate).local().format('M/D/YY h:mm a');
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        clearTimeout(this.refresher);
    }

    trackById(index, item) {
        return item.id;
    }

    viewAlarm(alarm) {
        const keys = ['severity', 'timestamp', 'code', 'description', 'vtc_id', 'endpoint_name'];

        this.dialogRef = this.dialogTable.open(TableviewComponent, {
            data: {
                item: alarm,
                keys: keys,
                title: 'Alarm Details',
                height: '500px',
                icon: 'images/Bell.png',
            },
            height: '580px',
            width: '650px',
        });
    }

    //  VTC id to endpoint name mapping in alarms panel

    vtcNameMapping(alarms) {
        const alarmsList = this.alarmsMappingService.vtcToMopNameMapping(alarms);

        if (alarmsList) {
            return alarmsList;
        }
    }
}
