import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { NetworkBulkUploadSaveComponent } from './network-bulk-upload-save.component';

@NgModule({
    imports: [CommonModule, SharedModelModule],
    declarations: [NetworkBulkUploadSaveComponent],
    exports: [NetworkBulkUploadSaveComponent],
})
export class FeatureNetworkBulkUploadSaveModule {}
