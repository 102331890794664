import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmComponent } from './confirm.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message'

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule, MessagesModule, MessageModule],
    declarations: [ConfirmComponent],
    exports: [ConfirmComponent],
})
export class UiConfirmModule {}
