import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment } from '@netfoundry-ui/shared/model';
import { result, toLower } from 'lodash';
import { LoggerService } from './logger.service';
import { HTTP_CLIENT } from './token.service';

@Injectable({ providedIn: 'root' })
export class CustomerService {
    apiUrl;
    appsUrl;
    networkPricing = 199.0;
    regionPricing = [{ region: null, price: 0.2, rate: 'GB' }];

    constructor(
        private logger: LoggerService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.apiUrl = this.environment.customerURL;
        this.appsUrl = this.environment.appsURL;
    }

    validateNewSignup(data) {
        const path = 'signups';
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        const fullpath = `${this.apiUrl}${path}`;
        const body = data;

        return this.http.post(fullpath, JSON.stringify(body), { headers: headers }).toPromise();
    }

    validateSignUp(data, props: string = '') {
        const path = 'signups';
        const fullPath = `${this.apiUrl}${path}`;
        let headers = this.setHeaders();
        headers = headers.set('nf-validate', props);
        const options = {
            headers: headers,
        };
        return this.http
            .post(fullPath, data, options)
            .toPromise()
            .then((result: any) => result)
            .catch((result): any => false);
    }

    createNewSignUp(data) {
        const path = 'signups';
        return this.post(path, data)
            .toPromise()
            .then((result: any) => {
                this.logger.log(result);
                return result;
            });
    }

    getSignUp(signupId) {
        const path = `signups/${signupId}`;
        return this.get(path)
            .toPromise()
            .then((result: any) => {
                this.logger.log(result);
                return result;
            });
    }

    selfResume() {
        const path = `customers/self/resume`;
        return this.put(path, {})
            .toPromise()
            .then((result: any) => {
                this.logger.log(result);
                return result;
            });
    }

    submitHubspotEntries(entries: any = []) {
        const fullpath = `${this.appsUrl}survey`;

        const body = {};
        entries.forEach((entry: any) => {
            body[entry.key] = entry.value;
        });
        return this.http.post(fullpath, JSON.stringify(body), { headers: this.setHeaders() });
    }

    requestSelfOffboard() {
        const path = '/customers/self/offboard';
        return this.post(path, {})
            .toPromise()
            .then((result: any) => {
                this.logger.log(result);
                return result;
            });
    }

    getCountriesList() {
        const path = 'countries';
        return this.get(path)
            .toPromise()
            .then((result: any) => {
                this.logger.log(result);
                return result;
            });
    }

    setNewSelfServiceUserFlags(tenantLabel, zipcode = '', country = '', plan = '') {
        localStorage.setItem(`nf_new_self_service_${tenantLabel}`, 'true');
        localStorage.setItem(`showGettingStarted_${tenantLabel}`, 'true');
        localStorage.setItem(`showSurvey_${tenantLabel}`, 'true');
        localStorage.setItem(`createNetwork_${tenantLabel}`, 'true');
        localStorage.setItem(`createFabric_${tenantLabel}`, 'true');
        localStorage.setItem(`nf_show_quick_start_hint_${tenantLabel}`, 'true');
        localStorage.setItem(`show_endpoints_info_${tenantLabel}`, 'true');
        localStorage.setItem(`show_services_info_${tenantLabel}`, 'true');
        localStorage.setItem(`show_edge-routers_info_${tenantLabel}`, 'true');
        localStorage.setItem(`show_appwans_info_${tenantLabel}`, 'true');
        localStorage.setItem(`show_posture-checks_info_${tenantLabel}`, 'true');
        localStorage.setItem(`show_edge-router-policies_info_${tenantLabel}`, 'true');
        localStorage.setItem(`show_certificates_info_${tenantLabel}`, 'true');
        localStorage.setItem(`fabricZipcode_${tenantLabel}`, zipcode);
        localStorage.setItem(`fabricCountry_${tenantLabel}`, country);
        localStorage.removeItem(`newInvitedUser_${tenantLabel}`);
        if (toLower(plan) === 'teams') {
            localStorage.setItem(`isSelfServiceTeams_${tenantLabel}`, 'true');
        }
    }

    /**
     * @param path
     * @param data
     */
    public post(path: string, data) {
        const fullpath = `${this.apiUrl}${path}`;
        const body = data;

        return this.http.post(fullpath, JSON.stringify(body), { headers: this.setHeaders() });
    }

    /**
     * @param path
     * @param data
     */
    public put(path: string, data) {
        const fullpath = `${this.apiUrl}${path}`;
        const body = data;

        return this.http.put(fullpath, JSON.stringify(body), { headers: this.setHeaders() });
    }

    /**
     * Generic GETter
     */

    public get(path: string) {
        const fullpath = `${this.apiUrl}${path}`;

        return this.http.get(fullpath, { headers: this.setHeaders() });
    }

    /**
     * Return headers
     */
    private setHeaders(): HttpHeaders {
        const headers = new HttpHeaders();

        return headers.append('Content-Type', 'application/json');
    }
}
