<div class="modal solid" id="SurveyModal">
    <div (click)="moveBack()" *ngIf="stepNum > 0" class="back" id="SignupBackButton"></div>
    <div (click)="hide()" class="buttonBall close" id="EscZEndpointClose"></div>
    <div class="fullRow">
        <div class="title">
            <span>{{ step.title }}</span>
        </div>
        <div class="subtitle detail">
            {{ step.subTitleDetail }}
        </div>
        <div class="subtitle stepNumber">{{ step.subTitleStep }}</div>
        <div class="row selections">
            <div
                (click)="select(selection)"
                *ngFor="let selection of selections"
                [ngClass]="{ selected: selection.selected }"
                class="selection"
            >
                <div class="selection-letter">{{ selection.letter }}</div>
                <div class="selection-text">{{ selection.text }}</div>
                <input
                    #otherInput
                    (input)="otherInputChanged(otherInput.value)"
                    *ngIf="selection.showInput"
                    [(ngModel)]="otherText"
                    class="survey-other-input"
                    tabindex="1"
                />
            </div>
        </div>
        <button
            (click)="next()"
            [disabled]="disableNext"
            [ngClass]="{ disabled: disableNext }"
            class="save next"
            id="NextButton"
        >
            {{ showSubmit ? 'Submit' : 'Next' }}
        </button>
    </div>
</div>
