import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { NetworkV2 } from '@netfoundry-ui/shared/model';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
    selector: 'app-network-restore-modal',
    templateUrl: './network-restore-modal.component.html',
    styleUrls: ['./network-restore-modal.component.scss'],
})
export class NetworkRestoreModalComponent {
    model: NetworkV2;
    networkControllerId;
    networkId;
    backups = {};
    backupName;
    backupFullName;
    backupTimestamp;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<NetworkRestoreModalComponent>,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        public growlerService: GrowlerService,
        private router: Router
    ) {
        this.model = data;
        this.networkId = data.model.id;
        this.networkControllerId = data.model.networkController.id;
    }

    ngOnInit() {
        this.networkServiceV2.listBackups(this.networkControllerId).subscribe((data) => {
            this.backups = data.content.map((backup: any) => ({
                backupTimestamp: moment(backup.lastModified).format('MMMM Do YYYY, h:mm:ss a'),
                backupShortName: backup.name.substring(backup.name.lastIndexOf('/') + 1),
                backupFullName: backup.name,
            }));
        });
        this.backupFullName = null;
    }

    close() {
        this.dialogRef.close();
    }

    restoreBackup() {
        this.networkServiceV2.restoreBackup(this.networkControllerId, this.backupFullName).subscribe(
            (result) => {
                this.growlerService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Restore Successful',
                        'Network Restore was started successfully'
                    )
                );
                this.dialogRef.close();
                return result;
            },
            (httpErrorResponse: { error: { errors: (string | undefined)[] } }) => {
                this.growlerService.show(
                    new GrowlerData('error', 'Error restoring network', httpErrorResponse.error.errors[0])
                );
            }
        );
    }
}
