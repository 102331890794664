<div [ngClass]="{ inline: isInline }" class="fullModal opened addForm" id="AddClientModal">
    <app-loader [isLoading]="isLoading"></app-loader>

    <div [hidden]="step !== 0" class="fullRow" id="ClientUpload">
        <div [ngClass]="{ isAt: step === 0 }" class="stepItem">
            <div class="title" style="filter: brightness(50%)">Import your Client As Code</div>
            <div class="subtitle">Select Your File To Upload</div>
            <div class="sized">
                <!-- Azure BULK Add -->
                <div class="subtext row">
                    Creating Clients using only code is easy. Just download our template below, update the code, and
                    upload your file. We’ll create the Clients and provide you with a summary and list of next steps.
                </div>
                <div class="label row">Download A Template</div>
                <div (click)="download()" class="graphicButtonCSV">
                    <div>Download An<br />Example File</div>
                    <div class="iconCSV"></div>
                </div>

                <div class="label row">Select And Upload A File</div>
                <div (onFileDrop)="fileDrop()" [uploader]="uploader" class="uploadArea" ng2FileDrop>
                    <div [hidden]="fileIsReady" class="label">Drag and drop a CSV file</div>
                    <div [hidden]="fileIsReady" class="sublabel">
                        or
                        <div (click)="showDialog()" class="linkButton" id="ClientFileUpload">
                            Select a file from your computer
                        </div>
                        <form [formGroup]="form">
                            <input
                                #fileInput
                                (change)="onFileChange($event)"
                                id="FileSelect"
                                name="file"
                                style="display: none"
                                type="file"
                            />
                        </form>
                    </div>
                    <div [hidden]="!fileIsReady" class="label">Click create to import CSV</div>
                </div>
                <div class="row buttonRow right" style="margin-top: 20px">
                    <div (click)="toggleSendEmails()" class="checkbox" id="toggleSendEmail">
                        <div [ngClass]="{ selected: sendEmails }" class="tSelect" id="toggleSendEmailButton"></div>
                        <div class="checkLabel">Send Emails After Creation</div>
                    </div>
                    <button (click)="save()" *ngIf="!processing" class="save" id="SaveClientButton">Create</button>
                    <button *ngIf="processing" class="save confirm" disabled>
                        Saving <span class="spinner"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div [hidden]="step !== 1" class="fullRow" id="ClientBuildingStatus">
        <div [ngClass]="{ isAt: step === 1 }" class="sized nextStep">
            <app-endpoints-bulk-upload-save
                #saveClientsAsCode
                [businessKey]="businessKey"
                [endpoints]="clients"
                [networkId]="networkId"
            >
            </app-endpoints-bulk-upload-save>
        </div>
    </div>
    <div (click)="hideForm()" class="buttonBall close" id="CloseClientButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div (click)="goBack()" [hidden]="processing" class="buttonBall back" id="BackClientButton">
        <div class="buttonLabel">BACK</div>
    </div>
</div>
