<div
    (click)="open()"
    class="cardStack openable"
    id="DownloadsCard"
    style="background-image: url(../assets/svgs/Icon_Small_Installers.svg); background-size: 27%"
>
    <div class="card2"></div>
    <div class="card1"></div>
    <div class="title flat">Download Installers</div>
</div>
