import { Endpoint } from './endpoint';
import { MopResource } from './mopresource';

export class GatewayCluster extends MopResource {
    public endpoints: Endpoint[];
    public geoRegionId: string;
    public dataCenterId: string;
    public protectionGroupId: string;
    public protectionType: string;
    public endpointType: string;
    public endpointId: string;

    // Map of codes with labels
    public gatewayTypes = {
        VCPEGW: 'V-CPE Gateway',
        AWSCPEGW: 'AWS Private Gateway',
        GW: 'Public Internet Gateway',
        AVWGW: 'Azure Site',
        AZCPEGW: 'Azure Private Gateway',
        AZSGW: 'Azure Stack Gateway',
        ZTGW: 'Hosted Ziti Bridge Gateway',
        ZTNHGW: 'Private Ziti Bridge Gateway',
        GCPCPEGW: 'Google Cloud Platform Gateway',
        ALICPEGW: 'AliBaba Private Gateway',
        OCPCPEGW: 'Oracle Cloud Gateway',
        L2VCPEGW: 'Voice Enhanced Gateway',
    };

    public getTypeName() {
        return this.protectionType === 'AwsAutoScale'
            ? 'AWS Auto Scaling Gateway'
            : this.gatewayTypes[this.endpointType]
            ? this.gatewayTypes[this.endpointType]
            : 'V-CPE Gateway';
    }

    public getSaveObject() {
        return {
            name: this.name,
            geoRegionId: this.geoRegionId,
            dataCenterId: this.dataCenterId,
            protectionGroupId: this.protectionGroupId,
            protectionType: this.protectionType,
            endpointType: this.endpointType,
        };
    }

    public getProtectionTypes() {
        return [
            { label: 'Active/Passive', value: '1:1' },
            { label: 'Round Robin', value: 'round-robin' },
        ];
    }

    public getUpgradeObject() {
        return {
            name: this.name,
            protectionGroupId: this.protectionGroupId,
            protectionType: this.protectionType,
            endpointId: this.endpointId,
        };
    }

    public getCloudStyle() {
        if (this.endpointType === 'AWSCPEGW') {
            return 'AWS';
        } else if (this.endpointType === 'AVWGW' || this.endpointType === 'AZCPEGW' || this.endpointType === 'AZSGW') {
            return 'Microsoft';
        } else {
            return '--';
        }
    }

    public getCloud() {
        if (this.endpointType === 'AWSCPEGW') {
            return '';
        } else {
            return '--';
        }
    }
}
