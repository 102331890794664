/**
 * Template class for all MOP resources with built in helper methods
 */
export class Exporters {
    public maxExportsPerExporter = 0;
    public type = '';
    public key = '';
    public exportCount = 0;

    constructor(object) {
        const keys = Object.keys(object);

        // TODO - Figure out how to do inflection so only public properties are populated
        for (const key of keys) {
            this[key] = object[key];
        }

        return this;
    }
}
