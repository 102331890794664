import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { Subscription } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { NetworkUtilizationTemplateQueryParameter } from '@netfoundry-ui/shared/model';

@Injectable({
    providedIn: 'root',
})
export class ResourceUtilization {
    public routersUsageData = [];
    public endpointsUsageData = [];
    public servicesUsageData = [];
    usageTotal = 0;

    endTime = Date.now();
    startTime = this.endTime - 24 * 60 * 60 * 1000;
    dateFilter: any = '30d'; // input
    dataReceived = new EventEmitter<any>();
    noData = true;
    items = [];
    aggregateField = '';
    private subscription = new Subscription();

    constructor(private elasticsearch: ElasticsearchService) {}

    public getTotalUsage() {
        return this.convertBytes(this.usageTotal);
    }

    public utlizationInfo_Backup(resource, networkId, networkGroupId, filterField, filterValue) {
        // not used
        const index = 'ncutilization';
        const items = [];
        //aggregateField = '';
        this.noData = true;

        if (resource === 'router') {
            this.aggregateField = 'nf_edge_router_name';
        } else if (resource === 'endpoint') {
            this.aggregateField = 'nf_endpoint_name';
        } else if (resource === 'service') {
            this.aggregateField = 'nf_service_name';
        }
        this.subscription.add(
            this.elasticsearch
                .search(
                    networkGroupId,
                    index,
                    this.getElasicQuery(this.aggregateField, filterField, filterValue, networkId, networkGroupId)
                )
                .subscribe(
                    (data) => {
                        if (data['aggregations']['unique_items']['buckets'].length === 0) {
                            this.noData = true;
                            this.items = items;
                            return null;
                        } else {
                            if (resource === 'router') {
                                this.usageTotal = 0;
                            }
                            for (const bucket of data['aggregations']['unique_items']['buckets']) {
                                const count = bucket['item_sum']['value'];
                                const key = bucket['key'];
                                if (resource === 'router') {
                                    this.usageTotal += count;
                                }
                                items.push({
                                    name: key,
                                    usage: this.convertBytes(count),
                                });
                            }
                            if (resource === 'router') {
                                this.routersUsageData = items;
                                this.dataReceived.emit(this.routersUsageData);
                            } else if (resource === 'endpoint') {
                                this.endpointsUsageData = items;
                                this.dataReceived.emit(this.endpointsUsageData);
                            } else if (resource === 'service') {
                                this.servicesUsageData = items;
                                this.dataReceived.emit(this.servicesUsageData);
                            }

                            if (data['aggregations']['unique_items']['buckets'].length > 0) {
                                this.noData = false;
                            }
                            return this.items;
                        }
                    },
                    (error) => {
                        console.log(error);
                        return null;
                    }
                )
        );
    }
    public utlizationInfo(resource, networkId, networkGroupId, filterField, filterValue): any {
        const index = 'ncutilization';
        const items = [];
        //aggregateField = '';
        this.noData = true;

        if (resource === 'router') {
            this.aggregateField = 'nf_edge_router_name';
        } else if (resource === 'endpoint') {
            this.aggregateField = 'nf_endpoint_name';
        } else if (resource === 'service') {
            this.aggregateField = 'nf_service_name';
        }

        //reporting
        // this will allow us to filter by endpoint, edge router, or service
        let componentName: any = '';
        let componentValue: any = '';

        if (filterField != null && filterValue != null) {
            componentName = filterField;
            componentValue = filterValue;
        }
        const networkUtilizationTemplateQueryParameter: NetworkUtilizationTemplateQueryParameter = {
            gte: 'now-' + this.dateFilter,
            lte: 'now',
            size: '0',
            aggTermSize: '3500',
            aggComponentName: this.aggregateField + '.keyword',
            componentName: componentName,
            componentValue: componentValue,
            networkId: networkId,
            networkGroupId: networkGroupId,
            indexName: index,
        };

        const utlPromise =
            //this.elasticsearch.search(networkGroupId,index,this.getElasicQuery(this.aggregateField, filterField, filterValue, networkId, networkGroupId))
            this.elasticsearch
                .apiTemplateSearch('ncutilization_resource_tmpl', networkUtilizationTemplateQueryParameter)
                .pipe(
                    tap(
                        (data) => {
                            if (!data['aggregations']) {
                                // && data['aggregations']['unique_items']['buckets'].length === 0) {
                                this.noData = true;
                                this.items = items;
                                // return null;
                            } else {
                                if (resource === 'router') {
                                    this.usageTotal = 0;
                                }
                                for (const bucket of data['aggregations']['unique_items']['buckets']) {
                                    const count = bucket['item_sum']['value'];
                                    const key = bucket['key'];
                                    if (resource === 'router') {
                                        this.usageTotal += count;
                                    }
                                    items.push({
                                        name: key,
                                        usage: this.convertBytes(count),
                                    });
                                }
                                if (resource === 'router') {
                                    this.routersUsageData = items;
                                    this.dataReceived.emit(this.routersUsageData);
                                } else if (resource === 'endpoint') {
                                    this.endpointsUsageData = items;
                                    this.dataReceived.emit(this.endpointsUsageData);
                                } else if (resource === 'service') {
                                    this.servicesUsageData = items;
                                    this.dataReceived.emit(this.servicesUsageData);
                                }
                                if (data['aggregations']['unique_items']['buckets'].length > 0) {
                                    this.noData = false;
                                }
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    ) // tap close
                ) // pipe close
                .toPromise();
        return utlPromise;
    }

    getElasicQuery(aggregateField, filterField, filterValue, networkId, networkGroupId) {
        const model: any = {
            aggs: {
                unique_items: {
                    terms: {
                        field: aggregateField + '.keyword',
                        size: 3500,
                        order: {
                            item_sum: 'desc',
                        },
                    },
                    aggs: {
                        item_sum: {
                            sum: {
                                field: 'usage',
                            },
                        },
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            range: {
                                timestamp: {
                                    gte: 'now-' + this.dateFilter,
                                    lte: 'now',
                                    format: 'epoch_millis',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                'network_id.keyword': {
                                    query: networkId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                'organizationId.keyword': {
                                    query: networkGroupId,
                                },
                            },
                        },
                        {
                            // filter down to two usage types since these are the items that we will calculate billing on
                            bool: {
                                should: [
                                    {
                                        match_phrase: {
                                            // traffic delivered through the fabric to service's destination, renders as TX
                                            'usage_type.keyword': 'usage.ingress.tx',
                                        },
                                    },
                                    {
                                        match_phrase: {
                                            // traffic received back through the fabric to the initiator, renders as RX
                                            'usage_type.keyword': 'usage.egress.tx',
                                        },
                                    },
                                ],
                                minimum_should_match: 1,
                            },
                        },
                    ],
                },
            },
        };

        // this will allow us to filter by endpoint, edge router, or service
        if (filterField != null && filterValue != null) {
            const search = { match_phrase: {} };
            search.match_phrase[filterField] = { query: filterValue };
            model.query.bool.must.push(search);
        }

        return model;
    }

    convertBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
