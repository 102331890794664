export class User {
    public id: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public initials: string;
    public organization: string;
    public accepted: boolean;
    public selected: boolean;
}
