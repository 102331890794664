import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HTTP_CLIENT, LoggerService, TokenService } from '@netfoundry-ui/shared/services';
import { Observable, of as observableOf, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';

export const IAM_CONFIGURATION = new InjectionToken<IamConfig>('IAM_CONFIGURATION');

export interface IamConfig {
    url: string;
    invitationUrl: string;
    intermediateReturnUrl: string;
    loginReturnUrl: string;
}

export interface AuthProvider {
    auth0ConnectionId: string;
    identityProviderName: string;
}

export interface Invitation {
    invitationUrl: string;
    invitedEmailAddress: string;
    toTenantId: string;
    targetIdentityId: string;
}

export interface IdentityMappingAuth0 {
    auth0UserId: string;
    identityProviderId: string;
}

export interface ProviderMappingAuth0 {
    auth0ConnectionId: string;
    identityProviderName: string;
    auth0ConnectionType: string;
}

@Injectable({ providedIn: 'root' })
export class IamService {
    public findTypes = [
        'identity-permission',
        'user-identities',
        'invitations',
        'tenants',
        'operations',
        'api-account-identities',
    ];

    public deleteTypes = ['identity-permission'];

    public getByTypes = ['user-identities', 'invitations'];

    public activateTypes = ['user-identities', 'tenants'];

    lastErrorSource = new Subject<HttpErrorResponse>();
    lastError = this.lastErrorSource.asObservable();

    private apiUrl;
    private invitationUrl;
    private intermediateReturnUrl;
    private loginReturnUrl;

    constructor(
        private tokenService: TokenService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        public logger: LoggerService,
        @Inject(IAM_CONFIGURATION) config?: IamConfig
    ) {
        this.apiUrl = config.url;
        this.invitationUrl = config.invitationUrl;
        this.intermediateReturnUrl = config.intermediateReturnUrl;
        this.loginReturnUrl = config.loginReturnUrl;
    }

    /**
     * This endpoint does not require auth
     */
    public getOrgByShortName(name) {
        const fullpath = `${this.apiUrl}tenants/label/${name}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Get the nfToken back for the org
     */
    public authorizeInitiate(tenantLabel: string) {
        const fullpath = `${this.apiUrl}tenants/authorize-initiate`;
        const body = { tenantLabel: tenantLabel, intermediateReturnUrl: this.loginReturnUrl };

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();
        // headers = headers.set('Content-Type', 'application/json') ;
        // this.logger.info('HEADERS', headers);

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Get the nfToken back for the org
     */
    public authorize(authToken: string) {
        const fullpath = `${this.apiUrl}tenants/authorize`;
        const body = { authToken: authToken };

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http.post(fullpath, JSON.stringify(body), { headers: headers });
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Get authorizations for the current user silently. Won't display error growler if unable to get identity info
     */
    public getSilentAuthorization() {
        const fullpath = `${this.apiUrl}identities/self`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http.get(fullpath, { headers: headers }).pipe(
                catchError((error) => {
                    this.logger.error('Unable to get authorizations for user');
                    return observableOf([]);
                })
            );
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Search by input params
     * Allowed Types:
     *    identity-permission
     *    user-identities
     *    invitations
     *    tenants
     *    operations
     */
    public find(type: string, params: any) {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            if (params['size'] == null) {
                params['size'] = 5000;
            }

            const fullpath = `${this.apiUrl}${type}`;
            const options = {
                headers: headers,
                params: params,
            };

            return this.http.get(fullpath, options).pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Get item by ID
     * Allowed Types:
     *    identity-permission
     *    user-identities
     *    invitations
     *    tenants
     *    operations
     */
    public get(type: string, id: string) {
        const fullpath = `${this.apiUrl}${type}/${id}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Create a thing
     * Allowed Types
     *  identity-permission
     *  user-identities
     *  invitations
     *  tenants
     *  api-account-identities
     */
    public create(type: string, body: any) {
        const fullpath = `${this.apiUrl}${type}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Delete a thing (Currently only an identity-permission)
     * Allowed Types:
     *  identity-permission
     */
    public delete(type: string, id: string): Observable<any> {
        const fullpath = `${this.apiUrl}${type}/${id}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .delete(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Update a thing (Currently only needed for an api-account-identity)
     * Allowed Types:
     *  api-account-identity
     */
    public update(type: string, id: string, body: any) {
        const fullpath = `${this.apiUrl}${type}/${id}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(fullpath, body, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Get item by key
     * Allowed Types:
     *      user-identities
     *      invitations
     */
    public getBy(type: string, key: string, value: string) {
        const fullpath = `${this.apiUrl}${type}/${key}/${value}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Activate identity or tenant
     * Allowed Types:
     *      user-identities
     *      tenants
     *      api-account-identities
     */
    public activate(type: string, id: string) {
        const fullpath = `${this.apiUrl}${type}/${id}/activate`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(fullpath, {}, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    public resetMfa(id: string) {
        const fullpath = `${this.apiUrl}user-identities/${id}/reset-mfa`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(fullpath, {}, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Deactivate identity or tenant
     *    Allowed Types:
     *      user-identities
     *      tenants
     *      api-account-identities
     */
    public deactivate(type: string, id: string) {
        const fullpath = `${this.apiUrl}${type}/${id}/deactivate`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(fullpath, {}, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Invite a user to a tenant
     */
    public sendInvite(email: string, tenantId: string) {
        const payload = {
            invitationUrl: this.invitationUrl,
            invitedEmailAddress: email,
            toTenantId: tenantId,
        };
        return this.create('invitations', payload);
    }

    /**
     * Map identity to provider
     */
    public mapIdentity(id: string, map: IdentityMappingAuth0) {
        const fullpath = `${this.apiUrl}user-identities/${id}/mapping`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(map), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Map identity to provider (ALIAS for mapIdentity)
     */
    public assign(id: string, map: IdentityMappingAuth0) {
        this.mapIdentity(id, map);
    }

    /**
     * Accept invite by Key - Initiates the invite
     */
    public acceptInvite(key: string) {
        const payload = { intermediateReturnUrl: this.intermediateReturnUrl };
        const fullpath = `${this.apiUrl}invitations/key/${key}/accept-initiate`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(payload), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Accept invite by Key - Initiates the invite
     */
    public confirmInvite(key: string, acceptToken: string) {
        const payload = { acceptToken: acceptToken };
        const fullpath = `${this.apiUrl}invitations/key/${key}/accept`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(payload), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Revoke invite by ID
     */
    public revokeInvite(id: string) {
        const fullpath = `${this.apiUrl}invitations/${id}/revoke`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(fullpath, {}, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Decline invite by ID
     */
    public declineInvite(key: string) {
        const fullpath = `${this.apiUrl}invitations/key/${key}/decline`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(fullpath, {}, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Map Tenant to Identity Provider
     */
    public addProviderToTenant(tenantId: string, map: ProviderMappingAuth0) {
        const fullpath = `${this.apiUrl}tenants/${tenantId}/identity-providers`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(map), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Register a new tenant and user
     */
    public registerTenant(body: any) {
        const fullpath = `${this.apiUrl}tenants/register-initiate`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Complete the registration process
     */
    public confirmRegistration(registrationToken) {
        const body = { registrationToken: registrationToken };
        const fullpath = `${this.apiUrl}tenants/register`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Checks for an existing mapping entry
     */
    public getNetworkGroupByOrganizationLabel(organizationLabel: string) {
        const fullpath = `${this.apiUrl}organizations/mapping/${organizationLabel}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Checks for an existing mapping entry
     */
    public getIdentityByMapping(identityKey: string, identityProviderId: string) {
        const fullpath = `${this.apiUrl}user-identities/mapping/${identityKey}/${identityProviderId}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Checks for an existing mapping entry
     */
    public getProviders() {
        const fullpath = `${this.apiUrl}identity-provider-types`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Sends an email to the provided address with the sitenames associated with the email
     */
    public sendForgottenSitenames(email: string) {
        const body = { email: email };
        const fullpath = `${this.apiUrl}tenants/forgotten-site`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Invalidate's the user's token
     */
    public logOut() {
        const fullpath = `${this.apiUrl}logout`;
        const body = {};

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * function for generating a new secret for API Account Identities
     */
    public rotateApiAccountPassword(id: string) {
        const fullpath = `${this.apiUrl}api-account-identities/${id}/rotate-password`;
        const body = {};

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * function for getting the nftoken to use when refreshing the user's token
     */
    public refreshToken() {
        const fullpath = `${this.apiUrl}tenants/authorize-refresh`;
        const body = {};

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    public setMfaProvider(tenantId, mfaProvider) {
        const fullpath = `${this.apiUrl}tenants/${tenantId}`;
        const body = { mfaProvider: mfaProvider };

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    public submitSupportTicket(body) {
        const fullpath = `${this.apiUrl}nfconsole/support/requests`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: any, error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            scope.logger.error('IAM Service error occurred:', error.error);
            // this.logger.error('IAM Service error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            scope.logger.error(`IAM Backend returned code ${error.status}`, error.error);
            // this.logger.error(`IAM Backend returned code ${error.status}`, error.error);
        }
        scope.lastErrorSource.next(error);
        return observableThrowError(error);
    }

    /**
     * Return headers
     */
    private setHeaders(): HttpHeaders {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (tokenResponse.expired) {
            // if the token is expired, return null
            headers = null;
        } else if (tokenResponse.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        if (headers != null) {
            return headers.append('Content-Type', 'application/json');
        } else {
            return headers;
        }
    }
}
