import { InjectionToken } from '@angular/core';
import { AuthenticationServiceType } from './authentication-service.type';

export const LOGIN_SERVICE = new InjectionToken<LoginServiceType>('LOGIN_SERVICE');

export interface LoginServiceType extends AuthenticationServiceType {
    forgotSiteName;
    emailAddress;
    isResuming;
    refreshSub;
    isRootDomain;
    tenantLabel;
    errorSiteName;
    vanityLogin;
    isAwsLogin;
    errorEmailAddress;
    isVanityUrl;

    logout(navigateToNotFound, skipTokenRevoke?: boolean, params?: any): void;

    _redirectToRootDomainForLogin(hostname);

    _catchAuthResult();

    clearStorage();

    _setSession(authResult): void;

    initiateSiteNameRequest();

    renewToken();

    scheduleRenewal();

    unscheduleRenewal();

    handleVanityUrl();

    rebuildLock();

    updateCallbackUrl(siteName);

    getCallbackUrl(siteName);

    goToVanitySite(authResult);

    continue(state);

    acceptInvite(nfToken, connection, prefill);

    gotoSignUp();

    // Updated Auth Flow
    newLogin(nfToken, returnUrl, connections);

    resumeLogin(authToken, state);

    handleAuthenticationWithHash(): void;

    getIdentityProviders();

    _catchVanityUrlAuth(sitename);
}
