import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule } from '@angular/forms';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CalendarModule } from 'primeng/calendar';
import { SideFilterComponent } from './side-filter.component';

@NgModule({
    imports: [CommonModule, FormsModule, ClickOutsideModule, ToggleButtonModule, CalendarModule],
    declarations: [SideFilterComponent],
    exports: [SideFilterComponent],
})
export class FeatureSideFilterModule {}
