import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService, AuthService, IamService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Endpoint, Environment, ENVIRONMENT, ShareData } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    ClientService,
    FeatureService,
    LoggerService,
    NetworkVersionService,
    RegionService,
    ValidateService,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { ShareService } from '@netfoundry-ui/shared/share';
import { RegionTransformPipe, SortbyPipe } from '@netfoundry-ui/ui/pipes';
import { saveAs } from 'file-saver';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-clientform',
    templateUrl: './clientform.component.html',
    styleUrls: ['./clientform.component.scss'],
})
export class ClientformComponent implements OnInit, OnDestroy {
    @Output() back: EventEmitter<boolean> = new EventEmitter();
    @Output() hide: EventEmitter<any> = new EventEmitter();
    @Output() newClient: EventEmitter<Endpoint> = new EventEmitter();

    @Input() model: Endpoint = new Endpoint({});
    regions: Location[] = [];
    isComplete = false;
    errorName = false;
    errorNameLength = false;
    errorType = false;
    errorRegion = false;
    errorCountry = false;
    clients = [];
    regKey = '';
    editId = '';
    attempt = 0;

    isBuilding = true;
    provisionedString = 'has been created';
    buildingString = 'is building';
    completedTitleStatusString = this.buildingString;
    isInline = false;
    processing = false;
    hideHelp = false;
    zitiRegKey;
    zitiRegKeyBlob;
    users = [];
    authUserIdentity = '';
    errorAuthUser = false;
    public newUserString = 'NewUser';
    addingNewUser = false;
    newUserEmail;
    errorEmail = false;
    userSelected = [];
    canCreateUsers = false;
    timeout = 250;
    requireAuthSession = false;
    canListUsers = false;
    // boolean to determine if the window is attempting to hide
    private isHiding = false;
    private regionServiceSub: Subscription;
    private clientServiceGetSub: Subscription = new Subscription();
    private clientServiceSaveSub: Subscription;
    private updatedClientSub = new Subscription();
    // subject used to determine whether or not the registration key was obtained
    private updatedRegKeySource = new Subject<boolean>();
    private updatedRegKey = this.updatedRegKeySource.asObservable();
    private subscription: Subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private clientService: ClientService,
        private regionService: RegionService,
        private shareService: ShareService,
        private growlerService: GrowlerService,
        private validateService: ValidateService,
        private dialogRef: MatDialogRef<ClientformComponent>,
        public zitiEnabledService: ZitiEnabledService,
        public authorizationService: AuthorizationService,
        private networkVersionService: NetworkVersionService,
        private regionTransform: RegionTransformPipe,
        private apiService: ApiService,
        private iamService: IamService,
        public featureService: FeatureService,
        private authService: AuthService,
        private sortByPipe: SortbyPipe,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        if (data.inline) {
            this.isInline = data.inline;
        }
    }

    async ngOnInit() {
        this.errorName = false;
        this.errorType = false;
        this.errorRegion = false;
        this.errorCountry = false;
        this.processing = false;

        if (this.zitiEnabledService.zitiEnabled) {
            this.model.endpointType = '';
        } else {
            this.model.endpointType = 'CL';
        }
        this.regionServiceSub = this.regionService.get().subscribe((result) => {
            this.regions = this.regionTransform.transform(result as Location[], false, false, false, true);
        });

        // subscribe to changes to updatedRegKey
        this.updatedClientSub.add(
            this.updatedRegKey.subscribe((isUpdated) => {
                // if the reset function was not called yet
                if (!this.isHiding) {
                    // if the registration key was not obtained and there were less than 10 attempts
                    if (!isUpdated) {
                        this.attempt++;

                        // setting a 250ms timeout before calling loadClient again
                        setTimeout(() => {
                            this.loadClient();
                        }, this.timeout);
                    }

                    // otherwise, if the reset function was called
                } else {
                    // setting isHiding to false so that the subscription can be reused if the user creates another gateway
                    this.isHiding = false;
                }
            })
        );
        this.canCreateUsers = this.authorizationService.canCreateUserIdentity();
        if (this.canCreateUsers) {
            this.users.push({ name: 'Add a New User', id: this.newUserString });
        }
        if (this.authorizationService.canListUserIdentities(this.apiService.theTenantIs.id)) {
            this.canListUsers = true;
            this.subscription.add(
                this.iamService
                    .find('user-identities', { tenantId: this.apiService.theTenantIs.id })
                    .subscribe((results: any) => {
                        for (const user of this.sortByPipe.transform(results, 'lastName', 'desc')) {
                            this.users.push({
                                name: `${user['firstName']} ${user['lastName']} - ${user['email']}`,
                                id: user['id'],
                            });
                        }
                    })
            );
        }
    }

    ngOnDestroy() {
        if (this.regionServiceSub != null) {
            this.regionServiceSub.unsubscribe();
        }

        if (this.clientServiceGetSub != null) {
            this.clientServiceGetSub.unsubscribe();
        }

        this.updatedClientSub.unsubscribe();

        if (this.clientServiceSaveSub != null) {
            this.clientServiceSaveSub.unsubscribe();
        }

        this.subscription.unsubscribe();
    }

    copy() {
        const element = <HTMLInputElement>document.getElementById('RegKey');
        element.focus();
        element.select();
        document.execCommand('copy');
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Reg Key Copied',
                'The reg key ' + this.regKey + ' has been copied to your clipboard'
            )
        );
    }

    // function for getting the registration key to be displayed
    loadClient() {
        // getting the self link
        const selfLink = this.model.getSelfLink();
        if (selfLink) {
            // getting the model from the self link
            this.clientServiceGetSub.add(
                this.clientService.getResource(selfLink).subscribe((client) => {
                    this.completedTitleStatusString = this.buildingString;
                    // if the registration key was null, set the regKeySource to false to indicate it's still missing
                    if (client['registrationKey'] == null) {
                        this.updatedRegKeySource.next(false);

                        // otherwise, if the key was obtained, update regKey and set regKeySource to true to indicate the key was obtained
                    } else {
                        this.regKey = client['registrationKey'];
                        this.isBuilding = client['status'] < 300;
                        this.timeout = 1000;
                        if (this.isBuilding) {
                            this.updatedRegKeySource.next(false);
                        } else {
                            if (client['endpointType'] === 'ZTCL' || this.zitiEnabledService.zitiPure) {
                                this.getZitiRegKey();
                            } else {
                                this.updatedRegKeySource.next(true);
                                this.completedTitleStatusString = this.provisionedString;
                            }
                        }
                    }
                })
            );
            // otherwise, if the selfLink is null, set regKeySource to false
        } else {
            this.updatedRegKeySource.next(false);
        }
    }

    reset() {
        // marking that the hide function was called
        this.isHiding = true;
        this.isComplete = false;
        this.errorName = false;
        this.errorCountry = false;
        this.errorRegion = false;
        // resetting the number of attempts
        this.attempt = 0;

        // resetting the updatedRegKeySource
        this.updatedRegKeySource.next(false);

        // clearing the old model
        this.model = new Endpoint({});
        // setting the geoRegionId to the empty string so that the select list defaults to the placeholder
        this.model.geoRegionId = '';

        // resetting the reg key so that the old regKey is not displayed at all on subsequent creations
        this.regKey = '';
        this.timeout = 150;
    }

    async save() {
        this.attempt = 0;
        if (this.zitiEnabledService.zitiPure) {
            this.model.endpointType = 'CL';
        }
        if (this.validate()) {
            this.processing = true;

            if (this.requireAuthSession) {
                if (this.addingNewUser) {
                    const newIdentity = await this.createUser(this.newUserEmail);
                    if (newIdentity != null) {
                        this.inviteIdentity(newIdentity);
                        this.model['sessionIdentityId'] = newIdentity.id;
                    } else {
                        this.processing = false;
                        return;
                    }
                } else {
                    this.model['sessionIdentityId'] = this.authUserIdentity;
                }
            }

            if (this.clientServiceSaveSub != null) {
                this.clientServiceSaveSub.unsubscribe();
            }

            this.clientServiceSaveSub = this.clientServiceSaveSub = this.clientService.save(this.model).subscribe(
                (result) => {
                    this.model = new Endpoint(result);
                    this.newClient.emit(this.model);
                    this.editId = this.model.getId();
                    this.growlerService.show(
                        new GrowlerData('success', 'Success', 'Client Save Complete', 'The information has been saved')
                    );
                    this.isComplete = true;
                    this.processing = false;
                    this.loadClient();
                },
                () => {
                    this.processing = false;
                }
            );
        }
    }

    validate(): boolean {
        this.errorName = false;
        this.errorRegion = false;
        this.errorNameLength = false;
        this.errorType = false;
        this.errorAuthUser = false;
        this.errorEmail = false;
        this.errorCountry = false;

        if (!this.validateService.isValidName(this.model.name)) {
            this.errorName = true;
        }
        if (
            !this.validateService.hasValue(this.model.name) ||
            this.model.name.length < 5 ||
            this.model.name.length > 65
        ) {
            this.errorNameLength = true;
        }
        if (!this.validateService.hasValue(this.model.geoRegionId)) {
            this.errorRegion = true;
        }

        if (!this.validateService.hasValue(this.model.countryId)) {
            this.errorCountry = true;
        }
        if (!this.validateService.hasValue(this.model.endpointType)) {
            this.errorType = true;
        }

        if (this.requireAuthSession && !this.validateService.hasValue(this.authUserIdentity)) {
            this.errorAuthUser = true;
        } else if (
            this.requireAuthSession &&
            this.authUserIdentity === this.newUserString &&
            !this.validateService.isValidEmail(this.newUserEmail)
        ) {
            this.errorEmail = true;
        }

        return (
            !this.errorName &&
            !this.errorRegion &&
            !this.errorCountry &&
            !this.errorNameLength &&
            !this.errorType &&
            !this.errorAuthUser &&
            !this.errorEmail
        );
    }

    share() {
        this.shareService.show(new ShareData('client', this.model));
    }

    openUrl(url) {
        window.open(url);
    }

    openDownloads() {
        if (this.model.endpointType === 'ZTCL') {
            window.open(this.networkVersionService.getDownloadsLink() + '#ziticapable', '_blank');
        } else {
            window.open(this.networkVersionService.getDownloadsLink() + '#standard', '_blank');
        }
    }

    openInstructions() {
        if (this.model.endpointType === 'ZTCL') {
            window.open('https://netfoundry.github.io/ziti-doc/ziti/clients/tunneler.html', '_blank');
        } else {
            window.open(
                'https://netfoundry.zendesk.com/hc/en-us/sections/360002445391-Client-Gateway-Endpoints',
                '_blank'
            );
        }
    }

    downloadJWT() {
        const name = this.model.name + '.jwt';
        saveAs(this.zitiRegKeyBlob, name);
    }

    async getZitiRegKey() {
        this.zitiRegKeyBlob = await this.clientService
            .downloadRegistrationKey(this.model)
            .pipe(take(1))
            .toPromise()
            .then((result) => result);

        this.zitiRegKey = await new Response(this.zitiRegKeyBlob).text();

        this.updatedRegKeySource.next(true);
        this.completedTitleStatusString = this.provisionedString;
    }

    toggleRequireAuth() {
        this.requireAuthSession = !this.requireAuthSession;
        if (this.requireAuthSession === false) {
            this.authUserIdentity = '';
            this.addingNewUser = false;
        }
    }

    onChangeAuthUserIdentityId(userIdentityIdList) {
        if (userIdentityIdList.length > 0) {
            this.authUserIdentity = userIdentityIdList[0];
        } else {
            this.authUserIdentity = null;
            this.errorEmail = false;
        }

        if (this.authUserIdentity === this.newUserString) {
            this.addingNewUser = true;
        } else {
            this.addingNewUser = false;
            this.errorEmail = false;
        }
    }

    async createUser(email: string) {
        const identityModel = {
            firstName: '',
            lastName: '',
            email: '',
            tenantId: '',
        };

        identityModel.email = email;

        const splitEmail = identityModel.email.split('@');
        const nameSplit = splitEmail[0].split('.');

        const nameRegex = /[^a-zA-Z]/g;
        if (nameSplit.length > 1) {
            identityModel.firstName = nameSplit[0].replace(nameRegex, '');
            identityModel.lastName = nameSplit[1].replace(nameRegex, '');
        } else {
            identityModel.lastName = nameSplit[0].replace(nameRegex, '');
        }

        identityModel.tenantId = this.apiService.theTenantIs.id;

        return await this.iamService
            .create('user-identities', identityModel)
            .toPromise()
            .then(
                (result) => result,
                () => {
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Unable to Create User',
                            'There was an issue when attempting to create a new user'
                        )
                    );
                    return null;
                }
            );
    }

    goBack() {
        this.back.emit(true);
    }

    hideForm(response?: unknown) {
        this.hide.emit(response);
    }

    private inviteIdentity(identity) {
        // model for the invitation using the invitation URL for the environment, the provided email, and the provided tenant ID
        const inviteModel = {
            invitationUrl: this.environment.identityConfig.invitationUrl,
            invitedEmailAddress: identity.email,
            toTenantId: this.apiService.theTenantIs.id,
            targetUserIdentityId: identity.id,
        };

        // sending the invitation
        this.iamService.create('invitations', inviteModel).subscribe(() => {
            this.logger.info('successfully sent invitation');
        });
    }
}
