import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HTTP_CLIENT, LoggerService, TokenService } from '@netfoundry-ui/shared/services';
import { Observable, of as observableOf, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';

export const NETFLOW_CONFIGURATION = new InjectionToken<NetFlowConfig>('NETFLOW_CONFIGURATION');

export interface NetFlowConfig {
    url: string;
    pageSize: number;
}

@Injectable({ providedIn: 'root' })
export class NetflowService {
    lastErrorSource = new Subject<HttpErrorResponse>();
    lastError = this.lastErrorSource.asObservable();

    private apiUrl;
    private pageSize;

    // Had to use the old http client because the new client produced odd bugs with the Edge service
    constructor(
        private logger: LoggerService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        private tokenService: TokenService,
        @Inject(NETFLOW_CONFIGURATION) config?: NetFlowConfig
    ) {
        this.apiUrl = config.url;
        this.pageSize = config.pageSize;
    }

    /**
     * Generic GETter
     */
    get(path: string, params?: any): Observable<any> {
        const fullpath = this._path(path);
        // this.logger.debug('GET to path: ' + fullpath);

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            const options = {
                headers: headers,
                params: params,
            };

            return this.http.get(fullpath, options).pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic PUTer
     */
    put(path: string, body: any = {}): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(this._path(path), JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic POSTer
     */
    post(path: string, body: any = {}): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(this._path(path), JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic DELETE
     */
    delete(path, body: any): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            let options = {};

            if (body) {
                options = {
                    headers: headers,
                    responseType: 'text',
                    body: JSON.stringify(body),
                };
            } else {
                options = {
                    headers: headers,
                    responseType: 'text',
                };
            }

            return this.http
                .delete(this._path(path), options)
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Returns raw resource response from the API
     */
    getLinkedResource(model: any, resourceName: string, params?: any) {
        if (model._links[resourceName] !== undefined && model._links[resourceName]['href'] !== undefined) {
            return this.get(model._links[resourceName]['href'], params);
        } else {
            this.logger.info('No resource link with name: ' + resourceName, model);
            // noinspection TypeScriptUnresolvedFunction
            return observableOf({});
        }
    }

    /**
     * Wrapper function to handle full URLs vs relative URLs.
     */
    protected _path(path: string) {
        if (typeof path.includes !== 'function') {
            this.logger.error(
                'Unexpected destination passed for an HTTP request. Trying to request against an endpoint of: ' + path
            );
        }

        if (path.includes(this.apiUrl) || path.includes('http://') || path.includes('https://')) {
            return path;
        } else {
            return `${this.apiUrl}${path}`;
        }
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: any, error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            this.logger.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            this.logger.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        scope.lastErrorSource.next(error);
        return observableThrowError(error);
    }

    /**
     * Return headers
     */
    private setHeaders(): HttpHeaders {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (tokenResponse.expired) {
            // if the token is expired, return null
            headers = null;
        } else if (tokenResponse.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        if (headers != null) {
            return headers.append('Content-Type', 'application/json');
        } else {
            return headers;
        }
    }
}
