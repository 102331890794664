<div class="host-v1-options-container">
    <app-source-transparency
        [errors]="errors"
        [isEditing]="isEditing"
        [isNew]="isNew"
        [model]="model"
        [readOnly]="readOnly"
    ></app-source-transparency>
    <app-listen-options
        [errors]="errors"
        [isEditing]="isEditing"
        [isNew]="isNew"
        [model]="model"
        [readOnly]="readOnly"
    ></app-listen-options>
</div>
