<div class="fullModal opened" id="AppWANFormModal">
    <!-- Header Editor Buttons -->
    <div
        (click)="edit()"
        *ngIf="!isEditing || canModify"
        [hidden]="isLoading || step !== 1"
        [hidden]="step === 1"
        class="buttonBall edit"
        id="EditAppWansButton"
    >
        <div class="buttonLabel">EDIT</div>
    </div>
    <div (click)="hide()" [hidden]="isLoading" class="buttonBall close" id="CloseAppWansButton">
        <div class="buttonLabel">ESC</div>
    </div>

    <app-loader [isLoading]="isLoading"></app-loader>
    <!-- AppWAN Form Elements -->
    <div [hidden]="isLoading || step !== 1" class="fullRow">
        <div [hidden]="!isAdding" class="title" style="filter: brightness(50%)">Create A New AppWAN</div>
        <div [hidden]="!isEditing" class="title" style="filter: brightness(50%)">Edit Your AppWAN</div>
        <div class="subtitle smaller">Choose from existing Feature, or add new ones</div>
        <div class="sized mid">
            <div class="row">
                <div class="step">1</div>
                <div class="label row">
                    AppWAN NAME<span [ngClass]="{ error: errorName }" class="note">Required</span>
                </div>
                <div class="row">
                    <input
                        (change)="updatedName()"
                        [(ngModel)]="model.name"
                        [disabled]="!canModify"
                        [ngClass]="{ error: errorName }"
                        id="AppwanName"
                        maxlength="64"
                        placeholder="{{ namePlaceholder }}"
                    />
                </div>
            </div>
            <div class="row" style="margin-bottom: 15px">
                <div class="step">2</div>
                <div class="label row">Add Clients, Gateways, or Endpoint Groups</div>
                <input
                    [(ngModel)]="searchEndpointString"
                    class="curved"
                    id="SearchEndpoints"
                    placeholder="Search for Endpoints"
                />
            </div>
        </div>
        <div class="sizedlarge">
            <div class="grid selectors">
                <div>
                    <div class="subcontent">
                        Available Groups
                        <div class="note padRight">Add New</div>
                        <div (click)="addGroup()" class="miniadd icon-BigSoftPlus" id="GroupMiniAdd"></div>
                    </div>

                    <div data-to="SelectedEndpoints" id="AllEndpoints" style="height: 109px">
                        <app-pickbox
                            #availableGroupsPicker
                            (itemSelected)="moveTo($event, 'group')"
                            [iconClass]="'itemGraphic icon-GP'"
                            [identifier]="'AvailableGroups'"
                            [isLoading]="groupsLoading"
                            [items]="availableGroups"
                            [resourceType]="'group'"
                            [scrollable]="true"
                            [searchString]="searchEndpointString"
                        ></app-pickbox>
                    </div>

                    <div class="subcontent" style="margin-top: 20px">
                        Available Clients
                        <div class="note padRight">Add New</div>
                        <div (click)="addClient()" class="miniadd icon-BigSoftPlus" id="ClientMiniAdd"></div>
                    </div>

                    <app-pickbox
                        #availableClientsPicker
                        (itemSelected)="moveTo($event, 'endpoint')"
                        [height]="'109px'"
                        [identifier]="'AvailableClients'"
                        [isLoading]="clientsLoading"
                        [items]="availableClients"
                        [resourceType]="'client'"
                        [scrollable]="true"
                        [searchString]="searchEndpointString"
                    ></app-pickbox>

                    <div class="subcontent" style="margin-top: 20px">
                        Available Gateways
                        <div class="note padRight">Add New</div>
                        <div (click)="addGateway()" class="miniadd icon-BigSoftPlus" id="GatewayMiniAdd"></div>
                    </div>

                    <app-pickbox
                        #availableGatewaysPicker
                        (itemSelected)="moveTo($event, 'endpoint')"
                        [height]="'109px'"
                        [iconClass]="'itemGraphic icon-GW'"
                        [identifier]="'AvailableGateways'"
                        [isLoading]="gatewaysLoading"
                        [items]="availableGateways"
                        [resourceType]="'gateway'"
                        [scrollable]="true"
                        [searchString]="searchEndpointString"
                    ></app-pickbox>
                </div>
                <!-- end left side -->

                <div class="moveButtons">
                    <div
                        (click)="removeAllEndpoints()"
                        *ngIf="!isEditing || canModify"
                        class="arrow moveLeft moveLeftEnd"
                        data-from="SelectedEndpoints"
                        data-to="AllEndpoints"
                        id="SelectedEndpoints_Arrow"
                    ></div>
                    <div
                        (click)="addAllEndpoints()"
                        *ngIf="!isEditing || canModify"
                        class="arrow moveRight moveRightEnd"
                        data-from="AllEndpoints"
                        data-to="SelectedEndpoints"
                        id="AllEndpoints_Arrow"
                    ></div>
                </div>

                <!-- begin right side  -->
                <div>
                    <div class="subcontent">Selected Endpoints</div>
                    <div class="formList selectedHeight scroll" data-to="variable" id="SelectedEndpoints">
                        <div
                            (click)="moveFrom(item, 'group')"
                            *ngFor="let item of allGroups | sortby: 'name'; let index = index"
                            [attr.data-index]="index"
                            [hidden]="!isAssigned(item, 'group')"
                            class="listItem GP"
                            id="Selected_{{ item.id }}"
                        >
                            <div class="itemGraphic icon-groups"></div>
                            {{ item.name }}
                        </div>
                        <div
                            (click)="moveFrom(item, 'gateway')"
                            *ngFor="let item of allGateways | sortby: 'name'; let index = index"
                            [attr.data-index]="index"
                            [hidden]="!isAssigned(item, 'endpoint')"
                            class="listItem padLeft GW"
                            id="Selected_{{ item.id }}"
                        >
                            <div class="itemGraphic icon-gateways"></div>
                            <app-resource-status [item]="item" [resourceType]="'gateway'"></app-resource-status>
                        </div>
                        <div
                            (click)="moveFrom(item, 'client')"
                            *ngFor="let item of allClients | sortby: 'name'; let index = index"
                            [attr.data-index]="index"
                            [hidden]="!isAssigned(item, 'endpoint')"
                            class="listItem padLeft CL"
                            id="Selected_{{ item.id }}"
                        >
                            <div class="itemGraphic icon-clients"></div>
                            <app-resource-status [item]="item" [resourceType]="'client'"></app-resource-status>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sized mid">
            <div class="row" style="margin-top: 10px; margin-bottom: 15px">
                <div class="step">3</div>
                <div class="label row">Add Services</div>
                <input
                    [(ngModel)]="searchServiceString"
                    class="curved"
                    id="SearchServices"
                    placeholder="Search for Services"
                />
            </div>
        </div>
        <div class="sizedlarge">
            <div class="grid selectors">
                <div>
                    <div class="subcontent">
                        Available Services
                        <div class="note padRight">Add New</div>
                        <div (click)="addService()" class="miniadd icon-BigSoftPlus" id="ServiceMiniAdd"></div>
                    </div>

                    <app-pickbox
                        #availableServicesPicker
                        (itemSelected)="moveTo($event, 'service')"
                        [iconClass]="'itemGraphic icon-AW'"
                        [identifier]="'AllServices'"
                        [isLoading]="servicesLoading"
                        [items]="availableServices"
                        [resourceType]="'service'"
                        [scrollable]="true"
                        [searchString]="searchServiceString"
                    ></app-pickbox>
                </div>

                <div class="moveButtons services">
                    <div
                        (click)="removeAllServices()"
                        *ngIf="!isEditing || canModify"
                        class="arrow moveLeft moveFor"
                        data-from="SelectedServices"
                        data-to="AllServices"
                        id="SelectedServices_Arrow"
                    ></div>
                    <div
                        (click)="addAllServices()"
                        *ngIf="!isEditing || canModify"
                        class="arrow moveRight moveFor"
                        data-from="AllServices"
                        data-to="SelectedServices"
                        id="AllServices_Arrow"
                    ></div>
                </div>
                <div>
                    <div class="subcontent">Selected Services</div>

                    <!--            <app-pickbox #selectedServicesPicker-->
                    <!--                 [items]="availableServicesPicker.itemsSelected"-->
                    <!--                 [resourceType]="'service'"-->
                    <!--                 [identifier]="'SelectedServices'"-->
                    <!--                 (itemSelected)="moveFrom($event,'service')"-->
                    <!--                 [scrollable]="false"-->
                    <!--                 [isLoading]="servicesLoading"-->
                    <!--                 [searchString]="searchServiceString"-->
                    <!--                 [iconClass]="'itemGraphic icon-AW'"-->
                    <!--            ></app-pickbox>-->

                    <div
                        class="formList selectedHeight scroll"
                        data-to="variable"
                        id="SelectedServices"
                        style="height: 110px"
                    >
                        <div
                            (click)="moveFrom(item, 'service')"
                            *ngFor="let item of allServices | sortby: 'name'; let index = index"
                            [attr.data-index]="index"
                            [hidden]="!isAssigned(item, 'service')"
                            class="listItem padLeft"
                            id="SelectedService_{{ item.id }}"
                        >
                            <div class="itemGraphic icon-services"></div>
                            <app-resource-status [item]="item" [resourceType]="'service'"></app-resource-status>
                        </div>
                    </div>
                </div>
            </div>
            <div></div>
            <div class="row buttonRow right">
                <button (click)="initSave()" *ngIf="!isEditing || canModify" class="save" id="SaveButton">
                    {{ isEditing ? 'Save' : 'Create' }}
                </button>
            </div>
        </div>
    </div>

    <!-- AppWAN Summary Elements -->
    <div [hidden]="step !== 2" class="fullRow">
        <div class="title" style="filter: brightness(50%)">Your AppWAN Summary</div>
        <div class="subtitle smaller">Your AppWAN has been created! A network summary is below.</div>
        <div class="fullModalcontent">
            What's next? Finish connecting your network by registering new clients and gateways.
        </div>

        <div class="sized">
            <div class="grid two marginBottom">
                <div class="hint">
                    <div class="title">New Clients</div>
                    <div class="subtitle">Share Client Registration Info</div>
                    <div class="image icon-Icon_Share"></div>
                </div>
                <div class="hint">
                    <div class="title">New Gateways</div>
                    <div class="subtitle">Tap to Launch and Register</div>
                    <div class="image icon-ClickIcon"></div>
                </div>
            </div>
            <div class="wizard">
                <div class="summary">
                    <div class="row">
                        <div class="step">1</div>
                        <div class="title">AppWAN Name</div>
                        <div class="subtitle">
                            {{ model.name }}
                            <div (click)="edit()" class="editor"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="step">2</div>
                        <div class="title">Endpoints</div>
                        <!-- Client List Area -->
                        <label class="underline"
                            >Clients
                            <div class="note">Share New Clients</div>
                        </label>
                        <div class="content">
                            <div *ngFor="let item of finalClients; let index = index" class="grid clientGrid">
                                <div class="gridLabel">
                                    <app-resource-status
                                        (click)="editClient(item, index)"
                                        [isZiti]="item.endpointType === 'ZTCL'"
                                        [item]="item"
                                        [resourceType]="'client'"
                                        style="cursor: pointer"
                                    ></app-resource-status>
                                    <div (click)="editClient(item, index)" class="clicker"></div>
                                </div>
                                <div [hidden]="item['registrationKey'] == null">
                                    <input
                                        [(ngModel)]="sendto[index]"
                                        [ngClass]="{ error: errorEmails }"
                                        id="RegSend{{ index }}"
                                    />
                                </div>
                                <div
                                    (click)="SendRegistrationInline(item, index)"
                                    *ngIf="sendingState[index] === ''"
                                    [hidden]="item['registrationKey'] == null"
                                    class="sendButton icon-Icon_Share"
                                    id="SendButton_{{ index }}"
                                ></div>
                                <div *ngIf="sendingState[index] === 'sending'" class="sendButton sending">
                                    <div class="spinner"></div>
                                </div>
                                <div
                                    *ngIf="sendingState[index] === 'sent'"
                                    class="sendButton sent icon-Icon_Check"
                                ></div>
                            </div>
                        </div>
                        <!-- Gateway List Area -->
                        <label class="underline"
                            >Gateways
                            <div class="note">Register New Gateways</div>
                        </label>
                        <div class="content">
                            <div *ngFor="let item of finalGateways; let index = index" class="grid">
                                <div [hidden]="item['registrationKey'] != null">
                                    {{ item.name }}
                                </div>
                                <div
                                    [hidden]="item['registrationKey'] == null"
                                    [ngClass]="{ noLauncherScript: !showLauncher(item) }"
                                    class="launch"
                                >
                                    <app-resource-status
                                        (click)="editGateway(item, index)"
                                        [item]="item"
                                        [resourceType]="'gateway'"
                                        style="cursor: pointer"
                                    ></app-resource-status>
                                    <div (click)="editGateway(item, index)" class="clicker"></div>
                                    <div
                                        (click)="
                                            authorizationService.canGetDataCenter(item['dataCenterId']) &&
                                                LaunchCloudScript(item)
                                        "
                                        *ngIf="
                                            showLauncher(item) &&
                                            IsAWS(item) &&
                                            !IsHA(item) &&
                                            !isGatewayRegistered(item)
                                        "
                                        class="tap aws"
                                    >
                                        Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                                    </div>
                                    <div
                                        (click)="LaunchAzureCloudScript(item)"
                                        *ngIf="
                                            showLauncher(item) &&
                                            IsAzure(item) &&
                                            !IsHA(item) &&
                                            !isGatewayRegistered(item)
                                        "
                                        class="tap azure"
                                    >
                                        Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                                    </div>
                                    <div
                                        *ngIf="IsGoogle(item) && !IsHA(item) && !isGatewayRegistered(item)"
                                        class="tap google"
                                    >
                                        <span class="icon-ClickIcon"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Service List Area -->
                    <div class="row">
                        <div class="step">3</div>
                        <div class="title">Services</div>
                        <label class="underline">Service Definitions</label>
                        <div class="content">
                            <div *ngFor="let item of finalServices; let index = index" class="grid serviceGrid">
                                <div class="status s{{ item.status }}"></div>
                                <span (click)="editService(item, index)" style="cursor: pointer">{{ item.name }}</span>
                                <div (click)="editService(item, index)" class="clicker"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- Group List Area -->
                        <div class="step">4</div>
                        <div>
                            <div class="title">Endpoint Groups</div>
                            <label class="underline">Groups</label>
                            <div class="content">
                                <div *ngFor="let item of finalGroups; let index = index" class="grid groupGrid">
                                    <span (click)="editGroups(item)" style="cursor: pointer">{{ item.name }}</span>
                                    <div (click)="editGroups(item, index)" class="clicker"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">&nbsp;</div>
                </div>
            </div>
            <div class="row">
                <div (click)="clone()" class="cloneButton">
                    <div class="icon"></div>
                    <div class="content">Want to add another environment<br />with the same services or endpoints?</div>
                    <div class="buttonText">Tap To Clone</div>
                    <div class="tapIcon icon-ClickIcon"></div>
                </div>
            </div>
        </div>
    </div>
</div>
