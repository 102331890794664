import { ElasticsearchQuery } from './elasticsearch-query';

export class EventsTimelineNetworksQuery extends ElasticsearchQuery {
    // query for
    protected model = {
        aggs: {
            networkId: {
                terms: {
                    field: 'networkId',
                    size: 100,
                    order: {
                        _count: 'desc',
                    },
                },
            },
        },
        size: 0,
        query: {
            bool: {
                must: [
                    {
                        match_all: {},
                    },
                    {
                        range: {
                            '@timestamp': {
                                gte: 'now-7d',
                                lte: 'now',
                                format: 'epoch_millis',
                            },
                        },
                    },
                    {
                        match_phrase: {
                            organizationId: {
                                query: '4d15ef35-cfa0-4963-a667-5c86d16ce77e',
                            },
                        },
                    },
                ],
            },
        },
    };

    public getIndexPattern() {
        return 'ncentityevent';
    }

    public getAggregateName() {
        return null;
    }
}
