import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientdashboardComponent } from '@netfoundry-ui/feature/dashboard/client-dashboard';
import { GatewaydashboardComponent } from '@netfoundry-ui/feature/dashboard/gateway-dashboard';
import { ServicesformComponent } from '@netfoundry-ui/feature/form/services-form';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { AppWan, Endpoint, Group, Service, ShareData } from '@netfoundry-ui/shared/model';
import {
    AppwanService,
    AzureDeployService,
    ClientService,
    GatewayClusterService,
    GatewayService,
    GetCloudformationLinkService,
    GroupService,
    LoggerService,
    NetworkVersionService,
    RegionService,
    ServiceService,
    ValidateService,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { ShareService } from '@netfoundry-ui/shared/share';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { RegionTransformPipe } from '@netfoundry-ui/ui/pipes';
import { WarningListComponent } from '@netfoundry-ui/ui/warning-list';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

const provisionedStatus = 300;
const registeredStatus = 400;

const standaloneTypeTag = '[S]';
const haTypeTag = '[HA]';
const autoscaleTypeTag = '[AS]';

@Component({
    selector: 'app-app-wan-wizard',
    templateUrl: './app-wan-wizard.component.html',
    styleUrls: ['./app-wan-wizard.component.scss'],
})
export class AppWanWizardComponent implements OnInit, OnDestroy {
    step = 1;
    stepStyle = 'step0';
    showAddGatewayPreselect = false;
    showAddPlaceClient = false;
    showAddPlaceGateway = false;
    hasNewClients = true;
    isAdding = true;
    isEditing = false;
    isDeveloper = false;
    gateways = [];
    gatewaysNoHa = [];
    gatewaysSelected: Endpoint[] = [];
    clients: Endpoint[] = [];
    clientsSelected: Endpoint[] = [];
    groups: Group[] = [];
    groupsSelected: Group[] = [];
    regions: Location[] = [];
    newGatewayRegions: Location[] = [];
    awsRegions: Location[] = [];
    azureRegions: Location[] = [];
    googleRegions: Location[] = [];
    locations = [];
    services: Service[] = [];
    ipNetworkServices: Service[] = [];
    ipHostServices: Service[] = [];
    availableServices: Service[] = [];
    sendto: string[] = [];
    sendingState: string[] = [];
    newPreSelectGateway = new Endpoint({});
    newGateway = new Endpoint({});
    newClient = new Endpoint({});
    gatewayPreSelected = null;
    model: AppWan = new AppWan({});
    finalModel: AppWan = new AppWan({});
    servicesToAssign: Service[] = [];
    // save step
    servicesCreated = false;
    appwanCreated = false;
    servicesAreAssigned = false;
    servicesAreReady = false;
    groupsAreAssigned = false;
    gatewaysAreReady = false;
    gatewaysAreAssigned = false;
    clientsAreReady = false;
    clientsAreAssigned = false;
    appwanTimedOut = false;
    appwanIsReady = false;
    appwanError = false;
    errors = [];
    step1Error = '';
    step2Error = '';
    step3HostError = '';
    step3NetworkError = '';
    step4Error = '';
    gwNameError = false;
    gwNameLengthError = false;
    gwTypeError = false;
    gwRegionError = false;
    gwPreNameError = false;
    gwPreNameLengthError = false;
    gwPreTypeError = false;
    gwPreRegionError = false;
    clNameError = false;
    clNameLengthError = false;
    clRegionError = false;
    clTypeError = false;
    step3HostErrors: string[] = [];
    step3NetworkErrors: string[] = [];
    clientsPending = [];
    servicesPending = [];
    servicesReady = [];
    clientsWithError = [];
    servicesWithError = [];
    clientsReady = [];
    gatewaysPending = [];
    gatewaysWithError = [];
    gatewaysReady = [];
    zitiDialogRef;
    hasNetworkServices = true;
    zitiClients = [];
    networkAndIcmpServices = [];
    namePlaceholder = 'What would you like to call this app?';
    canListEndpoints = true;
    canCreateEndpoints = true;
    canListGroups = true;
    canCreateGroups = true;
    addedGatewayToPreselect = false;
    clusterCount = 0;
    disableAzureStackDeploy = false;
    // todo remove
    hideLaunchButton = false;
    errorEmails = false;
    oldEndpointType = '';
    oldNewEndpointType = '';
    countryError = false;
    gwPreCountryError = false;
    clCountryError = false;
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private serviceService: ServiceService,
        private clientService: ClientService,
        private gatewayService: GatewayService,
        private groupService: GroupService,
        private regionService: RegionService,
        private appwanService: AppwanService,
        private growlerService: GrowlerService,
        public validateService: ValidateService,
        private shareService: ShareService,
        private cfService: GetCloudformationLinkService,
        private dialogRef: MatDialogRef<AppWanWizardComponent>,
        public dialogInlineRef: MatDialogRef<ServicesformComponent>,
        public dialogInlineGWRef: MatDialogRef<GatewaydashboardComponent>,
        public dialogInlineCLRef: MatDialogRef<ClientdashboardComponent>,
        public dialogForm: MatDialog,
        public zitiEnabledService: ZitiEnabledService,
        private clusterService: GatewayClusterService,
        public authorizationService: AuthorizationService,
        private azureDeployService: AzureDeployService,
        private networkVersionService: NetworkVersionService,
        private regionTransform: RegionTransformPipe,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    showAzureLauncher(gateway) {
        if (this.disableAzureStackDeploy && gateway.endpointType === 'AZSGW') {
            return false;
        } else {
            return true;
        }
    }

    ngOnInit() {
        // TODO remove
        this.hideLaunchButton = this.networkVersionService.currentNetworkVersion > 4;

        this.canListEndpoints = this.authorizationService.canListEndpoints();
        this.canCreateEndpoints = this.authorizationService.canCreateEndpoints();
        this.canListGroups = this.authorizationService.canListEndpointGroups();
        this.canCreateGroups = this.authorizationService.canCreateEndpointGroups();
        this.finalModel['services'] = [];
        this.finalModel['endpointGroups'] = [];
        this.finalModel['clients'] = [];
        this.finalModel['gateways'] = [];
        this.finalModel['hostingGateway'] = new Endpoint({});
        this.finalModel['hostingGatewayName'] = '';
        this.isDeveloper = this.data.isDeveloper;

        this.azureDeployService.canShowAzureFormationLink('AZSGW').subscribe((result) => {
            this.disableAzureStackDeploy = !result;
        });
        // setting the endpointType and geoRegionId to the empty string
        // this fixes the select boxes not having the default selected
        this.newPreSelectGateway.endpointType = '';
        this.newPreSelectGateway.geoRegionId = '';

        // setting geoRegionId to the empty string to fix the select box
        this.newClient.geoRegionId = '';
        if (this.zitiEnabledService.zitiEnabled) {
            // if the network is a ziti network, set the endpoint type to the empty string
            // the use will be required to set the endpoint type
            this.newClient.endpointType = '';
        } else {
            // if the network is not a ziti network, set the endpoint type to the standard client
            this.newClient.endpointType = 'CL';
        }

        // setting endpointType and geoRegionId to the empty string to fix the select boxes
        this.newGateway.endpointType = '';
        this.newGateway.geoRegionId = '';

        // inject an empty service row into the form
        this.AddHostServiceFields();
        this.AddNetworkServiceFields();

        const proms = [];
        if (this.canListEndpoints) {
            // gateways
            const gatewayPromise = this.gatewayService
                .get()
                .pipe(take(1))
                .toPromise()
                .then((result) => {
                    const gateways = [];
                    for (const gateway of result) {
                        if (
                            (gateway.status === provisionedStatus || gateway.status === registeredStatus) &&
                            gateway.endpointProtectionRole == null
                        ) {
                            this.gatewaysNoHa.push(gateway);
                            if (
                                gateway.endpointType !== 'ZTGW' &&
                                gateway.endpointType !== 'ZTNHGW' &&
                                gateway.endpointType !== 'L2VCPEGW'
                            ) {
                                gateway['typeTag'] = standaloneTypeTag;
                                gateways.push(gateway);
                            }
                        }
                    }

                    return gateways;
                });

            proms.push(gatewayPromise);

            // clients
            this.subscription.add(
                this.clientService.get().subscribe((result) => {
                    const clients = [];
                    for (const client of result) {
                        if (client.status === provisionedStatus || client.status === registeredStatus) {
                            clients.push(client);
                        }
                    }
                    this.clients = clients;
                })
            );
        }

        if (this.authorizationService.canListGatewayClusters()) {
            const clusterPromise = this.clusterService
                .get()
                .pipe(take(1))
                .toPromise()
                .then((result) => {
                    const clusters = [];
                    for (const cluster of result) {
                        this.clusterCount++;
                        if (
                            cluster.status === provisionedStatus &&
                            cluster.endpointProtectionRole == null &&
                            cluster.endpointType !== 'ZTGW' &&
                            cluster.endpointType !== 'ZTNHGW'
                        ) {
                            let typeTag = haTypeTag;
                            if (cluster.protectionType != null && cluster.protectionType === 'AwsAutoScale') {
                                typeTag = autoscaleTypeTag;
                            }

                            cluster['typeTag'] = typeTag;

                            clusters.push(cluster);
                        }
                    }
                    return clusters;
                });
            proms.push(clusterPromise);
        }

        Promise.all(proms).then((gatewayLists) => {
            this.gateways = [];
            for (const gatewayList of gatewayLists) {
                this.gateways = this.gateways.concat(gatewayList);
            }
            this.stepStyle = 'step0';
        });

        if (this.canListGroups) {
            // groups
            this.subscription.add(
                this.groupService.get().subscribe((groups) => {
                    this.groups = groups;
                })
            );
        }
        // regions
        this.subscription.add(
            this.regionService.get().subscribe((result) => {
                this.awsRegions = this.regionTransform.transform(result as Location[], true, false, false, false);
                this.azureRegions = this.regionTransform.transform(result as Location[], false, true, false, false);
                this.googleRegions = this.regionTransform.transform(result as Location[], false, false, true, false);
                this.regions = this.awsRegions;
                this.newGatewayRegions = this.awsRegions;
            })
        );

        // current services
        this.subscription.add(
            this.serviceService.get().subscribe((result) => {
                this.availableServices = result;
            })
        );
    }

    hideForm(e) {
        this.showAddGatewayPreselect = false;
    }

    AddGatewayPreselectForm() {
        this.showAddGatewayPreselect = true;
    }

    /**
     * Add a gateway to the select list this all services will be attached to
     */
    AddGatewayToPreselect() {
        this.addedGatewayToPreselect = true;

        if (!this._validatePreGatewayAdd(this.newPreSelectGateway)) {
            return;
        }

        this.gatewayService.save(this.newPreSelectGateway).subscribe((gateway) => {
            const gatewayModel = new Endpoint(gateway);
            this.gateways.push(gatewayModel);
            this.showAddGatewayPreselect = false;
            this.newPreSelectGateway = new Endpoint({});
            this.newPreSelectGateway.endpointType = '';
            this.newPreSelectGateway.geoRegionId = '';
            this.gatewayPreSelected = gatewayModel;
            this.oldEndpointType = '';
            this.regions = this.awsRegions;
        });
    }

    RemoveHostService(index) {
        this.ipHostServices.splice(index, 1);
    }

    RemoveNetworkService(index) {
        this.ipNetworkServices.splice(index, 1);
    }

    AddHostServiceFields() {
        const newService = new Service({
            bridgeStp: 'YES',
            collectionLocation: 'BOTH',
            cryptoLevel: 'STRONG',
            dnsOptions: 'NONE',
            icmpTunnel: 'NO',
            localNetworkGateway: 'YES',
            multicast: 'OFF',
            pbrType: 'WAN',
            permanentConnection: 'NO',
            rateSmoothing: 'NO',
            serviceInterceptType: 'IP',
            serviceType: '',
        });
        newService.serviceClass = 'CS';
        this.ipHostServices[this.ipHostServices.length] = newService;
    }

    AddNetworkServiceFields() {
        const newService = new Service({
            bridgeStp: 'YES',
            collectionLocation: 'BOTH',
            cryptoLevel: 'STRONG',
            dnsOptions: 'NONE',
            icmpTunnel: 'NO',
            localNetworkGateway: 'YES',
            multicast: 'OFF',
            pbrType: 'WAN',
            permanentConnection: 'NO',
            rateSmoothing: 'NO',
            serviceInterceptType: 'IP',
        });
        newService.serviceClass = 'GW';
        newService.serviceType = 'ALL';
        this.ipNetworkServices[this.ipNetworkServices.length] = newService;
    }

    ToggleAddClientForm() {
        this.showAddPlaceClient = !this.showAddPlaceClient;
    }

    /**
     * Add a client to the selection list this will populate the AppWan endpoints
     */
    AddClient() {
        // validating the client form
        if (!this._validateClientAdd(this.newClient) || !this.canCreateEndpoints) {
            return;
        }

        // saving the client
        this.clientService.save(this.newClient).subscribe((client) => {
            const endp = new Endpoint(client);
            this.clients.push(endp);
            this.clientsSelected.push(endp);
            this.showAddPlaceClient = false;
            this.newClient = new Endpoint({});

            // setting the geoRegionId to the empty string for the new client model
            // to fix the select list
            this.newClient.geoRegionId = '';

            if (this.zitiEnabledService.zitiEnabled) {
                // if the network is a ziti network, set the endpointType to the empty string
                // to fix the select options
                this.newClient.endpointType = '';
            } else {
                // if the network is not a ziti network set the endpointType to the standard CL
                this.newClient.endpointType = 'CL';
            }
        });
    }

    /**
     * Add a gateway to the selection list this will populate the AppWan endpoints
     */
    AddGateway() {
        if (!this._validateGatewayAdd(this.newGateway) || !this.canCreateEndpoints) {
            return;
        }

        this.gatewayService.save(this.newGateway).subscribe((gateway) => {
            const endp = new Endpoint(gateway);
            this.gateways.push(endp);
            this.gatewaysSelected.push(endp);
            this.showAddPlaceGateway = false;
            this.newGateway = new Endpoint({});

            // setting the endpointType and geoRegionId to the empty string to fix the select lists
            this.newGateway.endpointType = '';
            this.newGateway.geoRegionId = '';
            this.oldNewEndpointType = '';
            this.newGatewayRegions = this.awsRegions;
        });
    }

    ToggleAddPlaceGatewayForm() {
        this.showAddPlaceGateway = !this.showAddPlaceGateway;
    }

    hide() {
        this.dialogRef.close();
    }

    IsAzure(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }
        return gateway.endpointType.indexOf('AZ') >= 0;
    }

    IsAWS(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }
        return gateway.endpointType.indexOf('AWS') >= 0;
    }

    IsHA(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }
        return gateway['protectionGroupId'] != null;
    }

    IsGoogle(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }

        return gateway.endpointType === 'GCPCPEGW';
    }

    /**
     * Email the reg key
     */
    SendRegistration(client: Endpoint) {
        const endpoint = new Endpoint(client);
        // Send the registration email
        this.shareService.show(new ShareData('client', endpoint));
    }

    SendRegistrationInline(client: Endpoint, index) {
        const endpoint = new Endpoint(client);
        // Send the registration email
        const emails = this.sendto[index];

        if (this.validateEmails(emails)) {
            this.sendingState[index] = 'sending';

            const payload = {
                toList: emails.split(';').join(','),
                ccList: '',
                subject: 'NetFoundry - Registration Information',
                from: 'no-reply@netfoundry.io',
                replacementParams: {
                    ENV: '',
                    EMAIL: localStorage.getItem('profile_email'),
                    USER: localStorage.getItem('profile_nick'),
                },
            };

            this.clientService.share(endpoint, payload).subscribe((res) => {
                this.sendingState[index] = 'sent';
                this.sendto[index] = '';
                setTimeout(() => {
                    this.sendingState[index] = '';
                }, 3000);
            });
        }
    }

    validateEmails(emails) {
        this.errorEmails = false;

        if (!this.validateService.hasValue(emails)) {
            this.errorEmails = true;
        }

        if (!this.validateService.isValidEmailList(emails)) {
            this.errorEmails = true;
        }

        return !this.errorEmails;
    }

    /**
     * Email the reg key
     */
    SendGWRegistration(gateway: Endpoint) {
        const endpoint = new Endpoint(gateway);
        // Send the registration email
        this.shareService.show(new ShareData('gateway', endpoint));
    }

    LaunchAzureCloudScript(gateway: Endpoint) {
        this.azureDeployService.openAzureFormationLink(gateway);
    }

    /**
     * Launch cloud formation template in a new tab
     */
    LaunchCloudScript(gateway: Endpoint) {
        if (this.authorizationService.canGetDataCenter(gateway['dataCenterId'])) {
            this.subscription.add(
                this.gatewayService
                    .getResource(this.gatewayService.getLinkedResourceUrl(gateway, 'dataCenter'))
                    .subscribe((result) => {
                        const region = result['locationCode'];
                        const link = this.cfService.getCfLink(gateway, region);
                        const win = window.open(link, '_blank');
                        win.focus();
                    })
            );
        }
    }

    /**
     * Run validation on all steps of the form starting from the top
     */
    async runValidation() {
        this.logger.info('Running validation');

        this.step1Error = '';
        this.step2Error = '';
        this.step3HostError = '';
        this.step3NetworkError = '';
        this.step4Error = '';

        let isValid = true;

        if (!this.validateStep1()) {
            isValid = false;
        }
        if (!this.validateStep2()) {
            isValid = false;
        }
        if (!this.validateStep3()) {
            isValid = false;
        }
        if (!this.validateStep4()) {
            isValid = false;
        }

        return isValid;
    }

    /**
     * Validate step 1 of the form
     */
    validateStep1() {
        if (!this.validateService.isValidName(this.model.name)) {
            this.step1Error = 'AppWan Name is required';
            return false;
        }

        return true;
    }

    /**
     * Validate step 2 of the form
     */
    validateStep2() {
        if (
            !this.validateService.hasValue(this.gatewayPreSelected) ||
            !this.validateService.hasValue(this.gatewayPreSelected['id'])
        ) {
            this.step2Error = 'You must select a gateway';
            return false;
        }

        return true;
    }

    /**
     * Validate step 3 of the form
     */
    validateStep3() {
        this.step3HostError = '';
        this.step3HostErrors = [];

        this.step3NetworkError = '';
        this.step3NetworkErrors = [];

        let isValid = true;

        let emptyHostService = false;
        let emptyNetworkService = false;

        if (this.ipHostServices.length === 1) {
            emptyHostService = this.isEmptyHostService(this.ipHostServices[0]);
        }

        if (this.ipNetworkServices.length === 1) {
            emptyNetworkService = this.isEmptyNetworkService(this.ipNetworkServices[0]);
        }

        if (!emptyHostService || (emptyHostService && emptyNetworkService)) {
            isValid = this.validateHostServices() && isValid;
        }

        if (!emptyNetworkService || (emptyHostService && emptyNetworkService)) {
            isValid = this.validateNetworkServices() && isValid;
        }

        if (isValid) {
            if (!emptyHostService && !emptyNetworkService) {
                this.services = this.ipHostServices.concat(this.ipNetworkServices);
            } else if (emptyHostService) {
                this.services = this.ipNetworkServices.concat([]);
            } else {
                this.services = this.ipHostServices.concat([]);
            }
        }

        return isValid;
    }

    isEmptyHostService(service) {
        return (
            !this.validateService.hasValue(service.networkFirstPort) &&
            !this.validateService.hasValue(service.serviceType) &&
            !this.validateService.hasValue(service.interceptIp)
        );
    }

    isEmptyNetworkService(service) {
        return !this.validateService.hasValue(service.gatewayIp) && !this.validateService.hasValue(service.interceptIp);
    }

    validateHostServices() {
        let isValid = true;
        let index = 0;
        this.step3HostError = '';

        for (const service of this.ipHostServices) {
            this.logger.info('Validating services', service);
            this.step3HostErrors[index] = '';

            if (!this.validateService.isValidIP(service.networkIp)) {
                this.step3HostError += 'IP';
                this.step3HostErrors[index] += 'IP';
                isValid = false;
            }

            if (!this.validateService.isValidPortOrPortRange(service.networkFirstPort)) {
                this.step3HostError += 'Port';
                this.step3HostErrors[index] += 'Port';
                isValid = false;
            }

            if (!this.validateService.hasValue(service.serviceType)) {
                this.step3HostError += 'Protocol';
                this.step3HostErrors[index] += 'Protocol';
                isValid = false;
            }

            index++;
        }

        return isValid;
    }

    validateNetworkServices() {
        let isValid = true;
        let index = 0;
        this.step3NetworkError = '';

        for (const service of this.ipNetworkServices) {
            this.logger.info('Validating services', service);
            this.step3NetworkErrors[index] = '';

            if (!this.validateService.isValidCidrIP(service.gatewayIp)) {
                this.step3NetworkError += 'GatewayIp';
                this.step3NetworkErrors[index] += 'GatewayIp';
                isValid = false;
            }

            if (
                this.validateService.hasValue(service.interceptIp) &&
                !this.validateService.isValidIP(service.interceptIp)
            ) {
                this.step3NetworkError += 'Intercept';
                this.step3NetworkErrors[index] += 'Intercept';
                isValid = false;
            }

            index++;
        }
        return isValid;
    }

    /**
     * Validate step 4 of the form
     */
    validateStep4() {
        // nothing in step 4 is currently required, appwans can be empty
        return true;
    }

    /**
     * Run validation and then save all appwan Feature in a semi-synchronous fashion
     */
    async save() {
        this.step = 2;
        window.scroll(0, 0);

        this.stepStyle = 'step0';

        this.logger.info('Creating dependent services..');
        await this._createServices();
        this.stepStyle = 'step1';

        // save the appwan record
        this.logger.info('Saving appwan...');
        await this._saveAppWan();
        this.stepStyle = 'step2';

        this.logger.info('Assigning groups...');
        await this._assignGroups();
        this.stepStyle = 'step3';

        this.logger.info('Verifying gateways are ready...');
        await this._checkGatewaysReady();
        this.stepStyle = 'step4';

        this.logger.info('Assigning gateways...');
        await this._assignGateways();
        this.stepStyle = 'step5';

        this.logger.info('Verifying clients are ready...');
        await this._checkClientsReady();
        this.stepStyle = 'step6';

        this.logger.info('Assigning clients...');
        await this._assignClients();
        this.stepStyle = 'step7';

        await this._checkServicesReady();
        this.stepStyle = 'step8';

        // save services
        this.logger.info('Assigning services...');
        await this._assignServices();
        this.stepStyle = 'step9';

        this.logger.info('Getting final appwan summary');
        await this._getFinalAppWan();

        this.step = 3;
        window.scroll(0, 0);
    }

    // function for initializing the save process
    async initSave() {
        // running the validation
        const valid = await this.runValidation();

        // if the form is not valid
        if (!valid) {
            // display a growler, bring the form back to the top, cancel the save process
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Validation failed',
                    'Please review and correct form errors before continuing'
                )
            );
            this.step = 1;
            window.scroll(0, 0);
            return;
        }

        // if ziti is enabled
        if (this.zitiEnabledService) {
            // check if there are any ziti conflicts
            this.checkZitiConflicts();
        } else {
            // otherwise, if ziti is not enabled, start the save process
            this.save();
        }
    }

    openZitiNoGatewayWarning() {
        const bulletList = [];
        for (const client of this.zitiClients) {
            bulletList.push(client);
        }

        const data = {
            title: 'No Ziti Bridge Gateway',
            appendId: 'NoZitiBridge',
            subtitle:
                'In order to utilize Ziti clients a Ziti Bridge Gateway needs to be added to the AppWan. This affects the following clients:',
            icon: 'ZitiGateway',
            action: 'OK, Thanks for the heads up, Proceed',
            bulletList: bulletList,
        };
        this.zitiDialogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '340px',
            width: '600px',
            autoFocus: false,
        });
        this.zitiDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // start the save process
                this.save();
            }
        });
    }

    getNonZitiGateways() {
        const nonZitiGateways = [];
        for (const gateway of this.gatewaysSelected) {
            if (gateway.endpointType !== 'ZTGW' && gateway.endpointType !== 'ZTNHGW') {
                nonZitiGateways.push(gateway);
            }
        }
        return nonZitiGateways;
    }

    /**
     * Check the appwan status
     */
    public async checkAppWanStatus() {
        this.logger.info('Checking AppWan Status...');
        return await this.appwanService
            .getResource(this.model.getSelfLink())
            .toPromise()
            .then((appwan) => appwan['status']);
    }

    /**
     * Simple lookup method to translate a gateway ID to a name
     */
    public lookupGatewayName(service) {
        const serviceModel = new Service(service);
        const linkedEndpointId = serviceModel.getLinkedResourceId('endpoint');
        const linkedClusterId = serviceModel.getLinkedResourceId('gatewayCluster');

        const endpointId = linkedClusterId != null ? linkedClusterId : linkedEndpointId;
        for (const gateway of this.gateways) {
            if (endpointId === gateway.id) {
                return gateway.name;
            }
        }

        return '--';
    }

    openService(data) {
        this.dialogInlineRef = this.dialogForm.open(ServicesformComponent, {
            data: { model: data, inline: true },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.dialogInlineRef.afterClosed().subscribe((result) => {
            /// Refresh Screen??
        });
    }

    openGateway(data) {
        this.dialogInlineGWRef = this.dialogForm.open(GatewaydashboardComponent, {
            data: { model: data, inline: true },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.dialogInlineGWRef.afterClosed().subscribe(() => {
            /// Refresh Screen??
        });
    }

    openClient(data) {
        this.dialogInlineCLRef = this.dialogForm.open(ClientdashboardComponent, {
            data: { model: data, inline: true },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.dialogInlineCLRef.afterClosed().subscribe((result) => {
            /// Refresh Screen??
        });
    }

    // function for cloning the recently created appwan
    clone() {
        // setting the name placeholder
        this.namePlaceholder = this.model.name;

        // creating a new appwan model
        this.model = new AppWan({});

        for (const service of this.ipHostServices) {
            service.name = '';
        }

        for (const service of this.ipNetworkServices) {
            service.name = '';
            if (service.gatewayIp != null && service.gatewayCidrBlock != null) {
                service.gatewayIp = service.gatewayIp + '/' + service.gatewayCidrBlock;
            }
        }

        // clearing the service names just to be safe
        for (const service of this.services) {
            service.name = '';
        }
        // setting the selected clients and gateways
        this.clientsSelected = this.finalModel['clients'];
        this.gatewaysSelected = this.finalModel['gateways'];

        // resetting the form
        this.resetForm();
    }

    resetForm() {
        // resetting the variables used to track the progress of the appwan creation
        this.servicesCreated = false;
        this.appwanCreated = false;
        this.servicesAreAssigned = false;
        this.servicesAreReady = false;
        this.groupsAreAssigned = false;
        this.gatewaysAreReady = false;
        this.gatewaysAreAssigned = false;
        this.clientsAreReady = false;
        this.clientsAreAssigned = false;

        this.appwanTimedOut = false;
        this.appwanIsReady = false;
        this.appwanError = false;

        // clearing the errors list
        this.errors = [];

        // resetting the step errors
        this.step1Error = '';
        this.step2Error = '';
        this.step3NetworkError = '';
        this.step3HostError = '';
        this.step4Error = '';

        // resetting the booleans for various errors
        this.gwNameError = false;
        this.gwNameLengthError = false;
        this.gwTypeError = false;
        this.gwRegionError = false;
        this.gwPreNameError = false;
        this.gwPreNameLengthError = false;
        this.gwPreTypeError = false;
        this.gwPreRegionError = false;
        this.clNameError = false;
        this.clNameLengthError = false;
        this.clRegionError = false;
        this.clTypeError = false;

        // resetting the list of step 3 errors
        this.step3HostErrors = [];
        this.step3NetworkErrors = [];

        // resetting the pending gateways/clients lists
        this.clientsPending = [];
        this.gatewaysPending = [];

        // resetting the gateways/clients errors lists
        this.clientsWithError = [];
        this.gatewaysWithError = [];
        this.servicesWithError = [];

        // resetting the gateways/clients ready lists
        this.clientsReady = [];
        this.gatewaysReady = [];

        // resetting the list of services to assign
        this.servicesToAssign = [];

        // resetting the step and step style
        this.step = 1;
        this.stepStyle = 'step0';
    }

    showLauncher(gateway) {
        if (gateway != null) {
            return (
                !this.isGatewayRegistered(gateway) &&
                !this.IsHA(gateway) &&
                (this.IsAWS(gateway) ||
                    (this.IsAzure(gateway) &&
                        !(this.hideLaunchButton && gateway.endpointType === 'AZSGW') &&
                        this.showAzureLauncher(gateway)))
            );
        }
        return false;
    }

    isGatewayRegistered(gateway) {
        if (gateway != null) {
            return gateway['status'] > 300;
        }
        return false;
    }

    changeGatewayType(event) {
        // if the endpoint type hasn't change, do nothing
        if (this.oldEndpointType === this.newPreSelectGateway.endpointType) {
            return;
        }

        // if both the old endpoint type and the new endpoint type  uses the aws regions, don't do anything
        if (
            (this.oldEndpointType === '' || this.oldEndpointType === 'AWSCPEGW' || this.oldEndpointType === 'VCPEGW') &&
            (this.newPreSelectGateway.endpointType === 'AWSCPEGW' || this.newPreSelectGateway.endpointType === 'VCPEGW')
        ) {
            return;
        }

        // if both the old endpoint type and the new endpoint type  uses the azure regions, don't do anything
        if (
            (this.oldEndpointType === 'AZSGW' || this.oldEndpointType === 'AZCPEGW') &&
            (this.newPreSelectGateway.endpointType === 'AZSGW' || this.newPreSelectGateway.endpointType === 'AZCPEGW')
        ) {
            return;
        }

        this.oldEndpointType = this.newPreSelectGateway.endpointType;
        this.newPreSelectGateway.geoRegionId = '';

        switch (this.newPreSelectGateway.endpointType) {
            case 'AWSCPEGW':
            case 'VCPEGW':
                this.regions = this.awsRegions;
                break;

            case 'AZCPEGW':
            case 'AZSGW':
                this.regions = this.azureRegions;
                break;

            case 'GCPCPEGW':
                this.regions = this.googleRegions;
                break;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changeNewGatewayType(event) {
        // if the endpoint type hasn't change, do nothing
        if (this.oldNewEndpointType === this.newGateway.endpointType) {
            return;
        }

        // if both the old endpoint type and the new endpoint type  uses the aws regions, don't do anything
        if (
            (this.oldNewEndpointType === '' ||
                this.oldNewEndpointType === 'AWSCPEGW' ||
                this.oldNewEndpointType === 'VCPEGW' ||
                this.oldEndpointType.includes('ZT')) &&
            (this.newGateway.endpointType === 'AWSCPEGW' ||
                this.newGateway.endpointType === 'VCPEGW' ||
                this.newGateway.endpointType.includes('ZT'))
        ) {
            return;
        }

        // if both the old endpoint type and the new endpoint type  uses the azure regions, don't do anything
        if (
            (this.oldNewEndpointType === 'AZSGW' || this.oldNewEndpointType === 'AZCPEGW') &&
            (this.newGateway.endpointType === 'AZSGW' || this.newGateway.endpointType === 'AZCPEGW')
        ) {
            return;
        }

        this.oldNewEndpointType = this.newGateway.endpointType;
        this.newGateway.geoRegionId = '';

        switch (this.newGateway.endpointType) {
            case 'AWSCPEGW':
            case 'VCPEGW':
            case 'ZTGW':
            case 'ZTNHGW':
                this.newGatewayRegions = this.awsRegions;
                break;

            case 'AZCPEGW':
            case 'AZSGW':
                this.newGatewayRegions = this.azureRegions;
                break;

            case 'GCPCPEGW':
                this.newGatewayRegions = this.googleRegions;
                break;
        }
    }

    private _validateClientAdd(model) {
        // clearing the errors
        this.clNameError = false;
        this.clRegionError = false;
        this.clCountryError = false;
        this.clNameLengthError = false;
        this.clTypeError = false;

        // variable to determine whether the client form is valid
        let isValid = true;

        // if the client type is not CL or ZTCL, marking the clType as an error
        if (model.endpointType !== 'CL' && model.endpointType !== 'ZTCL') {
            this.clTypeError = true;
            isValid = false;
        }

        // checking this a vlaid name was provided
        if (!this.validateService.isValidName(model.name) || model.name.length > 52) {
            this.clNameError = true;
            isValid = false;
        }

        // checking this the name is at least 5 char long
        if (!this.validateService.hasValue(model.name)) {
            this.clNameLengthError = true;
            isValid = false;
        }

        if (!this.validateService.hasValue(model.countryId)) {
            this.clCountryError = true;
            isValid = false;
        }

        // checking this the geoRegionId was set
        if (!this.validateService.hasValue(model.geoRegionId)) {
            this.clRegionError = true;
            isValid = false;
        }

        return isValid;
    }

    private _validateGatewayAdd(model) {
        this.gwNameError = false;
        this.gwTypeError = false;
        this.gwRegionError = false;
        this.countryError = false;
        let isValid = true;

        if (!this.validateService.isValidName(model.name)) {
            this.gwNameError = true;
            isValid = false;
        } else if (model.name.length < 5) {
            this.gwNameLengthError = true;
            isValid = false;
        }

        if (model.endpointType === 'VCPEGW') {
            if (!this.validateService.hasValue(model.countryId)) {
                this.countryError = true;
                isValid = false;
            }
        } else {
            model.countryId = null;
        }

        if (!this.validateService.hasValue(model.geoRegionId)) {
            this.gwRegionError = true;
            isValid = false;
        }

        if (!this.validateService.hasValue(model.endpointType)) {
            this.gwTypeError = true;
            isValid = false;
        }

        return isValid;
    }

    private _validatePreGatewayAdd(model) {
        this.gwPreNameError = false;
        this.gwPreTypeError = false;
        this.gwPreRegionError = false;
        this.gwPreNameLengthError = false;
        this.gwPreCountryError = false;
        let isValid = true;

        if (!this.validateService.isValidName(model.name)) {
            this.gwPreNameError = true;
            isValid = false;
        } else if (model.name.length < 5) {
            this.gwPreNameLengthError = true;
            isValid = false;
        }
        if (model.endpointType === 'VCPEGW') {
            if (!this.validateService.hasValue(model.countryId)) {
                this.gwPreCountryError = true;
                isValid = false;
            }
        } else {
            model.countryId = null;
        }

        if (!this.validateService.hasValue(model.geoRegionId)) {
            this.gwPreRegionError = true;
            isValid = false;
        }

        if (!this.validateService.hasValue(model.endpointType)) {
            this.gwPreTypeError = true;
            isValid = false;
        }

        return isValid;
    }

    // function for checking if there are any ziti conflicts
    private checkZitiConflicts() {
        // getting all the clients the user selected this are of type ZTCL
        this.getZitiClients();

        // getting all services the user is adding of type GW or ICMP
        this.getNetworkAndIcmpServices();

        const gateways = this.getNonZitiGateways();

        // if there is at least one ziti client and at least one IP Network or ICMP service
        if (this.zitiClients.length > 0) {
            let noZitiGateways = false;
            if (gateways.length === this.gatewaysSelected.length) {
                noZitiGateways = true;
            }

            if (this.networkAndIcmpServices.length > 0 || gateways.length > 0) {
                // open up a warning dialog to inform the user this ziti clients won't be able to communicate with IP Network or ICMP services
                this.zitiDialogRef = this.dialogForm.open(WarningListComponent, {
                    data: {
                        isZitiWarning: true,
                        services: this.networkAndIcmpServices,
                        clients: this.zitiClients,
                        gateways: gateways,
                        noZitiGateways: noZitiGateways,
                    },
                    height: '340px',
                    width: '700px',
                    autoFocus: false,
                });

                // wait for the dialog to close
                this.zitiDialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        // start the save process
                        this.save();
                    }
                });
            } else if (this.gatewaysSelected.length === 0) {
                this.openZitiNoGatewayWarning();
            } else {
                this.save();
            }
        } else {
            // if there is no message to display, start the save process
            this.save();
        }
    }

    // function for getting all ziti clients
    private getZitiClients() {
        // clearing the list of any previous clients
        this.zitiClients = [];

        // looping through all the selected clients
        for (const client of this.clientsSelected) {
            // pushing any clients of type ZTCL to the list of ziti clients
            if (client.endpointType === 'ZTCL') {
                this.zitiClients.push(client);
            }
        }
    }

    // function for getting the IP Network and ICMP services the user is adding
    private getNetworkAndIcmpServices() {
        // clearing the list of any previous services
        this.networkAndIcmpServices = [];

        // looping through the list of services
        for (const service of this.services) {
            // if the service is an IP Network or ICMP service, add it to the list
            if (service.serviceClass === 'GW' || service.serviceClass === 'ICMP') {
                this.networkAndIcmpServices.push(service);
            }
        }
    }

    /**
     * Save services as a batch
     */
    private async _createServices() {
        const isGatewayReady = await this._waitGatewayPreselectedReady();

        if (isGatewayReady) {
            // create all services at once but wait until all of those requests complete
            const proms = [];
            let count = 1;
            for (let service of this.services) {
                service = this._processServiceModel(service);
                service.name = `${this.model.name} svc-${count < 10 ? '0' + count : count}`;
                proms.push(
                    this.serviceService
                        .save(service)
                        .toPromise()
                        .then((res) => res)
                );
                count++;
            }

            return await Promise.all(proms).then((vals) => {
                this.logger.info('Service creation responses', vals);

                for (const service of vals) {
                    const model = new Service(service);
                    if (model['id']) {
                        this.logger.info('Adding service ID to array: ', model.id);
                        this.servicesToAssign.push(model);
                    } else {
                        this.logger.error('Unknown service creation response', service);
                        const msg = 'Unknown service creation response';
                        this.errors.push(msg);
                        this.growlerService.show(new GrowlerData('error', 'Error', 'Service creation failed', msg));
                    }
                }

                this.servicesCreated = true;
                return true;
            });
        } else {
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Service creation failed',
                    'The gateway for the service is not in a provisioned state'
                )
            );
        }
    }

    private async _waitGatewayPreselectedReady() {
        const maxIterations = 10;
        let currentIteration = 0;
        let isProvisioned = false;
        while (currentIteration < maxIterations) {
            // check the status on a timer
            const result = await new Promise((resolve) => {
                setTimeout(() => {
                    resolve(this._checkStatusGatewayPreselected());
                }, 1000);
            });

            isProvisioned = result['isProvisioned'];

            if (result['isProvisioned'] || result['hasError']) {
                break;
            }

            currentIteration++;
        }
        return isProvisioned;
    }

    /**
     * Look at the current list of pending gateways and update the list with the items this are in a ready or error state
     */
    private async _checkStatusGatewayPreselected() {
        let preselectedPromise;

        if (this.gatewayPreSelected['endpointProtectionRole'] != null) {
            preselectedPromise = this.clusterService.getResource(this.gatewayPreSelected.getSelfLink()).toPromise();
        } else {
            preselectedPromise = this.gatewayService.getResource(this.gatewayPreSelected.getSelfLink()).toPromise();
        }

        return await preselectedPromise.then((gateway) => {
            // gateway is ready
            if (gateway['status'] === 300 || gateway['status'] === 400) {
                this.logger.info('Gateway: ' + gateway.name + ' is ready to be added to a service');
                return { isProvisioned: true, hasError: false };
                // gateway had an error
            } else if (gateway['status'] >= 500) {
                this.logger.info('Gateway: ' + gateway.name + ' had an error and cannot be added to the service');
                return { isProvisioned: false, hasError: true };
            } else {
                return { isProvisioned: false, hasError: false };
            }
        });
    }

    /**
     * Handle default values and input processing for service models
     */
    private _processServiceModel(service: Service) {
        if (this.validateService.hasValue(this.gatewayPreSelected['protectionType'])) {
            service.gatewayClusterId = this.gatewayPreSelected['id'];
        } else {
            service.endpointId = this.gatewayPreSelected['id'];
        }

        if (service.serviceClass === 'CS') {
            return this._processHostServiceModel(service);
        } else if (service.serviceClass === 'GW') {
            return this._processNetworkServiceModel(service);
        }
    }

    /**
     * Handle default values and input processing for service models
     */
    private _processHostServiceModel(service: Service) {
        service.interceptIp = service.networkIp;

        // splitting the port range on -
        const portRange = service.networkFirstPort.toString().split('-');

        // handle port ranges
        if (portRange.length === 2) {
            // otherwise, if there are two items, using the first item as the first port and the second item as the last port
            service.networkFirstPort = portRange[0].trim();
            service.networkLastPort = portRange[1].trim();
            service.interceptFirstPort = portRange[0].trim();
            service.interceptLastPort = portRange[1].trim();
        } else {
            // if there is only one item in the list (i.e no -) use the same number as the first/last port numbers
            service.networkLastPort = service.networkFirstPort;
            service.interceptFirstPort = service.networkFirstPort;
            service.interceptLastPort = service.networkFirstPort;
        }

        return service;
    }

    private _processNetworkServiceModel(service: Service) {
        const ipAndCidrBlock = service.gatewayIp.split('/');
        service.gatewayIp = ipAndCidrBlock[0];
        if (ipAndCidrBlock.length === 2) {
            service.gatewayCidrBlock = Number(ipAndCidrBlock[1]);
        } else {
            service.gatewayCidrBlock = 0;
        }

        if (!this.validateService.hasValue(service.interceptIp)) {
            service.interceptIp = service.gatewayIp;
        }
        return service;
    }

    /**
     * Save the appwan model so this we can attach stuff to it
     */
    private async _saveAppWan() {
        return await this.appwanService
            .save(this.model)
            .toPromise()
            .then(
                (appwan) => {
                    this.model = new AppWan(appwan);
                    this.appwanCreated = true;
                    this.logger.info('Appwan is saved!');
                    return true;
                },
                (error) => {
                    const msg = 'AppWan creation failed, check the console for further details';
                    this.errors.push(msg);
                    this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Failed', msg));
                    this.logger.error('AppWan creation failed', error);
                    return false;
                }
            );
    }

    /**
     * The batch service add will fail if any of the services are not in a ready state, make sure they are all ready before proceeding
     */
    private async _checkServicesReady() {
        this.servicesPending = this.servicesToAssign.concat([]);
        this.servicesToAssign = [];

        const maxIterations = 10;
        let currentIteration = 0;

        this.logger.info('Services pending: ', this.servicesPending);
        while (this.servicesPending.length > 0 && currentIteration < maxIterations) {
            this.logger.info('Check Pending Service Status Iteration: ' + currentIteration);
            this.logger.info('Pending Services: ' + this.servicesPending.length);

            // check the status on a timer
            const result = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(this._processPendingServices());
                }, 1000);
            });

            currentIteration++;
        }

        // pop a growler if some of the clients couldn't be added
        if (this.servicesPending.length > 0 || this.servicesWithError.length > 0) {
            // push the endpoints this timed out into the error list
            const failedNames = [];
            for (const service of this.servicesPending) {
                failedNames.push(service.name);
            }

            for (const service of this.servicesWithError) {
                failedNames.push(service.name);
            }

            // error growler for now
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Problem Validating Services',
                    'The following services were unable to be added to the appwan: ' + failedNames
                )
            );
        }

        this.servicesAreReady = true;

        return true;
    }

    /**
     * Attach services to the appwan
     */
    private async _assignServices() {
        // check the status on a timer
        const promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                this.logger.info('forcing a wait in case transfer nodes are building');
                resolve(true);
            }, 5000);
        });

        await promise;

        const isReady = await this._awaitAppWanReadyStatus();

        const serviceIds = [];
        for (const service of this.servicesToAssign) {
            if (this.validateService.hasValue(service.id)) {
                serviceIds.push(service.id);
            }
        }

        if (isReady) {
            return await this.appwanService
                .addServices(this.model, serviceIds)
                .toPromise()
                .then(
                    (res) => {
                        this.logger.info('Services are assigned', res);
                        this.servicesAreAssigned = true;
                        return true;
                    },
                    (error) => {
                        const msg = 'An error occurred while assigning services. AppWan creation aborted';
                        this.errors.push(msg);
                        this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Failed', msg));
                        this.logger.error('Service creation failed', error);
                        return false;
                    }
                );
        } else {
            const msg =
                'Appwan timed out or errored out before assigning services. Not ready for to have resources added';
            this.errors.push(msg);
            this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Timed Out', msg));
            return false;
        }
    }

    /**
     * Attach groups to the appwan
     */
    private async _assignGroups() {
        const groupIds = [];
        for (const group of this.groupsSelected) {
            groupIds.push(group.id);
        }

        // bail if there are no groups
        if (groupIds.length === 0) {
            this.groupsAreAssigned = true;
            return true;
        }

        const isReady = await this._awaitAppWanReadyStatus();

        if (isReady) {
            return await this.appwanService
                .addGroups(this.model, groupIds)
                .toPromise()
                .then((res) => {
                    this.logger.info('Groups are assigned', res);
                    this.groupsAreAssigned = true;
                    return true;
                });
        } else {
            const msg =
                'Appwan timed out or errored out before assigning groups. Not ready for to have resources added';
            this.errors.push(msg);
            this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Timed Out', msg));
            return false;
        }
    }

    /**
     * Attach gateways to the appwan
     */
    private async _assignGateways() {
        const gatewayIds = [];
        for (const gateway of this.gatewaysReady) {
            gatewayIds.push(gateway.id);
        }

        // bail if there are no gateways
        if (gatewayIds.length === 0) {
            this.gatewaysAreAssigned = true;
            return true;
        }

        const isReady = await this._awaitAppWanReadyStatus();

        if (isReady) {
            return await this.appwanService
                .addEndpoints(this.model, gatewayIds)
                .toPromise()
                .then((res) => {
                    this.logger.info('Gateways assigned', res);
                    this.gatewaysAreAssigned = true;
                    return true;
                });
        } else {
            const msg =
                'Appwan timed out or errored out before assigning gateways. Not ready for to have resources added';
            this.errors.push(msg);
            this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Timed Out', msg));
            return false;
        }
    }

    /**
     * Attach clients to the appwan
     */
    private async _assignClients() {
        const clientIds = [];
        for (const client of this.clientsReady) {
            this.sendto[clientIds.length] = '';
            this.sendingState[clientIds.length] = '';
            clientIds.push(client.id);
        }

        // bail if there are no clients to add
        if (clientIds.length === 0) {
            this.clientsAreAssigned = true;
            return true;
        }

        const isReady = await this._awaitAppWanReadyStatus();

        if (isReady) {
            return await this.appwanService
                .addEndpoints(this.model, clientIds)
                .toPromise()
                .then((res) => {
                    this.logger.info('Clients assigned', res);
                    this.clientsAreAssigned = true;
                    return true;
                });
        } else {
            const msg =
                'Appwan timed out or errored out before assigning clients. Not ready for to have resources added';
            this.errors.push(msg);
            this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Timed Out', msg));
            return false;
        }
    }

    /**
     * Build the finalized appwan model this actually made it into the backend. Expand all of the resource links so we know what's in it
     */
    private async _getFinalAppWan() {
        // get the base model
        this.finalModel = await this.appwanService
            .getResource(this.model.getSelfLink())
            .toPromise()
            .then((appwan) => new AppWan(appwan));

        // batch retrieve all of the child resources
        const proms = [];

        // The next three if statements are a quick and dirty solution to a messy issue introduced by permissioning
        // it is possible this a user may not have the ability to list services, endpoint groups, or endpoints but they may have permission to create them
        // in this (probably rare) scenario, the console should not make a GET request for the services,groups, or endpoints (based on the user's permission)
        // not having all three of the promises would then break the final aggregate promise as it gets the results from the individual promises based on the index

        if (this.authorizationService.canListServices()) {
            proms.push(
                this.appwanService
                    .getLinkedResources(this.finalModel, 'services')
                    .toPromise()
                    .then((services) => services)
            );
        } else {
            proms.push(
                new Promise((resolve, reject) => {
                    resolve([]);
                })
            );
        }

        if (this.canListGroups) {
            proms.push(
                this.appwanService
                    .getLinkedResources(this.finalModel, 'endpointGroups')
                    .toPromise()
                    .then((groups) => groups)
            );
        } else {
            proms.push(
                new Promise((resolve) => {
                    resolve([]);
                })
            );
        }

        if (this.canListEndpoints) {
            proms.push(
                this.appwanService
                    .getLinkedResources(this.finalModel, 'endpoints')
                    .toPromise()
                    .then((endpoints) => endpoints)
            );
        } else {
            proms.push(
                new Promise((resolve, reject) => {
                    resolve([]);
                })
            );
        }

        return await Promise.all(proms).then((values) => {
            const services = values[0];
            const groups = values[1];
            const endpoints = values[2];

            this.finalModel['services'] = services;
            this.finalModel['endpointGroups'] = groups;
            this.finalModel['clients'] = [];
            this.finalModel['gateways'] = [];
            for (const endpoint of endpoints) {
                if (endpoint.endpointType === 'CL' || endpoint.endpointType === 'ZTCL') {
                    if (endpoint['registrationKey'] !== null) {
                        this.hasNewClients = true;
                    }
                    this.finalModel['clients'].push(endpoint);
                } else {
                    this.finalModel['gateways'].push(endpoint);
                }
            }

            // get the hosting gateway model
            if (services.length > 0) {
                const service = new Service(services[0]);
                const linkedEndpointId = service.getLinkedResourceId('endpoint');
                const linkedClusterId = service.getLinkedResourceId('gatewayCluster');

                const endpointId = linkedClusterId != null ? linkedClusterId : linkedEndpointId;
                for (const gateway of this.gateways) {
                    if (gateway.getId() === endpointId) {
                        this.finalModel['hostingGateway'] = gateway;
                        this.finalModel['hostingGatewayName'] = gateway['name'];
                        break;
                    }
                }
            }

            return true;
        });
    }

    /**
     * AppWan status checker on a timer. Loops until appwan is ready or it times out
     */
    private async _awaitAppWanReadyStatus() {
        // max number of iterations
        const maxIterations = 60;
        // current iteration
        let currentIteration = 0;

        const timeout = 1000;

        // if we're calling this function just assume this the appWan is not ready until we've checked it again
        this.appwanIsReady = false;

        // looping until the appwan either becomes active or the max iterations is hit
        while (this.appwanIsReady === false && currentIteration < maxIterations) {
            this.logger.info('Check AppWan Status Iteration: ' + currentIteration);

            // check the status on a timer
            const promise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    // getting the AppWan status
                    resolve(this.checkAppWanStatus());
                }, timeout);
            });

            // getting the status after the promise is resolved
            const status = await promise;
            this.logger.info('AppWan Status is: ' + status);
            // if the status is 300, mark isFinished as true and isActive as true
            if (status === 300) {
                this.appwanIsReady = true;
                break;
                // if the status is either in error or the appwan is deleting, mark isFinished as true and isActive as false
            } else if (status === 500 || (status as number) >= 700) {
                this.appwanError = true;
            }

            currentIteration++;
        }

        if (currentIteration === maxIterations) {
            this.appwanTimedOut = true;
            const msg = 'AppWan Creation Appears to have timed out!';
            this.errors.push(msg);
            this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Timed Out', msg));
            return false;
        }

        if (this.appwanError) {
            const msg = 'AppWan Creation failed with an error, check the console';
            this.errors.push(msg);
            this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Failed', msg));
            return false;
        }

        this.logger.info('AppWan is in Ready status');
        return true;
    }

    /**
     * The batch client add will fail if any of the clients are not in a ready state, make sure they are all ready before proceeding
     */
    private async _checkClientsReady() {
        this.clientsPending = this.clientsSelected;

        const maxIterations = 10;
        let currentIteration = 0;

        this.logger.info('Clients pending: ', this.clientsPending);
        while (this.clientsPending.length > 0 && currentIteration < maxIterations) {
            this.logger.info('Check Pending Client Status Iteration: ' + currentIteration);
            this.logger.info('Pending clients: ' + this.clientsPending.length);

            // check the status on a timer
            const result = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(this._processPendingClients());
                }, 1000);
            });

            currentIteration++;
        }

        // pop a growler if some of the clients couldn't be added
        if (this.clientsPending.length > 0) {
            // push the endpoints this timed out into the error list
            const failedNames = [];
            for (const client of this.clientsPending) {
                this.clientsWithError.push(client);
                failedNames.push(client.name);
            }

            // error growler for now
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Problem Validating Clients',
                    'The following clients were unable to be added to the appwan: ' + failedNames
                )
            );
        }

        this.clientsAreReady = true;

        return true;
    }

    /**
     * Look at the current list of pending clients and update the list with the items this are in a ready or error state
     */
    private async _processPendingServices() {
        // check all clients at once
        const proms = [];
        for (let service of this.servicesPending) {
            service = new Service(service);
            proms.push(
                this.serviceService
                    .getResource(service.getSelfLink())
                    .toPromise()
                    .then((res) => res)
            );
        }

        return await Promise.all(proms).then((vals) => {
            this.logger.info('Service readiness responses', vals);
            for (let service of vals) {
                service = new Service(service);

                // client is ready
                if (service['status'] === 300) {
                    this.servicesToAssign.push(service);
                    this.logger.info('Service: ' + service.name + ' is ready to be added');
                    this._deleteServicePending(service);

                    // client had an error
                } else if (service['status'] >= 500) {
                    this.logger.info('Service: ' + service.name + ' had an error and cannot be added');
                    this.servicesWithError.push(service);
                    this._deleteServicePending(service);
                } else {
                    // do nothing if the client is less than 300, it's still building
                    this.logger.info('Pending', this.servicesPending);
                }
            }
        });
    }

    /**
     * Look at the current list of pending clients and update the list with the items this are in a ready or error state
     */
    private async _processPendingClients() {
        // check all clients at once
        const proms = [];
        for (let client of this.clientsPending) {
            client = new Endpoint(client);
            proms.push(
                this.clientService
                    .getResource(client.getSelfLink())
                    .toPromise()
                    .then((res) => res)
            );
        }

        return await Promise.all(proms).then((vals) => {
            this.logger.info('Client readiness responses', vals);
            for (let client of vals) {
                client = new Endpoint(client);

                // client is ready
                if (client['status'] === 300 || client['status'] === 400) {
                    this.clientsReady.push(client);
                    this.logger.info('Client: ' + client.name + ' is ready to be added');
                    this._deleteClientPending(client);

                    // client had an error
                } else if (client['status'] >= 500) {
                    this.logger.info('Client: ' + client.name + ' had an error and cannot be added');
                    this.clientsWithError.push(client);
                    this._deleteClientPending(client);
                } else {
                    // do nothing if the client is less than 300, it's still building
                    this.logger.info('Pending', this.clientsPending);
                }
            }
        });
    }

    /**
     * Purge the pending client from the list so we don't keep on looping
     */
    private _deleteClientPending(client) {
        for (let i = 0; i < this.clientsPending.length; i++) {
            const pending = this.clientsPending[i];
            if (pending.name === client.name) {
                this.clientsPending.splice(i, 1);
                break;
            }
        }
    }

    /**
     * Purge the pending service from the list so we don't keep on looping
     */
    private _deleteServicePending(service) {
        for (let i = 0; i < this.servicesPending.length; i++) {
            const pending = this.servicesPending[i];
            if (pending.name === service.name) {
                this.servicesPending.splice(i, 1);
                break;
            }
        }
    }

    /**
     * The batch gateway add will fail if any of the gateways are not in a ready state, make sure they are all ready before proceeding
     */
    private async _checkGatewaysReady() {
        this.gatewaysPending = this.gatewaysSelected;

        const maxIterations = 10;
        let currentIteration = 0;

        this.logger.info('Gateways pending: ', this.gatewaysPending);
        while (this.gatewaysPending.length > 0 && currentIteration < maxIterations) {
            this.logger.info('Check Pending Gateway Status Iteration: ' + currentIteration);
            this.logger.info('Pending gateways: ' + this.gatewaysPending.length);

            // check the status on a timer
            const result = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(this._processPendingGateways());
                }, 1000);
            });

            currentIteration++;
        }

        // pop a growler if some of the gateways couldn't be added
        if (this.gatewaysPending.length > 0) {
            // push the endpoints this timed out into the error list
            const failedNames = [];
            for (const gateway of this.gatewaysPending) {
                this.gatewaysWithError.push(gateway);
                failedNames.push(gateway.name);
            }

            // error growler for now
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Problem Validating Gateways',
                    'The following gateways were unable to be added to the appwan: ' + failedNames
                )
            );
        }

        this.gatewaysAreReady = true;

        return true;
    }

    /**
     * Look at the current list of pending gateways and update the list with the items this are in a ready or error state
     */
    private async _processPendingGateways() {
        // check all gateways at once
        const proms = [];
        for (let gateway of this.gatewaysPending) {
            gateway = new Endpoint(gateway);
            proms.push(
                this.gatewayService
                    .getResource(gateway.getSelfLink())
                    .toPromise()
                    .then((res) => res)
            );
        }

        return await Promise.all(proms).then((vals) => {
            this.logger.info('Gateway readiness responses', vals);

            for (let gateway of vals) {
                gateway = new Endpoint(gateway);

                // gateway is ready
                if (gateway['status'] === 300 || gateway['status'] === 400) {
                    this.gatewaysReady.push(gateway);
                    this.logger.info('Gateway: ' + gateway.name + ' is ready to be added');
                    this._deleteGatewayPending(gateway);

                    // gateway had an error
                } else if (gateway['status'] >= 500) {
                    this.logger.info('Gateway: ' + gateway.name + ' had an error and cannot be added');
                    this.gatewaysWithError.push(gateway);
                    this._deleteGatewayPending(gateway);
                } else {
                    // do nothing if the gateway is less than 300, it's still building
                    this.logger.info('Pending', this.gatewaysPending);
                }
            }
        });
    }

    /**
     * Purge the pending gateway from the list so we don't keep on looping
     */
    private _deleteGatewayPending(gateway) {
        for (let i = 0; i < this.gatewaysPending.length; i++) {
            const pending = this.gatewaysPending[i];
            if (pending.name === gateway.name) {
                this.gatewaysPending.splice(i, 1);
                break;
            }
        }
    }
}
