import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiNoitemsModule } from '@netfoundry-ui/ui/noitems';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { NetworkMetricsEventsDashComponent } from './network-metrics-events-dash.component';
import {ScrollingModule} from "@angular/cdk/scrolling";

@NgModule({
    imports: [CommonModule, UiNoitemsModule, UiLoaderModule, ScrollingModule],
    declarations: [NetworkMetricsEventsDashComponent],
    exports: [NetworkMetricsEventsDashComponent],
})
export class FeatureNetworkMetricsEventsDashModule {}
