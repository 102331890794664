import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textSearch',
})
export class TextSearchPipe implements PipeTransform {
    transform(items: any[], term: string, value: string): any {
        if (!items) {
            return [];
        }

        if (!value || value === '') {
            return items;
        }

        const searchText = value.toLowerCase();
        return items.filter((it) => it[term].toLowerCase().includes(searchText));
    }
}
