import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { SharedApiv2Module } from '@netfoundry-ui/shared/apiv2';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HighlightModule } from 'ngx-highlightjs';
import { JsonViewComponent } from './json-view.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatButtonModule,
        MatCardModule,
        MatCardModule,
        MatCardModule,
        SharedApiv2Module,
        MatDialogModule,
        MatTooltipModule,
        HighlightModule,
    ],
    declarations: [JsonViewComponent],
    exports: [JsonViewComponent],
})
export class FeatureJsonViewModule {}
