import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PagedAttributes, SimpleAttribute } from '@netfoundry-ui/shared/model';

@Component({
    selector: 'app-tags-selector',
    templateUrl: './tags-selector.component.html',
    styleUrls: ['./tags-selector.component.scss'],
})
export class TagsSelectorComponent {
    _availableAttributesMap = new Map<string, SimpleAttribute>();
    _availableAttributeArray: SimpleAttribute[] = [];
    _availableKeys: string[] = [];
    _selectedAttributeArray: SimpleAttribute[] = [];
    @Input() placeholder = 'Select';
    @Input() disableField = false;
    @Input() disableCreate = false;
    @Input() hideOption = '';
    @Input() isLoading = false;
    @Input() errorMessage = '';
    @Output() addAttribute = new EventEmitter<any>();
    @Output() removeAttribute = new EventEmitter<any>();
    hideSelect = true;
    displayedOptions: any[] = [];
    filterString = '';
    _hashAttributes = new PagedAttributes();

    constructor() {}

    _selectedAttributes = new Map<string, SimpleAttribute>();

    @Input() set selectedAttributes(tags: PagedAttributes) {
        if (tags) {
            this._selectedAttributes = tags.mappedAtrributes;
            this._selectedAttributeArray = Array.from(tags.mappedAtrributes.values()).sort(this.nameSort);
            this._filterAttributes();
        }
    }

    _hasError = false;

    @Input() set hasError(value: boolean) {
        this._hasError = value;
    }

    @Input() set availableAttributes(tags: PagedAttributes) {
        if (tags) {
            this._availableAttributesMap = tags.mappedAtrributes;
            this._availableAttributeArray = Array.from(tags.mappedAtrributes.values()).sort(this.nameSort);
            this._availableKeys = Array.from(tags.mappedAtrributes.keys()).sort();
            this._filterAttributes();
        }
    }

    isSelected(item: SimpleAttribute) {
        return this._selectedAttributes.has(item.name);
    }

    addSelected(item: SimpleAttribute) {
        if (!this._selectedAttributes.has(item.name)) {
            this.addAttribute.emit(item.name);
            this.hide();
        }
    }

    remove(item: SimpleAttribute) {
        if (!this.disableField) {
            this.removeAttribute.emit(item.name);
            this.hide();
        }
    }

    inputFocus() {
        this.hideSelect = false;
    }

    inputFocusOut() {
        this.hideSelect = true;
    }

    toggleSelect() {
        if (!this.disableField) {
            this.hideSelect = !this.hideSelect;
        }
    }

    openSelect() {
        if (!this.disableField) {
            this.hideSelect = false;
        }
    }

    hide() {
        this.hideSelect = true;
    }

    handleKeyUp($event: KeyboardEvent, filterString: string) {
        switch ($event.key) {
            case 'Enter':
                this.addNewAttribute(filterString);
                break;
            default:
                this.applyFilter();
        }
    }

    handleKeyDown($event: KeyboardEvent) {
        switch ($event.key) {
            case 'Tab':
                this.toggleSelect();
                this.filterString = '';
                break;
            default:
        }
    }

    applyFilter = () => {
        this._hasError = false;
        this._filterAttributes();
    };

    // update the filter on mouse up event
    // this is used for the specific case where the user clears the filter via the x button
    applyFilterMouseUp() {
        setTimeout(() => {
            if (this.filterString === '') {
                this._filterAttributes();
            }
        }, 50);
    }

    _filterAttributes() {
        let filteredAvailableAttributes: SimpleAttribute[];
        if (this.filterString) {
            filteredAvailableAttributes = [];
            for (const key of this._availableKeys) {
                if (key.indexOf(this.filterString) >= 0) {
                    filteredAvailableAttributes.push(this._availableAttributesMap.get(key) as SimpleAttribute);
                }
            }
        } else {
            filteredAvailableAttributes = this._availableAttributeArray;
        }

        this.displayedOptions = filteredAvailableAttributes;
    }

    addNewAttribute(attribute: string) {
        if (!this.disableCreate) {
            this._hasError = false;
            if (attribute !== '') {
                let reference = attribute.charAt(0);

                if (reference === '#' || reference === '@') {
                    attribute = attribute.substr(1);
                    // TODO remove when @ is supported
                    reference = '#';
                } else {
                    reference = '#';
                }

                attribute = attribute.trim();
                attribute = `${reference}${attribute}`;


                if (this._availableAttributesMap.has(attribute)) {
                    const option = this._availableAttributesMap.get(attribute);
                    this.addSelected(option as SimpleAttribute);
                } else {
                    if (this._selectedAttributes.has(attribute)) {
                        this.filterString = '';
                        return;
                    }
                }

                this.addAttribute.emit(attribute);
            }
            this.filterString = '';
            this.hide();
        }
    }

    exists(filterString: string) {
        for (const key in this._availableKeys) {
            if (key.indexOf(filterString) >= 0) return true;
        }
        return false;
    }

    identify(index: any, item: SimpleAttribute) {
        return item.name;
    }

    nameSort = (a: SimpleAttribute, b: SimpleAttribute) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
}
