import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AwsAutoscalingFormComponent } from '@netfoundry-ui/feature/form/aws-autoscaling-form';
import { NetworkGroupFormComponent } from '@netfoundry-ui/feature/form/network-group-form';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Environment, ENVIRONMENT, Network, NetworkGroup } from '@netfoundry-ui/shared/model';
import { ApiService, NetworkGroupService, RefresherService } from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { Subscription } from 'rxjs';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';

const deletingStatus = 800;

const columns = ['name', 'awsAutoScalingGatewayEnabled', 'createdAt', 'updatedAt'];
const filename = 'network-groups';
const translateStatus = true;

@Component({
    selector: 'app-network-groups',
    templateUrl: './network-groups.component.html',
    styleUrls: ['./network-groups.component.scss'],
})
export class NetworkGroupsComponent implements OnInit, OnDestroy {
    typeName = 'Network';
    items = [];
    displayedItems = [];
    itemCount = 0;
    showList = false;
    isLoading = true;
    allToggled = false;
    sorting = 'name';
    ordering = 'asc';
    filterString = '';
    public selectedNetworkGroupIds = [];
    public hasSomeDeletePermission = false;
    updatedNetworkGroupId;
    updatedNetworkGroupName;
    dialogRef;
    isDeleteAction = false;
    currentOrg = new NetworkGroup({});
    page = 1;
    pageSize = this.environment.pageSize;
    totalElements = 0;
    openIndex = -1;
    private deleting = 0;
    private subscription = new Subscription();
    private networkGroupSubscription = new Subscription();
    private setNetworkGroupAfterDelete = false;
    private deletedNetworkGroupIds = [];
    private inDeletingStatusCount = 0;
    private noDeleteAuthCount = 0;

    constructor(
        private networkGroupService: NetworkGroupService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private apiService: ApiService,
        public dialogForm: MatDialog,
        private refresher: RefresherService,
        public authorizationService: AuthorizationService,
        public filterService: TableFilterService,
        private csvDownloadService: CsvDownloadService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    ngOnInit() {
        // subscribing to the filter service to handle the change in page
        this.subscription.add(
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.page = pageNum;
            })
        );

        // subscribing to the filter service to handle search filter changes
        this.subscription.add(
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.filterString = filterString;
                this.applyFilter();
            })
        );

        // if it's the first time the org sub gets a result, we want to call initNetworkGroupSub even if networkGroupSubscription.closed is false
        this.networkGroupSubscription.closed = true;
        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrg = new NetworkGroup(org);
                if (this.authorizationService.canListNetworks()) {
                    if (this.networkGroupSubscription.closed) {
                        this.initNetworkGroupSub();
                    } else {
                        this.networkGroupService.get();
                    }
                } else {
                    this.networkGroupSubscription.unsubscribe();
                    this.isLoading = false;
                    this.showList = false;
                }
            })
        );
    }

    applyFilter() {
        this.displayedItems = this.filterService.applyLocalFilter(this.items);
        const newSelectedNetworkGroupIds = [];
        for (const networkGroup of this.displayedItems) {
            if (
                (this.selectedNetworkGroupIds.indexOf(networkGroup.id) > -1 || this.allToggled) &&
                networkGroup.status <= 600
            ) {
                newSelectedNetworkGroupIds.push(networkGroup.id);
                networkGroup.selected = true;
            } else {
                networkGroup.selected = false;
            }
        }
        this.selectedNetworkGroupIds = newSelectedNetworkGroupIds;
        this.isDeleteAction = this.anyToggled();
    }

    ngOnDestroy() {
        this.refresher.disableRefresh();
        this.subscription.unsubscribe();
        this.networkGroupSubscription.unsubscribe();
        this.filterService.reset();
    }

    refresh() {
        this.refresher.disableRefresh();
        if (this.authorizationService.canListNetworks()) {
            this.networkGroupService.get();
        }
        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
    }

    toggleAll() {
        this.allToggled = !this.allToggled;
        this.selectedNetworkGroupIds = [];

        for (const item of this.displayedItems) {
            if (this.authorizationService.canDeleteOrganization(item.id)) {
                item.selected = this.allToggled;
                if (this.allToggled) {
                    this.selectedNetworkGroupIds.push(item.id);
                }
            }
        }

        this.isDeleteAction = this.anyToggled();
    }

    open(networkGroup: NetworkGroup) {
        this.dialogRef = this.dialogForm.open(NetworkGroupFormComponent, {
            data: { model: networkGroup },
            height: '500px',
            width: '600px',
            autoFocus: false,
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result != null && result['loggingOut'] === undefined && result.networkGroup) {
                this.updatedNetworkGroupId = networkGroup.id;
                this.updatedNetworkGroupName = result.networkGroup.name;

                if (this.currentOrg.id === this.updatedNetworkGroupId) {
                    this.apiService.setCurrentOrg(new NetworkGroup(result.networkGroup));
                } else {
                    this.refresh();
                }
            }
        });
    }

    async getDeletingTotal() {
        const names = [];

        let deleteString = 'Are you sure you would like to delete the following Network Group:';
        let afterListString = 'This action cannot be undone.';
        const networkNames = [];

        for (const networkGroup of this.displayedItems) {
            if (networkGroup.selected) {
                names.push(networkGroup);
                await this.networkServiceV2
                    .findByNetworkGroupId(networkGroup.id)
                    .toPromise()
                    .then((result) => {
                        if (result?.resources.length > 0) {
                            deleteString = 'Unable to delete the following Network Group as it still contains ';
                            afterListString =
                                'If you are sure you want to delete the above Network Group please delete the following ';

                            for (const network of result.resources) {
                                networkNames.push(network.name);
                            }

                            if (networkNames.length > 1) {
                                deleteString += 'Networks:';
                                afterListString += `${networkNames.length} Networks first:`;
                            } else {
                                deleteString += 'a Network';
                                afterListString += 'Network first:';
                            }
                        }
                    });
            }
        }

        return {
            deleteString: deleteString,
            afterListString: afterListString,
            names: names,
            networkNames: networkNames,
        };
    }

    toggle(item: NetworkGroup) {
        if (this.authorizationService.canDeleteOrganization(item.id)) {
            item.selected = !item.selected;

            if (this.allToggled) {
                this.allToggled = !this.allToggled;
            }

            const index = this.selectedNetworkGroupIds.indexOf(item.id);
            if (index > -1) {
                this.selectedNetworkGroupIds.splice(index, 1);
            } else {
                this.selectedNetworkGroupIds.push(item.id);
            }
            this.isDeleteAction = this.anyToggled();
        }
    }

    anyToggled() {
        if (this.selectedNetworkGroupIds.length > 0) {
            if (
                this.selectedNetworkGroupIds.length ===
                this.itemCount - this.inDeletingStatusCount - this.noDeleteAuthCount
            ) {
                this.allToggled = true;
            }
            return true;
        }
        this.allToggled = false;
        return false;
    }

    toggleMenu(event, index) {
        event.stopPropagation();
        if (index !== this.openIndex) {
            this.openIndex = index;
        } else {
            this.openIndex = -1;
        }
    }

    async updatePermissions() {
        await this.authorizationService.getAuthorization();
        this.refresh();
    }

    async openConfirm(deleteEvent) {
        if (deleteEvent) {
            const deletingInfo = await this.getDeletingTotal();

            const isDestructive = deletingInfo.networkNames.length === 0;
            const action = deletingInfo.networkNames.length === 0 ? 'Yes' : 'OK';
            const data = {
                title: 'Delete',
                appendId: 'NetworkGroups',
                subtitle: deletingInfo.deleteString,
                afterListText: deletingInfo.afterListString,
                bulletList: deletingInfo.names,
                secondBulletList: deletingInfo.networkNames,
                secondBulletListOnClick: this.openNetworkPage,
                icon: 'DestructiveDelete',
                action: action,
                isDestructive: isDestructive,
                itemName: deletingInfo.names[0].name,
                context: this,
            };

            this.dialogRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            this.dialogRef.afterClosed().subscribe((result) => {
                // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                //  logged out of the console and we should close the dialog without continuing
                if (deletingInfo.networkNames.length > 0 || result === undefined) {
                    this.confirmed(false);
                } else if (result['loggingOut'] === undefined) {
                    this.confirmed(result);
                }
            });
        }
    }

    confirmDelete(item: NetworkGroup) {
        this.toggleAll();
        if (this.allToggled) {
            this.toggleAll();
        }

        item.selected = true;
        const index = this.selectedNetworkGroupIds.indexOf(item.id);
        if (index === -1) {
            this.selectedNetworkGroupIds.push(item.id);
        }
        this.isDeleteAction = true;
        this.openConfirm(true);
    }

    confirmed(event) {
        if (event) {
            this.refresher.disableRefresh();
            this.isLoading = true;
            this.deleting = this.getTotalSelected();
            for (let i = 0; i < this.displayedItems.length; i++) {
                if (this.displayedItems[i].selected) {
                    this.subscription.add(
                        this.networkGroupService.delete(this.displayedItems[i]).subscribe(
                            () => {
                                if (this.displayedItems[i].id === this.currentOrg.id) {
                                    this.setNetworkGroupAfterDelete = true;
                                }
                                // forcing the deleted items to become untoggled
                                this.toggle(this.displayedItems[i]);

                                // if the delete was successful, add the id of the current client to the list of clients to be in deleting state
                                this.deletedNetworkGroupIds.push(this.displayedItems[i].id);
                                this.deleting--;
                                if (this.deleting === 0) {
                                    this.isLoading = true;
                                    this.refresh();
                                }
                            },
                            () => {
                                // forcing the deleted items to become untoggled
                                this.toggle(this.displayedItems[i]);
                            }
                        )
                    );
                }
            }
        } else {
            this.isDeleteAction = false;
            for (const network of this.items) {
                network.selected = false;
            }
            this.allToggled = false;
            this.selectedNetworkGroupIds = [];
        }
    }

    getTotalSelected() {
        return this.selectedNetworkGroupIds.length;
    }

    public delete(network) {
        this.networkGroupService.delete(network);
    }

    closeActionMenu() {
        this.openIndex = -1;
    }

    sort(sortBy) {
        if (this.sorting === sortBy) {
            if (this.ordering === 'asc') {
                this.ordering = 'desc';
            } else {
                this.ordering = 'asc';
            }
        } else {
            this.ordering = 'asc';
            this.sorting = sortBy;
        }
    }

    getSortClass(id) {
        if (id === this.sorting) {
            return this.ordering;
        } else {
            return '';
        }
    }

    trackById(index, item) {
        return item.id;
    }

    openNetworkPage(network: Network) {
        window.open(`${window.location.origin}/networks?networkGroupId=${network.organizationId}`, '_blank');
    }

    buildNetworkSearchString(item) {
        return item.name;
    }

    editAutoScaling(networkGroup) {
        this.dialogRef = this.dialogForm.open(AwsAutoscalingFormComponent, {
            data: { model: networkGroup },
            height: '270px',
            width: '600px',
            autoFocus: false,
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result == null || result['loggingOut'] === undefined) {
                if (result && result.networkGroup && this.currentOrg.id === networkGroup.id) {
                    this.apiService.setCurrentOrg(new NetworkGroup(result.networkGroup));
                } else {
                    this.refresh();
                }
            }
        });
    }

    /**
     * function for setting a new network if the previously selected network was on the network group that was just deleted
     */
    async setNewNetworkGroup() {
        // looping through the list of network groups
        for (const networkGroup of this.items) {
            // if the network group is not in a deleting status
            if (networkGroup.status !== deletingStatus) {
                // get the first network in the group
                const success = await this.apiService
                    .getLinkedResource(networkGroup, 'networks', { page: 1, size: 1 })
                    .toPromise()
                    .then((result) => {
                        // if there is at least one network in the group
                        if (result['page']['totalElements'] > 0) {
                            // set the current network
                            const networkToSet = result['_embedded'].networks[0];
                            this.apiService.setCurrentNetwork(new Network(networkToSet));
                            return true;
                        }
                    });

                if (success) {
                    break;
                }
            }
        }
    }

    downloadCsv(event: any) {
        const csvItems = [];

        for (const item of this.displayedItems) {
            if (item.selected) {
                csvItems.push(item);
            }
        }

        this.toggleMenu(event, -2);
        this.csvDownloadService.download(filename, csvItems, columns, translateStatus);
    }

    private initNetworkGroupSub() {
        this.networkGroupSubscription = this.networkGroupService.get().subscribe((result) => {
            this.isDeleteAction = false;
            this.inDeletingStatusCount = 0;
            this.noDeleteAuthCount = 0;
            const theResult = result as NetworkGroup[];
            for (const networkGroup of theResult) {
                // if the networkgroup was just deleted, set the status to 800 as the status won't be updated the first refresh
                // this may not be necessary since network groups don't have a status. If they delete fast enough then this is not necessary
                if (this.deletedNetworkGroupIds.indexOf(networkGroup.id) > -1) {
                    networkGroup.status = deletingStatus;
                }

                if (networkGroup.status === deletingStatus) {
                    this.inDeletingStatusCount++;
                }

                // if the current network group was just renamed, update the name locally as it may take a second or two for the mop to reflect the change
                if (networkGroup.id === this.updatedNetworkGroupId) {
                    networkGroup.name = this.updatedNetworkGroupName;
                }

                const actions = [];

                if (this.authorizationService.canDeleteOrganization(networkGroup.id)) {
                    actions.push('delete');
                    this.hasSomeDeletePermission = true;
                } else {
                    this.noDeleteAuthCount++;
                }

                if (this.authorizationService.canUpdateOrganization(networkGroup.id)) {
                    actions.push('update');
                    actions.push('autoScaling');
                }

                networkGroup['actionList'] = actions;
            }
            if (this.noDeleteAuthCount === theResult.length) {
                this.hasSomeDeletePermission = false;
            }
            // the deleted network group ids are only needed for the first refresh. After the first refresh they should no longer appear
            // if the network group does appear again, then something went wrong with the delete and the status shouldn't be set to 800
            this.deletedNetworkGroupIds = [];
            this.items = theResult;
            this.displayedItems = this.items;

            this.itemCount = this.items.length;
            this.showList = this.itemCount > 0;
            this.isLoading = false;
            this.isDeleteAction = this.anyToggled();

            this.totalElements = this.items.length;
            this.filterService.setTotalElements(this.totalElements);

            this.updatedNetworkGroupId = '';
            this.updatedNetworkGroupName = '';

            // TODO set total pages based on this.service.page.totalPages once using backend pagination
            this.filterService.updateTotalPages();

            if (this.setNetworkGroupAfterDelete) {
                this.setNewNetworkGroup();
            }

            this.setNetworkGroupAfterDelete = false;

            this.applyFilter();
        });
        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
    }
}
