import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { ClickOutsideModule } from 'ng-click-outside';
import { TagsSelectorComponent } from './tags-selector/tags-selector.component';

@NgModule({
    imports: [CommonModule, FormsModule, UiPipesModule, ClickOutsideModule],
    exports: [TagsSelectorComponent],
    declarations: [TagsSelectorComponent],
})
export class FeatureTagsSelectorModule {}
