import { Component } from '@angular/core';
import { NetworkVersionService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-downloadbox',
    templateUrl: './downloadbox.component.html',
    styleUrls: ['./downloadbox.component.scss'],
})
export class DownloadboxComponent {
    constructor(private networkVersionService: NetworkVersionService) {}

    open() {
        window.open(this.networkVersionService.getDownloadsLink());
    }
}
