import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationsFormComponent } from '@netfoundry-ui/feature/form/notifications-form';
import { Network } from '@netfoundry-ui/shared/model';
import { ApiService, NetworkService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-tableview',
    templateUrl: './tableview.component.html',
    styleUrls: ['./tableview.component.scss'],
})
export class TableviewComponent implements OnInit {
    item = {};
    keys = [];
    title = 'Details';
    subtitle = '';
    height = '500px';
    icon = 'svgs/EditNetwork.svg';

    currentNetwork: Network;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<TableviewComponent>,
        public dialogForm: MatDialog,
        public apiService: ApiService,
        public networkService: NetworkService
    ) {
        this.item = data.item;
        this.keys = data.keys;
        this.title = data.title;
        this.height = data.height;
        this.icon = data.icon;
    }

    ngOnInit() {
        this.getCurrentNetwork();
    }

    getCurrentNetwork() {
        this.apiService.currentNetwork.subscribe((currentNetwork) => {
            this.currentNetwork = currentNetwork;
        });
    }

    open() {
        this.dialogForm.open(NotificationsFormComponent, {
            data: {
                model: {
                    name: '',
                    description: '',
                    path: {
                        domainCode: 'io.netfoundry.network',
                        path: [
                            {
                                typeCode: 'network-group',
                                id: this.currentNetwork.organizationId,
                            },

                            {
                                typeCode: 'network',
                                id: this.currentNetwork.id,
                            },
                        ],
                    },
                    destination: {
                        type: 'Email',
                        toEmailAddresses: [],
                    },
                    condition: {
                        type: '',
                        matchingCodes: [],
                        matchingCategories: [],
                    },
                },
            },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.dialogRef.close();
    }

    close() {
        this.dialogRef.close();
    }
}
