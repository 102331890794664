import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { V7CardComponent } from './v7-card.component';

@NgModule({
    declarations: [V7CardComponent],
    exports: [V7CardComponent],
    imports: [CommonModule],
})
export class FeatureV7CardModule {}
