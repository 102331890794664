import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { NoitemsComponent } from './noitems.component';

@NgModule({
    declarations: [NoitemsComponent],
    exports: [NoitemsComponent],
    imports: [CommonModule, FormsModule, UiPipesModule, UiLoaderModule],
})
export class UiNoitemsModule {}
