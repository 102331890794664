import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { NgxHateoasClientModule } from '@lagoshny/ngx-hateoas-client';
import { FeatureActionButtonModule } from '@netfoundry-ui/feature/action-button';
import { FeatureBillingSummaryModule } from '@netfoundry-ui/feature/billing-summary';
import { FeatureDateFiltersModule } from '@netfoundry-ui/feature/date-filters';
import { FeatureElkVisualizationModule } from '@netfoundry-ui/feature/elk-visualization';
import { FeatureEventTimelineDashModule } from '@netfoundry-ui/feature/event-timeline-dash';
import { FeatureFormPostureCheckFormModule } from '@netfoundry-ui/feature/form/posture-check-form';
import { FeatureFormZitiAppwanModule } from '@netfoundry-ui/feature/form/ziti-appwan';
import { FeatureHighchartsGenericModule } from '@netfoundry-ui/feature/highcharts-generic';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { FeatureZitiUtilizationBasicModule } from '@netfoundry-ui/feature/ziti-utilization-basic';
import { SharedApiv2Module } from '@netfoundry-ui/shared/apiv2';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { SharedServicesModule } from '@netfoundry-ui/shared/services';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { SharedElasticsearchModule } from '@netfoundry-ui/shared/elasticsearch';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { ZitiNetworkHealthComponent } from './ziti-network-health/ziti-network-health.component';
import { ZitiUtilizationPieMiniComponent } from './ziti-utilization-pie-mini/ziti-utilization-pie-mini.component';
import { ZitiUtilizationPieComponent } from './ziti-utilization-pie/ziti-utilization-pie.component';
import { ZitiUtilizationStackedComponent } from './ziti-utilization-stacked/ziti-utilization-stacked.component';
import { ZitiUtilizationTableComponent } from './ziti-utilization-table/ziti-utilization-table.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ScrollingModule,
        MatDialogModule,
        MatTabsModule,
        NgxHateoasClientModule,
        UiLoaderModule,
        FeatureFormZitiAppwanModule,
        FeatureFormPostureCheckFormModule,
        FeatureHighchartsGenericModule,
        FeatureActionButtonModule,
        SharedServicesModule,
        FeatureZitiUtilizationBasicModule,
        UiPipesModule,
        FeatureBillingSummaryModule,
        FeatureElkVisualizationModule,
        FeatureEventTimelineDashModule,
        FeatureDateFiltersModule,
        SharedModelModule,
        SharedApiv2Module,
        SharedElasticsearchModule,
        MultiSelectModule,
        FormsModule,
    ],
    declarations: [
        ZitiNetworkHealthComponent,
        ZitiUtilizationPieComponent,
        ZitiUtilizationPieMiniComponent,
        ZitiUtilizationStackedComponent,
        ZitiUtilizationTableComponent,
    ],
    exports: [
        ZitiNetworkHealthComponent,
        ZitiUtilizationPieComponent,
        ZitiUtilizationPieMiniComponent,
        ZitiUtilizationStackedComponent,
        ZitiUtilizationTableComponent,
    ],
})
export class FeatureZitiModule {}
