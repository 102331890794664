import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  CertificateAuthorityServiceV2,
  AttributesServiceResults,
  AttributesService,
} from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { ZitiOptionsService } from '@netfoundry-ui/shared/helpers';
import { CertificateAuthorityV2, PagedAttributes } from '@netfoundry-ui/shared/model';
import { ApiService, LoggerService, ValidateService } from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ziti-certificate-authority-form',
  templateUrl: './ziti-certificate-authority-form.component.html',
  styleUrls: ['./ziti-certificate-authority-form.component.scss'],
})
export class ZitiCertificateAuthorityFormComponent implements OnInit, OnDestroy {
  public model: CertificateAuthorityV2 = new CertificateAuthorityV2();
  errorName = false;
  errorPem = false;
  errorIdentityNameFormat = false;

  isInline = false;

  processing = false;

  subsciption: Subscription = new Subscription();
  relatedSubscription: Subscription = new Subscription();

  hideHelp = false;

  isEditing = false;
  canEdit = false;
  bulkEdit = false;

  originalOttEnabled = false;
  originalAutoEnroll = false;
  originalAuthEnabled = false;

  showFormattingHelp = false;

  confirmDilogRef;

  endpointAttributes = new PagedAttributes();
  selectedEndpointAttributes = new PagedAttributes();
  isLoadingEndpointAttr: boolean;
  endpointAttributeError: boolean;
  isLoading: any;
  currentNetwork: any;

  constructor(
    private logger: LoggerService,
    private growlerService: GrowlerService,
    private validateService: ValidateService,
    private dialogRef: MatDialogRef<ZitiCertificateAuthorityFormComponent>,
    private certificateAuthorityService: CertificateAuthorityServiceV2,
    public dialogForm: MatDialog,
    private apiService: ApiService,
    private optionService: ZitiOptionsService,
    private authorizationService: AuthorizationService,
    private attributeService: AttributesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  private initModel() {
    if (this.data.model) {
      this.model = this.data.model;
      this.isEditing = true;
      this.canEdit = this.authorizationService.canUpdateCA(this.model.id);
      this.originalAuthEnabled = this.model.authEnabled;
      this.originalAutoEnroll = this.model.ottCaEnrollmentEnabled;
      this.originalOttEnabled = this.model.autoCaEnrollmentEnabled;
    }
    if (this.data.bulkEdit) {
      this.bulkEdit = true;
    }
    this.model.authEnabled = true;
    if (!this.model.endpointAttributes) {
      this.model.endpointAttributes = [];
    }
  }

  ngOnInit(): void {
    this.initModel();

    this.errorName = false;
    this.errorPem = false;
    this.processing = false;
    this.currentNetwork = this.apiService.currentNetwork.getValue();
    if (this.currentNetwork) {
      if (!this.model.networkId) {
        this.model.networkId = this.currentNetwork.id;
      }
      if (this.model.autoCaEnrollmentEnabled) {
        this.initializeEndpointSelector();
      }
    }
    if (this.bulkEdit) {
      this.validate();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.hide();
  }

  ngOnDestroy() {
    this.subsciption.unsubscribe();
    this.relatedSubscription.unsubscribe();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.hide();
    }
  }

  hide() {
    this.dialogRef.close();
  }

  async save() {
    this.model.endpointAttributes = [];
    for (const attribute of this.selectedEndpointAttributes.mappedAtrributes.keys()) {
      this.model.endpointAttributes.push(attribute);
    }

    if (this.bulkEdit) {
      this.dialogRef.close();
    } else if (this.validate()) {
      if (this.isEditing && this.displayWarning()) {
        const confirmed = await this.openConfirm();
        if (!confirmed) {
          return;
        }
      }

      this.processing = true;

      if (this.model.identityNameFormat == null) {
        this.model.identityNameFormat = '';
      }

      if (!this.isEditing) {
        this.subsciption.add(
          this.certificateAuthorityService.createResource({ body: this.model }).subscribe({
            next: (result) => {
              this.growlerService.show(
                new GrowlerData(
                  'success',
                  'Success',
                  'CA Save Complete',
                  'The information has been saved'
                )
              );
              this.hide();
            },
            error: (error) => {
              this.growlerService.show(
                new GrowlerData(
                  'error',
                  'Error',
                  'Unable to Validate',
                  'Unable to validate the provided pem'
                )
              );
              this.processing = false;
            },
          })
        );
      } else {
        this.subsciption.add(
          this.certificateAuthorityService.patchResource(this.model).subscribe(
            (result) => {
              this.growlerService.show(
                new GrowlerData(
                  'success',
                  'Success',
                  'CA Save Complete',
                  'The information has been saved'
                )
              );
              this.hide();
            },
            (error) => {
              this.growlerService.show(
                new GrowlerData(
                  'error',
                  'Error',
                  'Unable to Validate',
                  'Unable to valida the provided pem'
                )
              );
              this.processing = false;
            }
          )
        );
      }
    }
  }

  validate(): boolean {
    this.errorName = false;
    this.errorPem = false;
    this.errorIdentityNameFormat = false;

    if (!this.validateService.isValidName(this.model.name)) {
      this.errorName = true;
    }
    if (!this.isEditing && !this.validateService.hasValue(this.model['certPem'])) {
      this.errorPem = true;
    }

    if (
      this.validateService.hasValue(this.model.identityNameFormat) &&
      !this.validateService.isValidName(this.model.identityNameFormat)
    ) {
      this.errorIdentityNameFormat = true;
    }

    return !this.errorName && !this.errorPem;
  }

  toggleAuthEnabled() {
    this.model.authEnabled = !this.model.authEnabled;
  }

  toggleAutoEnrollment() {
    this.model.autoCaEnrollmentEnabled = !this.model.autoCaEnrollmentEnabled;
    if (this.model.autoCaEnrollmentEnabled) {
      this.initializeEndpointSelector();
    }
  }

  toggleOttEnrollment() {
    this.model['ottCaEnrollmentEnabled'] = !this.model['ottCaEnrollmentEnabled'];
  }

  displayWarning() {
    return (
      (this.originalOttEnabled && !this.model.ottCaEnrollmentEnabled) ||
      (this.originalAutoEnroll && !this.model.autoCaEnrollmentEnabled) ||
      (this.originalAuthEnabled && !this.model.authEnabled)
    );
  }

  async openConfirm() {
    const changedItems = [];
    if (this.originalAutoEnroll && !this.model.autoCaEnrollmentEnabled) {
      changedItems.push({ name: 'Auto Creation' });
    }

    if (this.originalOttEnabled && !this.model.ottCaEnrollmentEnabled) {
      changedItems.push({ name: 'Pre-Creation' });
    }

    if (this.originalAuthEnabled && !this.model.authEnabled) {
      changedItems.push({ name: 'Connections Enabled' });
    }

    const data = {
      title: 'Warning',
      appendId: 'CertificateAuthorities',
      afterListText: 'Are you sure you want to change these options?',
      bulletList: changedItems,
      action: 'Yes',
      icon: 'Caution',
    };
    this.confirmDilogRef = this.dialogForm.open(ConfirmComponent, {
      data: data,
      height: '340px',
      width: '600px',
      autoFocus: false,
    });
    return await this.confirmDilogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        // if the result has a property loggingOut, rather than being just a boolean value, the user is being
        //  logged out of the console and we should close the dialog without continuing
        if (result === undefined) {
          return false;
        } else if (result['loggingOut'] === undefined) {
          return result;
        }
      });
  }

  toggleFormattingHelp() {
    this.showFormattingHelp = !this.showFormattingHelp;
  }

  closeFormattingHelp() {
    this.showFormattingHelp = false;
  }
  private initializeEndpointSelector() {
    this.isLoadingEndpointAttr = true;
    this.isLoading = true;
    this.getSelectedEndpointAttributes();
    this.endpointAttributeError = false;
    this.attributeService
      .getEndpointAttributes(this.currentNetwork, false)
      .then((results: AttributesServiceResults) => {
        const collector = results.namedAttributes;
        for (const sa of results.groupAttributes.mappedAtrributes.values()) {
          collector.mappedAtrributes.set(sa.name, sa);
        }
        this.endpointAttributes = collector;
      })
      .catch((err) => {
        this.endpointAttributeError = true;
        this.logger.error(err);
      })
      .finally(() => {
        this.isLoadingEndpointAttr = false;
        this.isLoading = this.isLoadingEndpointAttr;
      });
  }

  getSelectedEndpointAttributes() {
    const endCollector = new PagedAttributes();
    for (const attribute of this.model.endpointAttributes) {
      endCollector.mappedAtrributes.set(attribute, {
        name: attribute,
        isGroup: attribute.charAt(0) === '#',
        isNamed: attribute.charAt(0) === '@',
      });
    }

    this.selectedEndpointAttributes = endCollector;
  }

  addEndpointsAttributes(newAttribute) {
    const success = this.attributeService.addAttributes(newAttribute, [], this.selectedEndpointAttributes);

    if (success) this.selectedEndpointAttributes = success as PagedAttributes;
    else this.endpointAttributeError = true;
  }

  removeEndpointAttribute(oldAttribute) {
    this.selectedEndpointAttributes = this.attributeService.removeAttribute(
      oldAttribute,
      this.selectedEndpointAttributes
    );
  }
}
