import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FeatureService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-noitems',
    templateUrl: './noitems.component.html',
    styleUrls: ['./noitems.component.scss'],
})
export class NoitemsComponent implements OnChanges {
    @Input() image = 'No_Gateways';
    @Input() typeName = '';
    @Input() isEmpty = false;
    @Input() hasAdd = true;
    @Input() hiddenResults = false;
    @Input() isLoading = false;
    @Input() type = '';
    background;
    @Output() clickEmit = new EventEmitter<any>();
    @Output() refresh = new EventEmitter<any>();

    constructor(public featureService: FeatureService) {}

    ngOnChanges() {
        this.background = {
            'background-image': 'url(/assets/svgs/' + this.image + '.svg)',
        };
    }

    iconClick() {
        this.clickEmit.emit();
    }

    enablePowerUser() {
        this.isLoading = true;
        return this.featureService
            .setPowerUserEnabled(true)
            .then(() => {
                this.isLoading = false;
                this.refresh.emit();
            })
            .catch(() => {
                this.isLoading = false;
                this.refresh.emit();
            });
    }
}
