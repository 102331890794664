import { MopResource } from './mopresource';

export class Endpoint extends MopResource {
    // gateways - more to come
    public endpointType: string;
    public dataCenterId: string;
    public countryId: string;
    public geoRegionId: string;
    public componentId: string;
    public zitiClusterId: string;
    public o365BreakoutNextHopIp: string;
    public source: string;
    public clientMfaEnable: string;
    public sessionIdentityId: string;
    public registrationKey: string;
    // Map of codes with labels
    public gatewayTypes = {
        VCPEGW: 'V-CPE Gateway',
        AWSCPEGW: 'AWS Private Gateway',
        GW: 'Public Internet Gateway',
        AVWGW: 'Azure Site',
        AZCPEGW: 'Azure Private Gateway',
        AZSGW: 'Azure Stack Gateway',
        ZTGW: 'Hosted Ziti Bridge Gateway',
        ZTNHGW: 'Private Ziti Bridge Gateway',
        GCPCPEGW: 'Google Cloud Platform Gateway',
        ALICPEGW: 'AliBaba Private Gateway',
        OCPCPEGW: 'Oracle Cloud Gateway',
        L2VCPEGW: 'Voice Enhanced Gateway',
    };

    constructor(object) {
        super(object);

        this.dataCenterId = this.getLinkedResourceId('dataCenter');
        this.geoRegionId = this.getLinkedResourceId('geoRegion');
    }

    public getTypeName() {
        return this.gatewayTypes[this.endpointType] ? this.gatewayTypes[this.endpointType] : 'V-CPE Gateway';
    }

    public getType() {
        if (this.endpointType === 'CL' || this.endpointType === 'ZTCL') {
            return 'CL';
        } else {
            return 'GW';
        }
    }

    public getCloud() {
        if (this.endpointType === 'AWSCPEGW') {
            return '';
        } else {
            return '--';
        }
    }

    public getCloudStyle() {
        if (this.endpointType === 'AWSCPEGW') {
            return 'AWS';
        } else if (this.endpointType === 'AVWGW' || this.endpointType === 'AZCPEGW' || this.endpointType === 'AZSGW') {
            return 'Microsoft';
        } else {
            return '--';
        }
    }

    public getSaveObject() {
        return {
            name: this.name,
            countryId: this.countryId,
            // location: this.location,
            endpointType: this.endpointType,
            dataCenterId: this.dataCenterId,
            geoRegionId: this.geoRegionId,
            o365BreakoutNextHopIp: this.o365BreakoutNextHopIp,
            clientMfaEnable: this.clientMfaEnable,
            sessionIdentityId: this.sessionIdentityId,
        };
    }

    public convertToEndpointAsACode(geoRegion: any, dataCenter: any): any {
        return {
            name: this.name,
            endpointType: this.endpointType,
            geoRegion: geoRegion !== undefined ? geoRegion : '',
            dataCenter: dataCenter !== undefined ? dataCenter : '',
        };
    }
}
