import { Injectable } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ApiService } from '@netfoundry-ui/shared/services';
import moment from 'moment';
import { ReplaySubject, Subscription } from 'rxjs';
import { isEmpty } from 'lodash';
import { BillingService } from './billing.service';
import { AccountService } from './account.service';

@Injectable({ providedIn: 'root' })
export class BillingEnabledService {
    public billingEnabled = false;
    public isFourteenDayTrial = false;
    public showUsageBilling = false;
    public trialDaysRemaining = 13;

    public isEnterprise = false;

    public hideCardWarning = true;

    private subscription: Subscription = new Subscription();

    private currentOrg;
    private currentAccount;

    billingEnabledSource = new ReplaySubject<boolean>(1);
    isBillingEnabled = this.billingEnabledSource.asObservable();
    billingEnabledVal = false;

    constructor(
        private apiService: ApiService,
        private billingService: BillingService,
        private accountService: AccountService,
        private authorizationService: AuthorizationService
    ) {
        this.apiService.currentOrg.subscribe((org) => {
            this.currentOrg = org;
            this.checkBilling();
        });
        this.accountService.currentAccount.subscribe((account) => {
            this.currentAccount = account;
            this.checkBilling();
        });
    }

    // Check for the billing toggle
    // TODO - update function to check the org to determine if it is enterprise or self service
    public async checkBilling() {
        // marking 7 day trial as false initially
        this.isFourteenDayTrial = false;
        this.billingEnabled = false;
        if (isEmpty(this.currentOrg?.id)) {
            if (this.authorizationService.canViewBillingSubscriptions() || this.authorizationService.canViewEnterpriseBillingSubscriptions()) {
              this.billingEnabled = true;
            } else {
              this.billingEnabled = false;
            }
        } else if (!this.authorizationService.canListBillingSubscriptions(this.currentOrg?.id)) {
            this.billingEnabled = false;
        } else if (!this.authorizationService.canViewPaymentProfile()) {
            this.billingEnabled = false;
        } else if (!isEmpty(this.currentAccount)) {
            // if result is none then the customer is not self service billing and billing should not be shown
            if (this.currentAccount != null && this.currentAccount['active']) {
                this.billingService.setCurrentBillingAccount(this.currentAccount);

                let isBillingEnabled = false;
                let trialEnd: moment.Moment;
                let today: moment.Moment;
                switch (this.currentAccount['billingMode']) {
                    case 'Trial':
                        // mark isFourteenDayTrial is true
                        this.isFourteenDayTrial = true;
                        // determine the end date by taking the created date and adding 6 days to it
                        // adding 6 days as the first day counts as a trial day and the user gets 6 more after that
                        // additionally, rounding to the start of the day
                        trialEnd = moment.utc(this.currentAccount['createdAt']).add(13, 'days').startOf('day');

                        // get the moment object representing the start of the day today

                        today = moment.utc().startOf('day');

                        // determine the number of trial days remaining by taking the difference (in days) of the two dates
                        this.trialDaysRemaining = trialEnd.diff(today, 'days');

                        isBillingEnabled = true;
                        break;

                    case 'SelfService':
                        isBillingEnabled = true;
                        break;

                    case 'Enterprise':
                        this.isEnterprise = true;
                        isBillingEnabled = false;
                        break;
                }

                if (this.isFourteenDayTrial && this.currentAccount['stripeBillingCardId'] != null) {
                    // TODO, change mode to self service
                }

                this.billingEnabled = isBillingEnabled;
            } else {
                this.billingService.setCurrentBillingAccount(null);
                return false;
            }
        }
        this.billingEnabledSource.next(this.billingEnabled);
        this.billingEnabledVal = this.billingEnabled;
        return this.billingEnabled;
    }

    public async isBillingEnabledNetworkGroup(orgId, billingAccountId) {
        const returnVal = {
            billingEnabled: false,
            isFourteenDayTrial: false,
        };

        if (orgId === null) {
            returnVal.billingEnabled = false;
        } else if (!this.authorizationService.canListBillingKeys(orgId)) {
            returnVal.billingEnabled = false;
        } else {
            returnVal.billingEnabled = await this.billingService
                .getBillingAccount(billingAccountId)
                .toPromise()
                .then(
                    (billingOrganization) => {
                        // if result is none then the customer is not self service billing and billing should not be shown
                        if (billingOrganization != null && billingOrganization['active']) {
                            if (billingOrganization['mode'] === 'Trial') {
                                returnVal.isFourteenDayTrial = true;
                            }
                            return true;
                        } else {
                            return false;
                        }
                    },
                    (error) => false
                );
        }
        return returnVal;
    }

    closeWarning() {
        this.hideCardWarning = true;
    }

    openWarning() {
        this.hideCardWarning = false;
    }
}
