import { InjectionToken } from '@angular/core';

export const ENROLLMENT_DIALOG: InjectionToken<string> = new InjectionToken<string>('ENROLLMENT_DIALOG');
export const NETWORK_RESTART_DIALOG: InjectionToken<string> = new InjectionToken<string>('NETWORK_RESTART_DIALOG');
export const NETWORK_UPGRADE_DIALOG: InjectionToken<string> = new InjectionToken<string>('NETWORK_UPGRADE_DIALOG');
export const EDGE_ROUTER_UPGRADE_DIALOG: InjectionToken<string> = new InjectionToken<string>(
    'EDGE_ROUTER_UPGRADE_DIALOG'
);
export const ENDPOINT_DIALOG: InjectionToken<string> = new InjectionToken<string>('ENDPOINT_DIALOG');
export const SERVICE_DIALOG: InjectionToken<string> = new InjectionToken<string>('SERVICE_DIALOG');
export const APPWAN_DIALOG: InjectionToken<string> = new InjectionToken<string>('APPWAN_DIALOG');
export const ADVANCED_SERVICE_DIALOG: InjectionToken<string> = new InjectionToken<string>('ADVANCED_SERVICE_DIALOG');
export const CONFIG_SERVICE_DIALOG: InjectionToken<string> = new InjectionToken<string>('CONFIG_SERVICE_DIALOG');
export const ROUTER_DIALOG: InjectionToken<string> = new InjectionToken<string>('ROUTER_DIALOG');
