import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('services')
export class ServiceV2 extends Resource {
    id: string;
    networkId: string;
    name: string;
    endpoints: string[];
    egressRouterId: string;
    clientHostName: string;
    clientPortRange: string;
    serverHostName: string;
    serverPortRange: string;
    serverProtocol: string;
    attributes: string[];
    edgeRouterAttributes: string[];
    createdAt: string;
    updatedAt: string;
    selected = false;
    encryptionRequired = true;
    zitiId: string;
    interceptConflicts: any[] = [];
}
