<div (click)="goToLogin()" *ngIf="step <= 1" class="icon mobile" style="cursor: pointer"></div>
<div (click)="moveBack()" *ngIf="step > 1 && step < 7" class="buttonBall back" id="SignupBackButton">
    <div class="buttonLabel">Back</div>
</div>
<app-loader [isLoading]="isLoading" id="selfService"></app-loader>
<div *ngIf="step === 1 && !isLoading">
    <main>
        <div class="area mid">
            <div class="titleImage step1"></div>
            <div class="title">Let's Get Started</div>

            <!-- TODO: remove this section when google and microsoft login are working -->

            <div class="formContainer">
                <form
                    #ngForm="ngForm"
                    (ngSubmit)="signupForm1.valid && moveNext()"
                    [formGroup]="signupForm1"
                    name="signupForm1"
                >
                    <div class="area middle" id="NameForm">

                        <div class="subtitle trialPlan" >
                            <b>FREE 30 Day TRIAL&nbsp;</b>  of NetFoundry Enterprise
                        </div>
                        <div class="subtitle trialSubtitle" >
                            Try out ALL the Enterprise functionality including our fabric, edge software, management console, and telemetry with your own private zero trust overlay network based on OpenZiti. Then upgrade to Enterprise when you're ready.
                        </div>
                        <div class="planBlock">
                            <div class="subtitle trialSubtitle" >
                                <b>Capacity:</b> Up to 10 endpoints and 1 TB/month data transfer.
                            </div>
                            <div class="subtitle trialSubtitle" >
                                <b>One hour consultation:</b> A NetFoundry engineer will email you to schedule the online meeting.
                            </div>
                            <div class="subtitle trialSubtitle" >
                                <b>Termination:</b> Accounts idle for 3 consecutive business days will be automatically closed.
                            </div>
                        </div>

                        <div class="label row">
                            <div class="label row plan-title-container">
                                <a
                                    class="learnmoregettingstarted"
                                    href="https://netfoundry.io/pricing/"
                                    target="_blank"
                                >
                                    Learn more about this plan here.
                                </a>
                            </div>
                        </div>
                        <div class="label row">
                            Name
                            <span
                                *ngIf="signupForm1.get('firstName').invalid && ngForm.submitted"
                                class="error error-first-name"
                                id="ErrorFirstNameErr"
                                >First name required</span
                            >
                            <span
                                *ngIf="signupForm1.get('lastName').invalid && ngForm.submitted"
                                class="error error-last-name"
                                id="ErrorLastNameErr"
                                >Last name required</span
                            >
                        </div>
                        <div class="half">
                            <div>
                                <input
                                    [(ngModel)]="model.firstName"
                                    [ngClass]="{
                                        error: signupForm1.get('firstName').invalid && ngForm.submitted
                                    }"
                                    formControlName="firstName"
                                    id="FirstName"
                                    maxlength="150"
                                    name="firstName"
                                    placeholder="First"
                                    type="text"
                                />
                            </div>
                            <div>
                                <input
                                    [(ngModel)]="model.lastName"
                                    [ngClass]="{
                                        error: signupForm1.get('lastName').invalid && ngForm.submitted
                                    }"
                                    formControlName="lastName"
                                    id="LastName"
                                    maxlength="150"
                                    name="lastName"
                                    placeholder="Last"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div class="label row">
                            Email<span
                                *ngIf="signupForm1.get('email').invalid && ngForm.submitted"
                                class="error"
                                id="ErrorEmail"
                                >{{ !isBlackListed ? 'Valid email is required' : 'This email host is not accepted' }}
                            </span>
                        </div>
                        <div class="row">
                            <input
                                [(ngModel)]="model.email"
                                [ngClass]="{
                                    error: signupForm1.get('email').invalid && ngForm.submitted
                                }"
                                formControlName="email"
                                id="Email"
                                maxlength="150"
                                name="email"
                                placeholder="Work email"
                                type="text"
                            />
                        </div>
                        <div class="half" *ngIf="model.plan === 'trial'">
                            <div>
                                <div class="label row" *ngIf="model.plan === 'trial'">
                                    Country
                                    <span
                                        *ngIf="signupForm1.get('countryCode').invalid && ngForm.submitted"
                                        class="error error-country"
                                        id="CountryTeams"
                                        >Required</span
                                    >
                                </div>
                                <div class="row">
                                    <select
                                        [(ngModel)]="model.countryCode"
                                        [ngClass]="{
                                            error: signupForm1.get('countryCode').invalid && ngForm.submitted
                                        }"
                                        (change)="countryChanged()"
                                        formControlName="countryCode"
                                        id="CountryCodeTeams"
                                        maxlength="2"
                                        name="countryCode"
                                    >
                                        <option value="">Select Country</option>
                                        <option
                                            *ngFor="let country of countries | sortby: 'sortName'"
                                            [value]="country.code"
                                        >
                                            {{ country.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div class="label row">
                                    {{ mailCodeText }}
                                    <span
                                        *ngIf="!isZipCodeValid || (signupForm1.get('zip').invalid && ngForm.submitted)"
                                        class="error error-zip"
                                        id="Zip"
                                    >
                                        {{ !isZipCodeValid ? mailCodeText + ' Invalid' : 'Required' }}
                                    </span>
                                </div>
                                <div class="row">
                                    <input
                                        [(ngModel)]="model.zip"
                                        [ngClass]="{
                                            error:
                                                !isZipCodeValid || (signupForm1.get('zip').invalid && ngForm.submitted)
                                        }"
                                        formControlName="zip"
                                        id="Zip"
                                        maxlength="{{ isZip ? '5' : '32' }}"
                                        name="zip"
                                        [placeholder]="mailCodeText"
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div [ngClass]="{ error: ngForm.submitted && !model.terms }" class="cardArea terms">
                                <div class="checkItem two padBottom">
                                    <div>
                                        <div
                                            (click)="selectTerms()"
                                            [matTooltipClass]="'tooltip terms'"
                                            [ngClass]="{ selected: model.terms, disabled: !termsOpened }"
                                            class="cSelect"
                                            matTooltip="{{
                                                termsOpened
                                                    ? ''
                                                    : 'You must open/view the terms and conditions before agreeing'
                                            }}"
                                            matTooltipPosition="above"
                                        ></div>
                                    </div>
                                    <div (mousedown)="openTerms($event)" class="checkText slimHeight">
                                        I have read and agree to the
                                        <a
                                            href="https://netfoundry.io/terms-and-privacy-policy/"
                                            target="_blank"
                                            >Terms of Use and Privacy Policy</a
                                        >.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row topMargin">
                            <button class="button" id="NextButton1">Let's get Started</button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- TODO: uncomment this section when google and microsoft login are working
<div class="barGrid">
<div>
  <div class="miniTitle">Sign in with your email address</div>
  <div class="label row">&nbsp;</div>
  <div class="row">
      <div class="button google" (click)="initiateGoogleSignIn()">Sign In with Google</div>
  </div>
  <div class="row">
      <div class="button microsoft" (click)="initiateMicrosoftSignIn()">Sign In with Microsoft</div>
  </div>
</div>
<div>
  <div class="bar"></div>
</div>
<div>
  <form name="signupForm" [formGroup]="signupForm" (ngSubmit)="signupForm.valid && moveNext()">
      <div id="NameForm" class="area middle">
          <div class="miniTitle">Register with your email address</div>
          <div class="label row">Work EMail</div>
          <div class="row">
              <input id="emailAddress" name="emailAddress" maxlength="150" [(ngModel)]="model.email" type="email" placeholder="Work Email" formControlName="emailAddress"/>
          </div>
          <div class="label row">Password</div>
          <div class="row">
              <input id="password" name="password" maxlength="150" [(ngModel)]="model.password" type="password" placeholder="Password" formControlName="password"/>
          </div>
          <div class="subtitle row marginVertical">By signing up, you agree to NetFoundry's <a href="https://netfoundry.io/netfoundry-self-service-agreement/" target="_blank">terms and conditions</a></div>
          <div class="row topMargin">
              <button id="NextButton" class="button">Next</button>
          </div>
      </div>
  </form>
</div>
</div>
-->
            <app-copyright></app-copyright>
        </div>
    </main>
</div>
<div *ngIf="step === 2">
    <main>
        <div class="area mid">
            <div class="titleImage step1-5"></div>
            <div class="title">Account Setup</div>

            <!-- TODO: remove this section when google and microsoft login are working -->

            <div class="formContainer">
                <form
                    #ngForm="ngForm"
                    (ngSubmit)="signupForm2.valid && moveNext()"
                    [formGroup]="signupForm2"
                    name="signupForm2"
                >
                    <div class="area middle" id="PaymentForm">
                        <div class="miniTitleGetStarted">
                            For Growth Plans, please provide a credit card. Charges per endpoint will apply.
                        </div>
                        <div class="label row plan-title-container-2">
                            <a class="learnmoregettingstarted" href="https://netfoundry.io/pricing/" target="_blank">
                                Learn more about our plans here
                            </a>
                        </div>
                        <div *ngIf="false" class="label row plan-title-container-2">
                            <div>Selected Plan</div>
                            <a class="learnmoregettingstarted" href="https://netfoundry.io/pricing/" target="_blank"
                                >Learn about plans</a
                            >
                        </div>
                        <div *ngIf="false" class="row pick-plan-container pick-plan-step-2">
                            <div class="plan-container">
                                <div class="checkItem two padBottom plan-check-container teams-tier-select">
                                    <div (mousedown)="openTerms($event)" class="checkText slimHeight">Teams</div>
                                </div>
                                <div class="plan-details-container">
                                    <div class="plan-details-title">FREE Forever</div>
                                    <div class="plan-details-subtext">Up to 10 Endpoints</div>
                                </div>
                                <div
                                    (click)="selectPlan('teams')"
                                    [ngClass]="{ selected: model.plan === 'trial' }"
                                    class="cSelect plan-select"
                                    matTooltipPosition="above"
                                ></div>
                            </div>
                            <div class="plan-spacer"></div>
                            <div class="plan-container">
                                <div class="checkItem two padBottom plan-check-container growth-tier-select">
                                    <div (mousedown)="openTerms($event)" class="checkText slimHeight">Growth</div>
                                </div>
                                <div class="plan-details-container">
                                    <div class="plan-details-title">$5/endpoint/month</div>
                                    <div class="plan-details-subtext">Subject to the limits of the plan</div>
                                </div>
                                <div
                                    (click)="selectPlan('growth')"
                                    [ngClass]="{ selected: model.plan === 'growth' }"
                                    class="cSelect plan-select"
                                    matTooltipPosition="above"
                                ></div>
                            </div>
                        </div>
                        <div class="label row">Payment Method</div>
                        <div class="row">
                            <div
                                (ready)="onChargebeeReady($event)"
                                [ngClass]="{ error: ngForm.submitted && !this.cardValid }"
                                cbCardField
                                class="chargebee-input-card fieldset field"
                                id="card-field"
                            ></div>
                        </div>
                        <div class="label row">
                            Company Location
                            <span
                                *ngIf="signupForm2.get('countryCode').invalid && ngForm.submitted"
                                class="error error-country"
                                id="Country"
                                >Required</span
                            >
                        </div>
                        <div class="row">
                            <select
                                [(ngModel)]="model.countryCode"
                                [ngClass]="{
                                    error: signupForm2.get('countryCode').invalid && ngForm.submitted
                                }"
                                formControlName="countryCode"
                                id="CountryCode"
                                maxlength="2"
                                name="countryCode"
                            >
                                <option value="">Select Country</option>
                                <option *ngFor="let country of countries | sortby: 'sortName'" [value]="country.code">
                                    {{ country.name }}
                                </option>
                            </select>
                        </div>
                        <div
                            *ngIf="!model.countryCode || model.countryCode === '' || model.countryCode == 'US'"
                            style="display: initial"
                        >
                            <div class="label row">
                                Zip
                                <span *ngIf="!isZipCodeValid" class="error error-zip" id="Zip">Zip Invalid</span>
                            </div>
                            <div class="row">
                                <input
                                    [(ngModel)]="model.zip"
                                    [ngClass]="{ error: !isZipCodeValid }"
                                    formControlName="zip"
                                    id="Zip"
                                    maxlength="5"
                                    name="zip"
                                    placeholder="Zip Code"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div
                            *ngIf="model.countryCode && model.countryCode !== '' && model.countryCode != 'US'"
                            style="display: initial"
                        >
                            <div class="label row">
                                Postal Code
                                <span *ngIf="!isZipCodeValid" class="error error-zip" id="Zip"
                                    >Postal Code Invalid</span
                                >
                            </div>
                            <div class="row">
                                <input
                                    [(ngModel)]="model.zip"
                                    [ngClass]="{ error: !isZipCodeValid }"
                                    formControlName="zip"
                                    id="Zip"
                                    maxlength="32"
                                    name="zip"
                                    placeholder="Postal Code"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div class="row topMargin">
                            <button [disabled]="isLoading" class="button" id="NextButton2">Next</button>
                        </div>
                    </div>
                </form>
            </div>
            <app-copyright></app-copyright>
        </div>
        <app-loader [isLoading]="isLoading" id="selfServiceCardLoader"></app-loader>
    </main>
</div>
<div *ngIf="step === 3">
    <main>
        <div class="formContainer">
            <form
                #ngForm="ngForm"
                (ngSubmit)="vanityUrlForm.valid && moveNext()"
                [formGroup]="vanityUrlForm"
                name="vanityUrlForm"
            >
                <div class="area mid">
                    <div class="titleImage step2"></div>
                    <div class="title">Customize your Account</div>
                    <div class="miniTitle">
                        This is where you will access your new account. Make sure to bookmark it once you’re inside.
                    </div>
                    <div class="label row">Your Organization Name</div>
                    <span
                        *ngIf="vanityUrlForm.get('domain').invalid && ngForm.submitted"
                        class="error"
                        id="ErrorDomain"
                    >
                        {{
                            vanityUrlForm.get('domain').value !== ''
                                ? invalidDomain
                                    ? 'Invalid domain. Domains may only contain combination of letters of the alphabet, numbers 0-9 and a hyphen.'
                                    : 'Domain Taken'
                                : 'Domain Required'
                        }}</span
                    >
                    <div class="row">
                        <input
                            [(ngModel)]="model.domain"
                            [ngClass]="{
                                error: vanityUrlForm.get('domain').invalid && ngForm.submitted
                            }"
                            formControlName="domain"
                            id="Domain"
                            maxlength="63"
                            name="domain"
                            placeholder="orgname"
                            type="text"
                        />
                        <div class="inputAppend">.{{ originDomain }}</div>
                    </div>
                    <div class="row topMargin">
                        <button class="button" id="NextButton3">Next</button>
                    </div>
                    <app-copyright></app-copyright>
                </div>
            </form>
        </div>
    </main>
</div>

<div *ngIf="step === 4">
    <main>
        <div class="area mid">
            <div class="titleImage step3"></div>
            <div class="title">Validate Your Account</div>
            <div class="miniTitle">Enter the validation token that was sent to your email at:</div>
            <div class="miniTitle" style="font-size: 18px; font-weight: 600; color: var(--tableText)">
                {{ this.model.email }}
            </div>
            <div class="row topMargin formContainer">
                <span
                    *ngIf="!emailValidationErrors['invalid'] && !emailValidationErrors['expired']"
                    class="validation-label placeholder"
                >
                    Enter Token:
                </span>
                <span *ngIf="emailValidationErrors['invalid']" class="validation-label">
                    Token is invalid. Please re-enter and try again.
                </span>
                <span *ngIf="emailValidationErrors['expired']" class="validation-label">
                    Token is expired. Please request a new validation token.
                </span>
                <input
                    (change)="tokenChangedDebounced($event)"
                    (keydown)="tokenChangedDebounced($event)"
                    (keydown.enter)="submitEmailValidationToken()"
                    (paste)="tokenChangedDebounced($event)"
                    [(ngModel)]="emailValidationToken"
                    [ngClass]="{ error: emailValidationErrors['expired'] || emailValidationErrors['invalid'] }"
                    class="tokenInput"
                    id="DomainName"
                    name="domain"
                    placeholder="Enter validation token"
                    type="text"
                />
            </div>
            <div class="row topMargin">
                <button
                    (click)="moveNext()"
                    [disabled]="tokenEmpty"
                    [ngClass]="{ valid: tokenValid }"
                    class="button"
                    id="NextButton4"
                >
                    Next
                </button>
                <div (click)="resendToken()" class="resend-link">RESEND TOKEN</div>
            </div>
            <app-copyright></app-copyright>
        </div>
    </main>
</div>

<div *ngIf="step === 5">
    <main>
        <div class="area mid">
            <div class="titleImage step4"></div>
            <div class="title">Select your regions</div>
            <div class="miniTitle">The first region is FREE for Six Months!</div>
            <div class="label row region-label">
                <span
                    *ngIf="!validSubmitRegion && !selectedAtLeastOneRegion"
                    class="error error-region"
                    id="ErrorRegion"
                    >You must select at least one region</span
                >
            </div>
            <div [ngClass]="{ error: !validSubmitRegion && !selectedAtLeastOneRegion }" class="cardArea">
                <div *ngFor="let region of regions" class="checkItem">
                    <div>
                        <div (click)="select(region)" [ngClass]="{ selected: region.selected }" class="cSelect"></div>
                    </div>
                    <div>
                        {{ region.name }}
                    </div>
                    <div class="checkCost">
                        {{ region.cost }}
                    </div>
                </div>
                <div class="totalsItem">
                    <div>Monthly Cost</div>
                    <div class="checkCost bold">{{ totalCostString }}</div>
                </div>
            </div>

            <div class="label row terms-label">
                <span *ngIf="!validSubmitRegion && !model.terms" class="error error-terms" id="ErrorTerms"
                    >You must agree to the terms</span
                >
            </div>
            <div [ngClass]="{ error: !validSubmitRegion && !model.terms }" class="cardArea">
                <div class="checkItem two padBottom">
                    <div>
                        <div (click)="selectTerms()" [ngClass]="{ selected: model.terms }" class="cSelect"></div>
                    </div>
                    <div class="checkText slimHeight">
                        I have read and agree to the
                        <a href="https://netfoundry.io/netfoundry-self-service-agreement/" target="_blank"
                            >terms and conditions</a
                        >.
                    </div>
                </div>
            </div>

            <div class="row topMargin">
                <button
                    (click)="model.terms && selectedRegions.length > 0 ? moveNext() : failedSubmitRegion()"
                    class="button"
                    id="NextButton5"
                >
                    Next
                </button>
            </div>
            <app-copyright></app-copyright>
        </div>
    </main>
</div>
<!--
        using hidden here so that the elements are added to the DOM on page load, not when this step is reached
        doing this allows us to get rid of the setTimeout on setting the stripe element and makes the page look smoother
    -->
<div [hidden]="step !== 6">
    <main>
        <form #ngForm="ngForm" (ngSubmit)="moveNext()" [formGroup]="billingForm" name="billingForm">
            <div class="area mid">
                <div class="titleImage step5"></div>
                <div class="title">Billing Summary</div>
                <div class="miniTitle">We just need a credit card to get started</div>

                <div class="cardArea monthlyPricing">
                    <div class="summaryTitle">Your Monthly Pricing</div>
                    <div class="summarySubTitle">Monthly Network Pricing</div>
                    <div class="summaryItem">
                        <div class="summary">{{ '$' + billingService.networkPricing.toFixed(2) }} USD/Network*</div>
                    </div>
                    <div *ngIf="billingEnabledService.showUsageBilling" class="summarySubTitle">
                        Monthly Data Pricing
                    </div>
                    <div *ngIf="billingEnabledService.showUsageBilling">
                        <div *ngFor="let regionPricing of billingService.regionPricing" class="summaryItem">
                            <div *ngIf="regionPricing['region'] == null" class="summary">
                                {{ '$' + regionPricing['price'].toFixed(2) + '/' + regionPricing['rate'] }}
                            </div>
                        </div>
                    </div>
                    <div class="footer">* Your first network is free for the first month!</div>
                </div>
                <div class="label row">
                    <span
                        [matTooltipClass]="'tooltip'"
                        matTooltip="Full name as it appears on the credit card"
                        matTooltipPosition="above"
                    >
                        Name on Card
                    </span>
                    <span
                        *ngIf="billingForm.get('name').invalid && ngForm.submitted"
                        class="error"
                        id="ErrorBillingNameErr"
                        >Name required</span
                    >
                </div>
                <div class="row">
                    <div>
                        <input
                            [ngClass]="{
                                error: billingForm.get('name').invalid && ngForm.submitted
                            }"
                            formControlName="name"
                            id="BillingName"
                            maxlength="300"
                            name="name"
                            placeholder="Name"
                            type="text"
                        />
                    </div>
                </div>
                <div class="label row">
                    Billing Email
                    <span
                        *ngIf="billingForm.get('email').invalid && ngForm.submitted"
                        class="error"
                        id="ErrorBillingEmailErr"
                        >Valid Email required</span
                    >
                </div>
                <div class="row">
                    <div>
                        <input
                            [ngClass]="{
                                error: billingForm.get('email').invalid && ngForm.submitted
                            }"
                            formControlName="email"
                            id="BillingEmail"
                            maxlength="150"
                            name="email"
                            placeholder="Email"
                            type="text"
                        />
                    </div>
                </div>

                <div class="label row">
                    Credit or Debit Card
                    <span
                        *ngIf="!isProd"
                        [matTooltipClass]="'tooltip'"
                        matTooltip="Use test card 4242 4242 4242 4242 with any expiration date in the future and any CVC"
                        matTooltipPosition="above"
                        ><mat-icon class="helpIcon">help</mat-icon></span
                    >
                </div>
                <div [ngClass]="{ error: cardError }" class="row ccCard" id="ccCard"></div>
                <div class="label row ccError">
                    <span *ngIf="cardError" class="error cardError" id="CreditCardError">{{ cardErrorString }}</span>
                </div>
                <div class="row">
                    <div [ngClass]="{ error: billingFormSubmitted && !model.terms }" class="cardArea">
                        <div class="checkItem two padBottom">
                            <div>
                                <div
                                    (click)="selectTerms()"
                                    [ngClass]="{ selected: model.terms, disabled: !termsOpened }"
                                    class="cSelect"
                                ></div>
                            </div>
                            <div class="checkText slimHeight">
                                I have read and agree to the
                                <a href="https://netfoundry.io/netfoundry-self-service-agreement/" target="_blank"
                                    >terms and conditions</a
                                >.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row topMargin">
                    <button class="button" id="NextButton6">Submit Payment</button>
                </div>
            </div>
        </form>
    </main>
</div>
<div *ngIf="step >= 7">
    <main>
        <div class="area mid">
            <div class="titleImage step7"></div>
            <div class="title">Email Validated</div>
            <div class="area middle" id="PaymentForm">
                <div class="miniTitleGetStarted">
                    Thank you for validating your email address. Next you will be prompted to create a password for your
                    user account.
                </div>
            </div>
            <div class="loader-container">
                <app-loader [isLoading]="true" id="selfService"></app-loader>
            </div>
            <div class="copyright-container">
                <app-copyright></app-copyright>
            </div>
        </div>
    </main>
</div>
