import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('services')
export class PlatformService extends Resource {
    id: string;
    networkId: string;
    name: string;
    zitiId: string;
    encryptionRequired = true;
    attributes: string[];
    modelType: string;
    model: any;
    configIdByConfigTypeId: any;
    ownerIdentityId: string;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    edgeRouterAttributes: string[];
    endpointAttributes: string[];
}
