import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
    AttributesService,
    AttributesServiceResults,
    EdgeRouterServiceV2,
    PlatformServiceService,
    ServiceEdgeRouterPolicyService,
} from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { NetworkV2, PagedAttributes, ServiceEdgeRouterPolicy } from '@netfoundry-ui/shared/model';
import { ApiService, LoggerService, ValidateService } from '@netfoundry-ui/shared/services';
import _ from 'lodash';
import { ZitiOptionsService } from '@netfoundry-ui/shared/helpers';

@Component({
    selector: 'app-ziti-service-edge-router-policy',
    templateUrl: './ziti-service-edge-router-policy.component.html',
    styleUrls: ['./ziti-service-edge-router-policy.component.scss'],
})
export class ZitiServiceEdgeRouterPolicyComponent implements OnInit {
    public model: ServiceEdgeRouterPolicy;
    regions: Location[] = [];
    isComplete = false;
    errorName = false;
    errorNameLength = false;
    processing = false;
    hideHelp = false;
    isInline = false;
    isEditing = false;
    bulkEdit = false;

    canEdit = false;

    currentNetwork: NetworkV2;
    selectedEdgeRouterAttributes = new PagedAttributes();
    selectedServiceAttributes = new PagedAttributes();
    isLoadingEdgeRouterAttr: boolean;
    edgeRouterError: boolean;
    allEdgeRouters = [];
    edgeRouterAttributes = new PagedAttributes();
    serviceAttributes = new PagedAttributes();
    isLoading: any;
    isLoadingServiceAttr: boolean;
    svcError: boolean;
    v2EdgeRouters = [];
    isEdgeRouterPreview: boolean;
    invalidWords: string[] = [];
    v2Services = [];
    allServices = [];
    isServicePreview: boolean;
    isAllServices: boolean;
    isAllEdgeRouters: boolean;
    errors = {};
    erAttributeError = false;
    serviceAttributeError = false;

    constructor(
        private logger: LoggerService,
        private dialogRef: MatDialogRef<ZitiServiceEdgeRouterPolicyComponent>,
        private serviceEdgeRouterPolicyService: ServiceEdgeRouterPolicyService,
        private platformServiceService: PlatformServiceService,
        private edgeRouterService: EdgeRouterServiceV2,
        private apiService: ApiService,
        public validateService: ValidateService,
        public growlerService: GrowlerService,
        private authorizationService: AuthorizationService,
        private attributesService: AttributesService,
        private optionsService: ZitiOptionsService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
        this.hide();
    }

    ngOnInit() {
        this.initModel();

        this.currentNetwork = this.apiService.currentNetwork.getValue();
        if (this.currentNetwork) {
            this.model.networkId = this.currentNetwork.id;
            this.initializeEdgeRouterSelector();
            this.initializeServiceSelector();
        }
    }

    initModel() {
        if (this.data.model) {
            this.model = this.data.model;
            this.bulkEdit = this.data.bulkEdit;
            this.isEditing = !this.bulkEdit;
            this.canEdit = this.authorizationService.canUpdateEdgeRouterPolicy(this.model.id);
        } else {
            this.model = new ServiceEdgeRouterPolicy();
        }

        if (!this.model.serviceAttributes) {
            this.model.serviceAttributes = [];
        }
        if (!this.model.edgeRouterAttributes) {
            this.model.edgeRouterAttributes = [];
        }
        if (this.bulkEdit) {
            this.validate();
        }
    }

    hide(response?) {
        this.dialogRef.close(response);
    }

    async save() {
        this.model.semantic = 0;
        this.model.edgeRouterAttributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        this.model.serviceAttributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        if (await this.validate()) {
            if (this.bulkEdit) {
                this.dialogRef.close();
            } else if (this.model.id) {
                this.serviceEdgeRouterPolicyService
                    .updateResource(this.model)
                    .toPromise()
                    .then(() => {
                        this.processing = false;
                        this.dialogRef.close();
                    })
                    .catch((error) => {
                        this.processing = false;
                        this.logger.error('Error occured during service edge router policy update', error);
                        const errorMessage = _.get(error, 'error.errors[0]', error.message);
                        this.growlerService.show(
                            new GrowlerData('error', 'Service Edge Router Policy update request failed', errorMessage)
                        );
                    });
            } else {
                this.model.createServiceEdgeRouterPolicy = true;
                this.model.semantic = 0;
                this.serviceEdgeRouterPolicyService
                    .createResource({ body: this.model })
                    .toPromise()
                    .then(() => {
                        this.processing = false;
                        this.dialogRef.close();
                    })
                    .catch((error) => {
                        this.processing = false;
                        this.logger.error('Error from edge router policy creation', error);
                        this.growlerService.show(
                            new GrowlerData('error', 'Service Edge Router Policy creation failed', error.message)
                        );
                    });
            }
        }
    }

    async validate() {
        this.errors = {};
        this.erAttributeError = false;
        this.serviceAttributeError = false;
        if (!this.model.id) {
            const errors = await this.serviceEdgeRouterPolicyService.validateCreate(this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.serviceAttributes')) {
                this.serviceAttributeError = true;
                this.errors['serviceAttributes'] = _.find(errors, (e) => e.path === '$.serviceAttributes').message;
            }
            if (_.find(errors, (e) => e.path === '$.edgeRouterAttributes')) {
                this.erAttributeError = true;
                this.errors['edgeRouterAttributes'] = _.find(
                    errors,
                    (e) => e.path === '$.edgeRouterAttributes'
                ).message;
            }
            return errors.length === 0;
        } else {
            const errors = await this.serviceEdgeRouterPolicyService.validateUpdate(this.model.id, this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.serviceAttributes')) {
                this.serviceAttributeError = true;
                this.errors['serviceAttributes'] = _.find(errors, (e) => e.path === '$.serviceAttributes').message;
            }
            if (_.find(errors, (e) => e.path === '$.edgeRouterAttributes')) {
                this.erAttributeError = true;
                this.errors['edgeRouterAttributes'] = _.find(
                    errors,
                    (e) => e.path === '$.edgeRouterAttributes'
                ).message;
            }
            return errors.length === 0;
        }
    }

    _hasAllItems(items) {
        return _.some(items, (attr) => attr === '#all');
    }

    addEdgeRouterAttribute(newAttribute) {
        const success = this.attributesService.addAttributes(
            newAttribute,
            this.invalidWords,
            this.selectedEdgeRouterAttributes
        );

        if (success) this.selectedEdgeRouterAttributes = success as PagedAttributes;
        else this.edgeRouterError = true;
        const selList = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.edgeRouterAttributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        this.validate();
        this.getEdgeRouterPreviewList(namedList, toBeExploded);
    }

    getEdgeRouterPreviewList(namedList: string[], toBeExploded: string[]) {
        this.isAllEdgeRouters = false;
        this.isEdgeRouterPreview = true;
        if (toBeExploded.length === 0) {
            this.v2EdgeRouters = namedList;
            this.isEdgeRouterPreview = false;
        } else if (toBeExploded.indexOf('#all') >= 0) {
            this.v2EdgeRouters = this.allEdgeRouters.map((rec) => rec.name.substr(1));
            this.isAllEdgeRouters = true;
            this.isEdgeRouterPreview = false;
        } else {
            this.attributesService
                .getByAttribute(this.currentNetwork.id, 'edge-routers', toBeExploded)
                .then((results) => {
                    let tmpResults = results['_embedded']['edgeRouterList'].map((rec) => rec['name']);
                    tmpResults = [...namedList, ...tmpResults];

                    if (tmpResults?.length > 0) {
                        this.v2EdgeRouters = tmpResults.sort();
                    }
                })
                .finally(() => {
                    this.isEdgeRouterPreview = false;
                });
        }
    }

    private getServicePreviewList(namedList: string[], toBeExploded: string[]) {
        this.isAllServices = false;
        this.isServicePreview = true;
        if (toBeExploded.length === 0) {
            this.v2Services = namedList;
            this.isServicePreview = false;
        } else if (toBeExploded.indexOf('#all') >= 0) {
            this.v2Services = this.allServices.map((rec) => rec.name.substr(1));
            this.isAllServices = true;
            this.isServicePreview = false;
        } else {
            this.attributesService
                .getByAttribute(this.currentNetwork.id, 'services', toBeExploded)
                .then((results) => {
                    let tmpResults = results.map((rec) => rec['name']);
                    tmpResults = [...namedList, ...tmpResults];

                    if (tmpResults?.length > 0) {
                        this.v2Services = tmpResults.sort();
                    }
                })
                .finally(() => {
                    this.isServicePreview = false;
                });
        }
    }

    removeEdgeRouterAttribute(oldAttribute) {
        this.selectedEdgeRouterAttributes = this.attributesService.removeAttribute(
            oldAttribute,
            this.selectedEdgeRouterAttributes
        );
        const selList = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.edgeRouterAttributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        this.validate();
        this.getEdgeRouterPreviewList(namedList, toBeExploded);
    }

    addServiceAttribute(newAttribute) {
        const success = this.attributesService.addAttributes(newAttribute, [], this.selectedServiceAttributes);

        if (success) this.selectedServiceAttributes = success as PagedAttributes;
        else this.svcError = true;
        const selList = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.serviceAttributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        this.validate();
        this.getServicePreviewList(namedList, toBeExploded);
    }

    removeServiceAttribute(oldAttribute) {
        this.selectedServiceAttributes = this.attributesService.removeAttribute(
            oldAttribute,
            this.selectedServiceAttributes
        );
        const selList = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.serviceAttributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        this.validate();
        this.getServicePreviewList(namedList, toBeExploded);
    }

    private initializeServiceSelector() {
        this.isLoadingServiceAttr = true;
        this.getSelectedServices();
        this.svcError = false;
        this.attributesService
            .getServiceAttributes(this.currentNetwork, false)
            .then((results: AttributesServiceResults) => {
                const collector = results.namedAttributes;
                this.allServices = Array.from(results.namedAttributes.mappedAtrributes.values());
                if (this.isAllServices) {
                    this.v2Services = this.allServices.map((rec) => rec.name.substr(1));
                }
                for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                    collector.mappedAtrributes.set(sa.name, sa);
                }
                this.serviceAttributes = collector;
            })
            .catch((err) => {
                this.svcError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingServiceAttr = false;
                this.isLoading = this.isLoadingServiceAttr || this.isLoadingEdgeRouterAttr;
            });
    }

    private getSelectedServices() {
        const selectedEndpoints = new PagedAttributes();
        const toBeExploded = [];
        const namedList = [];
        this.model.serviceAttributes.forEach((att) => {
            selectedEndpoints.mappedAtrributes.set(att, {
                name: att,
                isGroup: att.charAt(0) === '#',
                isNamed: att.charAt(0) === '@',
            });
            if (att.charAt(0) === '#') {
                toBeExploded.push(att);
            } else {
                namedList.push(att.replace('@', ''));
            }
        });
        this.selectedServiceAttributes = selectedEndpoints;
        this.getServicePreviewList(namedList, toBeExploded);
    }

    private getSelectedEdgeRouters() {
        const selectedEdgeRouters = new PagedAttributes();
        const toBeExploded = [];
        const namedList = [];
        this.model.edgeRouterAttributes.forEach((att) => {
            selectedEdgeRouters.mappedAtrributes.set(att, {
                name: att,
                isGroup: att.charAt(0) === '#',
                isNamed: att.charAt(0) === '@',
            });
            if (att.charAt(0) === '#') {
                toBeExploded.push(att);
            } else {
                namedList.push(att.replace('@', ''));
            }
        });
        this.selectedEdgeRouterAttributes = selectedEdgeRouters;
        this.getEdgeRouterPreviewList(namedList, toBeExploded);
    }

    private initializeEdgeRouterSelector() {
        this.isLoadingEdgeRouterAttr = true;
        this.edgeRouterError = false;
        this.getSelectedEdgeRouters();
        this.attributesService
            .getEdgeRouterAttributes(this.currentNetwork, false)
            .then((results: AttributesServiceResults) => {
                const collector = results.namedAttributes;
                this.allEdgeRouters = Array.from(results.namedAttributes.mappedAtrributes.values());
                if (this.isAllEdgeRouters) {
                    this.v2EdgeRouters = this.allEdgeRouters.map((rec) => rec.name.substr(1));
                }
                for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                    collector.mappedAtrributes.set(sa.name, sa);
                }
                this.edgeRouterAttributes = collector;
            })
            .catch((err) => {
                this.edgeRouterError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingEdgeRouterAttr = false;
                this.isLoading = this.isLoadingServiceAttr || this.isLoadingEdgeRouterAttr;
            });
    }
}
