<div class="modal solid open">
    <div (click)="close()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="title">Move Network Controller</div>
    <div class="subtitle">Move your network controller via provider and location or choose an Allocated IP.</div>
    <div class="icon" style="background-image: url(/assets/svgs/EditNetwork.svg)"></div>
    <div *ngIf="!hasAllocatedIps" class="row">
        <div id="ProviderField">
            <div class="label row">
                Network Provider
                <span class="specialnote">Required</span>
            </div>
            <select (change)="providerChanged($event.target.value)" [(ngModel)]="model.provider" id="Providers">
                <option *ngFor="let provider of providers" [value]="provider.type">
                    {{ provider.type }}
                </option>
            </select>
        </div>
        <div id="LocationField">
            <div class="label row">
                Network Location
                <span class="specialnote">Required</span>
            </div>
            <select [(ngModel)]="model.region" id="NetworkLocationSelect">
                <option [value]="undefined">Select a Region</option>
                <option *ngFor="let region of displayedRegions" [value]="region.locationCode">
                    {{ region.city }} {{ region.locationCode }}
                </option>
            </select>
        </div>
        <div class="warningLabel" style="padding-top: 30px">
            <p-message
                severity="warn"
                text="Please note that you are about to move this network controller to provider {{
                    this.model.provider
                }} and region {{ this.model.region }}."
            ></p-message>
        </div>
    </div>
    <div class="label buttonSwitch row">
        <p-selectButton (click)="dataSelection()" *ngIf="hasAllocatedIps" [options]="options" [(ngModel)]="value" optionLabel="label" optionValue="value"></p-selectButton>
    </div>

    <div *ngIf="value=='provider' && hasAllocatedIps" class="row providerRow">
        <div class="providerField" id="ProviderField">
            <div class="label row">
                Network Provider
                <span class="specialnote">Required</span>
            </div>
            <select (change)="providerChanged($event.target.value)" [(ngModel)]="model.provider" id="Providers">
                <option *ngFor="let provider of providers" [value]="provider.type">
                    {{ provider.type }}
                </option>
            </select>
        </div>
        <div id="LocationField">
            <div class="label row">
                Network Location
                <span class="specialnote">Required</span>
            </div>
            <select [(ngModel)]="model.region" id="NetworkLocationSelect">
                <option [value]="undefined">Select a Region</option>
                <option *ngFor="let region of displayedRegions" [value]="region.locationCode">
                    {{ region.city }} {{ region.locationCode }}
                </option>
            </select>
        </div>
        <div class="warningLabel" style="padding-top: 30px">
            <p-message
                severity="warn"
                text="Please note that you are about to move this network controller to provider {{
                    this.model.provider
                }} and region {{ this.model.region }}."
            ></p-message>
        </div>
    </div>
    <div *ngIf="value=='ipAddress'" class="row ipRow">
        <div class="ipField" id="IpField">
            <div class="label row">
                Allocated Ips
                <span class="specialnote">Required</span>
            </div>
            <select (change)="dataSelection()" [(ngModel)]="selectedIp" id="ips">
                <option [value]="undefined">Select an IP</option>
                <option *ngFor="let ip of allocatedIps" [ngValue]="ip">
                    {{ ip.ip }} {{ ip.provider }} {{  ip.region }}
                </option>
            </select>
        </div>
    </div>


    <button (click)="moveNetwork()" [disabled]="disableButton" class="save" id="CreateButton">Move</button>
</div>
