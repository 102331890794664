<div *ngIf="isZitiWarning" class="modal solid open" id="ZitiWarningModal">
    <div (click)="cancel()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div [ngClass]="{ noZiti: noZitiGateways }" class="title overrideFont">
        FYI, Ziti capable clients cannot interact with the following
        {{ typeString }}.{{ noZitiGateways ? ' ' + noZitiGatewayString : '' }}
    </div>
    <div class="icon" style="background-image: url(/assets/svgs/ZitiGateway.svg)"></div>

    <div [ngClass]="{ noZiti: noZitiGateways }" class="row">
        <div
            [ngClass]="{
                three: hasGatewaysAndServices,
                two: !hasGatewaysAndServices
            }"
            class="warningHeader"
        >
            <div>Clients</div>
            <div *ngIf="services && services.length > 0">Services</div>
            <div *ngIf="gateways && gateways.length > 0">Gateways</div>
        </div>
        <div
            [ngClass]="{
                three: hasGatewaysAndServices,
                two: !hasGatewaysAndServices
            }"
            class="warningRow"
        >
            <div>
                <div *ngFor="let item of this.clients" class="warning"></div>
            </div>
            <div class="warningCol">
                <div *ngFor="let item of this.clients">{{ item.name }}</div>
            </div>
            <div *ngIf="services && services.length > 0" class="warningCol">
                <div *ngFor="let item of this.services">
                    {{
                        item.name
                            ? item.name
                            : item.serviceClass === 'GW'
                            ? item.gatewayIp + (item.gatewayCidrBlock ? '/' + item.gatewayCidrBlock : '')
                            : item.networkIp
                    }}
                </div>
            </div>
            <div *ngIf="gateways && gateways.length > 0" class="warningCol">
                <div *ngFor="let item of this.gateways">
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
    <div class="buttons">
        <div (click)="cancel()" class="linkButton cancel" id="CancelZitiWarningButton">
            {{ cancelAction }}
        </div>
        <button (click)="close()" class="save" id="CloseWarningButton">OK, Thanks for the heads up, Proceed</button>
    </div>
</div>

<div *ngIf="!isZitiWarning" class="modal solid open" id="WarningListModal">
    <div (click)="cancel()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div [ngClass]="{ overrideFont: overrideFont }" class="title">
        {{ title }}
    </div>
    <div class="icon {{ icon }}"></div>

    <div class="row generic">
        <div [ngClass]="{ four: list2.length > 0, two: list2.length === 0 }" class="warningHeader">
            <div>{{ list1Title }}</div>
            <div *ngIf="list2.length > 0">{{ list2Title }}</div>
        </div>
        <div [ngClass]="{ four: list2.length > 0, two: list2.length === 0 }" class="warningRow">
            <div>
                <div *ngFor="let item of list1" class="warning"></div>
            </div>
            <div class="warningCol">
                <div *ngFor="let item of list1">{{ item.name }}</div>
            </div>

            <div>
                <div *ngFor="let item of list2" class="success"></div>
            </div>
            <div class="successCol">
                <div *ngFor="let item of list2">{{ item.name }}</div>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button (click)="close()" class="save" id="CloseWarningButton">OK, Thanks for the heads up, Proceed</button>
    </div>
</div>
