import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CollectorsFormComponent } from '@netfoundry-ui/feature/form/collectors-form';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Collector, NetworkGroup } from '@netfoundry-ui/shared/model';
import { CollectorService } from '@netfoundry-ui/shared/netflow';
import { ApiService, LoggerService, NetworkGroupService, RefresherService } from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

const columns = ['name', 'networkGroupName', 'ipAddress', 'port', 'protocol', 'createdAt', 'updatedAt'];
const filename = 'collectors';
const translateStatus = true;

@Component({
    selector: 'app-netflow-collectors',
    templateUrl: './netflow-collectors.component.html',
    styleUrls: ['./netflow-collectors.component.scss'],
})
export class NetflowCollectorsComponent implements OnInit, OnDestroy {
    model = {};
    dialogRef;
    typeName = 'Collector';
    items = [];
    displayedItems = [];
    itemCount = 0;
    showList = false;
    isLoading = true;
    allToggled = false;
    filterString = '';
    sorting = 'name';
    ordering = 'asc';
    hasSomeDeletePermission = true;
    pageSize = 25;
    page = 1;
    totalElements = 0;
    trackById;
    isDeleteAction = false;
    collectorSub: Subscription = new Subscription();
    currentOrgId;
    deletedCollectorIds = [];
    updatedCollectorId;
    updatedCollector;
    selectedCollectorIds = [];
    noDeleteAuthCount = 0;
    organizationMap = {};
    networkGroupIds = [];
    skipNetworkGroupIdsIn = false;
    openIndex = -1;
    private deleting = 0;
    private deleteMe = [];
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        public dialogForm: MatDialog,
        private apiService: ApiService,
        private collectorService: CollectorService,
        private refresher: RefresherService,
        private filterService: TableFilterService,
        public authorizationService: AuthorizationService,
        private organizationService: NetworkGroupService,
        private csvDownloadService: CsvDownloadService
    ) {}

    async ngOnInit() {
        // subscribing to the filter service to handle the change in page
        this.subscription.add(
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.page = pageNum;
            })
        );

        // subscribing to the filter service to handle search filter changes
        this.subscription.add(
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.filterString = filterString;
                this.applyFilter();
            })
        );

        if (this.authorizationService.canListOrganizations()) {
            await this.organizationService
                .get()
                .pipe(take(1))
                .toPromise()
                .then((res) => {
                    if (res.length > 1) {
                        this.skipNetworkGroupIdsIn = true;
                    }
                    for (const item of res) {
                        const org = new NetworkGroup(item);

                        this.organizationMap[org.id] = org.name;

                        this.networkGroupIds.push(org.id);
                    }
                });
        }

        this.collectorSub.closed = true;
        this.subscription.add(
            this.apiService.currentOrg.subscribe((result) => {
                this.deletedCollectorIds = [];
                this.updatedCollectorId = '';
                this.updatedCollector = null;
                this.selectedCollectorIds = [];
                this.noDeleteAuthCount = 0;

                this.currentOrgId = new NetworkGroup(result).id;
                this.handlePermissionCheck();
            })
        );
    }

    handlePermissionCheck() {
        // TODO add permission check
        if (this.authorizationService.canListNetflowCollectors()) {
            if (!this.collectorSub.closed) {
                let params;
                if (!this.skipNetworkGroupIdsIn) {
                    params = { networkGroupIdsIn: this.networkGroupIds };
                }

                this.collectorService.find(params);
            } else {
                this.initCollectors();
            }
        } else {
            this.collectorSub.unsubscribe();
            this.isLoading = false;
            this.showList = false;
            this.items = [];
        }
    }

    initCollectors() {
        this.collectorSub.unsubscribe();

        let params;
        if (!this.skipNetworkGroupIdsIn) {
            params = { networkGroupIdsIn: this.networkGroupIds };
        }

        this.collectorSub = this.collectorService.find(params).subscribe((result) => {
            // resetting isDeleteAction
            this.isDeleteAction = false;

            // getting the result as a list of groups
            const theResult = result as Collector[];
            this.items = [];
            for (let collector of theResult) {
                collector['networkGroupName'] = this.organizationMap[collector.networkGroupId];

                // if the gateway was just deleted, set the status to 800 as the status won't be updated the first refresh
                if (this.deletedCollectorIds.indexOf(collector.id) === -1) {
                    if (collector.id === this.updatedCollectorId) {
                        collector = this.updatedCollector;
                    }

                    const actions = [];

                    if (this.authorizationService.canDeleteNetflowCollector(collector.id)) {
                        actions.push('delete');
                        this.hasSomeDeletePermission = true;
                    } else {
                        this.noDeleteAuthCount++;
                    }

                    if (this.authorizationService.canUpdateNetflowCollector(collector.id)) {
                        actions.push('update');
                    }

                    collector['actionList'] = actions;

                    this.items.push(collector);
                }
            }

            this.updatedCollector = null;
            this.updatedCollectorId = null;

            // the deleted gateway ids are only needed for the first refresh. After the first refresh they should no longer appear
            //  as they should be deleted
            // if the gateway does appear again, then something went wrong with the delete and the status shouldn't be set to 800
            this.deletedCollectorIds = [];

            this.displayedItems = this.items;

            this.itemCount = this.items.length;
            this.showList = this.itemCount > 0;

            // bump up the refresh if the list is large and we haven't already bumped it
            if (this.itemCount > 100 && this.refresher.refreshInterval === this.refresher.getDefaultInterval()) {
                const mult = this.itemCount / 100 / 2;
                this.logger.info('Multiplier is: ' + mult);
                this.refresher.refreshInterval = this.refresher.refreshInterval * mult;
                this.logger.info('Multiplied refresh interval: ' + this.refresher.refreshInterval);
            }

            // obtaining the total number of gateways
            // if the clientService has a page object, set totalElements to page.totalElements
            // otherwise, set it to the length of the client list
            this.totalElements = this.items.length;

            // TODO - remove when pagination is fully implemented, this case should be handled by the first if statement in this subscription
            if (this.items.length === this.totalElements && this.items.length <= this.pageSize) {
                this.page = 1;
            }

            this.filterService.setTotalElements(this.totalElements);

            // TODO set total pages based on this.service.page.totalPages once using backend pagination
            this.filterService.updateTotalPages();

            this.applyFilter();
            this.isLoading = false;
            this.isDeleteAction = this.anyToggled();
        });

        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
    }

    applyFilter() {
        this.displayedItems = this.filterService.applyLocalFilter(this.items);
        const newSelectedCollectors = [];
        for (const collector of this.displayedItems) {
            if (this.selectedCollectorIds.indexOf(collector.id) > -1 || this.allToggled) {
                newSelectedCollectors.push(collector.id);
                collector.selected = true;
            } else {
                collector.selected = false;
            }
        }
        this.selectedCollectorIds = newSelectedCollectors;
        this.isDeleteAction = this.anyToggled();

        this.totalElements = this.displayedItems.length;

        // TODO - enabled when using pagination and sorting. Need to also determine when to send the request. currently this function is
        //          getting called on keyup of the search bar, which would cause this to send a request for every key they press
        /*
this.paginationFilter = this.filterString;
this.filterService.setTotalElements(this.gatewayService.page.totalElements);

// TODO set total pages based on this.service.page.totalPages once using backend pagination
this.filterService.updateTotalPages();

// determining whether or not to refresh the page
if(this.isUsingPagination()) {
this.page = 1;
this.refresh();
}
*/
    }

    anyToggled() {
        if (this.selectedCollectorIds.length > 0) {
            if (this.selectedCollectorIds.length === this.itemCount - this.noDeleteAuthCount) {
                this.allToggled = true;
            }
            return true;
        }
        this.allToggled = false;
        return false;
    }

    toggleAll() {
        this.allToggled = !this.allToggled;
        this.selectedCollectorIds = [];

        for (const item of this.displayedItems) {
            // TODO add check to see if item has delete permission
            //if (true) {
            item.selected = this.allToggled;
            if (this.allToggled) {
                this.selectedCollectorIds.push(item.id);
            }
            //}
        }

        this.isDeleteAction = this.anyToggled();
    }

    toggle(item: Collector) {
        if (item['actionList'].includes('delete')) {
            item.selected = !item.selected;

            if (this.allToggled) {
                this.allToggled = !this.allToggled;
            }

            const index = this.selectedCollectorIds.indexOf(item.id);
            if (index > -1) {
                this.selectedCollectorIds.splice(index, 1);
            } else {
                this.selectedCollectorIds.push(item.id);
            }
            this.isDeleteAction = this.anyToggled();
        }
    }

    getSortClass(id) {
        if (id === this.sorting) {
            return this.ordering;
        } else {
            return '';
        }
    }

    toggleMenu(event, index) {
        event.stopPropagation();
        if (index !== this.openIndex) {
            this.openIndex = index;
        } else {
            this.openIndex = -1;
        }
    }

    sort(sortBy) {
        if (this.sorting === sortBy) {
            if (this.ordering === 'asc') {
                this.ordering = 'desc';
            } else {
                this.ordering = 'asc';
            }
        } else {
            this.ordering = 'asc';
            this.sorting = sortBy;
        }
    }

    refresh() {
        this.refresher.disableRefresh();
        this.handlePermissionCheck();
        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
    }

    openConfirm(deleteEvent) {
        if (deleteEvent) {
            const deletingInfo = this.getDeletingTotal();

            const data = {
                title: 'Delete',
                appendId: 'Collectors',
                subtitle: deletingInfo.deleteString,
                bulletList: deletingInfo.names,
                icon: 'Delete',
                action: 'Yes',
            };
            this.dialogRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            this.dialogRef.afterClosed().subscribe((result) => {
                // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                //  logged out of the console and we should close the dialog without continuing
                if (result === undefined) {
                    this.confirmed(false);
                } else if (result['loggingOut'] === undefined) {
                    this.confirmed(result);
                }
            });
        }
    }

    create(e) {
        this.dialogRef = this.dialogForm.open(CollectorsFormComponent, {
            data: { currentOrgId: this.currentOrgId },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result && result['loggingOut'] === undefined) {
                this.refresh();
            }
        });
    }

    getDeletingTotal() {
        let total = 0;
        const names = [];
        for (const collector of this.displayedItems) {
            if (collector.selected) {
                names.push(collector);
                total++;
            }
        }

        let deleteString = 'Are you sure you would like to delete ';

        if (total > 1) {
            deleteString += `these ${total} Collectors:`;
        } else {
            deleteString += 'the following Collector:';
        }

        return {
            deleteString: deleteString,
            names: names,
        };
    }

    confirmDelete(item: Collector) {
        this.toggleAll();
        if (this.allToggled) {
            this.toggleAll();
        }

        item.selected = true;
        const index = this.selectedCollectorIds.indexOf(item.id);
        if (index === -1) {
            this.selectedCollectorIds.push(item.id);
        }
        this.isDeleteAction = true;
        this.openConfirm(true);
    }

    confirmed(event) {
        if (event) {
            this.refresher.disableRefresh();
            this.isLoading = true;
            this.deleting = this.selectedCollectorIds.length;
            for (const item of this.displayedItems) {
                if (item.selected) {
                    this.deleteMe[this.deleteMe.length] = item;
                }
            }
            this.deleteNext();
        } else {
            this.selectedCollectorIds = [];
            this.isDeleteAction = false;
            this.refresh();
        }
    }

    deleteNext() {
        this.deleting--;
        this.subscription.add(
            this.collectorService.delete(this.deleteMe[this.deleting]).subscribe(
                () => {
                    // if the delete was successful, add the id of the current client to the list of clients to be in deleting state
                    this.deletedCollectorIds.push(this.deleteMe[this.deleting].id);
                    this.postDelete();
                },
                () => {
                    this.postDelete();
                }
            )
        );
    }

    postDelete() {
        // forcing the deleted items to become untoggled
        this.toggle(this.deleteMe[this.deleting]);
        if (this.deleting === 0) {
            this.deleteMe = [];
            this.isLoading = true;
            this.refresh();
        } else {
            this.deleteNext();
        }
    }

    open(item: Collector) {
        if (this.authorizationService.canUpdateNetflowCollector(item.id)) {
            this.model = item;
            this.dialogRef = this.dialogForm.open(CollectorsFormComponent, {
                data: { model: this.model, currentOrgId: this.currentOrgId },
                minHeight: '100%',
                minWidth: '100%',
                height: '100%',
                width: '100%',
            });

            this.dialogRef.afterClosed().subscribe((result) => {
                // under normal circumstances nothing is returned when the dialog is closed
                //  however, if something is returned, it is because the user is being logged out while this dialog is opened
                //    if this is the case, we do not want to call the refresh function as the user will be unauthenticated
                if (result && result['loggingOut'] === undefined) {
                    if (result['newName'] != null) {
                        this.updatedCollector = result['collector'];
                        this.updatedCollectorId = item.id;
                    }
                    this.refresh();
                }
            });
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.collectorSub.unsubscribe();
        this.refresher.disableRefresh();
    }

    getOrgName(orgId) {
        return this.organizationMap[orgId];
    }

    closeActionMenu() {
        this.openIndex = -1;
    }

    downloadCsv(event: any) {
        const csvItems = [];

        for (const item of this.displayedItems) {
            if (item.selected) {
                csvItems.push(item);
            }
        }

        this.toggleMenu(event, -2);
        this.csvDownloadService.download(filename, csvItems, columns, translateStatus);
    }
}
