import { LoggerService } from '@netfoundry-ui/shared/services';

export abstract class ElasticsearchQuery {
    /**
     * Models defined in subsequent classes
     */
    protected abstract model;

    constructor(protected logger: LoggerService) {}

    /**
     * Adds a single key-value filter to the query
     */
    public addFilter(term: string, value: any) {
        const filter = { match_phrase: { [term]: { query: value } } };
        this.model.query.bool.must.push(filter);
    }

    /**
     * Add string-based text search
     * Example "message: thing" or "NOT mode: something"
     */
    public addSearch(query: string) {
        const search = {
            query_string: {
                query: query,
                analyze_wildcard: true,
            },
        };
        this.model.query.bool.must.push(search);
    }

    /**
     * Sets the start and end time for the query
     */
    public addTimeFilter(startTime: any, endTime: any, timefield = '@timestamp') {
        const range = { range: { [timefield]: { gte: startTime, lte: endTime, format: 'epoch_millis' } } };
        this.model.query.bool.must.push(range);
    }

    /**
     * Get the current query back
     */
    public getQuery() {
        return this.model;
    }

    /**
     * Get the current query back
     */
    public getModel() {
        return this.model;
    }

    /**
     * Adds to the must section of the query
     */
    public addMust(term: string, value: any) {
        const filter = { match_phrase: { [term]: { query: value } } };
        this.model.query.bool.must.push(filter);
    }

    /**
     * Adds to the should section of the query (use for lists)
     */
    public matchFromList(term: string, values: any[]) {
        const match = {
            bool: {
                minimum_should_match: 1,
                should: [],
            },
        };

        for (let i = 0; i < values.length; i++) {
            const filter = { match_phrase: { [term]: { query: values[i] } } };
            match.bool.should.push(filter);
        }

        this.model.query.bool.must.push(match);
    }

    /**
     * Adds to the exclusion filters
     */
    public addMustNot(term: string, value: any) {
        const filter = { match_phrase: { [term]: { query: value } } };
        this.model.query.bool.must_not.push(filter);
    }

    /**
     * Alias for Must NOT
     */
    public addExclusion(term: string, value: any) {
        const filter = { match_phrase: { [term]: { query: value } } };
        this.model.query.bool.must_not.push(filter);
    }

    /**
     * Allow the default model aggregration to be overridden
     */
    public aggregateOn(fieldName: string) {
        this.model.aggs[this.getAggregateName()].terms.field = fieldName;
    }

    /**
     * Determine what interval setting to use for time-based aggregations
     */
    public calculateInterval(startTime: number, endTime: number) {
        let interval;
        const gap = (endTime - startTime) / 1000;

        this.logger.info('Gap is: ', gap);

        // less than 6 hours, use minutes
        if (gap < 21600) {
            interval = '1m';
            // 24 or less, use 10 minute
        } else if (gap <= 86401) {
            interval = '5m';
            // 7 days or less
        } else if (gap <= 604800) {
            interval = '30m';
            // else use daily
        } else {
            interval = '1d';
        }

        // this.logger.info('Interval is: ' + interval)

        return interval;
    }

    /**
     * Sets response size limit (# of records)
     */
    public setSize(numRecs) {
        this.model.size = numRecs;
    }

    /**
     * Sets the aggregation time interval
     */
    public setInterval(interval) {
        this.model.aggs[this.getAggregateName()].date_histogram.interval = interval;
    }

    /**
     * Calculate AND SET the time interval
     */
    public determineInterval(startTime: number, endTime: number) {
        const interval = this.calculateInterval(startTime, endTime);
        this.setInterval(interval);
        return interval;
    }

    public disableAggregation() {
        delete this.model.aggs;
    }

    public sortBy(term, sortOrder) {
        this.model['sort'] = [{ [term]: { order: sortOrder } }];
    }

    /**
     * Index should be partnered with the query model
     */
    public abstract getIndexPattern();

    public abstract getAggregateName();
}
