import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ELASTIC_CONFIGURATION, ElasticConfig, ElasticsearchService } from './elasticsearch.service';

@NgModule({
    imports: [CommonModule, HttpClientModule],
    declarations: [],
    exports: [],
    providers: [],
})
export class SharedElasticsearchModule {
    static forRoot(config: ElasticConfig): ModuleWithProviders<SharedElasticsearchModule> {
        return {
            ngModule: SharedElasticsearchModule,
            providers: [ElasticsearchService, { provide: ELASTIC_CONFIGURATION, useValue: config }],
        };
    }
}
