import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-toggle-property-editor',
    templateUrl: './toggle-property-editor.component.html',
    styleUrls: ['./toggle-property-editor.component.scss'],
})
export class TogglePropertyEditorComponent {
    @Input() model = {};
    @Input() propertyName = '';
    @Input() label = '';
    @Input() disabled = false;
    @Input() required = false;
    @Input() errors = [];
    @Input() toolTip = '';
    @Input() description = '';

    @Output() modelChange: EventEmitter<any> = new EventEmitter();
    @Output() toggled: EventEmitter<any> = new EventEmitter();

    onClick() {
        if (this.disabled) {
            return;
        }
        this.model[this.propertyName] = !this.model[this.propertyName];
        this.toggled.emit(this.model[this.propertyName]);
    }
}
