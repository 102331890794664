import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DownloadEntitiesComponent } from '@netfoundry-ui/feature/download-entities';
import { ZitiEdgeRouterPolicyComponent } from '@netfoundry-ui/feature/form/ziti-edge-router-policy';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { EdgeRouterPolicyServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { EdgeRouterPolicyV2 } from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, LoggerService, RefresherService } from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TableHeaderDefaultComponent } from '@netfoundry-ui/feature/data-table';
import { JsonViewComponent } from '@netfoundry-ui/feature/json-view';
import { PagedGetOption, Sort, SortOrder } from '@lagoshny/ngx-hateoas-client';

const columns = [
    'name',
    'edgeRouterAttributes',
    'endpointAttributes',
    'createdAt',
    'createdBy',
    'updatedAt',
    'id',
    'ownerIdentityId',
    'networkId',
    'zitiId',
];
const asCreateColumns = ['name', 'edgeRouterAttributes', 'endpointAttributes'];

const filename = 'edge-router-policies';
const translateStatus = true;

const provisionedStatus = 'PROVISIONED';

@Component({
    selector: 'app-edge-router-policies',
    templateUrl: './edge-router-policies.component.html',
    styleUrls: ['./edge-router-policies.component.scss'],
    providers: [EdgeRouterPolicyServiceV2],
})
export class EdgeRouterPoliciesComponent implements OnInit, OnDestroy {
    model;
    isLoading = false;
    allToggled = false;
    filterString = '';
    sorting = 'name';
    ordering: SortOrder = 'ASC';
    dialogRef;
    itemDialogRef;
    isDeleteAction = false;
    page = 1;
    pageSize = 30;
    totalElements = 0;
    currentNetworkId = '';
    currentOrganizationId = '';
    hasSomeDeletePermission = true;
    filterHasChanged = false;
    filterApplied = false;
    showNoData = false;
    gatewayTypesArray = [];
    public edgeRouterPolicies = [];
    hideAppButton = false;

    public openIndex = -1;
    currentNetwork;
    changedPage = false;
    columnDefs;
    paramList: { size: number; sort: Sort } = {
        size: this.pageSize,
        sort: { name: 'ASC' },
    };

    columnFilters: any = {
        name: '',
        attributes: '',
        os: '',
        version: '',
        osVersion: '',
        osRelease: '',
        appId: '',
        appVersion: '',
        arch: '',
        mfaEnabled: undefined,
        typeId: '',
        sessionActive: undefined,
        hasApiSession: null,
    };

    updatedEdgeRouterPolicy;
    enableDeleteProtection;
    private deleting = 0;
    private subscription = new Subscription();
    private deleteMe = [];
    private selectedIds = [];
    private deletedIds = [];
    private inDeletingStatusCount = 0;
    private noDeleteAuthCount = 0;
    private openId = '';

    constructor(
        private logger: LoggerService,
        private edgeRouterPolicyService: EdgeRouterPolicyServiceV2,
        private refresher: RefresherService,
        public dialogForm: MatDialog,
        private growlerService: GrowlerService,
        public featureService: FeatureService,
        public authorizationService: AuthorizationService,
        public filterService: TableFilterService,
        private csvDownloadService: CsvDownloadService,
        public apiService: ApiService
    ) {
        this.initTableColumns();
    }

    initTableColumns() {
        const columnFilters = this.columnFilters;
        const headerComponentParams = {
            filterType: 'TEXTINPUT',
            columnFilters,
        };

        this.columnDefs = [
            {
                colId: 'name',
                width: 200,
                minWidth: 100,
                field: 'name',
                tooltipField: 'name',
                sortColumn: this.sort.bind(this),
                headerName: 'Name',
                onCellClicked: (params) => {
                    this.open(params.data);
                },
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'erAttributes',
                width: 200,
                minWidth: 100,
                field: 'edgeRouterAttributes',
                tooltipField: 'edgeRouterAttributes',
                sortable: false,
                headerName: 'Edge Router Attributes',
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                onCellClicked: (params) => {
                    this.open(params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'endpointAttributes',
                width: 150,
                minWidth: 100,
                field: 'endpointAttributes',
                sortable: false,
                headerComponent: TableHeaderDefaultComponent,
                onCellClicked: (params) => {
                    this.open(params.data);
                },
                headerName: 'Endpoint Attributes',
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
        ];

        if (this.featureService.powerUserEnabled) {
            this.columnDefs.push(
                {
                    colId: 'id',
                    width: 200,
                    minWidth: 100,
                    field: 'id',
                    tooltipField: 'id',
                    sortColumn: this.sort.bind(this),
                    headerName: 'UUID',
                    onCellClicked: (params) => {
                        this.copy(params.data);
                    },
                    headerComponent: TableHeaderDefaultComponent,
                    resizable: true,
                    hide: true,
                    cellClass: 'nf-cell-vert-align tCol',
                    filter: true,
                },
                {
                    colId: 'zitiId',
                    width: 200,
                    minWidth: 100,
                    field: 'zitiId',
                    tooltipField: 'id',
                    sortColumn: this.sort.bind(this),
                    headerName: 'Ziti ID',
                    onCellClicked: (params) => {
                        if (params?.nfTextSelected) {
                            return;
                        }
                        this.copyZiti(params.data);
                    },
                    headerComponent: TableHeaderDefaultComponent,
                    resizable: true,
                    hide: true,
                    cellClass: 'nf-cell-vert-align tCol',
                    filter: true,
                },
                {
                    colId: 'managed',
                    width: 100,
                    minWidth: 100,
                    cellRenderer: 'cellManagedComponent',
                    field: 'managed',
                    sortable: false,
                    hide: true,
                    headerComponent: TableHeaderDefaultComponent,
                    //headerComponentParams: managedParams,
                    onCellClicked: (params) => {
                        if (params?.nfTextSelected) {
                            return;
                        }
                        this.open(params.data);
                    },
                    headerName: 'Managed',
                    resizable: true,
                    cellClass: 'nf-cell-vert-align tCol',
                    filter: true,
                }
            );
        }
    }

    ngOnInit() {
        this.filterService.setPageSize(this.pageSize);

        // subscribing to the filter service to handle the change in page
        this.subscription.add(
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.page = pageNum;
                this.changedPage = true;
                this.isLoading = true;
                this.refresh();
            })
        );

        // subscribing to the filter service to handle search filter changes
        this.subscription.add(
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.filterString = filterString;
                this.isfilterApplied();
                this.refresh();
            })
        );

        this.isLoading = true;
        this.subscription.add(
            this.apiService.currentNetwork.pipe(debounceTime(1000)).subscribe((network) => {
                this.currentNetwork = network;
                this.isLoading = true;
                this.filterService.setPage(1, true);
                this.page = 1;
                this.handlePermissionCheck();
            })
        );
        this.subscription.add(
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.page = pageNum;
            })
        );
    }

    handlePermissionCheck() {
        if (this.authorizationService.canListEdgeRouterPolicies()) {
            this.getEdgeRouterPolicies();
        } else {
            this.isLoading = false;
            this.edgeRouterPolicies = [];
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.filterService.reset();
        this.refresher.disableRefresh();
    }

    refresh() {
        this.refresher.disableRefresh();
        this.handlePermissionCheck();
    }

    toggleAll() {
        if (this.featureService.enableDeleteProtection) {
            return;
        }
        this.allToggled = !this.allToggled;
        this.selectedIds = [];

        for (const item of this.edgeRouterPolicies) {
            // TODO - check status?
            // check
            item.selected = this.allToggled;
            if (this.allToggled) {
                this.selectedIds.push(item.id);
            }
        }

        this.isDeleteAction = this.anyToggled();
    }

    anyToggled() {
        if (this.selectedIds.length > 0) {
            if (
                this.selectedIds.length ===
                Math.min(this.pageSize, this.totalElements) - this.inDeletingStatusCount - this.noDeleteAuthCount
            ) {
                this.allToggled = true;
            } else {
                this.allToggled = false;
            }
            return true;
        }
        this.allToggled = false;
        return false;
    }

    toggleMenu(event, index) {
        event.stopPropagation();
        if (index !== this.openIndex) {
            this.openIndex = index;
        } else {
            this.openIndex = -1;
        }
    }

    toggle(item: EdgeRouterPolicyV2) {
        item['selected'] = !item['selected'];
        this.enableDeleteProtection = this.featureService.enableDeleteProtection;

        if (this.allToggled) {
            this.allToggled = !this.allToggled;
        }

        const index = this.selectedIds.indexOf(item.id);
        if (index > -1) {
            this.selectedIds.splice(index, 1);
        } else {
            this.selectedIds.push(item.id);
        }
        if (!this.enableDeleteProtection) {
            this.isDeleteAction = true;
            this.hideAppButton = false;
        }
        if (this.selectedIds.length > 1 && this.enableDeleteProtection) {
            this.isDeleteAction = false;
            this.hideAppButton = true;
        }
        if (this.selectedIds.length === 1 && this.enableDeleteProtection) {
            this.isDeleteAction = true;
            this.hideAppButton = false;
        }
        if (this.selectedIds.length === 0) {
            this.hideAppButton = false;
            this.isDeleteAction = false;
        }
    }

    deleteSingleItem(item: EdgeRouterPolicyV2) {
        const subtitle = 'Are you sure you would like to delete the following edge router policy?:';

        this.enableDeleteProtection = this.featureService.enableDeleteProtection;

        const data = {
            title: 'Delete',
            appendId: 'Edge Router Policy',
            subtitle: subtitle,
            bulletList: [item],
            icon: 'Delete',
            action: 'Yes',
            isDestructive: this.enableDeleteProtection,
            itemName: item.name,
        };
        this.dialogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '340px',
            width: '600px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                // User canceled. Do nothing.
            } else if (result['loggingOut'] === undefined) {
                this.deletedIds = [];
                this.edgeRouterPolicyService.deleteResource(item).subscribe(
                    (data) => {
                        this.deletedIds.push(item.id);
                        this.deleteMe = [];
                        this.isLoading = true;
                        this.refresh();
                    },
                    (error) => {
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Edge Router Policy failed to delete',
                                'The selected edge router policy failed to delete from the system'
                            )
                        );
                    }
                );
            }
        });
    }

    confirmDelete(item: EdgeRouterPolicyV2) {
        this.toggleAll();
        if (this.allToggled) {
            this.toggleAll();
        }
        item['selected'] = true;
        const index = this.selectedIds.indexOf(item.id);
        if (index === -1) {
            this.selectedIds.push(item.id);
        }
        this.isDeleteAction = true;
        this.openConfirm(item);
    }

    openConfirm(item: EdgeRouterPolicyV2) {
        setTimeout(() => {
            const deletingInfo = this.getDeletingTotal();
            this.enableDeleteProtection = this.featureService.enableDeleteProtection;

            const data = {
                title: 'Delete',
                appendId: 'Router',
                subtitle: deletingInfo.deleteString,
                bulletList: deletingInfo.names,
                icon: 'Delete',
                action: 'Yes',
                isDestructive: this.enableDeleteProtection,
                itemName: deletingInfo.names[0].name,
            };

            this.dialogRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            this.dialogRef.afterClosed().subscribe((result) => {
                // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                //  logged out of the console and we should close the dialog without continuing
                if (result === undefined) {
                    this.confirmed(false);
                } else if (result['loggingOut'] === undefined) {
                    this.confirmed(result);
                }
            });
        });
    }

    copy(item: EdgeRouterPolicyV2) {
        navigator.clipboard.writeText(item.id);
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'ER Policy Id Copied',
                'The edgeRouterPolicyId has been copied to your clipboard'
            )
        );
    }

    copyZiti(item: EdgeRouterPolicyV2) {
        navigator.clipboard.writeText(item.zitiId);
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Appwan ziti Id Copied',
                'The appwan zitiId has been copied to your clipboard'
            )
        );
    }

    confirmed(event) {
        if (event) {
            this.refresher.disableRefresh();
            this.isLoading = true;
            this.deleting = this.getTotalSelected();
            for (const item of this.edgeRouterPolicies) {
                if (item.selected) {
                    this.deleteMe[this.deleteMe.length] = item;
                }
            }
            this.deleteNext();
        } else {
            this.selectedIds = [];
            this.isDeleteAction = false;
            this.refresh();
        }
    }

    deleteNext() {
        this.deleting--;
        this.subscription.add(
            this.edgeRouterPolicyService.deleteResource(this.deleteMe[this.deleting]).subscribe(
                (data) => {
                    // if the delete was successful, add the id of the current appwan to the list of appwans to be in deleting state
                    this.deletedIds.push(this.deleteMe[this.deleting].id);
                    this.postDelete();
                },
                (error) => {
                    this.postDelete();
                }
            )
        );
    }

    postDelete() {
        // forcing the deleted items to become untoggled
        this.toggle(this.deleteMe[this.deleting]);

        if (this.deleting === 0) {
            this.deleteMe = [];
            this.isLoading = true;
            this.refresh();
        } else {
            this.deleteNext();
        }
    }

    getTotalSelected() {
        return this.selectedIds.length;
    }

    getDeletingTotal() {
        let total = 0;
        const names = [];
        for (const item of this.edgeRouterPolicies) {
            if (item.selected) {
                names.push(item);
                total++;
            }
        }

        let deleteString = 'Are you sure you would like to delete ';

        if (total > 1) {
            deleteString += `these ${total} Edge Router Policies:`;
        } else {
            deleteString += 'the following Edge Router Policy:';
        }

        return {
            deleteString: deleteString,
            names: names,
        };
    }

    create(addEvent) {
        if (addEvent && this.authorizationService.canCreateEdgeRouterPolicies()) {
            if (this.currentNetwork.status === provisionedStatus) {
                this.itemDialogRef = this.dialogForm.open(ZitiEdgeRouterPolicyComponent, {
                    data: {},
                    minHeight: '100%',
                    minWidth: '100%',
                    height: '100%',
                    width: '100%',
                });
                this.itemDialogRef.afterClosed().subscribe((result) => {
                    if (!result || (result && result['loggingOut'] === undefined)) {
                        this.isLoading = true;
                        this.refresh();
                    }
                });
            } else {
                if (_.isEmpty(this.currentNetwork?.id)) {
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'No Network',
                            'Must create a network in order to add an Edge Router Policy.'
                        )
                    );
                } else {
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Network Not Ready',
                            'The Network must be provisioned in order to add an Edge Router Policy.'
                        )
                    );
                }
            }
        }
    }

    open(item: EdgeRouterPolicyV2) {
        this.model = item;
        if (this.currentNetwork.status === provisionedStatus) {
            this.dialogRef = this.dialogForm.open(ZitiEdgeRouterPolicyComponent, {
                data: {
                    model: this.model,
                },
                minHeight: '100%',
                minWidth: '100%',
                height: '100%',
                width: '100%',
            });

            this.dialogRef.afterClosed().subscribe((result) => {
                // under normal circumstances nothing is returned when the dialog is closed
                //  however, if something is returned, it is because the user is being logged out while this dialog is opened
                //    if this is the case, we do not want to call the refresh function as the user will be unauthenticated
                if (result && result['loggingOut'] === undefined) {
                    if (result['updatedEdgeRouterPolicy'] != null) {
                        this.updatedEdgeRouterPolicy = result['updatedEdgeRouterPolicy'];
                        this.updatedEdgeRouterPolicy['actionList'] = item['actionList'];
                        this.isLoading = true;
                        this.refresh();
                    }
                }
                // if user tries to edit the the values and enter wrong parameters, and then if he clicks on close button,
                // the list will get updated with wrong values until refresh. We need to implement clone operation in edit page to fix this issue
                if (result === null || result === undefined) {
                    this.refresh();
                }
            });
        } else {
            if (_.isEmpty(this.currentNetwork?.id)) {
                this.growlerService.show(
                    new GrowlerData(
                        'error',
                        'Error',
                        'No Network',
                        'Must create a network in order to add an Edge Router Policy.'
                    )
                );
            } else {
                this.growlerService.show(
                    new GrowlerData(
                        'error',
                        'Error',
                        'Network Not Ready',
                        'The Network must be provisioned in order to edit an Edge Router Policy.'
                    )
                );
            }
        }
    }

    closeActionMenu() {
        this.openIndex = -1;
    }

    filterChanged(columnId, value) {
        if (columnId === 'name') {
            this.filterString = value;
        }
        _.set(this.columnFilters, columnId, value);
        this.isLoading = true;
        this.isfilterApplied();
        this.shouldShowNoData();
        this.refresh();
    }

    isfilterApplied() {
        this.filterHasChanged = true;
        this.filterApplied = _.some(this.columnFilters, (value, filterName) => !_.isEmpty(_.toString(value)));
    }

    shouldShowNoData() {
        this.showNoData = this.edgeRouterPolicies.length <= 0 && !this.filterApplied && !this.isLoading;
    }

    sort(sortBy, ordering) {
        if (this.sorting === sortBy) {
            this.ordering = ordering;
        } else {
            this.ordering = 'ASC';
            this.sorting = sortBy;
        }

        this.changedPage = true;

        const s: Sort = {};
        s[this.sorting] = this.ordering;
        this.paramList.sort = s;
        this.refresh();
    }

    getSortClass(id) {
        if (id === this.sorting) {
            return this.ordering;
        } else {
            return '';
        }
    }

    trackById(index, item) {
        return item.id;
    }

    getEdgeRouterPolicies() {
        if (_.isEmpty(this.currentNetwork?.id)) {
            this.showNoData = true;
            this.isLoading = false;
            return;
        }
        if (this.filterString && this.page !== 1 && !this.changedPage) {
            this.changedPage = true;
            this.isLoading = true;
            this.filterService.setPage(1, true);
            this.page = 1;
        }

        this.changedPage = false;
        this.edgeRouterPolicyService
            .getEdgeRouterServicePolicyPage(this.getOptions())
            .then(
                (edgeRouterPolicies) => {
                    const newTotalPages = this.edgeRouterPolicyService.lastPageCount;

                    // if the number of pages has decreased and the user is past the last page now
                    if (this.page > newTotalPages) {
                        // move back one page
                        this.filterService.setPage(newTotalPages);
                    } else {
                        this.inDeletingStatusCount = 0;
                        const theResult = edgeRouterPolicies as EdgeRouterPolicyV2[];
                        let newHasSomeDeletePermission = false;
                        this.noDeleteAuthCount = 0;

                        let updatedEdgeRouterPolicyId;
                        if (this.updatedEdgeRouterPolicy != null) {
                            updatedEdgeRouterPolicyId = this.updatedEdgeRouterPolicy.id;
                        }
                        const newSelectedIds = [];
                        let index = 0;
                        for (const edgeRouterPolicy of theResult) {
                            if (this.openId === edgeRouterPolicy.id) {
                                this.open(edgeRouterPolicy);
                                this.openId = '';
                            }

                            if (edgeRouterPolicy.id === updatedEdgeRouterPolicyId) {
                                this.updatedEdgeRouterPolicy.selected = edgeRouterPolicy['selected'];
                                this.updatedEdgeRouterPolicy.status = edgeRouterPolicy['status'];
                                theResult[index] = this.updatedEdgeRouterPolicy;
                            }

                            if (this.selectedIds.indexOf(edgeRouterPolicy.id) > -1) {
                                edgeRouterPolicy.selected = true;
                                newSelectedIds.push(edgeRouterPolicy.id);
                            }

                            const actions = [];

                            // TODO add permission checks
                            if (this.authorizationService.canDeleteEdgeRouterPolicy(edgeRouterPolicy.id)) {
                                actions.push('delete');
                                newHasSomeDeletePermission = true;
                            } else {
                                this.noDeleteAuthCount++;
                            }

                            // TODO add permission checks
                            if (this.authorizationService.canUpdateEdgeRouterPolicy(edgeRouterPolicy.id)) {
                                actions.push('update');
                            }

                            if (this.featureService.powerUserEnabled) {
                                actions.push('json');
                            }
                            edgeRouterPolicy['actionList'] = actions;

                            index++;
                        }
                        this.selectedIds = newSelectedIds;

                        this.updatedEdgeRouterPolicy = null;
                        // the deleted service ids are only needed for the first refresh. After the first refresh they should no longer appear
                        // if the service does appear again, then something went wrong with the delete and the status shouldn't be set to 800
                        this.deletedIds = [];
                        this.edgeRouterPolicies = theResult;

                        // todo handle pagination
                        this.totalElements = this.edgeRouterPolicyService.lastTotalCount;

                        this.filterService.setTotalElements(this.totalElements);
                        this.filterService.setTotalElements(this.edgeRouterPolicyService.lastTotalCount);
                        this.filterService.setTotalPages(this.edgeRouterPolicyService.lastPageCount);

                        this.isDeleteAction = this.anyToggled();
                        this.hasSomeDeletePermission = newHasSomeDeletePermission;

                        this.isLoading = false;
                        this.logger.info('retrieved edge router policies for network');
                    }
                },
                (error) => {
                    this.logger.info(error);
                    this.isLoading = false;
                }
            )
            .finally(() => {
                this.filterHasChanged = false;
                this.isLoading = false;
                this.changedPage = false;
                this.shouldShowNoData();
            });

        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
    }

    downloadCsv(event: any) {
        const csvItems = [];
        const asCreateItems = [];

        for (const item of this.edgeRouterPolicies) {
            if (item.selected) {
                const asCreateItem = _.pickBy(item, function (value, key) {
                    return _.includes(asCreateColumns, key);
                });
                csvItems.push(item);
                asCreateItems.push(asCreateItem);
            }
        }

        this.toggleMenu(event, -2);
        this.dialogRef = this.dialogForm.open(DownloadEntitiesComponent, {
            data: {
                model: this.model,
                downloadService: this.csvDownloadService,
                currentNetwork: this.currentNetwork,
                downloadAll: false,
                entityDownloadService: this.edgeRouterPolicyService.downloadFileFormat.bind(
                    this.edgeRouterPolicyService
                ),
                entityType: 'Edge Router Policies',
                fileName: filename,
                items: csvItems,
                asCreateItems: asCreateItems,
                columns: columns,
                translateStatus,
            },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
    }

    openJson(item: EdgeRouterPolicyV2) {
        this.edgeRouterPolicyService.getJson(item.id).subscribe((result) => {
            this.dialogRef = this.dialogForm.open(JsonViewComponent, {
                data: {
                    model: result,
                    resourceType: 'endpoint',
                    networkId: this.currentNetwork.id,
                    networkGroupId: this.currentNetwork.networkGroupId,
                },
                height: '800px',
                width: '1200px',
            });
        });
    }

    buildEdgeRouterPoliciesSearchString(item: any) {
        const searchStringBuilder = [];
        searchStringBuilder.push(item.name, '|');
        if (item.serviceAttributes) {
            searchStringBuilder.push(item.serviceAttributes, '|');
        }

        if (item.endpointAttributes) {
            searchStringBuilder.push(item.endpointAttributes, '|');
        }
        return searchStringBuilder.join('').toLocaleLowerCase();
    }

    downloadAllToCsv(event) {
        this.dialogRef = this.dialogForm.open(DownloadEntitiesComponent, {
            data: {
                model: this.model,
                downloadService: this.csvDownloadService,
                currentNetwork: this.currentNetwork,
                downloadAll: true,
                entityDownloadService: this.edgeRouterPolicyService.downloadFileFormat.bind(
                    this.edgeRouterPolicyService
                ),
                entityType: 'Edge Router Policies',
                fileName: 'edge-router-policies',
            },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
    }

    getOptions(): PagedGetOption {
        const options = {
            params: {
                networkId: this.currentNetwork.id,
                isSystem: false,
            },
            pageParams: {
                size: this.paramList['size'],
                page: this.page - 1,
            },
            sort: this.paramList['sort'],
        };

        if (this.filterString) {
            const escapedChars = this.filterString.replace('%', '\\%').replace('_', '\\_');
            options.params['name'] = `%${escapedChars}%`;
        }

        return options;
    }
}
