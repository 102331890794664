<div class="modal solid open fade-in">
    <div class="fullColor"></div>
    <div class="row" style="margin-top: 10px">
        <div class="col-sm-4 pl-10">
            <div *ngIf="resourceType !== 'edge-router'" class="cardStack full" style="height: 150px">
                <mat-tab-group class="darkMode" dynamicHeight mat-align-tabs="start">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom">Utilization Total</span>
                        </ng-template>
                        <app-utilization-totals-box
                            [aggregateField]="templateService.usage_aggregate_field"
                            [dateFilter]="dateFilter"
                            [filterField]="filterType"
                            [filterValue]="filterValue"
                            [label]="'Total Utilization'"
                            [networkId]="networkId"
                            [newEndTime]="endTime"
                            [newStartTime]="startTime"
                            [organizationId]="networkGroupId"
                        >
                        </app-utilization-totals-box>
                    </mat-tab>
                </mat-tab-group>
            </div>

            <div *ngIf="resourceType === 'service'" class="cardStack full" style="height: 185px">
                <mat-tab-group class="darkMode" dynamicHeight mat-align-tabs="start">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom">Service Dial Health</span>
                        </ng-template>
                        <app-service-health-pie
                            [dateFilter]="dateFilter"
                            [networkGroupId]="networkGroupId"
                            [networkId]="networkId"
                            [serviceId]="model.id"
                        >
                        </app-service-health-pie>
                    </mat-tab>
                </mat-tab-group>
            </div>

            <div *ngIf="resourceType !== 'endpoint'" class="cardStack full" style="height: 350px">
                <div
                    (click)="downloadEndpointCsv()"
                    *ngIf="endpointItems"
                    class="metricDownload buttonAction export"
                    id="downloadEndpointcsv"
                ></div>
                <mat-tab-group class="darkMode" dynamicHeight mat-align-tabs="start">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom">{{ labelEndpoint }}</span>
                        </ng-template>

                        <div>
                            <app-ziti-utilization-table
                                (dataReceived)="receivedEndpointItems($event)"
                                [dateFilter]="dateFilter"
                                [filterField]="filterType"
                                [filterValue]="filterValue"
                                [networkId]="networkId"
                                [newEndTime]="endTime"
                                [newStartTime]="startTime"
                                [organizationId]="networkGroupId"
                                [selectedAttributes]="[]"
                                [aggregateField]="templateService.identity_aggregate_field"
                            ></app-ziti-utilization-table>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="hostingEndpointData">
                        <ng-template mat-tab-label>
                            <span class="title nobottom">Hosting Endpoint</span>
                        </ng-template>
                        <div>
                            <app-ziti-utilization-table
                                (dataReceived)="receivedEndpointItems($event)"
                                (filterItemOutput)="setFilter(eventFields.hostName, $event)"
                                [filterField]="filterType"
                                [filterValue]="filterValue"
                                [networkId]="currentNetwork?.id"
                                [newEndTime]="endTime"
                                [newStartTime]="startTime"
                                [organizationId]="currentNetwork?.networkGroupId"
                                [selectedAttributes]="[]"
                                [aggregateField]="templateService.host_aggregate_field"
                            ></app-ziti-utilization-table>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>

            <div *ngIf="resourceType !== 'service'" class="cardStack full" style="height: 350px">
                <div
                    (click)="downloadServiceCsv()"
                    *ngIf="serviceItems"
                    class="metricDownload buttonAction export"
                    id="downloadServicecsv"
                ></div>
                <mat-tab-group class="darkMode" dynamicHeight mat-align-tabs="start">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom">Usage By Service</span>
                        </ng-template>
                        <div>
                            <app-ziti-utilization-table
                                (dataReceived)="receivedServiceItems($event)"
                                [dateFilter]="dateFilter"
                                [filterField]="filterType"
                                [filterValue]="filterValue"
                                [locked]="serviceTableLocked"
                                [networkId]="networkId"
                                [newEndTime]="endTime"
                                [newStartTime]="startTime"
                                [organizationId]="networkGroupId"
                                [selectedAttributes]="[]"
                                [aggregateField]="templateService.service_aggregate_field"
                            >
                            </app-ziti-utilization-table>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>

        <!-- start right wide column -->
        <div class="col-sm-8 pr-0">
            <div class="row pt0">
                <div class="cardStack full" id="filterCard">
                    <div class="filtertitle">Filters</div>

                    <div style="width: 330px; display: inline-block">
                        <mat-chip-listbox aria-label="DatePicker">
                            <mat-chip-option (click)="updateStart('1h')" [selected]="dateFilter === '1h'"
                                >1h</mat-chip-option
                            >
                            <mat-chip-option (click)="updateStart('6h')" [selected]="dateFilter === '6h'"
                                >6h</mat-chip-option
                            >
                            <mat-chip-option (click)="updateStart('24h')" [selected]="dateFilter === '24h'"
                                >24h</mat-chip-option
                            >
                            <mat-chip-option (click)="updateStart('3d')" [selected]="dateFilter === '3d'"
                                >3d</mat-chip-option
                            >
                            <mat-chip-option (click)="updateStart('7d')" [selected]="dateFilter === '7d'"
                                >7d</mat-chip-option
                            >
                            <mat-chip-option (click)="updateStart('30d')" [selected]="dateFilter === '30d'"
                                >30d</mat-chip-option
                            >
                        </mat-chip-listbox>
                    </div>
                    <div id="resource-filter">
                        <mat-chip-listbox *ngIf="filterValue">
                            <mat-chip-option selected title="{{ model.name }}">{{ model.name }}</mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                    <div class="absoluteTimeRanges">
                        <p-calendar
                            (onClose)="closeCalendar()"
                            (onSelect)="closeCalendar()"
                            [(ngModel)]="rangeDates"
                            [readonlyInput]="false"
                            [showTime]="true"
                            hourFormat="12"
                            selectionMode="range"
                            inputId="range"
                            [showTime]="true"
                            [minDate]="minimumDate"
                            placeholder="Select Time Frame"
                        ></p-calendar>
                    </div>
                </div>
            </div>

            <!-- top widget -->
            <div class="row" style="padding: 10px">
                <div class="cardStack full mb0" style="height: 324px">
                    <mat-tab-group class="darkMode" mat-align-tabs="start">
                        <mat-tab *ngIf="resourceType === 'service'">
                            <ng-template mat-tab-label>
                                <span class="title nobottom">Service Dial Health</span>
                            </ng-template>
                            <div>
                                <ng-template matTabContent>
                                    <app-service-health-timeline
                                        [dateFilter]="dateFilter"
                                        [height]="'270px'"
                                        [networkGroupId]="networkGroupId"
                                        [networkId]="networkId"
                                        [serviceId]="model.id"
                                    ></app-service-health-timeline>
                                </ng-template>
                            </div>
                        </mat-tab>

                        <mat-tab>
                            <ng-template mat-tab-label>
                                <span class="title nobottom">Top Usage By Resource Type</span>
                            </ng-template>
                            <div class="fullSized">
                                <ng-template matTabContent>
                                    <div class="{{topUsageGridStyleClass}}">
                                        <div *ngIf="resourceType !== 'endpoint'">
                                            <app-ziti-utilization-pie-mini
                                                [aggregateField]="templateService.identity_aggregate_field"
                                                [dateFilter]="dateFilter"
                                                [filterField]="filterType"
                                                [filterValue]="filterValue"
                                                [height]="'270px'"
                                                [label]="labelEndpoint"
                                                [networkId]="networkId"
                                                [newEndTime]="endTime"
                                                [newStartTime]="startTime"
                                                [organizationId]="networkGroupId"
                                                [selectedAttributes]="[]"
                                            >
                                            </app-ziti-utilization-pie-mini>
                                        </div>

                                        <div *ngIf="resourceType !== 'service'">
                                            <app-ziti-utilization-pie-mini
                                                [aggregateField]="templateService.service_aggregate_field"
                                                [dateFilter]="dateFilter"
                                                [filterField]="filterType"
                                                [filterValue]="filterValue"
                                                [height]="'270px'"
                                                [label]="'Services'"
                                                [networkId]="networkId"
                                                [newEndTime]="endTime"
                                                [newStartTime]="startTime"
                                                [organizationId]="networkGroupId"
                                                [selectedAttributes]="[]"
                                            >
                                            </app-ziti-utilization-pie-mini>
                                        </div>

                                        <div *ngIf="resourceType !== 'edge-router'">
                                            <app-ziti-utilization-pie-mini
                                                [aggregateField]="templateService.edge_router_aggregate_field"
                                                [dateFilter]="dateFilter"
                                                [filterField]="filterType"
                                                [filterValue]="filterValue"
                                                [height]="'270px'"
                                                [label]="label"
                                                [networkId]="networkId"
                                                [newEndTime]="endTime"
                                                [newStartTime]="startTime"
                                                [organizationId]="networkGroupId"
                                                [selectedAttributes]="[]"
                                            >
                                            </app-ziti-utilization-pie-mini>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>

            <div class="row">
                <div class="cardStack full" style="height: 324px">
                    <mat-tab-group class="darkMode" mat-align-tabs="start">
                        <mat-tab *ngIf="resourceType !== 'endpoint'">
                            <ng-template mat-tab-label>
                                <span class="title nobottom">Usage By Identities</span>
                            </ng-template>
                            <div>
                                <ng-template matTabContent>
                                    <app-ziti-utilization-stacked
                                        [dateFilter]="dateFilter"
                                        [filterField]="filterType"
                                        [filterValue]="filterValue"
                                        [height]="'270px'"
                                        [networkGroupId]="networkGroupId"
                                        [networkId]="networkId"
                                        [selectedAttributes]="[]"
                                        [newStartTime]="startTime"
                                        [newEndTime]="endTime"
                                        [stackBy]="templateService.identity_aggregate_field"
                                    >
                                    </app-ziti-utilization-stacked>
                                </ng-template>
                            </div>
                        </mat-tab>

                        <mat-tab *ngIf="resourceType !== 'service'" label="Usage By Services">
                            <div>
                                <ng-template matTabContent>
                                    <app-ziti-utilization-stacked
                                        [dateFilter]="dateFilter"
                                        [filterField]="filterType"
                                        [filterValue]="filterValue"
                                        [height]="'270px'"
                                        [networkGroupId]="networkGroupId"
                                        [networkId]="networkId"
                                        [newEndTime]="endTime"
                                        [newStartTime]="startTime"
                                        [selectedAttributes]="[]"
                                        [stackBy]="templateService.service_aggregate_field"
                                    >
                                    </app-ziti-utilization-stacked>
                                </ng-template>
                            </div>
                        </mat-tab>

                        <mat-tab *ngIf="resourceType !== 'edge-router'" label="Usage By {{ label }}">
                            <div>
                                <ng-template matTabContent>
                                    <app-ziti-utilization-stacked
                                        [dateFilter]="dateFilter"
                                        [filterField]="filterType"
                                        [filterValue]="filterValue"
                                        [height]="'270px'"
                                        [networkGroupId]="networkGroupId"
                                        [networkId]="networkId"
                                        [newEndTime]="endTime"
                                        [newStartTime]="startTime"
                                        [selectedAttributes]="[]"
                                        [stackBy]="aggregateField"
                                    >
                                    </app-ziti-utilization-stacked>
                                </ng-template>
                            </div>
                        </mat-tab>

                        <mat-tab label="Usage By Direction">
                            <div>
                                <ng-template matTabContent>
                                    <app-ziti-utilization-basic
                                        [dateFilter]="dateFilter"
                                        [filterField]="filterType"
                                        [filterValue]="filterValue"
                                        [height]="'280px'"
                                        [networkGroupId]="networkGroupId"
                                        [networkId]="networkId"
                                        [newEndTime]="endTime"
                                        [newStartTime]="startTime"
                                    ></app-ziti-utilization-basic>
                                </ng-template>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>

            <div class="row" style="clear: both"></div>

            <div class="row" style="clear: both">&nbsp;</div>
        </div>
    </div>
    <div (click)="close()" class="closeButton icon-HamburgerAppClose" id="EscZEndpointClose"></div>
</div>
