import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-endpoints-bulk-upload-save',
    templateUrl: './endpoints-bulk-upload-save.component.html',
    styleUrls: ['./endpoints-bulk-upload-save.component.scss'],
})
export class EndpointsBulkUploadSaveComponent implements OnInit, OnDestroy {
    @Input() businessKey;
    @Input() networkId;
    @Input() endpoints = [];
    @Input() type = 'Endpoints';
    @Input() gatewayClusters = [];

    endpointNames = null;
    network;
    endpointsStatus = [];
    message;
    timeout = 5000;
    stepStyle;
    isPageActive = true;

    constructor(public apiService: ApiService, private logger: LoggerService) {}

    ngOnInit() {
        this.message = this.type + ' As Code Upload Process Initiated';
    }

    ngOnDestroy() {
        // to exit from the loop in the async function, when user navigates away from the page
        this.isPageActive = false;
    }

    async fetchStatus() {
        this.endpointsStatus = [];
        this.stepStyle = 'step0';

        this.message = this.type + ' As Code Upload Process Initiated';

        let isCompleted = null;
        while (isCompleted == null && this.isPageActive) {
            isCompleted = await this.fetchEndpointsAsCodeStatus();
        }
    }

    fetchEndpointsAsCodeStatus(): Promise<boolean> {
        return new Promise((resolve, reject): any => {
            this.logger.info('business Key ' + this.businessKey);

            const endpointNamesArray = [];
            const endpointsLength = this.endpoints !== undefined ? this.endpoints.length : 0;
            const gatewayClustersLength = this.gatewayClusters !== undefined ? this.gatewayClusters.length : 0;

            const maxSixe = endpointsLength + gatewayClustersLength > 3 ? 3 : endpointsLength + gatewayClustersLength;
            for (let i = 0; i < endpointsLength && i < maxSixe; i++) {
                endpointNamesArray.push(this.endpoints[i]['name']);
            }
            for (let i = 0; i < gatewayClustersLength && i + endpointsLength < maxSixe; i++) {
                endpointNamesArray.push(this.gatewayClusters[i]);
            }
            this.endpointNames =
                endpointNamesArray.length > 0 ? endpointNamesArray.toString() : 'endpoints/gateway clusters';
            if (endpointsLength + gatewayClustersLength > 3) {
                this.endpointNames = this.endpointNames + '...';
            }

            setTimeout(() => {
                this.apiService
                    .get(`workflow-status/${this.businessKey}?networkId=${this.networkId}`)
                    .toPromise()
                    .then(
                        (status) => {
                            if (status !== undefined && status['length'] > 0) {
                                this.message = this.type + ' As Code Upload Process In Progress';
                                // to store the status that we display to the user
                                this.endpointsStatus = [];
                                const statusList = status[0]['data'];
                                if (statusList.length > 0) {
                                    let index = -1;
                                    for (let i = 1; i < statusList.length; i++) {
                                        const currentTask = statusList[i]['task'];
                                        // do not display start event and end event in the status page
                                        if (
                                            currentTask.includes('End Event') ||
                                            currentTask === 'Start' ||
                                            currentTask === 'End' ||
                                            currentTask.includes('Sub Process Start') ||
                                            currentTask.includes('Sub Process End')
                                        ) {
                                            continue;
                                        }
                                        // display all meaningful messages in the status page
                                        if (index < 0 || this.endpointsStatus[index]['task'] !== currentTask) {
                                            this.endpointsStatus.push(statusList[i]);
                                            index++;
                                        } else {
                                            continue;
                                        }
                                        // check the percentage of completion
                                        if (currentTask === 'Call Create Endpoint' && this.stepStyle === 'step0') {
                                            this.stepStyle = 'step1';
                                        } else if (
                                            currentTask === 'Waiting For Endpoint Create Request' &&
                                            this.stepStyle !== 'step2'
                                        ) {
                                            this.stepStyle = 'step2';
                                        } else if (
                                            currentTask === 'Waiting For Endpoint Create Request' &&
                                            this.stepStyle === 'step2'
                                        ) {
                                            this.stepStyle = 'step3';
                                        } else if (currentTask === 'Check Status Of Endpoints') {
                                            this.stepStyle = 'step4';
                                        }
                                    }
                                }
                                if (statusList[statusList.length - 1]['task'].includes('End Event')) {
                                    this.stepStyle = 'step5';
                                    if (statusList[statusList.length - 2]['task'].includes('Error')) {
                                        this.message = this.type + ' As Code Upload Process Errored Out';
                                        this.logger.info(this.message);
                                        // is completed = false, i.e. failed
                                        resolve(false);
                                    } else {
                                        this.message = this.type + ' As Code Upload Process Completed';
                                        this.logger.info(this.message);
                                        // is completed = true
                                        resolve(true);
                                    }
                                } else if (statusList[statusList.length - 1]['task'].includes('Start Event')) {
                                    this.stepStyle = 'step2'; // this block has to be removed when flowable engine can send more status messages
                                } else {
                                    const statusTask = this.endpointsStatus[this.endpointsStatus.length - 1];
                                    this.message =
                                        statusTask !== undefined
                                            ? statusTask['task']
                                            : this.type + ' As Code Upload Process Started';
                                }
                            } else {
                                this.message = this.type + ' As Code Upload Process workflow is not yet initiated';
                            }
                            // is completed = null, i.e. process is running
                            resolve(null);
                        },
                        () => {
                            this.message = this.type + ' As Code Upload Process Status Check failed';
                            // is completed = false, i.e. failed
                            resolve(false);
                        }
                    );
            }, this.timeout);
        });
    }
}
