import { Injectable } from '@angular/core';
import { Endpoint, Network } from '@netfoundry-ui/shared/model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class GatewayService extends MopResourceService {
    page = null;
    links = null;
    private gatewaysSource = new Subject<Endpoint[]>();
    gateways = this.gatewaysSource.asObservable();
    private apiSub = new Subscription();
    private mapSub = new Subscription();

    constructor(protected logger: LoggerService, private apiservice: ApiService) {
        super(logger, apiservice);
    }

    /**
     * Gets all Clients for org
     */
    get(
        pageNumber?: number,
        sort?: string,
        filter?: string,
        pageSize?: number,
        endpointTypeString?: string
    ): Observable<any> {
        if (this.apiSub) {
            this.apiSub.unsubscribe();
        }

        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }
        // watch for when the network updates, when it does, update the gateways observable
        this.apiSub = this.apiService.currentNetwork.subscribe((network) => {
            if (endpointTypeString == null) {
                const endpointTypes = [];
                for (const endpoint of this.getGatewayTypes()) {
                    endpointTypes.push(endpoint.value);
                }

                endpointTypeString = endpointTypes.join(',');
            }

            const params = {
                endpointType: endpointTypeString,
                excludeClustered: true,
                // TODO filter
            };

            if (pageNumber != null) {
                params['page'] = pageNumber;
            }

            if (sort != null) {
                params['sort'] = sort;
            }

            if (filter != null && filter !== '') {
                params['nameLike'] = '%' + filter + '%';
            }

            if (pageSize != null) {
                params['size'] = pageSize;
            }

            this.mapSub = this.apiService
                .getLinkedResource(new Network(network), 'endpoints', params)
                .pipe(map(this._extractEmbedded))
                .subscribe((data) => {
                    this.page = data['page'];
                    this.links = data['links'];
                    this.gatewaysSource.next(data['endpoints']);
                });
        });

        // this is an observable that watches for network changes
        return this.gateways;
    }

    public getGatewayTypes() {
        return [
            { value: 'VCPEGW', label: 'V-CPE Gateway' },
            { value: 'AWSCPEGW', label: 'AWS Private Gateway' },
            { value: 'GW', label: 'Public Internet Gateway' },
            { value: 'AVWGW', label: 'Azure Site' },
            { value: 'AZCPEGW', label: 'Azure Private Gateway' },
            { value: 'AZSGW', label: 'Azure Stack Gateway' },
            { value: 'ZTGW', label: 'Hosted Ziti Bridge Gateway' },
            { value: 'ZTNHGW', label: 'Private Ziti Bridge Gateway' },
            { value: 'GCPCPEGW', label: 'Google Cloud Platform Gateway' },
            { value: 'ALICPEGW', label: 'AliBaba Private Gateway' },
            { value: 'OCPCPEGW', label: 'Oracle Cloud Gateway' },
            { value: 'L2VCPEGW', label: 'Voice Enhanced Gateway' },
        ];
    }

    /**
     * Function for sharing the registration information
     */
    public share(endpoint: Endpoint, emailParams: any) {
        // get the self link of the endpoint
        const selfLink = endpoint.getSelfLink();

        // append share to the self link
        const path = `${selfLink}/share`;

        // make a POST request with the path and the email params
        return this.apiService.post(path, emailParams);
    }

    /**
     * function for downloading the registration key
     */
    public downloadRegistrationKey(endpoint: Endpoint) {
        // getting the self link
        const selfLink = endpoint.getSelfLink();

        // appending downloadRegistrationKey
        const path = `${selfLink}/downloadRegistrationKey`;

        // making a GET request to the resulting path
        return this.apiService.getBlob(path);
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const endpoints: Endpoint[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['endpoints'] !== undefined) {
            for (let i = 0; i < res['_embedded']['endpoints'].length; i++) {
                const endpoint = res['_embedded']['endpoints'][i];
                if (endpoint['endpointType'] !== undefined && endpoint['endpointType'].includes('GW')) {
                    endpoints.push(new Endpoint(endpoint));
                }
            }
        }
        // object containing information on pagination such as page number, size, total number of items,  and total number of pages
        let page = null;
        if (res['page'] !== undefined) {
            page = res['page'];
        }

        // list of links for jumping to and from pages
        let links = null;
        if (res['_links'] !== undefined) {
            links = res['_links'];
        }

        return { endpoints: endpoints, page: page, links: links };
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return this.getLinkedResourceUrl(this.currentNetworkModel, 'endpoints');
    }
}
