import { Pipe, PipeTransform } from '@angular/core';

const statusLookup = {
    '100': 'New',
    '200': 'Building',
    '300': 'Active',
    '400': 'Registered',
    '500': 'Error',
    '600': 'Updating',
    '800': 'Deleting',
    '900': 'Defunct',
};

const undefinedString = 'Undefined';

@Pipe({
    name: 'statusPipe',
})
export class StatusPipe implements PipeTransform {
    transform(value: any, isEndpoint?: any): any {
        if (value === 300 && isEndpoint) {
            return 'Unregistered';
        }

        return statusLookup[value] ? statusLookup[value] : undefinedString;
    }
}
