<div [ngClass]="{ open: isOpen }" class="modal solid">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div [hidden]="isLoading" class="screens">
        <div (click)="close()" class="close icon-HamburgerAppClose" id="HeadOrgCloseButton"></div>
        <div class="modalIcon org{{ screenIndex }}"></div>
        <div class="title">{{ title }}</div>
        <div class="SubTitle">{{ subtitle }}</div>

        <div *ngIf="screenIndex === 0" class="sized">
            <div [ngClass]="{ error: errorsContain('NewOrgName') }" class="label row">
                Organization Name<span [ngClass]="{ error: errorsContain('NewOrgName') }" class="note">Required</span>
            </div>
            <input
                [(ngModel)]="model.name"
                [ngClass]="{ error: errorsContain('NewOrgName') }"
                id="NewOrgName"
                maxlength="200"
                placeholder="Enter an Organization Name"
                type="text"
            />
        </div>

        <div *ngIf="screenIndex === 1" class="sized">
            <div [ngClass]="{ error: errorsContain('NewOrgDomain') }" class="label row">
                Your Organization URL<span [ngClass]="{ error: errorsContain('NewOrgDomain') }" class="note"
                    >Required</span
                >
            </div>
            <div class="row">
                <input
                    (keyup)="onKey($event)"
                    [(ngModel)]="model.domain"
                    [ngClass]="{ error: errorsContain('NewOrgDomain') }"
                    id="NewOrgDomain"
                    name="domain"
                    placeholder="subdomain"
                    type="text"
                />
                <div class="inputAppend">.{{ originDomain }}</div>
            </div>
        </div>

        <!--
<div class="sized" *ngIf="screenIndex===2">
<div class="label row" [ngClass]="{error:errorsContain('NewOrgDomain')}">Select One Or More Roles</div>
<app-nf-select2
id="RoleSelector"
[options]="roles"
[selected]="rolesSelected"
[placeholder]="'Search For Roles'"
[isRoleForm]="true"
></app-nf-select2>
<div class="row center">
<div class="linkButton">Learn more about available roles</div>
</div>
</div>
-->
        <div *ngIf="screenIndex === 2 || screenIndex === 3" class="sized">
            <div class="row">
                <input
                    (click)="copy()"
                    class="fakeInput"
                    id="DomainName"
                    name="domain"
                    readonly
                    type="text"
                    value="{{ proto }}//{{ model.domain }}.{{ originDomain }}"
                />
            </div>
        </div>

        <div class="bubbles">
            <div
                *ngFor="let item of screens; let index = index"
                [ngClass]="{ on: isUnder(index) }"
                class="bubble"
            ></div>
        </div>
        <div class="buttons left">
            <button (click)="back()" *ngIf="showBack()" class="button" id="PrevOrgButton">Back</button>
        </div>
        <div class="buttons">
            <button (click)="next()" class="button" id="NextOrgButton">
                {{ buttonLabel }}
            </button>
        </div>
    </div>
</div>
