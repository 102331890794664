<div class="modal solid upgradeModal open" id="upgradeModal">
    <div (click)="cancel()" class="close cancel" id="CancelXButton"></div>
    <div class="icon upgradenetwork"></div>
    <div class="title">{{ title }}</div>
    <div class="subtitle">
        Are you sure you want to upgrade this Edge Router? All components will be temporarily unavailable.

        <div class="label">
            Select a Version
            <span class="specialnote">Required</span>
        </div>
        <select [(ngModel)]="selectedVersion" id="versionSelect" name="versionSelect">
            <option [ngValue]="null">Select A Version</option>
            <option *ngFor="let version of versionList" [ngValue]="version.networkVersion">
                {{ version.networkVersion }}
                <span *ngIf="version.recommended" class="inline-block ml-3"> (Recommended)</span>
            </option>
        </select>
    </div>
    <div class="buttons">
        <div (click)="cancel()" class="linkButton cancel" id="CancelUpgrade">
            {{ cancelAction }}
        </div>
        <button (click)="confirm()" [disabled]="!selectedVersion" class="save alert confirm" id="ConfirmAction">
            {{ action }}
        </button>
    </div>
</div>
