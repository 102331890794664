import { Inject, Injectable } from '@angular/core';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';
import { isEmpty, head, toLower } from 'lodash';
import { GrowlerService } from '@netfoundry-ui/shared/growler';
import { ReplaySubject, Subscription } from 'rxjs';
import { AuthorizationService, IamService } from '@netfoundry-ui/shared/authorization';
import { Tenant } from '@netfoundry-ui/shared/model';
import { BillingService } from './billing.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private subscription = new Subscription();

    currentAccountSource = new ReplaySubject<any>(1);
    currentAccount = this.currentAccountSource.asObservable();
    theCurrentAccountIs: any = {};
    currentTenant: any = {};
    currentOrg: any = {};
    billingAccountId;
    tenants = [];
    tenantMap = {};
    tenantId = '';

    constructor(
        public logger: LoggerService,
        public growlerService: GrowlerService,
        public apiService: ApiService,
        private billingService: BillingService,
        private iamService: IamService,
        private authorizationService: AuthorizationService
    ) {
        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                if (!isEmpty(org?.billingAccountId) && org.billingAccountId !== this.billingAccountId) {
                    this.getAccountDetailsForBillingAccountId(org?.billingAccountId);
                } else if (!isEmpty(this.currentTenant?.id)) {
                    this.getAccountDetailsForTenantId(this.currentTenant.id);
                }
                this.currentOrg = org;
                this.billingAccountId = org?.billingAccountId;
                this.loadTenants();
            })
        );
        this.subscription.add(
            this.apiService.currentTenant.subscribe((tenant) => {
                if (
                    this.authorizationService.canListTenants() &&
                    this.tenants?.length > 1 &&
                    tenant?.id !== this.currentTenant?.id
                ) {
                    this.getAccountDetailsForTenantId(tenant?.id);
                }
                this.currentTenant = tenant;
            })
        );
    }

    loadTenants() {
        if (this.authorizationService.canListTenants()) {
            this.iamService.find('tenants', { active: true }).subscribe((tenants) => {
                this.tenants = tenants as Tenant[];
                if (isEmpty(this.tenantId)) {
                    this.tenantId = localStorage.getItem('tenantId');
                }
                for (const tenant of this.tenants) {
                    this.tenantMap[tenant.id] = tenant;
                }
                this.currentTenant = this.tenantMap[this.tenantId];
                this.tenantId = this.currentTenant?.id;
                this.apiService.setAvailableTenants(this.tenants);
            });
        }
    }

    setAccount(account: any) {
        this.currentAccountSource.next(account);
        this.theCurrentAccountIs = account;
    }

    refreshAccountDetails() {
        this.getAccountDetailsForBillingAccountId(this.billingAccountId);
    }

    getAccountDetailsForTenantId(tenantId) {
        if (!tenantId || !this.hasBillingAccountRoles()) {
            return Promise.resolve();
        }
        return this.billingService.getAccount(tenantId).toPromise().then((result) => {
            const account: any = head(result);
            if (isEmpty(account)) {
                this.setAccount({});
                this.apiService.setSubscription({});
                this.apiService.setPaymentProfile({});
                return account;
            }
            this.getAccountDetailsForBillingAccountId(account?.id);
            return account;
        },
        () => {
            this.logger.error('Failed to get account details for tenant ID');
        });
    }

    getAccountDetailsForBillingAccountId(billingAccountId) {
        if (isEmpty(billingAccountId) || !this.hasBillingAccountRoles()) {
            return Promise.resolve();
        }
        const billingAccountPromise = this.billingService.getBillingAccount(billingAccountId).subscribe(
            (billingAccount: any) => {
                if (!billingAccount?.id || !billingAccount?.billingMode) {
                    return Promise.resolve();
                }
                this.setAccount(billingAccount);
                if (billingAccount.billingMode === 'Enterprise') {
                    return this.getEnterpriseSubscription(billingAccount.id);
                } else {
                    return this.getSelfServiceSubscription(billingAccount.id);
                }
            },
            () => {
                this.logger.error('unable to load account details');
            }
        );

        const paymentProfilePromise = this.getPaymentProfile(billingAccountId);

        return Promise.all([billingAccountPromise, paymentProfilePromise]);
    }

    private async getPaymentProfile(accountId) {
        if (!accountId || !this.hasPaymentProfileRoles()) {
            return Promise.resolve();
        }
        return this.billingService
            .getPaymentProfile(accountId)
            .toPromise()
            .then((result) => {
                const paymentProfile = head(result);
                this.apiService.setPaymentProfile(paymentProfile);
            })
            .catch(() => {
                this.logger.error('unable to load payment profile');
            });
    }

    private async getEnterpriseSubscription(accountId) {
        if (!this.hasPaymentProfileRoles()) {
            return Promise.resolve();
        }
        return this.billingService
            .getEnterpriseSubscription(accountId)
            .toPromise()
            .then((result: any) => {
                const subscription: any = this.getActiveSubscription(result);
                this.apiService.setSubscription(subscription);
            })
            .catch(() => {
                this.logger.error('unable to load account subscription');
            });
    }

    private async getSelfServiceSubscription(accountId) {
        let subscription: any = await this.billingService
            .getSelfServiceSubscription(accountId)
            .toPromise()
            .then(
                (result: any) => {
                    subscription = this.getActiveSubscription(result);
                },
                async () => {
                    this.logger.error('unable to load account subscription');
                    return;
                }
            )
            .catch(() => {
                this.logger.error('unable to load account subscription');
                return;
            });
        if (isEmpty(subscription)) {
            subscription = await this.billingService
                .getSelfServiceSubscription(accountId, true)
                .toPromise()
                .then(
                    (result2: any) => head(result2),
                    async () => {
                        this.logger.error('unable to load cancelled account subscription');
                        return;
                    }
                )
                .catch(() => {
                    this.logger.error('unable to load cancelled account subscription');
                    return;
                });
        }
        if (!isEmpty(subscription)) {
            this.apiService.setSubscription(subscription);
        }
    }

    private getActiveSubscription(subscriptions: any) {
        if (isEmpty(subscriptions)) {
            return head(subscriptions);
        }
        let activeSub;
        try {
            for (let i = 0; i < subscriptions.length; i++) {
                const sub = subscriptions[i];
                if (toLower(sub?.status) !== 'cancelled') {
                    activeSub = sub;
                    break;
                }
            }
        } catch (err) {
            activeSub = head(subscriptions);
        }
        return activeSub;
    }

    hasBillingAccountRoles() {
        return (
            this.authorizationService.canViewBillingSubscriptions() ||
            this.authorizationService.canViewEnterpriseBillingSubscriptions()
        );
    }

    hasPaymentProfileRoles() {
        return this.authorizationService.canViewPaymentProfile();
    }
}
