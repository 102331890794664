import { MopResource } from './mopresource';

export class PeeringAddress {
    public ipAddress: string;
    public asn: number;
}

export class BGP {
    public localPeeringAddress: PeeringAddress;
    public neighborPeers: PeeringAddress[];
    public advertiseLocal: boolean;
    public advertisedPrefixes: string[];
}

export class AzureVirtualWanSite extends MopResource {
    public azureSubscriptionId: string;
    public dataCenterId: string;
    public endpointId: string;
    public azureId: string;
    public azureResourceGroupName: string;
    public azureVirtualWanId: string;
    public publicIpAddress: string;
    public bgp: BGP;
    /*
public bgpPeerWeight: string;
public deviceLinkSpeed: string;
public deviceVendor: string;
public deviceModel: string;
public neighborPeers: string;
public arbitraryPrefixes: string;
*/

    public _links: {
        self: {
            href: string;
        };
    };

    constructor(object) {
        super(object);

        this.azureSubscriptionId = this.getLinkedResourceId('azureSubscription');
        this.dataCenterId = this.getLinkedResourceId('dataCenter');
        this.endpointId = this.getLinkedResourceId('endpoint');
    }

    public getSaveObject() {
        return {
            name: this.name,
            azureId: this.azureId,
            endpointId: this.endpointId,
            dataCenterId: this.dataCenterId,
            azureResourceGroupName: this.azureResourceGroupName,
            azureVirtualWanId: this.azureVirtualWanId,
            publicIpAddress: this.publicIpAddress,
            azureSubscriptionId: this.azureSubscriptionId,
            bgp: this.bgp,
        };
    }
}
