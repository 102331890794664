<app-loader *ngIf="isLoading" [isLoading]="isLoading" class="load-indicator" id="userSettingPageLoader"></app-loader>
<div class="sized user-settings-container" id="brandingPage">
    <div class="area padTop">
        <div class="label row">Dark Mode</div>
        <div class="row">
            <div class="darkGrid">
                <div class="title">Dark Mode</div>
                <div class="subtitle">Flip The Switch For<br />Darker Environments</div>
                <div>
                    <div class="switchGrid">
                        <div>
                            <div
                                (click)="toggleDarkMode()"
                                [ngClass]="{ on: brandingService.darkModeEnabled }"
                                class="toggle"
                                id="toggleButtonDarkMode"
                            >
                                <span [hidden]="!brandingService.darkModeEnabled">ON&nbsp;&nbsp;&nbsp;</span>
                                <span [hidden]="brandingService.darkModeEnabled">&nbsp;&nbsp;&nbsp;OFF</span>
                                <div class="switch"></div>
                            </div>
                        </div>
                        <div>
                            <span [hidden]="!brandingService.darkModeEnabled">Enabled</span>
                            <span [hidden]="brandingService.darkModeEnabled">Disabled</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="label row" *ngIf="this.authorizationService.canListOrganizations()">Power User Features</div>
        <div class="row power-user" *ngIf="this.authorizationService.canListOrganizations()">
            <div class="darkGrid">
                <div class="title">Power User</div>
                <div class="subtitle">Flip The Switch To Enable<br />Power User Features</div>
                <div>
                    <div class="switchGrid">
                        <div>
                            <div
                                (click)="togglePowerUserSetting()"
                                [ngClass]="{ on: featureService.powerUserEnabled }"
                                class="toggle"
                                id="toggleButtonPowerUserSettings"
                            >
                                <span [hidden]="!featureService.powerUserEnabled">ON&nbsp;&nbsp;&nbsp;</span>
                                <span [hidden]="featureService.powerUserEnabled">&nbsp;&nbsp;&nbsp;OFF</span>
                                <div class="switch"></div>
                            </div>
                        </div>
                        <div>
                            <span [hidden]="!featureService.powerUserEnabled">Enabled</span>
                            <span [hidden]="featureService.powerUserEnabled">Disabled</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="label row" *ngIf="!featureService.isCloudZiti && this.authorizationService.canListOrganizations()">New Navigation</div>
        <div class="row new-nav" *ngIf="!featureService.isCloudZiti && this.authorizationService.canListOrganizations()">
            <div class="darkGrid">
                <div class="title">New Navigation</div>
                <div class="subtitle">Flip The Switch to "OFF" to Opt Out of the<br />Redesigned Side Navigation</div>
                <div>
                    <div class="switchGrid">
                        <div>
                            <div
                                (click)="toggleSideNavigationSetting()"
                                [ngClass]="{ on: !featureService.disableNewNavigation }"
                                class="toggle"
                                id="toggleSideNavSettings"
                            >
                                <span [hidden]="featureService.disableNewNavigation">ON&nbsp;&nbsp;&nbsp;</span>
                                <span [hidden]="!featureService.disableNewNavigation">&nbsp;&nbsp;&nbsp;OFF</span>
                                <div class="switch"></div>
                            </div>
                        </div>
                        <div>
                            <span [hidden]="featureService.disableNewNavigation">Enabled</span>
                            <span [hidden]="!featureService.disableNewNavigation">Disabled</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="label row" *ngIf="featureService.isCloudZiti && this.authorizationService.canListNetworks()">Default Network</div>
        <div class="row" *ngIf="featureService.isCloudZiti && this.authorizationService.canListNetworks()">
          <div class="darkGrid default-network-container">
            <div class="title default-network-title">Default Network</div>
            <div class="network-selector-container">
              <lib-item-selector
                [(currentItem)]="defaultNetwork"
                [items]="networks"
                [showManageItems]="false"
                [showAddNewItem]="false"
                (currentItemChange)="setDefaultNetwork($event)"
              >
              </lib-item-selector>
            </div>
          </div>
        </div>
        <div class="label row" *ngIf="featureService.isCloudZiti" >Default App</div>
        <div class="row" *ngIf="featureService.isCloudZiti">
          <div class="darkGrid default-app-container">
            <div class="title default-app-title">Default App</div>
            <div class="app-selector-container">
              <select [(ngModel)]="defaultAppId" (change)="setDefaultApp($event)" id="Providers">
                <option [value]="undefined">Select an App</option>
                <option *ngFor="let app of appOptions" [value]="app.id" [hidden]="app.hidden">
                  {{ app.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div *ngIf="authorizationService.isSupportUser()" class="row padBottom super-user">
            <div class="label row">Support User Features</div>
            <div class="row padBottom power-user">
                <div (click)="copyToClipboard()" class="darkGrid">
                    <div class="title">Support User</div>
                    <div class="subtitle">Click To<br />Copy JWT</div>
                    <div class="jwtIcon"></div>
                </div>
            </div>
        </div>
    </div>
</div>
