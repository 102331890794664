import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService, IamService } from '@netfoundry-ui/shared/authorization';
import { Tenant } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    AppwanService,
    ClientService,
    GatewayService,
    GroupService,
    NetworkService,
    ServiceService,
    TourService,
    URLS,
} from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { isEmpty } from 'lodash';

@Component({
    selector: 'app-page-tour',
    templateUrl: './page-tour.component.html',
    styleUrls: ['./page-tour.component.scss'],
    providers: [],
})
export class PageTourComponent implements OnInit, OnChanges, OnDestroy {
    title = '';
    contents = '';
    itemIndex = 0;
    tourStyle = '';
    realStyle = '';
    url = '';
    gateways = [];
    clients = [];
    appwans = [];
    services = [];
    networks = [];
    groups = [];
    users = [];
    tourItems = [];
    tourLink = '';
    isOpen = false;
    tenantId = '';
    private subscription = new Subscription();
    private tourServiceSub = new Subscription();
    private appwanSub = new Subscription();
    private serviceSub = new Subscription();
    private clientSub = new Subscription();
    private gatewaySub = new Subscription();
    private groupsSub = new Subscription();
    private identitySub = new Subscription();

    constructor(
        private router: Router,
        private gatewayService: GatewayService,
        private appwanService: AppwanService,
        private clientService: ClientService,
        private serviceService: ServiceService,
        private groupService: GroupService,
        private networkService: NetworkService,
        private iamService: IamService,
        private tourService: TourService,
        private apiService: ApiService,
        private authorizationService: AuthorizationService
    ) {}

    async ngOnInit() {
        this.apiService.currentTenant
            .pipe(take(1))
            .toPromise()
            .then((tenant) => {
                this.tenantId = new Tenant(tenant).id;

                this.subscription.add(
                    this.apiService.currentNetwork.subscribe((network) => {
                        if (isEmpty(network.id)) {
                            return;
                        }
                        this.getResources();
                    })
                );

                this.subscription.add(
                    this.apiService.currentTenant.pipe(skip(1)).subscribe((tenant) => {
                        this.tenantId = new Tenant(tenant).id;
                        this.getResources();
                    })
                );
            });

        this.subscription.add(
            this.networkService.networks.subscribe((results) => {
                this.networks = results;
            })
        );

        this.tourServiceSub = this.tourService.data$.subscribe(() => {
            this.loadTour();
        });
    }

    getResources() {
        this.gatewaySub.unsubscribe();
        this.clientSub.unsubscribe();
        this.appwanSub.unsubscribe();
        this.serviceSub.unsubscribe();
        this.groupsSub.unsubscribe();
        this.gatewaySub.unsubscribe();
        this.identitySub.unsubscribe();
        if (this.authorizationService.canListAppWans()) {
            this.initAppwans();
        } else {
            this.appwans = [];
            this.appwanSub.unsubscribe();
        }

        if (this.authorizationService.canListServices()) {
            this.initServices();
        } else {
            this.services = [];
            this.serviceSub.unsubscribe();
        }

        if (this.authorizationService.canListEndpoints()) {
            this.initEndpoints();
        } else {
            this.clients = [];
            this.gateways = [];
            this.clientSub.unsubscribe();
            this.gatewaySub.unsubscribe();
        }

        if (this.authorizationService.canListEndpointGroups()) {
            this.initGroups();
        } else {
            this.groups = [];
            this.groupsSub.unsubscribe();
        }

        if (this.authorizationService.canListUserIdentities()) {
            this.initIdentities();
        } else {
            this.users = [];
            this.identitySub.unsubscribe();
        }
    }

    initIdentities() {
        this.identitySub = this.iamService
            .find('user-identities', { tenantId: this.tenantId })
            .subscribe((results: any) => {
                this.users = results;
            });
    }

    initAppwans() {
        this.appwanSub = this.appwanService.appWans.subscribe((result) => {
            this.appwans = result;
        });
    }

    initServices() {
        this.serviceSub = this.serviceService.services.subscribe((result) => {
            this.services = result;
        });
    }

    initEndpoints() {
        this.gatewaySub = this.gatewayService.gateways.subscribe((result) => {
            this.gateways = result;
        });
        this.clientSub = this.clientService.clients.subscribe((result) => {
            this.clients = result;
        });
    }

    initGroups() {
        this.subscription.add(
            this.groupService.endpointGroups.subscribe((result) => {
                this.groups = result;
            })
        );
    }

    ngOnChanges() {
        this.show();
    }

    ngOnDestroy() {
        this.tourServiceSub.unsubscribe();
        this.subscription.unsubscribe();
        this.appwanSub.unsubscribe();
        this.serviceSub.unsubscribe();
        this.clientSub.unsubscribe();
        this.gatewaySub.unsubscribe();
        this.subscription.unsubscribe();
    }

    loadTour() {
        this.tourItems = [];
        this.isOpen = true;
        this.tourStyle = 'tables';
        this.url = this.router.url;
        if (this.url === URLS.GATEWAYS || this.url === URLS.OLD_GATEWAYS || this.url === URLS.OLD_OLD_GATEWAYS) {
            this.tourLink =
                'https://netfoundry.zendesk.com/hc/en-us/articles/360017558212-Introduction-to-gateway-endpoints';
            this.tourItems[this.tourItems.length] = {
                title: "What's a Gateway?",
                contents:
                    'An Endpoint type that takes a data session originated/terminated on separate physical/logical device and originates/terminates that session onto a NetFoundry Network.',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Add a Gateway',
                contents: 'Tap the blue plus-sign icon to create a New Gateway',
            };
            if (this.gateways.length > 0) {
                this.tourItems[this.tourItems.length] = {
                    title: 'Edit an Existing Gateway',
                    contents:
                        'Click on a gateway row to view details or edit an existing gateway. Use the ellipsis menu at the end of each row to take actions on an individual gateway, like deleting and sharing.',
                };
            }
        } else if (this.url === URLS.APPWANS || this.url === URLS.OLD_APPWANS || this.url === URLS.OLD_OLD_APPWANS) {
            this.tourLink = 'https://netfoundry.zendesk.com/hc/en-us/articles/360018510571-Introduction-to-AppWANs';
            this.tourItems[this.tourItems.length] = {
                title: "What's an AppWAN?",
                contents:
                    "An AppWAN is a full description of your application's resources and authorized users and sites.",
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Add a AppWAN',
                contents: 'Tap the blue plus-sign icon to create a New AppWAN',
            };
            if (this.appwans.length > 0) {
                this.tourItems[this.tourItems.length] = {
                    title: 'Edit an Existing AppWAN',
                    contents:
                        'Click on an AppWAN row to view details about that AppWAN. Use the ellipsis menu at the end of each row to take actions on an individual AppWAN, like deleting and sharing.',
                };
            }
        } else if (this.url === URLS.CLIENTS || this.url === URLS.OLD_CLIENTS || this.url === URLS.OLD_OLD_CLIENTS) {
            this.tourLink =
                'https://netfoundry.zendesk.com/hc/en-us/articles/360018315911-Introduction-to-client-endpoints';
            this.tourItems[this.tourItems.length] = {
                title: "What's a Client?",
                contents:
                    'The NetFoundry client is light-weight agent that is installed on your computer or device in order to access one or more AppWANs.',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Add a Client',
                contents: 'Tap the blue plus-sign icon to create a New Client',
            };
            if (this.clients.length > 0) {
                this.tourItems[this.tourItems.length] = {
                    title: 'Edit an Existing Client',
                    contents:
                        'Click on a client row to view details about that client. Use the ellipsis menu at the end of each row to take actions on an individual client, like deleting and sharing.',
                };
            }
        } else if (this.url === URLS.SERVICES || this.url === URLS.OLD_SERVICES || this.url === URLS.OLD_OLD_SERVICES) {
            this.tourLink = 'https://netfoundry.zendesk.com/hc/en-us/articles/360018509951-Introduction-to-Services';
            this.tourItems[this.tourItems.length] = {
                title: "What's a Service?",
                contents:
                    'Services define resources on your local network that you want to reach using an AppWAN. A service definition consists of an IP address, protocol, and port. When you create a service you assign it to a gateway. That gateway becomes the egress point onto your local network to reach the service.',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Add a Service',
                contents: 'Tap the blue plus-sign icon to create a New Service',
            };
            if (this.services.length > 0) {
                this.tourItems[this.tourItems.length] = {
                    title: 'Edit an Existing Service',
                    contents:
                        'Click on a service row to edit it. Use the ellipsis menu at the end of each row to take actions on individual services.',
                };
            }
        } else if (
            this.url === URLS.MANAGEMENT_EVENTS ||
            this.url === URLS.OLD_EVENTS ||
            this.url === URLS.ALARMS ||
            this.url === URLS.OLD_ALARMS
        ) {
            this.tourStyle = 'events';
            this.tourLink = 'https://netfoundry.zendesk.com/hc/en-us/articles/360018254452-Audit-network-event-logs';
            this.tourItems[this.tourItems.length] = {
                title: "What's considered a Network Event?",
                contents:
                    'Network events are a log of timeline based activities such as creations, status changes, errors, and more. Use the filters to sort by time, event type, resource type, or severity.',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Export to CSV',
                contents: 'Tap the Export to CSV Button to download the Network Event Log.',
            };
        } else if (this.url === URLS.GROUPS || this.url === URLS.OLD_GROUPS || this.url === URLS.OLD_OLD_GROUPS) {
            this.tourStyle = 'groups';
            this.tourLink =
                'https://netfoundry.zendesk.com/hc/en-us/articles/360018238952-Create-and-manage-endpoint-groups';
            this.tourItems[this.tourItems.length] = {
                title: "What's an Endpoint Group?",
                contents:
                    'Clients and gateways are both types of endpoints. Endpoints can be assigned to AppWANs individually, or as a group. Endpoint groups are entirely user-defined, and are optional. You may choose to group endpoints by department, geographic region, service level, or any other way you choose. These groups can then be assigned to AppWANs.',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Add a Endpoint Group',
                contents: 'Tap the blue plus-sign icon to create a New Endpoint Group.',
            };
            if (this.groups.length > 0) {
                this.tourItems[this.tourItems.length] = {
                    title: 'Edit an Existing Group',
                    contents:
                        'When you click on a circle next to a group title, the blue plus-sign will turn into a red minus-sign, which will delete the group when clicked. The same can be done with individual group members to remove them from a group.',
                };
            }
        } else if (this.url === URLS.NETWORK || this.url === URLS.OLD_NETWORK) {
            this.tourLink = 'https://netfoundry.zendesk.com/hc/en-us/articles/360018194551-Create-and-manage-networks';
            this.tourItems[this.tourItems.length] = {
                title: 'Network Management',
                contents:
                    'An organization may have one or more NetFoundry networks associated with it. Each NetFoundry network is an entirely stand-alone, air-gapped environment. Networks cannot share clients, gateways, endpoint groups, services, or AppWANs with other networks.',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Add a Network',
                contents:
                    "Click the blue plus-sign in the upper right corner to create a new network. When you create a new Network, it will take a few minutes for NetFoundry to stand up the required infrastructure to support it. While it is being built, the Console will show a spinner icon next to the Network's name. Once it is finished the icon will change to a green circle, and you may begin provisioning your network for use.",
            };
            if (this.networks.length > 0) {
                this.tourItems[this.tourItems.length] = {
                    title: 'Edit a Network',
                    contents:
                        'Use the ellipsis menu at the end of each row to take actions on an individual network such as renaming the network, viewing network attribute to avoid firewall conflicts, and deleting a network.',
                };
            }
        } else if (this.url === URLS.NETWORK_GROUPS) {
            this.tourItems[this.tourItems.length] = {
                title: 'Network Group Management',
                contents:
                    'Network Groups can be made up of one or more networks. They quite useful when looking to apply groups of permissions to more than one network at a time.',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Add a Network Group',
                contents:
                    'Click the blue plus-sign in the upper right corner to create a new network group. You will first be prompted to enter a group name and optionally a new network should you choose to create one.',
            };
            if (this.networks.length > 0) {
                this.tourItems[this.tourItems.length] = {
                    title: 'Edit a Network Group',
                    contents:
                        'Use the ellipsis menu at the end of each row to take actions on an individual network group, such as renaming or deleting.',
                };
            }
        } else if (this.url === URLS.USERS || this.url === URLS.OLD_USERS) {
            this.tourStyle = 'users';
            this.tourLink =
                'https://netfoundry.zendesk.com/hc/en-us/articles/360018192891-Create-and-manage-user-accounts';
            this.tourItems[this.tourItems.length] = {
                title: 'User Management',
                contents:
                    'When you create a new organization, you are the only one with an account in it. You can invite others to join your Organization, all you need is their email address. When they receive the invitation, they will make the choice to accept it, reject it, or ignore it. If they accept the invite, they will be able to create an account in your Organization. If they reject it, then the invitation is no longer valid, and they will need a new invite if they change their mind later. An invitation is only valid for 7 days before it expires. Once expired, a new invitation will be needed to join.',
            };

            this.tourItems[this.tourItems.length] = {
                title: 'Add a New User',
                contents:
                    'Tap the blue plus-sign icon to invite a new user to your organization. Doing so sends an email invitation for the user to log in and set their password.',
            };
            if (this.users.length > 0) {
                this.tourItems[this.tourItems.length] = {
                    title: 'Delete an Existing User',
                    contents:
                        "To delete an existing user, select the user's row using the checkbox on the left-hand side of the table. You'll notice the plus button in the upper right change to a red minus. Click to delete and confirm the removal of the user.",
                };
                this.tourItems[this.tourItems.length] = {
                    title: 'Resending or Revoking an Invitation',
                    contents:
                        "The activation column in the table illustrates which users have accepted their invitations and those that have not. To resend an invitation to a user, use the ellipsis menu at the end of the row. You'll notice you also have the option to revoke an invitation for a user you no longer wish to invite.",
                };
            }
        } else if (this.url === URLS.LOGS || this.url === URLS.OLD_LOGS) {
            this.tourStyle = 'events';
            this.tourLink =
                'https://netfoundry.zendesk.com/hc/en-us/articles/360018523871-Audit-organization-event-logs';
            this.tourItems[this.tourItems.length] = {
                title: 'Organizational Event Timeline',
                contents:
                    'Different from Network Events, the Organizational Event Timeline includes events from all networks in your organization such as: <br/><li>User invitation sent / canceled / resent / revoked</li><li>User invitation accepted/declined</li><li>User deactivated</li><li>Logins and log out to the NetFoundry console and APIs</li><li>2FA settings changed</li><li>Network created/deleted</li>',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Export to CSV',
                contents: 'Tap the Export to CSV Button to download the Network Event Log.',
            };
        } else if (this.url === URLS.SUBSCRIPTIONS || this.url === URLS.OLD_SUBSCRIPTIONS) {
            this.tourStyle = 'events';
            this.tourLink = 'https://netfoundry.zendesk.com/hc/en-us/sections/360002806532-Azure-Virtual-WAN';
            this.tourItems[this.tourItems.length] = {
                title: 'Subscriptions',
                contents:
                    'Currently this page is specifically for Microsoft Azure Virtual WAN Users. To learn more see the following documentation in the support hub.',
            };
        } else if (this.url === URLS.SETTINGS || this.url === URLS.OLD_SETTINGS || this.url === URLS.OLD_OLD_SETTINGS) {
            this.tourStyle = 'settings';
            this.tourItems[this.tourItems.length] = {
                title: 'Settings',
                contents: 'Organization wide application settings can be controlled using the organization tab.',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Enable to Factor Identification',
                contents:
                    'Two-Factor Authentication (2FA) can be turned on for everyone signing into your Organization. When it is enabled, the NetFoundry Console will require it for all of your users; it cannot be enabled or disabled on a per-user basis. 2FA is provided by Google Authenticator, which is free for IOS and Andriod mobile operating systems. To do so enable the toggle switch at the top of the page.',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Set your Organization Theme',
                contents: 'Use the following controls to set your companies theme.',
            };
        } else if (this.url === URLS.VIRTUALWANS || this.url === URLS.OLD_VIRTUALWANS) {
            this.tourStyle = 'tables';
            this.tourLink = 'https://netfoundry.zendesk.com/hc/en-us/articles/360013428991-Getting-Started-Guides-';
            this.tourItems[this.tourItems.length] = {
                title: 'Azure Virtual WANs',
                contents: 'Associated Azure Virtual WANs will be available here.',
            };
        } else if (this.url === URLS.TOKENS) {
            this.tourStyle = 'tables';
            this.tourLink = 'https://netfoundry.zendesk.com/hc/en-us/articles/360013428991-Getting-Started-Guides-';
            this.tourItems[this.tourItems.length] = {
                title: 'API Accounts',
                contents:
                    "The API Accounts page is used to generate secret client IDs for use with your organization's applications consuming NetFoundrys API's.",
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Add a New API Account',
                contents: 'Click the blue plus-sign in the upper right corner to create a New API Account.',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Edit an API Account',
                contents:
                    'Use the ellipsis menu at the end of each row to edit an individual Applications details, generate a new key, or deactivate the application.',
            };
        } else if (this.url === URLS.PROFILE || this.url === URLS.OLD_PROFILE) {
            this.tourStyle = 'tables';
            this.tourLink = '';
            this.tourItems[this.tourItems.length] = {
                title: 'User Profile',
                contents:
                    "Use the user profile area to edit details such as name and email. Note, if you signed up using a single sign-on provider, such as Google, your user details such as Name, Email Address, and Photo need to be edited via that provider's interface.",
            };
        } else if (this.url === URLS.BRANDING || this.url === URLS.OLD_BRANDING || this.url === URLS.USER_SETTINGS) {
            this.tourStyle = 'events';
            this.tourLink = '';
            this.tourItems[this.tourItems.length] = {
                title: 'Customizations',
                contents:
                    'Through the customization tab any user can toggle between light and dark mode, which alters the appearance of the console based on preference and work environment. Organization Admins also have the ability to set system-wide primary and secondary color options, as well as upload a custom logo to match their brand should they choose. Branding specific changes will affect all users of the system, while light/dark mode is a personal preference and user specific. The default for both can be restored at any time. Users can also choose to enable "Power User" features which will allow the user to use advanced configurations when defining network entities like services and AppWANs.',
            };
        } else if (this.url === URLS.HOME || this.url === URLS.OLD_HOME) {
            this.tourStyle = 'dashboard';
            this.tourLink = '';
            this.tourItems[this.tourItems.length] = {
                title: "What's a Dashboard?",
                contents: 'This Dashboard displays a brief overview and at-a-glance details for your selected network.',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Upper Pods',
                contents:
                    'The top pods of the dashboard provide a quick reference to the number of Gateways, Services, Clients, Endpoint Groups, and AppWANs associated with the selected network. They also serve as direct links to the details pages for each. Also available on the far right is a quick link to the endpoint downloads page for the latest client and gateway installers for your system.',
            };
            this.tourItems[this.tourItems.length] = {
                title: 'Lower Pods',
                contents:
                    'The remaining pods provide various levels of detail into your Networks Events, Alarms, Data Usage, Network Utilization, and Top 5 Consuming Endpoints.',
            };
        } else {
            this.isOpen = false;
        }
        if (this.isOpen) {
            this.show();
        }
    }

    show() {
        window.scrollTo(0, 0);
        this.title = this.tourItems[this.itemIndex].title;
        this.contents = this.tourItems[this.itemIndex].contents;
        this.realStyle = this.tourStyle + this.itemIndex;
    }

    next() {
        if (this.itemIndex < this.tourItems.length - 1) {
            this.itemIndex++;
            this.show();
        }
    }

    goto() {
        window.open(this.tourLink);
    }

    close() {
        this.itemIndex = 0;
        this.isOpen = false;
    }
}
