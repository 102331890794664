<div *ngIf="isVisible" class="outageWarningContainer">
    <div class="outageWarning">
        <span *ngIf="showSpinner" class="spinner"></span>
        <div class="content">
            <div [innerHTML]="message"></div>
        </div>
        <div (click)="actionButtonClicked()" *ngIf="showSupportLink" class="support-link">{{ buttonText }}</div>
        <div (click)="close()" class="miniClose"></div>
    </div>
</div>
