import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-date-filters',
    templateUrl: './date-filters.component.html',
    styleUrls: ['./date-filters.component.scss'],
})
export class DateFiltersComponent {
    @Input() currentTimeFilter: string;
    @Output() startChange = new EventEmitter<any>();
    @Output() endChange = new EventEmitter<any>();

    startDate = new Date();
    endDate = Date.now();
    public value: Date = new Date();
    calStartDate = new Date();
    calEndDate = Date.now();

    constructor(private logger: LoggerService) {}

    updateStart(value) {
        this.currentTimeFilter = value;
        this.startChange.emit(value);
        this.logger.info('Emitted startTime value...');

        // change UI classes
    }
}
