import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BillingEnabledService, BillingService } from '@netfoundry-ui/feature/shared-services';
import { Auth0Service, IamService, LOGIN_SERVICE, LoginService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { BillableRegion, Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    CustomerService,
    GetCountryService,
    HTTP_CLIENT,
    LoggerService,
    NetworkGroupService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { StripeCardElement, StripeElements, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';
import _ from 'lodash';
import { take } from 'rxjs/operators';
import $ from 'jquery';

const domainRegex = /^([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9])$/;

@Component({
    selector: 'app-selfservice',
    providers: [BillingService],
    templateUrl: './selfservice.component.html',
    styleUrls: ['./selfservice.component.scss'],
})
export class SelfserviceComponent implements OnInit {
    keyRefresher;

    step = 1;
    model = {
        email: '',
        password: '',
        domain: '',
        terms: false,
        createNetwork: false,
        firstName: '',
        lastName: '',
        company: '',
        countryCode: '',
        zip: '',
        networkName: '',
        token: '',
        creditCard: '',
        plan: 'trial',
    };

    signupCode;

    isZip = true;
    mailCodeText = 'Zip';
    cardComponent: any;
    termsOpened = true;
    regions: BillableRegion[] = [];
    selectedRegions: BillableRegion[] = [];
    cost = 199;
    totalCost = 0;
    totalCostString = '';
    billingForm: FormGroup;
    signupForm1: FormGroup;
    signupForm2: FormGroup;
    vanityUrlForm: FormGroup;
    userInfoForm: FormGroup;
    elements: StripeElements;
    card: StripeCardElement;
    elementsOptions: StripeElementsOptions = {
        locale: 'en',
    };
    openWelcome = false;
    dbProvider;
    providerTypes = [];

    signupUrl = '';

    confirmedTenantLabel = '';
    nfToken = '';
    invitationKey = '';

    dialogRef;

    validSubmitRegion = true;

    selectedAtLeastOneRegion = false;

    originDomain = this.environment.originDomain;
    proto = location.protocol;

    cardError = false;
    cardErrorString = '';

    billingFormSubmitted = false;

    isProd = this.environment.production && !this.environment['showCreditCardHelp'];

    isBlackListed = false;

    isZipCodeValid = true;

    invalidDomain = false;
    customerId;
    productId;
    providerId;

    tokenValid = undefined;
    tokenEmpty = true;
    countries;
    isLoading = false;

    cardValid = true;
    customer = {
        firstName: '',
        lastName: '',
        email: '',
        location: {
            country: '',
            zipcode: '',
        },
        paymentMethod: { cardToken: '' },
        site: {
            name: '',
            label: '',
            networkGroupId: '',
            billingAccountId: '',
        },
    };

    emailValidationErrors = {};
    emailVerificationId = '';
    emailValidationToken = '';
    identityProviders = [];

    moveNext = _.debounce(this.handleNextStep, 200);

    constructor(
        private fb: FormBuilder,
        private growlerService: GrowlerService,
        private stripeService: StripeService,
        @Inject(LOGIN_SERVICE) private auth: LoginService,
        private iamService: IamService,
        private apiService: ApiService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        public dialogForm: MatDialog,
        public orgService: NetworkGroupService,
        public billingService: BillingService,
        public billingEnabledService: BillingEnabledService,
        private activatedRoute: ActivatedRoute,
        private logger: LoggerService,
        private validateService: ValidateService,
        private getCountryService: GetCountryService,
        private router: Router,
        private auth0Service: Auth0Service,
        private customerService: CustomerService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.regions.push(new BillableRegion('North America'));
        this.regions.push(new BillableRegion('Europe'));
        this.regions.push(new BillableRegion('APAC'));
        this.regions.push(new BillableRegion('South America'));
    }

    tokenChangedDebounced: any = () => {};

    ngOnInit() {
        const timestamp = Date.now();
        localStorage.setItem('nf_new_signup_start', timestamp + '');
        this.signupUrl = this.environment.identityConfig.invitationUrl;
        this.isLoading = true;

        this.customerService.getCountriesList().then((countries) => {
            const topLevelCountries = [];
            const countriesList = [];
            let usAdded = false;
            countries.forEach((country, index, obj) => {
                if (country.code === 'US') {
                    if (!usAdded) {
                        country.name = 'United States';
                        country.sortName = `0 ${country.name}`;
                        topLevelCountries.push(country);
                        usAdded = true;
                    }
                } else {
                    country.sortName = country.name;
                    countriesList.push(country);
                }
            });
            this.countries = [...topLevelCountries, ...countriesList];
            this.isLoading = false;
            this.model.countryCode = '';
        });

        // make sure we're logged out
        this.auth.clearStorage();
        this.getProviders();
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
            this.logger.info('Query Params', params);
            this.customerId = params.customerId;
            this.productId = params.productId;
            this.providerId = params.provider;
            if (params.stepNum) {
                const result = this.loadSignupData();
                this.step = parseInt(params.stepNum, 10);
            } else {
                localStorage.removeItem('nf_signup_data');
            }
            if (params.selfServicePlan) {
                this.model.plan = params.selfServicePlan;
                if (this.model.plan === 'growth') {
                    this.signupForm1?.removeControl('countryCode');
                    this.signupForm1?.removeControl('zip');
                }
            }
            if (params.signupCode) {
                this.signupCode = params.signupCode;
            }

            if (
                (this.customerId === undefined && this.productId !== undefined) ||
                (this.customerId !== undefined && this.productId === undefined)
            ) {
                this.growlerService.show(
                    new GrowlerData(
                        'error',
                        'Error',
                        'AWS marketplace parameters missing',
                        'CustomerId and productId are mandatory for creating AWS customer organization. Remove all the query parameters for creating other organizations'
                    )
                );
                return false;
            }
        });

        this.signupForm1 = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
        });

        if (this.model.plan === 'trial') {
            this.signupForm1.addControl('countryCode', this.fb.control('', [Validators.required]));
            this.signupForm1.addControl('zip', this.fb.control('', [Validators.required]));
        }

        this.signupForm2 = this.fb.group({
            //creditCard: ['', [Validators.required]],
            countryCode: ['', [Validators.required]],
            zip: ['', []],
        });

        this.vanityUrlForm = this.fb.group({
            domain: ['', [Validators.required]],
        });

        this.userInfoForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            company: ['', [Validators.required]],
            networkName: ['', []],
        });

        this.billingForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            name: ['', [Validators.required]],
        });
        this.setStepParameter();
        this.tokenChangedDebounced = _.debounce(this.tokenChanged, 500);
    }

    onChargebeeReady(cardComponent: any) {
        this.cardComponent = cardComponent;
        this.cardComponent.focus(true);
        this.cardComponent.placeholder = '* Card Number';
    }

    createAccount() {
        this.signup();
        // this.dialogRef = this.dialogForm.open(WelcomePromptComponent, {height: '700px', width: '900px', autoFocus: false });
    }

    closeWelcome() {
        this.dialogRef.close();
    }

    moveBack() {
        if (this.step === 3 && this.model.plan === 'trial') {
            this.step--;
        }
        this.step--;
        this.setStepParameter();
    }

    goToLogin() {
        this.router.navigate(['/login']);
    }

    async handleNextStep() {
        this.billingFormSubmitted = false;
        this.validSubmitRegion = true;
        this.model.domain = this.model.domain.toLowerCase();
        if (await this.validateStep()) {
            if (this.step === 1 && this.model.plan === 'trial') {
                this.step++;
            }
            if (this.step === 2 && !this.cardValid) {
                return;
            }
            if (this.step === 4) {
                // skipping over steps 4 (region selection) and 5 (billing section) as they're no longer used at the moment
                this.step = 6;
            }
            this.step++;
            if (this.step === 6) {
                this.stripeService.elements(this.elementsOptions).subscribe((elements) => {
                    this.elements = elements;
                    if (!this.card) {
                        this.card = this.elements.create('card', {
                            hidePostalCode: true,
                            style: {
                                base: {
                                    iconColor: '#d4d9e2',
                                    color: '#658292',
                                    lineHeight: '50px',
                                    fontWeight: 400,
                                    fontFamily: '"Open Sans", Arial, sans-serif',
                                    fontSize: '15px',
                                    '::placeholder': {
                                        color: '#CFD7E0',
                                    },
                                },
                            },
                        });
                        this.card.mount('#ccCard');
                    }
                });
            } else if (this.step === 7) {
                this.signup();
            }
        }
        this.setStepParameter();
        this.setSignupData();
    }

    setStepParameter() {
        const stepName = this.getStepName();
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { stepName: stepName, stepNum: this.step },
            queryParamsHandling: 'merge',
        });
    }

    getStepName() {
        let stepName = this.step + '';
        switch (this.step) {
            case 1:
                stepName = 'chosePlan';
                break;
            case 2:
                stepName = 'accountSetup';
                break;
            case 3:
                stepName = 'chooseOrgName';
                break;
            case 4:
                stepName = 'validateEmail';
                break;
            case 5:
                break;
            case 6:
                break;
            case 7:
                stepName = 'emailValidated';
                break;
            default:
                break;
        }
        return stepName;
    }

    async getCardToken() {
        // resetting cardError
        this.cardError = false;

        // getting the name provided in the signup form
        const billingName = this.signupForm1.get('name').value;

        // creating the token for the card
        const status = await this.stripeService
            .createToken(this.card, { name: billingName })
            .toPromise()
            .then((result) => {
                // if the result has a token
                if (result.token) {
                    // store the token ID
                    this.model.token = result.token.id;

                    return true;
                } else if (result.error) {
                    // if the result instead has an error
                    this.logger.info(result.error.message);

                    // display the error and resulting error message
                    this.cardError = true;
                    this.cardErrorString = result.error.message;
                    return false;
                }
            });
        return status;
    }

    async validateStep() {
        if (
            (this.customerId === undefined && this.productId !== undefined) ||
            (this.customerId !== undefined && this.productId === undefined)
        ) {
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'AWS marketplace parameters missing',
                    'CustomerId and productId are mandatory for creating AWS customer organization. Remove all the query parameters for creating other organizations'
                )
            );
            return false;
        }
        if (this.step >= 8) {
            return false;
        }
        if (this.step === 6) {
            this.billingFormSubmitted = true;
            const result = (await this.getCardToken()) && this.model.terms && this.billingForm.valid;
            return result;
        } else if (this.step === 4) {
            const result = await this.submitEmailValidationToken();
            return result;
        } else if (this.step === 3) {
            if (this.validateSiteName()) {
                const result = await this.checkSiteName();
                if (result) {
                    this.sendEmailValidation();
                }
                return result;
            } else {
                return false;
            }
        } else if (this.step === 2) {
            this.isZipCodeValid = this.validateService.isValidPostalCode(this.model.zip, this.model.countryCode);
            if (!this.isZipCodeValid) {
                return false;
            }
            this.isLoading = true;
            this.cardValid = await this.cardComponent
                .tokenize()
                .then((data: { token: any }) => {
                    this.customer.paymentMethod.cardToken = data.token;
                    this.cardValid = true;
                    this.isLoading = false;
                    this.logger.info(JSON.stringify(this.customer));
                    return true;
                })
                .catch((error: any) => {
                    this.isLoading = false;
                    //this.error = 'Problem while tokenizing your card details';
                    this.logger.error(error);
                    this.cardValid = false;
                    return false;
                });
            if (this.cardValid) {
                this.billingService.sendEmailVerification(this.model.email);
            }
        } else if (this.step === 1) {
            this.isZipCodeValid = true;
            this.model.zip = _.trim(this.model.zip);
            this.model.email = _.trim(this.model.email);
            if (this.model.plan === 'trial') {
                this.isZipCodeValid = this.validateService.isValidPostalCode(this.model.zip, this.model.countryCode);
            }
            const isEmailValid = await this.billingService
                .checkEmail(this.model.email)
                .toPromise()
                .then((result: any) => {
                    this.isBlackListed = false;

                    if (result.length === 0) {
                        return this.model.terms;
                    } else {
                        this.signupForm1.get('email').setErrors(Validators.required);
                        this.isBlackListed = true;
                        return false;
                    }
                });
            return this.isZipCodeValid && isEmailValid;
        }
        return true;
    }

    sendEmailValidation() {
        this.billingService
            .sendEmailVerification(this.model.email)
            .toPromise()
            .then((result: any) => {
                this.emailVerificationId = result.requestId;
            });
    }

    submitEmailValidationToken() {
        if (_.isEmpty(this.emailVerificationId)) {
            return Promise.resolve(true);
        }
        return this.billingService
            .submitEmailVerificationToken(this.model.email, this.emailVerificationId, this.emailValidationToken)
            .toPromise()
            .then((result: any) => {
                this.emailValidationErrors = {};
                if (result?.code.toLowerCase() === 'success') {
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Email Valid.',
                            'Your email has been successfully validated.'
                        )
                    );
                    return true;
                } else if (result?.code.toLowerCase() === 'expired') {
                    this.emailValidationErrors['expired'] = true;
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Failed',
                            'Expired Token.',
                            'The token entered is expired. Please request a new token.'
                        )
                    );
                    return false;
                } else {
                    // code could be 'invalid' or it's an unknown state, catch all not 'success' or 'expired'
                    this.emailValidationErrors['invalid'] = true;
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Failed',
                            'Invalid Token.',
                            'The token entered is invalid. Please re-enter and try again.'
                        )
                    );
                    return false;
                }
            })
            .catch((result) => {
                this.growlerService.show(
                    new GrowlerData(
                        'error',
                        'Failed',
                        'Token Validation Failed.',
                        'Unknown error occurred when validating token. Please try again.'
                    )
                );
                return false;
            });
    }

    validateSiteName() {
        let isValid = false;

        isValid = domainRegex.test(this.model.domain);

        if (!isValid) {
            this.vanityUrlForm.get('domain').setErrors(Validators.required);
            this.invalidDomain = true;
        } else {
            this.invalidDomain = false;
        }
        return isValid;
    }

    validateToken() {
        this.tokenValid = true;
    }

    tokenChanged(event) {
        _.delay(() => {
            this.tokenEmpty = _.isEmpty(this.emailValidationToken);
            if (this.emailValidationToken?.length === 6) {
                this.moveNext();
            }
        }, 50);
    }

    resendToken() {
        this.billingService
            .sendEmailVerification(this.model.email)
            .toPromise()
            .then((result: any) => {
                this.emailVerificationId = result.requestId;
                this.growlerService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Token Resent.',
                        'A new token has been sent to: ' + this.model.email
                    )
                );
            });
    }

    copy() {
        const element = <HTMLInputElement>document.getElementById('DomainName');
        element.focus();
        element.select();
        document.execCommand('copy');
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Site Url Copied',
                'Your new site url ' + this.model.domain + ' has been copied to your clipboard'
            )
        );
    }

    countryChanged() {
        this.isZip = !this.model.countryCode || this.model.countryCode === '' || this.model.countryCode === 'US';
        if (this.isZip) {
            this.mailCodeText = 'Zip';
        } else {
            this.mailCodeText = 'Postal Code';
        }
    }
    openTerms(event) {
        this.termsOpened = true;
    }

    selectTerms() {
        if (!this.termsOpened) {
            return;
        }
        this.model.terms = !this.model.terms;
    }

    selectPlan(plan) {
        this.model.plan = plan;
        if (this.model.plan !== 'trial') {
            this.signupForm1.removeControl('countryCode');
            this.signupForm1.removeControl('zip');
        } else {
            this.signupForm1.addControl('countryCode', this.fb.control('', [Validators.required]));
            this.signupForm1.addControl('zip', this.fb.control('', [Validators.required]));
        }
    }

    select(region) {
        region.selected = !region.selected;
        let foundSelected = false;
        this.selectedRegions = [];
        for (let i = 0; i < this.regions.length; i++) {
            const current: BillableRegion = this.regions[i];
            if (current.selected) {
                this.selectedRegions.push(current);
                if (foundSelected) {
                    this.totalCost += this.cost;
                    current.cost = '$' + this.cost.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                } else {
                    this.totalCost = 0;
                    current.cost = 'Free';
                    foundSelected = true;
                }
            } else {
                current.cost = '$' + this.cost.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }
        }
        if (this.totalCost <= 0) {
            this.totalCostString = 'Free';
        } else {
            this.totalCostString = '$' + this.totalCost.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '/mo';
        }
        if (!foundSelected) {
            for (let i = 0; i < this.regions.length; i++) {
                const current: BillableRegion = this.regions[i];
                current.cost = '';
            }
            this.totalCostString = '';
        }

        if (this.selectedRegions.length > 0) this.selectedAtLeastOneRegion = true;
        else this.selectedAtLeastOneRegion = false;
    }

    toggleCreate() {
        this.model.createNetwork = !this.model.createNetwork;
    }

    /**
     * This call is stubbed in for when we have a selectable authorization provider
     */
    getProviders() {
        this.iamService.getProviders().subscribe((providers: any) => {
            this.logger.info('Providers', providers);
            // @FIXME - TEMP fix until pipeline clears on the backend
            for (const provider of providers) {
                if (provider.auth0ConnectionId === 'Username-Password-Authentication') {
                    provider.auth0ConnectionType = 'Database';
                    // @FIXME - Force the DB provider as the default for now, eventually this will change to be selectable
                    this.dbProvider = provider;
                } else {
                    provider.auth0ConnectionType = 'Social';
                }
                this.providerTypes.push(provider);
            }
        });
    }

    loadSignupData() {
        const localData = localStorage.getItem(`nf_signup_data`);
        if (!localData) {
            return;
        }
        let data;
        try {
            data = JSON.parse(localData);
        } catch (er) {}
        if (!data) {
            return;
        }
        this.model.domain = data.name;
        this.customer.paymentMethod.cardToken = data.billingInfo?.cardToken;
        this.model.plan = _.toLower(data.billingInfo?.plan);
        this.model.firstName = data.adminUser?.firstName;
        this.model.lastName = data.adminUser?.lastName;
        this.model.email = data.adminUser?.email;
        this.model.countryCode = data.adminUser?.countryCode || '';
        this.model.zip = data.adminUser?.zip;
    }

    setSignupData() {
        const provider = {
            name: this.model.domain + ' ' + this.dbProvider.identityProviderTypeName,
            identityProviderTypeId: this.dbProvider.id,
        };
        const data = {
            name: this.model.domain,
            label: this.model.domain,
            identityProviders: [provider],
            billingInfo: {
                cardToken: this.customer.paymentMethod.cardToken,
                plan: this.model.plan.charAt(0).toUpperCase() + this.model.plan.slice(1),
            },
            adminUser: {
                firstName: this.model.firstName,
                lastName: this.model.lastName,
                email: this.model.email,
                countryCode: this.model.countryCode,
                zip: this.model.zip,
                emailOTP: this.emailValidationToken,
                metadata: {
                    signup_code: this.signupCode ? this.signupCode : this.model.plan,
                },
            },
        };
        localStorage.setItem(`nf_signup_data`, JSON.stringify(data));
    }

    async newCustomerSignup() {
        this.setSignupData();
        this.createNewUserAccount(this.model.email, this.model.domain, true);
    }

    async createNewUserAccount(nfEmail, tenantLabel, isSelfService?: boolean) {
        const state = {
            appState: {
                target: '/signup-complete',
                identityProviders: ['Username-Password-Authentication'],
                tenantLabel: tenantLabel,
                nfEmail: nfEmail,
                new_self_service: true,
                organization_id: tenantLabel,
            },
            organization_id: tenantLabel,
            nfEmail: nfEmail,
            tenantLabel: tenantLabel,
            allowSignup: true,
            showSignup: true,
            allowSignin: true,
            isInvitation: false,
            new_self_service: true,
            identityProviders: JSON.stringify(['Username-Password-Authentication']),
            redirect_uri: this.environment.authconfig.newSignupRedirect,
        };
        const signupData = JSON.parse(localStorage.getItem('nf_signup_data'));
        signupData.name = tenantLabel;
        signupData.label = tenantLabel;
        signupData.adminUser.email = nfEmail;
        localStorage.setItem('nf_signup_data', JSON.stringify(signupData));
        this.auth0Service.loginWithRedirect(state);
    }

    async signup() {
        //Wait a moment on the email validation success page before directing to Auth0
        setTimeout(() => {
            this.newCustomerSignup();
        }, 4000);
        return;
    }

    getSelfServiceProductId() {
        let productId = 'Netfoundry-Freemium';
        switch (this.model.plan) {
            case 'trial':
                productId = 'Trial';
                break;
            case 'growth':
                productId = 'Netfoundry-Pay-As-You-Go';
                break;
            default:
                productId = 'Netfoundry-Freemium';
                break;
        }
        return productId;
    }

    async getOrgId() {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const tenantId = this.http
            .get(this.environment.identityConfig.url + 'tenants/label/' + this.model.domain, { headers: headers })
            .toPromise()
            .then((response) => response['id']);
        return tenantId;
    }

    failedSubmitRegion() {
        this.validSubmitRegion = false;
    }

    // TODO: get google signin working
    initiateGoogleSignIn() {
        this.logger.info('User clicked the google sign in button');
    }

    // TODO: get microsoft signin working
    initiateMicrosoftSignIn() {
        this.logger.info('User clicked the microsoft sign in button');
    }

    async getOrganization(orgName) {
        const headers = new HttpHeaders();
        const orgId = await this.getOrgId();
        this.orgService.getResource(orgId);
    }

    async saveProfile(token) {
        if (this.signupForm1.valid && this.signupForm2.valid) {
            const orgId = await this.getOrgId();
            const email = this.signupForm1.get('email').value;

            this.customer.firstName = this.signupForm1.get('firstName').value;
            this.customer.lastName = this.signupForm1.get('lastName').value;
            this.customer.email = email;
            this.customer.location = {
                country: this.model.countryCode,
                zipcode: this.model.zip,
            };
            this.customer.site = {
                name: this.model.domain,
                label: this.model.domain,
                networkGroupId: orgId,
                billingAccountId: undefined,
            };

            if (token !== null) {
                this.isLoading = true;
                const wasProfileCreated = await this.billingService
                    .createPaymentProfileCard(this.customer)
                    .toPromise()
                    .then((result) => {
                        this.logger.info(result);
                        return true;
                    })
                    .catch((error: { token: any }) => {
                        this.isLoading = false;
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Account Creation Error',
                                'There was an error creating the account, please try again. If the issue persists please contact support.'
                            )
                        );
                        return false;
                    });

                return wasProfileCreated;
            }
        }
        return false;
    }

    private async checkSiteName() {
        const nameValid = await this.customerService
            .validateSignUp({ label: this.model.domain, name: this.model.domain }, 'label')
            .then((result) => {
                if (!result) {
                    return result;
                }
                let validName = true;
                result.forEach((row: any) => {
                    if (row.label === 'label' || row.label === 'name') {
                        validName = false;
                        this.growlerService.show(new GrowlerData('error', 'Error', 'Organization Name', row.message));
                        this.step = 3;
                        this.vanityUrlForm.get('domain').setErrors(Validators.required);
                    }
                });
                return validName;
            });
        return nameValid;
    }
}
