import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-table-cell-active',
    templateUrl: './table-cell-active.component.html',
    styleUrls: ['./table-cell-active.component.scss'],
})
export class TableCellActiveComponent implements ICellRendererAngularComp {
    cellParams;
    item = {
        id: '',
        name: '',
        selected: false,
        active: false,
        roleItem: false,
    };

    agInit(params: ICellRendererParams): void {
        this.cellParams = params;
        this.item = params.data;
    }

    refresh(params: ICellRendererParams): boolean {
        this.cellParams = params;
        this.item = params.data;
        return true;
    }

    edit(item) {
        //stub for user open function
    }
}
