<div class="fullModal opened" id="AppWANModal">
    <div class="fullRow">
        <div class="sized">
            <div *ngIf="step === 1" class="title stepItem isAt">CLOUD INTERCONNECT APPWAN INTRODUCTION</div>
            <div *ngIf="isNotIntroSummary()" class="title stepItem isAt">CREATE A NEW APPWAN</div>
            <div *ngIf="step === 9" class="title stepItem isAt">CONGRATULATIONS</div>

            <div *ngIf="step === 1" class="subtitle tiny stepItem isAt">
                In this builder, we'll guide you through the process of enabling bi-directional communication between
                two clouds. We'll start by creating
                <a
                    href="https://netfoundry.zendesk.com/hc/en-us/articles/360017558212-Introduction-to-Gateway-Endpoints"
                    target="_blank"
                    >Gateways</a
                >
                to install in both clouds. For each Gateway, we'll specify the
                <a
                    href="https://netfoundry.zendesk.com/hc/en-us/articles/360018509951-Introduction-to-Servicess"
                    target="_blank"
                    >Services</a
                >, Single IP's or a Range of IP's, through which the cloud can be accessed. In the summary screen,
                you'll see these represented as two separate
                <a
                    href="https://netfoundry.zendesk.com/hc/en-us/articles/360018510571-Introduction-to-AppWANs"
                    target="_blank"
                    >AppWAN</a
                >
                with the same name — the first allowing ingress access to cloud one, and second allowing ingress access
                to cloud two.
            </div>

            <div *ngIf="step === 2" class="subtitle smaller stepItem isAt">
                Looking to enable bi-directional communication between clouds?<br />Great, get started by naming your
                app and tapping next.
            </div>
            <div *ngIf="step === 3" class="subtitle smaller stepItem isAt">
                Perfect, now tell us about<br />the first cloud
            </div>
            <div *ngIf="step === 4" class="subtitle smaller stepItem isAt">
                Great, now tell us about<br />Cloud Two.
            </div>
            <div *ngIf="step === 5" class="subtitle smaller stepItem isAt">
                Excellent, now let's specify the services through<br />which your current cloud can communicate with
                your new cloud
            </div>
            <div *ngIf="step === 6" class="subtitle smaller stepItem isAt">
                Excellent, now let's specify the services through<br />which your current cloud can communicate with
                your new cloud
            </div>
            <div *ngIf="step === 9" class="subtitle smaller stepItem isAt">
                Your AppWAN has been created! A network summary is below.<br />What’s next? Finalize the connection by
                installing these gateways in each cloud.
            </div>

            <div class="row">
                <div *ngIf="step === 1" class="slider isAt">
                    <div class="step1Icon"></div>
                    <div class="slideTitle upper">Your App Name - Cloud 1</div>
                    <div class="slideTitle">Your App Name - Cloud 2</div>
                </div>

                <div
                    [ngClass]="{
                        slider: !this.skip || hasStepped,
                        isAt: step === 2,
                        passed: isPassedStep(2)
                    }"
                >
                    <div *ngIf="step === 2">
                        <div class="step2Icon"></div>
                        <div class="wizard mini">
                            <div class="header">
                                <div class="darken"></div>
                                <div class="darkenText">What would you like to call this AppWAN?</div>
                            </div>
                            <div class="padded">
                                <label [ngClass]="{ error: form.appWanName.error }" for="AppWANName"
                                    >{{ form.appWanName.label
                                    }}<span [ngClass]="{ error: form.appWanName.error }" class="note"
                                        >Required</span
                                    ></label
                                >
                                <input
                                    [(ngModel)]="form.appWanName.value"
                                    [ngClass]="{ error: form.appWanName.error }"
                                    id="AppWanName"
                                    maxlength="100"
                                    placeholder="{{ form.appWanName.placeholder }}"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    [ngClass]="{
                        isAt: step === 3 || step === 4,
                        passed: isPassedStep(3) && isPassedStep(4)
                    }"
                    class="slider"
                >
                    <app-appwan-wizard-gateway-select
                        #gatewaySelect
                        *ngIf="step === 3 || step === 4"
                        [isInterconnect]="true"
                        [sourceGatewayModel]="sourceGateway"
                        [step]="step"
                        [targetGatewayModel]="targetGateway"
                    ></app-appwan-wizard-gateway-select>
                </div>

                <div [ngClass]="{ isAt: step === 5, passed: isPassedStep(5) }" class="slider">
                    <app-appwan-wizard-service-select
                        #serviceSelectCloudOne
                        *ngIf="step !== 1 && step !== 2"
                        [isInterconnect]="true"
                        [sourceGatewayType]="sourceGatewayType"
                        [step]="step"
                        [targetGatewayType]="targetGatewayType"
                    >
                    </app-appwan-wizard-service-select>
                </div>

                <div [ngClass]="{ isAt: step === 6, passed: isPassedStep(6) }" class="slider">
                    <app-appwan-wizard-service-select
                        #serviceSelectCloudTwo
                        *ngIf="step !== 1 && step !== 2"
                        [isInterconnect]="true"
                        [sourceGatewayType]="sourceGatewayType"
                        [step]="step"
                        [targetGatewayType]="targetGatewayType"
                    >
                    </app-appwan-wizard-service-select>
                </div>

                <div [ngClass]="{ isAt: step === 7, passed: isPassedStep(7) }" class="silder">
                    <app-appwan-wizard-save
                        #saveComponentCloudOne
                        *ngIf="step !== 1 && step !== 2"
                        [appwanModel]="modelCloudOne"
                        [services]="servicesCloudOne"
                        [showStep]="7"
                        [sourceGateway]="sourceGateway"
                        [step]="step"
                        [targetGateway]="targetGateway"
                        [titleString]="'Cloud One'"
                    >
                    </app-appwan-wizard-save>
                </div>

                <div [ngClass]="{ isAt: step === 8, passed: isPassedStep(8) }" class="silder">
                    <app-appwan-wizard-save
                        #saveComponentCloudTwo
                        *ngIf="step !== 1 && step !== 2"
                        [appwanModel]="modelCloudTwo"
                        [services]="servicesCloudTwo"
                        [showStep]="8"
                        [sourceGateway]="targetGateway"
                        [step]="step"
                        [targetGateway]="sourceGateway"
                        [titleString]="'Cloud Two'"
                    >
                    </app-appwan-wizard-save>
                </div>

                <div [ngClass]="{ isAt: step === 9, passed: isPassedStep(9) }" class="slider">
                    <div *ngIf="step !== 1 && step !== 2" class="sized mid">
                        <div class="grid two">
                            <div class="hint">
                                <div class="hinttitle">NEW GATEWAYS</div>
                                <div class="hintsubtitle">Tap to Launch and Register</div>
                                <div class="image icon-ClickIcon"></div>
                            </div>
                            <div class="hint">
                                <div class="hinttitle">NEW SERVICES</div>
                                <div class="hintsubtitle">Tap to Edit Advanced Options</div>
                                <div class="image icon-TaptoEditAdvanced"></div>
                            </div>
                        </div>
                    </div>
                    <div class="grid two summaryGrid">
                        <app-appwan-wizard-summary
                            [finalModel]="finalModelCloudOne"
                            [ipHostServices]="ipHostServicesCloudOne"
                            [ipNetworkServices]="ipNetworkServicesCloudOne"
                            [isInterconnect]="true"
                            [showStep]="9"
                            [sourceGatewayString]="'Cloud One'"
                            [step]="step"
                            [targetGatewayString]="'Cloud Two'"
                        >
                        </app-appwan-wizard-summary>

                        <app-appwan-wizard-summary
                            [finalModel]="finalModelCloudTwo"
                            [ipHostServices]="ipHostServicesCloudTwo"
                            [ipNetworkServices]="ipNetworkServicesCloudTwo"
                            [isInterconnect]="true"
                            [showStep]="9"
                            [sourceGatewayString]="'Cloud Two'"
                            [step]="step"
                            [targetGatewayString]="'Cloud One'"
                        >
                        </app-appwan-wizard-summary>
                    </div>

                    <div *ngIf="step === 9" class="sized mid">
                        <div (click)="clone()" class="cloneButton">
                            <div class="icon"></div>
                            <div class="content">
                                Want to add another environment<br />with the same services or endpoints?
                            </div>
                            <div class="buttonText">Tap To Clone</div>
                            <div class="tapIcon icon-ClickIcon"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div (click)="next()" [ngClass]="{ isAt: step === 1 }" class="button squared base" id="GetStarted">
            Let's Get Started
        </div>
        <div (click)="toggleSkip()" [ngClass]="{ isAt: step === 1 }" class="checkbox" id="toggleSkip">
            <div [ngClass]="{ selected: skip }" class="tSelect" id="toggleSkipButton"></div>
            <div class="checkLabel">Skip This Next Time</div>
        </div>

        <div (click)="close()" class="buttonBall close" id="CloseAppWanWizardButton">
            <div class="buttonLabel">ESC</div>
        </div>
        <app-form-support-button></app-form-support-button>

        <div [ngClass]="{ isAt: isNotIntroSummary() }" class="steps">
            <div [ngClass]="{ isAt: step === 2 }" class="step"></div>
            <div [ngClass]="{ isAt: step === 3 }" class="step"></div>
            <div [ngClass]="{ isAt: step === 4 }" class="step"></div>
            <div [ngClass]="{ isAt: step === 5 }" class="step"></div>
            <div [ngClass]="{ isAt: step === 6 }" class="step"></div>
        </div>

        <div (click)="back()" [ngClass]="{ isAt: isBetweenSteps(2, 7) }" class="button squared back" id="backButton">
            Back
        </div>
        <div (click)="next()" [ngClass]="{ isAt: isBetweenSteps(1, 7) }" class="button squared next" id="nextButton">
            Next
        </div>
        <div (click)="next()" [ngClass]="{ isAt: step === 6 }" class="button squared next" id="createButton">
            Create My AppWAN
        </div>
    </div>
</div>
