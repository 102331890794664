import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-json-view',
    templateUrl: './json-view.component.html',
    styleUrls: ['./json-view.component.scss'],
})
export class JsonViewComponent {
    codeData;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<JsonViewComponent>) {
        this.codeData = JSON.stringify(data, null, 2);
    }

    close() {
        this.dialogRef.close();
    }
}
