<div [ngClass]="{ inline: isInline }" class="fullModal opened addForm" id="AddGatewayModal">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div *ngIf="!isComplete && !isLoading" class="fullRow" id="Stage2">
        <div class="title" style="filter: brightness(50%)">Create A New Gateway</div>
        <div class="subtitle">Enter your Gateway attributes</div>

        <div class="sized">
            <div class="label row">
                Gateway Name
                <span [hidden]="!errorNameLength" class="error">Name must be at least 5 characters</span>
                <span [hidden]="errorNameLength" class="note">Required</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="model.name"
                    [ngClass]="{ error: errorName }"
                    class="restrictNormal"
                    id="EditName"
                    maxlength="{{ maxLength }}"
                    minlength="5"
                    placeholder="What would you like to call this gateway?"
                    type="text"
                />
            </div>

            <div class="label row">
                Cloud Region<span [hidden]="!errorRegion" class="error">Required</span
                ><span [hidden]="errorRegion" class="note">Required</span>
            </div>
            <div class="row">
                <select
                    (keyup.enter)="save()"
                    [(ngModel)]="model.geoRegionId"
                    [ngClass]="{ error: errorRegion }"
                    class="regions"
                    id="AzureStackRegionList"
                    placeholder="Select a Cloud Region"
                >
                    <option value="">Select A Region</option>
                    <option *ngFor="let region of regions | sortby: 'name'" [value]="region.id">
                        {{ region.name }}
                    </option>
                </select>
            </div>

            <div class="label row">Select Use<span [ngClass]="{ error: errorType }" class="note">Required</span></div>
            <div class="row">
                <select [(ngModel)]="typeAppend" [ngClass]="{ error: errorType }" id="ZitiUseList">
                    <option value="">Hosted or Private</option>
                    <option value="GW">Hosted</option>
                    <option value="NHGW">Private</option>
                </select>
            </div>

            <div *ngIf="showBreakoutFields">
                <div class="label row">
                    O365 Breakout Next Hop IP
                    <span [hidden]="!errorNextHop" class="error">Invalid</span>
                    <span [hidden]="errorNextHop" class="note">Optional</span>
                </div>
                <div class="row">
                    <input
                        [(ngModel)]="model.o365BreakoutNextHopIp"
                        [ngClass]="{ error: errorNextHop }"
                        class="restrictNormal"
                        id="NextHopIp"
                        placeholder="Next Hop IP"
                        type="text"
                    />
                </div>
            </div>

            <div class="row buttonRow right" style="margin-top: 20px">
                <button (click)="save()" *ngIf="!processing" class="save" id="SaveGatewayButton">Create</button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
        </div>
    </div>
    <div [hidden]="!isComplete || isLoading" class="fullRow" id="Stage3">
        <div class="title">congratulations</div>
        <div class="subtitle">Your Gateway {{ completedTitleStatusString }}</div>
        <div [ngClass]="{ building: isBuilding, created: !isBuilding }" class="row center">
            <span *ngIf="isBuilding" class="completedEndpointBubble">
                <svg class="circular" viewBox="25 25 50 50">
                    <circle
                        class="path"
                        cx="50"
                        cy="50"
                        fill="none"
                        r="20"
                        stroke-miterlimit="10"
                        stroke-width="5"
                    ></circle>
                </svg>
            </span>
        </div>

        <div>
            <div *ngFor="let gateway of gateways; let index = index" class="row">
                <div class="sized">
                    <div *ngIf="!zitiEnabledService.zitiPure">
                        <div [hidden]="gateways.length === 0" class="label row">
                            {{ gateway.name }} Registration Key<span class="note">Click to Copy</span>
                        </div>
                        <div class="row">
                            <input
                                (click)="gatewayFormService.copy(gateway)"
                                [(ngModel)]="gateway.registrationKey"
                                class="RegKey"
                                data-enter="SaveButton"
                                id="RegKey_{{ gateway.id }}"
                                readonly
                                style="margin-bottom: 10px"
                                type="text"
                            />
                        </div>
                    </div>
                    <div *ngIf="zitiEnabledService.zitiPure">
                        <div class="label row">
                            {{ gateway.name }} Registration Key<span class="note">Tap To Download</span>
                        </div>
                        <div class="row">
                            <div (click)="downloadJWT(gateway, index)" class="downloadButton">
                                <div class="DownloadKey"></div>
                                <div>DOWNLOAD KEY</div>
                                <div class="TapToDownload"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="subtitle">Download and install instructions</div>

        <div class="row center">
            <div (click)="gatewayFormService.openDownloads()" class="buttonCard shadow openable">
                <div class="gwdownloadicons" style="background-image: url(/assets/svgs/Icon_Download.svg)"></div>
                <div class="title">Download Install Package</div>
                <div class="content">
                    Gateway to connect to Ziti networks, branches, & data centers using this Ziti Enabled gateway.
                </div>
                <div class="row center buttonArea">
                    <button class="save">Download</button>
                </div>
            </div>
            <div
                (click)="
                    gatewayFormService.openUrl(
                        'https://netfoundry.zendesk.com/hc/en-us/sections/360002445391-Client-Gateway-Endpoints'
                    )
                "
                class="buttonCard shadow"
            >
                <div class="gwdownloadicons" style="background-image: url(/assets/svgs/Icon_Doc.svg)"></div>
                <div class="title">View Install Instructions</div>
                <div class="content">Connect to space using a Ziti enabled gateway.<br />&nbsp;</div>
                <div class="row center buttonArea">
                    <button class="save">View</button>
                </div>
            </div>
        </div>
    </div>
    <div (click)="hideForm()" class="buttonBall close" id="CloseGatewayButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div class="private">
        <div
            (click)="share()"
            *ngIf="authorizationService.canShareEndpoint(model.id)"
            [hidden]="!isComplete"
            class="buttonBall share"
            id="ShareGatewayButton"
        >
            <div class="buttonLabel">SHARE</div>
        </div>
    </div>
    <div (click)="goBack()" [hidden]="isLoading || isComplete" class="buttonBall back" id="BackGatewayButton">
        <div class="buttonLabel">BACK</div>
    </div>
</div>
