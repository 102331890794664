import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Endpoint, GatewayCluster, Service } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    FeatureService,
    GatewayClusterService,
    GatewayService,
    ServiceService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { rMerge } from 'ranges-merge';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

const invalidString = 'Invalid';
const requiredString = 'Required';

const provisionedStatus = 300;
const registeredStatus = 400;

const portRangeRegex = new RegExp(/^([0-9\s]{1,}(|-[0-9\s]{1,})|,){1,}$/);

const specificPortsString = 'SPECIFIC_PORTS';

const standaloneTypeTag = '[S]';
const haTypeTag = '[HA]';
const autoscaleTypeTag = '[AS]';

@Component({
    selector: 'app-ip-network-service-form',
    templateUrl: './ip-network-service-form.component.html',
    styleUrls: ['./ip-network-service-form.component.scss'],
})
export class IpNetworkServiceFormComponent implements OnInit, OnDestroy, OnChanges {
    @Input() model: Service = new Service({
        bridgeStp: 'YES',
        collectionLocation: 'BOTH',
        cryptoLevel: 'STRONG',
        dnsOptions: 'NONE',
        icmpTunnel: 'NO',
        localNetworkGateway: 'YES',
        multicast: 'OFF',
        pbrType: 'WAN',
        permanentConnection: 'NO',
        rateSmoothing: 'NO',
        serviceInterceptType: 'IP',
        portInterceptMode: 'INTERCEPT_ALL',
        interceptPorts: {
            include: [],
            exclude: [],
        },
    });

    @Input() canEdit = true;

    @Input() gatewayId;
    @Input() gatewaySelfLink;
    @Input() isInline = false;
    @Input() isCluster = false;
    @Input() isHAService = false;

    @Output() back: EventEmitter<boolean> = new EventEmitter();
    @Output() hide: EventEmitter<any> = new EventEmitter();

    ipNetworkGateways = [];

    isAdding = false;
    isEditing = false;
    isComplete = false;
    allowBack = true;

    gatewayMap = {};
    // these are used so that changes can be made to the model while still preserving the user's input to the form
    interceptIp;

    // variables for the network/interecpt port ranges and the intercept IP
    gatewayIp;
    processing = false;
    interceptIpErrorString;
    nameErrorString;
    gatewayIpErrorString;
    icmpEnabled = true;
    dnsEnabled = false;
    transparencyEnabled = false;
    permanentConnectionEnabled = false;
    dataInterleavingEnabled = false;
    includePorts = [];
    includedPortsString;
    includedPortRangeList = [];
    includedSinglePortList = [];
    condensedIncludedPortRangeList = [];
    excludePorts = [];
    excludedPortsString;
    excludedPortRangeList = [];
    excludedSinglePortList = [];
    condensedExcludedPortRangeList = [];
    showConfirmPorts = false;
    condensedPorts = [];
    @Input() hideHelp = false;
    clusterCount = 0;
    errors = {
        name: false,
        serviceClass: false,
        endpointId: false,
        gatewayClusterId: false,
        serviceType: false,
        interceptIp: false,
        networkIp: false,
        networkFirstPort: false,
        networkLastPort: false,
        interceptFirstPort: false,
        interceptLastPort: false,
        gatewayCidrBlock: false,
        Network: false,
        NetworkIntercept: false,
        includedPortSet: false,
        excludedPortSet: false,
    };
    disableGatewaySelect = false;
    hideHaServiceToggle = false;
    private canGetEndpoint = false;
    private subscription = new Subscription();

    constructor(
        private gatewayService: GatewayService,
        private apiService: ApiService,
        private service: ServiceService,
        private growlerService: GrowlerService,
        private validateService: ValidateService,
        private clusterService: GatewayClusterService,
        public featureService: FeatureService,
        private authorizationService: AuthorizationService
    ) {
        this.model = new Service({});
        this.isAdding = true;
        this.isEditing = false;
        this.allowBack = true;
        this.model = new Service({
            bridgeStp: 'YES',
            collectionLocation: 'BOTH',
            cryptoLevel: 'STRONG',
            dnsOptions: 'NONE',
            icmpTunnel: 'NO',
            localNetworkGateway: 'YES',
            multicast: 'OFF',
            pbrType: 'WAN',
            permanentConnection: 'NO',
            rateSmoothing: 'NO',
            serviceInterceptType: 'IP',
            gatewayClusterId: null,
            portInterceptMode: 'INTERCEPT_ALL',
            interceptPorts: {
                include: [],
                exclude: [],
            },
        });
    }

    async ngOnInit() {
        this.canGetEndpoint = this.authorizationService.canGetEndpoint(this.gatewayId);

        this.processing = false;
        // check if isEditing is true
        if (this.isEditing) {
            if (this.canGetEndpoint) {
                // if it is a HA service, get the gateway cluster associated with the service
                if (this.isHAService) {
                    this.subscription.add(
                        this.apiService
                            .getLinkedResource(new Service(this.model), 'gatewayCluster')
                            .subscribe((result) => {
                                const cluster = new GatewayCluster(result);

                                this.clusterCount++;

                                let typeTag = haTypeTag;
                                if (cluster.protectionType != null && cluster.protectionType === 'AwsAutoScale') {
                                    typeTag = autoscaleTypeTag;
                                }

                                // creating an object to store in the gateways lists
                                const currEndpoint = {
                                    id: cluster.id,
                                    type: cluster.endpointType,
                                    isHa: true,
                                    name: cluster.name,
                                    typeTag: typeTag,
                                };

                                // setting the selected endpoint to the endpoint object
                                this.gatewayId = currEndpoint.id;
                                this.gatewayMap[currEndpoint.id] = currEndpoint;

                                // adding the endpoint object to the gateways and non ziti gateways lists
                                this.ipNetworkGateways.push(currEndpoint);
                            })
                    );
                } else {
                    // otherwise, get the endpoint associated with the service
                    this.subscription.add(
                        this.apiService.getLinkedResource(this.model, 'endpoint').subscribe((result) => {
                            const gateway = new Endpoint(result);

                            // creating an object to be stored in the gateways lists
                            const currEndpoint = {
                                id: gateway.id,
                                type: gateway.endpointType,
                                isHa: false,
                                name: gateway.name,
                                typeTag: standaloneTypeTag,
                            };

                            // adding the object to the gateways and non ziti gateways lists
                            this.ipNetworkGateways.push(currEndpoint);
                            // setting the selected endpoint to the obtained endpoint object
                            this.gatewayId = currEndpoint.id;
                            this.gatewayMap[currEndpoint.id] = currEndpoint;
                        })
                    );
                }
            } else {
                if (this.isHAService) {
                    this.model.gatewayClusterId = this.gatewayId;
                } else {
                    this.model.endpointId = this.gatewayId;
                }
            }
        } else if (this.gatewaySelfLink != null) {
            if (this.canGetEndpoint) {
                this.subscription.add(
                    this.apiService.get(this.gatewaySelfLink).subscribe((result) => {
                        if (this.isHAService) {
                            const cluster = new GatewayCluster(result);
                            this.clusterCount++;

                            let typeTag = haTypeTag;
                            if (cluster.protectionType != null && cluster.protectionType === 'AwsAutoScale') {
                                typeTag = autoscaleTypeTag;
                            }
                            // creating an object to store in the gateways lists
                            const currEndpoint = {
                                id: cluster.id,
                                type: cluster.endpointType,
                                isHa: true,
                                name: cluster.name,
                                typeTag: typeTag,
                            };

                            // setting the selected endpoint to the endpoint object
                            this.gatewayId = currEndpoint.id;
                            this.gatewayMap[currEndpoint.id] = currEndpoint;

                            // adding the endpoint object to the gateways and non ziti gateways lists
                            this.ipNetworkGateways.push(currEndpoint);
                        } else {
                            const gateway = new Endpoint(result);

                            // creating an object to be stored in the gateways lists
                            const currEndpoint = {
                                id: gateway.id,
                                type: gateway.endpointType,
                                isHa: false,
                                name: gateway.name,
                                typeTag: standaloneTypeTag,
                            };

                            // adding the object to the gateways and non ziti gateways lists
                            this.ipNetworkGateways.push(currEndpoint);
                            // setting the selected endpoint to the obtained endpoint object
                            this.gatewayId = currEndpoint.id;
                            this.gatewayMap[currEndpoint.id] = currEndpoint;
                        }
                    })
                );
            } else {
                if (this.isHAService) {
                    this.model.gatewayClusterId = this.gatewayId;
                } else {
                    this.model.endpointId = this.gatewayId;
                }
            }
        } else {
            const allEndpointTypes = this.gatewayService.getGatewayTypes();
            const filterEndpointTypes = [];
            let endpointTypesString = '';
            for (const endpointType of allEndpointTypes) {
                if (
                    endpointType.value !== 'ZTGW' &&
                    endpointType.value !== 'ZTNHGW' &&
                    endpointType.value !== 'L2VCPEGW'
                ) {
                    filterEndpointTypes.push(endpointType.value);
                }
            }

            endpointTypesString = filterEndpointTypes.join(',');
            const proms = [];

            if (this.authorizationService.canListEndpoints()) {
                // if the form is not in an edit state, get all of the gateways and clusters
                const gatewayPromise = this.gatewayService
                    .get(undefined, undefined, undefined, undefined, endpointTypesString)
                    .pipe(take(1))
                    .toPromise()
                    .then((result) => {
                        // iterating over each gateway
                        for (const item of result) {
                            // creating a gateway object
                            const gateway = new Endpoint(item);

                            // creating an object to represent the endpoint in the list of gateways
                            const currEndpoint = {
                                id: gateway.id,
                                type: gateway.endpointType,
                                isHa: false,
                                name: gateway.name,
                                typeTag: standaloneTypeTag,
                            };

                            // TODO: update the service form and gateways dashboard to send in the whole gateway object in order to only grab the specific gateway
                            // if the endpointId object is set (the form was opened from a dashboard with a gateway provided) and the current endpoint's ID matches
                            if (currEndpoint.id === this.gatewayId) {
                                this.model.endpointId = currEndpoint.id;
                            }

                            // if the gateway's status is less than 700 and not 500 and it does not belong to a closter
                            if (
                                (gateway.status === provisionedStatus || gateway.status === registeredStatus) &&
                                gateway['endpointProtectionRole'] == null
                            ) {
                                // push the current endpoint object to the list of non ziti gateways
                                this.ipNetworkGateways.push(currEndpoint);
                                this.gatewayMap[currEndpoint.id] = currEndpoint;
                            }
                        }
                    });
                proms.push(gatewayPromise);
            }

            if (this.authorizationService.canListGatewayClusters()) {
                // and all of the clusters
                const clusterPromise = this.clusterService
                    .get()
                    .pipe(take(1))
                    .toPromise()
                    .then((result) => {
                        for (const item of result) {
                            this.clusterCount++;
                            // converting the item to a cluster object
                            const cluster = new GatewayCluster(item);

                            let typeTag = haTypeTag;
                            if (cluster.protectionType != null && cluster.protectionType === 'AwsAutoScale') {
                                typeTag = autoscaleTypeTag;
                            }

                            // creating an object to represent the endpoint in the list of gateways
                            const currEndpoint = {
                                id: cluster.id,
                                type: cluster.endpointType,
                                isHa: true,
                                name: cluster.name,
                                typeTag: typeTag,
                            };

                            // TODO: update the service form and gateways dashboard to send in the whole gateway object in order to only grab the specific gateway
                            // if the endpointId object is set (the form was opened from a dashboard with a cluster provided) and the current cluster's ID matches
                            if (currEndpoint.id === this.gatewayId) {
                                this.model.gatewayClusterId = this.gatewayId;
                            }

                            // if the cluster's status is less than 700 and not equal to 500
                            if (
                                cluster.status === provisionedStatus &&
                                cluster.endpointType !== 'ZTGW' &&
                                cluster.endpointType !== 'ZTNHGW'
                            ) {
                                // add the cluster to the list of non ziti gateways
                                this.ipNetworkGateways.push(currEndpoint);
                                this.gatewayMap[currEndpoint.id] = currEndpoint;
                            }
                        }
                    });
                proms.push(clusterPromise);
            }

            await Promise.all(proms);
        }
    }

    ngOnChanges() {
        // if a gateway was provided
        if (this.gatewayId != null) {
            // disable gateway select
            this.disableGatewaySelect = true;
        }

        this.model = new Service(this.model);
        if (this.model.portInterceptMode == null) {
            this.model.portInterceptMode = 'INTERCEPT_ALL';
        }

        if (this.model.interceptPorts == null) {
            this.model.interceptPorts = {
                include: [],
                exclude: [],
            };
        }
        if (this.model.id != null) {
            this.isAdding = false;
            this.isEditing = true;
            this.allowBack = false;
            this.disableGatewaySelect = true;
            this.dnsEnabled = this.model.dnsOptions.toLowerCase() === 'tunneling';

            this.icmpEnabled = this.model.icmpTunnel.toLowerCase() === 'yes';

            this.permanentConnectionEnabled = this.model.permanentConnection.toLowerCase() === 'yes';

            this.transparencyEnabled = this.model['transparency'].toLowerCase() === 'yes';

            this.dataInterleavingEnabled = this.model['dataInterleaving'].toLowerCase() === 'yes';

            this.isHAService = this.model.gatewayClusterId != null;

            this.gatewayId = this.isHAService ? this.model.gatewayClusterId : this.model.endpointId;

            if (this.model.protectionGroupId != null) {
                this.isHAService = true;
            }

            // if the model has an intercept IP and the intercept IP is different than the gateway IP
            if (this.model.interceptIp && this.model.interceptIp !== this.model.gatewayIp) {
                // setting the value of interceptIp to the value of the model's intercept IP
                this.interceptIp = this.model.interceptIp;
            }

            this.gatewayIp = this.model.gatewayIp + '/' + this.model['gatewayCidrBlock'];

            if (this.model.portInterceptMode === specificPortsString) {
                this.includedPortsString = this.buildPortString(this.model.interceptPorts.include);

                this.excludedPortsString = this.buildPortString(this.model.interceptPorts.exclude);
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    clearErrors() {
        for (const error of Object.keys(this.errors)) {
            this.errors[error] = false;
        }

        this.interceptIpErrorString = '';
        this.nameErrorString = '';
    }

    // function for initialzing the save
    initSave() {
        // clearing any errors
        this.clearErrors();

        // normalizing and validating the information provided
        this.normalizeGatwayIpAndInterceptIp();
        if (this.isAdding || this.canGetEndpoint) {
            const selectedGateway = this.gatewayMap[this.gatewayId];

            if (selectedGateway) {
                if (selectedGateway.isHa) {
                    this.model.gatewayClusterId = selectedGateway.id;
                    this.model.endpointId = null;
                    this.isHAService = true;
                } else {
                    this.model.endpointId = selectedGateway.id;
                    this.model.gatewayClusterId = null;
                }
            }
        }

        // if the form is valid
        if (this.validate()) {
            // if the user chose specific ports for the port intercept mode
            if (this.model.portInterceptMode === specificPortsString) {
                // show the confirm window
                this.showConfirmPorts = true;
            } else {
                // otherwise save
                this.save();
            }
        }
    }

    save() {
        this.processing = true;
        this.model.bridgeStp = 'YES';
        this.model.collectionLocation = 'BOTH';
        this.model.cryptoLevel = 'STRONG';
        this.model.dnsOptions = 'NONE';
        this.model.localNetworkGateway = 'YES';
        this.model.multicast = 'OFF';
        this.model.pbrType = 'WAN';
        this.model.rateSmoothing = 'NO';
        this.model.serviceInterceptType = 'IP';

        this.model.transparency = this.transparencyEnabled ? 'YES' : 'NO';

        this.model.permanentConnection = this.permanentConnectionEnabled ? 'YES' : 'NO';

        this.model.serviceType = 'ALL';
        this.model.icmpTunnel = this.icmpEnabled ? 'YES' : 'NO';

        this.model.dnsOptions = this.dnsEnabled ? 'TUNNELING' : 'NONE';

        // prevents the MOP from overwriting any change to the cidr block
        this.model.gatewayNetmask = null;

        if (this.dataInterleavingEnabled) {
            this.model.dataInterleaving = 'YES';
            this.model.rateSmoothing = 'NO';
            this.model.lowLatency = 'NO';
        } else {
            this.model.dataInterleaving = 'NO';
            this.model.rateSmoothing = 'NO';
            this.model.lowLatency = 'YES';
        }

        // if specific ports were set, store them in the proper fields
        if (this.model.portInterceptMode === specificPortsString) {
            this.model.interceptPorts.include = this.includePorts;
            this.model.interceptPorts.exclude = this.excludePorts;
        } else {
            // otherwise clear the include and exclude lists
            this.model.interceptPorts.include = [];
            this.model.interceptPorts.exclude = [];
        }

        this.subscription.add(
            this.service.save(this.model).subscribe(
                (result) => {
                    if (this.isAdding) {
                        this.model = new Service(result);
                    }
                    // TODO: Check results for api errors
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Creation Complete',
                            'Service information has been saved.'
                        )
                    );
                    this.isComplete = true;
                    this.processing = false;
                    this.hideForm({ updatedService: this.model });
                },
                (error) => {
                    this.processing = false;
                }
            )
        );
    }

    validate(): boolean {
        if (!this.validateService.hasValue(this.model.name)) {
            this.nameErrorString = requiredString;
        } else {
            this.nameErrorString = invalidString;
        }

        this.errors['name'] = !this.validateService.isValidName(this.model.name);
        if (this.isHAService) {
            // don't need to include gatewayClusterId in edit so no need to check
            if (!this.isEditing) {
                this.errors['gatewayClusterId'] = !this.validateService.hasValue(this.model.gatewayClusterId);
            }
        } else {
            this.errors['endpointId'] = !this.validateService.hasValue(this.model.endpointId);
        }

        this.errors['gatewayCidrBlock'] = !this.validateService.hasValue(this.model['gatewayCidrBlock']);
        this.errors['interceptIp'] = !this.validateService.isValidIP(this.model.interceptIp);
        this.errors['gatewayIp'] = !this.validateService.isValidCidrIP(this.gatewayIp);

        if (this.model.portInterceptMode === specificPortsString) {
            this.validatePortRanges(this.includedPortsString, this.excludedPortsString);
        }

        for (const error of Object.keys(this.errors)) {
            if (this.errors[error] === true) {
                return false;
            }
        }
        return true;
    }

    goBack() {
        if (this.showConfirmPorts) {
            this.fixInterceptPorts();
        } else {
            this.back.emit(true);
        }
    }

    hideForm(response?: unknown) {
        this.hide.emit(response);
    }

    toggleIcmpEnabled() {
        this.icmpEnabled = !this.icmpEnabled;
    }

    toggleDnsEnabled() {
        this.dnsEnabled = !this.dnsEnabled;
    }

    toggleTransparency() {
        this.transparencyEnabled = !this.transparencyEnabled;
    }

    togglePermanentConnection() {
        this.permanentConnectionEnabled = !this.permanentConnectionEnabled;
    }

    toggleDataInterleavingEnabled() {
        this.dataInterleavingEnabled = !this.dataInterleavingEnabled;
    }

    /**
     * function for condensing a given set of port ranges
     * @param portRangeSet the set of port ranges to condense
     */
    condensePortRange(portRangeSet, singlePorts?) {
        // using mergeRanges to do the first condensation of the set
        // this will condense the overlapping ranges and order them
        // however, this does not handle cases such as [[1, 5], [6,10]]
        // only overlapping or touching ranges like [[1,3], [2,5]] = [1-5]
        //  or [[1,3], [3,5]] = [3,5]
        const condensedPortSet = rMerge(portRangeSet, { mergeType: 2 }) || [];

        // list to hold the final port set
        let finalPortSet = [];

        // if there is more than one port set after being condensed
        if (condensedPortSet.length > 1) {
            // grab the first port set
            let prevRange = condensedPortSet[0];

            // loop through all the port sets after the first
            for (let i = 1; i < condensedPortSet.length; i++) {
                // get the current port set
                const currRange = condensedPortSet[i];

                // if the current ranges low end is equal to one more than the previous ranges high end
                if (prevRange[1] + 1 === currRange[0]) {
                    // condense the range further and continue
                    prevRange = [prevRange[0], currRange[1]];
                } else {
                    // otherwise, push the previous range to the final list as it was condensed as far as it could be
                    finalPortSet.push(prevRange);
                    // set the current range as the next previous and continue
                    prevRange = currRange;
                }
            }

            // push the last remaining port range
            finalPortSet.push(prevRange);
        } else {
            // if there's only one port range then there's nothing to do
            finalPortSet = condensedPortSet;
        }

        if (singlePorts != null && singlePorts.length > 0) {
            return this.condenseSinglePorts(singlePorts, finalPortSet);
        } else {
            return finalPortSet;
        }
    }

    /**
     * function for condensing the list of port ranges with the list of single ports
     * this is needed since the mergeRanges package strips single number ranges (i.e. [10-10]) when merging a set of ranges
     * @param singlePorts list of single ports
     * @param portRanges list of port ranges
     */
    condenseSinglePorts(singlePorts, portRanges) {
        if (portRanges.length === 0) {
            portRanges = [];

            for (const singlePort of singlePorts) {
                portRanges.push([singlePort, singlePort]);
            }
        } else {
            // loop through the single ports
            for (const portNumber of singlePorts) {
                let index = 0;
                let prevRange;
                // loop through the list of port ranges
                for (const portRange of portRanges) {
                    // if the low number of the port range is less than the current port number
                    if (portRange[0] < portNumber) {
                        // if the end of the range is greater than the current port number or equal to the current port number
                        if (portRange[1] >= portNumber) {
                            // no need to do anything, the current port number is already included
                            break;
                        } else if (portRange[1] + 1 === portNumber) {
                            // otherwise, if the current port number is one greater than the end of the range, increase the end of the range by 1
                            portRange[1] += 1;

                            // if this isn't the last item in the list
                            if (index < portRanges.length - 1) {
                                // look ahead to the next range
                                const nextRange = portRanges[index + 1];

                                // if the start of the next range is now equal to the end of the current range
                                //  or is one greater than the end of the current range, join the ranges
                                if (nextRange[0] === portRange[1] || nextRange[0] === portRange[1] + 1) {
                                    portRange[1] = nextRange[1];

                                    portRanges.splice(index + 1, 1);
                                }
                            }
                            break;
                        } else if (portRange[1] < portNumber && index === portRanges.length - 1) {
                            portRanges.push([portNumber, portNumber]);
                            break;
                        }

                        // if the end of the port range is less than the current port number then we need to continue searching
                    } else if (portRange[0] === portNumber) {
                        // if the first number in the port range is greater than
                        break;
                    } else if (portRange[0] - 1 === portNumber) {
                        // otherwise, if the current port number is one less than the start of the range, decrease the start of the range by 1
                        portRange[0] -= 1;

                        // if this isn't the first item in the list
                        if (index > 0) {
                            // look back to the previous range
                            const prevRange = portRanges[index - 1];

                            // if the end of the previous range now equals the start of the current range
                            //  or the end of the previous range is now one less than the start of the current range, join the ranges
                            if (prevRange[1] === portRange[0] || prevRange[1] === portRange[0] - 1) {
                                portRange[0] = prevRange[1];

                                portRanges.splice(index - 1, 1);
                            }
                        }

                        break;
                    } else if (portRange[0] > portNumber) {
                        // if the first number of the range is greater than the single port number, add the single port number to the list of ranges before
                        // the current port range
                        portRanges.splice(index, 0, [portNumber, portNumber]);
                        break;
                    }
                    prevRange = portRange;
                    index++;
                }
            }
        }
        return portRanges;
    }

    /**
     * function for getting the final list of included ports
     * @param includedPortSet the condensed list of included ports provided by the user
     * @param excludePortSet the condnesed list of excluded ports provided by the user
     */
    excludePortRanges(includedPortSet, excludePortSet) {
        const finalPortSet = includedPortSet.slice(0);

        for (const excludedRange of excludePortSet) {
            let index = 0;
            for (const includedRange of finalPortSet) {
                // if the entire exclude range is less than the include range
                if (excludedRange[0] < includedRange[0] && excludedRange[1] < includedRange[0]) {
                    // finish the loop
                    break;

                    // otherwise, if the exclude range matches the include range
                } else if (excludedRange[0] <= includedRange[0] && excludedRange[1] >= includedRange[1]) {
                    // remove the include range
                    finalPortSet.splice(index, 1);
                    break;

                    // otherwise, if the ecluded range is within the included range
                } else if (excludedRange[0] > includedRange[0] && excludedRange[1] < includedRange[1]) {
                    // make a new included range from the start of the old to one less than the start of the excluded range
                    const newIncludedRange1 = [includedRange[0], excludedRange[0] - 1];

                    // make a new included range from one more than the end of the excluded range to the end of the included range
                    const newIncludedRange2 = [excludedRange[1] + 1, includedRange[1]];

                    // add the lower range
                    finalPortSet[index] = newIncludedRange1;

                    // increment the index
                    index++;

                    // add the upper range
                    finalPortSet.splice(index, 0, newIncludedRange2);

                    // break from the loop
                    break;

                    // otherwise, if the end of the excluded range is inside the included range
                    //  and the begining of the excluded range is less than begining of the included range
                } else if (excludedRange[0] <= includedRange[0] && excludedRange[1] < includedRange[1]) {
                    // move the start of the included range to exclude the end of the excluded range
                    includedRange[0] = excludedRange[1] + 1;
                    break;

                    // otherwise, if the start of the excluded range is inside of the included range
                    // and the end of the excluded range is outside of the included range
                } else if (
                    excludedRange[0] > includedRange[0] &&
                    excludedRange[0] <= includedRange[1] &&
                    excludedRange[1] >= includedRange[1]
                ) {
                    // move the end of the included rnage to exclude the start of the excluded range
                    includedRange[1] = excludedRange[0] - 1;

                    // don't break because the end of the excluded range may overlap another range
                }

                index++;
            }
        }
        return finalPortSet;
    }

    /**
     * function for normalizing a given port set
     * @param portSet the port set to normalize
     */
    normalizePortSet(portSet) {
        let isValid = true;

        // list to return
        const normalizedPortRangeList = [];
        const normalizedSinglePorts = [];

        const normalizedPortsSaveObject = [];

        if (portSet != null && portSet !== '') {
            portSet = portSet.replace(/(\r\n|\n|\r)/gm, '');

            // doing the intial split to split the string into the separate ranges
            const portRangeList = portSet.split(',');

            // looping through the list of port ranges
            for (let portRangeString of portRangeList) {
                portRangeString = portRangeString.trim();

                if (portRangeString !== '') {
                    // spliting the list further into the individaul ports
                    const portRangeStringList = portRangeString.trim().split('-');
                    // item to be pushed to the final list
                    const portRange = [];

                    // if the port range was split into 2 numbers
                    if (portRangeStringList.length === 2) {
                        // trim the first/last porst of any excess whitespace and convert them into anumber
                        portRange[0] = parseInt(portRangeStringList[0].trim(), 10);
                        portRange[1] = parseInt(portRangeStringList[1].trim(), 10);
                        if (portRange[0] !== portRange[1]) {
                            normalizedPortsSaveObject.push({
                                first: portRange[0],
                                last: portRange[1],
                            });

                            // add the normalized port to the final list
                            normalizedPortRangeList.push(portRange);

                            // validating the current port range
                            isValid =
                                isValid &&
                                this.validateService.isValidPortOrPortRange(`${portRange[0]}-${portRange[1]}`);
                        } else {
                            normalizedSinglePorts.push(portRange[0]);

                            normalizedPortsSaveObject.push({
                                first: portRange[0],
                                last: portRange[0],
                            });

                            isValid =
                                isValid && this.validateService.isValidPortOrPortRange(portRangeStringList[0].trim());
                        }
                    } else if (portRangeStringList.length === 1) {
                        const normalizedPort = parseInt(portRangeStringList[0].trim(), 10);

                        // if there port range was only one number, make both ends of the range the same number
                        normalizedSinglePorts.push(normalizedPort);

                        normalizedPortsSaveObject.push({
                            first: normalizedPort,
                            last: normalizedPort,
                        });

                        // validating the current port number
                        isValid = isValid && this.validateService.isValidPortOrPortRange(portRangeStringList[0].trim());
                    }
                }
            }
        }
        // return the final list
        return {
            portRanges: normalizedPortRangeList,
            singlePorts: normalizedSinglePorts,
            saveObject: normalizedPortsSaveObject,
            isValid: isValid,
        };
    }

    /**
     * function for validating the port ranges provided
     * @param includedPortRangeString the string representing the included ports
     * @param excludedPortRangeString the string represnting the excluded ports
     */
    validatePortRanges(includedPortRangeString, excludedPortRangeString) {
        // boolean to return whether or not the port ranges are valid
        let isValid = true;

        // clearing previous errors
        this.errors['includedPortSet'] = false;
        this.errors['excludedPortSet'] = false;

        // if an included port range was provided
        if (includedPortRangeString != null) {
            // making sure the included port range string is valid, this regex verifies that the port ranges are
            //  separated by a dash and the ranges or individual ports are separated by commas
            if (!portRangeRegex.test(includedPortRangeString)) {
                isValid = false;
                this.errors['includedPortSet'] = true;
            }

            // making sure the excluded port range string is valid, this regex verifies that the port ranges are
            //  separated by a dash and the ranges or individual ports are separated by commas
            if (excludedPortRangeString != null && !portRangeRegex.test(excludedPortRangeString)) {
                isValid = false;
                this.errors['excludedPortSet'] = true;
            }

            // clearing the lists containing the port ranges
            this.includedPortRangeList = [];
            this.excludedPortRangeList = [];

            // if the strings passed the regex
            if (isValid) {
                // normalize the included port string
                const normalizedInclude = this.normalizePortSet(includedPortRangeString);

                // normalize the ecluded port string
                const normalizedExclude = this.normalizePortSet(excludedPortRangeString);

                // obtain the list of included port ranges, the list of included single ports, and the combined list (for saving)
                //  and whether or not the ports/ranges are valid
                this.includedPortRangeList = normalizedInclude.portRanges;
                this.includedSinglePortList = normalizedInclude.singlePorts;
                this.includePorts = normalizedInclude.saveObject;
                this.errors['includedPortSet'] = !normalizedInclude.isValid;

                // obtain the list of excluded port ranges, the list of excluded single ports, the combined list (for saving),
                //  and whether or not the ports/ranges are valid
                this.excludedPortRangeList = normalizedExclude.portRanges;
                this.excludedSinglePortList = normalizedExclude.singlePorts;
                this.excludePorts = normalizedExclude.saveObject;
                this.errors['excludedPortSet'] = !normalizedExclude.isValid;

                isValid = normalizedExclude.isValid && normalizedInclude.isValid;
            }

            // if everything so far has been valid
            if (isValid) {
                // condense the included ports
                this.condensedIncludedPortRangeList = this.condensePortRange(
                    this.includedPortRangeList,
                    this.includedSinglePortList
                );

                // condense the excluded ports
                this.condensedExcludedPortRangeList = this.condensePortRange(
                    this.excludedPortRangeList,
                    this.excludedSinglePortList
                );

                // get the final port range with the exclusions taken out
                this.condensedPorts = this.excludePortRanges(
                    this.condensedIncludedPortRangeList,
                    this.condensedExcludedPortRangeList
                );

                // if the final port information minus the exclusions is an empty set
                if (this.condensedPorts.length === 0) {
                    // mark errors
                    this.errors['includedPortSet'] = true;
                    this.errors['excludedPortSet'] = true;

                    isValid = false;
                }
            }
        } else {
            // mark an error if the included port set was not provided
            this.errors['includedPortSet'] = true;
            isValid = false;
        }
        return isValid;
    }

    /**
     * function for translating the save object into a string
     * @param portSaveObject the save object to build the string from
     */
    buildPortString(portSaveObject) {
        // string to be returned
        let portSetString = '';

        // looping through each port range
        for (let i = 0; i < portSaveObject.length - 1; i++) {
            const portSet = portSaveObject[i];

            if (portSet['first'] !== portSet['last']) {
                // appending the first and last port numbers spearated by a dash and followed by a comma to the string
                portSetString += `${portSet['first']}-${portSet['last']}, `;
            } else {
                // if the first and last port are equal, append the single port
                portSetString += `${portSet['first']}, `;
            }
        }

        // appending the final port range
        const finalPortSet = portSaveObject[portSaveObject.length - 1];

        if (finalPortSet['first'] !== finalPortSet['last']) {
            // appending the first and last port numbers spearated by a dash
            portSetString += `${finalPortSet['first']}-${finalPortSet['last']}`;
        } else {
            // if the first and last port are equal, append the single port
            portSetString += `${finalPortSet['first']}`;
        }

        // returning the string
        return portSetString;
    }

    /**
     * function for making the back button go back to the edit screen when looking at the port range confirmation
     */
    fixInterceptPorts() {
        this.showConfirmPorts = false;
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.hideForm(new Service(this.model));
    }

    private normalizeGatwayIpAndInterceptIp() {
        if (this.gatewayIp !== null && this.gatewayIp !== undefined) {
            const ipAndCidrBlock = this.gatewayIp.split('/');
            this.model.gatewayIp = ipAndCidrBlock[0];
            if (ipAndCidrBlock.length === 2) {
                this.model['gatewayCidrBlock'] = ipAndCidrBlock[1];
            } else {
                this.model['gatewayCidrBlock'] = 0;
            }
        }

        // if the intercept IP was not provided, use the provided gateway IP
        // NOTE: using == for checking null as that checks both null and undefined
        if (this.interceptIp == null || this.interceptIp.trim() === '') {
            // set the intercept IP to be the same as the gateway IP
            this.model.interceptIp = this.model.gatewayIp;
            this.interceptIpErrorString = '';

            // otherwise, if the intercept IP was provided
            // NOTE: using != for checking null as that checks both null and undefined
        } else {
            // if the intercept IP was provided, setting the error string to Invalid in the event tha there are errors
            this.interceptIpErrorString = invalidString;

            // setting the intercept IP of the model to the user provided value
            this.model.interceptIp = this.interceptIp;
        }

        if (this.model.gatewayIp == null || this.model.gatewayIp.trim() === '') {
            // if the gateway IP was not provided, setting the error string to Required
            this.gatewayIpErrorString = requiredString;
        } else {
            // otherwise, setting the error string to Invalid in the event that there was an error
            this.gatewayIpErrorString = invalidString;
        }
    }
}
