import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureZitiUtilizationBasicModule } from '@netfoundry-ui/feature/ziti-utilization-basic';
import { FeatureDateFiltersModule } from '@netfoundry-ui/feature/date-filters';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { UiNoitemsModule } from '@netfoundry-ui/ui/noitems';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedApiv2Module } from '@netfoundry-ui/shared/apiv2';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { FeatureHighchartsGenericModule } from '@netfoundry-ui/feature/highcharts-generic';
import { MatTabsModule } from '@angular/material/tabs';
import { FeatureZitiModule } from '@netfoundry-ui/feature/ziti';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { MetricsModalComponent } from './metrics-modal/metrics-modal.component';
import { UtilizationTotalsBoxComponent } from './utilization-totals-box/utilization-totals-box.component';
import { ServiceHealthTimelineComponent } from './service-health-timeline/service-health-timeline.component';
import { ServiceHealthPieComponent } from './service-health-pie/service-health-pie.component';

@NgModule({
    imports: [
        CommonModule,
        FeatureDateFiltersModule,
        MatButtonModule,
        MatCardModule,
        UiPipesModule,

        UiNoitemsModule,
        MatTooltipModule,
        MatDialogModule,
        SharedApiv2Module,
        MatExpansionModule,
        MatGridListModule,
        FeatureDateFiltersModule,
        FeatureZitiUtilizationBasicModule,
        FeatureHighchartsGenericModule,

        MatTabsModule,
        MatChipsModule,
        MatDatepickerModule,
        MatChipsModule,
        FeatureZitiModule,
        UiLoaderModule,
        CalendarModule,
        FormsModule,
    ],
    declarations: [
        MetricsModalComponent,
        UtilizationTotalsBoxComponent,
        ServiceHealthTimelineComponent,
        ServiceHealthPieComponent,
    ],
    exports: [MetricsModalComponent],
})
export class FeatureMetricsModalModule {}
