import { Injectable } from '@angular/core';
import { Endpoint, Network } from '@netfoundry-ui/shared/model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class ClientService extends MopResourceService {
    page = null;
    links = null;
    bySessionIdentityIdPage = null;
    bySessionIdentityIdLinks = null;
    private clientsSource = new Subject<Endpoint[]>();
    clients = this.clientsSource.asObservable();
    private apiSub = new Subscription();
    private mapSub = new Subscription();
    private clientsBySessionIdentityIdSource = new Subject<Endpoint[]>();
    clientsBySessionIdentityId = this.clientsBySessionIdentityIdSource.asObservable();
    private apiSubBySessionIdentityId = new Subscription();
    private mapSubBySessionIdentityId = new Subscription();

    constructor(protected logger: LoggerService, private apiservice: ApiService) {
        super(logger, apiservice);
    }

    /**
     * Gets all Clients for org
     */
    get(
        pageNumber?: number,
        sort?: string,
        filter?: string,
        pageSize?: number,
        endpointTypeString?: string
    ): Observable<any> {
        if (this.apiSub) {
            this.apiSub.unsubscribe();
        }

        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }
        // watch for when the network updates, when it does, update the gateways observable
        this.apiSub = this.apiService.currentNetwork.subscribe((network) => {
            if (endpointTypeString == null) {
                const endpointTypes = [];
                for (const endpoint of this.getClientTypes()) {
                    endpointTypes.push(endpoint.value);
                }

                endpointTypeString = endpointTypes.join(',');
            }

            const params = {};

            params['endpointType'] = endpointTypeString;

            if (pageNumber != null) {
                params['page'] = pageNumber;
            }

            if (sort != null) {
                params['sort'] = sort;
            }

            if (filter != null && filter !== '') {
                params['nameLike'] = '%' + filter + '%';
            }

            if (pageSize != null) {
                params['size'] = pageSize;
            }

            this.mapSub = this.apiService
                .getLinkedResource(new Network(network), 'endpoints', params)
                .pipe(map(this._extractEmbedded))
                .subscribe((data) => {
                    this.page = data['page'];
                    this.links = data['links'];
                    this.clientsSource.next(data['endpoints']);
                });
        });

        // this is an observable that watches for network changes
        return this.clients;
    }

    /**
     * Special case for getting clients by session identity ID
     * This is currently necessary to prevent the console from accidentially overriding the standard GET requeset
     * Without this method it is possible for that the request one of two situations was possible:
     *
     *    The request to get clients by session identity ID was coming back after the standard clients GET.
     *      This would override the regular GET response with a list of clients managed by the current user
     *
     *    The standard GET request was coming back after the get by session identity ID request
     *      this would override the get by session identity ID response, and display the NF Auth view for all users
     *
     * By breaking this out into a seperate method it allows us to seperate the observables that store the response, preventing
     *  these requests from stepping over eachother
     */
    getBySessionIdentityId(sessionIdentityId: string, pageNumber?: number, pageSize?: number): Observable<any> {
        if (this.apiSubBySessionIdentityId) {
            this.apiSubBySessionIdentityId.unsubscribe();
        }

        if (this.mapSubBySessionIdentityId) {
            this.mapSubBySessionIdentityId.unsubscribe();
        }

        const params = {
            sessionIdentityId: sessionIdentityId,
        };

        if (pageNumber != null) {
            params['page'] = pageNumber;
        }

        if (pageSize != null) {
            params['size'] = pageSize;
        }

        this.mapSubBySessionIdentityId = this.apiService
            .get('endpoints', params)
            .pipe(map(this._extractEmbeddedSessionEndpoints))
            .subscribe((data) => {
                this.bySessionIdentityIdPage = data['page'];
                this.bySessionIdentityIdLinks = data['links'];
                this.clientsBySessionIdentityIdSource.next(data['endpoints']);
            });

        return this.clientsBySessionIdentityId;
    }

    public share(endpoint: Endpoint, emailParams: any) {
        const selfLink = endpoint.getSelfLink();
        const path = `${selfLink}/share`;
        return this.apiService.post(path, emailParams);
    }

    public downloadRegistrationKey(endpoint: Endpoint) {
        const selfLink = endpoint.getSelfLink();
        const path = `${selfLink}/downloadRegistrationKey`;
        return this.apiService.getBlob(path);
    }

    public getClientTypes() {
        return [
            { value: 'CL', label: 'Client' },
            { value: 'ZTCL', label: 'Ziti Client' },
        ];
    }

    protected _extractEmbeddedSessionEndpoints(res) {
        const endpoints: Endpoint[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['sessionEndpoints'] !== undefined) {
            for (let i = 0; i < res['_embedded']['sessionEndpoints'].length; i++) {
                const client = res['_embedded']['sessionEndpoints'][i];
                endpoints.push(new Endpoint(client));
            }
        }

        // object containing information on pagination such as page number, size, total number of items,  and total number of pages
        let page = null;
        if (res['page'] !== undefined) {
            page = res['page'];
        }

        // list of links for jumping to and from pages
        let links = null;
        if (res['_links'] !== undefined) {
            links = res['_links'];
        }

        return { endpoints: endpoints, page: page, links: links };
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const endpoints: Endpoint[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['endpoints'] !== undefined) {
            for (let i = 0; i < res['_embedded']['endpoints'].length; i++) {
                const client = res['_embedded']['endpoints'][i];
                if (
                    client['endpointType'] !== undefined &&
                    (client['endpointType'] === 'CL' || client['endpointType'] === 'ZTCL')
                ) {
                    endpoints.push(new Endpoint(client));
                }
            }
        }

        // object containing information on pagination such as page number, size, total number of items,  and total number of pages
        let page = null;
        if (res['page'] !== undefined) {
            page = res['page'];
        }

        // list of links for jumping to and from pages
        let links = null;
        if (res['_links'] !== undefined) {
            links = res['_links'];
        }

        return { endpoints: endpoints, page: page, links: links };
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return this.getLinkedResourceUrl(this.currentNetworkModel, 'endpoints');
    }
}
