import { Component } from '@angular/core';
import { EdgeRouterV2, EndpointV2 } from '@netfoundry-ui/shared/model';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import _ from 'lodash';

@Component({
    selector: 'app-table-cell-registration',
    templateUrl: './table-cell-registration.component.html',
    styleUrls: ['./table-cell-registration.component.scss'],
})
export class TableCellRegistrationComponent implements ICellRendererAngularComp {
    params;
    item = {
        id: '',
        jwt: null,
        verified: false,
        zitiId: null,
        managedBy: ''
    };
    registered = false;
    systemManaged = false;
    routerManaged = false;

    agInit(params: ICellRendererParams): void {
        if (!params.node.data) {
            return;
        }
        this.item = params.node.data;
        this.registered = this._isRegistered();
        this.routerManaged = this._isRouterManaged();
    }

    refresh(params: ICellRendererParams): boolean {
        if (!params.data) {
            return;
        }
        this.item = params.data;
        this.registered = this._isRegistered();
        this.routerManaged = this._isRouterManaged()
        return true;
    }

    _isRouterManaged() {
        if (this.item.managedBy === 'Router') {
           return this.routerManaged = true;
        } else {
            return this.routerManaged = false;
        }
    }

    _isRegistered() {
        if (this.item instanceof EdgeRouterV2) {
            return this.item.verified;
        } else {
                return _.isEmpty(this.item.jwt) && !_.isEmpty(this.item.zitiId);
            }
    }
}
