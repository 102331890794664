import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-v7-card',
    templateUrl: './v7-card.component.html',
    styleUrls: ['./v7-card.component.scss'],
})
export class V7CardComponent implements OnInit {
    // @Input() image: any = {};
    @Input() title: string;
    @Input() subtitle: string;
    @Input() subtitleOne: string;
    @Input() subtitleTwo: string;
    @Input() note: string;
    @Output() addEvent: EventEmitter<boolean> = new EventEmitter();
    @Input() resource: string;
    @Input() image: string;
    @Input() comingSoon = false;
    @Input() disabled = false;

    iconStyle: any;

    ngOnInit() {
        this.iconStyle = {
            'background-image': 'url("' + this.image + '")',
        };
    }

    doAction() {
        this.addEvent.emit(true);
    }
}
