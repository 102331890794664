import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { NetworkV2 } from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, HTTP_CLIENT, LoggerService } from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import _ from 'lodash';
import { AutoFabricService } from './auto-fabric.service';

@Component({
    selector: 'app-auto-fabric',
    templateUrl: './auto-fabric.component.html',
    styleUrls: ['./auto-fabric.component.scss'],
})
export class AutoFabricComponent implements OnInit {
    public address: string;
    public errors = {};
    public processing = false;
    public hideHelp = false;

    currentNetwork: NetworkV2;
    autoFillOptions: string[];
    hideSelect: boolean;
    doAutoComplete: boolean;
    private confirmDialog;
    private isSelfServiceTeamsTier = false;

    public constructor(
        public logger: LoggerService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        public growlerService: GrowlerService,
        private dialogRef: MatDialogRef<AutoFabricComponent>,
        public dialogForm: MatDialog,
        private autoFabricService: AutoFabricService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        private apiService: ApiService,
        public featureService: FeatureService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data.network != null) {
            this.currentNetwork = data.network;
        }
    }

    ngOnInit() {
        if (this.currentNetwork == null) {
            this.apiService.currentNetwork.subscribe((network) => {
                this.currentNetwork = network as unknown as NetworkV2;
            });
        }

        this.apiService.currentSubscription.subscribe(async (subscription) => {
            this.isSelfServiceTeamsTier =
                this.featureService.isSelfServiceTeamsTier(subscription) ||
                this.featureService.isSelfServicePAYG(subscription);
        });
    }

    async submit() {
        if (this.validate()) {
            this.processing = true;

            await this.autoFabricService.findGeoLocationFromAddress(this.address).then(
                (position) => {
                    this.autoFabricService
                        .createFabricFromGeoLocation(this.currentNetwork, position.lat, position.lng)
                        .then(
                            (data) => {
                                this.growlerService.show(
                                    new GrowlerData('success', 'Success', `Hosted Edge Routers are being created.`)
                                );
                                _.delay(() => {
                                    this.hide();
                                }, 500);
                            },
                            () => {
                                this.processing = false;
                                this.growlerService.show(
                                    new GrowlerData(
                                        'error',
                                        `Auto Hosted Edge Routers Failed `,
                                        'Unable to process file upload.'
                                    )
                                );
                            }
                        );
                },
                () => {
                    this.errors['invalidAddress'] = true;
                    this.processing = false;
                }
            );

            if (this.errors['invalidAddress']) {
                this.processing = false;
                return;
            }
        }
    }

    validate() {
        let isValid = true;

        this.errors = {};
        if (this.address == null || this.address.trim().length === 0) {
            this.errors['noAddress'] = true;
            isValid = false;
        }
        return isValid;
    }

    hide() {
        this.dialogRef.close();
    }

    confirmLocation(location: string) {
        const data = {
            title: 'Confirm Your Location',
            appendId: 'GeoLocationConfirmation',
            subtitle: 'Is this the correct location?',
            action: 'Yes',
            cancelAction: 'No, let me make changes',
            confirmContent: location,
            icon: 'netfoundryHostedIcon',
            isAlert: false,
        };

        this.confirmDialog = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '340px',
            width: '600px',
            autoFocus: false,
        });
        return this.confirmDialog
            .beforeClosed()
            .toPromise()
            .then((result) => {
                // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                //  logged out of the console and we should close the dialog without continuing
                if (result === undefined) {
                    return false;
                } else if (result['loggingOut'] === undefined) {
                    return result;
                }
            });
    }

    applyFilter() {
        if (!this.doAutoComplete) {
            this.doAutoComplete = true;
        }
        setTimeout(() => {
            if (this.address !== '' && this.address != null) {
                if (this.doAutoComplete) {
                    this.hideSelect = false;
                    this.getAutoComplete(this.address).then((result) => {
                        this.autoFillOptions = [];
                        if (result && result['items']) {
                            for (const item of result['items'] as []) {
                                this.autoFillOptions.push(item['title']);
                            }
                        }
                    });
                    this.doAutoComplete = false;
                }
            }
        }, 250);
    }

    getAutoComplete(query: string) {
        return new Promise((resolve, reject) => {
            const path = `https://autocomplete.search.hereapi.com/v1/autocomplete?apiKey=HDZOlFo6BVQO4hQwORijiMTFtivQ7GerNY-7WazsINE&maxResults=5&q=${query}`;

            const request = new XMLHttpRequest();

            request.open('GET', path);
            request.send();

            request.onreadystatechange = function () {
                if (request.readyState === 4) {
                    if (request.status === 200) {
                        resolve(JSON.parse(request.response));
                    } else {
                        reject(request.response);
                    }
                }
            };
        });
    }

    selectOption(option: string) {
        this.address = option;
        this.hideSelect = true;
    }
}
