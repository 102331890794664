import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewContainerRef,
} from '@angular/core';

@Component({
    selector: 'app-preview-list',
    templateUrl: './preview-list.component.html',
    styleUrls: ['./preview-list.component.scss'],
})
export class PreviewListComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('beforecounterext', { read: ElementRef }) beforeCounterEl: ElementRef | undefined;
    @ViewChildren('nameList', { read: ElementRef }) viewChildren!: QueryList<any>;

    @Input() hideOption: string;
    @Input() public label = '';
    @Input() public clickable = false;
    @Input() isLoading = false;
    @Input() allNames = ['Testing 1', 'Testing 2', 'Testing 3', 'One Test', 'Two Test'];
    @Output() itemSelected = new EventEmitter<string>();
    @Output() displayPostProcess = new EventEmitter<any>();
    public names = [];
    filterFor = '';

    ngOnInit() {
        this.names = [];
        this.names.push(...this.allNames);
        this.names = this.names.filter((item) => item !== this.hideOption);
        this.sort();
    }

    ngOnChanges() {
        this.names = [];
        this.names.push(...this.allNames);
        this.names = this.names.filter((item) => item !== this.hideOption);
        this.sort();
    }

    onKeydownEvent() {
        this.names = [];
        for (let i = 0; i < this.allNames.length; i++) {
            if (this.allNames[i].indexOf(this.filterFor) >= 0) {
                this.names.push(this.allNames[i]);
            }
        }
        this.sort();
    }

    sort() {
        this.names.sort((item1, item2) => item1.localeCompare(item2));
    }

    selected(name: string) {
        if (this.clickable) this.itemSelected.emit(name);
    }

    ngAfterViewInit(): void {
        this.viewChanges();
        this.viewChildren.changes.subscribe(() => {
            this.viewChanges();
        });
    }

    private viewChanges() {
        const vcrs = {
            beforeCounterEl: this.beforeCounterEl,
            listViews: this.viewChildren,
        };

        this.displayPostProcess.emit(vcrs);
    }
}
