import { MopResource } from './mopresource';

export class AzureVirtualWan extends MopResource {
    public azureId: string;

    public _links: {
        self: {
            href: string;
        };
    };

    constructor(object) {
        super(object);
    }

    public getSaveObject() {
        return {};
    }

    public getAzureId() {
        return this.azureId;
    }
}
