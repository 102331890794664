<div class="fullModal opened" id="AwsSplashScreen">
    <div class="icon-AwsSplashScreen"></div>
    <div class="title" style="filter: brightness(50%)">Welcome</div>

    <div class="message">
        <div class="subtitle">
            Thanks for signing up for the NetFoundry Console via AWS. If you already have a NetFoundry Account, click
            below to sign in. If not, contact your organization admin to send you an invitation.
        </div>
    </div>

    <div class="buttonArea">
        <button (click)="hide()" class="save">Continue to Sign In</button>
    </div>

    <div (click)="hide()" class="buttonBall close" id="CloseClientButton">
        <div class="buttonLabel">ESC</div>
    </div>

    <div class="splashDots"></div>
</div>
