import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Endpoint, Service } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    FeatureService,
    GatewayService,
    ServiceService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

// eslint-disable-next-line no-useless-escape
const nameRegEx = RegExp('^[A-Za-z0-9-\\s.]{1,64}$');

const invalidString = 'Invalid';
const requiredString = 'Required';

const provisionedStatus = 300;
const registeredStatus = 400;

@Component({
    selector: 'app-l2-service-form',
    templateUrl: './l2-service-form.component.html',
    styleUrls: ['./l2-service-form.component.scss'],
})
export class L2ServiceFormComponent implements OnInit, OnDestroy, OnChanges {
    @Input() model: Service = new Service({
        bridgeStp: 'YES',
        collectionLocation: 'BOTH',
        cryptoLevel: 'STRONG',
        dnsOptions: 'NONE',
        icmpTunnel: 'YES',
        localNetworkGateway: 'YES',
        multicast: 'OFF',
        pbrType: 'WAN',
        permanentConnection: 'NO',
        rateSmoothing: 'NO',
        serviceInterceptType: 'IP',
        bridgeIntf: 'br0',
        vlanId: 0,
        transparency: 'NO',
        serviceType: 'TCP',
        lowLatency: 'NO',
        dataInterleaving: 'NO',
        portInterceptMode: 'INTERCEPT_ALL',
    });

    @Input() canEdit = true;
    @Input() gatewayId;
    @Input() gatewaySelfLink;
    @Input() isInline = false;

    @Output() back: EventEmitter<boolean> = new EventEmitter();
    @Output() hide: EventEmitter<any> = new EventEmitter();

    l2gGateways = [];
    isAdding = false;
    isEditing = false;
    isComplete = false;
    allowBack = true;

    // variables for the network/interecpt port ranges and the intercept IP
    // these are used so that changes can be made to the model while still preserving the user's input to the form
    interceptIp;
    processing = false;

    networkIpErrorString;
    interceptIpErrorString;
    nameErrorString;

    permanentConnectionEnabled = false;

    @Input() hideHelp = false;

    errors = {
        name: false,
        serviceClass: false,
        endpointId: false,
        gatewayClusterId: false,
        serviceType: false,
        interceptIp: false,
        networkIp: false,
        networkFirstPort: false,
        networkLastPort: false,
        interceptFirstPort: false,
        interceptLastPort: false,
        gatewayCidrBlock: false,
        Network: false,
        NetworkIntercept: false,
    };
    disableGatewaySelect = false;
    private subscription = new Subscription();

    constructor(
        private gatewayService: GatewayService,
        private apiService: ApiService,
        private service: ServiceService,
        private growlerService: GrowlerService,
        private validateService: ValidateService,
        public featureService: FeatureService,
        private authorizationService: AuthorizationService
    ) {
        this.isAdding = true;
        this.isEditing = false;
        this.allowBack = true;
        this.model = new Service({
            bridgeStp: 'YES',
            collectionLocation: 'BOTH',
            cryptoLevel: 'STRONG',
            dnsOptions: 'NONE',
            icmpTunnel: 'NO',
            localNetworkGateway: 'YES',
            multicast: 'OFF',
            pbrType: 'WAN',
            permanentConnection: 'NO',
            rateSmoothing: 'NO',
            serviceInterceptType: 'IP',
            gatewayClusterId: null,
            bridgeIntf: 'br0',
            vlanId: 11,
            transparency: 'NO',
            serviceType: 'TCP',
            lowLatency: 'NO',
            dataInterleaving: 'NO',
            portInterceptMode: 'INTERCEPT_ALL',
        });
        this.model.serviceClass = 'L2G';
    }

    ngOnChanges() {
        // if a gateway was provided
        if (this.gatewayId != null) {
            // disable gateway select
            this.disableGatewaySelect = true;
        }

        this.model = new Service(this.model);
        if (this.model.id != null) {
            this.isAdding = false;
            this.isEditing = true;
            this.allowBack = false;
            this.disableGatewaySelect = true;

            this.permanentConnectionEnabled = this.model.permanentConnection.toLowerCase() === 'yes';

            this.gatewayId = this.model.endpointId;

            // if the model has an intercept IP and the intercept IP is different than the network IP
            if (this.model.interceptIp && this.model.interceptIp !== this.model.networkIp) {
                // setting the value of interceptIp to the value of hte model's intercept IP
                this.interceptIp = this.model.interceptIp;
            }
        }
    }

    async ngOnInit() {
        this.processing = false;
        // check if isEditing is true
        if (this.isEditing) {
            if (this.authorizationService.canGetEndpoint(this.gatewayId)) {
                // otherwise, get the endpoint associated with the service
                this.subscription.add(
                    this.apiService.getLinkedResource(this.model, 'endpoint').subscribe((result) => {
                        const gateway = new Endpoint(result);

                        // creating an object to be stored in the gateways lists
                        const currEndpoint = {
                            id: gateway.id,
                            type: gateway.endpointType,
                            isHa: false,
                            name: gateway.name,
                        };

                        // adding the object to the gateways and non ziti gateways lists
                        this.l2gGateways.push(currEndpoint);
                    })
                );
            }
        } else if (this.gatewaySelfLink != null) {
            if (this.authorizationService.canGetEndpoint(this.gatewayId)) {
                this.subscription.add(
                    this.apiService.get(this.gatewaySelfLink).subscribe((result) => {
                        const gateway = new Endpoint(result);

                        // creating an object to be stored in the gateways lists
                        const currEndpoint = {
                            id: gateway.id,
                            type: gateway.endpointType,
                            isHa: false,
                            name: gateway.name,
                        };

                        // adding the object to the gateways and non ziti gateways lists
                        this.l2gGateways.push(currEndpoint);
                        this.model.endpointId = gateway.id;
                    })
                );
            } else {
                this.model.endpointId = this.gatewayId;
            }
        } else if (this.authorizationService.canListEndpoints()) {
            const allEndpointTypes = this.gatewayService.getGatewayTypes();
            const filterEndpointTypes = [];
            for (const endpointType of allEndpointTypes) {
                if (endpointType.value === 'L2VCPEGW') {
                    filterEndpointTypes.push(endpointType.value);
                }
            }
            const endpointTypesString = filterEndpointTypes.join(',');
            // if the form is not in an edit state, get all of the gateways and clusters
            const gatewayPromise = this.gatewayService
                .get(undefined, undefined, undefined, undefined, endpointTypesString)
                .pipe(take(1))
                .toPromise()
                .then((result) => {
                    // iterating over each gateway
                    for (const item of result) {
                        // creating a gateway object
                        const gateway = new Endpoint(item);

                        // creating an object to represent the endpoint in the list of gateways
                        const currEndpoint = {
                            id: gateway.id,
                            type: gateway.endpointType,
                            isHa: false,
                            name: gateway.name,
                        };

                        // TODO: update the service form and gateways dashboard to send in the whole gateway object in order to only grab the specific gateway
                        // if the endpointId object is set (the form was opened from a dashboard with a gateway provided) and the current endpoint's ID matches
                        if (currEndpoint.id === this.gatewayId) {
                            this.model.endpointId = currEndpoint.id;
                        }

                        // if the gateway's status is less than 700 and not 500 and it does not belong to a closter
                        if (
                            (gateway.status === provisionedStatus || gateway.status === registeredStatus) &&
                            gateway['endpointProtectionRole'] == null
                        ) {
                            // push the current endpoint object to the list of non ziti gateways
                            this.l2gGateways.push(currEndpoint);
                        }
                    }
                });

            const proms = [gatewayPromise];

            await Promise.all(proms);
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    clearErrors() {
        for (const error of Object.keys(this.errors)) {
            this.errors[error] = false;
        }

        this.interceptIpErrorString = '';
        this.nameErrorString = '';
        this.networkIpErrorString = '';
    }

    save() {
        // clearing any errors
        this.clearErrors();
        if (this.validate()) {
            this.processing = true;
            this.model.bridgeStp = 'YES';
            this.model.bridgeIntf = 'br0';
            this.model.collectionLocation = 'BOTH';
            this.model.cryptoLevel = 'STRONG';
            this.model.dnsOptions = 'NONE';
            this.model.localNetworkGateway = 'YES';
            this.model.multicast = 'OFF';
            this.model.pbrType = 'WAN';
            this.model.rateSmoothing = 'NO';
            this.model.serviceInterceptType = 'IP';

            this.model.permanentConnection = this.permanentConnectionEnabled ? 'YES' : 'NO';

            this.model.serviceType = 'TCP';

            this.subscription.add(
                this.service.save(this.model).subscribe(
                    (result) => {
                        if (this.isAdding) {
                            this.model = new Service(result);
                        }
                        // TODO: Check results for api errors
                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Creation Complete',
                                'Service information has been saved.'
                            )
                        );
                        this.isComplete = true;
                        this.processing = false;
                        this.hideForm({ updatedService: this.model });
                    },
                    (error) => {
                        this.processing = false;
                    }
                )
            );
        }
    }

    validate(): boolean {
        if (!this.validateService.hasValue(this.model.name)) {
            this.nameErrorString = requiredString;
        } else {
            this.nameErrorString = invalidString;
        }

        this.errors['name'] = !this.validateService.isValidName(this.model.name);
        this.errors['endpointId'] = !this.validateService.hasValue(this.model.endpointId);

        for (const error of Object.keys(this.errors)) {
            if (this.errors[error] === true) {
                return false;
            }
        }
        return true;
    }

    togglePermanentConnection() {
        this.permanentConnectionEnabled = !this.permanentConnectionEnabled;
    }

    goBack() {
        this.back.emit(true);
    }

    hideForm(response?: unknown) {
        this.hide.emit(response);
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.hideForm(new Service(this.model));
    }
}
