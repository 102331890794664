<div *ngIf="businessKey != null">
    <div class="title" id="NetworkBuilding" style="filter: brightness(50%)">
        {{ networkName != null ? networkName : 'Your Network' }} Is Building
    </div>
    <div class="subtitle smaller">
        {{ networkName != null ? networkName : 'Your Network' }} is being created<br />Details are below.
    </div>
    <div class="row center" id="NetworkBulkSaveCurrentTask">
        <div [ngClass]="stepStyle" class="building network"></div>
        <div [ngClass]="{ open: stepStyle.includes('step') }" class="buildSteps" id="currentStatus">
            {{ message }}
        </div>
    </div>
    <div class="content" id="NetworkBulkSaveAllTasks">
        <div *ngIf="networkStatus.length > 0">
            <div *ngFor="let status of networkStatus; let index = index" class="statusRow">
                <div class="bubble severityInfo"></div>
                <div class="statusText" id="networkStatus_task_{{ index }}">
                    {{ status['task'] }}
                </div>
                <div class="statusText second" id="networkStatus_status_{{ index }}">
                    {{ status['status'] }}
                </div>
            </div>
        </div>
        <div
            *ngIf="
                networkSubProcessStatus.length > 0 &&
                subProcessOverallStatus !== null &&
                !subProcessOverallStatus.includes('Completed')
            "
        >
            <div *ngFor="let status of networkSubProcessStatus; let index = index" class="statusRow">
                <div class="bubble severityInfo"></div>
                <div class="statusText" id="networkSubProcessStatus_task_{{ index }}">
                    {{ status['task'] }}
                </div>
                <div class="statusText second" id="networkSubProcessStatus_status_{{ index }}">
                    {{ status['status'] }}
                </div>
            </div>
        </div>
    </div>
</div>
