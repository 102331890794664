<div class="card-container">
    <img [src]="item.icon" [style]="item.iconStyle" class="tunneler-icon" />
    <div class="tunneler-details">
        <h5 class="card-title">{{ item.title }}</h5>
        <p [innerHtml]="item.helpText" class="help-text"></p>
        <a
            (click)="linkClicked()"
            *ngIf="!item.useButton"
            [href]="item.link"
            [target]="item.target ? item.target : '_blank'"
            class="tunneler-link btn btn--sm btn--primary type--uppercase"
        >
            <span class="btn__text">{{ item.buttonText }}</span>
        </a>
        <button (click)="linkClicked()" *ngIf="item.useButton" class="tunneler-button" id="ConfirmAction{{ item.os }}">
            {{ item.buttonText }}
        </button>
    </div>
</div>
