import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lookup',
})
export class LookupPipe implements PipeTransform {
    transform(key: any, lookup: any, fallback: string): string {
        if (lookup[key] !== undefined) {
            return lookup[key];
        }

        // default to original value
        return fallback;
    }
}
