<div class="modal solid open">
    <div (click)="close()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="title">Toggle Port Configuration</div>
    <div class="icon upgradenetwork"></div>

    <div class="row">
        <div style="padding-top: 60px">
            <p-message
                severity="warn"
                text="Your network will be offline for a few minutes while the config port process is modified. Press 'Save' when you are ready to proceed."
            ></p-message>
        </div>
    </div>

    <div class="row">
        <div>
            <div class="label row">
                ENABLE PORT CONFIGURATION
                <div class="infoicon" matTooltip="" matTooltipPosition="below"></div>
                <span class="note">OPTIONAL</span>
            </div>
            <div class="row">
                <div class="certificatepod">
                    <span class="certificatetext">PORT CONFIGURATION</span>
                    <div
                        (click)="togglePortConfiguration()"
                        [ngClass]="{
                      on: sdsConfigPortEnabled,
                  }"
                        class="toggle"
                        style="float: right; margin-top: 15px; margin-right: 12px"
                    >
                        <span [hidden]="!sdsConfigPortEnabled" style="margin-left: -15px">YES</span>
                        <span [hidden]="sdsConfigPortEnabled" style="margin-right: -15px">NO</span>
                        <div class="switch"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="buttons">
        <div (click)="close()" class="linkButton" id="MiniCloseButton">Oops, no get me out of here</div>
        <button (click)="savePortConfiguration()" class="save" id="CreateButton">Save</button>
    </div>
</div>
