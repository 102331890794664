import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableHeaderDefaultComponent } from '@netfoundry-ui/feature/data-table';
import { CsvDownloadService } from '@netfoundry-ui/feature/shared-services';
import { ZitiResourceHelper } from '@netfoundry-ui/feature/ziti-resource-helper';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { ApiService } from '@netfoundry-ui/shared/services';
import * as ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-idle_fingers';
import * as yaml from 'js-yaml';
import _ from 'lodash';
import { FileUploader } from 'ng2-file-upload';
import { Router } from '@angular/router';

@Component({
    selector: 'app-upload-entities',
    templateUrl: './upload-entities.component.html',
    styleUrls: ['./upload-entities.component.scss'],
})
export class UploadEntitiesComponent implements OnInit, OnChanges {
    @Input() resourceData;
    @Output() doneEditing = new EventEmitter();

    // create an empty uploader until we have an actual URL
    networkUpload = false;
    uploader: FileUploader;
    fileText = '';
    fileObj: any = {};
    allRows;
    rowValidation = [];
    fileType = 'json';
    fileIsReady = false;
    isEditing = false;
    showTable = false;
    tableRefresh = false;
    fullScreen = false;
    form: FormGroup;
    fileInput: ElementRef;
    selectedFile;
    importing = false;

    gridObj = {};
    columnDefs = [
        {
            colId: 'name',
            minWidth: 100,
            field: 'name',
            tooltipField: 'name',
            headerName: 'Name',
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
    ];
    invalid = false;
    fileEmpty = false;
    importError = false;
    showInvalid = false;
    hideHelp = false;
    currentNetwork;
    resourceType = '';
    resourceLabel = '';
    buttonAction = 'add';
    actionButtonClass = 'buttonAdd';
    @ViewChild('editor') private editor: ElementRef<HTMLElement>;

    constructor(
        private csvDownloadService: CsvDownloadService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private growlerService: GrowlerService,
        private dialogRef: MatDialogRef<UploadEntitiesComponent>,
        public apiService: ApiService,
        private formBuilder: FormBuilder,
        private zitiResourceHelper: ZitiResourceHelper,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router
    ) {
        // initialize the uploader for the drag and drop
        this.uploader = new FileUploader({ url: '' });
        this.form = this.formBuilder.group({
            networkId: '',
            file: null,
        });
        this.initComponent();
    }

    @ViewChild('fileInput', { static: false }) set content(content: ElementRef) {
        if (content) {
            // initially setter gets called with undefined
            this.fileInput = content;
        }
    }

    ngOnInit(): void {
        this.apiService.currentNetwork.subscribe((network) => {
            this.currentNetwork = network;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.resourceData) {
            this.data = changes.resourceData.currentValue;
            this.fileObj = this.data.resources;
            this.networkUpload = true;
            this.isEditing = true;
            this.showTable = true;
            this.initComponent();
            this.validate(this.fileObj, true);
            this.fileIsReady = true;
        }
    }

    initComponent(): void {
        if (!this.data) {
            return;
        }
        this.setResourceLabel();
        this.columnDefs = this.zitiResourceHelper.getTableColumnDefinitions(this.data.resourceType);
    }

    setResourceLabel() {
        if (!this.data) {
            return;
        }
        switch (this.data.resourceType) {
            case 'ziti-app-wan':
                this.resourceLabel = 'AppWANs';
                break;
            case 'ziti-service':
                this.resourceLabel = 'Services';
                break;
            case 'ziti-endpoint':
                this.resourceLabel = 'Endpoints';
                break;
            case 'edge-router':
                this.resourceLabel = 'Edge Routers';
                break;
            case 'edge-router-policy':
                this.resourceLabel = 'Edge Router Policies';
                break;
            case 'posture-check':
                this.resourceLabel = 'Posture Checks';
                break;
        }
    }

    showDialog() {
        const event = new MouseEvent('click', { bubbles: true });
        this.fileInput.nativeElement.dispatchEvent(event);
    }

    fileDrop(event) {
        if (this.uploader.queue.length > 0) {
            const rawFile = _.get(this.uploader, 'queue[0].file.rawFile');
            if (rawFile) {
                _.set(event, 'target.files', [rawFile]);
                this.onFileChange(event);
            } else {
                this.importError = true;
                this.invalid = true;
            }
        } else {
            this.importError = true;
            this.invalid = true;
        }
    }

    onFileChange(event) {
        this.fileObj = {};
        this.invalid = false;
        this.importError = false;
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.form.get('file').setValue(file);
            this.fileIsReady = true;
            this.selectedFile = this.form.get('file').value;

            const fileNameComponents = this.selectedFile.name.split('.');
            this.fileType = fileNameComponents[fileNameComponents.length - 1];

            try {
                this.selectedFile.text().then((result) => {
                    try {
                        let fileObj;
                        if (this.fileType === 'yml') {
                            fileObj = yaml.load(result);
                        } else {
                            fileObj = JSON.parse(result);
                        }
                        this.validate(fileObj, true);
                    } catch (err) {
                        this.importError = true;
                        this.invalid = true;
                        this.importing = false;
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                `${this.resourceLabel} Import Failed `,
                                'Unable to process file upload.'
                            )
                        );
                    }
                });
            } catch (err) {
                this.importError = true;
                this.invalid = true;
                this.importing = false;
                this.growlerService.show(
                    new GrowlerData('error', `${this.resourceLabel} Import Failed `, 'Unable to process file upload.')
                );
            }
        }
    }

    validate(items, updateIndex = false) {
        this.fileEmpty = false;
        this.invalid = false;
        if (!items || items.length <= 0) {
            this.invalid = true;
            this.fileEmpty = true;
            return;
        }
        this.fileObj = [];
        let index = 0;
        this.rowValidation = [];
        _.forEach(items, (item) => {
            const { isValid, errors } = this.zitiResourceHelper.validateResource(item, this.data.resourceType);
            item.invalid = !isValid;
            if (updateIndex) {
                item.itemIndex = updateIndex ? index : item.itemIndex;
            }
            if (!isValid) {
                this.rowValidation[index] = { isValid, errors };
                this.invalid = true;
            }
            index++;
        });
        this.fileObj = items;
        this.fileText = JSON.stringify(this.fileObj, undefined, 2);
    }

    rowsToggled() {
        const selected = _.some(this.fileObj, (row) => row.selected);
        this.buttonAction = selected ? 'remove' : 'add';
        this.actionButtonClass = selected ? 'minus buttonAdd' : 'buttonAdd';
    }

    actionButton() {
        const removedRows = [];
        switch (this.buttonAction) {
            case 'add':
                // add row
                this.fileObj.push({ itemIndex: this.fileObj.length });
                this.fileObj = _.cloneDeep(this.fileObj);
                _.set(this.gridObj, 'api.nfRowData', this.fileObj);
                break;
            case 'remove':
                this.fileObj = _.filter(this.fileObj, (row) => {
                    if (row.selected) {
                        removedRows.push(row);
                    }
                    return !row.selected;
                });
                if (this.allRows) {
                    this.allRows = _.filter(this.allRows, (row) => {
                        const rowRemoved = _.some(removedRows, (removedRow) => row.itemIndex === removedRow.itemIndex);
                        return !rowRemoved;
                    });
                }
                this.refreshTable();
                break;
            default:
                break;
        }
        this.validate(this.fileObj);
        this.rowsToggled();
    }

    toggleFilter() {
        this.showInvalid = !this.showInvalid;
        if (this.showInvalid) {
            this.allRows = _.cloneDeep(this.fileObj);
            this.fileObj = _.filter(this.allRows, (row) => row.invalid);
        } else {
            this._applyAllData();
        }
        this.validate(this.fileObj);
        this.refreshTable();
    }

    refreshTable() {
        const newData = _.cloneDeep(this.fileObj);
        this.fileObj = [];
        this.tableRefresh = true;
        _.defer(() => {
            this.fileObj = newData;
            this.tableRefresh = false;
        });
    }

    copyData() {
        const selectedRows = _.cloneDeep(_.filter(this.fileObj, (row) => row.selected));
        let index = this.fileObj.length;
        _.forEach(selectedRows, (row) => {
            row.copied = true;
            row.name = row.name + '_copy';
            row.itemIndex = index;
            index++;
        });
        const newData = [...this.fileObj, ...selectedRows];
        _.forEach(newData, (row) => {
            row.selected = false;
        });
        this.fileObj = newData;
        this.rowsToggled();
        this.validate(this.fileObj);
        this.refreshTable();
    }

    _applyAllData() {
        if (this.allRows) {
            this.allRows = _.map(this.allRows, (row) => {
                _.forEach(this.fileObj, (filteredRow) => {
                    if (row.itemIndex === filteredRow.itemIndex) {
                        row = filteredRow;
                    }
                });
                return row;
            });
            this.fileObj = this.allRows;
        }
    }

    toggleEditor() {
        if (this.networkUpload) {
            const resourceData = { resources: this.fileObj, resourceType: this.data.resourceType };
            this.doneEditing.emit(resourceData);
            return;
        }
        if (this.isEditing && this.showTable && this.showInvalid) {
            this._applyAllData();
        }
        this.isEditing = !this.isEditing;
        this.showTable = !this.showTable;
        if (this.isEditing && !this.showTable) {
            this.updateEditor();
        }
    }

    toggleTable() {
        this.showTable = !this.showTable;
        if (!this.showTable) {
            this.updateEditor();
        }
    }

    updateEditor() {
        _.defer(() => {
            ace.config.set('fontSize', '14px');
            const aceEditor = ace.edit(this.editor.nativeElement);
            aceEditor.getSession().setMode(`ace/mode/${this.fileType}`);
            aceEditor.setTheme('ace/theme/idle_fingers');
            aceEditor.session.setValue(this.fileText);
            aceEditor.resize();
        });
    }

    uploadResources() {
        try {
            if (this.invalid || !this.fileObj) {
                return;
            }
            const resourceForm = this._getResourceForm(this.fileObj);
            this.importing = true;
            this.networkServiceV2.createResources(this.currentNetwork.id, resourceForm, 'application/json').subscribe(
                () => {
                    this.importing = false;
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            `${this.resourceLabel} Imported Successfully.`,
                            '<a href="' +
                                this.router.createUrlTree(['/process-executions']).toString() +
                                '">Click here to find out more</a>'
                        )
                    );
                    _.delay(() => {
                        this.hide();
                    }, 500);
                },
                () => {
                    this.importing = false;
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            `${this.resourceLabel} Import Failed `,
                            'Unable to process file upload.'
                        )
                    );
                }
            );
        } catch (err) {
            this.importing = false;
            this.growlerService.show(
                new GrowlerData('error', `${this.resourceLabel} Import Failed `, 'Unable to process file upload.')
            );
        }
    }

    onGridReady(event) {
        this.gridObj = event;
    }

    _getResourceForm(fileObj) {
        const resourceData: any = {
            networkId: this.currentNetwork.id,
            name: this.currentNetwork.name,
        };
        switch (this.data.resourceType) {
            case 'ziti-app-wan':
                resourceData.appWans = fileObj;
                break;
            case 'ziti-service':
                resourceData.services = fileObj;
                break;
            case 'ziti-endpoint':
                resourceData.endpoints = fileObj;
                break;
            case 'edge-router':
                resourceData.edgeRouters = fileObj;
                break;
            case 'edge-router-policy':
                resourceData.edgeRouterPolicies = fileObj;
                break;
            case 'posture-check':
                resourceData.postureChecks = fileObj;
                break;
        }
        return resourceData;
    }

    toggleModalSize() {
        this.fullScreen = !this.fullScreen;
    }

    hide() {
        this.dialogRef.close();
    }
}
