import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('browzer-apps')
export class BrowzerApp extends Resource {
    id: string;
    name: string;
    selected: boolean;
    tunnelHttps: boolean;
    auth: any;
    access: any;
    serviceId: string;
    networkId: string;
    path: string;
}
