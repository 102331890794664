<app-loading-indicator
    *ngIf="isLoading"
    [isLoading]="isLoading"
    class="load-indicator"
    id="zitiEndpointsPage"
></app-loading-indicator>

<div class="area data-table" id="ItemsArea">
    <app-data-table
        (gridReady)="onGridReady($event)"
        *ngIf="items.length >= 0 || filterHasChanged"
        [columnDefinitions]="columnDefs"
        [columnFilters]="columnFilters"
        [downloadAllEvents]="downloadAllEvents.bind(this)"
        [downloadFilteredEvents]="downloadFilteredEvents.bind(this)"
        [filterChanged]="filterChanged.bind(this)"
        [isTimeSearchAvailable]="true"
        [isNotDialLogs]="true"
        [isUTC]="isUTC"
        [newEndTime]="newEndTime"
        [newStartTime]="newStartTime"
        [rowData]="items"
        [hideSelectColumn]="true"
        [tableId]="'management-events'"
        pagination="true"
        paginationPageSize="30"
    >
    </app-data-table>
</div>
