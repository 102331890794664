import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GatewayFormsService } from '@netfoundry-ui/shared/gateway';
import {
    AzureResourceGroup,
    AzureSubscription,
    AzureVirtualWan,
    AzureVirtualWanSite,
    Endpoint,
    Network,
    NetworkGroup,
} from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, ZitiEnabledService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-gatewayform',
    templateUrl: './gatewayform.component.html',
    styleUrls: ['./gatewayform.component.scss'],
    providers: [GatewayFormsService],
})
export class GatewayformComponent implements OnInit {
    selectedType = '';
    isSelected = false;
    isInline = false;

    azureResourceGroups: AzureResourceGroup[] = [];
    azureVirtualWans: AzureVirtualWan[] = [];
    currentAzureSubscription = new AzureSubscription({});
    currentAzureSubscriptionId = null;
    currentOrgId;
    currentNetworkId: Network;
    canListAzureSubscriptions;
    canCreateAzureVirtualWanSites;
    canListDataCenters = true;
    canListGeoRegions = true;
    canCreateHaGateways = true;
    showBreakoutFields = false;
    isAvwSitePicker = false;
    avwSiteModel = new AzureVirtualWanSite({});
    gatewayModel = new Endpoint({});
    hideHelp = false;
    newGateway: Endpoint;
    hideHaUpgrade = false;
    networkGroup = new NetworkGroup({});
    private subscription = new Subscription();

    constructor(
        private dialogRef: MatDialogRef<GatewayformComponent>,
        public featureService: FeatureService,
        public zitiEnabledService: ZitiEnabledService,
        public gatewayFormService: GatewayFormsService,
        private apiService: ApiService,
        public authorizationService: AuthorizationService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.currentOrgId = this.apiService.theOrgIs.getId();
        this.currentNetworkId = this.apiService.theNetworkIs.getId();
        this.isAvwSitePicker = data.isAvwSitePicker;
        if (data.inline) {
            this.isInline = data.inline;
        }
        if (data.avwSiteModel) {
            this.avwSiteModel = new AzureVirtualWanSite(data.avwSiteModel);
            this.gatewayModel = new Endpoint(data.gatewayModel);
            this.setType('AVWGW');
        }
        if (data.hideHaUpgrade != null) {
            this.hideHaUpgrade = data.hideHaUpgrade;
        }
    }

    ngOnInit() {
        this.networkGroup = this.apiService.theOrgIs;

        this.showBreakoutFields =
            this.apiService.theNetworkIs.o365BreakoutCategory !== '' &&
            this.apiService.theNetworkIs.o365BreakoutCategory !== 'NONE';
        this.canListDataCenters = this.authorizationService.canListDataCenters();
        this.canListGeoRegions = this.authorizationService.canListGeoRegions();

        // azure subscription
        if (this.isAvwSitePicker && this.authorizationService.canListAzureSubscriptions()) {
            this.currentAzureSubscription = this.apiService.theAzureSubscriptionIs;
            this.currentAzureSubscriptionId = this.currentAzureSubscription.getId();
        }

        this.canListAzureSubscriptions = this.authorizationService.canListAzureSubscriptions();
        this.canCreateAzureVirtualWanSites = this.authorizationService.canCreateAzureVirtualWanSites();
        this.canCreateHaGateways = this.authorizationService.canCreateGatewayClusters();
    }

    hide() {
        this.dialogRef.close({ newGateway: this.newGateway });
    }

    setType(type: string) {
        this.selectedType = type;
        this.isSelected = true;
    }

    back() {
        this.selectedType = '';
        this.isSelected = false;
    }

    setNewGateway(newGateway) {
        this.newGateway = newGateway;
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.hide();
    }
}
