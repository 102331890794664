import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    BillingEnabledService,
    BillingService,
    CsvDownloadService,
    TableFilterService,
} from '@netfoundry-ui/feature/shared-services';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService, IamService } from '@netfoundry-ui/shared/authorization';
import {
    ElasticsearchService,
    EventsTimelineNetworksQuery,
    EventsTimelineQuery,
} from '@netfoundry-ui/shared/elasticsearch';
import { Network, NetworkV2 } from '@netfoundry-ui/shared/model';
import { ApiService, ErrorHistoryService, LoggerService, NetworkService } from '@netfoundry-ui/shared/services';
import { LookupPipe } from '@netfoundry-ui/ui/pipes';
import _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { TableHeaderDefaultComponent } from '@netfoundry-ui/feature/data-table';
import { EntityEventTemplateQueryParameter } from '@netfoundry-ui/shared/model';

const resourceTypeString = 'Resource Type';

const eventTypeString = 'Event Type';

const networkTitleString = 'Network';

@Component({
    selector: 'app-event-timeline-search',
    templateUrl: './event-timeline-search.component.html',
    styleUrls: ['./event-timeline-search.component.scss'],
    providers: [LookupPipe],
})
export class EventTimelineSearchComponent implements OnInit, OnDestroy {
    items = [];

    allItems = [];
    itemCount = 0;
    showList = false;
    isLoading = false;

    _endTime: number = Date.now();
    _startTime: number;

    query: EventsTimelineQuery;
    networksQuery: EventsTimelineNetworksQuery;
    errorMessage: string;
    _eventTypeFilter: string | boolean = false;
    _resourceTypeFilter: string | boolean = false;
    _starFilter: string | boolean = false;
    sorting = '@timestamp';
    ordering = 'desc';
    filterString = '';
    gridApi;
    @Output() networkFilters = new EventEmitter<string[]>();
    columns = [
        'eventType',
        'eventSeverity',
        'resourceType',
        'user',
        'eventDescription',
        'componentName',
        '@timestamp',
        'traceId',
    ];

    filename = 'Management Event Logs';

    pageSize = 100;

    paramList = {
        size: this.pageSize,
        sort: '@timestamp,asc',
    };

    @Input() dateFilter: any = '24h';
    @Input() endTime = Date.now();
    @Input() newStartTime;
    @Input() newEndTime;

    eventTypes = ['New', 'Building', 'Error', 'Changed', 'Status'];
    resourceTypes = [
        { name: 'AppWan', value: 'AppWan' },
        { name: 'Client', value: 'Client' },
        { name: 'Edge Router', value: 'Edge Router' },
        { name: 'Edge Router Policy', value: 'Edge Router Policy' },
        { name: 'Service Edge Router Policy', value: 'Service Edge Router Policy' },
        { name: 'Endpoint', value: 'Endpoint' },
        { name: 'Endpoint Group', value: 'EndpointGroup' },
        { name: 'Gateway', value: 'Gateway' },
        { name: 'Host', value: 'Host' },
        { name: 'Network', value: 'Network' },
        { name: 'Network Controller', value: 'NetworkController' },
        { name: 'Posture Check', value: 'Posture Check' },
        { name: 'Service', value: 'Service' },
        { name: 'Service Group', value: 'ServiceGroup' },
        { name: 'Service Connection', value: 'ServiceConnection' },
        { name: 'Transfer Node', value: 'TransferNode' },
        { name: 'Transfer Node Pool', value: 'TransferNodePool' },
    ];

    selectedResourceType = resourceTypeString;
    selectedEventType = eventTypeString;
    networkTitle = networkTitleString;
    filteringBy = '';
    public lookUp = {};
    public networkLookup = {};
    public networkFilterList = [];
    displayedItems = [];

    public managementEventData: any[] = [];
    page = 1;
    showNoData = false;
    currentTenantId;
    currentNetwork;
    @Input()
    showAllNetworks = false;
    currentOrgId = null;
    columnDefs;
    columnFilters: any = {
        timeStamp: '',
        resourceType: '',
        eventType: '',
    };
    isUTC;
    filterApplied = false;
    filterHasChanged = false;
    gridObj;
    noData = true;
    @Input() isTimeSearchAvailable = false;
    @Input() isNotDialLogs = true;
    private currentNetworkSub: Subscription = new Subscription();
    private elasticSearchSub: Subscription = null;
    private elasticSearchNetworksSub: Subscription = null;
    private noNetwork = false;
    private networksV6 = [];
    private networksV7 = [];
    private subscription: Subscription = new Subscription();
    private currentNetworkGroup;
    private initialized = false;

    constructor(
        private logger: LoggerService,
        private apiService: ApiService,
        private elasticsearch: ElasticsearchService,
        private iamService: IamService,
        private lookupPipe: LookupPipe,
        private networkService: NetworkService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private authorizationService: AuthorizationService,
        public filterService: TableFilterService,
        private errorHistoryService: ErrorHistoryService,
        private billingEnabledService: BillingEnabledService,
        private billingService: BillingService,
        private csvDownloadServiceService: CsvDownloadService
    ) {
        this.initTableColumns();

        this.newStartTime = moment().subtract(24, 'h');
        this.newEndTime = moment();
    }

    _severityFilter: string | boolean = false;

    get severityFilter() {
        return this._severityFilter;
    }

    // severity
    set severityFilter(filter) {
        this._severityFilter = filter || false;
        if (!this.noNetwork) {
            this.getTimelineData(this.currentNetwork.id);
        }
    }

    _networkFilter: string | boolean = false;

    get networkFilter() {
        return this._networkFilter;
    }

    // resourceType
    @Input()
    set networkFilter(filter) {
        this._networkFilter = filter || false;
        if (!this.noNetwork) {
            this.getTimelineData(this.currentNetwork.id);
        }
    }

    initTableColumns() {
        const columnFilters = this.columnFilters;
        const isTimeSearchAvailable = this.isTimeSearchAvailable;
        const headerComponentParams = {
            filterType: 'TEXTINPUT',
            columnFilters,
            isTimeSearchAvailable,
        };
        const time = (row) => {
            const item = row.data;
            return this.formatTime(item['@timestamp']);
        };
        const resourceTypeHeaderParams = {
            filterType: 'SELECT',
            filterOptions: [
                { label: 'ALL', value: '' },
                { label: 'AppWan', value: 'AppWan' },
                { label: 'Endpoint', value: 'Endpoint' },
                { label: 'Edge Router Policy', value: 'Edge Router Policy' },
                { label: 'Service', value: 'Service' },
                { label: 'Edge Router', value: 'Edge Router' },
                { label: 'Network', value: 'Network' },
                { label: 'Config', value: 'Config' },
                { label: 'Config Type', value: 'Config Type' },
                { label: 'Posture Check', value: 'Posture Check' },
                { label: 'Service Edge Router Policy', value: 'Service Edge Router Policy' },
            ],
            columnFilters,
        };

        const eventTypeHeaderParams = {
            filterType: 'SELECT',
            filterOptions: [
                { label: 'ALL', value: '' },
                { label: 'Deleted', value: 'Deleted' },
                { label: 'Provisioned', value: 'Provisioned' },
                { label: 'Changed', value: 'Changed' },
                { label: 'Log', value: 'log' },
                { label: 'Error', value: 'Error' },
            ],
            columnFilters,
        };

        this.columnDefs = [
            {
                colId: 'eventType',
                minWidth: 100,
                field: 'eventType',
                tooltipField: 'event_type',
                headerName: 'Event Type',
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
                headerComponentParams: eventTypeHeaderParams,
            },
            {
                colId: 'eventDescription',
                minWidth: 100,
                field: 'eventDescription',
                tooltipField: 'eventDescription',
                headerName: 'Event Description',
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'resourceType',
                minWidth: 100,
                field: 'resourceType',
                tooltipField: 'resourceType',
                headerName: 'Resource Type',
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
                headerComponentParams: resourceTypeHeaderParams,
            },
            {
                colId: 'user',
                minWidth: 100,
                field: 'user',
                tooltipField: 'user',
                headerName: 'User',
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: false,
            },
            {
                colId: '@timestamp',
                minWidth: 100,
                valueFormatter: time,
                tooltipField: '@timestamp',
                sortColumn: this.sort.bind(this),
                headerName: 'Time',
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'name',
                minWidth: 100,
                field: 'componentName',
                tooltipField: 'name',
                headerName: 'Name',
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'traceId',
                minWidth: 100,
                field: 'traceId',
                tooltipField: 'traceId',
                headerName: 'Trace Id',
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
            },
        ];
    }

    async ngOnInit() {
        const proms = [];

        this.apiService.currentNetworkGroup.subscribe((networkGroup) => {
            this.currentNetworkGroup = networkGroup;
        });

        if (this.billingEnabledService.billingEnabled) {
            if (!_.isNil(_.get(this.currentNetworkGroup, 'billingAccountId'))) {
                const currentTenantProm = await this.billingService
                    .getBillingAccount(this.currentNetworkGroup.billingAccountId)
                    .pipe(take(1))
                    .toPromise()
                    .then((billingAccount) => {
                        this.currentTenantId = billingAccount['attributionId'];
                    });
                proms.push(currentTenantProm);
            }
        } else {
            if (this.authorizationService.canListTenants() && this.authorizationService.canListUserIdentities()) {
                const currentTenantProm = await this.apiService.currentTenant
                    .pipe(take(1))
                    .toPromise()
                    .then((tenant) => {
                        this.currentTenantId = tenant.id;
                    });
                proms.push(currentTenantProm);
            }
        }

        const currentNetworkProm = await this.apiService.currentNetwork
            .pipe(take(1))
            .toPromise()
            .then((network) => {
                this.currentNetwork = network;
            });
        proms.push(currentNetworkProm);

        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrgId = org.getId();
            })
        );
        proms.push(currentNetworkProm);

        proms.push(this._loadV7networks());
        proms.push(this._loadV6networks());

        // combine the list
        let combined = [];
        combined = combined.concat(this.networksV6);
        combined = combined.concat(this.networksV7);

        Promise.all(proms).then(() => {
            // build lookup for network filter based on combined network lsits
            for (const network of combined) {
                if (this.networkLookup[network.id] === undefined) {
                    this.networkLookup[network.id] = network.name;
                    this.networkFilterList.push({ name: network.name, id: network.id });
                }
            }

            this.initEventTimelineSearch();
        });

        this.filterService.setPageEvent.subscribe((pageNum) => {
            this.gridObj.api.paginationGoToPage(pageNum - 1);
        });

        this.filterService.setUTC.subscribe((isUTC) => {
            this.isUTC = isUTC;
            this.gridObj.api.refreshCells({ force: true });
        });

        // subscribing to the filter service to handle search filter changes
        this.subscription.add(
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.columnFilters.name = filterString;
                this.isfilterApplied();
                this.refresh();
            })
        );
    }

    ngOnChanges() {
        this.getTimelineData(this.currentNetwork.id);
    }

    filterChanged(columnId, value) {
        if (columnId === 'name') {
            this.filterString = value;
        }
        _.set(this.columnFilters, columnId, value);
        this.isLoading = true;
        this.isfilterApplied();
        this.shouldShowNoData();
        this.refresh();
    }

    shouldShowNoData() {
        if (this.items.length === 0) {
            this.showNoData = true;
        }
    }

    isfilterApplied() {
        this.filterHasChanged = true;
        this.filterApplied = _.some(this.columnFilters, (value, filterName) => !_.isEmpty(_.toString(value)));
    }

    async initEventTimelineSearch() {
        this.filterService.setPageSize(this.pageSize);

        this._startTime = this.filterService.getStartTime();
        this._endTime = this.filterService.getEndTime();

        this.subscription.add(
            this.filterService.setDateFilterEvent.subscribe(() => {
                this.dateFilter = this.filterService.getDateFilter();
                this.newStartTime = this.filterService.getStartTime();
                this.newEndTime = this.filterService.getEndTime();
                this.isFilterApplied();

                if (!this.noNetwork) {
                    this.getTimelineData(this.currentNetwork.id);
                    if (this.showAllNetworks) {
                        this.getNetworkIds();
                    }
                }
            })
        );

        // subscribing to the filter service to handle the change in page
        this.subscription.add(
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.page = pageNum;
            })
        );

        // subscribing to the filter service to handle search filter changes
        this.subscription.add(
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.filterString = filterString;
                this.applyFilter();
            })
        );

        this.initialized = true;

        if (this.authorizationService.canListUserIdentities() && this.currentTenantId != null) {
            this.lookUp = {};

            // Do a lookup on the Auth0Id to translate the names
            await this.iamService
                .find('user-identities', { tenantId: this.currentTenantId })
                .toPromise()
                .then((users: any) => {
                    for (const user of users) {
                        if (!_.isEmpty(user['identityMappings']) && user['identityMappings'].length > 0) {
                            const mapping = user['identityMappings'].shift();
                            if (mapping['userIdentityId']) {
                                this.lookUp[mapping['userIdentityId']] = user['name'];
                            }
                        }
                    }
                });
            await this.iamService
                .find('api-account-identities', { tenantId: this.currentTenantId })
                .toPromise()
                .then((users: any) => {
                    for (const user of users) {
                        if (!_.isEmpty(user.id)) {
                            this.lookUp[user.id] = user.name;
                        }
                    }
                });
        } else {
            this.lookUp = {};
        }

        this.currentNetworkSub = this.apiService.currentNetwork.subscribe((network) => {
            this.currentNetwork = network;

            if (network.id !== null || this.showAllNetworks) {
                this.noNetwork = false;
                this.isLoading = true;
                this.getTimelineData(network.id);
                this.getAllTimelineData(network.id);
                if (this.showAllNetworks) {
                    this.getNetworkIds();
                }
            } else {
                this.noNetwork = true;
                this.isLoading = false;
                this.showList = false;
            }
        });
    }

    // Input Filters, trigger refresh on changes

    ngOnDestroy() {
        this.currentNetworkSub.unsubscribe();
        if (this.elasticSearchSub != null) {
            this.elasticSearchSub.unsubscribe();
        }

        if (this.elasticSearchNetworksSub != null) {
            this.elasticSearchNetworksSub.unsubscribe();
        }
        this.subscription.unsubscribe();
        this.filterService.reset();
    }

    applyFilter() {
        this.displayedItems = this.filterService.applyLocalFilter(this.items);
    }

    filtering(type) {
        if (this.filteringBy === type) {
            this.filteringBy = '';
        } else {
            this.filteringBy = type;
        }
    }

    closeFilters() {
        this.filteringBy = '';
    }

    // eventType
    setEventTypeFilter(filter) {
        if (filter === '' || false) {
            this.selectedEventType = eventTypeString;
            this._eventTypeFilter = false;
        } else {
            this.selectedEventType = filter;
            this._eventTypeFilter = filter || false;
        }
        if (!this.noNetwork) {
            this.getTimelineData(this.currentNetwork.id);
            this.getAllTimelineData(this.currentNetwork.id);
        }
    }

    getEventTypeFilter() {
        return this._eventTypeFilter;
    }

    setResourceTypeFilter(filter) {
        if (filter === '') {
            this.selectedResourceType = resourceTypeString;
            this._resourceTypeFilter = false;
        } else {
            this.selectedResourceType = filter;
            this._resourceTypeFilter = filter || false;
        }
        if (!this.noNetwork) {
            this.getTimelineData(this.currentNetwork.id);
        }
    }

    getResourceTypeFilter() {
        return this._resourceTypeFilter;
    }

    setNetworkFilter(filter) {
        if (filter === '') {
            this.networkTitle = networkTitleString;
            this._networkFilter = false;
        } else {
            if (this.networkLookup[filter] != null) {
                this.networkTitle = this.networkLookup[filter];
            } else {
                this.networkTitle = networkTitleString;
            }
            this._networkFilter = filter || false;
        }
        if (!this.noNetwork) {
            this.getTimelineData(this.currentNetwork.id);
            this.getAllTimelineData(this.currentNetwork.id);
        }
    }

    getNetworkIds() {
        if (this.elasticSearchNetworksSub != null) {
            this.elasticSearchNetworksSub.unsubscribe();
        }

        // make sure we don't go crazy while setting inputs
        if (!this.initialized) {
            return;
        }

        if (this.currentNetworkGroup.id == null) {
            console.warn('No org specified');
            return;
        }

        this.networksQuery = new EventsTimelineNetworksQuery(this.logger);
        this.networksQuery.addTimeFilter(this._startTime, this._endTime);
        this.networksQuery.addFilter('organizationId', this.currentOrgId);

        this.logger.info('Elasticsearch Events Networks query', JSON.stringify(this.networksQuery.getQuery()));
        this.elasticSearchNetworksSub = this.elasticsearch
            .search(this.networksQuery.getIndexPattern(), this.networksQuery.getQuery())
            .subscribe(
                (data) => {
                    const networks = data.aggregations.networkId.buckets;
                    for (const network of networks) {
                        if (this.networkLookup[network.key] === undefined) {
                            this.networkLookup[network.key] = network.key;
                            this.networkFilterList.push({ name: network.key, id: network.key });
                        }
                    }
                    this.networkFilters.emit(this.networkFilterList);
                },
                (error) => {
                    this.errorHistoryService.addError(error.message);
                }
            );
    }

    formatTime(theDate) {
        if (this.isUTC) {
            return moment.utc(theDate).format('M/D/YY h:mm a');
        } else {
            return moment.utc(theDate).local().format('M/D/YY h:mm a');
        }
    }

    sort(sortBy) {
        if (this.sorting === sortBy) {
            if (this.ordering === 'asc') {
                this.ordering = 'desc';
            } else {
                this.ordering = 'asc';
            }
        } else {
            this.ordering = 'asc';
            this.sorting = sortBy;
        }
        this.paramList['sort'] = `${this.sorting},${this.ordering}`;
        this.refresh();
    }

    public refresh() {
        this.getTimelineData(this.currentNetwork.id);
        this.getAllTimelineData(this.currentNetwork.id);
    }

    getSortClass(id) {
        if (id === this.sorting) {
            return this.ordering;
        } else {
            return '';
        }
    }

    isFilterApplied() {
        this.filterHasChanged = true;
    }

    trackByTimestamp(index, item) {
        return item.Timestamp;
    }

    process(event) {
        // user field
        if (!_.isEmpty(event['identityId'])) {
            event['user'] = this.lookupPipe.transform(event['identityId'], this.lookUp, event['identityId']);
        } else {
            event['user'] = '--';
        }

        if (event['networkId'] !== undefined) {
            event['networkName'] = this.lookupPipe.transform(
                event['networkId'],
                this.networkLookup,
                event['networkId']
            );
        } else {
            event['networkName'] = '--';
        }

        // legacy mop events
        if (event['eventSource'] === undefined) {
            // This mimics the logstash processing for legacy events, this can eventually go away ~90 days after Camelback is released
            //  determine resource type
            if (event['vtcId'].match('CL')) {
                event['resourceType'] = 'Client';
            } else if (event['vtcId'].match('GW')) {
                event['resourceType'] = 'Gateway';
            } else if (event['vtcId'].match('TN')) {
                event['resourceType'] = 'TransferNode';
            }

            event['eventSource'] = 'Network';
            event['eventType'] = 'Status';

            event['resourceId'] = event['vtcId'];
            event['eventSeverity'] = event['severity'];
            event['resourceName'] = event['commonName'];
            event['eventDescription'] = event['event'];
        }

        // Update the event description so it displays what resource had a state change
        if (event['commonName'] !== undefined) {
            event['eventDescription'] = event['eventDescription'] + ' - ' + event['commonName'];
        }

        if (event['traceId'] === undefined) {
            event['traceId'] = '--';
        }

        return event;
    }

    downloadAllEvents(event) {
        this.csvDownloadServiceService.download(this.filename, this.allItems, this.columns);
    }

    downloadFilteredEvents(event) {
        this.csvDownloadServiceService.download(this.filename, this.items, this.columns);
    }

    onGridReady(gridObj) {
        this.gridObj = gridObj;
    }

    /**
     * Get timeline events from the Network controller
     */
    public getTimelineData(networkId) {
        // make sure we don't go crazy while setting inputs
        if (!this.initialized) {
            return;
        }

        this.logger.info('Get management events timeline');
        this.isLoading = true;

        if (this.currentOrgId === null) {
            console.warn('No org specified');
            this.isLoading = false;
            return;
        }

        const index = 'ncentityevent';
        this.noData = true;
        this.isLoading = true;
        this.logger.info('Elasticsearch Events query', JSON.stringify(this.getQuery()));

        //reporting
        let networkEventResourceType: any = '';
        let networkEventType: any = '';
        let networkEventSort: any = '';
        let networkEvent: any = '';

        if (this.sorting === '@timestamp') {
            networkEventSort = this.ordering;
        }
        if (this.filterApplied && this.columnFilters.resourceType) {
            networkEventResourceType = this.columnFilters.resourceType;
        }
        if (this.filterApplied && this.columnFilters.eventType) {
            networkEventType = this.columnFilters.eventType;
        }
        if (this.filterApplied && this.columnFilters.name) {
            networkEvent = '*' + this.columnFilters.name + '*';
        }

        const entityEventTemplateParams: EntityEventTemplateQueryParameter = {
            indexName: index,
            gte: this.newStartTime.valueOf(),
            lte: this.newEndTime.valueOf(),
            size: '5000',
            networkGroupId: this.currentNetwork.networkGroupId,
            networkId: this.currentNetwork.id,
            networkEvent: networkEvent,
            networkEventResourceType: networkEventResourceType,
            networkEventType: networkEventType,
            networkEventSort: networkEventSort,
        };
        this.logger.info(
            'Elasticsearch Events template: ncentityevent_events_tmpl',
            JSON.stringify(entityEventTemplateParams)
        );

        // we may not need to unsubscribe from this manually, but it doesn't hurt to do so
        this.subscription.add(
            //this.elasticsearch.search(this.currentNetwork.networkGroupId, index, this.getQuery())
            this.elasticsearch
                .apiTemplateSearch('ncentityevent_events_tmpl', entityEventTemplateParams)
                .subscribe((data) => {
                    const items = this.elasticsearch.hitsToArray(data, 'management-events');
                    for (let i = 0; i < items.length; i++) {
                        items[i] = this.process(items[i]);
                    }
                    this.items = items.map((item, index) => ({
                        ...item,
                        itemIndex: index,
                    }));
                    this.filterService.setPageSize(30);
                    this.filterService.setTotalElements(this.items.length);
                    this.filterService.updateTotalPages();
                    console.log(this.managementEventData);
                    this.isLoading = false;
                    this.shouldShowNoData();
                })
        );
    }

    /**
     * Get timeline events from the Network controller
     */
    public getAllTimelineData(networkId) {
        // make sure we don't go crazy while setting inputs
        if (!this.initialized) {
            return;
        }

        this.logger.info('Get all management events timeline');
        this.isLoading = true;

        if (this.currentOrgId === null) {
            console.warn('No org specified');
            this.isLoading = false;
            return;
        }

        const index = 'ncentityevent';
        this.noData = true;
        this.isLoading = true;
        this.logger.info('Elasticsearch Events query', JSON.stringify(this.getAllQuery()));

        //reporting
        let networkEventResourceType: any = '';
        let networkEventType: any = '';
        let networkEventSort: any = '';
        let networkEvent: any = '';

        if (this.sorting === '@timestamp') {
            networkEventSort = this.ordering;
        }
        if (this.filterApplied && this.columnFilters.resourceType) {
            networkEventResourceType = this.columnFilters.resourceType;
        }
        if (this.filterApplied && this.columnFilters.eventType) {
            networkEventType = this.columnFilters.eventType;
        }
        if (this.filterApplied && this.columnFilters.name) {
            networkEvent = '*' + this.columnFilters.name + '*';
        }
        const entityEventTemplateParams: EntityEventTemplateQueryParameter = {
            indexName: index,
            gte: 'now-' + this.dateFilter,
            lte: 'now',
            size: '10000',
            networkGroupId: this.currentNetwork.networkGroupId,
            networkId: this.currentNetwork.id,
            networkEvent: networkEvent,
            networkEventResourceType: networkEventResourceType,
            networkEventType: networkEventType,
            networkEventSort: networkEventSort,
        };
        this.logger.info(
            'Elasticsearch Events template:ncentityevent_all_events_tmpl',
            JSON.stringify(entityEventTemplateParams)
        );

        // we may not need to unsubscribe from this manually, but it doesn't hurt to do so
        this.subscription.add(
            //this.elasticsearch.search(this.currentNetwork.networkGroupId, index, this.getAllQuery())
            this.elasticsearch
                .apiTemplateSearch('ncentityevent_all_events_tmpl', entityEventTemplateParams)

                .subscribe((data) => {
                    const allItems = this.elasticsearch.hitsToArray(data, 'management-events');
                    for (let i = 0; i < allItems.length; i++) {
                        allItems[i] = this.process(allItems[i]);
                    }
                    this.allItems = allItems.map((item, index) => ({
                        ...item,
                        itemIndex: index,
                    }));
                    this.filterService.setPageSize(30);
                    this.filterService.setTotalElements(this.allItems.length);
                    this.filterService.updateTotalPages();
                    console.log(this.managementEventData);
                    this.isLoading = false;
                })
        );
    }

    public getQuery() {
        const model: any = {
            query: {
                bool: {
                    must: [
                        {
                            match_phrase: {
                                organizationId: {
                                    query: this.currentNetwork.networkGroupId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                networkId: {
                                    query: this.currentNetwork.id,
                                },
                            },
                        },
                    ],
                    must_not: [
                        {
                            match_phrase: {
                                changeType: {
                                    query: 'soft',
                                },
                            },
                        },
                    ],
                },
            },
            size: 5000,
            _source: {
                excludes: [],
            },
        };
        if (this.sorting === '@timestamp') {
            model.sort = [{ '@timestamp': { order: this.ordering } }];
        }

        if (this.filterApplied && this.columnFilters.resourceType) {
            model.query.bool.must.push({ match_phrase: { resourceType: this.columnFilters.resourceType } });
        }
        if (this.filterApplied && this.columnFilters.eventType) {
            model.query.bool.must.push({ match_phrase: { eventType: this.columnFilters.eventType } });
        }
        if (this.filterApplied && this.columnFilters.name) {
            model.query.bool.must.push({
                query_string: { query: '*' + this.columnFilters.name + '*', analyze_wildcard: true },
            });
        }
        model.query.bool.must.push({
            range: {
                '@timestamp': {
                    gte: this.newStartTime.valueOf(),
                    lte: this.newEndTime.valueOf(),
                    format: 'epoch_millis',
                },
            },
        });
        return model;
    }

    public getAllQuery() {
        const model: any = {
            query: {
                bool: {
                    must: [
                        {
                            query_string: {
                                query: '*',
                                analyze_wildcard: true,
                            },
                        },
                        {
                            match_phrase: {
                                organizationId: {
                                    query: this.currentNetwork.networkGroupId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                'tags.keyword': {
                                    query: 'customer',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                networkId: {
                                    query: this.currentNetwork.id,
                                },
                            },
                        },
                        {
                            range: {
                                '@timestamp': {
                                    gte: 'now-' + this.dateFilter,
                                    lte: 'now',
                                    format: 'epoch_millis',
                                },
                            },
                        },
                    ],
                    must_not: [
                        {
                            match_phrase: {
                                changeType: {
                                    query: 'soft',
                                },
                            },
                        },
                    ],
                },
            },
            size: 10000,
            _source: {
                excludes: [],
            },
        };

        if (this.sorting === '@timestamp') {
            model.sort = [{ '@timestamp': { order: this.ordering } }];
        }

        if (this.filterApplied && this.columnFilters.resourceType) {
            model.query.bool.must.push({ match_phrase: { resourceType: this.columnFilters.resourceType } });
        }
        if (this.filterApplied && this.columnFilters.eventType) {
            model.query.bool.must.push({ match_phrase: { eventType: this.columnFilters.eventType } });
        }
        return model;
    }

    private async _loadV6networks() {
        return await this.networkService
            .get()
            .pipe(take(1))
            .toPromise()
            .then((networkList) => {
                // Hide deleting networks
                const networks = [];
                for (const network of networkList) {
                    if (network.status < 700 && network.organizationId !== null) {
                        networks.push(new Network(network));
                    }
                }

                this.networksV6 = networks;
            });
    }

    private async _loadV7networks() {
        return await this.networkServiceV2.getNetworksPage({ pageParams: { size: 2000, page: 0 } }).then(
            (networks) => {
                const v2Networks = [];
                for (const v2Network of networks) {
                    // only show v2 networks that are in a living state
                    if (v2Network.status !== 'DELETING' && v2Network.status !== 'DELETED') {
                        v2Networks.push(v2Network as NetworkV2);
                    }
                }

                this.networksV7 = v2Networks;
            }, // @TODO - better handling - 404's are ERROR but OK if no one has V2 networks yet
            (error) => {
                this.logger.error('Error returning V2 networks for event-timeline-search component', error);
                this.networksV7 = [];
            }
        );
    }
}
