import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Environment, ENVIRONMENT, PlatformService } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';
import {
    GetOption,
    HateoasResourceOperation,
    PagedGetOption,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import { isEmpty, isNumber, toLower, toString, merge } from 'lodash';

@Injectable({ providedIn: 'root' })
export class PlatformServiceService extends HateoasResourceOperation<PlatformService> {
    static defaultPaginationSize = 500;
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;
    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(PlatformService);
    }
    getResource(): Observable<PlatformService> {
        throw new Error('Do not use: see get getService');
    }

    getPage(): Observable<PagedResourceCollection<PlatformService>> {
        throw new Error('Do not use: see getServices');
    }

    getService(id, options: GetOption = {}): Promise<PlatformService> {
        return super
            .getResource(id, { ...PlatformServiceService.defaultHttpAccept, ...options })
            .toPromise()
            .then((service) => service);
    }

    async getServicesPage(options: PagedGetOption, includeProperties?: string): Promise<PlatformService[]> {
        let params = { ...PlatformServiceService.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super
            .getPage(params)
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((services) => services.resources);
    }
    
    async getAllServicesByNetworkId(networkId: string, options = {}, includeProps = ''): Promise<PlatformService[]> {
        const size = 1;
        let params: PagedGetOption = {
            params: { networkId },
            pageParams: { size, page: 0 },
            sort: { name: 'ASC' },
            headers: { accept: 'application/hal+json' },
        };
        params = merge(params, options);

        if (includeProps) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProps}` } };
        }
        let finalResults = [];
        return this.getServicesPage(params).then((results) => {
            finalResults = results;
            if (this.lastTotalCount > 1) {
                finalResults = [];
                const promises = [];
                let p = 0;
                const totalPages = Math.ceil(this.lastTotalCount / PlatformServiceService.defaultPaginationSize);
                params.pageParams.size = PlatformServiceService.defaultPaginationSize;
                while (p < totalPages) {
                    params.pageParams.page = p++;
                    promises.push(this.getServicesPage(params));
                }
                return Promise.all(promises).then((promiseResults) => {
                    promiseResults.forEach((r) => {
                        finalResults = finalResults.concat(r);
                    });
                    return finalResults;
                });
            } else {
                return finalResults;
            }
        });
    }

    public getConfigsByServiceId(id: string): Observable<any> {
        const options: any = {};
        const url = `${this.environment.v2apiUrl}services?id=${id}&embed=configs`;
        return this.http.get(url, options) as Observable<any>;
    }

    public findByName(name: string, networkId: string): Observable<string> {
        const options: any = {};
        const url = `${this.environment.v2apiUrl}services?networkId=${networkId}&name=${name}`;
        return this.http.get(url, options) as Observable<any>;
    }

    public downloadToCsv(networkId: string): Observable<any> {
        const params = new HttpParams().set('networkId', networkId).set('size', this.lastTotalCount);
        const headers = new HttpHeaders().set('Accept', 'text/csv');
        return this.http.get(this.environment.v2apiUrl + 'services?embed=config', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public validateUpdate(serviceId: string, model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .patch(this.environment.v2apiUrl + 'services/' + serviceId, model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public async validateCreate(model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .post(this.environment.v2apiUrl + 'services', model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public getJson(serviceId: string): Observable<string> {
        const options: any = {};
        const url = `${this.environment.v2apiUrl}services/${serviceId}/?meta=ziti,diffZiti`;
        return this.http.get(url, options) as Observable<any>;
    }

    public downloadFileFormat(
        networkId: string,
        format = 'text/csv',
        params: HttpParams = new HttpParams()
    ): Observable<any> {
        params = params.set('networkId', networkId);
        const headers = new HttpHeaders().set('Accept', format);
        return this.http.get(this.environment.v2apiUrl + 'services?embed=config', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public addressesFormatter(row, type): string {
        let addresses = '';

        const configs = row?.data?.configs || [];
        let typeIndex = 0;

        configs.forEach((config) => {
            if (!isEmpty(config?.addressAndPortIndex)) {
                let addressItems;
                if (type) {
                    addressItems = config?.addressAndPortIndex[type];
                } else {
                    addressItems = config?.addressAndPortIndex.ingress
                        ? config?.addressAndPortIndex.ingress
                        : config?.addressAndPortIndex.egress;
                }
                if (isEmpty(addressItems)) {
                    return;
                } else {
                    typeIndex++;
                }
                let from;
                let to;
                if (typeIndex > 1) {
                    addresses += ', ';
                }
                addressItems.forEach((val, index) => {
                    from = val.fromIpAddress ? val.fromIpAddress : toLower(val.address);
                    to = val.toIpAddress ? val.fromIpAddress : toLower(val.address);
                    if (addresses === from || addresses === to) {
                        return;
                    }
                    if (index > 0) {
                        addresses += ', ';
                    }
                    if (from === to) {
                        addresses += `${from}`;
                    } else {
                        addresses += `${from}--${to}`;
                    }
                });
            } else if (!isEmpty(config?.data?.allowedAddresses)) {
                addresses = toString(config?.data?.allowedAddresses);
            } else if (!isEmpty(config?.data?.addresses)) {
                addresses = toString(config?.data?.addresses);
            } else if (!isEmpty(config?.data?.address)) {
                addresses = config?.data?.address;
            }
        });
        return addresses;
    }

    public portsFormatter(row, type): string {
        let ports = '';

        const configs = row?.data?.configs || [];
        let typeIndex = 0;

        configs.forEach((config) => {
            if (!isEmpty(config?.addressAndPortIndex)) {
                let portItems;
                if (type) {
                    portItems = config?.addressAndPortIndex[type];
                } else {
                    portItems = config?.addressAndPortIndex.ingress
                        ? config?.addressAndPortIndex.ingress
                        : config?.addressAndPortIndex.egress;
                }
                if (isEmpty(portItems)) {
                    return;
                } else {
                    typeIndex++;
                }
                let from;
                let to;
                if (typeIndex > 1) {
                    ports += ', ';
                }
                portItems?.forEach((val, index) => {
                    from = val.fromPort;
                    to = val.toPort;
                    if (from === ports || to === ports) {
                        return;
                    }
                    if (index > 0) {
                        ports += ', ';
                    }
                    if (from === to) {
                        ports += `${from}`;
                    } else {
                        ports += `${from}--${to}`;
                    }
                });
            } else if (!isEmpty(config?.data?.portRanges)) {
                let from;
                let to;
                config?.data?.portRanges.forEach((val, index) => {
                    from = val.low;
                    to = val.high;
                    ports += `${from}--${to}`;
                    if (index < config?.data?.portRanges.length - 1) {
                        ports += ', ';
                    }
                });
            } else if (!isEmpty(config?.data?.ports)) {
                ports = toString(config?.data?.ports);
            } else if (isNumber(config?.data?.port)) {
                ports = config?.data?.port + '';
            }
        });
        return ports;
    }
}
