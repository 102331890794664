import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-table-cell-upgradable',
    templateUrl: './table-cell-upgradable.component.html',
    styleUrls: ['./table-cell-upgradable.component.scss'],
})
export class TableCellUpgradableComponent implements ICellRendererAngularComp {
    cellParams;
    item = {
        id: '',
        name: '',
        selected: false,
        productVersion: false,
        roleItem: false,
    };

    constructor() {}

    agInit(params: ICellRendererParams): void {
        this.cellParams = params;
        this.item = params.data;
    }

    refresh(params: ICellRendererParams): boolean {
        this.cellParams = params;
        this.item = params.data;
        return true;
    }

    edit(item) {
        //stub for user open function
    }

    upgrade($event) {
        $event.preventDefault();
        $event.stopPropagation();
        if (this.cellParams.service) this.cellParams.service(this.item, this.cellParams.parentVersion);
    }
}
