import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-start-menu-item',
    templateUrl: './start-menu-item.component.html',
    styleUrls: ['./start-menu-item.component.scss'],
})
export class StartMenuItemComponent {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() buttonText = '';
    @Input() iconClass = '';
    @Input() disabled = false;
    @Input() showSpinner = false;
    @Input() disabledTooltip = '';
    @Input() completed = false;
    @Output() itemSelected = new EventEmitter();

    constructor() {}

    menuButtonedClicked(event: any) {
        if (this.disabled) {
            return;
        }
        this.itemSelected.emit(event);
    }
}
