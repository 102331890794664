import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedApiv2Module } from '@netfoundry-ui/shared/apiv2';
import { FeatureTagsSelectorModule } from '@netfoundry-ui/feature/tags-selector';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { FeatureDateFiltersModule } from '@netfoundry-ui/feature/date-filters';
import { FeatureZitiUtilizationBasicModule } from '@netfoundry-ui/feature/ziti-utilization-basic';
import { FeatureFormSupportButtonModule } from '@netfoundry-ui/feature/form-support-button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FeatureEventsModalModule } from '@netfoundry-ui/feature/events-modal';
import { FeatureZitiZitiShareModule } from '@netfoundry-ui/feature/ziti/ziti-share';
import { FeatureZitiModule } from '@netfoundry-ui/feature/ziti';
import { FeaturePreviewListModule } from '@netfoundry-ui/feature/preview-list';
import { ZitiEdgeRouterComponent } from './ziti-edge-router.component';
import { ButtonModule } from 'primeng/button';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UiPipesModule,
        UiLoaderModule,
        SharedModelModule,
        MatDialogModule,
        SharedApiv2Module,
        FeatureTagsSelectorModule,
        MatRadioModule,
        MatExpansionModule,
        MatGridListModule,
        FeatureDateFiltersModule,
        FeatureZitiUtilizationBasicModule,
        FeatureFormSupportButtonModule,
        MatTooltipModule,
        RouterModule,
        FeatureEventsModalModule,
        SharedApiv2Module,
        FeatureZitiZitiShareModule,
        SharedModelModule,
        FeatureZitiModule,
        FeaturePreviewListModule,
        ButtonModule
    ],
    declarations: [ZitiEdgeRouterComponent],
    exports: [ZitiEdgeRouterComponent],
})
export class FeatureFormZitiEdgeRouterFormModule {}
