import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AllocatedIp, ENVIRONMENT, Environment } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import {
    GetOption,
    HateoasResourceOperation,
    PagedGetOption,
    HttpMethod,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AllocatedIpService extends HateoasResourceOperation<AllocatedIp> {
    static defaultPaginationSize = 500;
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;
    apiUrl: string;

    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(AllocatedIp);
        this.apiUrl = environment.v3Enabled ? environment.v3apiUrl : environment.v2apiUrl;
    }

    getAllocatedIp(id: string| number, options: GetOption = {}): Promise<AllocatedIp | undefined> {
        return super
            .getResource(id, { ...AllocatedIpService.defaultHttpAccept, ...options })
            .toPromise()
            .then((controller) => controller);
    }

    async getAllocatedIpPage(options?: PagedGetOption, includeProperties?: string): Promise<AllocatedIp[]> {
        let params = { ...AllocatedIpService.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super
            .getPage(params)
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((allocatedIps) => (allocatedIps ? allocatedIps.resources : ([] as AllocatedIp[])));
    }

    public releaseIp(ip: string): Observable <string> {
        const body: any = {};
        const url = `${this.apiUrl}host-addresses/${ip}`;
        return this.http.delete(url, body) as Observable<any>;
    }

    public getJson(ip: string): Observable<string> {
        const options: any = {};
        const url = `${this.environment.v2apiUrl}host-addresses/${ip}`;
        return this.http.get(url, options) as Observable<any>;
    }


    public downloadToCsv(networkId: string): Observable<any> {
        const params = new HttpParams().set('networkId', networkId).set('size', this.lastTotalCount);
        const headers = new HttpHeaders().set('Accept', 'text/csv');
        return this.http.get(this.environment.v2apiUrl + 'app-wans', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public downloadFileFormat(
        networkId: string,
        format = 'text/csv',
        params: HttpParams = new HttpParams()
    ): Observable<any> {
        params = params.set('networkId', networkId);
        const headers = new HttpHeaders().set('Accept', format);
        return this.http.get(this.environment.v2apiUrl + 'host-addresses', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

}