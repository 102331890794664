import { ElasticsearchQuery } from './elasticsearch-query';

export class UtilizationTimelineQuery extends ElasticsearchQuery {
    /**
     * Base query
     */
    protected model = {
        query: {
            bool: {
                must: [
                    {
                        match_all: {},
                    },
                    {
                        match_phrase: {
                            'tags.keyword': {
                                query: 'customer',
                            },
                        },
                    },
                ],
                must_not: [
                    {
                        match_phrase: {
                            nodeType: {
                                query: 'TransferNode',
                            },
                        },
                    },
                ],
            },
        },
        size: 0,
        _source: {
            excludes: [],
        },
        aggs: {
            aggregate: {
                date_histogram: {
                    field: '@timestamp',
                    interval: '1d',
                    time_zone: 'UTC',
                    min_doc_count: 1,
                },
                aggs: {
                    '1': {
                        sum: {
                            field: 'bytes',
                        },
                    },
                },
            },
        },
    };

    public getIndexPattern() {
        return 'ncvtccurrent';
    }

    public getAggregateName() {
        return 'aggregate';
    }
}
