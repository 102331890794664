import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import * as _ from 'lodash';

@Component({
    selector: 'app-table-cell-email',
    templateUrl: './table-cell-email.component.html',
    styleUrls: ['./table-cell-email.component.scss'],
})
export class TableCellEmailComponent implements ICellRendererAngularComp {
    cellParams;
    item = {
        id: '',
        name: '',
        selected: false,
        email: '',
        contactEmail: '',
        roleItem: '',
        roleName: '',
    };
    disableToggle = false;

    agInit(params: ICellRendererParams): void {
        this.cellParams = params;
        this.item = params.data;
        this.disableToggle = _.get(this.item, 'typeId') === 'Router';
    }

    refresh(params: ICellRendererParams): boolean {
        this.cellParams = params;
        this.item = params.data;
        this.disableToggle = _.get(this.item, 'typeId') === 'Router';
        return true;
    }

    getInitials(item) {
        let initials = '';
        if (!item) {
            return initials;
        }
        if (item.firstName && item.firstName.length > 0) {
            initials += item.firstName.substr(0, 1);
        } else {
            initials += item.email.substr(0, 1);
        }
        if (item.lastName && item.lastName.length > 0) {
            initials += item.lastName.substr(0, 1);
        } else {
            initials += item.email.substr(1, 1);
        }
        return initials;
    }

    open(item) {
        //stub for user open function
    }
}
