import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, ɵComponentType } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EdgeRouterServiceV2, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    EdgeRouterV2,
    Environment,
    ENVIRONMENT,
    NETWORK_RESTART_DIALOG,
    NETWORK_UPGRADE_DIALOG,
    NetworkController,
    NetworkV2,
} from '@netfoundry-ui/shared/model';
import {
    ApiService,
    ErrorHistoryService,
    HTTP_CLIENT,
    LoggerService,
    TokenService,
} from '@netfoundry-ui/shared/services';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import semver from 'semver';
import { PagedGetOption } from '@lagoshny/ngx-hateoas-client';
import { NetworkResizeModalComponent } from './network-resize-modal/network-resize-modal.component';

@Injectable({
    providedIn: 'root',
})
export class NetworkUpgradeService {
    networkVersions: any[] = [];
    apiUrl: string;
    provider;

    constructor(
        @Inject(HTTP_CLIENT) private http: HttpClient,
        private router: Router,
        public logger: LoggerService,
        public growlerService: GrowlerService,
        public apiService: ApiService,
        private tokenService: TokenService,
        private errorHistoryService: ErrorHistoryService,
        private authorizationSvc: AuthorizationService,
        public dialogForm: MatDialog,
        private edgeRouterSvc: EdgeRouterServiceV2,
        public networkService: NetworkServiceV2,
        @Inject(ENVIRONMENT) private environment: Environment,
        @Inject(NETWORK_RESTART_DIALOG) private networkRestartDialog: ɵComponentType<any>,
        @Inject(NETWORK_UPGRADE_DIALOG) private networkUpgradeDialog: ɵComponentType<any>
    ) {
        this.apiUrl = environment.v3Enabled ? environment.v3apiUrl : environment.v2apiUrl;
        this.refreshVersions();
    }

    getEdgeRouters(networkId: string): Promise<EdgeRouterV2[]> {
        const options = this.getOptions(networkId);
        return this.edgeRouterSvc.getEdgeRouterPage(options);
    }

    isUpgradeable(network: NetworkV2): boolean {
        const versions = this.getUpgradeableVersions(network).filter((r) => r.recommended);
        return versions && versions.length > 0;
    }

    getUpgradeableVersions(network: NetworkV2) {
        const versions: any[] = [];
        if (network.status === '300' || network.status === 'PROVISIONED') {
            if (this.authorizationSvc.canUpgradeNetwork(network.id)) {
                for (const version of this.networkVersions) {
                    if (
                        semver.lt(network.productVersion, version['networkVersion']) &&
                        (!version['minimumNetworkVersionForUpgrade'] ||
                            semver.gte(network.productVersion, version['minimumNetworkVersionForUpgrade']))
                    ) {
                        versions.push(version);
                    } else if (semver.gt(network.productVersion, version['networkVersion'])) {
                        break;
                    }
                }
            }
        }
        return versions;
    }
    getUpgradeableVersionsEdgeRouter(network: NetworkV2) {
        const versions: any[] = [];
        if (network.status === 'PROVISIONED') {
            if (this.authorizationSvc.canUpgradeNetwork(network.id)) {
                for (const version of this.networkVersions) {
                    versions.push(version)
                }
            }
        }
        return versions;
    }

    requestRestart(network: NetworkV2) {
        if (this.authorizationSvc.canUpgradeNetwork(network.id)) {
            const dialogRef = this.dialogForm.open(this.networkRestartDialog, {
                data: {
                    network: network,
                },
            });

            dialogRef.afterClosed().subscribe((options) => {
                if (options) {
                    if (options.host) {
                        this.restartHost(options.network).then(() => {
                            this.growlerService.show(
                                new GrowlerData(
                                    'success',
                                    'Success',
                                    'Restart Initiated',
                                    'Restart of network controller host initiated.  The network may take a few minutes to become available.<a href="' +
                                        this.router.createUrlTree([`/process-executions`]).toString() +
                                        '">Click here to find out more</a>"'
                                )
                            );
                        });
                    } else if (options.process) {
                        this.restartProcess(options.network).then(() => {
                            this.growlerService.show(
                                new GrowlerData(
                                    'success',
                                    'Success',
                                    'Restart Initiated',
                                    'Ziti process restart initiated.  The network may take a few minutes to become available.<a href="' +
                                        this.router.createUrlTree(['/process-executions']).toString() +
                                        '">Click here to find out more</a>"'
                                )
                            );
                        });
                    }
                }
            });
        }
    }

    requestResize(network: NetworkV2) {
        if (this.authorizationSvc.canUpgradeNetwork(network.id)) {
            this.networkService.getSingleEmbeddedHost(network.networkController.id).subscribe((data) => {
                this.provider = data[0].host[0].provider;
            this.http
                .get(`${this.apiUrl}/host-sizes?type=NC&provider=${this.provider}`, {
                    headers: this.setHeaders(),
                })
                .toPromise()
                .then((data) => {
                    if (data) {
                        const dialogRef = this.dialogForm.open(NetworkResizeModalComponent, {
                            data: {
                                network: network,
                                sizes: data,
                            },
                        });

                        dialogRef.afterClosed().subscribe((options) => {
                            if (options) {
                                this.resize(options.network, options.size).then(() => {
                                    this.growlerService.show(
                                        new GrowlerData(
                                            'success',
                                            'Success',
                                            'Resize Initiated',
                                            'Resize of network controller host initiated.  The network may take a few minutes to become available.<a href="' +
                                                this.router.createUrlTree(['/process-executions']).toString() +
                                                '">Click here to find out more</a>"'
                                        )
                                    );
                                });
                            }
                        });
                    }
                });
            });
        }
    }

    requestNCResize(networkController: NetworkController) {
        this.http
            .get(`${this.apiUrl}/host-sizes?type=NC`, {
                headers: this.setHeaders(),
            })
            .toPromise()
            .then((data) => {
                if (data) {
                    const dialogRef = this.dialogForm.open(NetworkResizeModalComponent, {
                        data: {
                            networkController: networkController,
                            sizes: data,
                        },
                    });

                    dialogRef.afterClosed().subscribe((options) => {
                        if (options) {
                            this.resize(options.network, options.size).then(() => {
                                this.growlerService.show(
                                    new GrowlerData(
                                        'success',
                                        'Success',
                                        'Resize Initiated',
                                        'Resize of network controller host initiated.  The network may take a few minutes to become available.<a href="' +
                                            this.router.createUrlTree(['/process-executions']).toString() +
                                            '">Click here to find out more</a>"'
                                    )
                                );
                            });
                        }
                    });
                }
            });
    }

    requestUpgrade(network: NetworkV2) {
        if (this.authorizationSvc.canUpgradeNetwork(network.id)) {
            const dialogRef = this.dialogForm.open(this.networkUpgradeDialog, {
                data: {
                    network: network,
                },
            });

            dialogRef.afterClosed().subscribe((options) => {
                if (options) {
                    this.upgrade(options);
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Upgrade initiated',
                            'Upgrade was initiated successfully.<a href="' +
                                this.router.createUrlTree(['/process-executions']).toString() +
                                '">Click here to find out more</a>"'
                        )
                    );
                }
            });
        }
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: any, error: HttpErrorResponse, silenceCodes?: number[]) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            scope.logger.error('HTTP error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            scope.logger.error(`HTTP response returned code ${error.status}`, error.error);
        }

        let errorMessage = error.message;
        if (error.error && error.error[0] && error.error[0]['message']) {
            errorMessage = error.error[0]['message'];
        }
        let displayGrowler = true;
        if (silenceCodes != null && silenceCodes.indexOf(error.status) > -1) {
            displayGrowler = false;
        } else {
            this.errorHistoryService.addError(error.message);
        }
        if (displayGrowler) {
            this.growlerService.show(new GrowlerData('error', 'Error', 'An error occured', errorMessage));
        }
        return observableThrowError(error);
    }

    private getOptions(networkId: string) {
        const options: PagedGetOption = {
            params: { networkId: networkId },
        };
        return options;
    }

    private async upgrade(options: any) {
        const body: any = {
            target: options.upgradeVersion,
        };
        this.apiService.setCurrentNetwork(options.network);
        if (options.all) {
            const er = await this.getEdgeRouters(options.network.id);
            if (!er || er.length === 0) {
                options.ncOnly = true;
            }
        }

        if (options.ncOnly) {
            this.http
                .post(`${this.apiUrl}network-controllers/${options.network.networkController.id}/upgrade`, body, {
                    headers: this.setHeaders(),
                })
                .toPromise()
                .then((result) => {
                    this.router.navigate(['/process-executions']);
                })
                .catch((error) => this.handleError(this, error));
        } else {
            if (options.edgeRouterIds) {
                body.edgeRouterIds = options.edgeRouterIds;
                body.includeController = options.includeController;
            }

            this.http
                .post(`${this.apiUrl}networks/${options.network.id}/upgrade`, body, { headers: this.setHeaders() })
                .toPromise()
                .then((result) => {
                    this.router.navigate(['/process-executions']);
                })
                .catch((error) => this.handleError(this, error));
        }
    }

    private async restartHost(network: any) {
        return this.http
            .put(
                `${this.apiUrl}network-controllers/${network.networkController.id}/host/restart`,
                {},
                {
                    headers: this.setHeaders(),
                }
            )
            .toPromise();
    }

    private async restartProcess(network: any) {
        return this.http
            .put(
                `${this.apiUrl}network-controllers/${network.networkController.id}/process/restart`,
                {},
                {
                    headers: this.setHeaders(),
                }
            )
            .toPromise();
    }

    private async resize(network: any, size: string) {
        const body = { size };
        return this.http
            .put(`${this.apiUrl}network-controllers/${network.networkController.id}/host/resize`, body, {
                headers: this.setHeaders(),
            })
            .toPromise();
    }

    private setHeaders(): HttpHeaders {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (!tokenResponse?.expired && tokenResponse?.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        if (headers != null) {
            return headers.append('Content-Type', 'application/json');
        } else {
            return headers;
        }
    }

    public refreshVersions() {
        this.http
            .get(`${this.apiUrl}network-versions`, {
                headers: this.setHeaders(),
            })
            .toPromise()
            .then((nv: any) => {
                this.networkVersions = nv
                    .filter((v: any) => v.active && semver.gt(v.networkVersion, '7.3.14'))
                    .map((v: any) => ({
                        networkVersion: v.networkVersion,
                        minimumNetworkVersionForUpgrade: v.minimumNetworkVersionForUpgrade,
                        recommended: v.recommended,
                    }))
                    .sort((b: any, a: any) => semver.compare(a.networkVersion, b.networkVersion));
                //this.logger.info(JSON.stringify(this.networkVersions, null, 4));
                setTimeout(this.refreshVersions, 3600000);
            })
            .catch((error) => this.handleError(this, error));
    }
}
