import { Component, OnDestroy, OnInit } from '@angular/core';
import { FeatureService } from '@netfoundry-ui/shared/services';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-logs',
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.component.scss'],
    providers: [FromDatePipe],
})
export class LogsComponent implements OnInit, OnDestroy {
    endTime: number = Date.now();
    startTime: number = this.endTime - 365 * (24 * 60 * 60 * 1000);
    errorMessage: string;
    currentTimeFilter = '24h';
    subscription: Subscription = new Subscription();

    eventTypeFilter: string | boolean = false;
    resourceTypeFilter: string | boolean = false;
    networkFilter: string | boolean = false;

    auditEventTypeFilter: string | boolean = false;
    auditSourceFilter: string | boolean = false;
    auditTakenByFilter: string | boolean = false;
    type = 'network';

    pageTitle = 'Network Events';

    eventTypes = ['New', 'Building', 'Error', 'Changed', 'Status'];
    resourceTypes = [
        'NetworkController',
        'Gateway',
        'Client',
        'Network',
        'Service',
        'ServiceGroup',
        'ServiceConnection',
        'EndpointGroup',
        'TransferNode',
        'TransferNodePool',
        'Host',
        'Endpoint',
        'AppWan',
    ];

    networkFilterList = [];

    // TODO: edit with more types or replace if possible to obtain types from the API
    auditEventTypes = ['Organization', 'Authentication', 'Settings', 'Account'];

    auditSources = ['Console', 'API'];
    users = [];

    constructor(private fromDate: FromDatePipe, public featureService: FeatureService) {}

    ngOnInit() {
        this.startTime = this.fromDate.transform(this.currentTimeFilter);
    }

    public setEvents(type) {
        this.type = type;

        if (type === 'network') {
            this.pageTitle = 'Network Events';
        } /* else {
      this.pageTitle = 'Audit Log';
    }*/
    }

    public setStartTime(value) {
        this.currentTimeFilter = value;
        this.startTime = this.fromDate.transform(this.currentTimeFilter);
    }

    setTakenByFilterList(list) {
        this.users = list;
    }

    setNetworkFilterList(list) {
        this.networkFilterList = list;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
