import { Injectable } from '@angular/core';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Endpoint, GatewayCluster, ShareData } from '@netfoundry-ui/shared/model';
import {
    AzureDeployService,
    GatewayClusterService,
    GatewayService,
    GetCloudformationLinkService,
    LoggerService,
    NetworkVersionService,
    ValidateService,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { ShareService } from '@netfoundry-ui/shared/share';
import { SortbyPipe } from '@netfoundry-ui/ui/pipes';
import { saveAs } from 'file-saver';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GatewayFormsService {
    subscription: Subscription = new Subscription();

    timeout = 150;

    gateways = [];
    regKey = [];
    zitiRegKeyBlobs = [];
    // subject used to determine whether or not the registration key was obtained
    private updatedRegKeySource = new Subject<boolean>();
    public updatedRegKey = this.updatedRegKeySource.asObservable();

    constructor(
        private logger: LoggerService,
        private cfService: GetCloudformationLinkService,
        private validateService: ValidateService,
        private shareService: ShareService,
        private growlerService: GrowlerService,
        private clusterService: GatewayClusterService,
        private sortByPipe: SortbyPipe,
        private gatewayService: GatewayService,
        private azureDeployService: AzureDeployService,
        private networkVersionService: NetworkVersionService,
        private zitiEnabledService: ZitiEnabledService
    ) {}

    async save(model) {
        this.logger.info('Saving...');
        return await this.gatewayService
            .save(model)
            .toPromise()
            .then(
                (result) => {
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Creation Complete',
                            'Gateway information has been saved.'
                        )
                    );
                    return new Endpoint(result);
                },
                (error) => {
                    this.logger.info('received an error while attempting to save: ', error);
                    return null;
                }
            );
    }

    async saveHa(clusterModel, model) {
        clusterModel.protectionGroupId = model.name;
        clusterModel.name = model.name;
        clusterModel.geoRegionId = model.geoRegionId;
        if (clusterModel.protectionType == null) {
            clusterModel.protectionType = '1:1';
        }
        clusterModel.endpointType = model.endpointType;
        clusterModel['dataCenterId'] = model.dataCenterId;

        return await this.clusterService
            .save(clusterModel)
            .toPromise()
            .then(
                (data) => {
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Creation Complete',
                            'HA Gateway information has been saved.'
                        )
                    );
                    return data;
                },
                (error) => {
                    this.logger.info('received an error while attempting to save: ', error);
                    return null;
                }
            );
    }

    getAwsRegionId(model) {
        // obtaining the AWS region ID for the gateway
        return this.gatewayService
            .getResource(this.gatewayService.getLinkedResourceUrl(model, 'dataCenter'))
            .toPromise()
            .then((result) => result['locationCode']);
    }

    // function for getting the registration keys to be displayed for a gateway cluster
    loadGatewayCluster(clusterModel) {
        // getting the endpoints associated with the cluster
        this.subscription.add(
            this.clusterService.getLinkedResources(clusterModel, 'endpoints').subscribe((endpoints) => {
                if (endpoints.length === 0) {
                    // if the endpoint list is empty, retry
                    this.updatedRegKeySource.next(false);
                } else {
                    // list of statuses for the endpoints
                    const statusList = [];

                    // boolean indicating whether or not the loop finished without needing to break out
                    let completedLoop = true;

                    const sortedGateways = this.sortByPipe.transform(endpoints, 'name', 'asc');

                    // looping through the endpoints
                    for (const item of sortedGateways) {
                        const endpoint = new Endpoint(item);

                        // if the endpoint's registration key is null, break out of the loop and restart
                        if (item['registrationKey'] == null) {
                            this.updatedRegKeySource.next(false);
                            completedLoop = false;
                            break;
                        } else {
                            // if the registration key exists and the registration key is not already in the list of keys
                            // add the reg key to the list of keys and the gateway to the list of gateways
                            if (this.regKey.indexOf(endpoint['registrationKey']) === -1) {
                                this.regKey.push(endpoint['registrationKey']);
                                this.gateways.push(endpoint);
                            }

                            // add the status of the current
                            statusList.push(endpoint.status);
                        }
                    }
                    // if the loop completed without breaking out
                    if (completedLoop) {
                        // increase the timeout
                        this.timeout = 1000;
                        // update the title, icon, and stop checking the endpoints if every endpoint is in an active state
                        if (statusList.every((x) => x === 300)) {
                            if (this.zitiEnabledService.zitiPure) {
                                for (const item of sortedGateways) {
                                    this.getZitiRegKey(new Endpoint(item));
                                }
                                this.updatedRegKeySource.next(true);
                            } else {
                                this.updatedRegKeySource.next(true);
                            }
                        } else {
                            // keep updating the endpoint status if not every endpoint is in an active state
                            this.updatedRegKeySource.next(false);
                        }
                    }
                }
            })
        );
    }

    // function for getting the registration key to be displayed
    loadGateway(model) {
        // getting the self link
        const selfLink = model.getSelfLink();
        if (selfLink) {
            // getting the model from the self link
            this.subscription.add(
                this.gatewayService.getResource(selfLink).subscribe((gateway) => {
                    // if the registration key was null, set the regKeySource to false to indicate it's still missing
                    if (gateway['registrationKey'] == null) {
                        this.updatedRegKeySource.next(false);

                        // otherwise, if the key was obtained, update regKey and set regKeySource to true to indicate the key was obtained
                    } else {
                        this.regKey = [gateway['registrationKey']];
                        this.gateways = [new Endpoint(gateway)];
                        const isBuilding = gateway['status'] < 300;
                        this.timeout = 1000;
                        if (isBuilding) {
                            this.updatedRegKeySource.next(false);
                        } else {
                            if (this.zitiEnabledService.zitiPure) {
                                this.getZitiRegKey(gateway);
                                this.updatedRegKeySource.next(true);
                            } else {
                                this.updatedRegKeySource.next(true);
                            }
                        }
                    }
                })
            );

            // otherwise, if the selfLink is null, set regKeySource to false
        } else {
            this.updatedRegKeySource.next(false);
        }
    }

    // function for getting the registration key to be displayed
    loadAutoScalingGateway(model) {
        // getting the self link
        const selfLink = model.getSelfLink();
        if (selfLink) {
            // getting the model from the self link
            this.subscription.add(
                this.clusterService.getResource(selfLink).subscribe((gateway) => {
                    this.regKey = [gateway['registrationKey']];
                    this.gateways = [new GatewayCluster(gateway)];
                    const isBuilding = gateway['status'] < 300;
                    this.timeout = 1000;
                    if (isBuilding) {
                        this.updatedRegKeySource.next(false);
                    } else {
                        this.updatedRegKeySource.next(true);
                    }
                })
            );

            // otherwise, if the selfLink is null, set regKeySource to false
        } else {
            this.updatedRegKeySource.next(false);
        }
    }

    openAzureFormationLink(gateway: Endpoint) {
        this.azureDeployService.openAzureFormationLink(gateway);
    }

    openUrl(url) {
        window.open(url);
    }

    openDownloads() {
        window.open(this.networkVersionService.getDownloadsLink() + '#gateways');
    }

    copy(gateway) {
        const element = <HTMLInputElement>document.getElementById(`RegKey_${gateway.id}`);
        element.focus();
        element.select();
        document.execCommand('copy');
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Reg Key Copied',
                'The reg key ' + gateway.registrationKey + ' has been copied to your clipboard'
            )
        );
    }

    share(gateway) {
        this.shareService.show(new ShareData('gateway', gateway));
    }

    validate(model, isHAGateway, selectedType, typeAppend?, exportModel?) {
        let errorName = false;
        let errorLocation = false;
        let errorType = false;
        let errorRegion = false;
        let errorNameLength = false;
        let errorNextHop = false;

        let errorCollector = false;
        let errorFrequency = false;

        errorName = isHAGateway
            ? !this.validateService.isValidHaName(model.name)
            : !this.validateService.isValidName(model.name);

        if (!this.validateService.isValidName(model.name)) {
            errorName = true;
        }
        if (!this.validateService.hasValue(model.name) || model.name.length < 5 || model.name.length > 65) {
            errorNameLength = true;
        }
        if (selectedType === 'ZT') {
            if (!this.validateService.hasValue(typeAppend)) {
                errorType = true;
            }
        }
        if (
            selectedType === 'GCPCPEGW' ||
            selectedType === 'VCPEGW' ||
            selectedType === 'L2VCPEGW' ||
            selectedType === 'AZCPEGW' ||
            selectedType === 'AZSGW' ||
            selectedType === 'ZT' ||
            selectedType === 'AWSCPEGW' ||
            selectedType === 'GW' ||
            selectedType === 'ALICPEGW' ||
            selectedType === 'OCPCPEGW'
        ) {
            if (!this.validateService.hasValue(model.geoRegionId) && !this.validateService.hasValue(model.countryId)) {
                errorRegion = true;
            }
        } else {
            if (!this.validateService.hasValue(model.dataCenterId)) {
                errorLocation = true;
            }
        }

        if (exportModel) {
            if (!this.validateService.hasValue(exportModel.collectorId)) {
                errorCollector = true;
            }

            if (
                !this.validateService.hasValue(exportModel.exportIntervalSeconds) ||
                isNaN(exportModel.exportIntervalSeconds) ||
                exportModel.exportIntervalSeconds < 1 ||
                exportModel.exportIntervalSeconds > 300
            ) {
                errorFrequency = true;
            }
        }

        if (this.validateService.hasValue(model.o365BreakoutNextHopIp)) {
            errorNextHop = !this.validateService.isValidIP(model.o365BreakoutNextHopIp);
        }
        const isValid =
            !errorName &&
            !errorRegion &&
            !errorLocation &&
            !errorNameLength &&
            !errorType &&
            !errorNextHop &&
            !errorCollector &&
            !errorFrequency;

        return {
            errorName: errorName,
            errorLocation: errorLocation,
            errorType: errorType,
            errorRegion: errorRegion,
            errorNameLength: errorNameLength,
            errorNextHop: errorNextHop,
            errorCollector: errorCollector,
            errorFrequency: errorFrequency,
            isValid: isValid,
        };
    }

    openCloudFormationLink(gateway, regionId) {
        const link = this.cfService.getCfLink(gateway, regionId);
        const win = window.open(link, '_blank');
        win.focus();
    }

    openAutoScaleCloudFormationLink(gateway, regionId) {
        const link = this.cfService.getAutoScaleCfLink(gateway, regionId);
        const win = window.open(link, '_blank');
        win.focus();
    }

    downloadJWT(model, index) {
        const name = model.name + '.jwt';
        saveAs(this.zitiRegKeyBlobs[index], name);
    }

    async getZitiRegKey(model) {
        const zitiRegKeyBlob = await this.gatewayService
            .downloadRegistrationKey(new Endpoint(model))
            .pipe(take(1))
            .toPromise()
            .then((result) => result);
        this.zitiRegKeyBlobs.push(zitiRegKeyBlob);
    }
}
