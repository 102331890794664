import { Injectable } from '@angular/core';
import { AzureSubscription } from '@netfoundry-ui/shared/model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class AzureSubscriptionService extends MopResourceService {
    azureSubscriptionList: AzureSubscription[];
    private azureSubscriptionsSource = new Subject<AzureSubscription[]>();
    azureSubscriptions = this.azureSubscriptionsSource.asObservable();

    /**
     * Gets all azureSubscriptions for org
     */
    get(): Observable<any> {
        this.apiService
            .get('azureSubscriptions')
            .pipe(map(this._extractEmbedded))
            .subscribe((azureSubscriptions) => {
                this.azureSubscriptionsSource.next(azureSubscriptions);
            });

        return this.azureSubscriptions;
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const azureSubscriptions: AzureSubscription[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['azureSubscriptions'] !== undefined) {
            for (let i = 0; i < res['_embedded']['azureSubscriptions'].length; i++) {
                const azureSubscription = res['_embedded']['azureSubscriptions'][i];
                azureSubscriptions.push(new AzureSubscription(azureSubscription));
            }

            return azureSubscriptions;
        }

        return [];
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return 'azureSubscriptions';
    }
}
