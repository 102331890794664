import { Item } from './item';

export class ListItem {
    public id: string;
    public name: string;
    public selfLink: string;
    public type: string;
    public items: Item[] = [];
    public selected: boolean;

    constructor(id: string, name: string, selfLink?: string) {
        this.id = id;
        this.name = name;
        this.selfLink = selfLink;
    }

    push(model: any, parentId: string, type: string, id: string, label: string, selectable = false) {
        this.items.push(new Item(model, parentId, type, id, label, selectable));
    }

    clear() {
        this.items = [];
    }
}
