import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Network, NetworkGroup } from '@netfoundry-ui/shared/model';
import { ApiService, NetworkService, ValidateService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-network-form',
    templateUrl: './network-form.component.html',
    styleUrls: ['./network-form.component.scss'],
})
export class NetworkFormComponent implements OnInit, OnDestroy {
    public networkModel: Network = new Network({});
    currentOrg: NetworkGroup = new NetworkGroup({});
    currentOrgId: string;
    processing = false;
    errorNetworkName = false;
    invalidNetworkName = false;
    private subscription = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<NetworkFormComponent>,
        private networkService: NetworkService,
        private apiService: ApiService,
        private validateService: ValidateService
    ) {
        if (data['id'] !== undefined && data['id'].length > 0) {
            this.networkModel = data;
        }
    }

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrg = new NetworkGroup(org);
                this.currentOrgId = this.currentOrg.getId();
            })
        );
    }

    cancel() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    save() {
        if (this.validate()) {
            this.networkModel.organizationId = this.currentOrgId;
            this.processing = true;
            this.subscription.add(
                this.networkService.save(this.networkModel).subscribe(
                    (data) => {
                        this.networkModel = new Network(data);

                        // propagate the updated network model if we've just updated the current network
                        if (this.networkModel.getId() === this.apiService.theNetworkIs.getId()) {
                            this.apiService.setCurrentNetwork(this.networkModel);
                        }
                        this.processing = false;
                        this.dialogRef.close();
                    },
                    () => {
                        this.processing = false;
                    }
                )
            );
        }
    }

    validate() {
        this.errorNetworkName = false;
        this.invalidNetworkName = false;

        if (!this.validateService.hasValue(this.networkModel.name)) {
            this.errorNetworkName = true;
        } else if (!this.validateService.isValidNetworkName(this.networkModel.name)) {
            this.errorNetworkName = true;
            this.invalidNetworkName = true;
        }

        return !this.errorNetworkName;
    }
}
