import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Auth0Service } from '@netfoundry-ui/shared/authorization';

const skipInterceptorHeader = 'X-Skip-Interceptor';

/** Pass untouched request through to the next request handler. */
@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    constructor(public auth0Service: Auth0Service) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if the request has the skip interceptor header, remove the header and don't add the authorization headers
        // this allows us to leave off any of the headers that get added by the interceptor in cases when adding the headers would
        // cause issues with the request
        if (request.headers.get(skipInterceptorHeader)) {
            request.headers.delete(skipInterceptorHeader);
        } else {
            const token = this.auth0Service?.accessToken;
            if (token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token.accessToken}`,
                    },
                });
            }
        }
        return next.handle(request);
    }
}
