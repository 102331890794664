import { Endpoint } from './endpoint';
import { Group } from './group';
import { MopResource } from './mopresource';
import { Service } from './service';

export class AppWan extends MopResource {
    public endpoints: Endpoint[] = [];
    public groups: Group[] = [];
    public services: Service[] = [];
    public appWanType: string;

    public getSaveObject() {
        return {
            name: this.name,
            appWanType: this.appWanType,
        };
    }

    /**
     *  groupEndpoints should be a array of { groupName : groupname, endpoints: endpointsdata } objects
     * and endpointsdata in _embedded endpoints object format
     *
     */
    populateValuesInAppwanAsACode(
        gatewayTypesArray: string[],
        hostGatewaysOfService: Endpoint[],
        groupEndpoints: any[],
        geoRegionMap: any,
        dataCenterMap: any
    ): any {
        const gateways = [];
        const gatewayAsACodeMap = {};
        const clientAsACodeMap = {};
        const gatewayResources = [];
        const clientResources = [];

        const clients = [];
        for (const endpoint of this.endpoints) {
            if (gatewayTypesArray.indexOf(endpoint.getType()) > -1) {
                const geoRegion = geoRegionMap[endpoint.geoRegionId];
                const dataCenter = dataCenterMap[endpoint.dataCenterId];
                const gatewayAsACode = endpoint.convertToEndpointAsACode(geoRegion, dataCenter);
                gateways.push(gatewayAsACode);
                gatewayResources.push(gatewayAsACode);
                gatewayAsACodeMap[endpoint.getId()] = gatewayAsACode;
            } else {
                const geoRegion = geoRegionMap[endpoint.geoRegionId];
                const dataCenter = dataCenterMap[endpoint.dataCenterId];
                const clientAsACode = endpoint.convertToEndpointAsACode(geoRegion, dataCenter);
                clientAsACodeMap[endpoint.getId()] = clientAsACode;
                clients.push(clientAsACode);
                clientResources.push(clientAsACode);
            }
        }

        const services = [];
        for (const svc of this.services) {
            let serviceAsACode;
            let hostGatewayFound = false;
            for (const hostGateway of hostGatewaysOfService) {
                if (hostGateway.getId() === svc.endpointId) {
                    hostGatewayFound = true;
                    if (gatewayAsACodeMap[hostGateway.getId()]) {
                        serviceAsACode = svc.convertToServiceAsACode(gatewayAsACodeMap[hostGateway.getId()]);
                    } else {
                        const geoRegion = geoRegionMap[hostGateway.geoRegionId];
                        const dataCenter = dataCenterMap[hostGateway.dataCenterId];
                        const hostGatewayAsACode = hostGateway.convertToEndpointAsACode(geoRegion, dataCenter);
                        gatewayAsACodeMap[hostGateway.getId()] = hostGatewayAsACode;
                        serviceAsACode = svc.convertToServiceAsACode(hostGatewayAsACode);
                        gatewayResources.push(hostGatewayAsACode);
                    }

                    // remove undefined values
                    for (const property in serviceAsACode) {
                        if (serviceAsACode[property] === undefined) {
                            delete serviceAsACode[property];
                        }
                    }
                    break;
                }
            }
            if (hostGatewayFound === false) {
                serviceAsACode = svc.convertToServiceAsACode({});
            }
            services.push(serviceAsACode);
        }

        const endpointGroups = [];
        for (const epg of this.groups) {
            const endpointGroupAsACode = epg.convertToGroupAsACode();
            for (const groupEmbeddedEndpoints of groupEndpoints) {
                if (groupEmbeddedEndpoints['groupName'] === epg.name) {
                    for (const groupEndpoint of groupEmbeddedEndpoints['endpoints']['_embedded']['endpoints']) {
                        const ep = new Endpoint(groupEndpoint);

                        // populate gateways list of endpoint groups
                        if (gatewayTypesArray.indexOf(ep.getType()) > -1) {
                            if (gatewayAsACodeMap[ep.getId()]) {
                                endpointGroupAsACode.gateways.push(gatewayAsACodeMap[ep.getId()]);
                            } else {
                                const geoRegion = geoRegionMap[ep.geoRegionId];
                                const dataCenter = dataCenterMap[ep.dataCenterId];
                                const gatewayAsACode = ep.convertToEndpointAsACode(geoRegion, dataCenter);
                                gatewayAsACodeMap[ep.getId()] = gatewayAsACode;
                                endpointGroupAsACode.gateways.push(gatewayAsACode);
                                gatewayResources.push(gatewayAsACode);
                            }
                        } else {
                            // populate clients list of endpoint groups

                            if (clientAsACodeMap[ep.getId()]) {
                                endpointGroupAsACode.clients.push(clientAsACodeMap[ep.getId()]);
                            } else {
                                const geoRegion = geoRegionMap[ep.geoRegionId];
                                const dataCenter = dataCenterMap[ep.dataCenterId];
                                const clientAsACode = ep.convertToEndpointAsACode(geoRegion, dataCenter);
                                clientAsACodeMap[ep.getId()] = clientAsACode;
                                endpointGroupAsACode.clients.push(clientAsACode);
                                clientResources.push(clientAsACode);
                            }
                        }
                    }
                }
            }
            endpointGroups.push(endpointGroupAsACode);
        }

        return {
            name: this.name,
            appWanType: this.appWanType,
            resources: {
                gateways: gatewayResources,
                clients: clientResources,
            },
            members: {
                gateways: gateways,
                clients: clients,
                endpointGroups: endpointGroups,
                services: services,
            },
        };
    }
}
