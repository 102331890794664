import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('auth-policies')
export class AuthPolicy extends Resource {
    id;
    networkId;
    zitiId;
    name;
    primary = new PrimaryAuthPolicy();
    secondary = new SecondaryAuthPolicy();
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    appliedAt: string;
    deletedAt: string;
    deletedBy: string;
    deleted: string;
}

export class PrimaryAuthPolicy {
    cert = new CertificatePolicy();
    extJwt = new ExternalJWTPolicy();
    updb = new DataPolicy();
}

export class SecondaryAuthPolicy {
    requireExtJwtSigner = '';
    requireTotp = false;
}

export class ExternalJWTPolicy {
    allowed = true;
    allowedSigners: string[] = [];
}

export class CertificatePolicy {
    allowed = true;
    allowExpiredCerts = true;
}

export class DataPolicy {
    allowed = false;
    lockoutDurationMinutes = 0;
    maxAttempts = 0;
    minPasswordLength = 8;
    requireMixedCase = false;
    requireNumberChar = false;
    requireSpecialChar = false;
}
