<div (clickOutside)="close(true)" class="modal solid confirmModal open" id="TourModal">
    <div (click)="close()" class="closeButton icon-HamburgerAppClose" id="CloseConsoleTour"></div>
    <div
        (click)="previous()"
        [ngClass]="{ showing: notFirst() }"
        class="move left icon-Sort_Down"
        id="ConsoleTourPrevious"
    ></div>
    <div
        (click)="next()"
        *ngIf="notLast()"
        [ngClass]="{ showing: notLast() }"
        class="move right icon-Sort_Down"
        id="ConsoleTourNext"
    ></div>
    <div class="grid tour">
        <div>
            <div class="rows">
                <div class="row">
                    <div [ngClass]="{ showing: index === 1 }" class="screen text">
                        <div class="title">Welcome to the NetFoundry Console!</div>
                        <div class="contents">
                            Let us show you around. We'll walk you through navigating the console and where different
                            functions are located. Tap next to started or use the arrows to jump forward or back.
                        </div>
                    </div>
                    <div [ngClass]="{ showing: index === 2 }" class="screen text">
                        <div class="title">Functional Areas of the Console</div>
                        <div class="contents">
                            The console's main navigation is divided into two logical groups: Network specific
                            functions; user/organizational orientated tasks.
                        </div>
                    </div>
                    <div [ngClass]="{ showing: index === 3 }" class="screen text">
                        <div class="title">Network Menu</div>
                        <div class="contents">
                            Located in the upper left of the window the network menu is your source to navigate all
                            functions of your selected network from the dashboard and routers to services and network
                            events.
                        </div>
                    </div>
                    <div [ngClass]="{ showing: index === 4 }" class="screen text">
                        <div class="title">Mission Control for your Network</div>
                        <div class="contents">
                            Your network dashboard is the single pane of glass for all functions of your network. View
                            data such as network events, data utilization, top endpoint usage, alarm history and more.
                        </div>
                    </div>
                    <div [ngClass]="{ showing: index === 5 }" class="screen text">
                        <div class="title">Manage Your Networks</div>
                        <div class="contents">
                            At the top of the network menu is the network dropdown used to jump between networks or add
                            new ones. Quick jump to the manage networks section using the link at the bottom of the
                            dropdown.
                        </div>
                    </div>
                    <div [ngClass]="{ showing: index === 6 }" class="screen text">
                        <div class="title">Add From Anywhere</div>
                        <div class="contents">
                            Use the plus symbol under the Network Icon to add relevant network features from anywhere in
                            the Console. Add a New Network, AppWAN (Service Policies), Service, Endpoints, or Endpoint
                            Group with just a tap.
                        </div>
                    </div>
                    <div [ngClass]="{ showing: index === 7 }" class="screen text">
                        <div class="title">Organization Menu</div>
                        <div class="contents">
                            Located in the lower left just above the user icon the org menu is your source to manage all
                            functions of your selected organization from managing users and API accounts to viewing
                            organization events and org settings.
                        </div>
                    </div>
                    <div [ngClass]="{ showing: index === 8 }" class="screen text">
                        <div class="title">Notifications, Support, and User Menus</div>
                        <div class="contents">
                            All user or organizational specific management features are located in the upper right of
                            the window. Use the support, notification, and user menus to do things like get help, review
                            notification history, or edit user preferences.
                        </div>
                    </div>
                    <div [ngClass]="{ showing: index === 9 }" class="screen text">
                        <div class="title">Support is always just a tap away</div>
                        <div class="contents">
                            In the console, help, and documentation is always there. It can be accessed at any time via
                            the support menu to take advantage of features like inline help and documentation, pages
                            tours, or submitting tickets.
                        </div>
                    </div>
                    <div [ngClass]="{ showing: index === 10 }" class="screen text">
                        <div class="title">
                            Now that you know how to navigate around... Let’s Take Your Shiny New Network for Spin
                        </div>
                        <div class="contents">
                            Time to push some packets. We’ll take care of setting everything up. Experience your new
                            Zero Trust Secure Network in just a few quick steps.
                        </div>
                    </div>
                </div>
                <div
                    [ngClass]="{
                        'not-provisioned': index === 10 && currentNetwork && currentNetwork.status !== 'PROVISIONED'
                    }"
                    class="buttonRow"
                >
                    <div (click)="next()" class="button square">
                        <span *ngIf="index !== 10" id="ConsoleTourNextButton">Next</span>
                        <span (click)="goToSampleService()" *ngIf="index === 10" id="ConsoleTourCloseButton">
                            {{
                                currentNetwork && currentNetwork.status === 'PROVISIONED'
                                    ? 'Try The Demo'
                                    : 'GO TO QUICK START'
                            }}
                        </span>
                    </div>
                    <div (click)="goto(1)" [ngClass]="{ showing: index === 1 }" class="dot"></div>
                    <div (click)="goto(2)" [ngClass]="{ showing: index === 2 }" class="dot"></div>
                    <div (click)="goto(3)" [ngClass]="{ showing: index === 3 }" class="dot"></div>
                    <div (click)="goto(4)" [ngClass]="{ showing: index === 4 }" class="dot"></div>
                    <div (click)="goto(5)" [ngClass]="{ showing: index === 5 }" class="dot"></div>
                    <div (click)="goto(6)" [ngClass]="{ showing: index === 6 }" class="dot"></div>
                    <div (click)="goto(7)" [ngClass]="{ showing: index === 7 }" class="dot"></div>
                    <div (click)="goto(8)" [ngClass]="{ showing: index === 8 }" class="dot"></div>
                    <div (click)="goto(9)" [ngClass]="{ showing: index === 9 }" class="dot"></div>
                    <div (click)="goto(10)" [ngClass]="{ showing: index === 10 }" class="dot"></div>
                </div>
            </div>
        </div>
        <div>
            <div class="screenImages">
                <div
                    [ngClass]="{ showing: index === 1 }"
                    class="screen image"
                    style="background-image: url(/assets/images/tour/screen1.png)"
                ></div>
                <div
                    [ngClass]="{ showing: index === 2 }"
                    class="screen image"
                    style="background-image: url(/assets/images/tour/screen2{{
                        featureService?.disableNewNavigation ? '_legacy' : ''
                    }}.png)"
                ></div>
                <div
                    [ngClass]="{ showing: index === 3 }"
                    class="screen image"
                    style="background-image: url(/assets/images/tour/screen3{{
                        featureService?.disableNewNavigation ? '_legacy' : ''
                    }}.png)"
                ></div>
                <div
                    [ngClass]="{ showing: index === 4 }"
                    class="screen image"
                    style="background-image: url(/assets/images/tour/screen4.png)"
                ></div>
                <div
                    [ngClass]="{ showing: index === 5 }"
                    class="screen image"
                    style="background-image: url(/assets/images/tour/screen5.png)"
                ></div>
                <div
                    [ngClass]="{ showing: index === 6 }"
                    class="screen image"
                    style="background-image: url(/assets/images/tour/screen6.png)"
                ></div>
                <div
                    [ngClass]="{ showing: index === 7 }"
                    class="screen image"
                    style="background-image: url(/assets/images/tour/screen7.png)"
                ></div>
                <div
                    [ngClass]="{ showing: index === 8 }"
                    class="screen image"
                    style="background-image: url(/assets/images/tour/screen8.png)"
                ></div>
                <div
                    [ngClass]="{ showing: index === 9 }"
                    class="screen image"
                    style="background-image: url(/assets/images/tour/screen9.png)"
                ></div>
                <div
                    [ngClass]="{ showing: index === 10 }"
                    class="screen image"
                    style="background-image: url(/assets/images/tour/screen10.png)"
                >
                    <div (click)="open('#')" class="hiddenLink pos1"></div>
                </div>
            </div>
        </div>
    </div>
</div>
