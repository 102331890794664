import { Component, HostListener, Inject, OnInit, ViewChild, ɵComponentType } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    AttributesService,
    AttributesServiceResults,
    EdgeRouterPolicyServiceV2,
    PlatformServiceService,
    ServiceServiceV2,
} from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    ConfigType,
    ENDPOINT_DIALOG,
    GenericService,
    NetworkV2,
    PagedAttributes,
    ROUTER_DIALOG,
} from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, LoggerService, ValidateService } from '@netfoundry-ui/shared/services';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import _ from 'lodash';
import * as uuid from 'uuid';
import { JsonViewComponent } from '@netfoundry-ui/ui/json-view';
import { ZitiOptionsService } from '@netfoundry-ui/shared/helpers';
import { Router } from '@angular/router';
import { PagedGetOption } from '@lagoshny/ngx-hateoas-client';
import { MetricsModalComponent } from '@netfoundry-ui/feature/metrics-modal';
import { DialLogsComponent } from '@netfoundry-ui/feature/dial-logs';

@Component({
    selector: 'app-advanced-service-config-form',
    templateUrl: './advanced-service-config-form.component.html',
    styleUrls: ['./advanced-service-config-form.component.scss'],
    providers: [FromDatePipe],
})
export class AdvancedServiceConfigFormComponent implements OnInit {
    @ViewChild(JsonViewComponent, { static: false }) editor: JsonViewComponent;

    public model: any = {
        prevName: 'ServiceName',
        attributes: [],
        model: {
            servicePolicy: {
                endpointAttributes: [],
            },
            serviceEdgeRouterPolicy: {
                edgeRouterAttributes: [],
            },
        },
    };
    invalidWords = ['all'];
    regions: Location[] = [];
    isComplete = false;
    processing = false;
    hideHelp = false;
    isInline = false;
    serviceAttributes = new PagedAttributes();
    edgeRouterAttributes = new PagedAttributes();
    endpointAttributes = new PagedAttributes();
    currentNetwork: NetworkV2;
    titles = ['Host', 'Options', 'Health Checks'];
    errors = {
        name: [],
        configs: {},
    };
    configTypesInit = false;
    configsInit = false;
    configsAdded = false;
    isEditing = false;
    canEdit = true;
    isNew = true;
    networkGroupId;
    bulkEdit = false;
    addedConfigs: any = [];
    serviceConfigs: any = [];

    activeTitle = 'Host';
    selectedConfigTypeId;
    selectedConfigId;
    selectedConfig;
    selectedConfigType;
    configSchema;
    configErrors: any = {};
    configTypes = [];
    configs = [];
    displayConfigs = [];
    displayConfigTypes = [];
    isFormView = false;

    isEdit = false;
    assocEndpointNames = [];
    endpointMap = {};
    assocRouterNames = [];
    routerMap = {};
    isLoading: boolean;
    isLoadingAssocRouters: boolean;
    isLoadingAssocEndpoints: boolean;
    isLoadingSvcAttr: boolean;
    svcError: boolean;
    isLoadingEndpointAttr: boolean;
    isLoadingEdgeRouterAttr: boolean;
    edgeRouterError: boolean;
    endpointAttributeError: boolean;
    selectedEndpointAttributes: any;
    edgeRoutersList: any;
    selectedEdgeRouterAttributes: PagedAttributes;
    selectedServiceAttributes: PagedAttributes;

    constructor(
        private logger: LoggerService,
        private dialogRef: MatDialogRef<AdvancedServiceConfigFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private serviceService: ServiceServiceV2,
        private edgeRouterPolicyServiceV2: EdgeRouterPolicyServiceV2,
        private apiService: ApiService,
        private validateService: ValidateService,
        private growlerService: GrowlerService,
        private authorizationService: AuthorizationService,
        private platformServiceService: PlatformServiceService,
        private optionsService: ZitiOptionsService,
        public featureService: FeatureService,
        private router: Router,
        private attributeService: AttributesService,
        public dialogForm: MatDialog,
        @Inject(ENDPOINT_DIALOG) private endpointDialog: ɵComponentType<any>,
        @Inject(ROUTER_DIALOG) private routerDialog: ɵComponentType<any>
    ) {}

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
        this.hide();
    }

    ngOnInit() {
        this.initModel();
        this.currentNetwork = this.apiService.currentNetwork.getValue();
        if (this.currentNetwork) {
            this.model.networkId = this.currentNetwork.id;
            this.networkGroupId = this.currentNetwork.networkGroupId;
            this.isLoading = true;
            this.getConfigs();
            this.getConfigTypes();
            this.initializeEndpointSelector();
            this.initializeEdgeRouterSelector();
            this.initializeServiceAttributeSelector();
            if (this.model.id) {
                this.findAssociatedEntities();
            }
        }

        if (this.bulkEdit) {
            this.validate();
        }
    }

    initModel() {
        if (this.data.model) {
            this.model = this.data.model;
            this.bulkEdit = this.data.bulkEdit;
            this.canEdit = this.authorizationService.canUpdateService(this.model.id);

            this.isNew = false;
            _.set(
                this.model,
                'model.servicePolicy.endpointAttributes',
                _.get(this.model, 'model.servicePolicy.endpointAttributes', [])
            );
            _.set(
                this.model,
                'serviceEdgeRouterPolicy.edgeRouterAttributes',
                _.get(this.model, 'model.serviceEdgeRouterPolicy.edgeRouterAttributes', [])
            );
            this.isEditing = !this.bulkEdit;
        } else {
            this.model.encryptionRequired = true;
            _.set(this.model, 'model.servicePolicy.endpointAttributes', []);
            _.set(this.model, 'model.serviceEdgeRouterPolicy.edgeRouterAttributes', []);
            this.model.attributes = [];
            this.model.configIdByConfigTypeId = undefined;
            this.isNew = true;
        }
        if (!this.model.name) {
            this.model.name = '';
        }
        if (!this.model.attributes) {
            this.model.attributes = [];
        }
    }

    getConfigTypes() {
        const options = this.getOptions(this.currentNetwork);
        this.currentNetwork.findRelatedPromise<ConfigType>('config-types', options).then((configTypes) => {
            this.configTypes = configTypes;
            this.configTypesInit = true;
            if (this.isNew) {
                const hostV1 = this.configTypes.find((configType) => configType.name === 'host.v1');
                const interceptV1 = this.configTypes.find((configType) => configType.name === 'intercept.v1');
                this.addedConfigs.push({
                    name: 'ServiceName-' + interceptV1.name,
                    type: interceptV1,
                    selectedConfigTypeId: interceptV1.id,
                    selectedConfigId: 'new_config',
                    config: {},
                    data: {},
                    schema: interceptV1.schema,
                    invalid: false,
                    id: uuid.v4(),
                    isNew: true,
                });
                this.addedConfigs.push({
                    name: 'ServiceName-' + hostV1.name,
                    type: hostV1,
                    config: {},
                    data: {},
                    selectedConfigTypeId: hostV1.id,
                    selectedConfigId: 'new_config',
                    schema: hostV1.schema,
                    invalid: false,
                    id: uuid.v4(),
                    isNew: true,
                });
                this.setDisplayConfigs();
                this.filterConfigTypes();
            } else {
                this.filterConfigTypes();
                if (this.isEditing && this.configsInit && !this.configsAdded) {
                    this.initAddedConfigs();
                }
            }
        });
    }

    getConfigs() {
        const options = this.getOptions(this.currentNetwork);
        this.currentNetwork.findRelatedPromise<ConfigType>('configs', options).then((configs) => {
            this.configs = configs;
            this.configsInit = true;
            this.setDisplayConfigs();
            if (!this.isNew && this.isEditing && this.configTypesInit && !this.configsAdded) {
                this.initAddedConfigs();
            }
        });
    }

    initAddedConfigs() {
        this.platformServiceService.getConfigsByServiceId(this.model.id).subscribe((data) => {
            this.serviceConfigs = data[0].configs;
            this.addedConfigs = this.serviceConfigs.map((config) => {
                const configTypeSource = this.configTypes.find((configType) => config.configTypeId === configType.id);
                return {
                    name: config.name,
                    type: configTypeSource,
                    config: config,
                    selectedConfigTypeId: configTypeSource.id,
                    selectedConfigId: config.id,
                    data: config.data,
                    schema: configTypeSource.schema,
                    invalid: false,
                    id: uuid.v4(),
                };
            });
            this.setDisplayConfigs();
            this.configsAdded = true;
        })

    }

    getMetrics() {
        this.dialogForm.open(MetricsModalComponent, {
            data: {
                resourceType: 'service',
                model: this.model,
                networkGroupId: this.networkGroupId,
                networkId: this.currentNetwork.id,
            },
            height: '800px',
            width: '1200px',
            autoFocus: false,
        });
    }

    getDialLogs() {
        this.dialogForm.open(DialLogsComponent, {
            data: {
                resourceType: 'service',
                model: this.model,
                networkGroupId: this.networkGroupId,
                networkId: this.currentNetwork.id,
            },
            height: '800px',
            width: '1000px',
            autoFocus: false,
        });
    }

    setDisplayConfigs() {
        const selectedConfigTypeIds = [];
        this.addedConfigs.forEach((config) => {
            config.displayConfigs = this.configs.filter((cfg) => cfg.configTypeId === config.selectedConfigTypeId);
            selectedConfigTypeIds.push(config.type.id);
        });
        this.displayConfigTypes = this.configTypes.filter(
            (configType) => !selectedConfigTypeIds.includes(configType.id)
        );
    }

    configTypeChanged(addedConfig) {
        addedConfig.type = this.configTypes.find((cfg) => cfg.id === addedConfig.selectedConfigTypeId);
        addedConfig.name = (this.model.name || 'ServiceName') + '-' + addedConfig.type.name;
        addedConfig.selectedConfigId = addedConfig.selectedConfigId || 'new_config';
        this.setDisplayConfigs();
        this.filterConfigTypes();
    }

    configChanged(addedConfig) {
        if (addedConfig.selectedConfigId === 'new_config') {
            addedConfig.config = {
                data: {},
            };
            addedConfig.data = {};
            return;
        }
        addedConfig.config = this.configs.find((cfg) => cfg.id === addedConfig.selectedConfigId);
        addedConfig.data = _.get(addedConfig, 'config.data');
    }

    filterConfigTypes() {
        this.configTypes.forEach((configType) => {
            configType.hidden = this.addedConfigs.some((config) => config.type.id === configType.id);
        });
    }

    public getOptions(currentNetwork, embedAll = false): PagedGetOption {
        if (!currentNetwork) {
            return {};
        }
        const params = { networkId: currentNetwork.id };
        if (embedAll) {
            params['embed'] = 'all';
        }
        return { params };
    }

    hide() {
        this.dialogRef.close({ newClient: this.model });
    }

    addServiceConfig() {
        const configToAdd = {
            name: '',
            type: {},
            config: {},
            data: {},
            schema: {},
            invalid: false,
            id: uuid.v4(),
            selectedConfigTypeId: undefined,
            selectedConfigId: undefined,
            isNew: true,
        };
        this.addedConfigs.push(configToAdd);
        this.filterConfigTypes();
        this.selectedConfigTypeId = undefined;
        this.selectedConfigId = undefined;
        this.selectedConfigType = undefined;
        this.selectedConfig = undefined;
    }

    validateConfigToAdd() {
        this.configErrors = {};
        if (_.isEmpty(this.selectedConfigTypeId)) {
            this.configErrors.configType = true;
        }
        if (_.isEmpty(this.selectedConfigId)) {
            this.configErrors.config = true;
        }
        return _.isEmpty(this.configErrors);
    }

    removeServiceConfig(index) {
        this.addedConfigs.splice(index, 1);
        this.filterConfigTypes();
    }

    toggleEncryption() {
        //TODO: toggle encryption
    }

    addEndpointsAttributes(newAttribute) {
        const success = this.attributeService.addAttributes(
            newAttribute,
            this.invalidWords,
            this.selectedEndpointAttributes
        );

        if (success) this.selectedEndpointAttributes = success as PagedAttributes;
        else this.endpointAttributeError = true;
    }

    addEdgeRouterAttribute(newAttribute) {
        const success = this.attributeService.addAttributes(
            newAttribute,
            this.invalidWords,
            this.selectedEdgeRouterAttributes
        );

        if (success) this.selectedEdgeRouterAttributes = success as PagedAttributes;
        else this.edgeRouterError = true;
    }

    addServiceAttribute(newAttribute) {
        const success = this.attributeService.addAttributes(
            newAttribute,
            this.invalidWords,
            this.selectedServiceAttributes
        );

        if (success) this.selectedServiceAttributes = success as PagedAttributes;
        else this.svcError = true;
    }

    removeServiceAttribute(oldAttribute) {
        this.selectedServiceAttributes = this.attributeService.removeAttribute(
            oldAttribute,
            this.selectedServiceAttributes
        );
    }

    removeEndpointAttribute(oldAttribute) {
        this.selectedEndpointAttributes = this.attributeService.removeAttribute(
            oldAttribute,
            this.selectedEndpointAttributes
        );
    }

    removeEdgeRouterAttribute(oldAttribute) {
        this.selectedEdgeRouterAttributes = this.attributeService.removeAttribute(
            oldAttribute,
            this.selectedEdgeRouterAttributes
        );
    }

    save() {
        if (this.validate()) {
            const genericService: GenericService = new GenericService();
            genericService.name = this.model.name;
            genericService.id = this.model.id;
            genericService.encryptionRequired = this.model.encryptionRequired;
            genericService.zitiId = this.model.zitiId;
            genericService.networkId = this.model.networkId;
            genericService.attributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
            genericService.modelType = 'Generic';
            genericService.model = {};
            genericService.model.configTypes = null;
            genericService.model.configs = this.addedConfigs.map((config) => ({
                configId: null,
                name: config.name,
                configTypeName: config.type.name,
                data: typeof config.data === 'string' ? JSON.parse(config.data) : config.data,
            }));
            genericService.model.servicePolicy = {
                name: this.model.name + '-ServicePolicy',
                type: 'Bind',
                semantic: 'AnyOf',
                endpointAttributes: Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys()), //_.get(this.model, 'model.servicePolicy.endpointAttributes', []),
                postureCheckAttributes: [],
            };
            genericService.model.serviceEdgeRouterPolicy = {
                name: this.model.name + 'ServiceEdgeRouterPolicy',
                semantic: 'AnyOf',
                edgeRouterAttributes: Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys()), //_.get(this.model, 'model.serviceEdgeRouterPolicy.edgeRouterAttributes', []),
            };
            // If no service edge router policy attributes are defined, default with #all
            if (_.isEmpty(genericService?.model?.serviceEdgeRouterPolicy?.edgeRouterAttributes)) {
                _.set(genericService, 'model.serviceEdgeRouterPolicy.edgeRouterAttributes', ['#all']);
            }
            genericService.model.terminators = null;
            genericService['_links'] = this.model._links;
            if (this.isEditing) {
                this.platformServiceService.patchResource(genericService).subscribe(
                    (data) => {
                        this.logger.info('Create service response: ', data);
                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Update Complete',
                                'Service update process started successfully.<a href="' +
                                    this.router.createUrlTree(['/process-executions']).toString() +
                                    '">Click here to find out more</a>'
                            )
                        );
                        this.processing = false;
                        this.dialogRef.close();
                    },
                    (httpErrorResponse) => {
                        this.processing = false;
                        this.logger.error('Error from service creation', httpErrorResponse);
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Service creation request failed. ',
                                httpErrorResponse.error.errors[0]
                            )
                        );
                    }
                );
            } else {
                this.platformServiceService.createResource({ body: genericService }).subscribe(
                    (data) => {
                        this.logger.info('Create service response: ', data);
                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Creation Complete',
                                'Service creation process started successfully.<a href="' +
                                    this.router.createUrlTree(['/process-executions']).toString() +
                                    '">Click here to find out more</a>'
                            )
                        );
                        this.processing = false;
                        this.dialogRef.close();
                    },
                    (httpErrorResponse) => {
                        this.processing = false;
                        this.logger.error('Error from service creation', httpErrorResponse);
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Service creation request failed. ',
                                httpErrorResponse.error.errors[0]
                            )
                        );
                    }
                );
            }
        }
    }

    validate() {
        let isValid = true;
        this.errors = {
            name: [],
            configs: [],
        };

        this.validateName();
        this.validateConfigs();

        const nameValid = this.errors['name'].length <= 0;
        const configsValid = _.isEmpty(this.errors['configs']);

        isValid = nameValid && configsValid;

        if (!isValid) {
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Service Configuration Invalid',
                    'Your service configuration is invalid. Please check input values and try again.'
                )
            );
        }
        return isValid;
    }

    nameChanged() {
        this.addedConfigs.forEach((config) => {
            if (config.name.indexOf(this.model.prevName) === 0) {
                config.name = config.name.replace(this.model.prevName, this.model.name);
            }
        });
        this.model.prevName = !_.isEmpty(this.model.name) ? this.model.name : 'ServiceName';
        if (_.isEmpty(this.model.name) || this.model.prevName === 'ServiceName') {
            this.addedConfigs.forEach((config) => {
                if (config.name.indexOf('-' + config.type.name) === 0) {
                    config.name = this.model.prevName + config.name;
                }
            });
        }
    }

    validateName() {
        this.errors['name'] = [];

        if (!this.validateService.isValidZitiName(this.model.name)) {
            this.errors['name'].push({
                message:
                    'Name must be at least 5 characters long and cannot contain any special characters except for _-()',
            });
        }
    }

    validateConfigs() {
        this.errors.configs = {};
        this.addedConfigs.forEach((config) => {
            if (config.invalid) {
                _.set(this.errors, `configs[${config.id}].jsonInvalid`, true);
            }
            if (!this.validateService.isValidZitiName(config.name)) {
                _.set(this.errors, `configs[${config.id}].nameInvalid`, true);
            }
        });
    }

    get disableEncryptionToggle() {
        return this.isEditing || !this.featureService.canDisabledServiceEncryption(this.currentNetwork);
    }

    toggleJsonView() {
        this.isFormView = !this.isFormView;
    }

    selectActiveTab(selectedTab) {
        this.activeTitle = selectedTab;
    }

    endpointSelected(name: string) {
        const service = this.endpointMap[name];
        const dialogRef = this.dialogForm.open(this.endpointDialog, {
            data: { model: service, inline: true },
            minHeight: '93%',
            minWidth: '100%',
            height: '93%',
            width: '100%',
        });
        dialogRef.afterClosed().subscribe(() => {});
    }

    routerSelected(name: string) {
        const router = this.routerMap[name];
        const dialogRef = this.dialogForm.open(this.routerDialog, {
            data: { model: router, inline: true },
            minHeight: '93%',
            minWidth: '100%',
            height: '93%',
            width: '100%',
        });
        dialogRef.afterClosed().subscribe(() => {});
    }

    findAssociatedEntities() {
        this.isLoadingAssocRouters = true;

        this.serviceService
            .findAssociatedRouters(this.model.id)
            .then((recs) => {
                this.assocRouterNames = recs.map((s) => s.name);
                recs.forEach((rec) => {
                    this.routerMap[rec.name] = rec;
                });
            })
            .finally(() => {
                this.isLoadingAssocRouters = false;
            });
        this.isLoadingAssocEndpoints = false;
        this.serviceService
            .findAssociatedEndpoints(this.model.id)
            .then((recs) => {
                this.assocEndpointNames = recs.map((s) => s.name);
                recs.forEach((rec) => {
                    this.endpointMap[rec.name] = rec;
                });
            })
            .finally(() => {
                this.isLoadingAssocEndpoints = false;
            });
    }

    private initializeServiceAttributeSelector() {
        this.isLoadingSvcAttr = true;
        this.getSelectedServiceAttributes();
        this.svcError = false;
        this.attributeService
            .getServiceAttributes(this.currentNetwork, true)
            .then((results: AttributesServiceResults) => {
                this.serviceAttributes = results.groupAttributes;
            })
            .catch((err) => {
                this.svcError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingSvcAttr = false;
                this.isLoading = this.isLoadingEndpointAttr || this.isLoadingSvcAttr || this.isLoadingEdgeRouterAttr;
            });
    }

    private getSelectedServiceAttributes() {
        const selectedServices = new PagedAttributes();
        this.model.attributes.forEach((att) => {
            selectedServices.mappedAtrributes.set(att, {
                name: att,
                isGroup: att.charAt(0) === '#',
                isNamed: att.charAt(0) === '@',
            });
        });
        this.selectedServiceAttributes = selectedServices;
    }

    private initializeEdgeRouterSelector() {
        this.isLoadingEdgeRouterAttr = true;
        this.getSelectedEdgeRouters();
        this.edgeRouterError = false;
        this.attributeService
            .getEdgeRouterAttributes(this.currentNetwork, false)
            .then((results: AttributesServiceResults) => {
                const collector = results.namedAttributes;
                for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                    collector.mappedAtrributes.set(sa.name, sa);
                }
                this.edgeRouterAttributes = collector;
            })
            .catch((err) => {
                this.edgeRouterError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingEdgeRouterAttr = false;
                this.isLoading = this.isLoadingEndpointAttr || this.isLoadingSvcAttr || this.isLoadingEdgeRouterAttr;
            });
    }

    private getSelectedEdgeRouters() {
        const selectedEdgeRouters = new PagedAttributes();
        this.model.model.serviceEdgeRouterPolicy.edgeRouterAttributes.forEach((att) => {
            selectedEdgeRouters.mappedAtrributes.set(att, {
                name: att,
                isGroup: att.charAt(0) === '#',
                isNamed: att.charAt(0) === '@',
            });
        });
        this.selectedEdgeRouterAttributes = selectedEdgeRouters;
    }

    private initializeEndpointSelector() {
        this.isLoadingEndpointAttr = true;
        this.getSelectedEndpoints();
        this.endpointAttributeError = false;
        this.attributeService
            .getEndpointAttributes(this.currentNetwork)
            .then((results: AttributesServiceResults) => {
                const collector = results.namedAttributes;
                for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                    collector.mappedAtrributes.set(sa.name, sa);
                }
                this.endpointAttributes = collector;
            })
            .catch((err) => {
                this.endpointAttributeError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingEndpointAttr = false;
                this.isLoading = this.isLoadingEndpointAttr || this.isLoadingSvcAttr || this.isLoadingEdgeRouterAttr;
            });
    }

    private getSelectedEndpoints() {
        const selectedEndpoints = new PagedAttributes();
        this.model.model.servicePolicy.endpointAttributes.forEach((att) => {
            selectedEndpoints.mappedAtrributes.set(att, {
                name: att,
                isGroup: att.charAt(0) === '#',
                isNamed: att.charAt(0) === '@',
            });
        });
        this.selectedEndpointAttributes = selectedEndpoints;
    }
}
