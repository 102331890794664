import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { LoggerService } from './logger.service';
import { HTTP_CLIENT } from './token.service';

@Injectable({ providedIn: 'root' })
export class BillingV2Service {
    apiUrl;

    networkPricing = 199.0;
    regionPricing = [{ region: null, price: 0.2, rate: 'GB' }];

    constructor(private logger: LoggerService, @Inject(HTTP_CLIENT) private http: HttpClient) {}

    /**
     * Generic GETter
     */

    public get(path: string) {
        const fullpath = `${this.apiUrl}${path}`;

        return this.http
            .get(fullpath, { headers: this.setHeaders() })
            .pipe(catchError((error) => this.handleError(this, error)));
    }

    /**
     * @param path
     * @param data
     */

    public save(path: string, data) {
        const fullpath = `${this.apiUrl}${path}`;
        const body = data;

        return this.http
            .post(fullpath, JSON.stringify(body), { headers: this.setHeaders() })
            .pipe(catchError((error) => this.handleError(this, error)));
    }

    public update(path: string, data) {
        const fullpath = `${this.apiUrl}${path}`;
        const body = data;

        return this.http
            .put(fullpath, JSON.stringify(body), { headers: this.setHeaders() })
            .pipe(catchError((error) => this.handleError(this, error)));
    }

    delete(path): Observable<any> {
        const fullpath = `${this.apiUrl}${path}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            let options = {};

            if (path) {
                options = {
                    headers: this.setHeaders().append('Content-Type', 'application/json'),
                    responseType: 'text',
                };
            }

            return this.http.delete(fullpath, options).pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: any, error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            this.logger.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            this.logger.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        scope.lastErrorSource.next(error);
        return observableThrowError(error);
    }

    /**
     * Return headers
     */
    private setHeaders(): HttpHeaders {
        let headers;

        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        const valid = new Date().getTime() < expiresAt;
        if (localStorage.getItem('access_token') && valid) {
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
        } else {
            headers = new HttpHeaders();
        }

        return headers.append('Content-Type', 'application/json');
    }
}

export interface User {
    id: number;
    username: string;
}
