import {Component, Inject, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { ApiService, ErrorHistoryService, LoggerService } from '@netfoundry-ui/shared/services';
import moment from 'moment';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import {
  Environment,
  ENVIRONMENT,
  EVENT_FIELDS,
  EventFields,
  EventTemplateQueryParameter
} from '@netfoundry-ui/shared/model';

@Component({
    selector: 'app-network-metrics-events-dash',
    templateUrl: './network-metrics-events-dash.component.html',
    styleUrls: ['./network-metrics-events-dash.component.scss'],
    providers: [ElasticsearchService],
})
export class NetworkMetricsEventsDashComponent implements OnChanges, OnDestroy, OnInit {
    @Input() componentId = '';
    @Input() dateFilter: any = '24h';
    @Input() endTime = Date.now();
    @Input() networkId: any = '';
    @Input() endpointId = '';
    @Input() edgeRouterId = '';
    @Input() endpointNameFilter = false;
    @Input() searchFilter: boolean | string = false;
    public eventData: any[] = [];
    public isLoading = false;
    canReadElasticSearch = true;
    currentOrgId = '';
    noData = true;
    private errorMessage = '';
    private query: any = {};
    private subscription: Subscription = new Subscription();
    private initialized = false;

    constructor(
        private logger: LoggerService,
        private authorizationService: AuthorizationService,
        private elasticsearch: ElasticsearchService,
        private apiService: ApiService,
        private errorHistoryService: ErrorHistoryService,
        @Inject(EVENT_FIELDS) private eventFields: EventFields,
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrgId = org.getId();

                this.initialized = true;
                this.logger.info('Network Event timeline initialized');
                this.ngOnChanges();
            })
        );
    }

    ngOnChanges() {
        if (!this.initialized || this.networkId === null || this.currentOrgId === null) {
            return;
        }

        this.canReadElasticSearch = this.authorizationService.canReadElasticSearch(this.currentOrgId, this.networkId);

        if (this.canReadElasticSearch) {
            this.getTimelineData(this.networkId);
        } else {
            this.isLoading = false;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    isNewDate(i: any) {
        if (i === 0) {
            return true;
        } else {
            const momentA: moment.Moment = moment(this.eventData[i - 1]['@timestamp']);
            const momentB: moment.Moment = moment(this.eventData[i]['@timestamp']);
            if (momentA.startOf('day').diff(momentB.startOf('day'), 'days') !== 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    formatDate(theDate: any) {
        return moment.utc(theDate).local().format('MMMM D, YYYY');
    }

    formatTime(theDate: any) {
        return moment.utc(theDate).local().format('hh:mma');
    }

    public getQuery() {
        const model: any = {
            query: {
                bool: {
                    must: [
                        {
                            match_all: {},
                        },
                        {
                            match_phrase: {
                                organizationId: {
                                    query: this.currentOrgId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                network_id: {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            range: {
                                timestamp: {
                                    gte: 'now-' + this.dateFilter,
                                    lte: 'now',
                                    format: 'epoch_millis',
                                },
                            },
                        },
                    ],
                },
            },
            size: 500,
            sort: [
                {
                    '@timestamp': {
                        order: 'desc',
                        unmapped_type: 'boolean',
                    },
                },
            ],
            _source: {
                excludes: ['geo', 'tags'],
            },
        };
        if (!_.isEmpty(this.endpointId)) {
            const search = { match_phrase: {} };
            _.set(search, `match_phrase.${this.eventFields.identityId}`, this.endpointId);
            model.query.bool.must.push(search);
        }
        if (!_.isEmpty(this.edgeRouterId)) {
            const search = { match_phrase: {} };
            _.set(search, `match_phrase.${this.eventFields.edgeRouterId}`, this.edgeRouterId);
            model.query.bool.must.push(search);
        }
        return model;
    }

    /**
     *
     * @param eventSeverity
     * @returns {any}
     */
    getSeverityClass(eventSeverity: any) {
        return eventSeverity;
    }

    /**
     *
     * @param entityType
     * @returns {any}
     */
    getEntityClass(entityType: any) {
        return entityType;
    }

    getEventTypeClass(eventType: any) {
        return eventType;
    }

    trackByTimestamp(index: any, item: any) {
        return item.Timestamp;
    }

    /**
     * Get timeline events from the Network controller
     */
    private getTimelineData(networkId: string) {
        if (networkId === null) {
            return;
        }

        if (this.currentOrgId === null) {
            console.warn('No org specified');
            this.isLoading = false;
            return;
        }

        this.isLoading = true;

        const index = 'ncevents';

        this.noData = true;
        this.isLoading = true;
        this.logger.info('Network Events Timeline query', JSON.stringify(this.getQuery()));
        //reporting
        let componentName = '';
        let componentValue = '';
        if (!_.isEmpty(this.endpointId)) {
            componentName = this.eventFields.identityId;
            componentValue = this.endpointId;
        }
        if (!_.isEmpty(this.edgeRouterId)) {
            componentName = this.eventFields.edgeRouterId;
            componentValue = this.edgeRouterId;
        }
        const eventTemplateParams: EventTemplateQueryParameter = {
            indexName: index,
            networkGroupId: this.currentOrgId,
            networkId: this.networkId,
            gte: 'now-' + this.dateFilter,
            lte: 'now',
            size: '500',
            componentName: componentName,
            componentValue: componentValue,
        };
        this.logger.info(
            'Network Events Timeline Temlate: ncevents_date_time_range_tmpl',
            JSON.stringify(eventTemplateParams)
        );
        this.subscription.add(
            //this.elasticsearch.search(this.currentOrgId, index, this.getQuery()).subscribe((data) => {
            this.elasticsearch
                .apiTemplateSearch('ncevents_date_time_range_tmpl', eventTemplateParams)
                .subscribe((data) => {
                    this.eventData = this.elasticsearch.hitsToArray(data, 'network-event-timeline').filter((data) => {
                        if (!data[this.eventFields.edgeRouterName] && !data[this.eventFields.identityName]) {
                            return false;
                        }
                        return true;
                    });
                    this.isLoading = false;
                    this.logger.info('networkevents data', this.eventData);
                }, () => {
                    this.isLoading = false;
                })
        );
    }
}
