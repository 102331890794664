<div
    [ngStyle]="{ height: height }"
    class="formList"
    data-to="SelectedEndpoints"
    id="Pickbox_{{ resourceType }}_{{ identifier }}"
>
    <app-container-loader [isLoading]="isLoading"></app-container-loader>

    <!-- use this box for the original LARGE list -->
    <cdk-virtual-scroll-viewport
        *ngIf="scrollable"
        class="scrollable-viewport"
        id="scrollable_{{ identifier }}"
        itemSize="36"
    >
        <div
            (click)="addToSelected(item)"
            *cdkVirtualFor="
                let item of items | sortby: sortField | textSearch: searchField:searchString;
                let index = index;
                trackBy: trackById
            "
            [attr.data-index]="index"
            class="listItem padLeft"
            id="{{ resourceType }}_{{ item[idProperty] }}"
        >
            <div [ngClass]="iconClass"></div>
            <app-resource-status [item]="item" [resourceType]="resourceType"></app-resource-status>
        </div>
    </cdk-virtual-scroll-viewport>

    <!-- use this for the SELECTED items -->
    <div *ngIf="!scrollable">
        <div
            (click)="addToSelected(item)"
            *ngFor="let item of items | sortby: sortField | textSearch: searchField:searchString; let index = index"
            [attr.data-index]="index"
            class="listItem padLeft"
            id="{{ resourceType }}_{{ item[idProperty] }}"
        >
            <div [ngClass]="iconClass"></div>
            <app-resource-status [item]="item" [resourceType]="resourceType"></app-resource-status>
        </div>
    </div>
</div>
