import { Component, Inject, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GroupsformComponent } from '@netfoundry-ui/feature/form/groups-form';
import { AuthorizationService, AuthService, IamService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { AppWan, Endpoint, Environment, ENVIRONMENT, Group, ListItem, ShareData } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    AppwanService,
    ClientService,
    FeatureService,
    GroupService,
    LoggerService,
    ValidateService,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { ShareService } from '@netfoundry-ui/shared/share';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { FromDatePipe, SortbyPipe } from '@netfoundry-ui/ui/pipes';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppWanFormComponent } from '@netfoundry-ui/feature/form/app-wan-form';

const deletingStatus = 800;
const registered = 400;

@Component({
    selector: 'app-clientdashboard',
    templateUrl: './clientdashboard.component.html',
    styleUrls: ['./clientdashboard.component.scss'],
    providers: [FromDatePipe],
})
export class ClientdashboardComponent implements OnInit, OnDestroy, OnChanges {
    model: Endpoint = new Endpoint({});
    processing = false;
    appwans: ListItem;
    groups: ListItem;
    errorName = false;
    currentTimeFilter = '24h';
    endTime: number = Date.now();
    startTime: number = this.endTime - 24 * 60 * 60 * 1000;
    directionFilter: string[] = ['DropTcpTx', 'DropUdpTx'];
    endpointNameFilter: any = false;
    resourceId;
    componentId;
    deleting = false;
    isInline = false;
    confirmDialog;
    selectDialog;
    allGroups: Group[] = [];
    groupFormComponent: Type<any> = GroupsformComponent;
    allAppWans: AppWan[] = [];
    appwanFormComponent: Type<any> = AppWanFormComponent;
    isRegistered = false;
    currentNetworkId = '';
    canShareEndpoint;
    hideHelp = false;
    activeDirectoryString = 'IMPORT';
    isMfaEnabledNetwork = false;
    originalMfaSetting = 'no';
    currentNetwork;
    zitiRegKey;
    zitiRegKeyBlob;
    canReadElasticSearch = true;
    users = [];
    authUserIdentity = '';
    errorAuthUser = false;
    requireAuthSession = false;
    canListUsers = false;
    canCreateUsers = false;
    public newUserString = 'NewUser';
    addingNewUser = false;
    newUserEmail;
    errorEmail = false;
    userSelected = [];
    defaultExpanded = false;
    private appwansData: AppWan[] = [];
    private groupsData: Group[] = [];
    private originalClientName = '';
    private subscription = new Subscription();
    private groupSubscription: Subscription = new Subscription();
    private allAppwanSubscription: Subscription = new Subscription();
    private relatedAppwanSubscription: Subscription = new Subscription();
    private relatedGroupsSubscription: Subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private clientService: ClientService,
        private shareService: ShareService,
        private growlerService: GrowlerService,
        private fromDate: FromDatePipe,
        private groupService: GroupService,
        private appwanService: AppwanService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogForm: MatDialog,
        private dialogRef: MatDialogRef<ClientdashboardComponent>,
        private validateService: ValidateService,
        public authorizationService: AuthorizationService,
        private apiService: ApiService,
        public zitiEnabledService: ZitiEnabledService,
        private iamService: IamService,
        public featureService: FeatureService,
        private authService: AuthService,
        private sortByPipe: SortbyPipe,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.currentNetwork = this.apiService.theNetworkIs;
        if (data.currentNetwork != null) {
            this.currentNetwork = data.currentNetwork;
        }

        this.model = new Endpoint(data.model);

        if (this.model.sessionIdentityId != null) {
            this.requireAuthSession = true;
            this.authUserIdentity = this.model.sessionIdentityId;
        }

        if (
            this.model['registrationAttemptsLeft'] > 0 &&
            (this.model.endpointType === 'ZTCL' || this.zitiEnabledService.zitiPure)
        ) {
            this.getZitiRegKey();
        }
        this.componentId = this.model['componentId'];
        this.resourceId = this.model.getId();
        this.originalClientName = this.model.name;
        if (data.inline) {
            this.isInline = data.inline;
        }
        this.isRegistered = this.model['status'] === registered;

        this.canShareEndpoint = this.authorizationService.canShareEndpoint(
            this.model.id,
            this.currentNetwork.organizationId,
            this.currentNetwork.id
        );
        this.canReadElasticSearch = this.authorizationService.canReadElasticSearch();
        this.originalClientName = this.model.clientMfaEnable;

        if (
            this.currentNetwork.mfaClientId != null &&
            this.currentNetwork.mfaIssuerId != null &&
            this.currentNetwork.mfaClientId !== '' &&
            this.currentNetwork.mfaIssuerId !== ''
        ) {
            this.isMfaEnabledNetwork = true;
        }
        this.canCreateUsers = this.authorizationService.canCreateUserIdentity();
        if (this.canCreateUsers) {
            this.users.push({ name: 'Add a New User', id: this.newUserString });
        }
        if (this.authorizationService.canListUserIdentities(this.apiService.theTenantIs.id)) {
            this.canListUsers = true;
            this.subscription.add(
                this.iamService
                    .find('user-identities', { tenantId: this.apiService.theTenantIs.id })
                    .subscribe((results: any) => {
                        for (const user of this.sortByPipe.transform(results, 'lastName', 'desc')) {
                            this.users.push({
                                name: `${user['firstName']} ${user['lastName']} - ${user['email']}`,
                                id: user['id'],
                            });
                            if (user['id'] === this.authUserIdentity) {
                                this.userSelected.push({
                                    name: `${user['firstName']} ${user['lastName']} - ${user['email']}`,
                                    id: user['id'],
                                });
                            }
                        }
                    })
            );
        }
    }

    ngOnChanges() {
        this.endpointNameFilter = this.model.name;
        this.resourceId = this.model.getId();

        if (this.model['componentId']) {
            this.componentId = this.model['componentId'];
        }
    }

    ngOnInit() {
        this.errorName = false;
        this.groups = new ListItem(this.model.getId(), 'Groups');
        this.appwans = new ListItem(this.model.getId(), 'AppWANs');

        // if the user has list appwan permissions
        if (this.authorizationService.canListAppWans(this.currentNetwork.organizationId, this.currentNetwork.id)) {
            // get appwans and related appwans
            this.getAppwans();
            this.getRelatedAppwans();
        }

        if (
            this.authorizationService.canListEndpointGroups(this.currentNetwork.organizationId, this.currentNetwork.id)
        ) {
            this.getRelatedGroups();
            if (this.model['source'] !== this.activeDirectoryString) {
                this.getGroups();
            }
        }
    }

    ngOnDestroy() {
        this.processing = true;
        this.subscription.unsubscribe();
        if (this.groupSubscription != null) {
            this.groupSubscription.unsubscribe();
        }
        if (this.allAppwanSubscription != null) {
            this.allAppwanSubscription.unsubscribe();
        }

        this.relatedGroupsSubscription.unsubscribe();
    }

    async save() {
        if (
            this.validate() &&
            this.authorizationService.canUpdateEndpoint(
                this.model.id,
                this.currentNetwork.organizationId,
                this.currentNetwork.id
            )
        ) {
            this.processing = true;

            if (this.requireAuthSession) {
                if (this.addingNewUser) {
                    const newIdentity = await this.createUser(this.newUserEmail);
                    if (newIdentity != null) {
                        this.inviteIdentity(newIdentity);
                        this.model['sessionIdentityId'] = newIdentity.id;
                    } else {
                        this.processing = false;
                        return;
                    }
                } else {
                    this.model['sessionIdentityId'] = this.authUserIdentity;
                }
            } else {
                this.model['sessionIdentityId'] = null;
            }

            let newName;
            if (this.originalClientName !== this.model.name) {
                newName = this.model.name;
            }
            this.subscription.add(
                this.clientService.save(this.model).subscribe(
                    (result) => {
                        // this.refreshEvent.emit(true);
                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Client Save Complete',
                                'The information has been saved'
                            )
                        );
                        this.dialogRef.close({ newName: newName });
                        this.processing = false;
                    },
                    (error) => {
                        this.processing = false;
                    }
                )
            );
        }
    }

    share() {
        if (this.model['registrationKey'] !== undefined) {
            this.shareService.show(new ShareData('client', this.model));
        }
    }

    copy() {
        const element = <HTMLInputElement>document.getElementById('EditRegKey');
        element.focus();
        element.select();
        document.execCommand('copy');
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Reg Key Copied',
                'The reg key ' + this.model['registrationKey'] + ' has been copied to your clipboard'
            )
        );
    }

    validate(): boolean {
        this.errorName = false;
        this.errorAuthUser = false;
        this.errorEmail = false;

        if (!this.validateService.isValidName(this.model.name)) {
            this.errorName = true;
        }

        if (this.requireAuthSession && !this.validateService.hasValue(this.authUserIdentity)) {
            this.errorAuthUser = true;
        } else if (
            this.requireAuthSession &&
            this.authUserIdentity === this.newUserString &&
            !this.validateService.isValidEmail(this.newUserEmail)
        ) {
            this.errorEmail = true;
        }

        return !this.errorName && !this.errorEmail && !this.errorAuthUser;
    }

    statusClass() {
        return 's' + this.model['status'];
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setStartTime(value) {
        this.startTime = this.fromDate.transform(value);
        this.endTime = Date.now();
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setEndTime(value) {
        // TODO - process date field into ms number
        this.endTime = this.fromDate.transform(value);
    }

    /**
     * Set the filter for all child Feature
     * @param value
     */
    public setDirectionFilter(value) {
        if (value === 'tx') {
            this.directionFilter = ['DropTcpTx', 'DropUdpTx'];
        } else {
            this.directionFilter = ['DropTcpRx', 'DropUdpRx'];
        }
    }

    confirmDelete() {
        if (
            this.authorizationService.canDeleteEndpoint(
                this.model.id,
                this.currentNetwork.organizationId,
                this.currentNetwork.id
            )
        ) {
            const data = {
                title: 'Delete',
                appendId: 'gatewayDash',
                subtitle: 'Are you sure you would like to delete the client' + this.model.name,
                icon: 'Delete',
                action: 'Yes',
            };
            this.confirmDialog = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            this.confirmDialog.beforeClosed().subscribe((result) => {
                // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                //  logged out of the console and we should close the dialog without continuing
                if (result === undefined) {
                    this.confirmed(false);
                } else if (result['loggingOut'] === undefined) {
                    this.confirmed(result);
                }
            });
            this.deleting = true;
        }
    }

    confirmed(event) {
        if (event) {
            if (this.deleting) {
                this.subscription.add(
                    this.clientService.delete(this.model).subscribe((data) => {
                        this.dialogRef.close({ deleted: true });
                    })
                );
            }
            this.deleting = false;
        }
    }

    refresh() {
        if (this.authorizationService.canListAppWans(this.currentNetwork.organizationId, this.currentNetwork.id)) {
            // getting the appwans
            this.getAppwans();
            this.getRelatedAppwans();
        }

        if (
            this.authorizationService.canListEndpointGroups(this.currentNetwork.organizationId, this.currentNetwork.id)
        ) {
            this.getGroups();
            this.getRelatedGroups();
        }
    }

    refreshLists(eventData) {
        this.refresh();
    }

    addToGroups(groupIds: string[]) {
        // making sure that there is at least one group to add
        if (groupIds !== null && groupIds !== undefined && groupIds.length > 0) {
            // variables used to determine when to call the refresh
            const expectedSubscriptions = groupIds.length;
            let numSubscriptionsFinished = 0;

            // looping through the list of all groups
            for (const group of this.allGroups) {
                // if the current group is one of the groups to remove add the client to, adding the client
                if (groupIds.indexOf(group.id) > -1) {
                    this.subscription.add(
                        this.groupService.addEndpoints(group, [this.model.id]).subscribe((result) => {
                            numSubscriptionsFinished++;

                            // if this is the last subscription to finish executing, refresh the view
                            if (numSubscriptionsFinished === expectedSubscriptions) {
                                this.growlerService.show(
                                    new GrowlerData(
                                        'success',
                                        'Success',
                                        'Save Complete',
                                        'Group information has been saved.'
                                    )
                                );
                                // group updates are not always instant
                                setTimeout(() => {
                                    this.refresh();
                                }, 1000);
                            }
                        })
                    );
                }
            }
        }
    }

    removeFromGroups(groupIds: string[]) {
        // making sure that there is at least one group to remove
        if (groupIds !== null && groupIds !== undefined && groupIds.length > 0) {
            // variables used to determine when to call the refresh
            const expectedSubscriptions = groupIds.length;
            let numSubscriptionsFinished = 0;

            // looping through the list of groups this client is part of
            for (const group of this.groupsData) {
                // if the current group is one of the groups to remove the client from, removing the client
                if (groupIds.indexOf(group.id) > -1) {
                    this.subscription.add(
                        this.groupService.removeEndpoints(group, [this.model.id]).subscribe((result) => {
                            numSubscriptionsFinished++;
                            // if this is the last subscription to finish executing, refresh the view
                            if (numSubscriptionsFinished === expectedSubscriptions) {
                                this.growlerService.show(
                                    new GrowlerData(
                                        'success',
                                        'Success',
                                        'Save Complete',
                                        'Group information has been saved.'
                                    )
                                );
                                this.refresh();
                                // group updates are not always instant
                                setTimeout(() => {
                                    this.refresh();
                                }, 1000);
                            }
                        })
                    );
                }
            }
        }
    }

    addToAppWans(appwanIds: string[]) {
        // making sure that there is at least one group to add
        if (appwanIds !== null && appwanIds !== undefined && appwanIds.length > 0) {
            // variables used to determine when to call the refresh
            const expectedSubscriptions = appwanIds.length;
            let numSubscriptionsFinished = 0;

            // looping through the list of all appwans
            for (const appwan of this.allAppWans) {
                // if the current appwan is one of the appwans to remove add the client to, adding the client
                if (appwanIds.indexOf(appwan.id) > -1) {
                    this.subscription.add(
                        this.appwanService.addEndpoints(appwan, [this.model.id]).subscribe((result) => {
                            numSubscriptionsFinished++;

                            // if this is the last subscription to finish executing, refresh the view
                            if (numSubscriptionsFinished === expectedSubscriptions) {
                                this.growlerService.show(
                                    new GrowlerData(
                                        'success',
                                        'Success',
                                        'Save Complete',
                                        'AppWan information has been saved.'
                                    )
                                );
                                this.refresh();
                            }
                        })
                    );
                }
            }
        }
    }

    removeFromAppWans(appwanIds: string[]) {
        // making sure that there is at least one appwan to remove
        if (appwanIds !== null && appwanIds !== undefined && appwanIds.length > 0) {
            // variables used to determine when to call the refresh
            const expectedSubscriptions = appwanIds.length;
            let numSubscriptionsFinished = 0;
            for (const appwan of this.appwansData) {
                // if the current appwan is one of the appwans to remove the client from, removing the client
                if (appwanIds.indexOf(appwan.id) > -1) {
                    this.subscription.add(
                        this.appwanService.removeEndpoints(appwan, [this.model.id]).subscribe((result) => {
                            numSubscriptionsFinished++;
                            // if this is the last subscription to finish executing, refresh the view
                            if (numSubscriptionsFinished === expectedSubscriptions) {
                                this.growlerService.show(
                                    new GrowlerData(
                                        'success',
                                        'Success',
                                        'Save Complete',
                                        'AppWan information has been saved.'
                                    )
                                );
                                this.refresh();
                            }
                        })
                    );
                }
            }
        }
    }

    downloadJWT() {
        const name = this.model.name + '.jwt';
        saveAs(this.zitiRegKeyBlob, name);
    }

    async getZitiRegKey() {
        this.zitiRegKeyBlob = await this.clientService
            .downloadRegistrationKey(this.model)
            .pipe(take(1))
            .toPromise()
            .then((result) => result);

        this.zitiRegKey = await new Response(this.zitiRegKeyBlob).text();
    }

    hide() {
        this.dialogRef.close();
    }

    toggleMfa() {
        if (this.model.clientMfaEnable === 'YES') {
            this.model.clientMfaEnable = 'NO';
        } else {
            this.model.clientMfaEnable = 'YES';
        }
    }

    toggleRequireAuth() {
        this.requireAuthSession = !this.requireAuthSession;
        this.userSelected = [];
    }

    onChangeAuthUserIdentityId(userIdentityIdList) {
        if (userIdentityIdList.length > 0) {
            this.authUserIdentity = userIdentityIdList[0];
        } else {
            this.authUserIdentity = null;
        }

        if (this.authUserIdentity === this.newUserString) {
            this.addingNewUser = true;
        } else {
            this.addingNewUser = false;
        }
    }

    async createUser(email: string) {
        const identityModel = {
            firstName: '',
            lastName: '',
            email: '',
            tenantId: '',
        };

        identityModel.email = email;

        const splitEmail = identityModel.email.split('@');
        const nameSplit = splitEmail[0].split('.');

        const nameRegex = /[^a-zA-Z]/g;
        if (nameSplit.length > 1) {
            identityModel.firstName = nameSplit[0].replace(nameRegex, '');
            identityModel.lastName = nameSplit[1].replace(nameRegex, '');
        } else {
            identityModel.lastName = nameSplit[0].replace(nameRegex, '');
        }

        identityModel.tenantId = this.apiService.theTenantIs.id;

        return await this.iamService
            .create('user-identities', identityModel)
            .toPromise()
            .then(
                (result) => result,
                (error) => {
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Unable to Create User',
                            'There was an issue when attempting to create a new user'
                        )
                    );
                    return null;
                }
            );
    }

    private getAppwans() {
        this.allAppwanSubscription.unsubscribe();

        this.allAppwanSubscription = this.appwanService.get().subscribe((result) => {
            this.allAppWans = [];
            for (const appwan of result as AppWan[]) {
                if (appwan.status < deletingStatus) {
                    if (
                        this.authorizationService.canUpdateAppWan(
                            appwan.id,
                            this.currentNetwork.organizationId,
                            this.currentNetwork.id
                        )
                    ) {
                        appwan['canUpdate'] = true;
                    }
                    this.allAppWans.push(appwan);
                }
            }
        });
    }

    private getRelatedAppwans() {
        this.relatedAppwanSubscription.unsubscribe();
        this.relatedAppwanSubscription = this.clientService
            .getLinkedResources(this.model, 'appWans')
            .subscribe((result) => {
                this.appwansData = [];
                this.appwans.clear();
                for (let appwan of result) {
                    appwan = new AppWan(appwan);
                    if (
                        this.authorizationService.canUpdateAppWan(
                            appwan.id,
                            this.currentNetwork.organizationId,
                            this.currentNetwork.id
                        )
                    ) {
                        appwan['canUpdate'] = true;
                    }
                    this.appwans.push(appwan, this.model.id, 'appwan', appwan.id, appwan.name, false);
                    this.appwansData.push(appwan);
                }
            });
    }

    private getGroups() {
        this.groupSubscription.unsubscribe();
        // othwerise, get the list of all groups and appwans
        this.groupSubscription = this.groupService.get().subscribe((result) => {
            this.allGroups = [];
            for (const item of result) {
                const group = new Group(item);
                if (
                    this.authorizationService.canUpdateEndpointGroup(
                        group.id,
                        this.currentNetwork.organizationId,
                        this.currentNetwork.id
                    )
                ) {
                    group['canUpdate'] = true;
                }
                this.allGroups.push(group);
            }
        });
    }

    private getRelatedGroups() {
        this.relatedGroupsSubscription.unsubscribe();
        this.relatedGroupsSubscription = this.clientService
            .getLinkedResources(this.model, 'endpointGroups')
            .subscribe((result) => {
                this.groups.clear();
                this.groupsData = [];
                for (let group of result) {
                    group = new Group(group);
                    if (
                        this.authorizationService.canUpdateEndpointGroup(
                            group.id,
                            this.currentNetwork.organizationId,
                            this.currentNetwork.id
                        )
                    ) {
                        group['canUpdate'] = true;
                    }
                    this.groups.push(group, this.model.id, 'group', group.id, group.name, false);
                    this.groupsData.push(group);
                }
            });
    }

    private inviteIdentity(identity) {
        // model for the invitation using the invitation URL for the environment, the provided email, and the provided tenant ID
        const inviteModel = {
            invitationUrl: this.environment.identityConfig.invitationUrl,
            invitedEmailAddress: identity.email,
            toTenantId: this.apiService.theTenantIs.id,
            targetUserIdentityId: identity.id,
        };

        // sending the invitation
        this.iamService.create('invitations', inviteModel).subscribe(() => {
            this.logger.info('successfully sent invitation');
        });
    }
}
