<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="EditModal">
    <div class="fullRow">
        <div class="title" style="filter: brightness(50%)">Client Details</div>
        <div class="subtitle">Edit your client attributes</div>
        <div class="row">
            <div class="midSized">
                <div class="half">
                    <div class="label row">
                        Client Name<span [hidden]="!errorName" class="error">Input Required</span
                        ><span [hidden]="errorName" class="note">Required</span>
                    </div>
                    <div class="row">
                        <input
                            [(ngModel)]="model.name"
                            [disabled]="
                                model['source'] === activeDirectoryString ||
                                !authorizationService.canUpdateEndpoint(
                                    model.id,
                                    currentNetwork.organizationId,
                                    currentNetwork.id
                                )
                            "
                            [ngClass]="{ error: errorName }"
                            class="restrictNormal"
                            data-enter="SaveEditButton"
                            id="EditCurrentName"
                            maxlength="64"
                            placeholder="What would you like to call this client?"
                        />
                    </div>
                </div>
                <!-- <div class="half padLeft">
<app-endpoint-geo
[componentId]="model.componentId"
[endpoint]="model"
[cloudRegionOnly]="!canReadElasticSearch"
></app-endpoint-geo>
</div> -->
            </div>
        </div>

        <div *ngIf="isMfaEnabledNetwork" class="row">
            <div class="midSized">
                <div class="half toggleHa">
                    <div class="label row">Multi Factor Authentication</div>
                    <div class="row">
                        <div class="darkGrid">
                            <div class="title">
                                <div class="icon-clientMfa"></div>
                            </div>
                            <div class="subtitle">
                                Enable OKTA Multi Factor<br />
                                Client Authentication
                            </div>
                            <div>
                                <div class="switchGrid">
                                    <div>
                                        <div
                                            (click)="toggleMfa()"
                                            [ngClass]="{ on: model.clientMfaEnable === 'YES' }"
                                            class="toggle"
                                        >
                                            <span [hidden]="model.clientMfaEnable !== 'YES'" id="ClientMFAToggleOn"
                                                >ON&nbsp;&nbsp;&nbsp;</span
                                            >
                                            <span [hidden]="model.clientMfaEnable === 'YES'" id="ClientMFAToggleOff"
                                                >&nbsp;&nbsp;&nbsp;OFF</span
                                            >
                                            <div class="switch"></div>
                                        </div>
                                    </div>
                                    <div>
                                        <span [hidden]="model.clientMfaEnable !== 'YES'">Enabled</span>
                                        <span [hidden]="model.clientMfaEnable === 'YES'">Disabled</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="midSized">
                <div [hidden]="model['registrationAttemptsLeft'] < 1" class="row" id="RegKeyArea">
                    <div *ngIf="model.endpointType === 'CL' && !zitiEnabledService.zitiPure">
                        <div class="label row edge">Client Registration Key<span class="note">Click to Copy</span></div>
                        <div class="row edge">
                            <input
                                (click)="copy()"
                                [(ngModel)]="model['registrationKey']"
                                class="RegKey"
                                id="EditRegKey"
                                readonly
                            />
                        </div>
                    </div>
                    <div *ngIf="model.endpointType === 'ZTCL' || zitiEnabledService.zitiPure">
                        <div class="label row">Client Registration Key<span class="note">Tap To Download</span></div>
                        <div class="row">
                            <div (click)="downloadJWT()" class="downloadButton">
                                <div class="DownloadKey"></div>
                                <div>DOWNLOAD KEY</div>
                                <div class="TapToDownload"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="canCreateUsers || canListUsers" class="row">
            <div class="midSized">
                <div class="label row labelExpansion">Require NF Auth Session</div>
                <div class="row expansion">
                    <mat-accordion>
                        <mat-expansion-panel #panel [expanded]="requireAuthSession" [hideToggle]="true">
                            <mat-expansion-panel-header (click)="toggleRequireAuth()" [collapsedHeight]="'65px'">
                                <mat-panel-title class="expansionLabel">
                                    <div class="title">
                                        <div class="icon-NFAuthorizationSessionManagement"></div>
                                    </div>
                                    <div class="subtitle">Require NF Auth Session</div>
                                    <div>
                                        <div class="switchGrid">
                                            <div>
                                                <div [ngClass]="{ on: requireAuthSession }" class="toggle">
                                                    <span [hidden]="!requireAuthSession">ON&nbsp;&nbsp;&nbsp;</span>
                                                    <span [hidden]="requireAuthSession">&nbsp;&nbsp;&nbsp;OFF</span>
                                                    <div class="switch"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-grid-list *ngIf="requireAuthSession" cols="10" rowHeight="100px">
                                <mat-grid-tile [colspan]="3">
                                    <div [ngClass]="{ error: errorAuthUser }" class="label gridTileLabel">
                                        Select a User
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="7">
                                    <div style="width: 100%">
                                        <app-nf-select2
                                            (selectionChange)="onChangeAuthUserIdentityId($event)"
                                            [hasError]="errorAuthUser"
                                            [isFullWidth]="true"
                                            [options]="users"
                                            [placeholder]="'Select a User'"
                                            [selectOne]="true"
                                            [selected]="userSelected"
                                            [usingCustomSort]="true"
                                            id="UserSelector"
                                        ></app-nf-select2>
                                    </div>
                                    <!--<select class="expansionPanelField" id="UserList" [ngClass]="{error:errorAuthUser}" [(ngModel)]="authUserIdentity" (keyup.enter)="save()" (change)="onChangeAuthUserIdentityId()">
<option value="">Select a User</option>
<option value="{{newUserString}}">Add a New User</option>
<option *ngFor="let user of users | sortby: 'lastName'" [value]="user.id">{{ user.firstName }} {{ user.lastName }} - {{ user.email }}</option>
</select>-->
                                </mat-grid-tile>
                                <mat-grid-tile *ngIf="addingNewUser" [colspan]="3">
                                    <div [ngClass]="{ error: errorEmail }" class="label gridTileLabel">
                                        Enter Email Address
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile *ngIf="addingNewUser" [colspan]="7">
                                    <input
                                        [(ngModel)]="newUserEmail"
                                        [ngClass]="{ error: errorEmail }"
                                        class="expansionPanelField"
                                        data-private
                                        id="EditEmail"
                                        placeholder="Enter an email address"
                                    />
                                </mat-grid-tile>
                            </mat-grid-list>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="midSized">
                <div class="row buttonRow right">
                    <button
                        (click)="confirmDelete()"
                        *ngIf="
                            model['source'] !== activeDirectoryString &&
                            authorizationService.canDeleteEndpoint(
                                model.id,
                                currentNetwork.organizationId,
                                currentNetwork.id
                            )
                        "
                        class="save alert confirm"
                        id="DeleteEditClientButton"
                        style="margin-right: 20px"
                    >
                        Delete Client
                    </button>
                    <button
                        (click)="save()"
                        *ngIf="
                            authorizationService.canUpdateEndpoint(
                                model.id,
                                currentNetwork.organizationId,
                                currentNetwork.id
                            ) && !processing
                        "
                        class="save"
                        id="SaveEditClientButton"
                    >
                        Update
                    </button>
                    <button *ngIf="processing" class="save confirm" disabled>
                        Saving <span class="spinner"></span>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="isRegistered">
            <div class="row">
                <div class="fullSized">
                    <div class="splitGrid gapped">
                        <div>
                            <div class="cardStack full" style="height: 390px">
                                <!-- Mop Event Timeline -->
                                <app-mop-timeline
                                    [componentId]="componentId"
                                    [endpointNameFilter]="endpointNameFilter"
                                    [networkId]="currentNetwork.id"
                                    [resourceId]="resourceId"
                                    [startTime]="startTime"
                                >
                                </app-mop-timeline>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div *ngIf="model.name" class="cardStack full" style="height: 390px">
                                    <div class="title">
                                        <!-- Date Filter -->
                                        <app-date-filters
                                            (endChange)="setEndTime($event)"
                                            (startChange)="setStartTime($event)"
                                            [currentTimeFilter]="currentTimeFilter"
                                        >
                                        </app-date-filters>

                                        <div class="clientUtilizationTitle">Client Utilization</div>

                                        <app-utilization-filters
                                            (directionChange)="setDirectionFilter($event)"
                                            [showUsageEndpoint]="false"
                                        >
                                        </app-utilization-filters>
                                    </div>

                                    <!-- Graph -->
                                    <app-utilization-timeline
                                        [endTime]="endTime"
                                        [endpointType]="endpointFilter"
                                        [networkDataTypes]="directionFilter"
                                        [networkId]="currentNetwork.id"
                                        [resourceId]="componentId"
                                        [startTime]="startTime"
                                    >
                                    </app-utilization-timeline>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row marginBottom">
            <div class="fullSized">
                <div class="thirdGrid gapped">
                    <app-itemlist
                        (addEvent)="addToGroups($event)"
                        (changed)="refreshLists($event)"
                        (closeEvent)="hide()"
                        (deleteEvent)="removeFromGroups($event)"
                        *ngIf="
                            authorizationService.canListEndpointGroups(currentNetwork.organizationId, currentNetwork.id)
                        "
                        [allItems]="allGroups"
                        [checkAuth]="true"
                        [disableOpen]="model['source'] === this.activeDirectoryString"
                        [formComponent]="groupFormComponent"
                        [hasToggle]="model['source'] !== this.activeDirectoryString"
                        [item]="groups"
                        [totalAssociated]="groups.items.length"
                    >
                    </app-itemlist>

                    <app-itemlist
                        (addEvent)="addToAppWans($event)"
                        (changed)="refreshLists($event)"
                        (closeEvent)="hide()"
                        (deleteEvent)="removeFromAppWans($event)"
                        *ngIf="authorizationService.canListAppWans(currentNetwork.organizationId, currentNetwork.id)"
                        [allItems]="allAppWans"
                        [checkAuth]="true"
                        [formComponent]="appwanFormComponent"
                        [hasToggle]="true"
                        [item]="appwans"
                        [totalAssociated]="appwans.items.length"
                    >
                    </app-itemlist>
                    <div *ngIf="zitiRegKey">
                        <div class="cardStack full" style="height: 370px">
                            <div class="title cEdit">Registration QR Code</div>
                            <div class="row qrCode">
                                <qrcode [level]="'M'" [qrdata]="zitiRegKey" [size]="300"></qrcode>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!zitiRegKey">
                        <div class="blank"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div (click)="hide()" class="buttonBall close" id="CloseEditClientButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div (click)="share()" *ngIf="canShareEndpoint" class="buttonBall share" id="ShareEditClientButton">
        <div class="buttonLabel">SHARE</div>
    </div>
    <div id="ClientStatus">
        <div class="graphic">
            <svg
                id="Layer_1"
                style="enable-background: new 0 0 72 72"
                version="1.1"
                viewBox="0 0 72 72"
                x="0px"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                y="0px"
            >
                <rect [ngClass]="statusClass()" height="42.9" width="65.6" x="3.5" y="7.4" />
                <rect class="st1" height="14.6" width="14.6" x="28.2" y="50.1" />
                <rect class="st1" height="10.8" width="65.5" x="3.6" y="39.5" />
                <g>
                    <polyline class="st2" points="68.9,50.3 3.5,50.3 3.5,7.4 	" />
                    <polyline class="st2" points="3.5,7.2 69,7.2 69,50.2 	" />
                    <path class="st3" d="M35.8,64.7" />
                    <path class="st3" d="M35.8,50.3" />
                    <line class="st2" x1="22" x2="49.4" y1="64.7" y2="64.7" />
                    <line class="st2" x1="3.5" x2="69" y1="39.5" y2="39.5" />
                    <g>
                        <line class="st2" x1="49.3" x2="60.1" y1="16.2" y2="27" />
                        <line class="st2" x1="57.4" x2="62.2" y1="14.4" y2="19.2" />
                    </g>
                </g>
                <g>
                    <line class="st2" x1="42.9" x2="42.9" y1="50.4" y2="64.7" />
                    <path class="st2" d="M49.1,64.7" />
                    <path class="st2" d="M21.2,64.7" />
                </g>
                <circle class="st4" cx="35.6" cy="44.9" r="1.8" />
                <line class="st2" x1="28.3" x2="28.3" y1="50.5" y2="64.3" />
            </svg>
        </div>
        Client <span id="statusText"></span>
    </div>
</div>
