import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { delay } from 'lodash';
import {HttpClient} from "@angular/common/http";
import {take} from "rxjs";

@Component({
    selector: 'app-iframe-modal',
    templateUrl: './iframe-modal.component.html',
    styleUrls: ['./iframe-modal.component.scss'],
})
export class IframeModalComponent implements OnInit {
    safeUrl: SafeResourceUrl;
    url: string;
    isSample = false;
    attempts = 0;
    checkingConnection = false;
    connected = false;
    doConnectionCheck = false;
    connectionEstablished = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<IframeModalComponent>,
        public dialogForm: MatDialog,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private httpClient: HttpClient
    ) {
        this.url = data.url;
        this.safeUrl = domSanitizer.bypassSecurityTrustResourceUrl(this.url + `?t=${new Date().getTime()}`);
        this.isSample = data.isSample;

    }

    ngOnInit() {
        this.doConnectionCheck = true;
        this.checkConnection();
    }

    hide() {
        this.doConnectionCheck = false;
        this.dialogRef.close({ success: this.connectionEstablished });
    }

    return() {
        this.hide();
    }

    refreshIframe() {
        this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('');
        delay(() => {
            this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                this.data.url + `?t=${new Date().getTime()}`
            );
        }, 50);
    }

    checkConnection() {
        if (!this.doConnectionCheck) {
            this.checkingConnection = false;
            return;
        }
        if (this.checkingConnection) {
            delay(() => {
                this.checkConnection();
            }, 3000);
            return;
        }
        this.checkingConnection = true;
        const headers = {
          'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        };
        this.httpClient.get(this.url, {observe: 'response', responseType: 'text', headers: headers}).pipe(take(1)).subscribe(
          (resp) => {
            this.checkingConnection = false;
            if (resp?.status === 200 && !this.connected) {
              this.connected = true;
              this.connectionEstablished = true;
              this.refreshIframe();
              this.connectionEstablished = true;
            } else if (resp?.status !== 200 && this.connected) {
              this.connected = false;
              this.refreshIframe();
            }
          },
          (error) => {
            console.log(error);
            this.checkingConnection = false;
            this.connected = false;
            this.refreshIframe();
          }
        );
        delay(() => {
          this.checkConnection();
        }, 3000);
    }
}
