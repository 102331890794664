import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-tunneler-card',
    templateUrl: './tunneler-card.component.html',
    styleUrls: ['./tunneler-card.component.scss'],
})
export class TunnelerCardComponent {
    @Input() item: any = {};
    @Output() selected = new EventEmitter();

    constructor() {}

    linkClicked() {
        this.selected.emit(this.item);
    }
}
