import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Chart } from 'angular-highcharts';

@Component({
    selector: 'app-highcharts-generic',
    templateUrl: './highcharts-generic.component.html',
    styleUrls: ['./highcharts-generic.component.scss'],
})
export class HighchartsGenericComponent implements OnChanges, OnInit {
    @Input() options: any;
    @Input() series: Array<any>;
    @Input() chartheight = '200px';

    highchart: Chart;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<HighchartsGenericComponent>) {
        if (data.options) {
            this.options = data.options;
        }

        if (data.series) {
            this.series = data.series;
        }

        if (data.height) {
            this.chartheight = data.height;
        }
    }

    ngOnInit() {
        this.initChart();
    }

    ngOnChanges() {
        this.initChart();
    }

    initChart() {
        if (!this.options || this.options === 'undefined') {
            return;
        }

        // this.logger.info('Chart Options', this.options);
        this.highchart = new Chart(this.options);
        if (this.series) {
            for (const chunk of this.series) {
                this.highchart.addSeries(chunk, true, true);
            }
        }
    }
}
