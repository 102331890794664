import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EdgeRouterV2, NetworkV2 } from '@netfoundry-ui/shared/model';
import { EdgeRouterServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { NetworkUpgradeService } from '../network-upgrade.service';
import { ApiService } from '@netfoundry-ui/shared/services';
import semver from 'semver';


@Component({
    selector: 'app-edge-router-upgrade-modal',
    templateUrl: './edge-router-upgrade-modal.component.html',
    styleUrls: ['./edge-router-upgrade-modal.component.scss'],
})
export class EdgeRouterUpgradeModalComponent {
    title = 'Edge Router Upgrade Available';
    icon = '';
    action = 'Upgrade Edge Router';
    cancelAction = 'Oops, no get me out of here';
    currentNetwork;
    edgeRouter;

    network: NetworkV2;
    versionList: any[];
    edgeRouterList: any[] = [];
    selectedVersion = null;
    selectedEdgeRouters: EdgeRouterV2[] = [];

    constructor(
        private dialogRef: MatDialogRef<EdgeRouterUpgradeModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public networkUpgradeSvc: NetworkUpgradeService,
        private edgeRouterSvc: EdgeRouterServiceV2,
        public apiService: ApiService
    ) {
        this.network = data.network;
        this.selectedEdgeRouters = data.edgeRouters;
        this.getCurrentNetwork();
        this.getUpgradeableVersions(this.currentNetwork);
    }

    cancel() {
        this.dialogRef.close();
    }

    getCurrentNetwork() {
        this.apiService.currentNetwork.subscribe((network) => {
            this.currentNetwork = network as NetworkV2;
        });
    }

    public getUpgradeableVersions(network: NetworkV2) {
        this.versionList = this.networkUpgradeSvc.getUpgradeableVersionsEdgeRouter(network);
        // this.edgeRouter = this.selectedEdgeRouters[0]
    }

    confirm() {
        if (!this.selectedVersion) this.cancel();
        else {
            this.selectedEdgeRouters.forEach((er) => {
                this.edgeRouterSvc.upgrade(er, this.selectedVersion);
            });
            this.dialogRef.close();
        }
    }
}
