import { MopResource } from './mopresource';

export class Location extends MopResource {
    public locationCode: string;
    public locationName: string;
    public provider: string;

    public getSaveObject() {
        return {};
    }

    getLabel() {
        if (this.locationCode) {
            if (this.locationCode.indexOf('us-east') === 0) {
                return 'US East (' + this.locationName + ')';
            } else if (this.locationCode.indexOf('us-west') === 0) {
                return 'US West (' + this.locationName + ')';
            } else if (this.locationCode.indexOf('ap') === 0) {
                return 'Asia Pacific (' + this.locationName + ')';
            } else if (this.locationCode.indexOf('ca') === 0) {
                return 'Canada (' + this.locationName + ')';
            } else if (this.locationCode.indexOf('eu') === 0) {
                return 'EU (' + this.locationName + ')';
            } else if (this.locationCode.indexOf('sa') === 0) {
                return 'South America (' + this.locationName + ')';
            } else {
                return this.locationName;
            }
        } else {
            return this.locationName;
        }
    }
}
