<div *ngIf="displaySummary">
    <div class="fullRow" id="AppwanSummary">
        <div [hidden]="!isAdding" class="title" id="AppwanSuccess" style="filter: brightness(50%)">Congratulations</div>
        <div [hidden]="!isEditing" class="title">Modify A AppWAN</div>
        <div class="subtitle smaller">Your AppWAN has been created. A network summary is below.</div>
        <div class="fullModalcontent">
            What's next? Finish connecting your network by registering new clients and gateways.
        </div>

        <div class="sized mid">
            <div class="grid two marginBottom">
                <div class="hint">
                    <div class="title">New Clients</div>
                    <div class="subtitle">Share Client Registration Info</div>
                    <div class="image icon-Icon_Share"></div>
                </div>
                <div class="hint">
                    <div class="title">New Gateways</div>
                    <div class="subtitle">Tap to Launch and Register</div>
                    <div class="image icon-ClickIcon"></div>
                </div>
            </div>
            <div class="wizard">
                <div class="icon step5 upside"></div>

                <div class="summary">
                    <div class="step">1</div>
                    <div>
                        <div class="title">Application Name</div>
                        <div class="subtitle">
                            {{ finalModel.name }}
                            <div (click)="edit()" class="editor"></div>
                        </div>
                    </div>
                </div>

                <div class="summary">
                    <div class="step">2</div>
                    <div>
                        <div class="title">Location</div>
                        <label class="underline"
                            >Gateway Name<span [hidden]="!showLauncher(finalModel['hostingGateway'])" class="note"
                                >Tap To Launch New Gateway</span
                            ></label
                        >
                        <div class="content">
                            <div
                                (click)="
                                    !showLauncher(finalModel['hostingGateway']) &&
                                        openGateway(finalModel['hostingGateway'])
                                "
                                [ngClass]="{
                                    noLauncherScript: !showLauncher(finalModel['hostingGateway'])
                                }"
                                class="launch"
                            >
                                {{ finalModel['hostingGatewayName'] }}
                                <div
                                    (click)="LaunchAzureCloudScript(finalModel['hostingGateway'])"
                                    *ngIf="
                                        showLauncher(finalModel['hostingGateway']) &&
                                        IsAzure(finalModel['hostingGateway']) &&
                                        !IsHA(finalModel['hostingGateway']) &&
                                        !isGatewayRegistered(finalModel['hostingGateway'])
                                    "
                                    class="tap azure"
                                >
                                    Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                                </div>
                                <div
                                    (click)="LaunchCloudScript(finalModel['hostingGateway'])"
                                    *ngIf="
                                        showLauncher(finalModel['hostingGateway']) &&
                                        IsAWS(finalModel['hostingGateway']) &&
                                        !IsHA(finalModel['hostingGateway']) &&
                                        !isGatewayRegistered(finalModel['hostingGateway']) &&
                                        authorizationService.canGetDataCenter(
                                            finalModel['hostingGateway']['dataCenterId']
                                        )
                                    "
                                    class="tap aws"
                                >
                                    Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                                </div>
                                <div
                                    *ngIf="
                                        IsGoogle(finalModel['hostingGateway']) &&
                                        !IsHA(finalModel['hostingGateway']) &&
                                        !isGatewayRegistered(finalModel['hostingGateway']) &&
                                        authorizationService.canGetDataCenter(
                                            finalModel['hostingGateway']['dataCenterId']
                                        )
                                    "
                                    class="google"
                                >
                                    <span class="icon-ClickIcon"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="summary">
                    <div class="step">3</div>
                    <div>
                        <div *ngIf="ipHostServices.length > 0 && !isEmptyHostService(ipHostServices[0])">
                            <div class="title">Host Services - Single IPs</div>
                            <div class="tableGrid four header">
                                <div>IP Address</div>
                                <div>Port / Range</div>
                                <div>Label</div>
                                <div>Gateway</div>
                            </div>
                            <!-- Need to check type and only show if Host -->
                            <div
                                *ngFor="let service of finalModel['services']; let index = index"
                                class="tableGrid four"
                            >
                                <!-- needed to add the ngIf on each div instead of using a parent div as the parent div broke the styling -->
                                <div *ngIf="service.serviceClass === 'CS'" class="staticCell">
                                    {{ service.networkIp }}
                                </div>
                                <div *ngIf="service.serviceClass === 'CS'" class="staticCell">
                                    {{
                                        service.networkFirstPort === service.networkLastPort
                                            ? service.networkFirstPort
                                            : service.networkFirstPort + '-' + service.networkLastPort
                                    }}
                                </div>
                                <div *ngIf="service.serviceClass === 'CS'" class="staticCell" class="clickable link">
                                    {{ service.name }}
                                    <div (click)="openService(service)" class="clicker"></div>
                                </div>
                                <div *ngIf="service.serviceClass === 'CS'" class="staticCell">
                                    {{ lookupGatewayName(service) }}
                                </div>
                            </div>
                        </div>

                        <div *ngIf="ipNetworkServices.length > 0 && !isEmptyNetworkService(ipNetworkServices[0])">
                            <div class="title">Network Services - Multiple IPs</div>
                            <div class="tableGrid four header">
                                <div>Network Address</div>
                                <div>Intercept Address</div>
                                <div>Label</div>
                                <div>Gateway</div>
                            </div>
                            <!-- Need to check type and only show if Network -->
                            <div
                                *ngFor="let service of finalModel['services']; let index = index"
                                class="tableGrid four"
                            >
                                <!-- needed to add the ngIf on each div instead of using a parent div as the parent div broke the styling -->
                                <div *ngIf="service.serviceClass === 'GW'" class="staticCell">
                                    {{ service.gatewayIp + '/' + service.gatewayCidrBlock }}
                                </div>
                                <div *ngIf="service.serviceClass === 'GW'" class="staticCell">
                                    {{ service.interceptIp }}
                                </div>
                                <div *ngIf="service.serviceClass === 'GW'" class="staticCell" class="clickable link">
                                    {{ service.name }}
                                    <div (click)="openService(service)" class="clicker"></div>
                                    <div *ngIf="service.serviceClass === 'GW'" class="staticCell">
                                        {{ lookupGatewayName(service) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="summary">
                    <div class="step">4</div>
                    <div>
                        <div class="title">People, Places, Groups</div>

                        <label class="underline"
                            >Clients<span [hidden]="!hasNewClients" class="note"
                                >Enter an Email Address to Share Registration</span
                            ></label
                        >
                        <div *ngFor="let client of finalModel['clients']; let index = index" class="grid clientGrid">
                            <div [hidden]="client['registrationKey'] != null" data-private>
                                {{ client.name }}
                            </div>
                            <div [hidden]="client['registrationKey'] == null" class="gridLabel clickable" data-private>
                                {{ client.name }}
                                <div (click)="openClient(client)" class="clicker"></div>
                            </div>
                            <div [hidden]="client['registrationKey'] == null" data-private>
                                <input
                                    [(ngModel)]="sendto[index]"
                                    [ngClass]="{ error: errorEmails }"
                                    id="RegSend{{ index }}"
                                />
                            </div>
                            <div
                                (click)="SendRegistrationInline(client, index)"
                                *ngIf="sendingState[index] === ''"
                                [hidden]="client['registrationKey'] == null"
                                class="sendButton icon-Icon_Share"
                                id="SendButton_{{ index }}"
                            ></div>
                            <div *ngIf="sendingState[index] === 'sending'" class="sendButton sending">
                                <div class="spinner"></div>
                            </div>
                            <div *ngIf="sendingState[index] === 'sent'" class="sendButton sent icon-Icon_Check"></div>
                        </div>

                        <label class="underline">Gateways</label>
                        <div *ngFor="let gateway of finalModel['gateways']; let index = index" class="grid">
                            <div [hidden]="gateway['registrationKey'] != null">
                                {{ gateway.name }}
                                <div (click)="!showLauncher(gateway) && openGateway(gateway)" class="clicker"></div>
                            </div>
                            <div
                                [hidden]="gateway['registrationKey'] == null"
                                [ngClass]="{ noLauncherScript: !showLauncher(gateway) }"
                                class="launch"
                            >
                                {{ gateway.name }}
                                <div (click)="!showLauncher(gateway) && openGateway(gateway)" class="clicker"></div>
                                <div
                                    (click)="
                                        authorizationService.canGetDataCenter(gateway['dataCenterId']) &&
                                            LaunchCloudScript(gateway)
                                    "
                                    *ngIf="
                                        showLauncher(gateway) &&
                                        IsAWS(gateway) &&
                                        !IsHA(gateway) &&
                                        !isGatewayRegistered(gateway)
                                    "
                                    class="tap aws"
                                >
                                    Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                                </div>
                                <div
                                    (click)="LaunchAzureCloudScript(gateway)"
                                    *ngIf="
                                        showLauncher(gateway) &&
                                        IsAzure(gateway) &&
                                        !IsHA(gateway) &&
                                        !isGatewayRegistered(gateway)
                                    "
                                    class="tap azure"
                                >
                                    Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                                </div>
                                <div
                                    *ngIf="
                                        IsGoogle(gateway) &&
                                        !IsHA(gateway) &&
                                        !isGatewayRegistered(gateway) &&
                                        authorizationService.canGetDataCenter(gateway['dataCenterId'])
                                    "
                                    class="tap google"
                                >
                                    <span class="icon-ClickIcon"></span>
                                </div>
                            </div>
                        </div>

                        <label class="underline">Groups</label>
                        <div
                            *ngFor="let group of finalModel['endpointGroups']; let index = index"
                            class="grid groupGrid"
                        >
                            <div>
                                {{ group.name }}
                                <div (click)="editGroups(group, index)" class="clicker"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">&nbsp;</div>
            </div>

            <div>
                <div (click)="resetForm()" class="cloneButton">
                    <div class="icon"></div>
                    <div [innerHTML]="cloneMessage" class="content"></div>
                    <div class="buttonText">Tap Here</div>
                    <div class="tapIcon icon-ClickIcon"></div>
                </div>
            </div>
        </div>
    </div>
</div>
