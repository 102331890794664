import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BillingSummaryComponent } from './billing-summary.component';

@NgModule({
    imports: [CommonModule, RouterModule, SharedModelModule, UiPipesModule, UiLoaderModule, MatTooltipModule],
    declarations: [BillingSummaryComponent],
    exports: [BillingSummaryComponent],
})
export class FeatureBillingSummaryModule {}
