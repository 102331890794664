import { Resource } from '@lagoshny/ngx-hateoas-client';
import { SemanticTypes } from '../enums/semantics';

export class BaseServicePolicy extends Resource {
    id: string;
    networkId: string;
    zitiId: string;
    name: string;
    type: string;
    semantic: SemanticTypes;
    serviceAttributes: string[];
    edgeRouterAttributes: string[];
    postureCheckAttributes: string[];
    ownerIdentityId: string;
    createdAt: string;
    updatedAt: string;
}
