import { Component, Input } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { AppWan, Endpoint, Service } from '@netfoundry-ui/shared/model';
import {
    AppwanService,
    GatewayClusterService,
    GatewayService,
    LoggerService,
    ServiceService,
    ValidateService,
} from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-appwan-wizard-save',
    templateUrl: './appwan-wizard-save.component.html',
    styleUrls: ['./appwan-wizard-save.component.scss'],
})
export class AppwanWizardSaveComponent {
    @Input() appwanModel: AppWan;
    @Input() services;
    @Input() sourceGateway: Endpoint;
    @Input() targetGateway: Endpoint;
    @Input() step;
    @Input() showStep;
    @Input() titleString = null;

    stepStyle;

    errors = [];

    appwanCreated = false;
    servicesCreated = false;
    gatewaysCreated = false;
    gatewaysAreReady = false;
    gatewaysAreAssigned = false;
    servicesAreReady = false;
    servicesAreAssigned = false;

    servicesToAssign = [];
    servicesPending = [];
    servicesWithError = [];

    appwanIsReady = false;
    appwanError = false;
    appwanTimedOut = false;

    hasNewClients = false;

    finalModel;

    constructor(
        private logger: LoggerService,
        private appwanService: AppwanService,
        private growlerService: GrowlerService,
        private gatewayService: GatewayService,
        private serviceService: ServiceService,
        private validateService: ValidateService,
        private clusterService: GatewayClusterService,
        private authorizationService: AuthorizationService
    ) {}

    /**
     * Run validation and then save all appwan Feature in a semi-synchronous fashion
     */
    async save() {
        this.stepStyle = 'step0';
        let continueSave = true;
        // save the appwan record
        this.logger.info('Saving appwan...');
        continueSave = await this._saveAppWan();
        this.stepStyle = 'step1';

        if (!continueSave) {
            return null;
        }

        this.logger.info('Saving gateways...');
        continueSave = await this._createGateways();
        this.stepStyle = 'step2';

        if (!continueSave) {
            return null;
        }

        this.logger.info('Creating dependent services..');
        continueSave = await this._createServices();
        this.stepStyle = 'step3';

        if (!continueSave) {
            return null;
        }

        if (this.targetGateway != null) {
            this.logger.info('Verifying gateways are ready...');
            continueSave = await this._waitGateway(this.targetGateway);
            this.gatewaysAreReady = true;
            this.stepStyle = 'step4';

            if (!continueSave) {
                return null;
            }

            this.logger.info('Assigning gateways...');
            await this._assignGateways();
            this.stepStyle = 'step5';
        }

        await this._checkServicesReady();
        this.stepStyle = 'step6';

        // save services
        this.logger.info('Assigning services...');
        await this._assignServices();
        this.stepStyle = 'step7';

        this.logger.info('Getting final appwan summary');
        const result = await this._getFinalAppWan();
        return result;
    }

    /**
     * Check the appwan status
     */
    public async checkAppWanStatus() {
        this.logger.info('Checking AppWan Status...');
        const status = await this.appwanService
            .getResource(this.appwanModel.getSelfLink())
            .toPromise()
            .then((appwan) => appwan['status']);

        return status;
    }

    reset() {
        this.errors = [];

        this.appwanCreated = false;
        this.servicesCreated = false;
        this.gatewaysCreated = false;
        this.gatewaysAreReady = false;
        this.gatewaysAreAssigned = false;
        this.servicesAreReady = false;
        this.servicesAreAssigned = false;

        this.servicesToAssign = [];
        this.servicesPending = [];
        this.servicesWithError = [];

        this.appwanIsReady = false;
        this.appwanError = false;
        this.appwanTimedOut = false;

        this.hasNewClients = false;
    }

    private async _createGateways() {
        const proms = [];
        let success = true;

        if (this.sourceGateway != null && this.sourceGateway.id == null) {
            this.logger.info('SAVING SOURCE GATEWAY');
            const sourceGatewayProm = this.saveGateway(this.sourceGateway).then(
                (gateway) => {
                    this.sourceGateway = new Endpoint(gateway);
                },
                (error) => {
                    const msg = 'Source Gateway creation failed, check the console for further details';
                    this.errors.push(msg);
                    this.growlerService.show(new GrowlerData('error', 'Error', 'Source Gateway Creation Failed', msg));
                    this.logger.error('Source Gateway creation failed', error);
                    success = false;
                }
            );
            proms.push(sourceGatewayProm);
        }

        if (this.targetGateway != null && this.targetGateway.id == null) {
            this.logger.info('SAVING TARGET GATEWAY');
            const targetGatewayProm = this.saveGateway(this.targetGateway).then(
                (gateway) => {
                    this.targetGateway = new Endpoint(gateway);
                },
                (error) => {
                    const msg = 'Target Gateway creation failed, check the console for further details';
                    this.errors.push(msg);
                    this.growlerService.show(new GrowlerData('error', 'Error', 'Target Gateway Creation Failed', msg));
                    this.logger.error('Target Gateway creation failed', error);
                    success = false;
                }
            );
            proms.push(targetGatewayProm);
        }

        this.logger.info('AWAITING PROMISES', proms);
        await Promise.all(proms);

        this.gatewaysCreated = true;

        return success;
    }

    private saveGateway(gateway) {
        return this.gatewayService.save(gateway).toPromise();
    }

    /**
     * Save the appwan model so this we can attach stuff to it
     */
    private async _saveAppWan() {
        return await this.appwanService
            .save(this.appwanModel)
            .toPromise()
            .then(
                (appwan) => {
                    this.appwanModel = new AppWan(appwan);
                    this.appwanCreated = true;
                    this.logger.info('Appwan is saved!');
                    return true;
                },
                (error) => {
                    const msg = 'AppWan creation failed, check the console for further details';
                    this.errors.push(msg);
                    this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Failed', msg));
                    this.logger.error('AppWan creation failed', error);
                    return false;
                }
            );
    }

    private sleep(timeout) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(1);
            }, timeout);
        });
    }

    /**
     * Save services as a batch
     */
    private async _createServices() {
        const isGatewayReady = await this._waitGateway(this.sourceGateway);

        if (isGatewayReady) {
            // create each service one at a time
            const vals = [];
            let count = 1;
            for (let service of this.services) {
                service = this._processServiceModel(service);
                service.name = `${this.appwanModel.name} svc-${count < 10 ? '0' + count : count}`;
                vals.push(await this.serviceService.save(service).toPromise());
                await this.sleep(3000);
                count++;
            }
            this.logger.info('Service creation responses', vals);
            let success = true;
            for (const service of vals) {
                const model = new Service(service);
                if (model['id']) {
                    this.logger.info('Adding service ID to array: ', model.id);
                    this.servicesToAssign.push(model);
                } else {
                    this.logger.error('Unknown service creation response', service);
                    const msg = 'Unknown service creation response';
                    this.errors.push(msg);
                    this.growlerService.show(new GrowlerData('error', 'Error', 'Service creation failed', msg));
                    success = false;
                }
            }

            this.servicesCreated = true;
            return success;
        } else {
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Service creation failed',
                    'The gateway for the service is not in a provisioned state'
                )
            );
            return false;
        }
    }

    /**
     * Handle default values and input processing for service models
     */
    private _processServiceModel(service: Service) {
        if (this.validateService.hasValue(this.sourceGateway['protectionType'])) {
            service.gatewayClusterId = this.sourceGateway['id'];
        } else {
            service.endpointId = this.sourceGateway['id'];
        }

        if (service.serviceClass === 'CS') {
            return this._processHostServiceModel(service);
        } else if (service.serviceClass === 'GW') {
            return this._processNetworkServiceModel(service);
        }
    }

    /**
     * Handle default values and input processing for service models
     */
    private _processHostServiceModel(service: Service) {
        service.interceptIp = service.networkIp;

        // splitting the port range on -
        const portRange = service.networkFirstPort.toString().split('-');

        // handle port ranges
        if (portRange.length === 2) {
            // otherwise, if there are two items, using the first item as the first port and the second item as the last port
            service.networkFirstPort = portRange[0].trim();
            service.networkLastPort = portRange[1].trim();
            service.interceptFirstPort = portRange[0].trim();
            service.interceptLastPort = portRange[1].trim();
        } else {
            // if there is only one item in the list (i.e no -) use the same number as the first/last port numbers
            service.networkLastPort = service.networkFirstPort;
            service.interceptFirstPort = service.networkFirstPort;
            service.interceptLastPort = service.networkFirstPort;
        }

        return service;
    }

    private _processNetworkServiceModel(service: Service) {
        const ipAndCidrBlock = service.gatewayIp.split('/');
        service.gatewayIp = ipAndCidrBlock[0];
        if (ipAndCidrBlock.length === 2) {
            service.gatewayCidrBlock = Number(ipAndCidrBlock[1]);
        } else {
            service.gatewayCidrBlock = 0;
        }
        return service;
    }

    private async _waitGateway(gateway: Endpoint) {
        const maxIterations = 10;
        let currentIteration = 0;
        let isProvisioned = false;
        while (currentIteration < maxIterations) {
            // check the status on a timer
            const result = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(this._checkStatusGateway(gateway));
                }, 1000);
            });

            isProvisioned = result['isProvisioned'];

            if (result['isProvisioned'] || result['hasError']) {
                break;
            }

            currentIteration++;
        }
        return isProvisioned;
    }

    /**
     * Look at the current list of pending gateways and update the list with the items this are in a ready or error state
     */
    private async _checkStatusGateway(gateway: Endpoint) {
        let preselectedPromise;

        if (this.sourceGateway != null && this.sourceGateway['endpointProtectionRole'] != null) {
            preselectedPromise = this.clusterService.getResource(gateway.getSelfLink()).toPromise();
        } else {
            preselectedPromise = this.gatewayService.getResource(gateway.getSelfLink()).toPromise();
        }

        return await preselectedPromise.then((gateway) => {
            // gateway is ready
            if (gateway['status'] === 300 || gateway['status'] === 400) {
                this.logger.info('Gateway: ' + gateway.name + ' is ready to be added to a service');
                return { isProvisioned: true, hasError: false };
                // gateway had an error
            } else if (gateway['status'] >= 500) {
                this.logger.info('Gateway: ' + gateway.name + ' had an error and cannot be added to the service');
                return { isProvisioned: false, hasError: true };
            } else {
                return { isProvisioned: false, hasError: false };
            }
        });
    }

    /**
     * Attach gateways to the appwan
     */
    private async _assignGateways() {
        const isReady = await this._awaitAppWanReadyStatus();

        if (isReady) {
            return await this.appwanService
                .addEndpoints(this.appwanModel, [this.targetGateway.id])
                .toPromise()
                .then((res) => {
                    this.logger.info('Gateways assigned', res);
                    this.gatewaysAreAssigned = true;
                    return true;
                });
        } else {
            const msg =
                'Appwan timed out or errored out before assigning gateways. Not ready for to have resources added';
            this.errors.push(msg);
            this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Timed Out', msg));
            return false;
        }
    }

    /**
     * AppWan status checker on a timer. Loops until appwan is ready or it times out
     */
    private async _awaitAppWanReadyStatus() {
        // max number of iterations
        const maxIterations = 60;
        // current iteration
        let currentIteration = 0;

        const timeout = 1000;

        // if we're calling this function just assume this the appWan is not ready until we've checked it again
        this.appwanIsReady = false;

        // looping until the appwan either becomes active or the max iterations is hit
        while (this.appwanIsReady === false && currentIteration < maxIterations) {
            this.logger.info('Check AppWan Status Iteration: ' + currentIteration);

            // check the status on a timer
            const promise = new Promise((resolve) => {
                setTimeout(() => {
                    // getting the AppWan status
                    resolve(this.checkAppWanStatus());
                }, timeout);
            });

            // getting the status after the promise is resolved
            const status = await promise;
            this.logger.info('AppWan Status is: ' + status);
            // if the status is 300, mark isFinished as true and isActive as true
            if (status === 300) {
                this.appwanIsReady = true;
                break;
                // if the status is either in error or the appwan is deleting, mark isFinished as true and isActive as false
            } else if (status === 500 || (status as number) >= 700) {
                this.appwanError = true;
            }

            currentIteration++;
        }

        if (currentIteration === maxIterations) {
            this.appwanTimedOut = true;
            const msg = 'AppWan Creation Appears to have timed out!';
            this.errors.push(msg);
            this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Timed Out', msg));
            return false;
        }

        if (this.appwanError) {
            const msg = 'AppWan Creation failed with an error, check the console';
            this.errors.push(msg);
            this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Failed', msg));
            return false;
        }

        this.logger.info('AppWan is in Ready status');
        return true;
    }

    /**
     * The batch service add will fail if any of the services are not in a ready state, make sure they are all ready before proceeding
     */
    private async _checkServicesReady() {
        this.servicesPending = this.servicesToAssign.concat([]);
        this.servicesToAssign = [];

        const maxIterations = 10;
        let currentIteration = 0;

        this.logger.info('Services pending: ', this.servicesPending);
        while (this.servicesPending.length > 0 && currentIteration < maxIterations) {
            this.logger.info('Check Pending Service Status Iteration: ' + currentIteration);
            this.logger.info('Pending Services: ' + this.servicesPending.length);

            // check the status on a timer
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(this._processPendingServices());
                }, 1000);
            });

            currentIteration++;
        }

        // pop a growler if some of the clients couldn't be added
        if (this.servicesPending.length > 0 || this.servicesWithError.length > 0) {
            // push the endpoints this timed out into the error list
            const failedNames = [];
            for (const service of this.servicesPending) {
                failedNames.push(service.name);
            }

            for (const service of this.servicesWithError) {
                failedNames.push(service.name);
            }

            // error growler for now
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Problem Validating Services',
                    'The following services were unable to be added to the appwan: ' + failedNames
                )
            );
        }

        this.servicesAreReady = true;

        return true;
    }

    /**
     * Attach services to the appwan
     */
    private async _assignServices() {
        // check the status on a timer
        const promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                this.logger.info('forcing a wait in case transfer nodes are building');
                resolve(true);
            }, 5000);
        });

        await promise;

        const isReady = await this._awaitAppWanReadyStatus();

        const serviceIds = [];
        for (const service of this.servicesToAssign) {
            if (this.validateService.hasValue(service.id)) {
                serviceIds.push(service.id);
            }
        }

        if (isReady) {
            return await this.appwanService
                .addServices(this.appwanModel, serviceIds)
                .toPromise()
                .then(
                    (res) => {
                        this.logger.info('Services are assigned', res);
                        this.servicesAreAssigned = true;
                        return true;
                    },
                    (error) => {
                        const msg = 'An error occurred while assigning services. AppWan creation aborted';
                        this.errors.push(msg);
                        this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Failed', msg));
                        this.logger.error('Service creation failed', error);
                        return false;
                    }
                );
        } else {
            const msg =
                'Appwan timed out or errored out before assigning services. Not ready for to have resources added';
            this.errors.push(msg);
            this.growlerService.show(new GrowlerData('error', 'Error', 'AppWan Creation Timed Out', msg));
            return false;
        }
    }

    /**
     * Look at the current list of pending clients and update the list with the items this are in a ready or error state
     */
    private async _processPendingServices() {
        // check all clients at once
        const proms = [];
        for (let service of this.servicesPending) {
            service = new Service(service);
            proms.push(
                this.serviceService
                    .getResource(service.getSelfLink())
                    .toPromise()
                    .then((res) => res)
            );
        }

        return await Promise.all(proms).then((vals) => {
            this.logger.info('Service readiness responses', vals);
            for (let service of vals) {
                service = new Service(service);

                // client is ready
                if (service['status'] === 300) {
                    this.servicesToAssign.push(service);
                    this.logger.info('Service: ' + service.name + ' is ready to be added');
                    this._deleteServicePending(service);

                    // client had an error
                } else if (service['status'] >= 500) {
                    this.logger.info('Service: ' + service.name + ' had an error and cannot be added');
                    this.servicesWithError.push(service);
                    this._deleteServicePending(service);
                } else {
                    // do nothing if the client is less than 300, it's still building
                    this.logger.info('Pending', this.servicesPending);
                }
            }
        });
    }

    /**
     * Purge the pending service from the list so we don't keep on looping
     */
    private _deleteServicePending(service) {
        for (let i = 0; i < this.servicesPending.length; i++) {
            const pending = this.servicesPending[i];
            if (pending.name === service.name) {
                this.servicesPending.splice(i, 1);
                break;
            }
        }
    }

    /**
     * Build the finalized appwan model this actually made it into the backend. Expand all of the resource links so we know what's in it
     */
    private async _getFinalAppWan() {
        // get the base model
        this.finalModel = await this.appwanService
            .getResource(this.appwanModel.getSelfLink())
            .toPromise()
            .then((appwan) => new AppWan(appwan));

        // batch retrieve all of the child resources
        const proms = [];

        // The next three if statements are a quick and dirty solution to a messy issue introduced by permissioning
        // it is possible this a user may not have the ability to list services, endpoint groups, or endpoints but they may have permission to create them
        // in this (probably rare) scenario, the console should not make a GET request for the services,groups, or endpoints (based on the user's permission)
        // not having all three of the promises would then break the final aggregate promise as it gets the results from the individual promises based on the index

        if (this.authorizationService.canListServices()) {
            proms.push(
                this.appwanService
                    .getLinkedResources(this.finalModel, 'services')
                    .toPromise()
                    .then((services) => services)
            );
        } else {
            proms.push(
                new Promise((resolve, reject) => {
                    resolve([]);
                })
            );
        }

        if (this.authorizationService.canListEndpoints()) {
            proms.push(
                this.appwanService
                    .getLinkedResources(this.finalModel, 'endpoints')
                    .toPromise()
                    .then((endpoints) => endpoints)
            );
        } else {
            proms.push(
                new Promise((resolve, reject) => {
                    resolve([]);
                })
            );
        }

        await Promise.all(proms).then((values) => {
            const services = values[0];
            const endpoints = values[1];

            this.finalModel['services'] = services;
            this.finalModel['gateways'] = endpoints;
            if (this.targetGateway != null) {
                this.finalModel['targetGateway'] = this.targetGateway;
                this.finalModel['targetGatewayName'] = this.targetGateway['name'];
            }

            // get the hosting gateway model
            if (services.length > 0) {
                const service = new Service(services[0]);

                this.finalModel['hostingGateway'] = this.sourceGateway;
                this.finalModel['hostingGatewayName'] = this.sourceGateway['name'];
            }
        });

        return this.finalModel;
    }
}
