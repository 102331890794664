<div [ngClass]="{ inline: isInline, readOnly: !canEdit && !isNew }" class="fullModal opened" id="ZitiServiceAddModal">
    <div [hidden]="isComplete" class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span *ngIf="!isEditing">Create A New Service</span>
            <span *ngIf="isEditing">Edit Your Service</span>
        </div>
        <div *ngIf="!isEditing" class="subtitle">Enter your service details</div>
        <div *ngIf="isEditing" class="subtitle">Edit your service details</div>
        <div
            class="subhelp"
            matTooltip="A service defines how connections are created for applications. Services are a fundamental building block of a NetFoundry overlay network."
            matTooltipPosition="below"
        >
            What's a Service?
            <div class="infoicon"></div>
        </div>
        <div class="sized full">
            <div class="grid two">
                <div>
                    <div class="label row">
                        Service Name
                        <span class="note" [hidden]="errors['name']">Required</span>
                    </div>
                    <div class="row">
                        <input
                            (keyup.enter)="save()"
                            [(ngModel)]="model.name"
                            [disabled]="isEditing && !canEdit"
                            [ngClass]="{ error: errors['name'] }"
                            id="EditName"
                            (change)="validate()"
                            placeholder="Name this service"
                            type="text"
                        />
                        <span [hidden]="!errors['name']" class="err">
                            {{ errors['name'] }}
                        </span>
                    </div>

                    <div class="label row">
                        Service Attributes
                        <div
                            class="infoicon"
                            matTooltip="Service Attributes are tags applied to a service. Apply the same tag to other services to form a group of services."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">Optional</span>
                    </div>
                    <div class="row">
                        <app-tags-selector
                            (addAttribute)="addServiceAttribute($event)"
                            (removeAttribute)="removeServiceAttribute($event)"
                            [availableAttributes]="serviceAttributes"
                            [disableField]="isEditing && !canEdit"
                            [hasError]="attributeError"
                            [errorMessage]="errors['attributes']"
                            [isLoading]="isLoadingSvcAttr"
                            [placeholder]="'Add attributes to group services'"
                            [selectedAttributes]="selectedServiceAttributes"
                            [errorMessage]="errors['attributes']"
                            id="Service"
                        ></app-tags-selector>
                    </div>

                    <div *ngIf="model.modelType !== 'TunnelerToEdgeRouter'">
                        <div [ngClass]="{ disabled: isEditing && !canEdit }" class="label row">
                            Edge Router Attributes
                            <div
                                class="infoicon"
                                matTooltip="The edge routers participating in this service. For all edge routers leave this field blank."
                                matTooltipPosition="below"
                            ></div>
                            <span class="note">Optional</span>
                        </div>
                        <div class="row">
                            <app-tags-selector
                                (addAttribute)="addEdgeRouterAttribute($event)"
                                (removeAttribute)="removeEdgeRouterAttribute($event)"
                                [availableAttributes]="edgeRouterAttributes"
                                [disableField]="isEditing && !canEdit"
                                [hasError]="erAttributeError"
                                [isLoading]="isLoadingEdgeRouterAttr"
                                [placeholder]="'Default: #all, restricts routers available to this service'"
                                [selectedAttributes]="selectedEdgeRouterAttributes"
                                id="Router"
                            ></app-tags-selector>
                        </div>
                    </div>
                    <div class="label row">
                        End-to-End Encryption
                        <div
                            class="infoicon"
                            matTooltip="Encrypts packets from intercept to termination."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">OPTIONAL</span>
                    </div>
                    <div class="row">
                        <div class="encryptionpod">
                            <span class="encryptiontext">ENCRYPT THIS SERVICE</span>
                            <div
                                (click)="toggleEncryption()"
                                [ngClass]="{
                                    on: model.encryptionRequired,
                                    disabled: disableEncryptionToggle
                                }"
                                [title]="
                                    disableEncryptionToggle
                                        ? 'Encryption must be enabled for advanced services on networks prior to version 7.3.91'
                                        : isEditing
                                        ? 'Encryption can\'t be changed once service has been created'
                                        : ''
                                "
                                class="toggle"
                                style="float: right; margin-top: 15px; margin-right: 20px"
                            >
                                <span [hidden]="!model.encryptionRequired" style="margin-left: -15px">YES</span>
                                <span [hidden]="model.encryptionRequired" style="margin-right: -15px">NO</span>
                                <div class="switch"></div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showSourceTransparency" class="label row">
                        SOURCE TRANSPARENCY
                        <div
                            class="infoicon"
                            matTooltip="Enabling the source transparency allows the source IP of the device to be visible at the destination app of host."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">OPTIONAL</span>
                    </div>
                    <div *ngIf="showSourceTransparency" class="darkBox transparency-pod">
                        <div>
                            <span class="encryptiontext">ENABLE SOURCE TRANSPARENCY</span>
                            <div
                                (click)="toggleTransparency()"
                                [ngClass]="{ on: model.model.sourceIpConfigType === 'Transparency' }"
                                class="toggle"
                                style="float: right; margin-top: 15px; margin-right: 20px"
                            >
                                <span
                                    [hidden]="model.model.sourceIpConfigType === 'Transparency'"
                                    style="margin-right: -15px"
                                    >NO</span
                                >
                                <span [hidden]="!model.model.sourceIpConfigType" style="margin-left: -15px">YES</span>
                                <div class="switch"></div>
                            </div>
                        </div>
                        <div
                            *ngIf="model.model.sourceIpConfigType === 'Transparency'"
                            [ngClass]="{ error: errors['sourceAddress'] }"
                            class="label"
                        >
                            ALLOWED SOURCE ADDRESSES
                        </div>
                        <p-chips
                            (keydown)="onKeyDown($event)"
                            *ngIf="model.model.sourceIpConfigType === 'Transparency'"
                            [(ngModel)]="model.model.sourceIpConfig.allowedSourceAddresses"
                            [allowDuplicate]="false"
                            [ngClass]="{ error: errors['sourceAddress'] }"
                            [placeholder]="'10.10.0.0/24'"
                            addOnBlur="true"
                            separator=","
                        >
                        </p-chips>
                        <span style="padding-bottom: 5px" [hidden]="!errors['allowedSourceAddresses']" class="err">
                            {{ errors['allowedSourceAddresses'] }}
                        </span>
                    </div>

                    <div *ngIf="isEditing" class="associated row">
                        <app-preview-list
                            (itemSelected)="endpointSelected($event)"
                            [allNames]="assocEndpointNames"
                            [clickable]="!isInline"
                            label="Associated Endpoints"
                        ></app-preview-list>
                        <app-preview-list
                            (itemSelected)="routerSelected($event)"
                            *ngIf="featureService.powerUserEnabled"
                            [allNames]="assocRouterNames"
                            [clickable]="!isInline"
                            label="Associated Edge Routers"
                        ></app-preview-list>
                    </div>
                </div>
                <div>
                    <div class="label row">
                        Client Intercept Configuration
                        <div
                            class="infoicon"
                            matTooltip="IP(s)/Hostname(s) or IP Ranges, Protocols and Port Ranges used by the client to reach the Destination/App."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">REQUIRED</span>
                    </div>
                    <div class="darkBox mb16">
                        <div [ngClass]="{ closed: !isClientOpen }">
                            <div [ngClass]="{ error: errors['clientProtocol'] }" class="label">PROTOCOL(S)</div>
                            <section
                                [ngClass]="{ error: errors['clientProtocol'] }"
                                class="mini-pod checkboxes grid two"
                                style="text-align: left"
                            >
                                <mat-checkbox
                                    (change)="protocolCheckboxSelection()"
                                    [(ngModel)]="tcp"
                                    class="round-checkbox mt6 mb6"
                                >
                                    TCP
                                </mat-checkbox>
                                <mat-checkbox
                                    (change)="protocolCheckboxSelection()"
                                    [(ngModel)]="udp"
                                    class="round-checkbox mt6 mb6"
                                >
                                    UDP
                                </mat-checkbox>
                                <span [hidden]="!errors['clientProtocolsError']" class="err">
                                    {{ errors['clientProtocolsError'] }}
                                </span>
                            </section>
                            <div [ngClass]="{ error: errors['clientAddress'] }" class="label">
                                INTERCEPT IP / INTERCEPT RANGES
                                <div
                                    class="infoicon"
                                    matTooltip="Hostnames must include a domain & sub-domain seperated by a “.” Example: example.domain.com"
                                    matTooltipPosition="below"
                                ></div>
                            </div>
                            <div *ngIf="errors['clientAddress']" class="error-message" class="label">
                                {{ ingressErrorMessage }}
                            </div>
                            <p-chips
                                (onAdd)="validate()"
                                (keydown)="onKeyDown($event)"
                                [(ngModel)]="model.model.clientIngress.addresses"
                                [allowDuplicate]="false"
                                [ngClass]="{ error: errors['clientAddress'] }"
                                [placeholder]="'10.10.0.0/24 domain.com'"
                                addOnBlur="true"
                                separator=","
                                (onRemove)="validate()"
                            >
                            </p-chips>
                            <span [hidden]="!errors['clientHostsError']" class="err">
                                {{ errors['clientHostsError'] }}
                            </span>
                            <div class="mb8">
                                <div [ngClass]="{ error: errors['clientPort'] }" class="label">PORT / RANGES</div>
                                <p-chips
                                    (keydown)="onKeyDown($event)"
                                    [(ngModel)]="ports"
                                    [allowDuplicate]="false"
                                    [ngClass]="{ error: errors['clientPort'] }"
                                    [placeholder]="'80 120-125 443 8080'"
                                    addOnBlur="true"
                                    separator=","
                                    (onAdd)="validate()"
                                >
                                </p-chips>
                                <span [hidden]="!errors['clientPortsError']" class="err">
                                    {{ errors['clientPortsError'] }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="previewBox mb8">
                        <div class="grid two preview">
                            <div class="previewTitle">CLIENT<br />CONFIG<br />PREVIEW</div>
                            <div
                                *ngIf="showProtocolsConfigString || showAddressesConfigString || showPortsConfigString"
                                class="previewContent"
                            >
                                {{ previewConfigText }}
                            </div>
                        </div>
                    </div>

                    <div class="label row">
                        Destination/App Configuration
                        <div
                            class="infoicon"
                            matTooltip="Destination/app Configuration including terminating {{
                                featureService?.disableNewNavigation ? 'endpoints' : 'identities'
                            }} & specific destination details if forwarding is disabled."
                            matTooltipPosition="below"
                        ></div>
                        <span class="note">REQUIRED</span>
                    </div>
                    <div class="darkBox">
                        <div class="row">
                            <div class="label">
                                Select {{ featureService?.disableNewNavigation ? 'Endpoints' : 'Identities' }}
                                <div
                                    class="infoicon"
                                    matTooltip="One or more {{
                                        featureService?.disableNewNavigation ? 'endpoints' : 'identities'
                                    }} which will handle this service."
                                    matTooltipPosition="below"
                                ></div>
                            </div>
                            <app-tags-selector
                                (addAttribute)="addEndpointsAttributes($event)"
                                (removeAttribute)="removeEndpointAttribute($event)"
                                [availableAttributes]="endpointAttributes"
                                [disableField]="isEditing && !canEdit"
                                [hasError]="epAttributeError"
                                [isLoading]="isLoadingEndpointAttr"
                                [placeholder]="
                                    'Choose the ' +
                                    (featureService?.disableNewNavigation ? 'endpoints' : 'identities') +
                                    ' to associate to this Service'
                                "
                                [selectedAttributes]="selectedEndpointAttributes"
                                id="endpointInput"
                                [errorMessage]="errors['bindEndpointAttributes']"
                            ></app-tags-selector>
                            <div class="darkBoxSub">
                                <div class="row">
                                    <div>
                                        <div>
                                            <div class="grid two toggles">
                                                <div [ngClass]="{ error: errors['serverProtocol'] }" class="label">
                                                    Forward Protocol
                                                </div>
                                                <div>
                                                    <div
                                                        (click)="protocolForwardingAllowedToggle()"
                                                        [ngClass]="{ on: protocolForwardingAllowed }"
                                                        class="toggle"
                                                        style="margin-top: 8px; float: right"
                                                    >
                                                        <span
                                                            [hidden]="protocolForwardingAllowed"
                                                            style="margin-right: -15px"
                                                            >NO</span
                                                        >
                                                        <span
                                                            [hidden]="!protocolForwardingAllowed"
                                                            style="margin-left: -15px"
                                                            >YES</span
                                                        >
                                                        <div class="switch"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <select
                                                (change)="protocolSelection($event)"
                                                *ngIf="!protocolForwardingAllowed"
                                                [(ngModel)]="model.model.serverEgress.protocol"
                                                [disabled]="isEditing && !canEdit"
                                                [ngClass]="{ error: errors['protocol'] }"
                                                id="ConfigRouterProtocol"
                                            >
                                                <option [ngValue]="undefined">Protocol Type</option>
                                                <option value="tcp">TCP</option>
                                                <option value="udp">UDP</option>
                                            </select>
                                            <section
                                                *ngIf="protocolForwardingAllowed"
                                                class="mini-pod checkboxes grid two forwarded-protocol"
                                                style="text-align: left"
                                            >
                                                <mat-checkbox
                                                    *ngIf="tcp"
                                                    [(ngModel)]="tcp"
                                                    [disabled]="true"
                                                    class="round-checkbox mt6 mb6"
                                                >
                                                    TCP
                                                </mat-checkbox>
                                                <mat-checkbox
                                                    *ngIf="udp"
                                                    [(ngModel)]="udp"
                                                    [disabled]="true"
                                                    class="round-checkbox mt6 mb6"
                                                >
                                                    UDP
                                                </mat-checkbox>
                                            </section>
                                            <span [hidden]="!errors['serverAllowedProtocolsError']" class="err">
                                                {{ errors['serverAllowedProtocolsError'] }}
                                            </span>
                                            <div class="grid two toggles">
                                                <div [ngClass]="{ error: errors['serverHostName'] }" class="label">
                                                    Forward Address
                                                </div>
                                                <div>
                                                    <div
                                                        (click)="hostForwardingAllowedToggle()"
                                                        [ngClass]="{ on: hostForwardingAllowed }"
                                                        class="toggle"
                                                        style="margin-top: 8px; float: right"
                                                    >
                                                        <span
                                                            [hidden]="hostForwardingAllowed"
                                                            style="margin-right: -15px"
                                                            >NO</span
                                                        >
                                                        <span
                                                            [hidden]="!hostForwardingAllowed"
                                                            style="margin-left: -15px"
                                                            >YES</span
                                                        >
                                                        <div class="switch"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                *ngIf="!hostForwardingAllowed"
                                                [(ngModel)]="model.model.serverEgress.host"
                                                [disabled]="isEditing && !canEdit"
                                                [ngClass]="{ error: errors['serverHostName'] }"
                                                id="ConfigRouterHostName"
                                                placeholder="host.name"
                                            />
                                            <p-chips
                                                *ngIf="hostForwardingAllowed"
                                                [(ngModel)]="model.model.clientIngress.addresses"
                                                [allowDuplicate]="false"
                                                [disabled]="true"
                                                [placeholder]=""
                                                addOnBlur="true"
                                                class="forwarded-addresses"
                                                separator=","
                                            >
                                            </p-chips>
                                            <span [hidden]="!errors['serverAllowedHostsError']" class="err">
                                                {{ errors['serverAllowedHostsError'] }}
                                            </span>
                                            <div class="grid two toggles">
                                                <div [ngClass]="{ error: errors['serverPort'] }" class="label">
                                                    Forward Port
                                                </div>
                                                <div>
                                                    <div
                                                        (click)="portForwardingAllowedToggle()"
                                                        [ngClass]="{ on: portForwardingAllowed }"
                                                        class="toggle"
                                                        style="margin-top: 8px; float: right"
                                                    >
                                                        <span
                                                            [hidden]="portForwardingAllowed"
                                                            style="margin-right: -15px"
                                                            >NO</span
                                                        >
                                                        <span
                                                            [hidden]="!portForwardingAllowed"
                                                            style="margin-left: -15px"
                                                            >YES</span
                                                        >
                                                        <div class="switch"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                *ngIf="!portForwardingAllowed"
                                                [(ngModel)]="model.model.serverEgress.port"
                                                [disabled]="(isEditing && !canEdit) || portForwardingAllowed"
                                                [ngClass]="{ error: errors['serverPort'] }"
                                                id="ConfigRouterPort"
                                                placeholder="0"
                                                type="number"
                                            />
                                            <p-chips
                                                *ngIf="portForwardingAllowed"
                                                [(ngModel)]="ports"
                                                [allowDuplicate]="false"
                                                [disabled]="true"
                                                [ngClass]="{ error: errors['clientPort'] }"
                                                [placeholder]="''"
                                                addOnBlur="true"
                                                separator=","
                                            ></p-chips>
                                            <span [hidden]="!errors['serverAllowedPortsError']" class="err">
                                                {{ errors['serverAllowedPortsError'] }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isEditing || canEdit" class="row buttonRow right" style="margin-top: 20px">
                        <button
                            (click)="save()"
                            *ngIf="!processing && !isLoading"
                            class="save"
                            id="SaveServiceZitiButton"
                        >
                            {{ bulkEdit ? 'Done' : isEditing ? 'Update' : 'Create' }}
                        </button>
                        <button *ngIf="isLoading || processing" class="save confirm" disabled>
                            {{ isLoading ? 'Loading' : 'Saving' }}
                            <span class="spinner"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div (click)="getMetrics()" *ngIf="isEditing" class="buttonBall metrics" id="MetricsButton">
        <div class="buttonLabel">METRICS</div>
    </div>
    <div (click)="getDialLogs()" *ngIf="isEditing" class="buttonBall network-failure" id="DialLogsButton">
        <div class="buttonLabel">Dial Logs</div>
    </div>

    <div (click)="hide(true)" class="buttonBall close" id="EscZitiServiceClose">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
