import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { FromDatePipe, TableSearchPipe } from '@netfoundry-ui/ui/pipes';

@Injectable({
    providedIn: 'root',
})
export class TableFilterService {
    setPageEvent: EventEmitter<number> = new EventEmitter();
    setFilterEvent: EventEmitter<string> = new EventEmitter();
    resetEvent: EventEmitter<boolean> = new EventEmitter();
    setUTC: EventEmitter<boolean> = new EventEmitter();
    setNewStartTime: EventEmitter<string> = new EventEmitter();
    setNewEndTime: EventEmitter<string> = new EventEmitter();
    setDateFilterEvent: EventEmitter<boolean> = new EventEmitter();
    endTime: number = Date.now();
    startTime: number = this.endTime - 7 * (24 * 60 * 60 * 1000);
    externalFiltersChanged: EventEmitter<any> = new EventEmitter();
    filterRemoved: EventEmitter<any> = new EventEmitter();
    showTextInput: EventEmitter<any> = new EventEmitter();
    public externalFilters = [];
    private pageNumber = 1;
    private pageSize = this.environment.pageSize;
    private totalElements = 1;
    private totalPages = 1;
    private filterString = '';
    private isUTC = false;
    private newStartTime;
    private newEndTime;
    private filterTimer;
    private timeout = 300;
    private dateFilter;

    constructor(
        private tableSearchPipe: TableSearchPipe,
        private fromDate: FromDatePipe,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    getStartElementNumber() {
        let lastElementNumber = this.pageNumber * this.pageSize;
        lastElementNumber = Math.min(lastElementNumber, this.totalElements);
        if (lastElementNumber < (this.pageNumber - 1) * this.pageSize + (this.totalElements === 0 ? 0 : 1)) {
            return 1;
        } else {
            return (this.pageNumber - 1) * this.pageSize + (this.totalElements === 0 ? 0 : 1);
        }
    }

    getLastElementNumber() {
        const lastElementNumber = this.pageNumber * this.pageSize;
        return Math.min(lastElementNumber, this.totalElements);
    }

    getTimeFormat() {
        this.setUTC.emit(true);
    }

    getPageNumber() {
        return this.pageNumber;
    }

    getNewStartTime() {
        this.setNewStartTime.emit(this.newStartTime);
    }

    getNewEndTime() {
        this.setNewEndTime.emit(this.newEndTime);
    }

    getPageSize() {
        return this.pageSize;
    }

    setPageSize(size: number) {
        this.pageSize = size;
    }

    getTotalElements() {
        return this.totalElements;
    }

    setTotalElements(totalElements: number) {
        this.totalElements = totalElements;
    }

    // function for determining whether or not pagination is in use
    isUsingPagination() {
        return this.totalElements > this.pageSize;
    }

    nextPage() {
        this.setPage(this.pageNumber + 1);
    }

    prevPage() {
        this.setPage(this.pageNumber - 1);
    }

    setPage(pageNumber: number, skipEmit?: boolean) {
        if (this.isUsingPagination() && pageNumber > 0 && pageNumber <= this.totalPages) {
            this.pageNumber = pageNumber;
        } else if (pageNumber < 1) {
            this.pageNumber = 1;
        }

        if (!skipEmit) {
            this.setPageEvent.emit(this.pageNumber);
        }
    }

    updateTotalPages() {
        this.setTotalPages(Math.ceil(this.totalElements / this.pageSize));
    }

    setTotalPages(totalPages: number) {
        if (totalPages < 1) {
            this.totalPages = 1;
        } else {
            this.totalPages = totalPages;
        }
    }

    getTotalPages() {
        return this.totalPages;
    }

    isLastPage() {
        return this.pageNumber === this.totalPages;
    }

    isFirstPage() {
        return this.pageNumber === 1;
    }

    setFilterString(filterString: string) {
        this.filterString = filterString;

        if (!this.isUsingPagination) {
            this.setFilterEvent.emit(this.filterString);
        } else {
            clearTimeout(this.filterTimer);
            this.filterTimer = setTimeout(() => {
                this.setFilterEvent.emit(this.filterString);
            }, this.timeout);
        }
    }

    getFilterString() {
        return this.filterString;
    }

    applyLocalFilter(items: any[], searchFunction?, searchParams?, skipPageUpdates?) {
        const displayedItems = this.tableSearchPipe.transform(items, this.filterString, searchFunction, searchParams);
        this.setTotalElements(displayedItems.length);
        if (!skipPageUpdates) {
            this.updateTotalPages();
            if (this.getPageNumber() > this.getTotalPages()) {
                this.pageNumber = this.getTotalPages();
                this.setPageEvent.emit(this.pageNumber);
            }
        }
        return displayedItems;
    }

    setDateRange(startTime, endTime) {
        this.startTime = startTime;
        this.endTime = endTime;
        this.setDateFilterEvent.emit(true);
    }

    setDateFilter(value) {
        this.dateFilter = value;
        this.setStartTime(this.dateFilter);
        this.setEndTime();
        this.setDateFilterEvent.emit(true);
    }

    getDateFilter() {
        return this.dateFilter;
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setStartTime(value) {
        this.startTime = this.fromDate.transform(value);
    }

    getStartTime() {
        return this.startTime;
    }

    public setEndTime() {
        this.endTime = Date.now();
    }

    getEndTime() {
        return this.endTime;
    }

    setExternalFilters(filters) {
        this.externalFilters = filters;
        this.externalFiltersChanged.emit(this.externalFilters);
    }

    removeFilter(filter) {
        this.filterRemoved.emit(filter);
    }

    reset() {
        this.pageNumber = 1;
        this.pageSize = 30;
        this.totalElements = 1;
        this.totalPages = 1;
        this.filterString = '';
        this.dateFilter = '7d';
        this.setStartTime(this.dateFilter);
        this.setEndTime();
        this.resetEvent.emit(true);
    }
}
