import { Inject, Injectable } from '@angular/core';
import { ConfigType, Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import { HateoasResourceOperation, PagedGetOption, PagedResourceCollection } from '@lagoshny/ngx-hateoas-client';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConfigTypeService extends HateoasResourceOperation<ConfigType> {
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;

    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(ConfigType);
    }

    getResource(): Observable<ConfigType> {
        throw new Error('Do not use: see get getEndpoint');
    }

    getPage(): Observable<PagedResourceCollection<ConfigType>> {
        throw new Error('Do not use: see getEndpoints');
    }

    public getConfigPage(options: PagedGetOption): Promise<ConfigType[]> {
        const params = { ...ConfigTypeService.defaultHttpAccept, ...options };
        return super
            .getPage(params)
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((configs) => configs.resources);
    }

    public getJson(configTypeId: string): Observable<string> {
        const options: any = {};
        const url = `${this.environment.v2apiUrl}config-types/${configTypeId}/?meta=ziti,diffZiti`;
        return this.http.get(url, options) as Observable<any>;
    }

    public validateUpdate(configTypeId: string, model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .patch(this.environment.v2apiUrl + 'config-types/' + configTypeId, model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public async validateCreate(model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .post(this.environment.v2apiUrl + 'config-types', model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public downloadFileFormat(
        networkId: string,
        format = 'text/csv',
        params: HttpParams = new HttpParams()
    ): Observable<any> {
        params = params.set('networkId', networkId);
        const headers = new HttpHeaders().set('Accept', format);
        return this.http.get(this.environment.v2apiUrl + 'config-types', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }
}
