import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GatewaydashboardComponent } from '@netfoundry-ui/feature/dashboard/gateway-dashboard';
import { ServicesformComponent } from '@netfoundry-ui/feature/form/services-form';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Endpoint } from '@netfoundry-ui/shared/model';
import {
    AzureDeployService,
    GatewayService,
    GetCloudformationLinkService,
    NetworkVersionService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-appwan-s3-wizard-summary',
    templateUrl: './appwan-s3-wizard-summary.component.html',
    styleUrls: ['./appwan-s3-wizard-summary.component.scss'],
})
export class AppwanS3WizardSummaryComponent implements OnInit {
    @Input() step;
    @Input() showStep;
    @Input() finalModel;

    @Input() ipNetworkServices;

    @Input() sourceGatewayString;
    disableAzureStackDeploy = false;
    // todo remove
    hideLaunchButton = false;
    private subscription: Subscription = new Subscription();

    constructor(
        private authorizationService: AuthorizationService,
        private gatewayService: GatewayService,
        private cfService: GetCloudformationLinkService,
        public dialogInlineRef: MatDialogRef<ServicesformComponent>,
        public dialogInlineGWRef: MatDialogRef<GatewaydashboardComponent>,
        public dialogForm: MatDialog,
        public validateService: ValidateService,
        private azureDeployService: AzureDeployService,
        private networkVersionService: NetworkVersionService
    ) {}

    ngOnInit() {
        // TODO remove
        this.hideLaunchButton = this.networkVersionService.currentNetworkVersion > 4;

        this.azureDeployService.canShowAzureFormationLink('AZSGW').subscribe((result) => {
            this.disableAzureStackDeploy = !result;
        });
    }

    showAzureLauncher(gateway) {
        if (this.disableAzureStackDeploy && gateway.endpointType === 'AZSGW') {
            return false;
        } else {
            return true;
        }
    }

    showLauncher(gateway) {
        if (gateway != null) {
            return (
                !this.isGatewayRegistered(gateway) &&
                !this.IsHA(gateway) &&
                (this.IsAWS(gateway) ||
                    (this.IsAzure(gateway) &&
                        !(this.hideLaunchButton && gateway.endpointType === 'AZSGW') &&
                        this.showAzureLauncher(gateway)))
            );
        }
        return false;
    }

    isGatewayRegistered(gateway) {
        if (gateway != null) {
            return gateway['status'] > 300;
        }
        return false;
    }

    IsAzure(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }
        return gateway.endpointType.indexOf('AZ') >= 0;
    }

    IsAWS(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }
        return gateway.endpointType.indexOf('AWS') >= 0;
    }

    IsHA(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }
        return gateway['protectionGroupId'] != null;
    }

    IsGoogle(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }

        return gateway.endpointType === 'GCPCPEGW';
    }

    openService(data) {
        this.dialogInlineRef = this.dialogForm.open(ServicesformComponent, {
            data: { model: data, inline: true },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.dialogInlineRef.afterClosed().subscribe(() => {
            /// Refresh Screen??
        });
    }

    openGateway(data) {
        this.dialogInlineGWRef = this.dialogForm.open(GatewaydashboardComponent, {
            data: { model: data, inline: true },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.dialogInlineGWRef.afterClosed().subscribe(() => {
            /// Refresh Screen??
        });
    }

    LaunchAzureCloudScript(gateway: Endpoint) {
        this.azureDeployService.openAzureFormationLink(gateway);
    }

    /**
     * Launch cloud formation template in a new tab
     */
    LaunchCloudScript(gateway: Endpoint) {
        if (this.authorizationService.canGetDataCenter(gateway['dataCenterId'])) {
            this.subscription.add(
                this.gatewayService
                    .getResource(this.gatewayService.getLinkedResourceUrl(gateway, 'dataCenter'))
                    .subscribe((result) => {
                        const region = result['locationCode'];
                        const link = this.cfService.getCfLink(gateway, region);
                        const win = window.open(link, '_blank');
                        win.focus();
                    })
            );
        }
    }

    isEmptyHostService(service) {
        return (
            !this.validateService.hasValue(service.networkFirstPort) &&
            !this.validateService.hasValue(service.serviceType) &&
            !this.validateService.hasValue(service.interceptIp)
        );
    }

    isEmptyNetworkService(service) {
        return !this.validateService.hasValue(service.gatewayIp) && !this.validateService.hasValue(service.interceptIp);
    }
}
