<div class="modal solid open">
    <div (click)="close()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="title">Restore Backup</div>
    <div class="subtitle">Restore to the backup of your choice.</div>
    <div class="icon" style="background-image: url(/assets/svgs/EditNetwork.svg)"></div>

    <div class="row">
        <div style="padding-top: 20px">
            <p-message
                severity="warn"
                text="This can affect network connectivity and cause issues for users on this network."
            ></p-message>
        </div>
        <div id="ProviderField">
            <div class="label row">
                Backup List
                <span class="specialnote">Required</span>
            </div>
            <select [(ngModel)]="backupFullName" id="Backups">
                <option [value]="null">Select Backup</option>
                <option *ngFor="let backup of backups" [value]="backup.backupFullName">
                    {{ backup.backupShortName }} | {{ backup.backupTimestamp }}
                </option>
            </select>
        </div>
    </div>
    <button (click)="restoreBackup()" class="save" id="CreateButton">Restore</button>
</div>
