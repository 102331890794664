<div *ngIf="businessKey != null">
    <div class="title" id="EndpointsBuilding" style="filter: brightness(50%)">
        {{ endpointNames != null ? endpointNames : 'Your Endpoint(s)' }} Is/Are Building
    </div>
    <div class="subtitle smaller">
        {{ endpointNames != null ? endpointNames : 'Your Endpoint(s)' }} Is/Are being created<br />Details are below.
    </div>
    <div class="row center" id="EndpointsBulkSaveCurrentTask">
        <div [ngClass]="stepStyle" class="building endpoints"></div>
        <div [ngClass]="{ open: stepStyle.includes('step') }" class="buildSteps" id="currentStatus">
            {{ message }}
        </div>
    </div>
    <div class="content" id="EndpointsBulkSaveAllTasks">
        <div *ngIf="endpointsStatus.length > 0">
            <div *ngFor="let status of endpointsStatus; let index = index" class="statusRow">
                <div class="bubble severityInfo"></div>
                <div class="statusText" id="endpointsStatus_task_{{ index }}">
                    {{ status['task'] }}
                </div>
                <div class="statusText second" id="endpointsStatus_status_{{ index }}">
                    {{ status['status'] }}
                </div>
            </div>
        </div>
    </div>
</div>
