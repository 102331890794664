import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment, NetworkV2 } from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, HTTP_CLIENT, LoggerService } from '@netfoundry-ui/shared/services';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';

import {
    EdgeRouterPolicyServiceV2,
    EdgeRouterServiceV2,
    NETWORK_SERVICE,
    NetworkServiceV2,
    RegionService,
} from '@netfoundry-ui/shared/apiv2';

import _, { delay, forEach, get, includes, isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { PagedGetOption } from '@lagoshny/ngx-hateoas-client';

@Injectable({ providedIn: 'root' })
export class AutoFabricService {
    private static geoLocationUrl =
        'https://autocomplete.search.hereapi.com/v1/geocode?apiKey=HDZOlFo6BVQO4hQwORijiMTFtivQ7GerNY-7WazsINE';
    public pollingForFabric = false;
    private autoFabricAttribute = '#auto-edge-routers';
    private gettingRouters = false;
    private subscription = new Subscription();
    private currentOrg;
    private currentNetwork;
    private paymentProfile;
    private isSelfService;
    private currentAutoFabric = [];

    constructor(
        private logger: LoggerService,
        private regionService: RegionService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        public growlerService: GrowlerService,
        private edgeRouterService: EdgeRouterServiceV2,
        private apiService: ApiService,
        private featureService: FeatureService,
        private edgeRouterPolicyService: EdgeRouterPolicyServiceV2,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrg = org;
                this.loadAutoFabric();
            })
        );
        this.subscription.add(
            this.apiService.currentPaymentProfile.subscribe((data) => {
                this.paymentProfile = data;
            })
        );
        this.subscription.add(
            this.apiService.currentSubscription.subscribe((subscription) => {
                this.isSelfService = this.featureService.isSelfServiceAccount(subscription);
                this.loadAutoFabric();
            })
        );
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((nw) => {
                this.currentNetwork = nw;
                this.loadAutoFabric();
            })
        );
        this.subscription.add(
            this.apiService.currentAutoFabric.subscribe((autoFabric) => {
                this.currentAutoFabric = autoFabric;
                this.verifyAutoFabric();
            })
        );
    }

    public loadAutoFabric() {
        if (
            !this.isSelfService ||
            isEmpty(this.currentOrg) ||
            isEmpty(this.currentNetwork) ||
            this.currentNetwork?.status !== 'PROVISIONED'
        ) {
            return;
        }
        this.getAutoFabricRouters(this.currentNetwork, true);
    }

    public verifyAutoFabric() {
        //TODO: Remove function entirely. Auto-fabric creation is no-longer console driven
        localStorage.removeItem(`nf_new_self_service_${this.currentOrg?.name}`);
        localStorage.removeItem('fabricZipcode_' + this.currentOrg?.name);
        localStorage.removeItem('fabricCountry_' + this.currentOrg?.name);
        localStorage.removeItem('createFabric_' + this.currentOrg?.name);
    }

    checkAutoFabricPolicy(network: NetworkV2) {
        //TODO: Remove function entirely. Auto-fabric creation is no-longer console driven
    }

    createAutoFabricPolicy(network: NetworkV2) {
        const resourceData: any = {
            networkId: network.id,
            name: network.name,
            edgeRouterPolicies: [this.getEdgeRouterPolicyJson(network)],
        };
        this.networkServiceV2.createResources(network.id, resourceData, 'application/json').subscribe(
            (data) => {
                this.logger.log('Auto fabric router creation request successful');
            },
            (error) => {
                this.logger.log('Auto fabric router creation failed', error);
            }
        );
    }

    findGeoLocationFromCountry(country: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const path = `${AutoFabricService.geoLocationUrl}&qq=country=${country}`;
            this.getGeoLocation(path, resolve, reject);
        });
    }

    findGeoLocationFromZipCode(country: string, zipCode: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const path = `${AutoFabricService.geoLocationUrl}&qq=country=${country}&postalCode=${zipCode}`;
            this.getGeoLocation(path, resolve, reject);
        });
    }

    findGeoLocationFromStreet(country: string, street: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const path = `${AutoFabricService.geoLocationUrl}&qq=country=${country}&street=${street}`;
            this.getGeoLocation(path, resolve, reject);
        });
    }

    findGeoLocationFromAddress(address: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const path = `${AutoFabricService.geoLocationUrl}&maxResult=5&q=address=${address}`;
            this.getGeoLocation(path, resolve, reject);
        });
    }

    async createFabricFromGeoLocation(
        network: NetworkV2,
        latitude: number,
        longitude: number,
        count = 1,
        pollForProvisioned = false,
        total = 2
    ): Promise<boolean> {
        const erData = await this.regionService
            .findRegionsByProviderAndGeolocation(['AWS'], latitude, longitude)
            .toPromise()
            .then(async (result) => {
                if (result.length === 0) {
                    // something went wrong, couldn't find any datacenters. This shouldn't happen
                    this.logger.error('Error getting regions for auto fabric');
                    return;
                } else {
                    return result.map((region) => ({
                        provider: region.provider,
                        region: region.locationCode,
                    }));
                }
            });
        if (erData && erData.length > 0) {
            const ers = erData.slice(0, total);
            return await this.uploadFabric(network, ers, count, pollForProvisioned);
        }
        this.logger.error('Error getting regions for auto fabric');
        return false;
    }

    uploadFabric(network: NetworkV2, erData: any, count = 1, pollForProvisioned = false): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const edgeRouters = [];

            const edgeRouterName = 'Auto NF Hosted Edge Router';

            for (const er of erData) {
                const name = `${edgeRouterName} ${count}`;
                edgeRouters.push(this.getEdgeRouterJson(name, network, er));
                count++;
            }

            const resourceData: any = {
                networkId: network.id,
                name: network.name,
                edgeRouters: edgeRouters,
            };

            try {
                const erpModel: any = this.getEdgeRouterPolicyJson(network);
                this.edgeRouterPolicyService.createResource({ body: erpModel }).subscribe(
                    () => {
                        this.networkServiceV2.createResources(network.id, resourceData, 'application/json').subscribe(
                            (data) => {
                                this.logger.log('Auto fabric router creation request successful');
                                delay(() => {
                                    this.getAutoFabricRouters(network, pollForProvisioned, true);
                                }, 2000);
                                resolve(true);
                            },
                            (error) => {
                                this.logger.log('Auto fabric router creation failed', error);
                                resolve(false);
                            }
                        );
                    },
                    (httpErrorResponse) => {
                        this.logger.log('Auto fabric ERP creation failed', httpErrorResponse);
                    }
                );
            } catch (err) {
                this.logger.log('Auto fabric creation failed', err);
                resolve(false);
            }
        });
    }

    getAutoFabricRouters(network, pollForProvisioned = false, pollIfEmpty = false) {
        if (this.pollingForFabric) {
            return;
        }
        this.pollingForFabric = true;
        this.edgeRouterService
            .getEdgeRouterPage(this.getOptions(network))
            .then((result) => {
                if (result?.length > 0) {
                    let provisioning = false;
                    const autoFab = [];
                    result.forEach((router) => {
                        if (router.status === 'PROVISIONING' || router.status === 'NEW') {
                            provisioning = true;
                        }
                        autoFab.push(router);
                    });

                    if (provisioning && pollForProvisioned) {
                        this.pollingForFabric = true;
                        this.apiService.setAutoFabric(autoFab);
                        setTimeout(() => {
                            this.pollingForFabric = false;
                            this.getAutoFabricRouters(network, pollForProvisioned, pollIfEmpty);
                        }, 5000);
                    } else if (pollIfEmpty) {
                        this.apiService.setAutoFabric(autoFab);
                        setTimeout(() => {
                            this.pollingForFabric = false;
                            this.getAutoFabricRouters(network, pollForProvisioned, pollIfEmpty);
                        }, 5000);
                    } else {
                        this.pollingForFabric = false;
                        this.apiService.setAutoFabric(autoFab);
                    }
                } else if (pollIfEmpty) {
                    this.apiService.setAutoFabric([]);
                    setTimeout(() => {
                        this.pollingForFabric = false;
                        this.getAutoFabricRouters(network, pollForProvisioned, pollIfEmpty);
                    }, 5000);
                } else {
                    this.pollingForFabric = false;
                    this.apiService.setAutoFabric([]);
                }
            })
            .catch((error) => {
                this.logger.error('Error fetching auto fabric');
                this.pollingForFabric = false;
            });
    }

    getEdgeRouterJson(name: string, network: NetworkV2, erData: any) {
        return {
            name: name,
            provider: erData.provider,
            region: erData.region,
            networkId: network.id,
            attributes: [this.autoFabricAttribute],
            linkListener: true,
        };
    }

    getEdgeRouterPolicyJson(network: NetworkV2) {
        return {
            name: 'Auto Edge Router Policy',
            edgeRouterAttributes: [this.autoFabricAttribute],
            endpointAttributes: ['#all'],
            networkId: network.id,
        };
    }

    private getGeoLocation(path: string, resolve: (value: any) => void, reject: (reason?: any) => void) {
        const request = new XMLHttpRequest();

        request.open('GET', path);
        request.send();

        request.onreadystatechange = function () {
            if (request.readyState === 4) {
                if (request.status === 200) {
                    const result = JSON.parse(request.response);
                    const item = get(result, 'items[0]');
                    const position = get(item, 'position');
                    resolve(position);
                } else {
                    reject(request.response);
                }
            }
        };
    }

    private getOptions(network) {
        const options: PagedGetOption = {
            params: {
                networkId: network?.id,
                name: '%',
            },
        };
        return options;
    }
}
