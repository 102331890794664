<div class="fullColor"></div>

<div class="row" style="margin-top: 20px">
    <div class="row">
        <div class="cardStack full filter">
            <div class="filtertitle">Filters</div>
            <div style="width: 330px; display: inline-block">
                <mat-chip-listbox aria-label="DatePicker">
                    <mat-chip-option (click)="updateStart('1h')" [selected]="dateFilter === '1h'">1h</mat-chip-option>
                    <mat-chip-option (click)="updateStart('6h')" [selected]="dateFilter === '6h'">6h</mat-chip-option>
                    <mat-chip-option (click)="updateStart('24h')" [selected]="dateFilter === '24h'"
                        >24h</mat-chip-option
                    >
                    <mat-chip-option (click)="updateStart('3d')" [selected]="dateFilter === '3d'">3d</mat-chip-option>
                    <mat-chip-option (click)="updateStart('7d')" [selected]="dateFilter === '7d'">7d</mat-chip-option>
                    <mat-chip-option (click)="updateStart('30d')" [selected]="dateFilter === '30d'"
                        >30d</mat-chip-option
                    >
                </mat-chip-listbox>
            </div>
            <div class="multiSelectFilter">
                <p-multiSelect
                    (onChange)="updateSelectedList()"
                    [(ngModel)]="selectedAttributes"
                    [options]="attributes"
                    maxSelectedLabels="3"
                    optionLabel="name"
                    optionValue="name"
                    placeholder="Filter By {{
                        featureService.disableNewNavigation ? 'Endpoint' : 'Identity'
                    }} Attribute"
                    selectedItemsLabel="{0} items selected"
                    selectionLimit="10"
                ></p-multiSelect>
            </div>
            <div class="absoluteTimeRanges">
                <p-calendar
                    (onClose)="close()"
                    (onSelect)="close()"
                    [(ngModel)]="rangeDates"
                    [readonlyInput]="false"
                    [showTime]="true"
                    hourFormat="12"
                    inputId="range"
                    selectionMode="range"
                    [minDate]="minimumDate"
                    [showTime]="true"
                    placeholder="Select Time Frame"
                ></p-calendar>
            </div>

            <div id="resource-filter">
                <mat-chip-listbox *ngIf="filterValue">
                    <mat-chip-option
                        (click)="setFilter(eventFields.identityName, null)"
                        *ngIf="filterType === eventFields.identityName"
                        class="selectedFilter"
                        selected
                        title="{{ filterValue }}"
                    >
                        {{ featureService.disableNewNavigation ? 'Endpoint' : 'Identity' }}:
                        {{ filterValue }}</mat-chip-option
                    >
                    <mat-chip-option
                        (click)="setFilter(eventFields.serviceName, null)"
                        *ngIf="filterType === eventFields.serviceName"
                        class="selectedFilter"
                        selected
                        title="{{ filterValue }}"
                    >
                        Service: {{ filterValue }}</mat-chip-option
                    >
                </mat-chip-listbox>
            </div>
        </div>
    </div>
    <div class="col-sm-4 pl-0">
        <div class="cardStack full" style="height: 400px">
            <div
                (click)="downloadEndpointCsv()"
                *ngIf="endpointItems"
                class="metricDownload buttonAction export"
                id="downloadEndpointcsv"
            ></div>
            <mat-tab-group
                (selectedTabChange)="onTabChange($event)"
                class="darkMode"
                dynamicHeight
                mat-align-tabs="start"
            >
                <mat-tab #dialingEndpoint>
                    <ng-template mat-tab-label>
                        <span class="title nobottom">{{ labelEndpoint }}</span>
                    </ng-template>
                    <div>
                        <app-ziti-utilization-table
                            (dataReceived)="receivedEndpointItems($event)"
                            (filterItemOutput)="setFilter(eventFields.identityName, $event)"
                            [filterField]="eventFields.serviceName"
                            [filterValue]="endpointTableFilterValue"
                            [networkId]="currentNetwork?.id"
                            [newEndTime]="endTime"
                            [newStartTime]="startTime"
                            [organizationId]="currentNetwork?.networkGroupId"
                            [selectedAttributes]="selectedAttributes"
                            [aggregateField]="templateService.identity_aggregate_field"
                        ></app-ziti-utilization-table>
                    </div>
                </mat-tab>
                <mat-tab #hostingEndpoint *ngIf="hostingEndpointData">
                    <ng-template mat-tab-label>
                        <span class="title nobottom"
                            >Hosting {{ featureService.disableNewNavigation ? 'Endpoint' : 'Identity' }}</span
                        >
                    </ng-template>
                    <div>
                        <app-ziti-utilization-table
                            (dataReceived)="receivedHostingEndpointItems($event)"
                            (filterItemOutput)="setFilter(eventFields.hostName, $event)"
                            [filterField]="eventFields.serviceName"
                            [filterValue]="endpointTableFilterValue"
                            [networkId]="currentNetwork?.id"
                            [newEndTime]="endTime"
                            [newStartTime]="startTime"
                            [organizationId]="currentNetwork?.networkGroupId"
                            [selectedAttributes]="selectedAttributes"
                            [aggregateField]="templateService.host_aggregate_field"
                        ></app-ziti-utilization-table>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

        <div class="cardStack full" style="height: 400px">
            <div
                (click)="downloadServiceCsv()"
                *ngIf="serviceItems"
                class="metricDownload buttonAction export"
                id="downloadServicecsv"
            ></div>
            <mat-tab-group class="darkMode" dynamicHeight mat-align-tabs="start">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span class="title nobottom">Usage By Service</span>
                    </ng-template>
                    <div>
                        <app-ziti-utilization-table
                            (dataReceived)="receivedServiceItems($event)"
                            (filterItemOutput)="setFilter(eventFields.serviceName, $event)"
                            [filterField]="eventFields.identityName"
                            [filterValue]="serviceTableFilterValue"
                            [locked]="serviceTableLocked"
                            [networkId]="currentNetwork?.id"
                            [newEndTime]="endTime"
                            [newStartTime]="startTime"
                            [organizationId]="currentNetwork?.networkGroupId"
                            [selectedAttributes]="selectedAttributes"
                            [aggregateField]="templateService.service_aggregate_field"
                        >
                        </app-ziti-utilization-table>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

    <!-- start right wide column -->
    <div class="col-sm-8 pr-0">
        <!-- top widget -->
        <div class="row">
            <div class="cardStack full" style="height: 324px">
                <mat-tab-group class="darkMode" mat-align-tabs="start">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom">Top Usage By Resource Type</span>
                        </ng-template>
                        <div class="fullSized">
                            <div class="thirdGrid">
                                <app-ziti-utilization-pie-mini
                                    [aggregateField]="templateService.identity_aggregate_field"
                                    [dateFilter]="dateFilter"
                                    [filterField]="filterType"
                                    [filterValue]="filterValue"
                                    [height]="'260px'"
                                    [label]="labelEndpoint"
                                    [networkId]="currentNetwork?.id"
                                    [newEndTime]="endTime"
                                    [newStartTime]="startTime"
                                    [organizationId]="currentNetwork?.networkGroupId"
                                    [selectedAttributes]="selectedAttributes"
                                >
                                </app-ziti-utilization-pie-mini>

                                <app-ziti-utilization-pie-mini
                                    [aggregateField]="templateService.service_aggregate_field"
                                    [dateFilter]="dateFilter"
                                    [filterField]="filterType"
                                    [filterValue]="filterValue"
                                    [height]="'260px'"
                                    [label]="'Services'"
                                    [networkId]="currentNetwork?.id"
                                    [newEndTime]="endTime"
                                    [newStartTime]="startTime"
                                    [organizationId]="currentNetwork?.networkGroupId"
                                    [selectedAttributes]="selectedAttributes"
                                >
                                </app-ziti-utilization-pie-mini>

                                <app-ziti-utilization-pie-mini
                                    [aggregateField]="templateService.edge_router_aggregate_field"
                                    [dateFilter]="dateFilter"
                                    [filterField]="filterType"
                                    [filterValue]="filterValue"
                                    [height]="'260px'"
                                    [label]="label"
                                    [networkId]="currentNetwork?.id"
                                    [newEndTime]="endTime"
                                    [newStartTime]="startTime"
                                    [organizationId]="currentNetwork?.networkGroupId"
                                    [selectedAttributes]="selectedAttributes"
                                >
                                </app-ziti-utilization-pie-mini>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>

        <div class="row">
            <div class="cardStack full" style="height: 400px">
                <mat-tab-group class="darkMode" mat-align-tabs="start">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom">Usage By {{ labelEndpoint }}</span>
                        </ng-template>
                        <div>
                            <ng-template matTabContent>
                                <app-ziti-utilization-stacked
                                    [dateFilter]="dateFilter"
                                    [filterField]="filterType"
                                    [filterValue]="filterValue"
                                    [height]="'340px'"
                                    [networkGroupId]="currentNetwork?.networkGroupId"
                                    [networkId]="currentNetwork?.id"
                                    [newEndTime]="endTime"
                                    [newStartTime]="startTime"
                                    [selectedAttributes]="selectedAttributes"
                                    [stackBy]="templateService.identity_aggregate_field"
                                >
                                </app-ziti-utilization-stacked>
                            </ng-template>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom">Usage By Services</span>
                        </ng-template>
                        <div>
                            <ng-template matTabContent>
                                <app-ziti-utilization-stacked
                                    [dateFilter]="dateFilter"
                                    [filterField]="filterType"
                                    [filterValue]="filterValue"
                                    [height]="'340px'"
                                    [networkGroupId]="currentNetwork?.networkGroupId"
                                    [networkId]="currentNetwork?.id"
                                    [newEndTime]="endTime"
                                    [newStartTime]="startTime"
                                    [selectedAttributes]="selectedAttributes"
                                    [stackBy]="templateService.service_aggregate_field"
                                >
                                </app-ziti-utilization-stacked>
                            </ng-template>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom">Usage By {{ label }}</span>
                        </ng-template>
                        <div>
                            <ng-template matTabContent>
                                <app-ziti-utilization-stacked
                                    [dateFilter]="dateFilter"
                                    [filterField]="filterType"
                                    [filterValue]="filterValue"
                                    [height]="'340px'"
                                    [networkGroupId]="currentNetwork?.networkGroupId"
                                    [networkId]="currentNetwork?.id"
                                    [newEndTime]="endTime"
                                    [newStartTime]="startTime"
                                    [selectedAttributes]="selectedAttributes"
                                    [stackBy]="templateService.host_aggregate_field"
                                >
                                </app-ziti-utilization-stacked>
                            </ng-template>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom">Usage By Direction</span>
                        </ng-template>
                        <div>
                            <ng-template matTabContent>
                                <app-ziti-utilization-basic
                                    [dateFilter]="dateFilter"
                                    [filterField]="filterType"
                                    [filterValue]="filterValue"
                                    [height]="'340px'"
                                    [networkGroupId]="currentNetwork?.networkGroupId"
                                    [networkId]="currentNetwork?.id"
                                    [newEndTime]="endTime"
                                    [newStartTime]="startTime"
                                    [selectedAttributes]="selectedAttributes"
                                ></app-ziti-utilization-basic>
                            </ng-template>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>

        <div class="row" style="clear: both"></div>

        <div class="row" style="clear: both">&nbsp;</div>
    </div>
</div>
