import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Endpoint } from '@netfoundry-ui/shared/model';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NetworkVersionService } from './network-version.service';
import { HTTP_CLIENT } from './token.service';

const azureStackLinkV4 =
    'https://portal.local.azurestack.external/#create/Microsoft.Template/uri/https%3A%2F%2Fbitbucket.org%2Fnetfoundry%2Fprod-azurestack-button%2Fraw%2F4550da017e8a5c5e0140250e83d34e0239568bab%2Fazuredeploy.json';
const azureStackLinkV5 =
    'https://portal.local.azurestack.external/#create/Microsoft.Template/uri/https%3A%2F%2Fbitbucket.org%2Fnetfoundry%2Fprod-azurestack-button%2Fraw%2F4550da017e8a5c5e0140250e83d34e0239568bab%2Fazuredeploy.json';

@Injectable({
    providedIn: 'root',
})
export class AzureDeployService {
    constructor(private networkVersionService: NetworkVersionService, @Inject(HTTP_CLIENT) private http: HttpClient) {}

    public openAzureFormationLink(gateway: Endpoint) {
        const azureFormationLink = this.getLink(gateway.endpointType);

        this.openAzureFormation(azureFormationLink);
    }

    public canShowAzureFormationLink(endpointType: string) {
        if (endpointType !== 'AZSGW') {
            return of(true);
        } else {
            const azureFormationLink = this.getLink(endpointType);

            return this.checkAzureFormationLink(azureFormationLink);
        }
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            return of(false);
        } else {
            return of(true);
        }
    }

    private openAzureFormation(azureFormationLink: string) {
        const win = window.open(azureFormationLink, '_blank');

        win.focus();
    }

    private getLink(endpointType: string) {
        // if the endpoint type is AZSGW
        if (endpointType === 'AZSGW') {
            if (this.networkVersionService.currentNetworkVersion >= 5) {
                return azureStackLinkV5;
            } else {
                return azureStackLinkV4;
            }
        } else {
            let versionNumber = this.networkVersionService.currentNetworkVersion;
            if (this.networkVersionService.currentNetworkVersion < 6) {
                versionNumber = 4;
            }

            return `https://portal.azure.com/#create/Microsoft.Template/uri/https%3A%2F%2Fraw.githubusercontent.com%2FNetFoundry%2Fazure-deploy%2Fmaster%2Fversioned_templates%2FazuredeployV${versionNumber}.json`;
        }
    }

    private checkAzureFormationLink(azureFormationLink: string) {
        const headers = new HttpHeaders();

        return this.http
            .get(azureFormationLink, { headers: headers })
            .pipe(catchError((error) => this.handleError(error)));
    }
}
