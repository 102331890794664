import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ElasticsearchService, UtilizationTimelineQuery } from '@netfoundry-ui/shared/elasticsearch';
import { Network, NetworkGroup, NetworkV2 } from '@netfoundry-ui/shared/model';
import { ApiService, ErrorHistoryService, LoggerService } from '@netfoundry-ui/shared/services';
import { TobytesPipe } from '@netfoundry-ui/ui/pipes';
import { Chart } from 'angular-highcharts';
import moment from 'moment';
import { Subscription } from 'rxjs';

// object representing the interval (in miliseconds) to use for a chart given the current date filter
const intervals = {
    '1h': moment.duration(10, 'minutes').asMilliseconds(),
    '6h': moment.duration(30, 'minutes').asMilliseconds(),
    '24h': moment.duration(2, 'hours').asMilliseconds(),
    '7d': moment.duration(1, 'days').asMilliseconds(),
    '30d': moment.duration(5, 'days').asMilliseconds(),
    '1m': moment.duration(5, 'days').asMilliseconds(),
    '6m': moment.duration(1, 'weeks').asMilliseconds(),
    '12m': moment.duration(1, 'months').asMilliseconds(),
};

// object for obtaining the interval to use for a chart given the current date filter
// for most filters the chart displays fine given just the interval
//  however, for 30d and 1m it decides to display 7 day intervals instead of 5 as specified in the intervals object
const units = {
    '1h': null,
    '6h': null,
    '24h': null,
    '7d': null,
    '30d': [['day', [5, 10, 15, 20, 25, 30]]],
    '1m': [['day', [5, 10, 15, 20, 25, 30]]],
    '6m': null,
    '12m': null,
};

@Component({
    selector: 'app-utilization-timeline',
    templateUrl: './utilization-timeline.component.html',
    styleUrls: ['./utilization-timeline.component.scss'],
    providers: [TobytesPipe, ElasticsearchService],
})
export class UtilizationTimelineComponent implements OnChanges, OnDestroy, OnInit {
    @Input() networkDataTypes: string[] = ['DropTcpRx', 'DropUdpRx'];
    @Input() endpointType: any = false; // Allow filtering for clients or gateways
    @Input() endpointNameFilter: any = false; // Allow filtering by endpoint name
    @Input() resourceId: any;
    @Input() resourceIdList: any;
    @Input() networkId; // : any = null;
    @Input() dateFilter: any = '24h';
    @Input() endTime: number = Date.now();
    @Input() startTime: number = this.endTime - 24 * 60 * 60 * 1000;
    totalUtilization = 0;
    totalUtilizationBytes = '';
    currentOrg = new NetworkGroup({});
    currentNetwork: Network | NetworkV2 = new Network({});
    chart: Chart;
    networkLoading = true;
    noData = false;
    noDataList = [];
    initialized = false;
    canReadElasticSearch = true;
    private subscription: Subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private authorizationService: AuthorizationService,
        private elasticsearch: ElasticsearchService,
        private toBytes: TobytesPipe,
        private apiService: ApiService,
        private errorHistoryService: ErrorHistoryService
    ) {}

    ngOnInit() {
        this.noDataList = [];

        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrg = org;

                this.subscription.add(
                    this.apiService.currentNetwork.subscribe((network) => {
                        this.currentNetwork = network;

                        this.initialized = true;
                        this.logger.info('Utilization timeline initialized');
                        this.ngOnChanges();
                    })
                );
            })
        );
    }

    ngOnChanges() {
        if (!this.initialized || this.networkId === null || this.currentOrg.id === null) {
            return;
        }

        this.canReadElasticSearch = this.authorizationService.canReadElasticSearch(this.currentOrg.id, this.networkId);

        if (this.canReadElasticSearch) {
            this.networkLoading = true;
            this.noDataList = [];

            // obtaining the unit and interval to use for the chart
            const unit = units[this.dateFilter];
            const interval = intervals[this.dateFilter];

            // initializing the chart with the desired unit and interval
            this.initChart(unit, interval);

            this.networkLoading = true;
            if (this.resourceIdList != null && this.resourceIdList.length > 0) {
                for (const resource of this.resourceIdList) {
                    this.getUtilizationData(this.networkId, resource.id, resource.name);
                }
            } else {
                this.getUtilizationData(this.networkId, this.resourceId);
            }
        } else {
            this.networkLoading = false;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public getUtilizationData(networkId, resourceId, name?) {
        if (networkId === null) {
            this.noData = true;
            this.networkLoading = false;
            return;
        }

        if (this.currentOrg.id === null) {
            this.noData = true;
            this.networkLoading = false;
            return;
        }

        if (name == null) {
            name = '';
        } else {
            name += ' - ';
        }

        const query = new UtilizationTimelineQuery(this.logger);
        query.addTimeFilter(this.startTime, this.endTime);

        query.addFilter('networkId', networkId);
        query.addFilter('organizationId', this.currentOrg.id);
        query.addExclusion('nodeType', 'TransferNode');
        query.addExclusion('vtcType', 'TransferNode');
        query.matchFromList('NetworkDataType', this.networkDataTypes);

        // optional filter by endpoint, vtc name trumps vtc type
        if (resourceId) {
            query.addFilter('resourceId', resourceId);
        }

        // optional filter by endpoint type
        if (this.endpointType) {
            query.addFilter('nodeType', this.endpointType);
        }

        if (this.endpointNameFilter) {
            query.addFilter('commonName.keyword', this.endpointNameFilter);
        }

        const interval = query.determineInterval(this.startTime, this.endTime);
        const index = interval === '1d' ? 'ncvtchistorical' : 'ncvtccurrent';

        this.logger.info('Utilization Timeline Query:', JSON.stringify(query.getQuery()));
        this.elasticsearch
            .search(this.currentOrg.id, index, query.getQuery(), {
                networkId: this.networkId,
            })
            .subscribe(
                (data) => {
                    const dataset = [];
                    const aggregate = query.getAggregateName();
                    if (data.aggregations != null && data.aggregations[aggregate] != null) {
                        if (data.aggregations[aggregate].buckets.length > 0) {
                            for (let i = 0; i < data.aggregations[aggregate].buckets.length; i++) {
                                const current = Math.round(Number(data.aggregations[aggregate].buckets[i]['1'].value));
                                const stamp = data.aggregations[aggregate].buckets[i].key;

                                const bucket = [stamp, current];
                                dataset.push(bucket);
                                this.totalUtilization += data.aggregations[aggregate].buckets[i]['1'].value;
                            }

                            this.totalUtilizationBytes = this.toBytes.transform(this.totalUtilization);

                            this.logger.info(dataset.length + ' hits returned from search');

                            this.noDataList.push(false);
                            this.noData = this.noDataList.every((item) => item === true);
                            this.networkLoading = false;
                            this.chart.addSeries(
                                {
                                    name: name + this.getSeriesName(),
                                    data: dataset,
                                    type: 'line',
                                },
                                true,
                                true
                            );
                        } else {
                            // no aggregations
                            console.warn('No timeline aggregrations present in utilization timeline data');
                            this.noDataList.push(true);
                            this.noData = this.noDataList.every((item) => item === true);
                            this.networkLoading = false;
                        }
                    } else {
                        // bad response
                        this.logger.error('Bad response from utilization timeline metric query');
                        this.noDataList.push(true);
                        this.noData = this.noDataList.every((item) => item === true);
                        this.networkLoading = false;
                    }
                },
                (error) => {
                    this.errorHistoryService.addError(error.message);
                }
            );
    }

    private initChart(xUnits, xInterval) {
        const pipe = this.toBytes;
        this.chart = new Chart({
            colors: [
                window.getComputedStyle(document.body).getPropertyValue('--primaryColor'),
                window.getComputedStyle(document.body).getPropertyValue('--secondaryColor'),
            ],
            title: { text: null },
            xAxis: {
                type: 'datetime',
                // the interval to use for the x-axis
                tickInterval: xInterval,

                // the max tick number
                max: this.endTime.valueOf(),

                // the min tick number
                min: this.startTime.valueOf(),

                // the units that the ticks are allowed to land on
                units: xUnits,
            },
            credits: { enabled: false },
            yAxis: {
                title: {
                    text: null,
                },
                min: 0,
                labels: {
                    formatter: function () {
                        return pipe.transform(this.value as number);
                    },
                },
            },
            tooltip: {
                formatter: function () {
                    return new Date(this.x).toLocaleString() + ' <br /> ' + pipe.transform(this.y);
                },
            },
            time: {
                useUTC: false,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    }

    /**
     * Get a readable label for the data
     * @returns {string}
     */
    private getSeriesName() {
        let name;
        if (this.networkDataTypes[0].includes('Rx')) {
            name = 'Sent';
        } else {
            name = 'Recieved';
        }

        if (this.endpointNameFilter) {
            name += ' / ' + this.endpointNameFilter;
        } else if (this.endpointType) {
            name += ' / ' + this.endpointType;
        }

        return name;
    }
}
