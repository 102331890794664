<aside *ngIf="screen">
    <div class="icon"></div>
    <div class="middle center">
        <div class="title">
            NetFoundry's software enables partners to integrate secure, high-performance networking inside their apps,
            platforms and services
        </div>
        <div class="button goto" routerLink="/trial/">Try for Free</div>
    </div>
</aside>
<app-loader *ngIf="!screen" [isLoading]="!screen" class="load-indicator" id="MainConsoleDisplay"></app-loader>
<div class="inviteinfo">
    <div class="icon mobile"></div>

    <div *ngIf="screen === 'review'" class="area middle" id="AcceptForm">
        <div class="row center">
            <div
                [ngClass]="environmentLogoClass"
                class="logo inline row center"
                style="background-position: center"
            ></div>
        </div>
        <div class="title row center">Accept Invitation to Join</div>
        <div class="subTitle row center">
            Would you like to accept the invitation that was sent to you to join the NetFoundry organization:
            <strong>{{ invitation.toTenantName }}</strong>
        </div>

        <div class="row">&nbsp;</div>
        <div class="title row center">Your organization name is:</div>

        <div class="title row center">
            <strong>{{ invitation['toTenantLabel'] }} </strong>
        </div>

        <div class="row">&nbsp;</div>
        <div class="row center">Please remember your organization name. You will need it for future logins</div>

        <div class="label row center">This invite will expire in {{ expires }}</div>
        <div class="row"></div>

        <div class="row center">
            <div (click)="accept()" class="button" id="AcceptButton">Accept</div>
        </div>
        <div class="label row">&nbsp;</div>
        <div class="center row">
            <span (click)="decline()" class="goto labelLink center">Do Not Accept</span>
        </div>
    </div>

    <div *ngIf="screen === 'declined'" class="area middle">
        <div [ngClass]="environmentLogoClass" class="logo inline row"></div>
        <div class="title row">Invitation Declined</div>
        <div class="subTitle row">Your invitation to join has been declined.</div>
    </div>

    <div *ngIf="screen === 'inuse'" class="area middle">
        <div [ngClass]="environmentLogoClass" class="logo inline row"></div>
        <div class="title row">Invitation In Use</div>
        <div class="subTitle row">Your invitation to join is already in use.</div>
    </div>

    <div *ngIf="screen === 'expired'" class="area middle">
        <div [ngClass]="environmentLogoClass" class="logo inline row"></div>
        <div class="title row">Invitation Expired</div>
        <div class="subTitle row">Your invitation to join is expired.</div>
    </div>

    <div *ngIf="screen === 'revoked'" class="area middle">
        <div [ngClass]="environmentLogoClass" class="logo inline row"></div>
        <div class="title row">Invitation Revoked</div>
        <div class="subTitle row">Your invitation to join has been revoked.</div>
    </div>

    <div *ngIf="screen === 'invalid'" class="area middle">
        <div [ngClass]="environmentLogoClass" class="logo inline row"></div>
        <div class="title row">Invitation Invalid</div>
        <div class="subTitle row">
            Your invitation to join is invalid. Please contact your system administrator to create a new invitation.
        </div>
    </div>
</div>
