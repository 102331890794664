import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RefresherService {
    // Singleton refresher, assign pollers to this
    public refreshTimerId;
    public refreshInterval = 15000;
    private _defaultInterval = 15000;

    disableRefresh() {
        clearTimeout(this.refreshTimerId);
    }

    getDefaultInterval() {
        return this._defaultInterval;
    }

    setInterval(interval) {
        this.refreshInterval = interval;
    }
}
