import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Network } from '@netfoundry-ui/shared/model';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-network-bulk-upload-save',
    templateUrl: './network-bulk-upload-save.component.html',
    styleUrls: ['./network-bulk-upload-save.component.scss'],
})
export class NetworkBulkUploadSaveComponent implements OnInit, OnDestroy {
    @Input() businessKey;
    @Input() network: Network = new Network({});
    @Output() uploaded = new EventEmitter<void>();

    networkName = null;
    networkStatus = [];
    networkSubProcessStatus = [];
    subProcessOverallStatus = null;
    message;
    timeout = 5000;
    stepStyle = 'step0';
    isPageActive = true;

    constructor(public apiService: ApiService, private logger: LoggerService) {}

    ngOnInit() {
        this.message = 'Network As Code Upload Process Initiated';
    }

    ngOnDestroy() {
        // to exit from the loop in the async function, when user navigates away from the page
        this.isPageActive = false;
    }

    async fetchStatus() {
        this.networkStatus = [];
        this.subProcessOverallStatus = null;
        this.stepStyle = 'step0';
        this.message = 'Network As Code Upload Process Initiated';

        let isCompleted = null;
        while (isCompleted === null && this.isPageActive) {
            isCompleted = await this.fetchNetworkAsCodeStatus();
        }
        if (this.isPageActive && isCompleted !== null && isCompleted) {
            this.uploaded.emit();
        }
    }

    fetchNetworkAsCodeStatus(): Promise<boolean> {
        return new Promise((resolve, reject): any => {
            setTimeout(() => {
                this.apiService
                    .get(`workflow-status/${this.businessKey}?networkId=${this.network.getId()}`)
                    .toPromise()
                    .then(
                        (status) => {
                            this.networkName = this.network['name'];
                            if (status !== undefined && status['length'] > 0) {
                                this.message = 'Network As Code Upload Process In Progress';
                                // to store the status that we display to the user
                                this.networkStatus = [];
                                this.networkSubProcessStatus = [];
                                const statusList = status[0]['data'];
                                if (statusList.length > 0) {
                                    let index = -1;
                                    for (let i = 1; i < statusList.length; i++) {
                                        const currentTask = statusList[i]['task'];
                                        // do not display start event and end event in the status page
                                        if (
                                            currentTask == null ||
                                            currentTask.includes('Start Event') ||
                                            currentTask.includes('End Event') ||
                                            currentTask.includes('Intermediate Task')
                                        ) {
                                            continue;
                                        }
                                        // display all meaningful messages in the status page
                                        if (index < 0 || this.networkStatus[index]['task'] !== currentTask) {
                                            this.networkStatus.push(statusList[i]);
                                            index++;
                                        } else {
                                            continue;
                                        }
                                        // check the percentage of completion
                                        if (currentTask.includes('Call Create Network')) {
                                            this.stepStyle = 'step1';
                                        } else if (currentTask.includes('Launch NF Network')) {
                                            this.stepStyle = 'step2';
                                        } else if (currentTask.includes('Configure NF Network')) {
                                            this.stepStyle = 'step3';
                                        } else if (currentTask.includes('Create Gateways')) {
                                            this.stepStyle = 'step4';
                                        } else if (currentTask.includes('Call AppWan As Code')) {
                                            this.stepStyle = 'step5';
                                        }
                                    }
                                }
                                let subProcessStatusList = [];
                                let lastSubProcessId;
                                for (let j = this.networkStatus.length - 1; j > -1; j--) {
                                    if (this.networkStatus[j]['calledProcessInstanceId'] !== null) {
                                        lastSubProcessId = this.networkStatus[j]['calledProcessInstanceId'];
                                        this.subProcessOverallStatus = this.networkStatus[j]['status'];
                                        break;
                                    }
                                }
                                if (lastSubProcessId !== null) {
                                    for (let i = 0; i < status['length']; i++) {
                                        if (status[i]['processInstanceId'] === lastSubProcessId) {
                                            subProcessStatusList = status[i]['data'];
                                            let index = -1;
                                            for (let j = 0; j < subProcessStatusList.length; j++) {
                                                const currentTask = subProcessStatusList[j]['task'];
                                                // do not display start event and end event in the status page
                                                if (
                                                    currentTask == null ||
                                                    currentTask.includes('Start Event') ||
                                                    currentTask.includes('End Event') ||
                                                    currentTask.includes('Intermediate Task')
                                                ) {
                                                    continue;
                                                }
                                                // display all meaningful messages in the status page
                                                if (
                                                    index < 0 ||
                                                    this.networkSubProcessStatus[index]['task'] !== currentTask
                                                ) {
                                                    this.networkSubProcessStatus.push(subProcessStatusList[j]);
                                                    index++;
                                                } else {
                                                    continue;
                                                }
                                                if (currentTask.includes('Launch NF Network')) {
                                                    this.stepStyle = 'step2';
                                                } else if (currentTask.includes('Configure NF Network')) {
                                                    this.stepStyle = 'step3';
                                                }
                                            }
                                        }
                                    }
                                }
                                if (statusList[statusList.length - 1]['task'].includes('End Event')) {
                                    this.stepStyle = 'step6';
                                    if (
                                        statusList[statusList.length - 1]['task'].includes('Error') ||
                                        (statusList.length > 1 &&
                                            statusList[statusList.length - 2]['task'].includes('Error'))
                                    ) {
                                        this.message = 'Network As Code Upload Process Errored Out';
                                        this.logger.info('Upload Network As Code process failed', this.networkName);
                                        // is completed = false, i.e. failed
                                        resolve(false);
                                    } else if (
                                        (subProcessStatusList.length > 0 &&
                                            subProcessStatusList[subProcessStatusList.length - 1]['task'].includes(
                                                'Error'
                                            )) ||
                                        (subProcessStatusList.length > 1 &&
                                            subProcessStatusList[subProcessStatusList.length - 2]['task'].includes(
                                                'Error'
                                            ))
                                    ) {
                                        this.subProcessOverallStatus = 'Failed';
                                        this.message = 'Network As Code Upload Process Errored Out';
                                        this.logger.info('Upload Network As Code process failed', this.networkName);
                                        // is completed = false, i.e. failed
                                        resolve(false);
                                    } else {
                                        this.message = 'Network As Code Upload Process Completed';
                                        this.logger.info(
                                            'Upload Network As Code process is successfully completed',
                                            this.networkName
                                        );
                                        // is completed = true
                                        resolve(true);
                                    }
                                } else {
                                    this.message = this.networkStatus[this.networkStatus.length - 1]['task'];
                                }
                            } else {
                                this.message = 'Network As Code Upload Process workflow is not yet initiated';
                            }
                            // is completed = null, i.e. process is running
                            resolve(null);
                        },
                        (error) => {
                            this.message = 'Network As Code Upload Process Status Check failed';
                            // is completed = false, i.e. failed
                            resolve(false);
                        }
                    );
            }, this.timeout);
        });
    }
}
