<app-action-button
    (addEvent)="edit($event)"
    (deleteEvent)="openConfirm('delete')"
    [hidden]="(!svc.isDeleteAction && !canCreate) || svc.hideAppButton"
    [isDeleteAction]="svc.isDeleteAction"
>
</app-action-button>
<app-noitems (clickEmit)="edit(true)" [image]="'No_Clients'" [isEmpty]="showNoData" [typeName]="typeName"></app-noitems>

<app-loading-indicator [isLoading]="svc.isLoading | async"></app-loading-indicator>
<div class="area data-table" id="ItemsArea">
    <app-noitems
        (clickEmit)="edit(true)"
        [image]="'No_Clients'"
        [isEmpty]="showNoData"
        [typeName]="typeName"
    ></app-noitems>

    <app-data-table2
        (actionRequested)="actionRequested($event)"
        (filterChanged)="svc.tableFilterChanged($event)"
        *ngIf="
            (svc.filterHasChanged | async) || (svc.pageHasChanged | async) || !(showNoData || (svc.isLoading | async))
        "
        [columnDefinitions]="columnDefs"
        [columnFilters]="svc.columnFilters"
        [rowData]="displayedItems"
        [tableId]="tableId"
    ></app-data-table2>
</div>

<div *ngIf="showInfoBubble && showNoData && (svc.isLoading | async) === false" class="infobox-container">
    <div class="infobox pop">
        <div
            (click)="hideInfoBubble()"
            class="close cancel"
            id="CancelXButtonHideHint"
            title="Click here prevent this info bubble from showing again"
        ></div>
        <div>
            <div class="smartziggy">
                <ng-lottie [options]="lottieOptions"></ng-lottie>
            </div>
            <span class="infotitle">{{ infoBubbleTitle }}</span>
            <span class="infotext">{{ infoBubbleText }}</span>
            <a class="infolink" [href]="infoBubbleUrl" target="_blank">Learn More</a>
        </div>
    </div>
</div>
