import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SortbyPipe } from '@netfoundry-ui/ui/pipes';
import {
    HostService,
    NETWORK_SERVICE,
    NetworkServiceV2,
    ProviderService,
    RegionService,
    NetworkControllerService
} from '@netfoundry-ui/shared/apiv2';
import { NetworkV2, Provider, RegionV2, AllocatedIp } from '@netfoundry-ui/shared/model';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Router } from '@angular/router';
import _ from 'lodash';

@Component({
    selector: 'app-move-network-modal',
    templateUrl: './move-network-modal.component.html',
    styleUrls: ['./move-network-modal.component.scss'],
})
export class MoveNetworkModalComponent implements OnInit {
    model: NetworkV2;
    displayedRegions: RegionV2[] = [];
    providers: Provider[] = [];
    hostId;
    networkControllerId;
    networkId;
    ips;
    options: any[] = [{label: 'Provider and Region', value: 'provider'},{label: 'IP Address', value: 'ipAddress'}];
    hostData: any;
    hasAllocatedIps = false;
    allocatedIps: AllocatedIp[] = [];
    providerSelected = false;
    allocatedIpSelected = false;
    value = 'provider';
    selectedIp;
    disableButton = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<MoveNetworkModalComponent>,
        private sortByPipe: SortbyPipe,
        private regionService: RegionService,
        private providerService: ProviderService,
        private hostService: HostService,
        private networkControllerService: NetworkControllerService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        public growlerService: GrowlerService,
        private router: Router
    ) {
        this.model = data;
        this.networkId = data.model.id;
        this.hostId = data.model.networkController.hostId;
        this.networkControllerId = data.model.networkController.id;
    }

    ngOnInit() {
        this.hostService.getHost(this.hostId).then((hostData) => {
            this.hostData = hostData;
            this.providerService.getProviderPage().then((providers) => {
                this.providers = providers.filter((provider) => provider.type === 'OCI' || provider.type === 'AWS');
                this.model.provider = this.hostData.provider;

                this.regionService.findRegionsByProvider([this.model.provider]).subscribe((regions) => {
                    this.displayedRegions = regions;
                    this.model.region = this.hostData.region;
                });
            });
        });
        this.networkControllerService.getAllocatedIps(this.networkId).subscribe((allocatedIps) => {
            this.allocatedIps = allocatedIps.filter((allocatedIp)=> allocatedIp.expiresAt !== null)
            if(!_.isEmpty(this.allocatedIps)) {
                this.hasAllocatedIps = true;
            }
        })
    }

    close() {
        this.dialogRef.close();
    }

    providerChanged(provider: string) {
        this.regionService.findRegionsByProvider([provider]).subscribe((regions) => {
            this.displayedRegions = regions;
        });
    }

    disableCreate() {
        if (this.value=== 'ipAddress' && this.model.ipAddress === undefined) {
            this.disableButton = true;
        } else {
            this.disableButton = false;
        }
    }

    dataSelection() {
        if(this.value === 'provider') {
            _.unset(this.model, 'ipaddress')
            _.unset(this.model, 'region')
            this.model.provider = 'AWS'
        }
        if(this.value === 'ipAddress' && !_.isEmpty(this.selectedIp)) {
            this.model.region = this.selectedIp.region
            this.model.ipAddress = this.selectedIp.ip
            this.model.provider = this.selectedIp.provider
        }
        this.disableCreate();
    }


    moveNetwork() {
        this.networkControllerService.move(this.networkControllerId, this.model.provider, this.model.region, this.model.ipAddress).subscribe(
            (result) => {
                this.growlerService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Move Successful',
                        'Network controller move process started successfully<a href="' +
                            this.router.createUrlTree(['/process-executions']).toString() +
                            '">Click here to find out more</a>"'
                    )
                );
                this.dialogRef.close();
            },
            (httpErrorResponse) => {
                this.growlerService.show(
                    new GrowlerData('error', 'Error moving network controller', httpErrorResponse.message)
                );
            }
        );
    }
}
