import { Injectable } from '@angular/core';
import { AzureResourceGroup, AzureSubscription } from '@netfoundry-ui/shared/model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class AzureResourceGroupService extends MopResourceService {
    azureResourceGroupList: AzureResourceGroup[];
    private azureResourceGroupsSource = new Subject<AzureResourceGroup[]>();
    azureResourceGroups = this.azureResourceGroupsSource.asObservable();
    private apiSub = new Subscription();
    private mapSub = new Subscription();

    /**
     * Gets all azureResourceGroups for azureSubscription
     */
    get(): Observable<any> {
        if (this.apiSub) {
            this.apiSub.unsubscribe();
        }

        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }
        // watch for when the network updates, when it does, update the gateways observable
        this.apiSub = this.apiService.currentAzureSubscription.subscribe((azureSubscription) => {
            this.mapSub = this.apiService
                .getLinkedResource(new AzureSubscription(azureSubscription), 'resourceGroups')
                .pipe(map(this._extractEmbedded))
                .subscribe((data) => {
                    this.azureResourceGroupsSource.next(data);
                });
        });

        // this is an observable that watches for azure subscription changes
        return this.azureResourceGroups;
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const azureResourceGroups: AzureResourceGroup[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['azureResourceGroups'] !== undefined) {
            for (let i = 0; i < res['_embedded']['azureResourceGroups'].length; i++) {
                const azureResourceGroup = res['_embedded']['azureResourceGroups'][i];
                azureResourceGroups.push(new AzureResourceGroup(azureResourceGroup));
            }

            return azureResourceGroups;
        }

        return [];
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return 'azureResourceGroups';
    }
}
