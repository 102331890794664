import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { GrowlerService } from '@netfoundry-ui/shared/growler';
import { ApiService, MenuService } from '@netfoundry-ui/shared/services';
import _ from 'lodash';
import moment from 'moment';
import {skip, Subscription} from 'rxjs';
import { EventTemplateQueryParameter } from '@netfoundry-ui/shared/model';
import {LoginService} from "@netfoundry-ui/shared/authorization";

@Component({
    selector: 'app-recent-alerts',
    templateUrl: './recent-alerts.component.html',
    styleUrls: ['./recent-alerts.component.scss'],
})
export class RecentAlertsComponent implements OnInit, OnDestroy {
    items = [];
    alarmItems = [];

    @Output() hideGrowlersEvent: EventEmitter<boolean> = new EventEmitter();

    moment;
    sorting = 'timestamp';
    ordering = 'desc';
    filterString = '';
    isLoading = false;
    isV7Network = false;
    dateFormat = 'M/d/yy h:mm:ss a';
    isLoggedIn = false;
    @Input() isOpen;
    @Input() currentNetwork: any = null;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private growlerService: GrowlerService,
        private menuService: MenuService,
        private apiService: ApiService,
        private elasticsearch: ElasticsearchService,
        private loginService: LoginService
    ) {
        this.moment = moment;
    }

    ngOnInit() {
        this.getGrowlerMessages();

        this.subscriptions.add(
            this.growlerService.updateGrowler.subscribe((result) => {
                this.getGrowlerMessages();
            })
        );

        this.subscriptions.add(
          this.loginService.authenticationChanged.subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
          })
        );

        this.subscriptions.add(
            this.menuService.toggleRecentAlerts.pipe(skip(1)).subscribe(showAlerts => {
              this.isOpen = !this.isOpen;
            })
        );
    }

    getGrowlerMessages() {
        const growlerErrorString = sessionStorage.getItem('growlerErrors');
        if (growlerErrorString !== null && growlerErrorString !== undefined && growlerErrorString !== '') {
            const growlerErrorObject = JSON.parse(sessionStorage.getItem('growlerErrors'));
            this.items = growlerErrorObject['growlerMessages'];
        }
    }

    formatTime(theDate) {
        return moment.utc(theDate).local().format('M/D/YY h:mm a');
    }

    sort(sortBy) {
        if (this.sorting === sortBy) {
            if (this.ordering === 'asc') {
                this.ordering = 'desc';
            } else {
                this.ordering = 'asc';
            }
        } else {
            this.ordering = 'asc';
            this.sorting = sortBy;
        }
    }

    getSortClass(id) {
        if (id === this.sorting) {
            return this.ordering;
        } else {
            return '';
        }
    }

    trackByTimestamp(index, item) {
        return item.timestamp;
    }

    close() {
        this.isOpen = false;
        this.hideGrowlersEvent.emit(true);
    }

    ngOnChanges() {
        if (this.currentNetwork) {
            this.getData();
            this.isV7Network = this.apiService.getNetworkVersion(this.currentNetwork) >= 7;
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    public getData() {
        const index = 'ncevents';
        const orgId = this.currentNetwork.networkGroupId;
        this.isLoading = true;
        //reporting
        const eventTemplateParams: EventTemplateQueryParameter = {
            indexName: index,
            networkGroupId: orgId,
            networkId: this.currentNetwork.id,
            gte: 'now-72h',
            lte: 'now',
            size: '100',
        };
        this.subscriptions.add(
            //this.elasticsearch.search(orgId, index, this.getQuery()).subscribe((data) => {
            this.elasticsearch
                .apiTemplateSearch('ncevents_fabric_router_tmpl', eventTemplateParams)
                .subscribe((data) => {
                    const hits = _.get(data, 'hits.hits', []);
                    const filteredItems = _.filter(hits, (alarm) => {
                        const erId = _.get(alarm, '_source.nf_edge_router_id');
                        return !_.isEmpty(erId);
                    });
                    this.alarmItems = filteredItems;
                    this.isLoading = false;
                })
        );
    }

    public getQuery() {
        const networkId = this.currentNetwork.id;
        const groupId = this.currentNetwork.networkGroupId;
        const model: any = {
            size: 100,
            sort: [
                {
                    '@timestamp': {
                        order: 'desc',
                        unmapped_type: 'boolean',
                    },
                },
            ],
            query: {
                bool: {
                    must: [
                        {
                            match_all: {},
                        },
                        {
                            match_phrase: {
                                namespace: {
                                    query: 'fabric.routers',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                network_id: {
                                    query: networkId, //variable
                                },
                            },
                        },
                        {
                            match_phrase: {
                                organizationId: {
                                    query: groupId, //variable
                                },
                            },
                        },
                        {
                            range: {
                                timestamp: {
                                    gte: 'now-72h', // default to last 3 days for now
                                    lte: 'now',
                                    format: 'epoch_millis',
                                },
                            },
                        },
                    ],
                },
            },
        };
        return model;
    }

    onMouseLeave() {
        if (!this.menuService.locked) {
            this.close();
        }
    }
}
