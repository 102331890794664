<div class="menu-item-container">
    <div *ngIf="completed" class="check-complete"></div>
    <div class="title-container">
        <div class="title-icon {{ iconClass }}"></div>
        <div class="title-text label">
            {{ title }}<br />
            <div [innerHtml]="subtitle" class="sub-title-text"></div>
        </div>
    </div>
    <div
        (click)="menuButtonedClicked($event)"
        [ngClass]="{ disabled: disabled }"
        [title]="disabled ? disabledTooltip : ''"
        class="menu-item-button save"
    >
        {{ buttonText }}
        <span *ngIf="showSpinner" class="spinner"></span>
    </div>
</div>
