import { Component, Input, OnInit } from '@angular/core';
import { GrowlerService } from '@netfoundry-ui/shared/growler';
import { FeatureService, LoggerService } from '@netfoundry-ui/shared/services';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import _ from 'lodash';

import { ZitiOptionsService } from '@netfoundry-ui/shared/helpers';

@Component({
    selector: 'app-listen-options',
    templateUrl: './listen-options.component.html',
    styleUrls: ['./listen-options.component.scss'],
    providers: [FromDatePipe],
})
export class ListenOptionsComponent implements OnInit {
    @Input() model: any = {
        data: {
            allowedSourceAddresses: [],
            listenOptions: {},
        },
    };
    @Input() isNew = true;
    @Input() isEditing = true;
    @Input() errors: any = {};
    @Input() readOnly = false;

    terminatorType;

    constructor(
        private logger: LoggerService,
        private growlerService: GrowlerService,
        private optionsService: ZitiOptionsService,
        public featureService: FeatureService
    ) {}

    ngOnInit() {
        this.initModel();
    }

    initModel() {
        if (!this.model?.data?.listenOptions) {
            _.set(this.model, 'data.listenOptions', {});
        }
        if (!_.get(this.model, 'data.listenOptions.bindUsingEdgeIdentity')) {
            this.terminatorType = 'edge';
        } else {
            this.terminatorType = 'fixed';
        }
    }

    terminatorTypeChanged() {
        _.set(this.model, 'data.listenOptions.bindUsingEdgeIdentity', this.terminatorType === 'edge');
        if (this.model.data.listenOptions.bindUsingEdgeIdentity) {
            _.unset(this.model, 'data.listenOptions.identity');
        }
    }
}
