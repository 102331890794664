import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';
import { TEMPLATE_SEARCH_SERVICE, TemplateSearchService } from "@netfoundry-ui/shared/apiv2";
import { TobytesPipe } from '@netfoundry-ui/ui/pipes';
import { Chart } from 'angular-highcharts';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { NetworkUtilizationTemplateQueryParameter } from '@netfoundry-ui/shared/model';

import { isEmpty, isNil } from 'lodash';

@Component({
    selector: 'app-ziti-utilization-table',
    templateUrl: './ziti-utilization-table.component.html',
    styleUrls: ['./ziti-utilization-table.component.scss'],
    providers: [TobytesPipe, { provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }],
})
export class ZitiUtilizationTableComponent implements OnInit, OnChanges, OnInit, OnDestroy {
    @Input() networkId: any = null;
    @Input() organizationId: any = null;
    @Input() height = '300px';
    @Input() aggregateField = 'nf_endpoint_name';
    @Input() label = 'Items';
    @Output() filterItemOutput = new EventEmitter<any>();
    @Input() filename: string;
    @Input() newStartTime: any;
    @Input() newEndTime: any;
    @Input() dateFilter: any;
    currentItemFilter: string;
    @Input() selectedAttributes = [];
    chart: Chart;
    colors = ['#0273fb', '#08dc5a', '#FF0D49', '#1aadce', '#6d00f2', '#ffc000', '#ff7e00', '#ca0000', '#00aeb0'];
    noData = true;
    isLoading = false;
    initialized = false;
    currentOrgId;
    zitiId;
    result_options = {};
    result_series = [];
    interval = '1m';
    items = [];
    @Input() locked = false;
    @Input() filterField = null;
    @Input() filterValue = null;
    // @Input() endTime = Date.now();
    // @Input() startTime = this.endTime - 24 * 60 * 60 * 1000;
    @Output() dataReceived = new EventEmitter<any>();
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private elasticsearch: ElasticsearchService,
        private toBytes: TobytesPipe,
        public apiService: ApiService,
        public csvDownloadService: CsvDownloadService,
        public filterService: TableFilterService,
        @Inject(TEMPLATE_SEARCH_SERVICE) public templateService: TemplateSearchService
    ) {
        this.newStartTime = moment().subtract(24, 'hours');
        this.newEndTime = moment();
    }

    ngOnInit() {
        this.initialized = true;
        this.generateData();
        this.subscription.add(
            this.filterService.setDateFilterEvent.subscribe(() => {
                this.newStartTime = this.filterService.getStartTime();
                this.newEndTime = this.filterService.getEndTime();
                this.generateData();
            })
        );
    }

    ngOnChanges() {
        // @TODO - fix me,
        if (
            this.filterValue !== this.currentItemFilter &&
            this.filterValue !== undefined &&
            this.currentItemFilter !== undefined
        ) {
            // this.logger.info('LABEL: '+ this.label +' FILTER VALUE: '+this.filterValue+ ' CURRENT: '+this.currentItemFilter);

            if (
                this.filterValue !== this.currentItemFilter &&
                this.filterValue !== null &&
                this.currentItemFilter !== undefined
            ) {
                this.currentItemFilter = null;
            }
        }

        this.generateData();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setItemFilter(value) {
        if (value === this.currentItemFilter) {
            value = null;
        }

        this.currentItemFilter = value;
        this.filterItemOutput.emit(value);
    }

    public getData() {
        const index = 'ncutilization';
        const items = [];

        this.noData = true;
        this.isLoading = true;

        //reporting
        let componentName: any = '';
        let componentValue: any = '';
        let attributes: any = '';

        if (
            this.filterField !== null &&
            this.filterValue !== null &&
            this.filterValue !== undefined &&
            this.filterField !== undefined
        ) {
            componentName = this.filterField;
            componentValue = this.filterValue;
        }
        if (this.selectedAttributes.length > 0) {
            attributes = JSON.stringify(this.selectedAttributes);
        }
        const networkUtilizationTemplateQueryParameter: NetworkUtilizationTemplateQueryParameter =
          this.templateService.getUtilizationTemplateQuery(
            this.newStartTime.valueOf(),
            this.newEndTime.valueOf(),
            this.dateFilter,
            componentName,
            componentValue,
            attributes,
            this.networkId,
            this.organizationId,
            index,
            this.aggregateField,
            '5000'
          );

        this.subscription.add(
            //this.elasticsearch.search(this.organizationId, index, this.getQuery())
            this.elasticsearch
                .apiTemplateSearch(
                    this.templateService.UTILIZATION_SUM_BY_COMPONENT_TEMPLATE,
                    networkUtilizationTemplateQueryParameter
                )
                .subscribe((data) => {
                    const items = this.templateService.getUsageSumByComponentData(data);
                    if (isEmpty(items) || isNil(items)) {
                      this.isLoading = false;
                      this.noData = true;
                      return;
                    }
                    this.items = items;
                    this.isLoading = false;
                    this.noData = false;
                    this.dataReceived.emit(this.items);
                })
        );
    }

    public getQuery() {
        console.log(this.selectedAttributes);
        const model: any = {
            aggs: {
                items: {
                    terms: {
                        field: this.aggregateField + '.keyword',
                        size: 5000,
                        order: {
                            item_sum: 'desc',
                        },
                    },
                    aggs: {
                        item_sum: {
                            sum: {
                                field: 'usage',
                            },
                        },
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            match_phrase: {
                                'network_id.keyword': {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                'organizationId.keyword': {
                                    query: this.organizationId,
                                },
                            },
                        },

                        {
                            // filter down to two usage types since these are the items that we will calculate billing on
                            bool: {
                                should: [
                                    {
                                        match_phrase: {
                                            // traffic delivered through the fabric to service's destination, renders as TX
                                            'usage_type.keyword': 'usage.ingress.tx',
                                        },
                                    },
                                    {
                                        match_phrase: {
                                            // traffic received back through the fabric to the initiator, renders as RX
                                            'usage_type.keyword': 'usage.egress.tx',
                                        },
                                    },
                                ],
                                minimum_should_match: 1,
                            },
                        },
                    ],
                    filter: {
                        bool: {
                            must: [{ match_all: {} }],
                        },
                    },
                },
            },
        };

        // this will allow us to filter by endpoint, edge router, or service
        if (this.filterField != null && this.filterValue != null) {
            const search = { match_phrase: {} };
            search.match_phrase[this.filterField] = { query: this.filterValue };
            model.query.bool.must.push(search);
        }

        if (this.selectedAttributes.length > 0) {
            const attributeFilter = {
                terms: {
                    'attributes.keyword': this.selectedAttributes,
                },
            };

            model.query.bool.filter.bool.must.push(attributeFilter);
        }

        model.query.bool.must.push({
            range: {
                '@timestamp': {
                    gte: this.newStartTime.valueOf(),
                    lte: this.newEndTime.valueOf(),
                    format: 'epoch_millis',
                },
            },
        });

        return model;
    }

    private generateData() {
        if (this.initialized && this.networkId !== null && this.networkId && this.organizationId !== null) {
            this.getData();
        }
    }
}
