import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    AzureResourceGroup,
    AzureSubscription,
    AzureVirtualWan,
    AzureVirtualWanSite,
    Endpoint,
    Network,
} from '@netfoundry-ui/shared/model';
import {
    ApiService,
    AzureResourceGroupService,
    AzureVirtualWanService,
    AzureVirtualWanSiteService,
    GatewayService,
    LocationService,
    LoggerService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-virtual-wans-form',
    templateUrl: './virtualwansiteform.component.html',
    styleUrls: ['./virtualwansiteform.component.scss'],
})
export class VirtualWanSiteFormComponent implements OnInit, OnDestroy {
    @Input() model: AzureVirtualWanSite = new AzureVirtualWanSite({});
    @Output() refreshEvent: EventEmitter<boolean> = new EventEmitter();
    azureResourceGroups: AzureResourceGroup[] = [];
    gateways: Endpoint[] = [];
    azureVirtualWans: AzureVirtualWan[] = [];
    currentAzureSubscription = new AzureSubscription({});
    currentAzureSubscriptionId;
    dataCenters = [];

    isSelected = false;
    isAdding = false;
    isEditing = false;
    isComplete = false;
    searchString = '';
    errorName = false;
    isLoading = true;
    errors = {
        name: false,
        azureResourceGroupName: false,
        endpointId: false,
        azureVirtualWanId: false,
        dataCenterId: false,
        publicIpdAddress: false,
        advertisedPrefixes: false,
    };

    private subscription = new Subscription();
    private currentNetwork: Network = new Network({});
    private wasModelChanged = false;

    constructor(
        private logger: LoggerService,
        private apiService: ApiService,
        private growlerService: GrowlerService,
        private dialogRef: MatDialogRef<VirtualWanSiteFormComponent>,
        private gatewayService: GatewayService,
        private azureResourceGroupService: AzureResourceGroupService,
        private azureVirtualWanService: AzureVirtualWanService,
        private azureVirtualWanSiteService: AzureVirtualWanSiteService,
        private locationService: LocationService,
        private validateService: ValidateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data.model !== undefined) {
            this.model = data.model;
            this.isAdding = false;
            this.isEditing = true;
        } else {
            this.model = new AzureVirtualWanSite({});

            // if these are not the empty string the placeholders won't show up
            this.model.endpointId = '';
            this.model.azureResourceGroupName = '';
            this.model.azureVirtualWanId = '';
            this.model.dataCenterId = '';

            this.isAdding = true;
            this.isEditing = false;
        }
    }

    ngOnInit() {
        this.errorName = false;

        this.subscription.add(
            this.apiService.currentAzureSubscription.subscribe((azureSubscription) => {
                this.currentAzureSubscription = new AzureSubscription(azureSubscription);
                this.currentAzureSubscriptionId = this.currentAzureSubscription.getId();
            })
        );

        this.subscription.add(
            this.apiService.currentNetwork.subscribe((network) => {
                this.currentNetwork = new Network(network);
            })
        );

        this.subscription.add(
            this.gatewayService.get().subscribe((gateways) => {
                this.gateways = gateways;
            })
        );

        this.subscription.add(
            this.azureResourceGroupService.get().subscribe((groups) => {
                this.logger.info('Resource Groups', groups);
                this.azureResourceGroups = groups;
            })
        );

        this.subscription.add(
            this.locationService.get().subscribe((dataCenters) => {
                const azureDcs = [];
                for (const dc of dataCenters) {
                    if (dc.provider === 'AZURE') {
                        azureDcs.push(dc);
                    }
                }

                this.dataCenters = azureDcs;
            })
        );

        this.subscription.add(
            this.azureVirtualWanService.get().subscribe((vwans) => {
                this.logger.info('Virtual Wans', vwans);
                this.azureVirtualWans = vwans;
            })
        );

        this.isAdding = true;
        this.isEditing = false;
    }

    // isAssigned(item, type) {
    //   if (typeof(item['getId']) !== 'function') {
    //     return false;
    //   }
    //   if (this.selectedSites.indexOf(item.getId())===-1) {
    //     return false;
    //   }
    //   return true;
    // }
    //
    // removeAllSites() {
    //   this.selectedSites = [];
    // }
    //
    // addAllSites() {
    //   this.selectedSites = [];
    //   for(let site of this.availableSites) {
    //     this.selectedSites.push(site);
    //   }
    // }

    // moveFrom(item) {
    //     this.selectedSites.splice(this.selectedSites.indexOf(item.getId()), 1);
    // }
    //
    // moveTo(item) {
    //   this.selectedSites.push(item.getId());
    // }

    // clearFilter() {
    //   this.searchString = '';
    // }

    hide() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    save() {
        if (this.validate()) {
            this.growlerService.show(
                new GrowlerData(
                    'info',
                    'Saving',
                    'Saving Site Information',
                    'Saving Azure Virtual Wan Site Data, Please Wait...'
                )
            );
            this.model.azureSubscriptionId = this.currentAzureSubscriptionId;
            this.subscription.add(
                this.azureVirtualWanSiteService.save(this.model).subscribe((data) => {
                    this.logger.info('AZURE VWAN Site DATA', data);
                    this.model = new AzureVirtualWanSite(data);
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Creation Complete',
                            'Virtual Wan Site information has been saved.'
                        )
                    );
                    this.hide();
                })
            );
        }
    }

    validate(): boolean {
        let isValid = true;

        // reset validation
        for (const key of Object.keys(this.errors)) {
            this.errors[key] = false;
        }
        this.errors['name'] = !this.validateService.isValidName(this.model.name);
        this.errors['endpointId'] = !this.validateService.hasValue(this.model.endpointId);
        this.errors['azureResourceGroupName'] = !this.validateService.hasValue(this.model.azureResourceGroupName);
        this.errors['dataCenterId'] = !this.validateService.hasValue(this.model.dataCenterId);
        this.errors['publicIpAddress'] = !this.validateService.isValidIP(this.model.publicIpAddress);
        // TODO: Update to fix for according to BGP changes
        this.errors['advertisedPrefixes'] = !this.validateService.isValidCidrIP(this.model.bgp.advertisedPrefixes[0]);
        this.errors['azureVirtualWanId'] = !this.validateService.hasValue(this.model.azureVirtualWanId);

        for (const key of Object.keys(this.errors)) {
            if (this.errors[key]) {
                isValid = false;
            }
        }

        return isValid;
    }
}
