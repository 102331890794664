import { Resource, HateoasResource } from '@lagoshny/ngx-hateoas-client';
@HateoasResource('host-addresses')

export class AllocatedIp extends Resource {
    createdAt: string;
    id: string;
    ip: string;
    zitiId: string;
    networkId: string;
    provider: string;
    region: string;
    expiresAt: string;
    selected = false;
}
