import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeatureFormAdvancedPlatformServiceFormModule } from '@netfoundry-ui/feature/form/advanced-platform-service-form';
import { FeatureFormPlatformServiceFormModule } from '@netfoundry-ui/feature/form/platform-service-form';
import { FeatureV7CardModule } from '@netfoundry-ui/feature/v7-card';
import { MatDialogModule } from '@angular/material/dialog';
import { ServiceCardsComponent } from './service-cards.component';

@NgModule({
    declarations: [ServiceCardsComponent],
    exports: [ServiceCardsComponent],
    imports: [
        CommonModule,
        FeatureV7CardModule,
        FeatureFormAdvancedPlatformServiceFormModule,
        FeatureFormPlatformServiceFormModule,
        MatDialogModule,
    ],
})
export class FeatureServiceCardsModule {}
