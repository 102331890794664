import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NOTIFICATION_CONFIG, NotificationConfig, NotificationService } from './notification.service';

@NgModule({
    imports: [CommonModule, HttpClientModule],
    declarations: [],
    exports: [],
    providers: [],
})
export class SharedNotificationModule {
    static forRoot(config: NotificationConfig): ModuleWithProviders<SharedNotificationModule> {
        return {
            ngModule: SharedNotificationModule,
            providers: [NotificationService, { provide: NOTIFICATION_CONFIG, useValue: config }],
        };
    }
}
