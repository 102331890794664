import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { BillingEnabledService } from '@netfoundry-ui/feature/shared-services';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ElasticsearchService, UtilizationTotalsQuery } from '@netfoundry-ui/shared/elasticsearch';
import { Network, NetworkV2 } from '@netfoundry-ui/shared/model';
import { ApiService, ErrorHistoryService, LoggerService, URLS } from '@netfoundry-ui/shared/services';
import { TobytesPipe } from '@netfoundry-ui/ui/pipes';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { NetworkUtilizationTemplateQueryParameter } from '@netfoundry-ui/shared/model';

@Component({
    selector: 'app-billing-summary',
    templateUrl: './billing-summary.component.html',
    styleUrls: ['./billing-summary.component.scss'],
    providers: [TobytesPipe, ElasticsearchService],
})
export class BillingSummaryComponent implements OnInit, OnChanges, OnDestroy {
    @Input() showDashLink = false;

    // the start of the billing cycle, defaults to start of current month
    @Input() cycleStart: moment.Moment = moment().startOf('month').startOf('day');

    // total number of bytes allowed, default is 1 GB
    @Input() allowedUsage = 0;

    // the number of free trial days left
    @Input() freeTrialLeft = 0;

    // the end of the cycle
    @Input() cycleEnd: moment.Moment = moment(this.cycleStart).add(1, 'months').endOf('day');

    @Input() networkFilter: Network | NetworkV2 = new Network({});

    // the billing date
    billDate: moment.Moment = moment().endOf('month').endOf('day');

    // number of days left in cycle
    daysLeft = 0;

    // projected usage
    projected = 0;

    // the total usage of the organization
    totalUtilization = 0;

    // percentage of total usage vs projected usage
    percentage = 0;

    noData = false;
    networkLoading = false;
    networkId: any = null;

    // the moment date format
    dateFormat = 'MMM D';

    // the ammount of usage remaining. not currently used
    remaining = this.allowedUsage;

    // billing displays data up to the previous hour
    utilizationAsOfTime = moment().startOf('hour').subtract(1, 'hours').format('MMM DD HH:mm A');
    regionUtilization = [];
    networkListUtilization = [];
    initialized = false;
    canReadElasticSearch = true;
    networkGroupId;
    Billing = URLS.BILLING;
    private utilizationSubscription = new Subscription();
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private authorizationService: AuthorizationService,
        private elasticsearch: ElasticsearchService,
        private toBytes: TobytesPipe,
        private apiService: ApiService,
        public billingEnabledService: BillingEnabledService,
        private errorHistoryService: ErrorHistoryService
    ) {}

    ngOnInit() {
        // the elastic search query returns a 400 if we do not wait for the current network to be set
        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.networkGroupId = org.getId();
                this.ngOnChanges();
            })
        );
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((currentNetwork) => {
                this.networkId = currentNetwork.id;
                this.networkFilter = currentNetwork;
                this.initialized = true;
                this.ngOnChanges();
            })
        );
    }

    ngOnChanges() {
        // make sure we can run permissions before we do anything
        if (!this.initialized || this.networkId === null || this.networkGroupId === null) {
            return;
        }

        // getting the end of the cycle by adding one month to the start of the cycle
        this.cycleEnd = moment(this.cycleStart).add(1, 'months');

        // getting the bill date by adding one day to the end of the cycle
        this.billDate = moment(this.cycleEnd).add(1, 'days');

        // determining the days left
        this.daysLeft = this.cycleEnd.diff(moment(), 'days');

        // resetting the utilization totals
        this.totalUtilization = 0;

        this.canReadElasticSearch = this.authorizationService.canReadElasticSearch(this.networkGroupId, this.networkId);

        if (this.canReadElasticSearch) {
            // query requires the current network is set
            if (this.networkId != null && this.networkId !== '') {
                this.networkLoading = true;
                this.noData = false;

                if (this.apiService.getNetworkVersion(this.networkFilter) < 7) {
                    this.getUtilizationData();
                } else {
                    this.getUtilizationDataV7();
                }
            } else {
                this.networkLoading = false;
                this.noData = true;
            }
        } else {
            this.networkLoading = false;
        }
    }

    public getUtilizationData() {
        if (this.networkGroupId === null) {
            console.warn('No org specified');
            this.networkLoading = false;
            this.noData = true;
            return;
        }

        const query = new UtilizationTotalsQuery(this.logger);
        query.addTimeFilter(this.cycleStart.valueOf(), this.cycleEnd.valueOf());
        query.addFilter('organizationId', this.networkGroupId);
        query.addFilter('networkId', this.networkId);
        query.matchFromList('NetworkDataType', ['DropTcpTx', 'DropUdpTx']);

        const queryModel = query.getQuery();
        queryModel.aggs = this._getAggregation();

        const index = 'ncvtchistorical';
        this.logger.info('Billing Summary Query:', JSON.stringify(queryModel));

        this.elasticsearch
            .search(this.networkGroupId, index, queryModel, {
                networkId: this.networkId,
            })
            .subscribe(
                (data) => {
                    const aggregate = 'billing_region';
                    this.totalUtilization = 0;

                    if (data.aggregations != null && data.aggregations[aggregate] != null) {
                        // get the total from the result so we don't have to calculate ourselves
                        if (data.aggregations['TotalBytes'] !== null) {
                            this.totalUtilization = data.aggregations['TotalBytes']['value'];
                            this.logger.info('Total Utilization', this.totalUtilization);
                        }

                        if (data.aggregations[aggregate].buckets.length > 0) {
                            this.regionUtilization = [];
                            for (const bucket of data.aggregations[aggregate].buckets) {
                                this.regionUtilization.push(this._toRegionModel(bucket.key, bucket.TotalBytes.value));
                            }

                            this.networkLoading = false;
                            this.noData = false;

                            // determining how many days there have been in the cycle
                            const daysIntoCycle = moment().diff(this.cycleStart, 'days');

                            // determining the average utilization per day
                            const utilizationPerDay = this.totalUtilization / daysIntoCycle;

                            this.projected = this.totalUtilization + utilizationPerDay * this.daysLeft;

                            // getting the percentage of total utilization to projected
                            this.percentage = (this.totalUtilization / this.projected) * 100;

                            this.remaining = this.allowedUsage - this.totalUtilization;
                        } else {
                            // no aggregations
                            console.warn('No timeline aggregrations present in utilization timeline data');
                            this.noData = true;
                            this.networkLoading = false;
                        }
                    } else {
                        // bad response
                        this.logger.error('Bad response from utilization timeline metric query');
                        this.noData = true;
                        this.networkLoading = false;
                    }
                },
                (error) => {
                    this.errorHistoryService.addError(error.message);
                }
            );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public prettyRegionProgressBarId(region) {
        return `ProgressBar${region.label.replace(new RegExp('/', 'g'), '-').replace(new RegExp(' ', 'g'), '')}`;
    }

    public getUtilizationDataV7() {
        if (this.networkGroupId === null) {
            console.warn('No org specified');
            this.networkLoading = false;
            this.noData = true;
            return;
        }

        const queryModel = this._getV7QueryModel();

        const index = 'ncutilization';
        // const index = 'ncvtccurrent';
        this.logger.info('Billing Summary Query V7:', JSON.stringify(queryModel));
        //reporting
        const networkUtilizationTemplateQueryParameter: NetworkUtilizationTemplateQueryParameter = {
            gte: this.cycleStart.valueOf() + '',
            lte: this.cycleEnd.valueOf() + '',
            size: '0',
            aggTermSize: '2',
            networkId: this.networkId,
            networkGroupId: this.networkGroupId,
            indexName: index,
        };
        // we may not need to unsubscribe from this manually, but it doesn't hurt to do so
        //this.elasticsearch.search(this.networkGroupId, index, queryModel, { networkId: this.networkId, })
        this.elasticsearch
            .apiTemplateSearch('ncutilization_usage_summary_tmpl', networkUtilizationTemplateQueryParameter)
            .subscribe(
                (data) => {
                    const aggregate = 'usage_sums';

                    if (data.aggregations != null && data.aggregations[aggregate] != null) {
                        if (data.aggregations[aggregate].buckets.length > 0) {
                            this.networkListUtilization = [];
                            this.totalUtilization = 0;
                            // calculate the total so we can use it for the percentage
                            for (const bucket of data.aggregations[aggregate].buckets) {
                                this.totalUtilization = this.totalUtilization + bucket.usage_bytes.value;
                            }

                            // generate the models
                            for (const bucket of data.aggregations[aggregate].buckets) {
                                this.networkListUtilization.push(
                                    this._toSliceModel(bucket.key, bucket.usage_bytes.value)
                                );
                            }

                            this.networkLoading = false;
                            this.noData = false;

                            // determining how many days there have been in the cycle
                            const daysIntoCycle = moment().diff(this.cycleStart, 'days');

                            // determining the average utilization per day
                            const utilizationPerDay = this.totalUtilization / daysIntoCycle;

                            this.projected = this.totalUtilization + utilizationPerDay * this.daysLeft;

                            // getting the percentage of total utilization to projected
                            this.percentage = (this.totalUtilization / this.projected) * 100;

                            this.remaining = this.allowedUsage - this.totalUtilization;
                        } else {
                            // no aggregations
                            console.warn('No timeline aggregrations present in utilization timeline data');
                            this.noData = true;
                            this.networkLoading = false;
                        }
                    } else {
                        // bad response
                        this.logger.error('Bad response from utilization timeline metric query');
                        this.noData = true;
                        this.networkLoading = false;
                    }
                },
                (error) => {
                    this.errorHistoryService.addError(error.message);
                    this.networkLoading = false;
                }
            );
    }

    private _getAggregation() {
        return {
            TotalBytes: {
                sum: {
                    field: 'bytes',
                },
            },
            billing_region: {
                terms: {
                    field: 'billing_region.keyword',
                    size: 5,
                    order: {
                        TotalBytes: 'desc',
                    },
                },
                aggs: {
                    TotalBytes: {
                        sum: {
                            field: 'bytes',
                        },
                    },
                },
            },
        };
    }

    private _toRegionModel(region: string, value: number) {
        const labelLookup = {
            NA: 'North America',
            SA: 'South America',
            APAC: 'Asia Pacific',
            EMEA: 'Europe / Middle East / Africa',
        };

        return {
            bytes: value,
            converted: this.toBytes.transform(value),
            region: region,
            label: labelLookup[region],
            percentage: ((value / this.totalUtilization) * 100).toFixed(2),
        };
    }

    private _toSliceModel(slice: string, value: number) {
        return {
            bytes: value,
            converted: this.toBytes.transform(value),
            // 'network': networkName,
            label: slice,
            percentage: ((value / this.totalUtilization) * 100).toFixed(2),
        };
    }

    private _getV7QueryModel() {
        return {
            aggs: {
                usage_sums: {
                    terms: {
                        field: 'usage_type.keyword',
                        size: 2,
                        order: {
                            usage_bytes: 'desc',
                        },
                    },
                    aggs: {
                        usage_bytes: {
                            sum: {
                                field: 'usage',
                            },
                        },
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            range: {
                                '@timestamp': {
                                    gte: this.cycleStart.valueOf(),
                                    lte: this.cycleEnd.valueOf(),
                                    format: 'epoch_millis',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                network_id: {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                organizationId: {
                                    query: this.networkGroupId,
                                },
                            },
                        },
                        {
                            bool: {
                                should: [
                                    {
                                        match_phrase: {
                                            'usage_type.keyword': 'usage.ingress.tx',
                                        },
                                    },
                                    {
                                        match_phrase: {
                                            'usage_type.keyword': 'usage.egress.tx',
                                        },
                                    },
                                ],
                                minimum_should_match: 1,
                            },
                        },
                    ],
                },
            },
        };
    }
}
