import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StoredSecret, ENVIRONMENT, Environment } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import {
    HateoasResourceOperation,
} from '@lagoshny/ngx-hateoas-client';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PrivateKeyService {

    lastPageCount = 0;
    lastTotalCount = 0;
    apiUrl: string;


    constructor(@Inject(HTTP_CLIENT) public http: HttpClient, @Inject(ENVIRONMENT) public environment: Environment) {
        this.apiUrl = environment.v3Enabled ? environment.v3apiUrl : environment.v2apiUrl;
    }


    validateKey(id: string, token: string, value: string,) {
        const headers = new HttpHeaders().set('nf-validate', '').set('X-Skip-Interceptor', 'true');
        const body = value; 
        return this.http
            .post(`${this.apiUrl}stored-secrets/${id}?token=${token}`, body, {
                headers: headers,
            })
            .toPromise();
    }

    public setPrivateKey(id: string, token: string, value: string) {
        const options: any = {};
        const body = value
        const url = `${this.apiUrl}stored-secrets/${id}?token=${token}`;
        return this.http.post(url, body, options) as Observable<any>;
    }


}