<div class="fullModal opened" id="CollectorAddModal">
    <div class="fullRow">
        <div class="title" style="filter: brightness(50%)">Create A New Netflow Collector</div>
        <div class="subtitle">Enter your collector attributes</div>
        <div class="sized">
            <div class="label row">
                Netflow Name<span [ngClass]="{ error: errors['name'] }" class="note">Required</span>
            </div>
            <div class="row">
                <input
                    (keyup.enter)="save()"
                    [(ngModel)]="model.name"
                    [ngClass]="{ error: errors['name'] }"
                    class="restrictNormal"
                    id="EditCollectorName"
                    maxlength="64"
                    minlength="5"
                    placeholder="What would you like to call this collector?"
                    type="text"
                />
            </div>
            <div class="row">
                <div class="half padRight">
                    <div class="label row">
                        IP Address<span [ngClass]="{ error: errors['ipAddress'] }" class="note">Required</span>
                    </div>
                    <div class="row">
                        <input
                            [(ngModel)]="model.ipAddress"
                            [disabled]="!canEdit"
                            [ngClass]="{ error: errors['ipAddress'] }"
                            class="restrictIp"
                            id="IPAddress"
                            placeholder="Example 10.0.1.20"
                            type="text"
                        />
                    </div>
                </div>
                <div class="half">
                    <div class="label row">
                        Port<span [ngClass]="{ error: errors['port'] }" class="note">Required</span>
                    </div>
                    <div class="padRight">
                        <div class="row">
                            <input
                                [(ngModel)]="model.port"
                                [ngClass]="{ error: errors['port'] }"
                                class="restrictNumeric"
                                id="Port"
                                placeholder="2055"
                                type="text"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!hideNetworkGroupSelect" id="NetworkGroupField">
                <div class="label row">
                    Network Group<span [ngClass]="{ error: errors['networkGroupId'] }" class="note">Required</span>
                </div>
                <select
                    [(ngModel)]="model.networkGroupId"
                    [ngClass]="{ error: errors['networkGroupId'] }"
                    id="NetworkGroupSelect"
                >
                    <option value="">Select A Network Group</option>
                    <option *ngFor="let networkGroup of networkGroupList | sortby: 'name'" [value]="networkGroup.id">
                        {{ networkGroup.name }}
                    </option>
                </select>
            </div>
            <div class="row buttonRow right" style="margin-top: 20px">
                <button (click)="save()" *ngIf="!processing" class="save" id="SaveNetflowButton">
                    {{ isEditing ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
        </div>
        <div (click)="close()" class="buttonBall close" id="CloseCollectorButton">
            <div class="buttonLabel">ESC</div>
        </div>
    </div>
</div>
