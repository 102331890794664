<div class="fullModal opened tunnelers-form-container">
    <div class="title" style="filter: brightness(50%)">Install a Tunneler</div>
    <div class="subtitle">Choose the software for your OS</div>
    <div class="tunneler-cards-container">
        <app-tunneler-card
            (selected)="itemSelected()"
            *ngFor="let item of tunnelerItems"
            [item]="item"
        ></app-tunneler-card>
    </div>
    <div (click)="hide()" class="buttonBall close" id="EscTunnelerCards">
        <div class="buttonLabel">ESC</div>
    </div>
</div>
