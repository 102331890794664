<div class="fullSized" style="margin-top: 30px">
    <div class="area" id="ItemsArea">
        <app-noitems [image]="'No_Gateways'" [isEmpty]="growlerErrors.length === 0" [typeName]="'errors'"></app-noitems>

        <div class="searchArea" id="GrowlerEventsSearchBar">
            <input [(ngModel)]="filterString" class="curved" id="SearchGrowlerEvents" placeholder="Filter" />
        </div>
        <div [hidden]="growlerErrors.length === 0" id="ItemTable">
            <div class="tableGrid tHeadRow">
                <div (click)="sort('title')" [ngClass]="getSortClass('title')" class="tHead desktop sort">Title</div>
                <div (click)="sort('subtitle')" [ngClass]="getSortClass('subtitle')" class="tHead desktop sort">
                    Description
                </div>
                <div (click)="sort('content')" [ngClass]="getSortClass('content')" class="tHead desktop sort">
                    Message
                </div>
                <div (click)="sort('timestamp')" [ngClass]="getSortClass('timestamp')" class="tHead sort">Time</div>
            </div>
            <div id="ItemsList">
                <div
                    *ngFor="
                        let error of growlerErrors | tableSearch: filterString | sortby: sorting:ordering;
                        let index = index;
                        trackBy: trackByTimestamp
                    "
                    [attr.data-index]="index"
                    class="tableGrid tRow logs"
                    id="Item_{{ item.id }}"
                >
                    <div class="tCol clickCol desktop" id="ErrorTitle_{{ index }}" title="{{ error.title }}">
                        {{ error.title }}
                    </div>
                    <div class="tCol clickCol desktop" id="ErrorSubtitle_{{ index }}" title="{{ error.subtitle }}">
                        {{ error.subtitle }}
                    </div>
                    <div
                        class="tCol clickCol desktop errorContent"
                        id="ErrorContent_{{ index }}"
                        title="{{ error.content }}"
                    >
                        {{ error.content }}
                    </div>
                    <div
                        class="tCol clickCol"
                        id="ErrorTimestamp_{{ index }}"
                        title="{{ formatTime(error.timestamp) }}"
                    >
                        {{ formatTime(error.timestamp) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
