<div class="fullColor"></div>
<div class="overlay"></div>
<div class="row center">
    <div class="dashboardtitle">{{ currentNetwork.name }} Dashboard</div>
    <div class="sixGrid gapped">
        <app-countbox
            *ngIf="canListEndpoints"
            count="{{ totalGateways + totalClusters }}"
            iconStyle="icon-Icon_Gateway"
            id="GatewaysBox"
            label="Gateways"
            link="gateways"
        ></app-countbox>
        <app-countbox
            *ngIf="canListEndpoints"
            count="{{ totalClients }}"
            iconStyle="icon-Icon_CL"
            id="ClientsBox"
            label="Clients"
            link="clients"
        ></app-countbox>
        <app-countbox
            *ngIf="canListGroups"
            count="{{ totalGroups }}"
            iconStyle="icon-Icon_GP"
            id="GroupsBox"
            label="Groups"
            link="groups"
        ></app-countbox>
        <app-countbox
            *ngIf="canListServices"
            count="{{ totalServices }}"
            iconStyle="icon-services"
            id="ServicesBox"
            label="Services"
            link="services"
        ></app-countbox>
        <app-countbox
            *ngIf="canListAppWans"
            count="{{ totalAppWans }}"
            iconStyle="icon-appwans"
            id="AppWansBox"
            label="AppWans"
            link="appwans"
        ></app-countbox>
        <app-downloadbox id="DownloadsBox"></app-downloadbox>
    </div>
</div>
<div class="row">
    <div class="splitGrid gapped">
        <div>
            <div class="cardStack full" style="height: 605px">
                <!-- Mop Event Timeline -->
                <app-mop-timeline
                    [endTime]="endTime"
                    [networkId]="currentNetworkId"
                    [startTime]="startTime"
                ></app-mop-timeline>
            </div>

            <div class="cardStack full billing" id="OpenAlarmsArea">
                <div class="title">Network Health</div>
                <div class="options">
                    <div class="toggle">
                        <div
                            (click)="setHealthView('uptime')"
                            [ngClass]="{ option: true, selected: healthView === 'uptime' }"
                        >
                            Uptime
                        </div>
                        <div
                            (click)="setHealthView('alarms')"
                            [ngClass]="{ option: true, selected: healthView === 'alarms' }"
                        >
                            Alarms
                        </div>
                    </div>
                </div>

                <div [hidden]="healthView === 'alarms'">
                    <!-- uptime pie chart -->
                    <app-network-uptime [interval]="currentDateFilter"></app-network-uptime>
                </div>

                <div [hidden]="healthView === 'uptime'">
                    <!-- Alarms Feature -->
                    <app-alarms [endTime]="endTime" [networkId]="currentNetworkId" [startTime]="startTime">
                    </app-alarms>
                </div>
            </div>
        </div>
        <div>
            <div class="row" style="margin-top: 25px; background-color: var(--navigation); border-radius: 7px">
                <app-billing-summary
                    [cycleStart]="cycleStart"
                    [networkFilter]="currentNetwork"
                    [showDashLink]="true"
                ></app-billing-summary>
            </div>
            <div class="row">
                <div class="cardStack full" style="height: 370px">
                    <!-- Date Filter -->
                    <app-date-filters
                        (endChange)="setEndTime($event)"
                        (startChange)="setStartTime($event)"
                        [currentTimeFilter]="currentTimeFilter"
                    ></app-date-filters>
                    <div class="title center utilization">Network Utilization</div>
                    <!-- Graph -->
                    <app-utilization-timeline
                        [dateFilter]="currentDateFilter"
                        [endTime]="endTime"
                        [endpointNameFilter]="endpointNameFilter"
                        [endpointType]="endpointFilter"
                        [networkDataTypes]="directionFilter"
                        [networkId]="currentNetworkId"
                        [startTime]="startTime"
                    ></app-utilization-timeline>
                </div>
            </div>
            <div *ngIf="canListEndpoints" class="row">
                <div class="cardStack full" style="height: 370px">
                    <app-utilization-filters
                        (directionChange)="setDirectionFilter($event)"
                        (endpointFilterChange)="setEndpointFilter($event)"
                    ></app-utilization-filters>
                    <div class="title">Endpoint Usage - Top 5</div>
                    <!-- Top 5 Utilization -->
                    <app-utilization-pie
                        (endpointName)="setEndpointNameFilter($event)"
                        [endTime]="endTime"
                        [endpointNameFilter]="endpointNameFilter"
                        [endpointType]="endpointFilter"
                        [networkDataTypes]="directionFilter"
                        [networkId]="currentNetworkId"
                        [startTime]="startTime"
                    ></app-utilization-pie>
                </div>
            </div>
        </div>
        <div class="row" style="clear: both">&nbsp;</div>
    </div>
</div>
