import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TunnelerCardComponent } from './tunneler-card/tunneler-card.component';

@NgModule({
    imports: [CommonModule],
    declarations: [TunnelerCardComponent],
    exports: [TunnelerCardComponent],
})
export class SharedTunnelerCardModule {}
