import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isEmpty } from 'lodash';

@Component({
    selector: 'app-table-cell-managed',
    templateUrl: './table-cell-managed.component.html',
    styleUrls: ['./table-cell-managed.component.scss'],
})
export class TableCellManagedComponent implements ICellRendererAngularComp {
    params;
    item = {
        id: '',
        syncId: null,
        typeId: '',
        exclusiveType: '',
        exclusiveTo: '',
        exclusiveToId: '',
    };
    clientSyncManaged = false;
    routerManaged = false;
    serviceManaged = false;
    notManaged = false;

    agInit(params: ICellRendererParams): void {
        if (!params.node.data) {
            return;
        }
        this.item = params.node.data;
        this.returnManagedIcon();
    }

    refresh(params: ICellRendererParams): boolean {
        if (!params.data) {
            return;
        }
        this.item = params.data;
        this.returnManagedIcon();
        return true;
    }

    returnManagedIcon() {
        if (this.item.syncId) {
            this.clientSyncManaged = true;
        }
        if (this.item.typeId === 'Router') {
            this.routerManaged = true;
        } else if (this.item.exclusiveType === 'edge-router') {
            this.routerManaged = true;
        } else if (this.item.exclusiveType === 'service') {
            this.serviceManaged = true;
        } else if (!isEmpty(this.item.exclusiveTo) || !isEmpty(this.item.exclusiveToId)) {
            this.serviceManaged = true;
        }
        if (this.item.typeId !== 'Router') {
            this.notManaged = true;
        }
    }
}
