import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FeatureService } from '@netfoundry-ui/shared/services';
import { SampleServiceService } from '../sample-service/sample-service.service';

@Component({
    selector: 'app-enrollment-instructions',
    templateUrl: './enrollment-instructions.component.html',
    styleUrls: ['./enrollment-instructions.component.scss'],
})
export class EnrollmentInstructionsComponent implements OnInit {
    view = 'select';
    tunnelerItems = [];
    step = 1;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<EnrollmentInstructionsComponent>,
        public dialogForm: MatDialog,
        private router: Router,
        private sampleServiceService: SampleServiceService,
        private featureService: FeatureService
    ) {}

    ngOnInit(): void {
        this.tunnelerItems = this.sampleServiceService.getTunnelerItems();
    }

    hide() {
        this.dialogRef.close();
    }

    itemSelected(event) {
        console.log(event);
        this.view = event.os || 'select';
    }

    nextStep() {
        if (this.step >= 4 && this.view === 'windows') {
            this.step = 4;
            return;
        } else if (this.step >= 5 && this.view === 'mac') {
            this.step = 5;
            return;
        }
        this.step++;
    }

    prevStep() {
        if (this.step <= 1) {
            this.step = 1;
            this.view = 'select';
            return;
        }
        this.step--;
    }
}
