import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userStatus',
})
export class UserStatusPipe implements PipeTransform {
    transform(items: any[], status: string): any {
        return items.filter((it) => {
            if (status === 'Active') {
                return it.active && !it.id.toLowerCase().includes('invitation') ? it : null;
            } else if (status === 'Inactive') {
                return !it.active && !it.id.toLowerCase().includes('invitation') ? it : null;
            } else if (status === 'Pending') {
                return it.id.toLowerCase().includes('invitation') ? it : null;
            } else if (status === 'Expired') {
                return it.id.toLowerCase().includes('invitation') ? it : null;
            } else {
                return it;
            }
        });
    }
}
