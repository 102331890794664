import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import _ from 'lodash';

@Component({
    selector: 'app-table-cell-events',
    templateUrl: './table-cell-events.component.html',
    styleUrls: ['./table-cell-events.component.scss'],
})
export class TableCellEventsComponent implements ICellRendererAngularComp {
    params;
    item = {
        id: undefined,
        event_type: undefined,
    };
    event_type = '';

    // eslint-disable-next-line @typescript-eslint/no-empty-function

    agInit(params: ICellRendererParams): void {
        this.event_type = this.returnCorrectEventTitle(_.get(params, 'data.event_type'));
        this.item = params.node.data;
    }

    refresh(params: ICellRendererParams): boolean {
        this.item = params.data;
        this.event_type = this.returnCorrectEventTitle(_.get(params, 'data.event_type'));
        return true;
    }

    returnCorrectEventTitle(eventType) {
        if (eventType === 'created') {
            return 'Endpoint Online';
        }
        if (eventType === 'deleted') {
            return 'Endpoint Offline';
        }
        if (eventType === 'router-offline') {
            return 'Edge Router Offline';
        }
        if (eventType === 'router-online') {
            return 'Edge Router Online';
        }
    }
}
