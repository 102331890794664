import { HateoasResource } from '@lagoshny/ngx-hateoas-client';
import { SemanticTypes } from '../enums/semantics';
import { BaseServicePolicy } from './base-service-policy';

@HateoasResource('service-edge-router-policies')
export class ServiceEdgeRouterPolicy extends BaseServicePolicy {
    semantic: SemanticTypes;
    createServiceEdgeRouterPolicy: boolean;

    getSemanticTypes() {
        const semanticTypes = Object.keys(SemanticTypes).map(function (type) {
            return type;
        });

        return semanticTypes;
    }
}
