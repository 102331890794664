import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('configs')
export class Config extends Resource {
    id: string;
    networkId: string;
    name: string;
    configTypeId: string;
    zitiId: string;
    data: any;
    ownerIdentityId: string;
    createdAt: string;
    updatedAt: string;
    selected = false;
    exclusiveTo: string;
}
