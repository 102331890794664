<div class="start-menu-header-cards-container">
    <div
        (click)="selected(card)"
        *ngFor="let card of cards"
        [ngClass]="{ 'header-card-disabled': card.disabled }"
        [title]="card.disabledTooltip"
        class="header-card"
    >
        <div *ngIf="card.completed" class="check-complete"></div>
        <div [ngClass]="card.backgroundClass" class="header-card-background"></div>
        <div class="header-card-title-container">
            <div class="header-card-title-and-icon-container">
                <span class="header-card-title">{{ card.title }}</span>
                <img
                    *ngIf="card.titleImageSrc"
                    [src]="card.titleImageSrc"
                    class="header-card-image"
                    style="height: 50px; width: 50px"
                />
            </div>
            <span *ngIf="card.subtitle" class="header-card-subtitle">{{ card.subtitle }}</span>
        </div>
        <div *ngIf="card.contentType === 'image'" class="header-content-image">
            <img [src]="card.imageSrc" />
        </div>
        <div *ngIf="card.contentType === 'links'" class="header-content-links" id="HeaderContentLinks">
            <span
                (click)="openLink(link)"
                *ngFor="let link of card.links; let i = index"
                class="header-content-link-container"
            >
                {{ link.text + (i < card.links.length - 1 ? ', ' : '') }}
            </span>
        </div>
        <div class="header-card-link-container">
            <span *ngIf="card.showSpinner" class="spinner"></span>
            <span class="header-card-link">{{ card.linkText }}</span>
        </div>
    </div>
</div>
