import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Service } from '@netfoundry-ui/shared/model';
import { FeatureService, ZitiEnabledService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-servicesform',
    templateUrl: './servicesform.component.html',
    styleUrls: ['./servicesform.component.scss'],
})
export class ServicesformComponent {
    model: Service = new Service({
        bridgeStp: 'YES',
        collectionLocation: 'BOTH',
        cryptoLevel: 'STRONG',
        dnsOptions: 'NONE',
        icmpTunnel: 'NO',
        localNetworkGateway: 'YES',
        multicast: 'OFF',
        pbrType: 'WAN',
        permanentConnection: 'NO',
        rateSmoothing: 'NO',
        serviceInterceptType: 'IP',
    });

    isSelected = false;
    isAdding = false;
    isEditing = false;
    isComplete = false;
    allowBack = true;
    isInline = false;

    isHAService = false;

    processing = false;

    endpointId = null;
    gatewaySelfLink = null;

    endpoint = null;

    canEdit = true;

    hideHelp = false;

    constructor(
        public dialogRef: MatDialogRef<ServicesformComponent>,
        public featureService: FeatureService,
        public authorizationService: AuthorizationService,
        public zitiEnabledService: ZitiEnabledService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.model.endpointId = '';
        if (data.inline) {
            this.isInline = data.inline;
        }
        // if a gateway was provided
        if (data.gateway) {
            // determine if the gateway ID is for a cluster
            this.isHAService = data.isCluster;

            this.endpointId = data.gateway;
            this.gatewaySelfLink = data.gatewaySelfLink;
        }
        if (data.model !== undefined) {
            this.model = data.model;

            if (!this.authorizationService.canUpdateService(this.model.id)) {
                this.canEdit = false;
            }

            this.isAdding = false;
            this.isEditing = true;
            this.isSelected = true;
            this.allowBack = false;
        } else {
            this.model = new Service({});
            this.isAdding = true;
            this.isEditing = false;
            this.allowBack = true;
            this.model = new Service({
                bridgeStp: 'YES',
                collectionLocation: 'BOTH',
                cryptoLevel: 'STRONG',
                dnsOptions: 'NONE',
                icmpTunnel: 'NO',
                localNetworkGateway: 'YES',
                multicast: 'OFF',
                pbrType: 'WAN',
                permanentConnection: 'NO',
                rateSmoothing: 'NO',
                serviceInterceptType: 'IP',
                gatewayClusterId: null,
                interceptPorts: null,
            });
        }
    }

    setClass(type: string) {
        this.model.name = '';
        this.model.networkIp = '';
        this.model.networkFirstPort = '';
        this.model.networkLastPort = '';
        this.model.interceptFirstPort = '';
        this.model.interceptLastPort = '';
        this.model['gatewayCidrBlock'] = 0;
        this.model.interceptIp = '';
        this.model.serviceType = '';
        this.model.gatewayIp = '';

        this.model.serviceClass = type;
        this.isSelected = true;
    }

    back() {
        this.model.serviceClass = '';
        this.isSelected = false;
    }

    hide(response?: unknown) {
        this.dialogRef.close(response);
    }
}
