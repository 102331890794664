<div [hidden]="!isLoading" id="Loading">
    <div class="grid-row">
        <div class="col">
            <div class="loader">
                <div class="nfloader">
                    <ng-lottie [options]="lottieOptions"></ng-lottie>
                </div>
            </div>
        </div>
    </div>
</div>
