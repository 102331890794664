import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppWanBulkUploadFormComponent } from '@netfoundry-ui/feature/form/app-wan-bulk-upload-form';
import { AppWanFormComponent } from '@netfoundry-ui/feature/form/app-wan-form';
import { AppWanInterconnectWizardComponent } from '@netfoundry-ui/feature/form/app-wan-interconnect-wizard';
import { AppWanMigrationWizardComponent } from '@netfoundry-ui/feature/form/app-wan-migration-wizard';
import { AppWanS3WizardComponent } from '@netfoundry-ui/feature/form/app-wan-s3-wizard';
import { AppWanWizardComponent } from '@netfoundry-ui/feature/form/app-wan-wizard';
import { ClientCardsFormComponent } from '@netfoundry-ui/feature/form/client-cards-form';
import { GatewayformComponent } from '@netfoundry-ui/feature/form/gateway-form';
import { GroupsformComponent } from '@netfoundry-ui/feature/form/groups-form';
import { ServicesformComponent } from '@netfoundry-ui/feature/form/services-form';
import { V2NetworkCreationFormComponent } from '@netfoundry-ui/feature/form/v2-network-creation-form';
import { ZitiAppwanComponent } from '@netfoundry-ui/feature/form/ziti-appwan';
import { ZitiEdgeRouterComponent } from '@netfoundry-ui/feature/form/ziti-edge-router-form';
import { ZitiEdgeRouterPolicyComponent } from '@netfoundry-ui/feature/form/ziti-edge-router-policy';
import { ZitiEndpointFormComponent } from '@netfoundry-ui/feature/form/ziti-endpoint-form';
import { ServiceCardsComponent } from '@netfoundry-ui/feature/service-cards';
import { AppwanTileService } from '@netfoundry-ui/feature/shared-services';
import { TilepickerComponent } from '@netfoundry-ui/feature/tilepicker';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { ApiService, FeatureService, LoggerService, NetworkService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import _ from 'lodash';
import { EdgeRouterServiceV2, EndpointServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { Router } from '@angular/router';
import { PagedGetOption } from '@lagoshny/ngx-hateoas-client';

@Component({
    selector: 'app-add-modal',
    templateUrl: './add-modal.component.html',
    styleUrls: ['./add-modal.component.scss'],
})
export class AddModalComponent implements OnInit, OnDestroy {
    currentNetworkId = null;
    currentOrgId = '';
    canCreateNetworks = false;
    canCreateAppwans = false;
    canCreateServices = false;
    canCreateEndpoints = false;
    canCreateGroups = false;
    canCreateEdgeRouters = false;
    canCreateEdgeRouterPolicies = false;
    networkStatus;
    networks = [];
    warnDialogRef;
    formDialogRef;
    appwanDialogRef;
    currentNetwork;
    networkVersion;
    endpointTotalElements = 0;
    edgeRouterTotalElements = 0;
    componentCount = 0;
    warnSelfServ = false;
    isSelfServiceTeamsTier = false;
    componentCountLoaded = false;
    private subscription = new Subscription();
    private pickerData = {
        title: 'CREATE A NEW APPWAN',
        description: 'What would you like to do?',
        tiles: [],
    };

    constructor(
        private dialogRef: MatDialogRef<AddModalComponent>,
        public dialogForm: MatDialog,
        private apiService: ApiService,
        public authorizationService: AuthorizationService,
        private appwanTileService: AppwanTileService,
        private growlerService: GrowlerService,
        private networkService: NetworkService,
        private featureService: FeatureService,
        private endpointServiceV2: EndpointServiceV2,
        private edgeRouterService: EdgeRouterServiceV2,
        private logger: LoggerService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((network) => {
                this.currentNetwork = network;
                this.networkStatus = network.status;

                this.networkVersion = this.apiService.getNetworkVersion(this.currentNetwork);

                if (this.isSelfServiceTeamsTier && !this.componentCountLoaded) {
                    this.componentCountLoaded = true;
                    this.getComponentCount().catch((reason) => {
                        this.logger.error('unable to load component count');
                    });
                }
            })
        );
        this.subscription.add(
            this.apiService.currentSubscription.subscribe(async (subscription) => {
                this.isSelfServiceTeamsTier =
                    this.featureService.isSelfServiceTeamsTier(subscription) ||
                    this.featureService.isSelfServicePAYG(subscription);
                if (this.isSelfServiceTeamsTier && this.currentNetwork && !this.componentCountLoaded) {
                    this.componentCountLoaded = true;
                    this.getComponentCount().catch((reason) => {
                        this.logger.error('unable to load component count');
                    });
                }
            })
        );
        this.checkPermissions();
    }

    async checkPermissions() {
        this.canCreateNetworks = this.authorizationService.canCreateNetworks();
        this.canCreateAppwans = this.authorizationService.canCreateAppWans();
        this.canCreateServices = this.authorizationService.canCreateServices();
        this.canCreateEndpoints = this.authorizationService.canCreateEndpoints();
        this.canCreateGroups = this.authorizationService.canCreateEndpointGroups();

        this.canCreateEdgeRouters = this.authorizationService.canCreateEdgeRouters();
        this.canCreateEdgeRouterPolicies = this.authorizationService.canCreateEdgeRouterPolicies();

        this.pickerData.tiles = this.appwanTileService.getTiles();

        if (this.authorizationService.canListNetworks()) {
            await this.networkService
                .get()
                .pipe(take(1))
                .toPromise()
                .then((networks) => {
                    this.networks = networks;
                });
        }
    }

    open(type) {
        if (type === 'network') {
            this.addNetwork();
        } else {
            if (this.networkStatus === 300 || this.networkStatus === 'PROVISIONED') {
                switch (type) {
                    case 'appwan':
                        this.openAppwan();
                        break;

                    case 'gateway':
                        this.formDialogRef = this.dialogForm.open(GatewayformComponent, {
                            data: {},
                            minHeight: '100%',
                            minWidth: '100%',
                            height: '100%',
                            width: '100%',
                        });
                        break;

                    case 'client':
                        this.formDialogRef = this.dialogForm.open(ClientCardsFormComponent, {
                            data: {},
                            minHeight: '100%',
                            minWidth: '100%',
                            height: '100%',
                            width: '100%',
                        });
                        break;

                    case 'service':
                        if (this.networkVersion < 7) {
                            this.formDialogRef = this.dialogForm.open(ServicesformComponent, {
                                data: {},
                                minHeight: '100%',
                                minWidth: '100%',
                                height: '100%',
                                width: '100%',
                            });
                        } else {
                            this.formDialogRef = this.dialogForm.open(ServiceCardsComponent, {
                                data: {},
                                minHeight: '100%',
                                minWidth: '100%',
                                height: '100%',
                                width: '100%',
                            });
                        }

                        break;

                    case 'group':
                        this.formDialogRef = this.dialogForm.open(GroupsformComponent, {
                            data: {},
                            minHeight: '100%',
                            minWidth: '100%',
                            height: '100%',
                            width: '100%',
                        });
                        break;

                    case 'endpoint':
                        if (this.warnSelfServ) {
                            this.showSelfServWarning();
                            break;
                        }
                        this.formDialogRef = this.dialogForm.open(ZitiEndpointFormComponent, {
                            data: {},
                            minHeight: '100%',
                            minWidth: '100%',
                            height: '100%',
                            width: '100%',
                        });
                        break;

                    case 'edgeRouter':
                        if (this.warnSelfServ) {
                            this.showSelfServWarning();
                            break;
                        }
                        this.formDialogRef = this.dialogForm.open(ZitiEdgeRouterComponent, {
                            data: {},
                            minHeight: '100%',
                            minWidth: '100%',
                            height: '100%',
                            width: '100%',
                        });
                        break;

                    case 'edgeRouterPolicy':
                        this.formDialogRef = this.dialogForm.open(ZitiEdgeRouterPolicyComponent, {
                            data: {},
                            minHeight: '100%',
                            minWidth: '100%',
                            height: '100%',
                            width: '100%',
                        });
                }
            } else {
                this.growlerService.show(
                    new GrowlerData('error', 'Error', 'Network Not Ready', 'The Network must be ready in order to add.')
                );
            }
        }

        // close the form
        this.formDialogRef.beforeClosed().subscribe((result) => {
            this.close();
        });
    }

    showSelfServWarning() {
        const data = {
            title: 'Plan Limit Reached',
            appendId: 'MaxComponents',
            subtitle: `Only 10 components are allowed in a Trial account.`,
            icon: 'AddaNetwork',
            action: 'Yes',
            isWarning: false,
        };
        this.warnDialogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '340px',
            width: '600px',
            autoFocus: false,
        });
        this.warnDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.router.navigate(['/billing']);
                this.close();
                return;
            }
        });
        return;
    }

    async getComponentCount() {
        const componentProms = [];
        if (_.isEmpty(this.currentNetwork?.id)) {
            this.endpointTotalElements = 0;
            this.edgeRouterTotalElements = 0;
        } else {
            const erProm = this.getEdgeRoutersProm();
            const epProm = this.getEndpointsProm();
            componentProms.push(erProm);
            componentProms.push(epProm);
        }
        return Promise.all(componentProms).then(() => {
            this.componentCount = this.endpointTotalElements + this.edgeRouterTotalElements;
            this.warnSelfServ = this.componentCount === 10;
        });
    }

    async getEndpointsProm() {
        this.endpointTotalElements = 0;
        return this.endpointServiceV2.getEndpointPage(this.getOptions()).then((endpoints) => {
            endpoints.forEach((endpoint) => {
                if (endpoint.typeId !== 'Router') {
                    this.endpointTotalElements++;
                }
            });
        });
    }

    async getEdgeRoutersProm() {
        return this.edgeRouterService.getEdgeRouterPage(this.getOptions()).then((edgeRouters) => {
            this.edgeRouterTotalElements = this.edgeRouterService.lastTotalCount;
        });
    }

    getOptions() {
        const options: PagedGetOption = {
            params: {
                networkId: this.currentNetwork.id,
            },
            pageParams: {
                size: 20,
                page: 0,
            },
        };
        return options;
    }

    openAppwan() {
        if (this.networkVersion >= 7) {
            this.appwanDialogRef = this.dialogForm.open(ZitiAppwanComponent, {
                data: {},
                minHeight: '100%',
                minWidth: '100%',
                height: '100%',
                width: '100%',
            });

            // close the form
            this.appwanDialogRef.beforeClosed().subscribe((result) => {
                this.close();
            });
        } else if (this.pickerData.tiles.length === 1 && this.pickerData.tiles[0].type === 'StandardEditor') {
            this.appwanDialogRef = this.dialogForm.open(AppWanFormComponent, {
                minHeight: '100%',
                minWidth: '100%',
                height: '100%',
                width: '100%',
            });

            // close the form
            this.appwanDialogRef.beforeClosed().subscribe((result) => {
                this.close();
            });
        } else {
            // Type picker
            this.formDialogRef = this.dialogForm.open(TilepickerComponent, {
                data: this.pickerData,
                minHeight: '100%',
                minWidth: '100%',
                height: '100%',
                width: '100%',
            });

            // Open the appwan form based on the tile selection
            const sub = this.formDialogRef.componentInstance.typeChange.subscribe((type) => {
                // Open the appropriate form based on the selection
                if (type === this.appwanTileService.cloudEditorTileString) {
                    this.appwanDialogRef = this.dialogForm.open(AppWanWizardComponent, {
                        data: { isDevelopment: false },
                        minHeight: '100%',
                        minWidth: '100%',
                        height: '100%',
                        width: '100%',
                    });
                } else if (type === this.appwanTileService.developmentEditorTileString) {
                    this.appwanDialogRef = this.dialogForm.open(AppWanWizardComponent, {
                        data: { isDevelopment: true },
                        minHeight: '100%',
                        minWidth: '100%',
                        height: '100%',
                        width: '100%',
                    });
                } else if (type === this.appwanTileService.migrationTileString) {
                    this.appwanDialogRef = this.dialogForm.open(AppWanMigrationWizardComponent, {
                        minHeight: '100%',
                        minWidth: '100%',
                        height: '100%',
                        width: '100%',
                    });
                } else if (type === this.appwanTileService.interconnectTileString) {
                    this.appwanDialogRef = this.dialogForm.open(AppWanInterconnectWizardComponent, {
                        minHeight: '100%',
                        minWidth: '100%',
                        height: '100%',
                        width: '100%',
                    });
                } else if (type === this.appwanTileService.bulkUploadTileString) {
                    this.appwanDialogRef = this.dialogForm.open(AppWanBulkUploadFormComponent, {
                        data: {},
                        minHeight: '100%',
                        minWidth: '100%',
                        height: '100%',
                        width: '100%',
                    });
                } else if (type === this.appwanTileService.s3TileString) {
                    this.appwanDialogRef = this.dialogForm.open(AppWanS3WizardComponent, {
                        minHeight: '100%',
                        minWidth: '100%',
                        height: '100%',
                        width: '100%',
                    });
                } else {
                    this.appwanDialogRef = this.dialogForm.open(AppWanFormComponent, {
                        minHeight: '100%',
                        minWidth: '100%',
                        height: '100%',
                        width: '100%',
                    });
                }

                // close the form
                this.appwanDialogRef.beforeClosed().subscribe((result) => {
                    this.close();
                });
            });
        }
    }

    close() {
        this.apiService.triggerAddedFromAnywhere();
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addNetwork() {
        if (this.authorizationService.canCreateNetworks()) {
            this.formDialogRef = this.dialogForm.open(V2NetworkCreationFormComponent, {
                minHeight: '100%',
                minWidth: '100%',
                height: '100%',
                width: '100%',
            });
        }
    }
}
