import { Endpoint } from './endpoint';

export class ShareData {
    public type: string;
    public endpoint: Endpoint;

    constructor(type = '', endpoint: Endpoint = null) {
        this.type = type;
        this.endpoint = endpoint;
    }
}
