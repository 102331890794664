<div class="modal solid open" id="TicketModal">
    <app-loader [isLoading]="processing"></app-loader>
    <div [hidden]="processing">
        <div (click)="cancel()" class="miniClose cancel icon-HamburgerAppClose" id="CloseTicketModal"></div>
        <div class="miniTitle" style="background-image: url(/assets/svgs/SupportHub.svg)">SUBMIT A TICKET</div>
        <div *ngIf="!isComplete">
            <div class="row center">
                <div class="title">
                    Hello <span class="highlight">{{ name }}</span
                    >, How can we help you?
                </div>
                <div class="contents">
                    Submit a ticket below to enter a bug, suggest a feature, highlight a usability issue, or ask for
                    billing or technical support.
                </div>
                <div class="attachmentsnote">
                    <div class="attachmentsnoteicon"></div>
                    This form supports text only at this time. If you would like to provide attachments and / or screen
                    shots to better demonstrate the issue you're experiencing, please reply to the automated e-mail,
                    which you will receive after submitting this form.
                </div>
            </div>
            <div class="grid split">
                <div class="label">User Submission Email</div>
                <div class="label">Regarding<span class="note"></span></div>
                <input [(ngModel)]="email" disabled id="SupportEMail" readonly type="text" />
                <select [(ngModel)]="form.type" [ngClass]="{ error: errors['type'] }" id="RegardingList">
                    <option *ngFor="let type of typeList" [value]="type.value">
                        {{ type.name }}
                    </option>
                </select>
                <div class="label">
                    Subject<span [ngClass]="{ error: errors['subject'] }" class="note">Required</span>
                </div>
                <div class="label">
                    Severity
                    <a class="info" href="http://support.netfoundry.io" target="_blank"></a
                    ><!--Shawn's Support Hub Article to go here--><span class="note"></span>
                </div>
                <input
                    [(ngModel)]="form.subject"
                    [ngClass]="{ error: errors['subject'] }"
                    id="SupportSubject"
                    placeholder="Enter Your Subject Here"
                    type="text"
                />
                <select [(ngModel)]="form.priority" [ngClass]="{ error: errors['severity'] }" id="TicketSeverityList">
                    <option value="low">Low</option>
                    <option selected value="normal">Normal</option>
                    <option value="high">High</option>
                    <option value="urgent">Urgent</option>
                </select>
            </div>
            <div class="grid">
                <div class="label">
                    Description<span [ngClass]="{ error: errors['comment'] }" class="note">Required</span>
                </div>
                <div class="input">
                    <textarea
                        [(ngModel)]="form.comment"
                        [ngClass]="{ error: errors['comment'] }"
                        id="TicketDescription"
                    ></textarea>
                    <div (click)="clear()" class="clearButton" id="ClearSupportButton">Clear</div>
                </div>
            </div>
            <div class="buttons">
                <div (click)="cancel()" class="linkButton cancel">No Thanks, get me out of here</div>
                <button (click)="send()" *ngIf="!processing" class="save" id="OpenTicketButton">Submit</button>
                <button *ngIf="processing" class="button confirm" disabled>
                    Sending <span class="spinner"></span>
                </button>
            </div>
        </div>
        <div *ngIf="isComplete">
            <div class="row icon ticketSuccessIcon"></div>
            <div class="row center large ticketSuccessString" id="TicketSubmitSuccess">
                Thank you for your ticket submission.<br />
                To view your ticket status at any time log into<br />
                <a href="http://support.netfoundry.io" target="_blank">support.netfoundry.io</a>
            </div>
            <div class="buttons center">
                <button (click)="open()" class="save" id="OpenSupportHub">Visit the Support Hub</button>
            </div>
        </div>
    </div>
</div>
