import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageTourComponent } from './page-tour.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [PageTourComponent],
    exports: [PageTourComponent],
})
export class FeaturePageTourModule {}
