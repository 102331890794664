import { InjectionToken } from '@angular/core';

export const EVENT_FIELDS = new InjectionToken('EVENT_FIELDS');

export class EventFields {

  readonly identityName: string;
  readonly identityId: string;
  readonly edgeRouterName: string;
  readonly edgeRouterId: string;
  readonly serviceName: string;
  readonly serviceId: string;
  readonly hostName: string;
}
