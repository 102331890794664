<div class="label row">
    {{ label }}
    <span class="beforeCounterExt" #beforecounterext></span>
    <div class="counter">{{ allNames.length }}</div>
</div>
<div class="listBox row">
    <input (keyup)="onKeydownEvent()" [(ngModel)]="filterFor" placeholder="Type to Filter" />
    <span *ngIf="isLoading" class="spinner"></span>

    <div class="text row">
        <div
            #nameList
            (click)="selected(name)"
            *ngFor="let name of names"
            [ngClass]="{ clickable: clickable }"
            class="listText"
        >
            {{ name }}
        </div>
    </div>
</div>
