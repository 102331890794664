<div class="label row">
    {{ label }}
    <!--<span *ngFor="let error of errors" class="error">{{ error.message }}</span>-->
    <span class="note">{{ required ? 'REQUIRED' : 'OPTIONAL' }}</span>
</div>
<div [matTooltip]="errors[0] && errors[0].message ? errors[0].message : ''" class="row">
    <input
        (blur)="onFocusOut()"
        (keyup)="onKeyUp($event)"
        [(ngModel)]="model[propertyName]"
        [disabled]="disabled"
        [maxlength]="maxLength"
        [minlength]="minLength"
        [ngClass]="{ error: errors.length > 0 }"
        [placeholder]="placeholder"
        id="{{ propertyName }}_PropertyEditor"
        type="text"
    />
</div>
