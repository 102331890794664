import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-utilization-filters',
    templateUrl: './utilization-filters.component.html',
    styleUrls: ['./utilization-filters.component.scss'],
})
export class UtilizationFiltersComponent {
    // Wire up emitters for parent Feature

    @Output()
    directionChange: EventEmitter<string> = new EventEmitter();
    directionFilter = 'tx';

    @Output()
    endpointFilterChange: EventEmitter<string> = new EventEmitter();
    endpointType = 'All';

    @Input() showUsageEndpoint = true;

    setDirection(value) {
        this.directionChange.emit(value);
        this.directionFilter = value;
    }

    setEndpointType(value) {
        this.endpointType = value;
        this.endpointFilterChange.emit(value);
    }
}
