import { Injectable } from '@angular/core';
import { Group, Network } from '@netfoundry-ui/shared/model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class GroupService extends MopResourceService {
    page = null;
    links = null;
    private endpointGroupsSource = new Subject<Group[]>();
    endpointGroups = this.endpointGroupsSource.asObservable();
    private apiSub = new Subscription();
    private mapSub = new Subscription();

    constructor(protected logger: LoggerService, private apiservice: ApiService) {
        super(logger, apiservice);
    }

    /**
     * Gets all Clients for org
     */
    get(pageNumber?: number, sort?: string, filter?: string, pageSize?: number): Observable<any> {
        if (this.apiSub) {
            this.apiSub.unsubscribe();
        }

        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }

        const params = {};

        if (pageNumber != null) {
            params['page'] = pageNumber;
        }

        if (sort != null) {
            params['sort'] = sort;
        }

        if (filter != null) {
            // TODO filter
        }

        if (pageSize != null) {
            params['size'] = pageSize;
        }

        // watch for when the network updates, when it does, update the endpoints observable
        this.apiSub = this.apiService.currentNetwork.subscribe((network) => {
            this.mapSub = this.apiService
                .getLinkedResource(new Network(network), 'endpointGroups', params)
                .pipe(map(this._extractEmbedded))
                .subscribe((data) => {
                    this.page = data['page'];
                    this.links = data['links'];
                    this.endpointGroupsSource.next(data['endpointGroups']);
                });
        });

        // this is an observable that watches for network changes
        return this.endpointGroups;
    }

    addEndpoints(model, ids) {
        return this.addRelationships('endpoints', model, ids);
    }

    removeEndpoints(model, ids) {
        return this.removeRelationships('endpoints', model, ids);
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const endpointGroups: Group[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['endpointGroups'] !== undefined) {
            for (let i = 0; i < res['_embedded']['endpointGroups'].length; i++) {
                const group = res['_embedded']['endpointGroups'][i];
                endpointGroups.push(new Group(group));
            }
        }

        // object containing information on pagination such as page number, size, total number of items,  and total number of pages
        let page = null;
        if (res['page'] !== undefined) {
            page = res['page'];
        }

        // list of links for jumping to and from pages
        let links = null;
        if (res['_links'] !== undefined) {
            links = res['_links'];
        }

        return { endpointGroups: endpointGroups, page: page, links: links };
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return this.getLinkedResourceUrl(this.currentNetworkModel, 'endpointGroups');
    }
}
