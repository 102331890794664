import { PercentPipe } from '@angular/common';
import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import {
  ApiService,
  ErrorHistoryService,
  LoggerService,
} from '@netfoundry-ui/shared/services';
import { TobytesPipe } from '@netfoundry-ui/ui/pipes';
import { Chart } from 'angular-highcharts';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MetricsModalComponent } from '@netfoundry-ui/feature/metrics-modal';
import { PlatformServiceService } from '@netfoundry-ui/shared/apiv2';
import { PlatformService } from '@netfoundry-ui/shared/model';
import { ServiceEventTemplateQueryParameter } from '@netfoundry-ui/shared/model';
import { TEMPLATE_SEARCH_SERVICE, TemplateSearchService } from "@netfoundry-ui/shared/apiv2";
import { isEmpty, isNil } from 'lodash';

@Component({
    selector: 'app-service-health-table',
    templateUrl: './service-health-table.component.html',
    styleUrls: ['./service-health-table.component.scss'],
    providers: [TobytesPipe, ElasticsearchService, PercentPipe],
})
export class ServiceHealthTableComponent implements OnInit, OnChanges, OnDestroy {
    model: PlatformService;
    @Input() networkId: any = null;
    @Input() endTime = Date.now();
    @Input() startTime = this.endTime - 24 * 60 * 60 * 1000;
    @Input() dateFilter: any = '24h';
    @Input() organizationId: any = null;
    currentOrgId;
    networkGroupId;
    initialized = false;
    noData = false;
    canReadElasticSearch = false;
    networkLoading = true;
    chart: Chart;
    items = [];
    isLoading = false;
    @Output() dataReceived = new EventEmitter<any>();
    dialogRef;
    name;
    item;
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private authorizationService: AuthorizationService,
        private elasticsearch: ElasticsearchService,
        private toBytes: TobytesPipe,
        private apiService: ApiService,
        private errorHistoryService: ErrorHistoryService,
        public dialogForm: MatDialog,
        private platformService: PlatformServiceService,
        @Inject(TEMPLATE_SEARCH_SERVICE) private templateService: TemplateSearchService
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentOrg.pipe(debounceTime(1000)).subscribe((org) => {
                this.currentOrgId = org.getId();

                this.initialized = true;
                this.logger.info('Service Health Table initialized');
                this.ngOnChanges();
            })
        );
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((currentNetwork) => {
                this.networkGroupId = currentNetwork.networkGroupId;
            })
        );
    }

    ngOnDestroy() {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }
    }

    ngOnChanges() {
        // make sure we have enough data to auth check before we do anything
        if (!this.initialized || this.networkId === null || this.currentOrgId === null) {
            return;
        }

        this.canReadElasticSearch = this.authorizationService.canReadElasticSearch(this.currentOrgId, this.networkId);

        if (this.canReadElasticSearch) {
            // data depends on the current network being set
            if (this.networkId != null) {
                this.networkLoading = true;
                this.noData = false;
                this.getData();
            } else {
                this.networkLoading = false;
                this.noData = true;
            }
        } else {
            this.logger.info('no perms for elasticsearch');
            this.networkLoading = false;
        }
        this.generateData();
    }

    getData() {
        const index = 'ncserviceevents';
        const items = [];

        this.noData = true;
        this.isLoading = true;

        const serviceEventTemplateParams: ServiceEventTemplateQueryParameter = this.templateService.getServiceHealthTemplateQuery(this.dateFilter, this.networkId, this.organizationId, index);
        this.subscription.add(
            //this.elasticsearch.search(this.organizationId, index, this.getQuery()).subscribe((data) => {
            this.elasticsearch
                .apiTemplateSearch(this.templateService.SERVICE_HEALTH_TEMPLATE, serviceEventTemplateParams)
                .subscribe((data) => {
                    this.items = this.templateService.getServiceHealthData(data);
                    this.dataReceived.emit(this.items);

                    if (!isEmpty(this.items) && !isNil(this.items)) {
                        this.noData = false;
                    }
                    this.isLoading = false;
                })
        );
    }

    public getQuery() {
        const model: any = {
            query: {
                bool: {
                    must: [
                        {
                            range: {
                                timestamp: {
                                    gte: 'now-' + this.dateFilter,
                                    lte: 'now',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                namespace: {
                                    query: 'service.events',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                'network_id.keyword': {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            wildcard: {
                                event_type: 'service.dial.*',
                            },
                        },
                    ],
                },
            },
            size: 0,
            aggs: {
                group_by_service_name: {
                    terms: {
                        field: 'nf_service_name.keyword',
                        size: 10,
                    },
                    aggs: {
                        eventSuccess: {
                            filter: {
                                term: {
                                    'event_type.keyword': 'service.dial.success',
                                },
                            },
                            aggs: {
                                sumSuccess: {
                                    sum: {
                                        field: 'count',
                                    },
                                },
                            },
                        },
                        eventFailure: {
                            filter: {
                                bool: {
                                    must_not: {
                                        term: {
                                            'event_type.keyword': 'service.dial.success',
                                        },
                                    },
                                },
                            },
                            aggs: {
                                sumFailure: {
                                    sum: {
                                        field: 'count',
                                    },
                                },
                            },
                        },
                        dial_failure_rate: {
                            bucket_script: {
                                buckets_path: {
                                    successCount: 'eventSuccess>sumSuccess',
                                    failureCount: 'eventFailure>sumFailure',
                                },
                                script: '1 - (params.failureCount / (params.successCount + params.failureCount))',
                            },
                        },
                        sorted_dial_failure_rate: {
                            bucket_sort: {
                                sort: [
                                    {
                                        dial_failure_rate: {
                                            order: 'asc',
                                        },
                                    },
                                ],
                            },
                        },
                    },
                },
            },
        };
        return model;
    }

    openMetrics(item: PlatformService) {
        const name = item.name;
        this.platformService.findByName(name, this.networkId).subscribe((result) => {
            this.item = result[0];
            this.dialogRef = this.dialogForm.open(MetricsModalComponent, {
                data: {
                    model: this.item,
                    resourceType: 'service',
                    networkId: this.networkId,
                    networkGroupId: this.networkGroupId,
                },
                height: '770px',
                width: '95vw',
            });
        });
    }

    private generateData() {
        if (this.initialized && this.networkId !== null && this.networkId && this.organizationId !== null) {
            this.getData();
        }
    }
}
