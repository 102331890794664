export class BillableRegion {
    public name: string;
    public selected: boolean;
    public cost: string;
    public disabled: boolean;

    constructor(name = '', selected = false, cost = '', disabled = false) {
        this.name = name;
        this.selected = selected;
        this.cost = cost;
        this.disabled = disabled;
    }
}
