<div class="cardStack full" id="{{ item.name }}QuickAdd" style="height: 370px">
    <div [hidden]="isEditing" class="title cEdit" id="{{ item.name }}ListOpen_{{ item.id }}">
        {{ item.name }}<span class="counter" id="{{ item.name }}QuickAddCounter">{{ totalAssociated }}</span>
    </div>
    <div [hidden]="!isEditing" class="headerSearch">
        <input
            [(ngModel)]="filterString"
            id="{{ item.name }}QuickAddSearch"
            placeholder="Search by {{ item.name }} name"
            type="text"
        />
    </div>
    <div
        *ngIf="hasToggle"
        [hidden]="!hasToggle || (state === 'add' && hideAddButton)"
        class="action"
        id="{{ item.name }}SubAction"
    >
        <div (click)="doAction()" [ngClass]="state" class="cSelect action" id="AddRemoveButton"></div>
    </div>
    <div *ngIf="hasAdd" [hidden]="hideAddButton" class="action" id="{{ item.name }}AddSubAction">
        <div (click)="doAdd()" [ngClass]="state" class="cSelect action" id="AddItemButton"></div>
    </div>
    <div [hidden]="isEditing" class="area" id="{{ item.name }}ListArea">
        <div [hidden]="totalAssociated > 0" class="noData">No Data</div>
        <span>
            <div
                *ngFor="let data of item.items"
                [ngClass]="{ itemToggle: hasToggle }"
                class="item"
                id="{{ item.name }}ListItem"
            >
                <div
                    (click)="toggle(data)"
                    [hidden]="!hasToggle || (checkAuth && !data.model['canUpdate'])"
                    [ngClass]="{ selected: data.selected }"
                    class="cSelect"
                    id="SubSelect_{{ data.id }}"
                ></div>
                <span (click)="goto(data)" class="clickable">{{ data.label }}</span>
            </div>
        </span>
    </div>
    <div *ngIf="hasToggle" [hidden]="!isEditing" class="area" id="{{ item.name }}ListAddArea">
        <span>
            <div
                *ngFor="let data of allItems | sortby: 'name' | textSearch: 'name':filterString"
                [hidden]="isAssociated(data.id) || (checkAuth && !data['canUpdate'])"
                [ngClass]="{ itemToggle: hasToggle }"
                class="item"
                id="{{ item.name }}ListAddItem"
            >
                <div
                    (click)="toggleAdd(data)"
                    [hidden]="!hasToggle"
                    [ngClass]="{ selected: data.selected }"
                    class="cSelect"
                    id="SubAddSelect_{{ data.id }}"
                ></div>
                {{ data.name }}
            </div>
        </span>
    </div>
</div>
