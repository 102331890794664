<div class="modal solid" id="WhatsNewModal">
    <div (click)="confirmWhatsNew($event)" class="closeButton icon-HamburgerAppClose" id="CloseWhatsNewButton"></div>
    <div class="whats-new-container">
        <div class="whats-new-left-side-text">
            <div class="title">{{ data.title }}</div>
            <div class="whats-new-text-content" [innerHTML]="data.textContent"></div>
            <div class="button-row">
                <button (click)="confirmWhatsNew($event)" class="save" id="ConfirmWhatsNew">Try Now</button>
            </div>
        </div>
        <div class="whats-new-right-side-preview">
            <img [src]="data.previewImageSrc" />
        </div>
    </div>
</div>
