import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShareData } from '@netfoundry-ui/shared/model';
import { Subject } from 'rxjs';
import { ShareComponent } from './share.component';

@Injectable({ providedIn: 'root' })
export class ShareService {
    dialogRef;
    private data = new Subject<ShareData>();

    constructor(public dialogForm: MatDialog) {}

    public show(data: ShareData) {
        this.dialogRef = this.dialogForm.open(ShareComponent, {
            data: data,
            height: '350px',
            width: '640px',
            autoFocus: false,
        });
    }
}
