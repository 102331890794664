import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { IamService, LOGIN_SERVICE, LoginService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { LoggerService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

import { defer, isEmpty } from 'lodash';

const awsProvider = 'AWS';

@Component({
    selector: 'app-auth',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    environmentLogoClass = this.environment['logoClass'] != null ? this.environment['logoClass'] : '';

    loggedIn = false;
    showVanityLogout = false;
    screen = 'login';
    invitation = {
        fromIdentityName: '',
        toTenantName: '',
        expiration: {
            nano: 0,
            epochSecond: 0,
        },
    };
    expires = '';
    key = '';
    domain = this.environment.originDomain;
    isProd = this.environment.production && !this.environment['showLoginNetworkWarning'];
    dialogRef;
    loginInit = false;
    private subscription = new Subscription();

    constructor(
        protected logger: LoggerService,
        private growlService: GrowlerService,
        @Inject(LOGIN_SERVICE) public loginSvc: LoginService,
        public iamService: IamService,
        private matDialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    ngOnInit() {
        const isNewAuthReturn = !isEmpty(this.activatedRoute?.snapshot?.queryParamMap?.get('authToken'));
        this.showVanityLogout = !isEmpty(this.activatedRoute?.snapshot?.queryParamMap?.get('vanityLogout'));
        if (this.loginSvc.isLoggedIn && !isNewAuthReturn) {
            window.location.href = `${window.location.protocol}//${window.location.host}/`;
            return;
        }
        if (!this.showVanityLogout) {
            this.loginSvc.logout(false, true, {}, true);
        }

        if (window.location.hostname !== this.environment.originDomain) {
            this.loginSvc.isRootDomain = false;
        }

        let normalLogin = true;
        this.subscription.add(
            this.activatedRoute.queryParams.subscribe((params: Params) => {
                if (params['provider'] && params['provider'].toLowerCase() === awsProvider.toLowerCase()) {
                    this.loginSvc.isAwsLogin = true;
                }

                // handle incoming vanity URL redirect
                // Vanity URL Login Step #3
                if (params['loginSiteName']) {
                    this.loginSvc._catchVanityUrlAuth(params['loginSiteName']);
                    normalLogin = false;
                }

                // Handle invitation sequence
                // This will occur if an invite has been accepted and auth0 bounces back to us
                const pathTenantLabel = params['site'];
                if (pathTenantLabel) {
                    this.key = params['invitationKey'];
                    this.loginSvc.tenantLabel = pathTenantLabel;
                    this.loginSvc.getIdentityProviders();
                    normalLogin = false;
                }

                // Handle login sequence
                // This will occur after the auth Lock has been popped and auth0 bounces back to us
                if (params['authToken'] !== undefined && params['state'] !== undefined) {
                    this.loginSvc.resumeLogin(params['authToken'], params['state']);
                    normalLogin = false;
                }

                const showVanityLogout = params['vanityLogout'] !== undefined;
                // if none of the weird scenarios were hit, show the login page
                if (normalLogin && (this.loginSvc.isVanityUrl || showVanityLogout)) {
                    this.showVanityLogout = this.showVanityLogout || showVanityLogout;
                    this.loginSvc.isResuming = false;
                } else if (normalLogin) {
                    this.loginSvc.isResuming = false;
                    this.showVanityLogout = false;
                } else {
                    this.showVanityLogout = false;
                }
                defer(() => {
                    this.loginInit = true;
                });
            })
        );
    }

    continueLogin() {
        if (isEmpty(this.loginSvc.tenantLabel)) {
            this.growlService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Invalid Organization Name',
                    'No organization name provided. Please enter a valid organization name to continue.'
                )
            );
            return;
        }
        this.loginSvc.getIdentityProviders();
    }

    public logout(navigateToNotFound, skipTokenRevoke?: boolean): void {
        this.loginSvc.logout(skipTokenRevoke, navigateToNotFound);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.loginSvc.refreshSub.unsubscribe();
    }

    toggleForgotSiteName() {
        this.loginSvc.emailAddress = '';
        this.loginSvc.tenantLabel = '';
        this.loginSvc.forgotSiteName = !this.loginSvc.forgotSiteName;
    }
}
