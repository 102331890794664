<app-action-button
    (addEvent)="create($event)"
    (deleteEvent)="openConfirm($event)"
    [hidden]="
        (!isDeleteAction && !authorizationService.canCreateNetworks()) ||
        (isDeleteAction && selectedNetworkIds.length > 1)
    "
    [isDeleteAction]="isDeleteAction"
>
</app-action-button>

<app-loader [isLoading]="isLoading" id="networksPage"></app-loader>

<div class="area" id="ItemsArea">
    <div (click)="create(true)" [hidden]="showList || isLoading" class="noarea" id="NoArea">
        <div class="icon" style="background-image: url(/assets/svgs/No_Networks.svg)"></div>
        <div class="title">No v6 networks currently exist</div>
    </div>
    <div [hidden]="!showList || isLoading" id="ItemTable">
        <div
            [ngClass]="{ showNetworkGroupName: canListNetworkGroups, powerUser: featureService.powerUserEnabled }"
            class="tableGrid tHeadRow"
        >
            <div class="tHead">
                <div
                    (click)="toggleAll()"
                    *ngIf="hasSomeDeletePermission"
                    [ngClass]="{ selected: allToggled }"
                    class="tSelect all"
                    id="ToggleAll"
                ></div>
            </div>

            <div (click)="sort('name')" [ngClass]="getSortClass('name')" class="tHead sort">Name</div>

            <div *ngIf="canListNetworkGroups" [ngClass]="getSortClass('networkGroupName')" class="tHead sort">
                <span (click)="sort('networkGroupName')">{{ selectedNetworkGroupName }}</span>
                <div
                    (click)="filtering('networkGroupName')"
                    (clickOutside)="filteringBy === 'networkGroupName' && closeFilters()"
                    class="filterButton"
                    id="networkGroupFilter"
                >
                    <div [ngClass]="{ open: filteringBy === 'networkGroupName' }" class="options">
                        <div (click)="setNetworkGroupNameFilter('')" class="option">All Network Groups</div>
                        <div
                            (click)="setNetworkGroupNameFilter(networkGroup.id)"
                            *ngFor="let networkGroup of networkGroups"
                            class="option"
                        >
                            {{ networkGroup.name }}
                        </div>
                    </div>
                </div>
            </div>

            <div (click)="sort('statusLabel')" [ngClass]="getSortClass('statusLabel')" class="tHead sort">Status</div>
            <div (click)="sort('regionLabel')" [ngClass]="getSortClass('statusLabel')" class="tHead sort">Region</div>
            <div (click)="sort('sizeLabel')" [ngClass]="getSortClass('statusLabel')" class="tHead sort">Size</div>
            <div (click)="sort('version')" [ngClass]="getSortClass('version')" class="tHead sort">Version</div>
            <div
                (click)="sort('provisionedAt')"
                *ngIf="provisionedAt"
                [ngClass]="getSortClass('provisionedAt')"
                class="tHead sort"
            >
                Provisioned Date
            </div>
            <div
                (click)="sort('createdAt')"
                *ngIf="!provisionedAt"
                [ngClass]="getSortClass('createdAt')"
                class="tHead sort"
            >
                Provisioned Date
            </div>
            <div *ngIf="featureService.powerUserEnabled" class="tHead sort">ID</div>

            <div class="thead tRight">
                <div (click)="toggleMenu($event, -2)" *ngIf="anyToggled()" class="dots" id="Dots_Bulk">
                    ...
                    <div (clickOutside)="closeActionMenu()" [ngClass]="{ open: -2 === openIndex }" class="tMenu one">
                        <div (click)="downloadCsv($event)" class="tActionRow" id="DownloadCSV_Bulk">Download CSV</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end table headers -->

        <!-- begin table body -->
        <div id="ItemsList">
            <div
                *ngFor="
                    let item of displayedItems
                        | sortby: sorting:ordering
                        | paginate
                            : {
                                  itemsPerPage: pageSize,
                                  currentPage: page,
                                  totalItems: filterService.getTotalElements()
                              };
                    let index = index;
                    trackBy: trackById
                "
                [attr.data-index]="index"
                [ngClass]="{ showNetworkGroupName: canListNetworkGroups, powerUser: featureService.powerUserEnabled }"
                class="tableGrid tRow t{{ item.status }} "
                id="Item_{{ item.id }}"
            >
                <div class="tCol">
                    <div
                        (click)="toggle(item)"
                        *ngIf="item.actionList.includes('delete')"
                        [ngClass]="{ selected: item.selected }"
                        class="tSelect"
                        id="RowSelect0_{{ item.id }}"
                    ></div>
                </div>

                <div (click)="goToDashboard(item)" class="tCol clickCol padLabel" id="RowSelect1_{{ item.id }}">
                    <div
                        class="status s{{ item.status }}"
                        data-id="{{ item.id }}"
                        id="n{{ item.id }}"
                        title="{{ item.statusLabel }}"
                    >
                        <div class="anim5"></div>
                    </div>
                    {{ item.name }}
                </div>

                <div
                    (click)="goToDashboard(item)"
                    *ngIf="canListNetworkGroups"
                    class="tCol clickCol"
                    id="RowSelect2_{{ item.id }}"
                >
                    {{ item.networkGroupName }}
                </div>

                <div (click)="goToDashboard(item)" class="tCol clickCol" id="RowSelect3_{{ item.id }}">
                    {{ item.statusLabel }}
                </div>
                <div (click)="goToDashboard(item)" class="tCol clickCol" id="RowSelect3_{{ item.id }}">
                    {{ item.region }}
                </div>
                <div (click)="goToDashboard(item)" class="tCol clickCol" id="RowSelect3_{{ item.id }}">
                    {{ item.size }}
                </div>

                <div class="tCol clickCol" id="RowSelect4_{{ item.id }}">
                    <span (click)="goToDashboard(item)">{{ item.version }}</span>
                    <button
                        (click)="upgrade(item)"
                        *ngIf="item.upgradeableVersions?.length > 0"
                        class="save alert confirm upgradebadge"
                    >
                        Upgrade
                    </button>
                </div>

                <div
                    (click)="goToDashboard(item)"
                    *ngIf="item.provisionedAt"
                    class="tCol clickCol"
                    id="RowSelect5_{{ item.id }}"
                >
                    {{ formatTime(item['provisionedAt']) }}
                </div>
                <div
                    (click)="goToDashboard(item)"
                    *ngIf="!item.provisionedAt"
                    class="tCol clickCol"
                    id="RowSelect5_{{ item.id }}"
                >
                    {{ formatTime(item['createdAt']) }}
                </div>
                <div
                    (click)="copy(item)"
                    *ngIf="featureService.powerUserEnabled"
                    class="tCol clickCol"
                    id="RowSelect5_{{ item.id }}"
                >
                    {{ item.id }}
                </div>

                <div class="tCol tRight">
                    <div
                        (click)="toggleMenu($event, index)"
                        *ngIf="item.actionList.length > 0"
                        class="dots"
                        id="RowDots_{{ index }}"
                    >
                        ...
                        <div (clickOutside)="closeActionMenu()" [ngClass]="{ open: index === openIndex }" class="tMenu">
                            <div
                                (click)="upgrade(item)"
                                *ngIf="item.upgradeableVersions?.length > 0"
                                class="tActionRow"
                                id="RowEdit_{{ index }}"
                            >
                                Upgrade Network
                            </div>

                            <div
                                (click)="openRename(item)"
                                *ngIf="item.actionList.includes('update')"
                                class="tActionRow"
                                id="RowEdit_{{ index }}"
                            >
                                Rename
                            </div>
                            <div
                                (click)="openJson(item)"
                                *ngIf="item.actionList.includes('json')"
                                class="tActionRow"
                                id="RowEdit_{{ index }}"
                            >
                                JSON
                            </div>
                            <div
                                (click)="o365Optimize(item)"
                                *ngIf="item.actionList.includes('o365')"
                                class="tActionRow"
                                id="RowOptimize_{{ index }}"
                            >
                                O365 Optimize
                            </div>
                            <div
                                (click)="open(item)"
                                *ngIf="item.actionList.includes('get')"
                                class="tActionRow"
                                id="RowDetails_{{ index }}"
                            >
                                Firewall Info
                            </div>
                            <div
                                (click)="openNotification(item)"
                                *ngIf="item.actionList.includes('get')"
                                class="tActionRow"
                                id="RowNotifications_{{ index }}"
                            >
                                Notifications
                            </div>
                            <div
                                (click)="openClientMfaForm(item)"
                                *ngIf="item.actionList.includes('clientMfa')"
                                class="tActionRow"
                                id="RowClientMFAConfig_{{ index }}"
                            >
                                Client Mfa Setup
                            </div>
                            <div
                                (click)="exportYML(item)"
                                *ngIf="item.actionList.includes('exportYml')"
                                class="tActionRow"
                                id="RowExportYML_{{ index }}"
                            >
                                Export YML
                            </div>
                            <div
                                (click)="confirmDelete(item)"
                                *ngIf="item.actionList.includes('delete')"
                                class="tActionRow"
                                id="RowDelete_{{ index }}"
                            >
                                Delete
                            </div>
                            <div
                                (click)="confirmExport(item)"
                                *ngIf="item.majorVersion >= 7"
                                class="tActionRow"
                                id="RowExport_{{ index }}"
                            >
                                Download
                            </div>
                            <div
                                (click)="suspend(item)"
                                *ngIf="authorizationService.isSupportUser() && item.status !== 'SUSPENDED'"
                                class="tActionRow"
                                id="RowEdit_{{ index }}"
                            >
                                Suspend Network
                            </div>
                            <div
                                (click)="resume(item)"
                                *ngIf="authorizationService.isSupportUser() && item.status === 'SUSPENDED'"
                                class="tActionRow"
                                id="RowEdit_{{ index }}"
                            >
                                Resume Network
                            </div>
                            <div
                                (click)="restart(item)"
                                *ngIf="authorizationService.isSupportUser()"
                                class="tActionRow"
                                id="RowEdit_{{ index }}"
                            >
                                Restart
                            </div>
                            <div
                                (click)="resize(item)"
                                *ngIf="authorizationService.isSupportUser()"
                                class="tActionRow"
                                id="RowEdit_{{ index }}"
                            >
                                Resize
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
