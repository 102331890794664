import {Injectable, InjectionToken, Inject} from '@angular/core';


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';

import {ZitiControllerService} from './ziti-controller.service'

import { ZITI_DOMAIN_CONTROLLER } from 'ziti-console-lib';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import { ApiService } from '@netfoundry-ui/shared/services';

export const BROWZER_SERVICE_SEARCH_SERVICE = new InjectionToken('BROWZER_SERVICE_SEARCH_SERVICE');

export interface BrowzerServiceSearchService {

  getServices();
}

@Injectable({ providedIn: 'root' })
export class V7BrowzerServiceSearchService implements BrowzerServiceSearchService {

    currentNetwork: any = {};

  constructor(
    public apiService: ApiService,
    @Inject(ENVIRONMENT) private environment: Environment,
    @Inject(HTTP_CLIENT) private http: HttpClient,
  ) {
  }

  public getServices(){
    this.currentNetwork = this.apiService.currentNetwork.getValue();
    const url = `${this.environment.v2apiUrl}services?networkId=${this.currentNetwork.id}&sort=name,ASC`;
    const headers = new HttpHeaders({
        'Accept': 'application/json'})
      return this.http
        .get(url, {headers: headers}) as Observable<any>;
  }
}


@Injectable({ providedIn: 'root' })
export class V8BrowzerServiceSearchService implements BrowzerServiceSearchService {


  constructor(
    @Inject(HTTP_CLIENT) private http: HttpClient,
    @Inject(ENVIRONMENT) private environment: Environment,
    @Inject(ZITI_DOMAIN_CONTROLLER) private zitiController: ZitiControllerService,
  ) {
  }


    public getServices(): Observable<any> {
            const zitiApiUrl = this.zitiController?.zitiSessionData?.zitiDomain + '/edge/management/v1/services';
            const headers = new HttpHeaders({
                'Accept': 'application/json',
                'zt-session': this.zitiController?.zitiSessionData?.zitiSessionId});
            return this.http
                .get(zitiApiUrl, {headers: headers}) as Observable<any>;
        ;
    }
}
