import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ElasticsearchService, EventsTimelineQuery } from '@netfoundry-ui/shared/elasticsearch';
import { ApiService, ErrorHistoryService, LoggerService } from '@netfoundry-ui/shared/services';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { EntityEventTemplateQueryParameter } from '@netfoundry-ui/shared/model';

@Component({
    selector: 'app-mop-timeline',
    templateUrl: './event-timeline-dash.component.html',
    styleUrls: ['./event-timeline-dash.scss'],
    providers: [ElasticsearchService],
})
export class EventTimelineDashComponent implements OnChanges, OnDestroy, OnInit {
    @Input() componentId: string;
    @Input() startTime;
    @Input() endTime = Date.now();
    @Input() networkId: any = null;
    @Input() endpointNameFilter = false;
    @Input() resourceId: string;
    @Input() searchFilter: boolean | string = false;
    @Input() componentIdList: string[];
    @Input() endpointId: string = null;
    @Input() edgeRouterId: string = null;
    public eventData: any[] = [];
    public isLoading = false;
    canReadElasticSearch = true;
    currentOrgId = null;
    private errorMessage: string;
    private query: any = {};
    private subscription: Subscription = new Subscription();
    private initialized = false;

    constructor(
        private logger: LoggerService,
        private authorizationService: AuthorizationService,
        private elasticsearch: ElasticsearchService,
        private apiService: ApiService,
        private errorHistoryService: ErrorHistoryService
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrgId = org.getId();

                this.initialized = true;
                this.logger.info('Event timeline initialized');
                this.ngOnChanges();
            })
        );
    }

    ngOnChanges() {
        if (!this.initialized || this.networkId === null || this.currentOrgId === null) {
            return;
        }

        this.canReadElasticSearch = this.authorizationService.canReadElasticSearch(this.currentOrgId, this.networkId);

        if (this.canReadElasticSearch) {
            this.getTimelineData(this.networkId);
        } else {
            this.isLoading = false;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    isNewDate(i) {
        if (i === 0) {
            return true;
        } else {
            const momentA: moment.Moment = moment(this.eventData[i - 1]['@timestamp']);
            const momentB: moment.Moment = moment(this.eventData[i]['@timestamp']);
            if (momentA.startOf('day').diff(momentB.startOf('day'), 'days') !== 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    formatDate(theDate) {
        return moment.utc(theDate).local().format('MMMM D, YYYY');
    }

    formatTime(theDate) {
        return moment.utc(theDate).local().format('hh:mma');
    }

    /**
     *
     * @param eventSeverity
     * @returns {any}
     */
    getSeverityClass(eventSeverity) {
        return eventSeverity;
    }

    /**
     *
     * @param entityType
     * @returns {any}
     */
    getEntityClass(entityType) {
        return entityType;
    }

    getEventTypeClass(eventType) {
        return eventType;
    }

    trackByTimestamp(index, item) {
        return item.Timestamp;
    }

    /**
     * Get timeline events from the Network controller
     */
    private getTimelineData(networkId) {
        if (networkId === null) {
            return;
        }

        if (this.currentOrgId === null) {
            console.warn('No org specified');
            this.isLoading = false;
            return;
        }

        this.isLoading = true;
        this.query = new EventsTimelineQuery(this.logger);
        this.query.addTimeFilter(this.startTime, this.endTime);
        this.query.addFilter('organizationId', this.currentOrgId);
        this.query.addFilter('networkId', networkId);

        // Some things got misnamed in tying all of the systems together, not there is a mess
        if (this.endpointNameFilter && this.resourceId) {
            this.query.addSearch(`resourceId.keyword: ${this.componentId} OR resourceId.keyword: ${this.resourceId}`);
        } else if (this.endpointNameFilter) {
            this.query.addFilter('commonName.keyword', this.endpointNameFilter);
        } else if (this.resourceId && this.componentIdList) {
            const ids = [];
            for (const componentId of this.componentIdList) {
                ids.push(componentId);
            }
            ids.push(this.resourceId);
            this.query.matchFromList('resourceId', ids);
        } else if (this.resourceId) {
            this.query.addFilter('resourceId', this.resourceId);
        } else if (this.componentIdList) {
            this.query.matchFromList('resourceId', this.componentIdList);
        } else if (this.componentId) {
            this.query.addFilter('resourceId', this.componentId);
        }
        if (this.endpointId !== null) {
            this.query.addFilter('resourceId', this.endpointId);
        }
        if (this.edgeRouterId !== null) {
            this.query.addFilter('resourceId', this.edgeRouterId);
        }

        this.query.setSize(100);

        this.logger.info('MOP Timeline query', JSON.stringify(this.query.getQuery()));
        // reporting
        // code clean up for existing reporting query call can be done after migration
        // "componentIdList" not used in v7 networks hence skipping it
        let queryString: any = '';
        let endpointName: any = '';
        let resourceId: any = '';
        let endpointId: any = '';
        let edgeRouterId: any = '';
        if (this.endpointNameFilter && this.resourceId) {
            queryString = `resourceId.keyword: ${this.componentId} OR resourceId.keyword: ${this.resourceId}`;
        } else if (this.endpointNameFilter) {
            endpointName = this.endpointNameFilter;
        } else if (this.componentId) {
            resourceId = this.componentId;
        }
        if (this.endpointId !== null) {
            endpointId = this.endpointId;
        }
        if (this.edgeRouterId !== null) {
            edgeRouterId = this.edgeRouterId;
        }
        if (this.resourceId) {
            resourceId = this.resourceId;
        }
        const entityEventTemplateParams: EntityEventTemplateQueryParameter = {
            indexName: 'ncentityevent',
            gte: this.startTime + '',
            lte: this.endTime + '',
            size: '100',
            networkGroupId: this.currentOrgId,
            networkId: networkId,
            resourceId: resourceId,
            endpointId: endpointId,
            edgeRouterId: edgeRouterId,
            queryString: queryString,
            endpointName: endpointName,
        };
        this.logger.info(
            'MOP Timeline teplate: ncentityevent_dashboard_events_tmpl',
            JSON.stringify(entityEventTemplateParams)
        );
        //this.elasticsearch.search(this.currentOrgId, this.query.getIndexPattern(), this.query.getQuery(), {networkId: this.networkId,})
        this.elasticsearch
            .apiTemplateSearch('ncentityevent_dashboard_events_tmpl', entityEventTemplateParams)
            .subscribe(
                (data) => {
                    this.eventData = this.elasticsearch.hitsToArray(data, 'mop-timeline');
                    this.isLoading = false;
                },
                (error) => {
                    this.errorMessage = 'Error loading timeline event data';
                    this.logger.error(error);
                    this.isLoading = false;
                    this.errorHistoryService.addError(error.message);
                }
            );
    }
}
