import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientdashboardComponent } from '@netfoundry-ui/feature/dashboard/client-dashboard';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Endpoint, Network, NetworkGroup } from '@netfoundry-ui/shared/model';
import { ApiService, NetworkService, ValidateService } from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-client-mfa-form',
    templateUrl: './client-mfa-form.component.html',
    styleUrls: ['./client-mfa-form.component.scss'],
})
export class ClientMfaFormComponent implements OnInit, OnDestroy {
    public networkModel: Network = new Network({});
    currentOrg: NetworkGroup = new NetworkGroup({});
    currentOrgId: string;
    processing = false;
    errorClientId = false;
    errorIssuerUrl = false;
    mfaEnabledClientNames = [];
    isLoading = false;
    private subscription = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ClientMfaFormComponent>,
        private networkService: NetworkService,
        private apiService: ApiService,
        private validateService: ValidateService,
        private growlerService: GrowlerService,
        public dialogForm: MatDialog
    ) {
        if (data['id'] !== undefined && data['id'].length > 0) {
            this.networkModel = data;
        }
    }

    async ngOnInit() {
        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrg = new NetworkGroup(org);
                this.currentOrgId = this.currentOrg.getId();
            })
        );

        await this.networkService
            .getLinkedResources(this.networkModel, 'endpoints')
            .toPromise()
            .then((endpoints) => {
                for (const endpoint of endpoints) {
                    if (endpoint.clientMfaEnable === 'YES') {
                        this.mfaEnabledClientNames.push(endpoint);
                    }
                }
            });
    }

    cancel() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    save(skipValidate?: boolean) {
        if (skipValidate || this.validate()) {
            // TODO: Save the name
            this.networkModel.organizationId = this.currentOrgId;
            this.processing = true;
            this.subscription.add(
                this.networkService.save(this.networkModel).subscribe(
                    (data) => {
                        this.networkModel = new Network(data);

                        // propagate the updated network model if we've just updated the current network
                        if (this.networkModel.getId() === this.apiService.theNetworkIs.getId()) {
                            this.apiService.setCurrentNetwork(this.networkModel);
                        }
                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Client MFA Settings Saved',
                                'Your Client MFA Settings for this Network have been saved.'
                            )
                        );
                        this.processing = false;
                        this.dialogRef.close();
                    },
                    () => {
                        this.processing = false;
                    }
                )
            );
        }
    }

    openLink() {
        // TODO
    }

    validate() {
        this.errorClientId = false;
        this.errorIssuerUrl = false;

        if (!this.validateService.hasValue(this.networkModel.mfaClientId)) {
            this.errorClientId = true;
        }

        if (!this.validateService.hasValue(this.networkModel.mfaIssuerId)) {
            this.errorIssuerUrl = true;
        }

        return !this.errorClientId && !this.errorIssuerUrl;
    }

    clearAndSave() {
        if (this.mfaEnabledClientNames.length > 0) {
            const data = {
                title: 'Delete',
                appendId: 'ClientMfa',
                subtitle: 'Unable to clear the Client MFA settings for the following network:',
                afterListText:
                    'Please turn off Client MFA on the following clients before disabling it on the network:',
                bulletList: [this.networkModel],
                secondBulletList: this.mfaEnabledClientNames,
                secondBulletListOnClick: this.openClient,
                icon: 'Delete',
                action: 'OK',
                isDestructive: false,
                itemName: this.networkModel.name,
                context: this,
            };
            const confirmDilogRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            confirmDilogRef.afterClosed().subscribe((result) => {
                this.refresh();
            });
        } else {
            this.networkModel.mfaClientId = '';
            this.networkModel.mfaIssuerId = '';
            this.save(true);
        }
    }

    openClient(item: Endpoint, that) {
        const data = {
            model: new Endpoint(item),
            inline: true,
            currentNetwork: that.networkModel,
        };
        that.dialogForm.open(ClientdashboardComponent, {
            data: data,
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
    }

    refresh() {
        this.isLoading = true;
        this.networkService
            .getLinkedResources(this.networkModel, 'endpoints')
            .toPromise()
            .then((endpoints) => {
                this.mfaEnabledClientNames = [];
                for (const endpoint of endpoints) {
                    if (endpoint.clientMfaEnable === 'YES') {
                        this.mfaEnabledClientNames.push(endpoint);
                    }
                }
                this.isLoading = false;
            });
    }
}
