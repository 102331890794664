<div *ngIf="businessKey != null">
    <div class="title" id="AppwanBuilding" style="filter: brightness(50%)">
        {{ appWanName != null ? appWanName : 'Your AppWan' }} Is Building
    </div>
    <div class="subtitle smaller">
        {{ appWanName != null ? appWanName : 'Your AppWan' }} is being created<br />Details are below.
    </div>
    <div class="row center" id="AppwanBulkSaveCurrentTask">
        <div [ngClass]="stepStyle" class="building appwan"></div>
        <div [ngClass]="{ open: stepStyle.includes('step') }" class="buildSteps" id="currentStatus">
            {{ message }}
        </div>
    </div>
    <div class="content" id="AppwanBulkSaveAllTasks">
        <div *ngIf="appwanStatus.length > 0">
            <div *ngFor="let status of appwanStatus; let index = index" class="statusRow">
                <div class="bubble severityInfo"></div>
                <div class="statusText" id="appWanStatus_task_{{ index }}">
                    {{ status['task'] }}
                </div>
                <div class="statusText second" id="appWanStatus_status_{{ index }}">
                    {{ status['status'] }}
                </div>
            </div>
        </div>
    </div>
</div>
