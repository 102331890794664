import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartModule } from 'angular-highcharts';
import { MatDialogModule } from '@angular/material/dialog';
import { HighchartsGenericComponent } from './highcharts-generic.component';

@NgModule({
    declarations: [HighchartsGenericComponent],
    exports: [HighchartsGenericComponent],
    imports: [CommonModule, ChartModule, MatDialogModule],
})
export class FeatureHighchartsGenericModule {}
