import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddCreditCardFormComponent } from '@netfoundry-ui/feature/form/add-credit-card-form';
import { BillingEnabledService } from '@netfoundry-ui/feature/shared-services';

@Component({
    selector: 'app-credit-card-warning',
    templateUrl: './credit-card-warning.component.html',
    styleUrls: ['./credit-card-warning.component.scss'],
})
export class CreditCardWarningComponent {
    daysRemaining = 6;
    freeTrialDays = 30;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() close: EventEmitter<boolean> = new EventEmitter();

    dialogRef;

    constructor(private billingEnabledService: BillingEnabledService, public dialogForm: MatDialog) {
        this.daysRemaining = billingEnabledService.trialDaysRemaining;
    }

    closeWarning() {
        this.close.emit(true);
    }

    addACard() {
        this.dialogRef = this.dialogForm.open(AddCreditCardFormComponent, {
            height: '550px',
            width: '575px',
        });
    }
}
