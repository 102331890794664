import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HTTP_CLIENT, LoggerService, TokenService } from '@netfoundry-ui/shared/services';
import { of as observableOf, Subject, throwError as observableThrowError } from 'rxjs';

import { catchError, delay } from 'rxjs/operators';

export const NOTIFICATION_CONFIG = new InjectionToken<NotificationConfig>('NOTIFICATION_CONFIG');

export interface NotificationConfig {
    url: string;
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    apiUrl;

    lastErrorSource = new Subject<HttpErrorResponse>();

    constructor(
        private logger: LoggerService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        private tokenService: TokenService,
        @Inject(NOTIFICATION_CONFIG) config?: NotificationConfig
    ) {
        this.apiUrl = config.url;
    }

    public find(params = {}) {
        const fullpath = `${this.apiUrl}/notification-settings`;

        const headers = this._setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers, params: params })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    public findById(id: string) {
        const fullpath = `${this.apiUrl}/notification-settings/${id}`;

        const headers = this._setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    public create(model: unknown = {}) {
        const fullpath = `${this.apiUrl}/notification-settings`;

        const headers = this._setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(model), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    public update(id, model: unknown = {}) {
        const fullpath = `${this.apiUrl}/notification-settings/${id}`;

        const headers = this._setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, JSON.stringify(model), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    public delete(id: string) {
        const fullpath = `${this.apiUrl}/notification-settings/${id}`;

        const headers = this._setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .delete(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: any, error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            this.logger.error('Notification Service error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            this.logger.error(`Notification service returned code ${error.status}`, error.error);
        }
        scope.lastErrorSource.next(error);
        return observableThrowError(error);
    }

    /**
     * Return headers
     */
    private _setHeaders(): HttpHeaders {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (tokenResponse.expired) {
            // if the token is expired, return null
            headers = null;
        } else if (tokenResponse.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        if (headers != null) {
            return headers.append('Content-Type', 'application/json');
        } else {
            return headers;
        }
    }
}
