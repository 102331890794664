import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from 'rxjs';
import { cloneDeep, isEmpty, delay } from 'lodash';
import { ApiService, HTTP_CLIENT, LoggerService } from '@netfoundry-ui/shared/services';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ENVIRONMENT, EdgeRouterRegistrationKeyV2, Environment, NetworkV2 } from '@netfoundry-ui/shared/model';
import { Router } from '@angular/router';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { NETWORK_SERVICE, NetworkServiceV2 } from './network.service';
import { EdgeRouterServiceV2 } from './edge-router.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class BrowzerOverviewService {
    isLoading = new BehaviorSubject<boolean>(true);
    isNetworkOK = new BehaviorSubject<boolean>(false);
    isEdgeRouterOK = new BehaviorSubject<boolean>(false);
    isEdgeRouterProvisioning = new BehaviorSubject<boolean>(false);
    isNameOK = new BehaviorSubject<boolean>(false);
    settingName = false;
    currentNetwork = new BehaviorSubject<NetworkV2>(new NetworkV2());
    currenNetworkId = '';
    subscription;
    refreshingCurrentNetwork = false;

    doRefresh = true;
    refreshTimer: any;
    apiUrl: string;

    invalidVersion = false;

    constructor(
        private logger: LoggerService,
        private authorizationService: AuthorizationService,
        @Inject(NETWORK_SERVICE) private networkSvc: NetworkServiceV2,
        private apiService: ApiService,
        private edgeRouterSvc: EdgeRouterServiceV2,
        private growlerSvc: GrowlerService,
        private router: Router,
        @Inject(ENVIRONMENT) private environment: Environment,
        @Inject(HTTP_CLIENT) private http: HttpClient,
    ) {
        this.subscription = new Subscription();
        this.subscription.add(
            this.apiService.currentNetwork.subscribe(async (currentNetwork) => {
                if (isEmpty(currentNetwork?.id)) {
                    return;
                }
                this.currenNetworkId = currentNetwork.id;
                const network = await this.networkSvc.getNetwork(this.currenNetworkId);
                this.currentNetwork.next(network as NetworkV2);
                this.isVersionAllowed(this.currentNetwork.value);
                if (currentNetwork?.id) {
                    this.checkBrowzerReadiness();
                }
            })
        );
        this.apiUrl = environment.v3Enabled ? environment.v3apiUrl : environment.v2apiUrl;
    }

    reset(doRefresh = true) {
        if (this.refreshTimer) clearTimeout(this.refreshTimer);
        this.doRefresh = doRefresh;
        // this.isNetworkOK.next(false);
        // this.isNameOK.next(false);
        // this.isEdgeRouterOK.next(false);
    }

    async checkBrowzerReadiness(): Promise<void> {
        this.isNetworkOK.next(this.currentNetwork.value?.publicCertEnabled);
        await this.isEdgeRouterWssEnabled();
        if (!this.isNetworkOK.value || !this.isNameOK.value || !this.isEdgeRouterOK.value) {
            this.refreshBrowzerReadiness();
        }
    }

    public createBrowzerInstance(networkId: string, edgeRouterProvider: string, edgeRouterRegion: string, domainNameLabel: string):Observable<any> {
        const body: any = {
            networkId,
            edgeRouterProvider,
            edgeRouterRegion,
            domainNameLabel
        };
        const url = `${this.apiUrl}browzers`
        return this.http.post(url, body, {headers: {'Accept': 'application/hal+json'}}) as Observable<any>;
    }

    public updateBrowzerInstance(networkId: string, edgeRouterProvider: string, edgeRouterRegion: string, browzerId: string, domainNameLabel: string):Observable<any> {
        const body: any = {
            networkId,
            edgeRouterProvider,
            edgeRouterRegion,
            domainNameLabel
        };
        const url = `${this.apiUrl}browzers/${browzerId}`
        return this.http.put(url, body, {headers: {'Accept': 'application/hal+json'}}) as Observable<any>;
    }

    public getBrowzerInstance(networkId): Observable<any> {
        const url = `${this.apiUrl}browzers/?networkId=${networkId}`
        return this.http.get(url, {headers: {'Accept': 'application/hal+json'}}) as Observable<any>;
    }

    public validateCreate(model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .post(this.apiUrl + 'browzers', model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }
    public validateUpdate(browzerId: string, model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .put(this.apiUrl + 'browzers/' + browzerId, model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    private refreshBrowzerReadiness() {
        if (this.refreshTimer) clearTimeout(this.refreshTimer);
        if (!this.doRefresh) {
          return;
        }
        this.refreshTimer = setTimeout(async () => {
            if (!this.isNetworkOK.value || !this.isNameOK.value) {
                const network = await this.networkSvc.getNetwork(this.currenNetworkId);
                this.currentNetwork.next(network as NetworkV2);
            }
            this.checkBrowzerReadiness();
        }, 5000);
    }

    isEdgeRouterWssEnabled(): Promise<void> {
        return this.edgeRouterSvc
            .getEdgeRouterPage({
                params: { networkId: this.currentNetwork.value?.id, wssListener: true, status: 'PROVISIONED,PROVISIONING' },
            })
            .then((rows) => {
                let provisioned = false;
                let provisioning = false;
                rows.forEach((er: any) => {
                    if (er.status === 'PROVISIONED') {
                        provisioned = true;
                    } else if (er.status === 'PROVISIONING') {
                        provisioning = true;
                    }
                });
                this.isEdgeRouterOK.next(provisioned);
                this.isLoading.next(false);
                this.isEdgeRouterProvisioning.next(!provisioned && provisioning);
            })
            .catch(() => {
                this.logger.error('Error checking router wss flag');
            });
    }

    // setName(uniqueName: string) {
    //     if (this.authorizationService.canUpdateNetwork(this.currentNetwork.value.id)) {
    //         const model = cloneDeep(this.currentNetwork.value);
    //         model.domainNameLabel = uniqueName;
    //         this.settingName = true;
    //         const nameSub: Subscription = this.isNameOK.subscribe((nameSet) => {
    //             if (nameSet) {
    //                 this.settingName = false;
    //                 this.growlerSvc.show(new GrowlerData('success', 'Success', 'Unique Name added to network.'));
    //                 nameSub.unsubscribe();
    //             } else {
    //                 delay(() => {
    //                     this.refreshCurrentNetwork();
    //                 }, 2000);
    //             }
    //         });
    //         return firstValueFrom(this.networkSvc.updateResourceById(model.id, { body: model })).then((rec) => {
    //             this.checkBrowzerReadiness();
    //             return rec;
    //         });
    //     }
    //     return Promise.resolve(false);
    // }

    async refreshCurrentNetwork() {
        if (this.refreshingCurrentNetwork) {
            return;
        }
        this.refreshingCurrentNetwork = true;
        const updatedNetwork: any = await this.networkSvc.getNetwork(this.currenNetworkId);
        this.apiService.setCurrentNetwork(updatedNetwork);
        this.currentNetwork.next(updatedNetwork);
        this.refreshingCurrentNetwork = false;
    }

    isNetworkBrowzerReady(network: NetworkV2): Promise<boolean> {
        if (!network.publicCertEnabled) return Promise.resolve(false);
        return this.edgeRouterSvc
            .getEdgeRouterPage({
                params: { networkId: network.id, wssListener: true, status: 'PROVISIONED' },
            })
            .then((rows) => rows && rows.length > 0);
    }

    isVersionAllowed(network: NetworkV2) {
        if (isEmpty(network)) {
            return true;
        }
        const majorVersionNumber = this.apiService.getNetworkVersion(network);

        const minorVersionNumber = this.apiService.getNetworkMinorVersion(network);
        const patchVersionNumber = this.apiService.getNetworkPatchVersion(network);
        this.invalidVersion =
            majorVersionNumber < 7 ||
            (majorVersionNumber === 7 && minorVersionNumber < 3) ||
            (majorVersionNumber === 7 && minorVersionNumber === 3 && patchVersionNumber < 90);
        return this.invalidVersion;
    }
}
