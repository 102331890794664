import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { GatewaydashboardComponent } from '@netfoundry-ui/feature/dashboard/gateway-dashboard';
// import { ClientdashboardComponent } from '@netfoundry-ui/feature/dashboard/client-dashboard';
import { ClientCardsFormComponent } from '@netfoundry-ui/feature/form/client-cards-form';
import { GatewayformComponent } from '@netfoundry-ui/feature/form/gateway-form';
import { GroupsformComponent } from '@netfoundry-ui/feature/form/groups-form';
import { ServicesformComponent } from '@netfoundry-ui/feature/form/services-form';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { AppWan, Endpoint, Group, Service } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    AppwanService,
    AzureDeployService,
    ClientService,
    GatewayService,
    GetCloudformationLinkService,
    GroupService,
    LoggerService,
    NetworkVersionService,
    RefresherService,
    ServiceService,
    ValidateService,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { WarningListComponent } from '@netfoundry-ui/ui/warning-list';
import { forkJoin, of, ReplaySubject, Subscription, throwError as observableThrowError } from 'rxjs';
import { take } from 'rxjs/operators';

const provisionedStatus = 300;
const registeredStatus = 400;

@Component({
    selector: 'app-app-wan-form',
    templateUrl: './app-wan-form.component.html',
    styleUrls: ['./app-wan-form.component.scss'],
})
export class AppWanFormComponent implements OnInit, OnDestroy {
    @Input() model: AppWan = new AppWan({});

    step = 1;
    processing = false;
    availableGateways: Endpoint[] = [];
    availableClients: Endpoint[] = [];
    availableGroups: Group[] = [];
    availableServices: Service[] = [];

    // Assignment lists, use IDs instead of objects
    selectedEndpoints = [];
    selectedGroups = [];
    selectedServices = [];
    endpointsAdded = [];
    endpointsRemoved = [];
    servicesAdded = [];
    servicesRemoved = [];
    groupsAdded = [];
    groupsRemoved = [];

    endpointMap = {};
    serviceMap = {};

    zitiClients = [];
    networkAndIcmpServices = [];

    isSelected = false;
    isAdding = false;
    isEditing = false;
    isCloning = false;
    isComplete = false;
    isInline = false;
    searchEndpointString = '';
    searchServiceString = '';

    // pickbox loaders
    clientsLoading = false;
    gatewaysLoading = false;
    servicesLoading = false;
    groupsLoading = false;

    finalGateways = [];
    finalServices = [];
    finalClients = [];
    finalGroups = [];

    @Output() refreshEvent: EventEmitter<boolean> = new EventEmitter();
    errorName = false;

    gatewaysRefreshedSource = new ReplaySubject<boolean>();
    gatewaysRefreshed = this.gatewaysRefreshedSource.asObservable();

    clientsRefreshedSource = new ReplaySubject<boolean>();
    clientsRefreshed = this.clientsRefreshedSource.asObservable();

    servicesRefreshedSource = new ReplaySubject<boolean>();
    servicesRefreshed = this.servicesRefreshedSource.asObservable();
    isLoading = false;
    zitiDialogRef;
    namePlaceholder = 'What would you like to call this AppWAN?';
    canModify = true;
    canCreate = true;
    hideHelp = false;
    newlyAddedGatewayIds = [];
    newlyAddedClientIds = [];
    newlyAddedServiceIds = [];
    serviceRef;
    groupRef;
    gatewaysRef;
    clientRef;
    dialogInlineRef;
    dialogInlineGWRef;
    disableAzureStackDeploy = false;
    allClients = [];
    allGateways = [];
    allGroups = [];
    allServices = [];
    // todo remove
    hideLaunchButton = false;
    sendingState: string[] = [];
    sendto: string[] = [];
    errorEmails = false;
    preSelectedEndpointIds = [];
    preSelectedGroupIds = [];
    preSelectedServiceIds = [];
    @ViewChild('availableClientsPicker', { static: true }) availableClientsPicker;
    @ViewChild('availableGatewaysPicker', { static: true })
    availableGatewaysPicker;
    @ViewChild('availableServicesPicker', { static: true })
    availableServicesPicker;
    @ViewChild('selectedServicesPicker', { static: true }) selectedServicesPicker;
    @ViewChild('availableGroupsPicker', { static: true }) availableGroupsPicker;
    private subscription = new Subscription();
    private wasModelChanged = false;
    private appwanStatusWasActive = true;
    private originalAppWanName;

    constructor(
        private logger: LoggerService,
        private serviceService: ServiceService,
        private clientService: ClientService,
        private gatewayService: GatewayService,
        private groupService: GroupService,
        private service: AppwanService,
        private growlerService: GrowlerService,
        private dialogRef: MatDialogRef<AppWanFormComponent>,
        private validateService: ValidateService,
        private zitiEnabledService: ZitiEnabledService,
        public dialogForm: MatDialog,
        public authorizationService: AuthorizationService,
        private refresher: RefresherService,
        private cfService: GetCloudformationLinkService,
        private apiService: ApiService,
        private azureDeployService: AzureDeployService,
        private networkVersionService: NetworkVersionService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // TODO remove
        this.hideLaunchButton = this.networkVersionService.currentNetworkVersion > 4;

        if (data != null) {
            this.isInline = data.inline;

            // setting cloning
            this.isCloning = data.clone;
        }

        if (data != null && data.model != null) {
            this.model = data.model;
            this.isAdding = false;
            this.isEditing = !data.clone && true;

            this.originalAppWanName = this.model.name;
            this.model.endpoints = [];

            // if cloning is set to true, set the name placeholder and create a new appwan model
            if (this.isCloning) {
                this.namePlaceholder = this.model.name;
            }
        } else {
            this.model = new AppWan({});
            this.isAdding = true;
            this.isEditing = false;
        }

        this.updateCanModify();
        this.updateCanCreate();
    }

    async ngOnInit() {
        // moved preselects their own method
        await this._getPreSelectedItems();

        // if cloning is set to true, set the name placeholder and create a new appwan model
        if (this.isCloning) {
            this.namePlaceholder = this.model.name;
            this.model = new AppWan({});
        }

        this.azureDeployService.canShowAzureFormationLink('AZSGW').subscribe((result) => {
            this.disableAzureStackDeploy = !result;
        });

        this.processing = false;
        this.errorName = false;

        if (this.authorizationService.canListEndpoints()) {
            this.gatewaysLoading = true;
            this.subscription.add(
                this.gatewayService.get().subscribe((result) => {
                    const gateways = [];
                    this.availableGateways = [];
                    for (const gateway of result) {
                        const index = this.newlyAddedGatewayIds.indexOf(gateway.id);
                        if (
                            (index > -1 ||
                                gateway.status === registeredStatus ||
                                (gateway.status === provisionedStatus && gateway.endpointType !== 'L2VCPEGW')) &&
                            (gateway.endpointProtectionRole == null || gateway.endpointType.includes('ZT'))
                        ) {
                            gateways.push(gateway);
                            this.endpointMap[gateway.id] = gateway;
                            if (index > -1 && gateway.status > 200) {
                                this.newlyAddedGatewayIds.splice(index, 1);
                            }
                            if (this.selectedEndpoints.indexOf(gateway.id) === -1) {
                                this.availableGateways.push(gateway);
                            }
                        }
                    }
                    this.allGateways = gateways;
                    this.gatewaysLoading = false;

                    this.gatewaysRefreshedSource.next(true);
                })
            );

            this.clientsLoading = true;
            this.subscription.add(
                this.clientService.get().subscribe((result) => {
                    const clients = [];
                    this.availableClients = [];
                    this.allClients = [];
                    for (const client of result) {
                        const index = this.newlyAddedClientIds.indexOf(client.id);
                        if (index > -1 || client.status === registeredStatus || client.status === provisionedStatus) {
                            clients.push(client);
                            this.endpointMap[client.id] = client;
                            if (index > -1 && client.status > 200) {
                                this.newlyAddedClientIds.splice(index, 1);
                            }

                            if (this.selectedEndpoints.indexOf(client.id) === -1) {
                                this.availableClients.push(client);
                            }
                        }
                    }

                    this.allClients = clients;
                    this.clientsLoading = false;

                    this.clientsRefreshedSource.next(true);
                })
            );
        }

        if (this.authorizationService.canListEndpointGroups()) {
            this.groupsLoading = true;
            this.subscription.add(
                this.groupService.get().subscribe((result) => {
                    this.availableGroups = [];
                    this.allGroups = [];
                    for (const group of result) {
                        this.allGroups.push(group);

                        if (this.selectedGroups.indexOf(group.id) === -1) {
                            this.availableGroups.push(group);
                        }
                    }
                    this.groupsLoading = false;
                })
            );
        }

        if (this.authorizationService.canListServices()) {
            this.servicesLoading = true;
            this.subscription.add(
                this.serviceService.get().subscribe((result) => {
                    const services = [];
                    this.availableServices = [];
                    for (const service of result) {
                        const index = this.newlyAddedServiceIds.indexOf(service.id);
                        if (index > -1 || service.status === provisionedStatus) {
                            services.push(service);
                            this.serviceMap[service.id] = service;
                            if (index > -1 && service.status > 200) {
                                this.newlyAddedServiceIds.splice(index, 1);
                            }
                        }

                        if (
                            this.selectedServices.indexOf(service.id) === -1 &&
                            service.status === provisionedStatus &&
                            service.serviceClass !== 'L2G'
                        ) {
                            this.availableServices.push(service);
                        }
                        if (service.status === provisionedStatus) {
                            this.allServices.push(service);
                        }
                    }
                    this.servicesLoading = false;
                    this.servicesRefreshedSource.next(true);
                })
            );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    moveTo(item, type) {
        if (this.canModify) {
            this.logger.info('Move item type: ' + type, item);

            // determine item type
            if (type === 'endpoint') {
                this.selectedEndpoints.push(item.getId());
                this.endpointsAdded.push(item.getId());

                const index = this.endpointsRemoved.indexOf(item.getId());
                if (index > -1) {
                    this.endpointsRemoved.splice(index, 1);
                }
            } else if (type === 'service') {
                this.selectedServices.push(item.getId());
                this.servicesAdded.push(item.getId());

                const index = this.servicesRemoved.indexOf(item.getId());
                if (index > -1) {
                    this.servicesRemoved.splice(index, 1);
                }
            } else {
                this.selectedGroups.push(item.getId());
                this.groupsAdded.push(item.getId());

                const index = this.groupsRemoved.indexOf(item.getId());
                if (index > -1) {
                    this.groupsRemoved.splice(index, 1);
                }
            }
        }
    }

    moveFrom(item, type) {
        if (this.canModify) {
            this.logger.info('Move item type: ' + type + ' from', item);

            // determine item type
            if (type === 'client') {
                this.selectedEndpoints.splice(this.selectedEndpoints.indexOf(item.getId()), 1);

                if (this.preSelectedEndpointIds.indexOf(item.getId()) > -1) {
                    this.endpointsRemoved.push(item.getId());
                }

                const index = this.endpointsAdded.indexOf(item.getId());
                if (index > -1) {
                    this.endpointsAdded.splice(index, 1);
                }

                // handle move in picker
                this.availableClientsPicker.removeFromSelected(item);
            } else if (type === 'gateway') {
                this.selectedEndpoints.splice(this.selectedEndpoints.indexOf(item.getId()), 1);

                if (this.preSelectedEndpointIds.indexOf(item.getId()) > -1) {
                    this.endpointsRemoved.push(item.getId());
                }

                const index = this.endpointsAdded.indexOf(item.getId());
                if (index > -1) {
                    this.endpointsAdded.splice(index, 1);
                }

                // handle move in picker
                this.availableGatewaysPicker.removeFromSelected(item);
            } else if (type === 'service') {
                this.selectedServices.splice(this.selectedServices.indexOf(item.getId()), 1);

                if (this.preSelectedServiceIds.indexOf(item.getId()) > -1) {
                    this.servicesRemoved.push(item.getId());
                }

                const index = this.servicesAdded.indexOf(item.getId());
                if (index > -1) {
                    this.servicesAdded.splice(index, 1);
                }

                // handle move in picker
                this.availableServicesPicker.removeFromSelected(item);
            } else {
                this.selectedGroups.splice(this.selectedGroups.indexOf(item.getId()), 1);

                if (this.preSelectedGroupIds.indexOf(item.getId()) > -1) {
                    this.groupsRemoved.push(item.getId());
                }

                const index = this.groupsAdded.indexOf(item.getId());
                if (index > -1) {
                    this.groupsAdded.splice(index, 1);
                }

                // handle move in picker
                this.availableGroupsPicker.removeFromSelected(item);
            }
        }
    }

    addAllEndpoints() {
        if (this.canModify) {
            this.endpointsAdded = [];
            this.selectedEndpoints = [];
            this.selectedGroups = [];
            this.endpointsAdded = [];
            this.groupsAdded = [];

            for (const endpoint of this.allClients) {
                if (this.preSelectedEndpointIds.indexOf(endpoint.getId()) === -1) {
                    this.endpointsAdded.push(endpoint.id);
                }
                this.selectedEndpoints.push(endpoint.id);
            }
            for (const endpoint of this.allGateways) {
                if (this.preSelectedEndpointIds.indexOf(endpoint.getId()) === -1) {
                    this.endpointsAdded.push(endpoint.id);
                }
                this.selectedEndpoints.push(endpoint.id);
            }
            this.endpointsRemoved = [];

            this.groupsAdded = [];

            for (const group of this.allGroups) {
                if (this.preSelectedGroupIds.indexOf(group.getId()) === -1) {
                    this.groupsAdded.push(group.id);
                }
                this.selectedGroups.push(group.id);
            }
            this.groupsRemoved = [];

            this.availableClients = [];
            this.availableGateways = [];
            this.availableGroups = [];
        }
    }

    addAllServices() {
        if (this.canModify) {
            this.servicesAdded = [];
            this.selectedServices = [];
            this.availableServicesPicker.itemsSelected = [];
            for (const service of this.allServices) {
                if (this.preSelectedEndpointIds.indexOf(service.getId()) === -1) {
                    this.servicesAdded.push(service.getId());
                }
                this.selectedServices.push(service.getId());
            }

            this.servicesRemoved = [];
            this.availableServices = [];
        }
    }

    removeAllEndpoints() {
        if (this.canModify) {
            this.endpointsRemoved = [];
            for (const endpoint of this.selectedEndpoints) {
                if (this.preSelectedEndpointIds.indexOf(endpoint) > -1) {
                    this.endpointsRemoved.push(endpoint);
                }
            }

            this.endpointsAdded = [];
            this.selectedEndpoints = [];

            this.groupsRemoved = [];
            for (const group of this.selectedGroups) {
                if (this.preSelectedGroupIds.indexOf(group) > -1) {
                    this.groupsRemoved.push(group);
                }
            }
            this.availableGroups = [...this.allGroups];
            this.availableClients = [...this.allClients];
            this.availableGateways = [...this.allGateways];
            this.groupsAdded = [];
            this.selectedGroups = [];
        }
    }

    removeAllServices() {
        if (this.canModify) {
            for (const service of this.selectedServices) {
                if (this.preSelectedServiceIds.indexOf(service) > -1) {
                    this.servicesRemoved.push(service);
                }
            }
            this.servicesAdded = [];
            this.selectedServices = [];
            this.availableServices = [...this.allServices];
            this.availableServicesPicker.itemsSelected = [];
            this.availableServicesPicker.triggerRefresh();
        }
    }

    isAssigned(item, type) {
        if (typeof item['getId'] !== 'function') {
            console.warn('Item: ' + type + ' is not a model', item);
            return false;
        }

        if (type === 'endpoint') {
            if (this.selectedEndpoints.indexOf(item.getId()) === -1) {
                return false;
            }
        } else if (type === 'service') {
            if (this.selectedServices.indexOf(item.getId()) === -1) {
                return false;
            }
        } else {
            // groups
            if (this.selectedGroups.indexOf(item.getId()) === -1) {
                return false;
            }
        }

        return true;
    }

    clearEndpointFilter() {
        this.searchEndpointString = '';
    }

    clearServiceFilter() {
        this.searchServiceString = '';
    }

    hide() {
        this.dialogRef.close();
    }

    async save() {
        if (this.validate()) {
            this.isLoading = true;
            this.processing = true;

            await this.waitForNewResources();

            this.getFinalResources();
            // if the model was changed, save the changes
            if (this.wasModelChanged) {
                let isReady = true;
                if (this.isEditing) {
                    isReady = await this.waitForAppwan();
                }

                if (isReady) {
                    this.service.save(this.model).subscribe(
                        (result) => {
                            this.logger.info('Save new model', result);
                            this.model = new AppWan(result);
                            this._sync();
                        },
                        (error) => {
                            this.processing = false;
                            this.isLoading = false;
                        }
                    );
                } else {
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'AppWAN Must Be Provisoned to Save',
                            'Please wait for the AppWAN to be in a provisoned state before saving your changes'
                        )
                    );
                }
                // otherwise, if the model was not changed, there is no need to save the changes
            } else {
                this._sync();
            }
        }
    }

    getFinalResources() {
        this.finalGateways = [];
        this.finalClients = [];
        this.finalServices = [];
        this.finalGroups = [];

        for (const gateway of this.allGateways) {
            if (this.selectedEndpoints.indexOf(gateway.id) > -1) {
                this.finalGateways.push(gateway);
            }
        }

        let i = 0;
        for (const client of this.allClients) {
            if (this.selectedEndpoints.indexOf(client.id) > -1) {
                this.finalClients.push(client);
                this.sendto[i] = '';
                this.sendingState[i] = '';
                i++;
            }
        }
        for (const service of this.allServices) {
            if (this.selectedServices.indexOf(service.id) > -1) {
                this.finalServices.push(service);
            }
        }

        for (const group of this.allGroups) {
            if (this.selectedGroups.indexOf(group.id) > -1) {
                this.finalGroups.push(group);
            }
        }
    }

    async waitForNewResources() {
        if (
            this.newlyAddedGatewayIds.length > 0 ||
            this.newlyAddedClientIds.length > 0 ||
            this.newlyAddedServiceIds.length > 0
        ) {
            for (let i = 0; i < 10; i++) {
                // check the status on a timer
                const result = await new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve(this.resourceWait());
                    }, 1000);
                });
                if (result) {
                    break;
                }
            }
        }
        return;
    }

    async resourceWait() {
        const proms = [];
        if (this.newlyAddedGatewayIds.length > 0) {
            proms.push(this.gatewaysRefreshed.pipe(take(1)).toPromise());
        }

        if (this.newlyAddedClientIds.length > 0) {
            proms.push(this.clientsRefreshed.pipe(take(1)).toPromise());
        }

        if (this.newlyAddedServiceIds.length > 0) {
            proms.push(this.servicesRefreshed.pipe(take(1)).toPromise());
        }

        if (proms.length > 0) {
            this.refresh();
            await Promise.all(proms);
            if (
                this.newlyAddedGatewayIds.length === 0 &&
                this.newlyAddedClientIds.length === 0 &&
                this.newlyAddedServiceIds.length === 0
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    initSave() {
        if (this.validate() && this.canModify) {
            if (this.zitiEnabledService.zitiEnabled) {
                this.checkZitiConflicts();
            } else {
                this.save();
            }
        }
    }

    openZitiNoGatewayWarning() {
        const bulletList = [];
        for (const client of this.zitiClients) {
            bulletList.push(client);
        }

        const data = {
            title: 'No Ziti Bridge Gateway',
            appendId: 'NoZitiBridge',
            subtitle:
                'In order to utilize Ziti clients a Ziti Bridge Gateway needs to be added to the AppWan. This affects the following clients:',
            icon: 'ZitiGateway',
            action: 'OK, Thanks for the heads up, Proceed',
            bulletList: bulletList,
        };
        this.zitiDialogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '340px',
            width: '600px',
            autoFocus: false,
        });
        this.zitiDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // start the save process
                this.save();
            }
        });
    }

    /**
     * function for handling the case where a user removes and adds a ziti gateway
     * the form and API prevent the user from having two ziti gateways on the same appwan
     * this caused issues when a user went to switch ziti gateways on the appwan due to how the appwan wizard works
     *
     * the appwan wizard collects all of the modifications it is going to do an sends them out in a batch
     * it only waits for the appwan to go back into a 300 state and does not wait for the change to actually take place
     * as such, it was possible to add/remove a ziti gateway and trigger the error for having two ziti gateways in the same appwan
     * this function is used to check to see if there's a situation where the user is adding/removing a ziti gateway
     * if so, it will remove the gateway first and wait for the gateway to get in a 300 state before continuing with the rest of the appwan save
     * doing this should allow enough time between requests to prevent the issue from happening
     */
    async handleZitiGatewaySwap(selfLink) {
        const zitiGateways = this.getAllZitiGateways();
        let removedZitiGateway = false;
        let addedZitiGateway = false;
        for (const zitiGateway of zitiGateways) {
            if (this.endpointsAdded.indexOf(zitiGateway.id) > -1) {
                addedZitiGateway = true;
            } else if (this.endpointsRemoved.indexOf(zitiGateway.id) > -1) {
                removedZitiGateway = true;
            }
        }

        if (addedZitiGateway && removedZitiGateway) {
            const isActive = await this.getStatus(selfLink, this.appwanStatusWasActive);
            if (isActive) {
                return await this.service
                    .removeEndpoints(this.model, this.endpointsRemoved)
                    .toPromise()
                    .then(
                        (result) => {
                            this.endpointsRemoved = [];
                            return true;
                        },
                        (error) => {
                            this.growlerService.show(
                                new GrowlerData(
                                    'error',
                                    'Error',
                                    'Unable to remove Endpoints. AppWan must be in Provisioned State'
                                )
                            );
                            return false;
                        }
                    );
            } else {
                this.growlerService.show(
                    new GrowlerData('error', 'Error', 'Unable to remove Endpoints. AppWan must be in Provisioned State')
                );
                return false;
            }
        } else {
            return true;
        }
    }

    async _sync() {
        // obtaining the self link for the model
        const selfLink = this.service.getSelfLink(this.model);

        const finishAppwan = await this.handleZitiGatewaySwap(selfLink);

        if (finishAppwan) {
            const forker = forkJoin(
                // if there was at least one endpoint removed and the appwan status is 300
                this.endpointsRemoved.length > 0 && (await this.getStatus(selfLink, this.appwanStatusWasActive))
                    ? // remove the endpoints from the appwan
                      this.service.removeEndpoints(this.model, this.endpointsRemoved)
                    : // otherwise, if there was at least one endpoint removed
                    this.endpointsRemoved.length > 0
                    ? // throw an error because the status of the appwan was not 300
                      observableThrowError(new Error('Unable to remove Endpoints. AppWan must be in Provisioned State'))
                    : // otherwise, use a dummy observable and do nothing
                      of(false),
                // if at least one endpoint was added and the status of the appwan is currently 300
                this.endpointsAdded.length > 0 && (await this.getStatus(selfLink, this.appwanStatusWasActive))
                    ? // add the new endpoints
                      this.service.addEndpoints(this.model, this.endpointsAdded)
                    : // othrewise, if there was at least one endpoint added
                    this.endpointsAdded.length > 0
                    ? // throw an error because the status of the appwan was not 300
                      observableThrowError(new Error('Unable to add Endpoints. AppWan must be in Provisioned State'))
                    : // otherwise, use a dummy observable and do nothing
                      of(false),
                this.groupsAdded.length > 0 && (await this.getStatus(selfLink, this.appwanStatusWasActive))
                    ? this.service.addGroups(this.model, this.groupsAdded)
                    : this.groupsAdded.length > 0
                    ? observableThrowError(new Error('Unable to add Groups. AppWan must be in Provisioned State'))
                    : of(false),
                this.groupsRemoved.length > 0 && (await this.getStatus(selfLink, this.appwanStatusWasActive))
                    ? this.service.removeGroups(this.model, this.groupsRemoved)
                    : this.groupsRemoved.length > 0
                    ? observableThrowError(new Error('Unable to remove Groups. AppWan must be in Provisioned State'))
                    : of(false),
                this.servicesAdded.length > 0 && (await this.getStatus(selfLink, this.appwanStatusWasActive))
                    ? this.service.addServices(this.model, this.servicesAdded)
                    : this.servicesAdded.length > 0
                    ? observableThrowError(new Error('Unable to add Services. AppWan must be in Provisioned State'))
                    : of(false),
                this.servicesRemoved.length > 0 && (await this.getStatus(selfLink, this.appwanStatusWasActive))
                    ? this.service.removeServices(this.model, this.servicesRemoved)
                    : this.servicesRemoved.length > 0
                    ? observableThrowError(new Error('Unable to add Services. AppWan must be in Provisioned State'))
                    : of(false)
            ).subscribe(
                (data) => {
                    this.logger.info('DATA', data);
                    this.refreshEvent.emit(true);
                    this.growlerService.show(
                        new GrowlerData('success', 'Success', 'AppWAN Save Complete', 'The information has been saved')
                    );
                    this.processing = false;
                    this.isLoading = false;
                    this.step = 2;
                },
                (err) => {
                    this.logger.error(err);
                    this.refreshEvent.emit(true);
                    let errorMessage = err.message;
                    if (err.error && err.error[0] && err.error[0]['message']) {
                        errorMessage = err.error[0]['message'];
                    }
                    this.growlerService.show(new GrowlerData('error', 'Error', errorMessage));
                    this.processing = false;
                    this.isLoading = false;
                    this.hide();
                }
            );
        }
    }

    validate(): boolean {
        this.errorName = false;
        if (!this.validateService.isValidName(this.model.name) || this.model.name.length > 52) {
            this.errorName = true;
        }
        return !this.errorName;
    }

    // function for determining if the name was updated
    updatedName() {
        // if the name was changed, then mark the model as being changed so that we update
        // the model and its attached resources
        if (this.model.name !== this.originalAppWanName) {
            this.wasModelChanged = true;
        } else {
            this.wasModelChanged = false;
        }
    }

    addService() {
        const data = {
            inline: true,
        };
        this.serviceRef = this.dialogForm.open(ServicesformComponent, {
            data: data,
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.serviceRef.afterClosed().subscribe((result) => {
            if (result && result['updatedService']) {
                const newService = new Service(result['updatedService']);
                this.selectedServices.push(newService.id);
                this.newlyAddedServiceIds.push(newService.id);
                this.servicesAdded.push(newService.id);
                this.serviceMap[newService.id] = newService;
                this.allServices.push(newService);
                this.availableServicesPicker.itemsSelected.push(newService);
                this.availableServicesPicker.triggerRefresh();
                this.selectedServicesPicker.triggerRefresh();
            }
        });
    }

    addGroup() {
        const data = {
            inline: true,
        };
        this.groupRef = this.dialogForm.open(GroupsformComponent, {
            data: data,
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.groupRef.afterClosed().subscribe((result) => {
            if (result && result['newGroup']) {
                const newGroup = new Group(result['newGroup']);
                this.selectedGroups.push(newGroup.id);
                this.groupsAdded.push(newGroup.id);
                this.allGroups.push(newGroup);
            }
        });
    }

    addClient() {
        const data = {
            inline: true,
        };
        this.clientRef = this.dialogForm.open(ClientCardsFormComponent, {
            data: data,
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.clientRef.afterClosed().subscribe((result) => {
            if (result && result['newClient']) {
                const newClient = new Endpoint(result['newClient']);
                this.selectedEndpoints.push(newClient.id);
                this.newlyAddedClientIds.push(newClient.id);
                this.endpointsAdded.push(newClient.id);
                this.allClients.push(newClient);
                this.refresh();
            }
            if (result && result['newClients']) {
                const newClients = result['newClients'];
                for (const newClientTemp of newClients) {
                    const newClient = new Endpoint(newClientTemp);
                    this.selectedEndpoints.push(newClient.id);
                    this.newlyAddedClientIds.push(newClient.id);
                    this.endpointsAdded.push(newClient.id);
                    this.allClients.push(newClient);
                }
                this.refresh();
            }
        });
    }

    addGateway() {
        const data = {
            inline: true,
            isAvwSitePicker: false,
            hideHaUpgrade: true,
        };
        this.gatewaysRef = this.dialogForm.open(GatewayformComponent, {
            data: data,
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.gatewaysRef.afterClosed().subscribe((result) => {
            if (result && result['newGateway']) {
                const newGateway = new Endpoint(result['newGateway']);
                this.selectedEndpoints.push(newGateway.id);
                this.newlyAddedGatewayIds.push(newGateway.id);
                this.endpointsAdded.push(newGateway.id);
                this.allGateways.push(newGateway);
                this.refresh();
            }
        });
    }

    refresh() {
        this.refresher.disableRefresh();
        let isRefreshing = false;
        if (this.authorizationService.canListEndpoints()) {
            if (this.newlyAddedGatewayIds.length > 0) {
                this.gatewayService.get();
                this.gatewaysLoading = true;
                isRefreshing = true;
            }

            if (this.newlyAddedClientIds.length > 0) {
                this.clientService.get();
                this.clientsLoading = true;
                isRefreshing = true;
            }
        }

        if (this.newlyAddedServiceIds.length > 0 && this.authorizationService.canListServices()) {
            this.serviceService.get();
            this.servicesLoading = true;
            isRefreshing = true;
        }

        if (isRefreshing) {
            this.refresher.refreshTimerId = setTimeout(() => {
                this.refresh();
            }, this.refresher.refreshInterval);
        }
    }

    // function for cloning an existing appwan
    clone() {
        this.isCloning = true;
        this.isEditing = false;
        this.isAdding = true;
        this.updateCanModify();
        // setting the name placeholder
        this.namePlaceholder = this.model.name;

        // creating a new appwan model
        this.model = new AppWan({});

        // setting endpointsAdded based on selectedEndpoints
        this.endpointsAdded = this.selectedEndpoints.concat([]);

        // settting the groupsAdded based on selectedGroups
        this.groupsAdded = this.selectedGroups.concat([]);

        // setting servicesAdded based on selectedServices
        this.servicesAdded = this.selectedServices.concat([]);
        this.step = 1;
    }

    showAzureLauncher(gateway) {
        if (this.disableAzureStackDeploy && gateway.endpointType === 'AZSGW') {
            return false;
        } else {
            return true;
        }
    }

    showLauncher(gateway) {
        if (gateway != null) {
            return (
                !this.isGatewayRegistered(gateway) &&
                !this.IsHA(gateway) &&
                (this.IsAWS(gateway) ||
                    (this.IsAzure(gateway) &&
                        !(this.hideLaunchButton && gateway.endpointType === 'AZSGW') &&
                        this.showAzureLauncher(gateway)))
            );
        }
        return false;
    }

    isGatewayRegistered(gateway) {
        if (gateway != null) {
            return gateway['status'] > 300;
        }
        return false;
    }

    IsAzure(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }
        return gateway.endpointType.indexOf('AZ') >= 0;
    }

    IsAWS(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }
        return gateway.endpointType.indexOf('AWS') >= 0;
    }

    IsHA(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }
        return gateway['protectionGroupId'] != null;
    }

    IsGoogle(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }

        return gateway.endpointType === 'GCPCPEGW';
    }

    LaunchAzureCloudScript(gateway: Endpoint) {
        this.azureDeployService.openAzureFormationLink(gateway);
    }

    /**
     * Launch cloud formation template in a new tab
     */
    LaunchCloudScript(gateway: Endpoint) {
        if (this.authorizationService.canGetDataCenter(gateway['dataCenterId'])) {
            this.subscription.add(
                this.gatewayService
                    .getResource(this.gatewayService.getLinkedResourceUrl(gateway, 'dataCenter'))
                    .subscribe((result) => {
                        const region = result['locationCode'];
                        const link = this.cfService.getCfLink(gateway, region);
                        const win = window.open(link, '_blank');
                        win.focus();
                    })
            );
        }
    }

    editClient(item, index) {
        const data = {
            model: new Endpoint(item),
            inline: true,
        };
        /*
this.clientRef = this.dialogForm.open(ClientdashboardComponent, {
data: data,
minHeight: '100%',
minWidth: '100%',
height: '100%',
width: '100%',
});

this.clientRef.afterClosed().subscribe((result) => {
// under normal circumstances nothing is returned when the dialog is closed
//  however, if something is returned, it is because the user is being logged out while this dialog is opened
//    if this is the case, we do not want to call the refresh function as the user will be unauthenticated
if (result && result['loggingOut'] === undefined) {
if (result['newName'] != null) {
  this.finalClients[index].name = result['newName'];
}
}
});
*/
    }

    editGateway(item, index) {
        const data = {
            model: new Endpoint(item),
            inline: true,
        };
        /*
this.gatewaysRef = this.dialogForm.open(GatewaydashboardComponent, {
data: data,
minHeight: '100%',
minWidth: '100%',
height: '100%',
width: '100%',
});
this.gatewaysRef.afterClosed().subscribe((result) => {
// under normal circumstances nothing is returned when the dialog is closed
//  however, if something is returned, it is because the user is being logged out while this dialog is opened
//    if this is the case, we do not want to call the refresh function as the user will be unauthenticated
if (result && result['loggingOut'] === undefined) {
if (result['newName'] != null) {
  this.finalGateways[index].name = result['newName'];
}
}
});
*/
    }

    editService(item, index) {
        const data = {
            model: item,
            inline: true,
        };
        this.serviceRef = this.dialogForm.open(ServicesformComponent, {
            data: data,
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.serviceRef.afterClosed().subscribe((res) => {
            if (res && res['loggingOut'] === undefined) {
                if (res['updatedService'] != null) {
                    this.finalServices[index] = new Service(res['updatedService']);
                }
            }
        });
    }

    editGroups(item, index) {
        const data = {
            model: item,
            inline: true,
        };

        this.groupRef = this.dialogForm.open(GroupsformComponent, {
            data: data,
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.groupRef.afterClosed().subscribe((result) => {
            if (result && result['newGroup']) {
                this.finalGroups[index] = new Group(result['newGroup']);
            }
        });
    }

    edit() {
        this.isEditing = true;
        const data = {
            model: this.model,
            clone: false,
        };
        this.step = 1;
    }

    async waitForAppwan() {
        let isProvisioned = false;
        for (let i = 0; i < 10; i++) {
            // check the status on a timer
            const result = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(this.checkAppwanStatus());
                }, 1000);
            });
            if (result === 300) {
                isProvisioned = true;
                break;
            }
        }
        return isProvisioned;
    }

    async checkAppwanStatus() {
        return await this.apiService
            .get(this.model.getSelfLink())
            .pipe(take(1))
            .toPromise()
            .then((appwan) => appwan['status']);
    }

    SendRegistrationInline(client: Endpoint, index) {
        const endpoint = new Endpoint(client);
        // Send the registration email
        const emails = this.sendto[index];

        if (this.validateEmails(emails)) {
            this.sendingState[index] = 'sending';

            const payload = {
                toList: emails.split(';').join(','),
                ccList: '',
                subject: 'NetFoundry - Registration Information',
                from: 'no-reply@netfoundry.io',
                replacementParams: {
                    ENV: '',
                    EMAIL: localStorage.getItem('profile_email'),
                    USER: localStorage.getItem('profile_nick'),
                },
            };

            this.clientService.share(endpoint, payload).subscribe((res) => {
                this.sendingState[index] = 'sent';
                this.sendto[index] = '';
                setTimeout(() => {
                    this.sendingState[index] = '';
                }, 3000);
            });
        }
    }

    validateEmails(emails) {
        this.errorEmails = false;

        if (!this.validateService.hasValue(emails)) {
            this.errorEmails = true;
        }

        if (!this.validateService.isValidEmailList(emails)) {
            this.errorEmails = true;
        }

        return !this.errorEmails;
    }

    private updateCanCreate() {
        this.canCreate = this.authorizationService.canCreateAppWans();
    }

    private updateCanModify() {
        let modelId;

        if (this.model.id != null) {
            modelId = this.model.id;
        }
        this.canModify = this.isAdding || this.isCloning || this.authorizationService.canUpdateAppWan(modelId);
    }

    private async _getPreSelectedItems() {
        const proms = [];
        if (this.authorizationService.canListEndpoints()) {
            const endpointsProm = this.service
                .getLinkedResources(this.model, 'endpoints')
                .toPromise()
                .then((res) => {
                    this.model.endpoints = [];
                    for (const endpoint of res) {
                        const ep = new Endpoint(endpoint);
                        this.selectedEndpoints.push(ep.getId());
                        this.preSelectedEndpointIds.push(ep.getId());

                        // let the picker Feature know which items are selected
                        if (ep.endpointType.match('CL')) {
                            this.availableClientsPicker.itemsSelected.push(ep);
                            this.availableClientsPicker.itemIdsSelected.push(ep.getId());
                        } else {
                            this.availableGatewaysPicker.itemsSelected.push(ep);
                            this.availableGatewaysPicker.itemIdsSelected.push(ep.getId());
                        }
                    }
                    // if cloning is set to true, update the endpointsAdded list
                    if (this.isCloning) {
                        this.endpointsAdded = this.selectedEndpoints.concat([]);
                    }
                });
            proms.push(endpointsProm);
        }

        if (this.authorizationService.canListServices()) {
            const servicesProm = this.service
                .getLinkedResources(this.model, 'services')
                .toPromise()
                .then((res) => {
                    this.model.services = [];
                    for (const service of res) {
                        const svc = new Service(service);
                        this.selectedServices.push(svc.getId());
                        this.availableServicesPicker.itemsSelected.push(svc);
                        this.availableServicesPicker.itemIdsSelected.push(svc.getId());
                        this.preSelectedServiceIds.push(svc.getId());
                    }
                    // if cloning is set to true, update the servicesAdded list
                    if (this.isCloning) {
                        this.servicesAdded = this.selectedServices.concat([]);
                    }
                });

            proms.push(servicesProm);
        }

        if (this.authorizationService.canListEndpointGroups()) {
            const groupsProm = this.service
                .getLinkedResources(this.model, 'endpointGroups')
                .toPromise()
                .then((res) => {
                    this.model.groups = [];
                    for (const group of res) {
                        const gp = new Group(group);
                        this.model.groups.push(gp);
                        this.selectedGroups.push(gp.getId());
                        this.availableGroupsPicker.itemsSelected.push(gp);
                        this.availableGroupsPicker.itemIdsSelected.push(gp.getId());
                        this.preSelectedGroupIds.push(gp.getId());
                    }
                    // if cloning is set to true, update the groupsAdded list
                    if (this.isCloning) {
                        this.groupsAdded = this.selectedGroups.concat([]);
                    }
                });

            proms.push(groupsProm);
        }

        await Promise.all(proms);
    }

    // function for checking if there are any ziti conflicts
    private checkZitiConflicts() {
        // getting all the clients the user selected that are of type ZTCL
        this.getZitiClients();

        // getting all services the user is adding of type GW or ICMP
        this.getNetworkAndIcmpServices();

        const zitiAndNonZitiGateways = this.getZitiAndNonGateways();
        const zitiGateways = zitiAndNonZitiGateways.zitiGateways;
        const nonZitiGateways = zitiAndNonZitiGateways.nonZitiGateways;
        // if there is at least one ziti client and at least one IP Network or ICMP service
        if (this.zitiClients.length > 0) {
            if (this.networkAndIcmpServices.length > 0 || nonZitiGateways.length > 0) {
                // open up a warning dialog to inform the user that ziti clients won't be able to communicate with IP Network or ICMP services
                this.zitiDialogRef = this.dialogForm.open(WarningListComponent, {
                    data: {
                        isZitiWarning: true,
                        services: this.networkAndIcmpServices,
                        clients: this.zitiClients,
                        gateways: nonZitiGateways,
                        noZitiGateways: zitiGateways.length === 0,
                    },
                    height: '340px',
                    width: '700px',
                    autoFocus: false,
                });

                // wait for the dialog to close
                this.zitiDialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        // start the save process
                        this.save();
                    }
                });
            } else if (zitiGateways.length === 0) {
                this.openZitiNoGatewayWarning();
            } else {
                this.save();
            }
        } else {
            // if there is no message to display, start the save process
            this.save();
        }
    }

    // function for getting all ziti clients
    private getZitiClients() {
        // clearing the list of any previous clients
        this.zitiClients = [];
        // looping through all the selected clients
        for (const clientId of this.selectedEndpoints) {
            const client = this.endpointMap[clientId];
            // pushing any clients of type ZTCL to the list of ziti clients
            if (client.endpointType === 'ZTCL') {
                this.zitiClients.push(client);
            }
        }
    }

    private getZitiAndNonGateways() {
        const zitiGateways = [];
        const nonZitiGateways = [];
        for (const gatewayId of this.selectedEndpoints) {
            const gateway = this.endpointMap[gatewayId];
            if (gateway.endpointType === 'ZTGW' || gateway.endpointType === 'ZTNHGW') {
                zitiGateways.push(gateway);
            } else if (gateway.endpointType !== 'CL' && gateway.endpointType !== 'ZTCL') {
                nonZitiGateways.push(gateway);
            }
        }

        return { zitiGateways: zitiGateways, nonZitiGateways };
    }

    private getAllZitiGateways() {
        const zitiGateways = [];
        for (const gateway of this.availableGateways) {
            if (gateway.endpointType === 'ZTGW' || gateway.endpointType === 'ZTNHGW') {
                zitiGateways.push(gateway);
            }
        }
        return zitiGateways;
    }

    // function for getting the IP Network and ICMP services the user is adding
    private getNetworkAndIcmpServices() {
        // clearing the list of any previous services
        this.networkAndIcmpServices = [];
        // looping through the list of services
        for (const serviceId of this.selectedServices) {
            const service = this.serviceMap[serviceId];
            // if the service is an IP Network or ICMP service, add it to the list
            if (service.serviceClass === 'GW' || service.serviceClass === 'ICMP') {
                this.networkAndIcmpServices.push(service);
            }
        }
    }

    private reset_lists() {
        // Assignment lists, use IDs instead of objects
        this.selectedEndpoints = [];
        this.selectedGroups = [];
        this.selectedServices = [];
        this.endpointsAdded = [];
        this.endpointsRemoved = [];
        this.servicesAdded = [];
        this.servicesRemoved = [];
        this.groupsAdded = [];
        this.groupsRemoved = [];
        this.sendingState = [];
        this.sendto = [];
    }

    // function for getting the status
    private async getStatus(selfLink, previousAppwanStatusWasActive) {
        // whether or not the loop is finished
        let isFinished = false;
        // whether or not the appwan is active
        let isActive = false;

        // max number of iterations
        const maxIterations = 5;
        // current iteration
        let currentIteration = 0;

        const timeout = 1000;

        // if the appwan was previously in an active state, try checking again
        if (previousAppwanStatusWasActive) {
            // looping until the appwan either becomes active or the max iterations is hit
            while (!isFinished && currentIteration < maxIterations) {
                currentIteration++;
                // creating a new promise
                const promise = new Promise((resolve, reject) => {
                    // putting a 1s delay before calling getResource
                    setTimeout(() => {
                        // getting the AppWan
                        this.service.getResource(selfLink).subscribe(
                            (result) => {
                                // resolving the promise with the status
                                resolve(result['status']);
                            },
                            (error) => {
                                // if there was an error, reject the promise with a 500 (error) status
                                resolve(500);
                            }
                        );
                    }, timeout);
                });

                // getting the status after the promise is resolved
                const status = await promise;
                // if the status is 300, mark isFinished as true and isActive as true
                if (status === 300) {
                    isFinished = true;
                    isActive = true;
                    // if the status is either in error or the appwan is deleting, mark isFinished as true and isActive as false
                } else if (status === 500 || (status as number) >= 700) {
                    isFinished = true;
                    isActive = false;
                }
            }
        } else {
            // if the appwan was not active the last time we checked, mark isActive as false
            isActive = false;
        }
        if (!isActive) {
            this.appwanStatusWasActive = false;
        }
        return isActive;
    }
}
