import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Endpoint, Group } from '@netfoundry-ui/shared/model';
import {
    ClientService,
    GatewayService,
    GroupService,
    ValidateService,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

const provisionedStatus = 300;
const registeredStatus = 400;

@Component({
    selector: 'app-groupsform',
    templateUrl: './groupsform.component.html',
    styleUrls: ['./groupsform.component.scss'],
})
export class GroupsformComponent implements OnInit, OnDestroy {
    model: Group;
    isEditing = false;
    isAdding = false;
    processing = false;
    allGateways: Endpoint[] = [];
    allClients: Endpoint[] = [];
    gateways: Endpoint[] = [];
    clients: Endpoint[] = [];
    selected: Endpoint[] = [];
    allEndpoints: string[] = [];
    selectedIds = [];
    searchString = '';
    errorName = false;
    isInline = false;
    clientsLoading = false;
    gatewaysLoading = false;
    refreshFilter = false;
    canUpdate = true;
    hideHelp = false;
    @ViewChild('availableClientsPicker', { static: false })
    availableClientsPicker;
    @ViewChild('availableGatewaysPicker', { static: false })
    availableGatewaysPicker;
    private subscription = new Subscription();
    private preSelectedIds = [];
    private removedEndpoints = [];

    constructor(
        private gatewayService: GatewayService,
        private clientService: ClientService,
        private groupService: GroupService,
        private growlerService: GrowlerService,
        private validateService: ValidateService,
        public authorizationService: AuthorizationService,
        public zitiEnabledService: ZitiEnabledService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<GroupsformComponent>
    ) {
        if (data.model !== undefined) {
            this.model = data.model;
            this.isAdding = false;
            this.isEditing = true;
            this.model.endpoints = [];
            if (this.authorizationService.canListEndpoints()) {
                this.subscription.add(
                    this.groupService.getLinkedResources(this.model, 'endpoints').subscribe((data) => {
                        for (let endpoint of data) {
                            endpoint = new Endpoint(endpoint);
                            this.model.endpoints.push(endpoint);
                            this.preSelectedIds.push(endpoint.getId());
                        }
                        this.setupEdit();
                    })
                );
            } else {
                this.setupEdit();
            }
        } else {
            this.model = new Group({});
            this.isAdding = true;
            this.isEditing = false;
        }
        if (data.inline) {
            this.isInline = data.inline;
        }
    }

    ngOnInit() {
        if (this.isEditing && this.authorizationService.canUpdateEndpointGroup(this.model.id)) {
            this.canUpdate = true;
        } else {
            this.canUpdate = false;
        }
        this.processing = false;
        this.errorName = false;
        if (this.authorizationService.canListEndpoints()) {
            this.gatewaysLoading = true;
            this.subscription.add(
                this.gatewayService.get().subscribe((result) => {
                    this.gateways = [];
                    this.allGateways = [];
                    for (const gateway of result) {
                        if (
                            (gateway.status === provisionedStatus || gateway.status === registeredStatus) &&
                            gateway.endpointProtectionRole == null
                        ) {
                            this.gateways.push(new Endpoint(gateway));
                            this.allGateways.push(new Endpoint(gateway));
                        }
                    }
                    this.gatewaysLoading = false;
                    this.setupEdit();
                })
            );
            this.clientsLoading = true;
            this.subscription.add(
                this.clientService.get().subscribe((result) => {
                    this.clients = [];
                    this.allClients = [];
                    for (const client of result) {
                        // excluding ZITI clients since they do not work in groups at the moment
                        if (client.status === provisionedStatus || client.status === registeredStatus) {
                            if (client['source'] !== 'IMPORT') {
                                this.clients.push(new Endpoint(client));
                                this.allClients.push(new Endpoint(client));
                            }
                        }
                    }
                    this.clientsLoading = false;
                    this.setupEdit();
                })
            );
        } else {
            this.setupEdit();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.hide();
        }
    }

    moveTo(endpoint: Endpoint) {
        if (this.isAdding || this.canUpdate) {
            this.refreshFilter = !this.refreshFilter;
            this.selected.push(endpoint);
            this.selectedIds.push(endpoint.id);
            if (endpoint.endpointType.includes('CL')) {
                const index = this.clients.indexOf(endpoint);
                // need to check if the index is -1 here because on the first time the edit window is opened it returns -1 for the index
                if (index > -1) {
                    const result = this.clients.splice(index.valueOf(), 1);
                }
            } else {
                // need to check if the index is -1 here because on the first time the edit window is opened it returns -1 for the index
                const index = this.gateways.indexOf(endpoint);
                if (index > -1) {
                    const result = this.gateways.splice(index, 1);
                }
            }
        }
    }

    moveFrom(endpoint: Endpoint) {
        if (this.isAdding || this.canUpdate) {
            this.refreshFilter = !this.refreshFilter;
            this.searchString = this.searchString + '';
            if (endpoint.endpointType.includes('CL')) {
                this.availableClientsPicker.removeFromSelected(endpoint);
            } else {
                this.availableGatewaysPicker.removeFromSelected(endpoint);
            }
            this.selected.splice(this.selected.indexOf(endpoint), 1);
            this.selectedIds.splice(this.selected.indexOf(endpoint.getId()), 1);

            if (
                this.preSelectedIds.indexOf(endpoint.getId()) > -1 &&
                this.removedEndpoints.indexOf(endpoint.getId()) === -1
            ) {
                this.removedEndpoints.push(endpoint.getId());
            }
        }
    }

    setupEdit() {
        this.selected = [];
        for (let i = 0; i < this.model.endpoints.length; i++) {
            this.selected.push(this.model.endpoints[i]);
            this.selectedIds.push(this.model.endpoints[i].getId());
            this.preSelectedIds.push(this.model.endpoints[i].getId());
            // let the picker Feature know which items are selected
        }
        this.testSelected();
    }

    addAll() {
        if (this.isAdding || this.canUpdate) {
            this.refreshFilter = !this.refreshFilter;
            this.selected = [];
            this.selectedIds = [];
            this.clients = [];
            this.gateways = [];
            for (const client of this.allClients) {
                this.selected.push(client);
                this.selectedIds.push(client.getId());
            }
            for (const gateway of this.allGateways) {
                this.selected.push(gateway);
                this.selectedIds.push(gateway.getId());
            }
        }
    }

    removeAll() {
        if (this.isAdding || this.canUpdate) {
            this.refreshFilter = !this.refreshFilter;
            for (const client of this.allClients) {
                if (this.selectedIds.indexOf(client.id) > -1) {
                    this.clients.push(client);
                }
                if (
                    this.preSelectedIds.indexOf(client.getId()) > -1 &&
                    this.removedEndpoints.indexOf(client.getId()) === -1
                ) {
                    this.removedEndpoints.push(client.getId());
                }
            }

            for (const gateway of this.allGateways) {
                if (this.selectedIds.indexOf(gateway.id) > -1) {
                    this.gateways.push(gateway);
                }

                if (
                    this.preSelectedIds.indexOf(gateway.getId()) > -1 &&
                    this.removedEndpoints.indexOf(gateway.getId()) === -1
                ) {
                    this.removedEndpoints.push(gateway.getId());
                }
            }

            this.selectedIds = [];
            this.selected = [];
        }
        this.availableGatewaysPicker.triggerRefresh();
        this.availableClientsPicker.triggerRefresh();
    }

    isSelected(endpoint) {
        for (let i = 0; i < this.selected.length; i++) {
            if (this.selected[i].getId() === endpoint.getId()) {
                return true;
            }
        }
        return false;
    }

    testSelected() {
        this.clients = [];
        this.gateways = [];
        for (let i = 0; i < this.allClients.length; i++) {
            if (!this.isSelected(this.allClients[i])) {
                this.clients.push(this.allClients[i]);
            }
        }
        for (let i = 0; i < this.allGateways.length; i++) {
            if (!this.isSelected(this.allGateways[i])) {
                this.gateways.push(this.allGateways[i]);
            }
        }
    }

    hide() {
        this.dialogRef.close();
    }

    clearFilter() {
        this.searchString = '';
    }

    save() {
        if (this.validate() && (this.isAdding || this.canUpdate)) {
            this.processing = true;

            this.subscription.add(
                this.groupService.save(this.model).subscribe(
                    (result) => {
                        // TODO: Check results for api errors
                        this.model = new Group(result);
                        this.model.endpoints = this.selected;

                        this.subscription.add(
                            this.groupService.removeEndpoints(this.model, this.removedEndpoints).subscribe(
                                () => {
                                    const ids = [];
                                    for (let i = 0; i < this.model.endpoints.length; i++) {
                                        ids.push(this.model.endpoints[i].getId());
                                    }
                                    this.subscription.add(
                                        this.groupService.addEndpoints(this.model, ids).subscribe(
                                            () => {
                                                this.growlerService.show(
                                                    new GrowlerData(
                                                        'success',
                                                        'Success',
                                                        'Save Complete',
                                                        'Group information has been saved.'
                                                    )
                                                );
                                                this.dialogRef.close({ newGroup: this.model });
                                                this.processing = false;
                                            },
                                            (error) => {
                                                this.processing = false;
                                            }
                                        )
                                    );
                                },
                                (error) => {
                                    this.processing = false;
                                }
                            )
                        );
                    },
                    (error) => {
                        this.processing = false;
                    }
                )
            );
        }
    }

    validate(): boolean {
        this.errorName = false;
        if (!this.validateService.isValidName(this.model.name)) {
            this.errorName = true;
        }
        return !this.errorName;
    }

    getType(type: string) {
        if (type.includes('CL')) {
            return 'CL';
        } else {
            return 'GW';
        }
    }
}
