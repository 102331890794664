import { Injectable } from '@angular/core';
import { AzureSubscription, MopResource, Network, NetworkGroup } from '@netfoundry-ui/shared/model';
import { of as observableOf, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';

@Injectable({ providedIn: 'root' })
export abstract class MopResourceService {
    currentNetworkModel: Network = new Network({});
    currentAzureSubscriptionModel: AzureSubscription = new AzureSubscription({});
    currentOrgModel: NetworkGroup = new NetworkGroup({});

    /**
     * Even though these also exist in the API service, routed Feature may need to use a disposable instance of this
     * service, therefore these may need to be used change triggers
     */
    currentNetworkSource = new Subject<Network>();
    currentNetwork = this.currentNetworkSource.asObservable();
    currentAzureSubscriptionSource = new Subject<AzureSubscription>();
    currentAzureSubscription = this.currentAzureSubscriptionSource.asObservable();
    currentOrgSource = new Subject<NetworkGroup>();
    currentOrg = this.currentOrgSource.asObservable();

    constructor(protected logger: LoggerService, protected apiService: ApiService) {
        this.apiService.currentNetwork.subscribe((network) => {
            this.currentNetworkModel = new Network(network);
            this.currentNetworkSource.next(network);
        });

        this.apiService.currentOrg.subscribe((org) => {
            this.currentOrgModel = new NetworkGroup(org);
            this.currentOrgSource.next(org);
        });

        this.apiService.currentAzureSubscription.subscribe((sub) => {
            this.currentAzureSubscriptionModel = new AzureSubscription(sub);
            this.currentAzureSubscriptionSource.next(sub);
        });
    }

    /**
     * Gets the self link
     */
    public getSelfLink(resource) {
        return resource._links.self.href;
    }

    /**
     * Extracts the resource ID
     */
    public getResourceId(resource) {
        if (resource._links.self.href != null) {
            return resource._links.self.href.split('/').pop();
        } else {
            return null;
        }
    }

    /**
     * Gets resource by the self link
     */
    public getResource(resourceLink) {
        this.logger.info('GET RESOURCE: ' + resourceLink);

        return this.apiService.get(resourceLink);
    }

    /**
     * Gets resource by the link name
     */
    public getLinkedResources(model, resourceName) {
        if (model._links[resourceName] !== undefined) {
            const resourceLink = model._links[resourceName]['href'];

            // get the linked resources, but extract the _embedded element and resource name from the response
            return this.getResource(resourceLink).pipe(
                map((data) => {
                    let resources = [];

                    if (
                        data['_embedded'] &&
                        data['_embedded'][resourceName] &&
                        data['_embedded'][resourceName].length > 0
                    ) {
                        resources = data['_embedded'][resourceName];
                    }

                    return resources;
                })
            );
        }

        // We've asked for something that that API does not support
        this.logger.error('No resource name of ' + resourceName + ' exists for this model', model);

        return observableOf([]);
    }

    /**
     * Get the selflink for a related resource
     */
    public getLinkedResourceUrl(model, resourceName) {
        if (model._links[resourceName] !== undefined) {
            return model._links[resourceName]['href'];
        }

        this.logger.error('No resource name of ' + resourceName + ' exists for this model', model);
        return null;
    }

    /**
     * Deletes resources against the self link and returns observable result
     */
    public delete(resourceModel: MopResource) {
        return this.apiService.delete(resourceModel.getSelfLink(), false);
    }

    /**
     * Saves the mop resource and returns result as observable
     */
    public save(resourceModel: MopResource) {
        if (resourceModel.getSelfLink()) {
            // edit
            return this.apiService.put(resourceModel.getSelfLink(), resourceModel.getSaveObject());
        } else {
            // add
            return this.apiService.post(this._getCreateUrl(), resourceModel.getSaveObject());
        }
    }

    /**
     * Get MOP resource status codes
     */
    public getStatusCodes() {
        const codes = {
            '100': 'NEW',
            '200': 'PROVISIONING',
            '300': 'PROVISIONED',
            '400': 'REGISTERED',
            '500': 'ERROR',
            '600': 'UPDATING',
            '800': 'DELETING',
            '900': 'DELETED',
        };

        return codes;
    }

    /**
     * Get the string value of a Mop status code
     */
    public getStatusByCode(code) {
        return this.getStatusCodes()[code];
    }

    protected abstract _getCreateUrl();

    protected abstract _extractEmbedded(res);

    /**
     * Add resource relationships to another resource
     */
    protected addRelationships(type: string, model: MopResource, list: string[]) {
        const body = { ids: list };

        const url = this.getLinkedResourceUrl(model, type);

        // @FIXME need validation that the named resource exists

        return this.apiService.post(url, body);
    }

    /**
     * Remove resource relationships from another resource
     */
    protected removeRelationships(type: string, model, list: string[]) {
        const body = { ids: list };
        const url = this.getLinkedResourceUrl(model, type);
        return this.apiService.delete(url, body);
    }
}
