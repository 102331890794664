import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { FeatureNfSelect2Module } from '@netfoundry-ui/feature/nf-select2';
import { FormsModule } from '@angular/forms';
import { OrganizationPromptComponent } from './organization-prompt.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatDialogModule,
        HttpClientModule,
        SharedModelModule,
        UiLoaderModule,
        FeatureNfSelect2Module,
    ],
    declarations: [OrganizationPromptComponent],
    exports: [OrganizationPromptComponent],
})
export class FeatureFormOrganizationPromptModule {}
