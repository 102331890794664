import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-rename-form',
    templateUrl: './rename-form.component.html',
    styleUrls: ['./rename-form.component.scss'],
})
export class RenameFormComponent {
    action = 'Rename';
    cancelAction = 'Oops, no get me out of here';
    name = '';
    newName = '';

    constructor(
        private logger: LoggerService,
        public self: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.name = data.name;
    }

    cancel(): void {
        this.self.close();
    }

    rename(): void {
        this.self.close(this.newName);
    }
}
