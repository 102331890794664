import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    Attribute,
    Environment,
    ENVIRONMENT,
    NetworkV2,
    PagedAttributes,
    SimpleAttribute,
} from '@netfoundry-ui/shared/model';
import _ from 'lodash';
import { HateoasResourceOperation, PagedGetOption } from '@lagoshny/ngx-hateoas-client';
import { HTTP_CLIENT, LoggerService, RefresherService, ValidateService } from '@netfoundry-ui/shared/services';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { EndpointServiceV2 } from './endpoint.service';
import { PlatformServiceService } from './platform-service.service';

export class AttributesServiceResults {
    namedAttributes: PagedAttributes = new PagedAttributes();
    groupAttributes: PagedAttributes = new PagedAttributes();
}

@Injectable({ providedIn: 'root' })
export class AttributesService extends HateoasResourceOperation<Attribute> {
    static defaultHttpAccept = { headers: { accept: 'application/hal+json' } };
    static defaultPaginationSize = 500;

    constructor(
        private logger: LoggerService,
        private auth: AuthorizationService,
        private endpointSvc: EndpointServiceV2,
        private platformSvc: PlatformServiceService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: Environment,
        private refresher: RefresherService,
        private validateService: ValidateService
    ) {
        super(Attribute);
    }

    public findAttributes(networkId: string, type: string, attribute?: string, options: PagedGetOption = {}): any {
        const attrOpts = this.getAttributeOptions(networkId, type, attribute);
        const combinedParams = { ...attrOpts.params, ...options.params };
        const params = {
            ...AttributesService.defaultHttpAccept,
            ...attrOpts,
            ...options,
            params: combinedParams,
        };
        return this.getPage(params)
            .toPromise()
            .then((results) => results?.resources);
    }

    public findDistinctAttributes(
        networkId: string,
        type: string,
        attribute?: string,
        options: PagedGetOption = {}
    ): Promise<SimpleAttribute[]> {
        const attrOpts = this.getAttributeOptions(networkId, type, attribute);
        const combinedParams = { ...attrOpts.params, ...options.params };
        const params = {
            ...AttributesService.defaultHttpAccept,
            ...attrOpts,
            ...options,
            params: combinedParams,
        };
        return this.getPage(params)
            .toPromise()
            .then((results) => {
                const mappedResults = results.resources.map((r) => ({
                    name: r.attribute,
                    attributeType: type,
                    isGroup: true,
                }));
                //changed this so we were looking at a deep comparison vs shallow comparison ie contents of obj a = contents of obj b
                const distinctResults = _.uniqWith(mappedResults, _.isEqual);
                return distinctResults;
            });
    }

    async getNamedAttributes(
        network: NetworkV2,
        entityType: string,
        includeProps = '',
        paginate = false,
        page = 0
    ): Promise<PagedAttributes> {
        const attrFields = includeProps.split('|');
        const size = paginate ? AttributesService.defaultPaginationSize : 1;
        const options: PagedGetOption = {
            pageParams: { size, page },
            sort: { name: 'ASC' },
            headers: { accept: 'application/hal+json' },
        };
        const finalResults = new PagedAttributes();
        return network.findRelatedPage(entityType, options, includeProps).then((results) => {
            results.resources.forEach((att) => {
                this.parseNamedAttributes(attrFields, att, finalResults);
            });
            finalResults.totalElements = results.totalElements;
            finalResults.totalPages = results.totalPages;
            finalResults.pageNumber = results.pageNumber;
            finalResults.pageSize = results.pageSize;
            if (!paginate && results.totalElements > 1) {
                const promises = [];
                let p = 0;
                const totalPages = Math.ceil(finalResults.totalElements / AttributesService.defaultPaginationSize);
                options.pageParams.size = AttributesService.defaultPaginationSize;
                while (p < totalPages) {
                    options.pageParams.page = p++;
                    promises.push(network.findRelatedPage(entityType, options, includeProps));
                }
                return Promise.all(promises).then((promiseResults) => {
                    promiseResults.forEach((r) => {
                        r.resources.forEach((att) => {
                            this.parseNamedAttributes(attrFields, att, finalResults);
                        });
                    });
                    return finalResults;
                });
            } else {
                return finalResults;
            }
        });
    }

    async getGroupAttributes(
        network: NetworkV2,
        entityType: string,
        includeProps = 'attribute',
        paginate = false,
        page = 0
    ): Promise<PagedAttributes> {
        const attrFields = includeProps.split('|');
        const size = paginate ? AttributesService.defaultPaginationSize : 1;
        const options: PagedGetOption = {
            pageParams: { size, page },
            params: {
                networkId: network.id,
                attributeType: entityType,
                beta: true,
            },
            headers: { accept: `application/hal+json;includeProperties=${includeProps}` },
        };

        const finalResults = new PagedAttributes();
        return this.getPage(options)
            .toPromise()
            .then((results) => {
                results.resources.forEach((att) => {
                    if (att && att.attribute.charAt(0) !== '@') {
                        att.attributeType = 'entityType';
                        this.parseGroupAttributes(attrFields, att, finalResults);
                    }
                });
                finalResults.totalElements = results.totalElements;
                finalResults.totalPages = results.totalPages;
                finalResults.pageNumber = results.pageNumber;
                finalResults.pageSize = results.pageSize;
                if (!paginate && results.totalElements > 1) {
                    const promises = [];
                    let p = 0;
                    const totalPages = Math.ceil(finalResults.totalElements / AttributesService.defaultPaginationSize);
                    options.pageParams.size = AttributesService.defaultPaginationSize;
                    while (p < totalPages) {
                        options.pageParams.page = p++;
                        promises.push(this.getPage(options).toPromise());
                    }
                    return Promise.all(promises).then((promiseResults) => {
                        promiseResults.forEach((r) => {
                            r.resources.forEach((att) => {
                                if (att && att.attribute.charAt(0) !== '@') {
                                    this.parseGroupAttributes(attrFields, att, finalResults);
                                }
                            });
                        });
                        return finalResults;
                    });
                } else {
                    return finalResults;
                }
            });
    }

    getEndpointAttributes(network: NetworkV2, skipNamed = false): Promise<AttributesServiceResults> {
        let endNamed;
        if (!skipNamed) {
            endNamed = this.getNamedAttributes(network, 'endpoints', 'id|name');
        } else endNamed = Promise.resolve();
        const endGroup = this.getGroupAttributes(network, 'endpoint', 'id|attribute');

        return Promise.all([endNamed, endGroup])
            .then((results) => {
                const finalResults = new AttributesServiceResults();
                if (results[0] !== undefined) {
                    finalResults.namedAttributes = results[0] as PagedAttributes;
                }
                if (results[1] !== undefined) {
                    for (const sa of (results[1] as PagedAttributes).mappedAtrributes.values()) {
                        finalResults.groupAttributes.mappedAtrributes.set(sa.name, sa);
                    }
                }
                return finalResults;
            })
            .catch((err) => this.logger.error(err)) as Promise<AttributesServiceResults>;
    }

    getServiceAttributes(network: NetworkV2, skipNamed = false): Promise<AttributesServiceResults> {
        let svcNamed;
        if (!skipNamed) {
            svcNamed = this.getNamedAttributes(network, 'services', 'id|name');
        } else svcNamed = Promise.resolve();
        const svcGroup = this.getGroupAttributes(network, 'service', 'id|attribute');

        return Promise.all([svcNamed, svcGroup])
            .then((results) => {
                const finalResults = new AttributesServiceResults();
                if (results[0] !== undefined) {
                    finalResults.namedAttributes = results[0] as PagedAttributes;
                }
                if (results[1] !== undefined) {
                    for (const sa of (results[1] as PagedAttributes).mappedAtrributes.values()) {
                        finalResults.groupAttributes.mappedAtrributes.set(sa.name, sa);
                    }
                }

                return finalResults;
            })
            .catch((err) => this.logger.error(err)) as Promise<AttributesServiceResults>;
    }

    getEdgeRouterAttributes(network: NetworkV2, skipNamed = false): Promise<AttributesServiceResults> {
        let erNamed;
        if (!skipNamed) {
            erNamed = this.getNamedAttributes(network, 'edge-routers', 'id|name|tunnelerEnabled|status|edgeRouterName');
        } else erNamed = Promise.resolve();
        const erGroup = this.getGroupAttributes(network, 'edge-router', 'id|attribute');

        return Promise.all([erNamed, erGroup])
            .then((results) => {
                const finalResults = new AttributesServiceResults();
                if (results[0] !== undefined) {
                    finalResults.namedAttributes = results[0] as PagedAttributes;
                }
                if (results[1] !== undefined) {
                    for (const sa of (results[1] as PagedAttributes).mappedAtrributes.values()) {
                        finalResults.groupAttributes.mappedAtrributes.set(sa.name, sa);
                    }
                }
                return finalResults;
            })
            .catch((err) => this.logger.error(err)) as Promise<AttributesServiceResults>;
    }

    getPostureCheckAttributes(network: NetworkV2, skipNamed = false): Promise<AttributesServiceResults> {
        let pcNamed;
        if (!skipNamed) {
            pcNamed = this.getNamedAttributes(network, 'posture-checks', 'id|name|type');
        } else pcNamed = Promise.resolve();
        const pcGroup = this.getGroupAttributes(network, 'posture-check', 'id|attribute');

        return Promise.all([pcNamed, pcGroup])
            .then((results) => {
                const finalResults = new AttributesServiceResults();
                if (results[0] !== undefined) {
                    finalResults.namedAttributes = results[0] as PagedAttributes;
                }
                if (results[1] !== undefined) {
                    for (const sa of (results[1] as PagedAttributes).mappedAtrributes.values()) {
                        finalResults.groupAttributes.mappedAtrributes.set(sa.name, sa);
                    }
                }
                return finalResults;
            })
            .catch((err) => this.logger.error(err)) as Promise<AttributesServiceResults>;
    }

    nameSort = (item1: SimpleAttribute, item2: SimpleAttribute) => item1.name.localeCompare(item2.name);

    addAttributes(
        newAttribute: string,
        invalidWords: string[],
        selectedAttributes: PagedAttributes
    ): boolean | PagedAttributes {
        if (invalidWords.includes(newAttribute)) {
            return false;
        }

        selectedAttributes.mappedAtrributes.set(newAttribute, {
            name: newAttribute,
            isGroup: newAttribute.startsWith('#'),
            isNamed: newAttribute.startsWith('@'),
        });
        const tmpSelected = new PagedAttributes();
        tmpSelected.mappedAtrributes = selectedAttributes.mappedAtrributes;
        tmpSelected.mappedAtrributes.set(newAttribute, {
            name: newAttribute,
            isGroup: newAttribute.startsWith('#'),
            isNamed: newAttribute.startsWith('@'),
        });
        return tmpSelected;
    }

    removeAttribute(oldAttribute: string, selectedAttributes: PagedAttributes): PagedAttributes {
        const tmpSelected = new PagedAttributes();
        tmpSelected.mappedAtrributes = selectedAttributes.mappedAtrributes;
        tmpSelected.mappedAtrributes.delete(oldAttribute);
        return tmpSelected;
    }

    getByAttribute(networkId: string, entityTableName: string, attributes: string[]): Promise<any> {
        let results = [];
        if (attributes.length === 0) return Promise.resolve([]);

        let attrStr = attributes.join(',');
        if (entityTableName === 'endpoints') {
            attrStr = attrStr.replace(/#/g, '');
            return this.endpointSvc.getAllEndpointsByNetworkId(networkId, { params: { attributes: attrStr } }, 'name');
        } else if (entityTableName === 'services') {
            return this.platformSvc.getAllServicesByNetworkId(networkId, { params: { attributes: attrStr } }, 'name');
        } else {
            const url = `${this.environment.v2apiUrl}\\${entityTableName}`;
            const options = {
                headers: { accept: 'application/hal+json;includeProperties=name' },
                params: { networkId: networkId, attributes: attrStr, sort: 'name,ASC' },
                responseType: 'json' as const,
            };
            return this.http
                .get(url, options)
                .toPromise()
                .then((responseResults) => {
                    if (responseResults) results = responseResults as any;
                    return results;
                })
                .catch((err) => {
                    this.logger.error(err);
                    return [];
                });
        }
    }

    private parseNamedAttributes(attrFields: string[], att: any, finalResults: PagedAttributes) {
        const sa = new SimpleAttribute();
        sa.isNamed = true;
        attrFields.forEach((f) => {
            sa[f] = att[f];
        });
        if (sa.name.charAt(0) !== '@') sa.name = '@' + sa.name;

        finalResults.mappedAtrributes.set(sa.name, sa);
    }

    private parseGroupAttributes(attrFields: string[], att: Attribute, finalResults: PagedAttributes) {
        const sa = new SimpleAttribute();
        sa.isGroup = true;
        attrFields.forEach((f) => {
            sa[f] = att[f];
        });
        sa.name = sa['attribute'];
        delete sa['attribute'];
        finalResults.mappedAtrributes.set(att['attribute'], sa);
    }

    private getAttributeOptions(networkId: string, type: string, attribute?: string) {
        const params = {
            networkId: networkId,
            attributeType: type,
            parentType: type,
        };

        if (!_.isEmpty(attribute)) {
            params['attribute'] = type === 'service' ? attribute : _.replace(attribute, '#', '');
        }
        return { params };
    }
}
