import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-tab-creator',
    templateUrl: './tab-creator.component.html',
    styleUrls: ['./tab-creator.component.scss'],
})
export class TabCreatorComponent {
    @Input() titles = [];
    @Input() activeTitle: string;
    @Output() selectTab = new EventEmitter<any>();
}
