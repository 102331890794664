import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnChanges, Output } from '@angular/core';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { HTTP_CLIENT, LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-banner-message',
    templateUrl: './banner-message.component.html',
    styleUrls: ['./banner-message.component.scss'],
})
export class BannerMessageComponent implements OnChanges {
    // @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Input() isLoggedIn = false;
    @Input() message = '';
    @Input() isVisible = false;
    @Input() showSupportLink = false;
    @Input() buttonText = '';
    @Input() showSpinner = false;

    @Output() buttonClicked = new EventEmitter();

    constructor(
        protected logger: LoggerService,
        @Inject(ENVIRONMENT) private environment: Environment,
        @Inject(HTTP_CLIENT) private http: HttpClient
    ) {}

    close() {
        this.isVisible = false;
    }

    actionButtonClicked() {
        this.buttonClicked.emit();
    }

    ngOnChanges(): void {
        if (!this.isLoggedIn) {
            return;
        }

        const statusfile = this.environment.production === true ? 'status.json' : 'development_status.json';
        // currently there is one URL for all environments, but two file s
        const url = 'https://status.production.netfoundry.io/';
        this.http.get(url + statusfile).subscribe((res) => {
            this.logger.info('Health banner response', res);
            if (res['status'] !== undefined && res['message'] !== undefined && res['status'] !== 'healthy') {
                this.isVisible = true;
                this.message = res['message'];
            }
        });
    }
}
