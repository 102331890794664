<div [hidden]="isCancelledAccount" class="action" id="SubAction">
    <div
        (click)="doAction()"
        [ngClass]="{
            minus: isDeleteAction,
            buttonAdd: !isGroupAction,
            bannerOpen: !billingEnabledservice.hideCardWarning && billingEnabledservice.isFourteenDayTrial,
            'cSelect action shadowBig': isGroupAction
        }"
    ></div>
</div>
