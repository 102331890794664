import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedApiv2Module } from '@netfoundry-ui/shared/apiv2';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { ZitiShareComponent } from './ziti-share.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule, SharedApiv2Module, SharedModelModule],
    declarations: [ZitiShareComponent],
    exports: [ZitiShareComponent],
})
export class FeatureZitiZitiShareModule {}
