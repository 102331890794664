import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExportCsvComponent } from './export-csv.component';

@NgModule({
    declarations: [ExportCsvComponent],
    exports: [ExportCsvComponent],
    imports: [CommonModule],
})
export class FeatureExportCsvModule {}
