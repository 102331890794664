import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { LoggerService } from '@netfoundry-ui/shared/services';
import { TableSearchPipe } from '@netfoundry-ui/ui/pipes';
import _ from 'lodash';

@Component({
    selector: 'app-nf-select2',
    templateUrl: './nf-select2.component.html',
    styleUrls: ['./nf-select2.component.scss'],
})
export class NfSelect2Component implements OnChanges, OnInit {
    hideSelect = true;

    @Input() options = [];
    @Input() selected = [];
    @Input() placeholder = 'Select';
    @Input() isRoleForm = false;
    @Input() disableField = false;
    @Input() hasError = false;
    @Input() isFullWidth = false;
    @Input() selectOne = false;
    @Input() useLocalFilter = true;
    @Input() optionsTotal = 0;
    @Input() sortOrder = 'desc';
    @Output() selectionChange = new EventEmitter<any>();
    @Output() filterChange = new EventEmitter<any>();
    @Output() showMore = new EventEmitter<any>();
    @Output() selectedChange = new EventEmitter<any>();
    @Input() usingCustomSort = false;
    @Input() moreLoading = false;

    displayedOptions = [];

    selectedIds = [];

    filterString;
    test = false;
    displayTotal = 0;

    constructor(private logger: LoggerService, private tableSearchPipe: TableSearchPipe) {}

    filterChangedDebounced = () => {};

    ngOnInit(): void {
        this.filterChangedDebounced = _.debounce(this.filterChanged.bind(this), 400);
    }

    ngOnChanges() {
        for (const option of this.selected) {
            if (this.selectedIds.indexOf(option['id']) === -1) {
                this.selectedIds.push(option['id']);
            }
        }
        this.applyFilter();
    }

    isSelected(item) {
        if (this.selectedIds.indexOf(item['id']) === -1) {
            return false;
        }

        return true;
    }

    addSelected(item) {
        if (this.selectedIds.indexOf(item['id']) > -1) {
            this.logger.info('Already selected, skipping...');
            return;
        }

        if (this.selectOne) {
            this.selectedIds = [];
            this.selected = [];
        }

        this.selectedIds.push(item['id']);
        this.selected.push(item);

        this.selectionChange.emit(this.selectedIds);
    }

    remove(item) {
        let index = -1;
        for (let i = 0; i < this.selected.length - 1; i++) {
            const option = this.selected[i];

            if (option['id'] === item['id']) {
                index = i;
                break;
            }
        }

        this.selected.splice(index, 1);
        this.selectedIds.splice(this.selectedIds.indexOf(item['id']), 1);

        this.selectionChange.emit(this.selectedIds);
    }

    toggleSelect() {
        if (!this.disableField) {
            this.hideSelect = !this.hideSelect;
        }
    }

    openSelect() {
        if (!this.disableField) {
            this.hideSelect = false;
        }
    }

    hide() {
        this.hideSelect = true;
    }

    filterChanged() {
        const filterEvent = {
            filterString: this.filterString,
        };
        this.filterChange.emit(filterEvent);
        if (this.useLocalFilter) {
            this.applyFilter();
        }
    }

    applyFilter() {
        this.displayedOptions = this.tableSearchPipe.transform(this.options, this.filterString, this.buildSearchString);
    }

    showMoreOptions() {
        if (this.useLocalFilter || !this.options || this.options.length >= this.optionsTotal) {
            return;
        }
        this.showMore.emit(true);
    }

    // update the filter on mouse up event
    // this is used for the specific case where the user clears the filter via the x button
    applyFilterMouseUp() {
        setTimeout(() => {
            if (this.filterString === '') {
                this.displayedOptions = this.tableSearchPipe.transform(
                    this.options,
                    this.filterString,
                    this.buildSearchString
                );
            }
        }, 50);
    }

    inputChanged(event) {
        this.filterChangedDebounced();
    }

    buildSearchString(item: any) {
        return item.name;
    }
}
