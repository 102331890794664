import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import _ from 'lodash';
import moment from 'moment';

@Component({
    selector: 'app-table-cell-process-info',
    templateUrl: './table-cell-process-info.component.html',
    styleUrls: ['./table-cell-process-info.component.scss'],
})
export class TableCellProcessInfoComponent implements ICellRendererAngularComp, OnDestroy {
    item: any = {
        selected: false,
        id: '',
        name: '',
        actionList: [],
    };
    cellParams;
    open = false;
    rowExpanded = false;
    moment: any = moment;

    fromNow;
    duration = '--';
    startedLabel;
    finishedLabel;
    status;
    state;
    showToggle;
    fromNowRefresher;
    durationRefresher;

    agInit(cellParams: ICellRendererParams): void {
        this.cellParams = cellParams;
        this.item = _.get(cellParams, 'data', {});
        this.rowExpanded = this.item.expanded;
        this.startedLabel = this._getStartedLabel();
        this.finishedLabel = this._getFinishedLabel();
        this.status = this._getStatus();
        this.showToggle = this._showToggle();
        this._getState();
        this._getfromNow();
        this._getDuration();
    }

    refresh(cellParams: ICellRendererParams): boolean {
        this.cellParams = cellParams;
        this.item = _.get(cellParams, 'data', {});
        this.startedLabel = this._getStartedLabel();
        this.finishedLabel = this._getFinishedLabel();
        this.status = this._getStatus();
        this.showToggle = this._showToggle();
        this._getState();
        return true;
    }

    toggleRowData(event) {
        if (this.rowExpanded) {
            this.cellParams.collapseRowData(this.item);
            this.rowExpanded = false;
        } else {
            this.cellParams.expandRowData(this.item);
            this.rowExpanded = true;
        }
        this.item.expanded = this.rowExpanded;
    }

    _getStartedLabel() {
        if (!this.item) {
            return '';
        }
        return !_.isEmpty(this.item.started) ? moment(this.item.started).format('M/D/YY h:mm:ss a') : '--';
    }

    _getFinishedLabel() {
        if (!this.item) {
            return '';
        }
        return !_.isEmpty(this.item.finished) ? moment(this.item.finished).format('M/D/YY h:mm:ss a') : '--';
    }

    _getStatus() {
        if (!this.item) {
            return '';
        }
        return this.item.state === 'RUNNING'
            ? 'In Progress'
            : this.item.state === 'SUCCESS' || this.item.state === 'FINISHED'
            ? 'Complete'
            : this.item.state === 'WARNING'
            ? 'Warning'
            : 'Failed';
    }

    _getfromNow() {
        if (!this.item) {
            return '';
        }
        const date = moment(this.item.finished);
        const prefix = date.isValid() ? 'Completed' : 'Started';
        const dateVal = date.isValid() ? this.item.finished : this.item.started;
        this.fromNow = `${prefix} ${moment(dateVal).fromNow()}`;
        this.fromNowRefresher = setTimeout(this._getfromNow.bind(this), 1000);
    }

    _getDuration() {
        if (!this.item) {
            return '';
        }
        if (!moment(this.item.finished).isValid()) {
            this.duration = this.item.rowType === 'root-process' ? '' : '--';
            return '';
        }
        if (!this.item.started || !moment(this.item.started).isValid()) {
            this.durationRefresher = setTimeout(this._getDuration.bind(this), 1000);
            return '--';
        }
        const start = moment(this.item.started);
        const end = moment(this.item.finished);
        const duration = moment.duration(end.diff(start));
        let time;
        let val;
        if (duration.asHours() >= 1) {
            val = Math.round(duration.asHours());
            if (val === 1) {
                time = val + ' hour';
            } else {
                time = val + ' hours';
            }
        } else if (duration.asMinutes() >= 1) {
            val = Math.round(duration.asMinutes());
            if (val === 1) {
                time = val + ' minute';
            } else {
                time = val + ' minutes';
            }
        } else if (duration.asSeconds() >= 1) {
            val = Math.round(duration.asSeconds());
            if (val === 1) {
                time = val + ' second';
            } else {
                time = val + ' seconds';
            }
        } else {
            val = Math.round(duration.asMilliseconds());
            if (val === 1) {
                time = val + ' millisecond';
            } else {
                time = val + ' milliseconds';
            }
        }

        const preFix = this.item.rowType === 'root-process' || this.item.isRoot ? ', took ' : '';
        this.duration = preFix + time;
        this.durationRefresher = setTimeout(this._getDuration.bind(this), 1000);
    }

    _getState() {
        if (this.item.rowType === 'root-process') {
            this.state = '';
            return;
        }
        if (!this.item || !this.item.state) {
            this.state = 'State';
            return;
        }
        switch (this.item.state) {
            case 'NEW':
                this.state = 'Waiting';
                break;
            case 'STARTED':
                this.state = 'In Progress';
                break;
            case 'ERROR':
                this.state = 'Failed';
                break;
            case 'SUCCESS':
                this.state = 'Done';
                break;
            case 'WARNING':
                this.state = 'Done (warning)';
                break;
            case 'FINISHED':
                this.state = 'Done';
                break;
            default:
                this.state = this.item.state;
                break;
        }
    }

    _showToggle() {
        if (!this.item) {
            return '';
        }
        return this.item.isRoot || !this.item.parentId || _.get(this.item, 'parallelSteps', []).length > 0;
    }

    ngOnDestroy() {
        clearTimeout(this.durationRefresher);
        clearTimeout(this.fromNowRefresher);
    }
}
