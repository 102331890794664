import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureEventTimelineDashModule } from '@netfoundry-ui/feature/event-timeline-dash';
import { FeatureNetworkMetricsEventsDashModule } from '@netfoundry-ui/feature/network-metrics-events-dash';
import { MatDialogModule } from '@angular/material/dialog';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { FeatureZitiModule } from '@netfoundry-ui/feature/ziti';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { FeatureDateFiltersModule } from '@netfoundry-ui/feature/date-filters';
import { MatChipsModule } from '@angular/material/chips';
import { EventsModalComponent } from './events-modal.component';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        UiPipesModule,
        FeatureEventTimelineDashModule,
        FeatureNetworkMetricsEventsDashModule,
        FeatureZitiModule,
        UiLoaderModule,
        FeatureDateFiltersModule,
        MatChipsModule,
    ],
    declarations: [EventsModalComponent],
    exports: [EventsModalComponent],
})
export class FeatureEventsModalModule {}
