import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    ErrorHistoryService,
    HTTP_CLIENT,
    LoggerService,
    TokenService,
} from '@netfoundry-ui/shared/services';
import moment from 'moment';
import { Observable, of as observableOf, Subscription, throwError as observableThrowError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { get } from 'lodash';

const networkPricingName = 'ActiveNetwork';
const regionPricingName = 'NetworkUtilization';

const INVOICE_NOT_PAID_STATUS = 'NOT_PAID';
const INVOICE_PAYMENT_DUE_STATUS = 'PAYMENT_DUE';
const UNPAID_STATUSES = [INVOICE_NOT_PAID_STATUS, INVOICE_PAYMENT_DUE_STATUS];
const MONTHLY_SUBSCRIPTION = 'MONTHLY';

@Injectable({ providedIn: 'root' })
export class BillingService implements OnDestroy {
    apiUrl: string;
    baseUrl: string;

    networkPricing = 199.0;
    regionPricing = [{ region: null, price: 0.2, rate: 'GB' }];

    billingAccountId = '';
    subscription: Subscription = new Subscription();
    currentBillingAccount = null;

    constructor(
        @Inject(HTTP_CLIENT) private http: HttpClient,
        public logger: LoggerService,
        public growlerService: GrowlerService,
        public apiService: ApiService,
        private tokenService: TokenService,
        private errorHistoryService: ErrorHistoryService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.apiUrl = this.environment?.billingConfig?.billingUrl;
        this.baseUrl = this.environment?.authconfig?.audience;
        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.billingAccountId = org['billingAccountId'];
            })
        );
    }

    setCurrentBillingAccount(account) {
        this.currentBillingAccount = account;
    }

    /**
     * function for getting the billing organization
     * @param billingAccountId the organization (old tenant) id to get the billing for OR the id of the billing record
     */
    public getBillingAccount(billingAccountId): Observable<any> {
        const fullpath = `${this.apiUrl}accounts/${billingAccountId}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null && billingAccountId != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error, [404])));
        } else if (headers == null) {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        } else {
            // returning an empty observable right away, this prevents the console from logging out an error
            return observableOf([]);
        }
    }

    /**
     * function for getting the billing organization
     * @param billingAccountId the id of the billing record
     */
    public updateBillingAccount(billingOrg): Observable<any> {
        const fullpath = `${this.apiUrl}accounts/${billingOrg.id}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(fullpath, JSON.stringify(billingOrg), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error, [404])));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    updateBillingCard(token): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null && this.currentBillingAccount != null) {
            const fullpath = `${this.apiUrl}payments/account/${this.currentBillingAccount.id}/card`;
            const body = {
                stripeCardToken: token,
            };

            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    getCustomer() {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null && this.currentBillingAccount != null) {
            const fullpath = `${this.apiUrl}account/${this.currentBillingAccount.id}/customer`;
            const options = {
                headers: headers,
            };

            return this.http.get(fullpath, options).pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    getContractsByAccountId(accountId) {
        const fullpath = `${this.apiUrl}contracts?accountId=${accountId}&active=true`;
        return this.http.get(fullpath, { headers: this.setHeaders() });
    }

    updateStripeContract(accountId, stripeCardToken, stripeCustomerName, stripeCustomerEmail) {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            const fullpath = `${this.apiUrl}contracts/stripe`;
            const body = {
                accountId: accountId,
                stripeCardToken: stripeCardToken,
                stripeCustomerName: stripeCustomerName,
                stripeCustomerEmail: stripeCustomerEmail,
            };

            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    updateCustomerEmail(customerEmail) {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null && this.currentBillingAccount != null) {
            const fullpath = `${this.apiUrl}account/${this.currentBillingAccount.id}/customer`;

            const body = {
                customerEmail: customerEmail,
            };

            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    updateOffBoarding(orgId, networkGroupIds) {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        const path = this.apiUrl.replace('billing', 'customer');
        const fullpath = path + 'off-boarding';

        const body = {
            organisationId: orgId,
            networkGroupIds: networkGroupIds,
        };

        return this.http
            .post(fullpath, JSON.stringify(body), { headers: headers })
            .pipe(catchError((error) => this.handleError(this, error)));
    }

    getBillingCard(): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null && this.currentBillingAccount != null) {
            const fullpath = `${this.apiUrl}payments/account/${this.currentBillingAccount.id}/card`;

            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    updateBillingMode(mode): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();
        const body = {
            billingMode: mode,
        };
        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null && this.currentBillingAccount != null) {
            const fullpath = `${this.apiUrl}accounts/${this.currentBillingAccount.id}`;
            return this.http
                .put(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    public async getOverdueStatus(subscription, isSelfService = false) {
        let invoices;
        if (isSelfService) {
            invoices = await this.getSelfServiceInvoices(subscription.subscriptionId).toPromise();
        } else {
            invoices = await this.getEnterpriseInvoices(subscription.subscriptionId).toPromise();
        }

        const isMonthly = subscription.billingFrequency === MONTHLY_SUBSCRIPTION;
        const currentTermStart = moment(subscription.currentTermStart);
        const currentTermEnd = moment(subscription.currentTermEnd);

        let lastOverdueDate;
        const currentDate = moment(new Date());
        let daysOverdue = 0;
        if (invoices) {
            invoices.forEach((invoice) => {
                if (UNPAID_STATUSES.includes(invoice.status)) {
                    const dueDate = moment(invoice.dueDate);
                    if (!isMonthly && dueDate.isAfter(currentTermStart) && dueDate.isBefore(currentTermEnd)) {
                        // If an invoice is marked as "NOT_PAID" or "PAYMENT_DUE" but the due date is still within a current/active
                        // Quarterly/Yearly subscription term: do not show any warning/banner
                        return;
                    }
                    if (!lastOverdueDate || lastOverdueDate.isBefore(dueDate)) {
                        lastOverdueDate = moment(invoice.dueDate);
                        daysOverdue = currentDate.diff(lastOverdueDate, 'days');
                    }
                }
            });
        }
        const overdueStatus: any = {
            isOverdue: daysOverdue > 0,
            daysOverdue: daysOverdue,
        };

        if (lastOverdueDate) {
            const month = lastOverdueDate.format('MMMM');
            const year = lastOverdueDate.format('YYYY');
            overdueStatus.monthAndYear = `${month}, ${year}`;
        }

        return overdueStatus;
    }

    /**
     * get pricing information
     */
    public getMopPricing(): Observable<any> {
        const fullpath = `${this.apiUrl}componenttypes`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        // TODO remove the false when ready to get mop pricing again
        // avoids lint errors
        const skip = false;
        if (skip) {
            // || headers != null) {
            return this.http
                .get(fullpath, { headers: this.setHeaders() })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    public initPricingData() {
        this.getMopPricing().subscribe((result: any) => {
            let networkPricingObj;
            let regionPricingObj;
            for (const pricing of result) {
                if (pricing['name'] === networkPricingName) {
                    networkPricingObj = pricing;
                } else if (pricing['name'] === regionPricingName) {
                    regionPricingObj = pricing;
                }
            }
            if (
                networkPricingObj != null &&
                networkPricingObj['pricingPlans'] != null &&
                networkPricingObj['pricingPlans'].length > 0
            ) {
                this.networkPricing = networkPricingObj['pricingPlans'][0]['amount'] / 1000;
            }

            if (
                regionPricingObj != null &&
                regionPricingObj['pricingPlans'] != null &&
                regionPricingObj['pricingPlans'].length > 0
            ) {
                this.regionPricing = [];
                for (const region of regionPricingObj['pricingPlans']) {
                    let regionString;
                    if (region['meta'].length > 0) {
                        switch (region['meta'][0]['val']) {
                            case 'NA':
                                regionString = 'North America';
                                break;
                            case 'SA':
                                regionString = 'South America';
                                break;
                            case 'APAC':
                                regionString = 'Asia-Pacific';
                                break;
                            case 'EU':
                                regionString = 'Europe';
                                break;
                            default:
                                regionString = region['meta'][0]['val'];
                                break;
                        }

                        this.regionPricing.push({
                            region: regionString,
                            price: region['amount'] / 100,
                            rate: 'GB',
                        });
                    }
                }
            } else {
                this.regionPricing = [{ region: null, price: 0.2, rate: 'GB' }];
            }
        });
    }

    /*
     * get invoices for an organization
     */
    public getInvoices(pageSize?, pageStart?, startDate?, endDate?): Observable<any> {
        /* TODO
let fullpath = `${this.apiUrl}organizations/${this.billingAccountId}/invoices`;

if(pageSize != null) {
fullpath += `?pageSize=${pageSize}`;
} else {
fullpath += `?pageSize=100`;
}

if(startDate != null) {
fullpath += `&startDate=${startDate}`;
}

if(endDate != null) {
fullpath += `&endDate=${endDate}`;
}

if(pageStart != null) {
fullpath += `&pageStart=${pageStart}`;
}

// getting the headers. This now checks to determine if the user's token expired
let headers = this.setHeaders();

// headers will be not null if the token is valid. If this is the case, kick off the request
if(headers != null) {
return this.http.get(fullpath, { headers: headers })
  .pipe( catchError(error => this.handleError(this, error) ) );
} else {

// delaying the response to allow the console time to trigger the logout and terminate the subscription
setTimeout(()=> {
return observableOf([]);
}, 1000);

}
*/

        // delaying the response to allow the console time to trigger the logout and terminate the subscription
        return observableOf([]);
    }

    public getAccount(attributionId): Observable<any> {
        const fullpath = `${this.apiUrl}accounts?attributionId=${attributionId}&active=true`;
        const headers = this.setHeaders();
        if (headers != null) {
            return this.http.get(fullpath, { headers: headers });
            // .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * function for deleting the subscription and deactivating the tenant
     */
    public cancelAccount(): Observable<any> {
        const fullpath = `${this.apiUrl}accounts/${this.currentBillingAccount.id}/active/false`;
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getPaymentProfile(billAccountId): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();
        const body = {
            billingAccountId: billAccountId,
        };
        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null && billAccountId != null) {
            const fullpath = `${this.apiUrl}payment-profiles?billingAccountId=${billAccountId}`;
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error, [404])));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    createPaymentProfileCard(customer): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();
        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            const fullpath = `${this.apiUrl}payment-profiles`;

            return this.http
                .post(fullpath, JSON.stringify(customer), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    patchPaymentProfileCard(paymentProfileId, customer): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();
        const body = {
            firstName: customer.firstName,
            lastName: customer.lastName,
            email: customer.email,
            paymentMethod: {
                cardToken: customer.paymentMethod.cardToken,
            },
        };
        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null && paymentProfileId != null) {
            const fullpath = `${this.apiUrl}payment-profiles/${paymentProfileId}`;

            return this.http
                .patch(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    updatePaymentProfileCard(paymentProfileId, customer): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null && paymentProfileId != null) {
            const fullpath = `${this.apiUrl}payment-profiles/${paymentProfileId}`;

            return this.http
                .put(fullpath, JSON.stringify(customer), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    checkEmail(email: string) {
        // getting the headers. This now checks to determine if the user's token expired
        let headers = this.setHeaders();
        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            headers = headers.append('nf-validate', ' ');

            const fullpath = `${this.baseUrl}customer/v1/otps`;
            const body = {
                email: email,
            };

            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    sendEmailVerification(email) {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();
        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            const fullpath = `${this.baseUrl}customer/v1/otps`;
            const body = {
                email: email,
            };

            return this.http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    submitEmailVerificationToken(email, requestId, token) {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();
        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            const fullpath = `${this.baseUrl}customer/v1/otps`;
            const body = {
                email: requestId,
                otp: token,
            };

            return this.http
                .put(fullpath, JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    getEnterpriseInvoices(subscriptionId) {
        const fullpath = `${this.apiUrl}enterprise-subscriptions/${subscriptionId}/invoices?limit=20`;
        this.logger.info(JSON.stringify(this.setHeaders()));
        return this.http
            .get(fullpath, { headers: this.setHeaders() })
            .pipe(catchError((error) => this.handleError(this, error, [404])));
    }

    getSelfServiceInvoices(subscriptionId) {
        const fullpath = `${this.apiUrl}self-service-subscriptions/${subscriptionId}/invoices?limit=20`;
        this.logger.info(JSON.stringify(this.setHeaders()));
        return this.http
            .get(fullpath, { headers: this.setHeaders() })
            .pipe(catchError((error) => this.handleError(this, error, [404])));
    }

    getEnterpriseSubscription(billingAccountId) {
        const fullpath = `${this.apiUrl}enterprise-subscriptions?billingAccountId=${billingAccountId}`;
        this.logger.info(JSON.stringify(this.setHeaders()));
        return this.http
            .get(fullpath, { headers: this.setHeaders() })
            .pipe(catchError((error) => this.handleError(this, error, [404])));
    }

    getEnterpriseOverageSubscriptions(subscriptionId) {
        const fullPath = `${this.apiUrl}enterprise-subscriptions/${subscriptionId}/overage-subscriptions`;
        return this.http
            .get(fullPath, { headers: this.setHeaders() })
            .pipe(catchError((error) => this.handleError(this, error)));
    }

    getEnterpriseSubscriptionUsage(subscriptionId, overageId, epochFrom, epochTo) {
        const fullPath = `${this.apiUrl}enterprise-subscriptions/${subscriptionId}/metered-overages?fromEpochMs=${epochFrom}&toEpochMs=${epochTo}&overageSubscriptionId=${overageId}`;
        return this.http
            .get(fullPath, { headers: this.setHeaders() })
            .pipe(catchError((error) => this.handleError(this, error)));
    }

    getEnterpriseSubscriptionOverageReportV2(subscriptionId, billingMonthYear) {
        const fullpath = `${this.apiUrl}enterprise-subscriptions/${subscriptionId}/overage-reports?billingPeriod=${billingMonthYear}`;
        let headers: HttpHeaders = this.setHeaders();
        headers = headers.append('version', 'v2');
        return this.http.get(fullpath, {
            responseType: 'blob',
            headers: headers,
        });
    }

    getEnterpriseSubscriptionOverageReport(subscriptionId, epochFrom, epochTo) {
        const fullpath = `${this.apiUrl}enterprise-subscriptions/${subscriptionId}/overage-reports?fromEpochMs=${epochFrom}&toEpochMs=${epochTo}`;
        return this.http.get(fullpath, {
            responseType: 'blob',
            headers: this.setHeaders(),
        });
    }

    public getEnterpriseSubscriptionOverageReportAvailableMonths(subscriptionId): Promise<any> {
        const fullpath = `${this.apiUrl}enterprise-subscriptions/${subscriptionId}/overage-reports/query-time-ranges`;
        return this.http.get(fullpath, { headers: this.setHeaders(), responseType: 'json' }).toPromise();
    }

    getSelfServiceSubscription(billingAccountId, includeCancelled = false) {
        const fullpath =
            `${this.apiUrl}self-service-subscriptions?billingAccountId=${billingAccountId}&` +
            `includeCancelled=${includeCancelled}`;
        this.logger.info(JSON.stringify(this.setHeaders()));
        return this.http
            .get(fullpath, { headers: this.setHeaders() })
            .pipe(catchError((error) => this.handleError(this, error, [404])));
    }

    cancelSelfServiceSubscription(subscriptionId, immediate = false, forUpgrade = false) {
        const fullpath = `${this.apiUrl}self-service-subscriptions/${subscriptionId}/states`;
        const cancelDate = new Date().getTime() + 172800000; // add 2 days in milliseconds ie. (2 * 24 * 60 * 60 * 1000)
        let body;
        if (immediate) {
            body = {
                targetState: 'CANCELLED',
                cancelMode: 'IMMEDIATE',
                initiateRefund: true,
            };
            if (forUpgrade) {
                body.status = 'CANCELLED_FOR_UPGRADE';
            }
        } else {
            body = {
                targetState: 'CANCELLED',
                cancelMode: 'SCHEDULED_DATE',
                cancelOnDate: cancelDate,
                initiateRefund: true,
            };
        }
        return this.http
            .put(fullpath, JSON.stringify(body), { headers: this.setHeaders() })
            .pipe(catchError((error) => this.handleError(this, error)));
    }

    createPAYGSelfServiceSubscription(billingAccountId, paymentProfileId) {
        const fullpath = `${this.apiUrl}self-service-subscriptions?planId=Netfoundry-Pay-As-You-Go`;
        const body = {
            billingAccountId: billingAccountId,
            paymentProfileId: paymentProfileId,
        };
        return this.http
            .post(fullpath, JSON.stringify(body), { headers: this.setHeaders() })
            .pipe(catchError((error) => this.handleError(this, error)));
    }

    getInvoicePDFLink(invoice, download) {
        const link = get(invoice, `links[${download ? 1 : 2}].href`);
        return this.http.get(link).toPromise();
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: any, error: HttpErrorResponse, silenceCodes?) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            scope.logger.error('Billing Service error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            scope.logger.error(`Billing Backend returned code ${error.status}`, error.error);
        }

        let errorMessage = error.message;
        if (error.error && error.error[0] && error.error[0]['message']) {
            errorMessage = error.error[0]['message'];
        }
        let displayGrowler = true;
        if (silenceCodes != null && silenceCodes.indexOf(error.status) > -1) {
            displayGrowler = false;
        } else {
            this.errorHistoryService.addError(error.message);
        }
        if (displayGrowler) {
            this.growlerService.show(new GrowlerData('error', 'Error', 'An error occurred', errorMessage));
        }
        return observableThrowError(error);
    }

    /**
     * Return headers
     */
    private setHeaders(): HttpHeaders {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (tokenResponse.expired) {
            // if the token is expired, return null
            headers = null;
        } else if (tokenResponse.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        if (headers != null) {
            return headers.append('Content-Type', 'application/json');
        } else {
            return headers;
        }
    }
}
