import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NetworkV2 } from '@netfoundry-ui/shared/model';
import { NetworkUpgradeService } from '../network-upgrade.service';

@Component({
    selector: 'app-network-restart-modal',
    templateUrl: './network-restart-modal.component.html',
    styleUrls: ['./network-restart-modal.component.scss'],
})
export class NetworkRestartModalComponent {
    title = 'Network Host Restart';
    icon = '';
    action = 'Restart Network';
    cancelAction = 'Oops, no get me out of here';

    network: NetworkV2;

    constructor(
        private dialogRef: MatDialogRef<NetworkRestartModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private networkUpgradeSvc: NetworkUpgradeService
    ) {
        this.network = data.network;
    }

    cancel() {
        this.dialogRef.close();
    }

    restartProcess() {
        const options = {
            process: true,
            network: this.network,
        };
        this.dialogRef.close(options);
    }

    restartHost() {
        const options = {
            host: true,
            network: this.network,
        };
        this.dialogRef.close(options);
    }
}
