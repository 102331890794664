import { Injectable, InjectionToken } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export const HTTP_CLIENT = new InjectionToken<HttpClient>('HTTP_CLIENT');

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    // replay subject for triggering the logout
    logoutSource = new ReplaySubject<boolean>();

    // observable that the console will subscribe to in order to determine when to logout
    logout = this.logoutSource.asObservable();

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    public getAccessToken() {
        // getting the access token from local storage
        //localStorage.getItem('access_token');
        // return the access token and whether or not it was expired
        return { accessToken: undefined, expired: false };
    }
}
