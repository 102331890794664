import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Network } from '@netfoundry-ui/shared/model';
import { ApiService, NetworkService, ValidateService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-o365-breakout',
    templateUrl: './o365-breakout.component.html',
    styleUrls: ['./o365-breakout.component.scss'],
})
export class O365BreakoutComponent {
    optimizationOptions = [
        { name: 'None', value: 'NONE' },
        { name: `"Optimize" Category`, value: 'OPTIMIZE' },
        { name: `"Optimize" + "Allow" Categories`, value: 'OPTIMIZE_ALLOW' },
    ];

    network: Network;

    errorOptimization = false;

    processing = false;

    constructor(
        private dialogRef: MatDialogRef<O365BreakoutComponent>,
        private validateService: ValidateService,
        private networkService: NetworkService,
        private apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private growlerService: GrowlerService
    ) {
        this.network = data.network;
    }

    cancel() {
        this.dialogRef.close();
    }

    save() {
        if (this.validate()) {
            this.processing = true;
            this.networkService.save(this.network).subscribe(
                (result) => {
                    this.network = new Network(result);
                    // propagate the updated network model if we've just updated the current network
                    if (this.network.getId() === this.apiService.theNetworkIs.getId()) {
                        this.apiService.setCurrentNetwork(this.network);
                    }
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Office365 Optimization Preferences Saved',
                            'Your Office365 Optimization Preferences for this Network have been saved.'
                        )
                    );
                    this.dialogRef.close();
                },
                (error) => {
                    this.processing = false;
                }
            );
        }
    }

    validate() {
        this.errorOptimization = false;

        let isValid = true;
        if (!this.validateService.hasValue(this.network.o365BreakoutCategory)) {
            isValid = false;
            this.errorOptimization = true;
        }
        return isValid;
    }
}
