import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GatewayFormsService } from '@netfoundry-ui/shared/gateway';
import { Endpoint, GatewayCluster } from '@netfoundry-ui/shared/model';
import { FeatureService, LoggerService, RegionService, ZitiEnabledService } from '@netfoundry-ui/shared/services';
import { RegionTransformPipe } from '@netfoundry-ui/ui/pipes';
import { Subscription } from 'rxjs';

const zitiPrefix = 'ZT';

@Component({
    selector: 'app-ziti-gateways-form',
    templateUrl: './ziti-gateways-form.component.html',
    styleUrls: ['./ziti-gateways-form.component.scss', '../gatewayform.component.scss'],
})
export class ZitiGatewaysFormComponent implements OnInit, OnDestroy {
    @Input() isInline = false;
    @Input() showBreakoutFields = false;
    @Input() public currentOrgId;
    @Input() public currentNetworkId;
    @Input() hideHaUpgrade = false;
    @Output() back: EventEmitter<boolean> = new EventEmitter();
    @Output() hide: EventEmitter<any> = new EventEmitter();
    @Output() newGateway: EventEmitter<Endpoint> = new EventEmitter();

    model: Endpoint = new Endpoint({});
    public clusterModel = new GatewayCluster({});

    typeAppend = '';
    isComplete = false;
    regions: Location[] = [];
    errorName = false;
    errorNameLength = false;
    errorType = false;
    errorLocation = false;
    errorRegion = false;
    errorNextHop = false;
    gateways = [];
    regKey = [];
    attempt = 0;
    isLoading = false;
    isBuilding = true;
    provisionedString = 'has been created';
    buildingString = 'is building';
    completedTitleStatusString = this.buildingString;
    timeout = 150;
    processing = false;
    hideHelp = false;
    errors = {
        name: false,
        geoRegionId: false,
        azureSiteName: false,
        azureResourceGroupName: false,
        azureVirtualWanId: false,
        publicIpdAddress: false,
        bgpPeeringAddress: false,
        bpgASN: false,
        bgpPeerWeight: false,
        deviceLinkSpeed: false,
        deviceVendor: false,
        deviceModel: false,
        bpgNeighborASN: false,
        bgpPeeringNeighborAddress: false,
    };
    maxLength = '64';
    private updatedRegKeySub = new Subscription();
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private regionService: RegionService,
        public featureService: FeatureService,
        public zitiEnabledService: ZitiEnabledService,
        public gatewayFormService: GatewayFormsService,
        public authorizationService: AuthorizationService,
        private regionTransform: RegionTransformPipe
    ) {}

    ngOnInit() {
        this.processing = false;
        this.errorName = false;
        this.errorLocation = false;
        this.errorRegion = false;
        this.errorType = false;

        // geo region for gateways
        this.subscription.add(
            this.regionService.get().subscribe((result) => {
                this.regions = this.regionTransform.transform(
                    result as Location[],
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                );
            })
        );

        // Reg Key
        // subscribe to changes to updatedRegKey
        this.updatedRegKeySub.add(
            this.gatewayFormService.updatedRegKey.subscribe((isUpdated) => {
                this.regKey = this.gatewayFormService.regKey;
                this.gateways = this.gatewayFormService.gateways;
                // if the registration key was not obtained and there were less than 10 attempts
                if (!isUpdated) {
                    this.attempt++;
                    // setting a 250ms timeout before calling loadGateway again
                    setTimeout(() => {
                        this.gatewayFormService.loadGateway(this.model);
                    }, this.gatewayFormService.timeout);
                } else {
                    this.completedTitleStatusString = this.provisionedString;
                    this.isBuilding = false;
                }
            })
        );
    }

    ngOnDestroy() {
        this.updatedRegKeySub.unsubscribe();
        this.subscription.unsubscribe();
    }

    async save() {
        this.logger.info('Saving...');
        if (this.validate()) {
            this.processing = true;
            this.model.endpointType = zitiPrefix + this.typeAppend;

            const result = await this.gatewayFormService.save(this.model);

            this.processing = false;
            if (result != null) {
                this.model = result;
                if (this.model.endpointType === 'ZTGW') {
                    this.hideForm();
                } else {
                    this.isComplete = true;
                }
                this.completedTitleStatusString = this.buildingString;
                // attempting to get the registration key for the gateway
                this.newGateway.emit(new Endpoint(this.model));
                this.gatewayFormService.loadGateway(this.model);
            }
        } else {
            this.logger.info('Gateway form is invalid');
        }
    }

    share() {
        this.gatewayFormService.share(this.gateways[0]);
    }

    validate(): boolean {
        const validateResult = this.gatewayFormService.validate(this.model, false, zitiPrefix, this.typeAppend);

        this.errorName = validateResult.errorName;
        this.errorLocation = validateResult.errorLocation;
        this.errorType = validateResult.errorType;
        this.errorRegion = validateResult.errorRegion;
        this.errorNameLength = validateResult.errorNameLength;
        this.errorNextHop = validateResult.errorNextHop;

        return validateResult.isValid;
    }

    goBack() {
        this.back.emit(true);
    }

    hideForm(response?: unknown) {
        this.hide.emit(response);
    }

    downloadJWT(model, index) {
        this.gatewayFormService.downloadJWT(model, index);
    }
}
