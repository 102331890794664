<div class="filters">
    <input
        (keyup)="setFilter()"
        [(ngModel)]="filterString"
        class="search"
        id="SearchFilter"
        placeholder="Type to Filter"
    />
    <div class="counters">
        {{ filterService.getStartElementNumber() }}-{{ filterService.getLastElementNumber() }} of
        {{ filterService.getTotalElements() }}
    </div>
    <div (clickOutside)="closeTime()" *ngIf="isTimeSearchAvailable" id="TimeSearch">
        <div (click)="showTimeSelect()" id="TimeSelectButton">{{ dateFilter }}</div>
        <div *ngIf="isNotDialLogs"  [ngClass]="{ open: isSelectingTime }" 
        class="timeSearchList" id="TimeSearchList">
            <div (click)="pickDateFilter('1h')" [ngClass]="{ selected: dateFilter === '1h' }" class="time">1h</div>
            <div (click)="pickDateFilter('6h')" [ngClass]="{ selected: dateFilter === '6h' }" class="time">6h</div>
            <div (click)="pickDateFilter('24h')" [ngClass]="{ selected: dateFilter === '24h' }" class="time">24h</div>
            <div (click)="pickDateFilter('7d')" [ngClass]="{ selected: dateFilter === '7d' }" class="time">7d</div>
            <div *ngIf="isNotDialLogs" (click)="pickDateFilter('30d')" [ngClass]="{ selected: dateFilter === '30d' }" class="time">30d</div>
            <div *ngIf="isNotDialLogs" (click)="pickDateFilter('1m')" [ngClass]="{ selected: dateFilter === '1m' }" class="time">1m</div>
            <div *ngIf="isNotDialLogs" (click)="pickDateFilter('6m')" [ngClass]="{ selected: dateFilter === '6m' }" class="time">6m</div>
            <div *ngIf="isNotDialLogs" (click)="pickDateFilter('12m')" [ngClass]="{ selected: dateFilter === 'All' }" class="time">All</div>
        </div>
        <div *ngIf="!isNotDialLogs" [ngClass]="{ open: isSelectingTime  }" 
        class="timeSearchList dialLogTimeSearchList" id="DialLogTimeSearchList">
            <div (click)="pickDateFilter('1h')" [ngClass]="{ selected: dateFilter === '1h' }" class="time">1h</div>
            <div (click)="pickDateFilter('6h')" [ngClass]="{ selected: dateFilter === '6h' }" class="time">6h</div>
            <div (click)="pickDateFilter('24h')" [ngClass]="{ selected: dateFilter === '24h' }" class="time">24h</div>
            <div (click)="pickDateFilter('7d')" [ngClass]="{ selected: dateFilter === '7d' }" class="time">7d</div>
        </div>
    </div>
    <div *ngIf="isTimeSearchAvailable" class="timeBox">
        <p-toggleButton
            (onChange)="handleTimeChange($event)"
            [(ngModel)]="isUTC"
            [class]="styleClass"
            [ngStyle]="style"
            iconPos="left"
            offIcon="pi pi-clock"
            offLabel="Local"
            onIcon="pi pi-clock"
            onLabel="UTC"
        ></p-toggleButton>
    </div>
    <div *ngIf="isTimeSearchAvailable" class="calendar">
        <p-calendar
            (onClose)="close()"
            [(ngModel)]="rangeDates"
            [readonlyInput]="false"
            [showTime]="true"
            hourFormat="12"
            inputId="range"
            placeholder="Select Time Frame"
            selectionMode="range"
            showButtonBar="true"
        ></p-calendar>
    </div>

    <div class="filtered-columns-list" id="ColumnFilterContainer">
        <div
            (click)="removeFilter(filter)"
            *ngFor="let filter of filters"
            class="filtered-column-item ag-dnd-ghost ag-unselectable ag-theme-alpine"
            id="ColumnVisibility_{{ filter.filterName }}"
            title="Remove filter"
        >
            <span class="ag-dnd-ghost-icon">
                <span class="ag-icon ag-icon-filter"></span>
            </span>
            <div class="ag-dnd-ghost-label">{{ filter.filterName }}: {{ filter.label }}</div>
        </div>
    </div>
    <div id="paginationControls">
        <div
            (click)="prev()"
            [ngClass]="{
                disabled: filterService.isFirstPage() || !filterService.isUsingPagination()
            }"
            class="navigate prev icon-prev"
        ></div>
        <div
            (click)="next()"
            [ngClass]="{
                disabled: filterService.isLastPage() || !filterService.isUsingPagination()
            }"
            class="navigate next icon-next"
        ></div>
    </div>
</div>
