<!--
    <div class="splitGrid mobileOnly padded">
      <div *ngIf="featureService.experimentalFeatures" id="MobileAuidtLogButton" class="tabletab" [ngClass]="{'selected':type === 'audit'}" (click)="setEvents('audit')">Audit Log</div>
      <div *ngIf="featureService.experimentalFeatures" id="MobileNetworkLogButton" class="tabletab" [ngClass]="{'selected':type === 'network'}" (click)="setEvents('network')">Network Events</div>
    </div>
    -->
<div *ngIf="type === 'network'">
    <!--
<div class="filterGrid auditGrid">

<div id="dateFilterContainer">
  <app-date-filters
    [currentTimeFilter]="currentTimeFilter"
    (startChange)="setStartTime($event)"
  ></app-date-filters>
</div>

<div id="networkContainer" class="filterContainer">
  <select [(ngModel)]="networkFilter" placeholder="Filter by Network">
    <option [ngValue]="false">All Networks</option>
    <option *ngFor="let network of networkFilterList" [ngValue]="network.id">{{ network.name }}</option>
  </select>
</div>

<div id="eventTypeContainer" class="filterContainer">
  <select [(ngModel)]="eventTypeFilter" placeholder="Filter by Event Type">
    <option [ngValue]="false">All Event Types</option>
    <option *ngFor="let type of eventTypes" [ngValue]="type">{{ type }}</option>
  </select>
</div>
<div id="resourceTypeContainer" class="filterContainer">
  <select [(ngModel)]="resourceTypeFilter" placeholder="Filter by Resource Type" >
    <option [ngValue]="false">All Resource Types</option>
    <option *ngFor="let type of resourceTypes" [ngValue]="type">{{ type }}</option>
  </select>
</div>

</div>
-->
    <!-- To be swapped out once auth logs are in place -->
    <app-event-timeline-search
        (networkFilters)="setNetworkFilterList($event)"
        [networkFilter]="networkFilter"
        [showAllNetworks]="true"
    ></app-event-timeline-search>
</div>

<!--
    <div *ngIf="featureService.experimentalFeatures && type === 'audit'">

      <div class="filterGrid auditGrid">

        <div id="dateFilterContainer">
          <app-date-filters
            [currentTimeFilter]="currentTimeFilter"
            (startChange)="setStartTime($event)"
          ></app-date-filters>
        </div>

        <div id="auditEventTypeContainer" class="filterContainer">
          <select [(ngModel)]="auditEventTypeFilter" placeholder="Filter by Event Type">
            <option [ngValue]="false">All Event Types</option>
            <option *ngFor="let type of auditEventTypes" [ngValue]="type">{{ type }}</option>
          </select>
        </div>
        <div id="auditSourceContainer" class="filterContainer">
          <select [(ngModel)]="auditSourceFilter" placeholder="Filter by Source" >
            <option [ngValue]="false">All Sources</option>
            <option *ngFor="let source of auditSources" [ngValue]="source">{{ source }}</option>
          </select>
        </div>

        <div id="auditTakenByContainer" class="filterContainer">
          <select [(ngModel)]="auditTakenByFilter" placeholder="Filter by User" >
            <option [ngValue]="false">All Accounts</option>
            <option *ngFor="let user of users" [ngValue]="user.id">{{ user.name }}</option>
          </select>
        </div>


      </div>
      <app-org-audit-log
        [startTime]="startTime"
        [endTime]="endTime"
        [eventTypeFilter]="auditEventTypeFilter"
        [sourceFilter]="auditSourceFilter"
        [takenByFilter]="auditTakenByFilter"
        (userFilters)="setTakenByFilterList($event)"
      ></app-org-audit-log>
    </div>

    -->
