import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'alarmDate',
})
export class AlarmDatePipe implements PipeTransform {
    transform(items: any[], start: number | false, end: number | false): any {
        if (!items) {
            return [];
        }

        if (!start) {
            return items;
        }

        return items.filter((it) => it['timestampSecsEpoch'] * 1000 > start);
    }
}
