import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-countbox',
    templateUrl: './countbox.component.html',
    styleUrls: ['./countbox.component.scss'],
})
export class CountboxComponent {
    @Input() label = 'Test';
    @Input() count = 0;
    @Input() iconStyle = 'iconGateways';
    @Input() link = '';
}
