<div [ngClass]="{ editing: editing }" class="modal solid open" id="NetworkGroupModal">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div (click)="cancel()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="title">{{ editing ? 'Edit your' : 'Add a' }} Network Group</div>
    <div [hidden]="editing" class="subtitle">Enter a group name and new network name</div>
    <div [hidden]="!editing" class="subtitle">Edit your Network Group Name</div>

    <div [hidden]="isLoading">
        <div class="row">
            <div class="label">
                Network Group Name<span [hidden]="!errorName" class="error note">Required</span
                ><span [hidden]="errorName" class="note">Required</span>
            </div>
        </div>
        <div class="row">
            <input
                [(ngModel)]="model['name']"
                [ngClass]="{ error: errorName }"
                id="NetworkGroupName"
                maxlength="64"
                placeholder="What would you like to call this Group?"
            />
        </div>
        <div *ngIf="!editing" class="row">
            <div class="label">
                New Network Name<span [hidden]="!errorNetworkName" class="error note">Optional</span
                ><span [hidden]="errorNetworkName" class="note">Optional</span>
            </div>
        </div>
        <div *ngIf="!editing" class="row">
            <input
                [(ngModel)]="newNetworkModel['name']"
                [ngClass]="{ error: errorNetworkName }"
                id="NetworkName"
                placeholder="What would you like to call this Network?"
            />
        </div>

        <div class="buttons">
            <button (click)="save()" *ngIf="!processing" class="save confirm" id="GenerateAccountButton">
                {{ editing ? 'Save' : 'Create' }}
            </button>
            <button *ngIf="processing" class="save confirm" disabled>
                {{ editing ? 'Saving ' : 'Creating' }} <span class="spinner"></span>
            </button>
        </div>
    </div>
</div>
