import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { Network } from '@netfoundry-ui/shared/model';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-network-uptime',
    templateUrl: './network-uptime.component.html',
    styleUrls: ['./network-uptime.component.scss'],
    // not entirely sure why this had to be done, but click to expand didn't seem to work without it -MG
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
})
export class NetworkUptimeComponent implements OnInit, OnChanges, OnDestroy {
    network = new Network({});
    pie_options = {};
    isLoading = false;
    uptime_series;
    colors = ['#08dc5a', '#910000'];

    bucket_sizes = {
        '1h': 12,
        '6h': 72,
        '24h': 288,
        '7d': 2016,
        '30d': 8640, // max check is 30 day
        '1m': 8640,
        '6m': 8640,
        '12m': 8640,
    };

    @Input() interval = '24h';

    uptimePercent: any = '--';
    noData = true;
    initialized = false;
    subscription = new Subscription();
    canReadElasticSearch = false;
    currentOrgId = null;
    networkId = null;

    constructor(
        private logger: LoggerService,
        private authorizationService: AuthorizationService,
        private elasticsearch: ElasticsearchService,
        private apiService: ApiService
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrgId = org.getId();
                this.initialized = true;
                this.ngOnChanges();
            })
        );
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((currentNetwork) => {
                if (currentNetwork) {
                    this.networkId = currentNetwork.id;
                    this.network = new Network(currentNetwork);
                    this.ngOnChanges();
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges() {
        if (!this.initialized || this.networkId === null || this.currentOrgId === null) {
            return;
        }

        if (this.network.status < 300) {
            this.noData = true;
            return;
        }

        this.canReadElasticSearch = this.authorizationService.canReadElasticSearch(this.currentOrgId, this.networkId);

        if (this.canReadElasticSearch) {
            // max interval of 30d for now until I figure out how to get just the aggregation count in the query
            if (this.interval === '6m' || this.interval === '12m') {
                this.interval = '30d';
            }

            this.getHistoricalAlarms();
        } else {
            this.noData = true;
        }
    }

    getHistoricalAlarms() {
        this.isLoading = true;
        this.elasticsearch
            .search(this.currentOrgId, 'ncalarms', this.getQuery(), {
                networkId: this.networkId,
            })
            .subscribe((data) => {
                this.uptime_series = [];
                let unhealthy_pct;
                let unhealthy_buckets = 0;
                let healthy_buckets;
                const total_buckets = this.bucket_sizes[this.interval];
                if (
                    data['aggregations'] !== undefined &&
                    data['aggregations']['time_buckets']['buckets'] !== undefined &&
                    data['aggregations']['time_buckets']['buckets'].length > 0
                ) {
                    unhealthy_buckets = data['aggregations']['time_buckets']['buckets'].length;
                    healthy_buckets = total_buckets - unhealthy_buckets;
                    unhealthy_pct = ((unhealthy_buckets / total_buckets) * 100).toFixed(2);
                    this.logger.info('Unhealthy pct', unhealthy_pct);
                } else {
                    this.logger.info('No health data for network uptime');
                    unhealthy_pct = 0;
                    healthy_buckets = total_buckets;
                }

                this.uptimePercent = Number(100.0 - unhealthy_pct);

                this.uptime_series = [
                    {
                        name: 'Uptime',
                        data: [
                            {
                                name: 'Healthy',
                                y: healthy_buckets,
                                borderColor: '#08dc5a',
                            },
                        ],
                    },
                ];

                if (unhealthy_buckets > 0) {
                    this.uptime_series[0].data.push({
                        name: 'Degraded',
                        y: unhealthy_buckets,
                        borderColor: '#910000',
                    });
                }
                this.setChartOptions();
                this.isLoading = false;
                this.noData = false;
            });
    }

    setChartOptions() {
        this.pie_options = {
            colors: this.colors,
            chart: { type: 'pie', backgroundColor: 'rgba(255, 255, 255, 0.0)' },
            title: { text: null },
            subtitle: { text: 'Last ' + this.interval, verticalAlign: 'bottom' },

            tooltip: {
                formatter: function () {
                    return this.point.name + '<br /><b>' + this.percentage.toFixed(2) + '%</b> ';
                },
                valueDecimals: 2,
            },
            credits: { enabled: false },
            plotOptions: {
                pie: {
                    shadow: false,
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                    allowPointSelect: true,
                    cursor: 'pointer',
                },
            },
            yAxis: {
                title: {
                    text: null,
                },
                labels: {
                    style: {
                        color: window.getComputedStyle(document.body).getPropertyValue('--text'),
                    },
                },
            },
            legend: {
                margin: 0,
                itemStyle: {
                    color: window.getComputedStyle(document.body).getPropertyValue('--text'),
                },
            },
        };
    }

    getQuery() {
        return {
            aggs: {
                time_buckets: {
                    date_histogram: {
                        field: '@timestamp',
                        interval: '5m',
                        time_zone: 'UTC',
                        min_doc_count: 1,
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            query_string: {
                                query: 'DEFLECT_POOL_BAD_STATE',
                                analyze_wildcard: true,
                                default_field: '*',
                            },
                        },
                        {
                            range: {
                                '@timestamp': {
                                    gte: 'now-' + this.interval,
                                    lte: 'now',
                                    format: 'epoch_millis',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                severity: {
                                    query: 'Critical',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                networkId: {
                                    query: this.networkId,
                                },
                            },
                        },
                    ],
                },
            },
        };
    }
}
