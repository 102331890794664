<div class="modal solid open" id="NetworkInfoModal">
    <div (click)="close()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="title">Network Firewall Info</div>
    <div class="icon" style="background-image: url(/assets/svgs/EditNetwork.svg)"></div>
    <div class="subtitle">To avoid firewall conflicts make sure the following OUTBOUND attributes are whitelisted.</div>

    <div class="row" style="overflow: auto; height: 300px; margin-top: 40px; padding-top: 0px">
        <!-- headers -->
        <div class="table three">
            <div class="header borderBottom borderRight">Type</div>
            <div class="header borderBottom borderRight">IP Address</div>
            <div class="header borderBottom">Port / Protocol</div>
        </div>

        <!-- rows -->
        <div *ngFor="let item of items" class="table three">
            <div class="header borderBottom borderRight">{{ item.type }}</div>
            <div class="borderBottom borderRight">
                <span *ngFor="let ip of item.ips" class="itemIp" style="display: inline-block">{{ ip }}</span>
            </div>
            <div class="borderBottom">{{ item.port }} / {{ item.protocol }}</div>
        </div>
    </div>

    <div class="buttons">
        <div (click)="csv_export()" class="save clear" id="ExportToCsv">Export To CSV</div>
    </div>

    <div buttons>
        <span class="versionInfo"> Network Version: {{ majorVersionNumber }} </span>
    </div>

    <div class="buttons">
        <button (click)="close()" class="save" id="CloseNetworkInfoButton">OK Thanks</button>
    </div>
</div>
