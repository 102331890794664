<div *ngIf="isOnline !== undefined">
    <div *ngIf="isOnline" class="onlineStatus fade-in" title="Endpoint Status">
        <div class="onlineImage"></div>
        <div class="buttonLabel labelOnline">Online</div>
    </div>
    <div *ngIf="lastOnlineTime !== undefined && !isOnline" class="onlineStatus fade-in" title="Endpoint Status">
        <div class="offlineImage"></div>
        <div class="buttonLabel labelOnline">
            Last Seen<br />
            <span style="font-weight: 400">{{ lastOnlineTime }}</span>
        </div>
    </div>
    <div *ngIf="lastOnlineTime === undefined" class="onlineStatus fade-in" title="Endpoint Status">
        <div class="offlineImage"></div>
        <div class="buttonLabel labelOnline">
            Last Seen<br />
            <span style="font-weight: 400">Never</span>
        </div>
    </div>
</div>
