import { Injectable } from '@angular/core';
import { Exports } from '@netfoundry-ui/shared/model';
import { ApiService } from '@netfoundry-ui/shared/services';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NetflowService } from './netflow.service';

@Injectable({
    providedIn: 'root',
})
export class ExportsService {
    page = null;
    links = null;
    private exportsSource = new Subject<Exports[]>();
    exports = this.exportsSource.asObservable();
    private mapSub = new Subscription();

    constructor(private netflowService: NetflowService, private apiService: ApiService) {}

    /**
     * Gets all Clients for org
     */
    find(params?: any): Observable<any> {
        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }

        this.mapSub = this.netflowService
            .get('exports', params)
            .pipe(map(this._extractEmbedded))
            .subscribe((data) => {
                this.page = data['page'];
                this.links = data['links'];
                this.exportsSource.next(data['exports']);
            });

        // this is an observable that watches for network changes
        return this.exports;
    }

    get(exportId) {
        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }

        return this.netflowService.get(`exports/${exportId}`);
    }

    /**
     * Saves the mop resource and returns result as observable
     */
    public save(exportObj: Exports) {
        if (exportObj.id !== '' && exportObj.id != null) {
            // edit
            return this.netflowService.put(`exports/${exportObj.id}`, exportObj.getSaveObject());
        } else {
            // add
            return this.netflowService.post('exports', exportObj.getSaveObject());
        }
    }

    /**
     * Deletes resources against the self link and returns observable result
     */
    public delete(exportObj: Exports) {
        return this.netflowService.delete(`exports/${exportObj.id}`, false);
    }

    /**
     * Synchronize the exports
     * @param exportObj
     */
    public synchronize(exportObj: Exports) {
        return this.netflowService.put(`exports/${exportObj.id}/synchronize`);
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const exportList: Exports[] = [];

        if (res['content'] !== undefined) {
            for (let i = 0; i < res['content'].length; i++) {
                const exportObj = res['content'][i];
                exportList.push(new Exports(exportObj));
            }
        }

        // object containing information on pagination such as page number, size, total number of items,  and total number of pages
        let page = null;
        if (res['page'] !== undefined) {
            page = res['page'];
        }

        // list of links for jumping to and from pages
        let links = null;
        if (res['_links'] !== undefined) {
            links = res['_links'];
        }

        return { exports: exportList, page: page, links: links };
    }
}
