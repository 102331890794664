import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment } from '@netfoundry-ui/shared/model';
import { ApiService, HTTP_CLIENT, LoggerService } from '@netfoundry-ui/shared/services';
import { GrowlerService } from '@netfoundry-ui/shared/growler';

declare const H: any;

@Injectable({ providedIn: 'root' })
export class TunnelersFormService {
    constructor(
        private logger: LoggerService,
        public growlerService: GrowlerService,
        private apiService: ApiService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    getTunnelerItems() {
        const items = [
            {
                os: 'mac',
                title: 'Ziti Desktop Edge',
                buttonText: 'Download',
                helpText:
                    'Download the tool to connect to your V7 Network with your <b>Mac</b>. Not sure what Ziti is? <br><a href="https://ziti.dev">Click Here</a> to learn more',
                icon: '/assets/images/tunnelers/Mac-Tunneler.jpg',
                link: 'https://apps.apple.com/app/id1460484572',
            },
            {
                os: 'windows',
                title: 'Ziti Desktop Edge',
                buttonText: 'Download',
                helpText:
                    'Download the tool to connect to your V7 Network with your <b>Windows PC</b>. Not sure what Ziti is? <br><a href="https://support.netfoundry.io/hc/en-us/articles/360052050291-Installing-Ziti-Desktop-Edge-on-Windows-PC-or-Server-2012-2016">Click Here</a> to learn more',
                icon: '/assets/images/tunnelers/Windows-Tunneler.jpg',
                target: '_self',
                link: 'https://github.com/openziti/desktop-edge-win/releases/download/2.1.16/Ziti.Desktop.Edge.Client-2.1.16.exe',
            },
            {
                os: 'linux',
                title: 'Ziti Linux Tunneler',
                buttonText: 'Download',
                helpText:
                    'Download the tool to connect to your V7 Network with your Linux Device. Not sure what Ziti is? <a href="https://support.netfoundry.io/hc/en-us/articles/360045177311">Click Here</a> to learn more',
                icon: '/assets/images/tunnelers/Linux-Tunneler.jpg',
                link: 'https://openziti.github.io/ziti/clients/linux.html',
            },
            {
                os: 'ios',
                title: 'Ziti Mobile Edge',
                buttonText: 'Download',
                helpText:
                    ' Download the tool to connect to your V7 Network with your iOS Device. Not sure what Ziti is? <a href="https://ziti.dev">Click Here</a> to learn more',
                icon: '/assets/images/tunnelers/iOS-Tunneler.jpg',
                link: 'https://apps.apple.com/app/id1460484353',
            },
            {
                os: 'android',
                title: 'Ziti Mobile Edge',
                buttonText: 'Download',
                helpText:
                    'Download the tool to connect to your V7 Network with your Android Device. Not sure what Ziti is? <a href="https://ziti.dev">Click Here</a> to learn more',
                icon: '/assets/images/tunnelers/Android-Tunneler.jpg',
                link: 'https://play.google.com/store/apps/details?id=org.openziti.mobile',
            },
            {
                os: 'docker',
                title: 'Ziti Tunneler via Docker Hub',
                buttonText: 'Get Started',
                helpText:
                    'Pull the docker image to connect to your V7 Network. Not sure what Ziti is? <a href="https://support.netfoundry.io/hc/en-us/articles/360046896891">Click Here</a> to learn more',
                icon: '/assets/images/tunnelers/Dockerhub-Tunneler.jpg',
                link: 'https://github.com/openziti/ziti-tunnel-sdk-c/tree/main/docker',
            },
            {
                os: 'sdk',
                title: 'Embed in Your App with Ziti SDKs',
                buttonText: 'Learn More',
                helpText:
                    'Build your own endpoints. Use Ziti Open Source SDKs to embed private networking into your app in a few lines of code.',
                icon: '/assets/images/tunnelers/zitisdk.jpeg',
                link: 'https://github.com/openziti/ziti-tunnel-sdk-c/tree/main/docker',
            },
        ];
        return items;
    }
}
