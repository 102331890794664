import {Component, EventEmitter, HostListener, Inject} from '@angular/core';
import {AuthPolicy, PagedAttributes, SimpleAttribute} from "@netfoundry-ui/shared/model";
import {LoggerService} from "@netfoundry-ui/shared/services";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BehaviorSubject} from "rxjs";
import {GrowlerData, GrowlerService} from "@netfoundry-ui/shared/growler";

@Component({
  selector: 'app-auth-policy-form',
  templateUrl: './auth-policy-form.component.html',
  styleUrls: ['./auth-policy-form.component.scss'],
})
export class AuthPolicyFormComponent {
  processing = false;

  model = new AuthPolicy();
  isEditing = false;
  hideHelp = false;
  requestUpdate = new EventEmitter<AuthPolicy>;
  requestValidate = new EventEmitter<AuthPolicy>;
  errorUpdate = new BehaviorSubject<any[]>([]);
  errMap: any = {};

  certAllowed = true;
  trueFalseYesNo = [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
  ]

  primaryAuthInfo = "Primary authentication establishes an API Sessions identity principal in Ziti.";
  secondaryAuthInfo = "Secondary authentication is represented by a series of Authentication Queries on an API Session in the authQueries property.";
  jwtSigners: PagedAttributes;
  jwtSignereError: any;
  isLoadingJwtSigners: any;
  selectedJwtSigners = new PagedAttributes();

  selectedRequireExtJwtSigner: PagedAttributes = new PagedAttributes();


  constructor(
    private logger: LoggerService,
    private dialogRef: MatDialogRef<AuthPolicyFormComponent>,
    public growlerService: GrowlerService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.errorUpdate.subscribe((errors: any) => {
      this.processing = false;
      if (errors?.length > 0) {
        this.processErrors(errors);
      }
    });
    this.dialogRef.beforeClosed().subscribe(() => {
      this.processing = false;
    });
    this.model = data.model;
    this.jwtSigners = data.jwtSigners;
    this.selectedJwtSigners = data.selectedJwtSigners;
    this.isEditing = !!this.model.id;

    if (this.model.secondary.requireExtJwtSigner)
      this.populateSelectedExtJwtSigner(this.model.secondary.requireExtJwtSigner);

  }

  save() {
    this.processing = true;
    this.requestUpdate.emit(this.model);
  }

  validate() {
    this.processing = true;
    this.requestValidate.emit(this.model);
  }

  hide() {
    this.dialogRef.close();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.hide();
  }

  private processErrors(errors: any) {

    // handle system error
    if (errors[0] ['http'] || errors[0] ['invalid']) {
      this.logger.error('Error from auth-policies', errors[0]['http']?.message ?? errors[0] ['invalid']);
      this.growlerService.show(
        new GrowlerData(
          'error',
          'Authentication Policy update request failed. ',
          errors[0] ['http']?.message ?? errors[0] ['invalid']
        )
      );
    } else {
      this.errMap = {};
      errors.forEach((err: any) => {
        this.errMap[err.label] = err.message;
      })

    }
  }

  addJwtSigner(name: string) {
    let skip = false;
    const id = this.jwtSigners.mappedAtrributes.get(name)?.id as string;

    this.model.primary.extJwt.allowedSigners.forEach((s: any) => {
      if (s.id === id) skip = true;
    });
    if (!skip) {
      this.model.primary.extJwt.allowedSigners.push(id);
      this.selectedJwtSigners = this.getNewPagedAttributes(name, id, this.selectedJwtSigners);
    }
  }

  addExtJwtSigner(name: string) {
    const rec = this.jwtSigners.mappedAtrributes.get(name);
    this.model.secondary.requireExtJwtSigner = rec?.id as string;
    this.selectedRequireExtJwtSigner = this.getNewPagedAttributes(name, rec?.id as string, new PagedAttributes());

  }

  getNewPagedAttributes(name: string, id: string, pa: PagedAttributes): PagedAttributes {
    const sa = new SimpleAttribute();
    sa.id = id;
    sa.name = name;
    sa.isGroup = false;
    sa.isNamed = false;
    const tmpSelected = new PagedAttributes();
    tmpSelected.mappedAtrributes = pa.mappedAtrributes;
    tmpSelected.mappedAtrributes.set(sa.name, sa);
    return tmpSelected;

  }

  removeJwtSigner(name: string) {
    const idx = this.model.primary.extJwt.allowedSigners.indexOf(name);
    this.model.primary.extJwt.allowedSigners.splice(idx, 1);
    const tmpSelected = new PagedAttributes();
    tmpSelected.mappedAtrributes = this.selectedJwtSigners.mappedAtrributes;
    tmpSelected.mappedAtrributes.delete(name);
    this.selectedJwtSigners = tmpSelected;
  }

  removeExtJwtSigner($event?: any) {
    this.model.secondary.requireExtJwtSigner = '';
    this.selectedRequireExtJwtSigner = new PagedAttributes();
  }

  populateSelectedExtJwtSigner(id: string) {
    let name = '';

    this.jwtSigners.mappedAtrributes.forEach(s => {
      if (id === s.id) name = s.name;
    })
    this.model.secondary.requireExtJwtSigner = id;
    this.selectedRequireExtJwtSigner = this.getNewPagedAttributes(name, id, new PagedAttributes());
  }

  populateSelectedJwtSigner(id: string) {
    let skip = false;

    this.model.primary.extJwt.allowedSigners.forEach((s: any) => {
      if (s.id === id) {
        skip = true;
      }
    });
    if (!skip) {
      let name = '';
      this.jwtSigners.mappedAtrributes.forEach(r => {
        if (r.id === id) name = r.name;
      })

      this.selectedJwtSigners = this.getNewPagedAttributes(name, id, this.selectedJwtSigners);
    }
  }

  toggleJWTAllowed() {
    this.model.primary.extJwt.allowed = !this.model.primary.extJwt.allowed;
    this.model.primary.extJwt.allowedSigners = [];
    this.selectedJwtSigners = new PagedAttributes();
    this.removeExtJwtSigner();
  }
}
