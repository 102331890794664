<app-action-button
    (addEvent)="create($event)"
    (deleteEvent)="openConfirm($event)"
    [hidden]="!authorizationService.canCreateEdgeRouterPolicies() || hideAppButton"
    [isDeleteAction]="isDeleteAction"
></app-action-button>

<app-loading-indicator
    *ngIf="isLoading"
    [isLoading]="isLoading"
    class="load-indicator"
    id="ZitiAppWANsPage"
></app-loading-indicator>

<div class="area data-table" id="ItemsArea">
    <app-noitems
        (clickEmit)="create(true)"
        [image]="'No_RouterPolicies'"
        [isEmpty]="showNoData"
        [typeName]="'Edge Router Policies'"
    ></app-noitems>

    <app-data-table
        *ngIf="filterApplied || (edgeRouterPolicies.length > 0 && !isLoading) || filterHasChanged || changedPage"
        [columnDefinitions]="columnDefs"
        [columnFilters]="columnFilters"
        [deleteItem]="deleteSingleItem.bind(this)"
        [downloadAll]="downloadAllToCsv.bind(this)"
        [downloadCsv]="downloadCsv.bind(this)"
        [filterChanged]="filterChanged.bind(this)"
        [openItem]="open.bind(this)"
        [openJson]="openJson.bind(this)"
        [rowData]="edgeRouterPolicies"
        [tableId]="'ziti-edge-router-policies'"
        [toggleAll]="toggleAll.bind(this)"
        [toggleItem]="toggle.bind(this)"
    >
    </app-data-table>
</div>
