<div [ngClass]="{ isAt: step === 3, passed: isPassedStep(3) }" class="slider">
    <div *ngIf="step === 3">
        <div class="wizard mini">
            <div id="ItemTable">
                <div class="tableGrid tHeadRow">
                    <!-- <div class="tHead"></div> -->
                    <div class="tHead desktop sort">Name</div>
                    <div class="tHead desktop sort">IP Address</div>
                    <div class="tHead desktop sort">Protocol</div>
                    <div class="tHead desktop sort">Ports</div>
                </div>
                <div id="ItemsList">
                    <div *ngFor="let filteredRange of filteredRanges" class="tableGrid tRow">
                        <div class="tCol clickCol desktop">{{ filteredRange.region }}</div>
                        <div class="tCol clickCol desktop">
                            {{ filteredRange.ip_prefix }}
                        </div>
                        <div class="tCol clickCol desktop">ALL</div>
                        <div class="tCol clickCol desktop">ALL</div>
                    </div>
                </div>
            </div>

            <!-- <div class="header">


<div class="darken"></div>
<div class="darkenText">Pre-configured Services for Accessing Your Bucket</div>
</div> -->
            <!-- <div class="row padded">
<h1>Pulling Details for {{region.name}}</h1>

<table>
<thead>
  <tr>
      <td>
          Name
      </td>
      <td>
          IP Address
      </td>
      <td>
          Protocol
      </td>
      <td>
          Ports
      </td>
  </tr>
</thead>
<tbody>
  <tr *ngFor= "let filteredRange of filteredRanges">
      <td>{{filteredRange.region}}</td>
      <td>{{filteredRange.ip_prefix}}</td>
      <td>ALL</td>
      <td>ALL</td>
  </tr>
</tbody>


</table>
</div> -->
        </div>
    </div>
</div>
