<div class="modal solid open">
    <div (click)="close()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div *ngIf="endpoint" class="title">{{ endpointName }} Dial Logs</div>
    <div *ngIf="service" class="title">{{ serviceName }} Dial Logs</div>
    <app-loading-indicator
        *ngIf="isLoading"
        [isLoading]="isLoading"
        class="load-indicator"
        id="dialLogsPage"
    ></app-loading-indicator>

    <div class="area data-table" id="ItemsArea">
        <app-data-table
            [tableId]="'dial-logs'"
            *ngIf="items.length >= 0 || filterHasChanged"
            [columnDefinitions]="columnDefs"
            [rowData]="items"
            [columnFilters]="columnFilters"
            [isTimeSearchAvailable]="true"
            [isNotDialLogs]="false"
            (gridReady)="onGridReady($event)"
            [filterChanged]="filterChanged.bind(this)"
            pagination="true"
            paginationPageSize="30"
            [newStartTime]="newStartTime"
            [newEndTime]="newEndTime"
        >
        </app-data-table>
    </div>
</div>
