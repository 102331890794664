<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="AddServiceModal">
    <div [hidden]="isEditing" class="title" style="filter: brightness(50%)">Create A New IP HOST Service</div>
    <div [hidden]="isAdding" class="title" style="filter: brightness(50%)">Edit Your IP HOST Service</div>
    <div class="subtitle">Enter your service attributes</div>
    <div class="sized">
        <div class="label row">
            Service Name<span [hidden]="!errors['name']" class="error" id="NameError">{{ nameErrorString }}</span
            ><span [hidden]="errors['name']" class="note" id="NameNote">Required</span>
        </div>
        <div class="row">
            <input
                [(ngModel)]="model.name"
                [disabled]="!canEdit"
                [ngClass]="{ error: errors['name'] }"
                class="restrictNormal"
                id="EditName"
                maxlength="64"
                placeholder="What would you like to call this service?"
                type="text"
            />
        </div>

        <div class="label row">
            Gateway<span [hidden]="!errors['endpointId']" class="error" id="GatewayListError">Required</span
            ><span [hidden]="errors['endpointId']" class="note" id="GatewayListNote">Required</span>
        </div>
        <div class="row">
            <select
                [(ngModel)]="gatewayId"
                [disabled]="disableGatewaySelect || !canEdit"
                [ngClass]="{ error: errors['endpointId'] }"
                id="GatewayList"
                placeholder="Select a Gateway"
            >
                <option [ngValue]="null">Select a Gateway</option>
                <option *ngFor="let gateway of ipHostGateways" [value]="gateway.id" id="{{ gateway.id }}">
                    {{ gateway.name }} {{ clusterCount > 0 ? gateway.typeTag : '' }}
                </option>
            </select>
        </div>

        <div class="row">
            <div class="half padRight">
                <div class="label row">
                    IP Address<span [hidden]="!errors['networkIp']" class="error" id="NetworkIpError">{{
                        networkIpErrorString
                    }}</span
                    ><span [hidden]="errors['networkIp']" class="note" id="NetworkIpNote">Required</span>
                </div>
                <div class="row">
                    <input
                        [(ngModel)]="model.networkIp"
                        [disabled]="!canEdit"
                        [ngClass]="{ error: errors['networkIp'] }"
                        class="restrictIp"
                        id="IPAddress"
                        placeholder="Example 10.0.1.20"
                        type="text"
                    />
                </div>
            </div>
            <div class="half">
                <div class="label row">
                    Port/Range<span
                        [hidden]="!errors['networkFirstPort'] && !errors['networkLastPort']"
                        class="error"
                        id="PortRangeError"
                        >{{ networkPortErrorString }}</span
                    ><span
                        [hidden]="errors['networkFirstPort'] || errors['networkLastPort']"
                        class="note"
                        id="PortRangeNote"
                        >Required</span
                    >
                </div>
                <div class="row">
                    <input
                        [(ngModel)]="networkPortRange"
                        [disabled]="!canEdit"
                        [ngClass]="{
                            error: errors['networkFirstPort'] || errors['networkLastPort']
                        }"
                        class="restrictNumeric"
                        id="FirstPort"
                        placeholder="80"
                        type="text"
                    />
                </div>
            </div>

            <div class="row">
                <div class="half padRight">
                    <div class="label row">
                        Intercept IP Address<span
                            [hidden]="!errors['interceptIp']"
                            class="error"
                            id="InterceptIpError"
                            >{{ interceptIpErrorString }}</span
                        >
                    </div>
                    <div class="row">
                        <input
                            [(ngModel)]="interceptIp"
                            [disabled]="!canEdit"
                            [ngClass]="{ error: errors['interceptIp'] }"
                            class="restrictIp"
                            id="InterceptAddress"
                            placeholder="{{
                                model.networkIp == null || model.networkIp === ''
                                    ? 'Example: 10.0.1.20'
                                    : model.networkIp
                            }}"
                            placeholder="10.0.1.20"
                            type="text"
                        />
                    </div>
                </div>

                <div class="half">
                    <div class="label row">
                        Intercept Port/Range<span
                            [hidden]="!errors['interceptFirstPort'] && !errors['interceptLastPort']"
                            class="error"
                            id="InterceptPortRangeError"
                            >{{ interceptPortErrorString }}</span
                        >
                    </div>
                    <div>
                        <div class="row">
                            <input
                                [(ngModel)]="interceptPortRange"
                                [disabled]="!canEdit"
                                [ngClass]="{
                                    error: errors['interceptFirstPort'] || errors['interceptLastPort']
                                }"
                                class="restrictNumeric"
                                id="InterceptFirstPort"
                                placeholder="{{
                                    networkPortRange == null || networkPortRange === '' ? '80' : networkPortRange
                                }}"
                                type="text"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="zitiEnabledService.zitiEnabled">
                <div class="half padRight">
                    <div class="label row">
                        Intercept DNS Host Name<span [hidden]="errors['interceptDnsHostname']" class="note zitiNote"
                            >For Ziti Capable Services</span
                        ><span [hidden]="!errors['interceptDnsHostname']" class="note error">Invalid</span>
                    </div>
                    <div class="row">
                        <input
                            [(ngModel)]="model.interceptDnsHostname"
                            [disabled]="!canEdit"
                            [ngClass]="{ error: errors['interceptDnsHostname'] }"
                            id="InterceptDnsHostName"
                            placeholder="Enter your intercept host name"
                            type="text"
                        />
                    </div>
                </div>

                <div class="half padRight">
                    <div class="label row">
                        Intercept Port<span [hidden]="errors['interceptDnsPort']" class="note zitiNote"
                            >For Ziti Capable Services</span
                        ><span [hidden]="!errors['interceptDnsPort']" class="note error">Invalid</span>
                    </div>
                    <div class="row">
                        <input
                            [(ngModel)]="model.interceptDnsPort"
                            [disabled]="!canEdit"
                            [ngClass]="{ error: errors['interceptDnsPort'] }"
                            id="InterceptDnsPort"
                            placeholder="Enter your DNS intercept port"
                            type="text"
                        />
                    </div>
                </div>
            </div>

            <div class="label row">
                Protocol Type<span [hidden]="!errors['serviceType']" class="error" id="ProtocolTypeError">Required</span
                ><span [hidden]="errors['serviceType']" class="note" id="ProtocolTypeNote">Required</span>
            </div>
            <div class="row">
                <select
                    [(ngModel)]="model.serviceType"
                    [disabled]="!canEdit"
                    [ngClass]="{ error: errors['serviceType'] }"
                    id="ProtocolList"
                    placeholder="Select a Protocol Type"
                >
                    <option value="">Select a Protocol Type</option>
                    <option value="TCP">TCP</option>
                    <option value="UDP">UDP</option>
                    <option value="ALL">TCP/UDP</option>
                </select>
            </div>
        </div>

        <div class="label row labelExpansion">Advanced Options<span class="note">Open to Edit Details</span></div>
        <div class="row expansion">
            <mat-accordion>
                <mat-expansion-panel #panel2 [hideToggle]="true" class="ipHost" id="IpHostAdvancedOptionsPanel">
                    <mat-expansion-panel-header [collapsedHeight]="'65px'">
                        <mat-panel-title class="expansionLabel">
                            <div class="title">
                                <div class="icon-AdvancedOptions"></div>
                            </div>
                            <div class="subtitle">Advanced Options</div>
                            <span
                                [ngClass]="{ open: panel2.expanded }"
                                class="expansion-indicator icon-Sort_Down"
                            ></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-grid-list cols="10" rowHeight="45px">
                        <mat-grid-tile [colspan]="9">
                            <div class="label">Transparency</div>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1">
                            <div class="toggleTile">
                                <div
                                    (click)="canEdit && toggleTransparency()"
                                    [ngClass]="{ on: transparencyEnabled }"
                                    class="toggle"
                                    id="IpHostTransparencyToggle"
                                >
                                    <span [hidden]="!transparencyEnabled" id="IpHostTransparencyToggleOn"
                                        >ON&nbsp;&nbsp;&nbsp;</span
                                    >
                                    <span [hidden]="transparencyEnabled" id="IpHostTransparencyToggleOff"
                                        >&nbsp;&nbsp;&nbsp;OFF</span
                                    >
                                    <div class="switch"></div>
                                </div>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="9">
                            <div class="label">Enable Permanent Connection</div>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1">
                            <div class="toggleTile">
                                <div
                                    (click)="canEdit && togglePermanentConnection()"
                                    [ngClass]="{ on: permanentConnectionEnabled }"
                                    class="toggle"
                                    id="PermanentConnectionToggle"
                                >
                                    <span [hidden]="!permanentConnectionEnabled" id="PermanentConnectionToggleOn"
                                        >ON&nbsp;&nbsp;&nbsp;</span
                                    >
                                    <span [hidden]="permanentConnectionEnabled" id="PermanentConnectionToggleOff"
                                        >&nbsp;&nbsp;&nbsp;OFF</span
                                    >
                                    <div class="switch"></div>
                                </div>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="9">
                            <div class="label">Data Interleaving</div>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1">
                            <div class="toggleTile">
                                <div
                                    (click)="canEdit && toggleDataInterleavingEnabled()"
                                    [ngClass]="{ on: dataInterleavingEnabled }"
                                    class="toggle"
                                    id="DataInterleavingToggle"
                                >
                                    <span [hidden]="!dataInterleavingEnabled" id="DataInterleavingToggleOn"
                                        >ON&nbsp;&nbsp;&nbsp;</span
                                    >
                                    <span [hidden]="dataInterleavingEnabled" id="DataInterleavingToggleOff"
                                        >&nbsp;&nbsp;&nbsp;OFF</span
                                    >
                                    <div class="switch"></div>
                                </div>
                            </div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="row right buttonRow" style="margin-top: 20px">
            <button (click)="save()" *ngIf="!processing && canEdit" class="save" id="SaveServiceButton">
                <span *ngIf="isEditing">Save</span>
                <span *ngIf="isAdding">Create</span>
            </button>
            <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
        </div>
    </div>

    <div (click)="hideForm()" class="buttonBall close" id="CloseServiceButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div (click)="goBack()" [hidden]="!allowBack" class="buttonBall back" id="BackServiceButton">
        <div class="buttonLabel">BACK</div>
    </div>
</div>
