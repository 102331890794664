import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterString',
})
export class FilterStringPipe implements PipeTransform {
    transform(items: any[], args: any): any {
        return items.filter((item) => item !== args);
    }
}
