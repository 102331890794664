import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateFiltersComponent } from '@netfoundry-ui/feature/date-filters';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Network } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    AppwanService,
    ClientService,
    FeatureService,
    GatewayClusterService,
    GatewayService,
    GroupService,
    RefresherService,
    ServiceService,
} from '@netfoundry-ui/shared/services';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const refreshInterval = 60000;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [FromDatePipe, DateFiltersComponent, ClientService, GatewayService],
})
export class DashboardComponent implements OnInit, OnDestroy {
    // Remove later
    isBillingEnabled = false;
    endTime: number = Date.now();
    startTime: number = this.endTime - 24 * 60 * 60 * 1000;
    currentTimeFilter = '24h';
    directionFilter: string[] = ['DropTcpTx', 'DropUdpTx'];
    endpointFilter: any = false;
    endpointNameFilter: any = false;
    totalGateways = 0;
    totalClusters = 0;
    totalClients = 0;
    totalServices = 0;
    totalGroups = 0;
    totalAppWans = 0;
    cycleStart = moment().startOf('month').startOf('day');
    canceled = false;
    public currentNetwork = new Network({});
    currentDateFilter = '24h';
    // declaring the id as its own variable and initializing it to null to avoid errors when the page is still in the process of loading
    currentNetworkId = null;
    gatewayCountSub = new Subscription();
    clusterCountSub = new Subscription();
    clientCountSub = new Subscription();
    groupCountSub = new Subscription();
    serviceCountSub = new Subscription();
    appwanCountSub = new Subscription();
    canListEndpoints = true;
    canListGroups = true;
    canListServices = true;
    canListAppWans = true;
    canListClusters = true;
    healthView = 'uptime';
    private subscription = new Subscription();

    constructor(
        private fromDate: FromDatePipe,
        public apiService: ApiService,
        private serviceService: ServiceService,
        private gatewayService: GatewayService,
        private clientService: ClientService,
        private groupService: GroupService,
        private appwanService: AppwanService,
        private refresher: RefresherService,
        public featureService: FeatureService,
        public authorizationService: AuthorizationService,
        private gatewayClusterService: GatewayClusterService
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentNetwork.pipe(debounceTime(500)).subscribe((data) => {
                this.currentNetwork = data;
                this.currentNetworkId = this.currentNetwork.id;

                this.appwanCountSub.closed = true;
                this.serviceCountSub.closed = true;
                this.gatewayCountSub.closed = true;
                this.clientCountSub.closed = true;
                this.groupCountSub.closed = true;
                this.clusterCountSub.closed = true;

                this.updatePermissions();
                this.getCounts();
            })
        );

        this.subscription.add(
            this.apiService.addedFromAnywhere.subscribe((result) => {
                this.getCounts();
            })
        );

        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, refreshInterval);
    }

    ngOnDestroy() {
        this.canceled = true;
        this.subscription.unsubscribe();
        this.refresher.disableRefresh();
        this.appwanCountSub.unsubscribe();
        this.serviceCountSub.unsubscribe();
        this.clientCountSub.unsubscribe();
        this.gatewayCountSub.unsubscribe();
        this.groupCountSub.unsubscribe();
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setStartTime(value) {
        this.startTime = this.fromDate.transform(value);
        this.endTime = Date.now();

        // if the filter is 1m, 6m, or 12m, we should display from the start of one month to the end of another
        if (value === '1m' || value === '6m' || value === '12m') {
            // the start time should be set to the start of the first month by the fromDate pipe
            //  setting teh end time to the end of the current month manually
            this.endTime = moment(this.endTime).endOf('month').valueOf();
        }
        this.currentDateFilter = value;
        this.refresher.disableRefresh();
        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, refreshInterval);
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setEndTime(value) {
        // TODO - process date field into ms number
        this.endTime = this.fromDate.transform(value);
    }

    /**
     * Set the filter for all Feature
     * @param value
     */
    public setEndpointFilter(value) {
        if (value === 'Clients') {
            this.endpointFilter = 'Client';
        } else if (value === 'Gateways') {
            this.endpointFilter = 'Gateway';
        } else {
            this.endpointFilter = false;
        }

        // clear the name filter so that it doesn't get lost
        this.endpointNameFilter = false;
    }

    /**
     * Set the filter for all child Feature
     * @param value
     */
    public setDirectionFilter(value) {
        if (value === 'tx') {
            this.directionFilter = ['DropTcpTx', 'DropUdpTx'];
        } else {
            this.directionFilter = ['DropTcpRx', 'DropUdpRx'];
        }
    }

    /**
     * Sets the observable value
     * @param value
     */
    public setEndpointNameFilter(value) {
        this.endpointNameFilter = value;
    }

    refresh() {
        this.refresher.disableRefresh();

        // if the filter is 1m, 6m, or 12m, then we need to update the start/end times a bit differently as
        //  these filters start at a begining of one month and end at the end of another
        if (this.currentDateFilter !== '1m' && this.currentDateFilter !== '6m' && this.currentDateFilter !== '12m') {
            const old = this.endTime;
            this.endTime = Date.now();
            // Scroll forward the start time as well. If we're on 24 hour selection, the graph resolution will change if over 24 hours.
            const timePassed = this.endTime - old;
            this.startTime = this.startTime + timePassed;
        } else {
            // number between 0 and 11 representing the current month
            const currentMonth = moment().month();

            // number between 0 and 11 representing the month of the old end time
            const oldEndMonth = moment(this.endTime).month();

            // if the current month is greater than the old end month by 1 or if the current month is 0 and the old month is 11 (year rolled over)
            //  update the start/end times
            if (currentMonth === oldEndMonth + 1 || (currentMonth === 0 && oldEndMonth === 11)) {
                this.setStartTime(this.currentDateFilter);
            }
        }

        this.cycleStart = moment().startOf('month').startOf('day');

        this.getCounts();

        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, refreshInterval);
    }

    setHealthView(value) {
        this.healthView = value;
    }

    private updatePermissions() {
        this.canListAppWans = this.authorizationService.canListAppWans();
        this.canListServices = this.authorizationService.canListServices();
        this.canListEndpoints = this.authorizationService.canListEndpoints();
        this.canListGroups = this.authorizationService.canListEndpointGroups();
        this.canListClusters = this.authorizationService.canListGatewayClusters();
    }

    private initAppwanCount(page, size) {
        this.appwanCountSub = this.appwanService.get(page, undefined, undefined, size).subscribe((result) => {
            this.totalAppWans = this.appwanService.page != null ? this.appwanService.page.totalElements : 0;
        });
    }

    private initServiceCount(page, size) {
        this.serviceCountSub = this.serviceService.get(page, undefined, undefined, size).subscribe((result) => {
            this.totalServices = this.serviceService.page != null ? this.serviceService.page.totalElements : 0;
        });
    }

    private initEndpointCount(page, size) {
        this.clientCountSub = this.clientService.get(page, undefined, undefined, size).subscribe((result) => {
            this.totalClients = this.clientService.page != null ? this.clientService.page.totalElements : 0;
        });

        this.gatewayCountSub = this.gatewayService.get(page, undefined, undefined, size).subscribe((result) => {
            this.totalGateways = this.gatewayService.page != null ? this.gatewayService.page.totalElements : 0;
        });
    }

    private initClusterCount(page, size) {
        this.clusterCountSub = this.gatewayClusterService.get(page, undefined, undefined, size).subscribe((result) => {
            this.totalClusters =
                this.gatewayClusterService.page != null ? this.gatewayClusterService.page.totalElements : 0;
        });
    }

    private initGroupCount(page, size) {
        this.groupCountSub = this.groupService.get(page, undefined, undefined, size).subscribe((result) => {
            this.totalGroups = this.groupService.page != null ? this.groupService.page.totalElements : 0;
        });
    }

    private getCounts() {
        const page = 0;
        const size = 1;

        if (this.canListAppWans) {
            if (this.appwanCountSub.closed) {
                this.initAppwanCount(page, size);
            } else {
                this.appwanService.get(page, undefined, undefined, size);
            }
        } else {
            this.totalAppWans = 0;
            this.appwanCountSub.unsubscribe();
        }

        if (this.canListServices) {
            if (this.serviceCountSub.closed) {
                this.initServiceCount(page, size);
            } else {
                this.serviceService.get(page, undefined, undefined, size);
            }
        } else {
            this.totalServices = 0;
            this.serviceCountSub.unsubscribe();
        }

        if (this.canListEndpoints) {
            if (this.gatewayCountSub.closed && this.clientCountSub.closed) {
                this.initEndpointCount(page, size);
            } else {
                this.gatewayService.get(page, undefined, undefined, size);
                this.clientService.get(page, undefined, undefined, size);
            }
        } else {
            this.totalGateways = 0;
            this.totalClients = 0;
            this.gatewayCountSub.unsubscribe();
            this.clientCountSub.unsubscribe();
        }

        if (this.canListClusters) {
            if (this.clusterCountSub.closed) {
                this.initClusterCount(page, size);
            } else {
                this.gatewayClusterService.get(page, undefined, undefined, size);
            }
        } else {
            this.totalClusters = 0;
            this.clusterCountSub.unsubscribe();
        }

        if (this.canListGroups) {
            if (this.groupCountSub.closed) {
                this.initGroupCount(page, size);
            } else {
                this.groupService.get(page, undefined, undefined, size);
            }
        } else {
            this.totalGroups = 0;
            this.groupCountSub.unsubscribe();
        }
    }
}
