<div class="row mini-pod" style="margin-bottom: 0">
    <div class="label row header">LISTEN OPTIONS</div>
    <span class="note">OPTIONAL</span>
    <div class="row-half">
        <div style="margin-right: 10px">
            <div [ngClass]="{ error: errors['connectTimeoutSeconds'] }" class="label">CONNECTION TIMEOUT</div>
            <input
                [(ngModel)]="model.data.listenOptions.connectTimeoutSeconds"
                [disabled]="readOnly"
                [ngClass]="{ error: errors['timeout'] }"
                class="connect-timeout-seconds"
                id="EditTimeout"
                placeholder="90 Seconds"
                type="number"
            />
        </div>
        <div>
            <div [ngClass]="{ error: errors['maxConnections'] }" class="label">MAX CONNECTIONS</div>
            <input
                [(ngModel)]="model.data.listenOptions.maxConnections"
                [disabled]="readOnly"
                [ngClass]="{ error: errors['maxConnections'] }"
                class="max-connections"
                id="EditMaxConnections"
                placeholder="3"
                type="number"
            />
        </div>
    </div>
    <div class="row-half">
        <div [ngClass]="{ error: errors['terminatorType'] }" class="label">Terminator Identifier Strategy</div>
        <div style="margin-right: 10px">
            <div class="label spacer"></div>
            <select
                (change)="terminatorTypeChanged()"
                [(ngModel)]="terminatorType"
                [disabled]="readOnly"
                [ngClass]="{ error: errors['terminatorType'] }"
                class="service-config-select"
                id="ServiceConfigTypes"
            >
                <option value="fixed">Fixed</option>
                <option value="edge">Edge</option>
            </select>
        </div>
        <div>
            <div class="label spacer"></div>
            <input
                *ngIf="terminatorType === 'fixed'"
                [(ngModel)]="model.data.listenOptions.identity"
                [disabled]="readOnly"
                [ngClass]="{ error: errors['identity'] }"
                id="EditFixedIdentifier"
                placeholder="fixed-identifier"
                type="text"
            />
        </div>
    </div>
</div>
