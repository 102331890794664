import { Inject, Injectable, ɵComponentType } from '@angular/core';
import { ApiService, FeatureService, NetworkVersionService } from '@netfoundry-ui/shared/services';
import {
    AppWanServiceV2,
    EndpointServiceV2,
    NETWORK_SERVICE,
    NetworkServiceV2,
    PlatformServiceService,
} from '@netfoundry-ui/shared/apiv2';
import { delay, isEmpty, set } from 'lodash';

import { saveAs } from 'file-saver';
import { debounceTime } from 'rxjs/operators';
import { EndpointV2, ENROLLMENT_DIALOG, Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { ReplaySubject } from 'rxjs';
import { TunnelersFormComponent } from '@netfoundry-ui/shared/tunnelers-form';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';

declare const gtag;

@Injectable({ providedIn: 'root' })
export class SampleServiceService {
    currentOrg: any = {};
    currentNetwork: any = {};
    autoFabric: any = [];
    sampleService: any;
    demoEndpoint: any;
    demoService: any;
    demoAppWAN: any;
    endpoint: any = {};
    tutorialService: any;
    tutorialAppWan: any;
    endpointCreated = false;
    jwtTimeout = 0;
    jwtAssigned = false;
    SAMPLE_SERVICE_NAME = 'Sample_Service';
    SAMPLE_SERVICE_ENDPOINT_NAME = 'Sample_Service_Endpoint';

    isLoadingSource = new ReplaySubject<boolean>(1);
    isLoading = this.isLoadingSource.asObservable();
    isLoadingIs = false;

    sampleServiceAsCode = {
        edgeRouters: [],
        edgeRouterPolicies: [],
        networkId: '',
        name: '',
        services: [
            {
                name: this.SAMPLE_SERVICE_NAME,
                encryptionRequired: true,
                attributes: [],
                modelType: 'TunnelerToEdgeRouter',
                model: {
                    clientIngress: {
                        host: 'sample.tools.netfoundry.io',
                        port: 443,
                    },
                    edgeRouterAttributes: [],
                    edgeRouterHosts: [
                        {
                            edgeRouterId: '',
                            serverEgress: {
                                protocol: 'tcp',
                                host: 'splash.tools.netfoundry.io',
                                port: 443,
                            },
                        },
                    ],
                },
            },
        ],
        certificateAuthorities: [],
        endpoints: [],
        appWans: [
            {
                networkId: null,
                name: this.SAMPLE_SERVICE_NAME + '_AppWAN',
                type: 'Dial',
                semantic: 'AnyOf',
                serviceAttributes: ['@' + this.SAMPLE_SERVICE_NAME],
                endpointAttributes: ['@Sample_Service_Endpoint'],
                postureCheckAttributes: [],
            },
        ],
        postureChecks: [],
    };

    dialogRef;

    constructor(
        public networkVersionService: NetworkVersionService,
        public endpointServiceV2: EndpointServiceV2,
        public appWanServiceV2: AppWanServiceV2,
        public apiService: ApiService,
        public growlerService: GrowlerService,
        public dialogForm: MatDialog,
        public featureService: FeatureService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private platformServiceService: PlatformServiceService,
        @Inject(ENVIRONMENT) public environment: Environment,
        @Inject(ENROLLMENT_DIALOG) private enrollmentDialog: ɵComponentType<any>
    ) {
        this.apiService.currentOrg.pipe().subscribe((org) => {
            this.currentOrg = org;
        });
        this.apiService.currentNetwork.pipe(debounceTime(1000)).subscribe((network) => {
            this.currentNetwork = network;
            this.getSampleEndpoint();
            this.getSampleService();
        });
        this.apiService.currentAutoFabric.pipe().subscribe((autoFabric) => {
            this.autoFabric = autoFabric;
        });
    }

    getSampleService() {
        this.platformServiceService.getServicesPage(this.getServiceOptions()).then((result) => {
            if (result?.length > 0) {
                this.sampleService = result[0];
            }
        });
    }

    getSampleEndpoint() {
        this.endpointServiceV2.getEndpointPage(this.getOptions()).then((endpoints) => {
            if (endpoints?.length > 0) {
                for (let i = 0; i < endpoints.length; i++)
                    if (endpoints[i].name === this.SAMPLE_SERVICE_ENDPOINT_NAME && endpoints[i].jwt) {
                        this.endpoint = endpoints[i];
                        break;
                    }
            }
        });
    }

    createSampleEndpoint() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: 'createSampleEndpoint',
            });
        if (isEmpty(this.endpoint?.jwt)) {
            this.setIsLoading(true);
            const ep = new EndpointV2();
            ep.selected = false;
            ep.attributes = [];
            ep.name = this.SAMPLE_SERVICE_ENDPOINT_NAME;
            ep.enrollmentMethod = { ott: true };
            ep.networkId = this.currentNetwork.id;
            ep.sessionIdentityId = '';
            this.endpointServiceV2
                .createResource({ body: ep })
                .toPromise()
                .then((ep: any) => {
                    this.endpoint = ep;
                    this.jwtTimeout = new Date().getUTCDate() + 20000;
                    this.setIsLoading(true);
                    this._checkForJWT(this.endpoint);
                });
        } else {
            this.downloadJWT();
        }
    }

    openEnrollmentInstructions() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: 'openEnrollmentInstructions',
            });
        this.dialogRef = this.dialogForm.open(this.enrollmentDialog, {
            data: {},
            //minHeight: '100%',
            //minWidth: '100%',
            height: '630px',
            width: '1300px',
        });
    }

    getTutorialService() {
        return this.tutorialService;
    }

    getOptions() {
        const params = {
            params: {
                networkId: this.currentNetwork.id,
            },
            pageParams: { size: 25, page: 0 },
        };
        return params;
    }

    getServiceOptions() {
        const params = {
            params: {
                name: this.SAMPLE_SERVICE_NAME,
                networkId: this.currentNetwork.id,
            },
            pageParams: { size: 25, page: 0 },
        };
        return params;
    }

    openZitiTunnelers() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: 'openZitiTunnelers',
            });
        this.dialogRef = this.dialogForm.open(TunnelersFormComponent, {
            data: {},
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        //window.open(this.networkVersionService.getDownloadsLink() + '#zititunnelers');
    }

    downloadJWT() {
        const name = this.endpoint.name + '.jwt';
        const jwt = new Blob([this.endpoint.jwt], { type: 'text/plain;charset=utf-8' });
        saveAs(jwt, name);
        if (!this.sampleService) {
            this.createSampleService();
        }
    }

    testSampleService() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: 'testSampleService',
            });
        window.open(this.environment.sampleServiceUrl, '_blank');
    }

    setIsLoading(isLoading: boolean) {
        this.isLoadingSource.next(isLoading);
        this.isLoadingIs = isLoading;
    }

    createSampleService() {
        if (!this.autoFabric || this.autoFabric.length <= 0) {
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Fabric Not Ready',
                    'Network auto fabric creation has not finished yet. Please try again later.'
                )
            );
            return;
        }
        set(this.sampleServiceAsCode, 'services[0].model.edgeRouterHosts[0].edgeRouterId', this.autoFabric[0].id);
        this.sampleServiceAsCode.networkId = this.currentNetwork.id;
        this.sampleServiceAsCode.name = this.currentNetwork.name;
        this.networkServiceV2
            .createResources(this.currentNetwork.id, this.sampleServiceAsCode, 'application/json')
            .toPromise()
            .then((result) => {
                console.log(result);
            });
    }

    hasTutorialEntities() {
        let options = this.getServiceOptions();
        delete options.params.name;
        options.params['attribute'] = 'nf_tutorial';
        let epFound = false;
        let svcFound = false;
        let awFound = false;
        const epPromise = this.endpointServiceV2.getEndpointPage(options).then((result) => {
            epFound = result?.length > 0;
            return epFound;
        });
        options = this.getServiceOptions();
        delete options.params.name;
        options.params['attribute'] = '#nf_tutorial';
        const svcPromise = this.platformServiceService.getServicesPage(options).then((result) => {
            if (result.length > 0) {
                this.tutorialService = result[0];
            }
            svcFound = result.length > 0;
            return svcFound;
        });
        options.params['attribute'] = '#nf_tutorial';
        const awPromise = this.appWanServiceV2.getAppWanPage(options).then((result) => {
            if (result.length > 0) {
                this.tutorialAppWan = result[0];
            }
            awFound = result.length > 0;
            return awFound;
        });
        return Promise.all([epPromise, svcPromise, awPromise]).then(() => epFound || svcFound || awFound);
    }

    async cleanupSampleService(steps = []) {
        const hasEntities = await this.hasTutorialEntities();
        if (!hasEntities) {
            return Promise.resolve();
        }
        const data = {
            title: 'Remove Entities',
            appendId: 'TutorialCleanup',
            useInnerHtml: true,
            subtitle: `It looks like you've done this tutorial before. Would you like us to remove the entities you created the last time the tutorial was run?
                 <p>This will delete any <b>Endpoint</b>, <b>Service</b>, and <b>AppWAN</b> that was created the last time you ran the tutorial.</p>`,
            bulletList: [],
            icon: 'Confirm',
            action: 'Yes, Delete Them',
            cancelAction: "No, I'll remove these items later",
        };
        this.dialogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '300px',
            width: '600px',
            autoFocus: false,
        });
        return this.dialogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                if (result) {
                    this.tutorialService = undefined;
                    this.deleteSampleEntities();
                    this.clearStepHistory(steps);
                    return true;
                }
                return false;
            });
    }

    clearStepHistory(steps = []) {
        steps.forEach((step) => {
            localStorage.removeItem(this.currentOrg?.name + '_' + step.label + '_complete');
        });
    }

    deleteSampleEntities() {
        const options = this.getServiceOptions();
        delete options.params.name;
        options.params['attribute'] = '#nf_tutorial';
        this.platformServiceService.getServicesPage(options).then((result) => {
            if (result?.length > 0) {
                this.demoService = result[0];
                this.platformServiceService.deleteResource(this.demoService).toPromise();
            }
        });
        options.params['attribute'] = 'nf_tutorial';
        this.endpointServiceV2.getEndpointPage(options).then((result) => {
            if (result?.length > 0) {
                this.demoEndpoint = result[0];
                this.endpointServiceV2.deleteResource(this.demoEndpoint).toPromise();
            }
        });
        options.params['attribute'] = '#nf_tutorial';
        this.appWanServiceV2.getAppWanPage(options).then((result) => {
            if (result?.length > 0) {
                this.demoAppWAN = result[0];
                this.appWanServiceV2.deleteResource(this.demoAppWAN).toPromise();
            }
        });
    }

    _checkForJWT(endpointId) {
        const currentTime = new Date().getUTCDate();
        this.endpointServiceV2.getEndpoint(this.endpoint.id).then(
            (data: EndpointV2) => {
                if (isEmpty(data.jwt) && !this.endpointCreated && currentTime < this.jwtTimeout) {
                    delay(() => {
                        this._checkForJWT(endpointId);
                    }, 500);
                } else {
                    this.endpoint = data;
                    this.jwtAssigned = true;
                    this.setIsLoading(false);
                    if (isEmpty(data.jwt) && currentTime >= this.jwtTimeout) {
                        new GrowlerData(
                            'error',
                            'Endpoint Creation Timeout.',
                            'Timed out waiting for the JWT to become available for the sample endpoint'
                        );
                    } else {
                        this.downloadJWT();
                    }
                }
            },
            (httpErrorResponse) => {
                this.setIsLoading(false);
                this.growlerService.show(
                    new GrowlerData('error', 'Endpoint update request failed. ', httpErrorResponse.error.errors[0])
                );
            }
        );
    }

    getTunnelerItems() {
        const items = [
            {
                os: 'windows',
                title: 'Ziti Desktop Edge',
                buttonText: 'Select',
                helpText:
                    'Follow the instructions for endpoint enrollment using the Ziti Desktop Edge for <b>Windows</b>',
                icon: '/assets/images/tunnelers/Windows-Tunneler.jpg',
                target: '_self',
                useButton: true,
                link: '',
            },
            {
                os: 'mac',
                title: 'Ziti Desktop Edge',
                buttonText: 'Select',
                helpText:
                    'Follow the instructions for endpoint enrollment using the Ziti Desktop Edge for <b>MacOS</b>',
                icon: '/assets/images/tunnelers/Mac-Tunneler.jpg',
                useButton: true,
                link: '',
            },
            {
                os: 'other',
                title: 'Using something else?',
                buttonText: "Go to Doc's",
                helpText: 'Click the link below to find documentation for all other supported Ziti tunnelers.',
                icon: '/assets/svgs/icon.svg',
                iconStyle: 'max-width: 125px;',
                link: 'https://support.netfoundry.io/hc/en-us/sections/360002445391-Endpoints',
            },
        ];
        return items;
    }
}
