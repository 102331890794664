import { Injectable } from '@angular/core';
import { AzureVirtualWanSite, Network } from '@netfoundry-ui/shared/model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class AzureVirtualWanSiteService extends MopResourceService {
    azureVirtualWanSiteList: AzureVirtualWanSite[];
    private azureVirtualWanSitesSource = new Subject<AzureVirtualWanSite[]>();
    azureVirtualWanSites = this.azureVirtualWanSitesSource.asObservable();
    private apiSub = new Subscription();
    private mapSub = new Subscription();

    /**
     * Gets all azureVirtualWans for azureSubscription
     */
    get(): Observable<any> {
        if (this.apiSub) {
            this.apiSub.unsubscribe();
        }

        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }
        // watch for when the network updates, when it does, update the gateways observable
        this.apiSub = this.apiService.currentNetwork.subscribe((network) => {
            this.mapSub = this.apiService
                .getLinkedResource(new Network(network), 'virtualWanSites')
                .pipe(map(this._extractEmbedded))
                .subscribe((data) => {
                    this.azureVirtualWanSitesSource.next(data);
                });
        });

        // this is an observable that watches for azure subscription changes
        return this.azureVirtualWanSites;
    }

    /**
     * Makes the request to deploy the avw site
     * @param siteModel the avw site to deploy
     */
    deploy(siteModel: AzureVirtualWanSite) {
        const deployUrl = `${siteModel.getSelfLink()}/deploy`;
        return this.apiService.put(deployUrl);
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const azureVirtualWanSites: AzureVirtualWanSite[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['azureVirtualWanSites'] !== undefined) {
            for (let i = 0; i < res['_embedded']['azureVirtualWanSites'].length; i++) {
                const azureSubscription = res['_embedded']['azureVirtualWanSites'][i];
                azureVirtualWanSites.push(new AzureVirtualWanSite(azureSubscription));
            }

            return azureVirtualWanSites;
        }

        return [];
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return this.getLinkedResourceUrl(this.currentNetworkModel, 'virtualWanSites');
    }
}
