import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttributesService, AttributesServiceResults, EdgeRouterPolicyServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { ZitiOptionsService } from '@netfoundry-ui/shared/helpers';
import { EdgeRouterPolicyV2, NetworkV2, PagedAttributes } from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, LoggerService, ValidateService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';
import _ from 'lodash';

@Component({
    selector: 'app-ziti-edge-router-policy',
    templateUrl: './ziti-edge-router-policy.component.html',
    styleUrls: ['./ziti-edge-router-policy.component.scss'],
})
export class ZitiEdgeRouterPolicyComponent implements OnInit, OnDestroy {
    public model: EdgeRouterPolicyV2;
    regions: Location[] = [];
    isComplete = false;
    errorName = false;
    errorNameLength = false;
    editId = '';
    processing = false;
    hideHelp = false;
    isInline = false;
    edgeRouterAttributes = new PagedAttributes();
    endpointAttributes = new PagedAttributes();
    isEditing = false;
    bulkEdit = false;
    errors = {};
    // to display under the preview section
    v2Endpoints = [];
    edgeRouters = [];

    allEndpoints = [];
    allEdgeRouters = [];

    // to display along with the attributes options in the tag selector
    v2EndpointNames = [];
    v2EdgeRouterNames = [];

    canEdit = false;

    currentNetwork: NetworkV2;
    relatedSubscription = new Subscription();
    selectedEndpointAttributes = new PagedAttributes();
    endpointAttributeError: boolean;
    isEndpointsPreview: boolean;
    invalidWords: string[] = [];
    selectedEdgeRouterAttributes = new PagedAttributes();
    edgeRouterError: boolean;
    isEdgeRouterPreview: boolean;
    endpointError: boolean;
    isLoadingEndpointAttr: boolean;
    isLoadingEdgeRouterAttr: boolean;
    isLoading: boolean;
    isAllEdgeRouters: boolean;
    isAllEndpoints: boolean;
    erAttributeError = false;
    epAttributeError = false;

    constructor(
        private logger: LoggerService,
        private dialogRef: MatDialogRef<ZitiEdgeRouterPolicyComponent>,
        private edgeRouterPolicyServiceV2: EdgeRouterPolicyServiceV2,
        private attributesService: AttributesService,
        private apiService: ApiService,
        public validateService: ValidateService,
        public growlerService: GrowlerService,
        private authorizationService: AuthorizationService,
        private optionsService: ZitiOptionsService,
        public featureService: FeatureService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnDestroy(): void {
        this.relatedSubscription.unsubscribe();
    }

    ngOnInit() {
        this.initModel();
        this.currentNetwork = this.apiService.currentNetwork.getValue();
        if (this.currentNetwork) {
            this.model.networkId = this.currentNetwork.id;
            this.initializeEdgeRouterSelector();
            this.initializeEndpointSelector();
        }
    }

    hide(response?) {
        this.dialogRef.close(response);
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
        this.hide();
    }

    async save() {
        this.model.edgeRouterAttributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        this.model.endpointAttributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());

        if (await this.validate()) {
            if (this.bulkEdit) {
                this.dialogRef.close();
            } else if (this.model.id) {
                this.edgeRouterPolicyServiceV2.updateResource(this.model).subscribe(
                    () => {
                        this.processing = false;
                        this.dialogRef.close();
                    },
                    (httpErrorResponse) => {
                        this.processing = false;
                        this.logger.error('Error from edge router policy updation', httpErrorResponse);
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Edge Router update request failed. ',
                                httpErrorResponse.error.errors[0]
                            )
                        );
                    }
                );
            } else {
                this.edgeRouterPolicyServiceV2.createResource({ body: this.model }).subscribe(
                    () => {
                        this.processing = false;
                        this.dialogRef.close();
                    },
                    (httpErrorResponse) => {
                        this.processing = false;
                        this.logger.error('Error from edge router policy creation', httpErrorResponse);
                        this.growlerService.show(
                            new GrowlerData('error', 'Edge Router creation failed. ', httpErrorResponse.error.errors[0])
                        );
                    }
                );
            }
        }
    }

    async validate() {
        this.errors = {};
        this.erAttributeError = false;
        this.epAttributeError = false;

        if (!this.model.id) {
            const errors = await this.edgeRouterPolicyServiceV2.validateCreate(this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.endpointAttributes')) {
                this.epAttributeError = true;
                this.errors['endpointAttributes'] = _.find(errors, (e) => e.path === '$.endpointAttributes').message;
            }
            if (_.find(errors, (e) => e.path === '$.edgeRouterAttributes')) {
                this.erAttributeError = true;
                this.errors['edgeRouterAttributes'] = _.find(
                    errors,
                    (e) => e.path === '$.edgeRouterAttributes'
                ).message;
            }
            return errors.length === 0;
        } else {
            const errors = await this.edgeRouterPolicyServiceV2.validateUpdate(this.model.id, this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.endpointAttributes')) {
                this.epAttributeError = true;
                this.errors['endpointAttributes'] = _.find(errors, (e) => e.path === '$.endpointAttributes').message;
            }
            if (_.find(errors, (e) => e.path === '$.edgeRouterAttributes')) {
                this.erAttributeError = true;
                this.errors['edgeRouterAttributes'] = _.find(
                    errors,
                    (e) => e.path === '$.edgeRouterAttributes'
                ).message;
            }
            return errors.length === 0;
        }
    }

    addEdgeRouterAttribute(newAttribute) {
        const success = this.attributesService.addAttributes(
            newAttribute,
            this.invalidWords,
            this.selectedEdgeRouterAttributes
        );

        if (success) this.selectedEdgeRouterAttributes = success as PagedAttributes;
        const selList = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());

        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.edgeRouterAttributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        this.validate();
        this.getEdgeRouterPreviewList(namedList, toBeExploded);
    }

    getEdgeRouterPreviewList(namedList: string[], toBeExploded: string[]) {
        this.isAllEdgeRouters = false;
        this.isEdgeRouterPreview = true;
        if (toBeExploded.length === 0) {
            this.edgeRouters = namedList;
            this.isEdgeRouterPreview = false;
        } else if (toBeExploded.indexOf('#all') >= 0) {
            this.edgeRouters = this.allEdgeRouters.map((rec) => rec.name.substr(1));
            this.isAllEdgeRouters = true;
            this.isEdgeRouterPreview = false;
        } else {
            this.attributesService
                .getByAttribute(this.currentNetwork.id, 'edge-routers', toBeExploded)
                .then((results) => {
                    let tmpResults = results['_embedded']['edgeRouterList'].map((rec) => rec['name']);
                    tmpResults = [...namedList, ...tmpResults];

                    if (tmpResults?.length > 0) {
                        this.edgeRouters = tmpResults.sort();
                    }
                })
                .finally(() => {
                    this.isEdgeRouterPreview = false;
                });
        }
    }

    removeEdgeRouterAttribute(oldAttribute) {
        this.selectedEdgeRouterAttributes = this.attributesService.removeAttribute(
            oldAttribute,
            this.selectedEdgeRouterAttributes
        );
        const selList = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.edgeRouterAttributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        this.validate();
        this.getEdgeRouterPreviewList(namedList, toBeExploded);
    }

    addEndpointAttribute(newAttribute) {
        const success = this.attributesService.addAttributes(newAttribute, [], this.selectedEndpointAttributes);

        if (success) this.selectedEndpointAttributes = success as PagedAttributes;
        else this.endpointAttributeError = true;
        const selList = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.endpointAttributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        this.validate();
        this.getEndpointPreviewList(namedList, toBeExploded);
    }

    removeEndpointAttribute(oldAttribute) {
        this.selectedEndpointAttributes = this.attributesService.removeAttribute(
            oldAttribute,
            this.selectedEndpointAttributes
        );
        const selList = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.endpointAttributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        this.validate();
        this.getEndpointPreviewList(namedList, toBeExploded);
    }

    private initModel() {
        if (this.data.model) {
            this.model = this.data.model;
            this.bulkEdit = this.data.bulkEdit;
            this.isEditing = !this.bulkEdit;
            this.canEdit = this.authorizationService.canUpdateEdgeRouterPolicy(this.model.id);
        } else {
            this.model = new EdgeRouterPolicyV2();
        }

        if (!this.model.endpointAttributes) {
            this.model.endpointAttributes = [];
        }
        if (!this.model.edgeRouterAttributes) {
            this.model.edgeRouterAttributes = [];
        }
        if (this.bulkEdit) {
            this.validate();
        }
    }

    private getEndpointPreviewList(namedList: string[], toBeExploded: string[]) {
        this.isAllEndpoints = false;
        this.isEndpointsPreview = true;
        if (toBeExploded.length === 0) {
            this.v2Endpoints = namedList;
            this.isEndpointsPreview = false;
        } else if (toBeExploded.indexOf('#all') >= 0) {
            this.v2Endpoints = this.allEndpoints.map((rec) => rec.name.substr(1));
            this.isAllEndpoints = true;
            this.isEndpointsPreview = false;
        } else {
            this.attributesService
                .getByAttribute(this.currentNetwork.id, 'endpoints', toBeExploded)
                .then((results) => {
                    let tmpResults;
                    if (_.isArray(results)) {
                        tmpResults = results.map((rec) => rec['name']);
                    } else {
                        tmpResults = results['_embedded']['endpointList'].map((rec) => rec['name']);
                    }

                    tmpResults = [...namedList, ...tmpResults];

                    if (tmpResults?.length > 0) {
                        this.v2Endpoints = tmpResults.sort();
                    }
                })
                .finally(() => {
                    this.isEndpointsPreview = false;
                });
        }
    }

    private initializeEndpointSelector() {
        this.isLoadingEndpointAttr = true;
        this.getSelectedEndpoints();
        this.endpointError = false;
        this.attributesService
            .getEndpointAttributes(this.currentNetwork, false)
            .then((results: AttributesServiceResults) => {
                const collector = results.namedAttributes;
                this.allEndpoints = Array.from(results.namedAttributes.mappedAtrributes.values());
                if (this.isAllEndpoints) {
                    this.v2Endpoints = this.allEndpoints.map((rec) => rec.name.substr(1));
                }
                for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                    collector.mappedAtrributes.set(sa.name, sa);
                }
                this.endpointAttributes = collector;
            })
            .catch((err) => {
                this.endpointError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingEndpointAttr = false;
                this.isLoading = this.isLoadingEndpointAttr || this.isLoadingEdgeRouterAttr;
            });
    }

    private getSelectedEndpoints() {
        const selectedEndpoints = new PagedAttributes();
        const toBeExploded = [];
        const namedList = [];
        this.model.endpointAttributes.forEach((att) => {
            selectedEndpoints.mappedAtrributes.set(att, {
                name: att,
                isGroup: att.charAt(0) === '#',
                isNamed: att.charAt(0) === '@',
            });
            if (att.charAt(0) === '#') {
                toBeExploded.push(att);
            } else {
                namedList.push(att.replace('@', ''));
            }
        });
        this.selectedEndpointAttributes = selectedEndpoints;
        this.getEndpointPreviewList(namedList, toBeExploded);
    }

    private getSelectedEdgeRouters() {
        const selectedEdgeRouters = new PagedAttributes();
        const toBeExploded = [];
        const namedList = [];
        this.model.edgeRouterAttributes.forEach((att) => {
            selectedEdgeRouters.mappedAtrributes.set(att, {
                name: att,
                isGroup: att.charAt(0) === '#',
                isNamed: att.charAt(0) === '@',
            });
            if (att.charAt(0) === '#') {
                toBeExploded.push(att);
            } else {
                namedList.push(att.replace('@', ''));
            }
        });
        this.selectedEdgeRouterAttributes = selectedEdgeRouters;
        this.getEdgeRouterPreviewList(namedList, toBeExploded);
    }

    private initializeEdgeRouterSelector() {
        this.isLoadingEdgeRouterAttr = true;
        this.edgeRouterError = false;
        this.getSelectedEdgeRouters();
        this.attributesService
            .getEdgeRouterAttributes(this.currentNetwork, false)
            .then((results: AttributesServiceResults) => {
                const collector = results.namedAttributes;
                this.allEdgeRouters = Array.from(results.namedAttributes.mappedAtrributes.values());
                if (this.isAllEdgeRouters) {
                    this.edgeRouters = this.allEdgeRouters.map((rec) => rec.name.substr(1));
                }
                for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                    collector.mappedAtrributes.set(sa.name, sa);
                }
                this.edgeRouterAttributes = collector;
            })
            .catch((err) => {
                this.edgeRouterError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingEdgeRouterAttr = false;
                this.isLoading = this.isLoadingEndpointAttr || this.isLoadingEdgeRouterAttr;
            });
    }
}
