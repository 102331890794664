import { MopResource } from './mopresource';

export class Network extends MopResource {
    public caName: string;
    public productFamily: string;
    public productVersion: string;
    public o365BreakoutCategory: string;
    public organizationId;
    public locationCode: string;
    public provider: string;
    public region: string;
    public networkConfigName: string;
    public mfaIssuerId: string;
    public mfaClientId: string;
    public publicCertEnabled: boolean;
    public _links: {
        self: {
            href: string;
        };
    };

    constructor(object) {
        super(object);

        this.organizationId = this.getLinkedResourceId('organization');
    }

    public getSaveObject() {
        return {
            name: this.name,
            productFamily: this.productFamily,
            productVersion: this.productVersion,
            organizationId: this.organizationId,
            locationCode: this.locationCode,
            networkConfigName: this.networkConfigName,
            o365BreakoutCategory: this.o365BreakoutCategory,
            mfaIssuerId: this.mfaIssuerId,
            mfaClientId: this.mfaClientId,
        };
    }
}
