import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-growler',
    templateUrl: './growler.component.html',
    styleUrls: ['./growler.component.scss'],
})
export class GrowlerComponent {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() level = '';
    @Input() content = '';
    @Input() show = false;
}
