import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedApiv2Module } from '@netfoundry-ui/shared/apiv2';

import { FeatureTagsSelectorModule } from '@netfoundry-ui/feature/tags-selector';
import { FeaturePreviewListModule } from '@netfoundry-ui/feature/preview-list';
import { FeatureFormSupportButtonModule } from '@netfoundry-ui/feature/form-support-button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { ZitiAppwanComponent } from './ziti-appwan.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModelModule,
        MatDialogModule,
        SharedApiv2Module,
        FeaturePreviewListModule,
        FeatureFormSupportButtonModule,
        MatTooltipModule,
        FeatureTagsSelectorModule,
    ],
    declarations: [ZitiAppwanComponent],
    exports: [ZitiAppwanComponent],
})
export class FeatureFormZitiAppwanModule {}
