import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { FileUploadModule } from 'ng2-file-upload';

import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { FeatureFormServicesFormModule } from '@netfoundry-ui/feature/form/services-form';
import { FeatureDashboardGatewayDashboardModule } from '@netfoundry-ui/feature/dashboard/gateway-dashboard';
import { FeatureDashboardClientDashboardModule } from '@netfoundry-ui/feature/dashboard/client-dashboard';
import { FeatureAppwanBulkUploadSaveModule } from '@netfoundry-ui/feature/appwan-bulk-upload-save';
import { FeatureFormSupportButtonModule } from '@netfoundry-ui/feature/form-support-button';
import { AppWanBulkUploadFormComponent } from './app-wan-bulk-upload-form.component';
import { AppwanUploadSummaryComponent } from './appwan-upload-summary.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UiLoaderModule,
        FileUploadModule,
        HttpClientModule,
        MatDialogModule,
        SharedModelModule,
        FeatureFormServicesFormModule,
        FeatureDashboardGatewayDashboardModule,
        FeatureDashboardClientDashboardModule,
        FeatureAppwanBulkUploadSaveModule,
        FeatureFormSupportButtonModule,
    ],
    declarations: [AppWanBulkUploadFormComponent, AppwanUploadSummaryComponent],
    exports: [AppWanBulkUploadFormComponent, AppwanUploadSummaryComponent],
})
export class FeatureFormAppWanBulkUploadFormModule {}
