import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    GetOption,
    HateoasResourceOperation,
    HttpMethod,
    PagedGetOption,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { CertificateAuthorityV2, ENVIRONMENT, Environment } from '@netfoundry-ui/shared/model';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CertificateAuthorityServiceV2 extends HateoasResourceOperation<CertificateAuthorityV2> {
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;
    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(CertificateAuthorityV2);
    }
    getResource(): Observable<CertificateAuthorityV2> {
        throw new Error('Do not use: see get getCertificateAuthority');
    }

    getPage(): Observable<PagedResourceCollection<CertificateAuthorityV2>> {
        throw new Error('Do not use: see getCertificateAuthoritys');
    }

    getCertificateAuthority(id, options: GetOption = {}): Promise<CertificateAuthorityV2> {
        return super
            .getResource(id, { ...CertificateAuthorityServiceV2.defaultHttpAccept, ...options })
            .toPromise()
            .then((endpoint) => endpoint);
    }

    async getCertificateAuthorityPage(
        options?: PagedGetOption,
        includeProperties?: string
    ): Promise<CertificateAuthorityV2[]> {
        let params = { ...CertificateAuthorityServiceV2.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super
            .getPage(params)
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((endpoints) => endpoints.resources);
    }

    verify(id: string, cert: string): Observable<CertificateAuthorityV2[]> {
        return this.customQuery<CertificateAuthorityV2[]>(HttpMethod.POST, '/' + id + '/verify', { body: cert });
    }

    public downloadToCsv(networkId: string): Observable<any> {
        const params = new HttpParams().set('networkId', networkId).set('size', this.lastTotalCount);
        const headers = new HttpHeaders().set('Accept', 'text/csv');
        return this.http.get(this.environment.v2apiUrl + 'certificate-authorities', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public downloadFileFormat(
        networkId: string,
        format = 'text/csv',
        params: HttpParams = new HttpParams()
    ): Observable<any> {
        params = params.set('networkId', networkId);
        const headers = new HttpHeaders().set('Accept', format);
        return this.http.get(this.environment.v2apiUrl + 'certificate-authorities', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }
}
