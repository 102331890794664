import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { ResourceStatusComponent } from './resource-status.component';

@NgModule({
    declarations: [ResourceStatusComponent],
    exports: [ResourceStatusComponent],
    imports: [CommonModule, SharedModelModule],
})
export class FeatureResourceStatusModule {}
