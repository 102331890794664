import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Endpoint } from '@netfoundry-ui/shared/model';
import { GatewayService, LoggerService, RegionService, ValidateService } from '@netfoundry-ui/shared/services';
import { RegionTransformPipe } from '@netfoundry-ui/ui/pipes';
import { Subscription } from 'rxjs';

const provisionedStatus = 300;
const registeredStatus = 400;

@Component({
    selector: 'app-s3-gateway-selector',
    templateUrl: './s3-gateway-selector.component.html',
    styleUrls: ['./s3-gateway-selector.component.scss'],
})
export class S3GatewaySelectorComponent implements OnInit, OnChanges, OnDestroy {
    @Input() step = 1;

    // the current source and target gateways
    @Input() sourceGatewayModel = new Endpoint({});

    // the previous step
    previousStep = 1;

    selectedRegionName;

    // the endpoint type of the source and target gateways

    sourceGatewayType = 'AWSCPEGW';

    // the current gateawy type to obtain
    currentGatewayType = 'AWSCPEGW';

    // maping of endpoint IDs to gateways
    gatewayMap = {};

    // mapping of endpoint types to gateway lists
    gatewayListMap = {};

    // object to help manage the form
    form = {
        sourceName: {
            error: false,
            placeholder: 'Enter a Name',
            label: 'Name',
            value: '',
        },
        region: {
            error: false,
            placeholder: 'Select A Region',
            label: 'Region',
            value: '',
        },
        sourceGateway: {
            error: false,
            placeholder: 'Search for a Gateway',
            label: 'OR SELECT FROM AN EXISTING GATEWAY',
            value: '',
        },
    };
    regions: Array<any> = [];
    awsRegions: Location[] = [];
    gateways = [];
    displayedGateways = [];
    gatewaysNoHa = [];
    savedsourceGateway = false;
    canSelectTarget = true;
    canCreateTarget = true;
    canListGateways = false;
    canCreateGateways = false;
    canListRegions = false;
    titleString = 'Where is your Current Source?';
    cloudText;
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private gatewayService: GatewayService,
        public validateService: ValidateService,
        private regionService: RegionService,
        private authorizationService: AuthorizationService,
        private regionTransform: RegionTransformPipe
    ) {}

    ngOnInit() {
        // checking the users permissions
        this.canListGateways = this.authorizationService.canListEndpoints();
        this.canCreateGateways = this.authorizationService.canCreateEndpointGroups();
        this.canListRegions = this.authorizationService.canListGeoRegions();

        // if the user can get gateways, get the list of gateways
        if (this.canListGateways) {
            // getting only gateways of the type selected for the source gateway
            this.subscription.add(
                this.gatewayService.get(undefined, undefined, undefined, undefined).subscribe((result) => {
                    const provisionedGateways = [];
                    this.gatewayMap = {};
                    for (const gateway of result) {
                        if (
                            (gateway.status === provisionedStatus || gateway.status === registeredStatus) &&
                            gateway.endpointProtectionRole == null
                        ) {
                            provisionedGateways.push(gateway);
                            this.gatewayMap[gateway.id] = gateway;
                        }
                    }
                    this.gateways = provisionedGateways;

                    // add an entry to relate the current gateway type to the list of gateways
                    this.gatewayListMap[this.currentGatewayType] = {
                        gatewayList: this.gateways.concat([]),
                        gatewayMap: this.gatewayMap,
                    };

                    // filter the list to exclude any previously selected gateways
                    this.filterGateways();
                })
            );
        }

        // if the user can list regions, getting the regions
        if (this.canListRegions) {
            // regions
            this.subscription.add(
                this.regionService.get().subscribe((result) => {
                    this.regions = this.regionTransform.transform(
                        result as Location[],
                        true,
                        false,
                        false,
                        false,
                        false,
                        false
                    );
                    this.logger.info(JSON.stringify(this.regions));

                    // this.targetRegions = this.awsRegions;
                    this.regions = this.regions as Array<any>;
                })
            );
        }
    }

    ngOnChanges() {
        // if the sourceGatewayModel was provided and the model has an ID, then the user may have just cloned the appwan.
        // the form needs to be updated to select the exiting gateway rather than using the previously provided information
        if (this.sourceGatewayModel != null && this.sourceGatewayModel.id != null) {
            // updating the form to store the target gateway's ID
            this.form.sourceGateway.value = this.sourceGatewayModel.id;

            // clearing any previously entered name/location value
            this.form.region.value = '';
            this.form.sourceName.value = '';

            // setting the source gateway type based on the target gateway's endpoint type
            this.sourceGatewayType = this.sourceGatewayModel.endpointType;

            // updating the form to hide the inputs for name/location and only show the select list
            this.canCreateTarget = false;
            this.canSelectTarget = true;
        }

        // save the previous step
        this.previousStep = this.step;
    }

    // function for getting the target gateway
    public getSourceGateway() {
        // otherwise returning a new gateway based on the provided information
        const gatewayModel = new Endpoint({});
        gatewayModel.endpointType = 'AWSCPEGW';
        gatewayModel.name = this.form.sourceName.value;
        this.logger.info('this is inside gateway selector', this.form.region);
        gatewayModel.geoRegionId = this.form.region.value;

        return gatewayModel;
    }

    // function for validating the target gateway
    validateSourceGateway() {
        let isValid = false;
        if (this.canCreateTarget) {
            this.form.region.error = !this.validateService.hasValue(this.form.region.value);

            this.form.sourceName.error = !this.validateService.isValidName(this.form.sourceName.value);

            isValid = !(this.form.region.error || this.form.sourceName.error);
        } else if (this.canSelectTarget) {
            isValid = this.validateService.hasValue(this.form.sourceGateway.value);
            this.form.sourceGateway.error = true;
        }

        return isValid;
    }

    // function for updating the list of gateways
    updateGatewayList(gatewayType) {
        // if the user can list gateways and the provided gateawy type is not equal to the current gateway type
        if (this.gatewayListMap[gatewayType] != null) {
            this.gateways = this.gatewayListMap[gatewayType]['gatewayList'].concat([]);
            this.gatewayMap = this.gatewayListMap[gatewayType]['gatewayMap'];
            this.filterGateways();
        } else if (this.canListGateways && gatewayType !== this.currentGatewayType) {
            // updating the list of gateways
            this.gatewayService.get(undefined, undefined, undefined, undefined, gatewayType);
        } else {
            this.filterGateways();
        }

        // saving the current gateway type
        this.currentGatewayType = gatewayType;
    }

    isPassedStep(index) {
        return this.step > index;
    }

    // function for resetting the gateway list map
    public clearGatewayListMap() {
        this.gatewayListMap = {};
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // function for setting the type of the target gateway
    private setTarget(type) {
        // if the endpoint type hasn't change, do nothing
        if (this.sourceGatewayType === type) {
            return;
        }

        // if both the old endpoint type and the new endpoint type  uses the aws regions, don't do anything
        if (
            (this.sourceGatewayType === '' ||
                this.sourceGatewayType === 'AWSCPEGW' ||
                this.sourceGatewayType === 'VCPEGW') &&
            (type === 'AWSCPEGW' || type === 'VCPEGW')
        ) {
            this.sourceGatewayType = type;
            this.form.sourceGateway.value = '';
            return;
        }

        // if both the old endpoint type and the new endpoint type  uses the azure regions, don't do anything
        if (
            (this.sourceGatewayType === 'AZSGW' || this.sourceGatewayType === 'AZCPEGW') &&
            (type === 'AZSGW' || type === 'AZCPEGW')
        ) {
            this.sourceGatewayType = type;
            this.form.sourceGateway.value = '';
            return;
        }

        switch (type) {
            case 'AWSCPEGW':
            case 'VCPEGW':
                this.regions = this.awsRegions;
                break;
        }

        this.sourceGatewayType = type;
        this.form.sourceGateway.value = '';
        this.form.region.value = '';
        this.updateGatewayList(type);
    }

    // function for filtering the gateways displayed
    // this is used for excluding any previously selected gateway from the list of available gateways

    // function for disabling selection of the source gateway from the drop down
    private disableTargetSelect() {
        // if the user starts to provide the necessary information for the gateawy, hide the select list
        if (this.form.sourceName.value !== '' || this.form.region.value !== '') {
            this.canSelectTarget = false;
        } else {
            this.canSelectTarget = true;
        }
    }

    // function for disabling the creation of a gateway
    private disableTargetCreate() {
        // if the user selects a gateway from the drop down hide the form for creating the gateway
        if (this.form.sourceGateway.value !== '') {
            this.canCreateTarget = false;
        } else {
            this.canCreateTarget = true;
        }
    }

    // this prevents the user from selecting the same gateway for both the source and target gateways
    private filterGateways() {
        this.displayedGateways = [];
        if (this.step === 3 && this.form.sourceGateway.value != null) {
            this.displayedGateways = this.gateways.filter((gateway) => gateway.id !== this.form.sourceGateway.value);
        } else {
            this.displayedGateways = this.gateways.concat([]);
        }

        // if there are no gateways of the selected type
        if (this.displayedGateways.length === 0) {
            this.canCreateTarget = true;
        }

        if (this.step === 3) {
            this.disableTargetSelect();
            this.disableTargetCreate();
        }
    }
}
