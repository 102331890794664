import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CreditCardWarningComponent } from './credit-card-warning.component';

@NgModule({
    imports: [CommonModule, MatDialogModule],
    declarations: [CreditCardWarningComponent],
    exports: [CreditCardWarningComponent],
})
export class FeatureCreditCardWarningModule {}
