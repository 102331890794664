import { Component, Inject, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VirtualWanSiteFormComponent } from '@netfoundry-ui/feature/form/virtual-wan-site-form';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    AppWan,
    AzureVirtualWanSite,
    Collector,
    Endpoint,
    Exports,
    GatewayCluster,
    Group,
    ListItem,
    Service,
    ShareData,
} from '@netfoundry-ui/shared/model';
import { CollectorService, ExportersService, ExportsService } from '@netfoundry-ui/shared/netflow';
import {
    ApiService,
    AppwanService,
    AzureDeployService,
    AzureVirtualWanSiteService,
    FeatureService,
    GatewayClusterService,
    GatewayService,
    GetCloudformationLinkService,
    GroupService,
    LoggerService,
    NetworkGroupService,
    NetworkVersionService,
    ValidateService,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { ShareService } from '@netfoundry-ui/shared/share';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { GroupsformComponent } from '@netfoundry-ui/feature/form/groups-form';
import { AppWanFormComponent } from '@netfoundry-ui/feature/form/app-wan-form';
import { ServicesformComponent } from '@netfoundry-ui/feature/form/services-form';

const deletingStatus = 800;
const registered = 400;

const exporterType = 'dvn-gateway';

@Component({
    selector: 'app-gatewaydashboard',
    templateUrl: './gatewaydashboard.component.html',
    styleUrls: ['./gatewaydashboard.component.scss'],
    providers: [FromDatePipe, GetCloudformationLinkService],
})
export class GatewaydashboardComponent implements OnInit, OnDestroy, OnChanges {
    model;
    azureSiteModel: AzureVirtualWanSite = new AzureVirtualWanSite({});
    isAzureGateway = false;
    processing = false;
    azureDialogRef;
    appwans = new ListItem('', '');
    groups = new ListItem('', '');
    services = new ListItem('', '');
    errorName = false;
    currentTimeFilter = '24h';
    endTime: number = Date.now();
    startTime: number = this.endTime - 24 * 60 * 60 * 1000;
    directionFilter: string[] = ['DropTcpTx', 'DropUdpTx'];
    endpointNameFilter: any = false;
    resourceId;
    componentId;
    currentNetworkId = '';
    currentOrgId = '';
    deleting = false;
    isInline = false;
    exportModel = new Exports({});
    appwansList = [];
    groupsList = [];
    groupFormComponent: Type<any> = GroupsformComponent;
    appwanFormComponent: Type<any> = AppWanFormComponent;
    serviceFormComponent: Type<any> = ServicesformComponent;
    // TODO: Of HA fill out and set these for the status in the corner
    isHAGateway = false;
    isZitiGateway = false;
    haGateways = [];
    confirmDialog;
    selectDialog;
    allGroups: Group[] = [];
    allAppWans: AppWan[] = [];
    showCloudFormationButton = false;
    showAzureFormationButton = false;
    hideRegKey = false;
    isRegistered = false;
    haUpgrade = false;
    maxLength = '64';
    // list of component IDs for a gateway cluster
    componentIdList = [];
    resourceIdList = [];
    canDeleteAzureSite = false;
    canUpdateGateway = false;
    canUpdateCluster = false;
    canDeleteGateway = false;
    canDeleteCluster = false;
    showBreakoutFields = false;
    errorNextHop = false;
    errorCollector = false;
    errorFrequency = false;
    canShareEndpoint = false;
    hideHelp = false;
    collectors = [];
    hasExport = false;
    netflowEnabled = false;
    hideNetflowFields = false;
    canCreateExports = false;
    canUpdateExports = false;
    isAutoScale = false;
    disableAzureFormationButton = true;
    // TODO remove
    disableButton = false;
    zitiRegKeyBlob;
    canReadElasticSearch = true;
    private id = '';
    private subscription = new Subscription();
    private appwansData: AppWan[] = [];
    private groupsData: Group[] = [];
    private servicesData: Service[] = [];
    private groupSubscription: Subscription = new Subscription();
    private allAppwanSubscription: Subscription = new Subscription();
    private relatedAppwanSubscription: Subscription = new Subscription();
    private relatedServiceSubscription: Subscription = new Subscription();
    private relatedGroupsSubscription: Subscription = new Subscription();
    private regionId = '';
    private originalGatewayName = '';

    constructor(
        private logger: LoggerService,
        private gatewayService: GatewayService,
        private shareService: ShareService,
        private growlerService: GrowlerService,
        private fromDate: FromDatePipe,
        private groupService: GroupService,
        private appwanService: AppwanService,
        public features: FeatureService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogForm: MatDialog,
        private dialogRef: MatDialogRef<GatewaydashboardComponent>,
        private cfService: GetCloudformationLinkService,
        private azureVirtualWanSiteService: AzureVirtualWanSiteService,
        public featureService: FeatureService,
        private validateService: ValidateService,
        private clusterService: GatewayClusterService,
        private apiService: ApiService,
        public authorizationService: AuthorizationService,
        private collectorService: CollectorService,
        private exportersService: ExportersService,
        private exportsService: ExportsService,
        private azureDeployService: AzureDeployService,
        private organizationService: NetworkGroupService,
        private networkVersionService: NetworkVersionService,
        public zitiEnabledService: ZitiEnabledService
    ) {
        // TODO remove
        this.disableButton =
            this.networkVersionService.currentNetworkVersion > 4 && data.model['endpointType'] === 'AZSGW';

        this.currentNetworkId = this.apiService.theNetworkIs.getId();
        this.currentOrgId = this.apiService.theOrgIs.getId();

        if (data.model['endpointType'].includes('AZ')) {
            this.azureDeployService.canShowAzureFormationLink(data.model['endpointType']).subscribe((result) => {
                this.disableAzureFormationButton = !result;
            });
        }

        this.showBreakoutFields =
            apiService.theNetworkIs.o365BreakoutCategory !== '' &&
            apiService.theNetworkIs.o365BreakoutCategory !== 'NONE';
        // if the model has a protectionType, mark as a HA gateway
        if (data.model['protectionType'] != null) {
            this.model = new GatewayCluster(data.model);
            this.isHAGateway = true;
            this.maxLength = '59';
            this.componentId = null;
            this.isAutoScale = this.model.protectionType === 'AwsAutoScale';
        } else {
            // otherwise, handle as a regular endpoint, check the registration status, and handle registration info
            this.model = new Endpoint(data.model);
            this.isRegistered = this.model['status'] === registered;
            this.handleRegistrationInfo();
            this.componentId = this.model['componentId'];
            this.componentIdList = null;
            this.haUpgrade = data.toggleHaUpgrade;
            this.errorName = data.haUpgradeError;
        }

        if (this.model['registrationAttemptsLeft'] > 0 && this.zitiEnabledService.zitiPure) {
            this.getZitiRegKey();
        }

        this.canCreateExports = this.authorizationService.canCreateExports();

        this.canShareEndpoint = this.authorizationService.canShareEndpoint(this.model.id);
        this.isZitiGateway = this.model.endpointType === 'ZTGW' || this.model.endpointType === 'ZTNHGW';
        this.isAzureGateway = this.model.endpointType === 'AVWGW';
        this.canReadElasticSearch = this.authorizationService.canReadElasticSearch();

        this.resourceId = this.model.getId();
        if (data.inline) {
            this.isInline = data.inline;
        }

        if (this.authorizationService.canListNetflowCollectors()) {
            if (this.authorizationService.canListOrganizations()) {
                this.subscription.add(
                    this.organizationService.get().subscribe((res) => {
                        if (res.length > 1) {
                            this.getCollectors();
                        }
                    })
                );
                this.getCollectors({ networkGroupIdsIn: [this.currentOrgId] });
            } else {
                this.getCollectors({ networkGroupIdsIn: [this.currentOrgId] });
            }
        }

        if (!this.isHAGateway) {
            this.subscription.add(
                this.exportersService
                    .findByNativeId(exporterType, this.model.id, {
                        networkId: this.currentNetworkId,
                    })
                    .subscribe((result) => {
                        if (result.exportCount > 0) {
                            this.hasExport = true;
                            this.netflowEnabled = true;

                            const params = {
                                networkGroupIdsIn: this.currentOrgId,
                                exporterTypesIn: exporterType,
                                exporterKeysIn: result.key,
                            };

                            this.subscription.add(
                                this.exportsService.find(params).subscribe((exportList) => {
                                    if (exportList.length > 0) {
                                        this.exportModel = new Exports(exportList[0]);
                                        this.canUpdateExports = this.authorizationService.canUpdateExport(
                                            this.exportModel.id
                                        );
                                    } else {
                                        this.hideNetflowFields = true;
                                    }
                                })
                            );
                        } else {
                            this.exportModel.networkGroupId = this.currentOrgId;
                            this.exportModel.exporterType = exporterType;
                            this.exportModel.exporterKey = result.key;
                            this.exportModel.exportIntervalSeconds = 20;
                        }
                    })
            );
        }

        this.originalGatewayName = this.model.name;
    }

    getCollectors(params?) {
        this.subscription.add(
            this.collectorService.find(params).subscribe((result) => {
                this.collectors = result as Collector[];
            })
        );
    }

    ngOnInit() {
        this.processing = false;
        this.groups = new ListItem(this.model.getId(), 'Groups');
        this.services = new ListItem(this.model.getId(), 'Services', this.model.getSelfLink());
        this.appwans = new ListItem(this.model.getId(), 'AppWANs');

        if (this.isHAGateway) {
            this.canUpdateCluster = this.authorizationService.canUpdateGatewayCluster(this.model.id);
            this.canDeleteCluster = this.authorizationService.canDeleteGatewayCluster(this.model.id);
        } else {
            this.canUpdateGateway = this.authorizationService.canUpdateEndpoint(this.model.id);
            this.canDeleteGateway = this.authorizationService.canDeleteEndpoint(this.model.id);
        }

        if (this.authorizationService.canListServices()) {
            this.getRelatedServices();
        }

        if (this.isHAGateway) {
            // if it is a HA gateway cluster, get the resources for the HA cluster
            this.getRelatedResourcesHa();
        } else {
            // if the gateawy is not HA and the user has list appwan permissions
            if (this.authorizationService.canListAppWans()) {
                // get appwans and related appwans
                this.getAppwans();
                this.getRelatedAppwans();
            }
            if (this.authorizationService.canListEndpointGroups()) {
                this.getGroups();
                this.getRelatedGroups();
            }
        }

        // Azure hack
        if (this.isAzureGateway && this.authorizationService.canListAzureVirtualWanSites()) {
            this.subscription.add(
                this.azureVirtualWanSiteService.get().subscribe((sites) => {
                    for (const site of sites) {
                        if (site.endpointId === this.model.getId()) {
                            this.azureSiteModel = site;
                            this.canDeleteAzureSite = this.authorizationService.canDeleteAzureVirtualWanSites(
                                this.azureSiteModel.id
                            );
                        }
                    }
                })
            );
        }
    }

    getAppwans() {
        this.allAppwanSubscription.unsubscribe();
        this.allAppwanSubscription = this.appwanService.get().subscribe((result) => {
            this.allAppWans = [];
            for (const appwan of result as AppWan[]) {
                if (appwan.status < deletingStatus) {
                    if (this.authorizationService.canUpdateAppWan(appwan.id)) {
                        appwan['canUpdate'] = true;
                    }
                    this.allAppWans.push(appwan);
                }
            }
        });
    }

    getRelatedAppwans() {
        this.relatedAppwanSubscription.unsubscribe();
        this.relatedAppwanSubscription = this.gatewayService
            .getLinkedResources(this.model, 'appWans')
            .subscribe((result) => {
                this.appwansList = result;
                this.appwans.clear();
                this.appwansData = [];
                for (let appwan of result) {
                    appwan = new AppWan(appwan);
                    if (this.authorizationService.canUpdateAppWan(appwan.id)) {
                        appwan['canUpdate'] = true;
                    }
                    this.appwans.push(appwan, this.model.id, 'appwan', appwan.id, appwan.name, false);
                    this.appwansData.push(appwan);
                }
            });
    }

    getRelatedServices() {
        this.relatedServiceSubscription.unsubscribe();
        if (this.isHAGateway) {
            this.relatedServiceSubscription = this.clusterService
                .getLinkedResources(this.model, 'services')
                .subscribe((result) => {
                    this.services.clear();
                    this.servicesData = result as Service[];
                    for (let service of this.servicesData) {
                        service = new Service(service);
                        this.services.push(service, this.model.id, 'service', service.id, service.name, false);
                    }
                });
        } else {
            this.relatedServiceSubscription = this.gatewayService
                .getLinkedResources(this.model, 'services')
                .subscribe((result) => {
                    this.services.clear();
                    this.servicesData = result as Service[];
                    for (let service of this.servicesData) {
                        service = new Service(service);
                        this.services.push(service, this.model.id, 'service', service.id, service.name, false);
                    }
                });
        }
    }

    // function for handling the resources for HA gateways specifically
    getRelatedResourcesHa() {
        this.haGateways = this.model.endpoints;

        const componentIds = [];
        const resourceIds = [];

        for (const gateway of this.haGateways) {
            // determining is registered based on whether or not any gateway is in a registered state
            this.isRegistered = this.isRegistered || gateway.status === 400;

            // add the current gateway's component ID to the list
            if (gateway.componentId) {
                componentIds.push(gateway.componentId);
            }
            resourceIds.push({ id: gateway.id, name: gateway.name });
        }

        this.componentIdList = componentIds;
        this.resourceIdList = resourceIds;
        this.handleRegistrationInfo();
    }

    handleRegistrationInfo() {
        if (this.isHAGateway) {
            // if it is a HA gateway cluster, determine if there are any gateways in the cluster that are unregistered
            let clusterHasUnregisteredGw = false;
            if (this.isAutoScale) {
                clusterHasUnregisteredGw = this.model.status === 300;
            } else {
                for (const gateway of this.haGateways) {
                    if (this.displayRegistration(gateway)) {
                        clusterHasUnregisteredGw = true;
                        break;
                    }
                }
            }

            // display cloudformation, azureformation and reg key based on whether or not any gateway is unregistered
            this.showCloudFormationButton = this.model.endpointType === 'AWSCPEGW' && clusterHasUnregisteredGw;
            this.showAzureFormationButton =
                (this.model.endpointType === 'AZCPEGW' || this.model.endpointType === 'AZSGW') &&
                clusterHasUnregisteredGw;

            this.hideRegKey =
                this.model.endpointType === 'GW' ||
                this.model.endpointType === 'ZTGW' ||
                this.model.endpointType === null ||
                !clusterHasUnregisteredGw;
        } else {
            // if the gateway is an AWS gateway, obtain the AWS region ID and envrionment to use in the cloud formation button
            if (this.model.endpointType === 'AWSCPEGW' && this.model['status'] === 300) {
                // obtaining the AWS region ID for the gateway
                if (this.authorizationService.canGetDataCenter(this.model['dataCenterId'])) {
                    this.subscription.add(
                        this.gatewayService
                            .getResource(this.gatewayService.getLinkedResourceUrl(this.model, 'dataCenter'))
                            .subscribe((result) => {
                                this.regionId = result['locationCode'];
                            })
                    );
                    this.showCloudFormationButton =
                        this.model.endpointType === 'AWSCPEGW' && this.displayRegistration(this.model);
                }
            }

            this.showAzureFormationButton =
                (this.model.endpointType === 'AZCPEGW' || this.model.endpointType === 'AZSGW') &&
                this.displayRegistration(this.model);

            this.hideRegKey =
                this.model.endpointType === 'GW' ||
                this.model.endpointType === 'ZTGW' ||
                this.model.endpointType === null ||
                !this.displayRegistration(this.model);
        }
    }

    ngOnChanges() {
        if (this.isHAGateway) {
            this.endpointNameFilter = false;
        } else {
            this.endpointNameFilter = this.model.name;
        }
        this.resourceId = this.model.getId();

        if (this.model['componentId']) {
            this.componentId = this.model['componentId'];
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        if (this.allAppwanSubscription != null) {
            this.allAppwanSubscription.unsubscribe();
        }

        if (this.groupSubscription != null) {
            this.groupSubscription.unsubscribe();
        }

        this.relatedAppwanSubscription.unsubscribe();
        this.relatedServiceSubscription.unsubscribe();
    }

    save() {
        if (this.validate() && this.authorizationService.canUpdateEndpoint(this.model.id)) {
            this.processing = true;
            if (this.model.o365BreakoutNextHopIp != null && this.model.o365BreakoutNextHopIp.trim() === '') {
                this.model.o365BreakoutNextHopIp = null;
            }
            if (this.haUpgrade) {
                this.upgradeToHaGateway();
            } else {
                let newName;
                if (this.originalGatewayName !== '' && this.originalGatewayName !== this.model.name) {
                    newName = this.model.name;
                }
                if (this.isHAGateway) {
                    this.subscription.add(
                        this.clusterService.save(this.model).subscribe(
                            (result) => {
                                this.growlerService.show(
                                    new GrowlerData(
                                        'success',
                                        'Success',
                                        'Save Complete',
                                        'Gateway information has been saved.'
                                    )
                                );
                                this.dialogRef.close({
                                    newName: newName,
                                    haGateways: this.haGateways,
                                });
                                this.processing = false;
                            },
                            (error) => {
                                this.processing = false;
                            }
                        )
                    );
                } else {
                    this.subscription.add(
                        this.gatewayService.save(this.model).subscribe(
                            (result) => {
                                // TODO: Parse results and check for errors
                                const closeObject = { newName: newName };
                                this.processing = false;
                                if (this.netflowEnabled) {
                                    this.exportModel.name = this.model.name;
                                    this.saveExport(closeObject);
                                } else if (this.hasExport) {
                                    this.deleteExport(closeObject);
                                } else {
                                    this.growlerService.show(
                                        new GrowlerData(
                                            'success',
                                            'Success',
                                            'Save Complete',
                                            'Gateway information has been saved.'
                                        )
                                    );
                                    this.dialogRef.close(closeObject);
                                }
                            },
                            (error) => {
                                this.processing = false;
                            }
                        )
                    );
                }
            }
        }
    }

    copy(gateway?) {
        let elementId = 'EditRegKey';
        if (gateway) {
            elementId += `_${gateway.id}`;
        }
        const element = <HTMLInputElement>document.getElementById(elementId);
        element.focus();
        element.select();
        document.execCommand('copy');
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Reg Key Copied',
                'The reg key ' + this.model['registrationKey'] + ' has been copied to your clipboard'
            )
        );
    }

    statusClass() {
        return 's' + this.model['status'];
    }

    open(model: Endpoint) {
        this.errorName = false;
        this.model = model;
        this.id = this.model.getId();
    }

    share() {
        this.shareService.show(new ShareData('gateway', this.model));
    }

    validate(): boolean {
        this.errorName = false;
        this.errorNextHop = false;
        this.errorCollector = false;
        this.errorFrequency = false;

        if (this.validateService.hasValue(this.model.o365BreakoutNextHopIp)) {
            this.errorNextHop = !this.validateService.isValidIP(this.model.o365BreakoutNextHopIp);
        }

        if (this.netflowEnabled && !this.validateService.hasValue(this.exportModel.collectorId)) {
            this.errorCollector = true;
        }

        if (
            this.netflowEnabled &&
            (!this.validateService.hasValue(this.exportModel.exportIntervalSeconds) ||
                isNaN(this.exportModel.exportIntervalSeconds) ||
                this.exportModel.exportIntervalSeconds < 1 ||
                this.exportModel.exportIntervalSeconds > 300)
        ) {
            this.errorFrequency = true;
        }

        this.errorName = this.haUpgrade
            ? !this.validateService.isValidHaName(this.model.name)
            : !this.validateService.isValidName(this.model.name);

        return !this.errorName && !this.errorNextHop && !this.errorCollector && !this.errorFrequency;
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setStartTime(value) {
        this.startTime = this.fromDate.transform(value);
        this.endTime = Date.now();
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setEndTime(value) {
        // TODO - process date field into ms number
        this.endTime = this.fromDate.transform(value);
    }

    /**
     * Set the filter for all child Feature
     * @param value
     */
    public setDirectionFilter(value) {
        if (value === 'tx') {
            this.directionFilter = ['DropTcpTx', 'DropUdpTx'];
        } else {
            this.directionFilter = ['DropTcpRx', 'DropUdpRx'];
        }
    }

    confirmDelete() {
        if (this.authorizationService.canDeleteEndpoint(this.model.id)) {
            let gatewayString = `gateway ${this.model.name}`;
            if (this.isHAGateway) {
                gatewayString = `HA ${gatewayString} and its associated gateways`;
            }
            this.deleting = true;
            const data = {
                title: 'Delete',
                appendId: 'gatewayDash',
                subtitle: `Are you sure you would like to delete the ${gatewayString}`,
                icon: 'Delete',
                action: 'Yes',
            };
            this.confirmDialog = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            this.confirmDialog.beforeClosed().subscribe((result) => {
                // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                //  logged out of the console and we should close the dialog without continuing
                if (result === undefined) {
                    this.confirmed(false);
                } else if (result['loggingOut'] === undefined) {
                    this.confirmed(result);
                }
            });
            this.deleting = true;
        }
    }

    confirmed(event) {
        if (event) {
            if (this.deleting) {
                // If this is an Azure GW, delete the azureVirtualWanSite first
                // currently we're not allowing Azure gateways to be HA, so no need to handle deleting an azure cluster at the moment
                if (this.isAzureGateway) {
                    if (this.azureSiteModel.id != null && this.canDeleteAzureSite) {
                        this.growlerService.show(
                            new GrowlerData(
                                'info',
                                'Info',
                                'Deleting Gateway',
                                'Deleting Azure Gateway resources, please wait...'
                            )
                        );
                        this.subscription.add(
                            this.azureVirtualWanSiteService.delete(this.azureSiteModel).subscribe((res) => {
                                this.subscription.add(
                                    this.gatewayService.delete(this.model).subscribe((data) => {
                                        this.dialogRef.close({ deleted: true });
                                    })
                                );
                            })
                        );
                    }
                } else if (this.isHAGateway) {
                    this.subscription.add(
                        this.clusterService.delete(this.model).subscribe((data) => {
                            this.dialogRef.close({ deleted: true });
                        })
                    );
                } else {
                    this.subscription.add(
                        this.gatewayService.delete(this.model).subscribe((data) => {
                            this.dialogRef.close({ deleted: true });
                        })
                    );
                }
            }
            this.deleting = false;
        }
    }

    refresh() {
        if (this.isHAGateway) {
            this.getRelatedResourcesHa();
        } else {
            // if the gateawy is not HA and the user has list appwan permissions
            if (this.authorizationService.canListAppWans()) {
                // get appwans and related appwans
                this.getAppwans();
                this.getRelatedAppwans();
            }

            if (this.authorizationService.canListEndpointGroups()) {
                this.getGroups();
                this.getRelatedGroups();
            }
        }
        if (this.authorizationService.canListServices()) {
            this.getRelatedServices();
        }
    }

    refreshLists(eventData) {
        this.refresh();
    }

    addToGroups(groupIds: string[]) {
        // making sure that there is at least one group to add
        if (groupIds !== null && groupIds !== undefined && groupIds.length > 0) {
            // variables used to determine when to call the refresh
            const expectedSubscriptions = groupIds.length;
            let numSubscriptionsFinished = 0;

            // looping through the list of all groups
            for (const group of this.allGroups) {
                // if the current group is one of the groups to remove add the client to, adding the client
                if (groupIds.indexOf(group.id) > -1) {
                    this.subscription.add(
                        this.groupService.addEndpoints(group, [this.model.id]).subscribe((result) => {
                            numSubscriptionsFinished++;

                            // if this is the last subscription to finish executing, refresh the view
                            if (numSubscriptionsFinished === expectedSubscriptions) {
                                this.growlerService.show(
                                    new GrowlerData(
                                        'success',
                                        'Success',
                                        'Save Complete',
                                        'Group information has been saved.'
                                    )
                                );
                                // group updates are not always instant
                                setTimeout(() => {
                                    this.refresh();
                                }, 1000);
                            }
                        })
                    );
                }
            }
        }
    }

    removeFromGroups(groupIds: string[]) {
        // making sure that there is at least one group to remove
        if (groupIds !== null && groupIds !== undefined && groupIds.length > 0) {
            // variables used to determine when to call the refresh
            const expectedSubscriptions = groupIds.length;
            let numSubscriptionsFinished = 0;

            // looping through the list of groups this client is part of
            for (const group of this.groupsData) {
                // if the current group is one of the groups to remove the client from, removing the client
                if (groupIds.indexOf(group.id) > -1) {
                    this.subscription.add(
                        this.groupService.removeEndpoints(group, [this.model.id]).subscribe((result) => {
                            numSubscriptionsFinished++;
                            // if this is the last subscription to finish executing, refresh the view
                            if (numSubscriptionsFinished === expectedSubscriptions) {
                                this.growlerService.show(
                                    new GrowlerData(
                                        'success',
                                        'Success',
                                        'Save Complete',
                                        'Group information has been saved.'
                                    )
                                );
                                // group updates are not always instant
                                setTimeout(() => {
                                    this.refresh();
                                }, 1000);
                            }
                        })
                    );
                }
            }
        }
    }

    addToAppWans(appwanIds: string[]) {
        // making sure that there is at least one group to add
        if (appwanIds !== null && appwanIds !== undefined && appwanIds.length > 0) {
            // variables used to determine when to call the refresh
            const expectedSubscriptions = appwanIds.length;
            let numSubscriptionsFinished = 0;

            // looping through the list of all appwans
            for (const appwan of this.allAppWans) {
                // if the current appwan is one of the appwans to remove add the client to, adding the client
                if (appwanIds.indexOf(appwan.id) > -1) {
                    this.subscription.add(
                        this.appwanService.addEndpoints(appwan, [this.model.id]).subscribe((result) => {
                            numSubscriptionsFinished++;

                            // if this is the last subscription to finish executing, refresh the view
                            if (numSubscriptionsFinished === expectedSubscriptions) {
                                this.growlerService.show(
                                    new GrowlerData(
                                        'success',
                                        'Success',
                                        'Save Complete',
                                        'AppWan information has been saved.'
                                    )
                                );
                                this.refresh();
                            }
                        })
                    );
                }
            }
        }
    }

    removeFromAppWans(appwanIds: string[]) {
        // making sure that there is at least one group to remove
        if (appwanIds !== null && appwanIds !== undefined && appwanIds.length > 0) {
            // variables used to determine when to call the refresh
            const expectedSubscriptions = appwanIds.length;
            let numSubscriptionsFinished = 0;
            for (const appwan of this.appwansData) {
                // if the current appwan is one of the appwans to remove the client from, removing the client
                if (appwanIds.indexOf(appwan.id) > -1) {
                    this.subscription.add(
                        this.appwanService.removeEndpoints(appwan, [this.model.id]).subscribe((result) => {
                            numSubscriptionsFinished++;
                            // if this is the last subscription to finish executing, refresh the view
                            if (numSubscriptionsFinished === expectedSubscriptions) {
                                this.growlerService.show(
                                    new GrowlerData(
                                        'success',
                                        'Success',
                                        'Save Complete',
                                        'AppWan information has been saved.'
                                    )
                                );
                                this.refresh();
                                setTimeout(() => {
                                    this.refresh();
                                }, 2000);
                            }
                        })
                    );
                }
            }
        }
    }

    openAzureFormationLink() {
        this.azureDeployService.openAzureFormationLink(this.model);
    }

    async openCloudFormationLink(gateway?) {
        let item;
        let region;
        if (gateway) {
            item = gateway;
            region = await this.gatewayService
                .getResource(this.gatewayService.getLinkedResourceUrl(gateway, 'dataCenter'))
                .toPromise()
                .then((result) => result['locationCode']);
        } else {
            item = this.model;
            region = this.regionId;
        }
        const link = this.cfService.getCfLink(item, region);
        const win = window.open(link, '_blank');
        win.focus();
    }

    openAutoScaleCloudFormationLink() {
        const link = this.cfService.getAutoScaleCfLink(this.model, this.regionId);
        const win = window.open(link, '_blank');
        win.focus();
    }

    close() {
        this.dialogRef.close();
    }

    azureEdit() {
        this.azureDialogRef = this.dialogForm.open(VirtualWanSiteFormComponent, {
            data: { model: this.azureSiteModel },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.azureDialogRef.beforeClosed().subscribe((result) => {
            if (result === undefined) {
                this.dialogRef.close();
            }
        });
    }

    displayRegistration(gateway) {
        return (
            gateway.status < 400 &&
            gateway.registrationAttemptsLeft > 0 &&
            gateway.registrationKey != null &&
            gateway.registrationKey.length > 0 &&
            gateway.endpointType !== 'GW' &&
            gateway.endpointType !== 'ZTGW'
        );
    }

    displayAutoScalingRegistration(gateway) {
        // TODO:
        return true;
    }

    toggleHaUpgrade() {
        this.haUpgrade = !this.haUpgrade;
    }

    // function for upgrading a standalone gateway to a HA gateway
    async upgradeToHaGateway() {
        if (this.isHAGateway) {
            // if the gateway is already an HA gateway, prevent the upgrade call from being made
            this.logger.info('Cannot upgrade the gateway as it already appears to be a HA Gateway');
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Unable to Upgrade Gateway',
                    'This gateway already appears to be a HA Gateway.'
                )
            );
            this.processing = false;
        } else if (this.model.endpointType === 'AVWGW') {
            // otherwise, if the gateway is an avw site, prevent the upgrade
            this.logger.info('Cannot upgrade the gateway as it is an AVW Site');
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Unable to Upgrade Gateway',
                    'Unable to upgrade an AVW Site to a HA Gateway.'
                )
            );
            this.processing = false;
        } else if (this.model.status > 400 || this.model.status < 300) {
            // otherwise, if the gateway is not in a provisioned or registerd state, prevent the upgrade
            this.logger.info('Cannot upgrade the gateway as it is not provisioned or registered');
            this.growlerService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Unable to Upgrade Gateway',
                    'Please wait until the gateway is provisioned or registered.'
                )
            );
            this.processing = false;
        } else {
            // checking if the gateway belongs to any appwans or groups
            const hasAppwansOrGroups = await this.gatewayHasAppwansOrGroups(this.model);

            // if it does, open a dialog error
            if (hasAppwansOrGroups.hasAppwans || hasAppwansOrGroups.hasGroups) {
                this.openHaUpgradeAppwanOrGroupError(this.model, hasAppwansOrGroups);
            } else {
                // creating the cluster object for the upgrade request
                const cluster = new GatewayCluster({});

                cluster.name = this.model.name;
                cluster.protectionType = '1:1';
                cluster.protectionGroupId = this.model.name;
                cluster.endpointId = this.model.id;

                // making the upgrade request
                this.subscription.add(
                    this.clusterService.upgrade(cluster).subscribe(
                        (result) => {
                            this.dialogRef.close({ refresh: true });

                            // displaying a success message and refreshing the page
                            this.growlerService.show(
                                new GrowlerData(
                                    'success',
                                    'Success',
                                    'Successfully Upgraded to HA Gateway',
                                    'Your gateway has been successfully upgraded. The other gateways in the cluster will appear shortly'
                                )
                            );
                        },
                        (error) => {
                            this.processing = false;
                        }
                    )
                );
            }
        }
    }

    hideHaUpgrade() {
        return (
            this.isHAGateway ||
            this.model.endpointType === 'AVWGW' ||
            this.model.endpointType === 'ZTGW' ||
            this.model.endpointType === 'ZTNHGW' ||
            this.model.status < 300 ||
            this.model.status > 400 ||
            !this.authorizationService.canUpdateEndpoint(this.model.id) ||
            !this.authorizationService.canCreateGatewayClusters()
        );
    }

    toggleNetflowEnabled() {
        if (this.exportModel.id != null && !this.authorizationService.canDeleteExport(this.exportModel.id)) {
            return;
        } else {
            this.netflowEnabled = !this.netflowEnabled;
        }
    }

    saveExport(closeObject) {
        this.subscription.add(
            this.exportsService.save(this.exportModel).subscribe((result) => {
                this.processing = false;
                this.growlerService.show(
                    new GrowlerData('success', 'Success', 'Save Complete', 'Gateway information has been saved.')
                );
                this.dialogRef.close(closeObject);
            })
        );
    }

    deleteExport(closeObject) {
        this.subscription.add(
            this.exportsService.delete(this.exportModel).subscribe((result) => {
                this.processing = false;
                this.growlerService.show(
                    new GrowlerData('success', 'Success', 'Save Complete', 'Gateway information has been saved.')
                );
                this.dialogRef.close(closeObject);
            })
        );
    }

    showExportForm() {
        return (
            !this.isZitiGateway &&
            !this.isAzureGateway &&
            !this.isHAGateway &&
            !this.haUpgrade &&
            (this.canCreateExports || this.netflowEnabled) &&
            (this.collectors.length > 0 || this.hasExport)
        );
    }

    downloadJWT() {
        const name = this.model.name + '.jwt';
        saveAs(this.zitiRegKeyBlob, name);
    }

    async getZitiRegKey() {
        this.zitiRegKeyBlob = await this.gatewayService
            .downloadRegistrationKey(this.model)
            .pipe(take(1))
            .toPromise()
            .then((result) => result);
    }

    private getGroups() {
        this.groupSubscription.unsubscribe();
        // othwerise, get the list of all groups and appwans
        this.groupSubscription = this.groupService.get().subscribe((result) => {
            this.allGroups = [];
            for (const item of result) {
                const group = new Group(item);
                if (this.authorizationService.canUpdateEndpointGroup(group.id)) {
                    group['canUpdate'] = true;
                }
                this.allGroups.push(group);
            }
        });
    }

    private getRelatedGroups() {
        this.relatedGroupsSubscription.unsubscribe();
        this.relatedGroupsSubscription = this.gatewayService
            .getLinkedResources(this.model, 'endpointGroups')
            .subscribe((result) => {
                this.groupsList = result;
                this.groups.clear();
                this.groupsData = [];
                for (let group of result) {
                    group = new Group(group);
                    if (this.authorizationService.canUpdateEndpointGroup(group.id)) {
                        group['canUpdate'] = true;
                    }
                    this.groups.push(group, this.model.id, 'group', group.id, group.name, false);
                    this.groupsData.push(group);
                }
            });
    }

    // function for determining whether or not a gateway is associated with an appwan or service
    private async gatewayHasAppwansOrGroups(endpoint) {
        return {
            hasAppwans: this.appwansList.length !== 0,
            appwansLength: this.appwansList.length,
            hasGroups: this.groupsList.length !== 0, // if the second result (from the second promise, endpointGroups)
            groupsLength: this.groupsList.length,
        };
    }

    // function for opening the dialog for when the gateway is associated with an appwan or group
    private openHaUpgradeAppwanOrGroupError(endpoint, hasAppwansOrGroups) {
        // resoure string based on whether it should be singular or plural
        let appwanString = `${hasAppwansOrGroups.appwansLength} AppWan`;
        appwanString += hasAppwansOrGroups.appwansLength > 1 ? 's' : '';

        let groupString = `${hasAppwansOrGroups.groupsLength} Endpoint Group`;
        groupString += hasAppwansOrGroups.groupsLength > 1 ? 's' : '';

        let bulletItems = [{ name: appwanString }, { name: groupString }];

        // string for the final appwan and or group error
        let appwanOrGroupError = '';

        // if there are both appwans and groups, build the message to indicate the gateway is associated with groups and appwans
        if (hasAppwansOrGroups.hasAppwans && hasAppwansOrGroups.hasGroups) {
            appwanOrGroupError = 'AppWans and Endpoint Groups';
            bulletItems = [{ name: appwanString }, { name: groupString }];
        } else if (hasAppwansOrGroups.hasAppwans) {
            // otherwise, if there are only appwans, build the message to indicate the gateway is associated with appwans
            appwanOrGroupError = 'AppWans';
            bulletItems = [{ name: appwanString }];
        } else {
            // otherwise, there are only groups, build the string to indicate the gateway is associated with groups
            appwanOrGroupError = 'Endpoint Groups';
            bulletItems = [{ name: groupString }];
        }

        // building the final warning message
        const warningMessage = `We're unable to upgrade ${endpoint.name} as it has the following Associations. Please remove it from all ${appwanOrGroupError} before upgrading.`;

        // data object for the confirm component
        const data = {
            title: 'Warning',
            appendId: 'Gateways',
            subtitle: warningMessage,
            icon: 'Caution',
            action: 'OK',
            warning: true,
            bulletList: bulletItems,
        };

        // opening the confirm dialog
        this.confirmDialog = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '375px',
            width: '600px',
            autoFocus: false,
        });
        this.processing = false;
    }
}
