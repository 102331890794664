<div class="modal solid open" id="ShareModal">
    <div (click)="close()" class="miniClose" id="MiniCloseXButton"></div>
    <div class="icon"></div>
    <div class="title">Share your {{ type }} details</div>
    <div class="subtitle">Email the Registration Key, Download Link, and Install Instructions</div>
    <div class="row">
        <textarea
            [(ngModel)]="emails"
            [ngClass]="{ error: errorEmails }"
            data-private
            id="ShareAddresses"
            placeholder="Enter one or more emails seperated with a ;"
        ></textarea>
    </div>
    <div class="buttons">
        <div (click)="close()" class="linkButton" id="MiniCloseButton">Oops, no get me out of here</div>
        <button (click)="doShare()" class="save" id="DoShareButton">Share</button>
    </div>
</div>
