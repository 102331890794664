import { Component } from '@angular/core';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';

@Component({
    selector: 'app-form-support-button',
    templateUrl: './form-support-button.component.html',
    styleUrls: ['./form-support-button.component.scss'],
})
export class FormSupportButtonComponent {
    constructor(private zendeskService: NgxZendeskWebwidgetService) {}

    openSupport() {
        this.zendeskService.zE('webWidget', 'show');
        this.zendeskService.zE('webWidget', 'open');
    }
}
