import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Environment, ENVIRONMENT} from "@netfoundry-ui/shared/model";
import { Message } from 'primeng/api';

declare const gtag;

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
    appendId = '';
    title = '';
    subtitle = '';
    icon = '';
    action = '';
    showConfirm = false;
    useInnerHtml = false;
    cancelAction = 'Oops, no get me out of here';
    showWarning = false;

    isTimeoutWarning = false;
    isWarning = false;
    bulletList = [];
    bulletListOnClick;

    isDestructive = false;
    isCancel = false;
    errorConfirm = false;
    confirmString = '';
    afterListText;
    secondBulletList = [];
    secondBulletListOnClick;
    context;
    messages: Message[] | undefined;

    itemName;

    deletePlaceholder = 'DELETE ';
    placeholder: any;
    hideCancelLink = false;

    constructor(
      private dialogRef: MatDialogRef<ConfirmComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      @Inject(ENVIRONMENT) public environment: Environment,
    ) {
        this.appendId = data.appendId;
        this.title = data.title;
        this.useInnerHtml = data.useInnerHtml ? data.useInnerHtml : false;
        this.subtitle = data.subtitle;
        this.icon = data.icon;
        this.action = data.action;
        this.isDestructive = data.isDestructive;
        this.isCancel = data.isCancel;
        this.context = data.context;

        this.messages = [
            { severity: 'warning', summary: 'Warning', detail: 'Please note that this will cause a brief network controller blip due to the addition of the Public Cert.' },
        ];
        if (data.cancelAction != null) {
            this.cancelAction = data.cancelAction;
        }
        if (data.warning) {
            this.isWarning = true;
            this.cancelAction = '';
        }
        if (data.bulletList) {
            this.bulletList = data.bulletList;
        }

        if (data.secondBulletList) {
            this.secondBulletList = data.secondBulletList;
        }

        if (data.afterListText) {
            this.afterListText = data.afterListText;
        }

        if (data.itemName) {
            this.itemName = data.itemName.toLowerCase();
            this.deletePlaceholder += data.itemName.toUpperCase();
        }

        if(data.showWarning) {
            this.showWarning = data.showWarning;
        }
        this.bulletListOnClick = data.bulletListOnClick;
        this.secondBulletListOnClick = data.secondBulletListOnClick;
        this.isTimeoutWarning = data.isTimeoutWarning;
        this.hideCancelLink = data.hideCancelLink;
    }

    cancel() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'confirm',
                event_label: this.title + ':' + this.subtitle,
                value: false,
            });
        this.dialogRef.close(false);
    }

    confirm() {
        if (gtag)
            gtag('event', 'click', {
                event_category: 'confirm',
                event_label: this.title + ':' + this.subtitle,
                value: this.itemName,
            });
        if (!this.isDestructive || this.environment.originDomain === 'localhost' || (this.isDestructive && this.checkConfirm())) {
            this.dialogRef.close(true);
        }
    }

    checkConfirm() {
        const action = this.isCancel ? 'cancel' : 'delete';
        this.errorConfirm = this.confirmString.trim().toLowerCase() !== `${action} ${this.itemName}`;
        return !this.errorConfirm;
    }
}
