import { ElasticsearchQuery } from './elasticsearch-query';

export class UtilizationTotalsQuery extends ElasticsearchQuery {
    /**
     * Base query
     */
    protected model = {
        size: 0,
        query: {
            bool: {
                must: [
                    {
                        query_string: {
                            query: '*',
                            analyze_wildcard: true,
                        },
                    },
                ],
                must_not: [],
            },
        },
        _source: {
            excludes: [],
        },
        aggs: {
            // aggregate goes here
            aggregate: {},
        },
    };

    public getIndexPattern() {
        return 'ncvtccurrent';
    }

    /**
     * Sets the aggregation term and size
     */
    public setAggregate(term, size = 5) {
        this.model.aggs.aggregate = {
            terms: {
                field: term,
                size: size,
                order: {
                    '1': 'desc',
                },
            },
            aggs: {
                '1': {
                    sum: {
                        field: 'bytes',
                    },
                },
            },
        };
    }

    /**
     * Get the name of the aggregation field
     */
    public getAggregateName() {
        return 'aggregate';
    }
}
