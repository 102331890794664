<div class="options">
    <div *ngIf="showUsageEndpoint" class="toggle" data-toset="UsageEndpoint">
        <div
            (click)="setEndpointType('All')"
            [ngClass]="{ option: true, selected: endpointType === 'All' }"
            id="endpointTypeAll"
        >
            All
        </div>
        <div
            (click)="setEndpointType('Gateways')"
            [ngClass]="{ option: true, selected: endpointType === 'Gateways' }"
            id="endpointTypeGateways"
        >
            Gateways
        </div>
        <div
            (click)="setEndpointType('Clients')"
            [ngClass]="{ option: true, selected: endpointType === 'Clients' }"
            id="endpointTypeClients"
        >
            Clients
        </div>
    </div>

    <div class="toggle" data-toset="UsageType">
        <div
            (click)="setDirection('tx')"
            [ngClass]="{ option: true, selected: directionFilter === 'tx' }"
            id="directionTx"
        >
            Received
        </div>
        <div
            (click)="setDirection('rx')"
            [ngClass]="{ option: true, selected: directionFilter === 'rx' }"
            id="directionRx"
        >
            Sent
        </div>
    </div>
</div>
