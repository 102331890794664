import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ElasticsearchService, UtilizationTotalsQuery } from '@netfoundry-ui/shared/elasticsearch';
import { Endpoint, GatewayCluster } from '@netfoundry-ui/shared/model';
import { ApiService, ErrorHistoryService, LoggerService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

const AWSGW = 'AWSCPEGW';
const publicInternetGW = 'GW';

@Component({
    selector: 'app-endpoint-geo',
    templateUrl: './endpoint-geo.component.html',
    styleUrls: ['./endpoint-geo.component.scss'],
})
export class EndpointGeoComponent implements OnInit, OnDestroy, OnChanges {
    endTime = Date.now();
    // - 7 days
    startTime = this.endTime - 24 * 60 * 60 * 1000 * 7;
    @Input() dataCenterId;
    @Input() geoRegionId;
    @Input() componentId;
    @Output() hasGeoData = new EventEmitter();
    @Input() endpoint = new Endpoint({});
    @Input() cloudRegionOnly = false;
    @Input() title = 'Cloud Region';
    // gateway cluster
    @Input() cluster = new GatewayCluster({});
    // list of componentIds for the gateways on the cluster
    @Input() componentIdList;
    locationOutput = '';
    hasGeoDetails = false;
    totals: any[];
    networkLoading = true;
    networkArea = false;
    noData = false;
    initialized = false;
    region;
    dataCenter;
    /**
     * it appears that [ngModel]="someValue" must be used instead of value="{{ someValue }}"
     * in order for the value to be somewhere in the atribute of the element when inspected in the browser
     * as such, the strings for basic-geo, foreign-geo, and us-geo must be created here instead of in the view
     * this is because [ngModel] does not allow for interpolation
     */
    basicGeoString = '';
    foreignGeoString = '';
    usGeoString = '';
    geo = {};
    geoModel = {
        continent_code: '',
        region_code: '',
        region_name: '',
        country_code3: '',
        ip: '',
        city_name: '',
        country_code2: '',
        longitude: 0,
        country_name: '0',
        location: {
            lat: 0,
            lon: 0,
        },
        timezone: '0',
        latitude: 0,
    };
    render: 'region' | 'basic-geo' | 'foreign-geo' | 'us-geo' | 'data-center' = 'region';
    currentNetwork;
    currentOrganization;
    private subscriptions: Subscription = new Subscription();
    private elasticSub: Subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private elasticsearch: ElasticsearchService,
        private apiService: ApiService,
        private authorizationService: AuthorizationService,
        private errorHistoryService: ErrorHistoryService
    ) {}

    ngOnInit() {
        this.initialized = true;
        this.apiService.currentNetwork.subscribe((network) => {
            if (this.cloudRegionOnly) {
                this.determineView();
            } else {
                this.getLastGeo(network.getId(), this.componentId, this.componentIdList);
            }
        });
    }

    ngOnChanges() {
        // if the component was initialized and either an endpoint or cluster was provided
        if (this.initialized && (this.endpoint.getId() !== null || this.cluster.getId() !== null)) {
            // reset the geoModel
            this.geo = this.geoModel;
            if (this.cloudRegionOnly) {
                this.determineView();
            } else {
                this.getLastGeo(this.apiService.theNetworkIs.getId(), this.componentId, this.componentIdList);
            }
        }
    }

    ngOnDestroy() {
        if (this.subscriptions != null) {
            this.subscriptions.unsubscribe();
        }
    }

    async determineView() {
        this.basicGeoString = '';
        this.foreignGeoString = '';
        this.usGeoString = '';

        // object representing the current endpoint
        let currEndpoint;

        // if the cluster was provided, user the cluster as the endpoint
        if (this.cluster.getId() !== null) {
            currEndpoint = this.cluster;
        } else {
            // otherwise use the endpoint provided
            currEndpoint = this.endpoint;
        }

        // it's possible for this to not finish before the if statements complete
        // turning into a promise to wait on the result if necessary
        const hasGeoRegion = currEndpoint._links['geoRegion'] != null;
        let promise;
        if (hasGeoRegion) {
            const geoRegionId = currEndpoint.getLinkedResourceId('geoRegion');
            if (this.authorizationService.canGetGeoRegion(geoRegionId)) {
                promise = this.apiService
                    .get(currEndpoint.getLinkedResource('geoRegion'))
                    .toPromise()
                    .then((region) => {
                        this.region = region['name'];
                    });
            }
        }

        const hasDataCenter = currEndpoint._links['dataCenter'] != null;
        if (hasDataCenter) {
            const dataCenterId = currEndpoint.getLinkedResourceId('dataCenter');
            if (this.authorizationService.canGetDataCenter(dataCenterId)) {
                this.subscriptions.add(
                    this.apiService.get(currEndpoint.getLinkedResource('dataCenter')).subscribe((dataCenter) => {
                        this.dataCenter = dataCenter['name'];
                    })
                );
            }
        }

        // most detailed view
        if (this.geo['city_name'] !== undefined && this.geo['city_name'] !== '' && this.geo['country_code2'] === 'US') {
            this.render = 'us-geo';
            this.usGeoString = `${this.geo['ip']} ( ${this.geo['city_name']}, ${this.geo['region_code']}, ${this.geo['country_name']})`;
        } else if (
            this.geo['city_name'] !== undefined &&
            this.geo['city_name'] !== '' &&
            this.geo['country_code2'] !== 'US'
        ) {
            this.render = 'foreign-geo';
            this.foreignGeoString = `${this.geo['ip']} ( ${this.geo['city_name']}, ${this.geo['country_name']})`;
        } else if (
            this.geo['city_name'] === undefined &&
            this.geo['ip'] !== undefined &&
            this.geo['country_code2'] !== undefined &&
            this.geo['country_code2'] !== ''
        ) {
            // waiting on the promise so the region is not undefined
            await promise;
            this.render = 'basic-geo';
            this.basicGeoString = `${this.geo['ip']} ( ${this.region} )`;
        } else if (
            hasDataCenter &&
            (currEndpoint.endpointType === AWSGW || currEndpoint.endpointType === publicInternetGW)
        ) {
            this.render = 'data-center';
        } else {
            this.render = 'region';
        }
    }

    /**
     * TODO - Make the aggregation field dynamic from an observable
     */
    public getLastGeo(networkId, componentId, componentIdList) {
        // if the networkId is null and neither a componentId nor a componentIdList were provided, return
        if (
            networkId === null ||
            ((componentIdList === null || componentIdList === undefined || componentIdList.length === 0) &&
                (componentId === null || componentId === undefined))
        ) {
            return;
        }

        this.elasticSub.unsubscribe();
        const query = new UtilizationTotalsQuery(this.logger);
        query.addTimeFilter(this.startTime, this.endTime);

        // Get a single record (most recent) back from a search query

        // if the componentIdList was provided, use matchFromList
        if (componentIdList != null && componentIdList.length > 0) {
            query.matchFromList('resourceId', componentIdList);
        } else {
            // otherwise, match the specific componentId
            query.addFilter('resourceId', componentId);
        }
        query.addFilter('networkId', networkId);
        query.addFilter('organizationId', this.apiService.theOrgIs.getId());
        query.setSize(1);
        query.disableAggregation();

        this.logger.info('Last GeoIP Query', JSON.stringify(query.getQuery()));

        // we may not need to unsubscribe from this manually, but it doesn't hurt to do so
        this.elasticSub = this.elasticsearch.search('ncvtccurrent', query.getQuery()).subscribe(
            (data) => {
                this.logger.info('GEO DATA', data);

                if (data['hits']['hits'] !== undefined && data['hits']['hits'].length > 0) {
                    const first = data['hits']['hits'].shift();

                    this.logger.info('FIRST', first);

                    if (first['_source']['geo']) {
                        this.geo = first['_source']['geo'];
                    }
                }

                this.determineView();
            },
            (error) => {
                this.errorHistoryService.addError(error.message);
            }
        );
    }
}
