<div class="fullColor"></div>
<div class="row center">
    <div class="col-md-5 col-sm-12 pl-0">
        <div class="networkname">{{ currentNetwork.name }}</div>
    </div>
    <div class="col-md-7 col-sm-12 pr-0">
        <div class="dashboardtitle">Network Dashboard</div>
        <button (click)="upgrade()" *ngIf="isUpgradeable" class="save alert confirm upgradeable" id="upgradeAvailable">
            Network Upgrade Available
        </button>
    </div>
</div>
<div class="row center mt8">
    <div class="col-lg-7 col-md-12 col-sm-12 mb24 pl-0 pr-s-0 pr-m-0 smfloat">
        <div class="overviewpod" *ngIf="!environment.v3Enabled">
            <div class="col-md-2 right-bar pl-0 pr-0">
                <div class="dash"></div>
            </div>
            <div class="col-md-2 right-bar pl-0 pr-0">
                <div class="overviewlink" routerLink="{{ App_Wans }}">
                    <div class="overview-count">{{ appWanTotalElements }}</div>
                    <div class="overview-text mb-sm-16">
                        {{ featureService?.disableNewNavigation ? 'APPWANS' : 'SERVICE POLICIES' }}
                    </div>
                </div>
            </div>
            <div class="col-md-2 right-bar pl-0 pr-0">
                <div class="overviewlink" routerLink="{{ Services }}">
                    <div class="overview-count">{{ serviceTotalElements }}</div>
                    <div class="overview-text mb-sm-16">SERVICES</div>
                </div>
            </div>
            <div class="col-md-2 right-bar pl-0 pr-0">
                <div class="overviewlink" routerLink="{{ Ziti_Endpoints }}">
                    <div class="overview-count">{{ endpointTotalElements }}</div>
                    <div class="overview-text mb-sm-16">
                        {{ featureService.disableNewNavigation ? 'ENDPOINTS' : 'IDENTITIES' }}
                    </div>
                </div>
            </div>
            <div class="col-md-2 right-bar pl-0 pr-0">
                <div class="overviewlink" routerLink="{{ Ziti_Routers }}">
                    <div class="overview-count">{{ edgeRouterTotalElements }}</div>
                    <div class="overview-text mb-sm-16">ROUTERS</div>
                </div>
            </div>
            <div class="col-md-2 no-bar pl-0 pr-0">
                <div class="overviewlink" routerLink="{{ Edge_Router_Policies }}">
                    <div class="overview-count">{{ edgeRouterPolicyTotalElements }}</div>
                    <div class="overview-text mb-sm-16">ROUTER POLICIES</div>
                </div>
            </div>
        </div>
        <div class="overviewpod" *ngIf="environment.v3Enabled">
          <div class="col-md-2 right-bar pl-0 pr-0">
            <div class="dash"></div>
          </div>
          <div class="col-md-2 right-bar pl-0 pr-0">
            <div class="overviewlink" routerLink="{{ Ziti_Service_Policies }}">
              <div class="overview-count">{{ appWanTotalElements }}</div>
              <div class="overview-text mb-sm-16">
                {{ featureService?.disableNewNavigation ? 'APPWANS' : 'SERVICE POLICIES' }}
              </div>
            </div>
          </div>
          <div class="col-md-2 right-bar pl-0 pr-0">
            <div class="overviewlink" routerLink="{{ Ziti_Services }}">
              <div class="overview-count">{{ serviceTotalElements }}</div>
              <div class="overview-text mb-sm-16">SERVICES</div>
            </div>
          </div>
          <div class="col-md-2 right-bar pl-0 pr-0">
            <div class="overviewlink" routerLink="{{ Ziti_Identities }}">
              <div class="overview-count">{{ endpointTotalElements }}</div>
              <div class="overview-text mb-sm-16">
                {{ featureService.disableNewNavigation ? 'ENDPOINTS' : 'IDENTITIES' }}
              </div>
            </div>
          </div>
          <div class="col-md-2 right-bar pl-0 pr-0">
            <div class="overviewlink" routerLink="{{ Ziti_Edge_Routers }}">
              <div class="overview-count">{{ edgeRouterTotalElements }}</div>
              <div class="overview-text mb-sm-16">ROUTERS</div>
            </div>
          </div>
          <div class="col-md-2 no-bar pl-0 pr-0">
            <div class="overviewlink" routerLink="{{ Ziti_Edge_Router_Policies }}">
              <div class="overview-count">{{ edgeRouterPolicyTotalElements }}</div>
              <div class="overview-text mb-sm-16">ROUTER POLICIES</div>
            </div>
          </div>
        </div>
        <div class="overviewpod" *ngIf="false && environment.v3Enabled">
          <div class="col-md-2 right-bar pl-0 pr-0">
            <div class="dash"></div>
          </div>
          <div class="col-md-2 right-bar pl-0 pr-0">
            <div class="overviewlink" routerLink="{{ Network_Controllers }}">
              <div class="overview-count">{{ networksTotalElements }}</div>
              <div class="overview-text mb-sm-16">CONTROLLERS</div>
            </div>
          </div>
          <div class="col-md-2 no-bar pl-0 pr-0">
            <div class="overviewlink" routerLink="{{ V8_Routers }}">
              <div class="overview-count">{{ edgeRouterTotalElements }}</div>
              <div class="overview-text mb-sm-16">ROUTERS</div>
            </div>
          </div>
        </div>
    </div>

    <div *ngIf="!environment.v3Enabled" class="col-lg-2 col-sm-4 mb24 pl-s-0 pr-s-0 pl-m-0 smfloat top-cell" routerLink="/topology">
        <div class="clearbutton">
            <div class="icon visualizericon"></div>
            <div class="text">NETWORK<br />VISUALIZER</div>
        </div>
    </div>

    <div class="col-lg-2 col-sm-4 mb24 pr-s-0 pl-s-0 smfloat">
        <div (click)="gotoDownloads()" class="clearbutton">
            <div class="icon downloadsicon"></div>
            <div class="text">{{ featureService.disableNewNavigation ? 'ENDPOINT' : 'IDENTITY' }}<br />DOWNLOADS</div>
        </div>
    </div>

    <div class="col-lg-1 col-sm-4 mb24 pr-s-0 pl-s-0 pr-0 smfloat">
        <div (click)="gotoapis()" class="clearbutton">
            <div class="ribbonbox">
                <div class="corner-ribbon top-right shadow">NEW</div>
            </div>
            <div class="icon nfapi"></div>
            <div class="text">API<br />DOCS</div>
        </div>
    </div>
</div>

<div class="row" [ngClass]="{ topRow: !featureService.isCloudZitiNetwork(currentNetwork) }">
    <div class="col-sm-4 mb24 pl-0">
        <div class="cardStack full" style="height: 600px">
            <!-- Mop Event Timeline -->
            <app-mop-timeline [endTime]="endTime" [networkId]="currentNetwork.id" [startTime]="startTime">
            </app-mop-timeline>
        </div>
        <div class="cardStack full networkEventMetrics" style="height: 600px">
            <app-network-metrics-events-dash [dateFilter]="dateFilter" [networkId]="currentNetwork.id">
            </app-network-metrics-events-dash>
        </div>

        <!--      <div id="NetworkHealthArea" class="cardStack full billing" style="height: 374px;">-->
        <!--        <div class="title">Network Health</div>-->
        <!--          <app-ziti-network-health></app-ziti-network-health>-->
        <!--      </div>-->
    </div>
    <div class="col-sm-8 pr-0">
        <div class="row mb24">
            <app-billing-summary [networkFilter]="currentNetwork" [showDashLink]="false"></app-billing-summary>
        </div>

        <div class="row mb24">
            <div class="cardStack full" style="height: 265px">
                <!-- Date Filter -->
                <app-date-filters
                    (startChange)="setStartTime($event)"
                    [currentTimeFilter]="dateFilter"
                ></app-date-filters>
                <div class="title center utilization">Network Utilization</div>

                <div style="margin-top: 50px">
                    <app-ziti-utilization-basic
                        [dateFilter]="dateFilter"
                        [height]="'220px'"
                        [networkGroupId]="currentNetwork.networkGroupId"
                        [networkId]="currentNetwork.id"
                        [newEndTime]="endTime"
                        [newStartTime]="startTime"
                    ></app-ziti-utilization-basic>
                </div>
            </div>
            <!--      <div class="row" >-->
            <!--        <div class="cardStack full" style="height: 370px;">-->

            <!--        </div>-->
            <!--      </div>-->
        </div>

        <div class="row mb24">
            <div class="cardStack full" style="height: 265px">
                <!-- <app-date-filters
[currentTimeFilter]="dateFilter"
(startChange)="setStartTime($event)">
</app-date-filters> -->
                <div class="title center utilization">
                    Active {{ featureService.disableNewNavigation ? 'Endpoints' : 'Identities' }}
                </div>
                <div style="margin-top: 50px">
                    <app-elk-visualization
                        [dateFilter]="dateFilter"
                        [height]="'220px'"
                        [networkId]="currentNetwork.id"
                        [organizationId]="currentNetwork.networkGroupId"
                    ></app-elk-visualization>
                </div>
            </div>
        </div>
        <div class="row" style="clear: both"></div>
        <div class="row mb24">
            <div class="cardStack full top-components-container">
                <mat-tab-group class="darkMode" mat-align-tabs="start">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom"
                                >Top {{ featureService?.disableNewNavigation ? 'Endpoints' : 'Identities' }}</span
                            >
                        </ng-template>
                        <div>
                            <app-ziti-utilization-pie
                                [dateFilter]="dateFilter"
                                [height]="'300px'"
                                [networkId]="currentNetwork.id"
                                [organizationId]="currentNetwork.networkGroupId"
                                [aggregateField]="templateService.identity_aggregate_field"
                            ></app-ziti-utilization-pie>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom">Top Services</span>
                        </ng-template>
                        <div>
                            <app-ziti-utilization-pie
                                [dateFilter]="dateFilter"
                                [height]="'300px'"
                                [networkId]="currentNetwork.id"
                                [organizationId]="currentNetwork.networkGroupId"
                                [aggregateField]="templateService.service_aggregate_field"
                            ></app-ziti-utilization-pie>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom">Top Edge Routers</span>
                        </ng-template>
                        <div>
                            <app-ziti-utilization-pie
                                [dateFilter]="dateFilter"
                                [height]="'300px'"
                                [networkId]="currentNetwork.id"
                                [organizationId]="currentNetwork.networkGroupId"
                                [aggregateField]="templateService.edge_router_aggregate_field"
                            ></app-ziti-utilization-pie>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="title nobottom">Service Health</span>
                        </ng-template>

                        <div>
                            <app-service-health-table
                                [dateFilter]="dateFilter"
                                [networkId]="currentNetwork.id"
                                [organizationId]="currentNetwork.networkGroupId"
                            ></app-service-health-table>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div class="row" style="clear: both">&nbsp;</div>
    </div>
</div>
