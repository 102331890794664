import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeatureExportCsvModule } from '@netfoundry-ui/feature/export-csv';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { SharedApiv2Module } from '@netfoundry-ui/shared/apiv2';
import { UiNoitemsModule } from '@netfoundry-ui/ui/noitems';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxPaginationModule } from 'ngx-pagination';
import { FeatureDataTableModule } from '@netfoundry-ui/feature/data-table';
import { LottieModule } from 'ngx-lottie';
import { EventTimelineSearchComponent } from './event-timeline-search.component';

export function playerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
    imports: [
        CommonModule,
        UiPipesModule,
        UiLoaderModule,
        FeatureExportCsvModule,
        NgxPaginationModule,
        UiNoitemsModule,
        ClickOutsideModule,
        SharedApiv2Module,
        FeatureDataTableModule,
        LottieModule.forRoot({ player: playerFactory }),
    ],
    declarations: [EventTimelineSearchComponent],
    exports: [EventTimelineSearchComponent],
})
export class FeatureEventTimelineSearchModule {}
