<div [ngStyle]="{ height: height }" class="modal solid open" id="TableViewModal">
    <div (click)="close()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="title" style="padding-top: 33px; padding-left: 120px">
        {{ title }}
    </div>
    <div class="alarmmodalicon"></div>

    <div class="subtitle">{{ subtitle }}</div>

    <div class="row">
        <!-- rows -->
        <div *ngFor="let key of keys" class="table two">
            <!-- only show keys that have data in the values -->
            <div *ngIf="item[key] !== '' && item[key] != null" class="header borderBottom borderRight">
                {{ key.toUpperCase() }}
            </div>
            <div *ngIf="item[key] !== '' && item[key] != null" class="detailtext borderBottom">
                {{ item[key] }}
            </div>
        </div>
    </div>

    <div class="buttons">
        <button (click)="open()" class="notificationbutton" id="TableViewModalButtonTest">
            <div class="plusicon"></div>
            Add Notification
        </button>
        <button class="save" id="TableViewModalButton" mat-dialog-close>OK Thanks</button>
    </div>
</div>
