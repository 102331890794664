<div class="title">Management Events</div>

<!--<app-noitems [typeName]="'timeline events'" [isEmpty]="eventData.length==0 && !isLoading" ></app-noitems>-->
<div *ngIf="eventData.length === 0 && !isLoading && canReadElasticSearch" class="noData" id="NoArea">No Data</div>
<div *ngIf="eventData.length === 0 && !isLoading && !canReadElasticSearch" class="noData" id="NoArea">
    No Permissions
</div>

<app-loader [isLoading]="isLoading"></app-loader>

<div
    *ngIf="eventData.length > 0 && !isLoading"
    class="row"
    id="NetworkTimeline"
    style="height: calc(100% - 50px); padding-left: 0px; padding-right: 41px"
>
    <div *ngFor="let alarm of eventData; let i = index; trackBy: trackByTimestamp">
        <div [ngClass]="{ hidden: !isNewDate(i) }" class="timeline">
            <span class="date">{{ formatDate(alarm['@timestamp']) }}</span>
        </div>
        <div class="timeline item">
            <div class="clock icon-Icon_Alarm"></div>
            <div class="connector"></div>
            {{ formatTime(alarm['@timestamp']) }}&nbsp;

            <!-- MOP event -->
            <span
                *ngIf="alarm['eventSeverity'] !== undefined && alarm['eventSource'] === 'MOP'"
                class="bubble severity{{ alarm.eventSeverity | titlecase }}"
                style="margin-right: 0px"
            ></span>

            <!-- current vtc online -->
            <span
                *ngIf="alarm['eventSeverity'] !== undefined && alarm['eventDescription'] === 'VTC Online'"
                class="bubble severityInfo"
                style="margin-right: 0px"
            ></span>

            <!-- current vtc offline -->
            <span
                *ngIf="alarm['eventSeverity'] !== undefined && alarm['eventDescription'] === 'VTC Offline'"
                class="bubble severityError"
                style="margin-right: 0px"
            ></span>

            <!-- current vtc provision -->
            <span
                *ngIf="alarm['eventSeverity'] !== undefined && alarm['eventDescription'] === 'VTC Provisioning'"
                class="bubble severityInfo"
                style="margin-right: 0px"
            ></span>

            <!-- legacy vtc online event -->
            <span
                *ngIf="alarm['severity'] !== undefined && alarm['event'] === 'VTC Online'"
                class="bubble severityInfo"
                style="margin-right: 0px"
            ></span>

            <!-- legacy vtc offline event -->
            <span
                *ngIf="alarm['severity'] !== undefined && alarm['event'] === 'VTC Offline'"
                class="bubble severityError"
                style="margin-right: 0px"
            ></span>

            <span *ngIf="alarm['commonName'] !== undefined" class="name">
                {{ alarm.componentName }}
            </span>

            <span *ngIf="alarm['commonName'] === undefined"> {{ alarm.componentName }} </span>
            <br />
            <div *ngIf="alarm['eventDescription'] !== undefined" class="details">
                {{ alarm.eventDescription }}
            </div>

            <div *ngIf="alarm['event'] !== undefined" class="details">
                {{ alarm.event }}
            </div>
        </div>
    </div>
</div>
