<div class="step-by-step-container">
    <div class="step-by-step-header">Step by Step</div>
    <span class="step-by-step-details">
        Once you've finished testing the sample service, use the tutorials below to learn how to navigate the console
        and define Zero Trust components using the NetFoundry console.
    </span>
    <div class="sample-service-steps">
        <div class="sample-service-step">
            <div class="step-number step-1"></div>
            <div class="step-details-container">
                <div class="step-label">Create a Service</div>
                <div class="step-details">
                    Open a guided tutorial with instructions on how to define an Endpoint, Service, and AppWAN.
                </div>
                <div
                    (click)="open(steps[0])"
                    [ngClass]="{ disabled: disabled }"
                    [title]="
                        disabled
                            ? 'Please complete the sample service steps on the previous page before beginning tutorial'
                            : ''
                    "
                    class="save step-by-step-button button"
                >
                    {{ 'Start' }}
                </div>
            </div>
        </div>
    </div>
</div>
