import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { AuthPolicy, Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { Subscription } from 'rxjs';
import { TableHeaderDefaultComponent } from '@netfoundry-ui/feature/data-table';
import { AnimationOptions } from 'ngx-lottie';
import { FeatureService } from '@netfoundry-ui/shared/services';
import { AuthPolicyFormComponent } from '@netfoundry-ui/feature/form/auth-policy-form';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { AuthPolicyService } from '@netfoundry-ui/shared/apiv2';
import { TableFilterService } from '@netfoundry-ui/feature/shared-services';

@Component({
    selector: 'app-auth-policies',
    templateUrl: './auth-policies.component.html',
    styleUrls: ['./auth-policies.component.scss'],
})
export class AuthPoliciesComponent implements OnInit, OnDestroy {
    displayedItems: any;
    columnDefs: any;
    changedPage: any;
    showNoData: any;
    canCreate = false;
    subscription = new Subscription();

    dialogRef: MatDialogRef<AuthPolicyFormComponent> | undefined;
    confirmRef: MatDialogRef<ConfirmComponent> | undefined;

    private data: AuthPolicy[] = [];

    showInfoBubble = false;
    lottieOptions: AnimationOptions = {
        path: '/assets/animations/SmartZiggy.json',
    };
    typeName = 'Authentication Policy';
    tableId = 'auth-policies';
    infoBubbleTitle = 'What is an Authentication Policy?';
    infoBubbleText =
        'Authentication Policies restrict the primary authentication methods available to endpoints' +
        ' (identities) and may enforce additional secondary authentication factors.' +
        ' Example authentication types are Certificates and External JWTs';
    infoBubbleUrl = 'https://docs.openziti.io/docs/learn/core-concepts/security/authentication/external-jwt-signers/';

    jwtSignerMap: any = {};

    constructor(
        public dialogForm: MatDialog,
        private growlerService: GrowlerService,
        public svc: AuthPolicyService,
        @Inject(ENVIRONMENT) private environment: Environment,
        private featureService: FeatureService,
        public filterService: TableFilterService
    ) {}

    ngOnInit(): void {
        this.svc.init();
        this.canCreate = this.svc.canCreate();

        this.subscription.add(
            this.svc.filteredRecords.pipe().subscribe((result) => {
                this.displayedItems = result || [];
                this.showNoData = this.displayedItems.length === 0 && !this.svc.columnFilters;
            })
        );

        this.subscription.add(
            this.svc.confirmDelete.subscribe(() => {
                this.openConfirm('delete');
            })
        );

        this.subscription.add(
            // subscribing to the filter service to handle the change in page
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.svc.page = pageNum;
                this.svc.pageHasChanged.next(true);
                this.svc.debouncedRefreshRecords();
            })
        );

        this.subscription.add(
            // subscribing to the filter service to handle search filter changes
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.svc.filterString = filterString;
                this.svc.filterHasChanged.next(true);
                this.svc.debouncedRefreshRecords();
            })
        );

        this.initTableColumns();
        this.showInfoBubble = this.featureService.getShowInfoBubble(this.tableId) ?? true;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    actionRequested(request: any) {
        switch (request.action) {
            case 'toggleAll':
                this.svc.toggleAll(this.displayedItems);
                break;
            case 'toggleItem':
                this.svc.toggle(request.item);
                break;
            case 'delete':
                this.svc.confirmAction(request.action, request.item);
                break;
            case 'update':
                this.edit(true, request.item);
                break;
            default:
                break;
        }
    }

    async edit(addEvent: boolean, policy?: AuthPolicy) {
        if (addEvent) {
            if ((policy?.id && this.svc.canUpdate(policy.id)) || (!policy && this.svc.canCreate())) {
                if (this.svc.networkReady()) {
                    const model = policy ?? new AuthPolicy();
                    model.networkId = this.svc.currentNetwork.getValue().id;
                    const jwtSigners = await this.svc.getSignersAsAttributes();
                    const selectedJwtSigners = this.svc.getSelectedAllowedSigners(jwtSigners, model);

                    this.dialogRef = this.dialogForm.open(AuthPolicyFormComponent, {
                        data: { model, jwtSigners, selectedJwtSigners },
                        minHeight: '100%',
                        minWidth: '100%',
                        height: '100%',
                        width: '100%',
                    });

                    this.dialogRef.afterOpened().subscribe(() => {
                        this.dialogRef?.componentInstance.requestUpdate.subscribe((policy: AuthPolicy) => {
                            this.svc.save(policy).then((errors) => {
                                if (errors.length === 0) {
                                    this.dialogRef?.close();
                                    this.showEditSuccess();
                                } else {
                                    this.dialogRef?.componentInstance.errorUpdate.next(errors);
                                }
                            });
                        });
                    });
                    this.dialogRef?.componentInstance.requestValidate.subscribe((policy: AuthPolicy) => {
                        this.svc.validate(policy).then((errors: any[]) => {
                            this.dialogRef?.componentInstance.errorUpdate.next(errors);
                        });
                    });
                } else {
                    this.showNetworkNotReadyError();
                }
            }
        }
    }

    hideInfoBubble() {
        this.featureService.setShowInfoBubble('jwt-policys', false);
        this.showInfoBubble = false;
    }

    openConfirm(action: string, item?: AuthPolicy) {
        if (action === 'delete') {
            const deletingInfo = this.getDeletingPrompt(item);

            const data = {
                title: 'Delete',
                appendId: 'Router',
                subtitle: deletingInfo.deleteString,
                bulletList: deletingInfo.names,
                icon: 'DescructiveDelete',
                isDestructive: this.svc.enableDeleteProtection,
                action: 'Yes',
                itemName: deletingInfo.names[0].name,
            };
            this.confirmRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            this.confirmRef.afterClosed().subscribe((result) => {
                if (result === undefined || result['loggingOut'] === undefined) {
                    this.svc.delete(result).catch((err) => {
                        this.showDeleteFailure(err);
                    });
                }
            });
        }
    }

    private showDeleteFailure(msg: string) {
        this.growlerService.show(
            new GrowlerData('error', 'Error', 'One or More Auth Policies  failed to delete.', msg)
        );
    }

    private showEditSuccess() {
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Authenticaton policy Save Complete',
                'The information has been saved'
            )
        );
    }

    getDeletingPrompt(item?: AuthPolicy) {
        const names = [];
        if (item) names.push(item);
        else {
            for (const item of this.displayedItems) {
                if ((item as any).selected) {
                    names.push(item);
                }
            }
        }

        const deleteString = 'Are you sure you would like to delete ';

        return {
            deleteString: deleteString,
            names: names,
        };
    }

    private showNetworkNotReadyError() {
        this.growlerService.show(
            new GrowlerData('error', 'Error', 'Network not ready', 'The network is not yet PROVISIONED')
        );
    }

    initTableColumns() {
        const headerComponentParams = {
            filterType: 'TEXTINPUT',
            columnFilters: this.svc.columnFilters,
        };

        this.columnDefs = [
            {
                colId: 'name',
                width: 120,
                minWidth: 100,
                field: 'name',
                tooltipField: 'name',
                sortColumn: this.svc.sort.bind(this.svc),
                sortable: true,
                headerName: 'Name',
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                onCellClicked: (params: any) => {
                    this.edit(true, params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'certBased',
                width: 100,
                minWidth: 100,
                sortable: true,
                sortColumn: this.svc.sort.bind(this.svc),
                field: 'primary.cert.allowed',
                tooltipField: 'certBased',
                headerName: 'Certificate Based',
                headerComponent: TableHeaderDefaultComponent,
                cellRenderer: (params: any) =>
                    params.data.primary.cert.allowed ? '<div class="trueChecked"></span>' : '',
                cellStyle: { textAlign: 'center', verticalAlign: 'middle' },
                onCellClicked: (params: any) => {
                    this.edit(true, params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'jwtBased',
                width: 100,
                minWidth: 100,
                sortable: true,
                sortColumn: this.svc.sort.bind(this.svc),
                field: 'primary.extJwt.allowedSigners',
                tooltipField: 'jwtBased',
                headerName: 'JWT Based',
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                cellRenderer: (params: any) => {
                    const allowed: string[] = [];
                    if (params.data.primary?.extJwt?.allowedSigners?.length > 0) {
                        params.data.primary.extJwt.allowedSigners.forEach((id: string) => {
                            allowed.push(this.svc.jwtSignerMap[id]);
                        });
                    }
                    return allowed.join(',');
                },
                onCellClicked: (params: any) => {
                    this.edit(true, params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'jwtReq',
                width: 100,
                minWidth: 100,
                sortable: true,
                sortColumn: this.svc.sort.bind(this.svc),
                field: 'secondary.requireExtJwtSigner',
                tooltipField: 'jwtReq',
                headerName: 'Require JWT',
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                cellRenderer: (params: any) => {
                    if (params.data.secondary?.requireExtJwtSigner) {
                        return this.svc.jwtSignerMap[params.data.secondary.requireExtJwtSigner];
                    }
                    return '';
                },
                onCellClicked: (params: any) => {
                    this.edit(true, params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'totpReq',
                width: 100,
                minWidth: 100,
                sortable: true,
                sortColumn: this.svc.sort.bind(this.svc),
                field: 'secondary.requireTotp',
                tooltipField: 'totpReq',
                headerName: 'Require TOTP',
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                cellRenderer: (params: any) =>
                    params.data.secondary?.requireTotp ? '<div class="trueChecked"></span>' : '',
                cellStyle: { textAlign: 'center', verticalAlign: 'middle' },

                onCellClicked: (params: any) => {
                    this.edit(true, params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
        ];
    }
}
