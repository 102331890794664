import { Injectable } from '@angular/core';
import { AppWan, Network } from '@netfoundry-ui/shared/model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class AppwanService extends MopResourceService {
    page = null;
    links = null;
    private appWansSource = new Subject<AppWan[]>();
    appWans = this.appWansSource.asObservable();
    private apiSub = new Subscription();
    private mapSub = new Subscription();

    constructor(protected logger: LoggerService, private apiservice: ApiService) {
        super(logger, apiservice);
    }

    /**
     * Gets all Clients for org
     */
    get(pageNumber?: number, sort?: string, filter?: string, pageSize?: number): Observable<AppWan[]> {
        if (this.apiSub) {
            this.apiSub.unsubscribe();
        }

        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }
        // watch for when the network updates, when it does, update the gateways observable
        this.apiSub = this.apiService.currentNetwork.subscribe((network) => {
            const params = {};

            if (pageNumber != null) {
                params['page'] = pageNumber;
            }

            if (sort != null) {
                params['sort'] = sort;
            }

            if (filter != null) {
                // TODO filter
            }

            if (pageSize != null) {
                params['size'] = pageSize;
            }

            this.mapSub = this.apiService
                .getLinkedResource(new Network(network), 'appWans', params)
                .pipe(map(this._extractEmbedded))
                .subscribe((data) => {
                    this.page = data['page'];
                    this.links = data['links'];
                    this.appWansSource.next(data['appwans']);
                });
        });

        // this is an observable that watches for network changes
        return this.appWans;
    }

    addEndpoints(model, ids) {
        return this.addRelationships('endpoints', model, ids);
    }

    removeEndpoints(model, ids) {
        return this.removeRelationships('endpoints', model, ids);
    }

    addGroups(model, ids) {
        return this.addRelationships('endpointGroups', model, ids);
    }

    removeGroups(model, ids) {
        return this.removeRelationships('endpointGroups', model, ids);
    }

    addServices(model, ids) {
        return this.addRelationships('services', model, ids);
    }

    removeServices(model, ids) {
        return this.removeRelationships('services', model, ids);
    }

    resync(id) {
        return this.apiService.post(this._getCreateUrl() + '/' + id + '/resync');
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res): { appwans: AppWan[]; page: string; links: string } {
        const appwans: AppWan[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['appWans'] !== undefined) {
            for (let i = 0; i < res['_embedded']['appWans'].length; i++) {
                const appwan = res['_embedded']['appWans'][i];
                appwans.push(new AppWan(appwan));
            }
        }

        // object containing information on pagination such as page number, size, total number of items,  and total number of pages
        let page = null;
        if (res['page'] !== undefined) {
            page = res['page'];
        }

        // list of links for jumping to and from pages
        let links = null;
        if (res['_links'] !== undefined) {
            links = res['_links'];
        }
        return { appwans: appwans, page: page, links: links };
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return this.getLinkedResourceUrl(this.currentNetworkModel, 'appWans');
    }
}
