import { Injectable } from '@angular/core';
import { Exporters } from '@netfoundry-ui/shared/model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NetflowService } from './netflow.service';

@Injectable({
    providedIn: 'root',
})
export class ExportersService {
    page = null;
    links = null;
    private exportersSource = new Subject<Exporters[]>();
    exporters = this.exportersSource.asObservable();
    private mapSub = new Subscription();

    constructor(private netflowService: NetflowService) {}

    /**
     * Gets all Clients for org
     */
    find(params?: any): Observable<any> {
        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }

        this.mapSub = this.netflowService
            .get('exporters', params)
            .pipe(map(this._extractEmbedded))
            .subscribe((data) => {
                this.page = data['page'];
                this.links = data['links'];
                this.exportersSource.next(data['exporters']);
            });

        // this is an observable that watches for network changes
        return this.exporters;
    }

    /**
     * Gets all Clients for org
     */
    findByType(type?: string, params?: any): Observable<any> {
        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }

        this.mapSub = this.netflowService
            .get(`exporters/${type}`, params)
            .pipe(map(this._extractEmbedded))
            .subscribe((data) => {
                this.page = data['page'];
                this.links = data['links'];
                this.exportersSource.next(data['exporters']);
            });

        // this is an observable that watches for network changes
        return this.exporters;
    }

    findByNativeId(type: string, nativeId: string, params?: any): Observable<any> {
        return this.netflowService.get(`exporters/${type}/native-id/${nativeId}`, params);
    }

    get(type, key, params?: any): Observable<any> {
        return this.netflowService.get(`exporters/${type}/key/${key}`, params);
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const exporters: Exporters[] = [];

        if (res['content'] !== undefined) {
            for (let i = 0; i < res['content'].length; i++) {
                const exporter = res['content'][i];
                exporters.push(new Exporters(exporter));
            }
        }

        // object containing information on pagination such as page number, size, total number of items,  and total number of pages
        let page = null;
        if (res['page'] !== undefined) {
            page = res['page'];
        }

        // list of links for jumping to and from pages
        let links = null;
        if (res['_links'] !== undefined) {
            links = res['_links'];
        }

        return { exporters: exporters, page: page, links: links };
    }
}
