<div class="fullModal opened" id="AppWANModal">
    <div [hidden]="step !== 1" class="fullRow" id="step1">
        <div [hidden]="!isAdding" class="title" style="filter: brightness(50%)">Create A New AppWAN</div>
        <div [hidden]="!isEditing" class="title" style="filter: brightness(50%)">Modify A AppWAN</div>
        <div class="subtitle smaller">
            Building an application-defined network is easy.<br />Answer the simple questions below to build your
            AppWAN.
        </div>

        <div class="sized">
            <!-- Step 1: Name Application -->
            <div class="wizard">
                <div class="icon step1"></div>
                <div>
                    <div class="step">1</div>
                    <div class="title">What is your applications name?</div>
                    <label [ngClass]="{ error: step1Error !== '' }" for="AppWANName"
                        >Application Name<span [ngClass]="{ error: step1Error !== '' }" class="note">Required</span>
                    </label>
                    <input
                        [(ngModel)]="model.name"
                        [ngClass]="{ error: step1Error !== '' }"
                        id="AppWanName"
                        maxlength="100"
                        placeholder="{{ namePlaceholder }}"
                        type="text"
                    />
                </div>
            </div>

            <!-- Step 2: Add Gateways -->
            <div class="wizard">
                <div class="icon step2"></div>
                <div>
                    <div class="step">2</div>
                    <div class="title">Where is your app located / hosted?</div>
                    <label [ngClass]="{ error: step2Error !== '' }" for="AppWanGatewayList"
                        >Select From Existing Places / Gateways<span
                            [ngClass]="{ error: step2Error !== '' }"
                            class="note"
                            >Required</span
                        ></label
                    >
                    <select
                        (ngModelChange)="hideForm($event)"
                        [(ngModel)]="gatewayPreSelected"
                        [ngClass]="{ error: step2Error !== '' }"
                        id="AppWanGatewayList"
                    >
                        <option [ngValue]="null">Select existing gateway</option>
                        <option *ngFor="let gateway of gateways" [ngValue]="gateway" id="{{ gateway.id }}">
                            {{ gateway.name }} {{ clusterCount > 0 ? gateway.typeTag : '' }}
                        </option>
                    </select>

                    <div [hidden]="!canCreateEndpoints" class="row">
                        <span class="ortext">OR </span>
                        <span (click)="AddGatewayPreselectForm()" class="linkButton" id="AddServiceGateway"
                            >Add a New One</span
                        >
                    </div>
                    <div [hidden]="!canCreateEndpoints || !showAddGatewayPreselect" class="row">
                        <div (click)="AddGatewayToPreselect()" class="gridAdd" id="CreateServiceGateway"></div>
                        <div class="grid split gateway">
                            <select
                                (change)="changeGatewayType($event)"
                                [(ngModel)]="newPreSelectGateway.endpointType"
                                [ngClass]="{ error: gwPreTypeError }"
                                id="AddGatewayTypeList"
                            >
                                <option value="">Gateway Type</option>
                                <option value="AWSCPEGW">AWS</option>
                                <option value="AZCPEGW">Azure Cloud</option>
                                <option value="AZSGW">Azure Stack</option>
                                <option value="GCPCPEGW">Google Cloud</option>
                                <option value="VCPEGW">Data Center</option>
                            </select>
                            <input
                                [(ngModel)]="newPreSelectGateway.name"
                                [ngClass]="{ error: gwPreNameError || gwPreNameLengthError }"
                                id="AddGatewayName"
                                max="100"
                                placeholder="Enter a name"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="row" style="height: 5px">&nbsp;</div>
                    <div [hidden]="!canCreateEndpoints || !showAddGatewayPreselect" class="row">
                        <select
                            *ngIf="newPreSelectGateway.endpointType !== 'VCPEGW'"
                            [(ngModel)]="newPreSelectGateway.geoRegionId"
                            [ngClass]="{ error: gwPreRegionError }"
                            id="AddGatewayRegionList"
                        >
                            <option value="">Region</option>
                            <option *ngFor="let region of regions | sortby: 'name'" [value]="region.id">
                                {{ region.name }}
                            </option>
                        </select>
                        <app-country-list
                            *ngIf="newPreSelectGateway.endpointType === 'VCPEGW'"
                            [errorCountry]="gwPreRegionError || gwPreCountryError"
                            [isWizard]="true"
                            [model]="newPreSelectGateway"
                        ></app-country-list>
                    </div>
                    <div class="row" style="height: 1px">&nbsp;</div>
                </div>
            </div>

            <!-- Step 3: Add Services -->
            <div class="wizard">
                <div class="icon step3"></div>
                <div>
                    <div class="step">3</div>
                    <div class="title">How do you want it to connect?</div>
                    <div class="subtitle">Expose Single IPs</div>
                    <div *ngFor="let service of ipHostServices; let index = index" class="row">
                        <div *ngIf="service.serviceClass === 'CS'">
                            <div
                                (click)="RemoveHostService(index)"
                                *ngIf="index > 0"
                                [ngClass]="{ minus: service.id !== '' }"
                                class="gridAdd"
                                id="AppWANRemoveService_{{ index }}"
                            ></div>
                            <div class="grid three" style="padding-bottom: 5px">
                                <label *ngIf="index === 0" [ngClass]="{ error: step3HostError.indexOf('IP') >= 0 }"
                                    >IP Address</label
                                >
                                <label *ngIf="index === 0" [ngClass]="{ error: step3HostError.indexOf('Port') >= 0 }"
                                    >Port/Range</label
                                >
                                <label
                                    *ngIf="index === 0"
                                    [ngClass]="{ error: step3HostError.indexOf('Protocol') >= 0 }"
                                    >Protocol</label
                                >

                                <input
                                    [(ngModel)]="ipHostServices[index].networkIp"
                                    [ngClass]="{
                                        error: step3HostErrors[index] && step3HostErrors[index].indexOf('IP') >= 0
                                    }"
                                    id="NewServiceIp_{{ index }}"
                                    max="15"
                                    placeholder="10.0.1.20"
                                    type="text"
                                />
                                <input
                                    [(ngModel)]="ipHostServices[index].networkFirstPort"
                                    [ngClass]="{
                                        error: step3HostErrors[index] && step3HostErrors[index].indexOf('Port') >= 0
                                    }"
                                    id="NewServicePort_{{ index }}"
                                    max="32"
                                    placeholder="22"
                                />

                                <select
                                    [(ngModel)]="ipHostServices[index].serviceType"
                                    [ngClass]="{
                                        error: step3HostErrors[index] && step3HostErrors[index].indexOf('Protocol') >= 0
                                    }"
                                    id="NewServiceProto_{{ index }}"
                                    placeholder="Protocol"
                                >
                                    <option value="">Select Protocol</option>
                                    <option value="TCP">TCP</option>
                                    <option value="UDP">UDP</option>
                                    <option value="ALL">TCP/UDP</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <span class="ortext">OR </span>
                        <span (click)="AddHostServiceFields()" class="linkButton" id="AddService"
                            >Add Additional +</span
                        >
                    </div>

                    <div *ngIf="!zitiEnabledService.zitiPure" class="title light andor">and/or</div>
                    <div *ngIf="!zitiEnabledService.zitiPure" class="subtitle">Expose Muliple IPs</div>
                    <div *ngIf="!zitiEnabledService.zitiPure">
                        <div *ngFor="let service of ipNetworkServices; let index = index" class="row">
                            <div *ngIf="service.serviceClass === 'GW'">
                                <div
                                    (click)="RemoveNetworkService(index)"
                                    *ngIf="index > 0"
                                    [ngClass]="{ minus: service.id !== '' }"
                                    class="gridAdd"
                                    id="AppWANRemoveNetworkService_{{ index }}"
                                ></div>
                                <div class="grid split" style="padding-bottom: 5px">
                                    <label
                                        *ngIf="index === 0"
                                        [ngClass]="{
                                            error: step3NetworkError.indexOf('GatewayIp') >= 0
                                        }"
                                        >Network Address</label
                                    >
                                    <label
                                        *ngIf="index === 0"
                                        [ngClass]="{
                                            error: step3NetworkError.indexOf('Intercept') >= 0
                                        }"
                                        >Intercept Address</label
                                    >
                                    <input
                                        [(ngModel)]="ipNetworkServices[index].gatewayIp"
                                        [ngClass]="{
                                            error:
                                                step3NetworkErrors[index] &&
                                                step3NetworkErrors[index].indexOf('GatewayIp') >= 0
                                        }"
                                        id="NewNetworkServiceIp_{{ index }}"
                                        max="18"
                                        placeholder="10.0.0.0/24"
                                        type="text"
                                    />
                                    <input
                                        [(ngModel)]="ipNetworkServices[index].interceptIp"
                                        [ngClass]="{
                                            error:
                                                step3NetworkErrors[index] &&
                                                step3NetworkErrors[index].indexOf('Intercept') >= 0
                                        }"
                                        id="NewNetworkServiceIntercept_{{ index }}"
                                        max="15"
                                        placeholder="{{
                                            ipNetworkServices[index].gatewayIp == null ||
                                            ipNetworkServices[index].gatewayIp === ''
                                                ? '10.0.0.0/24'
                                                : ipNetworkServices[index].gatewayIp
                                        }}"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!zitiEnabledService.zitiPure" class="row">
                        <span class="ortext">OR </span>
                        <span (click)="AddNetworkServiceFields()" class="linkButton" id="AddService"
                            >Add Additional +</span
                        >
                    </div>
                </div>
            </div>

            <!-- Step 4: -->
            <div class="wizard">
                <div class="icon step4"></div>
                <div>
                    <div class="step">4</div>
                    <div class="title">Who do you want to connect to it?</div>

                    <label [hidden]="!canCreateEndpoints && !canListEndpoints" class="thin" for="PeopleSelector"
                        >People<span class="note">Select from existing clients</span></label
                    >
                    <app-nf-select2
                        *ngIf="canCreateEndpoints || canListEndpoints"
                        [options]="clients"
                        [placeholder]="'Search for existing clients'"
                        [selected]="clientsSelected"
                        id="PeopleSelector"
                    ></app-nf-select2>
                    <div [hidden]="!canCreateEndpoints || showAddPlaceClient" class="row">
                        <span class="ortext">OR </span
                        ><span (click)="ToggleAddClientForm()" class="linkButton" id="AddClient">Add a New One</span>
                    </div>
                    <div class="row"></div>
                    <div [hidden]="!canCreateEndpoints || !showAddPlaceClient" class="row">
                        <span (click)="ToggleAddClientForm()" class="linkButton" id="CancelAddClient"
                            >Cancel Adding</span
                        >
                    </div>
                    <div [hidden]="!canCreateEndpoints || !showAddPlaceClient" class="row">
                        <div (click)="AddClient()" class="gridAdd" id="CreateClient"></div>
                        <div
                            [ngClass]="{
                                grid: zitiEnabledService.zitiEnabled,
                                two: zitiEnabledService.zitiEnabled
                            }"
                            class="client"
                        >
                            <input
                                [(ngModel)]="newClient.name"
                                [ngClass]="{ error: clNameError || clNameLengthError }"
                                id="AddPlaceClientName"
                                max="100"
                                placeholder="Enter a name"
                                type="text"
                            />
                            <select
                                *ngIf="zitiEnabledService.zitiEnabled"
                                [(ngModel)]="newClient.endpointType"
                                [ngClass]="{ error: clTypeError }"
                                id="AddClientType"
                            >
                                <option value="">Type</option>
                                <option value="CL">Standard</option>
                                <option value="ZTCL">Ziti Enabled</option>
                            </select>
                        </div>
                    </div>
                    <div class="row" style="height: 5px">&nbsp;</div>
                    <div [hidden]="!canCreateEndpoints || !showAddPlaceClient" class="row">
                        <app-country-list
                            [errorCountry]="clRegionError || clCountryError"
                            [isWizard]="true"
                            [model]="newClient"
                        ></app-country-list>
                    </div>

                    <div class="row">&nbsp;</div>
                    <label [hidden]="!canCreateEndpoints && !canListEndpoints" class="thin" for="PlacesSelector"
                        >Places<span class="note">Select from existing gateways</span></label
                    >
                    <app-nf-select2
                        *ngIf="canCreateEndpoints || canListEndpoints"
                        [options]="gatewaysNoHa"
                        [placeholder]="'Search for existing gateways'"
                        [selected]="gatewaysSelected"
                        id="PlacesSelector"
                    ></app-nf-select2>

                    <div [hidden]="!canCreateEndpoints || showAddPlaceGateway" class="row">
                        <span class="ortext">OR </span>
                        <span (click)="ToggleAddPlaceGatewayForm()" class="linkButton" id="AddGateway"
                            >Add a New One</span
                        >
                    </div>
                    <div [hidden]="!canCreateEndpoints || !showAddPlaceGateway" class="row">
                        <span (click)="ToggleAddPlaceGatewayForm()" class="linkButton" id="CancelAddGateway"
                            >Cancel Adding</span
                        >
                    </div>
                    <div [hidden]="!canCreateEndpoints || !showAddPlaceGateway" class="row">
                        <div (click)="AddGateway()" class="gridAdd" id="CreateGateway"></div>
                        <div class="grid split gateway">
                            <select
                                (change)="changeNewGatewayType($event)"
                                [(ngModel)]="newGateway.endpointType"
                                [ngClass]="{ error: gwTypeError }"
                                id="AddGatewayPlaceTypeList"
                            >
                                <option value="">Gateway Type</option>
                                <option value="AWSCPEGW">AWS</option>
                                <option value="AZCPEGW">Azure Cloud</option>
                                <option value="AZSGW">Azure Stack</option>
                                <option value="GCPCPEGW">Google Cloud</option>
                                <option value="VCPEGW">Data Center</option>
                                <option *ngIf="zitiEnabledService.zitiEnabled" value="ZTGW">Ziti Hosted</option>
                                <option *ngIf="zitiEnabledService.zitiEnabled" value="ZTNHGW">Ziti Private</option>
                            </select>
                            <input
                                [(ngModel)]="newGateway.name"
                                [ngClass]="{ error: gwNameError || gwNameLengthError }"
                                id="AddPlaceGatewayName"
                                max="100"
                                placeholder="Enter a name"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="row" style="height: 5px">&nbsp;</div>
                    <div [hidden]="!canCreateEndpoints || !showAddPlaceGateway" class="row">
                        <select
                            *ngIf="newGateway.endpointType !== 'VCPEGW'"
                            [(ngModel)]="newGateway.geoRegionId"
                            [ngClass]="{ error: gwRegionError }"
                            id="AddPlaceGatewayRegionList"
                        >
                            <option value="">Region</option>
                            <option *ngFor="let region of newGatewayRegions | sortby: 'name'" [value]="region.id">
                                {{ region.name }}
                            </option>
                        </select>
                        <app-country-list
                            *ngIf="newGateway.endpointType === 'VCPEGW'"
                            [errorCountry]="gwRegionError || countryError"
                            [isWizard]="true"
                            [model]="newGateway"
                        ></app-country-list>
                    </div>
                    <div class="row">&nbsp;</div>

                    <div *ngIf="canListGroups && groups.length > 0" class="row">
                        <label class="thin" for="GroupSelector"
                            >Groups<span class="note">Select from existing endpoint groups</span></label
                        >
                        <app-nf-select2
                            [options]="groups"
                            [placeholder]="'Search for existing groups'"
                            [selected]="groupsSelected"
                            id="GroupSelector"
                        ></app-nf-select2>
                    </div>
                </div>

                <span [hidden]="step4Error === ''" class="error">{{ step4Error }}</span>
            </div>

            <div class="buttonRow right">
                <button (click)="initSave()" class="save" id="SaveButton">
                    <span *ngIf="isEditing">Save You AppWAN</span>
                    <span *ngIf="isAdding">Create Your AppWAN</span>
                </button>
            </div>
        </div>
    </div>

    <div [hidden]="step !== 2" class="fullRow" id="step2">
        <div class="title" id="AppwanBuilding" style="filter: brightness(50%)">Your AppWan Is Building</div>
        <div class="subtitle smaller">Your AppWAN is being created<br />Details are below.</div>
        <div class="row center" id="appwanWizardBuild">
            <div [ngClass]="stepStyle" class="building appwan"></div>
            <div [ngClass]="{ open: stepStyle === 'step0' }" class="buildSteps" id="CreateService">
                Creating Services
            </div>
            <div [ngClass]="{ open: stepStyle === 'step1' }" class="buildSteps" id="CreateAppwan">Creating AppWAN</div>
            <div [ngClass]="{ open: stepStyle === 'step2' }" class="buildSteps" id="AssignGroups">Assigning Groups</div>
            <div [ngClass]="{ open: stepStyle === 'step3' }" class="buildSteps" id="VerifyGateway">
                Verifying Gateways
            </div>
            <div [ngClass]="{ open: stepStyle === 'step4' }" class="buildSteps" id="AssignGateways">
                Assigning Gateways
            </div>
            <div [ngClass]="{ open: stepStyle === 'step5' }" class="buildSteps" id="VerifyClient">
                Verifying Clients
            </div>
            <div [ngClass]="{ open: stepStyle === 'step6' }" class="buildSteps" id="AssignClients">
                Assigning Clients
            </div>
            <div [ngClass]="{ open: stepStyle === 'step7' }" class="buildSteps" id="VerifyServices">
                Verifying Services
            </div>
            <div [ngClass]="{ open: stepStyle === 'step8' }" class="buildSteps" id="AssignServices">
                Assigning Services
            </div>
        </div>
        <div class="content" id="appwanWizardCreated">
            <div style="margin: 30px auto; clear: both; width: 200px">
                <div *ngIf="servicesCreated" class="statusRow">
                    <span class="bubble severityInfo"></span>
                    <div class="statusText" id="ServiceCreated">Services Created</div>
                </div>
                <div *ngIf="appwanCreated" class="statusRow">
                    <span class="bubble severityInfo"></span>
                    <div class="statusText" id="appwanCreated">AppWAN Created</div>
                </div>
                <div *ngIf="groupsAreAssigned" class="statusRow">
                    <span class="bubble severityInfo"></span>
                    <div class="statusText" id="GroupsAssigned">Groups Assigned</div>
                </div>
                <div *ngIf="gatewaysAreReady" class="statusRow">
                    <span class="bubble severityInfo"></span>
                    <div class="statusText" id="gatewayVerified">Gateways Verified</div>
                </div>
                <div *ngIf="gatewaysAreAssigned" class="statusRow">
                    <span class="bubble severityInfo"></span>
                    <div class="statusText" id="gatewaysAssigned">Gateways Assigned</div>
                </div>
                <div *ngIf="clientsAreReady" class="statusRow">
                    <span class="bubble severityInfo"></span>
                    <div class="statusText" id="ClientsVerified">Clients Verified</div>
                </div>
                <div *ngIf="clientsAreAssigned" class="statusRow">
                    <span class="bubble severityInfo"></span>
                    <div class="statusText" id="clientsAssigned">Clients Assigned</div>
                </div>
                <div *ngIf="servicesAreReady" class="statusRow">
                    <span class="bubble severityInfo"></span>
                    <div class="statusText" id="ServicVerified">Services Verified</div>
                </div>
                <div *ngIf="servicesAreAssigned" class="statusRow">
                    <span class="bubble severityInfo"></span>
                    <div class="statusText" id="ServiceAssigned">Services Assigned</div>
                </div>
                <div *ngFor="let error of errors" class="statusRow">
                    <span class="bubble severityInfo error"></span>
                    <div class="statusText error" id="statusError">{{ error }}</div>
                </div>
            </div>
        </div>
    </div>

    <div [hidden]="step !== 3" class="fullRow" id="step3">
        <div [hidden]="!isAdding" class="title" id="AppwanSuccess" style="filter: brightness(50%)">Congratulations</div>
        <div [hidden]="!isEditing" class="title">Modify A AppWAN</div>
        <div class="subtitle smaller">Your AppWAN has been created. A network summary is below.</div>
        <div class="fullModalcontent">
            What's next? Finish connecting your network by registering new clients and gateways.
        </div>

        <div class="sized mid">
            <div class="grid two marginBottom">
                <div class="hint">
                    <div class="title">New Clients</div>
                    <div class="subtitle">Share Client Registration Info</div>
                    <div class="image icon-Icon_Share"></div>
                </div>
                <div class="hint">
                    <div class="title">New Gateways</div>
                    <div class="subtitle">Tap to Launch and Register</div>
                    <div class="image icon-ClickIcon"></div>
                </div>
            </div>
            <div class="wizard">
                <div class="icon step5 upside"></div>

                <div class="summary">
                    <div class="step">1</div>
                    <div>
                        <div class="title">Application Name</div>
                        <div class="subtitle">{{ finalModel.name }}</div>
                    </div>
                </div>

                <div class="summary">
                    <div class="step">2</div>
                    <div>
                        <div class="title">Location</div>
                        <label class="underline"
                            >Gateway Name<span [hidden]="!showLauncher(finalModel['hostingGateway'])" class="note"
                                >Tap To Launch New Gateway</span
                            ></label
                        >
                        <div class="content">
                            <div
                                (click)="
                                    !showLauncher(finalModel['hostingGateway']) &&
                                        openGateway(finalModel['hostingGateway'])
                                "
                                [ngClass]="{
                                    noLauncherScript: !showLauncher(finalModel['hostingGateway'])
                                }"
                                class="launch"
                            >
                                {{ finalModel['hostingGatewayName'] }}
                                <div
                                    (click)="LaunchAzureCloudScript(finalModel['hostingGateway'])"
                                    *ngIf="
                                        showLauncher(finalModel['hostingGateway']) &&
                                        IsAzure(finalModel['hostingGateway']) &&
                                        !IsHA(finalModel['hostingGateway']) &&
                                        !isGatewayRegistered(finalModel['hostingGateway'])
                                    "
                                    class="tap azure"
                                >
                                    Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                                </div>
                                <div
                                    (click)="LaunchCloudScript(finalModel['hostingGateway'])"
                                    *ngIf="
                                        showLauncher(finalModel['hostingGateway']) &&
                                        IsAWS(finalModel['hostingGateway']) &&
                                        !IsHA(finalModel['hostingGateway']) &&
                                        !isGatewayRegistered(finalModel['hostingGateway']) &&
                                        authorizationService.canGetDataCenter(
                                            finalModel['hostingGateway']['dataCenterId']
                                        )
                                    "
                                    class="tap aws"
                                >
                                    Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                                </div>
                                <div
                                    *ngIf="
                                        IsGoogle(finalModel['hostingGateway']) &&
                                        !IsHA(finalModel['hostingGateway']) &&
                                        !isGatewayRegistered(finalModel['hostingGateway']) &&
                                        authorizationService.canGetDataCenter(
                                            finalModel['hostingGateway']['dataCenterId']
                                        )
                                    "
                                    class="google"
                                >
                                    <span class="icon-ClickIcon"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="summary">
                    <div class="step">3</div>
                    <div>
                        <div *ngIf="ipHostServices.length > 0 && !isEmptyHostService(ipHostServices[0])">
                            <div class="title">Host Services - Single IPs</div>
                            <div class="tableGrid four header">
                                <div>IP Address</div>
                                <div>Port / Range</div>
                                <div>Label</div>
                                <div>Gateway</div>
                            </div>
                            <!-- Need to check type and only show if Host -->
                            <div
                                *ngFor="let service of finalModel['services']; let index = index"
                                class="tableGrid four"
                            >
                                <!-- needed to add the ngIf on each div instead of using a parent div as the parent div broke the styling -->
                                <div *ngIf="service.serviceClass === 'CS'" class="staticCell">
                                    {{ service.networkIp }}
                                </div>
                                <div *ngIf="service.serviceClass === 'CS'" class="staticCell">
                                    {{
                                        service.networkFirstPort === service.networkLastPort
                                            ? service.networkFirstPort
                                            : service.networkFirstPort + '-' + service.networkLastPort
                                    }}
                                </div>
                                <div
                                    (click)="openService(service)"
                                    *ngIf="service.serviceClass === 'CS'"
                                    class="clickable link"
                                    class="staticCell"
                                >
                                    {{ service.name }}
                                </div>
                                <div *ngIf="service.serviceClass === 'CS'" class="staticCell">
                                    {{ lookupGatewayName(service) }}
                                </div>
                            </div>
                        </div>

                        <div *ngIf="ipNetworkServices.length > 0 && !isEmptyNetworkService(ipNetworkServices[0])">
                            <div class="title">Network Services - Multiple IPs</div>
                            <div class="tableGrid four header">
                                <div>Network Address</div>
                                <div>Intercept Address</div>
                                <div>Label</div>
                                <div>Gateway</div>
                            </div>
                            <!-- Need to check type and only show if Network -->
                            <div
                                *ngFor="let service of finalModel['services']; let index = index"
                                class="tableGrid four"
                            >
                                <!-- needed to add the ngIf on each div instead of using a parent div as the parent div broke the styling -->
                                <div *ngIf="service.serviceClass === 'GW'" class="staticCell">
                                    {{ service.gatewayIp + '/' + service.gatewayCidrBlock }}
                                </div>
                                <div *ngIf="service.serviceClass === 'GW'" class="staticCell">
                                    {{ service.interceptIp }}
                                </div>
                                <div
                                    (click)="openService(service)"
                                    *ngIf="service.serviceClass === 'GW'"
                                    class="clickable link"
                                    class="staticCell"
                                >
                                    {{ service.name }}
                                </div>
                                <div *ngIf="service.serviceClass === 'GW'" class="staticCell">
                                    {{ lookupGatewayName(service) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="summary">
                    <div class="step">4</div>
                    <div>
                        <div class="title">People, Places, Groups</div>

                        <label class="underline"
                            >Clients<span [hidden]="!hasNewClients" class="note"
                                >Enter an Email Address to Share Registration</span
                            ></label
                        >
                        <div *ngFor="let client of finalModel['clients']; let index = index" class="grid clientGrid">
                            <div [hidden]="client['registrationKey'] != null">
                                {{ client.name }}
                            </div>
                            <div
                                (click)="openClient(client)"
                                [hidden]="client['registrationKey'] == null"
                                class="gridLabel clickable"
                            >
                                {{ client.name }}
                            </div>
                            <div [hidden]="client['registrationKey'] == null">
                                <input
                                    [(ngModel)]="sendto[index]"
                                    [ngClass]="{ error: errorEmails }"
                                    id="RegSend{{ index }}"
                                    type="text"
                                />
                            </div>
                            <div
                                (click)="SendRegistrationInline(client, index)"
                                *ngIf="sendingState[index] === ''"
                                [hidden]="client['registrationKey'] == null"
                                class="sendButton icon-Icon_Share"
                                id="SendButton_{{ index }}"
                            ></div>
                            <div *ngIf="sendingState[index] === 'sending'" class="sendButton sending">
                                <div class="spinner"></div>
                            </div>
                            <div *ngIf="sendingState[index] === 'sent'" class="sendButton sent icon-Icon_Check"></div>
                        </div>

                        <label class="underline">Gateways</label>
                        <div *ngFor="let gateway of finalModel['gateways']; let index = index" class="grid">
                            <div [hidden]="gateway['registrationKey'] != null">
                                {{ gateway.name }}
                            </div>
                            <div
                                (click)="!showLauncher(gateway) && openGateway(gateway)"
                                [hidden]="gateway['registrationKey'] == null"
                                [ngClass]="{ noLauncherScript: !showLauncher(gateway) }"
                                class="launch"
                            >
                                {{ gateway.name }}
                                <div
                                    (click)="
                                        authorizationService.canGetDataCenter(gateway['dataCenterId']) &&
                                            LaunchCloudScript(gateway)
                                    "
                                    *ngIf="
                                        showLauncher(gateway) &&
                                        IsAWS(gateway) &&
                                        !IsHA(gateway) &&
                                        !isGatewayRegistered(gateway)
                                    "
                                    class="tap aws"
                                >
                                    Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                                </div>
                                <div
                                    (click)="LaunchAzureCloudScript(gateway)"
                                    *ngIf="
                                        showLauncher(gateway) &&
                                        IsAzure(gateway) &&
                                        !IsHA(gateway) &&
                                        !isGatewayRegistered(gateway)
                                    "
                                    class="tap azure"
                                >
                                    Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                                </div>
                                <div
                                    *ngIf="IsGoogle(gateway) && !IsHA(gateway) && !isGatewayRegistered(gateway)"
                                    class="tap google"
                                >
                                    <span class="icon-ClickIcon"></span>
                                </div>
                            </div>
                        </div>

                        <label class="underline">Groups</label>
                        <div *ngFor="let group of finalModel['endpointGroups']; let index = index" class="grid">
                            <div>{{ group.name }}</div>
                        </div>
                    </div>
                </div>

                <div class="row">&nbsp;</div>
            </div>

            <div>
                <div (click)="clone()" class="cloneButton">
                    <div class="icon"></div>
                    <div class="content">Want to add another environment<br />with the same services or endpoints?</div>
                    <div class="buttonText">Tap To Clone</div>
                    <div class="tapIcon icon-ClickIcon"></div>
                </div>
            </div>
        </div>
    </div>

    <div (click)="hide()" class="buttonBall close" id="CloseAppWanWizardButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button></app-form-support-button>
</div>
