<div *ngIf="step === showStep && finalModel != null">
    <div class="sized mid">
        <div class="wizard padded">
            <div class="icon step5 upside"></div>

            <div class="summary">
                <div class="step">1</div>
                <div>
                    <div class="title">Application Name</div>
                    <div class="subtitle">{{ finalModel.name }}</div>
                </div>
            </div>

            <div class="summary">
                <div class="step">2</div>
                <div>
                    <div class="title">
                        Install this Gateway in
                        {{ sourceGatewayString != null ? sourceGatewayString : 'your current source' }}
                    </div>
                    <label class="underline"
                        >Gateway Name<span [hidden]="!showLauncher(finalModel['hostingGateway'])" class="note"
                            >Tap To Launch New Gateway</span
                        ></label
                    >
                    <div class="content">
                        <div
                            (click)="
                                !showLauncher(finalModel['hostingGateway']) && openGateway(finalModel['hostingGateway'])
                            "
                            [ngClass]="{
                                noLauncherScript: !showLauncher(finalModel['hostingGateway'])
                            }"
                            class="launch"
                        >
                            {{ finalModel['hostingGatewayName'] }}
                            <div
                                (click)="LaunchAzureCloudScript(finalModel['hostingGateway'])"
                                *ngIf="
                                    showLauncher(finalModel['hostingGateway']) &&
                                    IsAzure(finalModel['hostingGateway']) &&
                                    !IsHA(finalModel['hostingGateway']) &&
                                    !isGatewayRegistered(finalModel['hostingGateway'])
                                "
                                class="tap azure"
                            >
                                Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                            </div>
                            <div
                                (click)="LaunchCloudScript(finalModel['hostingGateway'])"
                                *ngIf="
                                    showLauncher(finalModel['hostingGateway']) &&
                                    IsAWS(finalModel['hostingGateway']) &&
                                    !IsHA(finalModel['hostingGateway']) &&
                                    !isGatewayRegistered(finalModel['hostingGateway'])
                                "
                                class="tap aws"
                            >
                                Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="summary">
                <div class="step">3</div>
                <div>
                    <div *ngIf="ipNetworkServices.length > 0 && !isEmptyNetworkService(ipNetworkServices[0])">
                        <div class="title">Services</div>
                        <div class="tableGrid four header">
                            <div>Network Address</div>
                            <div>Intercept Address</div>
                            <div>Label</div>
                            <div>Gateway</div>
                        </div>
                        <!-- Need to check type and only show if Network -->
                        <div *ngFor="let service of finalModel['services']; let index = index" class="tableGrid four">
                            <!-- needed to add the ngIf on each div instead of using a parent div as the parent div broke the styling -->
                            <div *ngIf="service.serviceClass === 'GW'" class="staticCell">
                                {{ service.gatewayIp + '/' + service.gatewayCidrBlock }}
                            </div>
                            <div *ngIf="service.serviceClass === 'GW'" class="staticCell">
                                {{ service.interceptIp }}
                            </div>
                            <div
                                (click)="openService(service)"
                                *ngIf="service.serviceClass === 'GW'"
                                class="clickable link"
                                class="staticCell"
                            >
                                {{ service.name }}
                            </div>
                            <div *ngIf="service.serviceClass === 'GW'" class="staticCell">
                                {{ finalModel['hostingGateway']['name'] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">&nbsp;</div>
        </div>
    </div>
</div>
