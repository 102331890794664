import { Component, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
    selector: 'app-growler-error-history',
    templateUrl: './growler-error-history.component.html',
    styleUrls: ['./growler-error-history.component.scss'],
})
export class GrowlerErrorHistoryComponent implements OnInit {
    growlerErrors = [];

    sorting = '@timestamp';
    ordering = 'desc';
    filterString = '';

    ngOnInit() {
        const growlerErrorString = sessionStorage.getItem('growlerErrors');
        if (growlerErrorString !== null && growlerErrorString !== undefined && growlerErrorString !== '') {
            const growlerErrorObject = JSON.parse(sessionStorage.getItem('growlerErrors'));

            this.growlerErrors = growlerErrorObject['growlerMessages'];
        }
    }

    formatTime(theDate) {
        return moment.utc(theDate).local().format('M/D/YY h:mm a');
    }

    sort(sortBy) {
        if (this.sorting === sortBy) {
            if (this.ordering === 'asc') {
                this.ordering = 'desc';
            } else {
                this.ordering = 'asc';
            }
        } else {
            this.ordering = 'asc';
            this.sorting = sortBy;
        }
    }

    getSortClass(id) {
        if (id === this.sorting) {
            return this.ordering;
        } else {
            return '';
        }
    }

    trackByTimestamp(index, item) {
        return item.timestamp;
    }
}
