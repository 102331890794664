<div class="fullModal opened" id="AuthPolicyAddModal">
    <div class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            {{ isEditing ? 'Modify Your' : 'Create A New' }} Authentication Policy
        </div>
        <div class="subtitle">{{ isEditing ? 'Modify' : 'Enter' }} the Authentication Policy Details</div>
        <div
            class="subhelp"
            matTooltip="Authentication Policies restrict the authentication methods available to identities."
        >
            What's an Authentication Policy?
        </div>
        <div class="sized">
            <div class="label row mt16">
                Policy Name
                <span [ngClass]="{ error: errMap.name }" class="note">{{ errMap.name ? 'Invalid' : 'Required' }}</span>
            </div>
            <div class="row">
                <input
                    (keyup.enter)="validate()"
                    [(ngModel)]="model.name"
                    [ngClass]="{ error: errMap.name }"
                    class="restrictNormal"
                    id="editName"
                    maxlength="64"
                    placeholder="Policy Name"
                    type="text"
                />
            </div>
            <span [hidden]="!errMap.name" class="err">{{ errMap.name }}</span>

            <div class="label row">
                Primary Authentication
                <div class="infoicon" [matTooltip]="primaryAuthInfo" matTooltipPosition="below"></div>
            </div>
            <div class="darkBox">
                <div class="lightenPanel">
                    <div
                        class="label"
                        style="color: var(--navigation); display: inline-block; margin-top: 0; vertical-align: middle"
                    >
                        Certificate Based Authentication
                    </div>
                    <div class="switchGrid special">
                        <div
                            (click)="model.primary.cert.allowed = !model.primary.cert.allowed"
                            class="toggle"
                            [class.on]="model.primary.cert.allowed"
                        >
                            <span [hidden]="!model.primary.cert.allowed" style="margin-right: 1rem">YES</span>
                            <span [hidden]="model.primary.cert.allowed" style="margin-left: 1rem">NO</span>
                            <div class="switch"></div>
                        </div>
                    </div>
                    <div
                        class="label"
                        style="color: var(--navigation); display: inline-block; margin-top: 0; vertical-align: middle"
                    >
                        Allow Expired Certificates
                    </div>
                    <div class="switchGrid special">
                        <div
                            (click)="model.primary.cert.allowExpiredCerts = !model.primary.cert.allowExpiredCerts"
                            class="toggle"
                            [class.on]="model.primary.cert.allowExpiredCerts"
                        >
                            <span [hidden]="!model.primary.cert.allowExpiredCerts" style="margin-right: 1rem">YES</span>
                            <span [hidden]="model.primary.cert.allowExpiredCerts" style="margin-left: 1rem">NO</span>
                            <div class="switch"></div>
                        </div>
                    </div>
                </div>
                <div class="spacer"></div>
                <div class="lightenPanel">
                    <div
                        class="label"
                        style="color: var(--navigation); display: inline-block; margin-top: 0; vertical-align: middle"
                    >
                        JWT Based Authentication
                    </div>
                    <div class="switchGrid special">
                        <div (click)="toggleJWTAllowed()" class="toggle" [class.on]="model.primary.extJwt.allowed">
                            <span [hidden]="!model.primary.extJwt.allowed" style="margin-right: 1rem">YES</span>
                            <span [hidden]="model.primary.extJwt.allowed" style="margin-left: 1rem">NO</span>
                            <div class="switch"></div>
                        </div>
                    </div>
                    <div class="label secondary">Allowed JWT Signers</div>
                    <app-tags-selector
                        (addAttribute)="addJwtSigner($event)"
                        (removeAttribute)="removeJwtSigner($event)"
                        [availableAttributes]="jwtSigners"
                        [hasError]="jwtSignereError"
                        [isLoading]="isLoadingJwtSigners"
                        [placeholder]="'Choose JWT Signers'"
                        [selectedAttributes]="selectedJwtSigners"
                        [disableField]="!model.primary.extJwt.allowed"
                        id="jwtSignersSelector"
                    ></app-tags-selector>
                </div>
            </div>

            <div class="label row">
                Secondary Authentication
                <div class="infoicon" [matTooltip]="secondaryAuthInfo" matTooltipPosition="below"></div>
            </div>
            <div class="darkBox">
                <div class="lightenPanel">
                    <div
                        class="label"
                        style="color: var(--navigation); display: inline-block; margin-top: 0; vertical-align: middle"
                    >
                        Require JWT From
                    </div>
                    <app-tags-selector
                        (addAttribute)="addExtJwtSigner($event)"
                        (removeAttribute)="removeExtJwtSigner($event)"
                        [availableAttributes]="jwtSigners"
                        [hasError]="jwtSignereError"
                        [isLoading]="isLoadingJwtSigners"
                        [placeholder]="'Choose JWT Signers'"
                        [selectedAttributes]="selectedRequireExtJwtSigner"
                        [disableField]="!model.primary.extJwt.allowed"
                        id="requireExtJwtSigner"
                    ></app-tags-selector>
                    <div
                        class="label"
                        style="color: var(--navigation); display: inline-block; margin-top: 0; vertical-align: middle"
                    >
                        Require TOTP Code
                    </div>
                    <div class="switchGrid special" style="margin-top: 1rem">
                        <div
                            (click)="model.secondary.requireTotp = !model.secondary.requireTotp"
                            class="toggle"
                            [class.on]="model.secondary.requireTotp"
                        >
                            <span [hidden]="!model.secondary.requireTotp" style="margin-right: 1rem">YES</span>
                            <span [hidden]="model.secondary.requireTotp" style="margin-left: 1rem">NO</span>
                            <div class="switch"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row buttonRow right">
                <button (click)="save()" *ngIf="!processing" class="save">
                    {{ isEditing ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
        </div>
        <div class="spacer"></div>
        <div (click)="hide()" class="buttonBall close" id="CloseClientButton">
            <div class="buttonLabel">ESC</div>
        </div>
        <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    </div>
</div>
