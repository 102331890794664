import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('attributes')
export class Attribute extends Resource {
    attribute: string;
    attributeType: string;
    createdAt: any;
    deletedAt: any;
    id: string;
    networkId: string;
    parentId: string;
    parentType: string;
    updatedAt: any;
}

export class SimpleAttribute {
    id?: string;
    name: string;
    attributeType?: string;
    isNamed?: boolean;
    isGroup?: boolean;
    attribute?: string;
}

export class PagedAttributes {
    //simpleAttributes: SimpleAttribute[]  = [];
    mappedAtrributes = new Map<string, SimpleAttribute>();
    totalElements: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
}
