import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import {
  ApiService,
  BrandingModeService,
  ErrorHistoryService, FeatureService,
  LoggerService,
  MenuService,
  ValidateService,
  URLS
} from '@netfoundry-ui/shared/services';

import { of, ReplaySubject, Subscription, timer } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { delay, get, isEmpty } from 'lodash';
import { Auth0Service } from './auth0.service';
import { AuthorizationService } from './authorization.service';
import { AwsSplashScreenComponent } from './aws-splash-screen/aws-splash-screen.component';
import { IamService } from './iam.service';
import { LoginServiceType } from './login-service.type';
import {ZitiLoginService} from "./ziti-login-service";

@Injectable({ providedIn: 'root' })
export class LoginService implements LoginServiceType {
    refreshSub = new Subscription();
    jwtHelper: JwtHelperService;
    dialogRef: MatDialogRef<any>;
    isAwsLogin = false;
    identityProviders = [];

    options = {
        closable: true,
        autoclose: true,
        allowSignUp: false,
        allowLogin: true,
        auth: {
            redirectUrl: this.environment.authconfig.callbackURL,
            responseType: 'token id_token',
            audience: this.environment.authconfig.audience,
            params: {
                scope: 'openid email profile',
            },
        },
        languageDictionary: {
            title: 'NetFoundry',
        },
        theme: {
            logo: 'https://console.netfoundry.io/assets/icons/android-icon-192x192.png',
            primaryColor: '#006cff',
        },
        prefill: {
            email: '',
            username: '',
            firstName: '',
            lastName: '',
        },
    };
    isRootDomain = true;
    emailAddress = '';
    errorEmailAddress = false;
    forgotSiteName = false;
    isResuming = true;
    tenantLabel = '';
    errorSiteName = false;
    vanityLogin = false;
    isLoggedIn = false;
    hasLoggedIn = false;
    authenticationChangedSource = new ReplaySubject<boolean>();
    authenticationChanged = this.authenticationChangedSource.asObservable();

    logoutSource = new ReplaySubject<boolean>();
    logoutOb = this.logoutSource.asObservable();
    isVanityUrl = false;
    url = '';

    constructor(
        protected logger: LoggerService,
        private router: Router,
        private iamService: IamService,
        private activatedRoute: ActivatedRoute,
        private growlService: GrowlerService,
        private matDialog: MatDialog,
        private locationService: Location,
        private brandingService: BrandingModeService,
        private validateService: ValidateService,
        private authorizationService: AuthorizationService,
        private menuService: MenuService,
        private errorHistoryService: ErrorHistoryService,
        private dialogForm: MatDialog,
        private auth0Service: Auth0Service,
        private apiService: ApiService,
        private zitiLoginService: ZitiLoginService,
        private featureService: FeatureService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.jwtHelper = new JwtHelperService();
        if (window.location.hostname !== this.environment.originDomain) {
            this.isRootDomain = false;
        }

        this.auth0Service?.isAuthenticated$?.subscribe((isLoggedIn) => {
            this.isLoggedIn = isLoggedIn;
            this.authenticationChangedSource.next(this.isLoggedIn);
            const wasLoggedIn = localStorage.getItem('wasLoggedIn') === 'true';
            if (this.isLoggedIn && this.isVanityUrl) {
                localStorage.setItem('wasLoggedIn', 'true');
                localStorage.setItem('isVanityUrl', 'true');
            }
            if (!this.isLoggedIn && wasLoggedIn) {
                localStorage.removeItem('wasLoggedIn');
                this.handleVanityUrl();
            }
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.url = event.url;
            }
        });
    }

    public logout(navigateToNotFound, skipTokenRevoke?: boolean, params?: any, localonly?: boolean): void {
        this.errorHistoryService.clearErrorHistory();

        this.menuService.unsetArea();

        this.authorizationService.obtainedMopPermissions = false;

        this.unscheduleRenewal();

        // it is possible the user has a dialog still open if they are being auto logged out
        // in this case we want to be sure that all dialogs are closed, as otherwise they would remain open after the user logs out
        for (const dialog of this.matDialog.openDialogs) {
            // when closing each dialog, pass an object containing a property loggingOut which is set to true
            // this is done as many of the dialogs in the nfconsole are set to preform some action that makes an API call after the dialog is closed
            // if the dialogs attempt this when the user is being logged out, they will be unauthenticated by the time the API call is made
            if (localonly) {
                dialog.close({ loggingOut: true });
            }
        }
        if (this.isAuthenticated() && !skipTokenRevoke) {
            this.iamService.logOut().subscribe(
                (result) => {
                    this.logger.info("Successfully revoked the user's token");
                },
                (error) => {
                    this.logger.info('Could not revoke the token due to an error: ', error);
                }
            );
        }

        const hostname = window.location.hostname;
        this.isVanityUrl = hostname !== this.environment.originDomain;
        let returnTo = window.location.origin + '/login';
        if (this.isVanityUrl) {
            returnTo = window.location.origin + '/vanity-logout';
        }
        const logoutOptions = {
            localOnly: localonly,
            returnTo: returnTo,
        };

        this.clearStorage();

        // clear branding colors
        this.brandingService.restore();
        this.auth0Service.logout(logoutOptions);
        // if navigateToNotFound is true, navigate the user to the PageNotFound component after logging out
        if (navigateToNotFound) {
            this.router.navigate(['/page-not-found']);
        } else if (localonly) {
            // otherwise, navigate to the login page as normal
            this.router.navigate(['/login'], params);
        }
    }

    public isAuthenticated(): boolean {
        return this.isLoggedIn;
    }

    public pageIsUnauthenticated(): boolean {
      if (this.url.indexOf(URLS.STORED_SECRETS) >= 0 && this.url.indexOf('?token=') >= 0) {
        return true;
      }
    }

    /**
     * If we are here, that means we are on the vanity URL and we need to login through the root domain, so redirect there and pass the originating Organization name
     * - Vanity URL Step #2
     */
    public _redirectToRootDomainForLogin(hostname) {
        // capture the Organization name from the URL
        this.logger.info('VANITY - We are on the vanity URL');
        const siteName = hostname.split('.')[0];

        let url = location.protocol + '//' + this.environment.originDomain;
        if (this.environment['originPort'] !== undefined && this.environment['originPort']) {
            url = url + ':' + this.environment.originPort;
        }

        // add siteName to path
        url = url + '/login?loginSiteName=' + siteName;
        this.logger.info('Callback URL being passed to Auth0 is: ' + this.getCallbackUrl(siteName));
        this.logger.info('Navigating to: ' + url);
        // This redirects bck to the root domain with the 'loginSiteName' being passed as a GET param, see step #3 above
        this._catchVanityUrlAuth(siteName);
    }

    /**
     * Handle the incoming auth result that was passed to us from the root domain
     * This happens if the user has just logged in through the root domain, the Auth0 result is passed over the URL to us
     * Need to decode it and save it in our local storage to it matches the vanity domain's domain.
     *
     * - Vanity URL Step #6 (continued)
     */
    public _catchAuthResult() {
        this.logger.info('Catch the incoming AuthResult for the vanity domain (_catchAuthResult() function )');
        const urlParams = new URLSearchParams(window.location.search);
        let authResult = urlParams.get('authResult');
        authResult = atob(authResult);
        // this.logger.info('Raws JSON String AuthResult', authResult);
        try {
            authResult = JSON.parse(authResult);
        } catch (err) {
            this.logger.error('Error decoding the auth result from the URL. JSON parse failure', err);
            this.growlService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Authentication Error',
                    'There was a problem authenticating, please try again'
                )
            );
            this.router.navigate(['/login']);
            return;
        }

        this._setSession(authResult);
        this.logger.info('Should be logged into vanity URL, redirect to dashboard');
        const url = this.featureService.isCloudZiti ? '/dashboard' : '/v7/dashboard';
        this.apiService.resetInitialNetworkId();
        this.router.navigate([url]);
    }

    public clearStorage() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('organizationId');
        localStorage.removeItem('profile_name');
        localStorage.removeItem('proflie_nick');
        localStorage.removeItem('profile_picture');
        localStorage.removeItem('profile_email');
        localStorage.removeItem('tenantName');
        localStorage.removeItem('tenantLabel');
        localStorage.removeItem('tenantId');
        localStorage.removeItem('isVanityUrl');
        localStorage.removeItem('wasLoggedIn');
        sessionStorage.removeItem('growlerErrors');
        sessionStorage.removeItem('lastTenantId');
    }

    /**
     * Save the Auth0 Result to Local Storage for the current domain
     */
    public _setSession(authResult): void {
        // Set the time that the access token will expire at
        this.logger.info('Setting session');
        this.logger.info('AUTH RESULT', authResult);
        const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
        const jwt = this.jwtHelper.decodeToken(authResult.accessToken);
        // fetch and store the Tenant info
        // Need to call this before the access token is set otherwise we will get a 403 (API bug)
        this.iamService.getOrgByShortName(jwt['https://netfoundry.io/tenant/label']).subscribe((result: any) => {
            this.logger.info('Tenant Info', result);
            localStorage.setItem('tenantName', result.name);
            localStorage.setItem('tenantLabel', result.label);
            localStorage.setItem('tenantId', result.id);
            localStorage.setItem('loggedInTenantId', result.id);

            // set branding once we have the tenant label
            const html = document.getElementsByTagName('html')[0];

            this.brandingService.fetchBrandCss().then((rawCss) => {
                html.style.cssText = rawCss;
            });

            // checking if the tenant the user is logged into is the same as the last tenant that was logged into
            //  in this session
            if (result.id !== sessionStorage.getItem('lastTenantId')) {
                // if the tenants are different, remove the old growler messages
                sessionStorage.removeItem('growlerErrors');
            }
            sessionStorage.setItem('lastTenantId', result.id);
        });

        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('expires_at', expiresAt);
        localStorage.setItem('Auth0Id', jwt.sub);
        localStorage.setItem('connectionId', jwt['https://netfoundry.io/auth0/connectionId']);

        this.scheduleRenewal();
    }

    initiateSiteNameRequest() {
        this.errorEmailAddress = false;
        if (this.validateService.isValidEmail(this.emailAddress)) {
            this.emailAddress = this.emailAddress.trim();

            this.iamService.sendForgottenSitenames(this.emailAddress).subscribe((result) => {
                this.forgotSiteName = false;
                this.growlService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Email Sent',
                        'An email with your organization names has been sent to the address provided'
                    )
                );
            });
        } else {
            this.errorEmailAddress = true;
            this.growlService.show(
                new GrowlerData('error', 'Error', 'Invalid Email Address', 'Please enter a valid email address.')
            );
        }
    }

    // function for requesting a fresh access token or the user
    public renewToken() {
        this.logger.info("Attempting to renew user's token");

        // rebuilding the lock
        this.rebuildLock();

        // calling the refreshToken function of mopsharedjs to obtain the nftoken
        // the nftoken obtained here is marked as being for the refresh flow and as such should not require any redirecting
        this.iamService.refreshToken().subscribe((result) => {
            // ensuring that a nfToken was obtained
            if (result['nfToken'] !== undefined) {
                // using checkSession to do a token renewal.
                // This is one of the reccommended method for managing tokens that are about to expire in single-page applications
            } else {
                // if no nfToken was obtained display a growler message
                this.growlService.show(
                    new GrowlerData(
                        'error',
                        'Error',
                        'Unable to Refresh Session',
                        'Unable to obtain a new nfToken to refresh the current session. Please log out and log back in to restart your session'
                    )
                );

                this.logger.info('failed to get nfToken from authorize-refresh');
            }
        });
    }

    // function for scheduling the renewal of the token
    // the time it takes to renew the token is based off the current time and the time the token expires
    // as such, it is OK to call this function multiple times before the token is set to expire as the time until renewal
    // will never increase
    public scheduleRenewal() {
        // making sure the user is authenticated before proceeding
        // if the user is no longer authenticated then it won't be possible to renew their token
        if (!this.isAuthenticated()) {
            // displaying a growler
            this.growlService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Unable to Refresh Session',
                    'Unable to refresh the current session. Please log out and log back in to restart your session'
                )
            );

            // logging the user out
            this.logout(false);
        }

        // getting rid of any previous renewal subscriptions
        this.unscheduleRenewal();

        this.refreshSub = new Subscription();

        // getting expired_at from localStorage
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));

        // creating an observable timer based on expired at
        const expiresIn$ = of(expiresAt).pipe(
            mergeMap((expiresAt: number) => {
                const now = Date.now();
                // Use timer to track delay until expiration
                // to run the refresh at the proper time
                // this timer will end 2 seconds before the token is set to expire
                // this is necessary as it is possible for isAuthenticated to fail if the timer ends too
                // close to the expiration of the token
                const result = timer(Math.max(2000, expiresAt - (now + 2000)));
                return result;
            })
        );

        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        this.refreshSub = expiresIn$.subscribe(() => {
            // renewing the token
            if (
                !(window.location.pathname.indexOf('/signup-complete') >= 0) &&
                !(window.location.pathname.indexOf('/signup-return') >= 0)
            ) {
                this.renewToken();
            }
        });
    }

    // function for unscheduling the token renewal
    public unscheduleRenewal() {
        // unsubscribing the refreshSub. this should not only unsubscribe from but also kill the Observable timer
        this.refreshSub.unsubscribe();
    }

    returnToVanityLogin() {
        this.router.navigate(['/login'], { queryParams: {} }).then((result) => {
            this.handleVanityUrl();
        });
    }

    /**
     * This is the handler that determine what to do about vanity URLs for every page load
     * - Vanity URL Step #1
     */
    public handleVanityUrl() {
        // not worried if we're already authenticated
        if (this.isAuthenticated() || this.pageIsUnauthenticated()) {
            return;
        }

        // Are we trying to process the incoming authResult right now?
        // Vanity URL step #6
        if (this.locationService.path().match(/authResult/)) {
            this._catchAuthResult();
            return;
        }

        const hostname = window.location.hostname;
        this.isVanityUrl = hostname !== this.environment.originDomain;
        const wasLoggedIn = !isEmpty(localStorage.getItem('wasLoggedIn'));
        if (
            this.isVanityUrl &&
            (wasLoggedIn ||
                this.locationService.path().match(/callback\?code=/) ||
                this.locationService.path().match(/login\?authToken=/))
        ) {
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const isVanityLogout =
            urlParams.get('vanityLogout') === 'true' || window.location.pathname?.indexOf('vanity-logout') >= 0;
        // Check if the incoming domain is the root domain, if it's not, handle the redirect
        if (this.isVanityUrl && !isVanityLogout) {
            this.isResuming = true;
            this._redirectToRootDomainForLogin(hostname);
        }
    }

    /**
     * Cleanly rebuild the log object based on the current options
     */
    rebuildLock() {}

    /**
     * Add a flag to the callback URL that we pass to Auth0 so it has the "returnToSiteName" GET parameter in the URL
     */
    updateCallbackUrl(siteName) {
        this.options.auth.redirectUrl = this.getCallbackUrl(siteName);
    }

    /**
     * What's the magic callback URL that let's us know what vanity domain to go to once we're authorized
     */
    getCallbackUrl(siteName) {
        const url = this.environment.authconfig.callbackURL + '?returnToSiteName=' + siteName;
        this.logger.info('Callback URL for auth0 is: ' + url);
        return url;
    }

    /**
     * We have a valid Auth Result from Auth0. Package up the result and pass it in the URL to the root domain
     * We have a valid Auth Result from Auth0. Package up the result and pass it in the URL to the root domain
     *  - Vanity URL step #5
     */
    goToVanitySite(authResult) {
        // extract the sitename param
        const urlParams = new URLSearchParams(window.location.search);
        const siteName = urlParams.get('returnToSiteName');

        let url = '';
        if (this.environment.domain === 'localhost' || this.environment.domain === 'uuix') {
            url = `${location.protocol}//${siteName}.${this.environment.domain}:${this.environment.originPort}/callback`;
        } else {
            url = `${location.protocol}//${siteName}.${this.environment.originDomain}/callback`;
        }

        // Attempt to shrink the authResult into only the necessary parts, otherwise the GET param is too large
        const authResultSmall = {
            expiresIn: authResult.expiresIn,
            accessToken: authResult.accessToken,
        };

        url = url + '?authResult=' + btoa(JSON.stringify(authResultSmall));
        this.logger.info('GO TO VANITY URL NOW: ' + url);
        window.location.href = url;
    }

    public continue(state) {
        window.location.href = `https://${this.environment.authconfig.domain}/continue?state=${state}`;
    }

    public acceptInvite(nfToken, connection, prefill, showLogin = true) {
        this.options.prefill.email = prefill;
        this.options.closable = showLogin;
        // create a new lock otherwise the prefills won't show

        this.logger.info('Allowed Provider Connection', connection);

        // let initialScreen = 'signUp';
        // if (connection.auth0ConnectionType.toLowerCase() === 'enterprise') {
        //     initialScreen = 'login';
        // }
        localStorage.setItem(`showConsoleTour_${prefill}`, 'true');
    }

    public gotoSignUp() {
        this.clearStorage();
        this.router.navigateByUrl('signup');
    }

    // Updated Auth Flow
    public async newLogin(nfToken, returnUrl, connections) {
        const idps = [];
        this.identityProviders?.forEach((idp) => {
            idps.push({ connectionId: idp.auth0ConnectionId, name: idp.name });
        });

        let redirectUri = this.environment.authconfig.callbackURL;
        if (this.vanityLogin) {
            let newUri = '';
            if (redirectUri.indexOf('https://') >= 0) {
                const redirectLocation = redirectUri.slice(8);
                newUri = `https://${this.tenantLabel}.${redirectLocation}`;
            } else {
                const redirectLocation = redirectUri.slice(7);
                newUri = `http://${this.tenantLabel}.${redirectLocation}`;
            }
            redirectUri = newUri;
        }
        this.zitiLoginService.clearZitiSession();
      const url = this.featureService.isCloudZiti ? '/dashboard' : '/v7/dashboard';
        const state = {
            appState: {
                nfToken: nfToken,
                target: url,
                allowSignup: false,
                identityProviders: this.identityProviders,
                branding: null,
            },
            nfToken: nfToken,
            allowSignup: false,
            identityProviders: JSON.stringify(idps),
            tenantLabel: this.tenantLabel,
            branding: null,
            redirect_uri: redirectUri,
        };
        await this.brandingService
            .fetchBrandCss(this.tenantLabel)
            .then((rawCss) => {
                state.branding = rawCss;
            })
            .finally(async () => {
                // even if branding fails, continue
                await this.auth0Service.loginWithRedirect(state);
            });
    }

    resumeLogin(authToken, state) {
        this.logger.info('Got an authToken', authToken);

        // @TODO - maybe display a message when resuming?
        this.isResuming = true;

        // verify that this user is actually a member of this tenant, if not pop an error from the identity service
        this.iamService.authorize(authToken).subscribe(
            (res) => {
                if (res['nfToken'] !== undefined) {
                    this.logger.info('Auth0 user is a member of the requested tenant, resuming authorization');
                    const url = `https://${this.environment.authconfig.domain}/continue?state=${state}&nfToken=${res['nfToken']}`;

                    // if token validation check returns with a 200, resume auth through Auth0 and pass auth0 the updated token
                    window.location.href = url;
                } else {
                    // alert('Unable to authenticate against the requested site, see console for more details');
                    this.logger.error('Unable to validate user against tenant', res);
                    this.growlService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Authentication Error',
                            'Unable to authenticate identity, missing a valid nfToken. See console for details.'
                        )
                    );
                    this.isResuming = false;
                }
            },
            (error) => {
                const errMessage = get(error, 'error[0].message', '');
                this.logger.error('Authentication Error', error);
                this.growlService.show(
                    new GrowlerData(
                        'error',
                        'Error',
                        'Authentication Error',
                        `Unable to authenticate ${errMessage}. Please make sure you signed in with the correct
                                 user account/credentials. See console for more details.`
                    )
                );

                this.isResuming = false;
            }
        );
    }

    // Call this method in app.component.ts
    // if using hash-based routing
    public handleAuthenticationWithHash(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                filter((event: NavigationStart) => /access_token|id_token|error(?!-history)/.test(event.url))
            )
            .subscribe(() => {
                this.logger.info('Resuming Auth from Auth0');
                this.isResuming = true;
                this._resumeAuthFromHash();
            });
    }

    async getIdentityProviders() {
        if (!this.tenantLabel || this.tenantLabel === '') {
            this.errorSiteName = true;
            this.growlService.show(
                new GrowlerData(
                    'error',
                    'Error',
                    'Invalid Organization Name',
                    'Please enter a valid organization name.'
                )
            );
        }

        this.identityProviders = [];
        await this.iamService
            .getOrgByShortName(this.tenantLabel)
            .toPromise()
            .then(
                (organization) => {
                    this.identityProviders = organization['identityProviders'];
                },
                (error) => {
                    if (error.status === 404) {
                        this.errorSiteName = true;
                        this.growlService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Invalid Organization Name',
                                'No organization exists with this name.'
                            )
                        );
                    } else {
                        this.errorSiteName = true;
                        this.growlService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Invalid Organization Name',
                                'Please enter a valid organization name.'
                            )
                        );
                    }
                }
            );
        this.initiateLogin(this.identityProviders[0]);
    }

    // Updated Auth Flow
    async initiateLogin(identityProvider) {
        this.iamService.authorizeInitiate(this.tenantLabel).subscribe(
            (result: any) => {
                this.errorSiteName = false;
                this.logger.info('Authorize tenant label: ' + this.tenantLabel, result);

                // Pop error if no token came back
                if (result['nfToken'] === undefined) {
                    this.vanityLogin = false;
                    this.isResuming = false;
                    this.growlService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Invalid Organization Name',
                            'No nfToken came back from the API. See the console for more details'
                        )
                    );
                } else {
                    this.newLogin(result.nfToken, result.intermediateReturnUrl, [identityProvider.auth0ConnectionId]);
                }
            },
            (error) => {
                if (error.status === 400) {
                    this.errorSiteName = true;
                    this.vanityLogin = false;
                    this.isResuming = false;
                    this.growlService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Invalid Organization Name',
                            'The organization name provided does not exist. To signup for an account, click the "TRY FOR FREE" link.'
                        )
                    );
                    // If org/vanity login is invalid, wait a few seconds after showing growler and then redirect to root domain/login
                    delay(() => {
                        if (this.isVanityUrl) {
                            window.location.href = this.environment.identityConfig.loginReturnUrl;
                        }
                    }, 6000);
                }
            }
        );
    }

    /**
     * A redirect has landed here on the root domain from a vanity URL
     *  - Catch the siteName from the URL parameters,
     *  - Hide the login form
     *  - Determine the Auth0 callback URL
     *  - Rebuild the Auth0 Lock object just to make sure there aren't memory artifacts
     *  - Pop the Auth0 lock and do the standard login
     *
     *  - Vanity URL Step #3 (continued)
     */
    public _catchVanityUrlAuth(siteName) {
        this.logger.info('Handling auth incoming auth request for a vanity URL - catchVanityUrlAuth');
        this.vanityLogin = true;
        this.tenantLabel = siteName;
        this.updateCallbackUrl(siteName);
        if (this.isAwsLogin) {
            this.dialogRef = this.dialogForm.open(AwsSplashScreenComponent, {
                data: {},
                minHeight: '100%',
                minWidth: '100%',
                height: '100%',
                width: '100%',
            });
            this.dialogRef.afterClosed().subscribe(() => {
                this.rebuildLock();
                this.getIdentityProviders();
            });
        } else {
            this.rebuildLock();
            this.getIdentityProviders();
        }
    }

    /**
     * The lock intercepts the auth info from the URL's hash (everything after the # sign) and then the helper clears it from the URL
     * This must be done from the root domain otherwise it pukes because the state nonce does not match
     * For vanity URLs, we have to fully capture and decode the token, and then forward that to the vanity domain to save into that domain's local storage
     */
    private _resumeAuthFromHash() {}
}
