<div class="fullModal opened">
    <div class="fullRow tilepicker">
        <div class="title" style="filter: brightness(50%)">{{ title }}</div>
        <div class="subtitle">{{ description }}</div>
        <div class="row center">
            <div
                (click)="setType(tile.type)"
                *ngFor="let tile of tileData"
                class="buttonCard selectType"
                id="{{ tile.elementId }}"
            >
                <div
                    [ngStyle]="{
                        'background-image': 'url(/assets/svgs/' + tile.svg + ')'
                    }"
                    class="icon"
                ></div>
                <div class="title">{{ tile.title }}</div>
                <div class="content">{{ tile.description }}<br />&nbsp;</div>
                <div class="row center buttonArea">
                    <div class="save">{{ tile.buttonTitle }}</div>
                </div>
                <div class="row center padTop">&nbsp;</div>
                <div *ngIf="tile.showExtraLink" class="row center padTop subButton">
                    <div (click)="setType(tile.extraLinkType)" class="linkButton">
                        {{ tile.extraLinkTitle }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div (click)="hide()" class="buttonBall close" id="CloseTilePickerButton">
        <div class="buttonLabel">ESC</div>
    </div>
</div>
