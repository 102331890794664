import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-warning-list',
    templateUrl: './warning-list.component.html',
    styleUrls: ['./warning-list.component.scss'],
})
export class WarningListComponent {
    clients = [];
    services = [];
    gateways = [];

    typeString = '';
    cancelAction = 'Oops, no get me out of here';

    noZitiGateways = false;

    noZitiGatewayString = 'Additionally, a Ziti Bridge is required to utilize Ziti clients.';

    hasGatewaysAndServices = false;

    list1 = [];
    list1Title = '';

    list2 = [];
    list2Title = '';

    icon = '';

    title = '';

    isZitiWarning = false;

    overrideFont = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<WarningListComponent>) {
        if (data.isZitiWarning) {
            this.isZitiWarning = true;

            this.services = data.services != null ? data.services : [];
            this.gateways = data.gateways != null ? data.gateways : [];
            this.clients = data.clients;

            this.noZitiGateways = data.noZitiGateways;

            let hasGateways = false;
            if (this.gateways.length > 0) {
                this.typeString = 'gateways';
                hasGateways = true;
            }

            let hasServices = false;
            if (this.services.length > 0) {
                this.typeString = 'services';
                hasServices = true;
            }

            if (hasGateways && hasServices) {
                this.hasGatewaysAndServices = true;
                this.typeString = 'services or gateways';
            }
        } else {
            this.list1 = data.list1;
            this.list1Title = data.list1Title;

            if (data.list2 != null) {
                this.list2 = data.list2;
                this.list2Title = data.list2Title;
            }

            this.icon = data.icon;
            this.title = data.title;

            this.overrideFont = data.overrideFont;
        }
    }

    close() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }
}
