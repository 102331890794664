<div [hidden]="!showExport" id="ExportAction">
    <div
        (click)="csv_export()"
        [ngClass]="{
            bannerOpen: !billingEnabledservice.hideCardWarning && billingEnabledservice.isFourteenDayTrial
        }"
        class="buttonAction export"
    ></div>
    <div class="title action">Export To CSV</div>
</div>
