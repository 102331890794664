import { MopResource } from './mopresource';

export class Service extends MopResource {
    public serviceClass: string;
    public serviceInterceptType: string;
    public serviceType: string;
    public lowLatency: string;
    public dataInterleaving: string;
    public interceptDnsHostname: string;
    public interceptDnsPort: number;
    public interceptFirstPort: string;
    public interceptLastPort: string;
    public networkIp: string;
    public networkFirstPort: any;
    public networkLastPort: any;
    public interceptIp: string;
    public networkNetmask: string;
    public endpointId: string;
    public protocol: string;
    public gatewayIp: string;
    public gatewayNetmask: string;
    public gatewayCidrBlock: number;
    public selected = false;
    public protocolType: string;
    public bridgeStp: string;
    public collectionLocation: string;
    public cryptoLevel: string;
    public dnsOptions: string;
    public icmpTunnel: string;
    public localNetworkGateway: string;
    public multicast: string;
    public pbrType: string;
    public permanentConnection: string;
    public rateSmoothing: string;
    public transparency: string;
    public dartServiceId: string;
    public netflowIndex: number;
    public profileIndex: number;
    public vlanId: number;
    public bridgeIntf: string;
    public bridgeAgeing: number;
    public bridgePrio: number;
    public bridgeFd: number;
    public bridgeHelloTime: number;
    public bridgeMaxAge: number;
    public bridgeCost: number;
    public protectionGroupId: string;
    public snmpIndex: number;
    public gatewayClusterId: string;
    public o365Conflict: boolean;
    public portInterceptMode: string;
    public interceptPorts: {
        include: string[];
        exclude: string[];
    };

    constructor(object) {
        super(object);

        this.endpointId = this.getLinkedResourceId('endpoint');
    }

    public getSaveObject() {
        return {
            name: this.name,
            serviceClass: this.serviceClass,
            serviceInterceptType: this.serviceInterceptType,
            serviceType: this.serviceType,
            lowLatency: this.lowLatency,
            dataInterleaving: this.dataInterleaving,
            interceptDnsHostname: this.interceptDnsHostname,
            interceptDnsPort: this.interceptDnsPort,
            interceptFirstPort: this.interceptFirstPort,
            interceptLastPort: this.interceptLastPort,
            networkIp: this.networkIp,
            networkFirstPort: this.networkFirstPort,
            networkLastPort: this.networkLastPort,
            interceptIp: this.interceptIp,
            endpointId: this.endpointId,
            networkNetmask: this.networkNetmask,
            gatewayIp: this.gatewayIp,
            gatewayNetmask: this.gatewayNetmask,
            gatewayCidrBlock: this.gatewayCidrBlock,
            protocolType: this.protocolType,
            bridgeStp: this.bridgeStp,
            collectionLocation: this.collectionLocation,
            cryptoLevel: this.cryptoLevel,
            dnsOptions: this.dnsOptions,
            icmpTunnel: this.icmpTunnel,
            localNetworkGateway: this.localNetworkGateway,
            multicast: this.multicast,
            pbrType: this.pbrType,
            permanentConnection: this.permanentConnection,
            rateSmoothing: this.rateSmoothing,
            transparency: this.transparency,
            bridgeIntf: this.bridgeIntf,
            bridgeAgeing: this.bridgeAgeing,
            bridgePrio: this.bridgePrio,
            bridgeFd: this.bridgeFd,
            bridgeHelloTime: this.bridgeHelloTime,
            bridgeMaxAge: this.bridgeMaxAge,
            bridgeCost: this.bridgeCost,
            protectionGroupId: this.protectionGroupId,
            gatewayClusterId: this.gatewayClusterId,
            o365Conflict: this.o365Conflict,
            portInterceptMode: this.portInterceptMode,
            interceptPorts: this.interceptPorts,
            vlanId: this.vlanId,
        };
    }

    public getServiceClasses() {
        return ['CS', 'GW', 'ICMP', 'L2G'];
    }

    public getProtocols() {
        return ['TCP', 'UDP', 'ALL'];
    }

    public getCollectionLocations() {
        return ['CLIENT', 'SERVER', 'BOTH'];
    }

    public getPbrTypes() {
        return ['WAN', 'MPLS', 'WAN_PROTECTED', 'MPLS_PROTECTED', 'ALL'];
    }

    public getCryptoLevels() {
        return ['NONE', 'BASIC', 'STRONG'];
    }

    convertToServiceAsACode(gatewayAsACode: any): any {
        return {
            name: this.name,
            bridgeAgeing: this.bridgeAgeing,
            bridgeCost: this.bridgeCost,
            bridgeFd: this.bridgeFd,
            bridgeHelloTime: this.bridgeHelloTime,
            bridgeIntf: this.bridgeIntf,
            bridgeMaxAge: this.bridgeMaxAge,
            bridgePrio: this.bridgePrio,
            bridgeStp: this.bridgeStp,
            collectionLocation: this.collectionLocation,
            cryptoLevel: this.cryptoLevel,
            dartServiceId: this.dartServiceId,
            dataInterleaving: this.dataInterleaving,
            dnsOptions: this.dnsOptions,
            gatewayCidrBlock: this.gatewayCidrBlock,
            gatewayClusterId: this.gatewayClusterId,
            gatewayNetmask: this.gatewayNetmask,
            icmpTunnel: this.icmpTunnel,
            interceptDnsHostname: this.interceptDnsHostname,
            interceptDnsPort: this.interceptDnsPort,
            interceptFirstPort: this.interceptFirstPort,
            interceptIp: this.interceptIp,
            interceptLastPort: this.interceptLastPort,
            localNetworkGateway: this.localNetworkGateway,
            lowLatency: this.lowLatency,
            multicast: this.multicast,
            netflowIndex: this.netflowIndex,
            networkFirstPort: this.networkFirstPort,
            networkIp: this.networkIp,
            networkLastPort: this.networkLastPort,
            networkNetmask: this.networkNetmask,
            o365Conflict: this.o365Conflict,
            pbrType: this.pbrType,
            permanentConnection: this.permanentConnection,
            portInterceptMode: this.portInterceptMode,
            profileIndex: this.profileIndex,
            protectionGroupId: this.protectionGroupId,
            protocol: this.protocol,
            protocolType: this.protocolType,
            rateSmoothing: this.rateSmoothing,
            selected: this.selected,
            serviceClass: this.serviceClass,
            serviceInterceptType: this.serviceInterceptType,
            serviceType: this.serviceType,
            snmpIndex: this.snmpIndex,
            transparency: this.transparency,
            vlanId: this.vlanId,
            endpoint: gatewayAsACode,
        };
    }
}
