import { Injectable } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';

// AppWan Editor Type for Tile Picker

const standardEditorTile = {
    type: 'StandardEditor',
    elementId: 'StandardEditorType',
    svg: 'BasicAppWAN.svg',
    title: 'Component Builder AppWAN',
    description: 'If you already have gateways, clients and services setup this is the choice for you.',
    buttonTitle: 'Create AppWan',
    showExtraLink: false,
};

const developmentEditorTile = {
    type: 'DevelopmentEditor',
    elementId: 'DevelopmentEditorType',
    svg: 'Developer.svg',
    title: 'Developer AppWAN',
    description: 'Manage your AWS or Azure Hosted Apps without the need for VPNs or Jump Servers.',
    buttonTitle: 'Create AppWan',
    showExtraLink: false,
};

const cloudEditorTile = {
    type: 'CloudEditor',
    elementId: 'CloudEditorType',
    svg: 'CloudHostedAppWAN.svg',
    title: 'SIMPLE CLOUD APPWAN',
    description:
        'Perfect for first-time or experienced users looking to set up an app in the cloud, V-CPE, or datacenter.',
    buttonTitle: 'Create AppWan',
    showExtraLink: false,
};

const migrationTile = {
    type: 'MigrationEditor',
    elementId: 'MigrationEditorType',
    svg: 'CloudMigrationAppWAN.svg',
    title: 'CLOUD MIGRATION APPWAN',
    description: 'Migrate your application to or between clouds, transmit your data from point A to point B.',
    buttonTitle: 'Create AppWan',
    showExtraLink: false,
};

const interconnectTile = {
    type: 'InterconnectEditor',
    elementId: 'InterconnectEditorType',
    svg: 'CloudInterconnectAppWAN.svg',
    title: 'CLOUD INTERCONNECT APPWAN',
    description: 'Enable bi-directional communication between two clouds, public or private.',
    buttonTitle: 'Create AppWan',
    showExtraLink: false,
};

const bulkUploadTile = {
    type: 'BulkUploadEditor',
    elementId: 'BulkUploadEditorType',
    svg: 'AsCode.svg',
    title: 'IMPORT YOUR APPWAN AS CODE',
    description: 'Prefer working in code? Simplify provisioning of your AppWAN, Services, and Endpoints.',
    buttonTitle: 'Create AppWan',
    showExtraLink: false,
};

const awsS3Tile = {
    type: 'S3Editor',
    elementId: 'S3EditorType',
    svg: 's3bucket.svg',
    title: 'Secure your AWS S3 Access',
    description:
        'Protect AWS Services and S3 Access by connecting securely via NetFoundry AWS Cloud Gateway to S3 and AWS Services',
    buttonTitle: 'Create AppWan',
    showExtraLink: false,
};

const l2Tile = {
    type: 'L2Editor',
    elementId: 'L2EditorType',
    svg: 'VoIPAppWAN.svg',
    title: 'Voice Enhanced appwan',
    description: 'Quickly set up your VoIP Voice Enhanced Network in a couple easy steps using this builder.',
    buttonTitle: 'Create AppWan',
    showExtraLink: false,
};

@Injectable({
    providedIn: 'root',
})
export class AppwanTileService {
    public standardEditorTileString = 'StandardEditor';

    public developmentEditorTileString = 'DevelopmentEditor';

    public cloudEditorTileString = 'CloudEditor';

    public migrationTileString = 'MigrationEditor';

    public interconnectTileString = 'InterconnectEditor';

    public bulkUploadTileString = 'BulkUploadEditor';

    public s3TileString = 'S3Editor';

    public l2TileString = 'L2Editor';

    constructor(private authorizationService: AuthorizationService) {}

    public getTiles() {
        const tiles = [];
        const canCreateServices = this.authorizationService.canCreateServices();
        const canListEndpoints = this.authorizationService.canListEndpoints();
        const canCreateEndpoints = this.authorizationService.canCreateEndpoints();
        const canListGeoRegions = this.authorizationService.canListGeoRegions();

        if (canCreateServices && (canListEndpoints || canCreateEndpoints) && canListGeoRegions) {
            tiles.push(cloudEditorTile);
            tiles.push(developmentEditorTile);
            tiles.push(migrationTile);
            tiles.push(interconnectTile);
            tiles.push(bulkUploadTile);
            tiles.push(awsS3Tile);
            tiles.push(l2Tile);
        }

        tiles.push(standardEditorTile);

        return tiles;
    }
}
