<div [ngClass]="{ open: isOpen }" class="tour">
    <div [ngClass]="realStyle" class="opener">
        <div class="openerBorder"></div>
        <div [ngClass]="realStyle" class="tourInfo">
            <div class="miniTitle">{{ title }}</div>
            <div (click)="close()" class="close icon-HamburgerAppClose" id="HeadTourCloseButton"></div>
            <div [innerHTML]="contents" class="contents"></div>
            <div class="buttons">
                <div (click)="goto()" *ngIf="tourLink !== ''" class="linkButton">Learn More</div>
                <div class="bubbles">
                    <div
                        *ngFor="let item of tourItems; let index = index"
                        [ngClass]="{ on: index === itemIndex }"
                        class="bubble"
                    ></div>
                </div>
                <button (click)="next()" *ngIf="itemIndex !== tourItems.length - 1" class="button" id="TourNextButton">
                    Next
                </button>
                <button
                    (click)="close()"
                    *ngIf="itemIndex === tourItems.length - 1"
                    class="button"
                    id="TourCloseButton"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
