import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { NetworkGroup } from '@netfoundry-ui/shared/model';
import { NetworkGroupService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-aws-autoscaling-form',
    templateUrl: './aws-autoscaling-form.component.html',
    styleUrls: ['./aws-autoscaling-form.component.scss'],
})
export class AwsAutoscalingFormComponent implements OnDestroy {
    networkGroup: NetworkGroup = new NetworkGroup({});

    processing = false;
    isLoading = false;

    private subscription: Subscription = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<AwsAutoscalingFormComponent>,
        private networkGroupService: NetworkGroupService,
        private growlerService: GrowlerService
    ) {
        if (this.data.model != null) {
            this.networkGroup = new NetworkGroup(this.data.model);
        }
    }

    cancel(updatedNetworkGroup?) {
        this.dialogRef.close({ networkGroup: updatedNetworkGroup });
    }

    save() {
        this.processing = true;
        this.isLoading = true;
        // Generate the token
        this.subscription.add(
            this.networkGroupService.save(this.networkGroup).subscribe(
                (result) => {
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Network Group Created',
                            'Network Group information has been saved'
                        )
                    );
                    this.processing = false;
                    this.isLoading = false;
                    this.cancel(result);
                },
                () => {
                    this.processing = false;
                    this.isLoading = false;
                }
            )
        );
    }

    toggleAwsAutoscaling() {
        if (!this.processing) {
            this.networkGroup.awsAutoScalingGatewayEnabled = !this.networkGroup.awsAutoScalingGatewayEnabled;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
