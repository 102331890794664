<div class="toggleButtons row">
    <div
        (click)="updateStart('1h')"
        [ngClass]="{ toggleButton: true, selected: currentTimeFilter === '1h' }"
        id="df-1h"
    >
        1h
    </div>
    <div
        (click)="updateStart('6h')"
        [ngClass]="{ toggleButton: true, selected: currentTimeFilter === '6h' }"
        id="df-6h"
    >
        6h
    </div>
    <div
        (click)="updateStart('24h')"
        [ngClass]="{ toggleButton: true, selected: currentTimeFilter === '24h' }"
        id="df-24h"
    >
        24h
    </div>
    <div
        (click)="updateStart('7d')"
        [ngClass]="{ toggleButton: true, selected: currentTimeFilter === '7d' }"
        id="df-7d"
    >
        7d
    </div>
    <div
        (click)="updateStart('30d')"
        [ngClass]="{ toggleButton: true, selected: currentTimeFilter === '30d' }"
        id="df-30d"
    >
        30d
    </div>
    <!-- <div id="df-1m" [ngClass]="{ toggleButton:true, selected: (currentTimeFilter=='1m') }" (click)="updateStart('1m')">1m</div> -->
    <!--  <div id="df-6m" [ngClass]="{ toggleButton:true, selected: (currentTimeFilter=='6m') }" (click)="updateStart('6m')">6m</div>-->
    <!--  <div id="df-12m" [ngClass]="{ toggleButton:true, selected: (currentTimeFilter=='12m') }" (click)="updateStart('12m')">All</div>-->
</div>
