import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttributesService, AttributesServiceResults, PostureCheckService } from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { ZitiOptionsService } from '@netfoundry-ui/shared/helpers';
import { NetworkV2, PagedAttributes, PostureCheck } from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, LoggerService, ValidateService } from '@netfoundry-ui/shared/services';
import * as isValidPath from 'is-valid-path';
import _ from 'lodash';
import semver from 'semver';

const windows = 'Windows';
const windowsServer = 'WindowsServer';
const linux = 'Linux';
const mac = 'macOS';
const android = 'Android';
const iOS = 'iOS';

const process = 'PROCESS';
const multiProcess = 'PROCESS_MULTI';
const domain = 'DOMAIN';
const os = 'OS';
const macAddress = 'MAC';
const mfa = 'MFA';

@Component({
    selector: 'app-posture-check-form',
    templateUrl: './posture-check-form.component.html',
    styleUrls: ['./posture-check-form.component.scss'],
})
export class PostureCheckFormComponent implements OnInit {
    isLoading = false;

    errorName = false;

    errorNameLength = false;

    errorPostureType = false;

    errorProcessPath = false;

    errorDomain = false;

    errorHash = false;

    errorMacAddress = false;

    currentNetwork: NetworkV2;

    windowsPathRegex = /^[a-zA-Z]:\\[\\\S|*\S]?.*$/;

    model: PostureCheck = new PostureCheck();

    expanded = [];

    isEditing = false;
    bulkEdit = false;

    processPath;
    domainString = '';
    domains = [];
    hash = null;
    signerCertificateThumbprint;
    signerFingerprints = [];
    processes = [];
    hashes;
    macAddressString = '';
    macAddresses = [];
    osType = '';
    multiHashString = '';
    multiHashes = [];
    multiSignerFingerprints = [];
    processOs = windows;
    hasTimeoutLimit = true;
    timeoutSeconds = '';
    promptOnWake = true;
    promptOnUnlock = true;
    ignoreLegacyEndpoints = true;
    timeoutHours = 0;
    timeoutMinutes = 0;
    attributeError = false;

    postureTypes = [
        {
            name: 'Domain Check',
            value: domain,
        },
        {
            name: 'MAC Address',
            value: macAddress,
        },
        {
            name: 'OS Check',
            value: os,
        },
    ];

    canEdit = false;
    invalidWords = ['all'];
    processing = false;

    windowsToggle = false;
    windowsMinVersion = '';
    windowsMaxVersion = '';
    errorWindowsMinVersion = false;
    errorWindowsMaxVersion = false;

    windowsServerToggle = false;
    windowsServerMinVersion = '';
    windowsServerMaxVersion = '';
    errorWindowsServerMinVersion = false;
    errorWindowsServerMaxVersion = false;

    macToggle = false;
    macMinVersion = '';
    macMaxVersion = '';
    errorMacMinVersion = false;
    errorMacMaxVersion = false;

    linuxToggle = false;
    linuxMinVersion = '';
    linuxMaxVersion = '';
    errorLinuxMinVersion = false;
    errorLinuxMaxVersion = false;

    androidToggle = false;
    androidMinVersion = '';
    androidMaxVersion = '';
    errorAndroidMinVersion = false;
    errorAndroidMaxVersion = false;

    iOSToggle = false;
    iOSMinVersion = '';
    iOSMaxVersion = '';
    erroriOSMinVersion = false;
    erroriOSMaxVersion = false;

    errorOsSelect = false;
    productVersion;
    isLoadingPostureCheckAttr: boolean;
    errors = {};

    pcError: boolean;
    selectedPostureCheckAttributes = new PagedAttributes();
    postureCheckAttributes = new PagedAttributes();

    constructor(
        private logger: LoggerService,
        private dialogRef: MatDialogRef<PostureCheckFormComponent>,
        public growlerService: GrowlerService,
        private apiService: ApiService,
        private validateService: ValidateService,
        private authorizationService: AuthorizationService,
        private postureCheckService: PostureCheckService,
        private attributeService: AttributesService,
        private optionsService: ZitiOptionsService,
        public featureService: FeatureService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    private initModel() {
        if (!this.model.type) {
            this.model.type = 'DOMAIN';
        }
        if (!this.model.attributes) {
            this.model.attributes = [];
        }

        if (!this.model) {
            _.set(this.model, 'model.data', _.get(this.model, 'model.data', {}));

            this.model.data.processes = [
                {
                    osType: 'Windows',

                    path: '',

                    hashes: [],

                    signerFingerprints: [],
                },
            ];

            this.expanded.push(true);

            this.multiHashes.push('');

            this.multiSignerFingerprints.push('');
        }
        if (this.data.model) {
            this.model = this.data.model;
            this.bulkEdit = this.data.bulkEdit;
            this.isEditing = !this.bulkEdit;
            if (!this.model.attributes) {
                this.model.attributes = [];
            }
            if (this.bulkEdit) {
                this.processEditModel(this.model);
            } else {
                this.postureCheckService.getPostureCheck(this.model.id).then((postureCheck: PostureCheck) => {
                    this.processEditModel(postureCheck);
                });
            }

            this.canEdit = this.authorizationService.canUpdatePostureCheck(this.model.id);
        }
        if (!this.model.data) {
            this.model.data = {};
        }

        if (!this.model.data.processes) {
            this.model.data.processes = [
                {
                    osType: 'Windows',

                    path: '',

                    hashes: [],

                    signerFingerprints: [],
                },
            ];

            this.expanded.push(true);

            this.multiHashes.push('');

            this.multiSignerFingerprints.push('');
        } else {
            for (const process of this.model.data.processes) {
                this.multiHashes.push(process.hashes.join(','));

                this.multiSignerFingerprints.push(process.signerFingerprints.join(','));

                this.expanded.push(false);
            }
        }
        if (this.bulkEdit) {
            this.validate();
        }

        if (this.model.type === 'MFA') {
            this.timeoutHours = Math.trunc(this.model.data.timeoutSeconds / 3600);
            this.timeoutMinutes = (this.model.data.timeoutSeconds % 3600) / 60;

            if (this.model.data.timeoutSeconds <= 0) {
                this.hasTimeoutLimit = false;
            }
            if (this.model.data.timeoutSeconds <= -1) {
                this.timeoutMinutes = 0;
            }

            if (this.model.data.promptOnUnlock === false) {
                this.promptOnUnlock = false;
            }

            if (this.model.data.promptOnWake === false) {
                this.promptOnWake = false;
            }

            if (this.model.data.ignoreLegacyEndpoints === false) {
                this.ignoreLegacyEndpoints = false;
            }
        }
    }

    processEditModel(postureCheck: PostureCheck) {
        switch (postureCheck.type) {
            case macAddress:
                this.processMacAddressModel(postureCheck);
                break;
            case os:
                this.processOsEditModel(postureCheck);
                break;
            case domain:
                this.domains = postureCheck.data['domains'];
                this.processDomains();
                break;
            case process:
                this.processOs = postureCheck.data.process['osType'];
                this.processPath = postureCheck.data.process['path'];
                if (postureCheck.data.process['hashes']) {
                    this.hash = postureCheck.data.process['hashes'][0];
                }
                this.signerCertificateThumbprint = postureCheck.data.process['signerFingerprint'];
                break;

            case multiProcess:
                this.processOs = postureCheck.data.process['osType'];
                this.processPath = postureCheck.data.process['path'];
                break;
        }
    }

    processMacAddressModel(postureCheck) {
        let index = 0;
        const last = postureCheck.data.macAddresses.length - 1;
        for (let macAddress of postureCheck.data.macAddresses) {
            if (!macAddress.includes(':') && !macAddress.includes('-')) {
                macAddress = macAddress.replace(/(.{2})/g, '$1:').slice(0, -1);
            }
            this.macAddressString += macAddress;
            if (index < last) {
                this.macAddressString += ';';
            }
            index++;
        }
    }

    processOsEditModel(postureCheck) {
        for (const operatingSystem of postureCheck.data.operatingSystems) {
            switch (operatingSystem.type) {
                case windows:
                    this.windowsToggle = true;

                    if (operatingSystem.versions && operatingSystem.versions.length > 0) {
                        const windowsVersions = operatingSystem.versions[0].split(' ');

                        this.windowsMinVersion = windowsVersions[0].replace('>', '').replace('=', '');
                        if (windowsVersions.length === 2) {
                            this.windowsMaxVersion = windowsVersions[1].replace('<', '').replace('=', '');
                        }
                    }

                    break;
                case windowsServer:
                    this.windowsServerToggle = true;

                    if (operatingSystem.versions && operatingSystem.versions.length > 0) {
                        const windowsServerVersions = operatingSystem.versions[0].split(' ');

                        this.windowsServerMinVersion = windowsServerVersions[0].replace('>', '').replace('=', '');
                        if (windowsServerVersions.length === 2) {
                            this.windowsServerMaxVersion = windowsServerVersions[1].replace('<', '').replace('=', '');
                        }
                    }

                    break;
                case mac:
                    this.macToggle = true;

                    if (operatingSystem.versions && operatingSystem.versions.length > 0) {
                        const macVersions = operatingSystem.versions[0].split(' ');

                        this.macMinVersion = macVersions[0].replace('>', '').replace('=', '');
                        if (macVersions.length === 2) {
                            this.macMaxVersion = macVersions[1].replace('<', '').replace('=', '');
                        }
                    }

                    break;
                case linux:
                    this.linuxToggle = true;

                    if (operatingSystem.versions && operatingSystem.versions.length > 0) {
                        const linuxVersions = operatingSystem.versions[0].split(' ');

                        this.linuxMinVersion = linuxVersions[0].replace('>', '').replace('=', '');
                        if (linuxVersions.length === 2) {
                            this.linuxMaxVersion = linuxVersions[1].replace('<', '').replace('=', '');
                        }
                    }

                    break;
                case iOS:
                    this.iOSToggle = true;

                    if (operatingSystem.versions && operatingSystem.versions.length > 0) {
                        const iOSVersions = operatingSystem.versions[0].split(' ');

                        this.iOSMinVersion = iOSVersions[0].replace('>', '').replace('=', '');
                        if (iOSVersions.length === 2) {
                            this.iOSMaxVersion = iOSVersions[1].replace('<', '').replace('=', '');
                        }
                    }

                    break;
                case android:
                    this.androidToggle = true;

                    if (operatingSystem.versions && operatingSystem.versions.length > 0) {
                        const androidVersions = operatingSystem.versions[0].split(' ');

                        this.androidMinVersion = androidVersions[0].replace('>', '').replace('=', '');
                        if (androidVersions.length === 2) {
                            this.androidMaxVersion = androidVersions[1].replace('<', '').replace('=', '');
                        }
                    }

                    break;
            }
        }
    }

    ngOnInit(): void {
        this.initModel();
        this.currentNetwork = this.apiService.currentNetwork.getValue();
        if (this.currentNetwork) {
            this.model.networkId = this.currentNetwork.id;

            const productVersion = this.currentNetwork.productVersion;

            if (semver.lt(productVersion, '7.3.33')) {
                this.postureTypes.push({
                    name: 'Process Check',

                    value: process,
                });
            } else {
                this.postureTypes.push({
                    name: 'Process Check',

                    value: multiProcess,
                });
            }
            if (semver.gt(productVersion, '7.3.42')) {
                this.postureTypes.push({
                    name: 'Multi Factor Authentication',
                    value: mfa,
                });
            }

            this.initializePostureCheckSelector();
        }
    }

    hide(response?) {
        this.dialogRef.close(response);
    }

    async save() {
        this.model.attributes = Array.from(this.selectedPostureCheckAttributes.mappedAtrributes.keys());
        if (await this.validate()) {
            this.processing = true;

            let data;

            if (this.model.type === process) {
                let hashes = null;
                if (this.validateService.hasValue(this.hash)) {
                    hashes = [this.hash];
                }
                const process = {
                    osType: this.processOs,
                    path: this.processPath,
                    hashes: hashes,
                    signerFingerprint: this.signerCertificateThumbprint,
                };
                data = {
                    process: process,
                };
            } else if (this.model.type === multiProcess) {
                for (let i = 0; i < this.model.data.processes.length; i++) {
                    this.model.data.processes[i].hashes = this.multiHashes[i].split(',');

                    this.model.data.processes[i].signerFingerprints = this.multiSignerFingerprints[i].split(',');
                }

                data = {
                    processes: this.model.data.processes,

                    semantic: 'AnyOf',
                };
            } else if (this.model.type === os) {
                const operatingSystems = this.processOsData();
                data = {
                    operatingSystems: operatingSystems,
                };
            } else if (this.model.type === domain) {
                data = {
                    domains: this.domains,
                };
            } else if (this.model.type === macAddress) {
                data = {
                    macAddresses: this.macAddresses,
                };
            }
            this.model.data = data;
            if (this.model.type === 'PROCESS_MULTI') {
                delete this.model.selected;
            } else if (this.model.type === mfa) {
                const timeoutSeconds = this.timeoutHours * 3600 + this.timeoutMinutes * 60;
                data = {
                    timeoutSeconds: timeoutSeconds,
                    promptOnWake: this.promptOnWake,
                    promptOnUnlock: this.promptOnUnlock,
                    ignoreLegacyEndpoints: this.ignoreLegacyEndpoints,
                };
                this.model.data = data;
            }

            if (this.bulkEdit) {
                this.dialogRef.close();
            } else if (this.model.id) {
                this.postureCheckService.patchResource(this.model).subscribe(
                    (data: PostureCheck) => {
                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Update Complete',
                                'Posture Check information has been updated.'
                            )
                        );
                        this.processing = false;
                        this.dialogRef.close({ updatedPostureCheck: data });
                    },
                    (httpErrorResponse) => {
                        this.processing = false;

                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Posture Check creation request failed. ',
                                httpErrorResponse.error.errors[0]
                            )
                        );
                    }
                );

                // update
            } else {
                this.postureCheckService.createResource({ body: this.model }).subscribe(
                    (data: PostureCheck) => {
                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Creation Complete',
                                'Posture Check information has been saved.'
                            )
                        );
                        this.processing = false;
                        this.dialogRef.close(data);
                    },
                    (httpErrorResponse) => {
                        this.processing = false;

                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Posture Check creation request failed. ',
                                httpErrorResponse.error.errors[0]
                            )
                        );
                    }
                );
            }
        }
    }

    processOsData() {
        const operatingSystems = [];

        if (this.windowsToggle) {
            const versions = [];

            if (this.windowsMinVersion) {
                let versionString = `>=${this.windowsMinVersion.trim()}`;

                if (this.windowsMaxVersion) {
                    versionString += ` <=${this.windowsMaxVersion.trim()}`;
                }

                versions.push(versionString);
            }

            operatingSystems.push({
                type: windows,
                versions: versions,
            });
        }

        if (this.windowsServerToggle) {
            const versions = [];

            if (this.windowsServerMinVersion) {
                let versionString = `>=${this.windowsServerMinVersion.trim()}`;

                if (this.windowsServerMaxVersion) {
                    versionString += ` <=${this.windowsServerMaxVersion.trim()}`;
                }

                versions.push(versionString);
            }

            operatingSystems.push({
                type: windowsServer,
                versions: versions,
            });
        }

        if (this.macToggle) {
            const versions = [];

            if (this.macMinVersion) {
                let versionString = `>=${this.macMinVersion.trim()}`;

                if (this.macMaxVersion) {
                    versionString += ` <=${this.macMaxVersion.trim()}`;
                }

                versions.push(versionString);
            }

            operatingSystems.push({
                type: mac,
                versions: versions,
            });
        }

        if (this.linuxToggle) {
            const versions = [];

            if (this.linuxMinVersion) {
                let versionString = `>=${this.linuxMinVersion.trim()}`;

                if (this.linuxMaxVersion) {
                    versionString += ` <=${this.linuxMaxVersion.trim()}`;
                }

                versions.push(versionString);
            }

            operatingSystems.push({
                type: linux,
                versions: versions,
            });
        }

        if (this.androidToggle) {
            const versions = [];

            if (this.androidMinVersion) {
                let versionString = `>=${this.androidMinVersion.trim()}`;

                if (this.androidMaxVersion) {
                    versionString += ` <=${this.androidMaxVersion.trim()}`;
                }

                versions.push(versionString);
            }

            operatingSystems.push({
                type: android,
                versions: versions,
            });
        }

        if (this.iOSToggle) {
            const versions = [];

            if (this.iOSMinVersion) {
                let versionString = `>=${this.iOSMinVersion.trim()}`;

                if (this.iOSMaxVersion) {
                    versionString += ` <=${this.iOSMaxVersion.trim()}`;
                }

                versions.push(versionString);
            }

            operatingSystems.push({
                type: iOS,
                versions: versions,
            });
        }

        return operatingSystems;
    }

    async validate() {
        this.errorName = false;
        this.errorNameLength = false;
        this.errorPostureType = false;
        this.errorProcessPath = false;
        this.errorOsSelect = false;
        this.errorDomain = false;
        this.errorHash = false;
        this.errorMacAddress = false;
        this.errors = {};
        this.attributeError = false;

        let isValid = true;

        if (!this.model.id) {
            const errors = await this.postureCheckService.validateCreate(this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.attributes')) {
                this.attributeError = true;
                this.errors['attributes'] = _.find(errors, (e) => e.path === '$.attributes').message;
            }
        } else {
            const errors = await this.postureCheckService.validateUpdate(this.model.id, this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.attributes')) {
                this.attributeError = true;
                this.errors['attributes'] = _.find(errors, (e) => e.path === '$.attributes').message;
            }
        }

        if (!this.validateService.hasValue(this.model.type)) {
            this.errorPostureType = true;
            isValid = false;
        } else {
            if (this.model.type === process) {
                // TODO validate the path is in correct form
                if (
                    !this.validateService.hasValue(this.processPath) ||
                    (!isValidPath(this.processPath) && !this.processPath.match(this.windowsPathRegex))
                ) {
                    this.errorProcessPath = true;
                    isValid = false;
                }
                if (this.validateService.hasValue(this.hash) && !this.validateService.isHex(this.hash)) {
                    this.errorHash = true;
                    isValid = false;
                }
            } else if (this.model.type === multiProcess) {
                for (let i = 0; i < this.model.data.processes.length; i++) {
                    if (!this.validateService.hasValue(this.model.data.processes[i].path)) {
                        this.errorProcessPath = true;

                        isValid = false;
                    }
                }
            } else if (this.model.type === os) {
                if (
                    !this.windowsToggle &&
                    !this.windowsServerToggle &&
                    !this.macToggle &&
                    !this.linuxToggle &&
                    !this.androidToggle &&
                    !this.iOSToggle
                ) {
                    this.errorOsSelect = true;
                    isValid = false;
                } else {
                    isValid = this.validateOSPostureCheck();
                }
            } else if (this.model.type === domain) {
                isValid = this.validateDomainPostureCheck() && isValid;
            } else if (this.model.type === macAddress) {
                isValid = this.validateMacAddressPostureCheck() && isValid;
            }
        }
        return isValid;
    }

    validateOSPostureCheck() {
        this.errorWindowsMinVersion = false;
        this.errorWindowsMaxVersion = false;

        this.errorWindowsServerMinVersion = false;
        this.errorWindowsServerMaxVersion = false;

        this.errorMacMinVersion = false;
        this.errorMacMaxVersion = false;

        this.errorLinuxMinVersion = false;
        this.errorLinuxMaxVersion = false;

        this.errorAndroidMinVersion = false;
        this.errorAndroidMaxVersion = false;

        this.erroriOSMinVersion = false;
        this.erroriOSMaxVersion = false;

        if (this.windowsToggle) {
            if (this.windowsMinVersion) {
                this.errorWindowsMinVersion =
                    !this.validateService.isValidVersion(this.windowsMinVersion) ||
                    !this.validateService.isValidSemver(this.windowsMinVersion);

                if (this.validateService.hasValue(this.windowsMaxVersion)) {
                    this.errorWindowsMaxVersion =
                        !this.validateService.isValidVersion(this.windowsMaxVersion) ||
                        !this.validateService.isValidSemver(this.windowsMaxVersion) ||
                        !this.validateService.isVersionLessThan(this.windowsMinVersion, this.windowsMaxVersion);
                }
            } else if (this.windowsMaxVersion) {
                this.errorWindowsMaxVersion = true;
            }
        }

        if (this.windowsServerToggle) {
            if (this.windowsServerMinVersion) {
                this.errorWindowsServerMinVersion =
                    !this.validateService.isValidVersion(this.windowsServerMinVersion) ||
                    !this.validateService.isValidSemver(this.windowsServerMinVersion);

                if (this.validateService.hasValue(this.windowsServerMaxVersion)) {
                    this.errorWindowsServerMaxVersion =
                        !this.validateService.isValidVersion(this.windowsServerMaxVersion) ||
                        !this.validateService.isValidSemver(this.windowsServerMaxVersion) ||
                        !this.validateService.isVersionLessThan(
                            this.windowsServerMinVersion,
                            this.windowsServerMaxVersion
                        );
                }
            } else if (this.windowsServerMaxVersion) {
                this.errorWindowsServerMaxVersion = true;
            }
        }

        if (this.macToggle) {
            if (this.macMinVersion) {
                this.errorMacMinVersion =
                    !this.validateService.isValidVersion(this.macMinVersion) ||
                    !this.validateService.isValidSemver(this.macMinVersion);

                if (this.validateService.hasValue(this.macMaxVersion)) {
                    this.errorMacMaxVersion =
                        !this.validateService.isValidVersion(this.macMaxVersion) ||
                        !this.validateService.isValidSemver(this.macMaxVersion) ||
                        !this.validateService.isVersionLessThan(this.macMinVersion, this.macMaxVersion);
                }
            } else if (this.macMaxVersion) {
                this.errorMacMaxVersion = true;
            }
        }

        if (this.linuxToggle) {
            if (this.linuxMinVersion) {
                this.errorLinuxMinVersion =
                    !this.validateService.isValidVersion(this.linuxMinVersion) ||
                    !this.validateService.isValidSemver(this.linuxMinVersion);

                if (this.validateService.hasValue(this.linuxMaxVersion)) {
                    this.errorLinuxMaxVersion =
                        !this.validateService.isValidVersion(this.linuxMaxVersion) ||
                        !this.validateService.isValidSemver(this.linuxMaxVersion) ||
                        !this.validateService.isVersionLessThan(this.linuxMinVersion, this.linuxMaxVersion);
                }
            } else if (this.linuxMaxVersion) {
                this.errorLinuxMaxVersion = true;
            }
        }

        if (this.androidToggle) {
            if (this.androidMinVersion) {
                this.errorAndroidMinVersion =
                    !this.validateService.isValidVersion(this.androidMinVersion) ||
                    !this.validateService.isValidSemver(this.androidMinVersion);

                if (this.validateService.hasValue(this.androidMaxVersion)) {
                    this.errorAndroidMaxVersion =
                        !this.validateService.isValidVersion(this.androidMaxVersion) ||
                        !this.validateService.isValidSemver(this.androidMaxVersion) ||
                        !this.validateService.isVersionLessThan(this.androidMinVersion, this.androidMaxVersion);
                }
            } else if (this.androidMaxVersion) {
                this.errorAndroidMaxVersion = true;
            }
        }

        if (this.iOSToggle) {
            if (this.iOSMinVersion) {
                this.erroriOSMinVersion =
                    !this.validateService.isValidVersion(this.iOSMinVersion) ||
                    !this.validateService.isValidSemver(this.iOSMinVersion);

                if (this.validateService.hasValue(this.iOSMaxVersion)) {
                    this.erroriOSMaxVersion =
                        !this.validateService.isValidVersion(this.iOSMaxVersion) ||
                        !this.validateService.isValidSemver(this.iOSMaxVersion) ||
                        !this.validateService.isVersionLessThan(this.iOSMinVersion, this.iOSMaxVersion);
                }
            } else if (this.iOSMaxVersion) {
                this.erroriOSMaxVersion = true;
            }
        }

        return !(
            this.errorWindowsMinVersion ||
            this.errorWindowsMaxVersion ||
            this.errorWindowsServerMinVersion ||
            this.errorWindowsServerMaxVersion ||
            this.errorMacMinVersion ||
            this.errorMacMaxVersion ||
            this.errorLinuxMinVersion ||
            this.errorLinuxMaxVersion ||
            this.errorAndroidMinVersion ||
            this.errorAndroidMaxVersion ||
            this.erroriOSMinVersion ||
            this.erroriOSMaxVersion
        );
    }

    validateMacAddressPostureCheck() {
        let isValid = true;

        if (this.validateService.hasValue(this.macAddressString)) {
            const tmpMacAddresses = this.macAddressString.trim().split(';');
            this.macAddresses = [];

            let index = 0;
            const last = tmpMacAddresses.length - 1;
            for (let address of tmpMacAddresses) {
                address = address.trim();
                if (index < last || this.validateService.hasValue(address)) {
                    if (this.validateService.isValidMacAddress(address)) {
                        this.macAddresses.push(address);
                    } else {
                        this.errorMacAddress = true;
                        isValid = false;
                        break;
                    }
                }
                index++;
            }
        } else {
            this.errorMacAddress = true;
            isValid = false;
        }
        return isValid;
    }

    validateDomainPostureCheck() {
        let isValid = true;

        if (this.validateService.hasValue(this.domainString)) {
            const tmpDomains = this.domainString.trim().split(';');
            this.domains = [];

            let index = 0;
            const last = tmpDomains.length - 1;
            for (let currentDomain of tmpDomains) {
                currentDomain = currentDomain.trim();
                if (index < last || this.validateService.hasValue(currentDomain)) {
                    if (this.validateService.isValidWindowsDomain(currentDomain)) {
                        this.domains.push(currentDomain);
                    } else {
                        this.errorDomain = true;
                        isValid = false;
                        break;
                    }
                }
                index++;
            }
        } else {
            this.errorDomain = true;
            isValid = false;
        }

        return isValid;
    }

    selectOs(os) {
        this.processOs = os;
    }

    expandProcessCheck(i) {
        this.expanded[i] = !this.expanded[i];
    }

    selectMultiProcessOS(process, os) {
        process.osType = os;
    }

    addAnotherProcessCheck() {
        this.model.data.processes.push({
            osType: 'Windows',
            path: '',

            hashes: '',

            signerFingerprints: '',
        });

        for (let i = 0; i < this.expanded.length; i++) {
            this.expanded[i] = false;
        }

        this.expanded.push(true);

        this.multiHashes.push('');

        this.multiSignerFingerprints.push('');
    }

    deleteProcessCheck(index) {
        if (this.model.data.processes.length > 1) {
            this.model.data.processes.splice(index, 1);
        }

        this.expanded.splice(index, 1);

        this.multiHashes.splice(index, 1);

        this.multiSignerFingerprints.splice(index, 1);
    }

    toggleWindows() {
        this.windowsToggle = !this.windowsToggle;

        if (!this.windowsToggle) {
            this.errorWindowsMinVersion = false;
            this.errorWindowsMaxVersion = false;

            this.windowsMinVersion = '';
            this.windowsMaxVersion = '';
        }
    }

    toggleWindowsServer() {
        this.windowsServerToggle = !this.windowsServerToggle;

        if (!this.windowsServerToggle) {
            this.errorWindowsServerMinVersion = false;
            this.errorWindowsServerMaxVersion = false;

            this.windowsServerMinVersion = '';
            this.windowsServerMaxVersion = '';
        }
    }

    toggleMac() {
        this.macToggle = !this.macToggle;

        if (!this.macToggle) {
            this.errorMacMinVersion = false;
            this.errorMacMaxVersion = false;

            this.macMinVersion = '';
            this.macMaxVersion = '';
        }
    }

    toggleLinux() {
        this.linuxToggle = !this.linuxToggle;

        if (!this.linuxToggle) {
            this.errorLinuxMinVersion = false;
            this.errorLinuxMaxVersion = false;

            this.linuxMinVersion = '';
            this.linuxMaxVersion = '';
        }
    }

    toggleAndroid() {
        this.androidToggle = !this.androidToggle;

        if (!this.androidToggle) {
            this.errorAndroidMinVersion = false;
            this.errorAndroidMaxVersion = false;

            this.androidMinVersion = '';
            this.androidMaxVersion = '';
        }
    }

    toggleiOS() {
        this.iOSToggle = !this.iOSToggle;

        if (!this.iOSToggle) {
            this.erroriOSMinVersion = false;
            this.erroriOSMaxVersion = false;

            this.iOSMinVersion = '';
            this.iOSMaxVersion = '';
        }
    }

    toggleWake() {
        this.promptOnWake = !this.promptOnWake;
    }

    toggleUnlock() {
        this.promptOnUnlock = !this.promptOnUnlock;
    }

    toggleDuration() {
        this.hasTimeoutLimit = !this.hasTimeoutLimit;
        if (!this.hasTimeoutLimit) {
            this.timeoutHours = 0;
            this.timeoutMinutes = 0;
        }
    }

    toggleLegacyEndpoints() {
        this.ignoreLegacyEndpoints = !this.ignoreLegacyEndpoints;
    }

    getSelectedPostureChecks() {
        const tmpCollectorMap1 = new PagedAttributes();
        this.model.attributes.forEach((attribute) => {
            tmpCollectorMap1.mappedAtrributes.set(attribute, { name: attribute, isGroup: true });
        });
        this.selectedPostureCheckAttributes = tmpCollectorMap1;
    }

    addPostureCheckAttribute(newAttribute) {
        const success = this.attributeService.addAttributes(newAttribute, [], this.selectedPostureCheckAttributes);

        if (success) this.selectedPostureCheckAttributes = success as PagedAttributes;
        else this.attributeError = true;
        this.model.attributes = Array.from(this.selectedPostureCheckAttributes.mappedAtrributes.keys());
        this.validate();
    }

    removePostureCheckAttribute(oldAttribute) {
        this.selectedPostureCheckAttributes.mappedAtrributes.delete(oldAttribute);
        const tmpSelected = new PagedAttributes();
        tmpSelected.mappedAtrributes = this.selectedPostureCheckAttributes.mappedAtrributes;
        this.selectedPostureCheckAttributes = tmpSelected;
        this.model.attributes = Array.from(this.selectedPostureCheckAttributes.mappedAtrributes.keys());
        this.validate();
    }

    private processDomains(): void {
        let index = 0;
        const last = this.domains.length - 1;
        for (const domain of this.domains) {
            this.domainString += domain;
            if (index < last) {
                this.domainString += ';';
            }
            index++;
        }
    }

    private initializePostureCheckSelector() {
        this.getSelectedPostureChecks();
        this.isLoadingPostureCheckAttr = true;
        this.pcError = false;
        this.attributeService
            .getPostureCheckAttributes(this.currentNetwork, true)
            .then((results: AttributesServiceResults) => {
                this.postureCheckAttributes = results.groupAttributes;
            })
            .catch((err) => {
                this.pcError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingPostureCheckAttr = false;
            });
    }
}
