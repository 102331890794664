<div [hidden]="!isLoading" id="ContainLoader">
    <!--  <div class="grid-row">-->
    <!--    <div class="col">-->

    <div class="container-loader">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
        <div class="bar4"></div>
        <div class="bar5"></div>
        <div class="bar6"></div>
    </div>

    <!--    </div>-->
    <!--  </div>-->
</div>
