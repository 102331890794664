<div class="modal solid awsAutoScalingModal open" id="awsAutoScalingModal">
    <div (click)="cancel()" class="close cancel" id="CancelXButtonAwsAutoScalingModal"></div>
    <div class="autoScalingTitle">AWS Auto Scaling Gateways</div>
    <div>
        <div class="label row darkGridRow">Auto Scaling Gateways<span class="note">Tap to Enable</span></div>
        <div class="row darkGridRow">
            <div class="darkGrid">
                <div class="title">
                    <div class="icon-aws-autoscaling"></div>
                </div>
                <div class="subtitle">
                    ENABLE AWS AUTO SCALING<br />
                    FOR THIS NETWORK GROUP
                </div>
                <div>
                    <div class="switchGrid">
                        <div>
                            <div
                                (click)="toggleAwsAutoscaling()"
                                [ngClass]="{ on: networkGroup.awsAutoScalingGatewayEnabled }"
                                class="toggle"
                                id="awsAutoScalingToggleButton"
                            >
                                <span [hidden]="!networkGroup.awsAutoScalingGatewayEnabled">ON&nbsp;&nbsp;&nbsp;</span>
                                <span [hidden]="networkGroup.awsAutoScalingGatewayEnabled">&nbsp;&nbsp;&nbsp;OFF</span>
                                <div class="switch"></div>
                            </div>
                        </div>
                        <div id="awsAutoScalingToggleStatus">
                            <span [hidden]="!networkGroup.awsAutoScalingGatewayEnabled">Enabled</span>
                            <span [hidden]="networkGroup.awsAutoScalingGatewayEnabled">Disabled</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--<div class ="row autoScalingNote">
  <span>Learn more about AWS Auto Scaling Gateways and their ideal use cases</span>
</div>-->
    <div class="buttons">
        <button (click)="save()" *ngIf="!processing" class="save" id="SaveAwsAutoScalingModal">Save</button>
        <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
    </div>
</div>
