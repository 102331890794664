import { MopResource } from './mopresource';

export class AzureSubscription extends MopResource {
    public subscriptionId: string;
    public tenantId: string;
    public applicationId: string;
    public applicationKey: string;
    public organizationId;
    public _links: {
        self: {
            href: string;
        };
    };

    constructor(object) {
        super(object);

        this.organizationId = this.getLinkedResourceId('organization');
    }

    public getSaveObject() {
        return {
            name: this.name,
            subscriptionId: this.subscriptionId,
            tenantId: this.tenantId,
            applicationId: this.applicationId,
            applicationKey: this.applicationKey,
            organizationId: this.organizationId,
        };
    }
}
