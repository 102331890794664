import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-table-cell-session',
    templateUrl: './table-cell-session.component.html',
    styleUrls: ['./table-cell-session.component.scss'],
})
export class TableCellSessionComponent implements ICellRendererAngularComp {
    params;
    item = {
        id: '',
        name: '',
        sessionIdentityId: undefined,
        sessionActive: undefined,
    };

    agInit(params: ICellRendererParams): void {
        this.item = params.node.data;
    }

    refresh(params: ICellRendererParams): boolean {
        this.item = params.data;
        return true;
    }
}
