import { Injectable } from '@angular/core';
import { Endpoint } from '@netfoundry-ui/shared/model';
import { take } from 'rxjs/operators';
import { ClientService } from './client.service';
import { GatewayService } from './gateway.service';

@Injectable({
    providedIn: 'root',
})
export class AlarmMappingService {
    constructor(public gatewayService: GatewayService, public clientService: ClientService) {}

    //  VTC id to endpoint name mapping in alarms panel
    public vtcToMopNameMapping(alarms: any) {
        const alarmList = alarms;
        const endpoints: Endpoint[] = [];

        if (alarmList) {
            // gateways
            const gatewayPromise = this.gatewayService
                .get()
                .pipe(take(1))
                .toPromise()
                .then((result) => {
                    for (const gateway of result) {
                        endpoints.push(new Endpoint(gateway));
                    }
                });

            // clients
            const clientPromise = this.clientService
                .get()
                .pipe(take(1))
                .toPromise()
                .then((result) => {
                    for (const gateway of result) {
                        endpoints.push(new Endpoint(gateway));
                    }
                });

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            Promise.all([gatewayPromise, clientPromise]).then((allendpoints) => {
                for (const endpoint of endpoints) {
                    for (const alarm of alarmList) {
                        if (alarm.vtc_id !== '' && alarm.vtc_id === endpoint.componentId) {
                            alarm['endpoint_name'] = endpoint.name;
                        }
                    }
                }
            });
            return alarmList;
        }
    }
}
