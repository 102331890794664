import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppWanMigrationWizardComponent } from '@netfoundry-ui/feature/form/app-wan-migration-wizard';
import { AppWan, Service } from '@netfoundry-ui/shared/model';
import { ValidateService } from '@netfoundry-ui/shared/services';

const skipString = 'skipAppWanInterconnectIntro';

@Component({
    selector: 'app-app-wan-interconnect-wizard',
    templateUrl: './app-wan-interconnect-wizard.component.html',
    styleUrls: ['./app-wan-interconnect-wizard.component.scss'],
})
export class AppWanInterconnectWizardComponent {
    // child Feature of the wizard
    @ViewChild('gatewaySelect') gatewaySelect;
    @ViewChild('serviceSelectCloudOne') serviceSelectCloudOne;
    @ViewChild('serviceSelectCloudTwo') serviceSelectCloudTwo;
    @ViewChild('saveComponentCloudOne') saveComponentCloudOne;
    @ViewChild('saveComponentCloudTwo') saveComponentCloudTwo;

    step = 1;

    sourceGatewayType;
    targetGatewayType;

    sourceGateway;
    targetGateway;

    // the highest step the user has been to. used for navigating via the step bubbles
    highestStep = 2;
    skip = false;
    form = {
        appWanName: {
            error: false,
            placeholder: 'Enter an AppWAN Name',
            label: 'AppWAN Name',
            value: '',
        },
    };

    servicesCloudOne: Service[] = [];
    ipNetworkServicesCloudOne: Service[] = [];
    ipHostServicesCloudOne: Service[] = [];

    servicesCloudTwo: Service[] = [];
    ipNetworkServicesCloudTwo: Service[] = [];
    ipHostServicesCloudTwo: Service[] = [];

    modelCloudOne: AppWan = new AppWan({});
    modelCloudTwo: AppWan = new AppWan({});
    finalModelCloudOne: AppWan = new AppWan({});
    finalModelCloudTwo: AppWan = new AppWan({});

    hasStepped = false;

    constructor(
        private dialogRef: MatDialogRef<AppWanMigrationWizardComponent>,
        public validateService: ValidateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // checking if the user has the skip intro option in local storage
        const skipIntro = localStorage.getItem(skipString);

        // if they do, skip the intro
        if (skipIntro === 'true') {
            this.skip = true;
            this.step = 2;
        } else {
            this.skip = false;
        }
    }

    // function for moving to a specific step
    goTo(stepNumber) {
        // if the user has already been at the step selected, go to the step
        if (stepNumber <= this.highestStep) {
            this.step = stepNumber;
        }
    }

    next() {
        this.hasStepped = true;
        switch (this.step) {
            case 2:
                if (this.validateAppwanName()) {
                    this.modelCloudOne.name = this.form.appWanName.value;
                    this.modelCloudTwo.name = this.form.appWanName.value;
                    this.step++;
                    this.highestStep++;
                }
                break;

            case 3:
                if (this.gatewaySelect.validateSourceGateway()) {
                    this.sourceGateway = this.gatewaySelect.getSourceGateway();
                    this.sourceGatewayType = this.sourceGateway.endpointType;
                    this.step++;
                    this.highestStep++;
                }
                break;

            case 4:
                if (this.gatewaySelect.validateTargetGateway()) {
                    this.targetGateway = this.gatewaySelect.getTargetGateway();
                    this.targetGatewayType = this.targetGateway.endpointType;
                    this.step++;
                    this.highestStep++;
                }
                break;

            case 5:
                if (this.serviceSelectCloudOne.validateServices()) {
                    this.ipHostServicesCloudOne = this.serviceSelectCloudOne.getIpHostServices();
                    this.ipNetworkServicesCloudOne = this.serviceSelectCloudOne.getIpNetworkServices();
                    this.servicesCloudOne = this.serviceSelectCloudOne.getServices();
                    this.step++;
                }
                break;

            case 6:
                if (this.serviceSelectCloudTwo.validateServices()) {
                    this.step++;
                    this.ipHostServicesCloudTwo = this.serviceSelectCloudTwo.getIpHostServices();
                    this.ipNetworkServicesCloudTwo = this.serviceSelectCloudTwo.getIpNetworkServices();
                    this.servicesCloudTwo = this.serviceSelectCloudTwo.getServices();
                    this.createCloudOne();
                }
                break;

            default:
                this.step++;
        }
    }

    back() {
        this.step--;
    }

    isNotIntroSummary() {
        return this.step > 1 && this.step < 7;
    }

    isPassedStep(index) {
        return this.step > index;
    }

    isNotAtStep(index) {
        return this.step < index;
    }

    toggleSkip() {
        this.skip = !this.skip;
        if (this.skip) {
            localStorage.setItem(skipString, 'true');
        } else {
            localStorage.removeItem(skipString);
        }
    }

    close() {
        this.dialogRef.close();
    }

    isBetweenSteps(start, end) {
        return this.step > start && this.step < end;
    }

    async createCloudOne() {
        this.modelCloudOne.name += '-Cloud1';
        this.finalModelCloudOne = await this.saveComponentCloudOne.save();
        if (this.finalModelCloudOne != null) {
            this.sourceGateway = this.finalModelCloudOne['hostingGateway'];
            this.targetGateway = this.finalModelCloudOne['targetGateway'];
            this.step++;
            this.createCloudTwo();
        }
    }

    async createCloudTwo() {
        this.modelCloudTwo.name += '-Cloud2';
        this.finalModelCloudTwo = await this.saveComponentCloudTwo.save();
        if (this.finalModelCloudTwo != null) {
            this.step++;
        }
    }

    clone() {
        // makging the previous appwan name the placeholder
        this.form.appWanName.placeholder = this.form.appWanName.value;

        // resetting the appwan name
        this.form.appWanName.value = '';

        // creating a new appwan model
        this.modelCloudOne = new AppWan({});
        this.modelCloudTwo = new AppWan({});

        // clearing the list of services
        this.servicesCloudOne = [];
        this.servicesCloudTwo = [];

        // clearing the gateway list map
        this.gatewaySelect.clearGatewayListMap();

        // preping the service select component for cloning
        this.serviceSelectCloudOne.clone();
        this.serviceSelectCloudTwo.clone();

        // resetting the save component
        this.saveComponentCloudOne.reset();
        this.saveComponentCloudTwo.reset();

        // going back to step 2
        this.step = 2;
    }

    validateAppwanName() {
        const isValid =
            this.validateService.hasValue(this.form.appWanName.value) &&
            this.validateService.isValidName(this.form.appWanName.value) &&
            this.form.appWanName.value.length < 53;
        this.form.appWanName.error = !isValid;
        return isValid;
    }
}
