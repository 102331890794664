import { Component, Input, OnInit } from '@angular/core';
import { Service } from '@netfoundry-ui/shared/model';
import { LoggerService, ValidateService, ZitiEnabledService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-appwan-wizard-service-select',
    templateUrl: './appwan-wizard-service-select.component.html',
    styleUrls: ['./appwan-wizard-service-select.component.scss'],
})
export class AppwanWizardServiceSelectComponent implements OnInit {
    @Input() sourceGatewayType;
    @Input() targetGatewayType;
    @Input() isInterconnect;
    @Input() step;

    form = {
        ipHostServices: {
            errors: [],
            port: {
                error: false,
                placeholder: '22',
                label: 'Port/Range',
            },
            ip: {
                error: false,
                placeholder: '10.0.1.20',
                label: 'IP Address',
            },
            protocol: {
                error: false,
                placeholder: 'Select',
                label: 'Protocol',
            },
        },
        networkServices: {
            errors: [],
            ip: {
                error: false,
                placeholder: '10.0.0.0/24',
                label: 'Network Address',
            },
            intercept: {
                error: false,
                placeholder: '10.0.0.0',
                label: 'Intercept Address',
            },
        },
    };

    services: Service[] = [];
    ipNetworkServices: Service[] = [];
    ipHostServices: Service[] = [];

    constructor(
        private logger: LoggerService,
        private validateService: ValidateService,
        public zitiEnabledService: ZitiEnabledService
    ) {}

    ngOnInit() {
        // inject an empty service row into the form
        this.addHostServiceFields();
        this.addNetworkServiceFields();
    }

    removeHostService(index) {
        this.ipHostServices.splice(index, 1);
    }

    removeNetworkService(index) {
        this.ipNetworkServices.splice(index, 1);
    }

    addHostServiceFields() {
        const newService = new Service({
            bridgeStp: 'YES',
            collectionLocation: 'BOTH',
            cryptoLevel: 'STRONG',
            dnsOptions: 'NONE',
            icmpTunnel: 'NO',
            localNetworkGateway: 'YES',
            multicast: 'OFF',
            pbrType: 'WAN',
            permanentConnection: 'NO',
            rateSmoothing: 'NO',
            serviceInterceptType: 'IP',
            serviceType: '',
        });
        this.form.ipHostServices.errors[this.form.ipHostServices.errors.length] = '';
        newService.serviceClass = 'CS';
        this.ipHostServices[this.ipHostServices.length] = newService;
    }

    addNetworkServiceFields() {
        const newService = new Service({
            bridgeStp: 'YES',
            collectionLocation: 'BOTH',
            cryptoLevel: 'STRONG',
            dnsOptions: 'NONE',
            icmpTunnel: 'NO',
            localNetworkGateway: 'YES',
            multicast: 'OFF',
            pbrType: 'WAN',
            permanentConnection: 'NO',
            rateSmoothing: 'NO',
            serviceInterceptType: 'IP',
        });
        this.form.networkServices.errors[this.form.networkServices.errors.length] = '';
        newService.serviceClass = 'GW';
        newService.serviceType = 'ALL';
        this.ipNetworkServices[this.ipNetworkServices.length] = newService;
    }

    /**
     * Validate step 3 of the form
     */
    validateServices() {
        let isValid = true;

        let emptyHostService = false;
        let emptyNetworkService = false;

        if (this.ipHostServices.length === 1) {
            emptyHostService = this.isEmptyHostService(this.ipHostServices[0]);
        }

        if (this.ipNetworkServices.length === 1) {
            emptyNetworkService = this.isEmptyNetworkService(this.ipNetworkServices[0]);
        }

        this.form.networkServices.ip.error = false;
        this.form.networkServices.intercept.error = false;
        this.form.networkServices.errors = [];

        this.form.ipHostServices.ip.error = false;
        this.form.ipHostServices.port.error = false;
        this.form.ipHostServices.protocol.error = false;
        this.form.ipHostServices.errors = [];

        if (!emptyHostService || (emptyHostService && emptyNetworkService)) {
            isValid = this.validateHostServices() && isValid;
        }

        if (!emptyNetworkService || (emptyHostService && emptyNetworkService)) {
            isValid = this.validateNetworkServices() && isValid;
        }

        if (isValid) {
            if (!emptyHostService && !emptyNetworkService) {
                this.services = this.ipHostServices.concat(this.ipNetworkServices);
            } else if (emptyHostService) {
                this.services = this.ipNetworkServices.concat([]);
            } else {
                this.services = this.ipHostServices.concat([]);
            }
        }
        return isValid;
    }

    isEmptyHostService(service) {
        return (
            !this.validateService.hasValue(service.networkFirstPort) &&
            !this.validateService.hasValue(service.serviceType) &&
            !this.validateService.hasValue(service.interceptIp)
        );
    }

    isEmptyNetworkService(service) {
        return !this.validateService.hasValue(service.gatewayIp) && !this.validateService.hasValue(service.interceptIp);
    }

    validateHostServices() {
        let isValid = true;
        let index = 0;

        for (const service of this.ipHostServices) {
            this.logger.info('Validating services', service);
            this.form.ipHostServices.errors[index] = '';

            if (!this.validateService.isValidIP(service.networkIp)) {
                this.form.ipHostServices.ip.error = true;
                this.form.ipHostServices.errors[index] += 'IP';
                isValid = false;
            }

            if (!this.validateService.isValidPortOrPortRange(service.networkFirstPort)) {
                this.form.ipHostServices.port.error = true;
                this.form.ipHostServices.errors[index] += 'Port';
                isValid = false;
            }

            if (!this.validateService.hasValue(service.serviceType)) {
                this.form.ipHostServices.protocol.error = true;
                this.form.ipHostServices.errors[index] += 'Protocol';
                isValid = false;
            }
            index++;
        }
        return isValid;
    }

    validateNetworkServices() {
        let isValid = true;
        let index = 0;

        for (const service of this.ipNetworkServices) {
            this.logger.info('Validating services', service);
            this.form.networkServices.errors[index] = '';

            if (!this.validateService.hasValue(service.interceptIp)) {
                const ipAndCidrBlock = service.gatewayIp.split('/');
                service.interceptIp = ipAndCidrBlock[0];
            } else if (!this.validateService.isValidIP(service.interceptIp)) {
                this.form.networkServices.errors[index] += 'Intercept';
                this.form.networkServices.intercept.error = true;
                isValid = false;
            }

            if (!this.validateService.isValidCidrIP(service.gatewayIp)) {
                this.form.networkServices.errors[index] += 'GatewayIp';
                this.form.networkServices.ip.error = true;
                isValid = false;
            }
            index++;
        }
        return isValid;
    }

    getIpHostServices() {
        return this.ipHostServices;
    }

    getIpNetworkServices() {
        return this.ipNetworkServices;
    }

    getServices() {
        return this.services;
    }

    // function used by the parent component for getting the form ready to clone
    clone() {
        // clear the service names
        for (const service of this.ipHostServices) {
            service.name = '';
        }

        // for ip network services, clear the service name and set the gateway IP back to one field instead of the IP and CIDR block being two separate fields
        for (const service of this.ipNetworkServices) {
            service.name = '';
            if (service.gatewayIp != null && service.gatewayCidrBlock != null) {
                const gatewayIpAndCidrBlock = service.gatewayIp + '/' + service.gatewayCidrBlock;
                service.gatewayIp = gatewayIpAndCidrBlock;
            }
        }

        // clearing the service names just to be safe
        for (const service of this.services) {
            service.name = '';
        }
    }
}
