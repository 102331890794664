import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Endpoint, Network, NetworkGroup } from '@netfoundry-ui/shared/model';
import { ApiService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-client-cards-form',
    templateUrl: './client-cards-form.component.html',
    styleUrls: ['./client-cards-form.component.scss'],
})
export class ClientCardsFormComponent implements OnInit {
    selectedType = '';
    isSelected = false;
    isInline = false;
    newClient: Endpoint;
    newClients: Endpoint[];
    currentOrgId;
    currentNetworkId: Network;
    canListGeoRegions = true;
    clientModel = new Endpoint({});
    hideHelp = false;
    hideHaUpgrade = false;
    networkGroup = new NetworkGroup({});
    private subscription = new Subscription();

    constructor(
        private dialogRef: MatDialogRef<ClientCardsFormComponent>,
        private apiService: ApiService,
        public authorizationService: AuthorizationService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.currentOrgId = this.apiService.theOrgIs.getId();
        this.currentNetworkId = this.apiService.theNetworkIs.getId();
        if (data.inline) {
            this.isInline = data.inline;
        }
    }

    ngOnInit() {
        this.networkGroup = this.apiService.theOrgIs;

        this.canListGeoRegions = this.authorizationService.canListGeoRegions();
    }

    setType(type: string) {
        this.selectedType = type;
        this.isSelected = true;
    }

    setNewClient(newClient) {
        this.newClient = newClient;
    }

    setNewClients(newClients) {
        this.newClients = newClients;
    }

    back() {
        this.selectedType = '';
        this.isSelected = false;
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.hide();
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.hide();
        }
    }

    hide() {
        this.dialogRef.close({
            newClient: this.newClient,
            newClients: this.newClients,
        });
    }
}
