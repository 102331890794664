import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { LoggerService } from '@netfoundry-ui/shared/services';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { LOGIN_SERVICE, LoginServiceType } from './login-service.type';

@Injectable({ providedIn: 'root' })
export class IdletimeoutService {
    timeoutSeconds = this.environment.defaultSessionTimeoutSeconds;
    timeoutWarningSeconds = this.environment.defaultTimeoutWarningSeconds;
    pldtTimeoutSeconds = this.environment.pldtTimeoutSeconds;
    // source for the timeout warning alert
    private timeoutWarningSource = new Subject<boolean>();
    timeoutWarningAlert = this.timeoutWarningSource.asObservable();
    // timer for the idle timeout
    private idleTimer;
    // timer for the idle timeout warning
    private idleTimerWarning;
    // need to convert from seconds to milliseconds
    private timeout = this.timeoutSeconds * 1000;
    // number seconds before the user is warned they are about to idle out
    private timeoutWarning = this.timeout - this.timeoutWarningSeconds * 1000;

    constructor(
        protected logger: LoggerService,
        protected router: Router,
        @Inject(LOGIN_SERVICE) public loginService: LoginServiceType,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    // function for initializing the timeout and clearing the old one
    public initIdleTimeout() {
        // clearing the old timeout
        this.clearIdleTimeout();

        // if the timeout length is greater than 0 milliseconds, start a new timeout
        if (this.timeout > 0) {
            this.idleTimer = setTimeout(() => {
                this.idleTimeout();
            }, this.timeout);
        }

        // if the timeout warning time limit is the same as the timeout, then there is no need to track the warning
        if (this.timeoutWarning !== this.timeout) {
            this.idleTimerWarning = setTimeout(() => {
                this.idleTimeoutWarning();
            }, this.timeoutWarning);
        }
    }

    // function for clearing the timeout
    public clearIdleTimeout() {
        clearTimeout(this.idleTimer);
        // clearing the timer for the warning
        clearTimeout(this.idleTimerWarning);

        // emiting false in order to clear reset the observable
        this.timeoutWarningSource.next(false);
    }

    // function for setting the interval. Not used right now but may be useful in the future
    public setTimeoutInterval(newTimeoutSeconds) {
        // converting the new timeout (in seconds) to milliseconds
        this.timeout = newTimeoutSeconds * 1000;

        this.timeoutWarning = this.timeout - this.timeoutWarningSeconds * 1000;
    }

    // function for logging the user out on the end of the timeout
    private idleTimeout() {
        // writing a message to the console. We may want to have a dialog that gets opened at some point in the future
        this.logger.info('You have been logged out of your session due to inactivity. Please log back in to continue');

        // clearing the timeout
        this.clearIdleTimeout();

        const hostname = window.location.hostname;
        const isVanityUrl = hostname !== this.environment.originDomain;
        let params;
        if (isVanityUrl) {
            params = { queryParams: { vanityLogout: true } };
        }
        // logging the user out
        this.loginService.logout(false, false, params);
    }

    // function for handling what to do when the idle timeout warning is reached
    private idleTimeoutWarning() {
        // writing a message to the console. We may want to have a dialog that gets opened at some point in the future
        this.logger.info('You will be logged out of your session soon due to inactivity');
        this.timeoutWarningSource.next(true);
    }
}
