import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GatewayFormsService } from '@netfoundry-ui/shared/gateway';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    AzureResourceGroup,
    AzureSubscription,
    AzureVirtualWan,
    AzureVirtualWanSite,
    Endpoint,
    GatewayCluster,
} from '@netfoundry-ui/shared/model';
import {
    ApiService,
    AzureResourceGroupService,
    AzureVirtualWanService,
    AzureVirtualWanSiteService,
    FeatureService,
    GatewayClusterService,
    LocationService,
    LoggerService,
    RegionService,
    ValidateService,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { RegionTransformPipe } from '@netfoundry-ui/ui/pipes';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-avw-site-gateway-form',
    templateUrl: './avw-site-gateway-form.component.html',
    styleUrls: ['./avw-site-gateway-form.component.scss', '../gatewayform.component.scss'],
})
export class AvwSiteGatewayFormComponent implements OnInit, OnDestroy {
    @Input() isInline = false;
    @Input() showBreakoutFields = false;
    @Input() public currentOrgId;
    @Input() public currentNetworkId;
    @Input() avwSiteModel: AzureVirtualWanSite;
    @Input() gatewayModel: Endpoint;
    @Input() hideHaUpgrade = false;
    @Output() back: EventEmitter<boolean> = new EventEmitter();
    @Output() hide: EventEmitter<any> = new EventEmitter();

    model: Endpoint = new Endpoint({});
    azureSiteModel = new AzureVirtualWanSite({});
    public clusterModel = new GatewayCluster({});

    azureSiteModels = [];
    protectionTypes = [];

    isComplete = false;
    azureLocations = [];
    regions: Location[] = [];
    errorName = false;
    errorNameLength = false;

    gateways = [];
    regKey = [];
    attempt = 0;
    isLoading = false;

    isHAGateway = false;
    isBuilding = true;
    provisionedString = 'has been created';
    buildingString = 'is building';
    completedTitleStatusString = this.buildingString;
    timeout = 150;
    processing = false;
    azureResourceGroups: AzureResourceGroup[] = [];
    azureVirtualWans: AzureVirtualWan[] = [];
    currentAzureSubscription = new AzureSubscription({});
    currentAzureSubscriptionId = null;
    neighbors = [];
    neighborErrors: string[] = [];
    neighborError = '';
    advertisedPrefixes = [];
    advertisedPrefixesErrors: boolean[] = [];
    advertisedPrefixesError = false;
    hasNeighbors = false;
    hasAdvertisedPrefixes = false;
    oldRegionId = '';
    oldName = '';
    hideHelp = false;
    errors = {
        name: false,
        geoRegionId: false,
        azureSiteName: false,
        azureResourceGroupName: false,
        azureVirtualWanId: false,
        dataCenterId: false,
        publicIpdAddress: false,
        bgpPeeringAddress: false,
        bpgASN: false,
        bgpPeerWeight: false,
        deviceLinkSpeed: false,
        deviceVendor: false,
        deviceModel: false,
        bpgNeighborASN: false,
        bgpPeeringNeighborAddress: false,
        nextHop: false,
    };
    maxLength = '64';
    isEditing = false;
    canEditGateway = false;
    canEditAzureSite = false;
    private updatedRegKeySub = new Subscription();
    private subscription = new Subscription();
    // subject used to determine whether or not the registration key was obtained
    private updatedRegKeySource = new Subject<boolean>();
    private updatedRegKey = this.updatedRegKeySource.asObservable();

    constructor(
        private logger: LoggerService,
        private locationService: LocationService,
        private apiService: ApiService,
        private regionService: RegionService,
        private growlerService: GrowlerService,
        private azureResourceGroupService: AzureResourceGroupService,
        private azureVirtualWanService: AzureVirtualWanService,
        private azureVirtualWanSiteService: AzureVirtualWanSiteService,
        public featureService: FeatureService,
        private validateService: ValidateService,
        private clusterService: GatewayClusterService,
        public zitiEnabledService: ZitiEnabledService,
        public gatewayFormService: GatewayFormsService,
        public authorizationService: AuthorizationService,
        private regionTransform: RegionTransformPipe
    ) {}

    ngOnInit() {
        this.processing = false;
        this.errorName = false;

        this.currentAzureSubscription = this.apiService.theAzureSubscriptionIs;
        this.currentAzureSubscriptionId = this.currentAzureSubscription.getId();

        if (this.currentAzureSubscriptionId != null) {
            // azure virtual wans
            this.subscription.add(
                this.azureVirtualWanService.get().subscribe((vwans: AzureVirtualWan[]) => {
                    this.logger.info('Virtual Wans', vwans);
                    this.azureVirtualWans = vwans;
                })
            );

            // azure resource groups
            this.subscription.add(
                this.azureResourceGroupService.get().subscribe((groups: AzureResourceGroup[]) => {
                    this.logger.info('Resource Groups', groups);
                    this.azureResourceGroups = groups;
                })
            );
        }

        // datacenters for azure
        this.subscription.add(
            this.locationService.get().subscribe((result) => {
                this.azureLocations = [];

                for (const dc of result) {
                    if (dc.provider === 'AZURE') {
                        this.azureLocations.push(dc);
                    }
                }
            })
        );

        // geo region for gateways
        this.subscription.add(
            this.regionService.get().subscribe((result) => {
                this.regions = this.regionTransform.transform(result as Location[], false, true, false, false, false);
            })
        );

        // Reg Key
        // subscribe to changes to updatedRegKey
        this.updatedRegKeySub.add(
            this.gatewayFormService.updatedRegKey.subscribe((isUpdated) => {
                this.regKey = this.gatewayFormService.regKey;
                this.gateways = this.gatewayFormService.gateways;
                // if the registration key was not obtained and there were less than 10 attempts
                if (!isUpdated) {
                    this.attempt++;
                    // setting a 250ms timeout before calling loadGateway again
                    setTimeout(() => {
                        if (this.isHAGateway) this.gatewayFormService.loadGatewayCluster(this.clusterModel);
                        else this.gatewayFormService.loadGateway(this.model);
                    }, this.timeout);
                } else {
                    this.completedTitleStatusString = this.provisionedString;
                    this.isBuilding = false;
                }
            })
        );

        this.model.geoRegionId = '';

        this.azureSiteModel.azureVirtualWanId = '';
        this.azureSiteModel.azureResourceGroupName = '';
        this.azureSiteModel.bgp = {
            localPeeringAddress: {
                ipAddress: '',
                asn: null,
            },
            neighborPeers: [],
            advertiseLocal: true,
            advertisedPrefixes: [],
        };

        this.neighbors.push({ ipAddress: '', asn: '' });
        this.advertisedPrefixes.push('');

        // if a gatewawy and avw site have been provided
        if (
            this.gatewayModel != null &&
            this.gatewayModel.id != null &&
            this.avwSiteModel != null &&
            this.avwSiteModel.id != null
        ) {
            this.isEditing = true;

            // updating the model and avw site model
            this.model = this.gatewayModel;
            this.azureSiteModel = this.avwSiteModel;

            // getting the neighbor peers. Currently the avw site model does not store the bgp settings as an object but rather as fields
            // on the avw site model. As such, the console needs to convert the model to the proper form
            const neighborPeers = this.azureSiteModel['neighborPeers'];
            // if there were neighbor peers already
            if (neighborPeers != null && neighborPeers !== '') {
                this.neighbors = [];
                // split the string by comma
                const neighborPeerList = neighborPeers.split(',');

                // for each of the remain strings
                for (const neighborPeer of neighborPeerList) {
                    // split it by colon to get the ip address and asn of the peer
                    const neighborInfo = neighborPeer.split(':');

                    // add to the list of neighbors
                    this.neighbors.push({
                        ipAddress: neighborInfo[0],
                        asn: neighborInfo[1],
                    });
                }
            }
            // getting the advertised prefixes associated with the avw site
            const prefixes = this.azureSiteModel['advertisedPrefixes'];
            if (prefixes != null && prefixes !== '') {
                // creating a list object out of the string
                this.advertisedPrefixes = prefixes.split(',');
            }

            const asn = this.azureSiteModel['bgpASN'] > 0 ? this.azureSiteModel['bgpASN'] : '';

            this.azureSiteModel.bgp = {
                localPeeringAddress: {
                    ipAddress: this.azureSiteModel['bgpPeeringAddress'],
                    asn: asn,
                },
                neighborPeers: [],
                advertiseLocal: this.azureSiteModel['advertiseLocal'],
                advertisedPrefixes: [],
            };

            this.canEditGateway = this.authorizationService.canUpdateEndpoint(this.model.id);
            this.canEditAzureSite = true; // TODO add auth check this.authorizationService.canUpdateA

            this.oldName = this.model.name;
            this.oldRegionId = this.model.geoRegionId;
        }

        this.protectionTypes = this.clusterService.getProtectionTypes();
    }

    ngOnDestroy() {
        this.updatedRegKeySub.unsubscribe();
        this.subscription.unsubscribe();
    }

    goBack() {
        this.back.emit(true);
    }

    hideForm(response?: unknown) {
        this.hide.emit(response);
    }

    save() {
        this.logger.info('Saving Azure...');

        if (this.validate_az()) {
            this.logger.info('Azure form validated...');
            if (this.isHAGateway) {
                this._save_az_cluster();
            } else {
                this._save_az();
            }
        } else {
            this.logger.info('Azure form invalid');
        }
    }

    update() {
        this.logger.info('updating Azure...');

        if (this.validate_az()) {
            this.logger.info('Azure form validated...');
            this._update_az();
        } else {
            this.logger.info('Azure form invalid');
        }
    }

    share() {
        this.gatewayFormService.share(this.gateways[0]);
    }

    validate_az() {
        let isValid = true;

        // reset validation
        this.errorName = false;
        for (const key of Object.keys(this.errors)) {
            this.errors[key] = false;
        }

        this.errors['name'] = !this.validateService.isValidName(this.model.name);
        this.errors['geoRegionId'] = !this.validateService.hasValue(this.model.geoRegionId);
        // this.errors['azureResourceGroupName'] = !this.validateService.hasValue(this.azureSiteModel.azureResourceGroupName);
        // this.errors['dataCenterId'] = !this.validateService.hasValue(this.azureSiteModel.dataCenterId);
        this.errors['publicIpAddress'] = this.validateService.hasValue(this.azureSiteModel.publicIpAddress)
            ? !this.validateService.isValidIP(this.azureSiteModel.publicIpAddress)
            : false;
        // this.errors['azureVirtualWanId'] = !this.validateService.hasValue(this.azureSiteModel.azureVirtualWanId);

        this.errors['bgpPeeringAddress'] = this.validateService.hasValue(
            this.azureSiteModel.bgp.localPeeringAddress.ipAddress
        )
            ? !this.validateService.isValidIP(this.azureSiteModel.bgp.localPeeringAddress.ipAddress)
            : false;
        if (this.validateService.hasValue(this.azureSiteModel.bgp.localPeeringAddress.asn)) {
            this.errors['bpgASN'] =
                !this.validateService.hasValue(this.azureSiteModel.bgp.localPeeringAddress.asn) ||
                isNaN(this.azureSiteModel.bgp.localPeeringAddress.asn) ||
                this.azureSiteModel.bgp.localPeeringAddress.asn < 1 ||
                this.azureSiteModel.bgp.localPeeringAddress.asn > 65534;
        } else {
            this.errors['bpgASN'] = false;
        }

        if (this.validateService.hasValue(this.model.o365BreakoutNextHopIp)) {
            this.errors['nextHop'] = !this.validateService.isValidIP(this.model.o365BreakoutNextHopIp);
        } else {
            this.errors['nextHop'] = false;
        }

        this.errors['bgpPeerWeight'] = false;
        this.errors['deviceLinkSpeed'] = false;
        this.errors['deviceVendor'] = false;
        this.errors['deviceModel'] = false;

        for (const key of Object.keys(this.errors)) {
            if (this.errors[key]) {
                isValid = false;
            }
        }

        this.errorName = this.errors['name'];

        this.logger.info('ERRORS', this.errors);

        isValid = this.validateNeighbors() && isValid;
        isValid = this.validateAdvertisedPrefixes() && isValid;

        return isValid;
    }

    async _save_az() {
        this.isLoading = true;
        this.logger.info('Attempting to save Azure GW..');
        this.model.endpointType = 'AVWGW';

        const result = await this.gatewayFormService.save(this.model);

        if (result.getId() != null) {
            this.model = result;

            // clone GW name to azure model
            this.azureSiteModel.name = this.model.name;
            this.azureSiteModel.endpointId = this.model.getId();

            if (this.hasNeighbors) {
                this.azureSiteModel.bgp.neighborPeers = this.neighbors;
            }

            if (this.hasAdvertisedPrefixes) {
                this.azureSiteModel.bgp.advertisedPrefixes = this.advertisedPrefixes;
            }
            this.growlerService.show(
                new GrowlerData(
                    'info',
                    'Saving',
                    'Saving Site Information',
                    'Saving Azure Gateway Information, Please Wait...'
                )
            );
            this.azureSiteModel.azureSubscriptionId = this.currentAzureSubscriptionId;
            this.subscription.add(
                this.azureVirtualWanSiteService.save(this.azureSiteModel).subscribe((data) => {
                    this.logger.info('AZURE VWAN Site DATA', data);
                    this.azureSiteModel = new AzureVirtualWanSite(data);
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Creation Complete',
                            'Gateway information has been saved.'
                        )
                    );

                    // attempting to get the registration key for the gateway
                    this.isLoading = false;
                    // setting the title to indicate that the gateways are building
                    this.completedTitleStatusString = this.buildingString;
                    this.isComplete = true;
                    this.logger.info('Load GW after Azure save');
                    this.gatewayFormService.loadGateway(this.model);
                })
            );
        } else {
            // failure growler
            this.isLoading = false;
            this.growlerService.show(
                new GrowlerData('error', 'Error', 'Gateway Creation Failed', 'Failed to create the Gateway.')
            );
        }
    }

    async _update_az() {
        this.isLoading = true;
        this.logger.info('Attempting to save Azure GW..');

        const wasGatewayUpdated = false;
        let result;
        if (this.oldName !== this.model.name || this.oldRegionId !== this.model.geoRegionId) {
            result = await this.gatewayFormService.save(this.model);
        }

        if (!wasGatewayUpdated || result.getId() != null) {
            // clone GW name to azure model
            this.azureSiteModel.name = this.model.name;
            // this.azureSiteModel.endpointId = this.model.getId();

            if (this.hasNeighbors) {
                this.azureSiteModel.bgp.neighborPeers = this.neighbors;
            }

            if (this.hasAdvertisedPrefixes) {
                this.azureSiteModel.bgp.advertisedPrefixes = this.advertisedPrefixes;
            }
            this.growlerService.show(
                new GrowlerData(
                    'info',
                    'Saving',
                    'Saving Site Information',
                    'Saving Azure Gateway Information, Please Wait...'
                )
            );
            this.azureSiteModel.azureSubscriptionId = this.currentAzureSubscriptionId;
            this.subscription.add(
                this.azureVirtualWanSiteService.save(this.azureSiteModel).subscribe((data) => {
                    this.logger.info('AZURE VWAN Site DATA', data);
                    this.azureSiteModel = new AzureVirtualWanSite(data);
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Creation Complete',
                            'Gateway information has been saved.'
                        )
                    );

                    // attempting to get the registration key for the gateway
                    this.isLoading = false;
                    this.hideForm();
                })
            );
        } else {
            // failure growler
            this.isLoading = false;
            this.growlerService.show(
                new GrowlerData('error', 'Error', 'Gateway Creation Failed', 'Failed to Update the Gateway.')
            );
        }
    }

    // function for saving a HA cluster of AVWG
    async _save_az_cluster() {
        this.isLoading = true;
        this.logger.info('Attempting to save Azure GW..');
        this.model.endpointType = 'AVWGW';

        this.clusterModel.protectionGroupId = this.model.name;
        this.clusterModel.name = this.model.name;
        this.clusterModel.geoRegionId = this.model.geoRegionId;
        this.clusterModel.protectionType = '1:1';
        this.clusterModel.endpointType = this.model.endpointType;

        const result = await this.gatewayFormService.saveHa(this.clusterModel, this.model);
        this.clusterModel = new GatewayCluster(result);

        this.clusterModel = await this.clusterService
            .save(this.clusterModel)
            .toPromise()
            .then((data) => new GatewayCluster(data));

        this.logger.info('Azure GW Saved...');
        this.growlerService.show(
            new GrowlerData(
                'info',
                'Saving',
                'Saving Site Information',
                'Saving Azure Gateway Information, Please Wait...'
            )
        );

        // saving the cluster and waiting on the result
        const gateways = await this.clusterService
            .getLinkedResources(this.clusterModel, 'endpoints')
            .toPromise()
            .then((result) => result as Endpoint[]);

        // create all azure gateways at once but wait until all of those requests complete
        const proms = [];
        for (const endpoint of gateways) {
            const gateway = new Endpoint(endpoint);
            if (gateway.id !== null) {
                // setting an object to represent the current azure site model
                const currAzureSiteModel = new AzureVirtualWanSite({});

                // clone current azureSiteModel from base azureSiteModel
                currAzureSiteModel.azureResourceGroupName = this.azureSiteModel.azureResourceGroupName;
                currAzureSiteModel.azureVirtualWanId = this.azureSiteModel.azureVirtualWanId;
                currAzureSiteModel.dataCenterId = this.azureSiteModel.dataCenterId;
                currAzureSiteModel.publicIpAddress = this.azureSiteModel.publicIpAddress;
                currAzureSiteModel.bgp = this.azureSiteModel.bgp;
                currAzureSiteModel.name = gateway.name;
                currAzureSiteModel.endpointId = gateway.id;
                currAzureSiteModel.azureSubscriptionId = this.currentAzureSubscriptionId;

                // adding the promise for creating the azure virtual wan site
                proms.push(
                    this.azureVirtualWanSiteService
                        .save(currAzureSiteModel)
                        .toPromise()
                        .then((data) => new AzureVirtualWanSite(data))
                );
            } else {
                // failure growler
                this.isLoading = false;
                this.growlerService.show(
                    new GrowlerData('error', 'Error', 'Gateway Creation Failed', 'Failed to create the Gateway.')
                );
            }
        }

        // once all the azure sites are done creating
        this.azureSiteModels = await Promise.all(proms).then((vals) => {
            // saving the avw sites
            const avwSiteModels = [];
            for (const azureSiteModel of vals) {
                avwSiteModels.push(azureSiteModel);
            }
            return avwSiteModels;
        });

        this.growlerService.show(
            new GrowlerData('success', 'Success', 'Creation Complete', 'Gateway information has been saved.')
        );
        this.processing = false;
        // attempting to get the registration key for the gateway
        this.isLoading = false;
        this.isComplete = true;
        this.completedTitleStatusString = this.buildingString;
        this.logger.info('Load GW after Azure save');
        this.gatewayFormService.loadGatewayCluster(this.clusterModel);
    }

    toggleHAGateway() {
        this.isHAGateway = !this.isHAGateway;
        if (this.isHAGateway) {
            this.maxLength = '59';
            this.model.name = this.model.name.substring(0, 59);
        } else {
            this.maxLength = '64';
        }
    }

    toggleAdvertise() {
        this.azureSiteModel.bgp.advertiseLocal = !this.azureSiteModel.bgp.advertiseLocal;
    }

    addPeeringNeighbor() {
        this.neighbors.push({ ipAddress: '', asn: '' });
    }

    removeNeighbor(index) {
        this.neighbors.splice(index, 1);
    }

    validateNeighbors() {
        let isValid = true;
        let index = 0;
        this.neighborError = '';
        this.neighborErrors = [];
        if (this.neighbors.length === 1 && this.neighbors[0].ipAddress === '' && this.neighbors[0].ipAddress === '') {
            this.hasNeighbors = false;
            return true;
        } else {
            this.hasNeighbors = true;
            for (const neighbor of this.neighbors) {
                this.neighborErrors[index] = '';

                if (!this.validateService.isValidIP(neighbor.ipAddress)) {
                    this.neighborError += 'IP';
                    this.neighborErrors[index] += 'IP';
                    isValid = false;
                }

                if (
                    !this.validateService.hasValue(neighbor.asn) ||
                    isNaN(neighbor.asn) ||
                    neighbor.asn < 1 ||
                    neighbor.asn > 65534
                ) {
                    this.neighborError += 'ASN';
                    this.neighborErrors[index] += 'ASN';
                    isValid = false;
                }

                index++;
            }
        }
        return isValid;
    }

    validateAdvertisedPrefixes() {
        let isValid = true;
        let index = 0;
        this.advertisedPrefixesError = false;
        this.advertisedPrefixesErrors = [];
        if (this.advertisedPrefixes.length === 1 && this.advertisedPrefixes[0] === '') {
            this.hasAdvertisedPrefixes = false;
            return true;
        } else {
            this.hasAdvertisedPrefixes = true;
            for (const advertisedPrefix of this.advertisedPrefixes) {
                this.advertisedPrefixesErrors[index] = false;

                if (!this.validateService.isValidCidrIP(advertisedPrefix)) {
                    this.advertisedPrefixesError = true;
                    this.advertisedPrefixesErrors[index] = true;
                    isValid = false;
                }
                index++;
            }
        }
        return isValid;
    }

    addAdvertisedPrefix() {
        this.advertisedPrefixes.push('');
    }

    removeAdvertisedPrefix(index) {
        this.advertisedPrefixes.splice(index, 1);
    }

    trackByIndex(index, item) {
        return index;
    }

    copy(gateway?) {
        let elementId = 'EditRegKey';
        if (gateway) {
            elementId += `_${gateway.id}`;
        }
        const element = <HTMLInputElement>document.getElementById(elementId);
        element.focus();
        element.select();
        document.execCommand('copy');
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Reg Key Copied',
                'The reg key ' + this.model['registrationKey'] + ' has been copied to your clipboard'
            )
        );
    }

    downloadJWT(model, index) {
        this.gatewayFormService.downloadJWT(model, index);
    }
}
