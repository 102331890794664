import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActionButtonComponent } from './action-button.component';

@NgModule({
    declarations: [ActionButtonComponent],
    exports: [ActionButtonComponent],
    imports: [CommonModule],
})
export class FeatureActionButtonModule {}
