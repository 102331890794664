import { Injectable } from '@angular/core';
import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('regions')
export class RegionV2 extends Resource {
    public locationCode: string;
    public name: string;
    public provider: string;
    public address: string;
    public city: string;
    public stateCode: string;
    public stateName: string;
    public countryName: string;
    public countryCode: string;
    public latitude: number;
    public longitude: number;

    public getName() {
        return this.name;
    }

    public getSelfLink() {
        return this._links.self.href;
    }
    public getRegionsLink() {
        return this._links.regions.href;
    }
    public getProvidersLink() {
        return this._links.providers.href;
    }
    public getProviderLink() {
        return this._links.provider.href;
    }
}
