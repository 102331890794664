/**
 * Template class for all MOP resources with built in helper methods
 */
export class Tenant {
    public name: string;
    public label: string;
    public auth0ConnectionId: string;
    public identityProviderName: string;
    public id: string;
    public selected = false;
    public deleted = false;
    public active = true;

    // Default nested property that should exist on all resources
    public _links = {
        self: {
            href: null,
        },
    };

    public hash: string;

    constructor(object) {
        const keys = Object.keys(object);

        // TODO - Figure out how to do inflection so only public properties are populated
        for (const key of keys) {
            this[key] = object[key];
        }

        this.hash = btoa(this._links.self.href);

        return this;
    }

    public getId() {
        if (this._links.self.href != null) {
            return this._links.self.href.split('/').pop();
        } else {
            return null;
        }
    }

    public getSelfLink() {
        return this._links.self.href;
    }

    public unhashLink() {
        return atob(this.hash);
    }

    public getLinkedResource(linkName: string) {
        if (this._links[linkName].href !== undefined) {
            return this._links[linkName].href;
        } else {
            return null;
        }
    }

    public getLinkedResourceId(linkName: string) {
        if (this._links[linkName] !== undefined && this._links[linkName]['href'] !== undefined) {
            return this._links[linkName]['href'].split('/').pop();
        } else {
            return null;
        }
    }
}
