<div [ngClass]="{ isAt: step === 2, passed: isPassedStep(2) }" class="slider">
    <div *ngIf="step === 2">
        <div class="wizard mini">
            <div class="header">
                <div class="darken"></div>
                <div class="darkenText">Create a Gateway to Secure Your Bucket</div>
            </div>
            <div class="row padded">
                <div *ngIf="canListRegions" class="grid two">
                    <label [ngClass]="{ error: form.sourceName.error }" for="Name"
                        >NAME
                        <span [ngClass]="{ error: form.sourceName.error }" class="note">Required</span>
                    </label>
                    <label [ngClass]="{ error: form.region.error }" for="Region"
                        >AWS REGION
                        <span [ngClass]="{ error: form.region.error }" class="note">Required</span>
                    </label>
                    <input
                        [(ngModel)]="form.sourceName.value"
                        [ngClass]="{ error: form.sourceName.error }"
                        id="Name"
                        maxlength="100"
                        placeholder="{{ form.sourceName.placeholder }}"
                        type="text"
                    />
                    <select [(ngModel)]="form.region.value" [ngClass]="{ error: form.region.error }" id="Region">
                        <option value="">{{ form.region.placeholder }}</option>
                        <option *ngFor="let region of regions | sortby: 'name'" [value]="region.id">
                            {{ region.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
