import { Injectable } from '@angular/core';
import { AzureSubscription, Network, NetworkGroup, Tenant } from '@netfoundry-ui/shared/model';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';
import { AuthService } from './auth.service';
import { IamService } from './iam.service';

const networkDomain = 'io.netfoundry.network';
const organizationDomain = 'io.netfoundry.identity';
const accountDomain = 'io.netfoundry.billing';

const READ = 'read';
const CREATE = 'create';
const UPGRADE = 'update-version';
const UPDATE = 'update';
const DELETE = 'delete';
const SUSPEND = 'suspend';
const RESUME = 'resume';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
    public obtainedMopPermissions = false;

    private mopPermissions;

    private permissions;

    private currentNetworkId;
    private networkGroupId;
    private currentTenantId;
    private currentSubscriptionId;

    private resourceTypeMap = {};
    private resourceTypeIdMap = {};
    private grants = [];

    private standardRoleTypes = {};

    constructor(
        private apiService: ApiService,
        private iamService: IamService,
        private authService: AuthService,
        private auth0Angular: AuthService,
        private logger: LoggerService
    ) {
        this.apiService.currentOrg.subscribe((org) => {
            this.networkGroupId = new NetworkGroup(org).id;
        });

        this.apiService.currentNetwork.subscribe((network) => {
            this.currentNetworkId = new Network(network).id;
        });

        this.apiService.currentTenant.subscribe((tenant) => {
            this.currentTenantId = new Tenant(tenant).id;
        });

        this.apiService.currentAzureSubscription.subscribe((sub) => {
            this.currentSubscriptionId = new AzureSubscription(sub).id;
        });
    }

    public async getSilentAuthorization() {
        const authResult = await this.iamService
            .getSilentAuthorization()
            .toPromise()
            .catch((error) => {
                this.logger.error('silent auth error: ' + JSON.stringify(error));
            });
        return authResult;
    }

    public async getAuthorization() {
        if (localStorage.getItem('access_token') == null) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            setTimeout(() => {}, 250);
        }
        const currentUser: any = await this.iamService
            .get('identities', 'self')
            .toPromise();
        const identityId = currentUser.id;

        this.apiService.setCurrentUser(currentUser);
        this.resourceTypeIdMap = {};
        this.resourceTypeIdMap = {};

        await this.authService
            .getPermissions(identityId)
            .toPromise()
            .then((result) => {
                this.mopPermissions = result['authorizations'];
                this.obtainedMopPermissions = true;
                this.permissions = this.mopPermissions;
                this.standardRoleTypes = result['standardRoleTypes'];
                this.grants = result['privilegeSources'];

                const resourceTypes = result['resourceTypes'];

                for (const resourceTypeKey of Object.keys(resourceTypes)) {
                    const resourceType = resourceTypes[resourceTypeKey];
                    this.resourceTypeMap[resourceType.code] = resourceType;
                    this.resourceTypeIdMap[resourceType.id] = resourceType;
                }

                return;
            });
    }

    // ### Network Authorization Checks ###

    // function for checking if the current user has permission to list networks
    public canListNetworks(currentOrgId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, currentOrgId, null);

        const path = this.buildPaths('network', resourceIdMap);

        return this.canDoAction(networkDomain, 'network', READ, path, true);
    }

    // function for checking if the current user has permission to get a specific network
    public canReadNetworkAsCode(currentOrgId?: string, networkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, currentOrgId, networkId);

        const path = this.buildPaths('network', resourceIdMap);

        return this.canDoAction(networkDomain, 'network', 'read-as-code', path);
    }

    // function for checking if the current user has permission to create a network
    public canCreateNetworks(networkGrpId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGrpId, null);

        const path = this.buildPaths('network', resourceIdMap);

        return this.canDoAction(networkDomain, 'network', CREATE, path);
    }

    // function for checking if the current user has permission to upgrade a network
    public canUpgradeNetwork(networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, null);

        const path = this.buildPaths('network', resourceIdMap);

        return this.canDoAction(networkDomain, 'network', UPGRADE, path);
    }

    // function for checking if the current user has permission to create a network
    public canCreateNetworksAsCode(currentOrgId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, currentOrgId, null);

        const path = this.buildPaths('network', resourceIdMap);

        return this.canDoAction(networkDomain, 'network', 'create-as-code', path);
    }

    // function for checking if the current user has permission to update a network
    public canUpdateNetwork(networkId: string, networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, networkId);

        const path = this.buildPaths('network', resourceIdMap);

        return this.canDoAction(networkDomain, 'network', UPDATE, path);
    }

    // function for checking if the current user has permission to delete a network
    public canDeleteNetwork(networkId: string, networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, networkId);

        const path = this.buildPaths('network', resourceIdMap);

        return this.canDoAction(networkDomain, 'network', DELETE, path);
    }

    // ### AppWan Authorization Checks ###

    // function for checking if the current user has permission to list appwans
    public canListAppWans(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('app-wan', resourceIdMap);

        return this.canDoAction(networkDomain, 'app-wan', READ, path);
    }

    // function for checking if the current user has permission to get a specific appwan
    public canGetAppWan(appwanId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['app-wan'] = appwanId;

        const path = this.buildPaths('app-wan', resourceIdMap);

        return this.canDoAction(networkDomain, 'app-wan', READ, path);
    }

    // function for checking if the current user has permission to get a specific appwan
    public canReadAppWanAsCode(appwanId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['app-wan'] = appwanId;

        const path = this.buildPaths('app-wan', resourceIdMap);

        return this.canDoAction(networkDomain, 'app-wan', 'read-as-code', path);
    }

    // function for checking if the current user has permission to create an appwan
    public canCreateAppWans(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('app-wan', resourceIdMap);

        return this.canDoAction(networkDomain, 'app-wan', CREATE, path);
    }

    // function for checking if the current user has permission to create an appwan
    public canCreateAppWansAsCode(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('app-wan', resourceIdMap);

        return this.canDoAction(networkDomain, 'app-wan', 'create-as-code', path);
    }

    // function for checking if the current user has permission to update an appwan
    public canUpdateAppWan(appwanId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['app-wan'] = appwanId;

        const path = this.buildPaths('app-wan', resourceIdMap);

        return this.canDoAction(networkDomain, 'app-wan', UPDATE, path);
    }

    // function for checking if the current user has permission to delete an appwan
    public canDeleteAppWan(appwanId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['app-wan'] = appwanId;

        const path = this.buildPaths('app-wan', resourceIdMap);

        return this.canDoAction(networkDomain, 'app-wan', DELETE, path);
    }

    // ### Service Authorization Checks ###

    // function for checking if the current user has permission to list services
    public canListServices(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('service', resourceIdMap);

        return this.canDoAction(networkDomain, 'service', READ, path);
    }

    // function for checking if the current user has permission to get a specific Service
    public canGetService(serviceId: string, networkGroupId: string, currentNetworkId: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['service'] = serviceId;

        const path = this.buildPaths('service', resourceIdMap);

        return this.canDoAction(networkDomain, 'service', READ, path);
    }

    // function for checking if the current user has permission to create an service
    public canCreateServices(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('service', resourceIdMap);

        return this.canDoAction(networkDomain, 'service', CREATE, path);
    }

    // function for checking if the current user has permission to update an service
    public canUpdateService(serviceId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['service'] = serviceId;

        const path = this.buildPaths('service', resourceIdMap);

        return this.canDoAction(networkDomain, 'service', UPDATE, path);
    }

    // function for checking if the current user has permission to update a browzer app
    public canUpdateBrowzerApp(appId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['browzer-app'] = appId;

        const path = this.buildPaths('browzer-app', resourceIdMap);

        return this.canDoAction(networkDomain, 'browzer-app', UPDATE, path);
    }

    // function for checking if the current user has permission to delete an service
    public canDeleteService(serviceId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['service'] = serviceId;

        const path = this.buildPaths('service', resourceIdMap);

        return this.canDoAction(networkDomain, 'service', DELETE, path);
    }

    // ### Endpoints Authorization Checks ###

    // function for checking if the current user has permission to list endpoints
    public canListEndpoints(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('endpoint', resourceIdMap);

        return this.canDoAction(networkDomain, 'endpoint', READ, path);
    }

    // function for checking if the current user has permission to get a specific Service
    public canGetEndpoint(endpointId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['endpoint'] = endpointId;

        const path = this.buildPaths('endpoint', resourceIdMap);

        return this.canDoAction(networkDomain, 'endpoint', READ, path);
    }

    // function for checking if the current user has permission to create an service
    public canCreateEndpoints(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('endpoint', resourceIdMap);

        return this.canDoAction(networkDomain, 'endpoint', CREATE, path);
    }

    // function for checking if the current user has permission to update an service
    public canUpdateEndpoint(endpointId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['endpoint'] = endpointId;

        const path = this.buildPaths('endpoint', resourceIdMap);

        return this.canDoAction(networkDomain, 'endpoint', UPDATE, path);
    }

    // function for checking if the current user has permission to delete an service
    public canDeleteEndpoint(endpointId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['endpoint'] = endpointId;

        const path = this.buildPaths('endpoint', resourceIdMap);

        return this.canDoAction(networkDomain, 'endpoint', DELETE, path);
    }

    // function for checking if the current user has permission to delete an service
    public canDeleteBrowzerApp(appId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['browzer-app'] = appId;

        const path = this.buildPaths('browzer-app', resourceIdMap);

        return this.canDoAction(networkDomain, 'browzer-app', DELETE, path);
    }

    public canShareEndpoint(endpointId: string, networkGroupId?: string, currentNetworkId?: string) {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['endpoint'] = endpointId;

        const path = this.buildPaths('endpoint', resourceIdMap);
        // TODO update when edge auth is updated
        return this.canDoAction(networkDomain, 'endpoint', READ, path);
    }

    // ### edge router Authorization Checks ###

    // function for checking if the current user has permission to list edge router
    public canListEdgeRouters(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('edge-router', resourceIdMap);

        return this.canDoAction(networkDomain, 'edge-router', READ, path);
    }

    // function for checking if the current user has permission to get a specific edge route
    public canGetEdgeRouter(edgeRouterId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['edge-router'] = edgeRouterId;

        const path = this.buildPaths('edge-router', resourceIdMap);

        return this.canDoAction(networkDomain, 'edge-router', READ, path);
    }

    // function for checking if the current user has permission to create an edge router
    public canCreateEdgeRouters(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('edge-router', resourceIdMap);

        return this.canDoAction(networkDomain, 'edge-router', CREATE, path);
    }

    // function for checking if the current user has permission to update an edge router
    public canUpdateEdgeRouter(edgeRouterId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['edge-router'] = edgeRouterId;

        const path = this.buildPaths('edge-router', resourceIdMap);

        return this.canDoAction(networkDomain, 'edge-router', UPDATE, path);
    }

    // function for checking if the current user has permission to delete an edge router
    public canDeleteEdgeRouter(edgeRouterId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['edge-router'] = edgeRouterId;

        const path = this.buildPaths('edge-router', resourceIdMap);

        return this.canDoAction(networkDomain, 'edge-router', DELETE, path);
    }

    public canSuspendEdgeRouter(edgeRouterId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['edge-router'] = edgeRouterId;

        const path = this.buildPaths('edge-router', resourceIdMap);

        return this.canDoAction(networkDomain, 'edge-router', SUSPEND, path);
    }

    public canResumeEdgeRouter(edgeRouterId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['edge-router'] = edgeRouterId;

        const path = this.buildPaths('edge-router', resourceIdMap);

        return this.canDoAction(networkDomain, 'edge-router', RESUME, path);
    }

    // ### edge router policy Authorization Checks ###

    // function for checking if the current user has permission to list edge router policies
    public canListEdgeRouterPolicies(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('edge-router-policy', resourceIdMap);

        return this.canDoAction(networkDomain, 'edge-router-policy', READ, path);
    }

    // function for checking if the current user has permission to get a specific edge router policy
    public canGetEdgeRouterPolicy(
        edgeRouterPolicyId: string,
        networkGroupId?: string,
        currentNetworkId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['edge-router-policy'] = edgeRouterPolicyId;

        const path = this.buildPaths('edge-router-policy', resourceIdMap);

        return this.canDoAction(networkDomain, 'edge-router-policy', READ, path);
    }

    // function for checking if the current user has permission to create an edge router plicy
    public canCreateEdgeRouterPolicies(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('edge-router-policy', resourceIdMap);

        return this.canDoAction(networkDomain, 'edge-router-policy', CREATE, path);
    }

    // function for checking if the current user has permission to update an edge router
    public canUpdateEdgeRouterPolicy(
        edgeRouterPolicyId: string,
        networkGroupId?: string,
        currentNetworkId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['edge-router-policy'] = edgeRouterPolicyId;

        const path = this.buildPaths('edge-router-policy', resourceIdMap);

        return this.canDoAction(networkDomain, 'edge-router-policy', UPDATE, path);
    }

    // function for checking if the current user has permission to delete an edge router policy
    public canDeleteEdgeRouterPolicy(
        edgeRouterPolicyId: string,
        networkGroupId?: string,
        currentNetworkId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['edge-router-policy'] = edgeRouterPolicyId;

        const path = this.buildPaths('edge-router-policy', resourceIdMap);

        return this.canDoAction(networkDomain, 'edge-router-policy', DELETE, path);
    }

    // ### Endpoint Groups Authorization Checks ###

    // function for checking if the current user has permission to list endpoint groups
    public canListEndpointGroups(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('endpoint-group', resourceIdMap);

        return this.canDoAction(networkDomain, 'endpoint-group', READ, path);
    }

    // function for checking if the current user has permission to get a specific endpoint group
    public canGetEndpointGroup(endpointGroupId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['endpoint-group'] = endpointGroupId;

        const path = this.buildPaths('endpoint-group', resourceIdMap);

        return this.canDoAction(networkDomain, 'endpoint-group', READ, path);
    }

    // function for checking if the current user has permission to create an endpoint group
    public canCreateEndpointGroups(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('endpoint-group', resourceIdMap);

        return this.canDoAction(networkDomain, 'endpoint-group', CREATE, path);
    }

    // function for checking if the current user has permission to update an endpoint group
    public canUpdateEndpointGroup(
        endpointGroupId: string,
        networkGroupId?: string,
        currentNetworkId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['endpoint-group'] = endpointGroupId;

        const path = this.buildPaths('endpoint-group', resourceIdMap);

        return this.canDoAction(networkDomain, 'endpoint-group', UPDATE, path);
    }

    // function for checking if the current user has permission to delete an service
    public canDeleteEndpointGroup(
        endpointGroupId: string,
        networkGroupId?: string,
        currentNetworkId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['endpoint-group'] = endpointGroupId;

        const path = this.buildPaths('endpoint-group', resourceIdMap);

        return this.canDoAction(networkDomain, 'endpoint-group', DELETE, path);
    }

    // ### Invitations Authorization Checks ###

    // function for checking if the current user has permission to list invitations
    public canListInvitations(currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        const path = this.buildPaths('invitation', resourceIdMap);

        return this.canDoAction(organizationDomain, 'invitation', READ, path);
    }

    // function for checking if the current user has permission to get a specific invitation
    public canGetInvitation(invitationId: string, currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(invitationId);

        resourceIdMap['invitation'] = invitationId;

        const path = this.buildPaths('invitation', resourceIdMap);

        return this.canDoAction(organizationDomain, 'invitation', READ, path);
    }

    // function for checking if the current user has permission to create an invitation
    public canCreateInvitations(currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        const path = this.buildPaths('invitation', resourceIdMap);

        return this.canDoAction(organizationDomain, 'invitation', CREATE, path);
    }

    // function for checking if the current user has permission to revoke an invitation
    public canRevokeInvitation(invitationId: string, currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        resourceIdMap['invitation'] = invitationId;

        const path = this.buildPaths('invitation', resourceIdMap);

        return this.canDoAction(organizationDomain, 'invitation', 'update-revoke', path);
    }

    // ### User Identities Authorization Checks ###

    // function for checking if the current user has permission to list user identities
    public canListUserIdentities(currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        const path = this.buildPaths('user-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'user-identity', READ, path);
    }

    // function for checking if the current user has permission to get a specific user identity
    public canGetUserIdentity(identityId: string, currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        resourceIdMap['user-identity'] = identityId;

        const path = this.buildPaths('user-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'user-identity', READ, path);
    }

    // function for checking if the current user has permission to create an user identity
    public canCreateUserIdentity(currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        const path = this.buildPaths('user-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'user-identity', CREATE, path);
    }

    // function for checking if the current user has permission to update an identity
    public canUpdateUserIdentity(identityId: string, currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        resourceIdMap['user-identity'] = identityId;

        const path = this.buildPaths('user-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'user-identity', UPDATE, path);
    }

    // function for checking if the current user has permission to activate or deactivate
    public canActivateOrDeactivateUserIdentity(identityId: string, currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        resourceIdMap['user-identity'] = identityId;

        const path = this.buildPaths('user-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'user-identity', 'update-active', path);
    }

    // function for checking if the current user has permission to activate or deactivate
    public canResetMfaUserIdentity(identityId: string, currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        resourceIdMap['user-identity'] = identityId;

        const path = this.buildPaths('user-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'user-identity', 'update-reset-mfa', path);
    }

    // function for checking if the current user has permission to activate or deactivate
    public canDeleteUserIdentity(identityId: string, currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        resourceIdMap['user-identity'] = identityId;

        const path = this.buildPaths('user-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'user-identity', DELETE, path);
    }

    // ### API Account Identities Authorization Checks ###

    // function for checking if the current user has permission to list api account identities
    public canListApiAccountIdentities(currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        const path = this.buildPaths('api-account-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'api-account-identity', READ, path);
    }

    // function for checking if the current user has permission to get a specific api account identity
    public canGetApiAccountIdentity(identityId: string, currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        resourceIdMap['api-account-identity'] = identityId;

        const path = this.buildPaths('api-account-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'api-account-identity', READ, path);
    }

    // function for checking if the current user has permission to create an user identity
    public canCreateApiAccountIdentity(currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        const path = this.buildPaths('api-account-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'api-account-identity', CREATE, path);
    }

    // function for checking if the current user has permission to update an api account identity
    public canUpdateApiAccountIdentity(identityId: string, currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        resourceIdMap['api-account-identity'] = identityId;

        const path = this.buildPaths('api-account-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'api-account-identity', UPDATE, path);
    }

    // function for checking if the current user has permission to activate or deactivate
    public canActivateOrDeactivateApiAccountIdentity(identityId: string, currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        resourceIdMap['api-account-identity'] = identityId;

        const path = this.buildPaths('api-account-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'api-account-identity', 'update-active', path);
    }

    // function for checking if the current user has permission to rotate the password of an api account identity
    public canRotatePasswordApiAccountIdentity(identityId: string, currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        resourceIdMap['api-account-identity'] = identityId;

        const path = this.buildPaths('api-account-identity', resourceIdMap);

        return this.canDoAction(organizationDomain, 'api-account-identity', 'update-rotate-password', path);
    }

    // ### Branding Authorization Checks ###

    // function for checking if the current user has permission to create colors
    public canUpdateColors(currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        const path = this.buildPaths('organization', resourceIdMap);

        return this.canDoAction(organizationDomain, 'organization', 'update-branding', path);
    }

    // function for checking if the current user has permission to delete colors
    public canDeleteColors(currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        const path = this.buildPaths('organization', resourceIdMap);

        return this.canDoAction(organizationDomain, 'organization', 'update-branding', path);
    }

    // function for checking if the current user has permission to create logos
    public canUpdateLogos(currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        const path = this.buildPaths('organization', resourceIdMap);

        return this.canDoAction(organizationDomain, 'organization', 'update-logo', path);
    }

    // function for checking if the current user has permission to delete logos
    public canDeleteLogos(currentTenantId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        const path = this.buildPaths('organization', resourceIdMap);

        return this.canDoAction(organizationDomain, 'organization', 'update-logo', path);
    }

    // ### Alarms Permissions ###

    // function for checking if the current user has permission to get alarms
    public canGetAlarms(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('network-alarm', resourceIdMap);

        return this.canDoAction(networkDomain, 'network-alarm', READ, path);
    }

    // function for checking if the current user has permission to get alarms
    public canReadAlarms(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('network', resourceIdMap);

        return this.canDoAction(networkDomain, 'network', 'read-alarms', path);
    }

    // function for checking if the current user has permission to get alarms
    public canReadElasticSearch(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        const path = this.buildPaths('network-group', resourceIdMap);

        // TODO check if right domain
        if (currentNetworkId === null) {
            return this.canDoAction(networkDomain, 'network-group', 'read-elastic-search', path, true);
        } else {
            return this.canDoAction(networkDomain, 'network', 'read-elastic-search', path, true);
        }
    }

    public canReadAllElasticSearch(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        const path = this.buildPaths('network-group', resourceIdMap);

        // TODO check if right domain
        if (currentNetworkId === null) {
            return this.canDoAction(networkDomain, 'network-group', 'read-elastic-search', path, false);
        } else {
            return this.canDoAction(networkDomain, 'network', 'read-elastic-search', path, false);
        }
    }

    // ### Azure Subscriptions Authorization Checks ###

    // function for checking if the current user has permission to list subscriptions
    public canListAzureSubscriptions(networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, null);

        const path = this.buildPaths('azure-subscription', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-subscription', READ, path);
    }

    // function for checking if the current user has permission to get a specific subscription
    public canGetAzureSubscription(subscriptionId: string, networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, subscriptionId);

        const path = this.buildPaths('azure-subscription', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-subscription', READ, path);
    }

    // function for checking if the current user has permission to create a subscription
    public canCreateAzureSubscriptions(networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, null);

        const path = this.buildPaths('azure-subscription', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-subscription', CREATE, path);
    }

    // function for checking if the current user has permission to update a subscription
    public canUpdateAzureSubscription(subscriptionId: string, networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, subscriptionId);

        const path = this.buildPaths('azure-subscription', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-subscription', UPDATE, path);
    }

    // function for checking if the current user has permission to delete a subscription
    public canDeleteAzureSubscription(subscriptionId: string, networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, subscriptionId);

        const path = this.buildPaths('azure-subscription', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-subscription', DELETE, path);
    }

    // ### Azure Resource Group Authorization Checks ###

    // function for checking if the current user has permission to list resource groups
    public canListAzureResourceGroups(networkGroupId?: string, currentSubscriptionId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, currentSubscriptionId);

        const path = this.buildPaths('azure-resource-group', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-resource-group', READ, path);
    }

    // function for checking if the current user has permission to list resource groups
    public canGetAzureResourceGroups(
        resourceGroupId: string,
        networkGroupId?: string,
        currentSubscriptionId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, currentSubscriptionId);

        resourceIdMap['azure-resource-group'] = resourceGroupId;

        const path = this.buildPaths('azure-resource-group', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-resource-group', READ, path);
    }

    // ### Azure Virtual Wans Authorization Checks ###

    // function for checking if the current user has permission to list virtual wans
    public canListAzureVirtualWans(networkGroupId?: string, currentSubscriptionId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, currentSubscriptionId);

        const path = this.buildPaths('azure-virtual-wan', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-virtual-wan', READ, path);
    }

    // function for checking if the current user has permission to list virtual wans
    public canGetAzureVirtualWans(
        virtualWanId: string,
        networkGroupId?: string,
        currentSubscriptionId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, currentSubscriptionId);

        resourceIdMap['azure-virtual-wan'] = virtualWanId;

        const path = this.buildPaths('azure-virtual-wan', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-virtual-wan', READ, path);
    }

    // ### Azure Virtual Wan Sites Authorization Checks ###

    // function for checking if the current user has permission to list virtual wan sites
    public canListAzureVirtualWanSites(networkGroupId?: string, currentSubscriptionId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, currentSubscriptionId);

        const path = this.buildPaths('azure-virtual-wan-site', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-virtual-wan-site', READ, path);
    }

    // function for checking if the current user has permission to get virtual wan sites
    public canGetAzureVirtualWanSites(
        virtualWanSiteId: string,
        networkGroupId?: string,
        currentSubscriptionId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, currentSubscriptionId);

        resourceIdMap['azure-virtual-wan-site'] = virtualWanSiteId;

        const path = this.buildPaths('azure-virtual-wan-site', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-virtual-wan-site', READ, path);
    }

    // function for checking if the current user has permission to create virtual wan sites
    public canCreateAzureVirtualWanSites(networkGroupId?: string, currentSubscriptionId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, currentSubscriptionId);

        const path = this.buildPaths('azure-virtual-wan-site', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-virtual-wan-site', CREATE, path);
    }

    // function for checking if the current user has permission to update virtual wan site
    public canUpdateAzureVirtualWanSite(
        virtualWanSiteId: string,
        networkGroupId?: string,
        currentSubscriptionId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, currentSubscriptionId);

        resourceIdMap['azure-virtual-wan-site'] = virtualWanSiteId;

        const path = this.buildPaths('azure-virtual-wan-site', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-virtual-wan-site', UPDATE, path);
    }

    // function for checking if the current user has permission to delete virtual wan sites
    public canDeleteAzureVirtualWanSites(
        virtualWanSiteId: string,
        networkGroupId?: string,
        currentSubscriptionId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, currentSubscriptionId);

        resourceIdMap['azure-virtual-wan-site'] = virtualWanSiteId;

        const path = this.buildPaths('azure-virtual-wan-site', resourceIdMap);

        return this.canDoAction(networkDomain, 'azure-virtual-wan-site', DELETE, path);
    }

    // function for checking if the current user has permission to deploy virtual wan sites
    public canDeployAzureVirtualWanSites(
        virtualWanSiteId: string,
        networkGroupId?: string,
        currentSubscriptionId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, undefined, currentSubscriptionId);

        resourceIdMap['azure-virtual-wan-site'] = virtualWanSiteId;

        const path = this.buildPaths('azure-virtual-wan-site', resourceIdMap);
        // TODO update when edge changes are out
        return this.canDoAction(networkDomain, 'azure-virtual-wan-site', UPDATE, path);
    }

    // ### Data Center Authorization Checks ###

    // function for checking if the current user has permission to list data centers
    public canListDataCenters(): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('data-center', resourceIdMap);

        // TODO check if the domain is correct
        return this.canDoAction(networkDomain, 'data-center', READ, path);
    }

    // function for checking if the current user has permission to get a data center
    public canGetDataCenter(dataCenterId): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        resourceIdMap['data-center'] = dataCenterId;

        const path = this.buildPaths('data-center', resourceIdMap);

        // TODO check if the domain is correct
        return this.canDoAction(networkDomain, 'data-center', READ, dataCenterId);
    }

    // ### Geo Region Authorization Checks ###

    // function for checking if the current user has permission to list geo regions
    public canListGeoRegions(): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('geo-region', resourceIdMap);

        return this.canDoAction(networkDomain, 'geo-region', READ, path);
    }

    // function for checking if the current user has permission to get a geo region
    public canGetGeoRegion(geoRegionId): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        resourceIdMap['geo-region'] = geoRegionId;

        const path = this.buildPaths('geo-region', resourceIdMap);

        // TODO check if the domain is correct
        return this.canDoAction(networkDomain, 'geo-region', READ, geoRegionId);
    }

    // TODO: figure out what is going on with billing/billing auth
    // ### Billing Keys Authorization Checks ###

    // function for checking if the current user has permission to list billing keys
    public canListBillingKeys(currentOrgId?: string): boolean {
        // TODO add proper billing auth when available
        return true;
    }

    // function for checking if the current user has permission to get a billing key
    public canGetBillingKey(keyId: string, currentOrgId?: string): boolean {
        // TODO add proper billing auth when available
        return true;
    }

    // function for checking if the current user has permission to update a billing key
    public canUpdateBillingKey(keyId: string, currentOrgId?: string): boolean {
        // TODO add proper billing auth when available
        return true;
    }

    // ### Billing Service Types Authorization Checks ###

    // function for checking if the current user has permission to list servicetypes
    public canListComponentTypes(): boolean {
        // TODO add proper billing auth when available
        return true;
    }

    // function for checking if the current user has permission to get a servicetype
    public canGetComponentType(serviceTypeId: string): boolean {
        // TODO add proper billing auth when available
        return true;
    }

    // ### Billing Invoices Authorization Checks ###

    // function for checking if the current user has permission to list invoices
    public canListInvoices(currentOrgId?: string): boolean {
        // TODO add proper billing auth when available
        return true;
    }

    // ### Billing Delete Subscription Authorization Checks ###

    // function for checking if the current user has permission to list invoices
    public canDeleteBillingSubscription(currentOrgId?: string): boolean {
        // TODO add proper billing auth when available
        return true;
    }

    // function for checking if the current user has permission to list invoices
    public canListBillingSubscriptions(currentOrgId?: string): boolean {
        // TODO add proper billing auth when available
        return true;
    }

    // function for checking if the current user has permission to list invoices
    public canGetBillingSubscription(subscriptionId: string, currentOrgId?: string): boolean {
        // TODO add proper billing auth when available
        return true;
    }

    // ### Gateway Cluster Authorization Checks ###

    // function for checking if the current user has permission to list gateway-clusters
    public canListGatewayClusters(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('gateway-cluster', resourceIdMap);

        return this.canDoAction(networkDomain, 'gateway-cluster', READ, path);
    }

    // function for checking if the current user has permission to get a specific gateway-clusters
    public canGetGatewayCluster(clusterId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['gateway-cluster'] = clusterId;

        const path = this.buildPaths('gateway-cluster', resourceIdMap);

        return this.canDoAction(networkDomain, 'gateway-cluster', READ, path);
    }

    // function for checking if the current user has permission to create an gateway-clusters
    public canCreateGatewayClusters(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('gateway-cluster', resourceIdMap);

        return this.canDoAction(networkDomain, 'gateway-cluster', CREATE, path);
    }

    // function for checking if the current user has permission to update an gateway-clusters
    public canUpdateGatewayCluster(clusterId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['gateway-cluster'] = clusterId;

        const path = this.buildPaths('gateway-cluster', resourceIdMap);

        return this.canDoAction(networkDomain, 'gateway-cluster', UPDATE, path);
    }

    // function for checking if the current user has permission to delete an gateway-clusters
    public canDeleteGatewayCluster(clusterId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['gateway-cluster'] = clusterId;

        const path = this.buildPaths('gateway-cluster', resourceIdMap);

        return this.canDoAction(networkDomain, 'gateway-cluster', DELETE, path);
    }

    public canShareGatewayCluster(clusterId: string, networkGroupId?: string, currentNetworkId?: string) {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['gateway-cluster'] = clusterId;

        const path = this.buildPaths('gateway-cluster', resourceIdMap);
        // TODO update when edge auth is updated
        return this.canDoAction(networkDomain, 'gateway-cluster', READ, path);
    }

    // function for checking if the current user has permission to list organizations
    public canListOrganizations(): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, null);

        const path = this.buildPaths('network-group', resourceIdMap);

        return this.canDoAction(networkDomain, 'network-group', READ, path, true);
    }

    // function for checking if the current user has permission to get a specific organzation
    public canGetOrganization(networkGroupId: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        const path = this.buildPaths('network-group', resourceIdMap);

        // TODO check if right domain
        const orgId = networkGroupId != null ? networkGroupId : this.networkGroupId;
        return this.canDoAction(networkDomain, 'network-group', READ, path);
    }

    // function for checking if the current user has permission to create a specific organzation
    public canCreateOrganization(networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        const path = this.buildPaths('network-group', resourceIdMap);

        // TODO check if right domain
        return this.canDoAction(networkDomain, 'network-group', CREATE, path, true);
    }

    // function for checking if the current user has permission to update an organization
    public canUpdateOrganization(networkGroupId: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        const path = this.buildPaths('network-group', resourceIdMap);

        // TODO check if right domain
        const orgId = networkGroupId != null ? networkGroupId : this.networkGroupId;
        return this.canDoAction(networkDomain, 'network-group', UPDATE, path);
    }

    // function for checking if the current user has permission to delete a specific organzation
    public canDeleteOrganization(networkGroupId: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        const path = this.buildPaths('network-group', resourceIdMap);

        return this.canDoAction(networkDomain, 'network-group', DELETE, path);
    }

    public canViewPaymentProfile(): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, null);
        const path = this.buildPaths('payment-profile', resourceIdMap);
        return this.canDoAction(accountDomain, 'payment-profile', READ, path, true);
    }

    public canEditPaymentProfile(): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, null);
        const path = this.buildPaths('payment-profile', resourceIdMap);
        return this.canDoAction(accountDomain, 'payment-profile', UPDATE, path, true);
    }

    public canViewEnterpriseBillingSubscriptions(): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, null);
        const path = this.buildPaths('enterprise-subscriptions', resourceIdMap);
        return this.canDoAction(accountDomain, 'enterprise-subscriptions', READ, path, true);
    }

    public canViewBillingSubscriptions(): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, null);
        const path = this.buildPaths('self-service-subscription', resourceIdMap);
        return this.canDoAction(accountDomain, 'self-service-subscription', READ, path, true);
    }

    public canEditBillingSubscriptions(): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, null);
        const path = this.buildPaths('self-service-subscription', resourceIdMap);
        return this.canDoAction(accountDomain, 'self-service-subscription', UPDATE, path, true);
    }

    // ### Tenants Authorization Checks ###

    // function for checking if the current user has permission to list tenants
    public canListTenants(): boolean {
        const resourceIdMap = this.buildResourceIdMap(null);

        const path = this.buildPaths('organization', resourceIdMap);

        return this.canDoAction(organizationDomain, 'organization', READ, path, true);
    }

    // function for checking if the current user has permission to create a tenant
    public canCreateTenant(): boolean {
        const resourceIdMap = this.buildResourceIdMap(null);

        const path = this.buildPaths('organization', resourceIdMap);

        return this.canDoAction(organizationDomain, 'organization', CREATE, path);
    }

    // function for checking if the current user has permission to update a tenant
    public canUpdateTenant(currentTenantId: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        const path = this.buildPaths('organization', resourceIdMap);

        return this.canDoAction(organizationDomain, 'organization', UPDATE, path);
    }

    // function for checking if the current user has permission to deactivate a tenant
    public canActivateOrDeactivateTenant(currentTenantId: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(currentTenantId);

        const path = this.buildPaths('organization', resourceIdMap);

        return this.canDoAction(organizationDomain, 'organization', 'update-active', path);
    }

    // ### Support Path Authorization Checks ###

    // function for checking if the current user has permission to list support paths
    public canListSupportPaths(): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('support-path', resourceIdMap);

        return this.canDoAction(networkDomain, 'support-path', READ, path);
    }

    // function for checking if the current user has permission to create support path
    public canCreateSupportPaths(): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('support-path', resourceIdMap);

        return this.canDoAction(networkDomain, 'support-path', CREATE, path);
    }

    // function for checking if the current user has permission to delete support path
    public canDeleteSupportPaths(): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('support-path', resourceIdMap);

        return this.canDoAction(networkDomain, 'support-path', DELETE, path);
    }

    // ### Certificate Authorities - cas Authorization Checks ###

    // function for checking if the current user has permission to list CAs
    public canListCAs(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('certificate-authority', resourceIdMap);

        return this.canDoAction(networkDomain, 'certificate-authority', READ, path);
    }

    // function for checking if the current user has permission to create or verify CAs
    public canCreateCAs(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('certificate-authority', resourceIdMap);

        return this.canDoAction(networkDomain, 'certificate-authority', CREATE, path);
    }

    // function for checking if the current user has permission to delete a CA
    public canDeleteCA(caId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['certificate-authority'] = caId;

        const path = this.buildPaths('certificate-authority', resourceIdMap);

        return this.canDoAction(networkDomain, 'certificate-authority', DELETE, path);
    }

    // function for checking if the current user has permission to delete a CA
    public canUpdateCA(caId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['certificate-authority'] = caId;

        const path = this.buildPaths('certificate-authority', resourceIdMap);

        return this.canDoAction(networkDomain, 'certificate-authority', UPDATE, path);
    }

    // ### Authentication Policies - authPolicies Authorization Checks ###

    // function for checking if the current user has permission to list Auth Policies
    public canListAuthPolicies(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('auth-policy', resourceIdMap);

        return this.canDoAction(networkDomain, 'auth-policy', READ, path);
    }

    // function for checking if the current user has permission to create or verify AuthPolicies
    public canCreateAuthPolicies(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('auth-policy', resourceIdMap);

        return this.canDoAction(networkDomain, 'auth-policy', CREATE, path);
    }

    // function for checking if the current user has permission to delete a AuthPolicies
    public canDeleteAuthPolicies(caId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['auth-policy'] = caId;

        const path = this.buildPaths('auth-policy', resourceIdMap);

        return this.canDoAction(networkDomain, 'auth-policy', DELETE, path);
    }

    // function for checking if the current user has permission to delete a AuthPolicies
    public canUpdateAuthPolicies(caId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['auth-policy'] = caId;

        const path = this.buildPaths('auth-policy', resourceIdMap);

        return this.canDoAction(networkDomain, 'auth-policy', UPDATE, path);
    }
    // ### JWT Signers - jwtSigners Authorization Checks ###

    // function for checking if the current user has permission to list JWTSigners
    public canListJwtSigners(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('external-jwt-signer', resourceIdMap);

        return this.canDoAction(networkDomain, 'external-jwt-signer', READ, path);
    }

    // function for checking if the current user has permission to create or verify JWTSigners
    public canCreateJwtSigners(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('external-jwt-signer', resourceIdMap);

        return this.canDoAction(networkDomain, 'external-jwt-signer', CREATE, path);
    }

    // function for checking if the current user has permission to delete a JWTSigners
    public canDeleteJwtSigners(jwtId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['external-jwt-signer'] = jwtId;

        const path = this.buildPaths('external-jwt-signer', resourceIdMap);

        return this.canDoAction(networkDomain, 'external-jwt-signer', DELETE, path);
    }

    // function for checking if the current user has permission to delete a JWTSigners
    public canUpdateJwtSigners(jwtId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['external-jwt-signer'] = jwtId;

        const path = this.buildPaths('external-jwt-signer', resourceIdMap);

        return this.canDoAction(networkDomain, 'external-jwt-signer', UPDATE, path);
    }

    /* Netflow Collectors Checks */
    public canListNetflowCollectors() {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('netflow-collector', resourceIdMap);

        return this.canDoAction(networkDomain, 'netflow-collector', READ, path);
    }

    public canCreateNetflowCollectors(networkGroupId?: string) {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        const path = this.buildPaths('netflow-collector', resourceIdMap);

        return this.canDoAction(networkDomain, 'netflow-collector', CREATE, path);
    }

    public canUpdateNetflowCollector(collectorId: string, networkGroupId?: string) {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        resourceIdMap['netflow-collector'] = collectorId;

        const path = this.buildPaths('netflow-collector', resourceIdMap);

        return this.canDoAction(networkDomain, 'netflow-collector', UPDATE, path);
    }

    public canDeleteNetflowCollector(collectorId: string, networkGroupId?: string) {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        resourceIdMap['netflow-collector'] = collectorId;

        const path = this.buildPaths('netflow-collector', resourceIdMap);

        return this.canDoAction(networkDomain, 'netflow-collector', DELETE, path);
    }

    /* Netflow Exports Checks */
    public canListExports() {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('netflow-export', resourceIdMap);

        return this.canDoAction(networkDomain, 'netflow-export', READ, path);
    }

    public canCreateExports(networkGroupId?: string) {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        const path = this.buildPaths('netflow-export', resourceIdMap);

        return this.canDoAction(networkDomain, 'netflow-export', CREATE, path);
    }

    public canUpdateExport(exportId: string, networkGroupId?: string) {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        resourceIdMap['netflow-export'] = exportId;

        const path = this.buildPaths('netflow-export', resourceIdMap);

        return this.canDoAction(networkDomain, 'netflow-export', UPDATE, path);
    }

    public canDeleteExport(exportId: string, networkGroupId?: string) {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        resourceIdMap['netflow-export'] = exportId;

        const path = this.buildPaths('netflow-export', resourceIdMap);

        return this.canDoAction(networkDomain, 'netflow-export', DELETE, path);
    }

    /* Netflow Exporters Checks */

    // TODO build functions out once URNs are available
    public canListExporters() {
        return true;
    }

    // ### TransferNode Authorization Checks ###

    // function for checking if the current user has permission to list transfernodes
    public canListTransferNodes(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('transfer-node', resourceIdMap);

        return this.canDoAction(networkDomain, 'transfer-node', READ, path);
    }

    // function for checking if the current user has permission to get a specific transfernode
    public canGetTransferNode(transfernodeId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['transfer-node'] = transfernodeId;

        const path = this.buildPaths('transfer-node', resourceIdMap);

        // TODO check if right domain
        return this.canDoAction(networkDomain, 'transfer-node', READ, path);
    }

    // function for checking if the current user has permission to create an transfernode
    public canCreateTransferNodes(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('transfer-node', resourceIdMap);

        // TODO check if right domain
        return this.canDoAction(networkDomain, 'transfer-node', CREATE, path);
    }

    // function for checking if the current user has permission to update an transfernode
    public canUpdateTransferNode(transfernodeId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['transfer-node'] = transfernodeId;

        const path = this.buildPaths('transfer-node', resourceIdMap);

        // TODO check if right domain
        return this.canDoAction(networkDomain, 'transfer-node', UPDATE, path);
    }

    // function for checking if the current user has permission to delete an transfernode
    public canDeleteTransferNode(transfernodeId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['transfer-node'] = transfernodeId;

        const path = this.buildPaths('transfer-node', resourceIdMap);

        return this.canDoAction(networkDomain, 'transfer-node', DELETE, path);
    }

    // ### TransferNode Pool Authorization Checks ###

    // function for checking if the current user has permission to list transfernode pools
    public canListTransferNodePools(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('transfer-node-pool', resourceIdMap);

        // TODO check if right domain
        return this.canDoAction(networkDomain, 'transfer-node-pool', READ, path);
    }

    // function for checking if the current user has permission to create transfernode pools
    public canCreateTransferNodePools(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('transfer-node-pool', resourceIdMap);

        // TODO check if right domain
        return this.canDoAction(networkDomain, 'transfer-node-pool', CREATE, path);
    }

    // function for checking if the current user has permission to update a transfernode pool
    public canUpdateTransferNodePool(
        transferNodePoolId: string,
        networkGroupId?: string,
        currentNetworkId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['transfer-node-pool'] = transferNodePoolId;

        const path = this.buildPaths('transfer-node-pool', resourceIdMap);

        // TODO check if right domain
        return this.canDoAction(networkDomain, 'transfer-node-pool', UPDATE, path);
    }

    // function for checking if the current user has permission to delete a transfernode pool
    public canDeleteTransferNodePool(
        transferNodePoolId: string,
        networkGroupId?: string,
        currentNetworkId?: string
    ): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['transfer-node-pool'] = transferNodePoolId;

        const path = this.buildPaths('transfer-node-pool', resourceIdMap);

        return this.canDoAction(networkDomain, 'transfer-node-pool', DELETE, path);
    }

    /** Clientsync Permissions */

    public canListClientSyncs() {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('client-sync', resourceIdMap);

        return this.canDoAction(networkDomain, 'client-sync', READ, path);
    }

    public canGetClientSync(syncId: string) {
        const resourceIdMap = this.buildResourceIdMap();

        resourceIdMap['client-sync'] = syncId;

        const path = this.buildPaths('client-sync', resourceIdMap);

        return this.canDoAction(networkDomain, 'client-sync', READ, path);
    }

    public canCreateClientSyncs() {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('client-sync', resourceIdMap);

        return this.canDoAction(networkDomain, 'client-sync', CREATE, path);
    }

    public canDeleteClientSync(syncId) {
        const resourceIdMap = this.buildResourceIdMap();

        resourceIdMap['client-sync'] = syncId;

        const path = this.buildPaths('client-sync', resourceIdMap);

        return this.canDoAction(networkDomain, 'client-sync', DELETE, path);
    }

    public canUpdateClientSync(syncId) {
        const resourceIdMap = this.buildResourceIdMap();

        resourceIdMap['client-sync'] = syncId;

        const path = this.buildPaths('client-sync', resourceIdMap);

        return this.canDoAction(networkDomain, 'client-sync', UPDATE, path);
    }

    // ### Healing Alerts Authorization Checks ###

    // function for checking if the current user has permission to list healing alerts
    public canListHealingAlerts(): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('healing-alert', resourceIdMap);

        return this.canDoAction(networkDomain, 'healing-alert', READ, path);
    }

    // function for checking if the current user has permission to get healing alerts
    public canGetHealingAlerts(alertId): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        resourceIdMap['healing-alert'] = alertId;

        const path = this.buildPaths('healing-alert', resourceIdMap);

        return this.canDoAction(networkDomain, 'healing-alert', READ, path);
    }

    // function for checking if the current user has permission to create healing alerts
    public canCreateHealingAlerts(): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('healing-alert', resourceIdMap);

        return this.canDoAction(networkDomain, 'healing-alert', CREATE, path);
    }

    // ### Healing Exemption Configuration Authorization Checks ###

    // function for checking if the current user has permission to list Exemption Configuration
    public canListExemptionConfiguration(): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('exemption-configuration', resourceIdMap);

        return this.canDoAction(networkDomain, 'exemption-configuration', READ, path);
    }

    // function for checking if the current user has permission to get Exemption Configuration
    public canGetExemptionConfiguration(alertId): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        resourceIdMap['exemption-configuration'] = alertId;

        const path = this.buildPaths('exemption-configuration', resourceIdMap);

        return this.canDoAction(networkDomain, 'exemption-configuration', READ, path);
    }

    // function for checking if the current user has permission to create Exemption Configuration
    public canCreateExemptionConfiguration(): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        const path = this.buildPaths('exemption-configuration', resourceIdMap);

        return this.canDoAction(networkDomain, 'exemption-configuration', CREATE, path);
    }

    // function for checking if the current user has permission to update Exemption Configuration
    public canUpdateExemptionConfiguration(alertId): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        resourceIdMap['exemption-configuration'] = alertId;

        const path = this.buildPaths('exemption-configuration', resourceIdMap);

        return this.canDoAction(networkDomain, 'exemption-configuration', UPDATE, path);
    }

    // function for checking if the current user has permission to delete Exemption Configuration
    public canDeleteExemptionConfiguration(alertId): boolean {
        const resourceIdMap = this.buildResourceIdMap();

        resourceIdMap['exemption-configuration'] = alertId;

        const path = this.buildPaths('exemption-configuration', resourceIdMap);

        return this.canDoAction(networkDomain, 'exemption-configuration', DELETE, path);
    }

    // function for checking if the current user has permission to list posture checks
    public canListPostureChecks(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('posture-check', resourceIdMap);

        return this.canDoAction(networkDomain, 'posture-check', READ, path);
    }

    // function for checking if the current user has permission to get a specific posture check
    public canGetPostureCheck(postureCheckId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['posture-check'] = postureCheckId;

        const path = this.buildPaths('posture-check', resourceIdMap);

        return this.canDoAction(networkDomain, 'posture-check', READ, path);
    }

    // function for checking if the current user has permission to create an posture check
    public canCreatePostureChecks(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('posture-check', resourceIdMap);

        return this.canDoAction(networkDomain, 'posture-check', CREATE, path);
    }

    // function for checking if the current user has permission to update an posture check
    public canUpdatePostureCheck(postureCheckId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['posture-check'] = postureCheckId;

        const path = this.buildPaths('posture-check', resourceIdMap);

        return this.canDoAction(networkDomain, 'posture-check', UPDATE, path);
    }

    // function for checking if the current user has permission to delete an posture check
    public canDeletePostureCheck(postureCheckId: string, networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        resourceIdMap['posture-check'] = postureCheckId;

        const path = this.buildPaths('posture-check', resourceIdMap);

        return this.canDoAction(networkDomain, 'posture-check', DELETE, path);
    }

    public canListStoredSecret( networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        const path = this.buildPaths('stored-secret', resourceIdMap);

        return this.canDoAction(networkDomain, 'stored-secret', READ, path);

    }
    public canCreateStoredSecret( networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);
        
        const path = this.buildPaths('stored-secret', resourceIdMap);

        return this.canDoAction(networkDomain, 'stored-secret', CREATE, path);

    }

    public canUpdateStoredSecret(storedSecretId: string, networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        resourceIdMap['stored-secret'] = storedSecretId;
        
        const path = this.buildPaths('stored-secret', resourceIdMap);

        return this.canDoAction(networkDomain, 'stored-secret', UPDATE, path);

    }

    public canDeleteStoredSecret(storedSecretId: string, networkGroupId?: string): boolean {
  
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        resourceIdMap['stored-secret'] = storedSecretId;
        
        const path = this.buildPaths('stored-secret', resourceIdMap);

        return this.canDoAction(networkDomain, 'stored-secret', DELETE, path);

    }

    public canCreateCustomerHostedNetwork(networkGroupId?): boolean {
        
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);
        const path = this.buildPaths('network', resourceIdMap);

        return this.canDoAction(networkDomain, 'network', 'create-customer-hosted', path);

    }



    public isSupportUser(): boolean {
        let isSupportUser = false;
        for (const grantKey of Object.keys(this.grants)) {
            const grant = this.grants[grantKey];
            if (
                grant.id === '97315ed6-2fc7-4a40-b7ea-abe53cc6a188' ||
                grant.id === '665bf926-a4de-4a65-a59e-1206e0ff6fcb' ||
                (grant.type.toLowerCase().includes('superuser') &&
                    grant.domainId !== undefined &&
                    grant.domainId === null)
            ) {
                isSupportUser = true;
                break;
            }
        }

        return isSupportUser;
    }

    // public isSuperUser(): boolean {
    //   let isSuperUser = false;
    //   for (const grantKey of Object.keys(this.grants)) {
    //     const grant = this.grants[grantKey];
    //     if (
    //       grant.type.toLowerCase().includes('superuser') &&
    //       grant.domainId !== undefined &&
    //       grant.domainId === null
    //     ) {
    //       isSuperUser = true;
    //       break;
    //     }
    //   }
    //
    //   return isSuperUser;
    // }

       /* stored secret permissions */

        // function for checking if the current user has permission to list stored secrets
    public canReadStoredSecrets(networkGroupId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId);

        const path = this.buildPaths('stored-secret', resourceIdMap);

        return this.canDoAction(networkDomain, 'stored-secret', READ, path);
    }

    public canCreateStoredSecrets(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('stored-secret', resourceIdMap);

        return this.canDoAction(networkDomain, 'stored-secret', CREATE, path);
    }

    public canDeleteStoredSecrets(networkGroupId?: string, currentNetworkId?: string): boolean {
        const resourceIdMap = this.buildResourceIdMap(undefined, networkGroupId, currentNetworkId);

        const path = this.buildPaths('stored-secret', resourceIdMap);

        return this.canDoAction(networkDomain, 'stored-secret', DELETE, path);
    }

    public canUpdateProcesses(networkId?: string): boolean {
      const resourceIdMap = this.buildResourceIdMap(undefined, undefined, networkId);

      const path = this.buildPaths('process-execution', resourceIdMap);

      return this.canDoAction(networkDomain, 'process-execution', UPDATE, path);
    }

    hasNoStandardRoles() {
        return Object.keys(this.standardRoleTypes).length === 0;
    }


    private canDoAction(
        domainCode: string,
        resourceCode: string,
        actionCode: string,
        resourcePaths: any[],
        anyResource?: boolean
    ) {
        let hasPermission = false;
        if (this.permissions != null) {
            // attempt to get the domain from the permission map
            const domain = this.permissions[domainCode];
            // if the domain is defined
            if (domain != null) {
                // attempt to get the resource type from the domain
                const resourceType = domain[resourceCode];

                // if the resource type is defined
                if (resourceType != null) {
                    // attempt to get the action from the resource type
                    const action = resourceType[actionCode];

                    // if the action is defined
                    if (action != null) {
                        if (anyResource) {
                            hasPermission = true;
                        } else {
                            // making hasPermission as true
                            hasPermission = false;
                            // looping through the paths on the action
                            for (const grantPathKey of Object.keys(action)) {
                                const grantPaths = action[grantPathKey];

                                // index for accessing the resource paths
                                let index = 0;
                                hasPermission = true;
                                for (const paths of grantPaths) {
                                    for (const path of paths) {
                                        // getting the path that needs to be checked
                                        const checkedPath = resourcePaths[index];
                                        if (checkedPath == null) {
                                            hasPermission = false;
                                            break;
                                        }
                                        // if the two paths are not the same
                                        if (
                                            checkedPath.resourceTypeId !== path.resourceTypeId ||
                                            checkedPath.resourceId !== path.resourceId
                                        ) {
                                            // mark that the user does not have permission and break out of the loop
                                            hasPermission = false;
                                            break;
                                        }
                                        index++;
                                    }
                                }

                                if (hasPermission) {
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }

        return hasPermission;
    }

    /**
     * function for building the resource ID map to be passed into the build paths function
     * all parameters are optional. If a parameter is not provided the default value will be used
     *
     * if instead the parameter is intentionally set to null, the item will be skipped
     *  this allows the console to exclude the path when checking a generic action like create
     *
     * @param organizationId the ID of the organization the resource belongs to
     * @param networkGroupId the ID of the network gorup the resource belongs to
     * @param networkId the ID of the network the resoure belongs to
     * @param azureSubscriptionId the ID of the azure subscription the resource belongs to
     */
    private buildResourceIdMap(
        organizationId?: string,
        networkGroupId?: string,
        networkId?: string,
        azureSubscriptionId?: string
    ) {
        // map of resource IDs to be returned
        const resourceIdMap = {};

        // skipping of networkGroupId was explicitly set to null
        if (networkGroupId !== null) {
            // setting the network group ID based on whether or not one was provided
            resourceIdMap['network-group'] = networkGroupId !== undefined ? networkGroupId : this.networkGroupId;
        }

        // skipping of organizationId was explicitly set to null
        if (organizationId !== null) {
            // setting the organization ID based on whether or not one was provided
            resourceIdMap['organization'] = organizationId !== undefined ? organizationId : this.currentTenantId;
        }

        // skipping of networkId was explicitly set to null
        if (networkId !== null) {
            // setting the network ID based on whether or not one was provided
            resourceIdMap['network'] = networkId !== undefined ? networkId : this.currentNetworkId;
        }

        // skipping of azureSubscriptionId was explicitly set to null
        if (azureSubscriptionId !== null) {
            // setting the azure subscription ID based on whether or not one was provided
            resourceIdMap['azure-subscription'] =
                azureSubscriptionId !== undefined ? azureSubscriptionId : this.currentSubscriptionId;
        }

        return resourceIdMap;
    }

    /**
     * function for building the paths object for a resource
     *
     * resourceTypeCode: the code for the base resource type
     * resourceIdMap: a map relating the resourceType to the resource ID for the parent resources
     */
    private buildPaths(resourceTypeCode, resourceIdMap) {
        // getting the resource type
        let resourceType = this.resourceTypeMap[resourceTypeCode];

        // path list to be returned
        let paths = [];

        // if the ID of the for the current resource type was provided
        if (resourceIdMap[resourceTypeCode] != null) {
            // starting to build the path
            paths = [{ resourceTypeId: resourceType.id, resourceId: resourceIdMap[resourceTypeCode] }];
        }

        // continuing while the resource type is not null and the resource has a parent ID
        while (resourceType && resourceType.parentId != null) {
            // getting the parent resource type
            const parentResourceType = this.resourceTypeIdMap[resourceType.parentId];

            // if the parent resource type is not null
            if (parentResourceType != null) {
                // add a new path for the parent
                paths.unshift({
                    resourceTypeId: parentResourceType.id,
                    resourceId: resourceIdMap[parentResourceType.code],
                });

                // set the current resource type to the parent
                resourceType = parentResourceType;
            } else {
                resourceType = null;
            }
        }
        return paths;
    }
}
