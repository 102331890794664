import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-services-bulk-upload-save',
    templateUrl: './services-bulk-upload-save.component.html',
    styleUrls: ['./services-bulk-upload-save.component.scss'],
})
export class ServicesBulkUploadSaveComponent implements OnInit, OnDestroy {
    @Input() businessKey;
    @Input() networkId;
    @Input() services = [];
    @Input() type = 'Services';

    serviceNames = null;
    network;
    servicesStatus = [];
    message;
    timeout = 5000;
    stepStyle;
    isPageActive = true;

    constructor(public apiService: ApiService, private logger: LoggerService) {}

    ngOnInit() {
        this.message = this.type + ' As Code Upload Process Initiated';
    }

    ngOnDestroy() {
        // to exit from the loop in the async function, when user navigates away from the page
        this.isPageActive = false;
    }

    async fetchStatus() {
        this.servicesStatus = [];
        this.stepStyle = 'step0';
        this.message = this.type + ' As Code Upload Process Initiated';

        let isCompleted = null;
        while (isCompleted == null && this.isPageActive) {
            isCompleted = await this.fetchServicesAsCodeStatus();
        }
    }

    fetchServicesAsCodeStatus(): Promise<boolean> {
        return new Promise((resolve, reject): any => {
            this.logger.info('business Key ' + this.businessKey);
            const serviceNamesArray = [];
            const maxSixe = this.services.length > 3 ? 3 : this.services.length;
            this.logger.info('as code max size ', maxSixe);
            for (let i = 0; i < maxSixe; i++) {
                serviceNamesArray.push(this.services[i]['name']);
            }
            this.serviceNames = serviceNamesArray.length > 0 ? serviceNamesArray.toString() : null;
            if (this.services.length > 3) {
                this.serviceNames = this.serviceNames + '...';
            }

            setTimeout(() => {
                this.apiService
                    .get(`workflow-status/${this.businessKey}?networkId=${this.networkId}`)
                    .toPromise()
                    .then(
                        (status) => {
                            if (status !== undefined && status['length'] > 0) {
                                this.message = this.type + ' As Code Upload Process In Progress';
                                // to store the status that we display to the user
                                this.servicesStatus = [];
                                const statusList = status[0]['data'];
                                if (statusList.length > 0) {
                                    let index = -1;
                                    for (let i = 1; i < statusList.length; i++) {
                                        const currentTask = statusList[i]['task'];
                                        // do not display start event and end event in the status page
                                        if (
                                            currentTask.includes('Start Event') ||
                                            currentTask.includes('End Event') ||
                                            currentTask === 'Start' ||
                                            currentTask === 'End' ||
                                            currentTask.includes('Sub Process Start') ||
                                            currentTask.includes('Sub Process End')
                                        ) {
                                            continue;
                                        }
                                        // display all meaningful messages in the status page
                                        if (index < 0 || this.servicesStatus[index]['task'] !== currentTask) {
                                            this.servicesStatus.push(statusList[i]);
                                            index++;
                                        } else {
                                            continue;
                                        }
                                        // check the percentage of completion
                                        if (currentTask === 'Call Create Service' && this.stepStyle === 'step0') {
                                            this.stepStyle = 'step1';
                                        } else if (
                                            currentTask === 'Waiting For Service Create Request' &&
                                            this.stepStyle !== 'step2'
                                        ) {
                                            this.stepStyle = 'step2';
                                        } else if (
                                            currentTask === 'Waiting For Service Create Request' &&
                                            this.stepStyle === 'step2'
                                        ) {
                                            this.stepStyle = 'step3';
                                        } else if (currentTask === 'Check Status Of Services') {
                                            this.stepStyle = 'step4';
                                        }
                                    }
                                }
                                if (statusList[statusList.length - 1]['task'].includes('End Event')) {
                                    this.stepStyle = 'step5';
                                    if (statusList[statusList.length - 2]['task'].includes('Error')) {
                                        this.message = this.type + ' As Code Upload Process Errored Out';
                                        this.logger.info(this.message);
                                        // is completed = false, i.e. failed
                                        resolve(false);
                                    } else {
                                        this.message = this.type + ' As Code Upload Process Completed';
                                        this.logger.info(this.message);
                                        // is completed = true
                                        resolve(true);
                                    }
                                } else {
                                    this.message = this.servicesStatus[this.servicesStatus.length - 1]['task'];
                                }
                            } else {
                                this.message = this.type + ' As Code Upload Process workflow is not yet initiated';
                            }
                            // is completed = null, i.e. process is running
                            resolve(null);
                        },
                        (error) => {
                            this.message = this.type + ' As Code Upload Process Status Check failed';
                            // is completed = false, i.e. failed
                            resolve(false);
                        }
                    );
            }, this.timeout);
        });
    }
}
