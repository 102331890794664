import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppWan } from '@netfoundry-ui/shared/model';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-appwan-bulk-upload-save',
    templateUrl: './appwan-bulk-upload-save.component.html',
    styleUrls: ['./appwan-bulk-upload-save.component.scss'],
})
export class AppwanBulkUploadSaveComponent implements OnInit, OnDestroy {
    @Input() businessKey;
    @Input() networkId;
    @Input() appWan: AppWan = new AppWan({});
    @Output() uploaded = new EventEmitter<void>();
    appWanName = null;
    network;
    appwanStatus = [];
    message;
    timeout = 5000;
    stepStyle;
    isPageActive = true;

    constructor(public apiService: ApiService, private logger: LoggerService) {}

    ngOnInit() {
        this.message = 'Appwan As Code Upload Process Initiated';
    }

    ngOnDestroy() {
        // to exit from the loop in the async function, when user navigates away from the page
        this.isPageActive = false;
    }

    async fetchStatus() {
        this.appwanStatus = [];
        this.stepStyle = 'step0';
        this.message = 'Appwan As Code Upload Process Initiated';

        let isCompleted = null;
        while (isCompleted == null && this.isPageActive) {
            isCompleted = await this.fetchAppWanAsCodeStatus();
        }
        if (isCompleted !== null && isCompleted && this.isPageActive) {
            this.uploaded.emit();
        }
    }

    fetchAppWanAsCodeStatus(): Promise<boolean> {
        return new Promise((resolve, reject): any => {
            this.logger.info('business Key ' + this.businessKey);
            this.appWanName = this.appWan ? this.appWan['name'] : null;

            setTimeout(() => {
                this.apiService
                    .get(`workflow-status/${this.businessKey}?networkId=${this.networkId}`)
                    .toPromise()
                    .then(
                        (status) => {
                            if (status !== undefined && status['length'] > 0) {
                                this.message = 'Appwan As Code Upload Process In Progress';
                                // to store the status that we display to the user
                                this.appwanStatus = [];
                                const statusList = status[0]['data'];
                                if (statusList.length > 0) {
                                    let index = -1;
                                    for (let i = 1; i < statusList.length; i++) {
                                        const currentTask = statusList[i]['task'];
                                        // do not display start event and end event in the status page
                                        if (
                                            currentTask == null ||
                                            currentTask.includes('Start Event') ||
                                            currentTask.includes('End Event') ||
                                            currentTask.includes('Intermediate Task')
                                        ) {
                                            continue;
                                        }
                                        // display all meaningful messages in the status page
                                        if (index < 0 || this.appwanStatus[index]['task'] !== currentTask) {
                                            this.appwanStatus.push(statusList[i]);
                                            index++;
                                        } else {
                                            continue;
                                        }
                                        // check the percentage of completion
                                        if (currentTask.includes('Call Create AppWan')) {
                                            this.stepStyle = 'step1';
                                        } else if (currentTask.includes('Create Endpoint Groups')) {
                                            this.stepStyle = 'step2';
                                        } else if (currentTask.includes('Associate Gateways and Clients To AppWan')) {
                                            this.stepStyle = 'step3';
                                        } else if (
                                            currentTask.includes('Associate Gateways And Clients To EndpointGroups')
                                        ) {
                                            this.stepStyle = 'step4';
                                        } else if (currentTask.includes('Associate Services To AppWan')) {
                                            this.stepStyle = 'step5';
                                        }
                                    }
                                }
                                if (statusList[statusList.length - 1]['task'].includes('End Event')) {
                                    this.stepStyle = 'step6';
                                    if (statusList[statusList.length - 1]['task'].includes('Error')) {
                                        this.message = 'Appwan As Code Upload Process Errored Out';
                                        this.logger.info(this.message);
                                        // is completed = false, i.e. failed
                                        resolve(false);
                                    } else {
                                        this.message = 'Appwan As Code Upload Process Completed';
                                        this.logger.info(this.message);
                                        // is completed = true
                                        resolve(true);
                                    }
                                } else {
                                    this.message = this.appwanStatus[this.appwanStatus.length - 1]['task'];
                                }
                            } else {
                                this.message = 'Appwan As Code Upload Process workflow is not yet initiated';
                            }
                            // is completed = null, i.e. process is running
                            resolve(null);
                        },
                        (error) => {
                            this.message = 'Appwan As Code Upload Process Status Check failed';
                            // is completed = false, i.e. failed
                            resolve(false);
                        }
                    );
            }, this.timeout);
        });
    }
}
