import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'lib-item-selector',
    templateUrl: './item-selector.component.html',
    styleUrls: ['./item-selector.component.scss'],
})
export class ItemSelectorComponent implements OnChanges {

  @Input() currentItem: any = {};
  @Input() items: any[] = [];
  @Input() isOpened = false;
  @Input() filterString = '';
  @Input() showAddNewItem = true;
  @Input() showManageItems = true;
  @Input() showHeader = true;
  @Input() addNewItemLabel = 'Add a New Network';
  @Input() manageItemsLabel = 'Manage Networks';
  @Input() manageItemsLink = '/networks';
  @Input() alwaysOpen = false;
  @Output() goToManageItems: EventEmitter<any> = new EventEmitter();
  @Output() currentItemChange: EventEmitter<any> = new EventEmitter();
  @Output() filterStringChange: EventEmitter<any> = new EventEmitter();
  @Output() addNewItem: EventEmitter<any> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    this.resetSelected();
  }

  resetSelected() {
    this.items.forEach((listItem) => {
      listItem.selected = this.currentItem?.id === listItem.id;
    });
  }

  toggleList(event: any) {
    this.isOpened = !this.isOpened;
  }

  stopProp(event: any) {}

  itemSelected(item: any) {
    this.currentItemChange.emit(item);
    this.isOpened = false;
    this.resetSelected();
    item.selected = true;
    this.closeItemList();
  }

  addNetItemSelected(event: any) {
    this.addNewItem.emit(event);
    this.closeItemList();
  }

  manageItemsSelected(event: any) {
    this.isOpened = false;
    this.goToManageItems.emit(event);
    this.closeItemList();
  }

  handleClickOutside(event: any) {
    this.closeItemList();
  }

  closeItemList() {
    if (this.alwaysOpen) {
      this.isOpened = true;
      return;
    }
    this.isOpened = false;
  }
}
