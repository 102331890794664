import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { MatDialogModule } from '@angular/material/dialog';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { FeatureTagsSelectorModule } from '@netfoundry-ui/feature/tags-selector';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { PostureCheckFormComponent } from './posture-check-form.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModelModule,
        MatDialogModule,
        UiLoaderModule,
        FeatureTagsSelectorModule,
        MatTooltipModule,
    ],
    declarations: [PostureCheckFormComponent],
    exports: [PostureCheckFormComponent],
})
export class FeatureFormPostureCheckFormModule {}
