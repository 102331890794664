import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IamService } from '@netfoundry-ui/shared/authorization';
import { GrowlerService } from '@netfoundry-ui/shared/growler';
import { ApiService, ErrorHistoryService, ValidateService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-ticket-form',
    templateUrl: './ticket-form.component.html',
    styleUrls: ['./ticket-form.component.scss'],
})
export class TicketFormComponent implements OnInit {
    email = '';
    name = '';
    type = 'Incident / Problem / Tech Support';
    subject = '';
    severity = 'Normal';
    description = '';
    isComplete = false;
    processing = false;
    errors = [];

    form = {
        selectedTenantId: '',
        selectedNetworkId: '',
        subject: '',
        comment: '',
        recentErrorMessages: [],
        type: 'incident',
        priority: 'normal',
    };

    typeList = [
        { name: 'Incident / Problem / Tech Support', value: 'incident' },
        { name: 'Question / Need / Feature Request', value: 'question' },
        { name: 'Change Request', value: 'task' },
    ];

    private subscription: Subscription = new Subscription();

    constructor(
        private iamService: IamService,
        private growlerService: GrowlerService,
        private apiService: ApiService,
        private validateService: ValidateService,
        private errorHistoryService: ErrorHistoryService,
        private dialogRef: MatDialogRef<TicketFormComponent>
    ) {}

    ngOnInit() {
        this.form.selectedTenantId = this.apiService.theTenantIs.id;
        this.form.selectedNetworkId = this.apiService.theNetworkIs.getId();

        this.reset();
        this.email = localStorage.getItem('profile_email');
        this.name = localStorage.getItem('profile_nick');
    }

    send() {
        if (this.validate()) {
            this.processing = true;

            this.form.recentErrorMessages = this.errorHistoryService.getErrorHistroy();

            this.subscription.add(
                this.iamService.submitSupportTicket(this.form).subscribe(() => {
                    this.processing = false;
                    this.isComplete = true;
                })
            );
        }
    }

    reset() {
        this.email = '';
        this.name = '';
        this.type = 'Incident / Problem / Tech Support';
        this.subject = '';
        this.severity = 'Normal';
        this.description = '';
        this.processing = false;
        this.isComplete = false;
    }

    clear() {
        this.description = '';
        this.form.comment = '';
    }

    cancel() {
        this.reset();
        this.isComplete = false;
        this.dialogRef.close();
    }

    open() {
        window.open('http://support.netfoundry.io');
    }

    validate() {
        let isValid = true;
        this.errors = [];

        if (!this.validateService.hasValue(this.form.subject)) {
            isValid = false;
            this.errors['subject'] = true;
        }

        if (!this.validateService.hasValue(this.form.comment)) {
            isValid = false;
            this.errors['comment'] = true;
        }

        if (!this.validateService.hasValue(this.form.type)) {
            this.errors['type'] = true;
            isValid = false;
        }

        if (!this.validateService.hasValue(this.form.priority)) {
            this.errors['severity'] = true;
            isValid = false;
        }

        return isValid;
    }
}
