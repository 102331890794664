<div class="progress-bar-container">
    <div
        *ngFor="let step of steps; let index = index"
        [ngClass]="{ 'last-step': index === steps.length - 1, 'step-done': currentStep.id > step.id }"
        class="progress-step-container"
    >
        <div class="progress-step-item">
            <div class="progress-step-ball"></div>
            <div class="progress-step-label">{{ step.name }}</div>
        </div>
        <div *ngIf="index !== steps.length - 1">
            <div class="progress-step-bar"></div>
        </div>
    </div>
</div>
