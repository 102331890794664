import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GatewayFormsService } from '@netfoundry-ui/shared/gateway';
import { Collector, Endpoint, Exports, GatewayCluster } from '@netfoundry-ui/shared/model';
import { CollectorService, ExportersService, ExportsService } from '@netfoundry-ui/shared/netflow';
import {
    FeatureService,
    GatewayClusterService,
    LoggerService,
    NetworkGroupService,
    RegionService,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { RegionTransformPipe } from '@netfoundry-ui/ui/pipes';
import { Subscription } from 'rxjs';

const gatewayType = 'AWSCPEGW';

const exporterType = 'dvn-gateway';

const awsAutoScaling = 'AwsAutoScale';

@Component({
    selector: 'app-aws-gateway-form',
    templateUrl: './aws-gateway-form.component.html',
    styleUrls: ['./aws-gateway-form.component.scss', '../gatewayform.component.scss'],
})
export class AwsGatewayFormComponent implements OnInit, OnDestroy {
    @Input() isInline = false;
    @Input() public currentOrgId;
    @Input() public currentNetworkId;
    @Input() showBreakoutFields = false;
    @Input() hideHaUpgrade = false;
    @Input() isAwsAutoScale = false;
    @Output() back: EventEmitter<boolean> = new EventEmitter();
    @Output() hide: EventEmitter<any> = new EventEmitter();
    @Output() newGateway: EventEmitter<Endpoint> = new EventEmitter();

    model: Endpoint = new Endpoint({});
    public clusterModel = new GatewayCluster({});

    gatewayTypes = [];
    protectionTypes = [];

    isComplete = false;
    regions: Location[] = [];
    errorName = false;
    errorNameLength = false;
    errorType = false;
    errorLocation = false;
    errorRegion = false;
    errorNextHop = false;
    errorCollector = false;
    errorFrequency = false;
    gateways = [];
    regKey = [];
    attempt = 0;
    isLoading = false;
    isHAGateway = false;
    isBuilding = true;
    provisionedString = 'has been created';
    buildingString = 'is building';
    completedTitleStatusString = this.buildingString;
    timeout = 150;
    processing = false;
    hideHelp = false;
    netflowEnabled = false;
    exportModel = new Exports({});
    collectors = [];
    errors = {
        name: false,
        azureSiteName: false,
        azureResourceGroupName: false,
        azureVirtualWanId: false,
        publicIpdAddress: false,
        bgpPeeringAddress: false,
        bpgASN: false,
        bgpPeerWeight: false,
        deviceLinkSpeed: false,
        deviceVendor: false,
        deviceModel: false,
        bpgNeighborASN: false,
        bgpPeeringNeighborAddress: false,
    };
    maxLength = '64';
    private updatedRegKeySub = new Subscription();
    private subscription = new Subscription();
    private regionId = '';

    constructor(
        private logger: LoggerService,
        private regionService: RegionService,
        public featureService: FeatureService,
        private clusterService: GatewayClusterService,
        public zitiEnabledService: ZitiEnabledService,
        public gatewayFormService: GatewayFormsService,
        public authorizationService: AuthorizationService,
        private collectorService: CollectorService,
        private exportersService: ExportersService,
        private exportsService: ExportsService,
        private organizationService: NetworkGroupService,
        private regionTransform: RegionTransformPipe
    ) {
        this.exportModel.exporterType = exporterType;
        this.exportModel.exportIntervalSeconds = 20;
    }

    ngOnInit() {
        if (this.isAwsAutoScale) {
            this.isHAGateway = true;
            this.clusterModel.protectionType = awsAutoScaling;
        }

        this.processing = false;
        this.errorName = false;
        this.errorLocation = false;
        this.errorRegion = false;
        this.errorType = false;

        // geo region for gateways
        this.subscription.add(
            this.regionService.get().subscribe((result) => {
                this.regions = this.regionTransform.transform(
                    result as Location[],
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                );
            })
        );

        // Reg Key
        // subscribe to changes to updatedRegKey
        this.updatedRegKeySub.add(
            this.gatewayFormService.updatedRegKey.subscribe((isUpdated) => {
                this.regKey = this.gatewayFormService.regKey;
                this.gateways = this.gatewayFormService.gateways;
                // if the registration key was not obtained and there were less than 10 attempts
                if (!isUpdated) {
                    this.attempt++;
                    // setting a 250ms timeout before calling loadGateway again
                    setTimeout(() => {
                        if (this.isHAGateway) {
                            if (this.isAwsAutoScale) this.gatewayFormService.loadAutoScalingGateway(this.clusterModel);
                            else this.gatewayFormService.loadGatewayCluster(this.clusterModel);
                        } else this.gatewayFormService.loadGateway(this.model);
                    }, this.gatewayFormService.timeout);
                } else {
                    this.completedTitleStatusString = this.provisionedString;
                    this.isBuilding = false;
                }
            })
        );

        if (this.authorizationService.canListNetflowCollectors() && this.authorizationService.canCreateExports()) {
            if (this.authorizationService.canListOrganizations()) {
                this.subscription.add(
                    this.organizationService.get().subscribe((res) => {
                        if (res.length > 1) {
                            this.getCollectors();
                        }
                    })
                );
                this.getCollectors({ networkGroupIdsIn: [this.currentOrgId] });
            } else {
                this.getCollectors({ networkGroupIdsIn: [this.currentOrgId] });
            }
        }

        this.protectionTypes = this.clusterService.getProtectionTypes();
    }

    getCollectors(params?) {
        this.subscription.add(
            this.collectorService.find(params).subscribe((result) => {
                this.collectors = result as Collector[];
            })
        );
    }

    ngOnDestroy() {
        this.updatedRegKeySub.unsubscribe();
        this.subscription.unsubscribe();
    }

    goBack() {
        this.back.emit(true);
    }

    hideForm(response?: unknown) {
        this.hide.emit(response);
    }

    async save() {
        this.logger.info('Saving...');
        if (this.validate()) {
            this.processing = true;
            this.model.endpointType = gatewayType;

            if (this.isHAGateway) {
                const result = await this.gatewayFormService.saveHa(this.clusterModel, this.model);
                this.processing = false;
                if (result != null) {
                    this.clusterModel = new GatewayCluster(result);
                    this.model = new Endpoint(result);
                    this.isComplete = true;

                    // setting the title to indicate that the gateways are building
                    this.completedTitleStatusString = this.buildingString;
                    if (!this.isAwsAutoScale) {
                        this.gatewayFormService.loadGatewayCluster(this.clusterModel);
                    } else {
                        this.gatewayFormService.loadAutoScalingGateway(this.clusterModel);
                    }
                }
            } else {
                const result = await this.gatewayFormService.save(this.model);

                this.processing = false;
                if (result != null) {
                    this.model = result;
                    this.completedTitleStatusString = this.buildingString;
                    this.newGateway.emit(new Endpoint(this.model));

                    if (!this.netflowEnabled) {
                        this.isComplete = true;
                    } else {
                        this.subscription.add(
                            this.exportersService
                                .findByNativeId(exporterType, this.model.id, {
                                    networkId: this.currentNetworkId,
                                })
                                .subscribe((result) => {
                                    this.exportModel.exporterKey = result.key;
                                    this.exportModel.networkGroupId = this.currentOrgId;
                                    this.exportModel.name = this.model.name;

                                    this.subscription.add(
                                        this.exportsService.save(this.exportModel).subscribe((result) => {
                                            this.isComplete = true;
                                        })
                                    );
                                })
                        );
                    }

                    // attempting to get the registration key for the gateway
                    this.gatewayFormService.loadGateway(this.model);
                }
            }
            this.gatewayFormService.getAwsRegionId(this.model).then((regionId) => {
                this.regionId = regionId;
            });
        } else {
            this.logger.info('Gateway form is invalid');
        }
    }

    share() {
        this.gatewayFormService.share(this.gateways[0]);
    }

    validate(): boolean {
        let exportForValidation;
        if (this.netflowEnabled) {
            exportForValidation = this.exportModel;
        }

        const validateResult = this.gatewayFormService.validate(
            this.model,
            this.isHAGateway,
            gatewayType,
            undefined,
            exportForValidation
        );

        this.errorName = validateResult.errorName;
        this.errorLocation = validateResult.errorRegion;
        this.errorType = validateResult.errorType;
        this.errorRegion = validateResult.errorRegion;
        this.errorNameLength = validateResult.errorNameLength;
        this.errorNextHop = validateResult.errorNextHop;
        this.errorCollector = validateResult.errorCollector;
        this.errorFrequency = validateResult.errorFrequency;

        return validateResult.isValid;
    }

    openCloudFormationLink(gateway) {
        this.gatewayFormService.openCloudFormationLink(gateway, this.regionId);
    }

    openAutoScaleCloudFormationLink() {
        this.gatewayFormService.openAutoScaleCloudFormationLink(this.model, this.regionId);
    }

    toggleHAGateway() {
        this.isHAGateway = !this.isHAGateway;
        if (this.isHAGateway) {
            this.maxLength = '59';
            this.model.name = this.model.name.substring(0, 59);
        } else {
            this.maxLength = '64';
        }
    }

    toggleNetflowEnabled() {
        this.netflowEnabled = !this.netflowEnabled;
    }

    downloadJWT(model, index) {
        this.gatewayFormService.downloadJWT(model, index);
    }
}
