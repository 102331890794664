import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { MatDialogModule } from '@angular/material/dialog';
import { FeatureHighchartsGenericModule } from '@netfoundry-ui/feature/highcharts-generic';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { ElkVisualizationComponent } from './elk-visualization.component';

@NgModule({
    imports: [CommonModule, UiPipesModule, MatDialogModule, FeatureHighchartsGenericModule, UiLoaderModule],
    declarations: [ElkVisualizationComponent],
    exports: [ElkVisualizationComponent],
})
export class FeatureElkVisualizationModule {}
