import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class SessionTimerService {
    sessionStartTime;

    sessionTime;

    setSessionStart() {
        this.sessionStartTime = moment();

        this.startSessionTime();
    }

    startSessionTime() {
        setInterval(() => {
            const currentDate = moment();
            const duration = moment.duration(currentDate.diff(this.sessionStartTime, 'seconds'), 'seconds');
            this.sessionTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
        }, 1000);
    }
}
