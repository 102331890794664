<div class="modal solid" id="AddAllModal">
    <div (click)="close()" class="closeButton icon-HamburgerAppClose" id="CloseAddAllButton"></div>
    <div class="title">What Would You Like to Add?</div>
    <div class="grid three">
        <div></div>
        <div (click)="open('network')" *ngIf="canCreateNetworks" class="iconButton" id="addNetwork">
            <div class="image icon-networks"></div>
            <div class="label">Add a New<br />Network</div>
        </div>
        <div></div>
    </div>
    <div class="grid two">
        <div (click)="open('appwan')" *ngIf="canCreateAppwans" class="iconButton" id="addAppwan">
            <div class="image icon-appwan"></div>
            <div class="label">
                Add a New<br />{{ featureService.enableNewNavigation ? 'Service Policy' : 'AppWAN' }}
            </div>
        </div>

        <div
            (click)="open('endpoint')"
            *ngIf="canCreateEndpoints && networkVersion >= 7"
            class="iconButton"
            id="addEndpoint"
        >
            <div class="image icon-endpoints"></div>
            <div class="label">Add a New<br />{{ featureService.enableNewNavigation ? 'Identity' : 'Endpoint' }}</div>
        </div>

        <div
            (click)="open('edgeRouter')"
            *ngIf="canCreateEdgeRouters && networkVersion >= 7"
            class="iconButton"
            id="addEdgeRouter"
        >
            <div class="image icon-routers"></div>
            <div class="label">Add a New<br />Edge Router</div>
        </div>

        <div
            (click)="open('edgeRouterPolicy')"
            *ngIf="canCreateEdgeRouterPolicies && networkVersion >= 7"
            class="iconButton"
            id="addEdgeRouterPolicy"
        >
            <div class="image icon-routerpolicy"></div>
            <div class="label">Add a New<br />Edge Router Policy</div>
        </div>

        <div
            (click)="open('gateway')"
            *ngIf="canCreateEndpoints && networkVersion < 7"
            class="iconButton"
            id="addGateway"
        >
            <div class="image icon-gateways"></div>
            <div class="label">Add a New<br />Gateway</div>
        </div>
        <div (click)="open('service')" *ngIf="canCreateServices" class="iconButton" id="addService">
            <div class="image icon-addServices"></div>
            <div class="label">Add a New<br />Service</div>
        </div>
        <div
            (click)="open('client')"
            *ngIf="canCreateEndpoints && networkVersion < 7"
            class="iconButton"
            id="addClient"
        >
            <div class="image icon-clients"></div>
            <div class="label">Add a New<br />Client</div>
        </div>
        <div></div>
        <div (click)="open('group')" *ngIf="canCreateGroups && networkVersion < 7" class="iconButton" id="addGroup">
            <div class="image icon-groups"></div>
            <div class="label">Add a New<br />Group</div>
        </div>
    </div>
</div>
