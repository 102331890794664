import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('external-jwt-signers')
export class JwtSigner extends Resource {
    id;
    networkId;
    zitiId;
    name;
    enabled = true;
    externalAuthUrl;
    issuer;
    clientId;
    scopes;
    audience;
    jwksEndpoint;
    claimsProperty = 'Subject';
    useExternalId = false;
    certPem;
    kid;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    appliedAt: string;
    deletedAt: string;
    deletedBy: string;
    deleted: string;
}
