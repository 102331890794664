import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Endpoint, GatewayCluster, Service } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    FeatureService,
    GatewayClusterService,
    GatewayService,
    ServiceService,
    ValidateService,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

const invalidString = 'Invalid';
const requiredString = 'Required';

const provisionedStatus = 300;
const registeredStatus = 400;

const standaloneTypeTag = '[S]';
const haTypeTag = '[HA]';
const autoscaleTypeTag = '[AS]';

@Component({
    selector: 'app-ip-host-service-form',
    templateUrl: './ip-host-service-form.component.html',
    styleUrls: ['./ip-host-service-form.component.scss'],
})
export class IpHostServiceFormComponent implements OnInit, OnDestroy, OnChanges {
    @Input() model: Service = new Service({
        bridgeStp: 'YES',
        collectionLocation: 'BOTH',
        cryptoLevel: 'STRONG',
        dnsOptions: 'NONE',
        icmpTunnel: 'NO',
        localNetworkGateway: 'YES',
        multicast: 'OFF',
        pbrType: 'WAN',
        permanentConnection: 'NO',
        rateSmoothing: 'NO',
        serviceInterceptType: 'IP',
    });

    @Input() canEdit = true;
    @Input() gatewayId;
    @Input() gatewaySelfLink;
    @Input() isInline = false;
    @Input() isCluster = false;
    @Input() isHAService = false;

    @Output() back: EventEmitter<boolean> = new EventEmitter();
    @Output() hide: EventEmitter<any> = new EventEmitter();

    ipHostGateways = [];
    isAdding = false;
    isEditing = false;
    isComplete = false;
    allowBack = true;

    gatewayMap = {};
    @Input() hideHelp = false;
    // these are used so that changes can be made to the model while still preserving the user's input to the form
    interceptPortRange;

    // variables for the network/interecpt port ranges and the intercept IP
    networkPortRange;
    interceptIp;
    processing = false;
    networkPortErrorString;
    interceptPortErrorString;
    networkIpErrorString;
    interceptIpErrorString;
    nameErrorString;
    transparencyEnabled = false;
    permanentConnectionEnabled = false;
    dataInterleavingEnabled = false;
    clusterCount = 0;
    errors = {
        name: false,
        serviceClass: false,
        endpointId: false,
        gatewayClusterId: false,
        serviceType: false,
        interceptIp: false,
        networkIp: false,
        networkFirstPort: false,
        networkLastPort: false,
        interceptFirstPort: false,
        interceptLastPort: false,
        gatewayCidrBlock: false,
        Network: false,
        NetworkIntercept: false,
    };
    disableGatewaySelect = false;
    private canGetEndpoint = false;
    private subscription = new Subscription();

    constructor(
        private gatewayService: GatewayService,
        private apiService: ApiService,
        private service: ServiceService,
        private growlerService: GrowlerService,
        private validateService: ValidateService,
        private clusterService: GatewayClusterService,
        public featureService: FeatureService,
        public zitiEnabledService: ZitiEnabledService,
        private authorizationService: AuthorizationService
    ) {
        this.model.endpointId = '';

        this.model = new Service({});
        this.isAdding = true;
        this.isEditing = false;
        this.allowBack = true;
        this.model = new Service({
            bridgeStp: 'YES',
            collectionLocation: 'BOTH',
            cryptoLevel: 'STRONG',
            dnsOptions: 'NONE',
            icmpTunnel: 'NO',
            localNetworkGateway: 'YES',
            multicast: 'OFF',
            pbrType: 'WAN',
            permanentConnection: 'NO',
            rateSmoothing: 'NO',
            serviceInterceptType: 'IP',
            gatewayClusterId: null,
        });
    }

    async ngOnInit() {
        this.canGetEndpoint = this.authorizationService.canGetEndpoint(this.gatewayId);
        this.processing = false;
        // check if isEditing is true
        if (this.isEditing) {
            if (this.canGetEndpoint) {
                // if it is a HA service, get the gateway cluster associated with the service
                if (this.isHAService) {
                    this.subscription.add(
                        this.apiService
                            .getLinkedResource(new Service(this.model), 'gatewayCluster')
                            .subscribe((result) => {
                                this.clusterCount++;
                                const cluster = new GatewayCluster(result);

                                // creating an object to store in the gateways lists
                                let typeTag = haTypeTag;
                                if (cluster.protectionType != null && cluster.protectionType === 'AwsAutoScale') {
                                    typeTag = autoscaleTypeTag;
                                }
                                const currEndpoint = {
                                    id: cluster.id,
                                    type: cluster.endpointType,
                                    isHa: true,
                                    name: cluster.name,
                                    typeTag: typeTag,
                                };

                                this.gatewayId = currEndpoint.id;
                                this.gatewayMap[currEndpoint.id] = currEndpoint;
                                // adding the endpoint object to the gateways and non ziti gateways lists
                                this.ipHostGateways.push(currEndpoint);
                            })
                    );
                } else {
                    // otherwise, get the endpoint associated with the service
                    this.subscription.add(
                        this.apiService.getLinkedResource(this.model, 'endpoint').subscribe((result) => {
                            const gateway = new Endpoint(result);

                            // creating an object to be stored in the gateways lists
                            const currEndpoint = {
                                id: gateway.id,
                                type: gateway.endpointType,
                                isHa: false,
                                name: gateway.name,
                                typeTag: standaloneTypeTag,
                            };

                            // adding the object to the gateways and non ziti gateways lists
                            this.ipHostGateways.push(currEndpoint);

                            this.gatewayId = currEndpoint.id;
                            this.gatewayMap[currEndpoint.id] = currEndpoint;
                        })
                    );
                }
            } else {
                if (this.isHAService) {
                    this.model.gatewayClusterId = this.gatewayId;
                } else {
                    this.model.endpointId = this.gatewayId;
                }
            }

            if (this.model.interceptDnsPort === 0) {
                this.model.interceptDnsPort = null;
            }
        } else if (this.gatewaySelfLink != null) {
            if (this.canGetEndpoint) {
                this.subscription.add(
                    this.apiService.get(this.gatewaySelfLink).subscribe((result) => {
                        if (this.isHAService) {
                            const cluster = new GatewayCluster(result);
                            this.clusterCount++;

                            let typeTag = haTypeTag;
                            if (cluster.protectionType != null && cluster.protectionType === 'AwsAutoScale') {
                                typeTag = autoscaleTypeTag;
                            }

                            // creating an object to store in the gateways lists
                            const currEndpoint = {
                                id: cluster.id,
                                type: cluster.endpointType,
                                isHa: true,
                                name: cluster.name,
                                typeTag: typeTag,
                            };

                            this.gatewayId = currEndpoint.id;
                            this.gatewayMap[currEndpoint.id] = currEndpoint;
                            // adding the endpoint object to the gateways and non ziti gateways lists
                            this.ipHostGateways.push(currEndpoint);
                        } else {
                            const gateway = new Endpoint(result);

                            // creating an object to be stored in the gateways lists
                            const currEndpoint = {
                                id: gateway.id,
                                type: gateway.endpointType,
                                isHa: false,
                                name: gateway.name,
                                typeTag: standaloneTypeTag,
                            };

                            // adding the object to the gateways and non ziti gateways lists
                            this.ipHostGateways.push(currEndpoint);

                            this.gatewayId = currEndpoint.id;
                            this.gatewayMap[currEndpoint.id] = currEndpoint;
                        }
                    })
                );
            } else {
                if (this.isHAService) {
                    this.model.gatewayClusterId = this.gatewayId;
                } else {
                    this.model.endpointId = this.gatewayId;
                }
            }
        } else {
            const proms = [];
            const allEndpointTypes = this.gatewayService.getGatewayTypes();
            const filterEndpointTypes = [];
            for (const endpointType of allEndpointTypes) {
                if (
                    endpointType.value !== 'ZTGW' &&
                    endpointType.value !== 'ZTNHGW' &&
                    endpointType.value !== 'L2VCPEGW'
                ) {
                    filterEndpointTypes.push(endpointType.value);
                }
            }
            const endpointTypesString = filterEndpointTypes.join(',');
            if (this.authorizationService.canListEndpoints()) {
                // if the form is not in an edit state, get all of the gateways and clusters
                const gatewayPromise = this.gatewayService
                    .get(undefined, undefined, undefined, undefined, endpointTypesString)
                    .pipe(take(1))
                    .toPromise()
                    .then((result) => {
                        // iterating over each gateway
                        for (const item of result) {
                            // creating a gateway object
                            const gateway = new Endpoint(item);

                            // creating an object to represent the endpoint in the list of gateways
                            const currEndpoint = {
                                id: gateway.id,
                                type: gateway.endpointType,
                                isHa: false,
                                name: gateway.name,
                                typeTag: standaloneTypeTag,
                            };

                            // if the gateway's status is less than 700 and not 500 and it does not belong to a closter
                            if (
                                (gateway.status === provisionedStatus || gateway.status === registeredStatus) &&
                                gateway['endpointProtectionRole'] == null
                            ) {
                                // push the current endpoint object to the list of gateways
                                this.gatewayMap[currEndpoint.id] = currEndpoint;
                                this.ipHostGateways.push(currEndpoint);
                            }
                        }
                    });
                proms.push(gatewayPromise);
            }

            if (this.authorizationService.canListGatewayClusters()) {
                // and all of the clusters
                const clusterPromise = this.clusterService
                    .get()
                    .pipe(take(1))
                    .toPromise()
                    .then((result) => {
                        for (const item of result) {
                            this.clusterCount++;
                            // converting the item to a cluster object
                            const cluster = new GatewayCluster(item);

                            let typeTag = haTypeTag;
                            if (cluster.protectionType != null && cluster.protectionType === 'AwsAutoScale') {
                                typeTag = autoscaleTypeTag;
                            }

                            // creating an object to represent the endpoint in the list of gateways
                            const currEndpoint = {
                                id: cluster.id,
                                type: cluster.endpointType,
                                isHa: true,
                                name: cluster.name,
                                typeTag: typeTag,
                            };

                            // if the cluster's status is less than 700 and not equal to 500
                            if (cluster.status === provisionedStatus) {
                                // adding the cluster to the list of gateways
                                this.gatewayMap[currEndpoint.id] = currEndpoint;
                                this.ipHostGateways.push(currEndpoint);
                            }
                        }
                    });
                proms.push(clusterPromise);
            }

            await Promise.all(proms);
        }
    }

    ngOnChanges() {
        if (this.gatewayId != null) {
            // disable gateway select
            this.disableGatewaySelect = true;
        }
        this.model = new Service(this.model);
        if (this.model.id != null) {
            this.isAdding = false;
            this.isEditing = true;
            this.allowBack = false;
            this.disableGatewaySelect = true;

            this.permanentConnectionEnabled = this.model.permanentConnection.toLowerCase() === 'yes';

            this.transparencyEnabled = this.model['transparency'].toLowerCase() === 'yes';

            this.dataInterleavingEnabled = this.model['dataInterleaving'].toLowerCase() === 'yes';

            this.isHAService = this.model.gatewayClusterId != null;

            this.gatewayId = this.isHAService ? this.model.gatewayClusterId : this.model.endpointId;

            if (this.model.protectionGroupId != null) {
                this.isHAService = true;
            }

            // if the model has an intercept IP and the intercept IP is different than the network IP
            if (this.model.interceptIp && this.model.interceptIp !== this.model.networkIp) {
                // setting the value of interceptIp to the value of hte model's intercept IP
                this.interceptIp = this.model.interceptIp;
            }

            // if the model has a network first and last port
            if (this.model.networkFirstPort !== '0' && this.model.networkLastPort !== '0') {
                // if the first port does not equal the last port
                if (this.model.networkFirstPort !== this.model.networkLastPort) {
                    // setting the port range with the first port - last port
                    this.networkPortRange = this.model.networkFirstPort + '-' + this.model.networkLastPort;
                } else {
                    // if the ports are equal, no need to specify the range with a dash
                    this.networkPortRange = this.model.networkFirstPort.toString();
                }
            }

            // if the intercept first and last ports exist
            if (this.model.interceptFirstPort !== '0' && this.model.interceptLastPort !== '0') {
                // if the intercept first port is different than the intercept last port
                if (this.model.interceptFirstPort !== this.model.interceptLastPort) {
                    // defining the port range and separting the range with a dash
                    this.interceptPortRange = this.model.interceptFirstPort + '-' + this.model.interceptLastPort;
                } else {
                    // if the ports are not a range, no need to use a dash
                    this.interceptPortRange = this.model.interceptFirstPort.toString();
                }
            }

            // if the intercept and network ports are equal, clear the interceptPortRange
            // this assumes that the user wants the network port range to be the same as the intercept port range
            // this allows the user to edit the network port range and change the intercept port range at the same time
            if (this.interceptPortRange === this.networkPortRange) {
                this.interceptPortRange = '';
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    clearErrors() {
        for (const error of Object.keys(this.errors)) {
            this.errors[error] = false;
        }

        this.interceptIpErrorString = '';
        this.interceptPortErrorString = '';
        this.nameErrorString = '';
        this.networkIpErrorString = '';
        this.networkPortErrorString = '';
    }

    save() {
        // clearing any errors
        this.clearErrors();

        // normalizing the IP addresses and ports

        this.normalizeNetworkIpAndInterceptIp();
        this.normalizeNetworkPorts();
        this.normalizeInterceptPorts();

        if (this.isAdding || this.canGetEndpoint) {
            const selectedGateway = this.gatewayMap[this.gatewayId];

            if (selectedGateway) {
                if (selectedGateway.isHa) {
                    this.model.gatewayClusterId = selectedGateway.id;
                    this.model.endpointId = null;
                    this.isHAService = true;
                } else {
                    this.model.endpointId = selectedGateway.id;
                    this.model.gatewayClusterId = null;
                }
            }
        }
        if (this.validate()) {
            this.processing = true;
            this.model.bridgeStp = 'YES';
            this.model.collectionLocation = 'BOTH';
            this.model.cryptoLevel = 'STRONG';
            this.model.dnsOptions = 'NONE';
            this.model.localNetworkGateway = 'YES';
            this.model.multicast = 'OFF';
            this.model.pbrType = 'WAN';
            this.model.rateSmoothing = 'NO';
            this.model.serviceInterceptType = 'IP';

            this.model.transparency = this.transparencyEnabled ? 'YES' : 'NO';

            this.model.permanentConnection = this.permanentConnectionEnabled ? 'YES' : 'NO';

            if (this.dataInterleavingEnabled) {
                this.model.dataInterleaving = 'YES';
                this.model.rateSmoothing = 'NO';
                this.model.lowLatency = 'NO';
            } else {
                this.model.dataInterleaving = 'NO';
                this.model.rateSmoothing = 'NO';
                this.model.lowLatency = 'YES';
            }
            this.subscription.add(
                this.service.save(this.model).subscribe(
                    (result) => {
                        if (this.isAdding) {
                            this.model = new Service(result);
                        }
                        // TODO: Check results for api errors
                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Creation Complete',
                                'Service information has been saved.'
                            )
                        );
                        this.isComplete = true;
                        this.processing = false;
                        this.hideForm({ updatedService: this.model });
                    },
                    (error) => {
                        this.processing = false;
                    }
                )
            );
        }
    }

    validate(): boolean {
        if (!this.validateService.hasValue(this.model.name)) {
            this.nameErrorString = requiredString;
        } else {
            this.nameErrorString = invalidString;
        }

        this.errors['name'] = !this.validateService.isValidName(this.model.name);
        if (this.isHAService) {
            // don't need to include gatewayClusterId in edit so no need to check
            if (!this.isEditing) {
                this.errors['gatewayClusterId'] = !this.validateService.hasValue(this.model.gatewayClusterId);
            }
        } else {
            this.errors['endpointId'] = !this.validateService.hasValue(this.model.endpointId);
        }

        this.errors['serviceType'] = !this.validateService.hasValue(this.model.serviceType);
        this.errors['networkIp'] = !this.validateService.isValidIP(this.model.networkIp);
        this.errors['interceptIp'] = !this.validateService.isValidIP(this.model.interceptIp);

        // checking if the first port is less than or equal to the last port
        const isValidNetworkPortRange = Number(this.model.networkFirstPort) <= Number(this.model.networkLastPort);
        // validating the first and last ports
        this.errors['networkFirstPort'] = !(
            this.validateService.isValidPort(this.model.networkFirstPort) && isValidNetworkPortRange
        );
        this.errors['networkLastPort'] = !(
            this.validateService.isValidPort(this.model.networkLastPort) && isValidNetworkPortRange
        );

        // checking if the first port number is less than or equal to the last port number
        const isValidInterceptPortRange = Number(this.model.interceptFirstPort) <= Number(this.model.interceptLastPort);

        // validating the first/last port numbers
        this.errors['interceptFirstPort'] = !(
            this.validateService.isValidPort(this.model.interceptFirstPort) && isValidInterceptPortRange
        );
        this.errors['interceptLastPort'] = !(
            this.validateService.isValidPort(this.model.interceptLastPort) && isValidInterceptPortRange
        );

        if (this.zitiEnabledService.zitiEnabled) {
            if (this.validateService.hasValue(this.model.interceptDnsHostname)) {
                this.errors['interceptDnsHostname'] = !this.validateService.isValidHostName(
                    this.model.interceptDnsHostname
                );
            }

            if (this.validateService.hasValue(this.model.interceptDnsPort)) {
                this.errors['interceptDnsPort'] = !this.validateService.isValidPort(this.model.interceptDnsPort);
            }
        }

        for (const error of Object.keys(this.errors)) {
            if (this.errors[error] === true) {
                return false;
            }
        }
        return true;
    }

    goBack() {
        this.back.emit(true);
    }

    hideForm(response?: unknown) {
        this.hide.emit(response);
    }

    toggleTransparency() {
        this.transparencyEnabled = !this.transparencyEnabled;
    }

    togglePermanentConnection() {
        this.permanentConnectionEnabled = !this.permanentConnectionEnabled;
    }

    toggleDataInterleavingEnabled() {
        this.dataInterleavingEnabled = !this.dataInterleavingEnabled;
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.hideForm(new Service(this.model));
    }

    private normalizeInterceptPorts() {
        // checking if a network port range was provided
        // NOTE: using != to check null as it checks both null and undefined
        if (this.interceptPortRange != null && this.interceptPortRange.trim() !== '') {
            // setting the error string to be displayed to Invalid in the event that there are errors
            this.interceptPortErrorString = invalidString;
            // splitting the port range on -
            const portRange = this.interceptPortRange.split('-');

            if (portRange.length === 1) {
                // if there is only one item in the list (i.e no -) use the same number as the first/last port numbers
                this.model.interceptFirstPort = this.interceptPortRange.trim();
                this.model.interceptLastPort = this.interceptPortRange.trim();
            } else if (portRange.length === 2) {
                // otherwise, if there are two items, using the first item as the first port and the second item as the last port
                this.model.interceptFirstPort = portRange[0].trim();
                this.model.interceptLastPort = portRange[1].trim();
            } else {
                // if there are more than 2 items (2 or more dashes), setting the first and last ports to be the empty string
                this.model.interceptFirstPort = '';
                this.model.interceptLastPort = '';
            }
        } else {
            // if there was no intercept port range provided, we won't display an error message
            // instead the fields will just be marked as an error
            this.interceptPortErrorString = '';

            // if no port range was provided, set the first/last ports to be equal to the network first/last ports
            this.model.interceptFirstPort = this.model.networkFirstPort;
            this.model.interceptLastPort = this.model.networkLastPort;
        }
    }

    private normalizeNetworkPorts() {
        // checking if a network port range was provided
        // NOTE: using != to check null as it checks both null and undefined
        if (this.networkPortRange != null && this.networkPortRange.trim() !== '') {
            // splitting the port range on -
            const portRange = this.networkPortRange.split('-');

            // if a network port range was provided, setting the error string to Invalid in the event that there are errors
            this.networkPortErrorString = invalidString;

            if (portRange.length === 1) {
                // if there is only one item in the list (i.e no -) use the same number as the first/last port numbers
                this.model.networkFirstPort = this.networkPortRange.trim();
                this.model.networkLastPort = this.networkPortRange.trim();
            } else if (portRange.length === 2) {
                // otherwise, if there are two items, using the first item as the first port and the second item as the last port
                this.model.networkFirstPort = portRange[0].trim();
                this.model.networkLastPort = portRange[1].trim();
            } else {
                // if there are more than 2 items (2 or more dashes), setting the first and last ports to be the empty string
                this.model.networkFirstPort = '';
                this.model.networkLastPort = '';
            }
        } else {
            // if there was no port range porvided, setting the error string to Required
            this.networkPortErrorString = requiredString;

            // otherwise, if the port range was not provided, setting the first and last ports to be the empty string
            this.model.networkFirstPort = '';
            this.model.networkLastPort = '';
        }
    }

    private normalizeNetworkIpAndInterceptIp() {
        // if the intercept IP was not provided, use the provided network IP
        // NOTE: using == for checking null as that checks both null and undefined
        if (this.interceptIp == null || this.interceptIp.trim() === '') {
            // set the intercept IP to be the same as the network IP
            this.model.interceptIp = this.model.networkIp;
            this.interceptIpErrorString = '';

            // otherwise, if the intercept IP was provided
            // NOTE: using != for checking null as that checks both null and undefined
        } else {
            // if the intercept IP was provided, setting the error string to Invalid in the event tha there are errors
            this.interceptIpErrorString = invalidString;

            // setting the intercept IP of the model to the user provided value
            this.model.interceptIp = this.interceptIp;
        }

        if (this.model.networkIp == null || this.model.networkIp.trim() === '') {
            // if the network IP was not provided, setting the error string to Required
            this.networkIpErrorString = requiredString;
        } else {
            // otherwise, setting the error string to Invalid in the event that there was an error
            this.networkIpErrorString = invalidString;
        }
    }
}
