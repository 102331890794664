<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="AddServiceModal">
    <div [hidden]="isSelected" class="fullRow" id="Stage1">
        <div [hidden]="!isAdding" class="title" style="filter: brightness(50%)">Create A New Service</div>
        <div class="subtitle">Choose a service type</div>
        <div class="row center">
            <div (click)="setClass('CS')" class="buttonCard selectType" id="HostServiceTypeButton">
                <div class="icon" style="background-image: url(assets/svgs/Icon_HostService.svg)"></div>
                <div class="title">IP Host Service</div>
                <div class="content">
                    Expose a single host to the NetFoundry Network. Provision access by assigning it to an AppWAN
                </div>
                <div class="row center buttonArea">
                    <button class="save" id="IpHostButton">Create an IP Host Service</button>
                </div>
            </div>
            <div
                (click)="setClass('GW')"
                *ngIf="!zitiEnabledService.zitiPure"
                class="buttonCard selectType"
                id="NetworkServiceTypeButton"
            >
                <div class="icon" style="background-image: url(assets/svgs/Icon_NetworkService.svg)"></div>
                <div class="title">IP Network Service</div>
                <div class="content">
                    Expose a CIDR block to the NetFoundry Network. Provision access by assigning it to an AppWAN
                </div>
                <div class="row center buttonArea">
                    <button class="save" id="NetworkHostButton">Create an IP Network Service</button>
                </div>
            </div>
            <div
                (click)="setClass('ICMP')"
                *ngIf="!isHAService && !zitiEnabledService.zitiPure"
                class="buttonCard selectType"
                id="HostICMPServiceTypeButton"
            >
                <div class="icon icmpHostIcon" style="background-image: url(assets/svgs/HostPingService.svg)"></div>
                <div class="title">Host Ping Service</div>
                <div class="content">
                    Expose ping to single host capability to the NetFoundry Network. Provision access by assigning it to
                    an ApppWAN
                </div>
                <div class="row center buttonArea">
                    <button class="save" id="HostICMPButton">Create a Host Ping Service</button>
                </div>
            </div>
            <div
                (click)="setClass('L2G')"
                *ngIf="!isHAService && !zitiEnabledService.zitiPure"
                class="buttonCard selectType"
                id="L2ServiceTypeButton"
            >
                <div class="icon icmpHostIcon" style="background-image: url(assets/svgs/VoIPService.svg)"></div>
                <div class="title">Voice Enhanced Service</div>
                <div class="content">
                    Create a VoIP Network Service. Can only be used in a Voice Enhanced AppWAN with a Voice Enhanced
                    Gateway.
                </div>
                <div class="row center buttonArea">
                    <button class="save" id="HostICMPButton">Create a Voice Enhanced Service</button>
                </div>
            </div>
            <div (click)="setClass('BULK_UPLOAD')" class="buttonCard selectType" id="BulkUploadServiceTypeButton">
                <div class="icon icmpHostIcon" style="background-image: url(assets/svgs/ServiceAsCode.svg)"></div>
                <div class="title">IMPORT YOUR SERVICE AS CODE</div>
                <div class="content">
                    Prefer working in code? Simplify provisioning of your service with a simple file upload
                </div>
                <div class="row center buttonArea">
                    <button class="save" id="HostICMPButton">Create Service</button>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="!isSelected" class="fullRow" id="Stage2">
        <div *ngIf="model.serviceClass === 'ICMP'" id="IcmpHostForm">
            <app-icmp-service-form
                (back)="back()"
                (hide)="hide($event)"
                [canEdit]="canEdit"
                [gatewayId]="endpointId"
                [gatewaySelfLink]="gatewaySelfLink"
                [hideHelp]="hideHelp"
                [isInline]="isInline"
                [model]="model"
            >
            </app-icmp-service-form>
        </div>
        <div *ngIf="model.serviceClass === 'L2G'" id="L2Form">
            <app-l2-service-form
                (back)="back()"
                (hide)="hide($event)"
                [canEdit]="canEdit"
                [gatewayId]="endpointId"
                [gatewaySelfLink]="gatewaySelfLink"
                [hideHelp]="hideHelp"
                [isInline]="isInline"
                [model]="model"
            >
            </app-l2-service-form>
        </div>
        <div *ngIf="model.serviceClass === 'GW'" id="IpNetworkForm">
            <app-ip-network-service-form
                (back)="back()"
                (hide)="hide($event)"
                [canEdit]="canEdit"
                [gatewayId]="endpointId"
                [gatewaySelfLink]="gatewaySelfLink"
                [hideHelp]="hideHelp"
                [isCluster]="isCluster"
                [isHAService]="isHAService"
                [isInline]="isInline"
                [model]="model"
            >
            </app-ip-network-service-form>
        </div>
        <div *ngIf="model.serviceClass === 'CS'" id="IpHostForm">
            <app-ip-host-service-form
                (back)="back()"
                (hide)="hide($event)"
                [canEdit]="canEdit"
                [gatewayId]="endpointId"
                [gatewaySelfLink]="gatewaySelfLink"
                [hideHelp]="hideHelp"
                [isCluster]="isCluster"
                [isHAService]="isHAService"
                [isInline]="isInline"
                [model]="model"
            >
            </app-ip-host-service-form>
        </div>
        <div *ngIf="model.serviceClass === 'BULK_UPLOAD'" id="BulkServicesForm">
            <app-bulk-upload-service-form
                (back)="back()"
                (hide)="hide($event)"
                [canEdit]="canEdit"
                [hideHelp]="hideHelp"
                [isInline]="isInline"
            >
            </app-bulk-upload-service-form>
        </div>
    </div>
    <div (click)="hide()" class="buttonBall close" id="CloseServiceButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div (click)="back()" [hidden]="!isSelected || !allowBack" class="buttonBall back" id="BackServiceButton">
        <div class="buttonLabel">BACK</div>
    </div>
</div>
