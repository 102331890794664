import {
    ApiService,
    FeatureService,
    LoggerService,
    MenuService,
    NetworkService,
    URLS,
    INFRASTRUCTURE_URLS,
    ZITI_URLS,
} from '@netfoundry-ui/shared/services';
import { Router } from '@angular/router';
import { Environment, ENVIRONMENT, Network, NetworkV2, Tenant } from '@netfoundry-ui/shared/model';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { NetworkUpgradeService } from '@netfoundry-ui/feature-network-upgrade';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { OrganizationPromptComponent } from '@netfoundry-ui/feature/form/organization-prompt';
import { MatDialog } from '@angular/material/dialog';
import { V2NetworkCreationFormComponent } from '@netfoundry-ui/feature/form/v2-network-creation-form';
import { Inject, Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { TicketFormComponent } from '@netfoundry-ui/feature/form/ticket-form';
import { ConsoleTourComponent } from '@netfoundry-ui/feature/console-tour';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';

export const ZAC_NAVIGATOR = {
    groups: [
        {
            label: '',
            menuItems: [
                {
                    label: 'Dashboard',
                    route: INFRASTRUCTURE_URLS.DASHBOARD,
                    iconClass: 'icon-Icon_Dash',
                    selectedRoutes: [INFRASTRUCTURE_URLS.DASHBOARD],
                    isMOPView: true,
                    requiresNetwork: true,
                },
            ],
        },
        {
            label: 'Core Components',
            menuItems: [
                {
                    label: 'Identities',
                    route: ZITI_URLS.ZITI_IDENTITIES,
                    iconClass: 'icon-endpoints',
                    selectedRoutes: [ZITI_URLS.ZITI_IDENTITIES, ZITI_URLS.ZITI_RECIPES, ZITI_URLS.ZITI_TERMINATORS, URLS.ZITIENDPOINTS],
                    requiresProvision: true,
                },
                {
                    label: 'Services',
                    route: ZITI_URLS.ZITI_SERVICES,
                    iconClass: 'icon-services',
                    selectedRoutes: [ZITI_URLS.ZITI_SERVICES, ZITI_URLS.ZITI_CONFIGS, ZITI_URLS.ZITI_CONFIG_TYPES, URLS.ZITISERVICES],
                    requiresProvision: true,
                },
                {
                    label: 'Routers',
                    route: ZITI_URLS.ZITI_ROUTERS,
                    iconClass: 'icon-routers',
                    selectedRoutes: [ZITI_URLS.ZITI_ROUTERS, ZITI_URLS.ZITI_TRANSIT_ROUTERS],
                    requiresProvision: true,
                },
            ],
        },
        {
            label: 'Access Management',
            menuItems: [
                {
                    label: 'Policies',
                    route: ZITI_URLS.ZITI_SERVICE_POLICIES,
                    iconClass: 'icon-Policies',
                    selectedRoutes: [
                        ZITI_URLS.ZITI_SERVICE_POLICIES,
                        ZITI_URLS.ZITI_ROUTER_POLICIES,
                        ZITI_URLS.ZITI_SERVICE_ROUTER_POLICIES,
                        URLS.ZITIPOLICIES,
                        URLS.ZITIAPPS
                    ],
                    requiresProvision: true,
                },
                {
                    label: 'Posture Checks',
                    route: ZITI_URLS.ZITI_POSTURE_CHECKS,
                    iconClass: 'icon-posturechecks',
                    selectedRoutes: [ZITI_URLS.ZITI_POSTURE_CHECKS],
                    requiresProvision: true,
                },
                {
                    label: 'Authentication',
                    route: ZITI_URLS.ZITI_CERT_AUTHORITIES,
                    iconClass: 'icon-CAs',
                    selectedRoutes: [ZITI_URLS.ZITI_CERT_AUTHORITIES, ZITI_URLS.ZITI_AUTH_POLICIES, ZITI_URLS.ZITI_JWT_SIGNERS],
                    requiresProvision: true,
                },
                {
                    label: 'Sessions',
                    route: ZITI_URLS.ZITI_SESSIONS,
                    iconClass: 'icon-time',
                    selectedRoutes: [ZITI_URLS.ZITI_SESSIONS, ZITI_URLS.ZITI_API_SESSIONS],
                    requiresProvision: true,
                }
            ],
        },
        {
            label: 'Visibility',
            menuItems: [
              {
                  label: 'Metrics',
                  route: INFRASTRUCTURE_URLS.METRICS,
                  iconClass: 'icon-AnalyticsReports',
                  selectedRoutes: [INFRASTRUCTURE_URLS.METRICS],
                  isMOPView: true,
                  requiresProvision: true,
              },
              {
                  label: 'Events',
                  route: URLS.MANAGEMENT_EVENTS,
                  iconClass: 'icon-Icon_Events',
                  selectedRoutes: [URLS.MANAGEMENT_EVENTS],
                  isMOPView: true,
                  requiresNetwork: true,
              },
              {
                  label: 'Processes',
                  route: URLS.PROCESS_EXECUTIONS,
                  iconClass: 'icon-Processes',
                  selectedRoutes: [URLS.PROCESS_EXECUTIONS],
                  isMOPView: true,
                  requiresNetwork: true,
              },
            ],
        },
        {
          label: 'Infrastructure',
          menuItems: [
            {
              label: 'Managed Components',
              route: URLS.NETWORK_CONTROLLERS,
              iconClass: 'icon-networks',
              selectedRoutes: [URLS.NETWORK_CONTROLLERS, INFRASTRUCTURE_URLS.ROUTERS, URLS.ALLOCATED_IPS],
              isMOPView: true,
              requiresNetwork: true,
            },
          ],
        },
    ],
    app: 'networks',
};

export const ORGANIZATION_NAVIGATOR = {
    groups: [
        {
            label: '',
            menuItems: [
                {
                    label: 'Users',
                    route: URLS.USERS,
                    iconClass: 'icon-ManageUsers',
                    selectedRoutes: [
                        URLS.USERS,
                        URLS.USER_ROLES,
                        URLS.MANAGE_INVITATIONS,
                        URLS.CLOUD_ZITI_USERS,
                        URLS.CLOUD_ZITI_USERS_ROLES,
                        URLS.CLOUD_ZITI_INVITATIONS,
                    ],
                },
                {
                    label: 'API Accounts',
                    route: URLS.TOKENS,
                    iconClass: 'icon-ManageAPIAccounts',
                    selectedRoutes: [URLS.TOKENS],
                },
                {
                    label: 'Stored Secrets',
                    route: URLS.STORED_SECRETS,
                    iconClass: 'icon-posturechecks',
                    selectedRoutes: [URLS.STORED_SECRETS],
                },
                {
                    label: 'Reporting and Analytics',
                    route: URLS.NETWORK_GROUP_SUMMARY,
                    iconClass: 'icon-AnalyticsReports',
                    selectedRoutes: [URLS.NETWORK_GROUP_SUMMARY],
                    mopV7Only: true
                },
                {
                    label: 'Billing Management',
                    route: URLS.ORG_PAYMENT_PROFILE,
                    iconClass: 'icon-ManageBilling',
                    selectedRoutes: [
                        URLS.ORG_PAYMENT_PROFILE,
                        URLS.ORG_ACCOUNT_DETAILS,
                        URLS.ORG_BILLING_SUBSCRIPTIONS,
                        URLS.ORG_BILLING_SUBSCRIPTION_OVERAGES,
                        URLS.ORG_BILLING_SUBSCRIPTION_OVERAGES,
                    ],
                    enterpriseOnly: true,
                },
                {
                    label: 'Billing Management',
                    route: URLS.PAYMENT_PROFILE,
                    iconClass: 'icon-ManageBilling',
                    selectedRoutes: [
                        URLS.PAYMENT_PROFILE,
                        URLS.ACCOUNT_DETAILS,
                        URLS.BILLING_SUBSCRIPTIONS,
                        URLS.BILLING_SUBSCRIPTION_OVERAGES,
                        URLS.BILLING_SUBSCRIPTION_OVERAGES,
                    ],
                    mopV7Only: true,
                    enterpriseOnly: true
                },
                {
                    label: 'Organizational Settings',
                    route: URLS.SETTINGS,
                    iconClass: 'icon-OrgSettings',
                    selectedRoutes: [URLS.SETTINGS],
                },
                {
                    label: 'Manage Organizations',
                    route: URLS.ORGANIZATIONS,
                    iconClass: 'icon-ManageOrg',
                    selectedRoutes: [URLS.ORGANIZATIONS],
                },
            ],
        },
    ],
    app: 'organization',
};

export const NETWORKS_NAVIGATOR = {
    groups: [
        {
            label: '',
            menuItems: [
                {
                    label: 'Dashboard',
                    route: URLS.V7DASHBOARD,
                    iconClass: 'icon-Icon_Dash',
                    selectedRoutes: [URLS.V7DASHBOARD],
                    requiresProvision: true,
                },
            ],
        },
        {
            label: 'Core Components',
            menuItems: [
                {
                    label: 'Identities',
                    sublabel: '(Endpoints)',
                    route: URLS.ZITIENDPOINTS,
                    iconClass: 'icon-endpoints',
                    selectedRoutes: [URLS.ZITIENDPOINTS],
                    className: 'ziti-identities-nav-item',
                    requiresProvision: true,
                },
                {
                    label: 'Services',
                    route: URLS.ZITISERVICES,
                    iconClass: 'icon-services',
                    selectedRoutes: [URLS.ZITISERVICES, URLS.ZITICONFIGS, URLS.ZITICONFIGTYPES],
                    className: 'ziti-services-nav-item',
                    requiresProvision: true,
                },
                {
                    label: 'Edge Routers',
                    route: URLS.ZITIROUTERS,
                    iconClass: 'icon-routers',
                    selectedRoutes: [URLS.ZITIROUTERS],
                    requiresProvision: true,
                },
            ],
        },
        {
            label: 'Access Management',
            menuItems: [
                {
                    label: 'Policies',
                    route: URLS.ZITIAPPS,
                    iconClass: 'icon-Policies',
                    sublabel: '(AppWANs)',
                    selectedRoutes: [URLS.ZITIAPPS, URLS.ZITI_SERVICE_ER_POLICIES, URLS.ZITIPOLICIES],
                    className: 'ziti-policies-nav-item',
                    requiresProvision: true,
                },
                {
                    label: 'Posture Checks',
                    route: URLS.POSTURE_CHECKS,
                    iconClass: 'icon-posturechecks',
                    selectedRoutes: [URLS.POSTURE_CHECKS],
                    requiresProvision: true,
                },
            ],
        },
        {
            label: 'Visibility',
            menuItems: [
                {
                    label: 'Attribute Explorer',
                    route: URLS.ATTRIBUTES_EXPLORER,
                    iconClass: 'icon-AttributeExplorer',
                    selectedRoutes: [URLS.ATTRIBUTES_EXPLORER],
                    requiresProvision: true,
                },
                {
                    label: 'Metrics',
                    route: URLS.METRICS,
                    iconClass: 'icon-AnalyticsReports',
                    selectedRoutes: [URLS.METRICS],
                    requiresProvision: true,
                },
                {
                    label: 'Events',
                    route: URLS.MANAGEMENT_EVENTS,
                    iconClass: 'icon-Icon_Events',
                    selectedRoutes: [URLS.MANAGEMENT_EVENTS],
                    requiresNetwork: true,
                },
                {
                    label: 'Processes',
                    route: URLS.PROCESS_EXECUTIONS,
                    iconClass: 'icon-Processes',
                    selectedRoutes: [URLS.PROCESS_EXECUTIONS],
                    requiresNetwork: true,
                },
            ],
        },
        {
            label: 'Management',
            menuItems: [
                {
                    label: 'Integrations',
                    route: URLS.SUBSCRIPTIONS,
                    iconClass: 'icon-integrations',
                    selectedRoutes: [URLS.SUBSCRIPTIONS],
                    requiresProvision: true,
                },
                {
                    label: 'Authentication',
                    route: URLS.ZITI_CERTIFICATE_AUTHORITIES,
                    iconClass: 'icon-CAs',
                    selectedRoutes: [URLS.ZITI_CERTIFICATE_AUTHORITIES],
                    requiresProvision: true,
                },
                {
                    label: 'BrowZer Apps',
                    route: URLS.BROWZER,
                    iconClass: 'icon-BrowZer',
                },
                {
                    label: 'Network Infrastructure',
                    route: URLS.NETWORK_CONTROLLERS,
                    iconClass: 'icon-networks',
                    selectedRoutes: [URLS.NETWORK_CONTROLLERS],
                },
                {
                    label: 'Networks',
                    route: URLS.NETWORKS_COMBINED,
                    iconClass: 'icon-ManageNetworks',
                    selectedRoutes: [URLS.NETWORKS_COMBINED, URLS.NETWORK_GROUPS],
                },

            ],
        },
    ],
};

export const BROWZER_NAVIGATOR = {
    groups: [
        {
            label: '',
            menuItems: [
                {
                    label: 'BrowZer Apps',
                    route: URLS.BROWZER_APPS,
                    iconClass: 'icon-BrowZer',
                    selectedRoutes: [URLS.BROWZER, URLS.BROWZER_APPS],
                },
                {
                    label: 'Routers',
                    route: URLS.BROWZER_ROUTERS,
                    iconClass: 'icon-routers',
                    selectedRoutes: [URLS.BROWZER_ROUTERS],
                },
                {
                    label: 'Authentication',
                    route: URLS.BROWZER_CERT_AUTHORITIES,
                    iconClass: 'icon-CAs',
                    selectedRoutes: [URLS.BROWZER_CERT_AUTHORITIES],
                    isZACView: true,
                },
            ],
        },
    ],
    app: 'browzer',
};

export const SUPPORT_NAVIGATOR = {
    groups: [
        {
            label: '',
            menuItems: [
                {
                    label: 'Support Hub',
                    link: 'https://support.netfoundry.io/hc/en-us',
                    iconClass: 'icon-hub',
                },
                {
                    label: 'Submit A Ticket',
                    action: 'openTicket',
                    iconClass: 'icon-hub',
                },
                {
                    label: 'Community Support',
                    link: 'https://community.netfoundry.io',
                    iconClass: 'icon-community',
                },
                {
                    label: 'Inline Help',
                    action: 'openSupport',
                    iconClass: 'icon-InlineHelp',
                },
                {
                    label: 'Console Tour',
                    action: 'openTour',
                    iconClass: 'icon-tour',
                },
                {
                    label: "What's New",
                    link: 'https://support.netfoundry.io/hc/en-us/sections/360008342332-Tech-Bulletins',
                    iconClass: 'icon-WhatsNew',
                },
                {
                    label: 'Getting Started',
                    route: URLS.GETTING_STARTED,
                    iconClass: 'icon-QuickStart',
                    selectedRoutes: [URLS.GETTING_STARTED],
                    class: 'getting-started-nav'
                }
            ],
        },
    ],
    app: 'support',
};

export const BILLING_NAVIGATOR = {
    groups: [
        {
            label: ' ',
            menuItems: [
                {
                    label: 'Billing Dashboard',
                    route: URLS.BILLING_DASHBOARD,
                    iconClass: 'icon-ManageBilling',
                    selectedRoutes: [
                        URLS.BILLING_DASHBOARD,
                        URLS.PAYMENT_PROFILE,
                        URLS.ACCOUNT_DETAILS,
                        URLS.BILLING_SUBSCRIPTIONS,
                        URLS.BILLING_SUBSCRIPTION_OVERAGES,
                        URLS.BILLING_SUBSCRIPTION_USAGE,
                    ],
                },
            ],
            headerBuffer: true,
            selfServiceOnly: true,
        },
    ],
    app: 'billing',
};

@Injectable({ providedIn: 'root' })
export class SideNavigatorService {
    public currentNetwork: any = {};
    public networkCanSupportBrowZer = false;
    public currentNav: any = this.featureService.isCloudZiti ? ZAC_NAVIGATOR : NETWORKS_NAVIGATOR;
    public disableMainNav = false;
    public networkProvisioning = false;
    public area = '';
    public open = false;
    public allNetworks: any = [];
    public upgradeableNetworks = 0;
    public currentSubscription: any = {};
    public isSelfService = false;
    public selfServiceBilling = false;
    public isSelfServiceTeamsTier = false;
    public currentTenant: any;
    public tenantId: any;
    public tenants: any = [];
    public tenantMap: any = {};
    public networksV6: any = [];
    public networksV7: any = [];
    public selectedNavItem: any = {};

    dialogRef: any;

    constructor(
        private logger: LoggerService,
        private router: Router,
        private apiService: ApiService,
        private featureService: FeatureService,
        private menuService: MenuService,
        private networkUpgradeService: NetworkUpgradeService,
        private authorizationService: AuthorizationService,
        private dialogForm: MatDialog,
        private networkService: NetworkService,
        private zendeskService: NgxZendeskWebwidgetService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.apiService.currentTenant.subscribe((tenant) => {
            this.tenantId = tenant.id;
            this.currentTenant = tenant;
            this.loadTenants();
        });

        this.apiService.currentNetwork.subscribe((network) => {
            this.currentNetwork = network;
            const majorVersionNumber = this.apiService.getNetworkVersion(this.currentNetwork);
            const minorVersionNumber = this.apiService.getNetworkMinorVersion(this.currentNetwork);
            const patchVersionNumber = this.apiService.getNetworkPatchVersion(this.currentNetwork);
            if (
                majorVersionNumber < 7 ||
                (majorVersionNumber === 7 && minorVersionNumber < 3) ||
                (majorVersionNumber === 7 && minorVersionNumber === 3 && patchVersionNumber < 90)
            ) {
                this.networkCanSupportBrowZer = false;
            } else {
                this.networkCanSupportBrowZer = true;
            }
            this.initNetworks();
            this.updateAppNavigator();

            const n2 = network as unknown as NetworkV2;
            this.disableMainNav = !n2?.status || n2?.status === 'PROVISIONING' || n2?.status === 'SUSPENDED';
            this.networkProvisioning = n2?.status === 'PROVISIONING';
        });

        this.menuService.area.subscribe((area) => {
            let doUpdate = false;
            if (this.area !== area) {
                doUpdate = true;
            }
            this.area = area;
            this.open = this.area.length > 0;
            if (doUpdate) {
                this.updateAppNavigator();
            }
        });

        this.menuService.navItemChanged.subscribe((navItem) => {
            this.selectedNavItem = navItem;
        });

        this.apiService.currentSubscription.subscribe((subscription) => {
            this.currentSubscription = subscription;
            this.isSelfService = this.featureService.isSelfServiceAccount(subscription);
            this.selfServiceBilling = this.isSelfService && this.authorizationService.canViewPaymentProfile();

            if (
                this.featureService.isSelfServiceTeamsTier(subscription) ||
                this.featureService.isSelfServicePAYG(subscription)
            ) {
                this.isSelfServiceTeamsTier = true;
            }
        });

        this.networkServiceV2._networks.subscribe((networks) => {
          this.allNetworks = networks;
        });
    }

    updateAppNavigator() {
        switch (this.area) {
            case 'Network':
                if (this.featureService.isCloudZiti) {
                    this.currentNav = ZAC_NAVIGATOR;
                } else {
                    this.currentNav = NETWORKS_NAVIGATOR;
                }
                break;
            case 'Infrastructure':
                this.currentNav = ZAC_NAVIGATOR;
                break;
            case 'ZAC':
                this.currentNav = ZAC_NAVIGATOR;
                break;
            case 'Browzer':
                this.currentNav = BROWZER_NAVIGATOR;
                break;
            case 'Organization':
                this.currentNav = ORGANIZATION_NAVIGATOR;
                break;
            case 'Billing':
                this.currentNav = BILLING_NAVIGATOR;
                break;
            case 'Support':
                this.currentNav = SUPPORT_NAVIGATOR;
                break;
            case 'MOP':
            default:
                if (isEmpty(this.currentNav)) {
                  this.currentNav = ZAC_NAVIGATOR;
                }
                break;
        }
        if (this.currentNav.app && this.area !== '') {
            this.menuService.setApp(this.currentNav.app);
        }
        this.updateSelectedNavItem();
    }

    updateSelectedNavItem() {
        this.currentNav.groups.forEach((group: any) => {
            group.menuItems.forEach((item: any) => {
                if (item.route === this.router.url) {
                    this.selectedNavItem = item;
                }
            })
        });
    }

    initNetworks() {
        this.networkServiceV2.getNetworks({ pageParams: { size: 2000, page: 0 } }).then((networks: any) => {
            this.upgradeableNetworks = 0;
            this.networksV7 = networks.map((nw: NetworkV2) => {
                if (this.area === 'Network') {
                    if (this.networkUpgradeService.isUpgradeable(nw)) {
                        this.upgradeableNetworks++;
                    }
                }
                return this.apiService.getNetworkModel(nw);
            });
            this.networkServiceV2._networks.next(this.networksV7);
            this.currentNav.groups.forEach((group: any) => {
                group.menuItems.forEach((item: any) => {
                    if (item.route === URLS.NETWORKS_COMBINED || item.route === INFRASTRUCTURE_URLS.NETWORKS) {
                        item.badgeCount = this.upgradeableNetworks;
                    }
                })
            });
            this.allNetworks = this.networksV7;
            if (this.allNetworks.length < 1 && !this.currentNetwork.isPlaceholder) {
                this.currentNetwork = new Network({});
                this.currentNetwork.name = 'Select A Network';
                this.currentNetwork.isPlaceholder = true;
                this.apiService.setCurrentNetwork(this.currentNetwork);
            }
        });
    }

    navigateToRoute(item: any) {
        if (this.disableMainNav && item.requiresProvision) {
            return;
        }
        this.router.navigate([item.route]);
        this.menuService.setNavItem(item);
    }

    isNavItemDisabled(item: any) {
        return (
            (this.disableMainNav && item.requiresProvision) ||
            (isEmpty(this.currentNetwork?.id) && item.requiresNetwork)
        );
    }

    loadTenants() {
        if (this.authorizationService.canListTenants()) {
            this.apiService.availableTenants.subscribe((tenants) => {
                this.tenants = tenants as Tenant[];

                for (const tenant of this.tenants) {
                    this.tenantMap[tenant.id] = tenant;
                }
                this.currentTenant = this.tenantMap[this.tenantId];
                this.tenantId = this.currentTenant.id;
            });
        }
    }

    addNetwork() {
        this.dialogRef = this.dialogForm.open(V2NetworkCreationFormComponent, {
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
    }

    addTenant() {
        this.dialogRef = this.dialogForm.open(OrganizationPromptComponent, {
            data: {},
            height: '475px',
            width: '855px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe(() => {
            this.loadTenants();
        });
    }

    openTicket() {
        this.dialogRef = this.dialogForm.open(TicketFormComponent, {
            data: {},
            height: '695px',
            width: '975px',
            autoFocus: false,
        });
    }

    openSupport() {
        this.zendeskService.zE('webWidget', 'show');
        this.zendeskService.zE('webWidget', 'open');
    }

    openTour() {
        this.dialogRef = this.dialogForm.open(ConsoleTourComponent, {
            data: {},
            height: '455px',
            width: '935px',
            autoFocus: false,
        });
    }
}
