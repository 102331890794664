<div class="modal solid open" id="CAVerifyForm">
    <div (click)="cancel()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="icon CAs"></div>
    <div class="title">Verify CA</div>

    <div class="label row">
        Certificate Authority Pem
        <span [ngClass]="{ error: errorPem }" class="note">{{ errorPem ? 'Invalid' : 'Required' }}</span>
    </div>
    <div class="row">
        <textarea
            [(ngModel)]="certificate"
            [ngClass]="{ error: errorPem }"
            class="restrictNormal"
            id="EditPem"
            placeholder="CA Pem"
            type="text"
        ></textarea>
    </div>

    <div class="buttons">
        <div (click)="cancel()" class="linkButton cancel">Oops, no get me out of here</div>
        <button (click)="verify()" *ngIf="!processing" class="save confirm" id="VerifyCA">Verify</button>
        <button *ngIf="processing" class="save confirm" disabled>Verifying <span class="spinner"></span></button>
    </div>
</div>
