import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { AuthorizationService, AuthService, IamService } from '@netfoundry-ui/shared/authorization';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { ApiService, RefresherService } from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import moment from 'moment';
import { Subscription } from 'rxjs';

const columns = ['name', 'description', 'createdAt', 'updatedAt'];
const filename = 'roles';
const translateStatus = true;

@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit, OnDestroy {
    typeName = 'Roles';
    items = [];
    displayedItems = [];
    itemCount = 0;
    showList = false;
    isLoading = true;
    allToggled = false;
    filterString = '';
    tooltipPosition = 'above';
    sorting = 'name';
    ordering = 'asc';
    dialogRef;
    isDeleteAction = false;
    isO365Optimized = false;
    page = 1;
    pageSize = this.environment.pageSize;
    totalElements = 0;
    roleSub = new Subscription();
    hasSomeDeletePermission = false;
    noDeleteAuthCount = 0;
    identityId = '';
    openIndex = -1;
    private deleting = 0;
    private deleteMe = [];
    private updatedRole = null;
    private subscription = new Subscription();
    private selectedRoleIds = [];
    private deletedRoleIds = [];
    private inDeletingStatusCount = 0;
    private routeSubscription = new Subscription();
    private openId = '';
    private paginationSort = ['name', 'asc'];

    constructor(
        private refresher: RefresherService,
        private apiService: ApiService,
        private route: ActivatedRoute,
        public dialogForm: MatDialog,
        public authorizationService: AuthorizationService,
        private filterService: TableFilterService,
        private iamService: IamService,
        private authService: AuthService,
        private csvDownloadService: CsvDownloadService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    async ngOnInit() {
        this.routeSubscription = this.route.params.subscribe((params) => {
            this.openId = params['id'];
        });

        // subscribing to the filter service to handle the change in page
        this.subscription.add(
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.page = pageNum;
            })
        );

        // subscribing to the filter service to handle search filter changes
        this.subscription.add(
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.filterString = filterString;
                this.applyFilter();
            })
        );

        await this.iamService
            .get('identities', 'self')
            .toPromise()
            .then((identity) => {
                this.identityId = identity['id'];
            });

        this.subscription.add(
            this.apiService.currentTenant.subscribe((result) => {
                // reset the page when the network is switched
                this.page = 1;

                if (result.id !== null) {
                    this.isLoading = true;
                    this.selectedRoleIds = [];
                    this.deletedRoleIds = [];
                    this.isDeleteAction = false;
                    this.handlePermissionCheck();
                } else {
                    this.isLoading = false;
                }
            })
        );
    }

    handlePermissionCheck() {
        this.getRoles();
    }

    applyFilter() {
        this.displayedItems = this.filterService.applyLocalFilter(this.items);
        const newSelectedRoleIds = [];
        for (const service of this.displayedItems) {
            if (this.selectedRoleIds.indexOf(service.id) > -1 || this.allToggled) {
                newSelectedRoleIds.push(service.id);
                service.selected = true;
            } else {
                service.selected = false;
            }
        }
        this.selectedRoleIds = newSelectedRoleIds;
        this.isDeleteAction = this.anyToggled();

        this.totalElements = this.displayedItems.length;

        // TODO - enabled when using pagination and sorting. Need to also determine when to send the request. currently this function is
        //          getting called on keyup of the search bar, which would cause this to send a request for every key they press
        /*
this.paginationFilter = this.filterString;
// determining whether or not to refresh the page
if(this.isUsingPagination()) {
this.page = 1;
this.refresh();
}
*/
    }

    ngOnDestroy() {
        this.refresher.disableRefresh();
        this.subscription.unsubscribe();
        this.roleSub.unsubscribe();
        this.filterService.reset();
    }

    refresh() {
        this.refresher.disableRefresh();
        this.handlePermissionCheck();
        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
    }

    toggleAll() {
        this.allToggled = !this.allToggled;
        this.selectedRoleIds = [];

        for (const item of this.displayedItems) {
            if (item.status < 700 && this.authorizationService.canDeleteService(item.id)) {
                item.selected = this.allToggled;
                if (this.allToggled) {
                    this.selectedRoleIds.push(item.id);
                }
            }
        }

        this.isDeleteAction = this.anyToggled();
    }

    toggle(item) {
        if (this.authorizationService.canDeleteService(item.id)) {
            item.selected = !item.selected;

            if (this.allToggled) {
                this.allToggled = !this.allToggled;
            }

            const index = this.selectedRoleIds.indexOf(item.id);
            if (index > -1) {
                this.selectedRoleIds.splice(index, 1);
            } else {
                this.selectedRoleIds.push(item.id);
            }
            this.isDeleteAction = this.anyToggled();
        }
    }

    anyToggled() {
        if (this.selectedRoleIds.length > 0) {
            if (this.selectedRoleIds.length === this.itemCount - this.inDeletingStatusCount - this.noDeleteAuthCount) {
                this.allToggled = true;
            }
            return true;
        }
        this.allToggled = false;
        return false;
    }

    getDeletingTotal() {
        let total = 0;
        const names = [];
        for (const role of this.displayedItems) {
            if (role.selected) {
                names.push(role);
                total++;
            }
        }

        let deleteString = 'Are you sure you would like to delete ';

        if (total > 1) {
            deleteString += `these ${total} Roles:`;
        } else {
            deleteString += 'the following Role:';
        }

        return {
            deleteString: deleteString,
            names: names,
        };
    }

    toggleMenu(event, index) {
        event.stopPropagation();
        if (index !== this.openIndex) {
            this.openIndex = index;
        } else {
            this.openIndex = -1;
        }
    }

    openConfirm(deleteEvent) {
        if (deleteEvent) {
            const deletingInfo = this.getDeletingTotal();

            const data = {
                title: 'Delete',
                appendId: 'Roles',
                subtitle: deletingInfo.deleteString,
                bulletList: deletingInfo.names,
                icon: 'Delete',
                action: 'Yes',
            };
            this.dialogRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            this.dialogRef.afterClosed().subscribe((result) => {
                // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                //  logged out of the console and we should close the dialog without continuing
                if (result === undefined) {
                    this.confirmed(false);
                } else if (result['loggingOut'] === undefined) {
                    this.confirmed(result);
                }
            });
        }
    }

    confirmDelete(item) {
        this.toggleAll();
        if (this.allToggled) {
            this.toggleAll();
        }

        item.selected = true;
        const index = this.selectedRoleIds.indexOf(item.id);
        if (index === -1) {
            this.selectedRoleIds.push(item.id);
        }
        this.isDeleteAction = true;
        this.openConfirm(true);
    }

    confirmed(event) {
        if (event) {
            this.refresher.disableRefresh();
            this.isLoading = true;
            this.deleting = this.getTotalSelected();
            for (const item of this.displayedItems) {
                if (item.selected) {
                    this.deleteMe[this.deleteMe.length] = item;
                }
            }
            this.deleteNext();
        } else {
            this.selectedRoleIds = [];
            this.isDeleteAction = false;
            this.refresh();
        }
    }

    deleteNext() {
        // TODO
    }

    postDelete() {
        // forcing the deleted items to become untoggled
        this.toggle(this.deleteMe[this.deleting]);

        if (this.deleting === 0) {
            this.deleteMe = [];
            this.isLoading = true;
            this.refresh();
        } else {
            this.deleteNext();
        }
    }

    getTotalSelected() {
        return this.selectedRoleIds.length;
    }

    create(addEvent) {
        // TODO
    }

    open(item) {
        // TODO
    }

    closeActionMenu() {
        this.openIndex = -1;
    }

    sort(sortBy) {
        if (this.sorting === sortBy) {
            if (this.ordering === 'asc') {
                this.ordering = 'desc';
            } else {
                this.ordering = 'asc';
            }
        } else {
            this.ordering = 'asc';
            this.sorting = sortBy;
        }

        // setting the paginationSort object
        this.paginationSort = [this.sorting, this.ordering];
    }

    getSortClass(id) {
        if (id === this.sorting) {
            return this.ordering;
        } else {
            return '';
        }
    }

    trackById(index, item) {
        return item.id;
    }

    formatTime(theDate: number) {
        return moment
            .utc(moment.unix(theDate / 1000))
            .local()
            .format('M/D/YY h:mm a');
    }

    downloadCsv(event: any) {
        const csvItems = [];

        for (const item of this.displayedItems) {
            if (item.selected) {
                csvItems.push(item);
            }
        }

        this.toggleMenu(event, -2);
        this.csvDownloadService.download(filename, csvItems, columns, translateStatus);
    }

    private getRoles() {
        this.subscription.add(
            this.authService.getRoles(this.identityId).subscribe((result) => {
                this.isDeleteAction = false;
                this.inDeletingStatusCount = 0;
                const theResult = result['content'];
                this.hasSomeDeletePermission = false;
                this.noDeleteAuthCount = 0;

                let updatedRoleId;
                if (this.updatedRole != null) {
                    updatedRoleId = this.updatedRole.id;
                }

                let index = 0;
                for (const role of theResult) {
                    if (this.openId === role['id']) {
                        this.open(role);
                        this.openId = '';
                    }

                    if (role.id === updatedRoleId) {
                        this.updatedRole.selected = role.selected;
                        this.updatedRole.status = role.status;
                        theResult[index] = this.updatedRole;
                    }

                    const actions = [];

                    // TODO add delete auth check

                    role['actionList'] = actions;

                    index++;
                }
                this.updatedRole = null;
                // the deleted service ids are only needed for the first refresh. After the first refresh they should no longer appear
                // if the service does appear again, then something went wrong with the delete and the status shouldn't be set to 800
                this.deletedRoleIds = [];
                this.items = theResult;
                this.displayedItems = this.items;
                this.itemCount = this.items.length;
                this.showList = this.itemCount > 0;

                // obtaining the total number of clients
                // if the clientService has a page object, set totalElements to page.totalElements
                // otherwise, set it to the length of the client list
                this.totalElements = this.items.length;

                this.filterService.setTotalElements(this.totalElements);

                // TODO set total pages based on this.service.page.totalPages once using backend pagination
                this.filterService.updateTotalPages();

                // TODO - remove when pagination is fully implemented, this case should be handled by the first if statement in this subscription
                if (this.items.length === this.totalElements && this.items.length <= this.pageSize) {
                    this.page = 1;
                }

                this.applyFilter();
                this.isLoading = false;
                this.isDeleteAction = this.anyToggled();
            })
        );

        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
    }
}
