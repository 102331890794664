import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isEmpty, get } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ApiService, FeatureService } from '@netfoundry-ui/shared/services';
import { WhatsnewComponent } from './whatsnew.component';

const WHATS_NEW_ITERATION_KEY = 'nf_whats_new_iteration';

@Injectable({
    providedIn: 'root',
})
export class WhatsnewService {
    public contentFile: any = {};
    contentLoaded = false;
    whatsNewIterationNumber = 0;
    userIterationNumber = 0;
    dialogRef: any;
    userLoaded = false;

    constructor(
        private http: HttpClient,
        public dialogForm: MatDialog,
        private featureService: FeatureService,
        private apiService: ApiService
    ) {}

    loadContentFile(isNewSelfService) {
        if (this.contentLoaded) {
            return;
        }
        this.http.get('/assets/data/whats-new.json').subscribe((data) => {
            this.contentFile = data;
            this.checkForDisplayConfirmation(isNewSelfService);
            this.contentLoaded = true;
        });
    }

    checkForDisplayConfirmation(isNewSelfService) {
        if (isEmpty(this.contentFile)) {
            return;
        }
        const whatsNewIteration = this.contentFile.iteration;
        this.userIterationNumber = this.featureService.whatsNewIteration;
        if (!isEmpty(this.userIterationNumber) || this.userIterationNumber === 0) {
            this.whatsNewIterationNumber = Number.parseInt(whatsNewIteration, 10);
            if (isNewSelfService) {
                this.featureService.enableNewNavigation = true;
                this.featureService.whatsNewIteration = this.whatsNewIterationNumber;
                return;
            }
            if (this.userIterationNumber < this.whatsNewIterationNumber) {
                this.displayWhatsNew();
            }
        } else {
            if (isNewSelfService) {
                this.featureService.enableNewNavigation = true;
                this.featureService.whatsNewIteration = this.whatsNewIterationNumber;
                return;
            }
            this.displayWhatsNew();
        }
    }

    displayWhatsNew() {
        this.dialogRef = this.dialogForm.open(WhatsnewComponent, {
            data: this.contentFile,
            height: '455px',
            width: '935px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.featureService.enableNewNavigation = true;
            }
            this.featureService.whatsNewIteration = this.whatsNewIterationNumber;
        });
        this.dialogRef.afterOpened().subscribe(() => {
            const cdkOverlayWrappers = document.getElementsByClassName('cdk-global-overlay-wrapper');
            const wrapperContainer = cdkOverlayWrappers[0];
            wrapperContainer.classList.add('nf-whats-new-overlay-wrapper');
        });
    }
}
