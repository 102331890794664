import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'regionTransform',
})
export class RegionTransformPipe implements PipeTransform {
    transform(
        value: any[],
        includeAws = true,
        includeAzure = true,
        includeGcp = true,
        includeGeneric = true,
        includeAli = true,
        includeOracle = true
    ): any {
        const result = [];

        for (const region of value) {
            if (includeAws && region.name.includes('AWS ')) {
                region.name = region.name.replace('AWS ', '');
                result.push(region);
            }

            if (includeAzure && region.name.includes('AZURE ')) {
                region.name = region.name.replace('AZURE ', '');
                result.push(region);
            }

            if (includeGcp && region.name.includes('GCP ')) {
                region.name = region.name.replace('GCP ', '');
                result.push(region);
            }

            if (includeGeneric && region.name.includes('GENERIC ')) {
                region.name = region.name.replace('GENERIC ', '');
                result.push(region);
            }
            if (includeAli && region.name.includes('ALICLOUD ')) {
                region.name = region.name.replace('ALICLOUD ', '');
                result.push(region);
            }
            if (includeOracle && region.name.includes('OCP ')) {
                region.name = region.name.replace('OCP ', '');
                result.push(region);
            }
        }

        return result;
    }
}
