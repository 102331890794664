import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TourService {
    private data = new Subject<string>();

    public data$ = this.data.asObservable();

    public show(data: string) {
        this.data.next(data);
    }
}
