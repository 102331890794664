import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ENVIRONMENT, Environment} from '@netfoundry-ui/shared/model';
import {LoggerService} from './logger.service';
import {HTTP_CLIENT} from './token.service';

import {result, toLower} from 'lodash';

@Injectable({providedIn: 'root'})
export class BannerService {
  apiUrl;
  appsUrl;

  constructor(
    private logger: LoggerService,
    @Inject(HTTP_CLIENT) private http: HttpClient,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {
    this.apiUrl = this.environment.bannerURL;
  }

  getBanners(targetIds: any[]) {
    let params = '';
    targetIds?.forEach((targetId, index) => {
      params += (index === 0 ? `?` : '&') + `targetId=${targetId}`;
    });
    const path = 'banners' + params;
    return this.get(path)
      .toPromise()
      .then((result: any) => {
        this.logger.log(result);
        return result;
      });
  }

  public get(path: string) {
    const url = `${this.apiUrl}${path}`;
    return this.http.get(url, {headers: this.setHeaders()});
  }

  private setHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    return headers.append('Content-Type', 'application/json');
  }
}
