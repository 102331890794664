import { URLS } from './urls';

export const v6tov7URLMapping = {
    '/endpoints/gateways': URLS.ZITIENDPOINTS,
    '/endpoints/clients': URLS.ZITIENDPOINTS,
    '/endpoints/azure-virtual-wan-sites': URLS.ZITIENDPOINTS,
    '/endpoints/groups': URLS.ZITIENDPOINTS,
    '/connections/appwans': URLS.ZITIAPPS,
    '/connections/services': URLS.ZITISERVICES,
    '/virtualwans': URLS.V7DASHBOARD,
    '/integrations': URLS.V7DASHBOARD,
    '/main': URLS.V7DASHBOARD,
};

export const v7tov6URLMapping = {
    '/v7/endpoints': URLS.GATEWAYS,
    '/v7/connections/appwans': URLS.APPWANS,
    '/v7/connections/services': URLS.SERVICES,
    '/v7/routers': URLS.GATEWAYS,
    '/v7/policies': URLS.GATEWAYS,
    '/v7/dashboard': URLS.HOME,
};
