import { Resource } from '@lagoshny/ngx-hateoas-client';
import { HateoasResource } from '@lagoshny/ngx-hateoas-client';
@HateoasResource('posture-checks')
export class PostureCheck extends Resource {
    id: string;
    zitiId: string;
    type: string;
    name: string;
    attributes: string[];

    /**
     * this field is used for the POST/PUT.
     * It will not be populated on the generic find for posture checks
     * It will however show up when doing a GET for a specific posture check
     */
    data: any;

    networkId: string;
    ownerIdentityId: string;
    createdBy: string;
    createdAt: string;
    deletedBy: string;
    deletedAt: string;
    selected = false;
}
