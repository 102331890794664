import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HTTP_CLIENT } from './token.service';

@Injectable({
    providedIn: 'root',
})
export class S3Service {
    constructor(@Inject(HTTP_CLIENT) private http: HttpClient) {}

    getAwsIps() {
        return this.http.get('https://ip-ranges.amazonaws.com/ip-ranges.json');

        // return ipRanges;
    }
}
