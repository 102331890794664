import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Endpoint } from '@netfoundry-ui/shared/model';
import { GatewayService, GetCountryService, RegionService, ValidateService } from '@netfoundry-ui/shared/services';
import { RegionTransformPipe } from '@netfoundry-ui/ui/pipes';
import { Subscription } from 'rxjs';

const provisionedStatus = 300;
const registeredStatus = 400;

@Component({
    selector: 'app-appwan-wizard-gateway-select',
    templateUrl: './appwan-wizard-gateway-select.component.html',
    styleUrls: ['./appwan-wizard-gateway-select.component.scss'],
})
export class AppwanWizardGatewaySelectComponent implements OnInit, OnChanges, OnDestroy {
    @Input() step = 1;
    @Input() isInterconnect = false;

    // the current source and target gateways
    @Input() sourceGatewayModel: Endpoint;
    @Input() targetGatewayModel: Endpoint;

    // the previous step
    previousStep = 1;

    // the endpoint type of the source and target gateways
    sourceGatewayType = 'AWSCPEGW';
    targetGatewayType = 'AWSCPEGW';

    // the current gateawy type to obtain
    currentGatewayType = 'AWSCPEGW';

    // maping of endpoint IDs to gateways
    gatewayMap = {};

    // mapping of endpoint types to gateway lists
    gatewayListMap = {};

    // object to help manage the form
    form = {
        sourceName: {
            error: false,
            placeholder: 'Enter a Name',
            label: 'Name',
            value: '',
        },
        sourceLocation: {
            error: false,
            placeholder: 'Select A Location',
            label: 'Location',
            value: '',
        },
        sourceRegion: {
            error: false,
            placeholder: 'Select A World Region',
            label: 'World Region',
            value: '',
        },
        sourceCountry: {
            error: false,
            placeholder: 'Select A Country',
            label: 'Country',
            value: '',
        },
        sourceGateway: {
            error: false,
            placeholder: 'Search for a Gateway',
            label: 'OR SELECT FROM AN EXISTING GATEWAY',
            value: '',
        },
        targetName: {
            error: false,
            placeholder: 'Enter a Name',
            label: 'Name',
            value: '',
        },
        targetLocation: {
            error: false,
            placeholder: 'Select A Location',
            label: 'Location',
            value: '',
        },
        targetRegion: {
            error: false,
            placeholder: 'Select A World Region',
            lable: 'World Region',
            value: '',
        },
        targetCountry: {
            error: false,
            placeholder: 'Select A Country',
            label: 'Country',
            value: '',
        },
        targetGateway: {
            error: false,
            placeholder: 'Search for a Gateway',
            label: 'OR SELECT FROM AN EXISTING GATEWAY',
            value: '',
        },
    };
    sourceRegions: Location[] = [];
    targetRegions: Location[] = [];
    awsRegions: Location[] = [];
    azureRegions: Location[] = [];
    googleRegions: Location[] = [];
    gateways = [];
    displayedGateways = [];
    gatewaysNoHa = [];
    savedSourceGateway = false;
    savedTargetGateway = false;
    canSelectSource = true;
    canCreateSource = true;
    canSelectTarget = true;
    canCreateTarget = true;
    canListGateways = false;
    canCreateGateways = false;
    canListRegions = false;
    titleString = 'Where is your Current Source?';
    cloudText;
    worldRegions = [];
    countries = [];
    private subscription = new Subscription();

    constructor(
        private gatewayService: GatewayService,
        public validateService: ValidateService,
        private regionService: RegionService,
        private authorizationService: AuthorizationService,
        private regionTransform: RegionTransformPipe,
        private getCountryService: GetCountryService
    ) {}

    ngOnInit() {
        this.getCountryService.getCountries().subscribe((countries) => {
            if (!countries['_embedded']) {
                return;
            }
            this.worldRegions = countries['_embedded'].countries;
        });

        // checking the users permissions
        this.canListGateways = this.authorizationService.canListEndpoints();
        this.canCreateGateways = this.authorizationService.canCreateEndpointGroups();
        this.canListRegions = this.authorizationService.canListGeoRegions();

        // if the user can get gateways, get the list of gateways
        if (this.canListGateways) {
            // getting only gateways of the type selected for the source gateway
            this.subscription.add(
                this.gatewayService
                    .get(undefined, undefined, undefined, undefined, this.sourceGatewayType)
                    .subscribe((result) => {
                        const provisionedGateways = [];
                        this.gatewayMap = {};
                        for (const gateway of result) {
                            if (
                                (gateway.status === provisionedStatus || gateway.status === registeredStatus) &&
                                gateway.endpointProtectionRole == null
                            ) {
                                provisionedGateways.push(gateway);
                                this.gatewayMap[gateway.id] = gateway;
                            }
                        }
                        this.gateways = provisionedGateways;

                        // add an entry to relate the current gateway type to the list of gateways
                        this.gatewayListMap[this.currentGatewayType] = {
                            gatewayList: this.gateways.concat([]),
                            gatewayMap: this.gatewayMap,
                        };

                        // filter the list to exclude any previously selected gateways
                        this.filterGateways();
                    })
            );
        }

        // if the user can list regions, getting the regions
        if (this.canListRegions) {
            // regions
            this.subscription.add(
                this.regionService.get().subscribe((result) => {
                    this.awsRegions = this.regionTransform.transform(
                        result as Location[],
                        true,
                        false,
                        false,
                        false,
                        false,
                        false
                    );
                    this.azureRegions = this.regionTransform.transform(
                        result as Location[],
                        false,
                        true,
                        false,
                        false,
                        false,
                        false
                    );
                    this.googleRegions = this.regionTransform.transform(
                        result as Location[],
                        false,
                        false,
                        true,
                        false,
                        false,
                        false
                    );
                    this.sourceRegions = this.awsRegions;
                    this.targetRegions = this.awsRegions;
                })
            );
        }
    }

    ngOnChanges() {
        // if the sourceGatewayModel was provided and the model has an ID, then the user may have just cloned the appwan.
        // the form needs to be updated to select the exiting gateway rather than using the previously provided information
        if (this.sourceGatewayModel != null && this.sourceGatewayModel.id != null) {
            // updating the form to store the source gateway's ID
            this.form.sourceGateway.value = this.sourceGatewayModel.id;

            // clearing any previously entered name/location value
            this.form.sourceLocation.value = '';
            this.form.sourceName.value = '';

            // setting the source gateway type based on the source gateway's endpoint type
            this.sourceGatewayType = this.sourceGatewayModel.endpointType;

            // updating the form to hide the inputs for name/location and only show the select list
            this.canCreateSource = false;
            this.canSelectSource = true;
        }

        // if the targetGatewayModel was provided and the model has an ID, then the user may have just cloned the appwan.
        // the form needs to be updated to select the exiting gateway rather than using the previously provided information
        if (this.targetGatewayModel != null && this.targetGatewayModel.id != null) {
            // updating the form to store the target gateway's ID
            this.form.targetGateway.value = this.targetGatewayModel.id;

            // clearing any previously entered name/location value
            this.form.targetLocation.value = '';
            this.form.targetName.value = '';

            // setting the source gateway type based on the target gateway's endpoint type
            this.targetGatewayType = this.targetGatewayModel.endpointType;

            // updating the form to hide the inputs for name/location and only show the select list
            this.canCreateTarget = false;
            this.canSelectTarget = true;
        }

        // if step is 3 update the list of gateways based on the source gateway type
        if (this.step === 3) {
            if (this.isInterconnect) {
                this.titleString = 'Where is Cloud One Hosted?';
                this.cloudText = 'Cloud One';
            } else {
                this.titleString = 'Where is the Current Source?';
                this.cloudText = 'Source';
            }
            // updating the gateway list based on the endpoint type
            this.updateGatewayList(this.sourceGatewayType);
        } else if (this.step === 4) {
            if (this.isInterconnect) {
                this.titleString = 'Where is Cloud Two Hosted?';
                this.cloudText = 'Cloud Two';
            } else {
                this.titleString = 'Where is the Target Destination?';
                this.cloudText = 'Destination';
            }

            // if the step is 4 update the list of gateawys based on the target gateway type
            this.updateGatewayList(this.targetGatewayType);
        }
        // save the previous step
        this.previousStep = this.step;
    }

    // function for returning the source gateway
    public getSourceGateway() {
        // if the user selected the source gateway via the drop down
        if (this.canSelectSource) {
            // return the source gateway
            return this.gatewayMap[this.form.sourceGateway.value];
        } else {
            // otherwise return a new gatway using the provided information
            const gatewayModel = new Endpoint({});
            gatewayModel.endpointType = this.sourceGatewayType;
            gatewayModel.name = this.form.sourceName.value;
            gatewayModel.geoRegionId = this.form.sourceLocation.value;
            if (this.sourceGatewayType === 'VCPEGW') {
                gatewayModel.countryId = this.form.sourceCountry.value;
            }

            return gatewayModel;
        }
    }

    // function for validating the source gateway
    public validateSourceGateway() {
        let isValid = false;
        if (this.canCreateSource) {
            this.form.sourceLocation.error = !this.validateService.hasValue(this.form.sourceLocation.value);

            this.form.sourceName.error = !this.validateService.isValidName(this.form.sourceName.value);

            isValid = !(this.form.sourceLocation.error || this.form.sourceName.error);
        } else if (this.canSelectSource) {
            isValid = this.validateService.hasValue(this.form.sourceGateway.value);
            this.form.sourceGateway.error = true;
        }

        return isValid;
    }

    // function for getting the target gateway
    public getTargetGateway() {
        // if the user selected the target gateway from the select list
        if (this.canSelectTarget) {
            // returning the target gateway
            return this.gatewayMap[this.form.targetGateway.value];
        } else {
            // otherwise returning a new gateway based on the provided information
            const gatewayModel = new Endpoint({});
            gatewayModel.endpointType = this.targetGatewayType;
            gatewayModel.name = this.form.targetName.value;
            gatewayModel.geoRegionId = this.form.targetLocation.value;
            if (this.targetGatewayType === 'VCPEGW') {
                gatewayModel.countryId = this.form.targetCountry.value;
            }

            return gatewayModel;
        }
    }

    // function for validating the target gateway
    validateTargetGateway() {
        let isValid = false;
        if (this.canCreateTarget) {
            this.form.targetLocation.error = !this.validateService.hasValue(this.form.targetLocation.value);

            this.form.targetName.error = !this.validateService.isValidName(this.form.targetName.value);

            isValid = !(this.form.targetLocation.error || this.form.targetName.error);
        } else if (this.canSelectTarget) {
            isValid = this.validateService.hasValue(this.form.targetGateway.value);
            this.form.targetGateway.error = true;
        }

        return isValid;
    }

    // function for updating the list of gateways
    updateGatewayList(gatewayType) {
        // if the user can list gateways and the provided gateawy type is not equal to the current gateway type
        if (this.gatewayListMap[gatewayType] != null) {
            this.gateways = this.gatewayListMap[gatewayType]['gatewayList'].concat([]);
            this.gatewayMap = this.gatewayListMap[gatewayType]['gatewayMap'];
            this.filterGateways();
        } else if (this.canListGateways && gatewayType !== this.currentGatewayType) {
            // updating the list of gateways
            this.gatewayService.get(undefined, undefined, undefined, undefined, gatewayType);
        } else {
            this.filterGateways();
        }

        // saving the current gateway type
        this.currentGatewayType = gatewayType;
    }

    isPassedStep(index) {
        return this.step > index;
    }

    // function for resetting the gateway list map
    public clearGatewayListMap() {
        this.gatewayListMap = {};
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    worldRegionChangeSource() {
        this.countries = this.worldRegions.filter((country) => country.worldRegion === this.form.sourceRegion.value);
    }

    countryChangeSource() {
        const country = this.countries.find((country) => country.id === this.form.sourceCountry.value);

        this.form.sourceLocation.value = country.geoRegionId;
    }

    worldRegionChangeTarget() {
        this.countries = this.worldRegions.filter((country) => country.worldRegion === this.form.targetRegion.value);
    }

    countryChangeTarget() {
        const country = this.countries.find((country) => country.id === this.form.targetCountry.value);

        this.form.targetLocation.value = country.geoRegionId;
    }

    // function for filtering the gateways displayed
    // this is used for excluding any previously selected gateway from the list of available gateways

    // function for setting the type of the source gateway
    setSource(type) {
        // if the endpoint type hasn't change, do nothing
        if (this.sourceGatewayType === type) {
            this.updateGatewayList(type);
            return;
        }

        // if both the old endpoint type and the new endpoint type  uses the aws regions, don't do anything
        if (
            (this.sourceGatewayType === '' ||
                this.sourceGatewayType === 'AWSCPEGW' ||
                this.sourceGatewayType === 'VCPEGW') &&
            (type === 'AWSCPEGW' || type === 'VCPEGW')
        ) {
            this.sourceGatewayType = type;
            this.form.sourceGateway.value = '';
            this.updateGatewayList(type);
            return;
        }

        // if both the old endpoint type and the new endpoint type  uses the azure regions, don't do anything
        if (
            (this.sourceGatewayType === 'AZSGW' || this.sourceGatewayType === 'AZCPEGW') &&
            (type === 'AZSGW' || type === 'AZCPEGW')
        ) {
            this.sourceGatewayType = type;
            this.form.sourceGateway.value = '';
            this.updateGatewayList(type);
            return;
        }

        switch (type) {
            case 'AWSCPEGW':
            case 'VCPEGW':
                this.sourceRegions = this.awsRegions;
                break;

            case 'AZCPEGW':
            case 'AZSGW':
                this.sourceRegions = this.azureRegions;
                break;

            case 'GCPCPEGW':
                this.sourceRegions = this.googleRegions;
                break;
        }

        this.sourceGatewayType = type;
        this.form.sourceGateway.value = '';
        this.form.sourceLocation.value = '';
        this.updateGatewayList(type);
    }

    // function for setting the type of the target gateway
    setTarget(type) {
        // if the endpoint type hasn't change, do nothing
        if (this.targetGatewayType === type) {
            return;
        }

        // if both the old endpoint type and the new endpoint type  uses the aws regions, don't do anything
        if (
            (this.targetGatewayType === '' ||
                this.targetGatewayType === 'AWSCPEGW' ||
                this.targetGatewayType === 'VCPEGW') &&
            (type === 'AWSCPEGW' || type === 'VCPEGW')
        ) {
            this.targetGatewayType = type;
            this.form.targetGateway.value = '';
            return;
        }

        // if both the old endpoint type and the new endpoint type  uses the azure regions, don't do anything
        if (
            (this.targetGatewayType === 'AZSGW' || this.targetGatewayType === 'AZCPEGW') &&
            (type === 'AZSGW' || type === 'AZCPEGW')
        ) {
            this.targetGatewayType = type;
            this.form.targetGateway.value = '';
            return;
        }

        switch (type) {
            case 'AWSCPEGW':
            case 'VCPEGW':
                this.targetRegions = this.awsRegions;
                break;

            case 'AZCPEGW':
            case 'AZSGW':
                this.targetRegions = this.azureRegions;
                break;

            case 'GCPCPEGW':
                this.targetRegions = this.googleRegions;
                break;
        }

        this.targetGatewayType = type;
        this.form.targetGateway.value = '';
        this.form.targetLocation.value = '';
        this.updateGatewayList(type);
    }

    // function for disabling selection of the source gateway from the drop down
    disableSourceSelect() {
        // if the user starts to provide the necessary information for the gateawy, hide the select list
        if (this.form.sourceName.value !== '' || this.form.sourceLocation.value !== '') {
            this.canSelectSource = false;
        } else {
            this.canSelectSource = true;
        }
    }

    // function for disabling the creation of a gateway
    disableSourceCreate() {
        // if the user selects a gateway from the drop down hide the form for creating the gateway
        if (this.form.sourceGateway.value !== '') {
            this.canCreateSource = false;
        } else {
            this.canCreateSource = true;
        }
    }

    // function for disabling selection of the source gateway from the drop down
    disableTargetSelect() {
        // if the user starts to provide the necessary information for the gateawy, hide the select list
        if (this.form.targetName.value !== '' || this.form.targetLocation.value !== '') {
            this.canSelectTarget = false;
        } else {
            this.canSelectTarget = true;
        }
    }

    // function for disabling the creation of a gateway
    disableTargetCreate() {
        // if the user selects a gateway from the drop down hide the form for creating the gateway
        if (this.form.targetGateway.value !== '') {
            this.canCreateTarget = false;
        } else {
            this.canCreateTarget = true;
        }
    }

    // this prevents the user from selecting the same gateway for both the source and target gateways
    private filterGateways() {
        this.displayedGateways = [];
        if (this.step === 3 && this.form.targetGateway.value != null) {
            this.displayedGateways = this.gateways.filter((gateway) => gateway.id !== this.form.targetGateway.value);
        } else if (this.step === 4 && this.form.sourceGateway.value != null) {
            this.displayedGateways = this.gateways.filter((gateway) => gateway.id !== this.form.sourceGateway.value);
        } else {
            this.displayedGateways = this.gateways.concat([]);
        }

        // if there are no gateways of the selected type
        if (this.displayedGateways.length === 0) {
            // if it's step 3 and the user hasd previously selected a gateway from a drop down list (causing the gateway inputs to become hidden)
            if (this.step === 3 && !this.canCreateSource) {
                // allow the user to see the gateway input fields for source gateway
                this.canCreateSource = true;

                // if it's step 4 and the user hasd previously selected a gateway from a drop down list (causing the gateway inputs to become hidden)
            } else if (this.step === 4 && !this.canCreateTarget) {
                // allow the user to see the gateway input fields for target gateway
                this.canCreateTarget = true;
            }
        }

        if (this.step === 3) {
            this.disableSourceSelect();
            this.disableSourceCreate();
        } else if (this.step === 4) {
            this.disableTargetSelect();
            this.disableTargetCreate();
        }
    }
}
