import { Injectable } from '@angular/core';
import { PagedGetOption } from '@lagoshny/ngx-hateoas-client';

@Injectable({
    providedIn: 'root',
})
export class ZitiOptionsService {
    public getOptions(currentNetwork, embedAll = false) {
        if (!currentNetwork) {
            return {};
        }
        const params = [{ key: 'networkId', value: currentNetwork.id }];
        if (embedAll) {
            params.push({ key: 'embed', value: 'all' });
        }
        return { params };
    }

    public getOptionsNew(currentNetwork, embedAll = false): PagedGetOption {
        if (!currentNetwork) {
            return {};
        }
        const params = { networkId: currentNetwork.id };
        if (embedAll) {
            params['embed'] = 'all';
        }
        return { params };
    }
}
