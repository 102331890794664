<div>
    <div
        [ngClass]="{
            step4Icons: (isInterconnect && step !== 6) || !isInterconnect,
            step6Icons: isInterconnect && step === 6
        }"
    >
        <div class="step4IconSource">
            <div [ngClass]="sourceGatewayType" class="provider"></div>
            <div class="cloudIcon">
                <div class="cloudText">
                    {{ isInterconnect ? 'Cloud One' : 'Source' }}
                </div>
            </div>
        </div>
        <div class="step4IconTarget">
            <div [ngClass]="targetGatewayType" class="provider"></div>
            <div class="cloudIcon">
                <div class="cloudText">
                    {{ isInterconnect ? 'Cloud Two' : 'Destination' }}
                </div>
            </div>
        </div>
    </div>
    <div class="wizard mini">
        <div
            [ngClass]="{
                rightLeft: isInterconnect && step === 5,
                leftRight: isInterconnect && step === 6
            }"
            class="header"
        >
            <div class="darken"></div>
            <div
                [ngClass]="{
                    rightcloudarrow: isInterconnect && step === 5,
                    leftcloudarrow: isInterconnect && step === 6
                }"
            ></div>
            <div *ngIf="!isInterconnect" class="darkenText">How would you like to communicate?</div>
            <div *ngIf="isInterconnect" class="darkenText">
                How would you like <b>Cloud {{ step === 5 ? 'One' : 'Two' }}</b> to access
                <b>Cloud {{ step === 5 ? 'Two' : 'One' }}</b
                >?
            </div>
        </div>
        <div class="row padded">
            <div class="subtitle">Expose Single IPs</div>
            <div *ngFor="let service of ipHostServices; let index = index" class="row">
                <div *ngIf="service.serviceClass === 'CS'">
                    <div
                        (click)="removeHostService(index)"
                        *ngIf="index > 0"
                        [ngClass]="{ minus: service.id !== '' }"
                        class="gridAdd"
                        id="AppWANRemoveService_{{ index }}"
                    ></div>
                    <div class="grid three" style="padding-bottom: 5px">
                        <label *ngIf="index === 0" [ngClass]="{ error: form.ipHostServices.ip.error }">{{
                            form.ipHostServices.ip.label
                        }}</label>
                        <label *ngIf="index === 0" [ngClass]="{ error: form.ipHostServices.port.error }">{{
                            form.ipHostServices.port.label
                        }}</label>
                        <label *ngIf="index === 0" [ngClass]="{ error: form.ipHostServices.protocol.error }">{{
                            form.ipHostServices.protocol.label
                        }}</label>

                        <input
                            [(ngModel)]="ipHostServices[index].networkIp"
                            [ngClass]="{
                                error:
                                    form.ipHostServices.errors[index] &&
                                    form.ipHostServices.errors[index].indexOf('IP') >= 0
                            }"
                            id="NewServiceIp_{{ index }}"
                            max="15"
                            placeholder="{{ form.ipHostServices.ip.placeholder }}"
                            type="text"
                        />
                        <input
                            [(ngModel)]="ipHostServices[index].networkFirstPort"
                            [ngClass]="{
                                error:
                                    form.ipHostServices.errors[index] &&
                                    form.ipHostServices.errors[index].indexOf('Port') >= 0
                            }"
                            id="NewServicePort_{{ index }}"
                            max="32"
                            placeholder="{{ form.ipHostServices.port.placeholder }}"
                        />

                        <select
                            [(ngModel)]="ipHostServices[index].serviceType"
                            [ngClass]="{
                                error:
                                    form.ipHostServices.errors[index] &&
                                    form.ipHostServices.errors[index].indexOf('Protocol') >= 0
                            }"
                            id="NewServiceProto_{{ index }}"
                        >
                            <option value="">
                                {{ form.ipHostServices.protocol.placeholder }}
                            </option>
                            <option value="TCP">TCP</option>
                            <option value="UDP">UDP</option>
                            <option value="ALL">TCP/UDP</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="ortext">OR </span>
                <span (click)="addHostServiceFields()" class="linkButton" id="AddService">Add Additional +</span>
            </div>

            <div *ngIf="!zitiEnabledService.zitiPure" class="title light andor">and/or</div>
            <div *ngIf="!zitiEnabledService.zitiPure" class="subtitle">Expose Muliple IPs</div>
            <div *ngIf="!zitiEnabledService.zitiPure">
                <div *ngFor="let service of ipNetworkServices; let index = index" class="row">
                    <div *ngIf="service.serviceClass === 'GW'">
                        <div
                            (click)="removeNetworkService(index)"
                            *ngIf="index > 0"
                            [ngClass]="{ minus: service.id !== '' }"
                            class="gridAdd"
                            id="AppWANRemoveNetworkService_{{ index }}"
                        ></div>
                        <div class="grid split" style="padding-bottom: 5px">
                            <label *ngIf="index === 0" [ngClass]="{ error: form.networkServices.ip.error }">{{
                                form.networkServices.ip.label
                            }}</label>
                            <label *ngIf="index === 0" [ngClass]="{ error: form.networkServices.intercept.error }">{{
                                form.networkServices.intercept.label
                            }}</label>
                            <input
                                [(ngModel)]="ipNetworkServices[index].gatewayIp"
                                [ngClass]="{
                                    error:
                                        form.networkServices.errors[index] &&
                                        form.networkServices.errors[index].indexOf('GatewayIp') >= 0
                                }"
                                id="NewNetworkServiceIp_{{ index }}"
                                max="18"
                                placeholder="{{ form.networkServices.ip.placeholder }}"
                                type="text"
                            />
                            <input
                                [(ngModel)]="ipNetworkServices[index].interceptIp"
                                [ngClass]="{
                                    error:
                                        form.networkServices.errors[index] &&
                                        form.networkServices.errors[index].indexOf('Intercept') >= 0
                                }"
                                id="NewNetworkServiceIntercept_{{ index }}"
                                max="15"
                                placeholder="{{
                                    ipNetworkServices[index].gatewayIp == null ||
                                    ipNetworkServices[index].gatewayIp === ''
                                        ? '10.0.0.0/24'
                                        : ipNetworkServices[index].gatewayIp
                                }}"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!zitiEnabledService.zitiPure" class="row">
                <span class="ortext">OR </span>
                <span (click)="addNetworkServiceFields()" class="linkButton" id="AddService">Add Additional +</span>
            </div>
        </div>
        <div class="row">&nbsp;</div>
    </div>
</div>
