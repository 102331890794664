import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ZitiLoginService {

  constructor() {}

  clearZitiSession() {
    localStorage.removeItem('ziti.settings');
  }
}
