<div class="signup-complete-container">
    <div class="area mid">
        <div class="titleImage step7"></div>
        <div *ngIf="status === 'waiting'" class="title">Congratulations!</div>
        <div *ngIf="status === 'delayed'" class="title">Sorry for the wait...</div>
        <div *ngIf="status === 'error'" class="title">Something went wrong...</div>
        <div *ngIf="status === 'waiting'" class="area middle subtitle">
            <div class="miniTitleGetStarted">
                You have successfully signed up for a NEW NetFoundry account! Please give us a moment to finalize your
                organization. After which you will be logged into the console.
            </div>
        </div>
        <div *ngIf="status === 'delayed'" class="area middle subtitle subtitle-long">
            <div class="miniTitleGetStarted">
                It's taking a little longer than normal to get things setup..
                <br />
                <br />
                If you'd like to wait until everything is ready, stay on this page and we'll log you into the console
                once everything is finished. Otherwise, look out for a welcome email from us with a link to log in to
                your account.
            </div>
        </div>
        <div *ngIf="status === 'error'" class="area middle subtitle subtitle-long">
            <div class="miniTitleGetStarted">
                We encountered and error when trying to spin up your account.
                <br />
                <br />
                Click <a href="/signup">here</a> to return to the signup page and try again. If the problem persists
                please contact support for additional assistance.
            </div>
        </div>
        <div *ngIf="status !== 'error'" class="loader-container">
            <app-loading-indicator [isLoading]="true" id="CallbackLoader"></app-loading-indicator>
        </div>
    </div>
    <div class="copyright-container">
        <app-copyright></app-copyright>
    </div>
</div>
