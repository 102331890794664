<div class="fullModal opened" id="AppWANMigrationModal">
    <div class="fullRow">
        <div class="sized">
            <div *ngIf="step === 1" class="title stepItem isAt">CLOUD MIGRATION APPWAN INTRODUCTION</div>
            <div *ngIf="isNotIntroSummary()" class="title stepItem isAt">CREATE A NEW APPWAN</div>
            <div *ngIf="step === 7" class="title stepItem isAt">CONGRATULATIONS</div>

            <div *ngIf="step === 1" class="subtitle tiny stepItem isAt">
                In this builder, we'll guide you through the process of creating
                <a
                    href="https://netfoundry.zendesk.com/hc/en-us/articles/360017558212-Introduction-to-Gateway-Endpoints"
                    target="_blank"
                    >Gateways</a
                >
                to install in both your current source and destination cloud. Including these Gateways in your
                <a
                    href="https://netfoundry.zendesk.com/hc/en-us/articles/360018510571-Introduction-to-AppWANs"
                    target="_blank"
                    >AppWAN</a
                >
                will allow them to communicate over your
                <a
                    href="https://netfoundry.zendesk.com/hc/en-us/articles/360018194551-Create-and-Manage-Networks"
                    target="_blank"
                    >Network</a
                >. Last we'll specify the
                <a
                    href="https://netfoundry.zendesk.com/hc/en-us/articles/360018509951-Introduction-to-Servicess"
                    target="_blank"
                    >Services</a
                >, Single IP's or a Range of IP's, through which your source can communicate with your destination
                cloud.
            </div>

            <div *ngIf="step === 2" class="subtitle smaller stepItem isAt">
                Looking to migrate your application to or between clouds? Great, get started by naming your app and
                tapping next.
            </div>
            <div *ngIf="step === 3" class="subtitle smaller stepItem isAt">
                Perfect, now tell us about your<br />current cloud or datacenter.
            </div>
            <div *ngIf="step === 4" class="subtitle smaller stepItem isAt">
                Great, now tell us about the<br />Cloud you’ll be migrating too.
            </div>
            <div *ngIf="step === 5" class="subtitle smaller stepItem isAt">
                Excellent, now let's specify the services through which your current source can communicate with your
                new cloud.
            </div>
            <div *ngIf="step === 7" class="subtitle smaller stepItem isAt">
                Your AppWAN has been created!<br />A network summary is below.
            </div>

            <div class="row">
                <div *ngIf="step === 1" class="slider isAt">
                    <div class="step1Icon"></div>
                    <div class="slideTitle">Your App Name</div>
                </div>

                <div
                    [ngClass]="{
                        slider: !this.skip || hasStepped,
                        isAt: step === 2,
                        passed: isPassedStep(2)
                    }"
                >
                    <div *ngIf="step === 2">
                        <div class="step2Icon"></div>
                        <div class="wizard mini">
                            <div class="header">
                                <div class="darken"></div>
                                <div class="darkenText">What would you like to call this AppWAN?</div>
                            </div>
                            <div class="padded">
                                <label [ngClass]="{ error: form.appWanName.error }" for="AppWANName"
                                    >{{ form.appWanName.label
                                    }}<span [ngClass]="{ error: form.appWanName.error }" class="note"
                                        >Required</span
                                    ></label
                                >
                                <input
                                    [(ngModel)]="form.appWanName.value"
                                    [ngClass]="{ error: form.appWanName.error }"
                                    id="AppWanName"
                                    maxlength="100"
                                    placeholder="{{ form.appWanName.placeholder }}"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    [ngClass]="{
                        isAt: step === 3 || step === 4,
                        passed: isPassedStep(3) && isPassedStep(4)
                    }"
                    class="slider"
                >
                    <app-appwan-wizard-gateway-select
                        #gatewaySelect
                        *ngIf="step !== 1 && step !== 2"
                        [sourceGatewayModel]="sourceGateway"
                        [step]="step"
                        [targetGatewayModel]="targetGateway"
                    ></app-appwan-wizard-gateway-select>
                </div>

                <div [ngClass]="{ isAt: step === 5, passed: isPassedStep(5) }" class="slider">
                    <div *ngIf="step !== 1 && step !== 2">
                        <app-appwan-wizard-service-select
                            #serviceSelect
                            [sourceGatewayType]="sourceGatewayType"
                            [step]="step"
                            [targetGatewayType]="targetGatewayType"
                        >
                        </app-appwan-wizard-service-select>
                    </div>
                </div>

                <div
                    *ngIf="step !== 1 && step !== 2"
                    [ngClass]="{ isAt: step === 6, passed: isPassedStep(6) }"
                    class="silder"
                >
                    <app-appwan-wizard-save
                        #saveComponent
                        [appwanModel]="model"
                        [services]="services"
                        [showStep]="6"
                        [sourceGateway]="sourceGateway"
                        [step]="step"
                        [targetGateway]="targetGateway"
                    >
                    </app-appwan-wizard-save>
                </div>

                <div
                    *ngIf="step !== 1 && step !== 2"
                    [ngClass]="{ isAt: step === 7, passed: isPassedStep(7) }"
                    class="slider"
                >
                    <div class="sized mid">
                        <div class="grid two marginBottom">
                            <div class="hint">
                                <div class="hinttitle">NEW GATEWAYS</div>
                                <div class="hintsubtitle">Tap to Launch and Register</div>
                                <div class="image icon-ClickIcon"></div>
                            </div>
                            <div class="hint">
                                <div class="hinttitle">NEW SERVICES</div>
                                <div class="hintsubtitle">Tap to Edit Advanced Options</div>
                                <div class="image icon-TaptoEditAdvanced"></div>
                            </div>
                        </div>
                    </div>

                    <app-appwan-wizard-summary
                        *ngIf="step === 7"
                        [finalModel]="finalModel"
                        [ipHostServices]="ipHostServices"
                        [ipNetworkServices]="ipNetworkServices"
                        [showStep]="7"
                        [step]="step"
                    >
                    </app-appwan-wizard-summary>

                    <div *ngIf="step === 7" class="sized mid">
                        <div (click)="clone()" class="cloneButton">
                            <div class="icon"></div>
                            <div class="content">
                                Want to add another environment<br />with the same services or endpoints?
                            </div>
                            <div class="buttonText">Tap To Clone</div>
                            <div class="tapIcon icon-ClickIcon"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            (click)="next()"
            *ngIf="step === 1"
            [ngClass]="{ isAt: step === 1 }"
            class="button squared base"
            id="GetStarted"
        >
            Let's Get Started
        </div>
        <div
            (click)="toggleSkip()"
            *ngIf="step === 1"
            [ngClass]="{ isAt: step === 1 }"
            class="checkbox"
            id="toggleSkip"
        >
            <div [ngClass]="{ selected: skip }" class="tSelect" id="toggleSkipButton"></div>
            <div class="checkLabel">Skip This Next Time</div>
        </div>
    </div>

    <div (click)="close()" class="buttonBall close" id="CloseAppWanWizardButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button></app-form-support-button>

    <div [ngClass]="{ isAt: isNotIntroSummary() }" class="steps">
        <div (click)="goTo(2)" [ngClass]="{ isAt: step === 2 }" class="step"></div>
        <div (click)="goTo(3)" [ngClass]="{ isAt: step === 3 }" class="step"></div>
        <div (click)="goTo(4)" [ngClass]="{ isAt: step === 4 }" class="step"></div>
        <div (click)="goTo(5)" [ngClass]="{ isAt: step === 5 }" class="step"></div>
    </div>

    <div (click)="back()" [ngClass]="{ isAt: isBetweenSteps(2, 6) }" class="button squared back" id="backButton">
        Back
    </div>
    <div (click)="next()" [ngClass]="{ isAt: isBetweenSteps(1, 6) }" class="button squared next" id="nextButton">
        Next
    </div>
    <div (click)="next()" [ngClass]="{ isAt: step === 5 }" class="button squared next" id="createButton">
        Create My AppWAN
    </div>
</div>
