import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlarmDatePipe } from './alarm-date.pipe';
import { AlarmSeverityPipe } from './alarm-severity.pipe';
import { AlarmTranslatePipe } from './alarm-translate.pipe';
import { FilterStringPipe } from './filter-string.pipe';
import { FromDatePipe } from './from-date.pipe';
import { LookupPipe } from './lookup.pipe';
import { OnlineStatusPipe } from './online-status.pipe';
import { RegionTransformPipe } from './region-transform.pipe';
import { SortbyPipe } from './sortby.pipe';
import { StatusPipe } from './status.pipe';
import { TableSearchPipe } from './table-search.pipe';
import { TextSearchPipe } from './text-search.pipe';
import { TobytesPipe } from './tobytes.pipe';
import { UserStatusPipe } from './user-status.pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        TobytesPipe,
        FromDatePipe,
        SortbyPipe,
        StatusPipe,
        OnlineStatusPipe,
        RegionTransformPipe,
        AlarmDatePipe,
        AlarmSeverityPipe,
        AlarmTranslatePipe,
        FilterStringPipe,
        LookupPipe,
        TableSearchPipe,
        TextSearchPipe,
        UserStatusPipe,
        SafePipe,
    ],
    exports: [
        TobytesPipe,
        FromDatePipe,
        SortbyPipe,
        StatusPipe,
        OnlineStatusPipe,
        RegionTransformPipe,
        AlarmDatePipe,
        AlarmSeverityPipe,
        AlarmTranslatePipe,
        FilterStringPipe,
        LookupPipe,
        TableSearchPipe,
        TextSearchPipe,
        UserStatusPipe,
        SafePipe,
    ],
    providers: [
        TobytesPipe,
        FromDatePipe,
        SortbyPipe,
        StatusPipe,
        OnlineStatusPipe,
        RegionTransformPipe,
        AlarmDatePipe,
        AlarmSeverityPipe,
        AlarmTranslatePipe,
        FilterStringPipe,
        LookupPipe,
        TableSearchPipe,
        TextSearchPipe,
        UserStatusPipe,
        SafePipe,
    ],
})
export class UiPipesModule {}
