import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT, PostureCheck } from '@netfoundry-ui/shared/model';
import { Observable, lastValueFrom, firstValueFrom, catchError, of } from 'rxjs';
import {
    GetOption,
    HateoasResourceOperation,
    PagedGetOption,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PostureCheckService extends HateoasResourceOperation<PostureCheck> {
    static defaultPaginationSize = 500;
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;
    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(PostureCheck);
    }
    getResource(): Observable<PostureCheck> {
        throw new Error('Do not use: see get getPostureCheck');
    }

    getPage(): Observable<PagedResourceCollection<PostureCheck>> {
        throw new Error('Do not use: see getPostureChecks');
    }

    getPostureCheck(id, options: GetOption = {}): Promise<PostureCheck> {
        return super
            .getResource(id, { ...PostureCheckService.defaultHttpAccept, ...options })
            .toPromise()
            .then((endpoint) => endpoint);
    }

    async getPostureCheckPage(options?: PagedGetOption, includeProperties?: string): Promise<PostureCheck[]> {
        let params = { ...PostureCheckService.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super
            .getPage(params)
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((endpoints) => endpoints.resources);
    }

    validateUpdate(postureCheckId: string, model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return lastValueFrom(
            this.http
                .patch(this.environment.v2apiUrl + 'posture-checks/' + postureCheckId, model, {
                    headers: headers,
                    responseType: 'json',
                })
                .pipe(catchError((err) => of([{ http: err }])))
        );
    }

    validateCreate(model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return lastValueFrom(
            this.http
                .post(this.environment.v2apiUrl + 'posture-checks', model, {
                    headers: headers,
                    responseType: 'json',
                })
                .pipe(catchError((err) => of([{ http: err }])))
        );
    }

    public getJson(postureCheckId: string): Observable<string> {
        const options: any = {};
        const url = `${this.environment.v2apiUrl}posture-checks/${postureCheckId}/?meta=ziti,diffZiti`;
        return this.http.get(url, options) as Observable<any>;
    }

    public downloadToCsv(networkId: string): Observable<any> {
        const params = new HttpParams().set('networkId', networkId).set('size', this.lastTotalCount);
        const headers = new HttpHeaders().set('Accept', 'text/csv');
        return this.http.get(this.environment.v2apiUrl + 'posture-checks', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public downloadFileFormat(
        networkId: string,
        format = 'text/csv',
        params: HttpParams = new HttpParams()
    ): Observable<any> {
        params = params.set('networkId', networkId);
        const headers = new HttpHeaders().set('Accept', format);
        return this.http.get(this.environment.v2apiUrl + 'posture-checks', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }
}
