import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('config-types')
export class ConfigType extends Resource {
    id: string;
    networkId: string;
    name: string;
    configTypeId: string;
    zitiId: string;
    schema: any;
    ownerIdentityId: string;
    createdAt: string;
    updatedAt: string;
    selected = false;
    reserved: boolean;
    exclusiveTo: boolean;
}
