import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Network, NetworkV2 } from '@netfoundry-ui/shared/model';
import { ApiService } from '@netfoundry-ui/shared/services';
import _ from 'lodash';

@Component({
    selector: 'app-resource-status',
    templateUrl: './resource-status.component.html',
    styleUrls: ['./resource-status.component.scss'],
})
export class ResourceStatusComponent implements OnChanges, OnInit {
    @Input() item = {
        status: 0,
        id: null,
        name: '',
        online: false,
        state: '',
        enabled: true,
    };
    @Input() resourceType: string;
    @Input() isZiti = false;
    @Input() showName = true;
    showLoader = false;
    @Input() showBubble = true;
    statusClass: string;
    deleteClass: string;
    statusClassV7: string;
    currentNetwork: Network | NetworkV2;
    statusTooltipMap = {
        v7Offline: 'Offline',
        v7Error: 'Error',
    };

    public statusLookup = {
        '0': 'Unknown',
        '100': 'New',
        '200': 'Building',
        '300': 'Active',
        '400': 'Registered',
        '500': 'Error',
        '600': 'Updating',
        '800': 'Deleting',
        '900': 'Defunct',
        WARNING: 'Warning',
        PROVISIONING: 'PROVISIONING',
        RUNNING: 'Running',
        PROVISIONED: 'Active',
        NEW: 'New',
        SUCCESS: 'Success',
        FINISHED: 'Finished',
        PROCESS_ERROR: 'Failed',
        FAILED: 'Failed',
        ERROR: 'Error',
        SKIPPED: 'Skipped',
        CANCELLED: 'Cancelled',
    };

    public onlineStatusLookup = {
        '100': 'Registering',
        '200': 'Offline',
        '300': 'Online',
    };

    constructor(public apiService: ApiService) {
        if (this.item['status'] === undefined) {
            this.item.status = 0;
        }
    }

    get statusTooltip() {
        // check if there is a value mapped to the status class, if not just use the status class
        return this.item.enabled === false
            ? 'Router Disabled'
            : _.get(this.statusTooltipMap, this.statusClass, this.statusClass);
    }

    ngOnChanges() {
        this.showLoader = false;

        if (this.currentNetwork) {
            if (this.apiService.getNetworkVersion(this.currentNetwork) < 7) {
                this.setupV6Status();
            }
            if (this.apiService.getNetworkVersion(this.currentNetwork) >= 7) {
                this.getV7GenericResourceStatus();
            }
        }
    }

    ngOnInit() {
        this.apiService.currentNetwork.subscribe((network) => {
            this.currentNetwork = network;
            this.ngOnChanges();
        });
    }

    getV7GenericResourceStatus() {
        this.deleteClass = '';

        if (this.item.status && this.item.status.toString() === 'PROVISIONING') {
            this.showLoader = true;
            return;
        } else if (this.item.status && this.item.status.toString() === 'ERROR') {
            this.statusClass = 'v7Error';
            return;
        }

        switch (this.resourceType) {
            case 'edgeRouter':
                this.statusClass = this.item.online ? 'Online' : 'v7Offline';
                break;
            case 'v7Endpoint':
                this.statusClass = this.item.online ? 'Online' : 'v7Offline';
                break;
            case 'process-execution':
                if (this.item.state === 'RUNNING' || this.item.state === 'STARTED') {
                    this.showLoader = true;
                } else {
                    const state = this.item.state === 'ERROR' ? 'PROCESS_ERROR' : this.item.state;
                    this.statusClass = this.statusLookup[state];
                }
                break;
            default:
                this.statusClass = this.statusLookup[this.item['state']];
        }
    }

    private setupV6Status() {
        this.deleteClass = '';
        this.statusClass = this.statusLookup[this.item['status']];

        if (
            (this.resourceType === 'client' || this.resourceType === 'gateway' || this.resourceType === 'endpoint') &&
            this.item.status === 300
        ) {
            this.statusClass = 'Unregistered';
            this.showLoader = false;
        } else if (
            (this.resourceType === 'client' ||
                this.resourceType === 'gateway' ||
                this.resourceType === 'endpoint' ||
                this.resourceType === 'edgeRouter') &&
            this.item.status === 400
        ) {
            if (!this.isZiti) {
                this.statusClass = this.onlineStatusLookup[this.item['currentState']];
            } else {
                this.statusClass = 'Active';
            }
            this.showLoader = false;
        } else if (this.item.status === 200 || this.item.status === 600 || this.item.status === 800) {
            this.showLoader = true;
        }
        // Special CSS if deleting?
        if (this.item.status === 800) {
            this.deleteClass = 'IsDeleting';
        }
    }
}
