import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { LoggerService } from '@netfoundry-ui/shared/services';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';

import { isEmpty } from 'lodash';
import { LOGIN_SERVICE, LoginServiceType } from './login-service.type';
import { AuthorizationService } from './authorization.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationResolveService {
    constructor(
        @Inject(LOGIN_SERVICE) private loginSvc: LoginServiceType,
        private authorizationService: AuthorizationService,
        private logger: LoggerService,
        private router: Router,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    async resolve(route: ActivatedRouteSnapshot): Promise<any> {
        if (
            this.loginSvc.isAuthenticated() &&
            (window.location.pathname.indexOf('/signup-complete') >= 0 ||
                window.location.pathname.indexOf('/signup-return') >= 0)
        ) {
            return true;
        } else if (this.loginSvc.isAuthenticated() && !this.authorizationService.obtainedMopPermissions) {
            try {
                //User is authenticated, now check to see if user authorized first before trying to get permissions
                const authResult = await this.authorizationService.getSilentAuthorization();
                if (isEmpty(authResult)) {
                    const hostname = window.location.hostname;
                    const isVanityUrl = hostname !== this.environment.originDomain;
                    this.logger.error('User is not authorized. Returning to login page.');
                    this.loginSvc.logout(false, true);
                    if (isVanityUrl) {
                        this.loginSvc._redirectToRootDomainForLogin(hostname);
                    } else {
                        this.router.navigate(['/login']);
                    }
                    return;
                }
                await this.authorizationService.getAuthorization();
            } catch (error) {
                this.logger.error(`Error getting authorization: ${error?.message}`);
                this.router.navigate(['/login']);
            }
            return;
        } else {
            return;
        }
    }
}
