import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService, BillingEnabledService, BillingService } from '@netfoundry-ui/feature/shared-services';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';

interface Customer {
    firstName: string;
    lastName: string;
    email: string;
    paymentMethod: PaymentMethod;
}

interface Address {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
}

interface PaymentMethod {
    cardToken: string;
}

interface Site {
    name: string;
    label: string;
    networkGroupId: string;
    billingAccountId: string;
}

@Component({
    selector: 'app-add-credit-card-form',
    templateUrl: './add-credit-card-form.component.html',
    styleUrls: ['./add-credit-card-form.component.scss'],
})
export class AddCreditCardFormComponent {
    isUpdating = false;
    obtainSourceError = false;
    isLoading = false;
    billingForm: FormGroup;
    keyId;
    forGrowth = false;

    canUpdateKey = false;
    canUpdateBilling = false;

    // CB  start
    customer: Customer;
    cardComponent: any;

    error: string;
    selectedUser: number;

    networkGroupDetails = {
        networkGroupId: '43f6687b-eb3c-4cff-8ca2-591dcfc31a69',
        billingAccountId: '372432f4-0eb9-455b-855a-7195ba56aac7',
    };

    // CB ebd

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private growlerService: GrowlerService,
        private dialogRef: MatDialogRef<AddCreditCardFormComponent>,
        private fb: FormBuilder,
        private billingService: BillingService,
        private accountService: AccountService,
        private apiService: ApiService,
        private billingEnabledService: BillingEnabledService,
        private logger: LoggerService,
        public authorizationService: AuthorizationService
    ) {
        this.billingForm = this.fb.group({
            firstName: [this.data?.firstName || '', [Validators.required]],
            lastName: [this.data?.lastName || '', [Validators.required]],
            email: [this.data?.email || '', [Validators.required, Validators.email]],
        });
        this.error = '';
        this.isLoading = false;
        this.customer = {
            firstName: this.data?.firstName || '',
            lastName: this.data?.lastName || '',
            email: this.data?.email || '',
            paymentMethod: { cardToken: '' },
        };
        this.forGrowth = this.data.forGrowth;
        this.selectedUser = -1;

        this.canUpdateBilling = this.authorizationService.canEditBillingSubscriptions();
    }

    onReady = (cardComponent: any) => {
        this.cardComponent = cardComponent;
        this.cardComponent.focus(true);
        this.cardComponent.placeholder = '* Card Number';
    };

    // function for closing the dialog
    cancel() {
        this.dialogRef.close();
    }

    onTokenize = (event: { preventDefault: () => void }) => {
        event.preventDefault();
        this.error = '';
        this.customer.paymentMethod.cardToken = '';
        this.isLoading = true;
        this.cardComponent
            .tokenize()
            .then((data: { token: any }) => {
                this.customer.paymentMethod.cardToken = data.token;
                this.isLoading = false;
                this.logger.info(JSON.stringify(this.customer));
                this.saveProfile(data.token);
            })
            .catch((error: { token: any }) => {
                this.isLoading = false;
                this.error = 'Problem while tokenizing your card details';
                this.logger.error(this.error);
            });
    };

    async saveProfile(token) {
        let profile_id = '';
        // Save the card to stripe, get token, assign to user via strip user id
        if (this.billingForm.valid) {
            const email = this.billingForm.get('email').value;

            this.customer.firstName = this.billingForm.get('firstName').value;
            this.customer.lastName = this.billingForm.get('lastName').value;
            this.customer.email = this.billingForm.get('email').value;

            if (token !== null) {
                this.isLoading = true;
                const wasProfileExists = await this.billingService
                    .getPaymentProfile(this.apiService.theOrgIs['billingAccountId'])
                    .toPromise()
                    .then((result: { paymentProfileId: any }) => {
                        profile_id = result[0].paymentProfileId;

                        return true;
                    })
                    .catch((error: { token: any }) => {
                        this.isLoading = false;
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Error Updating Payment',
                                'There was an error getPaymentProfile. Please try again later or contact support.'
                            )
                        );
                        return false;
                    });

                if (wasProfileExists && profile_id && profile_id !== '') {
                    const wasProfileUpdated = await this.billingService
                        .patchPaymentProfileCard(profile_id, this.customer)
                        .subscribe(
                            (result) => {
                                this.apiService.setPaymentProfile(result);
                                const newBillingOrg = this.billingService.currentBillingAccount;
                                newBillingOrg.email = email;
                                this.growlerService.show(
                                    new GrowlerData(
                                        'success',
                                        'Success',
                                        'Payment Successfully Updated',
                                        'Your payment information was successfully updated'
                                    )
                                );
                                this.isUpdating = false;
                                // this.card.clear();
                                this.isLoading = false;
                                this.accountService.refreshAccountDetails();
                                this.dialogRef.close(true);
                            },
                            (error) => {
                                this.isLoading = false;
                                this.growlerService.show(
                                    new GrowlerData(
                                        'error',
                                        'Error',
                                        'Error Updating card payment profile',
                                        'Please contact support for help.'
                                    )
                                );
                                return false;
                            }
                        );
                }
            }
        }
    }
}
