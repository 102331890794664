import {Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { _MatTabGroupBase } from '@angular/material/tabs';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { AppWanServiceV2, AttributesService } from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { EVENT_FIELDS, EventFields, NetworkV2 } from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService } from '@netfoundry-ui/shared/services';
import { TEMPLATE_SEARCH_SERVICE, TemplateSearchService } from "@netfoundry-ui/shared/apiv2";
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import semver from 'semver';

@Component({
    selector: 'app-metrics',
    templateUrl: './metrics.component.html',
    styleUrls: ['./metrics.component.scss'],
})
export class MetricsComponent implements OnInit, OnDestroy {
    setDateFilterEvent: EventEmitter<boolean> = new EventEmitter();
    setNewEndTime: EventEmitter<string> = new EventEmitter();
    setNewStartTime: EventEmitter<string> = new EventEmitter();
    @ViewChild('hostingEndpoint') hostingEndpoint;
    @ViewChild('dialingEndpoint') dialingEndpoint;
    currentNetwork: NetworkV2 | undefined;
    dateFilter = '24h';
    endTime: number = Date.now();
    startTime: number = this.endTime - 24 * 60 * 60 * 1000;

    startDate;
    endDate;

    subscription = new Subscription();
    activeFilters: '--';
    serviceItems;
    endpointItems;
    hostingEndpointItems;
    newStartTime;
    newEndTime;
    minimumDate;
    productVersion;
    label = 'Edge Routers';
    labelEndpoint = this.featureService?.disableNewNavigation ? 'Endpoints' : 'Identities';

    attributes = [];
    selectedAttributes = [];
    rangeDates: Date[];
    filterType: string;
    filterValue: string;
    endpointTableFilterValue: string;
    serviceTableFilterValue: string;
    endpointTableLocked = false;
    serviceTableLocked = false;
    hostingEndpointData = false;
    private relatedSubscription = new Subscription();

    constructor(
        public filterService: TableFilterService,
        public authorizationService: AuthorizationService,
        public apiService: ApiService,
        public featureService: FeatureService,
        public appwanServiceV2: AppWanServiceV2,
        private fromDate: FromDatePipe,
        public elasticsearch: ElasticsearchService,
        public csvDownloadService: CsvDownloadService,
        private attributesService: AttributesService,
        @Inject(TEMPLATE_SEARCH_SERVICE) public templateService: TemplateSearchService,
        @Inject(EVENT_FIELDS) public eventFields: EventFields,
    ) {
        this.minimumDate = new Date();
        this.minimumDate.setDate(this.minimumDate.getDate() - 30);
    }

    ngOnInit() {
        this.apiService.currentNetwork.pipe(debounceTime(1000)).subscribe((network) => {
            this.currentNetwork = network as unknown as NetworkV2;
            this.getEndpointAttributes();
            this.getProductVersion();
        });
        this.filterService.getDateFilter();

        this.close();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setStartTime(value) {
        this.startTime = this.fromDate.transform(value);
        this.endTime = Date.now();

        // if the filter is 1m, 6m, or 12m, we should display from the start of one month to the end of another
        if (value === '1m' || value === '6m' || value === '12m') {
            // the start time should be set to the start of the first month by the fromDate pipe
            //  setting teh end time to the end of the current month manually
            this.endTime = moment(this.endTime).endOf('month').valueOf();
        }
        this.dateFilter = value;
    }

    updateSelectedList() {}

    getProductVersion() {
        this.productVersion = this.currentNetwork.productVersion;
        //if at 7.3.79 or higher add hosting endpoint metrics
        if (semver.gte(this.productVersion, '7.3.79')) {
            this.hostingEndpointData = true;
            this.label = this.featureService?.disableNewNavigation ? 'Hosting Endpoints' : 'Hosting Identities';
            this.labelEndpoint = this.featureService?.disableNewNavigation ? 'Dialing Endpoints' : 'Dialing Identities';
        }
    }

    updateStart(value) {
        this.dateFilter = value;
        this.setStartTime(this.dateFilter);
        this.setEndTime();
        this.setDateFilterEvent.emit();
        this.rangeDates = null;
        this.close();
    }

    public setEndTime() {
        this.endTime = Date.now();
    }

    close() {
        if (!_.isEmpty(this.rangeDates) && !_.isNil(this.rangeDates[0]) && !_.isNil(this.rangeDates[1])) {
            this.filterService.setDateRange(moment.utc(this.rangeDates[0]), moment.utc(this.rangeDates[1]));
        }
    }

    setFilter(filterType, filterValue) {
        // the tables control the filters, they shouldn't filter on themselves
        if (filterType === this.eventFields.identityName && filterValue !== null) {
            // handle lock
            this.serviceTableFilterValue = filterValue;
            this.endpointTableFilterValue = null;
        }
        if (filterType === this.eventFields.serviceName && filterValue !== null) {
            // handle lock
            this.endpointTableFilterValue = filterValue;
            this.serviceTableFilterValue = null;
        }

        if (filterValue === null) {
            this.serviceTableFilterValue = null;
            this.endpointTableFilterValue = null;
        }

        this.filterType = filterType;
        this.filterValue = filterValue;
    }

    receivedServiceItems(items) {
        this.serviceItems = items;
    }

    receivedEndpointItems(items) {
        this.endpointItems = items;
    }

    receivedHostingEndpointItems(items) {
        this.hostingEndpointItems = items;
    }

    // setDateRange(startTime, endTime) {
    //     this.startTime = startTime;
    //     this.endTime = endTime;
    //     this.setDateFilterEvent.emit(true);
    // }

    downloadServiceCsv() {
        this.downloadCsv('serviceUsageTotals', this.serviceItems);
    }

    downloadEndpointCsv() {
        if (this.dialingEndpoint.isActive) {
            this.downloadCsv('endpointUsageTotals', this.endpointItems);
        }
        if (this.hostingEndpoint.isActive) {
            this.downloadCsv('hostingEndpointUsageTotals', this.hostingEndpointItems);
        }
    }

    onTabChange($event) {
        if (this.hostingEndpoint.isActive) {
            this.receivedHostingEndpointItems(this.hostingEndpointItems);
        }
        if (this.dialingEndpoint.isActive) {
            this.receivedEndpointItems(this.endpointItems);
        }
    }

    getNewStartTime() {
        this.setNewStartTime.emit(this.newStartTime);
    }

    getNewEndTime() {
        this.setNewEndTime.emit(this.newEndTime);
    }

    downloadCsv(filename, items) {
        const csvItems = [];
        for (const item of items) {
            csvItems.push(item);
        }
        const columns = ['name', 'usage'];
        this.csvDownloadService.download(filename, csvItems, columns);
    }

    async getEndpointAttributes() {
        this.attributes = await this.templateService.getAttributes(this.currentNetwork.id, 'endpoint')
    }

    getOptions(currentNetwork, embedAll = false) {
        if (!currentNetwork) {
            return {};
        }
        const params = { networkId: currentNetwork.id };
        if (embedAll) {
            params['embed'] = 'all';
        }
        return { params };
    }

    getAttributeOptions(type) {
        const params = [
            { key: 'networkId', value: this.currentNetwork.id },
            { key: 'attributeType', value: type },
            { key: 'parentType', value: type },
        ];
        return { params: params };
    }
}
