import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { SharedApiv2Module } from '@netfoundry-ui/shared/apiv2';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { FeatureFormSupportButtonModule } from '@netfoundry-ui/feature/form-support-button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { AutoFabricComponent } from './auto-fabric.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModelModule,
        SharedApiv2Module,
        MatDialogModule,
        HttpClientModule,
        FeatureFormSupportButtonModule,
        MatTooltipModule,
    ],
    declarations: [AutoFabricComponent],
    exports: [AutoFabricComponent],
})
export class FeatureAutoFabricModule {}
