import { Pipe, PipeTransform } from '@angular/core';

const onlineStatusLookup = {
    '100': 'Registering',
    '200': 'Offline',
    '300': 'Online',
};

@Pipe({
    name: 'onlineStatus',
})
export class OnlineStatusPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return onlineStatusLookup[value] ? onlineStatusLookup[value] : onlineStatusLookup['100'];
    }
}
