import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppWan, Endpoint, Service } from '@netfoundry-ui/shared/model';
import { LoggerService, ValidateService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-app-wan-s3-wizard',
    templateUrl: './app-wan-s3-wizard.component.html',
    styleUrls: ['./app-wan-s3-wizard.component.scss'],
})
export class AppWanS3WizardComponent {
    // child Feature of the wizard
    @ViewChild('gatewaySelect') gatewaySelect;
    @ViewChild('endpointSelect') endpointSelect;
    @ViewChild('serviceSelect') serviceSelect;
    @ViewChild('saveComponent') saveComponent;

    step = 1;

    // the highest step the user has been to. used for navigating via the step bubbles
    highestStep = 1;

    skip = false;
    form = {
        appWanName: {
            error: false,
            placeholder: 'Enter an AppWAN Name',
            label: 'AppWAN Name',
            value: '',
        },
    };
    model: AppWan = new AppWan({});
    finalModel: AppWan = new AppWan({});

    services: Service[] = [];
    ipNetworkServices: Service[] = [];
    ipHostServices: Service[] = [];
    clients: Endpoint[] = [];

    sourceGatewayType;
    targetGatewayType;
    endpointType;

    sourceGateway = new Endpoint({});

    endpoint;
    filteredRanges = [];

    hasStepped = false;

    regionId;
    regions;

    constructor(
        private logger: LoggerService,
        private dialogRef: MatDialogRef<AppWanS3WizardComponent>,
        public validateService: ValidateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    // function for moving forward
    async next() {
        this.hasStepped = true;
        this.logger.info('step is', this.step);
        switch (this.step) {
            case 1:
                if (this.validateAppwanName()) {
                    this.model.name = this.form.appWanName.value;
                    this.step++;
                    this.highestStep++;
                }
                break;

            case 2:
                if (this.gatewaySelect.validateSourceGateway()) {
                    this.sourceGateway = this.gatewaySelect.getSourceGateway();
                    this.logger.info('targetgateway', this.sourceGateway);
                    this.regionId = this.gatewaySelect.form.region.value;
                    this.regions = this.gatewaySelect.regions;
                    this.step++;
                    this.highestStep++;
                }
                break;

            case 3:
                this.services = [];
                for (const ip of this.serviceSelect.filteredRanges) {
                    const newService = new Service({
                        bridgeStp: 'YES',
                        collectionLocation: 'BOTH',
                        endpointId: this.sourceGateway.id,
                        cryptoLevel: 'STRONG',
                        dnsOptions: 'NONE',
                        icmpTunnel: 'NO',
                        localNetworkGateway: 'YES',
                        multicast: 'OFF',
                        pbrType: 'WAN',
                        permanentConnection: 'NO',
                        rateSmoothing: 'YES',
                        serviceInterceptType: 'IP',
                        serviceClass: 'GW',
                        serviceType: 'ALL',
                        gatewayIp: ip.ip_prefix,
                        interceptIp: ip.ip_prefix.slice(0, ip.ip_prefix.indexOf('/')),
                    });

                    this.services.push(newService);
                }
                if (this.validateServices()) {
                    this.logger.info('SERVICES', this.services);
                    this.step++;
                    this.create();
                }
                break;

            default:
                this.step++;
        }
        this.logger.info('step is', this.step);
    }

    back() {
        this.step--;
    }

    // function for moving to a specific step
    goTo(stepNumber) {
        // if the user has already been at the step selected, go to the step
        if (stepNumber <= this.highestStep) {
            this.step = stepNumber;
        }
    }

    isNotSummary() {
        return this.step < 4;
    }

    isPassedStep(index) {
        return this.step > index;
    }

    isNotAtStep(index) {
        return this.step < index;
    }

    close() {
        this.dialogRef.close();
    }

    isBetweenSteps(start, end) {
        return this.step > start && this.step < end;
    }

    async create() {
        this.finalModel = await this.saveComponent.save();
        if (this.finalModel != null) {
            this.sourceGateway = this.finalModel['hostingGateway'];
            this.step++;
        }
    }

    clone() {
        // makging the previous appwan name the placeholder
        this.form.appWanName.placeholder = this.form.appWanName.value;

        // resetting the appwan name
        this.form.appWanName.value = '';

        // creating a new appwan model
        this.model = new AppWan({});

        // clearing the list of services
        this.services = [];

        // clearing the gateway list map
        this.gatewaySelect.clearGatewayListMap();

        // preping the service select component for cloning
        this.serviceSelect.clone();

        // resetting the save component
        this.saveComponent.reset();

        // going back to step 2
        this.step = 2;
    }

    validateAppwanName() {
        const isValid =
            this.validateService.hasValue(this.form.appWanName.value) &&
            this.validateService.isValidName(this.form.appWanName.value);
        this.form.appWanName.error = !isValid;
        return isValid;
    }

    // these are all generated and doesn't have any user input
    validateServices() {
        return true;
    }
}
