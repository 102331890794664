import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedApiv2Module } from '@netfoundry-ui/shared/apiv2';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { FeatureTagsSelectorModule } from '@netfoundry-ui/feature/tags-selector';
import { MatRadioModule } from '@angular/material/radio';
import { FeatureDateFiltersModule } from '@netfoundry-ui/feature/date-filters';
import { FeatureZitiUtilizationBasicModule } from '@netfoundry-ui/feature/ziti-utilization-basic';
import { FeatureFormSupportButtonModule } from '@netfoundry-ui/feature/form-support-button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { ChipsModule } from 'primeng/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FeaturePreviewListModule } from '@netfoundry-ui/feature/preview-list';
import { AdvancedPlatformServiceFormComponent } from './advanced-platform-service-form.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModelModule,
        FormsModule,
        MatTooltipModule,
        MatDialogModule,
        SharedApiv2Module,
        UiPipesModule,
        FeatureTagsSelectorModule,
        MatRadioModule,
        FeatureDateFiltersModule,
        FeatureZitiUtilizationBasicModule,
        FeatureFormSupportButtonModule,
        ChipsModule,
        MatCheckboxModule,
        FeaturePreviewListModule,
    ],
    declarations: [AdvancedPlatformServiceFormComponent],
    exports: [AdvancedPlatformServiceFormComponent],
})
export class FeatureFormAdvancedPlatformServiceFormModule {}
