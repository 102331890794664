import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiNoitemsModule } from '@netfoundry-ui/ui/noitems';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { EventTimelineDashComponent } from './event-timeline-dash.component';

@NgModule({
    imports: [CommonModule, UiNoitemsModule, UiLoaderModule],
    declarations: [EventTimelineDashComponent],
    exports: [EventTimelineDashComponent],
})
export class FeatureEventTimelineDashModule {}
