import { ElasticsearchQuery } from './elasticsearch-query';

export class EventsTimelineQuery extends ElasticsearchQuery {
    protected model = {
        query: {
            bool: {
                must: [
                    {
                        query_string: {
                            query: '*',
                            analyze_wildcard: true,
                        },
                    },
                    {
                        match_phrase: {
                            'tags.keyword': {
                                query: 'customer',
                            },
                        },
                    },
                ],
                must_not: [
                    {
                        match_phrase: {
                            changeType: {
                                query: 'soft',
                            },
                        },
                    },
                ],
            },
        },
        size: 500,
        sort: [
            {
                '@timestamp': {
                    order: 'desc',
                    unmapped_type: 'boolean',
                },
            },
        ],
        _source: {
            excludes: [],
        },
    };

    public getIndexPattern() {
        return 'ncentityevent';
    }

    public getAggregateName() {
        return null;
    }
}
