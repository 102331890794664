import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-tutorials',
    templateUrl: './tutorials.component.html',
    styleUrls: ['./tutorials.component.scss'],
})
export class TutorialsComponent {
    @Input() steps = [];
    @Input() disabled = false;

    @Output() openStep = new EventEmitter();

    constructor() {}

    open(step) {
        this.openStep.emit(step);
    }
}
