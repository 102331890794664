import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('browzer-bootstrappers')
export class BrowzerBootstrapper extends Resource {
    networkId: string;
    provider: string;
    region: string;
    id: string;
    selected = false;
    name: string;
    status: string;
    
}