import { Component, HostListener, Inject, OnInit, ɵComponentType } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    AppWanServiceV2,
    AttributesService,
    AttributesServiceResults,
    EdgeRouterPolicyServiceV2,
    EdgeRouterServiceV2,
    EndpointServiceV2,
    PlatformServiceService,
    ServiceServiceV2,
} from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { ZitiOptionsService } from '@netfoundry-ui/shared/helpers';
import {
    ENDPOINT_DIALOG,
    NetworkV2,
    PagedAttributes,
    PlatformService,
    ROUTER_DIALOG,
} from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, LoggerService, ValidateService } from '@netfoundry-ui/shared/services';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import _ from 'lodash';
import { MetricsModalComponent } from '@netfoundry-ui/feature/metrics-modal';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DialLogsComponent } from '@netfoundry-ui/feature/dial-logs';
import { PagedGetOption } from '@lagoshny/ngx-hateoas-client';

@Component({
    selector: 'app-platform-service-form',
    templateUrl: './platform-service-form.component.html',
    styleUrls: ['./platform-service-form.component.scss'],
    providers: [FromDatePipe],
})
export class PlatformServiceFormComponent implements OnInit {
    public model = new PlatformService();
    invalidWords = ['#all'];
    regions: Location[] = [];
    isComplete = false;
    isLoading = false;
    editId = '';
    processing = false;
    hideHelp = false;
    isInline = false;
    isTerminator = true;
    isClientOpen = true;
    isServerOpen = true;
    isRouterOpen = true;
    isLoadingSvcAttr = false;
    isLoadingEdgeRouterAttr = false;
    networkList = [];
    edgeRoutersList = [];
    edgeRouterHosts = [];
    serviceAttributes = new PagedAttributes();
    edgeRouterAttributes = new PagedAttributes();
    endpointAttributes = new PagedAttributes();
    selectedServiceAttributes = new PagedAttributes();
    selectedEdgeRouterAttributes = new PagedAttributes();
    selectedEndpointAttributes = new PagedAttributes();
    currentNetwork: NetworkV2;
    errors = {};
    selectedEndpoints = [];
    isEditing = false;
    canEdit = false;
    isNew = false;
    networkGroupId;
    endpointAttributeError = false;
    bulkEdit = false;
    assocEndpointNames = [];
    endpointMap = {};
    assocRouterNames = [];
    routerMap = {};
    edgeRouterError: boolean;
    svcError: boolean;
    isLoadingEndpointAttr: boolean;
    isLoadingAssocEndpoints: boolean;
    isLoadingAssocRouters: boolean;
    erAttributeError = false;
    bindEndpointAttributeError = false;
    attributeError = false;
    clientHostError = false;
    clientPortError = false;
    egressPortError = false;
    egressHostError = false;
    erIdError;
    erHostError;
    erPortError;
    egressProtocolError = false;

    constructor(
        private logger: LoggerService,
        private dialogRef: MatDialogRef<PlatformServiceFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private serviceService: ServiceServiceV2,
        private edgeRouterPolicyServiceV2: EdgeRouterPolicyServiceV2,
        public appwanServiceV2: AppWanServiceV2,
        private validateService: ValidateService,
        private growlerService: GrowlerService,
        private authorizationService: AuthorizationService,
        private fromDate: FromDatePipe,
        private optionsService: ZitiOptionsService,
        private platformServiceService: PlatformServiceService,
        public featureService: FeatureService,
        private apiService: ApiService,
        public dialogForm: MatDialog,
        private router: Router,
        private attributeService: AttributesService,
        @Inject(ENDPOINT_DIALOG) private endpointDialog: ɵComponentType<any>,
        @Inject(ROUTER_DIALOG) private routerDialog: ɵComponentType<any>,
        private edgeRouterService: EdgeRouterServiceV2,
        private endpointService: EndpointServiceV2
    ) {}

    ngOnInit() {
        this.initializeModel();
        this.currentNetwork = this.apiService.currentNetwork.getValue();
        if (this.currentNetwork) {
            this.model.networkId = this.currentNetwork.id;
            this.networkGroupId = this.currentNetwork.networkGroupId;
            this.isLoading = true;
            this.initializeEndpointSelector();
            this.initializeEdgeRouterSelector();
            this.initializeServiceAttributeSelector();
            if (this.model.id) {
                this.findAssociatedEntities();
            }
        }
        this.initializeTutorial();
        if (this.bulkEdit) {
            this.validate();
        }
    }

    addEndpointsAttributes(newAttribute) {
        const success = this.attributeService.addAttributes(newAttribute, [], this.selectedEndpointAttributes);
        this.model.endpointAttributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        this.validate();
        if (success) this.selectedEndpointAttributes = success as PagedAttributes;
        else this.endpointAttributeError = true;
    }

    addEdgeRouterAttribute(newAttribute) {
        const success = this.attributeService.addAttributes(newAttribute, [], this.selectedEdgeRouterAttributes);
        this.model.edgeRouterAttributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        this.validate();
        if (success) this.selectedEdgeRouterAttributes = success as PagedAttributes;
        else this.edgeRouterError = true;
    }

    addServiceAttribute(newAttribute) {
        const success = this.attributeService.addAttributes(
            newAttribute,
            this.invalidWords,
            this.selectedServiceAttributes
        );
        this.model.attributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        this.validate();

        if (success) this.selectedServiceAttributes = success as PagedAttributes;
        else {
            this.svcError = true;
            this.errors['attributes'] = `attribute name not allowed: ${newAttribute}`;
        }
    }

    removeServiceAttribute(oldAttribute) {
        this.selectedServiceAttributes = this.attributeService.removeAttribute(
            oldAttribute,
            this.selectedServiceAttributes
        );
        this.model.attributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        this.validate();
    }

    removeEndpointAttribute(oldAttribute) {
        this.selectedEndpointAttributes = this.attributeService.removeAttribute(
            oldAttribute,
            this.selectedEndpointAttributes
        );
        this.model.endpointAttributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        this.validate();
    }

    removeEdgeRouterAttribute(oldAttribute) {
        this.selectedEdgeRouterAttributes = this.attributeService.removeAttribute(
            oldAttribute,
            this.selectedEdgeRouterAttributes
        );
        this.model.edgeRouterAttributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        this.validate();
    }

    toggleClient() {
        this.isClientOpen = !this.isClientOpen;
    }

    toggleServer() {
        this.isServerOpen = !this.isServerOpen;
    }

    toggleRouter() {
        this.isRouterOpen = !this.isRouterOpen;
    }

    sdkTunneler() {
        if (this.model.modelType === 'TunnelerToSdk') {
            this.model.modelType = 'TunnelerToEndpoint';
        } else {
            this.model.modelType = 'TunnelerToSdk';
        }
        this.typeSelection();
    }

    hide() {
        this.dialogRef.close({ newClient: this.model });
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
        this.hide();
    }

    get disableEncryptionToggle() {
        return (
            this.isEditing ||
            (this.model.modelType === 'TunnelerToEndpoint' &&
                !this.featureService.canDisabledServiceEncryption(this.currentNetwork))
        );
    }

    toggleEncryption() {
        if (
            this.isEditing ||
            (this.model.modelType === 'TunnelerToEndpoint' &&
                !this.featureService.canDisabledServiceEncryption(this.currentNetwork))
        ) {
            return;
        }
        if (this.model.modelType === 'TunnelerToEdgeRouter') {
            for (const edgeRouterHost of this.model.model.edgeRouterHosts) {
                if (edgeRouterHost.serverEgress.protocol === 'udp') {
                    this.model.encryptionRequired = false;
                    return;
                }
            }
        }

        this.model.encryptionRequired = !this.model.encryptionRequired;
    }

    async save(keyUp = false) {
        if (keyUp && this.apiService.tutorialActive) {
            return;
        }

        if (await this.validate()) {
            this.processing = true;

            if (this.bulkEdit) {
                this.dialogRef.close();
            } else if (this.model.id) {
                _.unset(this.model, 'configs');
                this.model.configIdByConfigTypeId = null;
                this.model.updatedAt = null;
                this.model.createdAt = null;
                this.model.createdBy = null;
                this.model.ownerIdentityId = null;
                this.platformServiceService.patchResource(this.model).subscribe(
                    (data) => {
                        this.logger.info('Update service response: ', data);
                        this.processing = false;
                        this.dialogRef.close({ updatedService: this.model });
                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Service update successful',
                                'Service update process started successfully.<a href="' +
                                    this.router.createUrlTree(['/process-executions']).toString() +
                                    '">Click here to find out more</a>'
                            )
                        );
                    },
                    (httpErrorResponse) => {
                        this.processing = false;
                        this.logger.error('Error from service update', httpErrorResponse);
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Service update request failed. ',
                                httpErrorResponse.error.errors[0]
                            )
                        );
                    }
                );
            } else {
                this.platformServiceService
                    .createResource({ body: this.model })
                    .pipe(take(1))
                    .subscribe(
                        (data) => {
                            this.logger.info('Create service response: ', data);
                            this.growlerService.show(
                                new GrowlerData(
                                    'success',
                                    'Success',
                                    'Creation Complete',
                                    'Service creation process started successfully.<a href="' +
                                        this.router.createUrlTree(['/process-executions']).toString() +
                                        '">Click here to find out more</a>'
                                )
                            );
                            this.apiService.setLatestService(data);
                            this.processing = false;
                            this.dialogRef.close(data);
                        },
                        (httpErrorResponse) => {
                            this.processing = false;
                            this.logger.error('Error from service creation', httpErrorResponse);
                            this.growlerService.show(
                                new GrowlerData(
                                    'error',
                                    'Service creation request failed. ',
                                    httpErrorResponse.error.errors[0]
                                )
                            );
                        }
                    );
            }
        }
    }

    async validate() {
        this.errors = {};
        this.attributeError = false;
        this.erAttributeError = false;
        this.endpointAttributeError = false;
        this.clientHostError = false;
        this.clientPortError = false;
        this.egressPortError = false;
        this.egressProtocolError = false;
        this.egressHostError = false;
        this.model.attributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        this.model.model.bindEndpointAttributes = [];
        for (const attribute of this.selectedEndpointAttributes.mappedAtrributes.keys()) {
            this.model.model.bindEndpointAttributes.push(attribute);
        }
        this.model.model.edgeRouterAttributes = [];
        for (const attribute of this.selectedEdgeRouterAttributes.mappedAtrributes.keys()) {
            this.model.model.edgeRouterAttributes.push(attribute);
        }

        if (!this.model.id) {
            const errors = await this.platformServiceService.validateCreate(this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.attributes')) {
                this.attributeError = true;
                this.errors['attributes'] = _.find(errors, (e) => e.path === '$.attributes').message;
            }
            if (_.find(errors, (e) => e.path.includes('edgeRouterAttributes'))) {
                this.erAttributeError = true;
                this.errors['edgeRouterAttributes'] = _.find(errors, (e) =>
                    e.path.includes('edgeRouterAttributes')
                ).message;
            }
            if (_.find(errors, (e) => e.path.includes('bindEndpointAttributes'))) {
                this.endpointAttributeError = true;
                this.errors['bindEndpointAttributes'] = _.find(errors, (e) =>
                    e.path.includes('bindEndpointAttributes')
                ).message;
            }
            if (_.find(errors, (e) => e.path === '$.model.clientIngress.host')) {
                this.clientHostError = true;
                this.errors['clientHostError'] = _.find(errors, (e) => e.path === '$.model.clientIngress.host').message;
            }
            if (_.find(errors, (e) => e.path === '$.model.clientIngress.port')) {
                this.clientPortError = true;
                this.errors['clientPortError'] = _.find(errors, (e) => e.path === '$.model.clientIngress.port').message;
            }
            if (this.model.modelType === 'TunnelerToEndpoint') {
                if (_.find(errors, (e) => e.path === '$.model.serverEgress.host')) {
                    this.egressHostError = true;
                    this.errors['egressHostError'] = _.find(
                        errors,
                        (e) => e.path === '$.model.serverEgress.host'
                    ).message;
                }
                if (_.find(errors, (e) => e.path === '$.model.serverEgress.port')) {
                    this.egressPortError = true;
                    this.errors['egressPortError'] = _.find(
                        errors,
                        (e) => e.path === '$.model.serverEgress.port'
                    ).message;
                }
                if (_.find(errors, (e) => e.path === '$.model.serverEgress.protocol')) {
                    this.egressProtocolError = true;
                    this.errors['egressProtocolError'] = _.find(
                        errors,
                        (e) => e.path === '$.model.serverEgress.protocol'
                    ).message;
                }
            }
            if (this.model.modelType === 'TunnelerToEdgeRouter') {
                for (let i = 0; i < this.model.model.edgeRouterHosts.length; i++) {
                    if (_.find(errors, (e) => e.path === '$.model.edgeRouterHosts[' + i + '].edgeRouterId')) {
                        this.erIdError = i;
                        this.errors['erIdError'] = _.find(
                            errors,
                            (e) => e.path === '$.model.edgeRouterHosts[' + i + '].edgeRouterId'
                        ).message;
                    }
                    if (_.find(errors, (e) => e.path === '$.model.edgeRouterHosts[' + i + '].serverEgress.host')) {
                        this.erHostError = i;
                        this.errors['erHostError'] = _.find(
                            errors,
                            (e) => e.path === '$.model.edgeRouterHosts[' + i + '].serverEgress.host'
                        ).message;
                    }
                    if (_.find(errors, (e) => e.path === '$.model.edgeRouterHosts[' + i + '].serverEgress.port')) {
                        this.erPortError = i;
                        this.errors['erPortError'] = _.find(
                            errors,
                            (e) => e.path === '$.model.edgeRouterHosts[' + i + '].serverEgress.port'
                        ).message;
                    }
                }
            }
            return errors.length === 0;
        } else {
            _.unset(this.model, 'configs');
            this.model.configIdByConfigTypeId = null;
            this.model.updatedAt = null;
            this.model.createdAt = null;
            this.model.createdBy = null;
            this.model.ownerIdentityId = null;
            const errors = await this.platformServiceService.validateUpdate(this.model.id, this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.attributes')) {
                this.attributeError = true;
                this.errors['attributes'] = _.find(errors, (e) => e.path === '$.attributes').message;
            }
            if (_.find(errors, (e) => e.path.includes('edgeRouterAttributes'))) {
                this.erAttributeError = true;
                this.errors['edgeRouterAttributes'] = _.find(errors, (e) =>
                    e.path.includes('edgeRouterAttributes')
                ).message;
            }
            if (_.find(errors, (e) => e.path === '$.model.bindEndpointAttributes')) {
                this.endpointAttributeError = true;
                this.errors['bindEndpointAttributes'] = _.find(
                    errors,
                    (e) => e.path === '$.model.bindEndpointAttributes'
                ).message;
            }
            if (_.find(errors, (e) => e.path === '$.model.clientIngress.host')) {
                this.clientHostError = true;
                this.errors['clientHostError'] = _.find(errors, (e) => e.path === '$.model.clientIngress.host').message;
            }
            if (_.find(errors, (e) => e.path === '$.model.clientIngress.port')) {
                this.clientPortError = true;
                this.errors['clientPortError'] = _.find(errors, (e) => e.path === '$.model.clientIngress.port').message;
            }
            if (this.model.modelType === 'TunnelerToEndpoint') {
                if (_.find(errors, (e) => e.path === '$.model.serverEgress.host')) {
                    this.egressHostError = true;
                    this.errors['egressHostError'] = _.find(
                        errors,
                        (e) => e.path === '$.model.serverEgress.host'
                    ).message;
                }
                if (_.find(errors, (e) => e.path === '$.model.serverEgress.port')) {
                    this.egressPortError = true;
                    this.errors['egressPortError'] = _.find(
                        errors,
                        (e) => e.path === '$.model.serverEgress.port'
                    ).message;
                }
                if (_.find(errors, (e) => e.path === '$.model.serverEgress.protocol')) {
                    this.egressProtocolError = true;
                    this.errors['egressProtocolError'] = _.find(
                        errors,
                        (e) => e.path === '$.model.serverEgress.protocol'
                    ).message;
                }
            }

            if (this.model.modelType === 'TunnelerToEdgeRouter') {
                for (let i = 0; i < this.model.model.edgeRouterHosts.length; i++) {
                    if (_.find(errors, (e) => e.path === '$.model.edgeRouterHosts[' + i + '].edgeRouterId')) {
                        this.erIdError = i;
                        this.errors['erIdError'] = _.find(
                            errors,
                            (e) => e.path === '$.model.edgeRouterHosts[' + i + '].edgeRouterId'
                        ).message;
                    }
                    if (_.find(errors, (e) => e.path === '$.model.edgeRouterHosts[' + i + '].serverEgress.host')) {
                        this.erHostError = i;
                        this.errors['erHostError'] = _.find(
                            errors,
                            (e) => e.path === '$.model.edgeRouterHosts[' + i + '].serverEgress.host'
                        ).message;
                    }
                    if (_.find(errors, (e) => e.path === '$.model.edgeRouterHosts[' + i + '].serverEgress.port')) {
                        this.erPortError = i;
                        this.errors['erPortError'] = _.find(
                            errors,
                            (e) => e.path === '$.model.edgeRouterHosts[' + i + '].serverEgress.port'
                        ).message;
                    }
                }
            }
            return errors.length === 0;
        }
    }

    getMetrics() {
        this.dialogForm.open(MetricsModalComponent, {
            data: {
                resourceType: 'service',
                model: this.model,
                networkGroupId: this.networkGroupId,
                networkId: this.currentNetwork.id,
            },
            height: '800px',
            width: '1200px',
            autoFocus: false,
        });
    }

    getDialLogs() {
        this.dialogForm.open(DialLogsComponent, {
            data: {
                resourceType: 'service',
                model: this.model,
                networkGroupId: this.networkGroupId,
                networkId: this.currentNetwork.id,
            },
            height: '800px',
            width: '1000px',
            autoFocus: false,
        });
    }

    protocolSelection($event) {
        if ($event.currentTarget.value === 'udp' && this.model.modelType === 'TunnelerToEdgeRouter') {
            this.model.encryptionRequired = false;
            return;
        }

        if (this.model.model.edgeRouterHosts && this.model.model.edgeRouterHosts.length > 0) {
            for (const edgeRouterHost of this.model.model.edgeRouterHosts) {
                if (this.model.modelType === 'TunnelerToEdgeRouter') {
                    if (
                        edgeRouterHost.serverEgress.protocol === 'udp' &&
                        this.model.modelType === 'TunnelerToEdgeRouter'
                    ) {
                        return;
                    }
                }
            }
        }
        this.validate();
        this.model.encryptionRequired = true;
    }

    typeSelection() {
        if (this.model.modelType === 'TunnelerToEdgeRouter') {
            this.model.model = {
                edgeRouterHosts: [
                    {
                        edgeRouterId: '',
                        serverEgress: {},
                    },
                ],
                clientIngress: this.model.model.clientIngress,
            };
        } else {
            this.model.model = {
                serverEgress: {},
                clientIngress: this.model.model.clientIngress,
                edgeRouterAttributes: [],
            };
        }
    }

    addHAEdgeRouter() {
        if (this.model.model.edgeRouterHosts.length < 4) {
            const lastEdgeRouterHost = this.model.model.edgeRouterHosts[this.model.model.edgeRouterHosts.length - 1];
            this.model.model.edgeRouterHosts.push({
                edgeRouterId: '',
                serverEgress: {
                    protocol: lastEdgeRouterHost.serverEgress.protocol,
                    host: lastEdgeRouterHost.serverEgress.host,
                    port: lastEdgeRouterHost.serverEgress.port,
                },
            });
        }
    }

    deleteHAEdgeRouter(index) {
        if (this.model.model.edgeRouterHosts.length > 1) {
            this.model.model.edgeRouterHosts.splice(index, 1);
        }
    }

    endpointSelected(name: string) {
        const service = this.endpointMap[name];
        this.dialogForm.open(this.endpointDialog, {
            data: { model: service, inline: true },
            minHeight: '93%',
            minWidth: '100%',
            height: '93%',
            width: '100%',
        });
    }

    routerSelected(name: string) {
        const router = this.routerMap[name];
        this.dialogForm.open(this.routerDialog, {
            data: { model: router, inline: true },
            minHeight: '93%',
            minWidth: '100%',
            height: '93%',
            width: '100%',
        });
    }

    // filterServiceAttributes(value) {
    //   if (value && value.length > 2) {
    //     this.isLoadingSvcAttr = true;
    //     this.attributeService
    //       .findDistinctAttributes(this.currentNetwork.id, 'service', null, {
    //         params: {
    //           name: '%' + value + '%',
    //         },
    //         pageParams: {
    //           page: 0,
    //           size: 2000,
    //         },
    //       })
    //       .then((newServiceAttributes) => {
    //         this.serviceAttributeSet = new Set(newServiceAttributes.map((a) => a.name));
    //         this.serviceGroupAttributes.simpleAttributes = [...new Set([...this.appWanServiceAttributes, ...this.serviceAttributeSet])];
    //       })
    //       .finally(() => {
    //         this.isLoadingSvcAttr = false;
    //       });
    //   }
    // }

    findAssociatedEntities() {
        this.isLoadingAssocRouters = true;

        this.serviceService
            .findAssociatedRouters(this.model.id)
            .then((recs) => {
                this.assocRouterNames = recs.map((s) => s.name);
                recs.forEach((rec) => {
                    this.routerMap[rec.name] = rec;
                });
            })
            .finally(() => {
                this.isLoadingAssocRouters = false;
            });
        this.isLoadingAssocEndpoints = false;
        this.serviceService
            .findAssociatedEndpoints(this.model.id)
            .then((recs) => {
                this.assocEndpointNames = recs.map((s) => s.name);
                recs.forEach((rec) => {
                    this.endpointMap[rec.name] = rec;
                });
            })
            .finally(() => {
                this.isLoadingAssocEndpoints = false;
            });
    }

    private initializeServiceAttributeSelector() {
        this.isLoadingSvcAttr = true;
        this.svcError = false;
        this.attributeService
            .getServiceAttributes(this.currentNetwork, true)
            .then((results: AttributesServiceResults) => {
                this.serviceAttributes = results.groupAttributes;
            })
            .catch((err) => {
                this.svcError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingSvcAttr = false;
                this.isLoading = this.isLoadingEndpointAttr || this.isLoadingSvcAttr || this.isLoadingEdgeRouterAttr;
            });
    }

    private initializeTutorial() {
        if (this.apiService.tutorialActive) {
            this.model.modelType = 'TunnelerToEdgeRouter';
            _.set(this.model, 'modelType', _.get(this.model, 'modelType', 'TunnelerToEdgeRouter'));
            this.model.model = {
                edgeRouterHosts: [
                    {
                        edgeRouterId: '',
                        serverEgress: {},
                    },
                ],
                clientIngress: {},
            };

            this.apiService.tutorialService.subscribe((svc: PlatformService) => {
                this.model.name = svc.name;
                const attrs = _.cloneDeep(svc.attributes);
                const erHosts = _.cloneDeep(svc.model.edgeRouterHosts);
                if (_.isEmpty(this.model.attributes)) {
                    this.model.attributes = undefined;
                }
                if (_.isEmpty(this.model?.model?.edgeRouterHosts[0]?.edgeRouterId)) {
                    _.set(this.model, 'model.edgeRouterHosts[0].edgeRouterId', '');
                }
                _.delay(() => {
                    this.model.attributes = attrs;
                    _.set(this.model, 'model.clientIngress.host', svc.model?.clientIngress?.host || '');
                    _.set(this.model, 'model.clientIngress.port', svc.model?.clientIngress?.port || '');
                    _.set(this.model, 'model.edgeRouterHosts[0].edgeRouterId', erHosts[0].edgeRouterId);
                    _.set(
                        this.model,
                        'model.edgeRouterHosts[0].serverEgress.protocol',
                        erHosts[0].serverEgress?.protocol
                    );
                    _.set(this.model, 'model.edgeRouterHosts[0].serverEgress.host', erHosts[0].serverEgress?.host);
                    _.set(this.model, 'model.edgeRouterHosts[0].serverEgress.port', erHosts[0].serverEgress?.port);
                    const tmpCollectorMap1 = new PagedAttributes();
                    this.model.attributes.forEach((attribute) => {
                        tmpCollectorMap1.mappedAtrributes.set(attribute, { name: attribute, isGroup: true });
                    });
                    this.selectedServiceAttributes = tmpCollectorMap1;
                    this.initializeServiceAttributeSelector();
                }, 200);
            });
        }
    }

    private initializeEdgeRouterSelector() {
        this.isLoadingEdgeRouterAttr = true;
        this.edgeRouterError = false;
        const options: PagedGetOption = {
            params: {
                networkId: this.currentNetwork.id,
                embed: 'host',
            },
            pageParams: {
                size: 30,
                page: 0,
            },
            sort: { name: 'ASC' },
        };
        this.edgeRouterService.getEdgeRouterPage(options).then((result) => {
            result.forEach((er) => {
                this.edgeRoutersList.push(er);
            });
        });
        this.attributeService
            .getEdgeRouterAttributes(this.currentNetwork, false)
            .then((results: AttributesServiceResults) => {
                const collector = new PagedAttributes();
                const edgeArr = Array.from(results.namedAttributes.mappedAtrributes.values());
                edgeArr.forEach((rec) => {
                    const bindEndpoints = _.get(this.model, 'model.bindEndpoint', []);
                    if (bindEndpoints.indexOf(rec.id) > -1) {
                        this.selectedEndpointAttributes.mappedAtrributes.set(rec.name, rec);
                    }
                    if (rec['tunnelerEnabled'] !== true && rec['status'] !== 'ERROR') {
                        collector.mappedAtrributes.set(rec.name, rec);
                        // this.edgeRoutersList.push(rec);
                    }
                });
                for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                    collector.mappedAtrributes.set(sa.name, sa);
                }
                this.edgeRouterAttributes = collector;
            })
            .catch((err) => {
                this.edgeRouterError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingEdgeRouterAttr = false;
                this.isLoading = this.isLoadingEndpointAttr || this.isLoadingSvcAttr || this.isLoadingEdgeRouterAttr;
            });
    }

    private initializeEndpointSelector() {
        this.isLoadingEndpointAttr = true;
        this.endpointAttributeError = false;
        this.attributeService
            .getEndpointAttributes(this.currentNetwork)
            .then((results: AttributesServiceResults) => {
                const collector = results.namedAttributes;
                for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                    collector.mappedAtrributes.set(sa.name, sa);
                }
                this.endpointAttributes = collector;
            })
            .catch((err) => {
                this.endpointAttributeError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingEndpointAttr = false;
                this.isLoading = this.isLoadingEndpointAttr || this.isLoadingSvcAttr || this.isLoadingEdgeRouterAttr;
            });
    }

    private initializeModel() {
        this.model.encryptionRequired = true;
        this.model.modelType = 'TunnelerToEndpoint';
        this.isInline = this.data.inline;

        if (this.data.model) {
            this.bulkEdit = this.data.bulkEdit;
            this.model = this.data.model;
            if (!this.model.model) {
                if (this.model.modelType === 'TunnelerToEdgeRouter') {
                    this.model.model = {
                        edgeRouterHosts: [
                            {
                                edgeRouterId: '',
                                serverEgress: {},
                            },
                        ],
                        clientIngress: {},
                    };
                } else {
                    this.model.model = {
                        serverEgress: {},
                        clientIngress: {},
                    };
                }
            } else {
                _.set(this.model, 'model.clientIngress', _.get(this.model, 'model.clientIngress', {}));
                _.set(this.model, 'modelType', _.get(this.model, 'modelType', 'TunnelerToSdk'));
                const erHosts = _.get(this.model, 'model.edgeRouterHosts', []);
                _.forEach(erHosts, (host) => {
                    if (host.edgeRouterName) {
                        this.edgeRoutersList.push(host);
                    }
                });
                _.set(
                    this.model,
                    'model.bindEndpointAttributes',
                    _.get(this.model, 'model.bindEndpointAttributes', [])
                );

                this.canEdit = this.authorizationService.canUpdateService(this.model.id);
                this.isEditing = !this.bulkEdit;
            }
        } else {
            this.isNew = true;
        }

        if (!this.model.model) {
            if (this.model.modelType === 'TunnelerToEdgeRouter') {
                this.model.model = {
                    edgeRouterHosts: [
                        {
                            edgeRouterId: '',
                            serverEgress: {},
                        },
                    ],
                    clientIngress: {},
                };
            } else {
                this.model.model = {
                    serverEgress: {},
                    clientIngress: {},
                };
            }
        }

        if (!this.model.model.edgeRouterAttributes) {
            this.model.model.edgeRouterAttributes = [];
        } else {
            const tmpPagedAttributes = new PagedAttributes();
            this.model.model.edgeRouterAttributes.forEach((att) => {
                if (att.charAt(0) === '@') {
                    tmpPagedAttributes.mappedAtrributes.set(att, { name: att, isNamed: true });
                } else {
                    tmpPagedAttributes.mappedAtrributes.set(att, { name: att, isGroup: true });
                }
            });

            this.selectedEdgeRouterAttributes = tmpPagedAttributes;
        }

        if (!this.model.model.bindEndpoints) {
            this.model.model.bindEndpoints = [];
        }

        if (!this.model.attributes) {
            this.model.attributes = [];
        }

        const tmpCollectorMap1 = new PagedAttributes();
        this.model.attributes.forEach((attribute) => {
            tmpCollectorMap1.mappedAtrributes.set(attribute, { name: attribute, isGroup: true });
        });
        this.selectedServiceAttributes = tmpCollectorMap1;

        if (!this.model.name) {
            this.model.name = '';
        }
        if (this.model && this.model.model && (this.model.id || this.bulkEdit)) {
            const endCollector = new PagedAttributes();
            for (const attribute of this.model.model.bindEndpointAttributes) {
                endCollector.mappedAtrributes.set(attribute, {
                    name: attribute,
                    isGroup: attribute.charAt(0) === '#',
                    isNamed: attribute.charAt(0) === '@',
                });
            }

            this.selectedEndpointAttributes = endCollector;
        }
    }
}
