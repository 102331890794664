/**
 * Template class for all MOP resources with built in helper methods
 */
export class ClientSyncConfigurations {
    public id = '';
    public groupIds: string[] = [];
    public nameField = '';
    public syncTime: string = undefined;
    public syncFrequencyMinutes = 0;
    public adminEmail = '';
    public emailEndUsers = true;
    public deleteEndpointAutomatically = false;
    public syncDevices = true;
    public autoSync = false;
    public reissueExpiredEndpointsAutomatically = false;

    // Default nested property that should exist on all resources
    public _links = {
        self: {
            href: null,
        },
    };

    public hash: string;

    constructor(object) {
        const keys = Object.keys(object);

        // TODO - Figure out how to do inflection so only public properties are populated
        for (const key of keys) {
            this[key] = object[key];
        }

        // this.id = this.getId();
        this.hash = btoa(this._links.self.href);

        return this;
    }

    public getSaveObject() {
        return {
            groupIds: this.groupIds,
            nameField: this.nameField,
            syncTime: this.syncTime ? this.syncTime : undefined,
            syncFrequencyMinutes: this.syncFrequencyMinutes,
            adminEmail: this.adminEmail,
            emailEndUsers: this.emailEndUsers,
            deleteEndpointAutomatically: this.deleteEndpointAutomatically,
            syncDevices: this.syncDevices,
            autoSync: this.autoSync,
            reissueExpiredEndpointsAutomatically: this.reissueExpiredEndpointsAutomatically,
        };
    }

    public getId() {
        if (this._links.self.href != null) {
            return this._links.self.href.split('/').pop();
        } else {
            return null;
        }
    }

    public getSelfLink() {
        return this._links.self.href;
    }

    public unhashLink() {
        return atob(this.hash);
    }

    public getLinkedResource(linkName: string) {
        if (this._links[linkName].href !== undefined) {
            return this._links[linkName].href;
        } else {
            return null;
        }
    }

    public getLinkedResourceId(linkName: string) {
        if (this._links[linkName] !== undefined && this._links[linkName]['href'] !== undefined) {
            return this._links[linkName]['href'].split('/').pop();
        } else {
            return null;
        }
    }
}
