<div *ngIf="isLoading" class="no-items-loader-container">
    <app-loader [isLoading]="isLoading"></app-loader>
</div>
<div [hidden]="!isEmpty" class="noarea" id="NoArea" [ngClass]="{'read-only': !hasAdd}">
    <div (click)="iconClick()">
        <div [ngStyle]="background" class="icon"></div>
        <div *ngIf="type !== 'Bootstrapper'" class="title">
            This network currently has no {{ typeName }} to display.<br /><span [hidden]="!hasAdd"
                >Tap on the <span class="addExample"></span> to add one.</span
            >
        </div>
    </div>
    <div *ngIf="type === 'Bootstrapper'">
        <div class="title">
            The BrowZer bootstrapper will be available after finishing the BrowZer setup on your network.
        </div>
    </div>
    <div *ngIf="hiddenResults" class="title hidden-results">
        <span
            >Some results have been filtered. <a (click)="enablePowerUser()">Click here</a> to enable
            <b>Power User</b> mode and display hidden results.</span
        >
    </div>
</div>
