import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';

@NgModule({
    imports: [CommonModule, HttpClientModule, SharedModelModule, UiPipesModule],
})
export class FeatureSharedServicesModule {}
