import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService, FeatureService, URLS } from '@netfoundry-ui/shared/services';
import { Router } from '@angular/router';

import { delay } from 'lodash';

@Component({
    selector: 'app-console-tour',
    templateUrl: './console-tour.component.html',
    styleUrls: ['./console-tour.component.scss'],
})
export class ConsoleTourComponent implements OnInit {
    index = 1;
    total = 10;
    currentNetwork;
    Appwans = URLS.APPWANS;
    preventClickOutsideClose = true;

    constructor(
        private dialogRef: MatDialogRef<ConsoleTourComponent>,
        private networkDialogRef: MatDialogRef<ConsoleTourComponent>,
        private router: Router,
        private apiService: ApiService,
        public featureService: FeatureService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.index = 1;
        this.apiService.currentNetwork.subscribe((nw) => {
            this.currentNetwork = nw;
        });
        delay(() => {
            this.preventClickOutsideClose = false;
        }, 500);
    }

    previous() {
        if (this.index > 1) {
            this.index--;
        }
    }

    next() {
        this.preventClickOutsideClose = true;
        if (this.index < this.total) {
            this.index++;
        } else {
            this.close();
        }
        delay(() => {
            this.preventClickOutsideClose = false;
        }, 500);
    }

    goto(index: any) {
        this.index = index;
    }

    close(clickOutside = false) {
        if (clickOutside && this.preventClickOutsideClose) {
            return;
        }
        if (this.index === 10) {
            this.goToSampleService();
            localStorage.setItem('take-console-tour-complete', 'true');
        }
        this.dialogRef.close();
    }

    notFirst() {
        return this.index > 1;
    }

    notLast() {
        return this.index < this.total;
    }

    open(url: any) {
        window.open(url);
    }

    openNewNetworkDialog() {
        this.dialogRef.close({ openNewNetworkDialog: true });
    }

    openTutorial() {
        this.close();
        // Open Tutorial
    }

    openAppWAN() {
        this.close();
        // Open AppWAN Wizard
    }

    goToSampleService() {
        if (this.currentNetwork?.status !== 'PROVISIONED') {
            this.router.navigate([URLS.GETTING_STARTED]);
            return;
        }
        this.router.navigate([URLS.SAMPLE_SERVICE]);
    }
}
