import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormSupportButtonComponent } from './form-support-button.component';

@NgModule({
    declarations: [FormSupportButtonComponent],
    exports: [FormSupportButtonComponent],
    imports: [CommonModule],
})
export class FeatureFormSupportButtonModule {}
