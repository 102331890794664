import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { JsonViewComponent } from './json-view.component';

@NgModule({
    imports: [CommonModule, MatDialogModule],
    declarations: [JsonViewComponent],
    exports: [JsonViewComponent],
})
export class UiJsonViewModule {}
