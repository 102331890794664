import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

@Component({
    selector: 'app-table-header-process',
    templateUrl: './table-header-process.component.html',
    styleUrls: ['./table-header-process.component.scss'],
})
export class TableHeaderProcessComponent implements IHeaderAngularComp, AfterViewInit {
    item: {
        selected: boolean;
    };
    headerParams: any = {
        api: {},
    };
    headerName;
    enableSorting;
    columnObj;
    columnDef: any = {
        sortDir: undefined,
        colId: undefined,
        pinColumn: undefined,
        sortColumn: undefined,
    };
    filterType = 'TEXTINPUT';
    filterOptions = [];
    columnFilters: any = {};
    filtering = false;
    pinned;
    showBubble = false;

    @ViewChild('menuButton') menuButton: ElementRef;

    constructor() {
        if (!this) {
            return;
        }
        this.item = {
            selected: false,
        };
    }

    agInit(headerParams: any = {}): void {
        if (!headerParams) {
            return;
        }
        this.headerParams = headerParams;
    }

    ngAfterViewInit() {
        if (this.showBubble) {
            // this.updateStatusClass();
        }
    }

    refresh(params: IHeaderParams): boolean {
        return true;
    }

    hide(): void {
        this.headerParams.api.nfHideColumn(this.columnObj, false);
    }
}
