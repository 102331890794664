<app-loader [isLoading]="isLoading" id="azurevirtualwansPage"></app-loader>

<div [hidden]="isLoading" class="area" id="ItemsArea">
    <div [hidden]="items.length > 0 || isLoading" class="noarea" id="NoArea">
        <div [ngStyle]="{ 'background-image': 'url(/assets/svgs/no-data.svg)' }" class="icon"></div>
        <div class="title">This network currently has no Azure Virtual WANs<br /></div>
    </div>
    <div [hidden]="items.length === 0 || isLoading" id="ItemTable">
        <div class="tableGrid tHeadRow">
            <div (click)="sort('name')" [ngClass]="getSortClass('name')" class="tHead sort">Azure Virtual WAN</div>
            <div (click)="sort('azureId')" [ngClass]="getSortClass('azureId')" class="tHead desktop sort">Azure Id</div>
        </div>
        <div id="ItemsList">
            <div
                *ngFor="
                    let item of displayedItems
                        | sortby: sorting:ordering
                        | paginate
                            : {
                                  itemsPerPage: pageSize,
                                  currentPage: page,
                                  totalItems: filterService.getTotalElements()
                              };
                    let index = index;
                    trackBy: trackById
                "
                [attr.data-index]="index"
                class="tableGrid tRow t{{ item.status }}"
                id="Item_{{ item.id }}"
            >
                <div class="tCol clickCol" id="RowSelect1_{{ item.id }}">
                    {{ item.name }}
                </div>
                <div class="tCol clickCol desktop" id="RowSelect2_{{ item.id }}">
                    {{ item.azureId }}
                </div>
            </div>
        </div>
    </div>
</div>
