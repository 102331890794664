import { Component, EventEmitter, Input, Output } from '@angular/core';

declare const gtag;

@Component({
    selector: 'app-header-card',
    templateUrl: './header-card.component.html',
    styleUrls: ['./header-card.component.scss'],
})
export class HeaderCardComponent {
    @Input() cards: any = [];
    @Output() itemSelected = new EventEmitter();

    itemComplete = false;

    constructor() {}

    selected(card): void {
        if (!card.itemSelected || card.disabled) {
            return;
        }
        card.itemSelected();
    }

    openLink(link: any) {
        if (!link.src) {
            return;
        }
        if (gtag)
            gtag('event', 'click', {
                event_category: 'getting-started',
                event_label: link.event,
            });
        window.open(link.src, '_blank');
    }
}
