import { Injectable } from '@angular/core';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { OnlineStatusPipe, StatusPipe } from '@netfoundry-ui/ui/pipes';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { saveAs } from 'file-saver';
import _ from 'lodash';

const defaultOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    useBom: true,
};

@Injectable({
    providedIn: 'root',
})
export class CsvDownloadService {
    constructor(
        private growler: GrowlerService,
        private statusPipe: StatusPipe,
        private onlineStatusPipe: OnlineStatusPipe
    ) {}

    public download(
        filename: string,
        items: any[],
        columns: string[],
        translateStatus?: boolean,
        isEndpoint?: boolean,
        options?: any,
        toUpperCase = true
    ) {
        const useOptions = options != null ? options : defaultOptions;

        const data = [];

        if (items.length > 0) {
            // Construct the header rows
            const header = {};

            for (const column of columns) {
                const headerText = toUpperCase ? column.toUpperCase() : column;
                header[column] = headerText;
            }
            data.push(header);

            // Construct each row based on the columns defined
            for (const item of items) {
                const row = {};

                for (const column of columns) {
                    const columnValue = _.get(item, column);

                    if ((columnValue === undefined || columnValue === null) && column !== 'onlineStatus') {
                        row[column] = '';
                    } else {
                        if (translateStatus && column === 'status') {
                            if (isEndpoint && item.status !== 400) {
                                row[column] = this.onlineStatusPipe.transform(item.currentState);
                            } else {
                                row[column] = this.statusPipe.transform(columnValue, isEndpoint);
                            }
                        } else if (column === 'onlineStatus') {
                            row[column] = item['currentState'];
                        } else if (Array.isArray(columnValue)) {
                            row[column] = columnValue.join(',');
                        } else {
                            row[column] = columnValue;
                        }
                    }
                }
                data.push(row);
            }

            const csv = new AngularCsv(data, filename, useOptions);
        } else {
            this.growler.show(
                new GrowlerData(
                    'ERROR',
                    'No Data',
                    'Dataset is Empty',
                    'The system must have data in order to generate an export file.'
                )
            );
        }
    }

    downloadAll(data, filename, useOptions?, type?) {
        let fileType = 'csv';
        switch (type) {
            case 'text/csv':
                fileType = 'csv';
                break;
            case 'application/json':
                fileType = 'json';
                break;
            case 'application/x-yaml':
                fileType = 'yml';
                break;
        }
        if (useOptions && useOptions.isEndpoint) {
            data = data.replace('hasApiSession', 'online');
        }
        const newBlob = new Blob([decodeURIComponent(encodeURI(data))], {
            type: `${type};charset=utf-8;`,
        });
        saveAs(newBlob, `${filename}.${fileType}`);
    }
}
