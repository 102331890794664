import { Injectable } from '@angular/core';
import { MopResource, Network } from '@netfoundry-ui/shared/model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { defer } from 'lodash';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class NetworkService extends MopResourceService {
    networkList: Network[];
    page = null;
    links = null;
    private networksSource = new Subject<Network[]>();
    networks = this.networksSource.asObservable();

    constructor(protected logger: LoggerService, protected apiservice: ApiService) {
        super(logger, apiservice);
    }

    /**
     * Gets all networks for org
     */
    get(pageNumber?: number, sort?: string, filter?: string, pageSize?: number): Observable<any> {
        defer(() => {
            this.networksSource.next([]);
        });
        return this.networks;
    }

    /**
     * Saves the mop resource and returns result as observable
     * overrides the save function of the mopsharedresource service
     */
    public save(resourceModel: MopResource, organizationId?: string) {
        defer(() => {
            this.networksSource.next([]);
        });
        return this.networks;
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const networks: Network[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['networks'] !== undefined) {
            for (let i = 0; i < res['_embedded']['networks'].length; i++) {
                const network = res['_embedded']['networks'][i];
                networks.push(new Network(network));
            }
        }

        // object containing information on pagination such as page number, size, total number of items,  and total number of pages
        let page = null;
        if (res['page'] !== undefined) {
            page = res['page'];
        }

        // list of links for jumping to and from pages
        let links = null;
        if (res['_links'] !== undefined) {
            links = res['_links'];
        }

        return { networks: networks, page: page, links: links };
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl(organizationId?: string) {
        const orgId = organizationId != null ? organizationId : this.apiService.theOrgIs.getId();
        return `organizations/${orgId}/networks`;
    }
}
