import { HateoasResource, PagedGetOption, PagedResourceCollection, Resource } from '@lagoshny/ngx-hateoas-client';
import { BaseResource } from '@lagoshny/ngx-hateoas-client/lib/model/resource/base-resource';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppWanV2 } from './app-wan';
import { CertificateAuthorityV2 } from './certificate-authority';
import { EdgeRouterV2 } from './edge-router';
import { EdgeRouterPolicyV2 } from './edge-router-policy';
import { EndpointV2 } from './endpoint';
import { NetworkController } from './network-controller';
import { ServiceV2 } from './service';

@HateoasResource('networks', { routeName: 'networkResourceRoute' })
export class NetworkV2 extends Resource {
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };

    id: string;
    networkGroupId: string;
    name: string;
    customerProviderDetails: any;
    alternateDomainName: string;
    productVersion: string;
    locationCode: string;
    region: string;
    provider: string;
    o365BreakoutCategory: string;
    size: string;
    networkController: NetworkController;
    createdAt: string;
    updatedAt: string;
    endpoints: EndpointV2[];
    edgeRouters: EdgeRouterV2[];
    edgeRouterPolicies: EdgeRouterPolicyV2[];
    services: ServiceV2[];
    appWans: AppWanV2[];
    status: string;
    certificateAuthorities: CertificateAuthorityV2[];
    selected = false;
    networkConfigMetadataId: string;
    publicCertEnabled: boolean;
    ipAddress: string;

    public getId() {
        return this.id;
    }

    public getSelfLink() {
        return this._links.self.href;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getRelatedPage<T>(relatedType: string, options: PagedGetOption): Observable<T> {
        throw new Error('Do not use: see getRelated specific functions');
    }

    public getRelatedSubscription<T>(
        typeStr: string,
        options: PagedGetOption,
        includeProperties?: string
    ): Observable<T[]> {
        let params = { ...NetworkV2.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super.getRelatedPage(typeStr, params).pipe(map((records) => records.resources)) as unknown as Observable<
            T[]
        >;
    }

    public findRelatedPage(
        typeStr: string,
        options: PagedGetOption,
        includeProperties?: string
    ): Promise<PagedResourceCollection<BaseResource>> {
        let params = { ...NetworkV2.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super.getRelatedPage(typeStr, params).toPromise();
    }

    public findRelatedPromise<T>(typeStr: string, options: PagedGetOption, includeProperties?: string): Promise<T[]> {
        return this.findRelatedPage(typeStr, options, includeProperties).then(
            (results) => results.resources
        ) as unknown as Promise<T[]>;
    }
}
