import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeatureHighchartsGenericModule } from '@netfoundry-ui/feature/highcharts-generic';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { SharedElasticsearchModule } from '@netfoundry-ui/shared/elasticsearch';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { MatDialogModule } from '@angular/material/dialog';
import { ZitiUtilizationBasicComponent } from './ziti-utilization-basic.component';

@NgModule({
    imports: [
        CommonModule,
        FeatureHighchartsGenericModule,
        UiLoaderModule,
        UiPipesModule,
        SharedElasticsearchModule,
        MatDialogModule,
    ],
    declarations: [ZitiUtilizationBasicComponent],
    exports: [ZitiUtilizationBasicComponent],
})
export class FeatureZitiUtilizationBasicModule {}
