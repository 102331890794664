import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT, HostV2 } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';
import {
    GetOption,
    HateoasResourceOperation,
    PagedGetOption,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';

@Injectable({ providedIn: 'root' })
export class HostService extends HateoasResourceOperation<HostV2> {
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;

    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(HostV2);
    }

    getResource(): Observable<HostV2> {
        throw new Error('Do not use: see get getEndpoint');
    }

    getPage(): Observable<PagedResourceCollection<HostV2>> {
        throw new Error('Do not use: see getEndpoints');
    }

    getHost(id, options: GetOption = {}): Promise<HostV2> {
        return super
            .getResource(id, { ...HostService.defaultHttpAccept, ...options })
            .toPromise()
            .then((endpoint) => endpoint);
    }

    async getHostPage(options?: PagedGetOption): Promise<HostV2[]> {
        return super
            .getPage({ ...HostService.defaultHttpAccept, ...options })
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((endpoints) => endpoints.resources);
    }

    public findByNetworkId(networkId: string): Promise<HostV2[]> {
        const options: PagedGetOption = {
            ...HostService.defaultHttpAccept,
            params: { networkId },
        };
        return super
            .getPage(options)
            .toPromise()
            .then((rec) => rec.resources);
    }

    public getSizes(provider): Observable<HostV2[]> {
        const options: any = {};
        const url = `${this.environment.v2apiUrl}host-sizes?providers=${provider}&type=ER`;
        return this.http.get(url, options) as Observable<any>;
    }
}
