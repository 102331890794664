import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { CertificateAuthority } from '@netfoundry-ui/shared/model';
import { CertificateAuthorityService, ValidateService } from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-certificate-authorities-form',
    templateUrl: './certificate-authorities-form.component.html',
    styleUrls: ['./certificate-authorities-form.component.scss'],
})
export class CertificateAuthoritiesFormComponent implements OnInit, OnDestroy {
    public model: CertificateAuthority = new CertificateAuthority({});
    errorName = false;
    errorPem = false;

    isInline = false;

    processing = false;

    subsciption: Subscription = new Subscription();

    hideHelp = false;

    isEditing = false;

    originalOttEnabled = false;
    originalAutoEnroll = false;
    originalAuthEnabled = false;

    confirmDilogRef;
    bulkEdit = false;

    constructor(
        private growlerService: GrowlerService,
        private validateService: ValidateService,
        private dialogRef: MatDialogRef<CertificateAuthoritiesFormComponent>,
        private certificateAuthorityService: CertificateAuthorityService,
        public dialogForm: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data.model) {
            this.model = data.model;
            this.isEditing = true;
            this.originalAuthEnabled = this.model.isAuthEnabled;
            this.originalAutoEnroll = this.model.isAutoCaEnrollmentEnabled;
            this.originalOttEnabled = this.model.isOttCaEnrollmentEnabled;
        }
        if (data?.bulkEdit) {
            this.bulkEdit = true;
        }
    }

    ngOnInit() {
        this.errorName = false;
        this.errorPem = false;
        this.processing = false;
        if (this.bulkEdit) {
          this.validate();
        }
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.hide();
    }

    ngOnDestroy() {
        this.subsciption.unsubscribe();
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.hide();
        }
    }

    hide() {
        this.dialogRef.close();
    }

    async save() {
      if (this.bulkEdit) {
        this.dialogRef.close();
      } else if (this.validate()) {
            if (this.isEditing && this.displayWarning()) {
                const confirmed = await this.openConfirm();
                if (!confirmed) {
                    return;
                }
            }

            this.processing = true;

            this.subsciption.add(
                this.certificateAuthorityService.save(this.model).subscribe(
                    (result) => {
                        this.growlerService.show(
                            new GrowlerData('success', 'Success', 'CA Save Complete', 'The information has been saved')
                        );
                        this.hide();
                    },
                    (error) => {
                        this.processing = false;
                    }
                )
            );
        }
    }

    OnDestroy() {
        this.subsciption.unsubscribe();
    }

    validate(): boolean {
        this.errorName = false;
        this.errorPem = false;

        if (!this.validateService.isValidName(this.model.name)) {
            this.errorName = true;
        }
        if (!this.isEditing && !this.validateService.hasValue(this.model.certPem)) {
            this.errorPem = true;
        }

        return !this.errorName && !this.errorPem;
    }

    toggleAuthEnabled() {
        this.model.isAuthEnabled = !this.model.isAuthEnabled;
    }

    toggleAutoEnrollment() {
        this.model.isAutoCaEnrollmentEnabled = !this.model.isAutoCaEnrollmentEnabled;
    }

    toggleOttEnrollment() {
        this.model['isOttCaEnrollmentEnabled'] = !this.model['isOttCaEnrollmentEnabled'];
    }

    displayWarning() {
        return (
            (this.originalOttEnabled && !this.model.isOttCaEnrollmentEnabled) ||
            (this.originalAutoEnroll && !this.model.isAutoCaEnrollmentEnabled) ||
            (this.originalAuthEnabled && !this.model.isAuthEnabled)
        );
    }

    async openConfirm() {
        const changedItems = [];
        if (this.originalAutoEnroll && !this.model.isAutoCaEnrollmentEnabled) {
            changedItems.push({ name: 'Auto CA Enrollment' });
        }

        if (this.originalOttEnabled && !this.model.isOttCaEnrollmentEnabled) {
            changedItems.push({ name: 'OTT Enrollment Enabled' });
        }

        if (this.originalAuthEnabled && !this.model.isAuthEnabled) {
            changedItems.push({ name: 'Auth Enabled' });
        }

        const data = {
            title: 'Warning',
            appendId: 'CertificateAuthorities',
            subtitle: 'Disabling the following options will cause future enrollments using this CA to fail:',
            afterListText: 'Are you sure you want to change these options?',
            bulletList: changedItems,
            action: 'Yes',
            icon: 'Caution',
        };
        this.confirmDilogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '340px',
            width: '600px',
            autoFocus: false,
        });
        return await this.confirmDilogRef
            .afterClosed()
            .toPromise()
            .then((result) => {
                // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                //  logged out of the console and we should close the dialog without continuing
                if (result === undefined) {
                    return false;
                } else if (result['loggingOut'] === undefined) {
                    return result;
                }
            });
    }
}
