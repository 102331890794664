import { Component, Input, OnInit } from '@angular/core';
import { GrowlerService } from '@netfoundry-ui/shared/growler';
import { FeatureService, LoggerService } from '@netfoundry-ui/shared/services';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import _ from 'lodash';

import { ZitiOptionsService } from '@netfoundry-ui/shared/helpers';

@Component({
    selector: 'app-source-transparency',
    templateUrl: './source-transparency.component.html',
    styleUrls: ['./source-transparency.component.scss'],
    providers: [FromDatePipe],
})
export class SourceTransparencyComponent implements OnInit {
    @Input() model: any = {
        data: {
            allowedSourceAddresses: [],
            listenOptions: {},
        },
    };
    @Input() isNew = true;
    @Input() isEditing = true;
    @Input() readOnly = false;
    @Input() errors: any = {};

    transparencyEnabled = false;

    constructor(
        private logger: LoggerService,
        private growlerService: GrowlerService,
        private optionsService: ZitiOptionsService,
        public featureService: FeatureService
    ) {}

    ngOnInit() {
        this.initModel();
    }

    initModel() {
        if (this.model?.data?.allowedSourceAddresses) {
            this.transparencyEnabled = true;
        }
    }

    onSourceAddressKeyDown(event: KeyboardEvent) {
        if (event.key === ' ') {
            event.preventDefault();
            const element = event.target as HTMLElement;
            element.blur();
            element.focus();
        }
    }

    toggleSourceTransparency() {
        this.transparencyEnabled = !this.transparencyEnabled;
        if (this.transparencyEnabled && !this.model?.data?.allowedSourceAddresses) {
            _.set(this.model, 'data.allowedSourceAddresses', []);
        } else {
            _.unset(this.model, 'data.allowedSourceAddresses');
        }
    }
}
