import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT, ServiceV2 } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import {
    GetOption,
    HateoasResourceOperation,
    PagedGetOption,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ServiceServiceV2 extends HateoasResourceOperation<ServiceV2> {
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;

    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(ServiceV2);
    }

    getResource(): Observable<ServiceV2> {
        throw new Error('Do not use: see get getService');
    }

    getPage(): Observable<PagedResourceCollection<ServiceV2>> {
        throw new Error('Do not use: see getServices');
    }

    getService(id, options: GetOption = {}): Promise<ServiceV2> {
        return super
            .getResource(id, { ...ServiceServiceV2.defaultHttpAccept, ...options })
            .toPromise()
            .then((service) => service);
    }

    async getServicesPage(options?: PagedGetOption): Promise<ServiceV2[]> {
        return super
            .getPage({ ...ServiceServiceV2.defaultHttpAccept, ...options })
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((services) => services.resources);
    }

    public downloadToCsv(networkId: string): Observable<any> {
        const params = new HttpParams().set('networkId', networkId).set('size', this.lastTotalCount);
        const headers = new HttpHeaders().set('Accept', 'text/csv');
        return this.http.get(this.environment.v2apiUrl + 'services', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public findAssociatedRouters(serviceId: string): Promise<any> {
        if (!serviceId) return Promise.resolve([]);
        const params = new HttpParams().set('accessibleServiceId', serviceId);
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http
            .get(this.environment.v2apiUrl + 'edge-routers', {
                headers: headers,
                params: params,
                responseType: 'json',
            })
            .toPromise();
    }

    public findAssociatedEndpoints(serviceId: string): Promise<any> {
        if (!serviceId) return Promise.resolve([]);
        const params = new HttpParams().set('accessibleServiceId', serviceId);
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http
            .get(this.environment.v2apiUrl + 'endpoints', {
                headers: headers,
                params: params,
                responseType: 'json',
            })
            .toPromise();
    }
}
