import { Component, HostListener, Inject, Input, OnDestroy, OnInit, EventEmitter, OnChanges } from '@angular/core';
import moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { Subscription } from 'rxjs';
import {EVENT_FIELDS, EventFields, NetworkV2} from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, LoggerService } from '@netfoundry-ui/shared/services';
import { debounceTime } from 'rxjs/operators';
import _ from 'lodash';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { TableHeaderDefaultComponent } from '@netfoundry-ui/feature/data-table';
import { FabricCircuitTemplateQueryParameter } from '@netfoundry-ui/shared/model';
import {TEMPLATE_SEARCH_SERVICE, TemplateSearchService} from "@netfoundry-ui/shared/apiv2";

@Component({
    selector: 'app-dial-logs',
    templateUrl: './dial-logs.component.html',
    styleUrls: ['./dial-logs.component.scss'],
    providers: [FromDatePipe, CsvDownloadService, TableFilterService],
})
export class DialLogsComponent implements OnInit, OnDestroy, OnChanges {
    setDateFilterEvent: EventEmitter<boolean> = new EventEmitter();
    setNewEndTime: EventEmitter<string> = new EventEmitter();
    setNewStartTime: EventEmitter<string> = new EventEmitter();
    @Input() networkId: any = null;
    @Input() organizationId: any = null;
    @Input() filterField = null;
    @Input() filterValue = null;
    @Input() newStartTime: any;
    @Input() newEndTime: any;
    @Input() endpointId = '';
    @Input() serviceId = '';
    dateFilter = '24h';
    endTime: number = Date.now();
    startTime: number = this.endTime - 24 * 60 * 60 * 1000;
    sorting = '@timestamp';
    currentNetwork = new NetworkV2();
    isLoading = false;
    initialized = false;
    showNoData = false;
    displayedItems = [];
    endpoint;
    endpointName;
    gridApi: any;
    itemCount = 0;
    service;
    serviceName;
    errorMessage: string | undefined;
    ordering = 'desc';
    serviceTableLocked = false;
    filterHasChanged = false;
    filterApplied = false;
    filterString = '';
    subscription = new Subscription();
    eventTypes = ['New', 'Building', 'Error', 'Changed', 'Status'];
    items = [];
    page = 1;
    gridObj;

    pageSize = 30;
    paramList = {
        size: this.pageSize,
        sort: '@timestamp,asc',
    };

    columnDefs;

    filterTypes = {
        endpoint: 'nf_endpoint_id',
        service: 'nf_service_id',
        'edge-router': 'nf_edge_router_id',
    };
    columnFilters: any = {
        timeStamp: '',
        resourceType: '',
        eventType: '',
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fromDate: FromDatePipe,
        private csvDownloadService: CsvDownloadService,
        private dialogRef: MatDialogRef<DialLogsComponent>,
        private elasticsearch: ElasticsearchService,
        public filterService: TableFilterService,
        public apiService: ApiService,
        private logger: LoggerService,
        @Inject(TEMPLATE_SEARCH_SERVICE) public templateService: TemplateSearchService,
        @Inject(EVENT_FIELDS) private eventFields: EventFields,
    ) {
        this.initTableColumns();
        this.newStartTime = moment().subtract(24, 'h');
        this.newEndTime = moment();
        if (this.data.resourceType === 'endpoint') {
            this.endpoint = true;
            this.endpointName = data.model.name;
            this.endpointId = data.model.id;
        } else {
            this.service = true;
            this.serviceName = data.model.name;
            this.serviceId = data.model.id;
        }
    }

    initTableColumns() {
        const columnFilters = this.columnFilters;

        const headerComponentParams = {
            filterType: 'TEXTINPUT',
            columnFilters,
        };
        const time = (row: { data: any }) => {
            const item = row.data;
            return this.formatTime(item['@timestamp']);
        };

        const eventTypeHeaderParams = {
            filterType: 'SELECT',
            filterOptions: [
                { label: 'ALL', value: '' },
                { label: 'Created', value: 'created' },
                { label: 'Failed', value: 'failed' },
                { label: 'Path Updated', value: 'pathUpdated' },
                { label: 'Deleted', value: 'deleted' },
            ],
            columnFilters,
        };

        const terminatorLocal = (row) => {
            const item = row.data;
            const path = _.get(item, 'path', null);
            if (path) {
                return path.terminator_local_addr;
            }
        };
        const terminatorRemote = (row) => {
            const item = row.data;
            const path = _.get(item, 'path', null);
            if (path) {
                return path.terminator_remote_addr;
            }
        };

        const name = (row) => {
            const item = row.data;
            if (this.endpoint) {
                return item[this.eventFields.serviceName];
            } else {
                return item[this.eventFields.identityName];
            }
        };

        this.columnDefs = [
            {
                colId: 'eventType',
                minWidth: 100,
                field: 'event_type',
                tooltipField: 'event_type',
                headerName: 'Event Type',
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
                headerComponentParams: eventTypeHeaderParams,
            },
            {
                colId: '@timestamp',
                minWidth: 100,
                valueFormatter: time,
                tooltipField: '@timestamp',
                sortColumn: this.sort.bind(this),
                headerName: 'Time',
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'name',
                minWidth: 100,
                valueFormatter: name,
                tooltipField: 'name',
                sortColumn: this.sort.bind(this),
                headerName: 'Name',
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'termLocal',
                minWidth: 100,
                valueFormatter: terminatorLocal,
                tooltipField: 'termLocal',
                headerName: 'Terminator Local',
                hide: true,
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'termRemote',
                minWidth: 100,
                valueFormatter: terminatorRemote,
                tooltipField: 'termRemote',
                headerName: 'Terminator Remote',
                headerComponent: TableHeaderDefaultComponent,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'failureCause',
                minWidth: 100,
                field: 'failure_cause',
                tooltipField: 'name',
                headerName: 'Failure Cause',
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
        ];
    }

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentNetwork.pipe(debounceTime(500)).subscribe((network) => {
                this.currentNetwork = network;
                this.networkId = network.id;
                this.organizationId = this.currentNetwork.networkGroupId;
                this.initialized = true;
                this.generateUniqueDialLogs();
                this.filterService.setPageSize(this.pageSize);
                this.subscription.add(
                    this.filterService.setDateFilterEvent.subscribe(() => {
                        this.newStartTime = this.filterService.getStartTime();
                        this.newEndTime = this.filterService.getEndTime();
                        this.isFilterApplied();
                        this.generateUniqueDialLogs();
                    })
                );
            })
        );
        this.filterService.setPageEvent.subscribe((pageNum) => {
            this.gridObj.api.paginationGoToPage(pageNum - 1);
        });

        this.subscription.add(
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.columnFilters.name = filterString;
                this.isFilterApplied();
                this.refresh();
            })
        );
        this.filterService.getDateFilter();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    filterChanged(columnId: _.PropertyPath, value: string) {
        if (columnId === 'name') {
            this.filterString = value;
        }
        _.set(this.columnFilters, columnId, value);
        this.isLoading = true;
        this.isFilterApplied();
        this.shouldShowNoData();
        this.refresh();
    }

    isFilterApplied() {
        this.filterHasChanged = true;
        this.filterApplied = _.some(this.columnFilters, (value, filterName) => !_.isEmpty(_.toString(value)));
    }

    applyFilter() {
        this.displayedItems = this.filterService.applyLocalFilter(this.items);
    }

    ngOnChanges() {
        if (!this.initialized || this.networkId === null || this.organizationId === null) {
            return;
        }

        this.logger.info('Changes...');
    }

    onGridReady(gridObj) {
        this.gridObj = gridObj;
    }

    public getUniqueDialLogs() {
        this.isLoading = true;
        if (!this.initialized) {
            return;
        }
        this.filterService.setTotalElements(this.itemCount);

        const index = 'ncfabriccircuits';
        this.logger.info('inside dial logs call');

        this.logger.info('Ziti Unique Dial Logs Query:', JSON.stringify(this.getQuery()));

        //reportig
        let networkEventType: any = '';
        let endpointName: any = '';
        let serviceName: any = '';
        if (this.filterApplied && this.columnFilters.eventType) {
            networkEventType = this.columnFilters.eventType;
        }
        if (this.filterApplied && this.columnFilters.name && this.endpointId) {
            serviceName = this.columnFilters.name;
        }
        if (this.filterApplied && this.columnFilters.name && this.serviceId) {
            endpointName = this.columnFilters.name;
        }

        const fabricCircuitTemplateQueryParameter: FabricCircuitTemplateQueryParameter = this.templateService.getFabricCircuitTemplateQuery(index, this.newStartTime, this.newEndTime, this.endpointId, this.serviceId, this.networkId, this.organizationId, networkEventType, endpointName, serviceName);

        this.subscription.add(
            //this.elasticsearch.search(this.organizationId, index, this.getQuery())
            this.elasticsearch
                .apiTemplateSearch(
                    this.templateService.SERVICE_ENDPOINT_DIAL_LOGS_TEMPLATE,
                    fabricCircuitTemplateQueryParameter
                )
                .subscribe(
                    (data) => {
                        this.logger.info(data);
                        this.items = this.elasticsearch.hitsToArray(data, 'dial-logs-timeline').map((item, index) => ({
                            ...item,
                            itemIndex: index,
                        }));
                        this.filterService.setPageSize(30);
                        this.filterService.setTotalElements(this.items.length);
                        this.filterService.updateTotalPages();
                        this.isLoading = false;
                        this.shouldShowNoData();
                    },
                    (error) => {
                        this.errorMessage = 'error loading timeline data';
                        this.logger.error(error);
                    }
                )
        );
    }

    public getQuery() {
        const model: any = {
            track_total_hits: true,
            sort: [
                {
                    '@timestamp': {
                        order: 'desc',
                        unmapped_type: 'boolean',
                    },
                },
            ],
            size: 500,
            _source: true,
            query: {
                bool: {
                    must: [],
                    filter: [
                        {
                            match_phrase: {
                                organizationId: this.organizationId,
                            },
                        },
                        {
                            match_phrase: {
                                network_id: this.networkId,
                            },
                        },
                    ],
                },
            },
        };
        model.query.bool.must.push({
            range: {
                '@timestamp': {
                    gte: this.newStartTime.valueOf(),
                    lte: this.newEndTime.valueOf(),
                    format: 'epoch_millis',
                },
            },
        });
        if (!_.isEmpty(this.endpointId)) {
            const search = { match_phrase: { nf_endpoint_id: this.endpointId } };
            model.query.bool.must.push(search);
        }
        if (!_.isEmpty(this.serviceId)) {
            const search = { match_phrase: { nf_service_id: this.serviceId } };
            model.query.bool.must.push(search);
        }
        if (this.filterApplied && this.columnFilters.eventType) {
            model.query.bool.must.push({ match_phrase: { event_type: this.columnFilters.eventType } });
        }
        if (this.filterApplied && this.columnFilters.name) {
            model.query.bool.must.push({
                query_string: { query: '*' + this.columnFilters.name + '*', analyze_wildcard: true },
            });
        }
        return model;
    }

    sort(sortBy: any) {
        if (this.sorting === sortBy) {
            if (this.ordering === 'asc') {
                this.ordering = 'desc';
            } else {
                this.ordering = 'asc';
            }
        } else {
            this.ordering = 'asc';
            this.sorting = sortBy;
        }
        this.paramList['sort'] = `${this.sorting},${this.ordering}`;
        this.refresh();
    }

    shouldShowNoData() {
        if (this.items.length === 0) {
            this.showNoData = true;
        }
    }

    public refresh() {
        this.generateUniqueDialLogs();
    }

    close() {
        this.dialogRef.close();
    }

    formatTime(theDate: moment.MomentInput) {
        return moment.utc(theDate).local().format('M/D/YY h:mm a');
    }

    private generateUniqueDialLogs() {
        if (this.initialized && this.networkId !== null && this.networkId && this.organizationId !== null) {
            this.getUniqueDialLogs();
        } else {
            this.logger.info('Missing networkId, skipping network event timeline call');
        }
    }
}
