import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule } from '@angular/forms';
import { NfSelect2Component } from './nf-select2.component';

@NgModule({
    declarations: [NfSelect2Component],
    exports: [NfSelect2Component],
    imports: [CommonModule, FormsModule, UiPipesModule, ClickOutsideModule],
})
export class FeatureNfSelect2Module {}
