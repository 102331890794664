import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RegionService } from '@netfoundry-ui/shared/services';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { UiConfirmModule } from '@netfoundry-ui/ui/confirm';
import { AppWanServiceV2 } from './services/app-wan.service';
import { AttributesService } from './services/attributes.service';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { CertificateAuthorityServiceV2 } from './services/certificate-authority.service';
import { ConfigTypeService } from './services/config-type.service';
import { ConfigService } from './services/config.service';
import { EdgeRouterPolicyServiceV2 } from './services/edge-router-policy.service';
import { EdgeRouterServiceV2 } from './services/edge-router.service';
import { EndpointServiceV2 } from './services/endpoint.service';
import { NetworkControllerService } from './services/network-controller.service';
import { NetworkServiceV2 } from './services/network.service';
import { PlatformServiceService } from './services/platform-service.service';
import { PostureCheckService } from './services/posture-check.service';
import { ProcessExecutionServiceV2 } from './services/process-execution.service';
import { ServiceEdgeRouterPolicyService } from './services/service-edge-router-policy.service';
import { ServicePolicyService } from './services/service-policy.service';
import { ServiceServiceV2 } from './services/service.service';
import { ZitiControllerService } from './services/ziti-controller.service';
import { ZitiApiInterceptor } from './services/ziti-api.interceptor';

@NgModule({
    imports: [CommonModule, HttpClientModule, SharedModelModule, UiConfirmModule],
    declarations: [],
    exports: [],
    providers: [],
})
export class SharedApiv2Module {
    static forRoot(): ModuleWithProviders<SharedApiv2Module> {
        return {
            ngModule: SharedApiv2Module,
            providers: [
                AppWanServiceV2,
                EdgeRouterServiceV2,
                EdgeRouterPolicyServiceV2,
                EndpointServiceV2,
                NetworkControllerService,
                NetworkServiceV2,
                RegionService,
                ServiceServiceV2,
                ProcessExecutionServiceV2,
                CertificateAuthorityServiceV2,
                AttributesService,
                AuthInterceptor,
                PostureCheckService,
                ConfigService,
                ConfigTypeService,
                PlatformServiceService,
                ServiceEdgeRouterPolicyService,
                ServicePolicyService,
                ZitiApiInterceptor,
            ],
        };
    }
}
