import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BrandingModeService, LoggerService } from '@netfoundry-ui/shared/services';
import LogRocket from 'logrocket';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IamService } from './iam.service';

@Injectable({
    providedIn: 'root',
})
export class Auth0Service {
    isAuthenticated$: Observable<boolean>;
    idToken: any;
    user: any;
    accessToken: any;
    expiration: any;
    appState: any;
    jwtHelper: JwtHelperService;
    authenticated: any;

    constructor(
        private logger: LoggerService,
        private auth0Angular: AuthService,
        private iamService: IamService,
        private brandingService: BrandingModeService
    ) {
        auth0Angular.error$?.subscribe((error) => this.handleError(error));
        this.jwtHelper = new JwtHelperService();

        auth0Angular.idTokenClaims$?.subscribe((claims) => {
            if (claims?.exp) {
                const expiresAt = JSON.stringify(claims.exp * 1000);
                localStorage.setItem('expires_at', expiresAt);
            }
            this.idToken = claims?.__raw;
            localStorage.setItem('profile_email', claims?.email);
            localStorage.setItem('profile_nick', claims?.nickname);
            localStorage.setItem('profile_picture', claims?.picture);
        });
        auth0Angular.appState$?.subscribe((state) => {
            const nfState = this.jwtHelper.decodeToken(state.nfToken);
            if (nfState?.tenantLabel) {
                this._setSession(nfState.tenantLabel);
            }
            this.appState = state;
        });
        auth0Angular.user$?.subscribe(async (user) => {
            if (user) {
                const u = auth0Angular.getIdTokenClaims();
                this.user = u;
                LogRocket.identify(user.email, {
                    name: user.name,
                    email: user.email,
                });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const _hsq = (window._hsq = window._hsq || []);
                _hsq.push([
                    'identify',
                    {
                        email: user.email,
                    },
                ]);
            }
        });
        this.isAuthenticated$ = auth0Angular.isAuthenticated$;
        this.isAuthenticated$?.subscribe(async (result) => {
            this.authenticated = result;
            const enjoyhint_instance = window['enjoyhint_instance'];
            if (!this.authenticated && enjoyhint_instance?.trigger) {
                enjoyhint_instance.trigger('skip');
            }
        });
    }

    loginWithRedirect(state?: any): void {
        // Call this to redirect the user to the login page
        this.auth0Angular.loginWithRedirect(state);
    }

    logout(options: any = { returnTo: window.location.origin }): void {
        this.auth0Angular.logout(options);
    }

    handleError(error: Error): void {
        this.logger.error(error.message);
    }

    getErrorObservable(): Observable<any> {
        return this.auth0Angular.error$;
    }

    getUserObservable(): Observable<any> {
        return this.auth0Angular.user$;
    }

    getUser() {
        return this.auth0Angular.user$.pipe(
            take(1),
            tap((user) => {
                this.logger.info(`returning ${JSON.stringify(user)}`);
            })
        );
    }

    public _setSession(tenantLabel): void {
        // Set the time that the access token will expire at
        this.logger.info('Setting session for tenant: ' + tenantLabel);
        //const jwt = this.jwtHelper.decodeToken(authResult.accessToken);
        // fetch and store the Tenant info
        const authToken = this.getAuthToken();
        const jwt = this.jwtHelper.decodeToken(authToken);
        localStorage.setItem('Auth0Id', jwt.sub);
        localStorage.setItem('connectionId', jwt['https://netfoundry.io/auth0/connectionId']);

        this.iamService.getOrgByShortName(tenantLabel).subscribe((result: any) => {
            this.logger.info('Tenant Info', result);
            localStorage.setItem('tenantName', result.name);
            localStorage.setItem('tenantLabel', result.label);
            localStorage.setItem('tenantId', result.id);
            localStorage.setItem('loggedInTenantId', result.id);

            // set branding once we have the tenant label
            const html = document.getElementsByTagName('html')[0];

            this.brandingService.fetchBrandCss().then((rawCss) => {
                html.style.cssText = rawCss;
            });
            // checking if the tenant the user is logged into is the same as the last tenant that was logged into
            // in this session
            if (result.id !== sessionStorage.getItem('lastTenantId')) {
                // if the tenants are different, remove the old growler messages
                sessionStorage.removeItem('growlerErrors');
            }
            sessionStorage.setItem('lastTenantId', result.id);
        });
    }

    getAuthToken() {
        const setlocalStorage = Object.entries(localStorage);
        const bearerJwtObjectArray = setlocalStorage.find(([key]) => /@@auth0spa*/.test(key));
        const bearerJwtObject = JSON.parse(bearerJwtObjectArray[1]);
        const bearerJwt = bearerJwtObject.body.access_token;
        return bearerJwt;
    }
}
