import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'alarmTranslate',
})
export class AlarmTranslatePipe implements PipeTransform {
    severities = ['Critical', 'Major', 'Minor', 'Warning', 'Standing_Condition', 'Clear'];

    // Currently we will only expose mapped alarms
    map = {
        VTC_CALLP_UNREACHABLE: {
            nf_code: 'ENDPOINT_SESSION_CONTROLLER_COMM',
            nf_category: 'ENDPOINT_COMM_OUTAGE',
            nf_description: 'ENDPOINT Cannot Contact Network Control Plane',
        },
        VTC_SERVER_ERROR: {
            nf_code: 'APPWAN_SERVICE_UNAVAILABLE',
            nf_category: 'APPWAN_SERVICE_UNAVAILABLE',
            nf_description: 'APPWAN Data Service Transport Unavailable',
        },
        VTC_SERVICE_OFFLINE: {
            nf_code: 'APPWAN_HOST_UNAVAILABLE',
            nf_category: 'APPWAN_SERVICE_UNAVAILABLE',
            nf_description: 'APPWAN Endpoint hosting a service is unavailable',
        },
        DEFLECT_POOL_BAD_STATE: {
            nf_code: 'APPWAN_CHANNELS_LOW',
            nf_category: 'APPWAN_UNAVAILABLE',
            nf_description: 'APPWAN Data Transport Unavailable',
        },
        VTC_DRIVER_ABSENT: {
            nf_code: 'ENDPOINT_NF_APPLICATION_DRIVER_MISSING',
            nf_category: 'ENDPOINT_APPLICATION_MISSING',
            nf_description: 'ENDPOINT application not installed or functioning properly',
        },
        FIREWALL: {
            nf_code: 'ENDPOINT_DETECTED_AND_PREVENTED_DATA_ANOMALY',
            nf_category: 'ENDPOINT_PREVENTED_DATA_ANOMALY',
            nf_description: 'ENDPOINT Detected and prevented possible malicious data',
        },
        VTC_CERT_MISSING: {
            nf_code: 'ENDPOINT_CERTIFICATE_UNAVAILABLE',
            nf_category: 'ENDPOINT_CERTIFICATE_UNAVAILABLE',
            nf_description: 'ENDPOINT application cannot find certificate for identification with network',
        },
        VTC_TRUSTED_NIC_DOWN: {
            nf_code: 'ENDPOINT_LAN_INTERFACE_DOWN',
            nf_category: 'ENDPOINT_LAN_INTERFACE_DOWN',
            nf_description: 'ENDPOINT LAN interface unavailable for application communication',
        },
        L2MLX_CONFIG_ERROR: {
            nf_code: 'L2SWITCH_ENDPOINT_LOCAL_SETUP_MISCONFIGURED',
            nf_category: 'L2SWITCH_ENDPOINT_LOCAL_SETUP_MISCONFIGURED',
            nf_description: 'L2 Switch Configuration Issue Detected',
        },
        DSX_VTC_LOGIN_LOCKED: {
            nf_code: 'ENDPOINT_CLIENT_MULTIFACTOR_AUTH_LOCKOUT',
            nf_category: 'ENDPOINT_CLIENT_MULTIFACTOR_AUTH_LOCKOUT',
            nf_description: 'Client failed to login with user/password multifactor auth 3 times',
        },
        DSX_GLOBAL_CALLP: {
            nf_code: 'NETWORK_SERVICES_UNAVAILABLE',
            nf_category: 'NETWORK_SERVICES_UNAVAILABLE_OR_UNPROVISIONED',
            nf_description: 'Unable to initiate network data sessions OR no services have been provisioned',
        },
        MAX_MTU_EXCEED: {
            nf_code: 'ENDPOINT_MAX_PACKET_SIZE_EXCEEDED',
            nf_category: 'ENDPOINT_MAX_PACKET_SIZE_EXCEEDED',
            nf_description: 'Endpoint detected packet that was too large to process',
        },
        VTC_OUT_OF_SOCKET: {
            nf_code: 'ENDPOINT_SOCKETS_EXHAUSTED',
            nf_category: 'ENDPOINT_SOCKETS_EXHAUSTED',
            nf_description: 'Endpoint exceeded max number of possible data connections',
        },
    };

    transform(alarms: any[]): any {
        const processed = [];
        for (const alarm of alarms) {
            if (this.map[alarm['code']] !== undefined) {
                const mapItem = this.map[alarm['code']];
                alarm['code'] = mapItem['nf_code'];
                alarm['category'] = mapItem['nf_category'];
                alarm['description'] = mapItem['nf_description'];

                // dart returns strings for current, and integers for historical, bleh
                if (this.severities[alarm['severity']] !== undefined) {
                    alarm['severity'] = this.severities[alarm['severity']];
                }

                processed.push(alarm);
            }
        }

        return processed;
    }
}
