import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeatureActionButtonModule } from '@netfoundry-ui/feature/action-button';
import { UiNoitemsModule } from '@netfoundry-ui/ui/noitems';

import { SharedApiv2Module } from '@netfoundry-ui/shared/apiv2';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { ClickOutsideModule } from 'ng-click-outside';
import { FeatureDataTableModule } from '@netfoundry-ui/feature/data-table';
import { FormsModule } from '@angular/forms';
import { FeatureJsonViewModule } from '@netfoundry-ui/feature/json-view';
import { EdgeRouterPoliciesComponent } from './edge-router-policies.component';

@NgModule({
    imports: [
        CommonModule,
        ClickOutsideModule,
        FeatureActionButtonModule,
        UiNoitemsModule,
        SharedApiv2Module,
        MatDialogModule,
        SharedModelModule,
        UiLoaderModule,
        FeatureDataTableModule,
        FormsModule,
        FeatureJsonViewModule,
    ],
    declarations: [EdgeRouterPoliciesComponent],
    exports: [EdgeRouterPoliciesComponent],
})
export class FeatureZitiEdgeRouterPoliciesModule {}
