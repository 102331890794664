import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { catchError, delay } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';

import _ from 'lodash';
import { HTTP_CLIENT, TokenService } from './token.service';
import { LoggerService } from './logger.service';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class OrganizationPreferencesService {
    apiUrl;
    preferences;
    preferenceId;
    organization;
    user;

    constructor(
        private logger: LoggerService,
        private apiService: ApiService,
        private tokenService: TokenService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.apiUrl = _.get(environment, 'identityConfig.url');
        this.apiService.currentTenant.subscribe((organization) => {
            this.organization = organization;
        });
        this.apiService.currentUser.subscribe((user) => {
            this.user = user;
        });
    }

    public getPreferences() {
        if (!this.user) {
            return Promise.resolve();
        }
        return this.get('organization-preferences/self')
            .toPromise()
            .then((result: any) => {
                this.preferenceId = result.id;
                this.preferences = result.preferences;
                this.apiService.setCurrentOrgPreferences(this.preferences);
                const tenantId = localStorage.getItem('tenantId');
                localStorage.setItem(
                    'enableDeleteProtection_' + tenantId,
                    this.preferences.enableDeleteProtection ? 'on' : 'off'
                );
                return this.preferences;
            })
            .catch((result: any) => {
                this.preferences = {};
                this.apiService.setCurrentOrgPreferences(this.preferences);
                this.logger.error('Unable to retrieve user preferences');
            });
    }

    createOrgPreference(organizationId) {
        const fullpath = `${this.apiUrl}organization-preferences`;
        const headers = this.setHeaders();
        const body = {
            organizationId,
            preferences: { enableDeleteProtection: false },
        };
        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            if (!body.organizationId) {
                const orgId = _.get(this.organization, 'id', '');
                body.organizationId = orgId;
                return this.http
                    .post(fullpath, JSON.stringify(body), { headers: headers })
                    .pipe(catchError((error) => this.handleError(this, error)));
            } else {
                return this.http
                    .post(fullpath, JSON.stringify(body), { headers: headers })
                    .pipe(catchError((error) => this.handleError(this, error)));
            }
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    public setOrganizationPreference(key, value) {
        _.set(this.preferences, key, value);
        return this.set(this.preferenceId, this.preferences)
            .toPromise()
            .then((result: any) => {
                this.preferences = result.preferences;
                this.apiService.setCurrentOrgPreferences(this.preferences);
                return this.preferences;
            });
    }

    /**
     * Get item by ID
     * Allowed Types:
     *    identity-permission
     *    user-identities
     *    invitations
     *    tenants
     *    operations
     */
    public get(type: string) {
        const fullpath = `${this.apiUrl}${type}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Get item by ID
     * Allowed Types:
     *    identity-permission
     *    user-identities
     *    invitations
     *    tenants
     *    operations
     */
    public set(id: string, preferences: any) {
        this.preferenceId = id;
        let fullpath = `${this.apiUrl}organization-preferences/${id}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();
        const body = {
            preferences: preferences,
        };
        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            if (!body) {
                fullpath = `${this.apiUrl}organization-preferences/${id}`;
                return this.http
                    .put(fullpath, JSON.stringify(body), { headers: headers })
                    .pipe(catchError((error) => this.handleError(this, error)));
            } else {
                return this.http
                    .patch(fullpath, JSON.stringify(body), { headers: headers })
                    .pipe(catchError((error) => this.handleError(this, error)));
            }
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: any, error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            scope.logger.error('Feature Service error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            scope.logger.error(`Feature Backend returned code ${error.status}`, error.error);
        }
        scope.lastErrorSource.next(error);
        return observableThrowError(error);
    }

    /**
     * Return headers
     */
    private setHeaders(): HttpHeaders | null {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (tokenResponse.expired) {
            // if the token is expired, return null
            headers = null;
        } else if (tokenResponse.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        if (headers != null) {
            return headers.append('Content-Type', 'application/json');
        } else {
            return headers;
        }
    }
}
