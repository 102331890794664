import {Component, Inject, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Subscription } from 'rxjs';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { NetworkUtilizationTemplateQueryParameter } from '@netfoundry-ui/shared/model';
import { TEMPLATE_SEARCH_SERVICE, TemplateSearchService } from "@netfoundry-ui/shared/apiv2";
import {isEmpty, isNil} from "lodash";

@Component({
    selector: 'app-utilization-totals-box',
    templateUrl: './utilization-totals-box.component.html',
    styleUrls: ['./utilization-totals-box.component.scss'],
})
export class UtilizationTotalsBoxComponent implements OnInit, OnChanges, OnDestroy {
    @Input() sourceId: string;
    @Input() networkId: any = null;
    @Input() organizationId: any = null;
    @Input() dateFilter: any = '24h';
    @Input() newStartTime: any;
    @Input() newEndTime: any;
    @Input() height = '300px';
    @Input() aggregateField = 'usage_type';
    @Input() sumAggregateField: string;
    @Input() label = 'Utilization Total';
    chart: Chart;
    colors = ['#0273fb', '#08dc5a', '#FF0D49', '#1aadce', '#6d00f2', '#ffc000', '#ff7e00', '#ca0000', '#00aeb0'];
    noData = true;
    isLoading = false;
    initialized = false;
    currentOrgId;
    interval = '1m';
    items = [];
    @Input() filterField = null;
    @Input() filterValue = null;
    @Input() endTime = Date.now();
    @Input() startTime = this.endTime - 24 * 60 * 60 * 1000;
    totalTx = 0;
    totalRx = 0;
    private subscription = new Subscription();

    constructor(
      private elasticsearch: ElasticsearchService,
      public filterService: TableFilterService,
      @Inject(TEMPLATE_SEARCH_SERVICE) public templateService: TemplateSearchService
  ) {}

    ngOnInit() {
        this.initialized = true;
        this.generateTopItemsData();
        this.subscription.add(
            this.filterService.setDateFilterEvent.subscribe(() => {
                this.newStartTime = this.filterService.getStartTime();
                this.newEndTime = this.filterService.getEndTime();
                this.generateTopItemsData();
            })
        );
    }

    ngOnChanges() {
        this.generateTopItemsData();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public getTopItems() {
        const index = 'ncutilization';
        const items = [];

        this.noData = true;
        this.isLoading = true;

        //reporting
        let componentName: any = '';
        let componentValue: any = '';
        // this will allow us to filter by endpoint, edge router, or service
        if (this.filterField != null && this.filterValue != null) {
            componentName = this.filterField;
            componentValue = this.filterValue;
        }

        const networkUtilizationTemplateQueryParameter: NetworkUtilizationTemplateQueryParameter =
          this.templateService.getUtilizationTemplateQuery(
            this.newStartTime.valueOf(),
            this.newEndTime.valueOf(),
            this.dateFilter,
            componentName,
            componentValue,
            [],
            this.networkId,
            this.organizationId,
            index,
            this.aggregateField,
            '10'
          );

        this.subscription.add(
            //this.elasticsearch.search(this.organizationId, index, this.getQuery())
            this.elasticsearch
                .apiTemplateSearch(
                    this.templateService.UTILIZATION_SUM_BY_COMPONENT_TEMPLATE,
                    networkUtilizationTemplateQueryParameter
                )
                .subscribe((data) => {
                  const result = this.templateService.getUsageTotalSumByComponentData(data);
                  if (isEmpty(result) || isNil(result)) {
                    this.isLoading = false;
                    this.noData = true;
                    return;
                  }
                  this.isLoading = false;
                  this.noData = false;
                  this.totalTx = result.totalTx;
                  this.totalRx = result.totalRx;
                  this.items = items;
                })
        );
    }

    public getQuery() {
        const model: any = {
            aggs: {
                items: {
                    terms: {
                        field: this.aggregateField,
                        size: 10,
                        order: {
                            item_sum: 'desc',
                        },
                    },
                    aggs: {
                        item_sum: {
                            sum: {
                                field: 'usage',
                            },
                        },
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            match_phrase: {
                                'network_id.keyword': {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                'organizationId.keyword': {
                                    query: this.organizationId,
                                },
                            },
                        },
                        {
                            // filter down to two usage types since these are the items that we will calculate billing on
                            bool: {
                                should: [
                                    {
                                        match_phrase: {
                                            // traffic delivered through the fabric to service's destination, renders as TX
                                            'usage_type.keyword': 'usage.ingress.tx',
                                        },
                                    },
                                    {
                                        match_phrase: {
                                            // traffic received back through the fabric to the initiator, renders as RX
                                            'usage_type.keyword': 'usage.egress.tx',
                                        },
                                    },
                                ],
                                minimum_should_match: 1,
                            },
                        },
                    ],
                },
            },
        };
        model.query.bool.must.push({
            range: {
                '@timestamp': {
                    gte: this.newStartTime.valueOf(),
                    lte: this.newEndTime.valueOf(),
                    format: 'epoch_millis',
                },
            },
        });

        // this will allow us to filter by endpoint, edge router, or service
        if (this.filterField != null && this.filterValue != null) {
            const search = { match_phrase: {} };
            search.match_phrase[this.filterField] = { query: this.filterValue };
            model.query.bool.must.push(search);
        }

        return model;
    }

    private generateTopItemsData() {
        if (this.initialized && this.networkId !== null && this.networkId && this.organizationId !== null) {
            this.getTopItems();
        }
    }
}
