import { Injectable, Inject } from '@angular/core';
import { Config, ENVIRONMENT, Environment } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import { HateoasResourceOperation, PagedGetOption, PagedResourceCollection } from '@lagoshny/ngx-hateoas-client';
import { tap } from 'rxjs/operators';
import { isEmpty, isNumber, toLower, toString } from 'lodash';

@Injectable({ providedIn: 'root' })
export class ConfigService extends HateoasResourceOperation<Config> {
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;

    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(Config);
    }
    getResource(): Observable<Config> {
        throw new Error('Do not use: see get getEndpoint');
    }

    getPage(): Observable<PagedResourceCollection<Config>> {
        throw new Error('Do not use: see getEndpoints');
    }

    public getConfigPage(options: PagedGetOption): Promise<Config[]> {
        const params = { ...ConfigService.defaultHttpAccept, ...options };
        return super
            .getPage(params)
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((configs) => configs.resources);
    }

    public validateUpdate(configId: string, model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .patch(this.environment.v2apiUrl + 'configs/' + configId, model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public async validateCreate(model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .post(this.environment.v2apiUrl + 'configs', model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public downloadFileFormat(
        networkId: string,
        format = 'text/csv',
        params: HttpParams = new HttpParams()
    ): Observable<any> {
        params = params.set('networkId', networkId);
        const headers = new HttpHeaders().set('Accept', format);
        return this.http.get(this.environment.v2apiUrl + 'configs', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public getJson(configId: string): Observable<string> {
        const options: any = {};
        const url = `${this.environment.v2apiUrl}configs/${configId}/?meta=ziti,diffZiti`;
        return this.http.get(url, options) as Observable<any>;
    }

    public addressesFormatter(row): string {
        let addresses = '';
        if (!isEmpty(row.data?.addressAndPortIndex)) {
            const addressItems = row.data.addressAndPortIndex.ingress
                ? row.data.addressAndPortIndex.ingress
                : row.data.addressAndPortIndex.egress;
            let from;
            let to;
            addressItems.forEach((val, index) => {
                from = val.fromIpAddress ? val.fromIpAddress : toLower(val.address);
                to = val.toIpAddress ? val.fromIpAddress : toLower(val.address);
                if (addresses === from || addresses === to) {
                    return;
                }
                if (index > 0) {
                    addresses += ', ';
                }
                if (from === to) {
                    addresses += `${from}`;
                } else {
                    addresses += `${from}--${to}`;
                }
            });
        } else if (!isEmpty(row.data?.data?.allowedAddresses)) {
            addresses = toString(row.data?.data?.allowedAddresses);
        } else if (!isEmpty(row.data?.data?.addresses)) {
            addresses = toString(row.data?.data?.addresses);
        } else if (!isEmpty(row.data?.data?.address)) {
            addresses = row.data?.data?.address;
        }
        return addresses;
    }

    public portsFormatter(row): string {
        let ports = '';
        if (!isEmpty(row.data?.addressAndPortIndex)) {
            const portItems = row.data.addressAndPortIndex.ingress
                ? row.data.addressAndPortIndex.ingress
                : row.data.addressAndPortIndex.egress;
            let from;
            let to;
            portItems?.forEach((val, index) => {
                from = val.fromPort;
                to = val.toPort;
                if (from === ports || to === ports) {
                    return;
                }
                if (index > 0) {
                    ports += ', ';
                }
                if (from === to) {
                    ports = from;
                } else {
                    ports += `${from}--${to}`;
                }
            });
        } else if (!isEmpty(row.data?.data?.portRanges)) {
            let from;
            let to;
            row.data?.data?.portRanges.forEach((val, index) => {
                from = val.low;
                to = val.high;
                ports += `${from}--${to}`;
                if (index < row.data?.data?.portRanges.length - 1) {
                    ports += ', ';
                }
            });
        } else if (!isEmpty(row.data?.data?.ports)) {
            ports = toString(row.data?.data?.ports);
        } else if (isNumber(row.data?.data?.port)) {
            ports = row.data?.data?.port + '';
        }
        return ports;
    }
}
