import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class ZitiEnabledService implements OnDestroy {
    zitiEnabled = false;
    zitiPure = false;
    private subscription: Subscription = new Subscription();

    constructor(private apiService: ApiService) {
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((result) => {
                this.zitiEnabled = result.productFamily != null && result.productFamily.toLowerCase().includes('ziti');

                this.zitiPure = result.productFamily != null && result.productFamily.toLowerCase() === 'ziti';
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
