import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NetworkController, NetworkV2 } from '@netfoundry-ui/shared/model';

@Component({
    selector: 'app-network-resize-modal',
    templateUrl: './network-resize-modal.component.html',
    styleUrls: ['./network-resize-modal.component.scss'],
})
export class NetworkResizeModalComponent {
    title = 'Network Host Resize';
    icon = '';
    action = 'Resize Network';
    cancelAction = 'Oops, no get me out of here';

    network: NetworkV2;
    size = '';
    sizes: string[] = [];
    isEnabled = false;

    constructor(
        private dialogRef: MatDialogRef<NetworkResizeModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.network = data.network;
        this.sizes = data.sizes;
        this.size = this.network.size;
    }

    optionSelected(size: string) {
        this.size = size;
        this.isEnabled = !!size;
    }

    cancel() {
        this.dialogRef.close();
    }

    resize() {
        const options = {
            size: this.size,
            network: this.network,
        };
        this.dialogRef.close(options);
    }
}
