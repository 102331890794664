import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { AUTHORIZATION_CONFIGURATION, AuthorizationConfig } from './auth.service';
import { AwsSplashScreenComponent } from './aws-splash-screen/aws-splash-screen.component';
import { LoginIdentityProviderSelectorComponent } from './login-identity-provider-selector/login-identity-provider-selector.component';

@NgModule({
    imports: [CommonModule, HttpClientModule, FormsModule, MatDialogModule, SharedModelModule, RouterModule],
    declarations: [LoginIdentityProviderSelectorComponent, AwsSplashScreenComponent],
    exports: [LoginIdentityProviderSelectorComponent, AwsSplashScreenComponent],
    providers: [],
})
export class SharedAuthorizationModule {
    static forRoot(config: AuthorizationConfig): ModuleWithProviders<SharedAuthorizationModule> {
        return {
            ngModule: SharedAuthorizationModule,
            providers: [{ provide: AUTHORIZATION_CONFIGURATION, useValue: config }],
        };
    }
}
