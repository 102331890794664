<div class="modal solid open">
    <div (click)="close()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="title">Add Public Certificate</div>
    <div class="subtitle">Are you sure you want to add a public certificate to this Network?</div>
    <div class="icon upgradenetwork"></div>

    <div class="row">
        <div style="padding-top: 60px">
            <p-message
                severity="warn"
                text="Your network will be offline for a few minutes while the new certificate is installed. Press 'Add Now' when you are ready to proceed."
            ></p-message>
        </div>
    </div>
    <div class="buttons">
        <div (click)="close()" class="linkButton" id="MiniCloseButton">Oops, no get me out of here</div>
        <button (click)="addPublicCert()" class="save" id="CreateButton">Add Now</button>
    </div>
</div>
