<div class="fullModal opened">
    <div class="title" style="filter: brightness(50%)">Create A New Service</div>
    <div class="subtitle">Choose a Service type</div>
    <div class="svc-card-container">
        <app-v7-card
            (addEvent)="chooseSimpleServiceForm($event)"
            image="/assets/svgs/Simple.svg"
            subtitle="Create a basic SDK, Endpoint, or Edge Router Hosted Service. Works with older versions of Endpoint Software."
            title="Simple Service"
        ></app-v7-card>
        <app-v7-card
            (addEvent)="chooseAdvancedServiceForm($event)"
            image="/assets/svgs/Advanced.svg"
            subtitle="Advanced Services allow more configuration options and settings than a simple service."
            title="Advanced Service"
        ></app-v7-card>
        <app-v7-card
            (addEvent)="chooseAdvancedConfigurationForm($event)"
            *ngIf="featureService.powerUserEnabled"
            image="/assets/svgs/configs.svg"
            subtitle="This service allows custom configurations. Select from pre-defined configs and/or add new ones."
            title="Advanced Configuration"
        ></app-v7-card>
    </div>
    <div (click)="hide()" class="buttonBall close" id="EscZitiAWClose">
        <div class="buttonLabel">ESC</div>
    </div>
</div>
