import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    ApiService,
    FeatureService,
    LoggerService,
    MenuService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { cloneDeep, debounce, delay, get, isEmpty, set, toString } from 'lodash';
import * as $ from 'jquery';

import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { MatDialog } from '@angular/material/dialog';
import { takeWhile } from 'rxjs/operators';
import { IframeModalComponent } from '@netfoundry-ui/shared/iframe-modal';
import { AppWanV2, EndpointV2, NetworkGroup, PlatformService } from '@netfoundry-ui/shared/model';
import { EnrollmentInstructionsComponent } from '../enrollment-instructions/enrollment-instructions.component';
import { SampleServiceService } from './sample-service.service';

declare const gtag;

const EnjoyHint = get(window, 'EnjoyHint', (p: any) => {});

@Component({
    selector: 'app-sample-service',
    templateUrl: './sample-service.component.html',
    styleUrls: ['./sample-service.component.scss'],
})
export class SampleServiceComponent implements OnInit {
    currentRootStep = 0;
    currentNetwork: any = {};
    endpoint: any = {};
    isLoading = false;
    disableTestButton = true;
    disableTutorial = true;
    autoFabric = [];
    enterKeyStep = '';
    contextOpen = false;
    view = 'sample-service-steps';
    showNextSection = false;

    enjoy_hint_options = {
        showSkip: false,
        showNext: true,
        showPrev: true,
        onEnd: () => {
            this.tutorialClosed();
        },
        keyCode: '',
        top: 100,
        onSkip: () => {
            this.tutorialClosed();
        },
    };

    endpointsLabel = 'Endpoints';
    appwansLabel = 'AppWANs';
    endpointsNavSelector = '.ziti-endpoints-nav';
    servicesNavSelector = '.ziti-services-nav';
    appWansNavSelector = '.ziti-appwans-nav';

    stepProcessing = false;
    enjoyhint_instance = new EnjoyHint(this.enjoy_hint_options);
    currentStep: any = {};
    tutorialEndpoint: any = new EndpointV2();
    tutorialService: any = new PlatformService();
    tutorialAppWAN: any = new AppWanV2();
    doJwtDownload = true;
    currentStepName = '';
    newServiceName: any = '';
    latestEndpoint = {};
    latestService = {};
    appWanEndpointAttrVal: any = '';
    appWanServiceAttrVal;
    dialogRef;
    currentOrg = new NetworkGroup({});
    steps = [
        {
            id: 0,
            name: 'Create an Endpoint',
            stepStart: 1,
            route: '/getting-started/sample-service',
            icon: '/assets/images/AppName.svg',
            menuIcon: 'icon-Icon_CL',
            label: 'nf-create-endpoint-tutorial',
            preHints: {
                'new-endpoint-nav': (viaControls) => {
                    this.tutorialEndpoint = new EndpointV2();
                    this.apiService.setTutorialEndpoint(this.tutorialEndpoint);
                    this.doJwtDownload = true;
                    if (this.router.url?.indexOf('sample-service') < 0) {
                        this.router.navigate(['/getting-started/sample-service']);
                    }
                },
                'new-endpoint-add': (viaControls) => {
                    $('#EscZEndpointClose').click();
                    if (this.router.url?.indexOf('v7/endpoints') < 0) {
                        this.router.navigate(['/v7/endpoints']);
                    }
                },
                'new-endpoint-name': (viaControls = false) => {
                    if (viaControls) {
                        this.apiService.openNewEndpointDialog.emit({});
                    }
                },
                'save-new-endpoint': (viaControls = false) => {
                    if (!this.tutorialEndpoint.attributes || this.tutorialEndpoint.attributes.length < 1) {
                        this.tutorialEndpoint.attributes = ['#demo'];
                    }
                    this.tutorialEndpoint.attributes.push('#nf_tutorial');
                    this.apiService.setTutorialEndpoint(this.tutorialEndpoint);
                },
                'exit-new-endpoint': (viaControls = false) => {
                    if (this.doJwtDownload) {
                        this.doJwtDownload = false;
                        $('.downloadJwtButton').click();
                    }
                },
            },
            hints: [
                {
                    event: 'tutorial-controls',
                    selector: '.sample-service-container .off-screen',
                    //arrowColor: 'transparent',
                    margin: 0,
                    description:
                        '<h2>Before We Start</h2><p>Use the forward and back buttons to navigate through the tutorial steps. <p> You can also press the <text style="color: #00a6eb">Enter</text> key at any time to automatically proceed to the next step.</p>',
                    onBeforeStart: () => {
                        delay(() => {
                            //$('#enjoyhint_arrpw_line').hide();
                        }, 50);
                    },
                },
                {
                    event: 'tutorial-controls-2',
                    selector: '.sample-service-container .off-screen-2',
                    //arrowColor: 'transparent',
                    margin: 0,
                    description:
                        '<p>To quit the tutorial, click the red <text style="color: var(--red)">X</text> in the top right corner at any time.</p>',
                    onBeforeStart: () => {
                        delay(() => {
                            //$('#enjoyhint_arrpw_line').hide();
                        }, 50);
                    },
                },
                {
                    event: 'new-endpoint-nav',
                    selector: this.endpointsNavSelector,
                    description: `Select <text style="color: #00a6eb">${this.endpointsLabel}</text> from the navigation menu.`,
                    onBeforeStart: () => {
                        this.menuService.setArea('Network');
                        $(this.endpointsNavSelector).off('click');
                        $(this.endpointsNavSelector).click((event) => {
                            delay(() => {
                                this.nextStep();
                            }, 1000);
                        });
                    },
                },
                {
                    event: 'new-endpoint-add',
                    selector: '.buttonAdd',
                    description: 'Click the <text style="color: #00a6eb">Add</text> button in the top right corner.',
                    onBeforeStart: () => {
                        $('.buttonAdd').off('click');
                        $('.buttonAdd').click((event) => {
                            delay(() => {
                                this.nextStep();
                            }, 1000);
                        });
                    },
                },
                {
                    event: 'new-endpoint-name',
                    selector: '.ep-name-input',
                    description:
                        'Give your new ' +
                        (this.featureService?.disableNewNavigation ? 'endpoint' : 'identity') +
                        ' a name. Names must be at least 5 characters and can NOT contain special characters. <br>For this example lets use the name <text style="color: #00a6eb">Demo-' +
                        (this.featureService?.disableNewNavigation ? 'Endpoint' : 'Identity') +
                        '</text>',
                    onBeforeStart: () => {
                        const nameChanged = debounce(
                            (event) => {
                                $('.ep-name-input').off('keyup');
                                const newName: any = $('#EditZRouterName').val();
                                if (newName?.length > 5) {
                                    //this.nextStep();
                                    this.tutorialEndpoint.name = newName;
                                } else if (event.keyCode === 13 && newName?.length <= 0) {
                                    this.tutorialEndpoint.name = 'Demo-Endpoint';
                                    this.apiService.setTutorialEndpoint(this.tutorialEndpoint);
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('.ep-name-input').off('keyup');
                        $('.ep-name-input').keyup(nameChanged.bind(this));
                    },
                },
                {
                    event: 'new-endpoint-attributes',
                    selector: '.ep-attributes-row',
                    description:
                        'You can use the attributes field to group ' + this.featureService?.disableNewNavigation
                            ? 'endpoints'
                            : 'identities' +
                              ' together. <p>This field is optional, but lets use the name <text style="color: #00a6eb">demo</text> for this example. <p>When you\'re done typing, hit the <text style="color: #00a6eb">Enter</text> key</p>',
                    onBeforeStart: () => {
                        $('.ep-attributes-row input').focus();
                        this.currentStepName = 'new-endpoint-attributes';
                        const epName: any = $('#EditZRouterName').val();
                        if (!epName || epName?.length <= 0) {
                            this.tutorialEndpoint.name = this.featureService?.disableNewNavigation
                                ? 'Demo-Endpoint'
                                : 'Demo-Identity';
                            this.apiService.setTutorialEndpoint(this.tutorialEndpoint);
                        }
                        const attrChanged = debounce(
                            (event) => {
                                const val: any = $('.ep-attributes-row input').val();
                                if (event.keyCode === 13 && val?.length <= 0) {
                                    this.tutorialEndpoint.attributes = ['#demo'];
                                    this.apiService.setTutorialEndpoint(this.tutorialEndpoint);
                                    $('.fullRow').click();
                                    $('.ep-attributes-row input').off('keyup');
                                } else if (event.keyCode === 13) {
                                    //this.nextStep();
                                    this.tutorialEndpoint.attributes = ['#' + $('.ep-attributes-row input').val()];
                                    this.apiService.setTutorialEndpoint(this.tutorialEndpoint);
                                    $('.ep-attributes-row input').off('keyup');
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('.ep-attributes-row input').off('keyup');
                        $('.ep-attributes-row input').keyup(attrChanged.bind(this));
                    },
                },
                {
                    event: 'save-new-endpoint',
                    selector: '#SaveZitiEndpointButton',
                    description:
                        'When you\'re done editing, click the <text style="color: #00a6eb">Create</text> button or hit <text style="color: #00a6eb">Enter</text>',
                    onBeforeStart: () => {
                        $('#ZitiEndpointAddModal').click();
                    },
                },
                {
                    event: 'download-new-jwt',
                    selector: '.downloadJwtButton',
                    description:
                        'Click the <text style="color: #00a6eb">Download Key</text> button to download your enrolment token',
                    onBeforeStart: () => {
                        this.currentStepName = 'download-new-jwt';
                        $('.downloadJwtButton').off('click');
                        $('.downloadJwtButton').click(() => {
                            if (!this.doJwtDownload) {
                                return;
                            }
                            delay(() => {
                                this.doJwtDownload = false;
                                this.nextStep();
                            }, 500);
                        });
                    },
                },
                {
                    event: 'exit-new-endpoint',
                    selector: '#EscZEndpointClose',
                    description:
                        "Once you've downloaded and enrolled your new identity. Click the close button to continue.",
                    onBeforeStart: () => {
                        this.currentStepName = 'exit-new-endpoint';
                        $('#EscZEndpointClose').off('click');
                        $('#EscZEndpointClose').click(() => {
                            delay(() => {
                                this.exitEnjoyHint();
                                this.apiService.tutorialActive = false;
                                const data = {
                                    title: 'Step #1 Complete',
                                    appendId: 'SampleService',
                                    subtitle: `You've successfully created your first ${
                                        this.featureService?.disableNewNavigation ? 'Endpoint' : 'Identity'
                                    }! We'll use this ${
                                        this.featureService?.disableNewNavigation ? 'endpoint' : 'identity'
                                    } to connect to services that we define for your network. Click the continue button to see instructions on how to enroll this ${
                                        this.featureService?.disableNewNavigation ? 'endpoint' : 'identity'
                                    } using a Ziti tunneler.`,
                                    bulletList: [],
                                    icon: 'Confirm',
                                    action: 'Continue',
                                };
                                this.dialogRef = this.dialogForm.open(ConfirmComponent, {
                                    data: data,
                                    height: '300px',
                                    width: '600px',
                                    autoFocus: false,
                                });
                                this.dialogRef.afterClosed().subscribe((result) => {
                                    localStorage.setItem(
                                        this.currentOrg?.name + '_' + this.currentStep?.label + '_complete',
                                        'true'
                                    );
                                    this.openStep(this.steps[1]);
                                });
                            }, 200);
                        });
                    },
                },
            ],
        },
        {
            id: 1,
            name: 'Enroll an Endpoint',
            stepStart: 1,
            route: '/create',
            icon: '/assets/images/AppName.svg',
            menuIcon: 'icon-endpoints',
            preHints: {},
            label: 'nf-enroll-endpoint-tutorial',
            hints: [
                {
                    event: 'new-endpoint-enrollment',
                    selector: '#EscZEndpointClose',
                    description:
                        "Once you've downloaded and enrolled your new identity. Click the close button to continue.",
                    onBeforeStart: () => {
                        this.currentStepName = 'new-endpoint-enrollment';
                        delay(() => {
                            this.exitEnjoyHint();
                            this.apiService.tutorialActive = false;
                            this.dialogRef = this.dialogForm.open(EnrollmentInstructionsComponent, {
                                data: {},
                                //minHeight: '100%',
                                //minWidth: '100%',
                                height: '630px',
                                width: '1300px',
                            });
                            this.dialogRef.afterClosed().subscribe((result) => {
                                localStorage.setItem(
                                    this.currentOrg?.name + '_' + this.currentStep?.label + '_complete',
                                    'true'
                                );
                                this.openStep(this.steps[2]);
                            });
                        }, 200);
                    },
                },
            ],
        },
        {
            id: 2,
            name: 'Create a Service',
            stepStart: 1,
            route: '/create',
            icon: '/assets/images/AppName.svg',
            menuIcon: 'icon-services',
            label: 'nf-create-service-tutorial',
            preHints: {
                'new-service-nav': (viaControls) => {},
                'new-service-add': (viaControls) => {
                    $('#EscZEndpointClose').click();
                    if (this.router.url?.indexOf('v7/connections/services') < 0) {
                        this.router.navigate(['/v7/connections/services']);
                    }
                    this.tutorialService = new PlatformService();
                    this.tutorialService.model = {
                        edgeRouterHosts: [
                            {
                                edgeRouterId: '',
                                serverEgress: {},
                            },
                        ],
                        clientIngress: {},
                    };
                    this.tutorialService.modelType = 'TunnelerToEdgeRouter';
                    this.apiService.setTutorialService(this.tutorialService);
                },
                'new-simple-service': (viaControls) => {
                    if (viaControls) {
                        this.apiService.openNewServiceDialog.emit({});
                    }
                },
                'new-service-name': (viaControls) => {
                    if (viaControls) {
                        $('#SelectButtonSimpleService').off('click');
                        $('#SelectButtonSimpleService').click();
                    }
                },
                'new-service-attributes': (viaControls) => {
                    if (isEmpty(this.tutorialService.name)) {
                        this.tutorialService.name = 'Demo-Service';
                        this.apiService.setTutorialService(this.tutorialService);
                    }
                },
                'new-service-host': (viaControls) => {
                    if (isEmpty(this.tutorialService.attributes)) {
                        this.tutorialService.attributes = ['#demo'];
                        this.apiService.setTutorialService(this.tutorialService);
                    }
                },
                'new-service-port': (viaControls) => {
                    const host = get(this.tutorialService, 'model.clientIngress.host');
                    if (isEmpty(host)) {
                        set(this.tutorialService, 'model.clientIngress.host', 'demo.tools.netfoundry.io');
                    }
                    this.apiService.setTutorialService(this.tutorialService);
                },
                'new-service-router': (viaControls) => {
                    const port = get(this.tutorialService, 'model.clientIngress.port');
                    if (isEmpty(port)) {
                        set(this.tutorialService, 'model.clientIngress.port', '443');
                    }
                    this.apiService.setTutorialService(this.tutorialService);
                },
                'new-service-router-proto': (viaControls) => {
                    const edgeRouterIds = $('#ConfigRouters option');
                    const erId = $(edgeRouterIds.get(1)).val();
                    this.tutorialService.model.edgeRouterHosts = [{ edgeRouterId: erId }];
                    this.apiService.setTutorialService(this.tutorialService);
                },
                'new-service-router-host': (viaControls) => {
                    const proto = get(this.tutorialService, 'model.edgeRouterHosts[0].serverEgress.protocol');
                    if (isEmpty(proto)) {
                        set(this.tutorialService, 'model.edgeRouterHosts[0].serverEgress.protocol', 'tcp');
                    }
                    this.apiService.setTutorialService(this.tutorialService);
                },
                'new-service-router-port': (viaControls) => {
                    const host = get(this.tutorialService, 'model.edgeRouterHosts[0].serverEgress.host');
                    if (isEmpty(host)) {
                        set(
                            this.tutorialService,
                            'model.edgeRouterHosts[0].serverEgress.host',
                            'splash.tools.netfoundry.io'
                        );
                    }
                    this.apiService.setTutorialService(this.tutorialService);
                },
                'save-service-router': (viaControls) => {
                    const port = get(this.tutorialService, 'model.edgeRouterHosts[0].serverEgress.port');
                    if (isEmpty(port)) {
                        set(this.tutorialService, 'model.edgeRouterHosts[0].serverEgress.port', '443');
                    }
                    this.tutorialService?.attributes?.push('#nf_tutorial');
                    this.apiService.setTutorialService(this.tutorialService);
                },
            },
            hints: [
                {
                    event: 'new-service-nav',
                    selector: this.servicesNavSelector,
                    description: 'Select <text style="color: #00a6eb">Services</text> from the navigation menu.',
                    onBeforeStart: () => {
                        this.menuService.setArea('Network');
                        $(this.servicesNavSelector).off('click');
                        $(this.servicesNavSelector).click((event) => {
                            delay(() => {
                                this.nextStep();
                            }, 1000);
                        });
                    },
                },
                {
                    event: 'new-service-add',
                    selector: '.buttonAdd',
                    description: 'Click the <text style="color: #00a6eb">Add</text> button in the top right corner.',
                    onBeforeStart: () => {
                        $('.buttonAdd').off('click');
                        $('.buttonAdd').click((event) => {
                            delay(() => {
                                this.nextStep();
                            }, 500);
                        });
                    },
                },
                {
                    event: 'new-simple-service',
                    selector: '#SelectButtonSimpleService',
                    description: 'Select the <text style="color: #00a6eb">Simple Service</text> option from the list',
                    onBeforeStart: () => {
                        $('#SelectButtonSimpleService').off('click');
                        $('#SelectButtonSimpleService').click((event) => {
                            delay(() => {
                                if (this.enterKeyStep === 'new-simple-service') {
                                    this.enterKeyStep = '';
                                    return;
                                }
                                this.nextStep();
                            }, 500);
                        });
                    },
                },
                {
                    event: 'new-service-name',
                    selector: '#EditName',
                    description:
                        'Give your new service a name. Names must be at least 5 characters and can NOT contain special characters. <p>For this example lets use the name <text style="color: #00a6eb">Demo-Service</text></p>',
                    onBeforeStart: () => {
                        const nameChanged = debounce(
                            (event) => {
                                const newName: any = $('#EditName').val();
                                this.newServiceName = newName;
                                if (newName?.length > 5) {
                                    //this.nextStep();
                                    this.tutorialService.name = newName;
                                } else if (event.keyCode === 13 && newName?.length <= 0) {
                                    this.tutorialService.name = 'Demo-Service';
                                    this.apiService.setTutorialService(this.tutorialService);
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('#EditName').off('keyup');
                        $('#EditName').keyup(nameChanged.bind(this));
                    },
                },
                {
                    event: 'new-service-attributes',
                    selector: '.platform-serivce-attributes',
                    description:
                        'You can use the attributes field to group services together. <br>This field is optional, but lets use the name <text style="color: #00a6eb">demo</text> for this example.',
                    onBeforeStart: () => {
                        $('.platform-serivce-attributes input').focus();
                        this.currentStepName = 'new-service-attributes';
                        const attrChanged = debounce(
                            (event) => {
                                const val: any = $('.platform-serivce-attributes input').val();
                                if (event.keyCode === 13 && val?.length <= 0) {
                                    this.tutorialService.attributes = ['#demo'];
                                    this.apiService.setTutorialService(this.tutorialService);
                                    $('.fullRow').click();
                                    $('.platform-serivce-attributes input').off('keyup');
                                } else if (event.keyCode === 13) {
                                    //this.nextStep();
                                    this.tutorialService.attributes = [
                                        '#' + $('.platform-serivce-attributes input').val(),
                                    ];
                                    this.apiService.setTutorialService(this.tutorialService);
                                    $('.platform-serivce-attributes input').off('keyup');
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('.platform-serivce-attributes').off('keyup');
                        $('.platform-serivce-attributes').keyup(attrChanged.bind(this));
                    },
                },
                {
                    event: 'new-service-host',
                    selector: '#ConfigServiceClientHostName',
                    description:
                        'Enter a valid hostname or IP address to use for this service. <p>This will be a private address that only authenticated NetFoundry endpoints can access.</p> For this demo we\'ll use <text style="color: #00a6eb">demo.tools.netfoundry.io</text>',
                    onBeforeStart: () => {
                        $('#ConfigServiceClientHostName').focus();
                        $('#ConfigServiceClientHostName').val('demo.tools.netfoundry.io');
                        const hostChanged = debounce(
                            (event) => {
                                const newHost = $('#ConfigServiceClientHostName').val();
                                set(this.tutorialService, 'model.clientIngress.host', newHost);
                                if (event.keyCode !== 13 && this.validateService.isValidHostName(newHost)) {
                                    //this.nextStep();
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('#ConfigServiceClientHostName').off('keyup');
                        $('#ConfigServiceClientHostName').keyup(hostChanged.bind(this));
                    },
                },
                {
                    event: 'new-service-port',
                    selector: '#ConfigServiceClientPort',
                    description:
                        'Next, enter the port you\'d like to use for this host. <p>We recommend <text style="color: #00a6eb">443</text> for the purpose of this demo.</p>',
                    onBeforeStart: () => {
                        $('#ConfigServiceClientPort').focus();
                        $('#ConfigServiceClientPort').val('443');
                        const portChanged = debounce(
                            (event) => {
                                const newPort = $('#ConfigServiceClientPort').val();
                                set(this.tutorialService, 'model.clientIngress.port', newPort);
                                if (event.keyCode !== 13 && this.validateService.isValidPort(newPort)) {
                                    //this.nextStep();
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('#ConfigServiceClientPort').off('keyup');
                        $('#ConfigServiceClientPort').keyup(portChanged.bind(this));
                    },
                },
                {
                    event: 'new-service-router',
                    selector: '#ConfigRouters',
                    description:
                        "Next select an edge router to host this service. We've automatically created one for you as part of your default network fabric.",
                    onBeforeStart: () => {
                        //$('#ConfigRouters').val('443');
                        const nameChanged = debounce(
                            () => {
                                const newName: any = $('#ConfigRouters').val();
                                if (newName?.length > 5) {
                                    this.nextStep();
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('#ConfigRouters').off('change');
                        $('#ConfigRouters').change(nameChanged.bind(this));
                    },
                },
                {
                    event: 'new-service-router-proto',
                    selector: '#ConfigRouterProtocol',
                    description:
                        'Then select a protocol. <p>For this demo use the <text style="color: #00a6eb">TCP</text> protocol</p>',
                    onBeforeStart: () => {
                        //$('#ConfigRouterProtocol').focus();
                        const protoChanged = debounce(
                            () => {
                                const newProto = $('#ConfigRouterProtocol').val();
                                set(this.tutorialService, 'model.edgeRouterHosts[0].serverEgress.protocol', newProto);
                                this.nextStep();
                            },
                            1000,
                            { trailing: true }
                        );
                        $('#ConfigRouterProtocol').off('change');
                        $('#ConfigRouterProtocol').change(protoChanged.bind(this));
                    },
                },
                {
                    event: 'new-service-router-host',
                    selector: '#ConfigRouterHostName',
                    description:
                        'Then enter the address of the service that this router will host. <p>For this demo lets use the host we\'ve created for you @ <text style="color: #00a6eb">splash.tools.netfoundry.io</text></p>',
                    onBeforeStart: () => {
                        $('#ConfigRouterHostName').focus();
                        $('#ConfigRouterHostName').val('splash.tools.netfoundry.io');
                        const hostChanged = debounce(
                            (event) => {
                                const newHost = $('#ConfigRouterHostName').val();
                                set(this.tutorialService, 'model.edgeRouterHosts[0].serverEgress.host', newHost);
                                if (event.keyCode !== 13 && this.validateService.isValidHostName(newHost)) {
                                    set(this.tutorialService, 'model.edgeRouterHosts[0].serverEgress.host', newHost);
                                    //this.nextStep();
                                }
                            },
                            2000,
                            { trailing: true }
                        );
                        $('#ConfigRouterHostName').off('keyup');
                        $('#ConfigRouterHostName').keyup(hostChanged.bind(this));
                    },
                },
                {
                    event: 'new-service-router-port',
                    selector: '#ConfigRouterPort',
                    description:
                        'Finally, enter the port you\'d like to with . <p>For this demo we\'ll use port <text style="color: #00a6eb">443</text></p>',
                    onBeforeStart: () => {
                        $('#ConfigRouterPort').focus();
                        $('#ConfigRouterPort').val('443');
                        const portChanged = debounce(
                            (event) => {
                                const newPort = $('#ConfigRouterPort').val();
                                set(this.tutorialService, 'model.edgeRouterHosts[0].serverEgress.port', newPort);
                                if (event.keyCode !== 13 && this.validateService.isValidPort(newPort)) {
                                    //this.nextStep();
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('#ConfigRouterPort').off('keyup');
                        $('#ConfigRouterPort').keyup(portChanged.bind(this));
                    },
                },
                {
                    event: 'save-service-router',
                    selector: '#SaveServiceZitiButton',
                    description:
                        'When you\'re done click <text style="color: #00a6eb">Create</text> or hit <text style="color: #00a6eb">Enter</text>',
                    onBeforeStart: () => {},
                },
                {
                    event: 'exit-new-service',
                    selector: '#EscZEndpointClose',
                    description: '',
                    onBeforeStart: () => {
                        delay(() => {
                            this.exitEnjoyHint();
                            this.apiService.tutorialActive = false;
                            const data = {
                                title: 'Step #2 Complete',
                                appendId: 'SampleService',
                                subtitle:
                                    "You've successfully created your first Service! Lastly, we'll show you how to use this service in an AppWAN so that it can be accessed by the endpoint you created in step #1.",
                                bulletList: [],
                                icon: 'Confirm',
                                action: 'Continue',
                            };
                            this.dialogRef = this.dialogForm.open(ConfirmComponent, {
                                data: data,
                                height: '300px',
                                width: '600px',
                                autoFocus: false,
                            });
                            this.dialogRef.afterClosed().subscribe((result) => {
                                localStorage.setItem(
                                    this.currentOrg?.name + '_' + this.currentStep?.label + '_complete',
                                    'true'
                                );
                                this.openStep(this.steps[3]);
                            });
                        }, 50);
                    },
                },
            ],
        },
        {
            id: 3,
            name: 'Create an AppWAN',
            stepStart: 1,
            route: '/create',
            icon: '/assets/images/AppName.svg',
            menuIcon: 'icon-appwans',
            label: 'nf-create-appwan-tutorial',
            preHints: {
                'new-appwan-nav': (viaControls) => {
                    $('#EscZitiServiceClose').click();
                },
                'new-appwan-add': (viaControls) => {
                    if (this.router.url?.indexOf('v7/connections/appwans') < 0) {
                        this.router.navigate(['/v7/connections/appwans']);
                    }
                    this.tutorialAppWAN = new AppWanV2();
                    this.tutorialAppWAN.endpointAttributes = [];
                    this.tutorialAppWAN.serviceAttributes = [];
                    this.apiService.setTutorialAppWAN(this.tutorialAppWAN);
                },
                'new-appwan-name': (viaControls) => {
                    if (viaControls) {
                        this.apiService.openNewAppWANDialog.emit({});
                    }
                },
                'new-appwan-service-attributes': (viaControls) => {
                    if (isEmpty(this.tutorialAppWAN.name)) {
                        this.tutorialAppWAN.name = 'Demo-AppWAN';
                        this.apiService.setTutorialAppWAN(this.tutorialAppWAN);
                    }
                },
                'new-appwan-service-attributes-2': (viaControls) => {
                    if (isEmpty(this.tutorialAppWAN.serviceAttributes) && isEmpty(this.appWanServiceAttrVal)) {
                        const attributes = isEmpty(this.tutorialService.attributes)
                            ? ['#demo']
                            : this.tutorialService.attributes;
                        this.tutorialAppWAN.serviceAttributes = attributes;
                        this.apiService.setTutorialAppWAN(this.tutorialAppWAN);
                        delay(() => {
                            this.nextStep(true, false);
                        }, 500);
                    }
                },
                'new-appwan-endpoint-attributes-2': (viaControls) => {
                    if (isEmpty(this.tutorialAppWAN.endpointAttributes) && isEmpty(this.appWanEndpointAttrVal)) {
                        const attributes = isEmpty(this.tutorialEndpoint.attributes)
                            ? ['#demo']
                            : this.tutorialEndpoint.attributes;
                        this.tutorialAppWAN.endpointAttributes = attributes;
                        this.tutorialAppWAN?.endpointAttributes?.push('#nf_tutorial');
                        this.apiService.setTutorialAppWAN(this.tutorialAppWAN);
                        delay(() => {
                            this.nextStep(true, false);
                        }, 500);
                    }
                },
            },
            hints: [
                {
                    event: 'new-appwan-nav',
                    selector: this.appWansNavSelector,
                    description: `Select <text style="color: #00a6eb">${this.appwansLabel}</text> from the navigation menu.`,
                    onBeforeStart: () => {
                        this.menuService.setArea('Network');
                        $(this.appWansNavSelector).off('click');
                        $(this.appWansNavSelector).click((event) => {
                            delay(() => {
                                this.nextStep();
                            }, 500);
                        });
                    },
                },
                {
                    event: 'new-appwan-add',
                    selector: '.buttonAdd',
                    description: 'Click the <text style="color: #00a6eb">Add</text> button in the top right corner.',
                    onBeforeStart: () => {
                        $('.buttonAdd').off('click');
                        $('.buttonAdd').click((event) => {
                            delay(() => {
                                this.nextStep();
                            }, 500);
                        });
                    },
                },
                {
                    event: 'new-appwan-name',
                    selector: '#EditName',
                    description:
                        'Give your new AppWAN a name. Names must be at least 5 characters and can NOT contain special characters. <p>For this example lets use the name <text style="color: #00a6eb">Demo-AppWAN</text></p>',
                    onBeforeStart: () => {
                        const nameChanged = debounce(
                            (event) => {
                                const newName: any = $('#EditName').val();
                                if (newName?.length > 5) {
                                    //this.nextStep();
                                    this.tutorialAppWAN.name = newName;
                                } else if (event.keyCode === 13 && newName?.length <= 0) {
                                    this.tutorialAppWAN.name = 'Demo-AppWAN';
                                    this.apiService.setTutorialAppWAN(this.tutorialAppWAN);
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('#EditName').off('keyup');
                        $('#EditName').keyup(nameChanged.bind(this));
                    },
                },
                {
                    event: 'new-appwan-service-attributes',
                    selector: '.row.service-attributes',
                    description: `Use the tag selector to find the service you created in the previous step. <br> Begin typing the name of the attribute used for your service: <text style="color: #00a6eb">demo</text>`,
                    onBeforeStart: () => {
                        this.currentStepName = 'new-appwan-service-attributes';
                        $('.row.service-attributes input').focus();
                        const attrChanged = debounce(
                            (event) => {
                                this.appWanServiceAttrVal = $('.row.service-attributes input').val();
                                if (!isEmpty(this.appWanServiceAttrVal)) {
                                    $('.row.service-attributes input').off('keyup');
                                    this.nextStep();
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('.row.service-attributes input').off('keyup');
                        $('.row.service-attributes input').keyup(attrChanged.bind(this));
                    },
                },
                {
                    event: 'new-appwan-service-attributes-2',
                    selector: '.row.service-attributes',
                    description:
                        'Existing attributes will show as selectable options in the dropdown under the filter. <p>Select the <text style="color: #00a6eb">demo</text> attribute to continue.</p>',
                    margin: 100,
                    onBeforeStart: () => {
                        this.currentStepName = 'new-appwan-service-attributes';
                        const selected = (event) => {
                            $('.row.service-attributes .select2-results__option.ng-star-inserted').off('click');
                            this.nextStep();
                        };
                        $('.row.service-attributes input').off('click');
                        $('.row.service-attributes .select2-results__option.ng-star-inserted').click(
                            selected.bind(this)
                        );
                    },
                },
                {
                    event: 'new-appwan-service-preview',
                    selector: '#ServicesPreview',
                    description:
                        'You should then see the service you created <br> populate in the "Services Preview" list',
                    onBeforeStart: () => {
                        $('#ZitiAppWANAddModal').click();
                        $('.row.service-attributes input').blur();
                        this.currentStepName = 'new-appwan-service-preview';
                        const nameChanged = debounce(
                            (event) => {
                                if (event.keyCode === 13) {
                                    //this.nextStep();
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('.ep-attributes-row input').off('keyup');
                        $('.ep-attributes-row input').keyup(nameChanged.bind(this));
                    },
                },
                {
                    event: 'new-appwan-endpoint-attributes',
                    selector: '.row.endpoint-attributes',
                    description: `Then use this tag selector to find the endpoint you created in the first step.<br> Begin typing the name of the attribute used for your service: <text style="color: #00a6eb">demo</text>`,
                    onBeforeStart: () => {
                        this.currentStepName = 'new-appwan-endpoint-attributes';
                        $('.row.endpoint-attributes input').focus();
                        const nameChanged = debounce(
                            (event) => {
                                this.appWanEndpointAttrVal = $('.row.endpoint-attributes input').val();
                                if (!isEmpty(this.appWanEndpointAttrVal)) {
                                    $('.row.endpoint-attributes input').off('keyup');
                                    this.nextStep();
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('.row.endpoint-attributes input').off('keyup');
                        $('.row.endpoint-attributes input').keyup(nameChanged.bind(this));
                    },
                },
                {
                    event: 'new-appwan-endpoint-attributes-2',
                    selector: '.row.endpoint-attributes',
                    description:
                        'Existing attributes will show as selectable options in the dropdown under the filter. <p>Click the <text style="color: #00a6eb">demo</text> attribute to continue.</p>',
                    margin: 100,
                    onBeforeStart: () => {
                        this.apiService.setTutorialAppWAN(this.tutorialAppWAN);
                        this.currentStepName = 'new-appwan-endpoint-attributes-2';
                        const selected = (event) => {
                            $('.row.endpoint-attributes .select2-results__option.ng-star-inserted').off('click');
                            this.nextStep();
                        };
                        $('.row.endpoint-attributes input').off('click');
                        $('.row.endpoint-attributes .select2-results__option.ng-star-inserted').click(
                            selected.bind(this)
                        );
                    },
                },
                {
                    event: 'new-appwan--preview',
                    selector: '#EndpointsPreview',
                    description:
                        'You should then see the endpoint you created populate in the "Endpoints Preview" list',
                    onBeforeStart: () => {
                        this.apiService.setTutorialAppWAN(this.tutorialAppWAN);
                        $('#ZitiAppWANAddModal').click();
                        $('.row.endpoint-attributes input').blur();
                        this.currentStepName = 'new-appwan-service-attributes';
                        const nameChanged = debounce(
                            (event) => {
                                if (event.keyCode === 13) {
                                    //this.nextStep();
                                }
                            },
                            1000,
                            { trailing: true }
                        );
                        $('.ep-attributes-row input').off('keyup');
                        $('.ep-attributes-row input').keyup(nameChanged.bind(this));
                    },
                },
                {
                    event: 'new-appwan-save',
                    selector: '#SaveAppWANZitiButton',
                    description:
                        'When you\'re done, click the <text style="color: #00a6eb">Create</text> button or hit <text style="color: #00a6eb">Enter</text>',
                    onBeforeStart: () => {
                        $('#SaveAppWANZitiButton').off('click');
                        $('#SaveAppWANZitiButton').click(() => {
                            let doSub = true;
                            this.apiService.latestAppWAN.pipe(takeWhile((svc) => doSub)).subscribe((svc) => {
                                doSub = false;
                                this.exitEnjoyHint();
                                this.apiService.tutorialActive = false;
                                const data = {
                                    title: 'Step #3 Complete',
                                    appendId: 'SampleService',
                                    subtitle: `You've successfully created your first AppWAN! You should now be able to access the private hostname that was defined in the service you created. Click continue to open a new tab and access your new service @ https://${get(
                                        this.tutorialService,
                                        'model.clientIngress.host',
                                        'demo.tools.netfoundry.io'
                                    )}`,
                                    bulletList: [],
                                    icon: 'Confirm',
                                    action: 'Continue',
                                };
                                this.dialogRef = this.dialogForm.open(ConfirmComponent, {
                                    data: data,
                                    height: '300px',
                                    width: '600px',
                                    autoFocus: false,
                                });
                                this.dialogRef.afterClosed().subscribe((result) => {
                                    localStorage.setItem(
                                        this.currentOrg?.name + '_' + this.currentStep?.label + '_complete',
                                        'true'
                                    );
                                    this.openStep(this.steps[4]);
                                });
                            });
                        });
                    },
                },
            ],
        },
        {
            id: 4,
            name: 'Test Service',
            stepStart: 1,
            route: '/getting-started/sample-service',
            icon: '/assets/images/AppName.svg',
            menuIcon: 'icon-appwans',
            label: 'nf-test-service-tutorial',
            preHints: {},
            hints: [
                {
                    event: 'test-service-iframe',
                    selector: 'body',
                    description: '',
                    onBeforeStart: () => {
                        this.openIframe();
                        localStorage.setItem(`testServiceOpened_${this.currentOrg?.name}`, 'true');
                    },
                },
            ],
        },
    ];
    showNextButton = false;

    constructor(
        public sampleServiceService: SampleServiceService,
        private router: Router,
        public apiService: ApiService,
        public featureService: FeatureService,
        public menuService: MenuService,
        public dialogForm: MatDialog,
        public logger: LoggerService,
        private validateService: ValidateService
    ) {}

    ngOnInit() {
        this.sampleServiceService.isLoading.subscribe((isLoading) => {
            this.isLoading = isLoading;
        });
        this.apiService.currentOrg.subscribe((data) => {
            this.currentOrg = data;
            this.disableTutorial = localStorage.getItem(`testServiceOpened_${this.currentOrg?.name}`) !== 'true';
            this.showNextButton = localStorage.getItem(`zt-in-minutes-complete_${this.currentOrg?.name}`) === 'true';
        });
        this.apiService.currentAutoFabric.subscribe((autoFabric) => {
            this.autoFabric = autoFabric;
            if (this.autoFabric?.length > 0) {
                this.autoFabric.forEach((router) => {
                    if (router.status === 'PROVISIONED') {
                        this.disableTestButton = false;
                    }
                });
            }
        });
        this.apiService.latestEndpoint.subscribe((ep) => {
            this.latestEndpoint = ep;
            if (ep?.name.length > 0 && this.tutorialEndpoint.name === ep.name) {
                delay(() => {
                    this.nextStep();
                }, 500);
            }
        });
        this.apiService.latestService.subscribe((svc) => {
            this.latestService = svc;
            if (svc?.name.length > 0 && this.tutorialService.name === svc.name) {
                delay(() => {
                    this.nextStep();
                }, 500);
            }
        });
        this.featureService.disableNewNavChanged.subscribe((disabled: boolean) => {
            this.updateNavHints(disabled);
        });
        this.updateNavHints(this.featureService.disableNewNavigation);
    }

    updateNavHints(newNavDisabled) {
        if (newNavDisabled) {
            this.endpointsNavSelector = '.ziti-endpoints-nav';
            this.servicesNavSelector = '.ziti-services-nav';
            this.appWansNavSelector = '.ziti-appwans-nav';
            this.endpointsLabel = 'Endpoints';
            this.appwansLabel = 'AppWANs';
        } else {
            this.endpointsNavSelector = '.ziti-identities-nav-item';
            this.servicesNavSelector = '.ziti-services-nav-item';
            this.appWansNavSelector = '.ziti-policies-nav-item';
            this.endpointsLabel = 'Identities';
            this.appwansLabel = 'Policies';
            this.steps[0].name = 'Create an Identity';
            this.steps[1].name = 'Enroll an Identity';
            this.steps[3].hints[8].description =
                'You should then see the identity you created populate in the "Identities Preview" list';
        }
        this.steps[0].hints[2].selector = this.endpointsNavSelector;
        this.steps[2].hints[0].selector = this.servicesNavSelector;
        this.steps[3].hints[0].selector = this.appWansNavSelector;
        this.steps[0].hints[2].description = `Select <text style="color: #00a6eb">${this.endpointsLabel}</text> from the navigation menu.`;
        this.steps[3].hints[0].description = `Select <text style="color: #00a6eb">${this.appwansLabel}</text> from the navigation menu.`;
    }

    handleTutorialTimingMetrics(step) {
        const timestamp = Date.now();
        const tutorialStart = localStorage.getItem('nf_new_signup_tutorial_start');
        if (isEmpty(tutorialStart)) {
            localStorage.setItem('nf_new_signup_tutorial_start', tutorialStart);
            return;
        }

        if (step?.id !== this.steps[this.steps.length - 1].id) {
            return;
        }
        const startTime = Number.parseInt(tutorialStart, 10);
        const totalTime = (timestamp - startTime) / 1000;
        if (gtag)
            gtag('event', 'click', {
                event_category: 'signup-tutorial-time',
                event_label: totalTime,
            });
    }

    goBack() {
        if (this.view === 'tutorials') {
            this.view = 'sample-service-steps';
            return;
        }
        this.router.navigate(['getting-started']);
    }

    doNext() {
        this.showNextSection = true;
        if (this.view === 'sample-service-steps') {
            this.view = 'tutorials';
        }
    }

    async openStep(selectedStep) {
        if (selectedStep.id === 0) {
            await this.sampleServiceService.cleanupSampleService(this.steps);
            this.tutorialService = this.sampleServiceService.getTutorialService();
        }
        if (this.disableTutorial) {
            return;
        }
        if (selectedStep?.id === 0) {
            this.tutorialEndpoint = new EndpointV2();
            this.apiService.setTutorialEndpoint(this.tutorialEndpoint);
            if (isEmpty(this.tutorialService)) {
                this.tutorialService = new PlatformService();
            }
            this.apiService.setTutorialService(this.tutorialService);
            this.tutorialAppWAN = new AppWanV2();
            this.apiService.setTutorialAppWAN(this.tutorialAppWAN);
        } else if (selectedStep?.id > 0) {
            const prevStep = this.steps[selectedStep.id - 1];
            localStorage.setItem(this.currentOrg?.name + '_' + prevStep.label + '_complete', 'true');
        }
        this.currentRootStep = selectedStep.id;
        if (this.enjoyhint_instance?.trigger && this.enjoyhint_instance.getCurrentStep() > 0) {
            this.exitEnjoyHint();
        }
        this.updateNavHints(this.featureService.disableNewNavigation);
        this.enjoyhint_instance = new EnjoyHint(this.enjoy_hint_options);
        set(window, 'enjoyhint_instance', this.enjoyhint_instance);
        this.currentStep = cloneDeep(selectedStep);
        if (selectedStep.id === 2) {
            this.updateAppWANHints();
        }
        this.enjoyhint_instance.set(this.currentStep.hints);
        this.enjoyhint_instance.run();
        this.apiService.setTutorialSteps(this.steps);
        this.apiService.setTutorialStep(this.currentStep);
        this.apiService.tutorialActive = true;
        this.handleTutorialTimingMetrics(this.currentStep);
        delay(() => {
            const continueTutorialDebounced = debounce(this.continueTutorial.bind(this), 1000, { leading: true });
            $(document).off('keyup');
            $(document).keyup((event) => {
                if (event.keyCode === 13 || event.keyCode === 9) {
                    const currentHintNum = this.enjoyhint_instance.getCurrentStep();
                    const currentHint = this.steps[this.currentRootStep].hints[currentHintNum];
                    this.enterKeyStep = currentHint.event;
                    continueTutorialDebounced(currentHint);
                }
            });
            $(document).off('keydown');
            $(document).keydown((event) => {
                if (event.keyCode === 9) {
                    $(event.target).focus();
                    event.preventDefault();
                }
            });
            this.addTourGuideControls(selectedStep.id);
        }, 200);
    }

    continueTutorial(currentHint) {
        delay(() => {
            this.nextStep(true);
        }, 1000);
    }

    exitEnjoyHint() {
        try {
            this.enjoyhint_instance?.trigger('skip');
        } catch (e) {
            this.logger.error('Error exiting enjoyhint');
            this.logger.error(e);
        }
    }

    updateAppWANHints() {
        this.currentStep.hints.forEach((hint) => {
            if (hint.event === 'new-appwan-service-attributes') {
                const attr = this.tutorialService?.attributes?.length > 0 ? this.tutorialService.attributes[0] : 'demo';
                hint.description = `Use the tag selector to find the service you created in the previous step.
                              <br> Begin typing the name of the attribute used for your service: <text style="color: #00a6eb">${attr}</text>`;
            } else if (hint.event === 'new-appwan-endpoint-attributes') {
                const attr =
                    this.tutorialEndpoint?.attributes?.length > 0 ? this.tutorialEndpoint.attributes[0] : 'demo';
                hint.description = `Use the tag selector to find the endpoint you created in the previous step.
                              <br> Begin typing the name of the attribute used for your endpoint: <text style="color: #00a6eb">${attr}</text>`;
            }
        });
    }

    nextStep(viaControls = false, skipStep = false) {
        if (this.stepProcessing) {
            return;
        }
        this.stepProcessing = true;
        const stepIncrement = skipStep ? 1 : 0;
        const currentHintNum = this.enjoyhint_instance.getCurrentStep();
        const currentHint = this.steps[this.currentRootStep].hints[currentHintNum];
        const nextHintNum = currentHintNum + 1 + stepIncrement;
        const nextHint = this.steps[this.currentRootStep].hints[nextHintNum];

        if (viaControls && currentHint?.event) {
            let exit = false;
            switch (currentHint.event) {
                case 'save-new-endpoint':
                    $('#SaveZitiEndpointButton').click();
                    exit = true;
                    break;
                case 'save-service-router':
                    $('#SaveServiceZitiButton').click();
                    exit = true;
                    break;
                case 'new-appwan-save':
                    $('#SaveAppWANZitiButton').click();
                    exit = true;
                    break;
                case 'new-appwan-service-attributes':
                    //exit = true;
                    break;
                case 'new-appwan-endpoint-attributes-2':
                    //exit = true;
                    break;
                case 'exit-new-endpoint':
                    $('#EscZEndpointClose').click();
                    break;
                default:
                    break;
            }
            if (exit) {
                this.stepProcessing = false;
                return;
            }
        }

        if (
            nextHint?.event &&
            this.steps[this.currentRootStep].preHints &&
            this.steps[this.currentRootStep].preHints[nextHint.event]
        ) {
            try {
                this.steps[this.currentRootStep].preHints[nextHint.event](viaControls);
            } catch (e) {
                this.logger.error('Error invoking pre-hint function: ' + e.message);
            }
        }
        delay(() => {
            this.enjoyhint_instance.trigger('next');
            if (skipStep) {
                this.enjoyhint_instance.trigger('next');
            }
            this.stepProcessing = false;
        }, 100);
    }

    prevStep() {
        const prevHintNum = this.enjoyhint_instance.getCurrentStep() - 1;
        const nextHint = this.steps[this.currentRootStep].hints[prevHintNum];
        if (this.steps[this.currentRootStep].preHints[nextHint.event]) {
            try {
                this.steps[this.currentRootStep].preHints[nextHint.event]();
            } catch (e) {
                this.logger.error('Error invoking pre-hint function: ' + e.message);
            }
        }
        delay(() => {
            $('.enjoyhint_prev_btn').click();
        }, 100);
    }

    openContextMenu() {
        if (this.contextOpen) {
            $('.context-menu').removeClass('context-menu-open');
            delay(() => {
                $('.context-menu-container').removeClass('context-menu-container-open');
            }, 300);
        } else {
            $('.context-menu-container').addClass('context-menu-container-open');
            delay(() => {
                $('.context-menu').addClass('context-menu-open');
            }, 10);
        }
        this.contextOpen = !this.contextOpen;
    }

    menuOptionClicked(event) {
        const stepId = $(event.currentTarget).data('stepId');
        this.openStep(this.steps[stepId]);
    }

    openIframe() {
        this.exitEnjoyHint();
        this.apiService.tutorialActive = false;
        const host = this.tutorialService?.model?.clientIngress?.host || 'demo.tools.netfoundry.io';
        const protocol = toString(this.tutorialService?.model?.clientIngress?.port) === '443' ? 'https' : 'http';
        const data = {
            url: `${protocol}://${host}/`,
        };
        this.dialogRef = this.dialogForm.open(IframeModalComponent, {
            data: data,
            height: '600px',
            width: '900px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            localStorage.setItem(`${this.currentOrg?.name}_nf-test-service-tutorial_complete`, 'true');
            const updatedStep = cloneDeep(this.currentStep);
            updatedStep.id = updatedStep.id + 1;
            this.apiService.setTutorialStep(updatedStep);
            this.addTourGuideControls(this.currentStep?.id);
            const data = {
                title: 'Congrats!',
                appendId: 'SampleService',
                subtitle: `You've completed the Quick Start tutorial! Click "Done" to return to the "Getting Started" page and see our other help links and documentation.`,
                bulletList: [],
                icon: 'Confirm',
                action: 'Done',
            };
            this.dialogRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '300px',
                width: '600px',
                autoFocus: false,
            });
            this.dialogRef.afterClosed().subscribe((result) => {
                this.router.navigate(['/getting-started']);
                this.tutorialClosed();
                this.exitEnjoyHint();
            });
        });
    }

    openSampleService() {
        if (this.disableTestButton) {
            return;
        }

        this.disableTutorial = false;
        localStorage.setItem(`testServiceOpened_${this.currentOrg?.name}`, 'true');

        const data = {
            url: `https://sample.tools.netfoundry.io`,
            isSample: true,
        };
        this.dialogRef = this.dialogForm.open(IframeModalComponent, {
            data: data,
            height: '600px',
            width: '900px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            this.menuService.setArea('Network');
            this.disableTutorial = false;
            if (result.success) {
                localStorage.setItem(`zt-in-minutes-complete_${this.currentOrg?.name}`, 'true');
                this.showNextButton = true;
            }
        });
    }

    addTourGuideControls(stepNum) {
        let contextMenuOptions = '';
        let stepIndex = 0;
        let lastCompleteIndex;
        this.steps.forEach((step) => {
            const stepComplete =
                localStorage.getItem(this.currentOrg?.name + '_' + step.label + '_complete') === 'true';
            if (stepComplete) {
                lastCompleteIndex = stepIndex;
            }
            const completeStyle = stepComplete ? 'step-complete' : '';
            const stepActiveStyle = stepNum === stepIndex ? 'active' : '';
            const stepEnabledStyle = stepIndex <= lastCompleteIndex + 1 ? 'enabled' : '';
            const firstStepStyle = stepIndex === 0 ? 'first-step' : '';
            contextMenuOptions += `<div class="context-menu-option ${completeStyle} ${stepActiveStyle} ${stepEnabledStyle} ${firstStepStyle}" data-step-id="${step.id}">
                    <div class="image ${step.menuIcon}" style="width: 30px;margin-left: 5px;padding-right: 0;"></div>
                    <div class="check-complete"></div>
                    <span>${step.name}</span>
                </div>`;
            stepIndex++;
        });
        //const contextDisplay = this.contextOpen ? 'flex' : 'none';
        const menuStyle = this.contextOpen ? 'context-menu-open' : '';
        const tourGuideControl = `
          <div class="tour-guide-controls">
              <div class="context-menu-container">
                  <div class="context-menu ${menuStyle}">
                      <div class="tour-guide-slide-up">
                          <div class="tour-guide-slide-up-image"></div>
                      </div>
                      ${contextMenuOptions}
                      <div class="context-menu-option bottom-placeholder"></div>
                  </div>
              </div>
              <div class="tour-guide-control tour-guide-back-button">
                  <div class="tour-button-image"></div>
              </div>
              <div class="tour-guide-slide-up">
                  <div class="tour-guide-slide-up-image"></div>
              </div>
              <div class="tour-guide-slide-up-overlay">
              </div>
              <div class="tour-guide-middle">
                  <div class="tour-button-image"></div>
              </div>
              <div class="tour-guide-control tour-guide-forward-button">
                  <div class="tour-button-image"></div>
              </div>
          </div>
        `;
        $('.tour-guide-controls').remove();
        $('body>div.enjoyhint').append(tourGuideControl);
        delay(() => {
            $('.tour-guide-back-button').click(this.prevStep.bind(this, true));
            $('.tour-guide-forward-button').click(this.nextStep.bind(this, true, false));
            $('.tour-guide-middle').click(this.openContextMenu.bind(this, true));
            $('.context-menu-option').click(this.menuOptionClicked.bind(this));
            $('.tour-guide-slide-up').click(this.openContextMenu.bind(this, true));
        }, 100);
    }

    tutorialClosed() {
        this.apiService.tutorialActive = false;
    }
}
