import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HostService, NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Environment, ENVIRONMENT, NetworkSyncV2, NetworkV2 } from '@netfoundry-ui/shared/model';
import { ApiService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';
import semver from 'semver';

@Component({
    selector: 'app-network-support',
    templateUrl: './network-support.component.html',
    styleUrls: ['./network-support.component.scss'],
})
export class NetworkSupportComponent implements OnInit, OnDestroy {
    networkModel: NetworkV2 = new NetworkV2();
    item = {};

    majorVersionNumber;
    versionNumber;
    currentNetwork;
    zitiCredentials: any = {};
    subscription: Subscription = new Subscription();
    zitiurl: string;
    ziticli: string;
    sessionToken: string;
    loginPort: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<NetworkSupportComponent>,
        private apiService: ApiService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private hostService: HostService,
        private growlerService: GrowlerService,
        @Inject(ENVIRONMENT) public environment: Environment
    ) {
        this.networkModel = data.network;
        this.majorVersionNumber = apiService.getNetworkVersion(this.networkModel);
        this.versionNumber = this.networkModel.productVersion;
    }

    ngOnInit(): void {
        const hostId = this.networkModel.networkController.hostId;
        this.hostService.getHost(hostId).then((result) => {
            this.item = {
                type: 'Network Controller',
                ips: [result['ipAddress']],
                port: '80/443/6262',
            };
            this.zitiurl = `https://${this.item['ips']}/edge/v1/authenticate?method=password`;
        });
        this.subscription.add(
            this.networkServiceV2
                .getNetworkControllerSecrets(this.networkModel.networkController.id)
                .subscribe((result) => {
                    this.zitiCredentials = result ?? {};
                    // this.ziticli = `/opt/netfoundry/ziti/ziti-controller/ziti edge login 127.0.0.1:8443/edge/management/v1 -u ${this.zitiCredentials.zitiUserId} -p ${this.zitiCredentials.zitiPassword} -c /opt/netfoundry/ziti/ziti-controller/certs/ca.external/controller01.external.chain.cert.pem`;
                })
        );
        this.subscription.add(
            this.networkServiceV2
                .getNetworkControllerInfo(this.networkModel.networkController.id)
                .subscribe((result) => {
                    this.loginPort = result.host[0].port;
                    this.subscription.add(
                        this.networkServiceV2
                            .getNetworkControllerSession(this.networkModel.networkController.id)
                            .subscribe((result) => {
                                this.sessionToken = result.sessionToken;
                                if (semver.lt(this.versionNumber, '7.3.90')) {
                                    this.ziticli = `/opt/netfoundry/ziti/ziti-controller/ziti edge login 127.0.0.1:8443/edge/management/v1 -t ${this.sessionToken} -c /opt/netfoundry/ziti/ziti-controller/certs/ca.external/controller01.external.chain.cert.pem`;
                                } else {
                                    this.ziticli = `/opt/netfoundry/ziti/ziti-controller/ziti edge login 127.0.0.1:8443/edge/management/v1 -t ${this.sessionToken} --ca /opt/netfoundry/ziti/ziti-controller/certs/ca.external/controller01.external.chain.cert.pem`;
                                }
                            })
                    );
                })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    close() {
        this.dialogRef.close();
    }

    syncNetwork(syncNetwork: NetworkV2, toZiti: boolean) {
        console.log('Resyncing network ', syncNetwork);
        const networkSyncCommand = new NetworkSyncV2();
        networkSyncCommand.networkId = syncNetwork.getId();
        networkSyncCommand.toZiti = toZiti;
        const networkSyncCommandList = [];
        networkSyncCommandList.push(networkSyncCommand);
        this.networkServiceV2.syncNetworks(syncNetwork, networkSyncCommandList);
        this.dialogRef.close();
    }

    copy(elemId) {
        const elem = document.getElementById(elemId);
        const text = elem.innerText;

        this.copyTextToClipboard(text);
        this.growlerService.show(
            new GrowlerData('success', 'Success', 'Copied to clipboard', 'Content has been copied to your clipboard')
        );
    }

    copyTextToClipboard(text) {
        const txtArea = document.createElement('textarea');

        txtArea.style.position = 'fixed';
        txtArea.style.top = '0';
        txtArea.style.left = '0';
        txtArea.style.opacity = '0';
        txtArea.value = text;
        document.body.appendChild(txtArea);
        txtArea.select();
        try {
            const successful = document.execCommand('copy');
            if (successful) {
                return true;
            }
        } catch (err) {
            console.log('unable to copy');
        }
        document.body.removeChild(txtArea);
        return false;
    }
}
