import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT, Network, NetworkV2 } from '@netfoundry-ui/shared/model';
import { Subscription } from 'rxjs';
import { HTTP_CLIENT } from '..';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class NetworkVersionService {
    currentNetwork: Network;
    subscription: Subscription = new Subscription();
    currentNetworkVersion = 4;

    constructor(
        private apiService: ApiService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.subscription.add(
            apiService?.currentNetwork?.subscribe((network) => {
                this.currentNetwork = new Network(network);
                if (this.currentNetwork.id != null) {
                    this.currentNetworkVersion = this.getNetworkVersion(this.currentNetwork);
                }
            })
        );
    }

    getNetworkVersion(network: Network) {
        const splitVersionNumber = network.productVersion.split('.');
        return parseInt(splitVersionNumber[0], 10);
    }

    getNetworkMinorVersion(network: Network) {
        const splitVersionNumber = network.productVersion.split('.');
        return parseInt(splitVersionNumber[1], 10);
    }

    getNetworkPatchVersion(network: Network) {
        const splitVersionNumber = network.productVersion.split('.');
        return parseInt(splitVersionNumber[2], 10);
    }

    getDownloadsLink() {
        if (this.currentNetworkVersion < 5) {
            return this.environment.downloadsURL + '4';
        } else {
            return this.environment.downloadsURL + this.currentNetworkVersion;
        }
    }

    getNetworkFeatures(network: Network | NetworkV2) {
        const headers = new HttpHeaders().set('Accept', 'application/hal+json');
        const params = new HttpParams();
        const version = network.productVersion;
        return this.http.get(this.environment.v2apiUrl + 'network-versions?networkVersion=' + version, {
            headers: headers,
            params: params,
        });
    }
}
