import { Resource } from '@lagoshny/ngx-hateoas-client';

export class GenericService extends Resource {
    id: string;
    networkId: string;
    name: string;
    zitiId: string;
    encryptionRequired = true;
    attributes: string[];
    modelType: string;
    model: any;
    configTypes: any[];
    configs: [];
    terminators: any;
    servicePolicy: any;
    authority: any;
    serviceEdgeRouterPolicy: any;
    configIdByConfigTypeId: any;
    ownerIdentityId: string;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    edgeRouterAttributes: string[];
    endpointAttributes: string[];
}
