import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
    ActiveDirectory,
    AzureActiveDirectory,
    AzureIntuneActiveDirectory,
    ClientSyncConfigurations,
    ClientSyncResource,
    ZitiActiveDirectory,
} from '@netfoundry-ui/shared/model';
import { ApiService, HTTP_CLIENT, LoggerService, TokenService } from '@netfoundry-ui/shared/services';
import { Observable, of as observableOf, Subject, Subscription, throwError as observableThrowError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';

export const CLIENT_SYNCS_CONFIGURATION = new InjectionToken<ClientSyncsConfig>('CLIENT_SYNCS_CONFIGURATION');

export interface ClientSyncsConfig {
    url: string;
}

@Injectable({ providedIn: 'root' })
export class ClientSyncsService {
    lastErrorSource = new Subject<HttpErrorResponse>();
    lastError = this.lastErrorSource.asObservable();
    private clientSyncSource = new Subject<ClientSyncResource[]>();
    clientSyncs = this.clientSyncSource.asObservable();
    private apiSub = new Subscription();
    private mapSub = new Subscription();
    private apiUrl;

    constructor(
        @Inject(HTTP_CLIENT) private http: HttpClient,
        private apiService: ApiService,
        public logger: LoggerService,
        private tokenService: TokenService,
        @Inject(CLIENT_SYNCS_CONFIGURATION) config?: ClientSyncsConfig
    ) {
        this.apiUrl = config.url;
    }

    /**
     * Generic PUTer
     */
    put(path: string, body: any = {}): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(this._path(path), JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic POSTer
     */
    post(path: string, body: any = {}): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(this._path(path), JSON.stringify(body), { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic GETter
     */
    get(path: string, ignore403?: boolean): Observable<any> {
        const fullpath = this._path(path);
        // this.logger.debug('GET to path: ' + fullpath);

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error, ignore403)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic DELETE
     */
    delete(path, body: any): Observable<any> {
        let options = {};

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            if (body) {
                options = {
                    headers: headers,
                    responseType: 'text',
                    body: JSON.stringify(body),
                };
            } else {
                options = {
                    headers: headers,
                    responseType: 'text',
                };
            }

            return this.http
                .delete(this._path(path), options)
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    getClientSyncs(clientSync?: ClientSyncResource): Observable<any> {
        if (this.apiSub) {
            this.apiSub.unsubscribe();
        }

        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }
        // watch for when the network updates, when it does, update the gateways observable
        this.apiSub = this.apiService.currentNetwork.subscribe((network) => {
            if (!network.getId()) {
                return;
            }
            let url = `networks/${network.getId()}/clientsyncs`;
            if (clientSync != null && clientSync.syncId != null) {
                url += `/${clientSync.syncId}`;
            }
            this.mapSub = this.get(url).subscribe((data) => {
                const syncs = [];
                for (const obj of data.syncs) {
                    const sync = this.convertClientSync(obj);
                    if (sync) {
                        syncs.push(sync);
                    }
                }
                this.clientSyncSource.next(syncs);
            });
        });

        // this is an observable that watches for network changes
        return this.clientSyncs;
    }

    getClientSyncConfigurations(clientSyncId): Observable<any> {
        const url = `networks/${this.apiService.theNetworkIs.getId()}/clientsyncs/${clientSyncId}/configurations`;
        return this.get(url, true).pipe(catchError(() => this.handleConfigurationsError()));
    }

    getClientSyncGroups(clientSyncId, groupName?, size?, beta?): Observable<any> {
        let url = `networks/${this.apiService.theNetworkIs.getId()}/clientsyncs/${clientSyncId}/groups`;
        if (groupName) {
            url += `?name=${groupName}`;
        }
        if (size) {
            if (!groupName) {
                url += `?size=${size}`;
            } else {
                url += `&size=${size}`;
            }
        }
        if (beta) {
            if (!groupName && !size) {
                url += `?beta=true`;
            } else {
                url += `&beta=true`;
            }
        }
        return this.get(url);
    }

    getSelectedClientSyncGroups(clientSyncId): Observable<any> {
        const url = `networks/${this.apiService.theNetworkIs.getId()}/clientsyncs/${clientSyncId}/selected-groups`;
        return this.get(url);
    }

    /**
     * Returns raw resource response from the API
     */
    getLinkedResource(model: any, resourceName: string) {
        if (model._links[resourceName] !== undefined && model._links[resourceName]['href'] !== undefined) {
            return this.get(model._links[resourceName]['href']);
        } else {
            this.logger.info('No resource link with name: ' + resourceName, model);
            // noinspection TypeScriptUnresolvedFunction
            return observableOf({});
        }
    }

    /**
     * Get the selflink for a related resource
     */
    public getLinkedResourceUrl(model, resourceName) {
        if (model._links[resourceName] !== undefined) {
            return model._links[resourceName]['href'];
        }

        this.logger.error('No resource name of ' + resourceName + ' exists for this model', model);
        return null;
    }

    /**
     * Saves the mop resource and returns result as observable
     */
    public saveClientSyncs(resourceModel) {
        if (resourceModel.syncId) {
            // edit
            const url = `${this._getCreateUrl()}/${resourceModel.syncId}`;
            return this.put(url, resourceModel.getSaveObject());
        } else {
            // add
            return this.post(this._getCreateUrl(), resourceModel.getSaveObject());
        }
    }

    /**
     * Saves the mop resource and returns result as observable
     */
    public saveClientSyncsConfig(resourceModel, clientSyncId) {
        if (resourceModel.id) {
            // edit
            const url = `${this._getCreateUrl()}/${clientSyncId}/configurations`;
            return this.put(url, resourceModel.getSaveObject());
        } else {
            // add
            const url = `${this._getCreateUrl()}/${clientSyncId}/configurations/`;
            return this.post(url, resourceModel.getSaveObject());
        }
    }

    public syncNow(resourceModel) {
        const url = `${this._getCreateUrl()}/${resourceModel.syncId}/run`;
        return this.post(url);
    }

    public disconnect(resourceModel) {
        const url = `${this._getCreateUrl()}/${resourceModel.syncId}/disconnect`;
        return this.put(url);
    }

    public deleteSync(resourceModel) {
        const url = `${this._getCreateUrl()}/${resourceModel.syncId}`;
        return this.delete(url, false);
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl(networkId?: string) {
        const netId = networkId != null ? networkId : this.apiService.theNetworkIs.getId();
        return `networks/${netId}/clientsyncs`;
    }

    /**
     * Wrapper function to handle full URLs vs relative URLs.
     */
    protected _path(path: string) {
        if (typeof path.includes !== 'function') {
            this.logger.error(
                'Unexpected destination passed for an HTTP request. Trying to request against an endpoint of: ' + path
            );
        }

        if (path.includes(this.apiUrl) || path.includes('http://') || path.includes('https://')) {
            return path;
        } else {
            return `${this.apiUrl}${path}`;
        }
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: any, error: HttpErrorResponse, ignore403?: boolean) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            scope.logger.error('Client-Sync service error occurred:', error.error);
            // this.logger.error('IAM Service error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            scope.logger.error(`Client-Sync Backend returned code ${error.status}`, error.error);
            // this.logger.error(`IAM Backend returned code ${error.status}`, error.error);
        }

        if (error.status !== 403 || !ignore403) {
            scope.lastErrorSource.next(error);
        }
        return observableThrowError(error);
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbeddedClientSync(res) {
        const clientSyncs = [];

        if (res['_embedded'] !== undefined && res['_embedded']['clientsyncs'] !== undefined) {
            for (let i = 0; i < res['_embedded']['clientsyncs'].length; i++) {
                const clientSync = res['_embedded']['clientsyncs'][i];
                const converted = this.convertClientSync(clientSync);
                if (converted) {
                    clientSyncs.push(converted);
                }
            }
        }

        return clientSyncs;
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleConfigurationsError() {
        return observableOf(new ClientSyncConfigurations({}));
    }

    /**
     * Return headers
     */
    private setHeaders(): HttpHeaders {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (tokenResponse.expired) {
            // if the token is expired, return null
            headers = null;
        } else if (tokenResponse.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        if (headers != null) {
            return headers.append('Content-Type', 'application/json');
        } else {
            return headers;
        }
    }

    private convertClientSync(clientSync) {
        // TODO handle different types of client syncs
        if (clientSync.provider === 'AAD') {
            return new AzureActiveDirectory(clientSync);
        } else if (clientSync.provider === 'INTUNE_AAD') {
            return new AzureIntuneActiveDirectory(clientSync);
        } else if (clientSync.provider === 'AD') {
            return new ActiveDirectory(clientSync);
        } else if (clientSync.provider === 'ZITI_AD') {
            return new ZitiActiveDirectory(clientSync);
        }
        this.logger.info('Unhandled ClientSync provider type', clientSync.provider);
        return undefined;
    }
}
