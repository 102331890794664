import { Resource, HateoasResource } from '@lagoshny/ngx-hateoas-client';
@HateoasResource('stored-secrets')

export class StoredSecret extends Resource {
    networkGroupId: string;
    id: string;
    name: string; 
    value: string;
    selected = false;
    type: string;
}
