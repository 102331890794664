<div style="display: inline" title="{{ statusTooltip }}">
    <span *ngIf="showBubble" [ngClass]="statusClass" class="bubble">
        <svg *ngIf="showLoader" class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" fill="none" r="20" stroke-miterlimit="10" stroke-width="3"></circle>
        </svg>
    </span>
    <div class="disabled-icon" *ngIf="item.enabled === false"></div>
</div>

<span *ngIf="showName" [ngClass]="deleteClass" id="Label_{{ item.id }}">{{ item.name }}</span>
