import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { FeatureNetworkBulkUploadSaveModule } from '@netfoundry-ui/feature/network-bulk-upload-save';
import { FeatureFormSupportButtonModule } from '@netfoundry-ui/feature/form-support-button';
import { WizardComponent } from './wizard/wizard.component';
import { ListHeaderComponent } from './list-header/list-header.component';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { SideButtonBarComponent } from './side-header/side-button-bar.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ClickOutsideModule,
        FormsModule,
        ReactiveFormsModule,
        FeatureNetworkBulkUploadSaveModule,
        FeatureFormSupportButtonModule,
        FileUploadModule,
        UiPipesModule,
    ],
    declarations: [SideButtonBarComponent, SideNavigationComponent, ListHeaderComponent, WizardComponent],
    exports: [SideButtonBarComponent, SideNavigationComponent, ListHeaderComponent, WizardComponent],
})
export class FeatureViewsTemplateModule {}
