import { lastValueFrom, Observable } from 'rxjs';
import { Environment } from '@netfoundry-ui/shared/model';
import {
    GetOption,
    HateoasResourceOperation,
    PagedGetOption,
    PagedResourceCollection,
    Resource,
} from '@lagoshny/ngx-hateoas-client';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

export abstract class BaseHateoasResourceService<T extends Resource> extends HateoasResourceOperation<T> {
    defaultPaginationSize = 500;
    defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;

    constructor(protected http: HttpClient, protected environment: Environment, resourceType: new () => T) {
        super(resourceType);
    }

    override getResource(): Observable<T> {
        throw new Error('Do not use: see get getResourceById');
    }

    override getPage(): Observable<PagedResourceCollection<T>> {
        throw new Error('Do not use: see getResourcePage');
    }

    getResourceById(id: string, options: GetOption = {}): Promise<T | undefined> {
        return lastValueFrom(super.getResource(id, { ...this.defaultHttpAccept, ...options }));
    }

    async getResourcePage(options?: PagedGetOption, includeProperties?: string): Promise<T[]> {
        let params = { ...this.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return lastValueFrom(
            super.getPage(params).pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                }),
                map((authPolicies) => (authPolicies ? authPolicies.resources : []))
            )
        );
    }

    async getAllByNetworkId(networkId: string, options: any = {}, includeProps = ''): Promise<T[]> {
        const size = 1;
        let params: PagedGetOption = {
            params: { networkId },
            pageParams: { size, page: 0 },
            sort: { name: 'ASC' },
            headers: { accept: 'application/hal+json' },
        };
        params.params = { ...params.params, ...options.params };
        params.pageParams = { ...params.pageParams, ...options.pageParams };
        params.sort = { ...params.sort, ...options.sort };
        params.headers = { ...params.headers, ...options.headers };

        if (includeProps) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProps}` } };
        }
        let finalResults: any[] = [];
        return this.getResourcePage(params).then((results) => {
            finalResults = results;
            if (this.lastTotalCount > 1) {
                finalResults = [];
                const promises = [];
                let p = 0;
                const totalPages = Math.ceil(this.lastTotalCount / this.defaultPaginationSize);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                params.pageParams.size = this.defaultPaginationSize;
                while (p < totalPages) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    params.pageParams.page = p++;
                    promises.push(this.getResourcePage(params));
                }
                return Promise.all(promises).then((promiseResults) => {
                    promiseResults.forEach((r) => {
                        finalResults = finalResults.concat(r);
                    });
                    return finalResults;
                });
            } else {
                return finalResults;
            }
        });
    }
}
