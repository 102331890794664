<div *ngIf="step === showStep && finalModel != null">
    <div class="sized mid">
        <div class="wizard padded">
            <div class="icon step5 upside"></div>

            <div class="summary">
                <div class="step">1</div>
                <div>
                    <div class="title">Application Name</div>
                    <div class="subtitle">{{ finalModel.name }}</div>
                </div>
            </div>

            <div class="summary">
                <div class="step">2</div>
                <div>
                    <div class="title">
                        Install this Gateway in
                        {{ sourceGatewayString != null ? sourceGatewayString : 'your current source' }}
                    </div>
                    <label class="underline"
                        >Gateway Name<span [hidden]="!showLauncher(finalModel['hostingGateway'])" class="note"
                            >Tap To Launch New Gateway</span
                        ></label
                    >
                    <div class="content">
                        <div
                            (click)="
                                !showLauncher(finalModel['hostingGateway']) && openGateway(finalModel['hostingGateway'])
                            "
                            [ngClass]="{
                                noLauncherScript: !showLauncher(finalModel['hostingGateway'])
                            }"
                            class="launch"
                        >
                            {{ finalModel['hostingGatewayName'] }}
                            <div
                                (click)="LaunchAzureCloudScript(finalModel['hostingGateway'])"
                                *ngIf="
                                    showLauncher(finalModel['hostingGateway']) &&
                                    IsAzure(finalModel['hostingGateway']) &&
                                    !IsHA(finalModel['hostingGateway']) &&
                                    !isGatewayRegistered(finalModel['hostingGateway'])
                                "
                                class="tap azure"
                            >
                                Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                            </div>
                            <div
                                (click)="LaunchCloudScript(finalModel['hostingGateway'])"
                                *ngIf="
                                    showLauncher(finalModel['hostingGateway']) &&
                                    IsAWS(finalModel['hostingGateway']) &&
                                    !IsHA(finalModel['hostingGateway']) &&
                                    !isGatewayRegistered(finalModel['hostingGateway'])
                                "
                                class="tap aws"
                            >
                                Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!isInterconnect" class="summary">
                <div class="step">3</div>
                <div>
                    <div class="title">Install this Gateway in your destination cloud</div>
                    <label class="underline"
                        >Gateway Name<span [hidden]="!showLauncher(finalModel['targetGateway'])" class="note"
                            >Tap To Launch New Gateway</span
                        ></label
                    >
                    <div class="content">
                        <div
                            (click)="
                                !showLauncher(finalModel['targetGateway']) && openGateway(finalModel['targetGateway'])
                            "
                            [ngClass]="{
                                noLauncherScript: !showLauncher(finalModel['targetGateway'])
                            }"
                            class="launch"
                        >
                            {{ finalModel['targetGatewayName'] }}
                            <div
                                (click)="LaunchAzureCloudScript(finalModel['targetGateway'])"
                                *ngIf="
                                    showLauncher(finalModel['targetGateway']) &&
                                    IsAzure(finalModel['targetGateway']) &&
                                    !IsHA(finalModel['targetGateway']) &&
                                    !isGatewayRegistered(finalModel['targetGateway'])
                                "
                                class="tap azure"
                            >
                                Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                            </div>
                            <div
                                (click)="LaunchCloudScript(finalModel['targetGateway'])"
                                *ngIf="
                                    showLauncher(finalModel['targetGateway']) &&
                                    IsAWS(finalModel['targetGateway']) &&
                                    !IsHA(finalModel['targetGateway']) &&
                                    !isGatewayRegistered(finalModel['targetGateway'])
                                "
                                class="tap aws"
                            >
                                Tap To Launch&nbsp;<span class="icon-ClickIcon"></span>
                            </div>
                            <div
                                *ngIf="
                                    IsGoogle(finalModel['targetGateway']) &&
                                    !IsHA(finalModel['targetGateway']) &&
                                    !isGatewayRegistered(finalModel['targetGateway'])
                                "
                                class="tap google"
                            >
                                <span class="icon-ClickIcon"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="summary">
                <div class="step">{{ isInterconnect ? '3' : '4' }}</div>
                <div>
                    <div *ngIf="ipHostServices.length > 0 && !isEmptyHostService(ipHostServices[0])">
                        <div class="title">Host Services - Single IPs</div>
                        <div class="tableGrid four header">
                            <div>IP Address</div>
                            <div>Port / Range</div>
                            <div>Label</div>
                            <div>Gateway</div>
                        </div>
                        <!-- Need to check type and only show if Host -->
                        <div *ngFor="let service of finalModel['services']; let index = index" class="tableGrid four">
                            <!-- needed to add the ngIf on each div instead of using a parent div as the parent div broke the styling -->
                            <div *ngIf="service.serviceClass === 'CS'" class="staticCell">
                                {{ service.networkIp }}
                            </div>
                            <div *ngIf="service.serviceClass === 'CS'" class="staticCell">
                                {{
                                    service.networkFirstPort === service.networkLastPort
                                        ? service.networkFirstPort
                                        : service.networkFirstPort + '-' + service.networkLastPort
                                }}
                            </div>
                            <div
                                (click)="openService(service)"
                                *ngIf="service.serviceClass === 'CS'"
                                class="clickable link"
                                class="staticCell"
                            >
                                {{ service.name }}
                            </div>
                            <div *ngIf="service.serviceClass === 'CS'" class="staticCell">
                                {{ finalModel['hostingGateway']['name'] }}
                            </div>
                        </div>
                    </div>

                    <div *ngIf="ipNetworkServices.length > 0 && !isEmptyNetworkService(ipNetworkServices[0])">
                        <div class="title">Network Services - Multiple IPs</div>
                        <div class="tableGrid four header">
                            <div>Network Address</div>
                            <div>Intercept Address</div>
                            <div>Label</div>
                            <div>Gateway</div>
                        </div>
                        <!-- Need to check type and only show if Network -->
                        <div *ngFor="let service of finalModel['services']; let index = index" class="tableGrid four">
                            <!-- needed to add the ngIf on each div instead of using a parent div as the parent div broke the styling -->
                            <div *ngIf="service.serviceClass === 'GW'" class="staticCell">
                                {{ service.gatewayIp + '/' + service.gatewayCidrBlock }}
                            </div>
                            <div *ngIf="service.serviceClass === 'GW'" class="staticCell">
                                {{ service.interceptIp }}
                            </div>
                            <div
                                (click)="openService(service)"
                                *ngIf="service.serviceClass === 'GW'"
                                class="clickable link"
                                class="staticCell"
                            >
                                {{ service.name }}
                            </div>
                            <div *ngIf="service.serviceClass === 'GW'" class="staticCell">
                                {{ finalModel['hostingGateway']['name'] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="isInterconnect" class="summary">
                <div class="step">4</div>
                <div>
                    <div class="title">
                        {{ targetGatewayString }} Gateway
                        <span class="note target">Included in the app to allow communication</span>
                    </div>
                    <div (click)="openGateway(finalModel['targetGateway'])" class="subtitle">
                        {{ finalModel['targetGatewayName'] }}
                    </div>
                </div>
            </div>

            <div class="row">&nbsp;</div>
        </div>
    </div>
</div>
