import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateFiltersComponent } from './date-filters.component';

@NgModule({
    imports: [CommonModule],
    declarations: [DateFiltersComponent],
    exports: [DateFiltersComponent],
})
export class FeatureDateFiltersModule {}
