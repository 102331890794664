<span (clickOutside)="hide()" [ngClass]="{ roleForm: isRoleForm, fullWidth: isFullWidth }" class="clickOutside">
    <span
        class="select2 select2-container select2-container--default select2-container--below select2-container--focus"
        dir="ltr"
    >
        <span class="selection">
            <span
                aria-expanded="false"
                aria-haspopup="true"
                class="select2-selection select2-selection--multiple"
                role="combobox"
                tabindex="-1"
            >
                <input
                    (click)="openSelect()"
                    (keyup)="filterChangedDebounced()"
                    (mouseup)="applyFilterMouseUp()"
                    (search)="inputChanged($event)"
                    [(ngModel)]="filterString"
                    [disabled]="disableField"
                    aria-autocomplete="list"
                    autocapitalize="off"
                    autocomplete="off"
                    autocorrect="off"
                    class="select2-search__field"
                    placeholder="{{ placeholder }}"
                    role="textbox"
                    spellcheck="false"
                    tabindex="0"
                    type="search"
                />
                <ul
                    *ngIf="selected && selected.length > 0"
                    [ngClass]="{ error: hasError }"
                    class="select2-selection__rendered"
                >
                    <li class="select2-search select2-search--inline"></li>
                    <li
                        (click)="$event.stopPropagation()"
                        *ngFor="let item of selected | sortby: 'name'"
                        class="select2-selection__choice"
                        title="{{ item.name }}"
                    >
                        <span
                            (click)="remove(item); $event.stopPropagation()"
                            class="select2-selection__choice__remove"
                            role="presentation"
                            >×</span
                        >{{ item.name }}
                    </li>
                </ul>
            </span>
        </span>
        <span aria-hidden="true" class="dropdown-wrapper"></span>
    </span>

    <span
        *ngIf="!usingCustomSort"
        [ngClass]="{ withBorder: !hideSelect }"
        class="nf-select2-dropdown select2-dropdown--below"
        dir="ltr"
    >
        <span class="select2-results">
            <ul
                [hidden]="hideSelect"
                aria-expanded="true"
                aria-hidden="false"
                aria-multiselectable="true"
                class="select2-results__options"
                role="tree"
            >
                <li
                    (click)="addSelected(option)"
                    *ngFor="let option of displayedOptions | sortby: 'name':sortOrder; let i = index"
                    [hidden]="isSelected(option)"
                    aria-selected="false"
                    class="select2-results__option"
                    id="test_{{ i }}"
                    role="treeitem"
                >
                    {{ option.name }}
                </li>
                <li
                    (click)="showMoreOptions()"
                    [hidden]="useLocalFilter || ((!options || options.length >= optionsTotal) && !moreLoading)"
                    aria-selected="false"
                    class="select2-results__option show-more-option"
                    role="treeitem"
                >
                    SHOW MORE...
                    <span *ngIf="moreLoading" class="spinner"></span>
                </li>
            </ul>
        </span>
    </span>

    <span
        *ngIf="usingCustomSort"
        [ngClass]="{ withBorder: !hideSelect }"
        class="nf-select2-dropdown select2-dropdown--below"
        dir="ltr"
    >
        <span class="select2-results">
            <ul
                [hidden]="hideSelect"
                aria-expanded="true"
                aria-hidden="false"
                aria-multiselectable="true"
                class="select2-results__options"
                role="tree"
            >
                <li
                    (click)="addSelected(option)"
                    *ngFor="let option of displayedOptions"
                    [hidden]="isSelected(option)"
                    aria-selected="false"
                    class="select2-results__option"
                    role="treeitem"
                >
                    {{ option.name }}
                </li>
            </ul>
        </span>
    </span>
</span>
