<div class="modal solid resizeModal open" id="resizeModal">
    <div (click)="cancel()" class="close cancel" id="CancelXButton"></div>
    <div class="icon upgradenetwork"></div>
    <div class="title">{{ title }}</div>
    <div class="subtitle">{{ network.name }}</div>
    <div class="resize-options-container">
        <div class="label">
            Select Size
            <span class="specialnote">Required</span>
        </div>
        <select (ngModelChange)="optionSelected($event)" [ngModel]="size" id="upgradeOptions" name="optionSelect">
            <option *ngFor="let s of sizes" [ngValue]="s.name">{{ s.name }} - {{ s.description }}</option>
        </select>
    </div>

    <div class="buttons">
        <div (click)="cancel()" class="linkButton cancel" id="CancelUpgrade">
            {{ cancelAction }}
        </div>
        <button (click)="resize()" [disabled]="!isEnabled" class="save alert confirm" id="ConfirmAction">
            {{ action }}
        </button>
    </div>
</div>
