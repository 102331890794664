import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { NetworkUpgradeModalComponent } from './network-upgrade-modal/network-upgrade-modal.component';
import { NetworkRestartModalComponent } from './network-restart-modal/network-restart-modal.component';
import { NetworkResizeModalComponent } from './network-resize-modal/network-resize-modal.component';
import { EdgeRouterUpgradeModalComponent } from './edge-router-upgrade-modal/edge-router-upgrade-modal.component';

@NgModule({
    imports: [CommonModule, FormsModule, MultiSelectModule],
    declarations: [
        EdgeRouterUpgradeModalComponent,
        NetworkUpgradeModalComponent,
        NetworkRestartModalComponent,
        NetworkResizeModalComponent,
    ],
    exports: [
        EdgeRouterUpgradeModalComponent,
        NetworkUpgradeModalComponent,
        NetworkResizeModalComponent,
        NetworkRestartModalComponent,
    ],
})
export class FeatureNetworkUpgradeModule {}
