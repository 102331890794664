import { MopResource } from './mopresource';

export class CertificateAuthority extends MopResource {
    public tags: any;
    public isAutoCaEnrollmentEnabled: boolean;
    public isOttCaEnrollmentEnabled: boolean;
    public isAuthEnabled: boolean;
    public isVerified: boolean;
    public certPem: string;
    public fingerprint: string;

    constructor(object) {
        super(object);
    }

    public getSaveObject() {
        return {
            name: this.name,
            isAutoCaEnrollmentEnabled: this.isAutoCaEnrollmentEnabled,
            isOttCaEnrollmentEnabled: this.isOttCaEnrollmentEnabled,
            isAuthEnabled: this.isAuthEnabled,
            certPem: this.certPem,
        };
    }
}
