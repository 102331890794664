<div [ngClass]="{ inline: isInline }" class="fullModal opened addForm" id="AddGatewayModal">
    <app-loader [isLoading]="isLoading"></app-loader>

    <div *ngIf="!isComplete && !isLoading" class="fullRow" id="Stage2">
        <div [hidden]="selectedType === 'AZB'" class="title" style="filter: brightness(50%)">Create A New Site</div>
        <div [hidden]="selectedType === 'AZB'" class="subtitle">Enter your site attributes</div>
        <div class="sized">
            <div *ngIf="model.status && model.status < 400 && model.registrationKey != null">
                <div *ngIf="!zitiEnabledService.zitiPure">
                    <div class="label row edge">Registration Key<span class="note">Click to Copy</span></div>
                    <div class="row edge">
                        <input
                            (click)="copy()"
                            [(ngModel)]="model['registrationKey']"
                            class="RegKey"
                            id="EditRegKey"
                            readonly
                            type="text"
                        />
                    </div>
                </div>
                <div *ngIf="zitiEnabledService.zitiPure" class="sized">
                    <div class="label row">
                        {{ gateway.name }} Registration Key<span class="note">Tap To Download</span>
                    </div>
                    <div class="row">
                        <div (click)="downloadJWT(gateway, index)" class="downloadButton">
                            <div class="DownloadKey"></div>
                            <div>DOWNLOAD KEY</div>
                            <div class="TapToDownload"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="label row">
                Site Name
                <span [hidden]="!errorNameLength" class="error">Name must be at least 5 characters</span>
                <span [hidden]="errorNameLength" class="note">Required</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="model.name"
                    [ngClass]="{ error: errorName }"
                    class="restrictNormal"
                    id="EditName"
                    maxlength="{{ maxLength }}"
                    minlength="5"
                    placeholder="What would you like to call this site?"
                    type="text"
                />
            </div>

            <!-- Azure Site -->
            <div class="label row">
                Cloud Region<span
                    [ngClass]="{ error: errors['geoRegionId'] }"
                    class="infonote"
                    matTooltip="Pick a region that is closest to your Branch offices or Azure regions you wish to attach to the Primary Cloud resources."
                    matTooltipPosition="right"
                    matTooltipShowDelay="500"
                    >Required</span
                >
            </div>
            <div class="row">
                <select
                    [(ngModel)]="model.geoRegionId"
                    [disabled]="isEditing"
                    [ngClass]="{ error: errors['geoRegionId'] }"
                    id="AzureRegionList"
                    placeholder="Select a Cloud Region"
                >
                    <option value="">Gateway Cloud Region</option>
                    <option *ngFor="let region of regions | sortby: 'name'" [value]="region.id">
                        {{ region.name }}
                    </option>
                </select>
            </div>

            <div *ngIf="showBreakoutFields">
                <div class="label row">
                    O365 Breakout Next Hop IP
                    <span [hidden]="!errors['nextHop']" class="error">Invalid</span>
                    <span [hidden]="errors['nextHop']" class="note">Optional</span>
                </div>
                <div class="row">
                    <input
                        [(ngModel)]="model.o365BreakoutNextHopIp"
                        [ngClass]="{ error: errors['nextHop'] }"
                        class="restrictNormal"
                        id="NextHopIp"
                        placeholder="Next Hop IP"
                        type="text"
                    />
                </div>
            </div>

            <div class="label row">
                Azure Resource Group Name<span [ngClass]="{ error: errors['azureResourceGroupName'] }" class="note"
                    >{{ isEditing ? 'Required to Deploy' : 'Optional' }} - Must be Created in Azure</span
                >
            </div>
            <div class="row">
                <select
                    [(ngModel)]="azureSiteModel.azureResourceGroupName"
                    [disabled]="currentAzureSubscriptionId == null"
                    [ngClass]="{ error: errors['azureResourceGroupName'] }"
                    id="ResourceGroupList"
                >
                    <option value="">Select a Resource Group</option>
                    <option *ngFor="let group of azureResourceGroups | sortby: 'name'" [value]="group.getName()">
                        {{ group.name }}
                    </option>
                </select>
            </div>

            <div class="label row">
                Azure Virtual WAN<span [ngClass]="{ error: errors['azureVirtualWanId'] }" class="note"
                    >{{ isEditing ? 'Required to Deploy' : 'Optional' }} - Must be Created in Azure</span
                >
            </div>
            <div class="row">
                <select
                    [(ngModel)]="azureSiteModel.azureVirtualWanId"
                    [disabled]="currentAzureSubscriptionId == null"
                    [ngClass]="{ error: errors['azureVirtualWanId'] }"
                    id="VirtualWanList"
                >
                    <option value="">Select a Virtual WAN</option>
                    <option *ngFor="let wan of azureVirtualWans | sortby: 'name'" [value]="wan.getAzureId()">
                        {{ wan.name }}
                    </option>
                </select>
            </div>

            <div class="label row">
                Azure Data Center Location<span
                    [ngClass]="{ error: errors['dataCenterId'] }"
                    class="note"
                    matTooltip="Pick the region where  Azure Cloud Resources reside."
                    matTooltipPosition="right"
                    matTooltipShowDelay="500"
                    >{{ isEditing ? 'Required to Deploy' : 'Optional' }}</span
                >
            </div>
            <div class="row">
                <select
                    [(ngModel)]="azureSiteModel.dataCenterId"
                    [disabled]="currentAzureSubscriptionId == null"
                    [ngClass]="{ error: errors['dataCenterId'] }"
                    id="VirtualLocationList"
                >
                    <option value="">Select a Location</option>
                    <option *ngFor="let dc of azureLocations | sortby: 'name'" [value]="dc.getId()">
                        {{ dc.name }}
                    </option>
                </select>
            </div>

            <div class="label row">
                Public IP Address<span [ngClass]="{ error: errors['publicIpAddress'] }" class="note">{{
                    isEditing ? 'Required to Deploy' : 'Optional'
                }}</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="azureSiteModel.publicIpAddress"
                    [ngClass]="{ error: errors['publicIpAddress'] }"
                    class="restrictNormal"
                    id="PublicIpAddress"
                    placeholder="Public IP Address"
                    type="text"
                />
            </div>

            <div class="grid two toggleGrid" style="padding-bottom: 5px">
                <div *ngIf="featureService.experimentalFeatures" class="label">
                    Advertise VPN site connected subnets to AVW
                </div>

                <div
                    (click)="toggleAdvertise()"
                    *ngIf="featureService.experimentalFeatures"
                    [ngClass]="{ on: azureSiteModel.bgp.advertiseLocal }"
                    class="toggle toggleInput inlineToggle"
                >
                    <span [hidden]="!azureSiteModel.bgp.advertiseLocal">ON&nbsp;&nbsp;&nbsp;</span>
                    <span [hidden]="azureSiteModel.bgp.advertiseLocal">&nbsp;&nbsp;&nbsp;OFF</span>
                    <div class="switch"></div>
                </div>
            </div>

            <div class="label row">
                Local BGP Peering Address<span
                    [ngClass]="{ error: errors['bgpPeeringAddress'] }"
                    class="infonote"
                    matTooltip="*MUST* be unique within the connected sites and VNets of the AVW as well as all local subnets for the site being added"
                    matTooltipPosition="right"
                    matTooltipShowDelay="500"
                    >{{ isEditing ? 'Required to Deploy' : 'Optional' }}</span
                >
            </div>
            <div class="row">
                <input
                    (keyup.enter)="save()"
                    [(ngModel)]="azureSiteModel.bgp.localPeeringAddress.ipAddress"
                    [ngClass]="{ error: errors['bgpPeeringAddress'] }"
                    class="restrictNormal"
                    id="BgpPeeringAddress"
                    placeholder="192.168.0.101"
                    type="text"
                />
            </div>

            <div class="label row">
                Autonomous System Number<span
                    [ngClass]="{ error: errors['bpgASN'] }"
                    class="infonote"
                    matTooltip="*MUST* be unique within the connected sites and VNets of the AVW as well as all local subnets for the site being added"
                    matTooltipPosition="right"
                    matTooltipShowDelay="500"
                    >{{ isEditing ? 'Required to Deploy' : 'Optional' }}</span
                >
            </div>
            <div class="row">
                <input
                    (keyup.enter)="save()"
                    [(ngModel)]="azureSiteModel.bgp.localPeeringAddress.asn"
                    [ngClass]="{ error: errors['bpgASN'] }"
                    class="restrictNormal"
                    id="BpgAsn"
                    placeholder="Unique value between 1 and 65535"
                    type="text"
                />
            </div>

            <div *ngIf="featureService.experimentalFeatures" class="label row labelExpansion">
                Advanced Options<span class="note">Open to Edit Details</span>
            </div>
            <div *ngIf="featureService.experimentalFeatures" class="row expansion">
                <mat-accordion>
                    <mat-expansion-panel #panel [hideToggle]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'65px'">
                            <mat-panel-title class="expansionLabel">
                                <div class="title">
                                    <div class="icon-AdvancedOptions"></div>
                                </div>
                                <div class="subtitle">Advanced Options</div>
                                <span
                                    [ngClass]="{ open: panel.expanded }"
                                    class="expansion-indicator icon-Sort_Down"
                                ></span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="label row">
                            BGP Neighbor Peers<span
                                [hidden]="neighborError.indexOf('IP') >= 0 || neighborError.indexOf('ASN') >= 0"
                                class="note"
                                >Optional</span
                            ><span
                                [hidden]="neighborError.indexOf('ASN') === -1 && neighborError.indexOf('IP') === -1"
                                class="note error"
                                >Invalid</span
                            >
                        </div>
                        <div class="wizard noShadow">
                            <div>
                                <div *ngFor="let neighbor of neighbors; let index = index" class="row">
                                    <div
                                        (click)="removeNeighbor(index)"
                                        *ngIf="index > 0"
                                        [ngClass]="{ minus: index >= 0 }"
                                        class="gridAdd"
                                        id="RemoveNeighbor_{{ index }}"
                                    ></div>
                                    <div class="grid two" style="padding-bottom: 5px">
                                        <label
                                            *ngIf="index === 0"
                                            [ngClass]="{ error: neighborError.indexOf('IP') >= 0 }"
                                            >Peering Address</label
                                        >
                                        <label
                                            *ngIf="index === 0"
                                            [ngClass]="{ error: neighborError.indexOf('ASN') >= 0 }"
                                            >ASN</label
                                        >

                                        <input
                                            [(ngModel)]="neighbors[index].ipAddress"
                                            [ngClass]="{
                                                error: neighborErrors[index] && neighborErrors[index].indexOf('IP') >= 0
                                            }"
                                            id="NeighborIp_{{ index }}"
                                            max="15"
                                            placeholder="192.168.0.101"
                                            type="text"
                                        />
                                        <input
                                            [(ngModel)]="neighbors[index].asn"
                                            [ngClass]="{
                                                error:
                                                    neighborErrors[index] && neighborErrors[index].indexOf('ASN') >= 0
                                            }"
                                            id="NeighborASN_{{ index }}"
                                            placeholder="Unique value between 1 and 65535"
                                            type="text"
                                        />
                                    </div>
                                </div>

                                <div class="row">
                                    OR
                                    <span (click)="addPeeringNeighbor()" class="linkButton" id="AddPeeringNeighbor"
                                        >Add Additional +</span
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="label row">
                            Additional Subnets to Advertise<span [hidden]="advertisedPrefixesError" class="note"
                                >Optional</span
                            ><span [hidden]="!advertisedPrefixesError" class="note error">Invalid</span>
                        </div>
                        <div class="wizard noShadow">
                            <div>
                                <div
                                    *ngFor="
                                        let advertisedPrefix of advertisedPrefixes;
                                        let index = index;
                                        trackBy: trackByIndex
                                    "
                                    class="row"
                                >
                                    <div style="padding-bottom: 5px">
                                        <div
                                            (click)="removeAdvertisedPrefix(index)"
                                            *ngIf="index > 0"
                                            [ngClass]="{ minus: index >= 0 }"
                                            class="gridAdd"
                                            id="RemoveAdvertisedPrefix_{{ index }}"
                                        ></div>

                                        <label *ngIf="index === 0" [ngClass]="{ error: advertisedPrefixesError }"
                                            >Advertised Prefix (CIDR Notation)</label
                                        >

                                        <input
                                            [(ngModel)]="advertisedPrefixes[index]"
                                            [ngClass]="{ error: advertisedPrefixesErrors[index] }"
                                            id="AdvertisedPrefix_{{ index }}"
                                            max="18"
                                            placeholder="10.0.0.0/24"
                                            type="text"
                                        />
                                    </div>
                                </div>

                                <div class="row">
                                    OR
                                    <span (click)="addAdvertisedPrefix()" class="linkButton" id="AddAdvertisedPrefix"
                                        >Add Additional +</span
                                    >
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <!--
<div class="label row">Peer Weight<span [hidden]="errors['bgpPeerWeight']" class="note">Optional</span><span class="note error" [hidden]="!errors['bgpPeerWeight']">Invalid</span></div>
<div class="row">
<input id="BgpPeerWeight" class="restrictNormal" type="text" [ngClass]="{error:errors['bgpPeerWeight']}" placeholder="Peer Weight" [(ngModel)]="azureSiteModel['bgpPeerWeight']" (keyup.enter)="save()" />
</div>

<div class="label row">Device Link Speed<span class="note" [hidden]="errors['deviceLinkSpeed']" class="note">Optional</span><span class="note error" [hidden]="!errors['deviceLinkSpeed']">Invalid</span></div>
<div class="row">
<input id="DeviceLinkSpeed" class="restrictNormal" type="text" [ngClass]="{error:errors['deviceLinkSpeed']}" placeholder="Device Link Speed" [(ngModel)]="azureSiteModel['deviceLinkSpeed']" (keyup.enter)="save()" />
</div>

<div class="label row">Device Vendor<span class="note" [hidden]="errors['deviceVendor']">Optional</span><span class="note error" [hidden]="!errors['deviceVendor']">Invalid</span></div>
<div class="row">
<input id="DeviceVendor" class="restrictNormal" type="text" [ngClass]="{error:errors['deviceVendor']}" placeholder="Device Vendor" [(ngModel)]="azureSiteModel['deviceVendor']" (keyup.enter)="save()" />
</div>

<div class="label row">Device Model<span class="note" [hidden]="errors['deviceModel']">Optional</span><span class="note error" [hidden]="!errors['deviceModel']">Invalid</span></div>
<div class="row">
<input id="DeviceModel" class="restrictNormal" type="text" [ngClass]="{error:errors['deviceModel']}" placeholder="Device Model" [(ngModel)]="azureSiteModel['deviceModel']" (keyup.enter)="save()" />
</div>
-->

            <div class="row buttonRow right" style="margin-top: 20px">
                <button (click)="save()" *ngIf="!processing && !isEditing" class="save" id="SaveGatewayButton">
                    Create
                </button>
                <button *ngIf="processing && !editing" class="save confirm" disabled>
                    Saving <span class="spinner"></span>
                </button>

                <button (click)="update()" *ngIf="!processing && isEditing" class="save" id="UpdateGatewayButton">
                    Update
                </button>
                <button *ngIf="processing && editing" class="save confirm" disabled>
                    Updating <span class="spinner"></span>
                </button>
            </div>
        </div>
    </div>

    <div [hidden]="!isComplete || isLoading" class="fullRow" id="Stage3">
        <div class="title">congratulations</div>
        <div class="subtitle">Your Site {{ completedTitleStatusString }}</div>
        <div [ngClass]="{ building: isBuilding, created: !isBuilding }" class="row center">
            <span *ngIf="isBuilding" class="completedEndpointBubble">
                <svg class="circular" viewBox="25 25 50 50">
                    <circle
                        class="path"
                        cx="50"
                        cy="50"
                        fill="none"
                        r="20"
                        stroke-miterlimit="10"
                        stroke-width="5"
                    ></circle>
                </svg>
            </span>
        </div>

        <div *ngFor="let gateway of gateways; let index = index" class="row">
            <div class="sized">
                <div *ngIf="!zitiEnabledService.zitiPure">
                    <div [hidden]="gateways.length === 0" class="label row">
                        {{ gateway.name }} Registration Key<span class="note">Click to Copy</span>
                    </div>
                    <div class="row">
                        <input
                            (click)="gatewayFormService.copy(gateway)"
                            [(ngModel)]="gateway.registrationKey"
                            class="RegKey"
                            data-enter="SaveButton"
                            id="RegKey_{{ gateway.id }}"
                            readonly
                            style="margin-bottom: 10px"
                            type="text"
                        />
                    </div>
                </div>
                <div *ngIf="zitiEnabledService.zitiPure" class="sized">
                    <div class="label row">
                        {{ gateway.name }} Registration Key<span class="note">Tap To Download</span>
                    </div>
                    <div class="row">
                        <div (click)="downloadJWT(gateway, index)" class="downloadButton">
                            <div class="DownloadKey"></div>
                            <div>DOWNLOAD KEY</div>
                            <div class="TapToDownload"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div (click)="hideForm()" class="buttonBall close" id="CloseGatewayButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div class="private">
        <div
            (click)="share()"
            *ngIf="authorizationService.canShareEndpoint(model.id)"
            [hidden]="!isComplete"
            class="buttonBall share"
            id="ShareGatewayButton"
        >
            <div class="buttonLabel">SHARE</div>
        </div>
    </div>
    <div
        (click)="goBack()"
        [hidden]="isEditing || isLoading || isComplete"
        class="buttonBall back"
        id="BackGatewayButton"
    >
        <div class="buttonLabel">BACK</div>
    </div>
</div>
