import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TunnelersFormService } from './tunnelers-form.service';

@Component({
    selector: 'app-tunnelers-form',
    templateUrl: './tunnelers-form.component.html',
    styleUrls: ['./tunnelers-form.component.scss'],
})
export class TunnelersFormComponent implements OnInit {
    tunnelerItems: any = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<TunnelersFormComponent>,
        public dialogForm: MatDialog,
        private router: Router,
        private tunnelersFormService: TunnelersFormService
    ) {}

    ngOnInit(): void {
        this.tunnelerItems = this.tunnelersFormService.getTunnelerItems();
    }

    hide() {
        this.dialogRef.close();
    }

    itemSelected() {
        //this.dialogRef.close();
    }
}
