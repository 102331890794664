import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EndpointV2, Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { Observable, of } from 'rxjs';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import {
    GetOption,
    HateoasResourceOperation,
    HttpMethod,
    PagedGetOption,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { tap } from 'rxjs/operators';
import { merge } from 'lodash';

@Injectable({ providedIn: 'root' })
export class EndpointServiceV2 extends HateoasResourceOperation<EndpointV2> {
    static defaultPaginationSize = 500;
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;

    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(EndpointV2);
    }

    override getResource(): Observable<EndpointV2> {
        throw new Error('Do not use: see get getEndpoint');
    }

    override getPage(): Observable<PagedResourceCollection<EndpointV2>> {
        throw new Error('Do not use: see getEndpoints');
    }

    getEndpoint(id: string | number, options: GetOption = {}): Promise<EndpointV2 | undefined> {
        return super
            .getResource(id, { ...EndpointServiceV2.defaultHttpAccept, ...options })
            .toPromise()
            .then((endpoint) => endpoint);
    }

    async getEndpointPage(options?: PagedGetOption, includeProperties?: string): Promise<EndpointV2[]> {
        let params = { ...EndpointServiceV2.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super
            .getPage(params)
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((endpoints) => (endpoints ? endpoints.resources : ([] as EndpointV2[])));
    }

    async getAllEndpointsByNetworkId(networkId: string, options = {}, includeProps = ''): Promise<EndpointV2[]> {
        const size = 1;
        let params: PagedGetOption = {
            params: { networkId },
            pageParams: { size, page: 0 },
            sort: { name: 'ASC' },
            headers: { accept: 'application/hal+json' },
        };
        params = { ...params, ...options };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        params.params['networkId'] = networkId;

        if (includeProps) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProps}` } };
        }
        let finalResults: any[] = [];
        return this.getEndpointPage(params).then((results) => {
            finalResults = results;
            if (this.lastTotalCount > 1) {
                finalResults = [];
                const promises = [];
                let p = 0;
                const totalPages = Math.ceil(this.lastTotalCount / EndpointServiceV2.defaultPaginationSize);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                params.pageParams.size = EndpointServiceV2.defaultPaginationSize;
                while (p < totalPages) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    params.pageParams.page = p++;
                    promises.push(this.getEndpointPage(params));
                }
                return Promise.all(promises).then((promiseResults) => {
                    promiseResults.forEach((r) => {
                        finalResults = finalResults.concat(r);
                    });
                    return finalResults;
                });
            } else {
                return finalResults;
            }
        });
    }

    public findBySessionIdentityId(sessionIdentityId: string): Promise<EndpointV2[]> {
        const options: PagedGetOption = {
            ...EndpointServiceV2.defaultHttpAccept,
            params: { sessionIdentityId: sessionIdentityId },
        };
        return super
            .getPage(options)
            .toPromise()
            .then((rec) => (rec ? rec.resources : ([] as EndpointV2[])));
    }

    public validateUpdate(endpointId: string, model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .patch(this.environment.v2apiUrl + 'endpoints/' + endpointId, model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public async validateCreate(model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .post(this.environment.v2apiUrl + 'endpoints', model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public getPostureData(endpointId: string): Observable<any> {
        return this.http.get(this.environment.v2apiUrl + 'endpoints/' + endpointId + '/posture-data');
    }

    public resetMFA(endpointId: string): Observable<any> {
        return this.http.delete(this.environment.v2apiUrl + 'endpoints/' + endpointId + '/mfa');
    }

    public resetToken(ids: string[]): Observable<any> {
        return this.http.post<any>(this.environment.v2apiUrl + 'endpoints/reissue', ids);
    }

    public reEnroll(ids: string[]): Observable<any> {
        return this.http.post<any>(this.environment.v2apiUrl + 'endpoints/re-enroll', ids);
    }

    public getJson(endpointId: string): Observable<string> {
        const options: any = {};
        const url = `${this.environment.v2apiUrl}endpoints/${endpointId}/?meta=ziti,diffZiti`;
        return this.http.get(url, options) as Observable<any>;
    }

    public findAssociatedServices(
        endpointId: string,
        includeProperties = 'id|name',
        contentType = 'application/json'
    ): Promise<any> {
        if (!endpointId) return Promise.resolve([]);
        const params = new HttpParams().set('accessibleEndpointId', endpointId);
        const headers = new HttpHeaders().set('Accept', `${contentType};includeProperties=${includeProperties}`);
        return this.http
            .get(this.environment.v2apiUrl + 'services', {
                headers: headers,
                params: params,
                responseType: 'json',
            })
            .toPromise();
    }

    public findAssociatedRouters(endpointId: string): Promise<any> {
        if (!endpointId) return Promise.resolve([]);
        const params = new HttpParams().set('accessibleEndpointId', endpointId);
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http
            .get(this.environment.v2apiUrl + 'edge-routers', {
                headers: headers,
                params: params,
                responseType: 'json',
            })
            .toPromise();
    }

    public findManagedRouters(zitiId: string): Observable<any> {
        const params = new HttpParams().set('zitiId', zitiId);
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get(this.environment.v2apiUrl + 'edge-routers', {
            headers: headers,
            params: params,
            responseType: 'json',
        });
    }

    public findAffectedAppwans(endpointId: string, contentType = 'application/json'): Observable<any> {
        if (endpointId) {
            const params = new HttpParams().set('affectedEndpointId', endpointId);
            const headers = new HttpHeaders().set('Accept', contentType);
            return this.http.get(this.environment.v2apiUrl + 'app-wans', {
                headers: headers,
                params: params,
                responseType: 'json',
            });
        }
        return of({});
    }

    public downloadFileFormat(
        networkId: string,
        format = 'text/csv',
        params: HttpParams = new HttpParams()
    ): Observable<any> {
        params = params.set('networkId', networkId);
        const headers = new HttpHeaders().set('Accept', format);
        return this.http.get(this.environment.v2apiUrl + 'endpoints', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    /**
     * Function for sharing the registration information
     */
    public share(emailParams: any) {
        // make a POST request with the path and the email params
        return this.customQuery(HttpMethod.POST, '/share', { body: [emailParams] });
    }

    public findEndpointsOptionalIncludeProperties(
        networkId: string,
        size = 1,
        page = 0,
        includeProperties: any
    ): Observable<any> {
        const params = new HttpParams()
            .set('networkId', networkId)
            .set('size', size)
            .set('page', page)
            .set('sort', 'name,asc');
        const headersA = new HttpHeaders().set('Accept', `application/json;includeProperties=${includeProperties}`);
        const headersB = new HttpHeaders().set('Accept', `application/json;`);
        return this.http.get(this.environment.v2apiUrl + 'endpoints', {
            headers: includeProperties ? headersA : headersB,
            params: params,
            responseType: 'text',
        });
    }
}
