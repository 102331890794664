<!--  <div class="title">Open Alarms <span id="AlarmCount" class="counter">{{ alarmCount }}</span></div>-->
<div id="AlarmList">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div *ngIf="alarms.length === 0 && !isLoading && canReadAlarms" class="noData">No Data</div>
    <div *ngIf="alarms.length === 0 && !isLoading && !canReadAlarms" class="noData">No Permissions</div>
    <div *ngIf="alarms.length > 0 && !isLoading" id="AlarmContainer">
        <div
            (click)="viewAlarm(alarm)"
            *ngFor="let alarm of alarms; trackBy: trackById"
            class="alarmItem"
            style="cursor: pointer"
        >
            <div [ngClass]="'sev' + alarm.severity" class="sev"></div>
            <div class="cut" title="{{ alarm.description }}">{{ alarm.code }}</div>
            <div class="alarmDate" title="{{ formatTime(alarm.timestamp) }}">
                {{ formatTime(alarm.timestamp) }}
            </div>
        </div>
    </div>
</div>
