import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdvancedPlatformServiceFormComponent } from '@netfoundry-ui/feature/form/advanced-platform-service-form';
import { PlatformServiceFormComponent } from '@netfoundry-ui/feature/form/platform-service-form';
import { FeatureService } from '@netfoundry-ui/shared/services';
import { AdvancedServiceConfigFormComponent } from '@netfoundry-ui/feature/form/advanced-service-config-form';

@Component({
    selector: 'app-service-cards',
    templateUrl: './service-cards.component.html',
    styleUrls: ['./service-cards.component.scss'],
})
export class ServiceCardsComponent {
    serviceFormDialogRef;

    @Output() refresh = new EventEmitter<any>();

    constructor(
        private dialogRef: MatDialogRef<ServiceCardsComponent>,
        public dialogForm: MatDialog,
        public featureService: FeatureService
    ) {}

    chooseSimpleServiceForm($event) {
        this.dialogRef.close();
        this.serviceFormDialogRef = this.dialogForm.open(PlatformServiceFormComponent, {
            data: {},
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.serviceFormDialogRef.afterClosed().subscribe((result) => {
            this.refresh.emit(null);
        });
    }

    chooseAdvancedServiceForm($event) {
        this.dialogRef.close();
        this.serviceFormDialogRef = this.dialogForm.open(AdvancedPlatformServiceFormComponent, {
            data: {},
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.serviceFormDialogRef.afterClosed().subscribe((result) => {
            this.refresh.emit(null);
        });
    }

    chooseAdvancedConfigurationForm($event) {
        if (!this.dialogRef) {
            return;
        }

        this.dialogRef.close();
        this.serviceFormDialogRef = this.dialogForm.open(AdvancedServiceConfigFormComponent, {
            data: {},
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.serviceFormDialogRef.afterClosed().subscribe((result) => {
            this.refresh.emit(null);
        });
    }

    hide() {
        this.dialogRef.close();
    }
}
