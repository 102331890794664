import { ClientSyncResource } from './client-sync-resource';

export class AzureActiveDirectory extends ClientSyncResource {
    // gateways - more to come
    public applicationId: string;
    public secretKey: string;
    public tenantId: string;

    constructor(object) {
        super(object);
        this.provider = 'AAD';
        this.supportsDeviceSync = true;
        this.supportsUserSync = true;
    }

    // Map of codes with labels
    public getClientNameFields(options?: AADOptions) {
        if (options && options.deviceLabels) {
            return [
                { name: 'Device ID', value: 'id' },

                { name: 'Device Display Name', value: 'displayName' },
            ];
        }

        return [
            { name: 'User ID', value: 'id' },
            { name: 'Display Name', value: 'displayName' },
            { name: 'User Principal name', value: 'userPrincipalName' },
            { name: 'Email', value: 'mail' },
        ];
    }

    public getSaveObject() {
        return {
            provider: this.provider,
            applicationId: this.applicationId,
            secretKey: this.secretKey,
            tenantId: this.tenantId,
        };
    }
}

export class AADOptions {
    deviceLabels = false;
}
