<div class="cell-details-container">
    <div
        (click)="toggleRowData($event)"
        *ngIf="showToggle"
        [ngClass]="{ 'user-rows-open': rowExpanded, 'user-rows-closed': !rowExpanded }"
        attr.name="RowActions_{{ item.name }}"
        class="actions"
        id="RowActions_{{ item.id }}"
    >
        {{ rowExpanded ? this.labelOpen : this.labelClosed }}
        <span
            [ngClass]="{ 'ag-icon-tree-open': rowExpanded, 'ag-icon-tree-closed': !rowExpanded }"
            class="ag-icon"
            role="presentation"
            unselectable="on"
        ></span>
    </div>
</div>
