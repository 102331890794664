import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('executions', { routeName: 'networkResourceRoute' })
export class ProcessExecution extends Resource {
    id: string;
    type: string;
    name: string;
    attributes: string[];
    processTree: any;
    expanded: any;
    description: any;

    /**
     * this field is used for the POST/PUT.
     * It will not be populated on the generic find for posture checks
     * It will however show up when doing a GET for a specific posture check
     */
    data: any;
    processId: any;
    processRecord: any;
    started: any;
    finished: any;
    state: string;
    networkId: string;

    ownerIdentityId: string;
    createdBy: string;
    createdAt: string;
    deletedBy: string;
    deletedAt: string;
    selected = false;
    steps: any;
}
