import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { NetworkVersionService } from './network-version.service';

@Injectable({ providedIn: 'root' })
export class GetCloudformationLinkService {
    constructor(
        private logger: LoggerService,
        private apiService: ApiService,
        private networkVersionService: NetworkVersionService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    // function for obtaining the link to launch the cloudformation script
    getCfLink(model, regionId) {
        let networkVersion = '&param_NetworkVersion=V';
        if (this.networkVersionService.currentNetworkVersion < 4) {
            networkVersion += '4';
        } else {
            networkVersion += this.networkVersionService.currentNetworkVersion;
        }

        // build the link in pieces for readability and to pass linting
        const awsURL = 'https://console.aws.amazon.com/cloudformation/home#/stacks/create/review';
        const templateURL = 'https://netfoundry-aws-quickstart.s3.amazonaws.com/production/nf-gw-standalone.template';
        const stackName = `&stackName=${model.name}`;
        const regKey = `&param_GatewayRegistrationKey=${model['registrationKey']}`;
        const region = `&region=${regionId}`;
        const url = `${awsURL}?templateURL=${templateURL}${stackName}${regKey}${region}${networkVersion}`;
        this.logger.info('CF Launch URL', url);
        return url;
    }

    getAutoScaleCfLink(model, regionId) {
        let networkVersion = '&param_NetworkVersion=V';
        if (this.networkVersionService.currentNetworkVersion < 4) {
            networkVersion += '4';
        } else {
            networkVersion += this.networkVersionService.currentNetworkVersion;
        }

        // build the link in pieces for readability and to pass linting
        const awsURL = 'https://console.aws.amazon.com/cloudformation/home#/stacks/create/review';
        const templateURL = 'https://netfoundry-aws-quickstart.s3.amazonaws.com/production/nf-gw-autoscaling.template';
        const stackName = `&stackName=${model.name}`;
        const region = `&region=${regionId}`;
        const environmentName =
            this.environment.domain === 'localhost' || this.environment.domain === 'uiux'
                ? 'sandbox'
                : this.environment.domain;
        const environmentParam = `&param_Environment=${environmentName}`;
        const clusterId = `&param_ClusterId=${model.id}`;
        const networkId = `&param_NetworkId=${this.apiService.theNetworkIs.id}`;
        const clientId = `&param_ClientId=${model.clientId}`;
        const clientSercret = `&param_ClientSecret=${model.clientSecret}`;
        const url = `${awsURL}?templateURL=${templateURL}${stackName}${region}${environmentParam}${clusterId}${networkId}${clientId}${clientSercret}${networkVersion}`;
        this.logger.info('CF Launch URL', url);
        return url;
    }
}
