import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-signup-return',
    templateUrl: './signup-return.component.html',
    styleUrls: ['./signup-return.component.scss'],
})
export class SignupReturnComponent implements OnInit {
    isLoading = true;

    constructor() {}

    ngOnInit(): void {
        this.isLoading = true;
    }
}
