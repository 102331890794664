import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LottieModule } from 'ngx-lottie';
import { LoaderComponent } from './loader.component';
import { LoadingIndicatorComponent } from './loading-indicator.component';

export function playerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
    declarations: [LoaderComponent, LoadingIndicatorComponent],
    exports: [LoaderComponent, LoadingIndicatorComponent],
    imports: [CommonModule, LottieModule.forRoot({ player: playerFactory })],
})
export class UiLoaderModule {}
