import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ZShareData } from '@netfoundry-ui/shared/model';
import { Subject } from 'rxjs';
import { ZitiShareComponent } from './ziti-share.component';

@Injectable({
    providedIn: 'root',
})
export class ZitiShareService {
    dialogRef;
    private data = new Subject<ZShareData>();
    public data$ = this.data.asObservable();

    constructor(public dialogForm: MatDialog) {}

    public show(data: ZShareData) {
        this.dialogRef = this.dialogForm.open(ZitiShareComponent, {
            data: data,
            height: '350px',
            width: '640px',
            autoFocus: false,
        });
    }
}
