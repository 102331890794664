import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { IamService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { ErrorHistoryService, FeatureService, HTTP_CLIENT, LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-userinfo',
    templateUrl: './userinfo.component.html',
    styleUrls: ['./userinfo.component.scss'],
})
export class UserinfoComponent implements OnInit {
    email = '';
    nickname = '';
    photo = '';
    canReset = false;
    experimentalFeatures = false;
    showFeatureToggle = false;
    user: any = { firstName: '', lastName: '' };
    errorFirstName = false;
    errorLastName = false;
    processing = false;
    isLoading = false;
    private data: GrowlerData = new GrowlerData();

    constructor(
        private logger: LoggerService,
        private growlService: GrowlerService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        private iamService: IamService,
        public featureService: FeatureService,
        private errorHistoryService: ErrorHistoryService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    private static getHeaders() {
        return new HttpHeaders().set('content-type', 'application/json');
    }

    async ngOnInit() {
        if (this.featureService.allowHiddenFeatures()) {
            this.showFeatureToggle = true;
        }

        this.email = localStorage.getItem('profile_email');
        this.nickname = localStorage.getItem('profile_nick');
        this.photo = localStorage.getItem('profile_picture');

        this.canResetPassword();

        await this.getUser();
    }

    resetPassword() {
        const url = `https://${this.environment.authconfig.domain}/dbconnections/change_password`;
        const postjson = {
            client_id: this.environment.authconfig.clientID,
            email: localStorage.getItem('profile_email'),
            connection: localStorage.getItem('connectionId'),
        };

        this.http.post(url, JSON.stringify(postjson), { headers: UserinfoComponent.getHeaders() }).subscribe(
            (res) => {
                this.logger.info('Password reset response', res);
            },
            (error) => {
                this.errorHistoryService.addError(error.message);
            }
        );

        this.data.title = 'Updated';
        this.data.subtitle = 'Password Reset';
        this.data.level = 'success';
        this.data.content =
            'You will receive an email to reset your password at ' + localStorage.getItem('profile_email');
        this.growlService.show(this.data);
    }

    // @TODO - will need to tweak this eventually once the API adds flags to the provider records
    canResetPassword() {
        // If there is no profile email for this user, there will definitely not be an option to reset by email
        if (!localStorage.getItem('profile_email') || localStorage.getItem('profile_email') === '') {
            return;
        }

        if (localStorage.getItem('connectionId').match(/password/gi)) {
            this.canReset = true;
        }
    }

    toggleExperimentalFeatures() {
        this.experimentalFeatures = !this.experimentalFeatures;
        this.featureService.setExperimentalFeatures(this.experimentalFeatures);
    }

    save() {
        if (this.validate()) {
            this.isLoading = true;
            this.iamService.update('user-identities', this.user.id, this.user).subscribe(
                () => {
                    this.getUser();
                    this.growlService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Profile Information Saved',
                            'Your profile information has been updated.'
                        )
                    );
                },
                () => {
                    this.getUser();
                    this.growlService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Failed to Save Profile Information',
                            'There was an issue when saving your profile information, no changes have been made.'
                        )
                    );
                }
            );
        }
    }

    validate() {
        this.errorFirstName = false;
        this.errorLastName = false;

        if (this.user.firstName.length > 128) {
            this.errorFirstName = true;
        }

        if (this.user.lastName.length < 1 || this.user.lastName.length > 128) {
            this.errorLastName = true;
        }
        return !this.errorFirstName && !this.errorLastName;
    }

    async getUser() {
        await this.iamService
            .get('identities', 'self')
            .toPromise()
            .then((identity) => {
                this.user = identity;
                this.isLoading = false;
            });
    }
}
