import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    AppWanServiceV2,
    AttributesService,
    AttributesServiceResults,
    EdgeRouterPolicyServiceV2,
    NETWORK_SERVICE,
    NetworkServiceV2,
    PlatformServiceService,
    PostureCheckService,
    ServiceServiceV2,
} from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { ZitiOptionsService } from '@netfoundry-ui/shared/helpers';
import {
    AppWanV2,
    DialBind,
    NetworkV2,
    PagedAttributes,
    PostureCheck,
    ServicePolicy,
    SimpleAttribute,
} from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, LoggerService, ValidateService } from '@netfoundry-ui/shared/services';
import _ from 'lodash';

declare const gtag;

@Component({
    selector: 'app-ziti-appwan',
    templateUrl: './ziti-appwan.component.html',
    styleUrls: ['./ziti-appwan.component.scss'],
})
export class ZitiAppwanComponent implements OnInit {
    public model: AppWanV2 = new AppWanV2();
    public modelSP: ServicePolicy = new ServicePolicy();
    public dialBindTypes = DialBind;
    type: DialBind = DialBind.Dial;
    endpointAttributes = new PagedAttributes();
    serviceAttributes = new PagedAttributes();
    postureCheckAttributes = new PagedAttributes();
    selectedEndpointAttributes = new PagedAttributes();
    selectedServiceAttributes = new PagedAttributes();
    selectedPostureCheckAttributes = new PagedAttributes();
    isComplete = false;
    processing = false;
    hideHelp = false;
    isInline = false;
    currentNetwork: NetworkV2;
    isEditing = false;
    bulkEdit = false;
    // to display in the preview section
    v2Services: string[] = [];
    servicePreviewList: string[] = [];
    v2Endpoints = [];
    v2PostureChecks = [];
    allEndpoints = [];
    allServices = [];
    allPostureChecks: SimpleAttribute[] = [];
    canEdit = false;
    errors = {};
    epAttributeError = false;
    pcAttributeError = false;
    mfaEnabled = false;
    endpointAttributeError = false;
    serviceAttributeError = false;
    showMFAEnabled = true;
    isSupported;
    isLoadingEndpointAttr = false;
    isLoadingSvcAttr = false;
    isLoadingPostureCheckAttr = false;
    svcError = false;
    pcError = false;
    endpointError = false;
    isServicesPreview: boolean;
    isPostureCheckPreview: boolean;
    isEndpointsPreview: boolean;
    isLoading: boolean;
    isAllEndpoints = false;
    isAllServices = false;
    isAllPostureChecks = false;
    private isHiding = false;
    private conflictsOnly = false;

    constructor(
        private logger: LoggerService,
        private dialogRef: MatDialogRef<ZitiAppwanComponent>,
        private appwanV2Service: AppWanServiceV2,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private edgeRouterPolicyServiceV2: EdgeRouterPolicyServiceV2,
        private serviceService: ServiceServiceV2,
        public growlerService: GrowlerService,
        private authorizationService: AuthorizationService,
        private validateService: ValidateService,
        private postureCheckService: PostureCheckService,
        private platformServiceService: PlatformServiceService,
        private optionsService: ZitiOptionsService,
        public featureService: FeatureService,
        private attributesService: AttributesService,
        private apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private renderer: Renderer2
    ) {}

    ngOnInit() {
        this.isLoading = true;
        this.initModel();
        this.currentNetwork = this.apiService.currentNetwork.getValue();
        if (this.currentNetwork) {
            this.model.networkId = this.currentNetwork.id;

            this.checkFeatureSupport();
            this.initializeServiceAttributeSelector();
            this.initializeEndpointSelector();
            this.initializePostureCheckSelector();
        }
        this.initTutorial();
    }

    hide(resposne?) {
        this.dialogRef.close(resposne);
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
        this.hide();
    }

    async checkFeatureSupport() {
        this.isSupported = false;
        try {
            const networkFeatures = JSON.parse(sessionStorage.getItem('networkFeatures'));
            this.isSupported = _.some(
                networkFeatures,
                (feature: any) => feature.displayName === 'posture-check.mfa.timeout-seconds'
            );
        } catch (e) {
            this.isSupported = false;
        }
        // show the mfa enabled toggle if the new mfa posture check stuff is not supported
        this.showMFAEnabled = !this.isSupported;

        // if the attribtutes already include @zitimfa and new posture check stuff is not supported take out @zitimfa from being shown in UI
        const pcAttrs = _.get(this.model, 'postureCheckAttributes', []);
        if (pcAttrs.includes('@zitimfa') && !this.isSupported) {
            this.mfaEnabled = true;
        }
    }

    mfaEnableToggle() {
        this.mfaEnabled = !this.mfaEnabled;
        if (this.mfaEnabled) {
            this.model.postureCheckAttributes.push('@zitimfa');
        } else {
            const indexOfAttribute = this.model.postureCheckAttributes.indexOf('@zitimfa');
            if (indexOfAttribute >= 0) {
                this.model.postureCheckAttributes.splice(indexOfAttribute, 1);
            }
        }
    }

    async save(keyUp = false) {
        if (keyUp && this.apiService.tutorialActive) {
            return;
        }
        this.model.endpointAttributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        this.model.serviceAttributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        this.model.postureCheckAttributes = Array.from(this.selectedPostureCheckAttributes.mappedAtrributes.keys());

        if (await this.validate()) {
            this.processing = true;
            if (this.bulkEdit) {
                if (this.mfaEnabled && !this.isSupported) {
                    this.model.postureCheckAttributes.push('@zitimfa');
                }
                this.dialogRef.close();
            } else if (this.model.id) {
                // updating

                this.appwanV2Service.updateResource(this.model).subscribe(
                    () => {
                        this.processing = false;
                        this.hide({ updatedAppWan: this.model });
                    },
                    (httpErrorResponse) => {
                        this.processing = false;
                        this.logger.error('Error from appwan patch', httpErrorResponse);
                        this.growlerService.show(
                            new GrowlerData('error', 'AppWan patch failed. ', httpErrorResponse.error.errors[0])
                        );
                    }
                );
            } else {
                if (this.mfaEnabled && !this.isSupported) {
                    this.model.postureCheckAttributes.push('@zitimfa');
                } else {
                    const indexOfAttribute = this.model.postureCheckAttributes.indexOf('@zitimfa');
                    if (indexOfAttribute >= 0) {
                        this.model.postureCheckAttributes.splice(indexOfAttribute, 1);
                    }
                }

                if (gtag)
                    gtag('event', 'click', {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        event_category: 'appwan',
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        event_label: 'create',
                        value: JSON.stringify(this.model),
                    });
                this.appwanV2Service.createResource({ body: this.model }).subscribe(
                    (data) => {
                        this.processing = false;
                        this.dialogRef.close();
                        this.apiService.setLatestAppWAN(data);
                    },
                    (httpErrorResponse) => {
                        this.processing = false;
                        this.growlerService.show(
                            new GrowlerData('error', 'AppWan creation failed. ', httpErrorResponse.error.errors[0])
                        );
                    }
                );
            }
        }
    }

    async validate() {
        this.errors = {};
        this.serviceAttributeError = false;
        this.epAttributeError = false;
        this.pcAttributeError = false;
        if (!this.model.id) {
            const errors = await this.appwanV2Service.validateCreate(this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.serviceAttributes')) {
                this.serviceAttributeError = true;
                this.errors['serviceAttributes'] = _.find(errors, (e) => e.path === '$.serviceAttributes').message;
            }
            if (_.find(errors, (e) => e.path === '$.endpointAttributes')) {
                this.epAttributeError = true;
                this.errors['endpointAttributes'] = _.find(errors, (e) => e.path === '$.endpointAttributes').message;
            }
            if (_.find(errors, (e) => e.path === '$.postureCheckAttributes')) {
                this.pcAttributeError = true;
                this.errors['postureCheckAttributes'] = _.find(
                    errors,
                    (e) => e.path === '$.postureCheckAttributes'
                ).message;
            }
            return errors.length === 0;
        } else {
            const errors = await this.appwanV2Service.validateUpdate(this.model.id, this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.serviceAttributes')) {
                this.serviceAttributeError = true;
                this.errors['serviceAttributes'] = _.find(errors, (e) => e.path === '$.serviceAttributes').message;
            }
            if (_.find(errors, (e) => e.path === '$.endpointAttributes')) {
                this.epAttributeError = true;
                this.errors['endpointAttributes'] = _.find(errors, (e) => e.path === '$.endpointAttributes').message;
            }
            if (_.find(errors, (e) => e.path === '$.postureCheckAttributes')) {
                this.pcAttributeError = true;
                this.errors['postureCheckAttributes'] = _.find(
                    errors,
                    (e) => e.path === '$.postureCheckAttributes'
                ).message;
            }
            return errors.length === 0;
        }
    }

    addEndpointsAttributes(newAttribute) {
        const success = this.attributesService.addAttributes(newAttribute, [], this.selectedEndpointAttributes);

        if (success) this.selectedEndpointAttributes = success as PagedAttributes;
        else this.endpointAttributeError = true;
        const selList = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.endpointAttributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        this.validate();
        this.getEndpointPreviewList(namedList, toBeExploded);
    }

    addPostureCheckAttribute(newAttribute) {
        const success = this.attributesService.addAttributes(newAttribute, [], this.selectedPostureCheckAttributes);

        if (success) this.selectedPostureCheckAttributes = success as PagedAttributes;
        else this.pcError = true;
        const selList = Array.from(this.selectedPostureCheckAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.postureCheckAttributes = Array.from(this.selectedPostureCheckAttributes.mappedAtrributes.keys());
        this.validate();
        this.getPostureCheckPreviewList(namedList, toBeExploded);
    }

    addServiceAttribute(newAttribute) {
        const success = this.attributesService.addAttributes(newAttribute, [], this.selectedServiceAttributes);

        if (success) this.selectedServiceAttributes = success as PagedAttributes;
        else this.svcError = true;
        const selList = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.serviceAttributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        this.validate();
        this.getServicePreviewList(namedList, toBeExploded);
    }

    removeServiceAttribute(oldAttribute) {
        this.selectedServiceAttributes = this.attributesService.removeAttribute(
            oldAttribute,
            this.selectedServiceAttributes
        );
        const selList = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.serviceAttributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        this.validate();
        this.getServicePreviewList(namedList, toBeExploded);
    }

    removeEndpointAttribute(oldAttribute) {
        this.selectedEndpointAttributes = this.attributesService.removeAttribute(
            oldAttribute,
            this.selectedEndpointAttributes
        );
        const selList = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.endpointAttributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        this.validate();
        this.getEndpointPreviewList(namedList, toBeExploded);
    }

    removePostureCheckAttribute(oldAttribute) {
        this.selectedPostureCheckAttributes = this.attributesService.removeAttribute(
            oldAttribute,
            this.selectedPostureCheckAttributes
        );
        const selList = Array.from(this.selectedPostureCheckAttributes.mappedAtrributes.keys());
        const toBeExploded = selList.filter((rec) => rec.charAt(0) !== '@');
        const namedList = selList.filter((rec) => rec.charAt(0) === '@').map((rec) => rec.replace('@', ''));
        this.model.postureCheckAttributes = Array.from(this.selectedPostureCheckAttributes.mappedAtrributes.keys());
        this.validate();
        this.getPostureCheckPreviewList(namedList, toBeExploded);
    }

    postProcessDisplay = (elements) => {
        if (
            elements &&
            (this.data.model.interceptConflicts.length > 0 || this.data.model.loopbackConflicts.length > 0)
        ) {
            if (elements.beforeCounterEl) {
                const img = this.renderer.createElement('img');
                this.renderer.setAttribute(img, 'src', '/assets/svgs/Conflict-Intercept.svg');
                this.renderer.setStyle(img, 'width', '1.5rem');
                while (elements.beforeCounterEl.nativeElement.lastElementChild) {
                    elements.beforeCounterEl.nativeElement.removeChild(
                        elements.beforeCounterEl.nativeElement.lastElementChild
                    );
                }
                this.renderer.appendChild(elements.beforeCounterEl.nativeElement, img);
                img.addEventListener('click', this.toggleConflicted.bind(this));
            }
            elements.listViews.forEach((el) => {
                const name = el.nativeElement.innerText;
                if (this.data.model?.interceptConflicts.length > 0) {
                    for (let idx = 0; idx < this.data.model.interceptConflicts.length; idx++) {
                        const conflict = this.data.model.interceptConflicts[idx];
                        if (name === conflict.fromServiceName || name === conflict.toServiceName) {
                            this.renderer.setStyle(el.nativeElement, 'color', '#f49c00');
                            const img = this.renderer.createElement('img');
                            this.renderer.setAttribute(img, 'src', '/assets/svgs/Conflict-Intercept.svg');
                            this.renderer.setStyle(img, 'width', '1.25rem');
                            this.renderer.setStyle(img, 'float', 'right');
                            while (el.nativeElement.lastElementChild) {
                                el.nativeElement.removeChild(el.nativeElement.lastElementChild);
                            }
                            this.renderer.appendChild(el.nativeElement, img);
                            break;
                        }
                    }
                }
                if (this.data.model?.loopbackConflicts?.length > 0) {
                    for (let idx = 0; idx < this.data.model.loopbackConflicts.length; idx++) {
                        const conflict = this.data.model.loopbackConflicts[idx];
                        if (name === conflict.fromServiceName || name === conflict.toServiceName) {
                            this.renderer.setStyle(el.nativeElement, 'color', 'red');
                            const img = this.renderer.createElement('img');
                            this.renderer.setAttribute(img, 'src', '/assets/svgs/Conflict-intercept.svg');
                            this.renderer.setStyle(img, 'width', '1.25rem');
                            this.renderer.setStyle(img, 'float', 'right');
                            while (el.nativeElement.lastElementChild) {
                                el.nativeElement.removeChild(el.nativeElement.lastElementChild);
                            }
                            this.renderer.appendChild(el.nativeElement, img);
                            break;
                        }
                    }
                }
            });
        }
    };

    toggleConflicted = () => {
        this.conflictsOnly = !this.conflictsOnly;
        if (this.conflictsOnly) {
            const filtered: string[] = [];
            this.v2Services.forEach((name) => {
                if (this.data.model?.interceptConflicts.length > 0) {
                    for (let idx = 0; idx < this.data.model.interceptConflicts.length; idx++) {
                        const conflict = this.data.model.interceptConflicts[idx];
                        if (name === conflict.fromServiceName || name === conflict.toServiceName) {
                            filtered.push(name);
                            break;
                        }
                    }
                }
                if (this.data.model?.loopbackConflicts?.length > 0) {
                    for (let idx = 0; idx < this.data.model.loopbackConflicts.length; idx++) {
                        const conflict = this.data.model.loopbackConflicts[idx];
                        if (name === conflict.fromServiceName || name === conflict.toServiceName) {
                            if (filtered.indexOf(name) < 0) filtered.push(name);
                        }
                    }
                }
            });

            this.servicePreviewList = filtered;
        } else {
            this.servicePreviewList = this.v2Services;
        }
    };

    private initModel() {
        if (this.data.model) {
            this.model = this.data.model;
            this.bulkEdit = this.data.bulkEdit;
            this.isEditing = !this.bulkEdit;

            this.canEdit = this.authorizationService.canUpdateAppWan(this.model.id);
        }
        if (!this.model.endpointAttributes) {
            this.model.endpointAttributes = [];
        }
        if (!this.model.serviceAttributes) {
            this.model.serviceAttributes = [];
        }
        if (!this.model.postureCheckAttributes) {
            this.model.postureCheckAttributes = [];
        }
        if (this.bulkEdit) {
            this.validate();
        }
    }

    private initTutorial() {
        if (this.apiService.tutorialActive) {
            this.apiService.tutorialAppWAN.subscribe((aw: AppWanV2) => {
                this.model.name = aw.name;
                const svcAttrs = _.cloneDeep(aw.serviceAttributes);
                const epAttrs = _.cloneDeep(aw.endpointAttributes);
                if (_.isEmpty(this.model.serviceAttributes)) {
                    this.model.serviceAttributes = undefined;
                }
                if (_.isEmpty(this.model.endpointAttributes)) {
                    this.model.endpointAttributes = undefined;
                }
                _.delay(() => {
                    this.model.serviceAttributes = svcAttrs;
                    this.model.endpointAttributes = epAttrs;

                    if (!_.isEmpty(this.model.serviceAttributes)) {
                        this.getSelectedServices();
                    }
                    if (!_.isEmpty(this.model.endpointAttributes)) {
                        this.getSelectedEndpoints();
                    }
                }, 200);
            });
        }
    }

    private initializePostureCheckSelector() {
        this.getSelectedPostureChecks();
        this.isLoadingPostureCheckAttr = true;
        this.pcError = false;
        this.attributesService
            .getPostureCheckAttributes(this.currentNetwork)
            .then((results: AttributesServiceResults) => {
                if (results) {
                    this.allPostureChecks = Array.from(results?.namedAttributes.mappedAtrributes.values());
                    if (this.isAllPostureChecks) {
                        // second chance to process #all
                        this.v2PostureChecks = this.allPostureChecks.map((rec) => rec.name.substr(1));
                    }
                    const collector = results.namedAttributes;
                    for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                        collector.mappedAtrributes.set(sa.name, sa);
                    }
                    this.postureCheckAttributes = collector;
                    this.processMFA();
                }
            })
            .catch((err) => {
                this.pcError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingPostureCheckAttr = false;
                this.isLoading = this.isLoadingSvcAttr || this.isLoadingEndpointAttr || this.isLoadingPostureCheckAttr;
            });
    }

    private processMFA() {
        const mfaChecks = this.allPostureChecks.filter((sa) => sa['type'] === 'MFA');

        if (mfaChecks.length === 0) {
            const postureCheckModel = new PostureCheck();
            postureCheckModel.type = 'MFA';
            postureCheckModel.name = 'zitimfa';
            postureCheckModel.networkId = this.currentNetwork.id;
            postureCheckModel.data = {};
            postureCheckModel.attributes = [];
            this.postureCheckService.createResource({ body: postureCheckModel }).toPromise();
        }
    }

    private initializeServiceAttributeSelector() {
        this.isLoadingSvcAttr = true;
        this.getSelectedServices();
        this.svcError = false;
        this.attributesService
            .getServiceAttributes(this.currentNetwork)
            .then((results: AttributesServiceResults) => {
                if (results) {
                    const collector = results.namedAttributes;

                    this.allServices = Array.from(results.namedAttributes.mappedAtrributes.values());
                    if (this.isAllServices) {
                        // second chance to process #all
                        this.v2Services = this.allServices.map((rec) => rec.name.substr(1));
                    }
                    for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                        collector.mappedAtrributes.set(sa.name, sa);
                    }
                    this.serviceAttributes = collector;
                }
            })
            .catch((err) => {
                this.svcError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.servicePreviewList = this.v2Services;
                this.isLoadingSvcAttr = false;
                this.isLoading = this.isLoadingSvcAttr || this.isLoadingEndpointAttr || this.isLoadingPostureCheckAttr;
            });
    }

    private initializeEndpointSelector() {
        this.isLoadingEndpointAttr = true;
        this.getSelectedEndpoints();
        this.endpointError = false;
        this.attributesService
            .getEndpointAttributes(this.currentNetwork)
            .then((results: AttributesServiceResults) => {
                if (results) {
                    const collector = results.namedAttributes;
                    this.allEndpoints = Array.from(results.namedAttributes.mappedAtrributes.values());
                    if (this.isAllEndpoints) {
                        // second chance to process #all
                        this.v2Endpoints = this.allEndpoints.map((rec) => rec.name.substr(1));
                    }
                    for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                        collector.mappedAtrributes.set(sa.name, sa);
                    }
                    this.endpointAttributes = collector;
                }
            })
            .catch((err) => {
                this.endpointError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingEndpointAttr = false;
                this.isLoading = this.isLoadingSvcAttr || this.isLoadingEndpointAttr || this.isLoadingPostureCheckAttr;
            });
    }

    private getSelectedServices() {
        const selectedServices = new PagedAttributes();
        const toBeExploded = [];
        const namedList = [];
        if (this.model.serviceAttributes) {
            this.model.serviceAttributes.forEach((att) => {
                selectedServices.mappedAtrributes.set(att, {
                    name: att,
                    isGroup: att.charAt(0) === '#',
                    isNamed: att.charAt(0) === '@',
                });
                if (att.charAt(0) === '#') {
                    toBeExploded.push(att);
                } else {
                    namedList.push(att.replace('@', ''));
                }
            });
        }
        this.selectedServiceAttributes = selectedServices;
        this.getServicePreviewList(namedList, toBeExploded);
    }

    private getServicePreviewList(namedList: string[], toBeExploded: string[]) {
        this.isAllServices = false;
        this.isServicesPreview = true;
        if (toBeExploded.length === 0) {
            this.v2Services = namedList;
            this.servicePreviewList = this.v2Services;
            this.isServicesPreview = false;
        } else if (toBeExploded.indexOf('#all') >= 0) {
            this.v2Services = this.allServices.map((rec) => rec.name.substr(1));
            this.servicePreviewList = this.v2Services;
            this.isServicesPreview = false;
            this.isAllServices = true;
        } else {
            this.attributesService
                .getByAttribute(this.currentNetwork.id, 'services', toBeExploded)
                .then((results) => {
                    let tmpResults = results.map((rec) => rec['name']);
                    tmpResults = [...namedList, ...tmpResults];

                    if (tmpResults?.length > 0) {
                        this.v2Services = tmpResults.sort();
                    }
                })
                .finally(() => {
                    this.servicePreviewList = this.v2Services;
                    this.isServicesPreview = false;
                });
        }
    }

    private getSelectedPostureChecks() {
        const selectedPostureChecks = new PagedAttributes();
        const toBeExploded = [];
        const namedList = [];
        if (this.model.postureCheckAttributes) {
            this.model.postureCheckAttributes.forEach((att) => {
                selectedPostureChecks.mappedAtrributes.set(att, {
                    name: att,
                    isGroup: att.charAt(0) === '#',
                    isNamed: att.charAt(0) === '@',
                });
                if (att.charAt(0) === '#') {
                    toBeExploded.push(att);
                } else {
                    namedList.push(att.replace('@', ''));
                }
            });
        }
        this.selectedPostureCheckAttributes = selectedPostureChecks;
        this.getPostureCheckPreviewList(namedList, toBeExploded);
    }

    private getPostureCheckPreviewList(namedList: string[], toBeExploded: string[]) {
        this.isAllPostureChecks = false;
        this.isPostureCheckPreview = true;
        if (toBeExploded.length === 0) {
            this.v2PostureChecks = namedList;
            this.isPostureCheckPreview = false;
        } else if (toBeExploded.indexOf('#all') >= 0) {
            this.v2PostureChecks = this.allPostureChecks.map((rec) => rec.name.substr(1));
            this.isPostureCheckPreview = false;
            this.isAllPostureChecks = true;
        } else {
            this.attributesService
                .getByAttribute(this.currentNetwork.id, 'posture-checks', toBeExploded)
                .then((results) => {
                    let tmpResults = results['_embedded']['postureCheckList'].map((rec) => rec['name']);
                    tmpResults = [...namedList, ...tmpResults];

                    if (tmpResults?.length > 0) {
                        this.v2PostureChecks = tmpResults.sort();
                    }
                })
                .finally(() => {
                    this.isPostureCheckPreview = false;
                });
        }
    }

    private getSelectedEndpoints() {
        const selectedEndpoints = new PagedAttributes();
        const toBeExploded = [];
        const namedList = [];
        this.model.endpointAttributes.forEach((att) => {
            selectedEndpoints.mappedAtrributes.set(att, {
                name: att,
                isGroup: att.charAt(0) === '#',
                isNamed: att.charAt(0) === '@',
            });
            if (att.charAt(0) === '#') {
                toBeExploded.push(att);
            } else {
                namedList.push(att.replace('@', ''));
            }
        });
        this.selectedEndpointAttributes = selectedEndpoints;
        this.getEndpointPreviewList(namedList, toBeExploded);
    }

    private getEndpointPreviewList(namedList: string[], toBeExploded: string[]) {
        this.isAllEndpoints = false;
        this.isEndpointsPreview = true;
        if (toBeExploded.length === 0) {
            this.v2Endpoints = namedList;
            this.isEndpointsPreview = false;
        } else if (toBeExploded.indexOf('#all') >= 0) {
            this.v2Endpoints = this.allEndpoints.map((rec) => rec.name.substr(1));
            this.isEndpointsPreview = false;
            this.isAllEndpoints = true;
        } else {
            this.attributesService
                .getByAttribute(this.currentNetwork.id, 'endpoints', toBeExploded)
                .then((results) => {
                    const tmpResults = [...namedList, ...results.map((rec) => rec['name'])];

                    if (tmpResults?.length > 0) {
                        this.v2Endpoints = tmpResults.sort();
                    }
                })
                .finally(() => {
                    this.isEndpointsPreview = false;
                });
        }
    }
}
