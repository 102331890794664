import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT, ServicePolicy } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';
import {
    GetOption,
    HateoasResourceOperation,
    PagedGetOption,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { HttpClient } from '@angular/common/http';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ServicePolicyService extends HateoasResourceOperation<ServicePolicy> {
    static defaultPaginationSize = 500;
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;

    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(ServicePolicy);
    }

    getResource(): Observable<ServicePolicy> {
        throw new Error('Do not use: see get getServicePolicy');
    }

    getPage(): Observable<PagedResourceCollection<ServicePolicy>> {
        throw new Error('Do not use: see getServicePolicys');
    }

    getServicePolicy(id, options: GetOption = {}): Promise<ServicePolicy> {
        return super
            .getResource(id, { ...ServicePolicyService.defaultHttpAccept, ...options })
            .toPromise()
            .then((servicePolicy) => servicePolicy);
    }

    async getServicePolicyPage(options?: PagedGetOption, includeProperties?: string): Promise<ServicePolicy[]> {
        let params = { ...ServicePolicyService.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super
            .getPage(params)
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((servicePolicies) => servicePolicies.resources);
    }
}
