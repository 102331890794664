import { Injectable } from '@angular/core';
import { Location } from '@netfoundry-ui/shared/model';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class LocationService extends MopResourceService {
    private locationsSource = new ReplaySubject<Location[]>();
    locations = this.locationsSource.asObservable();

    constructor(protected logger: LoggerService, private apiservice: ApiService) {
        super(logger, apiservice);
    }

    /**
     * Gets all Locations
     */
    get(): Observable<any> {
        this.apiService
            .get('dataCenters')
            .pipe(map(this._extractEmbedded))
            .subscribe((data) => {
                this.locationsSource.next(data);
            });

        return this.locations;
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const locations: Location[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['dataCenters'] !== undefined) {
            for (let i = 0; i < res['_embedded']['dataCenters'].length; i++) {
                const location = res['_embedded']['dataCenters'][i];
                locations.push(new Location(location));
            }
        }

        return locations;
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return null;
    }
}
