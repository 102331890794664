import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Network } from '@netfoundry-ui/shared/model';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-avw-site-gateway-bulk-form',
    templateUrl: './avw-site-gateway-bulk-form.component.html',
    styleUrls: ['./avw-site-gateway-bulk-form.component.scss', '../gatewayform.component.scss'],
})
export class AvwSiteGatewayBulkFormComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

    @Input() isInline = false;
    @Output() back: EventEmitter<boolean> = new EventEmitter();
    @Output() hide: EventEmitter<any> = new EventEmitter();
    currentNetwork = new Network({});
    isLoading = false;
    // create an empty uploader until we have an actual URL
    uploader: FileUploader;
    fileUpload;
    form: FormGroup;
    fileIsReady = false;
    processing = false;
    hideHelp = false;
    // boolean to determine if the window is attempting to hide
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private renderer: Renderer2,
        private apiService: ApiService,
        private growlerService: GrowlerService,
        private formBuilder: FormBuilder
    ) {
        // initialize file upload form
        this.form = this.formBuilder.group({
            networkId: '',
            file: null,
        });

        // initialize the uploader for the drag and drop
        this.uploader = new FileUploader({ url: '' });
    }

    ngOnInit() {
        // azure subscription
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((network) => {
                this.currentNetwork = new Network(network);

                // upload URL
                const uploadOptions = {
                    url: this.currentNetwork.getSelfLink() + '/virtualWanSites',
                    authToken: 'Bearer ' + localStorage.getItem('access_token'),
                    additionalParameter: {
                        networkId: this.apiService.theNetworkIs.getId(),
                    },
                };
                this.uploader = new FileUploader(uploadOptions);
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    download() {
        window.open('/assets/data/Example.csv');
    }

    showDialog() {
        const event = new MouseEvent('click', { bubbles: true });
        this.fileInput.nativeElement.dispatchEvent(event);
    }

    goBack() {
        this.back.emit(true);
    }

    hideForm(response?: unknown) {
        this.hide.emit(response);
    }

    save() {
        this.logger.info('Saving Azure bulk import...');
        this._save_az_bulk();
    }

    _save_az_bulk() {
        // if we used the drag and drop
        if (this.uploader.queue.length > 0) {
            this.processing = true;
            const FileObject = this.uploader.queue[0];
            this.logger.info('Temp file', FileObject);
            this.logger.info('Adding file from queue...', FileObject._file);
            FileObject.upload();
            this.growlerService.show(
                new GrowlerData(
                    'info',
                    'Processing',
                    'Processing Bulk Records',
                    'Processing bulk records, Please Wait...'
                )
            );
            this.fileUpload = FileObject;
            this.isLoading = false;

            FileObject.onComplete = (response, status, headers) => {
                if (status < 300) {
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Creation Complete',
                            'Gateway information has been imported.'
                        )
                    );
                } else {
                    const error = JSON.parse(response);
                    this.logger.info('ERROR MESSAGE', error);
                    let errorMessage = '';
                    if (error != null && error[0] != null && error[0]['message'] != null) {
                        errorMessage = error[0]['message'];
                    }
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Bulk Import Failed',
                            'Bulk import has failed. Import has been canceled. ' + errorMessage
                        )
                    );
                }

                this.processing = false;
                this.uploader.clearQueue();
                this.hideForm();
            };
        } else {
            this.logger.error('No files in queue to upload');
            this.growlerService.show(
                new GrowlerData('error', 'Error', 'Bulk Import Failed', 'No files queued to upload')
            );
        }
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.logger.info('Adding file...', file);
            this.form.get('file').setValue(file);
            this.fileIsReady = true;
            this.uploader.addToQueue([this.form.get('file').value]);
        }
    }

    fileDrop() {
        if (this.uploader.queue.length > 0) {
            this.fileIsReady = true;
        }
    }
}
