import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-aws-splash-screen',
    templateUrl: './aws-splash-screen.component.html',
    styleUrls: ['./aws-splash-screen.component.scss'],
})
export class AwsSplashScreenComponent {
    constructor(private dialogRef: MatDialogRef<AwsSplashScreenComponent>) {}

    hide() {
        this.dialogRef.close();
    }
}
