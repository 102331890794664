import { Component, Inject, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { ApiService, ErrorHistoryService, LoggerService } from '@netfoundry-ui/shared/services';
import { NetworkV2 } from '@netfoundry-ui/shared/model';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime } from 'rxjs/operators';
import { EventTemplateQueryParameter } from '@netfoundry-ui/shared/model';

@Component({
    selector: 'app-online-status',
    templateUrl: './online-status.component.html',
    styleUrls: ['./online-status.component.scss'],
    providers: [ElasticsearchService],
})
export class OnlineStatusComponent implements OnInit, OnDestroy, OnChanges {
    @Input() endpointId = '';
    public eventData: any[] = [];
    public isLoading = false;
    canReadElasticSearch = true;
    currentOrgId: string | null | undefined = null;
    currentNetwork = new NetworkV2();
    endpoint;
    networkGroupId;
    networkId: string | null | undefined;
    isOnline = true;
    isOffline = false;
    lastOnlineTime;
    private query: any = {};
    private subscription: Subscription = new Subscription();
    private initialized = false;

    constructor(
        private logger: LoggerService,
        private authorizationService: AuthorizationService,
        private elasticsearch: ElasticsearchService,
        private apiService: ApiService,
        private errorHistoryService: ErrorHistoryService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.endpoint = data.model;
        this.networkGroupId = data.networkGroupId;
        if (data.model?.lastOnlineAt) {
            this.lastOnlineTime = this.formatTime(data.model.lastOnlineAt);
        }
    }

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrgId = org.getId();

                this.initialized = true;
                this.logger.info('Online/Offline initialized');
            })
        );
        this.subscription.add(
            this.apiService.currentNetwork.pipe(debounceTime(1000)).subscribe((network) => {
                this.initData(network.id);
                this.networkId = network.id;
                this.ngOnChanges();

                this.subscription.add(
                    this.apiService.addedFromAnywhere.subscribe(() => {
                        this.initData(network.id);
                    })
                );
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges() {
        if (!this.initialized || this.networkId === null || this.currentOrgId === null) {
            return;
        }

        this.canReadElasticSearch = this.authorizationService.canReadElasticSearch(this.currentOrgId, this.networkId);

        if (this.canReadElasticSearch) {
            this.getOnlineStatus(this.networkId);
            this.getOnlineOfflineStatus();
        } else {
            this.isLoading = false;
        }
    }

    formatTime(theDate: moment.MomentInput) {
        return moment.utc(theDate).local().format('M/D/YY h:mm a');
    }

    getOptions() {
        const params = [{ key: 'networkId', value: this.currentNetwork.id }];
        return { params: params };
    }

    formatDate(lastOnlineTime: moment.MomentInput) {
        return moment.utc(lastOnlineTime).local().format('MMMM Do YYYY, h:mm');
    }

    getOnlineOfflineStatus() {
        if (this.endpoint?.online === true) {
            this.isOnline = true;
        } else {
            this.isOnline = false;
        }
    }

    public getQuery() {
        const model: any = {
            aggs: {
                last_hit: {
                    top_hits: {
                        _source: 'timestamp',
                        size: 1,
                        sort: [
                            {
                                '@timestamp': {
                                    order: 'desc',
                                },
                            },
                        ],
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            match_all: {},
                        },
                        {
                            match_phrase: {
                                event_type: {
                                    query: 'deleted',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                network_id: {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                nf_endpoint_id: {
                                    query: this.endpoint?.id,
                                },
                            },
                        },
                        {
                            range: {
                                timestamp: {
                                    gte: 'now-90d',
                                    lte: 'now',
                                    format: 'epoch_millis',
                                },
                            },
                        },
                    ],
                },
            },
        };
        return model;
    }

    private initData(networkId: any) {
        this.networkServiceV2.getNetwork(networkId).then((nw: NetworkV2) => {
            this.currentNetwork = nw;
        });
    }

    private getOnlineStatus(networkId: string | null | undefined) {
        if (networkId === null) {
            return;
        }
        if (this.currentOrgId === null) {
            this.logger.info('No org specified');
            return;
        }

        const index = 'ncevents';
        this.logger.info('online status events timeline query', JSON.stringify(this.getQuery()));
        //request parameters
        const eventTemplateParams: EventTemplateQueryParameter = {
            indexName: index,
            networkGroupId: this.currentOrgId + '',
            networkId: this.networkId + '',
            gte: 'now-90d',
            lte: 'now',
            endpointId: this.endpoint?.id,
            size: '0',
        };
        this.logger.info(
            'online status events timeline template: ncevents_endpoint_last_seen_tmpl',
            JSON.stringify(eventTemplateParams)
        );
        this.subscription.add(
            //this.elasticsearch.search(this.currentOrgId ?? '', index, this.getQuery()).subscribe((data) => {
            this.elasticsearch
                .apiTemplateSearch('ncevents_endpoint_last_seen_tmpl', eventTemplateParams)
                .subscribe((data) => {
                    this.eventData = this.elasticsearch.hitsToArray(data, 'online-status');
                    this.logger.info('online-status data', data);
                })
        );
    }
}
