import { ClientSyncResource } from './client-sync-resource';

export class AzureIntuneActiveDirectory extends ClientSyncResource {
    // gateways - more to come
    public applicationId: string;
    public secretKey: string;
    public tenantId: string;

    constructor(object) {
        super(object);
        this.provider = 'INTUNE_AAD';
        this.supportsDeviceSync = true;
        this.supportsUserSync = false;
    }

    // Map of codes with labels
    public getClientNameFields() {
        return [
            { name: 'Device ID', value: 'id' },
            { name: 'Device Name', value: 'deviceName' },
        ];
    }

    public getSaveObject() {
        return {
            provider: this.provider,
            applicationId: this.applicationId,
            secretKey: this.secretKey,
            tenantId: this.tenantId,
        };
    }
}
