import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FeatureZitiModule } from '@netfoundry-ui/feature/ziti';
import { OnlineStatusComponent } from './online-status.component';

@NgModule({
    imports: [CommonModule, MatDialogModule, FeatureZitiModule],
    declarations: [OnlineStatusComponent],
    exports: [OnlineStatusComponent],
})
export class FeatureOnlineStatusModule {}
