import { Component, EventEmitter, Input, OnChanges, Output, Type } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ListItem } from '@netfoundry-ui/shared/model';
import { LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-itemlist',
    templateUrl: './itemlist.component.html',
    styleUrls: ['./itemlist.component.scss'],
})
export class ItemlistComponent implements OnChanges {
    @Input() item: ListItem;
    @Input() isCluster = false;
    @Input() allItems = [];
    @Input() hasToggle = false;
    @Input() hasAdd = false;
    @Input() totalAssociated = 0;
    @Input() checkAuth = false;
    @Input() disableOpen = false;
    @Input() formComponent: Type<any>;

    @Output() deleteEvent: EventEmitter<string[]> = new EventEmitter();
    @Output() addEvent: EventEmitter<string[]> = new EventEmitter();
    @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() changed: EventEmitter<boolean> = new EventEmitter();
    allToggled = false;
    state = 'add';
    isEditing = false;
    allAssigned = false;
    filterString = '';
    hideAddButton = false;
    dialogRef: MatDialogRef<any>;
    private selectedItemIds = [];
    private selectedAddItemIds = [];

    constructor(
        private logger: LoggerService,
        private authorizationService: AuthorizationService,
        public dialogForm: MatDialog
    ) {}

    ngOnChanges() {
        this.hideAddButton = false;
        if (this.checkAuth) {
            const type = this.item.name.toLowerCase();
            if (type === 'services') {
                this.hideAddButton = !this.authorizationService.canCreateServices();
            } else if (this.allItems.length === 0) {
                this.hideAddButton = true;
            } else {
                let noUpdateAuthUnassignedCount = 0;
                for (const item of this.allItems) {
                    if (!this.isAssociated(item.id) && !item['canUpdate']) {
                        noUpdateAuthUnassignedCount++;
                    }
                }
                if (this.allItems.length - this.totalAssociated === noUpdateAuthUnassignedCount) {
                    this.hideAddButton = true;
                }
            }
        }
    }

    public noData(): boolean {
        if (this.item == null) {
            return true;
        }
        if (this.item.items == null) {
            return true;
        }
        return this.item.items.length === 0;
    }

    // toggle for endpoint
    toggle(item: any) {
        item.selected = !item.selected;
        const index = this.selectedItemIds.indexOf(item.id);
        if (index > -1) {
            this.selectedItemIds.splice(index, 1);
        } else {
            this.selectedItemIds.push(item.id);
        }
        if (this.anyToggled()) {
            this.state = 'minus';
        } else {
            this.state = 'add';
        }
    }

    toggleAdd(item: any) {
        item.selected = !item.selected;
        const index = this.selectedAddItemIds.indexOf(item.id);
        if (index > -1) {
            this.selectedAddItemIds.splice(index, 1);
        } else {
            this.selectedAddItemIds.push(item.id);
        }
        if (this.selectedAddItemIds.length > 0) {
            this.state = 'check';
        } else {
            this.state = 'cancel';
        }
    }

    toggleAll() {
        this.allToggled = !this.allToggled;
        this.selectedItemIds = [];
        for (const resource of this.item.items) {
            resource.selected = this.allToggled;
            if (this.allToggled) {
                this.selectedItemIds.push(resource.id);
            }
        }
        if (this.anyToggled()) {
            this.state = 'minus';
        } else {
            this.state = 'add';
        }
    }

    anyToggled() {
        if (this.selectedItemIds.length > 0) {
            if (this.selectedItemIds.length === this.item.items.length) {
                this.allToggled = true;
            }
            return true;
        }
        this.allToggled = false;
        return false;
    }

    doAction() {
        if (this.state === 'add') {
            this.reset();
            this.state = 'cancel';
            this.isEditing = true;
        } else if (this.state === 'cancel') {
            this.reset();
        } else if (this.state === 'minus') {
            this.deleteEvent.emit(this.selectedItemIds);
            this.reset();
        } else if (this.state === 'check') {
            this.addEvent.emit(this.selectedAddItemIds);
            this.reset();
        }
    }

    isAssociated(id) {
        for (const item of this.item.items) {
            if (item.id === id) {
                return true;
            }
        }
        return false;
    }

    reset() {
        for (const item of this.allItems) {
            item.selected = false;
        }
        for (const item of this.item.items) {
            item.selected = false;
        }
        this.selectedItemIds = [];
        this.selectedAddItemIds = [];
        this.state = 'add';
        this.isEditing = false;
    }

    doAdd() {
        const data = {
            gateway: this.item.id,
            gatewaySelfLink: this.item.selfLink,
            inline: true,
            isCluster: this.isCluster,
        };
        this.dialogRef = this.dialogForm.open(this.formComponent, {
            data: data,
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            this.changed.emit(true);
        });
    }

    goto(data) {
        if (!this.disableOpen) {
            const type = this.item.name.toLowerCase();

            this.logger.info('GO TO ', type);

            if (this.formComponent) {
                this.dialogRef = this.dialogForm.open(this.formComponent, {
                    data: { model: data.model, inline: true },
                    minHeight: '100%',
                    minWidth: '100%',
                    height: '100%',
                    width: '100%',
                });
                this.dialogRef.afterClosed().subscribe(() => {
                    this.changed.emit(true);
                });
            }
        } else {
            this.closeEvent.emit(true);
        }

        this.logger.info(`Navigate to ${this.formComponent}`);
    }
}
