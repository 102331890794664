import { Injectable } from '@angular/core';
import { CertificateAuthority, Network } from '@netfoundry-ui/shared/model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class CertificateAuthorityService extends MopResourceService {
    page = null;
    links = null;
    private casSource = new Subject<CertificateAuthority[]>();
    cas = this.casSource.asObservable();
    private apiSub = new Subscription();
    private mapSub = new Subscription();

    /**
     * Gets all CAs for network
     */
    get(
        pageNumber?: number,
        sort?: string,
        filter?: string,
        pageSize?: number,
        endpointTypeString?: string
    ): Observable<any> {
        if (this.apiSub) {
            this.apiSub.unsubscribe();
        }

        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }
        // watch for when the network updates, when it does, update the gateways observable
        this.apiSub = this.apiService.currentNetwork.subscribe((network) => {
            const params = {};

            if (pageNumber != null) {
                params['page'] = pageNumber;
            }

            if (sort != null) {
                params['sort'] = sort;
            }

            if (filter != null) {
                // TODO filter
            }

            if (pageSize != null) {
                params['size'] = pageSize;
            }

            this.mapSub = this.apiService
                .getLinkedResource(new Network(network), 'cas', params)
                .pipe(map(this._extractEmbedded))
                .subscribe((data) => {
                    this.page = data['page'];
                    this.links = data['links'];
                    this.casSource.next(data['cas']);
                });
        });

        // this is an observable that watches for network changes
        return this.cas;
    }

    verify(ca: CertificateAuthority, networkId: string, certificate: any) {
        const body = {
            certificate: certificate,
        };
        return this.apiService.post(`networks/${networkId}/cas/${ca.id}/verify`, body);
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const cas: CertificateAuthority[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['cas'] !== undefined) {
            for (let i = 0; i < res['_embedded']['cas'].length; i++) {
                const ca = res['_embedded']['cas'][i];
                cas.push(new CertificateAuthority(ca));
            }
        }

        // object containing information on pagination such as page number, size, total number of items,  and total number of pages
        let page = null;
        if (res['page'] !== undefined) {
            page = res['page'];
        }

        // list of links for jumping to and from pages
        let links = null;
        if (res['_links'] !== undefined) {
            links = res['_links'];
        }

        return { cas: cas, page: page, links: links };
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return this.getLinkedResourceUrl(this.currentNetworkModel, 'cas');
    }
}
