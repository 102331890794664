import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Network, Notification } from '@netfoundry-ui/shared/model';
import { NotificationService } from '@netfoundry-ui/shared/notification';
import { NetworkService, URLS } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';

import _ from 'lodash';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';

@Component({
    selector: 'app-notifications-form',
    templateUrl: './notifications-form.component.html',
    styleUrls: ['./notifications-form.component.scss'],
})
export class NotificationsFormComponent implements OnInit {
    Notifications = URLS.NOTIFICATIONS;

    errors = {};
    processing = false;
    canEdit = true;
    networksList = [];
    v2NetworksList = [];
    networkId: string;
    emails = '';
    networkGroupName;
    isComplete = false;
    isBuilding;
    hideHelp = false;
    combinedNetworks;
    v6Networks;

    model: Notification = {
        name: '',
        description: '',
        path: {
            domainCode: 'io.netfoundry.network',
            path: [
                {
                    typeCode: 'network',
                    id: '',
                },
            ],
        },
        destination: {
            type: 'Email',
            toEmailAddresses: [],
        },
        condition: {
            type: '',
            matchingCodes: [],
            matchingCategories: [],
        },
    };

    private subscription = new Subscription();

    constructor(
        public notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<NotificationsFormComponent>,
        public networkService: NetworkService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private router: Router,
        public growlerService: GrowlerService,
        public authorizationService: AuthorizationService
    ) {
        if (data['model'] !== undefined) {
            this.model = data['model'];
            this.emails = this.model.destination.toEmailAddresses.join(',');
            this.networkId = this.model.path.path.find((n) => n.typeCode === 'network').id;
        }
    }

    ngOnInit() {
        this.combinedNetworks = [];
        if (!this.authorizationService.canCreateNetworks()) {
            this.canEdit = false;
        }

        this.networkService.get().subscribe((networks) => {
            this.networksList = networks as Network[];

            this.combinedNetworks = this.networksList
                .filter((network) => network.status !== 800)
                .concat(this.v2NetworksList);

            this.selectNetwork(this.networkId);
        });

        return this.networkServiceV2.getNetworksPage({ pageParams: { size: 2000, page: 0 } }).then((networks) => {
            this.v2NetworksList = networks;

            this.combinedNetworks = this.networksList.concat(this.v2NetworksList);

            this.selectNetwork(this.networkId);
        });

        if (this.data.network) {
            this.networkId = this.data.network.id;
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    hide(response?) {
        this.dialogRef.close(response);
    }

    onChange($event) {
        const networkId = $event.target.value;
        this.selectNetwork(networkId);
    }

    selectNetwork(networkId) {
        const network = this.combinedNetworks.find((n) => n.id === networkId);

        const id = network.networkGroupId ? network.networkGroupId : network.organizationId;

        this.model.path.path = [
            {
                id,
                typeCode: 'network-group',
            },
            {
                id: network.id,
                typeCode: 'network',
            },
        ];
    }

    save() {
        if (!this.validate(this.model)) {
            return;
        }
        if (this.model.id) {
            this.processing = true;
            this.model.destination.toEmailAddresses = this.emails.split(',');
            this.subscription.add(
                this.notificationService.update(this.model.id, this.model).subscribe(
                    (data: Notification) => {
                        this.model = data;
                        this.dialogRef.close();
                    },
                    (HttpErrorResponse) => {
                        this.processing = false;
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Error creating Notification.',
                                HttpErrorResponse.error.errors[0]
                            )
                        );
                    }
                )
            );
        } else {
            this.processing = true;
            this.model.destination.toEmailAddresses = this.emails.split(',');
            this.subscription.add(
                this.notificationService.create(this.model).subscribe(
                    (data: Notification) => {
                        this.model = data;
                        this.processing = false;
                        this.isComplete = true;
                    },
                    (HttpErrorResponse) => {
                        this.processing = false;
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                'Error creating Notification.',
                                HttpErrorResponse.error.errors[0]
                            )
                        );
                    }
                )
            );
        }
    }

    validate(model) {
        if (_.isEmpty(model)) {
            return false;
        }
        this.errors = {};
        if (_.isEmpty(model.name)) {
            this.errors['name'] = true;
        }
        if (_.isEmpty(_.get(model, 'condition.type'))) {
            this.errors['type'] = true;
        }
        if (_.isEmpty(_.get(model, 'path.path[0].id'))) {
            this.errors['network'] = true;
        }
        return _.isEmpty(this.errors);
    }
}
