import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { ApiService, CertificateAuthorityService, ValidateService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-certificate-authorities-verify-form',
    templateUrl: './certificate-authorities-verify-form.component.html',
    styleUrls: ['./certificate-authorities-verify-form.component.scss'],
})
export class CertificateAuthoritiesVerifyFormComponent implements OnDestroy {
    certificate;

    errorPem;

    subscription: Subscription = new Subscription();

    model;

    processing = false;

    constructor(
        private dialogRef: MatDialogRef<CertificateAuthoritiesVerifyFormComponent>,
        private validateService: ValidateService,
        private certificateAuthorityService: CertificateAuthorityService,
        private apiService: ApiService,
        private growlerService: GrowlerService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.model = data.model;
    }

    validate() {
        this.errorPem = false;
        if (!this.validateService.hasValue(this.certificate)) {
            this.errorPem = true;
        }

        return !this.errorPem;
    }

    verify() {
        if (this.validate()) {
            this.processing = true;
            this.subscription.add(
                this.certificateAuthorityService
                    .verify(this.model, this.apiService.theNetworkIs.id, this.certificate)
                    .subscribe(
                        (result) => {
                            this.growlerService.show(
                                new GrowlerData(
                                    'success',
                                    'Success',
                                    'Attempting to verify CA',
                                    'The CA is attempting to be verified'
                                )
                            );
                            this.processing = false;
                            this.cancel();
                        },
                        (error) => {
                            this.growlerService.show(
                                new GrowlerData(
                                    'error',
                                    'Error',
                                    'Unable to Verify CA',
                                    'An issue is preventing the CA from being verified'
                                )
                            );
                            this.processing = false;
                            this.cancel();
                        }
                    )
            );
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
