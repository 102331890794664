import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Endpoint } from '@netfoundry-ui/shared/model';
import { ApiService, GetCountryService, HTTP_CLIENT } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-country-list',
    templateUrl: './country-list.component.html',
    styleUrls: ['./country-list.component.scss'],
})
export class CountryListComponent implements OnInit {
    worldRegions = [];
    countries = [];
    selectedWorldRegion = null;
    @Input() model: Endpoint;
    @Input() errorCountry = false;
    @Input() isWizard = false;
    isLoading = false;
    nullValue = null;

    constructor(
        private apiService: ApiService,
        public getCountryService: GetCountryService,
        @Inject(HTTP_CLIENT) private httpClient: HttpClient
    ) {}

    ngOnInit() {
        this.model.countryId = null;
        this.isLoading = true;
        this.getCountryService.getCountries().subscribe((countries) => {
            if (!countries['_embedded']) {
                return;
            }

            this.worldRegions = countries['_embedded'].countries;
            this.isLoading = false;
        });
    }

    worldRegionChange() {
        this.countries = this.worldRegions.filter((country) => country.worldRegion === this.selectedWorldRegion);
    }

    countryChange() {
        const country = this.countries.find((country) => country.id === this.model.countryId);

        this.model.geoRegionId = country.geoRegionId;
    }
}
