import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, Environment, Provider } from '@netfoundry-ui/shared/model';
import { GetOption, HateoasResourceOperation, PagedResourceCollection } from '@lagoshny/ngx-hateoas-client';
import { HttpClient } from '@angular/common/http';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';

@Injectable({ providedIn: 'root' })
export class ProviderService extends HateoasResourceOperation<Provider> {
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };

    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(Provider);
    }

    getResource(): Observable<Provider> {
        throw new Error('Do not use: see get getProvider');
    }

    getPage(): Observable<PagedResourceCollection<Provider>> {
        throw new Error('Do not use: see getProviders');
    }

    getProvider(id, options: GetOption = {}): Promise<Provider> {
        return super
            .getResource(id, { ...ProviderService.defaultHttpAccept, ...options })
            .toPromise()
            .then((endpoint) => endpoint);
    }

    async getProviderPage(options?: GetOption, includeProperties?: string): Promise<Provider[]> {
        let params = { ...ProviderService.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super
            .getCollection(params)
            .toPromise()
            .then((providers) => providers.resources);
    }
}
