<div class="sample-service-loader-container">
    <app-loader [isLoading]="isLoading" id="selfService"></app-loader>
</div>
<div class="sample-service-anchor">
    <div class="background-container"></div>
    <div
        (click)="goBack()"
        [ngClass]="{ 'feature-disabled': !featureService.experimentalFeatures }"
        class="buttonBall navButton back"
        id="BackButton"
    >
        <div class="buttonLabel">Back</div>
    </div>
    <div class="sample-service-container">
        <div
            *ngIf="view === 'sample-service-steps' || true"
            [ngClass]="{
                'section-on-screen': view === 'sample-service-steps' && showNextSection,
                'section-off-screen': view !== 'sample-service-steps'
            }"
            class="sample-service-section steps-section"
        >
            <div class="sample-service-header">
                <div class="header-title">Zero Trust is as Easy as 1,2,3</div>
                <div class="header-details">
                    Use this simple service to test the power of zero trust network access in minutes with a few simple
                    steps. We already took care of standing up your first network and provisioning your network fabric.
                    Now we'll take care of standing up a test service and linking it all together via an AppWan. You
                    just need to create and enroll an endpoint to access the service.
                </div>
            </div>
            <div class="sample-service-steps">
                <div class="sample-service-step">
                    <div class="step-number step-1"></div>
                    <div class="step-details-container">
                        <div class="step-label">Download Software</div>
                        <div class="step-details">
                            Select the {{ featureService.disableNewNavigation ? 'endpoint' : 'identity' }} software for
                            your OS of choice. You'll use this to enroll
                            {{ featureService.disableNewNavigation ? 'endpoints' : 'identities' }}.
                        </div>
                        <div (click)="sampleServiceService.openZitiTunnelers()" class="step-button button step1-button">
                            Download
                        </div>
                    </div>
                </div>
                <div class="sample-service-step">
                    <div class="step-number step-2"></div>
                    <div class="step-details-container">
                        <div class="step-label">
                            Create an {{ featureService.disableNewNavigation ? 'Endpoint' : 'Identity' }}
                        </div>
                        <div class="step-details">
                            We’ll set up an {{ featureService.disableNewNavigation ? 'endpoint' : 'identity' }} for you.
                            Just download the JWT registration token and
                            <span (click)="sampleServiceService.openEnrollmentInstructions()" class="enroll-link"
                                >enroll</span
                            >
                            it using the {{ featureService.disableNewNavigation ? 'endpoint' : 'identity' }} software.
                        </div>
                        <div
                            (click)="sampleServiceService.createSampleEndpoint()"
                            class="step-button button step2-button"
                        >
                            Create
                        </div>
                    </div>
                </div>
                <div class="sample-service-step">
                    <div class="step-number step-3"></div>
                    <div class="step-details-container">
                        <div class="step-label">Access the Service</div>
                        <div class="step-details">Finally, Tap below to test access to the service.</div>
                        <div
                            (click)="openSampleService()"
                            [ngClass]="{ disabled: disableTestButton }"
                            [title]="
                                disableTestButton
                                    ? 'Edge Routers must finish provisioning before you can test the service'
                                    : ''
                            "
                            class="save step-button button step3-button"
                        >
                            {{ disableTestButton ? 'Creating Routers' : 'Tap To Test' }}
                            <span *ngIf="disableTestButton" class="spinner"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            *ngIf="showNextSection && featureService.experimentalFeatures"
            [ngClass]="{
                'section-on-screen': view === 'tutorials',
                'section-off-screen': view !== 'tutorials' && showNextSection
            }"
            class="sample-service-section tutorials-section"
        >
            <app-tutorials (openStep)="openStep($event)" [disabled]="disableTutorial" [steps]="steps"></app-tutorials>
        </div>
        <div class="off-screen"></div>
        <div class="off-screen-2"></div>
    </div>
    <div *ngIf="!featureService.experimentalFeatures" class="step-by-step-container">
        <div class="step-by-step-header">Step by Step</div>
        <span class="step-by-step-details">
            Once you've finished testing the sample service, click the button below to view a guided instructional on
            how to define your own custom ZeroTrust service.
        </span>
        <div
            (click)="openStep(steps[0])"
            [ngClass]="{ disabled: disableTutorial }"
            [title]="disableTutorial ? 'Please complete the test service steps above before beginning tutorial' : ''"
            class="save step-by-step-button button"
        >
            {{ 'Open' }}
        </div>
    </div>
    <div
        (click)="doNext()"
        *ngIf="view !== 'tutorials' && showNextButton && featureService.experimentalFeatures"
        class="buttonBall navButton next"
        id="NextButton"
    >
        <div class="buttonLabel">Next</div>
    </div>
</div>
