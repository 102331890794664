import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerService, LoggerService } from '@netfoundry-ui/shared/services';

import $ from 'jquery';
import { clone } from 'lodash';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit {
    model;
    showSubmit = false;
    stepNum = 0;
    step: any = {};
    selections = [];
    otherText = '';
    selectionWarning = false;
    disableNext = true;

    surveyModel = [
        {
            step: 1,
            title: 'Welcome',
            subTitleDetail: `While we spin up your Zero Trust Network, tell us a little about yourself to personalize your
                       experience with these three simple questions.`,
            subTitleStep: '1. Which best describes your role?',
            selections: [
                {
                    letter: 'A',
                    text: 'DevOps, network admin, engineer',
                },
                {
                    letter: 'B',
                    text: 'Developer',
                },
                {
                    letter: 'C',
                    text: 'Product or business',
                },
                {
                    letter: 'D',
                    text: 'Security team',
                },
                {
                    letter: 'E',
                    text: 'Other',
                    hasInput: true,
                },
            ],
            selection: '',
            selectionIndex: -1,
            surveyKey: 'customer_role',
        },
        {
            step: 2,
            title: 'Perfect!',
            subTitleDetail: `Two more quick multiple choice questions while your controllers are spinning up`,
            subTitleStep: '2. How will you use the NetFoundry platform?',
            selections: [
                {
                    letter: 'A',
                    text: 'Embed zero trust in my products',
                },
                {
                    letter: 'B',
                    text: 'Remote management for admins',
                },
                {
                    letter: 'C',
                    text: 'Remote access for users',
                },
                {
                    letter: 'D',
                    text: 'Machine to machine secure',
                },
                {
                    letter: 'E',
                    text: 'Other',
                    hasInput: true,
                },
            ],
            selection: '',
            selectionIndex: -1,
            surveyKey: 'intended_use',
        },
        {
            step: 3,
            title: 'Almost Done',
            subTitleDetail: `One last step and you're ready to start creating.`,
            subTitleStep: '3. How did you discover NetFoundry?',
            selections: [
                {
                    letter: 'A',
                    text: 'Referred by someone',
                },
                {
                    letter: 'B',
                    text: 'Forum, podcast, webinar, article',
                },
                {
                    letter: 'C',
                    text: 'Search',
                },
                {
                    letter: 'D',
                    text: 'Event or show',
                },
                {
                    letter: 'E',
                    text: 'Other',
                    hasInput: true,
                },
            ],
            selection: '',
            selectionIndex: -1,
            surveyKey: 'discovery_method',
        },
    ];

    constructor(
        private dialogRef: MatDialogRef<SurveyComponent>,
        private logger: LoggerService,
        private customerService: CustomerService,
        private growlerService: GrowlerService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.model = data.model;
        this.step = this.surveyModel[0];
        this.selections = this.step.selections;
    }

    ngOnInit(): void {
        this.logger.log('Opened Survery Component');
    }

    next(): void {
        if (this.step.selectionIndex < this.step.selections.length - 1) {
            this.step.selection = clone(this.step.selections[this.step.selectionIndex]);
        } else if (this.step.selectionIndex === this.step.selections.length - 1) {
            this.step.selection = clone(this.step.selections[this.step.selectionIndex]);
            this.step.selection.isOther = true;
            this.step.selection.otherText = this.otherText;
        }

        if (this.showSubmit) {
            this.submitSurvey();
            this.hide();
            return;
        }
        this.stepNum++;
        this.step = this.surveyModel[this.stepNum];
        this.selections = this.step.selections;
        this.showSubmit = this.stepNum === this.surveyModel.length - 1;
        this.otherText = '';
        this.disableNext = this.step.selection <= 0;
    }

    moveBack() {
        this.stepNum--;
        this.step = this.surveyModel[this.stepNum];
        this.selections = this.step.selections;
        this.showSubmit = false;
    }

    select(selection: any) {
        let selectionIndex = 0;
        this.selections.forEach((sel: any) => {
            sel.selected = false;
            sel.showInput = false;
            if (sel.letter === selection.letter) {
                this.step.selectionIndex = selectionIndex;
            }
            selectionIndex++;
        });
        selection.selected = true;
        if (selection.hasInput) {
            selection.showInput = true;
            setTimeout(() => {
                const input = $('.survey-other-input');
                input.focus();
            }, 20);
        }
        const enableNext =
            this.step.selectionIndex < this.selections.length - 1 ||
            (this.step.selectionIndex === this.selections.length - 1 && this.otherText.length > 0);
        this.disableNext = !enableNext;
    }

    otherInputChanged(val: any) {
        this.disableNext = val?.length <= 0;
    }

    submitSurvey() {
        const entries: any = [];
        this.surveyModel.forEach((step: any) => {
            const val = step.selection.isOther ? 'OTHER: ' + step.selection.otherText : step.selection.text;
            entries.push({ key: step.surveyKey, value: val });
        });
        this.customerService
            .submitHubspotEntries(entries)
            .toPromise()
            .then((result) => {
                this.growlerService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Survey Submitted',
                        'Thank you. Your survey responses were successfully submitted'
                    )
                );
                this.logger.log('Survey submitted', result);
            })
            .catch((error) => {
                this.logger.error(error);
            });
    }

    hide() {
        this.dialogRef.close();
    }
}
