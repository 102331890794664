import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'alarmSeverity',
})
export class AlarmSeverityPipe implements PipeTransform {
    transform(items: any[], severity: number): any {
        if (!items) {
            return [];
        }
        if (severity === -1) {
            return items;
        }

        return items.filter((it) => it['severity'] === severity);
    }
}
