import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EndpointServiceV2, PostureCheckService } from '@netfoundry-ui/shared/apiv2';
import { PostureCheck } from '@netfoundry-ui/shared/model';
import { ApiService } from '@netfoundry-ui/shared/services';
import moment from 'moment';
import { take } from 'rxjs/operators';
import _ from 'lodash';
import { PagedGetOption } from '@lagoshny/ngx-hateoas-client';

@Component({
    selector: 'app-posture-check-info-modal',
    templateUrl: './posture-check-info-modal.component.html',
    styleUrls: ['./posture-check-info-modal.component.scss'],
})
export class PostureCheckInfoModalComponent implements OnInit {
    public endpointId;
    public codePostureData = '';
    public postureData;
    public postureChecks = [];
    public currentNetwork;
    public macPostureCheck;
    public domainPostureCheck;
    public osPostureCheck;
    public processPostureChecks = [];
    public lastCollected;

    public mfaStatus;
    isFormView = true;

    constructor(
        public endpointService: EndpointServiceV2,
        public postureCheckService: PostureCheckService,
        public apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<PostureCheckInfoModalComponent>
    ) {
        this.endpointId = data.endpointId;
    }

    ngOnInit(): void {
        this.apiService.currentNetwork.subscribe((network) => {
            this.currentNetwork = network;
        });

        this.endpointService.getPostureData(this.endpointId).subscribe((data: any) => {
            this.postureData = data;
            this.codePostureData = JSON.stringify(data, null, 2);

            const dates = [];

            if (this.postureData.postureData.domain && this.postureData.postureData.domain.postureCheckId) {
                dates.push(this.postureData.postureData.domain.lastUpdatedAt);
            }
            if (this.postureData.postureData.os && this.postureData.postureData.os.postureCheckId) {
                dates.push(this.postureData.postureData.os.lastUpdatedAt);
            }
            if (this.postureData.postureData.mac && this.postureData.postureData.mac.postureCheckId) {
                dates.push(this.postureData.postureData.mac.lastUpdatedAt);
            }
            if (this.postureData.postureData.processes?.length > 0) {
                for (const processCheck of this.postureData.postureData.processes) {
                    if (processCheck.postureCheckId) {
                        dates.push(processCheck.lastUpdatedAt);
                    }
                }
            }
            if (dates.length > 0) {
                this.lastCollected = moment.max(dates.map((d) => moment(d)));
            }

            if (this.postureData.postureData.apiSessionPostureData) {
                const sessionData = Object.values(this.postureData.postureData.apiSessionPostureData);
                if (sessionData[0]) {
                    this.mfaStatus = (sessionData[0] as any).mfa.passedMfa;
                }
            }
            this.postureCheckService.getPostureCheckPage(this.getOptions()).then((data: any) => {
                for (const postureCheck of data) {
                    this.postureCheckService
                        .getPostureCheck(postureCheck.id)

                        .then((p: PostureCheck) => {
                            if (this.postureData) {
                                if (p.zitiId === this.postureData.postureData.domain.postureCheckId) {
                                    this.domainPostureCheck = p;
                                }
                                if (p.zitiId === this.postureData.postureData.mac.postureCheckId) {
                                    this.macPostureCheck = p;
                                }
                                if (p.zitiId === this.postureData.postureData.os.postureCheckId) {
                                    this.osPostureCheck = p;
                                }
                                for (const process of this.postureData.postureData.processes) {
                                    if (p.zitiId === process.postureCheckId) {
                                        this.processPostureChecks.push({
                                            process: process,
                                            postureCheck: p,
                                        });
                                    }
                                }
                            }
                        });
                }
            });
        });
    }

    formatTime(theDate) {
        if (_.isEmpty(theDate)) {
            return;
        }
        return moment(theDate).local().format('M/D/YY h:mm a');
    }

    close() {
        this.dialogRef.close();
    }

    getOptions(): PagedGetOption {
        const options = {
            params: {
                networkId: this.currentNetwork.id,
            },
            pageParams: {
                size: 2000,
                page: 0,
            },
        };

        return options;
    }
    toggleJsonView() {
        this.isFormView = !this.isFormView;
    }
}
