import {Component, Inject, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { LoggerService } from '@netfoundry-ui/shared/services';
import { TEMPLATE_SEARCH_SERVICE, TemplateSearchService } from "@netfoundry-ui/shared/apiv2";
import { TobytesPipe } from '@netfoundry-ui/ui/pipes';
import { Chart } from 'angular-highcharts';
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import { Subscription } from 'rxjs';
import { TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { NetworkUtilizationTemplateQueryParameter } from '@netfoundry-ui/shared/model';

@Component({
    selector: 'app-ziti-utilization-stacked',
    templateUrl: './ziti-utilization-stacked.component.html',
    styleUrls: ['./ziti-utilization-stacked.component.scss'],
    providers: [TobytesPipe, { provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }],
})
export class ZitiUtilizationStackedComponent implements OnInit, OnChanges, OnDestroy {
    @Input() sourceId: string;
    @Input() networkId: any = null;
    @Input() networkGroupId: any = null;
    @Input() dateFilter: any = '24h';
    @Input() height = '300px';
    @Input() stackBy = 'nf_endpoint_name.keyword';
    @Input() selectedAttributes = [];
    @Input() newStartTime: any;
    @Input() newEndTime: any;
    /**
     * *** Valid filterable fields ***
     * identity_id = endpoint's zitiIdString
     * service_id = service's zitiIdString
     * source_id = edge router's zitiIdString
     */
    @Input() filterField = null;
    // @Input() readyToRender = false;
    @Input() filterValue = null;
    chart: Chart;
    colors = ['#0273fb', '#08dc5a', '#FF0D49', '#1aadce', '#6d00f2', '#ffc000', '#ff7e00', '#ca0000', '#00aeb0'];
    noData = true;
    isLoading = false;
    initialized = false;
    currentOrgId;
    utilization_options = {};
    utilization_series = [];
    private subscription = new Subscription();

    constructor(
        private elasticsearch: ElasticsearchService,
        private toBytes: TobytesPipe,
        private graphViewer: MatDialog,
        private logger: LoggerService,
        public filterService: TableFilterService,
        @Inject(TEMPLATE_SEARCH_SERVICE) public templateService: TemplateSearchService
    ) {
        this.newStartTime = moment().subtract(24, 'hours');
        this.newEndTime = moment();
    }

    ngOnInit() {
        this.initialized = true;
        this.generateUtilizationData();
        this.subscription.add(
            this.filterService.setDateFilterEvent.subscribe(() => {
                this.newStartTime = this.filterService.getStartTime();
                this.newEndTime = this.filterService.getEndTime();
                this.generateUtilizationData();
            })
        );
    }

    ngOnChanges() {
        this.generateUtilizationData();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public getUtilizationData() {
        const index = 'ncutilization';

        this.logger.info('Ziti Utilization Stacked Query:', JSON.stringify(this.getQuery()));
        // this.statusLoading = true;

        this.noData = true;
        this.isLoading = true;

        //reporting
        let componentName: any = '';
        let componentValue: any = '';
        let attributes: any = '';
        if (this.filterField != null && this.filterValue != null) {
            componentName = this.filterField;
            componentValue = this.filterValue;
        }
        if (this.selectedAttributes.length > 0) {
            attributes = JSON.stringify(this.selectedAttributes);
        }
      const networkUtilizationTemplateQueryParameter: NetworkUtilizationTemplateQueryParameter =
        this.templateService.getUtilizationTemplateQuery(
          this.newStartTime.valueOf(),
          this.newEndTime.valueOf(),
          this.dateFilter,
          componentName,
          componentValue,
          attributes,
          this.networkId,
          this.networkGroupId,
          index,
          this.stackBy,
          '10'
        );
      this.subscription.add(
        this.elasticsearch
          .apiTemplateSearch(this.templateService.UTILIZATION_BYTES_BY_COMPONENT_TEMPLATE, networkUtilizationTemplateQueryParameter)
          .subscribe((data) => {
            this.set_chart_options();
            this.utilization_series = this.templateService.getStackedUtilizationSeriesData(data);

            if (this.utilization_series.length > 0) {
              this.noData = false;
            }
            this.isLoading = false;
          }, () => {
            this.isLoading = false;
          })
      );
    }

    public set_chart_options() {
        const startTime = moment()
            .local()
            .subtract(
                this.dateFilter.slice(0, -1),
                this.dateFilter.slice(this.dateFilter.length - 1, this.dateFilter.length)
            );
        const endTime = moment().local();

        window.moment = moment;
        // utilization options
        const pipe = this.toBytes;
        this.utilization_options = {
            time: {
                timezone: momentTz.tz.guess(),
            },
            colors: this.colors,
            styledMode: true,
            title: { text: null },
            subtitle: {
                text: 'Stacked chart is limited to the top 10 results',
                verticalAlign: 'bottom',
            },
            xAxis: {
                type: 'datetime',
                // the max tick number
                max: this.newEndTime.valueOf(),
                // the min tick number
                min: this.newStartTime.valueOf(),
            },
            credits: { enabled: false },
            chart: {
                // spacing: [10, 10, 0, 10],
                type: 'area',
                height: this.height,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    fillOpacity: 0.5,
                },
            },
            yAxis: {
                title: {
                    text: null,
                },
                min: 0,
                tickPixelInterval: 20,
                labels: {
                    formatter: function () {
                        return pipe.transform(this.value);
                    },
                    style: {
                        color: window.getComputedStyle(document.body).getPropertyValue('--text'),
                    },
                },
            },
            legend: {
                margin: 0,
                maxHeight: 95,
                itemStyle: {
                    color: window.getComputedStyle(document.body).getPropertyValue('--text'),
                },
            },
            tooltip: {
                formatter: function () {
                    /* tslint:disable */
                    const d = new Date(this.x);
                    /* tslint:enable */
                    return (
                        d.toLocaleDateString() +
                        '<br >' +
                        d.toLocaleTimeString() +
                        '<br /><strong>' +
                        pipe.transform(this.y) +
                        '</strong>'
                    );
                },
            },
        };
    }

    public getQuery() {
        const model: any = {
            aggs: {
                items: {
                    terms: {
                        field: this.stackBy + '.keyword',
                        size: 10,
                        order: {
                            usage_bytes: 'desc',
                        },
                    },
                    aggs: {
                        usage_bytes: {
                            sum: {
                                field: 'usage',
                            },
                        },
                        timebuckets: {
                            date_histogram: {
                                field: 'timestamp',
                                interval: this.determine_interval(),
                                time_zone: momentTz.tz.guess(),
                                min_doc_count: 0,
                            },
                            aggs: {
                                usage_bytes: {
                                    sum: {
                                        field: 'usage',
                                    },
                                },
                            },
                        },
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            match_phrase: {
                                network_id: {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                organizationId: {
                                    query: this.networkGroupId,
                                },
                            },
                        },
                        {
                            // filter down to two usage types since these are the items that we will calculate billing on
                            bool: {
                                should: [
                                    {
                                        match_phrase: {
                                            // traffic delivered through the fabric to service's destination, renders as TX
                                            'usage_type.keyword': 'usage.ingress.tx',
                                        },
                                    },
                                    {
                                        match_phrase: {
                                            // traffic received back through the fabric to the initiator, renders as RX
                                            'usage_type.keyword': 'usage.egress.tx',
                                        },
                                    },
                                ],
                                minimum_should_match: 1,
                            },
                        },
                    ],
                    filter: {
                        bool: {
                            must: [{ match_all: {} }],
                        },
                    },
                },
            },
        };

        // this will allow us to filter by endpoint, edge router, or service
        if (this.filterField != null && this.filterValue != null) {
            const search = { match_phrase: {} };
            search.match_phrase[this.filterField] = { query: this.filterValue };
            model.query.bool.must.push(search);
        }
        if (this.selectedAttributes.length > 0) {
            const attributeFilter = {
                terms: {
                    'attributes.keyword': this.selectedAttributes,
                },
            };

            model.query.bool.filter.bool.must.push(attributeFilter);
        }
        model.query.bool.must.push({
            range: {
                '@timestamp': {
                    gte: this.newStartTime.valueOf(),
                    lte: this.newEndTime.valueOf(),
                    format: 'epoch_millis',
                },
            },
        });

        return model;
    }

    private generateUtilizationData() {
        if (this.initialized && this.networkId !== null && this.networkId && this.networkGroupId !== null) {
            this.getUtilizationData();
        }
    }

    private determine_interval() {
        if (this.dateFilter.includes('m')) {
            return '1d';
        } else if (this.dateFilter.includes('d')) {
            return '1h';
        } else if (this.dateFilter === '1h') {
            return '5m';
        } else if (this.dateFilter.includes('h')) {
            return '1h';
        } else {
            // just in case...
            return '1d';
        }
    }
}
