import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Injectable, Inject, Injector } from '@angular/core';
import { EdgeRouterPolicyV2, ENVIRONMENT, Environment } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import {
    GetOption,
    HateoasResourceOperation,
    PagedGetOption,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EdgeRouterPolicyServiceV2 extends HateoasResourceOperation<EdgeRouterPolicyV2> {
    static defaultPaginationSize = 500;
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;
    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(EdgeRouterPolicyV2);
    }

    getResource(): Observable<EdgeRouterPolicyV2> {
        throw new Error('Do not use: see get getServiceEdgeRouterPolicy');
    }

    getPage(): Observable<PagedResourceCollection<EdgeRouterPolicyV2>> {
        throw new Error('Do not use: see getServiceEdgeRouterPolicys');
    }

    getEgeRouterServicePolicy(id, options: GetOption = {}): Promise<EdgeRouterPolicyV2> {
        return super
            .getResource(id, { ...EdgeRouterPolicyServiceV2.defaultHttpAccept, ...options })
            .toPromise()
            .then((servicePolicy) => servicePolicy);
    }

    async getEdgeRouterServicePolicyPage(
        options?: PagedGetOption,
        includeProperties?: string
    ): Promise<EdgeRouterPolicyV2[]> {
        let params = { ...EdgeRouterPolicyServiceV2.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super
            .getPage(params)
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((servicePolicies) => servicePolicies.resources);
    }
    // public findByNetworkId(networkId: string): Observable<EdgeRouterPolicyV2[]> {
    //     const options: any = {
    //         params: [
    //             { key: 'networkId', value: networkId },
    //             { key: 'isSystem', value: false },
    //         ],
    //     };
    //     return this.search('findByNetworkId', options);
    // }

    public downloadToCsv(networkId: string): Observable<any> {
        const params = new HttpParams()
            .set('networkId', networkId)
            .set('size', this.lastTotalCount)
            .set('isSystem', 'false');
        const headers = new HttpHeaders().set('Accept', 'text/csv');
        return this.http.get(this.environment.v2apiUrl + 'edge-router-policies', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public validateUpdate(edgeRouterPolicyId: string, model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .patch(this.environment.v2apiUrl + 'edge-router-policies/' + edgeRouterPolicyId, model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public async validateCreate(model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .post(this.environment.v2apiUrl + 'edge-router-policies', model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public getJson(edgeRouterPolicyId: string): Observable<string> {
        const options: any = {};
        const url = `${this.environment.v2apiUrl}edge-router-policies/${edgeRouterPolicyId}/?meta=ziti,diffZiti`;
        return this.http.get(url, options) as Observable<any>;
    }

    public downloadFileFormat(
        networkId: string,
        format = 'text/csv',
        params: HttpParams = new HttpParams()
    ): Observable<any> {
        params = params.set('networkId', networkId).set('isSystem', 'false');
        const headers = new HttpHeaders().set('Accept', format);
        return this.http.get(this.environment.v2apiUrl + 'edge-router-policies', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }
}
