<div [ngClass]="{ isAt: step === 3, passed: isPassedStep(3) }" class="slider">
    <div *ngIf="step === 3">
        <div class="step3Icon">
            <div class="cloudIcon">
                <div class="cloudText">{{ cloudText }}</div>
            </div>
        </div>
        <div class="wizard">
            <div class="header">
                <div class="darken"></div>
                <div class="darkenText">{{ titleString }}</div>
            </div>
            <div class="row padded">
                <div class="grid five">
                    <div
                        (click)="setSource('AWSCPEGW')"
                        [ngClass]="{ selected: sourceGatewayType === 'AWSCPEGW' }"
                        class="selectorItem aws"
                        id="sourceAws"
                    >
                        AWS Cloud
                    </div>
                    <div
                        (click)="setSource('AZCPEGW')"
                        [ngClass]="{ selected: sourceGatewayType === 'AZCPEGW' }"
                        class="selectorItem azure"
                        id="sourceAzureCloud"
                    >
                        Azure Cloud
                    </div>
                    <div
                        (click)="setSource('AZSGW')"
                        [ngClass]="{ selected: sourceGatewayType === 'AZSGW' }"
                        class="selectorItem azure"
                        id="sourceAzureStack"
                    >
                        Azure Stack
                    </div>
                    <div
                        (click)="setSource('GCPCPEGW')"
                        [ngClass]="{ selected: sourceGatewayType === 'GCPCPEGW' }"
                        class="selectorItem google"
                        id="sourceGoogleCloud"
                    >
                        Google Cloud
                    </div>
                    <div
                        (click)="setSource('VCPEGW')"
                        [ngClass]="{ selected: sourceGatewayType === 'VCPEGW' }"
                        class="selectorItem private"
                        id="sourceDataCenter"
                    >
                        Data Center
                    </div>
                </div>
                <div
                    *ngIf="sourceGatewayType !== 'VCPEGW' && canCreateSource && canListRegions && canCreateGateways"
                    class="grid two"
                >
                    <label [ngClass]="{ error: form.sourceName.error }" for="SourceName"
                        >{{ form.sourceName.label }}
                        <span [ngClass]="{ error: form.sourceName.error }" class="note">Required</span>
                    </label>
                    <label [ngClass]="{ error: form.sourceLocation.error }" for="SourceLocation"
                        >{{ form.sourceLocation.label }}
                        <span [ngClass]="{ error: form.sourceLocation.error }" class="note">Required</span>
                    </label>
                    <input
                        (keyup)="disableSourceSelect()"
                        [(ngModel)]="form.sourceName.value"
                        [ngClass]="{ error: form.sourceName.error }"
                        id="SourceName"
                        maxlength="100"
                        placeholder="{{ form.sourceName.placeholder }}"
                        type="text"
                    />
                    <select
                        (change)="disableSourceSelect()"
                        [(ngModel)]="form.sourceLocation.value"
                        [ngClass]="{ error: form.sourceLocation.error }"
                        id="SourceLocation"
                    >
                        <option value="">{{ form.sourceLocation.placeholder }}</option>
                        <option *ngFor="let region of sourceRegions | sortby: 'name'" [value]="region.id">
                            {{ region.name }}
                        </option>
                    </select>
                </div>
                <div *ngIf="sourceGatewayType === 'VCPEGW'" class="grid evenThree">
                    <label [ngClass]="{ error: form.sourceName.error }" for="SourceName"
                        >{{ form.sourceName.label }}
                        <span [ngClass]="{ error: form.sourceName.error }" class="note">Required</span>
                    </label>

                    <label [ngClass]="{ error: form.sourceRegion.error }" for="SourceRegion"
                        >{{ form.sourceRegion.label }}
                        <span [ngClass]="{ error: form.sourceRegion.error }" class="note">Required</span>
                    </label>

                    <label [ngClass]="{ error: form.sourceCountry.error }" for="SourceCountry"
                        >{{ form.sourceCountry.label }}
                        <span [ngClass]="{ error: form.sourceCountry.error }" class="note">Required</span>
                    </label>

                    <input
                        (keyup)="disableSourceSelect()"
                        [(ngModel)]="form.sourceName.value"
                        [ngClass]="{ error: form.sourceName.error }"
                        id="SourceName"
                        maxlength="100"
                        placeholder="{{ form.sourceName.placeholder }}"
                        type="text"
                    />

                    <select
                        (change)="worldRegionChangeSource()"
                        [(ngModel)]="form.sourceRegion.value"
                        [ngClass]="{ error: this.form.sourceLocation.error }"
                        class="select"
                        id="SourceRegion"
                    >
                        <option [ngValue]="''">Select A World Region</option>
                        <option
                            *ngFor="let worldRegion of worldRegions | pluck: 'worldRegion' | unique"
                            [value]="worldRegion"
                        >
                            {{ worldRegion }}
                        </option>
                    </select>

                    <select
                        (change)="countryChangeSource()"
                        [(ngModel)]="form.sourceCountry.value"
                        [ngClass]="{ error: this.form.sourceLocation.error }"
                        class="select"
                        id="SourceCountry"
                    >
                        <option [ngValue]="''">Select A Country</option>
                        <option *ngFor="let country of countries" [value]="country.id">
                            {{ country.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <div *ngIf="canSelectSource && canListGateways && displayedGateways.length > 0" class="footer">
                <div class="darken"></div>
                <label for="SourceGateway">{{ form.sourceGateway.label }}</label>
                <select
                    (change)="disableSourceCreate()"
                    [(ngModel)]="form.sourceGateway.value"
                    [ngClass]="{ error: form.sourceGateway.error }"
                    id="SourceGateway"
                >
                    <option value="">{{ form.sourceGateway.placeholder }}</option>
                    <option *ngFor="let gateway of displayedGateways | sortby: 'name'" [value]="gateway.id">
                        {{ gateway.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>
<div [ngClass]="{ isAt: step === 4, passed: isPassedStep(4) }" class="slider">
    <div *ngIf="step === 4">
        <div class="step3Icon">
            <div class="cloudIcon">
                <div class="cloudText">{{ cloudText }}</div>
            </div>
        </div>
        <div class="wizard">
            <div class="header">
                <div class="darken"></div>
                <div class="darkenText">{{ titleString }}</div>
            </div>
            <div class="row padded">
                <div class="grid five">
                    <div
                        (click)="setTarget('AWSCPEGW')"
                        [ngClass]="{ selected: targetGatewayType === 'AWSCPEGW' }"
                        class="selectorItem aws"
                        id="targetAws"
                    >
                        AWS Cloud
                    </div>
                    <div
                        (click)="setTarget('AZCPEGW')"
                        [ngClass]="{ selected: targetGatewayType === 'AZCPEGW' }"
                        class="selectorItem azure"
                        id="targetAzureCloud"
                    >
                        Azure Cloud
                    </div>
                    <div
                        (click)="setTarget('AZSGW')"
                        [ngClass]="{ selected: targetGatewayType === 'AZSGW' }"
                        class="selectorItem azure"
                        id="targetAzureStack"
                    >
                        Azure Stack
                    </div>
                    <div
                        (click)="setTarget('GCPCPEGW')"
                        [ngClass]="{ selected: targetGatewayType === 'GCPCPEGW' }"
                        class="selectorItem google"
                        id="targetGoogleCloud"
                    >
                        Google Cloud
                    </div>
                    <div
                        (click)="setTarget('VCPEGW')"
                        [ngClass]="{ selected: targetGatewayType === 'VCPEGW' }"
                        class="selectorItem private"
                        id="targetDataCenter"
                    >
                        Data Center
                    </div>
                </div>
                <div
                    *ngIf="targetGatewayType !== 'VCPEGW' && canCreateTarget && canListRegions && canCreateGateways"
                    class="grid two"
                >
                    <label [ngClass]="{ error: form.targetName.error }" for="TargetName"
                        >{{ form.targetName.label }}
                        <span [ngClass]="{ error: form.targetName.error }" class="note">Required</span>
                    </label>
                    <label [ngClass]="{ error: form.targetLocation.error }" for="TargetLocation"
                        >{{ form.targetLocation.label }}
                        <span [ngClass]="{ error: form.targetLocation.error }" class="note">Required</span>
                    </label>
                    <input
                        (keyup)="disableTargetSelect()"
                        [(ngModel)]="form.targetName.value"
                        [ngClass]="{ error: form.targetName.error }"
                        id="TargetName"
                        maxlength="100"
                        placeholder="{{ form.targetName.placeholder }}"
                        type="text"
                    />
                    <select
                        (change)="disableTargetSelect()"
                        [(ngModel)]="form.targetLocation.value"
                        [ngClass]="{ error: form.targetLocation.error }"
                        id="TargetLocation"
                    >
                        <option value="">{{ form.targetLocation.placeholder }}</option>
                        <option *ngFor="let region of targetRegions | sortby: 'name'" [value]="region.id">
                            {{ region.name }}
                        </option>
                    </select>
                </div>
                <div *ngIf="targetGatewayType === 'VCPEGW'" class="grid evenThree">
                    <label [ngClass]="{ error: form.targetName.error }" for="TargetName"
                        >{{ form.targetName.label }}
                        <span [ngClass]="{ error: form.targetName.error }" class="note">Required</span>
                    </label>

                    <label [ngClass]="{ error: form.targetRegion.error }" for="TargetRegion"
                        >{{ form.targetRegion.label }}
                        <span [ngClass]="{ error: form.targetRegion.error }" class="note">Required</span>
                    </label>

                    <label [ngClass]="{ error: form.targetCountry.error }" for="TargetCountry"
                        >{{ form.targetCountry.label }}
                        <span [ngClass]="{ error: form.targetCountry.error }" class="note">Required</span>
                    </label>

                    <input
                        (keyup)="disableTargetSelect()"
                        [(ngModel)]="form.targetName.value"
                        [ngClass]="{ error: form.targetName.error }"
                        id="TargetName"
                        maxlength="100"
                        placeholder="{{ form.targetName.placeholder }}"
                        type="text"
                    />

                    <select
                        (change)="worldRegionChangeTarget()"
                        [(ngModel)]="form.targetRegion.value"
                        [ngClass]="{ error: this.form.targetLocation.error }"
                        class="select"
                        id="TargetRegion"
                    >
                        <option [ngValue]="''">Select A World Region</option>
                        <option
                            *ngFor="let worldRegion of worldRegions | pluck: 'worldRegion' | unique"
                            [value]="worldRegion"
                        >
                            {{ worldRegion }}
                        </option>
                    </select>

                    <select
                        (change)="countryChangeTarget()"
                        [(ngModel)]="form.targetCountry.value"
                        [ngClass]="{ error: this.form.targetLocation.error }"
                        class="select"
                        id="TargetCountry"
                    >
                        <option [ngValue]="''">Select A Country</option>
                        <option *ngFor="let country of countries" [value]="country.id">
                            {{ country.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <div *ngIf="canSelectTarget && canListGateways && displayedGateways.length > 0" class="footer">
                <div class="darken"></div>
                <label for="TargetGateway">{{ form.targetGateway.label }}</label>
                <select
                    (change)="disableTargetCreate()"
                    [(ngModel)]="form.targetGateway.value"
                    [ngClass]="{ error: form.targetGateway.error }"
                    id="TargetGateway"
                >
                    <option value="">{{ form.targetGateway.placeholder }}</option>
                    <option *ngFor="let gateway of displayedGateways | sortby: 'name'" [value]="gateway.id">
                        {{ gateway.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>
