<div class="modal solid restartModal open" id="restartModal">
    <div (click)="cancel()" class="close cancel" id="CancelXButton"></div>
    <div class="icon upgradenetwork"></div>
    <div class="title">{{ title }}</div>
    <div class="subtitle">{{ network.name }}</div>
    <div class="icongrid">
        <div class="outer">
            <div (click)="restartProcess()" class="inner">
                <img src="assets/svgs/ProcessCheck.svg" />
            </div>
            <div class="icontitle">Restart Ziti Process</div>
        </div>
        <div class="or">OR</div>
        <div class="outer">
            <div (click)="restartHost()" class="inner">
                <img src="assets/svgs/NetFoundryHosted.svg" />
            </div>
            <div class="icontitle">Restart Network Controller Host</div>
        </div>
    </div>
</div>
