<div class="modal solid open" id="renameModal">
    <div (click)="cancel()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div class="title">Rename Resource</div>
    <div class="icon" style="background-image: url(/assets/svgs/Edit.svg)"></div>

    <div class="content">
        <div class="row">
            <div class="label">Current Name: {{ name }}</div>
            <input [(ngModel)]="newName" id="newName" placeholder="Enter new name..." />
        </div>
    </div>
    <div class="buttons">
        <div (click)="cancel()" class="linkButton cancel" id="CancelUpgrade">
            {{ cancelAction }}
        </div>
        <button
            (click)="rename()"
            [disabled]="!newName || newName.length < 5"
            class="save alert confirm"
            id="ConfirmAction"
        >
            {{ action }}
        </button>
    </div>
</div>
