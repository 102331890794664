import { Component, DoCheck, EventEmitter, Input, KeyValueDiffers, OnDestroy, OnInit, Output } from '@angular/core';
import { TableFilterService } from '@netfoundry-ui/feature/shared-services';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import moment from 'moment';

@Component({
    selector: 'app-side-filter',
    templateUrl: './side-filter.component.html',
    styleUrls: ['./side-filter.component.scss'],
})
export class SideFilterComponent implements OnDestroy, DoCheck, OnInit {
    @Input() isTimeSearchAvailable = false;
    @Input() appliedFilters;
    @Input() columnFilters: any = { name: '' };
    @Input() dateFilter: any = '24h';
    @Input() isUTC = false;
    @Input() isNotDialLogs = true;
    newStartTime;
    newEndTime;
    rangeDates: Date[];
    externalFilters = [];
    filters = [];
    @Output() filterRemoved = new EventEmitter();

    public filterString = '';
    timeSearch = '7d';
    isSelectingTime = false;
    private subscription: Subscription = new Subscription();
    private filterDiffer;

    constructor(public filterService: TableFilterService, private differ: KeyValueDiffers) {
        this.filterService.resetEvent.subscribe(() => {
            this.filterString = '';
            this.timeSearch = '7d';
        });

        this.filterDiffer = differ.find({}).create();
    }

    ngOnInit() {
        this.close();
        this.filterService.externalFiltersChanged.subscribe((filters) => {
            this.externalFilters = filters || [];
            this.updateAppliedFilters();
        });
    }

    removeFilter(filter) {
        this.filterRemoved.emit(filter);
        this.filterService.removeFilter(filter);
        if (filter.columnId === 'name') {
            this.filterString = '';
        }
        this.updateAppliedFilters();
    }

    ngDoCheck() {
        const changes = this.filterDiffer.diff(this.columnFilters);
        if (changes) {
            changes.forEachChangedItem((change) => {
                if (change.key === 'name') {
                    this.filterString = change.currentValue;
                }
            });
        }
    }

    close() {
        if (!_.isEmpty(this.rangeDates)) {
            this.filterService.setDateRange(moment.utc(this.rangeDates[0]), moment.utc(this.rangeDates[1]));
        } else {
            this.filterService.setDateFilter('24h');
        }
    }

    showTimeSelect() {
        this.isSelectingTime = true;
    }

    closeTime() {
        this.isSelectingTime = false;
    }

    pickDateFilter(time) {
        this.timeSearch = time;
        this.dateFilter = time;
        this.filterService.setDateFilter(time);
        this.rangeDates = null;
        this.closeTime();
    }

    next() {
        if (!this.filterService.isLastPage()) {
            this.filterService.nextPage();
        }
    }

    prev() {
        if (!this.filterService.isFirstPage()) {
            this.filterService.prevPage();
        }
    }

    setFilter() {
        _.debounce(() => {
            if (this.appliedFilters) {
                this.updateAppliedFilters();
            }
            this.filterService.setFilterString(this.filterString);
        }, 300)();
    }

    handleTimeChange(e) {
        this.isUTC = e.checked;
        this.filterService.setUTC.emit(this.isUTC);
    }

    updateAppliedFilters() {
        this.columnFilters.name = this.filterString;
        const filterExists = _.some(this.appliedFilters, { columnId: 'name' });
        if (filterExists) {
            if (_.isEmpty(this.columnFilters.name)) {
                _.remove(this.appliedFilters, { columnId: 'name' });
            } else {
                _.forEach(this.appliedFilters, (filter) => {
                    if (filter.columnId === 'name') {
                        filter.label = this.columnFilters.name;
                    }
                });
            }
        } else if (!_.isEmpty(this.columnFilters.name)) {
            this.appliedFilters.push({
                columnId: 'name',
                label: this.columnFilters.name,
                filterName: 'Name',
            });
        }
        this.filters = [...this.appliedFilters, ...this.externalFilters];
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
