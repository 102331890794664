import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { AzureVirtualWan, Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { ApiService, AzureVirtualWanService, LoggerService, RefresherService } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-azurevirtualwans',
    templateUrl: './azurevirtualwans.component.html',
    styleUrls: ['./azurevirtualwans.component.scss'],
})
export class AzureVirtualWansComponent implements OnInit, OnDestroy {
    subscription = new Subscription();
    azureVirtualWans: AzureVirtualWan[] = [];
    typeName = 'VirtualWANs';
    items = [];
    itemCount = 0;
    showList = false;
    isLoading = false;
    filterString = '';
    sorting = 'name';
    ordering = 'asc';

    page = 1;
    pageSize = this.environment.pageSize;

    displayedItems = [];

    constructor(
        private logger: LoggerService,
        private apiService: ApiService,
        private service: AzureVirtualWanService,
        private refresher: RefresherService,
        public filterService: TableFilterService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    ngOnInit() {
        // subscribing to the filter service to handle the change in page
        this.subscription.add(
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.page = pageNum;
            })
        );

        // subscribing to the filter service to handle search filter changes
        this.subscription.add(
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.filterString = filterString;
                this.applyFilter();
            })
        );

        this.isLoading = true;
        this.subscription.add(
            this.service.get().subscribe((result) => {
                this.logger.info('Azure virtual wans', result);
                this.items = result;
                this.isLoading = false;

                this.filterService.setTotalElements(result.length);

                // TODO set total pages based on this.service.page.totalPages once using backend pagination
                this.filterService.updateTotalPages();
                this.applyFilter();
            })
        );
        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval * 4);
    }

    applyFilter() {
        this.displayedItems = this.filterService.applyLocalFilter(this.items);
    }

    ngOnDestroy() {
        this.refresher.disableRefresh();
        this.subscription.unsubscribe();
        this.filterService.reset();
    }

    refresh() {
        this.refresher.disableRefresh();
        if (this.apiService.theAzureSubscriptionIs.getId() !== null) {
            this.service.get();
            this.refresher.refreshTimerId = setTimeout(() => {
                this.refresh();
            }, this.refresher.refreshInterval * 4);
        }
    }

    sort(sortBy) {
        if (this.sorting === sortBy) {
            if (this.ordering === 'asc') {
                this.ordering = 'desc';
            } else {
                this.ordering = 'asc';
            }
        } else {
            this.ordering = 'asc';
            this.sorting = sortBy;
        }
    }

    getSortClass(id) {
        if (id === this.sorting) {
            return this.ordering;
        } else {
            return '';
        }
    }

    trackById(index, item) {
        return item.id;
    }
}
