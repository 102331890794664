import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('endpoints')
export class EndpointV2 extends Resource {
    id: string;
    networkId: string;
    name: string;
    jwt: string;
    jwtExpiresAt: string;
    attributes: string[];
    environmentInfo: any;
    enrollmentMethod: EnrollmentMethod;
    sdkInfo: any;
    sessionIdentityId: string;
    createdAt: string;
    updatedAt: string;
    sessionActive: boolean;
    selected = false;
    zitiId: string;
    hasApiSession: boolean;
    mfaEnabled: boolean;
    type: string;
    typeId: string;
    lastOnlineAt: string;
    syncId: string;
    syncResourceId: string;
    os: string;
    externalId: string;
    authPolicyId: string;
}

export interface EnrollmentMethod {
    ott?: boolean;
    updb?: string;
    ottca?: string;
}
