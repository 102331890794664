import {Component, Inject, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { LoggerService } from '@netfoundry-ui/shared/services';
import { TEMPLATE_SEARCH_SERVICE, TemplateSearchService } from "@netfoundry-ui/shared/apiv2";
import { TobytesPipe } from '@netfoundry-ui/ui/pipes';
import { Chart } from 'angular-highcharts';
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import { Subscription } from 'rxjs';
import { TableFilterService } from '@netfoundry-ui/feature/shared-services';

@Component({
    selector: 'app-ziti-utilization-basic',
    templateUrl: './ziti-utilization-basic.component.html',
    styleUrls: ['./ziti-utilization-basic.component.scss'],
    providers: [TobytesPipe, { provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }],
})
export class ZitiUtilizationBasicComponent implements OnInit, OnChanges, OnDestroy {
    @Input() sourceId: string;
    @Input() networkId: any = null;
    @Input() networkGroupId: any = null;
    @Input() dateFilter: any = '24h';
    @Input() height = '300px';
    @Input() selectedAttributes = [];
    @Input() newStartTime: any;
    @Input() newEndTime: any;
    /**
     * *** Valid filterable fields ***
     * identity_id = endpoint's zitiIdString
     * service_id = service's zitiIdString
     * source_id = edge router's zitiIdString
     */
    @Input() filterField = null;
    // @Input() readyToRender = false;
    @Input() filterValue = null;
    chart: Chart;
    colors = ['#2f7ed8', '#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'];
    noData = true;
    isLoading = false;
    initialized = false;
    currentOrgId;
    utilization_options = {};
    utilization_series = [];
    interval = '10m';
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private elasticsearch: ElasticsearchService,
        private toBytes: TobytesPipe,
        private graphViewer: MatDialog,
        public filterService: TableFilterService,
        @Inject(TEMPLATE_SEARCH_SERVICE) private templateService: TemplateSearchService
    ) {}

    ngOnInit() {
        this.initialized = true;
        this.generateUtilizationData();
        this.subscription.add(
            this.filterService.setDateFilterEvent.subscribe(() => {
                this.newStartTime = this.filterService.getStartTime();
                this.newEndTime = this.filterService.getEndTime();
                this.generateUtilizationData();
            })
        );
    }

    ngOnChanges() {
        this.generateUtilizationData();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public getUtilizationData() {
        const index = 'ncutilization';

        this.logger.info('Ziti Utilization Timeline Query:', JSON.stringify(this.getQuery()));
        // this.statusLoading = true;

        this.noData = true;
        this.isLoading = true;

        //reporting
        let componentName: any = '';
        let componentValue: any = '';
        let attributes: any = '';
        if (this.filterField != null && this.filterValue != null) {
            componentName = this.filterField;
            componentValue = this.filterValue;
        }
        if (this.selectedAttributes.length > 0) {
            attributes = JSON.stringify(this.selectedAttributes);
        }
        const networkUtilizationTemplateQueryParameter = this.templateService.getUtilizationTemplateQuery(this.newStartTime.valueOf(), this.newEndTime.valueOf(), this.dateFilter, componentName, componentValue, attributes, this.networkId, this.networkGroupId, index, '10');

        this.subscription.add(
            this.elasticsearch
                .apiTemplateSearch(this.templateService.UTILIZATION_SERIES_TEMPLATE, networkUtilizationTemplateQueryParameter)
                .subscribe((data) => {
                    const utilization_series = this.templateService.getUtilizationSeriesData(data);
                    if (utilization_series.length <= 0) {
                      this.isLoading = false;
                      this.noData = true;
                      return;
                    }
                    this.set_chart_options();
                    this.utilization_series = utilization_series;
                    this.noData = false;
                    this.isLoading = false;
                }, () => {
                    this.isLoading = false;
                })
        );
    }

    public set_chart_options() {
        const startTime = moment()
            .local()
            .subtract(
                this.dateFilter.slice(0, -1),
                this.dateFilter.slice(this.dateFilter.length - 1, this.dateFilter.length)
            );
        const endTime = moment().local();

        window.moment = moment;
        // utilization options
        const pipe = this.toBytes;
        this.utilization_options = {
            time: {
                timezone: momentTz.tz.guess(),
            },
            colors: this.colors,
            styledMode: true,
            title: { text: null },
            xAxis: {
                type: 'datetime',
                // the max tick number
                max: this.newEndTime.valueOf(),
                // the min tick number
                min: this.newStartTime.valueOf(),
            },
            credits: { enabled: false },
            chart: {
                height: this.height,
                type: 'area',
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    fillOpacity: 0.5,
                },
            },
            yAxis: {
                title: {
                    text: null,
                },
                min: 0,
                tickPixelInterval: 20,
                labels: {
                    formatter: function () {
                        return pipe.transform(this.value);
                    },
                    style: {
                        color: window.getComputedStyle(document.body).getPropertyValue('--text'),
                    },
                },
            },
            legend: {
                margin: 0,
                maxHeight: 95,
                itemStyle: {
                    color: window.getComputedStyle(document.body).getPropertyValue('--text'),
                },
            },
            tooltip: {
                formatter: function () {
                    /* tslint:disable */
                    const d = new Date(this.x);
                    /* tslint:enable */
                    return d.toLocaleDateString() + '<br /><strong>' + pipe.transform(this.y) + '</strong>';
                },
            },
        };
    }

    public getQuery() {
        const model: any = {
            aggs: {
                timebuckets: {
                    // aggregate into time buckets
                    date_histogram: {
                        field: 'timestamp',
                        interval: this.determine_interval(),
                        time_zone: momentTz.tz.guess(),
                        min_doc_count: 1,
                    },
                    aggs: {
                        usage_sums: {
                            // create a sum per usage type
                            terms: {
                                field: 'usage_type.keyword',
                                size: 2, // should only need 2 since we're filtering to two usage types
                                order: {
                                    usage_bytes: 'desc',
                                },
                            },
                            aggs: {
                                usage_bytes: {
                                    // roll up the usage field into total bytes per timebucket, per usage type
                                    sum: {
                                        field: 'usage',
                                    },
                                },
                            },
                        },
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            match_phrase: {
                                network_id: {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                organizationId: {
                                    query: this.networkGroupId,
                                },
                            },
                        },
                        {
                            // filter down to two usage types since these are the items that we will calculate billing on
                            bool: {
                                should: [
                                    {
                                        match_phrase: {
                                            // traffic delivered through the fabric to service's destination, renders as TX
                                            'usage_type.keyword': 'usage.ingress.tx',
                                        },
                                    },
                                    {
                                        match_phrase: {
                                            // traffic received back through the fabric to the initiator, renders as RX
                                            'usage_type.keyword': 'usage.egress.tx',
                                        },
                                    },
                                ],
                                minimum_should_match: 1,
                            },
                        },
                    ],
                    filter: {
                        bool: {
                            must: [{ match_all: {} }],
                        },
                    },
                },
            },
        };

        // this will allow us to filter by endpoint, edge router, or service
        if (this.filterField != null && this.filterValue != null) {
            const search = { match_phrase: {} };
            search.match_phrase[this.filterField] = { query: this.filterValue };
            model.query.bool.must.push(search);
        }
        if (this.selectedAttributes && this.selectedAttributes.length > 0) {
            const attributeFilter = {
                terms: {
                    'attributes.keyword': this.selectedAttributes,
                },
            };

            model.query.bool.filter.bool.must.push(attributeFilter);
        }

        model.query.bool.must.push({
            range: {
                '@timestamp': {
                    gte: this.newStartTime.valueOf(),
                    lte: this.newEndTime.valueOf(),
                    format: 'epoch_millis',
                },
            },
        });

        return model;
    }

    private generateUtilizationData() {
        if (this.initialized && this.networkId !== null && this.networkId && this.networkGroupId !== null) {
            this.getUtilizationData();
        }
    }

    private determine_interval() {
        if (this.dateFilter.includes('m')) {
            return '1d';
        } else if (this.dateFilter.includes('d')) {
            return '1h';
        } else if (this.dateFilter.includes('h')) {
            return '5m';
        } else {
            // just in case...
            return '1d';
        }
    }
}
