import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillingEnabledService } from '@netfoundry-ui/feature/shared-services';
import { ApiService, FeatureService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-action-button',
    templateUrl: './action-button.component.html',
    styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent implements OnInit {
    @Input() isDeleteAction = false;
    @Input() isGroupAction = false;
    @Output() deleteEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() addEvent: EventEmitter<boolean> = new EventEmitter();

    isCancelledAccount = false;

    constructor(
        public billingEnabledservice: BillingEnabledService,
        private apiService: ApiService,
        private featureService: FeatureService
    ) {}

    doAction() {
        if (this.isDeleteAction) {
            this.deleteEvent.emit(true);
        } else {
            this.addEvent.emit(true);
        }
    }

    ngOnInit(): void {
        this.apiService.currentSubscription.subscribe((subscription) => {
            this.isCancelledAccount = this.featureService.isCancelledAccount(subscription);
        });
    }
}
