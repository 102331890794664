import { EdgeRouterV2 } from './edge-router';
import { EndpointV2 } from './endpoint';

export class ZShareData {
    public type: string;
    public endpoint: EndpointV2;
    public edgeRouter: EdgeRouterV2;

    constructor(type = '', endpoint: EndpointV2 = null, edgeRouter: EdgeRouterV2 = null) {
        this.type = type;
        this.endpoint = endpoint;
        this.edgeRouter = edgeRouter;
    }
}
