import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ENVIRONMENT, Environment, Network } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    ErrorHistoryService,
    HTTP_CLIENT,
    NetworkVersionService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Component({
    selector: 'app-network-info',
    templateUrl: './network-info.component.html',
    styleUrls: ['./network-info.component.scss'],
    providers: [],
})
export class NetworkInfoComponent implements OnInit {
    networkModel: Network = new Network({});
    items = [];

    majorVersionNumber;
    private lookupUrl = 'https://dns.google.com/resolve?&type=A&name=';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<NetworkInfoComponent>,
        private apiService: ApiService,
        @Inject(HTTP_CLIENT) private httpClient: HttpClient,
        private validateService: ValidateService,
        private errorHistoryService: ErrorHistoryService,
        private networkVersionService: NetworkVersionService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.networkModel = data.network;
        this.majorVersionNumber = networkVersionService.getNetworkVersion(this.networkModel);
    }

    ngOnInit() {
        this._get_network_controller_ips();
        // this._get_gateway_ips();
        this._get_registration_ips();
        this._get_transfer_node_ips();
    }

    _get_network_controller_ips() {
        // pull up the network details without switching the actual network context for the app
        const link = this.networkModel.getSelfLink();

        this.apiService.get(link + '/networkControllerHosts').subscribe((nodes) => {
            if (nodes['_embedded'] === undefined || nodes['_embedded']['networkControllerHosts'] === undefined) {
                return;
            }
            const ncNodes = nodes['_embedded']['networkControllerHosts'];

            for (const node of ncNodes) {
                const model = {
                    type: 'Network Controller',
                    protocol: 'TCP/UDP',
                    port: '443, 49012',
                    ips: [node['ipAddress']],
                };

                this.items.push(model);
            }
        });
    }

    _get_registration_ips() {
        this.items.push({
            type: 'Registration Service',
            ips: [this.environment.registrationIp],
            port: 18443,
            protocol: 'TCP',
        });
    }

    _get_gateway_ips() {
        let domain = this.environment.domain;
        if (this.environment.domain === 'localhost' || this.environment.domain === 'uuix') {
            domain = 'sandbox';
        }
        const regServer = 'gateway.' + domain + '.netfoundry.io';

        let headers = new HttpHeaders().set('X-Skip-Interceptor', 'true');
        headers = headers.set('Accept', '*/*');
        headers = headers.set('Content-Type', 'text/plain');
        this.httpClient.get(this.lookupUrl + regServer, { headers: headers }).subscribe(
            (res) => {
                const model = {
                    type: 'Gateway API Service',
                    ips: [],
                    port: 443,
                    protocol: 'TCP',
                };

                for (const answer of res['Answer']) {
                    if (this.validateService.isValidIP(answer.data)) {
                        model.ips.push(answer.data);
                    }
                }

                this.items.push(model);
            },
            (error) => {
                this.errorHistoryService.addError(error.message);
            }
        );
    }

    _get_transfer_node_ips() {
        // pull up the network details without switching the actual network context for the app
        const link = this.networkModel.getSelfLink();

        this.apiService.get(link + '/transferNodes').subscribe((nodes) => {
            if (nodes['_embedded'] === undefined || nodes['_embedded']['transferNodeInlines'] === undefined) {
                return;
            }
            const transferNodes = nodes['_embedded']['transferNodeInlines'];

            if (transferNodes.length > 0) {
                for (const node of transferNodes) {
                    const model = {
                        type: 'Network Node',
                        protocol: 'TCP/UDP',
                        port: '5520-5550, 49002',
                        ips: [node['host']['primaryIpAddress']],
                    };

                    this.items.push(model);
                }
            }
        });
    }

    csv_export() {
        // Construct the header rows
        const data = [];
        const columns = [];
        const header = {};
        for (const column of Object.keys(this.items[0])) {
            header[column] = column.toUpperCase();
            columns.push(column);
        }
        data.push(header);

        // Construct each row based on the columns defined
        for (const item of this.items) {
            const row = {};

            for (const column of columns) {
                if (item[column] === undefined) {
                    row[column] = '';
                } else {
                    if (column === 'version number') {
                        row[column] = this.majorVersionNumber;
                    } else if (column === 'ips' || column === 'port') {
                        row[column] = item[column].toString().replace(/,/g, '; ');
                    } else {
                        row[column] = item[column];
                    }
                }
            }
            data.push(row);
        }

        const options = {
            fieldSeparator: ',',
            quoteStrings: '',
            decimalseparator: '.',
            showLabels: true,
            showTitle: false,
            useBom: true,
        };
        const csv = new AngularCsv(data, 'NetFoundry_IP_Whitelist', options);
    }

    close() {
        this.dialogRef.close();
    }
}
