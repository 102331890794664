import { Endpoint } from './endpoint';
import { MopResource } from './mopresource';

export class Group extends MopResource {
    public endpoints: Endpoint[] = [];
    public source: string;

    public getSaveObject() {
        return {
            name: this.name,
        };
    }

    public getType() {
        return 'GP';
    }

    public convertToGroupAsACode(): any {
        return {
            name: this.name,
            gateways: [],
            clients: [],
        };
    }
}
