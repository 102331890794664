import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'fromDate',
})
export class FromDatePipe implements PipeTransform {
    transform(value: string): any {
        const num: moment.DurationInputArg1 = parseInt(value, 10);
        const unit: moment.unitOfTime.DurationConstructor | void = this.getUnit(value);

        // default to last 24 hours
        let date = moment().subtract(24, 'hours').unix() * 1000;

        if (num > 0 && unit) {
            if (unit === 'months') {
                let newDate = moment().startOf('day').startOf('month');
                if (num !== 1) {
                    newDate = newDate.subtract(num, unit);
                }
                date = newDate.unix() * 1000;
            } else {
                date = moment().subtract(num, unit).unix() * 1000;
            }
        }

        return date;
    }

    private getUnit(value: any): moment.unitOfTime.DurationConstructor | void {
        if (value.includes('h')) {
            return 'hours';
        } else if (value.includes('d')) {
            return 'days';
        } else if (value.includes('w')) {
            return 'weeks';
        } else if (value.includes('m')) {
            return 'months';
        } else {
            return;
        }
    }
}
