import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/*
 * Convert Bytes to MB, GB, TB
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | bytes:number
 * Example:
 *   {{ 1024 | bytes }}
 *   formats to: 1MB
 */
@Pipe({
    name: 'tobytes',
})
export class TobytesPipe implements PipeTransform {
    transform(value: number, asString = true): any {
        const pipe = new DecimalPipe('en-US-POSIX');

        let unit = 'B';
        let converted = value;

        if (value >= 1024 && value < Math.pow(1024, 2)) {
            unit = 'KB';
            converted = value / 1024;
        } else if (value >= Math.pow(1024, 2) && value < Math.pow(1024, 3)) {
            unit = 'MB';
            converted = value / 1024 / 1024;
        } else if (value >= Math.pow(1024, 3) && value < Math.pow(1024, 4)) {
            unit = 'GB';
            converted = value / 1024 / 1024 / 1024;
        } else if (value >= Math.pow(1024, 4)) {
            unit = 'TB';
            converted = value / 1024 / 1024 / 1024 / 1024;
        }

        if (asString) {
            return pipe.transform(converted, '1.0-2') + unit;
        } else {
            return { value: parseFloat(pipe.transform(converted, '1.0-2')), unit: unit };
        }
    }
}
