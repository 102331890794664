import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { MatTabsModule } from '@angular/material/tabs';
import { RecentAlertsComponent } from './recent-alerts.component';

@NgModule({
    imports: [CommonModule, MatTabsModule, UiPipesModule],
    declarations: [RecentAlertsComponent],
    exports: [RecentAlertsComponent],
})
export class SharedRecentAlertsModule {}
