import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
    playerVars: YT.PlayerVars = { rel: 0, autoplay: 0, playlist: '6WeXbhgipPc', playsinline: 1 };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private logger: LoggerService,
        private dialogRef: MatDialogRef<VideoPlayerComponent>
    ) {}

    ngOnInit(): void {
        this.logger.log('player init');
    }

    hide() {
        this.dialogRef.close();
    }
}
