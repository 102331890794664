import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { HttpClientModule } from '@angular/common/http';
import { BannerMessageComponent } from './banner-message/banner-message.component';

@NgModule({
    imports: [CommonModule, SharedModelModule, HttpClientModule],
    declarations: [BannerMessageComponent],
    exports: [BannerMessageComponent],
})
export class SharedBannerMessageModule {}
