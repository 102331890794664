import { Injectable } from '@angular/core';
import { AzureSubscription, AzureVirtualWan } from '@netfoundry-ui/shared/model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class AzureVirtualWanService extends MopResourceService {
    private azureVirtualWansSource = new Subject<AzureVirtualWan[]>();
    azureVirtualWans = this.azureVirtualWansSource.asObservable();
    private apiSub = new Subscription();
    private mapSub = new Subscription();

    constructor(protected logger: LoggerService, protected apiSvc: ApiService) {
        super(logger, apiSvc);
    }

    /**
     * Gets all azureVirtualWans for azureSubscription
     */
    get(): Observable<any> {
        if (this.apiSub) {
            this.apiSub.unsubscribe();
        }

        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }
        // watch for when the network updates, when it does, update the gateways observable
        this.apiSub = this.apiService.currentAzureSubscription.subscribe((azureSubscription) => {
            this.mapSub = this.apiService
                .getLinkedResource(new AzureSubscription(azureSubscription), 'virtualWans')
                .pipe(map(this._extractEmbedded))
                .subscribe((data) => {
                    this.azureVirtualWansSource.next(data);
                });
        });

        // this is an observable that watches for azure subscription changes
        return this.azureVirtualWans;
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const azureVirtualWans: AzureVirtualWan[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['azureVirtualWans'] !== undefined) {
            for (let i = 0; i < res['_embedded']['azureVirtualWans'].length; i++) {
                const azureVirtualWan = res['_embedded']['azureVirtualWans'][i];
                azureVirtualWans.push(new AzureVirtualWan(azureVirtualWan));
            }

            return azureVirtualWans;
        }

        return [];
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return 'azureVirtualWans';
    }
}
