import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService, IamService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Environment, ENVIRONMENT, Tenant } from '@netfoundry-ui/shared/model';
import { HTTP_CLIENT, LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-organization-prompt',
    templateUrl: './organization-prompt.component.html',
    styleUrls: ['./organization-prompt.component.scss'],
})
export class OrganizationPromptComponent implements OnInit {
    keyRefresher;

    title = '';
    subtitle = '';
    errors = [];
    isOpen = false;
    buttonLabel = 'Next';
    screenIndex = 0;
    originDomain = 'nfconsole.io';
    proto = location.protocol;
    model = {
        name: '',
        domain: '',
        firstName: '',
        lastName: '',
        email: '',
        roles: [],
    };
    screens = [
        {
            title: 'Create a New Organization',
            subtitle: 'Get started by choosing a name for your organization.',
        },
        {
            title: 'Customize Your Organization',
            subtitle: 'Perfect, now let’s setup a custom url for this organization.',
        },
        /*{
title: 'Customize Your Organization',
subtitle: 'Excellent, now choose one or more default roles for new users.'
},*/
        {
            title: 'Setting Up Your Organization',
            subtitle: 'This is where your users will access their organization.',
        },
        {
            title: 'All Set!',
            subtitle:
                'As an administrator you can access your organization at any time via the organization toggle, or logging in at your custom URL below.',
        },
    ];

    identityId;

    roles = [];

    roleMap = [];

    rolesSelected = [];

    dbProvider;

    signupUrl;

    providerTypes = [];

    isLoading = false;

    currentLoggedInTenant = new Tenant({});

    constructor(
        private logger: LoggerService,
        private growlerService: GrowlerService,
        private dialogRef: MatDialogRef<OrganizationPromptComponent>,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        private iamService: IamService,
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    async ngOnInit() {
        this.signupUrl = this.environment.identityConfig.invitationUrl;

        this.getProviders();
        this.screenIndex = 0;
        this.isOpen = true;
        this.showScreen();
        const name = localStorage.getItem('profile_name');
        const nameList = name.split(' ');
        if (nameList.length > 1) {
            this.model.firstName = nameList[0];
            this.model.lastName = nameList[1];
        } else if (nameList.length === 1) {
            this.model.firstName = nameList[0];
            this.model.lastName = '';
        } else {
            this.model.firstName = '--';
            this.model.lastName = '--';
        }
        this.model.email = localStorage.getItem('profile_email');
        /*
await this.iamService.get('identities', 'self').toPromise().then(identity => {
this.identityId = identity['id'];
});


this.authService.getRoles(this.identityId).subscribe(result=> {
this.roles = result['content'];
for(let role of this.roles) {
this.roleMap[role.id] = role;
}
});
*/

        this.iamService.get('tenants', localStorage.getItem('loggedInTenantId')).subscribe((res) => {
            this.currentLoggedInTenant = res as Tenant;
        });
    }

    async validate() {
        this.errors = [];
        if (this.screenIndex === 0) {
            if (this.model.name.length === 0 || this.model.name.length > 128) {
                this.errors[this.errors.length] = 'NewOrgName';
                return false;
            }
        } else if (this.screenIndex === 1) {
            const result = await this.checkSiteName();
            this.errors[this.errors.length] = 'NewOrgDomain';
            return result;
        }
        return true;
    }

    copy() {
        const element = <HTMLInputElement>document.getElementById('DomainName');
        element.focus();
        element.select();
        document.execCommand('copy');
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Site Url Copied',
                'Your new site url ' + this.model.domain + ' has been copied to your clipboard'
            )
        );
    }

    back() {
        if (this.screenIndex > 0) {
            this.screenIndex--;
            this.showScreen();
        }
    }

    showBack() {
        return this.screenIndex > 0 && this.screenIndex < this.screens.length - 1;
    }

    async next() {
        if (await this.validate()) {
            if (this.screenIndex === 2) {
                this.signup();
            } else {
                this.screenIndex++;
                if (this.screenIndex < this.screens.length) {
                    if (this.screenIndex === this.screens.length - 1) {
                        this.buttonLabel = 'Close';
                    } else {
                        this.buttonLabel = 'Next';
                    }
                    this.showScreen();
                } else {
                    this.close();
                }
            }
        }
    }

    isUnder(index) {
        return this.screenIndex >= index;
    }

    showScreen() {
        this.title = this.screens[this.screenIndex].title;
        this.subtitle = this.screens[this.screenIndex].subtitle;
    }

    close() {
        this.screenIndex = 0;
        this.isOpen = false;
        this.dialogRef.close();
    }

    errorsContain(id) {
        for (let i = 0; i < this.errors.length; i++) {
            if (this.errors[i] === id) {
                return true;
                break;
            }
        }
    }

    onKey(event) {
        this.model.domain = this.model.domain.substring(0, 63);
        this.model.domain = this.model.domain.replace(/[^A-Za-z0-9-]/g, '');
        clearTimeout(this.keyRefresher);
        this.keyRefresher = setTimeout(() => {
            this.model.domain = this.model.domain.replace(/-(?![A-Za-z0-9])/g, '');
        }, 500);
    }

    async signup() {
        this.isLoading = true;
        this.onKey(true);

        const body = {
            name: this.model.name,
            label: this.model.domain,
            auth0ConnectionId: this.dbProvider.auth0ConnectionId,
            auth0ConnectionType: this.dbProvider.auth0ConnectionType,
            identityProviderName: `${this.model.domain} ${this.dbProvider.identityProviderTypeName}`,
        };

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const tenantLabelResult = await this.http
            .get(this.environment.identityConfig.url + 'tenants/label/' + this.model.domain, { headers: headers })
            .toPromise()
            .then(
                (result) => {
                    this.growlerService.show(
                        new GrowlerData('error', 'Error', 'Custom URL In use', 'Please enter a new URL')
                    );
                    this.screenIndex = 1;
                    this.isLoading = false;
                },
                (error) => true
            );

        if (tenantLabelResult) {
            await this.iamService
                .create('tenants', body)
                .toPromise()
                .then(
                    (res) => {
                        this.logger.info('Signup response', res);
                        this.isLoading = false;
                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Site Creation Successful',
                                'Your new site was successfully created. Accept the invitation you receive to access it'
                            )
                        );
                        this.screenIndex++;
                        this.buttonLabel = 'Close';
                    },
                    (error) => {
                        this.isLoading = false;
                        const errorMessage = error.error.errorDetail;
                        this.growlerService.show(new GrowlerData('error', 'Error', 'Registration Error', errorMessage));
                        this.logger.error('Signup Error', error);
                        this.screenIndex = 0;
                    }
                );
        }
    }

    /**
     * This call is stubbed in for when we have a selectable authorization provider
     */
    getProviders() {
        this.iamService.getProviders().subscribe((providers: any) => {
            this.logger.info('Providers', providers);
            // @FIXME - TEMP fix until pipeline clears on the backend
            for (const provider of providers) {
                if (provider.auth0ConnectionId === 'Username-Password-Authentication') {
                    provider.auth0ConnectionType = 'Database';
                    // @FIXME - Force the DB provider as the default for now, eventually this will change to be selectable
                    this.dbProvider = provider;
                } else {
                    provider.auth0ConnectionType = 'Social';
                }
                this.providerTypes.push(provider);
            }
        });
    }

    private async checkSiteName() {
        const headers = new HttpHeaders();
        return this.http
            .get(this.environment.identityConfig.url + 'tenants/label/' + this.model.domain, { headers: headers })
            .toPromise()
            .then(
                () => {
                    this.growlerService.show(
                        new GrowlerData('error', 'Error', 'Organization Name In use', 'Please enter a new name')
                    );
                    return false;
                },
                () => true
            );
    }
}
