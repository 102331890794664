<div *ngIf="step === showStep">
    <div class="title" id="AppwanBuilding" style="filter: brightness(50%)">
        {{ titleString != null ? titleString : 'Your AppWan' }} Is Building
    </div>
    <div class="subtitle smaller">
        {{ titleString != null ? titleString : 'Your AppWan' }} is being created<br />Details are below.
    </div>
    <div class="row center" id="appwanWizardBuild">
        <div [ngClass]="stepStyle" class="building appwan"></div>
        <div [ngClass]="{ open: stepStyle === 'step1' }" class="buildSteps" id="CreateAppwan">Creating AppWAN</div>
        <div [ngClass]="{ open: stepStyle === 'step0' }" class="buildSteps" id="CreateService">Creating Gateways</div>
        <div [ngClass]="{ open: stepStyle === 'step2' }" class="buildSteps" id="CreateService">Creating Services</div>
        <div [ngClass]="{ open: stepStyle === 'step3' }" class="buildSteps" id="VerifyGateway">Verifying Gateways</div>
        <div [ngClass]="{ open: stepStyle === 'step4' }" class="buildSteps" id="AssignGateways">Assigning Gateways</div>
        <div [ngClass]="{ open: stepStyle === 'step5' }" class="buildSteps" id="VerifyServices">Verifying Services</div>
        <div [ngClass]="{ open: stepStyle === 'step6' }" class="buildSteps" id="AssignServices">Assigning Services</div>
    </div>
    <div class="content" id="appwanWizardCreated">
        <div style="margin: 30px auto; clear: both; width: 200px">
            <div *ngIf="appwanCreated" class="statusRow">
                <span class="bubble severityInfo"></span>
                <div class="statusText" id="appwanCreated">AppWAN Created</div>
            </div>
            <div *ngIf="gatewaysCreated" class="statusRow">
                <span class="bubble severityInfo"></span>
                <div class="statusText" id="gatewayVerified">Gateways Created</div>
            </div>
            <div *ngIf="servicesCreated" class="statusRow">
                <span class="bubble severityInfo"></span>
                <div class="statusText" id="ServiceCreated">Services Created</div>
            </div>
            <div *ngIf="gatewaysAreReady" class="statusRow">
                <span class="bubble severityInfo"></span>
                <div class="statusText" id="gatewayVerified">Gateways Verified</div>
            </div>
            <div *ngIf="gatewaysAreAssigned" class="statusRow">
                <span class="bubble severityInfo"></span>
                <div class="statusText" id="gatewaysAssigned">Gateways Assigned</div>
            </div>
            <div *ngIf="servicesAreReady" class="statusRow">
                <span class="bubble severityInfo"></span>
                <div class="statusText" id="ServicVerified">Services Verified</div>
            </div>
            <div *ngIf="servicesAreAssigned" class="statusRow">
                <span class="bubble severityInfo"></span>
                <div class="statusText" id="ServiceAssigned">Services Assigned</div>
            </div>
            <div *ngFor="let error of errors" class="statusRow">
                <span class="bubble severityInfo error"></span>
                <div class="statusText error" id="statusError">{{ error }}</div>
            </div>
        </div>
    </div>
</div>
