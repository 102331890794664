<div class="label row">{{ title }}</div>
<div class="row">
    <!-- US city with full geo -->
    <div *ngIf="render === 'us-geo'">
        <input [ngModel]="usGeoString" class="restrictNormal geoInput location" disabled id="LocationInputUS" />
    </div>

    <!-- Outside of US with full geo -->
    <div *ngIf="render === 'foreign-geo'">
        <input
            [ngModel]="foreignGeoString"
            class="restrictNormal geoInput location"
            disabled
            id="LocationInputForeign"
        />
    </div>

    <!-- outside of US with minimal geo -->
    <div *ngIf="render === 'basic-geo'">
        <input [ngModel]="basicGeoString" class="restrictNormal geoInput location" disabled id="LocationInputBasic" />
    </div>

    <!-- no geo and not AWS region -->
    <div *ngIf="render === 'region'">
        <input [ngModel]="region" class="restrictNormal location" disabled id="LocationInputRegion" />
    </div>

    <!-- no geo and AWS region -->
    <div *ngIf="render === 'data-center'">
        <input [ngModel]="dataCenter" class="restrictNormal location" disabled id="LocationInputDC" />
    </div>
</div>
