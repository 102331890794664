import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppWan, Service } from '@netfoundry-ui/shared/model';
import { ValidateService } from '@netfoundry-ui/shared/services';

const skipString = 'skipAppWanMigrationIntro';

@Component({
    selector: 'app-app-wan-migration-wizard',
    templateUrl: './app-wan-migration-wizard.component.html',
    styleUrls: ['./app-wan-migration-wizard.component.scss'],
})
export class AppWanMigrationWizardComponent {
    // child Feature of the wizard
    @ViewChild('gatewaySelect') gatewaySelect;
    @ViewChild('serviceSelect') serviceSelect;
    @ViewChild('saveComponent') saveComponent;

    step = 1;

    // the highest step the user has been to. used for navigating via the step bubbles
    highestStep = 2;

    skip = false;
    form = {
        appWanName: {
            error: false,
            placeholder: 'Enter an AppWAN Name',
            label: 'AppWAN Name',
            value: '',
        },
    };
    model: AppWan = new AppWan({});
    finalModel: AppWan = new AppWan({});

    services: Service[] = [];
    ipNetworkServices: Service[] = [];
    ipHostServices: Service[] = [];

    sourceGatewayType;
    targetGatewayType;

    sourceGateway;
    targetGateway;

    hasStepped = false;

    constructor(
        private dialogRef: MatDialogRef<AppWanMigrationWizardComponent>,
        public validateService: ValidateService,
        private changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // checking if the user has the skip intro option in local storage
        const skipIntro = localStorage.getItem(skipString);

        // if they do, skip the intro
        if (skipIntro === 'true') {
            this.skip = true;
            this.step = 2;
        } else {
            this.skip = false;
        }
    }

    // function for moving forward
    async next() {
        this.hasStepped = true;

        switch (this.step) {
            case 2:
                if (this.validateAppwanName()) {
                    this.model.name = this.form.appWanName.value;
                    this.step++;
                    this.highestStep++;
                }
                break;

            case 3:
                if (this.gatewaySelect.validateSourceGateway()) {
                    this.sourceGateway = this.gatewaySelect.getSourceGateway();
                    this.sourceGatewayType = this.sourceGateway.endpointType;
                    this.step++;
                    this.highestStep++;
                }
                break;

            case 4:
                if (this.gatewaySelect.validateTargetGateway()) {
                    this.targetGateway = this.gatewaySelect.getTargetGateway();
                    this.targetGatewayType = this.targetGateway.endpointType;
                    this.step++;
                    this.highestStep++;
                }
                break;

            case 5:
                if (this.serviceSelect.validateServices()) {
                    this.ipHostServices = this.serviceSelect.getIpHostServices();
                    this.ipNetworkServices = this.serviceSelect.getIpNetworkServices();
                    this.services = this.serviceSelect.getServices();
                    this.step++;
                    this.create();
                }
                break;

            default:
                this.step++;
        }
        this.changeDetector.detectChanges();
    }

    back() {
        this.step--;
    }

    // function for moving to a specific step
    goTo(stepNumber) {
        // if the user has already been at the step selected, go to the step
        if (stepNumber <= this.highestStep) {
            this.step = stepNumber;
        }
    }

    isNotIntroSummary() {
        return this.step > 1 && this.step < 6;
    }

    isPassedStep(index) {
        return this.step > index;
    }

    isNotAtStep(index) {
        return this.step < index;
    }

    toggleSkip() {
        this.skip = !this.skip;
        if (this.skip) {
            localStorage.setItem(skipString, 'true');
        } else {
            localStorage.removeItem(skipString);
        }
    }

    close() {
        this.dialogRef.close();
    }

    isBetweenSteps(start, end) {
        return this.step > start && this.step < end;
    }

    async create() {
        this.finalModel = await this.saveComponent.save();
        if (this.finalModel != null) {
            this.sourceGateway = this.finalModel['hostingGateway'];
            this.targetGateway = this.finalModel['targetGateway'];
            this.step++;
        }
    }

    clone() {
        // makging the previous appwan name the placeholder
        this.form.appWanName.placeholder = this.form.appWanName.value;

        // resetting the appwan name
        this.form.appWanName.value = '';

        // creating a new appwan model
        this.model = new AppWan({});

        // clearing the list of services
        this.services = [];

        // clearing the gateway list map
        this.gatewaySelect.clearGatewayListMap();

        // preping the service select component for cloning
        this.serviceSelect.clone();

        // resetting the save component
        this.saveComponent.reset();

        // going back to step 2
        this.step = 2;
    }

    validateAppwanName() {
        const isValid =
            this.validateService.hasValue(this.form.appWanName.value) &&
            this.validateService.isValidName(this.form.appWanName.value) &&
            this.form.appWanName.value.length < 53;
        this.form.appWanName.error = !isValid;
        return isValid;
    }
}
