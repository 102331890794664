<div
    *ngIf="!isWarning"
    [ngClass]="{ isCancel: isCancel }"
    class="modal solid confirmModal open"
    id="ConfirmModal{{ appendId }}"
>
    <div (click)="cancel()" *ngIf="!isTimeoutWarning" class="close cancel" id="CancelXButton{{ appendId }}"></div>
    <div (click)="confirm()" *ngIf="isTimeoutWarning" class="close cancel" id="CancelXButton{{ appendId }}"></div>
    <div class="icon {{ icon }}"></div>
    <div class="title">{{ title }}</div>
    <div [ngClass]="{ isDestructive: isDestructive, isCancel: isCancel }" class="subtitle">
        <div class="scroll">
            <div *ngIf="useInnerHtml" [innerHtml]="subtitle"></div>
            <div *ngIf="!useInnerHtml">{{ subtitle }}</div>
            <div *ngIf="bulletList.length > 0" class="bullets" id="BulletList">
                <ul>
                    <li
                        (click)="!!bulletListOnClick && bulletListOnClick(item, context)"
                        *ngFor="let item of bulletList"
                        [ngClass]="{ bulletClick: !!bulletListOnClick }"
                    >
                        <a href="{{ item.src }}">{{ item.name }}</a>
                    </li>
                </ul>
            </div>
            <div *ngIf="afterListText" class="after-list-text">
                {{ afterListText }}
            </div>
            <div *ngIf="secondBulletList.length > 0" class="bullets" id="BulletList">
                <ul>
                    <li
                        (click)="!!secondBulletListOnClick && secondBulletListOnClick(item, context)"
                        *ngFor="let item of secondBulletList"
                        [ngClass]="{ bulletClick: !!secondBulletListOnClick }"
                    >
                        {{ item.name || item }}
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="isDestructive">
            <div class="row deleteRow deleteLabel label">
                Type "{{ this.isCancel ? 'cancel' : 'delete' }} <span id="deleteName">{{ itemName }}"</span> to confirm
            </div>
            <div class="row deleteRow">
                <input
                    [(ngModel)]="confirmString"
                    [ngClass]="{ error: errorConfirm }"
                    id="ConfirmDelete"
                    placeholder="{{ placeholder }}"
                    type="text"
                />
            </div>
        </div>
        <div *ngIf="showWarning">
            <p-message
            severity="warn"
            text="Please note that this may cause some downtime with the network controller due to the addition of the Public Certificate."
        ></p-message>
        </div>
    </div>

    <div class="buttons">
        <div (click)="cancel()" class="linkButton cancel" id="CancelConfirmButton{{ appendId }}" *ngIf="!hideCancelLink">
            {{ cancelAction }}
        </div>
        <button
            *ngIf="action && action !== ''"
            (click)="confirm()"
            class="save alert confirm"
            id="ConfirmAction{{ appendId }}"
        >
            {{ action }}
        </button>
    </div>
</div>

<!--
   Differnt styles for when displaying as a warning for the HA gateway upgrade
   The original plan was to handle this with the ngClass directive. However, doing so would cause the classes to update after the
   view initialized. This caused the dialog to have an expanding effect as it was displayed
   The quick solution was to use ngIf to handle the cases separtely
-->

<div *ngIf="isWarning" class="modal solid confirmModal open warning" id="ConfirmModal{{ appendId }}">
    <div (click)="cancel()" class="close cancel" id="CancelXButton{{ appendId }}"></div>
    <div class="icon {{ icon }}"></div>
    <div class="title">{{ title }}</div>
    <div class="subtitle">
        {{ subtitle }}
        <div *ngIf="bulletList.length > 0" class="bullets" id="BulletList">
            <ul class="bulletListNotAllowed">
                <li
                    (click)="!!bulletListOnClick && bulletListOnClick(item, context)"
                    *ngFor="let item of bulletList"
                    [ngClass]="{ bulletClick: !!bulletListOnClick }"
                >
                    {{ item.name }}
                </li>
            </ul>
        </div>
    </div>
    <div class="buttons">
        <button (click)="confirm()" class="save alert confirm" id="ConfirmAction{{ appendId }}">
            {{ action }}
        </button>
    </div>
</div>
