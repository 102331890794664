<div class="utilization-total">
    <div class="thirdGrid">
        <div class="total-tx">{{ totalTx | tobytes }} <span class="metric-label">Tx</span></div>

        <div class="divider">/</div>

        <div class="total-rx">{{ totalRx | tobytes }} <span class="metric-label">Rx</span></div>
    </div>

    <div class="total">
        Total: {{ totalTx + totalRx | tobytes }}
        <!--    <span class="label">Total</span>-->
    </div>
</div>
