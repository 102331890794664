import { Injectable } from '@angular/core';
import { Region } from '@netfoundry-ui/shared/model';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class RegionService extends MopResourceService {
    private geoRegionsSource = new ReplaySubject<Region[]>();
    geoRegions = this.geoRegionsSource.asObservable();

    constructor(protected logger: LoggerService, private apiservice: ApiService) {
        super(logger, apiservice);
    }

    /**
     * Gets all Regions
     */
    get(): Observable<any> {
        this.apiService
            .get('geoRegions')
            .pipe(map(this._extractEmbedded))
            .subscribe((data) => {
                this.geoRegionsSource.next(data);
            });

        return this.geoRegions;
    }

    _getCreateUrl() {
        return null;
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const regions: Region[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['geoRegions'] !== undefined) {
            for (let i = 0; i < res['_embedded']['geoRegions'].length; i++) {
                const region = res['_embedded']['geoRegions'][i];
                regions.push(new Region(region));
            }
        }

        return regions;
    }
}
