import { ClientSyncResource } from './client-sync-resource';

export class ZitiActiveDirectory extends ClientSyncResource {
    // gateways - more to come
    public accountDN: string;
    public baseDN: string;
    public password: string;
    public identity: string;
    public serviceName: string;

    constructor(object) {
        super(object);
        this.provider = 'ZITI_AD';
        this.supportsDeviceSync = false;
        this.supportsUserSync = true;
    }

    // Map of codes with labels
    public getClientNameFields() {
        return [
            { name: 'Common Name (CN)', value: 'cn' },
            { name: 'Display Name', value: 'displayName' },
            { name: 'User Principal name', value: 'userPrincipalName' },
            { name: 'Email', value: 'mail' },
        ];
    }

    public getSaveObject() {
        return {
            provider: this.provider,
            accountDN: this.accountDN,
            password: this.password,
            baseDN: this.baseDN,
            identity: this.identity,
            serviceName: this.serviceName,
        };
    }
}
