import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('certificate-authorities')
export class CertificateAuthorityV2 extends Resource {
    id: string;
    networkId: string;
    name: string;
    fingerprint: string;
    jwt: string;
    authEnabled: boolean;
    ottCaEnrollmentEnabled: boolean;
    autoCaEnrollmentEnabled: boolean;
    verified: boolean;
    verificationToken: string;
    endpointAttributes: string[];
    identityNameFormat: string;
    createdAt: string;
    updatedAt: string;
    selected = false;
}
