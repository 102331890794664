import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiNoitemsModule } from '@netfoundry-ui/ui/noitems';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { GrowlerErrorHistoryComponent } from './growler-error-history/growler-error-history.component';
import { GrowlerComponent } from './growler.component';

@NgModule({
    imports: [CommonModule, FormsModule, UiPipesModule, UiNoitemsModule],
    declarations: [GrowlerComponent, GrowlerErrorHistoryComponent],
    exports: [GrowlerComponent, GrowlerErrorHistoryComponent],
})
export class SharedGrowlerModule {}
