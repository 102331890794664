import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { CertificateAuthority, Endpoint, Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { ApiService, CertificateAuthorityService, RefresherService } from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { CertificateAuthoritiesFormComponent } from './certificate-authorities-form/certificate-authorities-form.component';
import { CertificateAuthoritiesVerifyFormComponent } from './certificate-authorities-verify-form/certificate-authorities-verify-form.component';

const columns = [
    'name',
    'isAutoCaEnrollmentEnabled',
    'isOttCaEnrollmentEnabled',
    'isAuthEnabled',
    'isVerified',
    'verificationToken',
    'createdAt',
    'updatedAt',
];
const filename = 'certificate-authorities';
const translateStatus = true;

@Component({
    selector: 'app-certificate-authorities',
    templateUrl: './certificate-authorities.component.html',
    styleUrls: ['./certificate-authorities.component.scss'],
})
export class CertificateAuthoritiesComponent implements OnInit, OnDestroy {
    model: CertificateAuthority = new CertificateAuthority({});

    items = [];
    displayedItems = [];
    itemCount = 0;
    showList = false;
    isLoading = true;
    allToggled = false;
    filterString = '';
    sorting = 'name';
    ordering = 'asc';
    selectedCAIds = [];
    dialogRef;
    isDeleteAction = false;
    tooltipPosition = 'above';
    page = 1;
    pageSize = this.environment.pageSize;
    totalElements = 0;
    hasSomeDeletePermission = false;
    noDeleteAuthCount = 0;
    currentNetworkId = '';
    caSub: Subscription = new Subscription();
    deleteCAIds = [];
    openIndex = -1;
    private deleting = 0;
    private deleteMe = [];
    private subscription = new Subscription();
    private isNullCurrentNetwork = false;
    private networkStatus = 0;
    private paginationSort = ['name', 'asc'];
    private paginationFilter;
    private openId = '';

    constructor(
        private refresher: RefresherService,
        private apiService: ApiService,
        public dialogForm: MatDialog,
        private growlerService: GrowlerService,
        public authorizationService: AuthorizationService,
        private filterService: TableFilterService,
        private csvDownloadService: CsvDownloadService,
        private certificateAuthorityService: CertificateAuthorityService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    ngOnInit() {
        // subscribing to the filter service to handle the change in page
        this.subscription.add(
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.page = pageNum;
            })
        );

        // subscribing to the filter service to handle search filter changes
        this.subscription.add(
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.filterString = filterString;
                this.applyFilter();
            })
        );

        this.caSub.closed = true;

        this.subscription.add(
            this.apiService.currentNetwork.subscribe((result) => {
                // reset the page when the network is switched
                this.page = 1;

                const newNetworkId = result.getId();

                if (newNetworkId !== null) {
                    this.isLoading = true;
                    this.isNullCurrentNetwork = false;
                    this.networkStatus = result.status;
                } else {
                    this.isNullCurrentNetwork = true;
                    this.isLoading = false;
                }

                if (this.currentNetworkId !== newNetworkId) {
                    this.isDeleteAction = false;
                    this.allToggled = false;
                }

                this.currentNetworkId = newNetworkId;
                this.handlePermissionCheck();
            })
        );
    }

    async handlePermissionCheck() {
        if (this.authorizationService.canListCAs()) {
            if (_.isEmpty(this.currentNetworkId)) {
                this.isLoading = false;
                return;
            }
            if (!this.caSub.closed) {
                this.certificateAuthorityService.get();
            } else {
                this.initCAs();
            }
        } else {
            this.caSub.unsubscribe();
            this.isLoading = false;
            this.showList = false;
            this.items = [];
        }
    }

    applyFilter() {
        this.displayedItems = this.filterService.applyLocalFilter(this.items);
        const newSelectedCAIds = [];
        for (const ca of this.displayedItems) {
            if (this.selectedCAIds.indexOf(ca.id) > -1 || this.allToggled) {
                newSelectedCAIds.push(ca.id);
                ca.selected = true;
            } else {
                ca.selected = false;
            }
        }
        this.selectedCAIds = newSelectedCAIds;
        this.isDeleteAction = this.anyToggled();

        this.totalElements = this.displayedItems.length;

        // TODO - enabled when using pagination and sorting. Need to also determine when to send the request. currently this function is
        //          getting called on keyup of the search bar, which would cause this to send a request for every key they press
        /*
this.paginationFilter = this.filterString;
this.filterService.setTotalElements(this.certificateAuthorityService.page.totalElements);

// TODO set total pages based on this.service.page.totalPages once using backend pagination
this.filterService.updateTotalPages();

// determining whether or not to refresh the page
if(this.isUsingPagination()) {
this.page = 1;
this.refresh();
}
*/
    }

    closeActionMenu() {
        this.openIndex = -1;
    }

    refresh() {
        this.refresher.disableRefresh();
        if (!this.isNullCurrentNetwork) {
            this.handlePermissionCheck();
            this.refresher.refreshTimerId = setTimeout(() => {
                this.refresh();
            }, this.refresher.refreshInterval);
        }
    }

    toggleAll() {
        this.allToggled = !this.allToggled;
        this.selectedCAIds = [];

        for (const item of this.displayedItems) {
            if (item['actionList'].includes('delete')) {
                item.selected = this.allToggled;
                if (this.allToggled) {
                    this.selectedCAIds.push(item.id);
                }
            }
        }

        this.isDeleteAction = this.anyToggled();
    }

    toggle(item: Endpoint) {
        if (item['actionList'].includes('delete')) {
            item.selected = !item.selected;

            if (this.allToggled) {
                this.allToggled = !this.allToggled;
            }

            const index = this.selectedCAIds.indexOf(item.id);
            if (index > -1) {
                this.selectedCAIds.splice(index, 1);
            } else {
                this.selectedCAIds.push(item.id);
            }
            this.isDeleteAction = this.anyToggled();
        }
    }

    anyToggled() {
        if (this.selectedCAIds.length > 0) {
            if (this.selectedCAIds.length === this.itemCount - this.noDeleteAuthCount) {
                this.allToggled = true;
            }
            return true;
        }
        this.allToggled = false;
        return false;
    }

    toggleMenu(event, index) {
        event.stopPropagation();
        if (index !== this.openIndex) {
            this.openIndex = index;
        } else {
            this.openIndex = -1;
        }
    }

    openConfirm(deleteEvent) {
        if (deleteEvent) {
            const deletingInfo = this.getDeletingTotal();

            const data = {
                title: 'Delete',
                appendId: 'CertificateAuthorities',
                subtitle: deletingInfo.deleteString,
                afterListText: deletingInfo.afterListString,
                bulletList: deletingInfo.names,
                itemName: deletingInfo.itemName,
                icon: 'DestructiveDelete',
                action: 'Yes',
                isDestructive: true,
            };
            this.dialogRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            this.dialogRef.afterClosed().subscribe((result) => {
                // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                //  logged out of the console and we should close the dialog without continuing
                if (result === undefined) {
                    this.confirmed(false);
                } else if (result['loggingOut'] === undefined) {
                    this.confirmed(result);
                    this.refresh();
                }
            });
        }
    }

    confirmDelete(item: Endpoint) {
        this.toggleAll();
        if (this.allToggled) {
            this.toggleAll();
        }

        item.selected = true;
        const index = this.selectedCAIds.indexOf(item.id);
        if (index === -1) {
            this.selectedCAIds.push(item.id);
        }
        this.isDeleteAction = true;
        this.openConfirm(true);
    }

    confirmed(event) {
        if (event) {
            this.refresher.disableRefresh();
            this.isLoading = true;
            this.deleting = this.getTotalSelected();
            for (const item of this.displayedItems) {
                if (item.selected) {
                    this.deleteMe[this.deleteMe.length] = item;
                }
            }
            this.deleteNext();
        } else {
            this.selectedCAIds = [];
            this.isDeleteAction = false;
            this.refresh();
        }
    }

    async deleteNext() {
        this.deleting--;

        // normal CA delete
        this.subscription.add(
            this.certificateAuthorityService.delete(this.deleteMe[this.deleting]).subscribe(
                (data) => {
                    this.deleteCAIds.push(this.deleteMe[this.deleting].id);
                    this.postDelete();
                },
                (error) => {
                    this.postDelete();
                }
            )
        );
    }

    postDelete() {
        // forcing the deleted items to become untoggled
        this.toggle(this.deleteMe[this.deleting]);
        if (this.deleting === 0) {
            this.deleteMe = [];
            this.isLoading = true;
            this.refresh();
        } else {
            this.deleteNext();
        }
    }

    getTotalSelected() {
        return this.selectedCAIds.length;
    }

    getDeletingTotal() {
        const total = 0;
        let deletingItem;
        for (const item of this.displayedItems) {
            if (item.selected) {
                deletingItem = item;
                break;
            }
        }

        const deleteString = 'Are you sure you would like to delete the following Certificate Authority:';
        const afterListString = 'Any identities using the above CA for authentication will now fail to connect.';

        return {
            deleteString: deleteString,
            afterListString: afterListString,
            names: [deletingItem],
            itemName: deletingItem.name,
        };
    }

    create(addEvent) {
        if (addEvent && this.authorizationService.canCreateCAs()) {
            if (this.networkStatus === 300) {
                this.dialogRef = this.dialogForm.open(CertificateAuthoritiesFormComponent, {
                    data: {},
                    minHeight: '100%',
                    minWidth: '100%',
                    height: '100%',
                    width: '100%',
                });

                this.dialogRef.afterClosed().subscribe((result) => {
                    // under normal circumstances nothing is returned when the dialog is closed
                    //  however, if something is returned, it is because the user is being logged out while this dialog is opened
                    //    if this is the case, we do not want to call the refresh function as the user will be unauthenticated
                    if (result != null && result['loggingOut'] === undefined) {
                        this.refresh();
                    }
                });
            } else {
                this.growlerService.show(
                    new GrowlerData(
                        'error',
                        'Error',
                        'Network Not Ready',
                        'The Network must be ready in order to add a Certificate Authority.'
                    )
                );
            }
        }
    }

    async open(item: any) {
        if (item.actionList.includes('update')) {
            if (this.networkStatus === 300) {
                this.dialogRef = this.dialogForm.open(CertificateAuthoritiesFormComponent, {
                    data: {
                        model: item,
                    },
                    minHeight: '100%',
                    minWidth: '100%',
                    height: '100%',
                    width: '100%',
                });

                this.dialogRef.afterClosed().subscribe((result) => {
                    // under normal circumstances nothing is returned when the dialog is closed
                    //  however, if something is returned, it is because the user is being logged out while this dialog is opened
                    //    if this is the case, we do not want to call the refresh function as the user will be unauthenticated
                    if (result && result['loggingOut'] === undefined) {
                        this.isLoading = true;
                        this.refresh();
                    }
                });
            } else {
                this.growlerService.show(
                    new GrowlerData(
                        'error',
                        'Error',
                        'Network Not Ready',
                        'The Network must be ready in order to edit a Certificate Authority.'
                    )
                );
            }
        }
    }

    sort(sortBy) {
        if (this.sorting === sortBy) {
            if (this.ordering === 'asc') {
                this.ordering = 'desc';
            } else {
                this.ordering = 'asc';
            }
        } else {
            this.ordering = 'asc';
            this.sorting = sortBy;
        }

        // setting the paginationSort object
        this.paginationSort = [this.sorting, this.ordering];

        // determining whether or not to refresh the page
        if (this.isUsingPagination()) {
            this.refresh();
        }
    }

    getSortClass(id) {
        if (id === this.sorting) {
            return this.ordering;
        } else {
            return '';
        }
    }

    trackById(index, item) {
        return item.id;
    }

    // function for determining whether or not pagination is in use
    isUsingPagination() {
        return this.certificateAuthorityService.page != null && this.totalElements > this.pageSize;
    }

    setPage(pageNumber) {
        this.page = pageNumber;
        if (
            this.isUsingPagination() &&
            pageNumber > 0 &&
            pageNumber <= this.certificateAuthorityService.page.totalPages
        ) {
            this.refresh();
        }
    }

    downloadCsv(event: any) {
        const csvItems = [];

        for (const item of this.displayedItems) {
            if (item.selected) {
                csvItems.push(item);
            }
        }

        this.toggleMenu(event, -2);
        this.csvDownloadService.download(filename, csvItems, columns, translateStatus);
    }

    ngOnDestroy() {
        this.refresher.disableRefresh();
        this.subscription.unsubscribe();
        this.caSub.unsubscribe();
        this.filterService.reset();
    }

    verifyCA(item) {
        this.dialogRef = this.dialogForm.open(CertificateAuthoritiesVerifyFormComponent, {
            data: { model: item },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            // under normal circumstances nothing is returned when the dialog is closed
            //  however, if something is returned, it is because the user is being logged out while this dialog is opened
            //    if this is the case, we do not want to call the refresh function as the user will be unauthenticated
            if (result != null && result['loggingOut'] === undefined) {
                this.refresh();
            }
        });
    }

    private initCAs() {
        let newHasSomeDeletePermission = false;
        this.noDeleteAuthCount = 0;

        // TODO - use pagination when filtering is enabled
        this.caSub = this.certificateAuthorityService.get().subscribe((result) => {
            const newItems = [];
            const usePagination = false;
            // if the page object exists and the current page is greater than the total number of pages
            if (
                usePagination &&
                this.certificateAuthorityService.page != null &&
                this.page > this.certificateAuthorityService.page.totalPages
            ) {
                // sest the current page to the last page
                this.page = this.certificateAuthorityService.page.totalPages;
                // do a second get with the updated information
                this.certificateAuthorityService.get(this.page, this.paginationSort.toString(), this.paginationFilter);
            } else {
                const theResult = result as Endpoint[];

                for (const ca of theResult) {
                    if (this.openId === ca.id) {
                        this.open(ca);
                        this.openId = '';
                    }

                    const actions = [];
                    if (this.authorizationService.canUpdateCA(ca.id)) {
                        actions.push('update');
                    }

                    if (this.authorizationService.canDeleteCA(ca.id)) {
                        actions.push('delete');
                        newHasSomeDeletePermission = true;
                    } else {
                        this.noDeleteAuthCount++;
                    }

                    // TODO use correct permissions
                    if (this.authorizationService.canCreateCAs()) {
                        actions.push('verify');
                    }

                    ca['actionList'] = actions;

                    newItems.push(ca);
                }
            }
            this.items = newItems;
            // the deleted CA ids are only needed for the first refresh. After the first refresh they should no longer appear
            //  as they should be deleted
            // if the CA does appear again, then something went wrong with the delete and the status shouldn't be set to 800
            this.deleteCAIds = [];

            this.displayedItems = this.items;
            this.itemCount = this.items.length;
            this.showList = this.itemCount > 0;

            // bump up the refresh if the list is large and we haven't already bumped it
            if (this.itemCount > 100 && this.refresher.refreshInterval === this.refresher.getDefaultInterval()) {
                const mult = this.itemCount / 100 / 2;
                this.refresher.refreshInterval = this.refresher.refreshInterval * mult;
            }

            // obtaining the total number of CAs
            // if the certificateAuthorityService has a page object, set totalElements to page.totalElements
            // otherwise, set it to the length of the certificateAuthority list
            this.totalElements =
                this.certificateAuthorityService.page != null
                    ? this.certificateAuthorityService.page.totalElements
                    : this.items.length;

            // TODO - remove when pagination is fully implemented, this case should be handled by the first if statement in this subscription
            if (this.items.length === this.totalElements && this.items.length <= this.pageSize) {
                this.page = 1;
            }

            this.filterService.setTotalElements(this.totalElements);

            // TODO set total pages based on this.service.page.totalPages once using backend pagination
            this.filterService.updateTotalPages();
            this.applyFilter();
            this.isLoading = false;
            this.isDeleteAction = this.anyToggled();
            this.hasSomeDeletePermission = newHasSomeDeletePermission;
            this.refresher.disableRefresh();
            this.refresher.refreshTimerId = setTimeout(() => {
                this.refresh();
            }, this.refresher.refreshInterval);
        });
    }
}
