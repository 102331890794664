import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { ElasticsearchService, UtilizationTotalsQuery } from '@netfoundry-ui/shared/elasticsearch';
import { ApiService, ErrorHistoryService, LoggerService } from '@netfoundry-ui/shared/services';
import { TobytesPipe } from '@netfoundry-ui/ui/pipes';
import { Chart } from 'angular-highcharts';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-utilization-pie',
    templateUrl: './utilization-pie.component.html',
    styleUrls: ['./utilization-pie.component.scss'],
    providers: [TobytesPipe, ElasticsearchService],
})
export class UtilizationPieComponent implements OnInit, OnChanges, OnDestroy {
    @Input() networkDataTypes: string[] = ['ChannelTcpRx', 'ChannelUdpRx'];
    @Input() endpointType: any = false; // Allow filtering for clients or gateways
    @Input() networkId: any = null;
    @Input() endTime = Date.now();
    @Input() startTime = this.endTime - 24 * 60 * 60 * 1000;
    @Output() endpointName: EventEmitter<any> = new EventEmitter();
    @Input() endpointNameFilter: any = false;
    totals: any[];
    usageTotal = 0;
    piechart: Chart = new Chart({
        chart: { type: 'pie' },
        title: { text: null },
        credits: { enabled: false },
        tooltip: { enabled: false },
        plotOptions: {
            pie: {
                shadow: false,
                center: ['100px', '100px'], // this was doing weird things to the tooltip and hiding it behind the white
                dataLabels: {
                    enabled: false,
                },
            },
        },
        yAxis: {
            title: {
                text: null,
            },
        },
    });
    networkLoading = true;
    networkArea = false;
    noData = false;
    canReadElasticSearch = false;
    initialized = false;
    currentOrgId;
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private authorizationService: AuthorizationService,
        private elasticsearch: ElasticsearchService,
        private toBytes: TobytesPipe,
        private apiService: ApiService,
        private errorHistoryService: ErrorHistoryService
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentOrg.pipe(debounceTime(1000)).subscribe((org) => {
                this.currentOrgId = org.getId();

                this.initialized = true;
                this.logger.info('Utilization pie initialized');
                this.ngOnChanges();
            })
        );
    }

    ngOnDestroy() {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }
    }

    ngOnChanges() {
        // make sure we have enough data to auth check before we do anything
        if (!this.initialized || this.networkId === null || this.currentOrgId === null) {
            return;
        }

        this.canReadElasticSearch = this.authorizationService.canReadElasticSearch(this.currentOrgId, this.networkId);

        if (this.canReadElasticSearch) {
            // data depends on the current network being set
            if (this.networkId != null) {
                this.networkLoading = true;
                this.noData = false;
                this.initChart();
                this.getUtilizationTotals(this.networkId);
            } else {
                this.networkLoading = false;
                this.noData = true;
            }
        } else {
            this.logger.info('no perms for elasticsearch');
            this.networkLoading = false;
        }
    }

    setEndpointNameFilter(value) {
        if (this.endpointNameFilter === value) {
            this.endpointName.emit(false);
            this.endpointNameFilter = false;
        } else {
            this.endpointName.emit(value);
            this.endpointNameFilter = value;
        }
    }

    /**
     * TODO - Make the aggregation field dynamic from an observable
     */
    public getUtilizationTotals(networkId) {
        const query = new UtilizationTotalsQuery(this.logger);
        query.addTimeFilter(this.startTime, this.endTime);

        if (networkId === null) {
            this.networkLoading = false;
            this.noData = true;
            this.networkArea = false;
            return;
        }

        if (this.currentOrgId === null) {
            this.networkLoading = false;
            this.noData = true;
            this.networkArea = false;
            return;
        }

        // @FIXME
        query.addFilter('networkId', networkId);
        query.addFilter('organizationId', this.currentOrgId);

        query.addExclusion('nodeType', 'TransferNode');
        query.addExclusion('vtcType', 'TransferNode');
        query.matchFromList('NetworkDataType', this.networkDataTypes);
        query.setAggregate('commonName.keyword', 5);

        // optional filter by endpoint type
        if (this.endpointType) {
            query.addFilter('nodeType', this.endpointType);
        }

        this.logger.info('Utilization Totals Query', JSON.stringify(query.getQuery()));

        const interval = query.calculateInterval(this.startTime, this.endTime);
        const index = interval === '1d' ? 'ncvtchistorical' : 'ncvtccurrent';

        this.elasticsearch
            .search(this.currentOrgId, index, query.getQuery(), {
                networkId: this.networkId,
            })
            .subscribe(
                (data) => {
                    const table = this.elasticsearch.toTable(data, query);

                    if (Object.keys(table).length === 0) {
                        this.logger.info('No usable data returned for top 5 utilization');
                        this.networkLoading = false;
                        this.noData = true;
                        this.networkArea = false;
                        return;
                    }

                    // clear totals
                    this.totals = [];
                    this.usageTotal = 0;
                    const keys = Object.keys(table);

                    // build a new dataset
                    for (let i = 0; i < keys.length; i++) {
                        const key = keys[i];
                        const set = {
                            name: key,
                            y: table[key],
                        };

                        // update the shared properties
                        this.totals.push(set);
                        this.usageTotal += table[key];
                    }

                    this.logger.info(`Pie charting totals: ${this.totals.toString()}`);
                    this.networkLoading = false;
                    this.noData = false;
                    this.networkArea = true;

                    this.piechart.addSeries(
                        {
                            name: 'Endpoints',
                            data: this.totals,
                            type: 'pie',
                        },
                        true,
                        true
                    );
                },
                (error) => {
                    this.errorHistoryService.addError(error.message);
                }
            );
    }

    private initChart() {
        this.piechart = new Chart({
            colors: [
                '#0273fb',
                '#08dc5a',
                '#ff0d49',
                '#ffc003',
                '#001d53',
                '#1273cc',
                '#e4d354',
                '#2b908f',
                '#f45b5b',
                '#91e8e1',
            ],
            chart: { type: 'pie', backgroundColor: 'rgba(255, 255, 255, 0.0)' },
            title: { text: null },
            tooltip: { enabled: false },
            credits: { enabled: false },
            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['100px', '100px'], // this was doing weird things to the tooltip and hiding it behind the white
                    dataLabels: {
                        enabled: false,
                    },
                },
            },
            yAxis: {
                title: {
                    text: null,
                },
            },
        });
        this.piechart['backgroundColor'] = 'rgba(255, 255, 255, 0.0)';
    }
}
