import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { CollectorService } from './collector.service';
import { ExportersService } from './exporters.service';
import { ExportsService } from './exports.service';
import { NETFLOW_CONFIGURATION, NetFlowConfig, NetflowService } from './netflow.service';

@NgModule({
    imports: [CommonModule, HttpClientModule, SharedModelModule],
    declarations: [],
    exports: [],
    providers: [],
})
export class SharedNetflowModule {
    static forRoot(config: NetFlowConfig): ModuleWithProviders<SharedNetflowModule> {
        return {
            ngModule: SharedNetflowModule,
            providers: [
                CollectorService,
                ExportsService,
                ExportersService,
                NetflowService,
                { provide: NETFLOW_CONFIGURATION, useValue: config },
            ],
        };
    }
}
