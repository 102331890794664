<div class="fullModal opened iframe-modal-container" id="IframModalComponent">
    <div class="title" style="filter: brightness(50%)">
        <span>Service Preview</span>
    </div>
    <div class="subtitle">
        <span>Below you should see the "Hello World" splash page, accessed via your private service @</span>
    </div>
    <div class="subtitle">
        <span *ngIf="!isSample"><b>https://demo.tools.netfoundry.io</b></span>
        <span *ngIf="isSample"><b>https://sample.tools.netfoundry.io</b></span>
    </div>
    <div class="subtitle bottom">
        <span
            >*If the preview doesn't load, please make sure that your Ziti tunneler is running and your endpoint is
            enrolled.</span
        >
    </div>
    <div (click)="hide()" class="buttonBall close" id="EscZEndpointClose">
        <div class="buttonLabel">ESC</div>
    </div>
    <iframe *ngIf="connected" [src]="safeUrl" class="iframe-container"></iframe>
    <div *ngIf="!connected" class="iframe-placeholder"></div>
    <app-loading-indicator *ngIf="!connected" [isLoading]="true"></app-loading-indicator>
    <div class="row buttonRow right" style="margin-top: 20px">
        <button (click)="refreshIframe()" class="save" id="IFrameReloadButton">Reload</button>
        <button (click)="return()" class="save" id="IFrameReturnButton">Close</button>
    </div>
</div>
