<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="AddServiceModal">
    <div [hidden]="isEditing" class="title" style="filter: brightness(50%)">Create A New IP Network Service</div>
    <div [hidden]="isAdding" class="title" style="filter: brightness(50%)">Edit Your IP Network Service</div>
    <div [hidden]="showConfirmPorts">
        <div class="subtitle">Enter your service attributes</div>
        <div class="sized">
            <div class="label row">
                Service Name<span [hidden]="!errors['name']" class="error" id="NameError">{{ nameErrorString }}</span
                ><span [hidden]="errors['name']" class="note" id="NameNote">Required</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="model.name"
                    [disabled]="!canEdit"
                    [ngClass]="{ error: errors['name'] }"
                    class="restrictNormal"
                    id="EditName"
                    maxlength="64"
                    placeholder="What would you like to call this service?"
                    type="text"
                />
            </div>

            <div class="label row">
                Gateway<span [hidden]="!errors['endpointId']" class="error" id="GatewayListError">Required</span
                ><span [hidden]="errors['endpointId']" class="note" id="GatewayListNote">Required</span>
            </div>
            <div class="row">
                <select
                    [(ngModel)]="gatewayId"
                    [disabled]="disableGatewaySelect || !canEdit"
                    [ngClass]="{ error: errors['endpointId'] }"
                    id="GatewayList"
                    placeholder="Select a Gateway"
                >
                    <option [ngValue]="null">Select a Gateway</option>
                    <option *ngFor="let gateway of ipNetworkGateways" [value]="gateway.id" id="{{ gateway.id }}">
                        {{ gateway.name }} {{ clusterCount > 0 ? gateway.typeTag : '' }}
                    </option>
                </select>
            </div>

            <div class="label row">
                Network Address<span [hidden]="!errors['gatewayIp']" class="error" id="NetworkAddressError">{{
                    gatewayIpErrorString
                }}</span
                ><span [hidden]="errors['gatewayIp']" class="note" id="NetworkAddressNote">Required</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="gatewayIp"
                    [disabled]="!canEdit"
                    [ngClass]="{
                        error: errors['gatewayIp'] || errors['gatewayCidrBlock']
                    }"
                    id="NetworkAddress"
                    placeholder="10.0.0.0/24"
                    type="text"
                />
            </div>
            <div class="label row">
                Intercept Address<span [hidden]="!errors['interceptIp']" class="error" id="InterceptAddressError">{{
                    interceptIpErrorString
                }}</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="interceptIp"
                    [disabled]="!canEdit"
                    [ngClass]="{ error: errors['interceptIp'] }"
                    id="NetworkInterceptAddress"
                    placeholder="{{ gatewayIp == null || gatewayIp === '' ? '10.0.0.0/24' : gatewayIp }}"
                    type="text"
                />
            </div>

            <div class="label row">
                Port Intercept Mode<span class="note" id="PortInterceptModeNote">Required</span>
            </div>
            <div class="row">
                <select
                    [(ngModel)]="model.portInterceptMode"
                    id="PortInterceptMode"
                    placeholder="Select a Port Intercept Mode"
                >
                    <option [ngValue]="'INTERCEPT_ALL'">All Ports (By Default)</option>
                    <option [ngValue]="'SPECIFIC_PORTS'">Specific Ports</option>
                </select>
            </div>

            <div *ngIf="model.portInterceptMode === 'SPECIFIC_PORTS'">
                <div class="label row">
                    Specify Intercept Ports and Ranges<span
                        [hidden]="!errors['includedPortSet']"
                        class="error"
                        id="InterceptPortsError"
                        >Invalid or Missing Intercept Port Input</span
                    ><span [hidden]="errors['includedPortSet']" class="note" id="InterceptPortsNote">Required</span>
                </div>
                <div class="row">
                    <textarea
                        [(ngModel)]="includedPortsString"
                        [disabled]="!canEdit"
                        [ngClass]="{ error: errors['includedPortSet'] }"
                        class="restrictNormal"
                        id="EditInterceptPorts"
                        placeholder="Example: 80, 4200, 1264-2654"
                    ></textarea>
                </div>

                <div class="label row">
                    Specify Excluded Intercept Ports and Ranges<span
                        [hidden]="!errors['excludedPortSet']"
                        class="error"
                        id="ExcludePortsError"
                        >Invalid Input</span
                    ><span [hidden]="errors['excludedPortSet']" class="note" id="ExcludePortsNote">Required</span>
                </div>
                <div class="row">
                    <textarea
                        [(ngModel)]="excludedPortsString"
                        [disabled]="!canEdit"
                        [ngClass]="{ error: errors['excludedPortSet'] }"
                        class="restrictNormal"
                        id="EditExcludePorts"
                        placeholder="Example: 1271, 1800-1871"
                    ></textarea>
                </div>
            </div>

            <div class="label row labelExpansion">Advanced Options<span class="note">Open to Edit Details</span></div>
            <div class="row expansion">
                <mat-accordion>
                    <mat-expansion-panel
                        #panel1
                        [hideToggle]="true"
                        class="networkHost"
                        id="IpNetworkAdvancedOptionsPanel"
                    >
                        <mat-expansion-panel-header [collapsedHeight]="'65px'">
                            <mat-panel-title class="expansionLabel">
                                <div class="title">
                                    <div class="icon-AdvancedOptions"></div>
                                </div>
                                <div class="subtitle">Advanced Options</div>
                                <span
                                    [ngClass]="{ open: panel1.expanded }"
                                    class="expansion-indicator icon-Sort_Down"
                                ></span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-grid-list cols="10" rowHeight="45px">
                            <mat-grid-tile [colspan]="9">
                                <div class="label">Enable ICMP Tunneling</div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1">
                                <div class="toggleTile">
                                    <div
                                        (click)="canEdit && toggleIcmpEnabled()"
                                        [ngClass]="{ on: icmpEnabled }"
                                        class="toggle gridToggle"
                                        id="IcmpToggle"
                                    >
                                        <span [hidden]="!icmpEnabled" id="IcmpToggleOn">ON&nbsp;&nbsp;&nbsp;</span>
                                        <span [hidden]="icmpEnabled" id="IcmpToggleOff">&nbsp;&nbsp;&nbsp;OFF</span>
                                        <div class="switch"></div>
                                    </div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="9">
                                <div class="label">Transparency</div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1">
                                <div class="toggleTile">
                                    <div
                                        (click)="canEdit && toggleTransparency()"
                                        [ngClass]="{ on: transparencyEnabled }"
                                        class="toggle"
                                        id="IpNetworkTransparencyToggle"
                                    >
                                        <span [hidden]="!transparencyEnabled" id="IpNetworkTransparencyToggleOn"
                                            >ON&nbsp;&nbsp;&nbsp;</span
                                        >
                                        <span [hidden]="transparencyEnabled" id="IpNetworkTransparencyToggleOff"
                                            >&nbsp;&nbsp;&nbsp;OFF</span
                                        >
                                        <div class="switch"></div>
                                    </div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="9">
                                <div class="label">Enable DNS Tunneling</div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1">
                                <div class="toggleTile">
                                    <div
                                        (click)="canEdit && toggleDnsEnabled()"
                                        [ngClass]="{ on: dnsEnabled }"
                                        class="toggle"
                                        id="DnsToggle"
                                    >
                                        <span [hidden]="!dnsEnabled" id="DnsToggleOn">ON&nbsp;&nbsp;&nbsp;</span>
                                        <span [hidden]="dnsEnabled" id="DnsToggleOff">&nbsp;&nbsp;&nbsp;OFF</span>
                                        <div class="switch"></div>
                                    </div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="9">
                                <div class="label">Enable Permanent Connection</div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1">
                                <div class="toggleTile">
                                    <div
                                        (click)="canEdit && togglePermanentConnection()"
                                        [ngClass]="{ on: permanentConnectionEnabled }"
                                        class="toggle"
                                        id="PermanentConnectionToggle"
                                    >
                                        <span [hidden]="!permanentConnectionEnabled" id="PermanentConnectionToggleOn"
                                            >ON&nbsp;&nbsp;&nbsp;</span
                                        >
                                        <span [hidden]="permanentConnectionEnabled" id="PermanentConnectionToggleOff"
                                            >&nbsp;&nbsp;&nbsp;OFF</span
                                        >
                                        <div class="switch"></div>
                                    </div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="9">
                                <div class="label">Data Interleaving</div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1">
                                <div class="toggleTile">
                                    <div
                                        (click)="canEdit && toggleDataInterleavingEnabled()"
                                        [ngClass]="{ on: dataInterleavingEnabled }"
                                        class="toggle"
                                        id="DataInterleavingToggle"
                                    >
                                        <span [hidden]="!dataInterleavingEnabled" id="DataInterleavingToggleOn"
                                            >ON&nbsp;&nbsp;&nbsp;</span
                                        >
                                        <span [hidden]="dataInterleavingEnabled" id="DataInterleavingToggleOff"
                                            >&nbsp;&nbsp;&nbsp;OFF</span
                                        >
                                        <div class="switch"></div>
                                    </div>
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div class="row right buttonRow" style="margin-top: 20px">
                <button (click)="initSave()" *ngIf="!processing && canEdit" class="save" id="SaveServiceButton">
                    <span *ngIf="isEditing">Save</span>
                    <span *ngIf="isAdding">Create</span>
                </button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
        </div>
    </div>

    <div [hidden]="!showConfirmPorts">
        <div class="fullRow">
            <div class="sized">
                <div class="subtitle checkPorts">
                    You've chosen to pick specific intercept ports and or ranges.<br />A summary of your active ports,
                    minus exclusions are below.
                </div>
                <div class="content">See something you'd like to change? Tap edit or back to update your service.</div>

                <div class="wizard">
                    <div class="header">
                        <div class="darkenText">Intercept Port Summary</div>
                    </div>
                    <div class="grid split">
                        <div class="portSummary">
                            <div class="label checkPorts">Active Intercept Ports</div>
                            <div class="row">
                                <div *ngFor="let portRange of condensedPorts">
                                    {{
                                        portRange[0] === portRange[1] ? portRange[0] : portRange[0] + '-' + portRange[1]
                                    }}
                                </div>
                            </div>
                        </div>

                        <div
                            class="icon checkPorts"
                            style="background-image: url(/assets/svgs/Icon_NetworkService.svg)"
                        ></div>
                    </div>
                </div>
                <div class="row right buttonRow" style="margin-top: 20px">
                    <button
                        (click)="goBack()"
                        *ngIf="canEdit"
                        [disabled]="processing"
                        class="save clear edit"
                        id="EditServiceButton"
                    >
                        Edit
                    </button>

                    <button (click)="save()" *ngIf="!processing && canEdit" class="save" id="SaveServiceButton2">
                        <span *ngIf="isEditing">Save</span>
                        <span *ngIf="isAdding">Create</span>
                    </button>
                    <button *ngIf="processing" class="save confirm" disabled>
                        Saving <span class="spinner"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div (click)="hideForm()" class="buttonBall close" id="CloseServiceButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    <div (click)="goBack()" [hidden]="!allowBack" class="buttonBall back" id="BackServiceButton">
        <div class="buttonLabel">BACK</div>
    </div>
</div>
