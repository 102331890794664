import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('hosts')
export class HostV2 extends Resource {
    id: string;
    networkId: string;
    ownerIdentityId: string;
    port: number;
    provider: string;
    providerInstanceId: string;
    size: string;
    dataCenterId: string;
    locationMetadataId: string;
    userData: string;
    createdAt: string;
    updatedAt: string;
    ipAddress: string;
}
