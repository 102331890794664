import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, lastValueFrom, of, Subject, Subscription, take } from 'rxjs';
import { JwtSigner, Environment, ENVIRONMENT, NetworkV2 } from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, HTTP_CLIENT, LoggerService } from '@netfoundry-ui/shared/services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { TableFilterService } from '@netfoundry-ui/feature/shared-services';
import _ from 'lodash';
import { PagedGetOption, Sort, SortOrder } from '@lagoshny/ngx-hateoas-client';
import { BaseHateoasResourceService } from './base-hateoas-resource.service';

@Injectable({
    providedIn: 'root',
})
export class JwtSignerService extends BaseHateoasResourceService<JwtSigner> {
    page = 1;
    pageSize = 30;
    currentNetwork = new BehaviorSubject<NetworkV2>(new NetworkV2());
    unFilteredRecords = new BehaviorSubject<JwtSigner[]>([]);
    filteredRecords = new BehaviorSubject<JwtSigner[]>([]);
    allToggled = false;

    private anyToggled = false;
    filterString = '';
    enableDeleteProtection = false;
    selectedRecordIds: string[] = [];
    isDeleteAction = false;
    hideAppButton = false;
    columnFilters: any = { name: '' };
    sorting = 'name';
    ordering: SortOrder = 'ASC';
    paramList: { size: number; sort: Sort } = {
        size: this.pageSize,
        sort: { name: 'ASC' },
    };
    isLoading = new BehaviorSubject<boolean>(true);
    filterHasChanged = new BehaviorSubject<boolean>(false);
    pageHasChanged = new BehaviorSubject<boolean>(false);
    confirmDelete = new Subject<JwtSigner>();

    subscription: Subscription | undefined;
    debouncedRefreshRecords = _.debounce(() => this.refreshRecords(), 400);

    constructor(
        private logger: LoggerService,
        @Inject(HTTP_CLIENT) protected override http: HttpClient,
        @Inject(ENVIRONMENT) protected override environment: Environment,
        private apiService: ApiService,
        private authorizationService: AuthorizationService,
        private filterService: TableFilterService,
        private featureSvc: FeatureService
    ) {
        super(http, environment, JwtSigner);
        this.init();
    }

    init() {
        this.subscription = new Subscription();
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((currentNetwork) => {
                this.currentNetwork.next(currentNetwork);
                this.filterString = '';
                this.filterService.setPage(1, true);
                this.page = 1;
                this.filterHasChanged.next(true);
                this.pageHasChanged.next(true);
                this.debouncedRefreshRecords();
            })
        );
    }

    reset() {
        this.columnFilters = { name: '' };
        this.subscription?.unsubscribe();
        this.filterString = '';
        this.filterService.reset();
        this.debouncedRefreshRecords();
    }

    sort(sortBy: string, ordering: string) {
        if (this.sorting === sortBy) {
            this.ordering = ordering === 'asc' ? 'ASC' : ordering === 'desc' ? 'DESC' : (ordering as SortOrder);
        } else {
            this.ordering = 'ASC';
            this.sorting = sortBy;
        }

        const s: Sort = {};
        s[this.sorting] = this.ordering;
        if (this.sorting === 'claimsProperty') s['useExternalId'] = this.ordering === 'ASC' ? 'DESC' : 'ASC';
        this.paramList.sort = s;

        this.pageHasChanged.next(true);
        this.debouncedRefreshRecords();
    }

    private refreshRecords() {
        this.isLoading.next(true);
        const currentNetwork = this.currentNetwork.value;
        if (currentNetwork?.id) {
            if (this.columnFilters.name && this.page !== 1 && !this.pageHasChanged.getValue()) {
                this.filterService.setPage(1, true);
                this.page = 1;
            }
            this.getResourcePage(this.getOptions())
                .then((results) => {
                    this.unFilteredRecords.next(results);
                    this.filterService.setTotalPages(this.lastPageCount);
                    // if the number of pages has decreased and the user is past the last page now
                    if (this.page > this.lastPageCount) {
                        // move back one page
                        this.filterService.setPage(this.lastPageCount, true);
                    } else {
                        const filteredItems = results.map((rec: any) => {
                            rec.actionList = this.getAllowedActions(rec.id);
                            if (rec.zitiId) rec.status = 'Valid';
                            else rec.status = 'ERROR';
                            return rec;
                        });
                        this.filteredRecords.next(filteredItems || []);
                    }

                    this.filterService.setTotalElements(this.lastTotalCount);
                    this.isLoading.next(false);
                    this.filterHasChanged.next(false);
                    this.pageHasChanged.next(false);
                })
                .catch(() => {
                    this.isLoading.next(false);
                    this.filterHasChanged.next(false);
                    this.pageHasChanged.next(false);
                });
        }
    }

    private getOptions() {
        const options: PagedGetOption = {
            headers: {
                accept: 'application/hal+json',
            },
            params: { networkId: this.currentNetwork.value.id },
            pageParams: {
                page: this.page - 1,
                size: this.pageSize,
            },
            sort: this.paramList.sort,
        };

        if (this.filterString || this.columnFilters.name) {
            let filterVal = this.filterString;
            if (!_.isEmpty(this.columnFilters.name)) {
                filterVal = this.columnFilters.name;
            }
            const escapedChars = filterVal.replace('%', '\\%').replace('_', '\\_');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            options.params['name'] = `%${escapedChars}%`;
        }
        if (this.columnFilters.issuer) {
            const esc = this.columnFilters.issuer.replace('%', '\\%'); // .replace('_', '\\_');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            options.params['issuer'] = `%${esc}%`;
        }
        if (this.columnFilters.jwtBased) {
            const esc = this.columnFilters.jwtBased.replace('%', '\\%'); // .replace('_', '\\_');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            options.params['claimsProperty'] = `%${esc}%`;
        }
        return options;
    }

    isAnyToggled() {
        return this.anyToggled;
    }

    toggleAll(displayedItems: any[]): string[] {
        if (this.featureSvc.enableDeleteProtection) {
            return [];
        }
        this.allToggled = !this.allToggled;
        this.anyToggled = this.allToggled;
        const selectedRecordIds = [];
        for (const item of displayedItems) {
            if (this.authorizationService.canDeleteJwtSigners(item.id)) {
                item.selected = this.allToggled;
                if (this.allToggled) {
                    selectedRecordIds.push(item.id);
                }
            }
        }
        this.isDeleteAction = this.anyToggled;
        return selectedRecordIds;
    }

    toggle(item: any) {
        if (this.authorizationService.canDeleteJwtSigners(item.id)) {
            item.selected = !item.selected;
            this.enableDeleteProtection = this.featureSvc.enableDeleteProtection;

            if (this.allToggled) {
                this.allToggled = !this.allToggled;
            }

            const index = this.selectedRecordIds.indexOf(item.id);
            if (index > -1) {
                this.selectedRecordIds.splice(index, 1);
            } else {
                this.selectedRecordIds.push(item.id);
            }

            this.anyToggled = this.selectedRecordIds.length > 0;

            this.isDeleteAction = this.anyToggled;

            if (!this.enableDeleteProtection) {
                this.isDeleteAction = true;
                this.hideAppButton = false;
            }
            if (this.selectedRecordIds.length > 1 && this.enableDeleteProtection) {
                this.isDeleteAction = false;
                this.hideAppButton = true;
            }
            if (this.selectedRecordIds.length === 1 && this.enableDeleteProtection) {
                this.isDeleteAction = true;
                this.hideAppButton = false;
            }
            if (this.selectedRecordIds.length === 0) {
                this.hideAppButton = false;
                this.isDeleteAction = false;
            }
        }
        return this.selectedRecordIds;
    }

    canCreate() {
        return this.authorizationService.canCreateJwtSigners(undefined, this.currentNetwork.getValue().id);
    }

    canUpdate(jwtSignerId: string) {
        return this.authorizationService.canUpdateJwtSigners(jwtSignerId, undefined, this.currentNetwork.getValue().id);
    }

    networkReady() {
        return this.currentNetwork.value.status === 'PROVISIONED';
    }

    async save(model: JwtSigner): Promise<any[]> {
        const results = await this.validate(model);
        if (!_.isArray(results) || results.length === 0) {
            if (!model.id) {
                this.createResource({ body: model })
                    .pipe(take(1))
                    .subscribe({
                        next: () => {
                            this.debouncedRefreshRecords();
                            return [];
                        },
                        error: (err) => [{ save: err.status }],
                    });
            } else {
                this.patchResource(model)
                    .pipe(take(1))
                    .subscribe({
                        next: () => {
                            this.debouncedRefreshRecords();
                            return [];
                        },
                        error: (err) => [{ save: err.status }],
                    });
            }
        }
        return results;
    }

    validate(model: JwtSigner): any {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        if (model?.id) {
            return lastValueFrom(
                this.http
                    .patch(this.environment.v2apiUrl + 'external-jwt-signers/' + model.id, model, {
                        headers: headers,
                        responseType: 'json',
                    })
                    .pipe(catchError((err) => of([{ http: err }])))
            );
        } else if (model) {
            return lastValueFrom(
                this.http
                    .post(this.environment.v2apiUrl + 'external-jwt-signers', model, {
                        headers: headers,
                        responseType: 'json',
                    })
                    .pipe(catchError((err) => of([{ http: err }])))
            );
        } else return Promise.resolve([{ invalid: 'jwt signer model cannot be undefined' }]);
    }

    getAllowedActions(jwtSignerId: string): any[] {
        const actions = [];

        if (this.authorizationService.canUpdateJwtSigners(jwtSignerId)) {
            actions.push('update');
        }
        if (this.authorizationService.canDeleteJwtSigners(jwtSignerId)) {
            actions.push('delete');
        }
        return actions;
    }

    confirmAction(action: string, item: JwtSigner) {
        this.allToggled = false;
        this.selectedRecordIds = [item.id];
        (item as any).selected = true;
        this.confirmDelete.next(item);
    }

    async delete(event: any): Promise<any[]> {
        this.isLoading.next(true);
        return new Promise<any[]>((resolve, reject) => {
            const promises: Promise<any>[] = [];
            if (event) {
                for (const item of this.filteredRecords.getValue()) {
                    if ((item as any).selected) {
                        promises.push(lastValueFrom(this.deleteResource(item)));
                    }
                }
                Promise.all(promises)
                    .then((resp) => {
                        resolve(resp);
                        this.isLoading.next(false);
                        this.selectedRecordIds = [];
                        this.isDeleteAction = false;
                        this.refreshRecords();
                    })
                    .catch((err) => {
                        this.logger.error(err);
                        this.isLoading.next(false);
                        this.selectedRecordIds = [];
                        this.isDeleteAction = false;
                        this.refreshRecords();
                        reject(err?.error?.errors[0]);
                    });
            } else {
                this.selectedRecordIds = [];
                this.isDeleteAction = false;
                this.refreshRecords();
                resolve([]);
            }
        });
    }

    tableFilterChanged(event: any) {
        if (event.columnId === 'name') {
            this.filterString = event.value;
        }
        _.set(this.columnFilters, event.columnId, event.value);

        this.filterHasChanged.next(true);
        this.debouncedRefreshRecords();
    }
}
