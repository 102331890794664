import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { SharedModelModule } from '@netfoundry-ui/shared/model';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedApiv2Module } from '@netfoundry-ui/shared/apiv2';

import { FeatureTagsSelectorModule } from '@netfoundry-ui/feature/tags-selector';
import { FeaturePreviewListModule } from '@netfoundry-ui/feature/preview-list';
import { FeatureFormSupportButtonModule } from '@netfoundry-ui/feature/form-support-button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ZitiEdgeRouterPolicyComponent } from './ziti-edge-router-policy.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UiLoaderModule,
        SharedModelModule,
        MatDialogModule,
        SharedApiv2Module,
        FeatureTagsSelectorModule,
        FeaturePreviewListModule,
        FeatureFormSupportButtonModule,
        MatTooltipModule,
    ],
    declarations: [ZitiEdgeRouterPolicyComponent],
    exports: [ZitiEdgeRouterPolicyComponent],
})
export class FeatureFormZitiEdgeRouterPolicyModule {}
