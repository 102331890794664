import { Injectable } from '@angular/core';
import { Network, Service } from '@netfoundry-ui/shared/model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class ServiceService extends MopResourceService {
    page = null;
    links = null;
    private servicesSource = new Subject<Service[]>();
    services = this.servicesSource.asObservable();
    private apiSub = new Subscription();
    private mapSub = new Subscription();

    constructor(protected logger: LoggerService, private apiservice: ApiService) {
        super(logger, apiservice);
    }

    /**
     * Gets all Clients for org
     */
    get(pageNumber?: number, sort?: string, filter?: string, pageSize?: number): Observable<any> {
        if (this.apiSub) {
            this.apiSub.unsubscribe();
        }

        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }

        const params = {};

        if (pageNumber != null) {
            params['page'] = pageNumber;
        }

        if (sort != null) {
            params['sort'] = sort;
        }

        if (filter != null) {
            // TODO filter
        }

        if (pageSize != null) {
            params['size'] = pageSize;
        }

        // watch for when the network updates, when it does, update the gateways observable
        this.apiSub = this.apiService.currentNetwork.subscribe((network) => {
            this.mapSub = this.apiService
                .getLinkedResource(new Network(network), 'services', params)
                .pipe(map(this._extractEmbedded))
                .subscribe((data) => {
                    this.page = data['page'];
                    this.links = data['links'];
                    this.servicesSource.next(data['services']);
                });
        });

        // this is an observable that watches for network changes
        return this.services;
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const services: Service[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['services'] !== undefined) {
            for (let i = 0; i < res['_embedded']['services'].length; i++) {
                const service = res['_embedded']['services'][i];
                services.push(new Service(service));
            }
        }

        // object containing information on pagination such as page number, size, total number of items,  and total number of pages
        let page = null;
        if (res['page'] !== undefined) {
            page = res['page'];
        }

        // list of links for jumping to and from pages
        let links = null;
        if (res['_links'] !== undefined) {
            links = res['_links'];
        }

        return { services: services, page: page, links: links };
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return this.getLinkedResourceUrl(this.currentNetworkModel, 'services');
    }
}
