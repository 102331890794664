<div
    [ngClass]="{ editing: this.isEditing, 'full-screen': fullScreen, 'full-network-upload': this.networkUpload }"
    class="modal solid"
    id="UploadEntitiesModal"
>
    <div class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span>Upload {{ this.resourceLabel }}</span>
        </div>
        <div *ngIf="!isEditing || !showTable" [hidden]="networkUpload" class="subtitle">
            Upload your {{ resourceLabel }} as code
        </div>
        <div *ngIf="isEditing && showTable" class="subtitle">
            Double click to edit cell values. Single click to open advanced editor.
        </div>
        <div class="sized">
            <div id="EntitiesBulkUpload">
                <div class="label filterRow row">
                    <span
                        (click)="toggleFilter()"
                        [hidden]="(!isEditing || !invalid) && !showInvalid"
                        class="filter-label"
                        >{{ invalid || showInvalid ? (!showInvalid ? 'Show Invalid' : 'Show All') : '' }}</span
                    >
                    <span [hidden]="!invalid || fileEmpty" class="invalidMessage"
                        >Upload file is invalid. Please check content and try again.</span
                    >
                    <span [hidden]="!invalid || !fileEmpty" class="invalidMessage"
                        >Upload file is empty. Please check content and try again</span
                    >
                    <div
                        (click)="actionButton()"
                        *ngIf="isEditing && showTable"
                        [ngClass]="actionButtonClass"
                        class="actionButton"
                    ></div>
                    <span *ngIf="fileIsReady && isEditing && showTable" class="row-count-edit"
                        >{{ fileObj.length }} of {{ fileObj.length }}</span
                    >
                </div>
                <div
                    (drop)="fileDrop($event)"
                    *ngIf="!isEditing"
                    [ngClass]="{ invalid: invalid, valid: !invalid && fileIsReady }"
                    [uploader]="uploader"
                    class="uploadArea"
                    ng2FileDrop
                >
                    <div [hidden]="fileIsReady" class="label">Drag and drop a JSON or YML file</div>
                    <div [hidden]="fileIsReady" class="sublabel">
                        or
                        <div (click)="showDialog()" class="linkButton" id="EndpointFileUpload">
                            Select a file from your computer
                        </div>
                        <form [formGroup]="form">
                            <input
                                #fileInput
                                (change)="onFileChange($event)"
                                id="FileSelect"
                                name="file"
                                style="display: none"
                                type="file"
                            />
                        </form>
                    </div>
                    <span *ngIf="fileIsReady && !isEditing && !showTable && !importError" class="row-count"
                        >{{ fileObj.length }} {{ resourceLabel }}</span
                    >
                    <div *ngIf="!invalid">
                        <div [hidden]="!fileIsReady" class="label">Click "Upload" to create {{ resourceLabel }}</div>
                        <div (click)="showDialog()" *ngIf="fileIsReady" class="linkButton">
                            or choose a different file
                        </div>
                    </div>
                    <div *ngIf="invalid">
                        <div [hidden]="!fileIsReady" class="label">Invalid {{ resourceLabel }}</div>
                        <div class="linkContainer">
                            <div *ngIf="!importError" class="linkPrefix">Edit data or</div>
                            <div (click)="showDialog()" *ngIf="fileIsReady" class="linkButton">
                                choose a different file
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="!isEditing" class="code-editor-container">
                    <div #editor [hidden]="!isEditing || showTable" class="code-editor"></div>
                    <app-data-table
                        (gridReady)="onGridReady($event)"
                        *ngIf="showTable && !tableRefresh"
                        [columnDefinitions]="columnDefs"
                        [ngClass]="{ invalid: invalid, valid: !invalid && fileIsReady }"
                        [rowData]="fileObj"
                        [rowValidation]="rowValidation"
                        [rowsToggled]="rowsToggled.bind(this)"
                        [tableId]="resourceType + '_upload'"
                        [validateService]="validate.bind(this)"
                        [view]="'upload'"
                        class="edit-upload-table"
                    >
                    </app-data-table>
                </div>
            </div>
            <div class="row buttonRow right">
                <button
                    (click)="copyData()"
                    *ngIf="fileIsReady && isEditing && showTable && buttonAction === 'remove'"
                    [disabled]="importing"
                    class="save"
                    id="CopyButton"
                >
                    Copy
                </button>
                <button
                    (click)="toggleEditor()"
                    *ngIf="fileIsReady && !isEditing && !importError"
                    [disabled]="importing"
                    class="save"
                    id="EditUploadButton"
                >
                    Edit
                </button>
                <button
                    (click)="toggleTable()"
                    *ngIf="fileIsReady && isEditing && !showTable"
                    [disabled]="importing"
                    class="save"
                    id="TableEditButton"
                >
                    Show Table
                </button>
                <button
                    (click)="toggleTable()"
                    *ngIf="fileIsReady && isEditing && showTable"
                    [disabled]="importing"
                    class="save"
                    id="SourceEditButton"
                >
                    View Source
                </button>
                <button
                    (click)="toggleEditor()"
                    *ngIf="fileIsReady && isEditing"
                    [disabled]="importing"
                    class="save"
                    id="DoneEditUploadButton"
                >
                    Done
                </button>
                <button
                    (click)="uploadResources()"
                    *ngIf="!importing && !isEditing"
                    [disabled]="!fileIsReady || invalid"
                    class="save"
                    id="UploadEndpointsButton"
                >
                    Upload
                </button>
                <button *ngIf="importing" class="save confirm" disabled>Uploading <span class="spinner"></span></button>
            </div>
        </div>
        <div (click)="toggleModalSize()" class="buttonBall resize" id="ResizeModalButton"></div>
        <div (click)="hide()" class="buttonBall close" id="EscUploadEntitiesClose"></div>
    </div>
</div>
