import { Pipe, PipeTransform } from '@angular/core';
import { OnlineStatusPipe } from './online-status.pipe';
import { StatusPipe } from './status.pipe';

const desc = 'desc';

@Pipe({
    name: 'sortby',
})
export class SortbyPipe implements PipeTransform {
    constructor(private statusPipe: StatusPipe, private onlineStatusPipe: OnlineStatusPipe) {}

    transform(array: Array<string>, args: string, order?: string, isEndpoint?: boolean): Array<string> {
        // defaults for ascending
        let lessThanPos = -1;
        let greaterThanPos = 1;
        let isAscending = true;

        if (order && order.toLowerCase() === desc) {
            lessThanPos = 1;
            greaterThanPos = -1;
            isAscending = false;
        }

        array.sort((a: any, b: any) => {
            let aArgs;

            let bArgs;

            if (isEndpoint && args === 'status') {
                if (a[args] === 400) {
                    aArgs = this.onlineStatusPipe.transform(a['currentState']);
                } else {
                    aArgs = this.statusPipe.transform(a[args], true);
                }

                if (b[args] === 400) {
                    bArgs = this.onlineStatusPipe.transform(b['currentState']);
                } else {
                    bArgs = this.statusPipe.transform(b[args], true);
                }
            } else {
                aArgs = a[args];
                bArgs = b[args];
            }

            // if aArgs is not null convert it to lower case
            aArgs = aArgs && typeof aArgs !== 'boolean' && typeof aArgs !== 'number' ? aArgs.toLowerCase() : aArgs;

            // if bArgs is not null convert it to lower case
            bArgs = bArgs && typeof bArgs !== 'boolean' && typeof bArgs !== 'number' ? bArgs.toLowerCase() : bArgs;

            let splitAArgs = [];
            let splitBArgs = [];

            if (typeof aArgs === 'string' && typeof bArgs === 'string') {
                splitAArgs = aArgs.split('.');
                splitBArgs = bArgs.split('.');
            }
            // if both a and b are in the form of w.x.y.z, assume it is an IP address and sort by address
            if (splitAArgs.length === 4 && splitBArgs.length === 4 && args !== 'name') {
                for (let i = 0; i < splitAArgs.length; i++) {
                    const aNumbers = Number(splitAArgs[i]);
                    const bNumbers = Number(splitBArgs[i]);
                    if (aNumbers < bNumbers) {
                        return lessThanPos;
                    } else if (aNumbers > bNumbers) {
                        return greaterThanPos;
                    }
                }
                return 0;
            } else {
                // checking if any of the arguments are undefined, and if they are sorting them to the bottom of the list
                if ((aArgs === undefined || aArgs === null) && bArgs !== undefined && bArgs !== null) {
                    return isAscending ? greaterThanPos : lessThanPos;
                } else if (aArgs !== undefined && aArgs !== null && (bArgs === undefined || bArgs === null)) {
                    return isAscending ? lessThanPos : greaterThanPos;
                } else if (!aArgs && !bArgs) {
                    return 0;
                } else {
                    if (aArgs < bArgs) {
                        return lessThanPos;
                    } else if (aArgs > bArgs) {
                        return greaterThanPos;
                    } else {
                        return 0;
                    }
                }
            }
        });
        return array;
    }
}
