<app-action-button
    (addEvent)="create($event)"
    (deleteEvent)="openConfirm($event)"
    [hidden]="!isDeleteAction && !authorizationService.canCreateNetflowCollectors()"
    [isDeleteAction]="isDeleteAction"
></app-action-button>

<app-loader [isLoading]="isLoading" id="netflowcollectorsPage"></app-loader>

<div class="area" id="ItemsArea">
    <app-noitems
        (clickEmit)="create(true)"
        [image]="'NetFlowCollectors'"
        [isEmpty]="!isLoading && items.length === 0"
        [typeName]="'Netflow'"
    ></app-noitems>

    <div [hidden]="!showList || isLoading" id="ItemTable">
        <div class="tableGrid tHeadRow">
            <div class="tHead">
                <div
                    (click)="toggleAll()"
                    *ngIf="hasSomeDeletePermission"
                    [ngClass]="{ selected: allToggled }"
                    class="tSelect all"
                    id="ToggleAll"
                ></div>
            </div>
            <div (click)="sort('name')" [ngClass]="getSortClass('name')" class="tHead sort">Collector Label</div>
            <div (click)="sort('networkGroupName')" [ngClass]="getSortClass('networkGroupName')" class="tHead sort">
                Network Group
            </div>
            <div class="thead tRight">
                <div (click)="toggleMenu($event, -2)" *ngIf="anyToggled()" class="dots" id="Dots_Bulk">
                    ...
                    <div (clickOutside)="closeActionMenu()" [ngClass]="{ open: -2 === openIndex }" class="tMenu one">
                        <div (click)="downloadCsv($event)" class="tActionRow" id="DownloadCSV_Bulk">Download CSV</div>
                    </div>
                </div>
            </div>
        </div>
        <div id="ItemsList">
            <div
                *ngFor="
                    let item of displayedItems
                        | sortby: sorting:ordering:'true'
                        | paginate
                            : {
                                  itemsPerPage: pageSize,
                                  currentPage: page,
                                  totalItems: totalElements
                              };
                    let index = index;
                    trackBy: trackById
                "
                [attr.data-index]="index"
                class="tableGrid tRow t{{ item.status }}"
                id="Item_{{ item.id }}"
            >
                <div class="tCol">
                    <div
                        (click)="toggle(item)"
                        *ngIf="item.actionList.includes('delete')"
                        [ngClass]="{ selected: item.selected }"
                        class="tSelect"
                        id="RowSelect0_{{ item.id }}"
                    ></div>
                </div>
                <div (click)="open(item)" class="tCol clickCol desktop" id="RowSelect1_{{ item.id }}">
                    <span>{{ item.name }}</span>
                </div>
                <div (click)="open(item)" class="tCol clickCol desktop" id="RowSelect2_{{ item.id }}">
                    {{ item.networkGroupName }}
                </div>
                <div class="tCol tRight">
                    <div
                        (click)="toggleMenu($event, index)"
                        *ngIf="item.actionList.length > 0"
                        class="dots"
                        id="RowDots_{{ index }}"
                    >
                        ...
                        <div (clickOutside)="closeActionMenu()" [ngClass]="{ open: index === openIndex }" class="tMenu">
                            <div
                                (click)="open(item)"
                                *ngIf="item.actionList.includes('update')"
                                class="tActionRow"
                                id="RowEdit_{{ index }}"
                            >
                                Edit
                            </div>
                            <div
                                (click)="confirmDelete(item)"
                                *ngIf="item.actionList.includes('delete')"
                                class="tActionRow"
                                id="RowDelete_{{ index }}"
                            >
                                Delete
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
