<div class="row mini-pod" style="margin-top: 15px">
    <div class="label row" style="margin-bottom: 0px; margin-top: 5px; font-size: 17px">ENABLE SOURCE TRANSPARENCY</div>
    <div
        (click)="!readOnly && toggleSourceTransparency()"
        [ngClass]="{ on: transparencyEnabled }"
        class="toggle"
        style="float: right; margin-top: 10px; margin-right: 12px; position: absolute; right: 0; top: 0"
    >
        <span [hidden]="!transparencyEnabled" style="margin-left: -15px">YES</span>
        <span [hidden]="transparencyEnabled" style="margin-right: -15px">NO</span>
        <div class="switch"></div>
    </div>
    <div [ngClass]="{ error: errors['sourceAddress'] }" class="label" style="color: var(--placeholder)">
        ALLOWED SOURCE ADDRESSES
    </div>
    <p-chips
        (keydown)="onSourceAddressKeyDown($event)"
        [(ngModel)]="model.data.allowedSourceAddresses"
        [allowDuplicate]="false"
        [disabled]="!transparencyEnabled || readOnly"
        [ngClass]="{ error: errors['sourceAddress'] }"
        [placeholder]="transparencyEnabled ? '10.10.0.0/24' : ''"
        addOnBlur="true"
        separator=","
    >
    </p-chips>
</div>
