import { Injectable } from '@angular/core';
import { GatewayCluster, Network } from '@netfoundry-ui/shared/model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { MopResourceService } from './mopresource.service';

@Injectable({ providedIn: 'root' })
export class GatewayClusterService extends MopResourceService {
    page = null;
    links = null;
    private gatewaysSource = new Subject<GatewayCluster[]>();
    gateways = this.gatewaysSource.asObservable();
    private apiSub = new Subscription();
    private mapSub = new Subscription();

    constructor(protected logger: LoggerService, private apiservice: ApiService) {
        super(logger, apiservice);
    }

    /**
     * Gets all Clients for org
     */
    get(pageNumber?: number, sort?: string, filter?: string, pageSize?: number, embed?: boolean): Observable<any> {
        if (this.apiSub) {
            this.apiSub.unsubscribe();
        }

        if (this.mapSub) {
            this.mapSub.unsubscribe();
        }

        let embedVal;
        if (embed) {
            embedVal = 'Endpoints';
        }
        // watch for when the network updates, when it does, update the gateways observable
        this.apiSub = this.apiService.currentNetwork.subscribe((network) => {
            const params = {};

            if (pageNumber != null) {
                params['page'] = pageNumber;
            }

            if (sort != null) {
                params['sort'] = sort;
            }

            if (filter != null) {
                // TODO filter
            }

            if (pageSize != null) {
                params['size'] = pageSize;
            }

            if (embedVal != null) {
                params['embed'] = embedVal;
            }

            this.mapSub = this.apiService
                .getLinkedResource(new Network(network), 'gatewayClusters', params)
                .pipe(map(this._extractEmbedded))
                .subscribe((data) => {
                    this.page = data['page'];
                    this.links = data['links'];
                    this.gatewaysSource.next(data['clusters']);
                });
        });

        // this is an observable that watches for network changes
        return this.gateways;
    }

    public getProtectionTypes() {
        return [
            { label: 'Active/Passive', value: '1:1' },
            { label: 'Round Robin', value: 'round-robin' },
        ];
    }

    /**
     * Saves the mop resource and returns result as observable
     */
    public upgrade(clusterModel: GatewayCluster) {
        return this.apiService.post(this._getCreateUrl(), clusterModel.getUpgradeObject());
    }

    /**
     * Function for sharing the registration information
     */
    public share(cluster: GatewayCluster, emailParams: any) {
        // get the self link of the endpoint
        const selfLink = cluster.getSelfLink();

        // append share to the self link
        const path = `${selfLink}/share`;

        // make a POST request with the path and the email params
        return this.apiService.post(path, emailParams);
    }

    /**
     * Preprocesses the data before returning to the front-end
     */
    protected _extractEmbedded(res) {
        const clusters: GatewayCluster[] = [];

        if (res['_embedded'] !== undefined && res['_embedded']['gatewayClusters'] !== undefined) {
            console.log('CLUSTERS', res['_embedded']['gatewayClusters']);

            for (let i = 0; i < res['_embedded']['gatewayClusters'].length; i++) {
                const cluster = res['_embedded']['gatewayClusters'][i];
                clusters.push(new GatewayCluster(cluster));
            }
        }
        // object containing information on pagination such as page number, size, total number of items,  and total number of pages
        let page = null;
        if (res['page'] !== undefined) {
            page = res['page'];
        }

        // list of links for jumping to and from pages
        let links = null;
        if (res['_links'] !== undefined) {
            links = res['_links'];
        }

        return { clusters: clusters, page: page, links: links };
    }

    /**
     * Returns the create URL for this resource type
     */
    protected _getCreateUrl() {
        return this.getLinkedResourceUrl(this.currentNetworkModel, 'gatewayClusters');
    }
}
