import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NetworkV2 } from '@netfoundry-ui/shared/model';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';

import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import moment from 'moment';
import { ApiService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-events-modal',
    templateUrl: './events-modal.component.html',
    styleUrls: ['./events-modal.component.scss'],
    providers: [FromDatePipe],
})
export class EventsModalComponent implements OnInit, OnDestroy {
    currentNetwork = new NetworkV2();
    dateFilter = '24h';
    currentTimeFilter = '24h';
    endTime: number = Date.now();
    startTime: number = this.endTime - 24 * 60 * 60 * 1000;
    subscription = new Subscription();
    endpoint;
    edgeRouter;
    networkGroupId;

    constructor(
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        public authorizationService: AuthorizationService,
        public apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fromDate: FromDatePipe,
        public dialogRef: MatDialogRef<EventsModalComponent>,
        public dialogForm: MatDialog
    ) {
        if (this.data.model.hostId || this.data.resourceType) {
            this.edgeRouter = data.model;
        } else {
            this.endpoint = data.model;
        }

        this.networkGroupId = data.networkGroupId;
    }

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentNetwork.pipe(debounceTime(1000)).subscribe((network) => {
                this.initData(network.id);
                this.subscription.add(
                    this.apiService.addedFromAnywhere.subscribe(() => {
                        this.initData(network.id);
                    })
                );
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getOptions() {
        const params = [{ key: 'networkId', value: this.currentNetwork.id }];
        return { params: params };
    }

    close() {
        this.dialogRef.close();
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setStartTime(value: string) {
        this.startTime = this.fromDate.transform(value);
        this.endTime = Date.now();

        // if the filter is 1m, 6m, or 12m, we should display from the start of one month to the end of another
        if (value === '1m' || value === '6m' || value === '12m') {
            // the start time should be set to the start of the first month by the fromDate pipe
            //  setting teh end time to the end of the current month manually
            this.endTime = moment(this.endTime).endOf('month').valueOf();
        }
        this.dateFilter = value;
    }

    /**
     * Updates the observables for starttime / endtime
     * @param value
     */
    public setEndTime(value: string) {
        this.endTime = this.fromDate.transform(value);
    }

    updateStart(value: string) {
        this.dateFilter = value;
    }

    private initData(networkId: any) {
        this.networkServiceV2.getNetwork(networkId).then((nw: NetworkV2) => {
            this.currentNetwork = nw;
        });
    }
}
