<div *ngIf="item.isRoot || item.rowType === 'root-process'" class="process-info-container">
    <app-resource-status [item]="item" [resourceType]="'process-execution'"></app-resource-status>
    <div *ngIf="item.isRoot || showToggle" class="info-details-container" id="RowInfo_{{ item.id }}">
        <div class="duration-container">
            <div class="clock-icon"></div>
            <div class="duration-summary">{{ fromNow }}{{ duration }}</div>
        </div>
        <div class="status-container">
            <div class="status-summary">
                {{ status }}
                <div *ngIf="item.warning">{{ item.warning }}</div>
            </div>
        </div>
    </div>
</div>
<div
    *ngIf="item.rowType === 'step-header'"
    [ngClass]="{ 'parallel-step': item.isParallel }"
    class="step-header-container"
    id="RowInfo_{{ item.id }}"
>
    <div class="step-label header-label">
        {{ item.isParallel ? 'Name' : 'Step' }}
    </div>
    <div class="started-label header-label">Started</div>
    <div class="finished-label header-label">Finished</div>
    <div class="duration-label header-label">Duration</div>
    <div class="state-label header-label">State</div>
</div>
<div
    *ngIf="!item.isRoot && (item.rowType === 'sub-process' || item.rowType === 'parallel-step')"
    [ngClass]="{ 'parallel-step': item.isParallel }"
    class="step-header-container"
    id="RowInfo_{{ item.id }}"
>
    <div class="step-label step-content">
        <app-resource-status [item]="item" [resourceType]="'process-execution'"></app-resource-status>
        <div class="sub-process-image"></div>
    </div>
    <div class="started-label step-content">
        {{ startedLabel }}
    </div>
    <div class="finished-label step-content">
        {{ finishedLabel }}
    </div>
    <div class="duration-label step-content">
        {{ duration }}
    </div>
    <div class="state-label step-content">
        {{ state }}
    </div>
</div>
