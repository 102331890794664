export class Item {
    public parentId: string;
    public id: string;
    public model: any;
    public label: string;
    public type: string;
    public selectable: boolean;
    public selected: boolean;

    constructor(
        model: any,
        parentId: string,
        type: string,
        id: string,
        label: string,
        selectable = false,
        selected = false
    ) {
        this.model = model;
        this.parentId = parentId;
        this.type = type;
        this.id = id;
        this.label = label;
        this.selectable = selectable;
        this.selected = selected;
    }
}
