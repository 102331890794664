import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-container-loader',
    templateUrl: './container-loader.component.html',
    styleUrls: ['./container-loader.component.scss'],
})
export class ContainerLoaderComponent {
    @Input() isLoading = false;
}
