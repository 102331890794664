import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { MatDialogModule } from '@angular/material/dialog';
import { LottieModule } from 'ngx-lottie';
import { SharedTunnelerCardModule } from '@netfoundry-ui/shared/tunneler-card';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { StartMenuItemComponent } from './start-menu-item/start-menu-item.component';
import { SampleServiceComponent } from './sample-service/sample-service.component';
import { EnrollmentInstructionsComponent } from './enrollment-instructions/enrollment-instructions.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { HeaderCardComponent } from './header-card/header-card.component';
import { TutorialsComponent } from './tutorials/tutorials.component';

export function playerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
    imports: [
        CommonModule,
        SharedTunnelerCardModule,
        UiLoaderModule,
        MatDialogModule,
        LottieModule.forRoot({ player: playerFactory }),
    ],
    declarations: [
        GettingStartedComponent,
        StartMenuItemComponent,
        SampleServiceComponent,
        EnrollmentInstructionsComponent,
        ProgressBarComponent,
        HeaderCardComponent,
        TutorialsComponent,
    ],
    exports: [
        GettingStartedComponent,
        StartMenuItemComponent,
        SampleServiceComponent,
        ProgressBarComponent,
        TutorialsComponent,
        EnrollmentInstructionsComponent,
    ],
})
export class FeatureGettingStartedModule {}
