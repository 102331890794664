<div class="modal solid o365Modal open" id="o365Modal">
    <div (click)="cancel()" class="close cancel" id="CancelXButtonO365Modal"></div>
    <div class="icon O365"></div>
    <div class="title">Office365 Optimization</div>
    <div class="subtitle">Direct Internet Breakout for Office365</div>
    <div class="content">
        <div class="label">
            Optimization Preferences<span [hidden]="!errorOptimization" class="error">Input Required</span>
        </div>
        <select
            [(ngModel)]="network.o365BreakoutCategory"
            [ngClass]="{ error: errorOptimization }"
            id="OptimizationPreferenceList"
        >
            <option value="">Select Optimization</option>
            <option *ngFor="let optimization of optimizationOptions" [value]="optimization.value">
                {{ optimization.name }}
            </option>
        </select>
    </div>

    <div class="buttons">
        <button (click)="save()" *ngIf="!processing" class="save" id="SaveO365Optimization">Save</button>
        <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
    </div>
</div>
