import { Component, Input } from '@angular/core';
import { BillingEnabledService, CsvDownloadService } from '@netfoundry-ui/feature/shared-services';

@Component({
    selector: 'app-export-csv',
    templateUrl: './export-csv.component.html',
    styleUrls: ['./export-csv.component.scss'],
})
export class ExportCsvComponent {
    @Input() showExport = true;
    @Input() items = [];
    @Input() columns = [];
    @Input() filename = 'export';
    @Input() translateStatus = false;
    @Input() isEndpoint = false;

    @Input() options = {
        fieldSeparator: ',',
        quoteStrings: '',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        useBom: true,
    };

    constructor(
        public billingEnabledservice: BillingEnabledService,
        private csvDownloadServiceService: CsvDownloadService
    ) {}

    public csv_export() {
        this.csvDownloadServiceService.download(
            this.filename,
            this.items,
            this.columns,
            this.translateStatus,
            this.isEndpoint,
            this.options
        );
    }
}
