import { Component, Inject, OnInit } from '@angular/core';
import { IamService } from '@netfoundry-ui/shared/authorization';
import { ApiService, CustomerService, LoggerService } from '@netfoundry-ui/shared/services';
import { Router } from '@angular/router';

import { first, isEmpty } from 'lodash';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { GrowlerService } from '@netfoundry-ui/shared/growler';
import { NetworkV2 } from '@netfoundry-ui/shared/model';
import { AutoFabricService } from '@netfoundry-ui/feature/auto-fabric';

declare const gtag;

@Component({
    selector: 'app-signup-complete',
    templateUrl: './signup-complete.component.html',
    styleUrls: ['./signup-complete.component.scss'],
})
export class SignupCompleteComponent implements OnInit {
    isLoading = true;
    accessToken = '';
    jwtHelper: JwtHelperService;
    attempts = 0;
    zipcode: string;
    country: string;
    tenantLabel: string;
    plan: string;
    status = 'waiting';

    constructor(
        private customerService: CustomerService,
        private router: Router,
        private logger: LoggerService,
        private iamService: IamService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private growlerService: GrowlerService,
        private apiService: ApiService,
        private autoFabricService: AutoFabricService,
        private loggerService: LoggerService
    ) {
        this.jwtHelper = new JwtHelperService();
    }

    ngOnInit(): void {
        const newSignupId = localStorage.getItem(`nf_new_signup_id`);
        const nf_signup_data = localStorage.getItem(`nf_signup_data`);
        const data = JSON.parse(nf_signup_data);
        if (!isEmpty(newSignupId)) {
            this.checkSignupStatus(newSignupId, data);
        } else {
            this.isLoading = true;
            const setlocalStorage = Object.entries(localStorage);
            const bearerJwtObjectArray = setlocalStorage.find(([key]) => /@@auth0spa*/.test(key));
            const bearerJwtObject = JSON.parse(bearerJwtObjectArray[1]);
            const authToken = bearerJwtObject.body.access_token;
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            this.accessToken = authToken;
            data.identityProviders = ['Username-Password-Authentication'];
            data.authToken = authToken;
            this.customerService
                .createNewSignUp(data)
                .then((result) => {
                    const signupId = result?.id;
                    if (!isEmpty(signupId)) {
                        localStorage.setItem('nf_new_signup_id', signupId);
                        this.checkSignupStatus(signupId, data);
                    } else {
                        this.handleSignupError('Signup result did not return a signup ID', result);
                    }
                })
                .catch((result) => {
                    this.handleSignupError('Error occurred during new account signup', result);
                });
            const timestamp = Date.now();
            const signupStart = localStorage.getItem('nf_new_signup_start');

            if (isEmpty(signupStart)) {
                return;
            }
            const startTime = Number.parseInt(signupStart, 10);
            const totalTime = (timestamp - startTime) / 1000;
            if (gtag)
                gtag('event', 'click', {
                    event_category: 'signup-submission-time',
                    event_label: totalTime,
                });
        }
    }

    handleSignupError(message, data?) {
        localStorage.removeItem('nf_new_signup_id');
        this.logger.error(message, data);
        this.status = 'error';
    }

    checkSignupStatus(signupId, signupData) {
        const MAX_ATTEMPTS = 90;
        this.tenantLabel = signupData.label;
        this.zipcode = signupData.adminUser?.zip;
        this.country = signupData.adminUser?.countryCode;
        this.plan = signupData.billingInfo?.plan;
        this.customerService
            .getSignUp(signupId)
            .then((result) => {
                if (result.success === true) {
                    this.customerService.setNewSelfServiceUserFlags(
                        this.tenantLabel,
                        this.zipcode,
                        this.country,
                        this.plan
                    );
                    localStorage.removeItem('nf_new_signup_id');
                    this._setSession();
                    const timestamp = Date.now();
                    const signupStart = localStorage.getItem('nf_new_signup_start');

                    if (isEmpty(signupStart)) {
                        return;
                    }
                    const startTime = Number.parseInt(signupStart, 10);
                    const totalTime = (timestamp - startTime) / 1000;
                    if (gtag)
                        gtag('event', 'click', {
                            event_category: 'signup-complete-time',
                            event_label: totalTime,
                        });
                } else if (result.success === false) {
                    this.handleSignupError('Signup returned a result of success == false', result);
                } else if (this.attempts < MAX_ATTEMPTS) {
                    setTimeout(() => {
                        this.checkSignupStatus(signupId, signupData);
                    }, 5000);
                } else if (this.attempts >= MAX_ATTEMPTS) {
                    this.status = 'delayed';
                    setTimeout(() => {
                        this.checkSignupStatus(signupId, signupData);
                    }, 5000);
                }
                this.attempts++;
            })
            .catch((result) => {
                this.handleSignupError('Error occurred during new account singup', result);
            });
    }

    /**
     * Save the Auth0 Result to Local Storage for the current domain
     */
    public _setSession(): void {
        this.logger.info('Setting session');
        const jwt = this.jwtHelper.decodeToken(this.accessToken);
        this.iamService.getOrgByShortName(jwt['https://netfoundry.io/tenant/label']).subscribe(async (result: any) => {
            this.logger.info('Tenant Info', result);
            localStorage.setItem('tenantName', result.name);
            localStorage.setItem('tenantLabel', result.label);
            localStorage.setItem('tenantId', result.id);
            localStorage.setItem('loggedInTenantId', result.id);

            if (result.id !== sessionStorage.getItem('lastTenantId')) {
                // if the tenants are different, remove the old growler messages
                sessionStorage.removeItem('growlerErrors');
            }
            sessionStorage.setItem('lastTenantId', result.id);
            window.location.href = `${window.location.protocol}//${window.location.host}/getting-started`;
        });

        localStorage.setItem('access_token', this.accessToken);
        localStorage.setItem('Auth0Id', jwt.sub);
        localStorage.setItem('connectionId', jwt['https://netfoundry.io/auth0/connectionId']);
    }
}
