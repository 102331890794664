import { Pipe, PipeTransform } from '@angular/core';

// filters for most objects
const linksString = '_links';
const hashString = 'hash';
const selectedString = 'selected';
const updatedAtString = 'updatedAt';
const createdAtString = 'createdAt';
const statusString = 'status';

// filters for networks
const organizationIdString = 'organizationId';

// filters for gateways/clients
const vtcAuthKeyString = 'vtcAuthKey';
const registrationKeyString = 'registrationKey';
const vtcAuthPinString = 'vtcAuthPin';
const registrationAttemptsLeftString = 'registrationAttemptsLeft';
// const dataCenterIdString = 'dataCenterId';
// const geoRegionIdString = 'geoRegionId';

// filters for services
const lowLatencyString = 'lowLatency';
const dataInterleavingString = 'dataInterleaving';
const transparencyString = 'transparency';
const localNetworkGatewayString = 'localNetworkGateway';
const multicastString = 'multicast';
const dnsOptionsString = 'dnsOptions';
const icmpTunnelString = 'icmpTunnel';
const cryptoLevelString = 'cryptoLevel';
const permanentConnectionString = 'permanentConnection';
const bridgeStpString = 'bridgeStp';
const rateSmoothingString = 'rateSmoothing';
const netflowIndexString = 'netflowIndex';
const profileIndexString = 'profileIndex';
const bridgeAgeingString = 'bridgeAgeing';
const bridgePrioString = 'bridgePrio';
const bridgeFdString = 'bridgeFd';
const bridgeHelloTimeString = 'bridgeHelloTime';
const bridgeMaxAgeString = 'bridgeMaxAge';
const bridgeCostString = 'bridgeCost';

// filters for appwans
const endpointsString = 'endpoints';
const groupsString = 'groups';
const servicesString = 'services';

// filters for network/organization events
const timestampString = 'timestamp';
const targetIndexString = 'target_index';
const versionString = '@version';
const atTimestampString = '@timestamp';

// filtes for alarms
const domainString = 'domain';
const alarmIdString = 'alarm_id';
const timestampSecsEpochString = 'timestampSecsEpoch';

// These items will be EXCLUDED from the table search
const filterList = [
    linksString,
    hashString,
    selectedString,
    updatedAtString,
    createdAtString,
    statusString,
    organizationIdString,
    vtcAuthKeyString,
    registrationKeyString,
    vtcAuthPinString,
    registrationAttemptsLeftString,
    lowLatencyString,
    dataInterleavingString,
    transparencyString,
    localNetworkGatewayString,
    multicastString,
    dnsOptionsString,
    icmpTunnelString,
    cryptoLevelString,
    permanentConnectionString,
    bridgeStpString,
    rateSmoothingString,
    netflowIndexString,
    profileIndexString,
    bridgeAgeingString,
    bridgePrioString,
    bridgeFdString,
    bridgeHelloTimeString,
    bridgeMaxAgeString,
    bridgeCostString,
    endpointsString,
    groupsString,
    servicesString,
    timestampString,
    targetIndexString,
    versionString,
    atTimestampString,
    domainString,
    alarmIdString,
    timestampSecsEpochString,
];

@Pipe({
    name: 'tableSearch',
})
export class TableSearchPipe implements PipeTransform {
    /* function for filtering the list based on search paramaters
     * takes the list of items, the value to search on, an optional custom concat function, and an opptional
     *  additional paramater for the custom concat function
     * returns the filtered list
     */
    transform(items: any[], value: string, concatFunction?, additionalParams?: any): any {
        // if the list of items is empty or undefined, return an empty list
        if (!items) {
            return [];
        }

        // if the value is undefined or is empty, returning the original list of items
        if (!value || value === '') {
            return items;
        }

        // converting the value to search for to lower case
        const searchText = value.toLowerCase();

        // string reprsenting the item
        let itemString = '';

        // if a custom concat function was not provided, use the default
        if (concatFunction === undefined) {
            // filter the list of items using the default concat function
            return items.filter((it) => {
                itemString = this.defaultConcat(it);
                if (itemString.includes(searchText)) {
                    return it;
                }
            });

            // otherwise, use the provided custom concat function
        } else {
            // returning the result
            return this.useCustomConcat(items, searchText, concatFunction, additionalParams);
        }
    }

    /*
     * default concat function
     * concats all the fields of an object to use for searching
     * separates values by |
     * excludes any fields in the filterList
     */
    private defaultConcat(item) {
        // array to use as the string builder
        const stringBuilder = [];

        // looping througth each property in the object
        for (const property in item) {
            // eslint-disable-next-line no-prototype-builtins
            if (item.hasOwnProperty(property)) {
                // determiningif the property should be excluded based on the filter list
                // excluding the property if the value is null, empty, or undefined
                if (filterList.indexOf(property) === -1 && item[property]) {
                    // adding the property and a pipe to the list
                    stringBuilder.push(item[property]);
                    stringBuilder.push('|');
                }
            }
        }
        // building the string and returning it
        return stringBuilder.join('').toLowerCase();
    }

    /* function for running a custom concat function
     * takes the list of items, the string to search on, the custom function, and an optional additional paramater for the concat function
     * runs the concat function on the item and additional input and searches on the resulting string
     * returns the list of matches
     */
    private useCustomConcat(items: any[], searchString: string, customConcat, additionalParams?: any) {
        // array to use as the string builder
        // string representing the item
        let itemString = '';

        // filtering the list of items
        return items.filter((it) => {
            // getting the item string using the custom concat function
            itemString = customConcat(it, additionalParams).toLowerCase();

            // checking if the item string contains the value that the user is searching for
            if (itemString.includes(searchString)) {
                return it;
            }
        });
    }
}
