import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import _ from 'lodash';

@Component({
    selector: 'app-table-cell-os',
    templateUrl: './table-cell-os.component.html',
    styleUrls: ['./table-cell-os.component.scss'],
})
export class TableCellOSComponent implements ICellRendererAngularComp {
    params;
    item = {
        id: undefined,
        name: undefined,
        jwt: undefined,
        os: undefined,
    };
    type = 'os';

    // eslint-disable-next-line @typescript-eslint/no-empty-function

    agInit(params: ICellRendererParams): void {
        this.type = _.get(params, 'type');
        this.item = params.node.data;
    }

    refresh(params: ICellRendererParams): boolean {
        this.item = params.data;
        return true;
    }
}
