<div [ngClass]="{ inline: isInline }" class="fullModal opened addForm" id="AddClientModal">
    <div [hidden]="isSelected" class="fullRow" id="Stage1">
        <div class="title" style="filter: brightness(50%)">Create A New Client</div>
        <div class="subtitle">Choose a client type</div>
        <div class="tileSized">
            <div
                (click)="setType('CLIENT')"
                *ngIf="canListGeoRegions"
                class="buttonCard selectType"
                id="SelectButtonClient"
            >
                <div class="icon" style="background-image: url(/assets/svgs/clientcard.svg)"></div>
                <div class="title">Netfoundry Client</div>
                <div class="content">
                    Windows, Mac, iOS, Andriod and more. Use the console to easily provision and register a client
                    <br />&nbsp;
                </div>
                <div class="row center buttonArea">
                    <button class="save">Create Client</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>

            <div
                (click)="setType('BULK_UPLOAD')"
                *ngIf="canListGeoRegions"
                class="buttonCard selectType"
                id="SelectButtonBulkImport"
            >
                <div class="icon" style="background-image: url(/assets/svgs/AsCode.svg)"></div>
                <div class="title">IMPORT YOUR CLIENT AS CODE</div>
                <div class="content">
                    Prefer working in code? Simplify provisioning of your Client with a simple file upload.<br />&nbsp;
                </div>
                <div class="row center buttonArea">
                    <button class="save">Create Client</button>
                </div>
                <div class="row center padTop subButton">&nbsp;</div>
            </div>
        </div>
    </div>

    <div [hidden]="!isSelected" class="fullRow">
        <div *ngIf="selectedType === 'CLIENT'">
            <app-clientform (back)="back()" (hide)="hide()" (newClient)="setNewClient($event)" [model]="clientModel">
            </app-clientform>
        </div>

        <div *ngIf="selectedType === 'BULK_UPLOAD'">
            <app-bulk-upload-client-form
                (back)="back()"
                (hide)="hide()"
                (newClients)="setNewClients($event)"
                [currentNetworkId]="currentNetworkId"
                [currentOrgId]="currentOrgId"
            >
            </app-bulk-upload-client-form>
        </div>
    </div>
    <div (click)="hide()" class="buttonBall close" id="CloseClientButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
