import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-table-cell-registration',
    templateUrl: './table-cell-mfa.component.html',
    styleUrls: ['./table-cell-mfa.component.scss'],
})
export class TableCellMFAComponent implements ICellRendererAngularComp {
    params;
    item = {
        id: '',
        mfaEnabled: false,
    };

    agInit(params: ICellRendererParams): void {
        if (!params.node.data) {
            return;
        }
        this.item = params.node.data;
    }

    refresh(params: ICellRendererParams): boolean {
        if (!params.data) {
            return;
        }
        this.item = params.data;
        return true;
    }
}
