import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
    @Input() steps: any;
    @Input() currentStep: any;
    @Output() stepSelected = new EventEmitter();

    private subscription = new Subscription();

    constructor(public apiService: ApiService) {}

    ngOnInit(): void {
        this.subscription.add(
            this.apiService.tutorialSteps.subscribe((steps) => {
                this.steps = steps;
            })
        );
        this.subscription.add(
            this.apiService.tutorialStep.subscribe((step) => {
                this.currentStep = step;
            })
        );
    }
}
