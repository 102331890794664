import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HTTP_CLIENT, LoggerService, TokenService } from '@netfoundry-ui/shared/services';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';

export const AUTHORIZATION_CONFIGURATION = new InjectionToken<AuthorizationConfig>('AUTHORIZATION_CONFIGURATION');

export interface AuthorizationConfig {
    url: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    lastErrorSource = new Subject<HttpErrorResponse>();
    lastError = this.lastErrorSource.asObservable();

    private apiUrl;

    constructor(
        @Inject(HTTP_CLIENT) private http: HttpClient,
        public logger: LoggerService,
        private tokenService: TokenService,
        @Inject(AUTHORIZATION_CONFIGURATION) config?: AuthorizationConfig
    ) {
        this.apiUrl = config?.url;
    }

    public getPermissions(identityId: any): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}authorizations`;

        const params = {
            identityId: [identityId],
        };

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers, params })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    public findRoles(role: string, orgShortName: string): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}roles`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        const params = {
            nameLike: `${role} - ${orgShortName}`,
            size: '2000',
        };

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers, params })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    public addRole(identityId: string, roleId: string): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}roles/${roleId}/identity-grants/${identityId}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(fullpath, {}, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    public getRoles(identityId: string): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}roles`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        const params = {
            identityId: identityId,
            size: '2000',
        };

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers, params: params })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    public getRolePermissions(roleId: string): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}policies`;
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        const params = {
            roleId: roleId,
            size: '2000',
        };

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers, params: params })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    public removeRole(identityId: string, roleId: string): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}roles/${roleId}/identity-grants/${identityId}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .delete(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    /**
     *
     * @param params object containing the paramaters. can contain the following:
     * resourceTypeIds
     * authorizationStrategyNames
     * includedByStandardRoleTypeId
     */
    getStandardRoleTypes(params?: any): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}standard-role-types/`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers, params: params })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    /**
     *
     * method for creating a new standard role type
     */
    createStandardRoleType(body?: any): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}standard-role-types/`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, body, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    /**
     *
     * @param params object containing the paramaters. can contain the following:
     * standardRoleTypeId
     * identityId
     * resourceId
     */
    getStandardRoles(params?: any, body?: any): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}standard-roles/`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .request('GET', fullpath, { headers: headers, params: params, body })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    /**
     *
     * @param params object containing the paramaters. can contain the following:
     * standardRoleId
     * identityId
     * isDeleted
     */
    getStandardRoleGrants(params?: any): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}standard-role-grants/`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers, params: params })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    /**
     *
     * method for creating a new standard role
     */
    createStandardRole(body?: any): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}standard-roles/`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, body, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    /**
     *
     * method for creating a new standard role
     */
    grantStandardRole(roleId: string, identityId: string): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}standard-roles/${roleId}/identity-grants/${identityId}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(fullpath, {}, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    /**
     * function for revoking a standard role
     * @param roleId the id of the role to revoke
     * @param identityId the identity ID to remove the role from
     */
    revokeStandardRole(roleId: string, identityId: string): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}standard-roles/${roleId}/identity-grants/${identityId}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .delete(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    /**
     * function for getting custom roles
     */
    // getCustomRoles(params?: any): Observable<any[] | unknown> {
    //     const fullpath = `${this.apiUrl}custom-roles/`;
    //
    //     // getting the headers. This now checks to determine if the user's token expired
    //     const headers = this.setHeaders();
    //
    //     // headers will be not null if the token is valid. If this is the case, kick off the request
    //     if (headers != null) {
    //         return this.http
    //             .get(fullpath, { headers: headers, params: params })
    //             .pipe(catchError((error) => this.handleError(this, error)));
    //     } else {
    //         // delaying the response to allow the console time to trigger the logout and terminate the subscription
    //         return of([]).pipe(delay(1000));
    //     }
    // }

    /**
     * function for getting the resource types
     */
    getResourceTypes(): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}resource-types/`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    /**
     * function for getting super users
     */
    getSuperUsers(params?: any): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}super-users/`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers, params: params })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    getPublicGrants(params?: any): Observable<any[] | unknown> {
        const fullpath = `${this.apiUrl}public-grants/`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers, params: params })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    getIdentityResourceActions(params?: any): Observable<any> {
        const fullpath = `${this.apiUrl}identity-resource-actions/`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers, params: params })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return of([]).pipe(delay(1000));
        }
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: AuthService, error: HttpErrorResponse): Observable<any[] | unknown> {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            scope.logger.error('IAM Service error occurred:', error.error);
            // this.logger.error('IAM Service error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            scope.logger.error(`IAM Backend returned code ${error.status}`, error.error);
            // this.logger.error(`IAM Backend returned code ${error.status}`, error.error);
        }
        scope.lastErrorSource.next(error);
        return throwError(error);
    }

    /**
     * Return headers
     */
    private setHeaders(): HttpHeaders | null {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (tokenResponse.expired) {
            // if the token is expired, return null
            headers = null;
        } else if (tokenResponse.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        if (headers != null) {
            return headers.append('Content-Type', 'application/json');
        } else {
            return headers;
        }
    }
}
