import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, Environment, RegionV2 } from '@netfoundry-ui/shared/model';
import { HateoasResourceOperation } from '@lagoshny/ngx-hateoas-client';
import { HttpClient } from '@angular/common/http';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';

@Injectable({ providedIn: 'root' })
export class RegionService extends HateoasResourceOperation<RegionV2> {
    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(RegionV2);
    }

    public findRegionsByProvider(providers: string[]): Observable<RegionV2[]> {
        const options: any = {};
        const url = `${this.environment.v2apiUrl}regions/?provider=${providers}`;
        return this.http.get(url, options) as Observable<any>;
    }

    public findRegionsByProviderAndGeolocation(
        providers: string[],
        latitude: number,
        longitude: number
    ): Observable<RegionV2[]> {
        const options: any = {};
        const url = `${this.environment.v2apiUrl}regions/?provider=${providers}&latitude=${latitude}&longitude=${longitude}`;
        return this.http.get(url, options) as Observable<any>;
    }
}
