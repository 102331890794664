import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import _ from 'lodash';

@Component({
    selector: 'app-table-cell-details',
    templateUrl: './table-cell-details.component.html',
    styleUrls: ['./table-cell-details.component.scss'],
})
export class TableCellDetailsComponent implements ICellRendererAngularComp {
    item: any = {
        selected: false,
        id: '',
        name: '',
        actionList: [],
    };
    cellParams;
    open = false;
    rowExpanded = false;
    showToggle = false;
    showDetails = false;
    labelClosed = 'Details';
    labelOpen = 'Details';

    agInit(cellParams: ICellRendererParams): void {
        this.cellParams = cellParams;
        this.item = _.get(cellParams, 'data', {});
        this.rowExpanded = this.item.expanded;
        this.showToggle = this.item.root || !this.item.parentId || _.get(this.item, 'parallelSteps', []).length > 0;
        this.getLabelText(this.item);
    }

    refresh(cellParams: ICellRendererParams): boolean {
        this.cellParams = cellParams;
        this.item = _.get(cellParams, 'data', {});
        this.showToggle = this.item.root || !this.item.parentId || _.get(this.item, 'parallelSteps', []).length > 0;
        this.getLabelText(this.item);
        return true;
    }

    toggleRowData(event) {
        if (this.rowExpanded) {
            this.cellParams.collapseRowData(this.item);
            this.rowExpanded = false;
        } else {
            this.cellParams.expandRowData(this.item);
            this.rowExpanded = true;
        }
        this.item.expanded = this.rowExpanded;
    }

    private getLabelText(item) {
        if (!_.isEmpty(item.email)) {
            this.labelClosed = 'Show Roles';
            this.labelOpen = 'Hide Roles';
        } else if (item.userItem) {
            this.labelClosed = 'Show Users';
            this.labelOpen = 'Hide Users';
        } else if (item.parallelSteps && item.parallelSteps.length > 0) {
            this.labelClosed = 'Steps';
            this.labelOpen = 'Steps';
        }
        return;
    }
}
