import { Component, Input, OnInit } from '@angular/core';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';

@Component({
    selector: 'app-host-v1-options',
    templateUrl: './host-v1-options.component.html',
    styleUrls: ['./host-v1-options.component.scss'],
    providers: [FromDatePipe],
})
export class HostV1OptionsComponent implements OnInit {
    @Input() model: any = {
        data: {
            allowedSourceAddresses: [],
            listenOptions: {},
        },
    };
    @Input() isNew = true;
    @Input() isEditing = true;
    @Input() readOnly = false;
    @Input() errors: any = {};

    constructor() {}

    ngOnInit() {
        this.initModel();
    }

    initModel() {}
}
