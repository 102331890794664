import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Collector, NetworkGroup } from '@netfoundry-ui/shared/model';
import { CollectorService } from '@netfoundry-ui/shared/netflow';
import { NetworkGroupService, ValidateService } from '@netfoundry-ui/shared/services';
import { SortbyPipe } from '@netfoundry-ui/ui/pipes';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-collectors-form',
    templateUrl: './collectors-form.component.html',
    styleUrls: ['./collectors-form.component.scss'],
})
export class CollectorsFormComponent implements OnInit, OnDestroy {
    processing = false;
    canEdit = true;
    isEditing = false;

    model: Collector = new Collector({
        ipAddress: '',
        port: '',
        protocol: 'UDP',
        name: '',
        networkGroupId: '',
    });
    errors = [];

    currentOrgId;

    networkGroupList = [];
    hideNetworkGroupSelect = true;

    private subscription: Subscription = new Subscription();

    constructor(
        private dialogRef: MatDialogRef<CollectorsFormComponent>,
        private collectorService: CollectorService,
        private validateService: ValidateService,
        private organizationService: NetworkGroupService,
        private sortByPipe: SortbyPipe,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.currentOrgId = data.currentOrgId;
        if (data.model) {
            this.model = data.model;
            this.isEditing = true;
        }
    }

    ngOnInit() {
        // getting all the network groups the user has access to
        this.subscription.add(
            this.organizationService.get().subscribe((res) => {
                // storing them in a list
                for (const org of res) {
                    const organization = new NetworkGroup(org);

                    // TODO check if the user has permissions to create collectors on the org
                    // first part should be a check if the user can create collectors
                    // second should be a check if the user can update collectors
                    if ((this.isEditing && true) || (!this.isEditing && true)) {
                        this.networkGroupList.push(organization);
                    }
                }

                // if the user only has access to one network group
                if (this.networkGroupList.length === 1) {
                    // hide the network group selection
                    this.hideNetworkGroupSelect = true;

                    if (!this.isEditing) {
                        // set the organization ID on the model based on the one network group
                        this.model.networkGroupId = this.networkGroupList[0].getId();
                    }
                } else {
                    this.hideNetworkGroupSelect = false;
                    this.networkGroupList = this.sortByPipe.transform(this.networkGroupList, 'name', 'desc');

                    if (!this.isEditing) {
                        // otherwise set the organization ID to an empty string to fix the placeholder
                        this.model.networkGroupId = '';
                    }
                }
            })
        );
    }

    close() {
        this.dialogRef.close({ collector: this.model });
    }

    save() {
        if (this.validate()) {
            this.processing = true;
            this.subscription.add(
                this.collectorService.save(this.model).subscribe((result) => {
                    if (!this.isEditing) {
                        this.model = new Collector(result);
                    }

                    this.processing = false;
                    this.close();
                })
            );
        }
    }

    validate() {
        let isValid = true;
        this.errors = [];

        if (!this.validateService.isValidIP(this.model.ipAddress)) {
            isValid = false;
            this.errors['ipAddress'] = true;
        }

        if (!this.validateService.isValidName(this.model.name)) {
            isValid = false;
            this.errors['name'] = true;
        }

        if (!this.validateService.isValidPort(this.model.port.toString())) {
            isValid = false;
            this.errors['port'] = true;
        }

        if (!this.validateService.hasValue(this.model.protocol)) {
            isValid = false;
            this.errors['protocol'] = true;
        }

        if (!this.validateService.hasValue(this.model.networkGroupId)) {
            isValid = false;
            this.errors['networkGroupId'] = true;
        }

        return isValid;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
