import { HttpClient } from '@angular/common/http';
import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Network, Service } from '@netfoundry-ui/shared/model';
import { ApiService, HTTP_CLIENT, LoggerService } from '@netfoundry-ui/shared/services';
import { FileUploader } from 'ng2-file-upload';
import { FileSaverService } from 'ngx-filesaver';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-bulk-upload-service-form',
    templateUrl: './bulk-upload-service-form.component.html',
    styleUrls: ['./bulk-upload-service-form.component.scss'],
})
export class BulkUploadServiceFormComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
    @ViewChild('saveServicesAsCode', { static: true }) saveServicesAsCode;

    @Input() hideHelp = false;
    @Input() isInline = false;
    @Input() canEdit = true;

    @Output() back: EventEmitter<boolean> = new EventEmitter();
    @Output() hide: EventEmitter<any> = new EventEmitter();
    step = 0;
    currentNetwork = new Network({});
    networkId;
    isLoading = false;
    // create an empty uploader until we have an actual URL
    uploader: FileUploader;
    fileUpload;
    form: FormGroup;
    fileIsReady = false;
    processing = false;
    businessKey;
    canListServices = true;
    canCreateServices = true;
    services: Service[] = [];
    isAdding = true;
    isEditing = false;
    cloneMessage = 'Want to add another environment<br />by uploading another csv file?';
    // boolean to determine if the window is attempting to hide
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private renderer: Renderer2,
        private formBuilder: FormBuilder,
        private growlerService: GrowlerService,
        private apiService: ApiService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        private fileSaverService: FileSaverService,
        private dialogRef: MatDialogRef<BulkUploadServiceFormComponent>,
        public authorizationService: AuthorizationService,
        public dialogForm: MatDialog
    ) {
        // initialize file upload form
        this.form = this.formBuilder.group({
            networkId: '',
            file: null,
        });

        // initialize the uploader for the drag and drop
        this.uploader = new FileUploader({ url: '' });
        this.canListServices = this.authorizationService.canListServices(undefined, undefined);
        this.canCreateServices = this.authorizationService.canCreateServices(undefined, undefined);
    }

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((network) => {
                this.currentNetwork = new Network(network);
                this.networkId = this.currentNetwork.getId();

                // upload URL
                const uploadOptions = {
                    url: this.currentNetwork.getSelfLink() + '/services',
                    authToken: 'Bearer ' + localStorage.getItem('access_token'),
                    additionalParameter: {
                        networkId: this.apiService.theNetworkIs.getId(),
                    },
                };
                this.uploader = new FileUploader(uploadOptions);
            })
        );
    }

    showDialog() {
        const event = new MouseEvent('click', { bubbles: true });
        this.fileInput.nativeElement.dispatchEvent(event);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    download() {
        const templateFile = 'ServicesTemplate.csv';
        this.http
            .get(`/assets/data/${templateFile}`, {
                observe: 'response',
                responseType: 'blob',
            })
            .subscribe((res) => {
                this.fileSaverService.save(res.body, templateFile);
            });
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.logger.info('Saving Services data...');
        this.addServicesCSV();
    }

    async addServicesCSV() {
        // if we used the drag and drop
        if (this.uploader.queue.length > 0) {
            this.processing = true;
            const FileObject = this.uploader.queue[0];
            this.logger.info('Temp file', FileObject);
            this.logger.info('Adding file from queue...', FileObject._file);
            await FileObject.upload();
            this.fileUpload = FileObject;
            this.isLoading = false;

            this.step = 1;
            FileObject.onComplete = (response: any, status: any, headers: any) => {
                if (status < 300) {
                    const servicesAsCodeResponse = JSON.parse(response);
                    this.businessKey = servicesAsCodeResponse['businessKey'];
                    this.services = servicesAsCodeResponse['services'];
                    this.logger.info('services as code upload process business key' + this.businessKey);

                    this.saveServicesAsCode.fetchStatus();
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'Services Template Upload Complete',
                            'Services template upload has been successful.'
                        )
                    );
                } else {
                    const error = JSON.parse(response);
                    this.logger.info('ERROR MESSAGE', error);
                    let errorMessage = '';
                    if (error != null && error[0] != null && error[0]['message'] != null) {
                        errorMessage = error[0]['message'];
                    }

                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Services Template Upload Failed',
                            'Services template upload has failed. Import has been canceled. ' + errorMessage
                        )
                    );
                }

                this.processing = false;
                this.uploader.clearQueue();
                if (status >= 300) {
                    this.resetForm();
                }
            };
        } else {
            this.logger.error('No files in queue to upload');
            this.growlerService.show(
                new GrowlerData('error', 'Error', 'Services Template Upload Failed', 'No files queued to upload')
            );
        }
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.logger.info('Adding file...', file);
            this.form.get('file').setValue(file);
            this.fileIsReady = true;
            this.uploader.addToQueue([this.form.get('file').value]);
        }
    }

    fileDrop() {
        if (this.uploader.queue.length > 0) {
            this.fileIsReady = true;
        }
    }

    // function for resetting the form
    resetForm() {
        this.businessKey = null;
        this.fileIsReady = false;
        this.processing = false;

        this.services = [];

        this.fileInput.nativeElement.value = '';

        this.step = 0;
    }
}
