import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    GetOption,
    HateoasResourceOperation,
    PagedGetOption,
    PagedResourceCollection,
    HttpMethod,
} from '@lagoshny/ngx-hateoas-client';
import { Environment, ENVIRONMENT, ProcessExecution } from '@netfoundry-ui/shared/model';
import { HTTP_CLIENT, FeatureService } from '@netfoundry-ui/shared/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';

@Injectable({ providedIn: 'root' })
export class ProcessExecutionServiceV2 extends HateoasResourceOperation<ProcessExecution> {
    static defaultPaginationSize = 500;
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    apiUrl: string;
    lastPageCount = 0;
    lastTotalCount = 0;

    constructor(
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: Environment,
        private featureService: FeatureService,
        public growlerService: GrowlerService
    ) {
        super(ProcessExecution);
        this.apiUrl = environment.v3Enabled ? environment.v3apiUrl : environment.v2apiUrl;
    }

    getResource(): Observable<ProcessExecution> {
        throw new Error('Do not use: see get getProcessExecution');
    }

    getProcessExecution(id, options: GetOption = {}): Promise<ProcessExecution> {
        return super
            .getResource(id, { ...ProcessExecutionServiceV2.defaultHttpAccept, ...options })
            .toPromise()
            .then((rec) => rec);
    }
    public getExecutionData(executionId: number): Promise<any> {
        const headers = new HttpHeaders().set('Accept', 'application/hal+json');
        const params = new HttpParams();
        return this.http
            .get(this.apiUrl + `executions/${executionId}?meta=processData`, {
                headers: headers,
                params: params,
            })
            .toPromise();
    }
    async getProcessExecutionPage(options?: PagedGetOption, includeProperties?: string): Promise<ProcessExecution[]> {
        let params: PagedGetOption = { ...ProcessExecutionServiceV2.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super
            .getPage(params)
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((processes) => processes.resources);
    }

    doExecutionAction(action) {
      const url = action.url;
      const headers = new HttpHeaders().set('Accept', 'application/hal+json');
      const params = new HttpParams();
      if (action.method === 'PUT') {
        return this.http.put(url, {headers: headers,params: params}).toPromise();
      } else if (action.method === 'POST') {
        return this.http.post(url, {headers: headers,params: params}).toPromise();
      } else if (action.method === 'GET') {
        return this.http.get(url, {headers: headers,params: params}).toPromise();
      } else if (action.method === 'DELETE') {
        return this.http.delete(url, {headers: headers,params: params}).toPromise();
      } else if (action.method === 'PATCH') {
        return this.http.patch(url, {headers: headers,params: params}).toPromise();
      }
    }

    retryExecution(executionId) {
      const headers = new HttpHeaders().set('Accept', 'application/hal+json');
      const params = new HttpParams();
      return this.http
        .post(this.apiUrl + `executions/${executionId}/retry`, {
          headers: headers,
          params: params,
        })
        .toPromise();
    }

    // WARNING: getProcessExecutionsByNetworkId() is an expensive operation - use at your own risk
    // in fact, don't use it
    async getProcessExecutionsByNetworkId(
        networkId: string,
        options = {},
        includeProps = ''
    ): Promise<ProcessExecution[]> {
        throw new Error('Do not use: too expensive');
    }
}
