import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientdashboardComponent } from '@netfoundry-ui/feature/dashboard/client-dashboard';
import { GatewaydashboardComponent } from '@netfoundry-ui/feature/dashboard/gateway-dashboard';
import { AppWanFormComponent } from '@netfoundry-ui/feature/form/app-wan-form';
import { GroupsformComponent } from '@netfoundry-ui/feature/form/groups-form';
import { ServicesformComponent } from '@netfoundry-ui/feature/form/services-form';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { AppWan, Endpoint, Group, Service } from '@netfoundry-ui/shared/model';
import {
    AppwanService,
    AzureDeployService,
    ClientService,
    GatewayClusterService,
    GatewayService,
    GetCloudformationLinkService,
    LoggerService,
    NetworkVersionService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppWanBulkUploadFormComponent } from '..';

const provisionedStatus = 300;
const registeredStatus = 400;

@Component({
    selector: 'app-appwan-upload-summary',
    templateUrl: './appwan-upload-summary.component.html',
    styleUrls: ['./appwan-upload-summary.component.scss'],
})
export class AppwanUploadSummaryComponent implements OnInit {
    @Input() appWan: AppWan = new AppWan({});
    @Input() displaySummary = false;
    @Input() cloneMessage = 'Want to add another environment';
    @Output() createNew = new EventEmitter<void>();
    finalModel: AppWan = new AppWan({});
    canListEndpoints = true;
    canCreateEndpoints = true;
    canListGroups = true;
    canCreateGroups = true;
    gateways = [];
    hasNewClients = false;
    isAdding = true;
    isEditing = false;
    groupRef;
    formRef;
    ipNetworkServices: Service[] = [];
    ipHostServices: Service[] = [];
    sendto: string[] = [];
    sendingState: string[] = [];
    disableAzureStackDeploy = false;
    // todo remove
    hideLaunchButton = false;
    errorEmails = false;
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        public authorizationService: AuthorizationService,
        private appwanService: AppwanService,
        public validateService: ValidateService,
        private cfService: GetCloudformationLinkService,
        private gatewayService: GatewayService,
        private clientService: ClientService,
        private azureDeployService: AzureDeployService,
        public dialogForm: MatDialog,
        public dialogInlineRef: MatDialogRef<ServicesformComponent>,
        public dialogInlineGWRef: MatDialogRef<GatewaydashboardComponent>,
        public dialogInlineCLRef: MatDialogRef<ClientdashboardComponent>,
        private networkVersionService: NetworkVersionService,
        private dialogRef: MatDialogRef<AppWanBulkUploadFormComponent>,
        private clusterService: GatewayClusterService
    ) {
        this.canListEndpoints = this.authorizationService.canListEndpoints(undefined, undefined);
        this.canCreateEndpoints = this.authorizationService.canCreateEndpoints(undefined, undefined);
        this.canListGroups = this.authorizationService.canListEndpointGroups(undefined, undefined);
        this.canCreateGroups = this.authorizationService.canCreateEndpointGroups(undefined, undefined);
        this.initializeFinalModel();
    }

    initializeFinalModel() {
        this.finalModel['services'] = [];
        this.finalModel['endpointGroups'] = [];
        this.finalModel['clients'] = [];
        this.finalModel['gateways'] = [];
        this.finalModel['hostingGateway'] = new Endpoint({});
        this.finalModel['hostingGatewayName'] = '';
    }

    ngOnInit() {
        // TODO remove
        this.hideLaunchButton = this.networkVersionService.currentNetworkVersion > 4;

        this.azureDeployService.canShowAzureFormationLink('AZSGW').subscribe((result) => {
            this.disableAzureStackDeploy = !result;
        });

        const proms = [];
        if (this.canListEndpoints) {
            // gateways
            const gatewayPromise = this.gatewayService
                .get()
                .pipe(take(1))
                .toPromise()
                .then((gatewaysList) => {
                    const gateways = [];
                    for (const gateway of gatewaysList) {
                        if (
                            (gateway.status === provisionedStatus || gateway.status === registeredStatus) &&
                            gateway.endpointProtectionRole == null
                        ) {
                            if (gateway.endpointType !== 'ZTGW' && gateway.endpointType !== 'ZTNHGW') {
                                gateways.push(gateway);
                            }
                        }
                    }
                    return gateways;
                });

            proms.push(gatewayPromise);
        }

        if (this.authorizationService.canListGatewayClusters()) {
            const clusterPromise = this.clusterService
                .get()
                .pipe(take(1))
                .toPromise()
                .then((result) => {
                    const clusters = [];
                    for (const cluster of result) {
                        if (
                            cluster.status === provisionedStatus &&
                            cluster.endpointProtectionRole == null &&
                            cluster.endpointType !== 'ZTGW' &&
                            cluster.endpointType !== 'ZTNHGW'
                        ) {
                            clusters.push(cluster);
                        }
                    }
                    return clusters;
                });
            proms.push(clusterPromise);
        }

        Promise.all(proms).then((gatewayLists) => {
            this.gateways = [];
            for (const gatewayList of gatewayLists) {
                this.gateways = this.gateways.concat(gatewayList);
            }
        });
    }

    showAzureLauncher(gateway) {
        if (this.disableAzureStackDeploy && gateway.endpointType === 'AZSGW') {
            return false;
        } else {
            return true;
        }
    }

    showLauncher(gateway) {
        if (gateway != null) {
            return (
                !this.isGatewayRegistered(gateway) &&
                !this.IsHA(gateway) &&
                (this.IsAWS(gateway) ||
                    (this.IsAzure(gateway) &&
                        !(this.hideLaunchButton && gateway.endpointType === 'AZSGW') &&
                        this.showAzureLauncher(gateway)))
            );
        }
        return false;
    }

    isGatewayRegistered(gateway) {
        if (gateway != null) {
            return gateway['status'] > 300;
        }
        return false;
    }

    IsAzure(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }
        return gateway.endpointType.indexOf('AZ') >= 0;
    }

    IsAWS(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }
        return gateway.endpointType.indexOf('AWS') >= 0;
    }

    IsGoogle(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }

        return gateway.endpointType === 'GCPCPEGW';
    }

    IsHA(gateway) {
        if (!gateway || !gateway.endpointType) {
            return false;
        }
        return gateway['protectionGroupId'] != null;
    }

    isEmptyHostService(service) {
        return (
            !this.validateService.hasValue(service.networkFirstPort) &&
            !this.validateService.hasValue(service.serviceType) &&
            !this.validateService.hasValue(service.interceptIp)
        );
    }

    isEmptyNetworkService(service) {
        return !this.validateService.hasValue(service.gatewayIp) && !this.validateService.hasValue(service.interceptIp);
    }

    /**
     * Launch cloud formation template in a new tab
     */
    LaunchCloudScript(gateway: Endpoint) {
        if (this.authorizationService.canGetDataCenter(gateway['dataCenterId'])) {
            this.subscription.add(
                this.gatewayService
                    .getResource(this.gatewayService.getLinkedResourceUrl(gateway, 'dataCenter'))
                    .subscribe((result) => {
                        const region = result['locationCode'];
                        const link = this.cfService.getCfLink(gateway, region);
                        const win = window.open(link, '_blank');
                        win.focus();
                    })
            );
        }
    }

    /**
     * Build the finalized appwan model that actually made it into the backend. Expand all of the resource links so we know what's in it
     */
    async _getFinalAppWan() {
        this.hasNewClients = false;
        this.initializeFinalModel();

        const appWanModel = new AppWan(this.appWan);

        // get the base model
        this.finalModel = await this.appwanService
            .getResource(appWanModel.getSelfLink())
            .toPromise()
            .then((appwan) => new AppWan(appwan));

        this.logger.info('final model ', this.finalModel);
        // batch retrieve all of the child resources
        const proms = [];

        // The next three if statements are a quick and dirty solution to a messy issue introduced by permissioning
        // it is possible that a user may not have the ability to list services, endpoint groups, or endpoints but they may have permission to create them
        // in this (probably rare) scenario, the console should not make a GET request for the services,groups, or endpoints (based on the user's permission)
        // not having all three of the promises would then break the final aggregate promise as it gets the results from the individual promises based on the index

        if (this.authorizationService.canListServices(undefined, undefined)) {
            proms.push(
                this.appwanService
                    .getLinkedResources(this.finalModel, 'services')
                    .toPromise()
                    .then((services) => services)
            );
        } else {
            proms.push(
                new Promise((resolve, reject) => {
                    resolve([]);
                })
            );
        }

        if (this.canListGroups) {
            proms.push(
                this.appwanService
                    .getLinkedResources(this.finalModel, 'endpointGroups')
                    .toPromise()
                    .then((groups) => groups)
            );
        } else {
            proms.push(
                new Promise((resolve, reject) => {
                    resolve([]);
                })
            );
        }

        if (this.canListEndpoints) {
            proms.push(
                this.appwanService
                    .getLinkedResources(this.finalModel, 'endpoints')
                    .toPromise()
                    .then((endpoints) => endpoints)
            );
        } else {
            proms.push(
                new Promise((resolve, reject) => {
                    resolve([]);
                })
            );
        }

        return await Promise.all(proms).then((values) => {
            const services = values[0];
            const groups = values[1];
            const endpoints = values[2];

            this.finalModel['services'] = services;
            this.finalModel['endpointGroups'] = [];
            for (const group of groups) {
                this.finalModel['endpointGroups'].push(new Group(group));
            }
            this.finalModel['clients'] = [];
            this.finalModel['gateways'] = [];
            for (const endpoint of endpoints) {
                if (endpoint.endpointType === 'CL' || endpoint.endpointType === 'ZTCL') {
                    if (endpoint['registrationKey'] !== null) {
                        this.hasNewClients = true;
                    }
                    this.finalModel['clients'].push(endpoint);
                    this.sendto[this.finalModel['clients'].length - 1] = '';
                    this.sendingState[this.finalModel['clients'].length - 1] = '';
                } else {
                    this.finalModel['gateways'].push(endpoint);
                }
            }

            for (const svc of services) {
                if (svc['serviceClass'] === 'CS') {
                    this.ipHostServices.push(svc);
                }
                if (svc['serviceClass'] === 'GW') {
                    this.ipNetworkServices.push(svc);
                }
            }

            // get the hosting gateway model
            if (services.length > 0) {
                const service = new Service(services[0]);
                const linkedEndpointId = service.getLinkedResourceId('endpoint');
                const linkedClusterId = service.getLinkedResourceId('gatewayCluster');

                const endpointId = linkedClusterId != null ? linkedClusterId : linkedEndpointId;
                for (const gateway of this.gateways) {
                    if (gateway.getId() === endpointId) {
                        this.finalModel['hostingGateway'] = gateway;
                        this.finalModel['hostingGatewayName'] = gateway['name'];
                        break;
                    }
                }
            }

            return true;
        });
    }

    /**
     * Simple lookup method to translate a gateway ID to a name
     */
    public lookupGatewayName(service) {
        const serviceModel = new Service(service);
        const linkedEndpointId = serviceModel.getLinkedResourceId('endpoint');
        const linkedClusterId = serviceModel.getLinkedResourceId('gatewayCluster');

        const endpointId = linkedClusterId != null ? linkedClusterId : linkedEndpointId;
        for (const gateway of this.gateways) {
            if (endpointId === gateway.id) {
                return gateway.name;
            }
        }

        return '--';
    }

    SendRegistrationInline(client: Endpoint, index) {
        const endpoint = new Endpoint(client);
        // Send the registration email
        const emails = this.sendto[index];

        if (this.validateEmails(emails)) {
            this.sendingState[index] = 'sending';

            const payload = {
                toList: emails.split(';').join(','),
                ccList: '',
                subject: 'NetFoundry - Registration Information',
                from: 'no-reply@netfoundry.io',
                replacementParams: {
                    ENV: '',
                    EMAIL: localStorage.getItem('profile_email'),
                    USER: localStorage.getItem('profile_nick'),
                },
            };

            this.clientService.share(endpoint, payload).subscribe((res) => {
                this.sendingState[index] = 'sent';
                this.sendto[index] = '';
                setTimeout(() => {
                    this.sendingState[index] = '';
                }, 3000);
            });
        }
    }

    validateEmails(emails) {
        this.errorEmails = false;

        if (!this.validateService.hasValue(emails)) {
            this.errorEmails = true;
        }

        if (!this.validateService.isValidEmailList(emails)) {
            this.errorEmails = true;
        }

        return !this.errorEmails;
    }

    LaunchAzureCloudScript(gateway: Endpoint) {
        this.azureDeployService.openAzureFormationLink(gateway);
    }

    openService(data) {
        this.dialogInlineRef = this.dialogForm.open(ServicesformComponent, {
            data: { model: data, inline: true },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.dialogInlineRef.afterClosed().subscribe((result) => {
            /// Refresh Screen??
        });
    }

    openGateway(data) {
        this.dialogInlineGWRef = this.dialogForm.open(GatewaydashboardComponent, {
            data: { model: data, inline: true },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.dialogInlineGWRef.afterClosed().subscribe((result) => {
            /// Refresh Screen??
        });
    }

    openClient(data) {
        this.dialogInlineCLRef = this.dialogForm.open(ClientdashboardComponent, {
            data: { model: data, inline: true },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.dialogInlineCLRef.afterClosed().subscribe((result) => {
            /// Refresh Screen??
        });
    }

    resetForm() {
        this.createNew.emit();
    }

    editGroups(item, index) {
        const data = {
            model: item,
            inline: true,
        };

        this.groupRef = this.dialogForm.open(GroupsformComponent, {
            data: data,
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.groupRef.afterClosed().subscribe((result) => {
            if (result && result['newGroup']) {
                this.finalModel['endpointGroups'][index] = new Group(result['newGroup']);
            }
        });
    }

    edit() {
        const appWanModel = new AppWan(this.finalModel);
        const data = {
            model: appWanModel,
            clone: false,
        };

        this.dialogRef.close();

        this.formRef = this.dialogForm.open(AppWanFormComponent, {
            data: data,
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.formRef.afterClosed().subscribe((result) => {
            /// Refresh Screen??
        });
    }
}
