<div *ngIf="!isWizard">
    <div class="row">
        <div class="half pad-right">
            <div class="label row" id="WorldListDiv">
                <span class="private"></span>World Region<span></span><span class="note">Required</span>
            </div>
            <select
                (change)="worldRegionChange()"
                [(ngModel)]="selectedWorldRegion"
                [ngClass]="{ error: errorCountry }"
                class="countries select"
                placeholder="Select a World Region"
            >
                <option [ngValue]="null">Select A World Region</option>
                <option *ngFor="let worldRegion of worldRegions | pluck: 'worldRegion' | unique" [value]="worldRegion">
                    {{ worldRegion }}
                </option>
            </select>
        </div>
        <div class="half pad-left">
            <div class="label row" id="CountryListDiv">
                <span class="private"></span>Country<span></span><span class="note">Required</span>
            </div>
            <select
                (change)="countryChange()"
                [(ngModel)]="model.countryId"
                [ngClass]="{ error: errorCountry }"
                class="countries select"
                placeholder="Select a Country"
            >
                <option [ngValue]="null">Select A Country</option>
                <option *ngFor="let country of countries" [value]="country.id">
                    {{ country.name }}
                </option>
            </select>
        </div>
    </div>
</div>

<div *ngIf="isWizard">
    <div class="grid split">
        <select
            (change)="worldRegionChange()"
            [(ngModel)]="selectedWorldRegion"
            [ngClass]="{ error: errorCountry }"
            class="countries select"
            placeholder="Select a World Region"
        >
            <option [ngValue]="null">Select A World Region</option>
            <option *ngFor="let worldRegion of worldRegions | pluck: 'worldRegion' | unique" [value]="worldRegion">
                {{ worldRegion }}
            </option>
        </select>
        <select
            (change)="countryChange()"
            [(ngModel)]="model.countryId"
            [ngClass]="{ error: errorCountry }"
            class="countries select"
            placeholder="Select a Country"
        >
            <option [ngValue]="null">Select A Country</option>
            <option *ngFor="let country of countries" [value]="country.id">
                {{ country.name }}
            </option>
        </select>
    </div>
</div>
