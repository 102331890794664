import { Injectable } from '@angular/core';
import { Network } from '@netfoundry-ui/shared/model';
import { ReplaySubject, Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';

@Injectable({ providedIn: 'root' })
export class AlarmsService {
    private currentAlarmsSource = new ReplaySubject<any[]>();
    public currentAlarms = this.currentAlarmsSource.asObservable();

    private historicalAlarmsSource = new ReplaySubject<any[]>();
    public historicalAlarms = this.historicalAlarmsSource.asObservable();
    private apiSubHistorical = new Subscription();
    private apiSubCurrent = new Subscription();

    constructor(private logger: LoggerService, private apiService: ApiService) {}

    /**
     * Get historical alarms for
     */
    public getHistorical() {
        if (this.apiSubHistorical) {
            this.apiSubHistorical.unsubscribe();
        }

        // watch for when the network updates, when it does, update the gateways observable
        this.apiSubHistorical = this.apiService.currentNetwork.subscribe((network) => {
            if (network.status !== 300) {
                this.logger.info(
                    'Skipping alarms request, network controller is not ready. Status code is: ' + network.status
                );
                this.historicalAlarmsSource.next([]);
            } else {
                this._get('historical', network).subscribe((data) => {
                    this.logger.info('Historical Alarms GET', data);

                    if (
                        data !== null &&
                        data['result'] !== undefined &&
                        data['result'] === 'success' &&
                        data['alarms'] !== undefined &&
                        data['alarms']['alarm'] !== undefined
                    ) {
                        this.historicalAlarmsSource.next(data['alarms']['alarm']);
                    } else {
                        this.logger.info('Unable to retrieve alarms from dart', data);
                        this.historicalAlarmsSource.next([]);
                    }
                });
            }
        });

        // this is an observable that watches for network changes
        return this.historicalAlarms;
    }

    /**
     * Get current alarms for network
     */
    public getCurrentAlarmsForNetwork(network: Network) {
        const networkAlarmsSource = new ReplaySubject<any[]>();
        const networkAlarms = networkAlarmsSource.asObservable();

        if (network.status !== 300) {
            this.logger.info(
                'Skipping alarms request, network controller is not ready. Status code is: ' + network.status
            );
            networkAlarmsSource.next([]);
        } else {
            const path = `networks/${network.getId()}/alarms/current`;
            this.apiService.get(path).subscribe((data) => {
                this.logger.info('Current Alarms GET', data);

                if (
                    data !== null &&
                    data['result'] !== undefined &&
                    data['result'] === 'success' &&
                    data['alarms'] !== undefined &&
                    data['alarms']['alarm'] !== undefined
                ) {
                    networkAlarmsSource.next(data['alarms']['alarm']);
                } else {
                    this.logger.error('Unable to retrieve alarms from dart', data);
                    networkAlarmsSource.next([]);
                }
            });
        }
        return networkAlarms;
    }

    public getCurrent() {
        if (this.apiSubCurrent) {
            this.apiSubCurrent.unsubscribe();
        }

        // watch for when the network updates, when it does, update the gateways observable
        this.apiSubCurrent = this.apiService.currentNetwork.subscribe((network) => {
            if (network.status !== 300) {
                this.logger.info(
                    'Skipping alarms request, network controller is not ready. Status code is: ' + network.status
                );
                this.currentAlarmsSource.next([]);
            } else {
                this._get('current', network).subscribe((data) => {
                    this.logger.info('Current Alarms GET', data);

                    if (
                        data !== null &&
                        data['result'] !== undefined &&
                        data['result'] === 'success' &&
                        data['alarms'] !== undefined &&
                        data['alarms']['alarm'] !== undefined
                    ) {
                        this.currentAlarmsSource.next(data['alarms']['alarm']);
                    } else {
                        this.logger.error('Unable to retrieve alarms from dart', data);
                        this.currentAlarmsSource.next([]);
                    }
                });
            }
        });

        // this is an observable that watches for network changes
        return this.currentAlarms;
    }

    public cleanUp() {
        this.apiSubCurrent.unsubscribe();
        this.apiSubHistorical.unsubscribe();
    }

    protected _get(type: string, network: Network) {
        const path = `networks/${network.getId()}/alarms/${type}`;

        return this.apiService.get(path);
    }
}
