import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from '@netfoundry-ui/shared/services';

export interface Tile {
    elementId;
    type;
    svg;
    isVisible;
    title;
    description;
    buttonTitle;
    showExtraLink;
    extraLinkTitle;
    extraLinkType;
}

@Component({
    selector: 'app-tilepicker',
    templateUrl: './tilepicker.component.html',
    styleUrls: ['./tilepicker.component.scss'],
})
export class TilepickerComponent {
    @Input() isHidden = false;
    @Input() title = '';
    @Input() description = '';
    @Input() tileData: Tile[] = [];
    @Output() typeChange = new EventEmitter<any>();

    constructor(
        private logger: LoggerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<TilepickerComponent>
    ) {
        this.tileData = data.tiles;
        this.title = data.title;
        this.description = data.description;
    }

    setType(type) {
        this.logger.info('Type has changed to', type);
        this.typeChange.emit(type);
        this.hide();
    }

    hide() {
        this.dialogRef.close();
    }
}
