import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CLIENT_SYNCS_CONFIGURATION, ClientSyncsConfig, ClientSyncsService } from './client-syncs.service';

@NgModule({
    imports: [CommonModule, HttpClientModule],
    declarations: [],
    exports: [],
    providers: [],
})
export class SharedClientSyncsModule {
    static forRoot(config: ClientSyncsConfig): ModuleWithProviders<SharedClientSyncsModule> {
        return {
            ngModule: SharedClientSyncsModule,
            providers: [ClientSyncsService, { provide: CLIENT_SYNCS_CONFIGURATION, useValue: config }],
        };
    }
}
