import { HateoasResource, Resource } from '@lagoshny/ngx-hateoas-client';

@HateoasResource('browzers')
export class BrowZers extends Resource {
    domainNameLabel: string;
    networkId: string;
    edgeRouterProvider: string;
    edgeRouterRegion: string;
    id: string;
    status: string;
    publicCertEnabled: boolean;
    wssListener: boolean;
    bootstrapper: boolean;
}