<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="CertificateAuthorityAddModal">
  <div class="fullRow">
    <div class="title" style="filter: brightness(50%)">
      {{ isEditing ? 'Modify Your' : 'Create A New' }} Certificate Authority
    </div>
    <div class="subtitle">{{ isEditing ? 'Modify' : 'Enter' }} your CA details</div>
    <div
      class="subhelp"
      matTooltip="This is helpful for creating and enrolling endpoints in bulk. You may create a CA trust relationship with your network by importing your own CA certificate."
      matTooltipPosition="below"
    >
      What is this?
    </div>
    <div class="sized">
      <div class="label row">
        Certificate Name
        <div class="step">1</div>
        <span [ngClass]="{ error: errorName }" class="note">{{ errorName ? 'Invalid' : 'Required' }}</span>
      </div>
      <div class="row">
        <input
          [(ngModel)]="model.name"
          [ngClass]="{ error: errorName }"
          class="restrictNormal"
          id="EditName"
          maxlength="64"
          placeholder="Name this CA"
          type="text"
        />
      </div>

      <div *ngIf="!isEditing || bulkEdit" class="label row">
        Certificate Details
        <div
          class="infoicon"
          matTooltip="The CA certificate to import and trust for enrollment and authentication purposes. If you have chains of intermediate CAs, this should be the intermediate CA used to issue leaf certificates used by your devices."
          matTooltipPosition="below"
        ></div>
        <div class="step">2</div>
        <span [ngClass]="{ error: errorPem }" class="note">{{ errorPem ? 'Invalid' : 'Required' }}</span>
      </div>
      <div *ngIf="!isEditing || bulkEdit" class="row">
                <textarea
                  [(ngModel)]="model.certPem"
                  [ngClass]="{ error: errorPem }"
                  class="restrictNormal"
                  id="EditPem"
                  placeholder="PEM"
                  type="text"
                ></textarea>
      </div>
      <div class="grid three">
        <div (click)="toggleAutoEnrollment()" class="checkbox" id="CAAutoEnrollment">
          <div
            [ngClass]="{ selected: model.autoCaEnrollmentEnabled }"
            class="tSelect"
            id="toggleAutoEnrollment"
          ></div>
          <div class="label checkBoxLabel">
            Allow Auto-create
            <div
              class="infoicon"
              matTooltip="Create an enrolled endpoint when a certificate issued by this CA is presented for the first time along with this CA's reusable enrollment token."
              matTooltipPosition="below"
            ></div>
          </div>
        </div>

        <div (click)="toggleOttEnrollment()" class="checkbox" id="OTTCaEnrollment">
          <div
            [ngClass]="{ selected: model.ottCaEnrollmentEnabled }"
            class="tSelect"
            id="toggleOttCaEnrollment"
          ></div>
          <div class="label checkBoxLabel">
            Allow Pre-create
            <div
              class="infoicon"
              matTooltip="Enroll a pre-existing endpoint when its token is presented along with a certificate issued by this CA."
              matTooltipPosition="below"
            ></div>
          </div>
        </div>

        <div (click)="toggleAuthEnabled()" class="checkbox authEnabled" id="AuthEnabled">
          <div [ngClass]="{ selected: model.authEnabled }" class="tSelect" id="toggleAuthEnabled"></div>
          <div class="label checkBoxLabel">
            Enable Connections
            <div
              class="infoicon"
              matTooltip="Allow enrolled endpoints to connect to services. Leaving this off allows endpoints to enroll but not connect."
              matTooltipPosition="below"
            ></div>
          </div>
        </div>
      </div>

      <div *ngIf="model.autoCaEnrollmentEnabled" class="label row">
        Endpoint Attributes
        <div *ngIf="!isEditing || bulkEdit" class="step">3</div>
        <div *ngIf="isEditing && !bulkEdit" class="step">2</div>
        <div
          class="infoicon"
          matTooltip="Associates the provided endpoints and endpoint groups to this CA. Also assign these attributes if auto-creating an endpoint with this CA."
          matTooltipPosition="below"
        ></div>
      </div>
      <div *ngIf="model.autoCaEnrollmentEnabled" class="row">
        <app-tags-selector
          (addAttribute)="addEndpointsAttributes($event)"
          (removeAttribute)="removeEndpointAttribute($event)"
          [availableAttributes]="endpointAttributes"
          [disableField]="isEditing && !canEdit && !bulkEdit"
          [hasError]="endpointAttributeError"
          [isLoading]="isLoadingEndpointAttr"
          [placeholder]="'Choose the endpoints to associate to this CA'"
          [selectedAttributes]="selectedEndpointAttributes"
          id="Service"
        ></app-tags-selector>
      </div>

      <div *ngIf="model.autoCaEnrollmentEnabled" class="label row">
        Endpoint Name Format
        <div *ngIf="!isEditing || bulkEdit" class="step">4</div>
        <div *ngIf="isEditing && !bulkEdit" class="step">3</div>
        <span [ngClass]="{ error: errorIdentityNameFormat }" class="note">{{
          errorIdentityNameFormat ? 'Invalid' : ''
          }}</span>
        <div
          class="infoicon"
          matTooltip="The name format to use when endpoints are enrolled. Default is [caName]-[commonName]. If multiple endpoints would have the same name on enrollment, increasing digits will be added to the end of the name"
          matTooltipPosition="below"
        ></div>
      </div>
      <div *ngIf="model.autoCaEnrollmentEnabled" class="row">
        <input
          [(ngModel)]="model.identityNameFormat"
          [ngClass]="{ error: errorIdentityNameFormat }"
          class="restrictNormal"
          id="EditNameFormat"
          maxlength="255"
          placeholder="[caName]-[commonName]"
          type="text"
        />
      </div>
      <div *ngIf="model.autoCaEnrollmentEnabled" class="row">
        <div (click)="toggleFormattingHelp()" class="note subnote">
          {{ showFormattingHelp ? 'Hide' : 'Formatting' }} Help
        </div>
      </div>
      <div *ngIf="showFormattingHelp" class="row formattingHelp">
        <div class="row formattingHelpItem">
          <span class="formattingHelpLabel">[caName]</span> - replaced with the CA's name
        </div>
        <div class="row formattingHelpItem">
          <span class="formattingHelpLabel">[caId]</span> - replaced with the CA's id
        </div>
        <div class="row formattingHelpItem">
          <span class="formattingHelpLabel">[commonName]</span> - replaced with the common name of the
          enrolling certificate
        </div>
        <div class="row formattingHelpItem">
          <span class="formattingHelpLabel">[requestedName]</span> - replaced with the name requested by the
          enrolling identity
        </div>
        <div class="row formattingHelpItem">
          <span class="formattingHelpLabel">[identityId]</span> - replaced with the id of the resulting
          identity
        </div>
        <div class="row formattingHelpItem">
          <span class="formattingHelpLabel">no format provided</span> - default name format:
          [caName]-[commonName]
        </div>
      </div>

      <div *ngIf="!isEditing || canEdit || bulkEdit" class="row buttonRow right" style="margin-top: 20px">
        <button (click)="save()" *ngIf="!processing && !isLoading" class="save" id="SaveCAButton">
          {{ bulkEdit ? 'Done' : isEditing ? 'Update' : 'Create' }}
        </button>
        <button *ngIf="isLoading || processing" class="save confirm" disabled>
          {{ isLoading ? 'Loading' : 'Saving' }}
          <span class="spinner"></span>
        </button>
      </div>
    </div>
  </div>
  <div (click)="hide()" class="buttonBall close" id="CloseClientButton">
    <div class="buttonLabel">ESC</div>
  </div>
  <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
