import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { LoggerService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'app-pickbox',
    templateUrl: './pickbox.component.html',
    styleUrls: ['./pickbox.component.scss'],

    // we need to tell the browser when to re-render inside the confines of this component
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickboxComponent {
    @Input() identifier = 'picker';

    // the master list of items, "selected" items will be hidden
    @Input() items = [];
    @Input() sortField = 'name';
    @Input() searchField = 'name';
    @Input() searchString = '';
    @Input() resourceType = 'endpoint';
    @Input() idProperty = 'id';
    @Input() iconClass = 'itemGraphic icon-CL';
    @Input() height = '110px';
    @Input() scrollable = false;
    @Input() isLoading = false;
    @Input() canList = true;
    @Input() canEdit = true;
    @Input() changed = 0;
    @Input() canSelect = true;
    @Input() selectErrorMessage = '';

    // this means you clicked it, and it is now hidden from the list
    @Output() itemSelected = new EventEmitter<any>();

    // this means it is visible and back into the list
    @Output() itemDeselected = new EventEmitter<any>();

    // tell the world we just changed...some other list somewhere might need to change
    @Output() notifyChange = new EventEmitter<any>();

    itemsSelected = [];
    itemIdsSelected = [];
    itemsRemoved = [];
    itemIdsRemoved = [];

    constructor(
        private logger: LoggerService,
        private changeRef: ChangeDetectorRef,
        private growlerService: GrowlerService
    ) {}

    // this adds to the master list
    addToList(model) {
        // const itemsCopy = this.items;

        this.logger.info('Add to list', model);
        // itemsCopy.push(model);

        this.items.push(model);

        // let the browser know we should re-render
        this.triggerRefresh();
    }

    // this remove an entry from the master list, and all other lists
    removeFromList(model) {
        this.removeFromSelected(model);

        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            if (item[this.idProperty] === model[this.idProperty]) {
                this.logger.info('Remove item from master list', item);
                this.items.splice(i, 1);
                break;
            }
        }

        // let the browser know we should re-render
        this.triggerRefresh();
    }

    // this selects an items, hides it, but keeps it in the master list
    addToSelected(model) {
        if (!this.canEdit) {
            this.logger.info('No edit permissions, doing nothing');
            return;
        }

        if (!this.canSelect) {
            this.growlerService.show(new GrowlerData('error', 'Error', 'Cannot Select', this.selectErrorMessage));
            return;
        }

        this.items.splice(this.items.indexOf(model), 1);

        this.logger.info(this.identifier + ': Add to selected', model);

        // emit the event
        this.itemSelected.emit(model);

        // add model to the list
        this.itemsSelected.push(model);

        // add id to the list
        this.itemIdsSelected.push(model[this.idProperty]);

        this.triggerRefresh();
    }

    // is the ID in the selected array?
    isSelected(model) {
        return this.itemIdsSelected.indexOf(model.getId()) >= 0;
    }

    // removes the model and ID from the "selected" lists and makes the item visible again
    removeFromSelected(model) {
        this.logger.info(this.identifier + ': Remove from selected', model);

        // notify of removal
        this.itemDeselected.emit(model);

        // remove from list of selected ids
        this.itemIdsSelected.splice(this.itemIdsSelected.indexOf(model.getId()), 1);

        // add to list of items removed
        this.itemIdsRemoved.push(model.getId());

        // add to list of models removed
        this.itemsRemoved.push(model);

        // remove from list of models selected
        for (let i = 0; i < this.itemsSelected.length; i++) {
            const item = this.itemsSelected[i];
            if (item[this.idProperty] === model[this.idProperty]) {
                this.logger.info('Remove item from selected', item);
                this.itemsSelected.splice(i, 1);
            }
        }

        this.items.push(model);

        // let the browser know we should re-render
        this.triggerRefresh();
    }

    // Angular does not always detect the changes properly, this is a dirty hack to force it to do so
    triggerRefresh() {
        setTimeout(() => {
            const strCopy = this.searchString;
            this.searchString = '';
            this.items = [...this.items];
            this.itemsSelected = [...this.itemsSelected];
            this.changeRef.detectChanges();
            this.notifyChange.emit(1);
            this.searchString = strCopy;
        }, 10);
    }

    trackById(index, item) {
        return item.id;
    }
}
