<div
    (click)="!comingSoon && doAction()"
    [style.pointer-events]="comingSoon ? 'none' : 'auto'"
    class="serviceCardTile"
    id="Stage1"
>
    <div>
        <div class="buttonCard selectType" id="SelectButtonSimpleService">
            <div [ngStyle]="iconStyle" class="icon"></div>
            <div class="title">{{ title }}</div>
            <div class="content">
                {{ subtitle }} <span class="red">{{ note }} </span><br />&nbsp;
            </div>
            <div *ngIf="!comingSoon" class="row center buttonArea">
                <button class="save">Create Service</button>
            </div>
            <div *ngIf="comingSoon" class="row center buttonArea">
                <button class="save disabled">Requires Upgrade</button>
            </div>
            <div class="row center padTop subButton">&nbsp;</div>
        </div>
    </div>
</div>
