<div *ngIf="!isLoading" class="start-menu-header-background"></div>
<div *ngIf="!isLoading" class="start-menu-footer-background"></div>
<div class="start-menu-loader-container">
    <app-loading-indicator [isLoading]="isLoading"></app-loading-indicator>
</div>
<div class="start-menu-anchor-container">
    <div *ngIf="!isLoading" class="start-menu-container">
        <div [ngClass]="{ provisioning: provisioning }" class="start-menu-header">
            <div class="start-menu-header-title-container">
                <div class="col-sm-4 pl-0">
                    <div class="header-title">Quick Start Guide<br /></div>
                </div>
                <div class="col-sm-7">
                    <div class="header-details">
                        <div class="detail-meet-ziggy">ZIGGY FROM NETFOUNDRY</div>
                        <div class="detail-congrats">
                            {{ noNetwork ? NO_NETWORK_TEXT : provisioningNetwork ? PROVISIONING_TEXT : ZIGGY_TEXT }}
                        </div>
                        <div class="header-details__arrow"></div>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="header-icon">
                        <div class="gettingstartedziggy">
                            <ng-lottie
                                (animationCreated)="animationCreated($event)"
                                *ngIf="provisioningNetwork"
                                [options]="lottieOptionsConstruction"
                            ></ng-lottie>
                        </div>
                        <div class="gettingstartedziggy">
                            <ng-lottie
                                (animationCreated)="animationCreated($event)"
                                *ngIf="!provisioningNetwork"
                                [options]="lottieOptionsHello"
                            ></ng-lottie>
                        </div>
                    </div>
                </div>
            </div>
            <app-header-card [cards]="headerCards"></app-header-card>
        </div>

        <div class="start-menu-list-container">
            <app-start-menu-item
                (itemSelected)="item.itemSelected($event)"
                *ngFor="let item of menuItems"
                [buttonText]="item.buttonText"
                [completed]="item.completed"
                [disabledTooltip]="item.disabledTooltip"
                [disabled]="item.disabled"
                [hidden]="item.hidden"
                [iconClass]="item.iconClass"
                [showSpinner]="item.showSpinner"
                [subtitle]="item.subtitle"
                [title]="item.title"
            >
            </app-start-menu-item>
        </div>
        <div class="row">
            <div class="col-sm-6 pl-0 mt8" style="color: var(--tableText)">
                Return to this guide at any point by navigating to: <br />
                <i class="icon-support" style="font-size: 11px"></i> Support
                <i class="icon-next" style="font-size: 11px"></i>
                <i class="icon-QuickStart" style="font-size: 11px"></i> Getting Started
            </div>
            <div class="col-sm-6 toggle-container pr-0">
                <div class="hide-start-page-toggle mt8">
                    <span class="toggle-text">Hide this page</span>
                    <div (click)="toggleHideStartPage()" [ngClass]="{ on: !showStartPage }" class="toggle">
                        <span [hidden]="!showStartPage" style="margin-left: 15px">OFF</span>
                        <span [hidden]="showStartPage" style="margin-right: 15px">ON</span>
                        <div class="switch"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
