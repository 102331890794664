import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-text-property-editor',
    templateUrl: './text-property-editor.component.html',
    styleUrls: ['./text-property-editor.component.scss'],
})
export class TextPropertyEditorComponent {
    @Input() model = {};
    @Input() propertyName = '';
    @Input() label = '';
    @Input() disabled = false;
    @Input() required = false;
    @Input() errors = [];
    @Input() maxLength = 64;
    @Input() minLength = 5;
    @Input() placeholder = '';

    @Output() modelChange: EventEmitter<any> = new EventEmitter();
    @Output() focusOut: EventEmitter<any> = new EventEmitter();

    onFocusOut() {
        this.focusOut.emit(this.model);
    }

    onKeyUp(event) {
        this.modelChange.emit(this.model);
    }
}
