import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class GetCountryService {
    constructor(public apiService: ApiService) {}

    getCountries() {
        return this.apiService.get(`countries`);
    }

    getCountriesPipe() {
        return this.apiService.get(`countries`).pipe(map(this._extractEmbedded));
    }

    protected _extractEmbedded(res) {
        const countries = [];

        if (res['_embedded'] !== undefined && res['_embedded']['countries'] !== undefined) {
            for (let i = 0; i < res['_embedded']['countries'].length; i++) {
                const country = res['_embedded']['countries'][i];
                countries.push(country);
            }
        }

        return countries;
    }
}
