import {Component, Inject, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EdgeRouterServiceV2, EndpointServiceV2, PlatformServiceService } from '@netfoundry-ui/shared/apiv2';
import { ElasticsearchService } from '@netfoundry-ui/shared/elasticsearch';
import { ApiService, LoggerService } from '@netfoundry-ui/shared/services';
import { TEMPLATE_SEARCH_SERVICE, TemplateSearchService } from "@netfoundry-ui/shared/apiv2";
import { TobytesPipe } from '@netfoundry-ui/ui/pipes';
import { Chart } from 'angular-highcharts';
import { Subscription } from 'rxjs';
import { NetworkUtilizationTemplateQueryParameter } from '@netfoundry-ui/shared/model';
import { isEmpty, isNil } from "lodash";

@Component({
    selector: 'app-ziti-utilization-pie',
    templateUrl: './ziti-utilization-pie.component.html',
    styleUrls: ['./ziti-utilization-pie.component.scss'],
    providers: [TobytesPipe, { provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }],
})
export class ZitiUtilizationPieComponent implements OnInit, OnChanges, OnDestroy {
    @Input() sourceId: string;
    @Input() networkId: any = null;
    @Input() organizationId: any = null;
    @Input() dateFilter: any = '24h';
    @Input() height = '300px';
    @Input() aggregateField: string;
    @Input() sumAggregateField: string;
    @Input() getById: (id: string) => Promise<string>;
    chart: Chart;
    colors = ['#0273fb', '#08dc5a', '#FF0D49', '#1aadce', '#6d00f2', '#ffc000', '#ff7e00', '#ca0000', '#00aeb0'];
    noData = true;
    isLoading = false;
    initialized = false;
    currentOrgId;
    zitiId;
    top_five_options = {};
    top_five_series = [];
    interval = '1m';
    items = [];
    @Input() filterField = null;
    @Input() filterValue = null;
    @Input() endTime = Date.now();
    @Input() startTime = this.endTime - 24 * 60 * 60 * 1000;
    private subscription = new Subscription();

    constructor(
        private logger: LoggerService,
        private elasticsearch: ElasticsearchService,
        private toBytes: TobytesPipe,
        private graphViewer: MatDialog,
        public apiService: ApiService,
        public platformService: PlatformServiceService,
        public endpointService: EndpointServiceV2,
        public edgeRouterService: EdgeRouterServiceV2,
        @Inject(TEMPLATE_SEARCH_SERVICE) private templateService: TemplateSearchService
    ) {}

    ngOnInit() {
        this.initialized = true;
        this.generateTopFiveData();
    }

    ngOnChanges() {
        this.generateTopFiveData();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public set_chart_options() {
        this.top_five_options = {
            colors: this.colors,
            styledMode: true,
            title: { text: null },
            credits: { enabled: false },
            chart: {
                type: 'pie',
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                height: '300px',
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['100px', '100px'], // this was doing weird things to the tooltip and hiding it behind the white
                    dataLabels: {
                        enabled: false,
                    },
                    size: '100%',
                },
            },
            yAxis: {
                title: { text: null },
                // tickPixelInterval: 20,
            },
            tooltip: { enabled: false },
        };
    }

    public getTop5() {
        const index = 'ncutilization';
        this.logger.info('Ziti Top 5:', JSON.stringify(this.getQuery()));
        this.noData = true;
        this.isLoading = true;
        const size = '5';
        //reporting
        const networkUtilizationTemplateQueryParameter: NetworkUtilizationTemplateQueryParameter = this.templateService.getTopComponentsTemplateQuery(this.dateFilter, this.aggregateField, this.networkId, this.organizationId, index, size);

        this.subscription.add(
            //this.elasticsearch.search(this.organizationId, index, this.getQuery())
            this.elasticsearch
                .apiTemplateSearch(this.templateService.TOP_COMPONENTS_TEMPLATE, networkUtilizationTemplateQueryParameter)
                .subscribe(async (data) => {
                    const topComponents = this.templateService.getTopComponentsData(data);
                    if (!isEmpty(topComponents) && !isNil(topComponents)) {
                      this.noData = false;
                      this.isLoading = false;
                    }
                    this.set_chart_options();
                    this.top_five_series = [
                        {
                            name: 'Endpoints',
                            data: topComponents.TopFive,
                        },
                    ];
                    this.items = topComponents.items;
                    this.isLoading = false;
                }, () => {
                    this.isLoading = false;
                })
        );
    }

    public getQuery() {
        const model: any = {
            aggs: {
                unique_items: {
                    terms: {
                        field: this.aggregateField + '.keyword',
                        size: 5,
                        order: {
                            item_sum: 'desc',
                        },
                    },
                    aggs: {
                        item_sum: {
                            sum: {
                                field: 'usage',
                            },
                        },
                    },
                },
            },
            size: 0,
            query: {
                bool: {
                    must: [
                        {
                            range: {
                                timestamp: {
                                    gte: 'now-' + this.dateFilter,
                                    lte: 'now',
                                    format: 'epoch_millis',
                                },
                            },
                        },
                        {
                            match_phrase: {
                                'network_id.keyword': {
                                    query: this.networkId,
                                },
                            },
                        },
                        {
                            match_phrase: {
                                'organizationId.keyword': {
                                    query: this.organizationId,
                                },
                            },
                        },
                        {
                            // filter down to two usage types since these are the items that we will calculate billing on
                            bool: {
                                should: [
                                    {
                                        match_phrase: {
                                            // traffic delivered through the fabric to service's destination, renders as TX
                                            'usage_type.keyword': 'usage.ingress.tx',
                                        },
                                    },
                                    {
                                        match_phrase: {
                                            // traffic received back through the fabric to the initiator, renders as RX
                                            'usage_type.keyword': 'usage.egress.tx',
                                        },
                                    },
                                ],
                                minimum_should_match: 1,
                            },
                        },
                    ],
                },
            },
        };

        // this will allow us to filter by endpoint, edge router, or service
        if (this.filterField != null && this.filterValue != null) {
            const search = { match_phrase: {} };
            search.match_phrase[this.filterField] = { query: this.filterValue };
            model.query.bool.must.push(search);
        }

        return model;
    }

    private generateTopFiveData() {
        if (this.initialized && this.networkId !== null && this.networkId && this.organizationId !== null) {
            this.getTop5();
        }
    }
}
