<div class="row">
    <div *ngIf="!isLoading && !noData">
        <app-highcharts-generic
            [chartheight]="height"
            [options]="identity_options"
            [series]="identity_series"
        ></app-highcharts-generic>
    </div>
</div>

<!--<div class="row">-->
<!--  <div *ngIf="!isLoading && !noData">-->
<!--    <app-highcharts-generic [options]="utilization_options" [chartheight]="height" [series]="utilization_rx_series" ></app-highcharts-generic>-->
<!--  </div>-->
<!--</div>-->

<div *ngIf="!isLoading && noData" class="noDataZiti">No Data</div>
<app-loader [isLoading]="isLoading"></app-loader>
