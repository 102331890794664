import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import _ from 'lodash';

@Component({
    selector: 'app-table-cell-resource-status',
    templateUrl: './table-cell-resource-status.component.html',
    styleUrls: ['./table-cell-resource-status.component.scss'],
})
export class TableCellResourceStatusComponent implements ICellRendererAngularComp {
    cellParams;
    item = {
        id: '',
        name: '',
        resourceType: '',
        processTree: [],
    };
    resourceType = '';
    expanded = false;

    get showExpandCollapse() {
        return _.get(this.item, 'processTree.length') === 1;
    }

    agInit(params: ICellRendererParams): void {
        if (!params.node.data) {
            return;
        }
        this.cellParams = params;
        this.item = params.node.data;
        this.resourceType = _.get(params, 'resourceType');
    }

    refresh(params: ICellRendererParams): boolean {
        this.cellParams = params;
        this.item = params.data;
        return true;
    }

    expandRow(): void {
        if (!this.expanded) {
            this.cellParams.node.setExpanded(true);
        } else {
            this.cellParams.node.setExpanded(false);
        }
        this.expanded = !this.expanded;
    }
}
